import { environment } from '@marlin/environment';

import { Logger } from './logger';
import { initLogger } from './logger.module';

export const setupLogger = (): void => {
  if (environment.module.logger.token && environment.module.logger.token !== '') {
    initLogger(new Logger(environment.module.logger.token));
  }
};
