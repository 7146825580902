import { MarlinTheme } from '@marlin/shared/theme';
import { TDateString, defaultDateTime, formatDate } from '@marlin/shared/utils-common-date';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  content: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(26),
    marginBottom: theme.typography.pxToRem(24),
    color: theme.palette.text.secondary,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.typography.pxToRem(10),
    },
  },

  time: {
    textTransform: 'uppercase',
  },

  formattedTime: {
    [theme.breakpoints.down('md')]: {
      color: theme.palette.text.primary,
    },
  },
}));

interface ITimeSectionProps {
  label: string;
  datetime: TDateString | null;
  children?: JSX.Element;
}

export function TimeSection({ label, datetime, children }: ITimeSectionProps) {
  const { classes } = useStyles();

  const formattedTime = useMemo(() => {
    return formatDate(datetime, defaultDateTime);
  }, [datetime]);

  return (
    <div className={classes.content}>
      <div className={classes.time}>
        {label}: <span className={classes.formattedTime}>{formattedTime}</span>
      </div>
      <div>{children}</div>
    </div>
  );
}
