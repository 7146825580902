import { Link } from '@marlin/shared/ui-page';

import { content } from '../translation-keys';
import { BaseError } from './base-error.component';

export const InvitationNotAccepted = (): JSX.Element => {
  return (
    <BaseError
      title={content.INVITATION_NOT_ACCEPTED_TITLE}
      subtitle={
        <>
          {content.INVITATION_NOT_ACCEPTED_BODY}&nbsp;
          {
            <Link to={`mailto:${content.SUPPRORT_LINK}`} external>
              {content.SUPPRORT_LINK}
            </Link>
          }
          {'.'}
        </>
      }
    />
  );
};
