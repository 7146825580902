import { MarlinTheme } from '@marlin/shared/theme';
import { ChipGroup } from '@marlin/shared/ui-form';
import { FormField, Select } from '@marlin/shared/ui-form-common';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';
import { useMobileFilterFormContext } from './mobile-filter-form-provider.component';
import { IBaseFilters, TSortingOrder, defaultSorting } from './model';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  select: {
    width: '100%',
  },

  chips: {
    marginTop: theme.typography.pxToRem(8),
  },
}));

const sortingChips = [
  {
    label: content.LABEL_DESCENDING,
    value: 'Descending' as const,
  },
  {
    label: content.LABEL_ASCENDING,
    value: 'Ascending' as const,
  },
];

export const MobileFilterSorting = <TSort extends string, TFilters extends IBaseFilters<TSort>>() => {
  const { classes } = useStyles();
  const { sortList } = useMobileFilterFormContext<TSort, TFilters>();
  const { watch, setValue } = useFormContext();
  const isSortBySet = watch('sorting.sortBy') !== defaultSorting.sortBy;

  useEffect(() => {
    if (!isSortBySet) {
      setValue('sorting.direction', defaultSorting.direction);
    }
  }, [isSortBySet, setValue]);

  return (
    <div>
      <FormField<IBaseFilters<TSort>> fieldName="sorting.sortBy">
        {(props) => (
          <Select
            {...props}
            prefix="sorting"
            label={content.SORTING_LABEL}
            data={sortList}
            emptyOption=" "
            className={classes.select}
          />
        )}
      </FormField>
      <FormField<IBaseFilters<TSort>> fieldName="sorting.direction">
        {(props) => (
          <div className={classes.chips}>
            <ChipGroup<TSortingOrder> {...props} chips={sortingChips} disabled={!isSortBySet} />
          </div>
        )}
      </FormField>
    </div>
  );
};
