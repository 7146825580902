import { IAddress } from '@marlin/asset/data-access/location';
import { SectionEntry, SectionHeader, SectionTitle } from '@marlin/shared/ui-asset-card-mobile';

import { content } from '../../../content';

interface IDetailsProps {
  address: IAddress;
}

const addressDataTestId = 'location-address';
const addressDataEntryTestId = `${addressDataTestId}-entry`;

const territoryLabel = (country?: string) => {
  return country === 'Canada' ? content.PROVINCE : content.STATE;
};

const postalLabel = (country?: string) => {
  return country === 'Canada' ? content.POSTAL_CODE : content.ZIP_CODE;
};

export const Address = ({ address }: IDetailsProps) => {
  return (
    <>
      <SectionHeader>
        <SectionTitle testId="location-address">{content.LOCATION_ADDRESS}</SectionTitle>
      </SectionHeader>
      <SectionEntry testId={addressDataEntryTestId} title={content.COUNTRY}>
        {address.country || '-'}
      </SectionEntry>
      <SectionEntry testId={addressDataEntryTestId} title={content.ADDRESS_1}>
        {address.address1 || '-'}
      </SectionEntry>
      <SectionEntry testId={addressDataEntryTestId} title={content.ADDRESS_2}>
        {address.address2 || '-'}
      </SectionEntry>
      <SectionEntry testId={addressDataEntryTestId} title={content.CITY}>
        {address.city || '-'}
      </SectionEntry>
      <SectionEntry testId={addressDataEntryTestId} title={territoryLabel(address.country)}>
        {address.state || '-'}
      </SectionEntry>
      <SectionEntry testId={addressDataEntryTestId} title={postalLabel(address.country)}>
        {address.postalCode || '-'}
      </SectionEntry>
    </>
  );
};
