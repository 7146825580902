import { content } from './content';
import { IGlobalEquipmentConfig } from './model';

export const globalConfigTekmarSsc: IGlobalEquipmentConfig = {
  dashboard: {
    liveDataExpirationSeconds: 720,
  },
  calculationValues: false,
  chart: [
    {
      name: 'temperature',
      label: content.temperature,
      type: 'rangeArea',
      additionalAxisUom: '%',
      mainDatapoint: 'supplyTemp',
    },
    {
      name: 'pressure',
      label: content.pressure,
      type: 'rangeArea',
      mainDatapoint: 'pressure',
    },
    {
      name: 'boilerFiringRate',
      label: content.boilerFiringRate,
      type: 'rangeArea',
      mainDatapoint: 'boiler1FiringRate',
    },
  ],
};
