import { z } from 'zod';

export const TelemetryParamsSchema = z
  .object({
    from: z.string(),
    to: z.string(),
  })
  .transform((data) => ({
    from: new Date(data.from),
    to: new Date(data.to),
  }));

export type TTelemetryParams = z.infer<typeof TelemetryParamsSchema>;
