import { MarlinTheme } from '@marlin/shared/theme';
import { Link } from '@marlin/shared/ui-page';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { TAssetType, TDeviceType } from '@marlin/system-map/shared/data-access-schemas';
import DeviceHubRoundedIcon from '@mui/icons-material/DeviceHubRounded';
import EggRoundedIcon from '@mui/icons-material/EggRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import PanoramaPhotosphereOutlinedIcon from '@mui/icons-material/PanoramaPhotosphereOutlined';
import SensorsRoundedIcon from '@mui/icons-material/SensorsRounded';
import SpeedRoundedIcon from '@mui/icons-material/SpeedRounded';
import ThermostatRoundedIcon from '@mui/icons-material/ThermostatRounded';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  assetLinkWrapper: {
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.typography.pxToRem(4),
    flexWrap: 'wrap',
    color: theme.palette.primary.contrastText,
    '& > a': {
      color: theme.palette.primary.contrastText,
    },
  },
}));

const DeviceTypeLabel = ({ deviceType }: { deviceType: TDeviceType | null }) => {
  switch (deviceType) {
    case 'TEMPERATURE': {
      return (
        <>
          <ThermostatRoundedIcon />
          {content.TEMPERATURE_LABEL}
        </>
      );
    }
    case 'PRESSURE': {
      return (
        <>
          <SpeedRoundedIcon />
          {content.PRESSURE_LABEL}
        </>
      );
    }
    case 'LEAK': {
      return (
        <>
          <EggRoundedIcon />
          {content.LEAK_LABEL}
        </>
      );
    }
    case 'PULSE_METER': {
      return (
        <>
          <PanoramaPhotosphereOutlinedIcon />
          {content.FLOW_LABEL}
        </>
      );
    }
    default: {
      return (
        <>
          <SensorsRoundedIcon />
          {content.SENSOR}
        </>
      );
    }
  }
};

export interface IAssetLinkProps {
  asset: {
    id: string | null;
    type?: TAssetType | null;
    name: string | null;
    deviceType?: TDeviceType | null;
  };
}

export const AssetLink = ({ asset }: IAssetLinkProps) => {
  const { classes } = useStyles();
  const router = useRouter();
  const currentUrl = useMemo(() => router.getUrl(), [router]);

  switch (asset.type) {
    case 'LOCATION': {
      return (
        <div className={classes.assetLinkWrapper}>
          <LocationOnRoundedIcon />
          {content.LOCATION}
          <Link to={`/locations/${asset.id}`}>{asset.name}</Link>
        </div>
      );
    }
    case 'EQUIPMENT': {
      return (
        <div className={classes.assetLinkWrapper}>
          <DeviceHubRoundedIcon />
          {content.EQUIPMENT}
          <Link to={`/equipment/${asset.id}`}>{asset.name}</Link>
        </div>
      );
    }
    case 'DEVICE': {
      if (asset.deviceType) {
        return (
          <div className={classes.assetLinkWrapper}>
            <DeviceTypeLabel deviceType={asset.deviceType} />
            <Link to={routes.deviceDrawer.open(currentUrl, asset.id || '')}>{asset.name}</Link>
          </div>
        );
      }

      return <div>{asset.type}</div>;
    }

    default: {
      return <div>{asset.type}</div>;
    }
  }
};
