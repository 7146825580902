import { useSearchParams } from '@marlin/shared/utils-router';
import { useCallback } from 'react';

import { useEditSettingContext } from '../../../shared/context/edit-setting.context';

interface IUseSettingListItem {
  name: string;
}

export const useSettingListItem = ({ name }: IUseSettingListItem) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const settingId = searchParams.get('settingId');
  const isEditing = searchParams.get('settingId') === name;
  const { setEditedSetting, setIsDirty } = useEditSettingContext();

  const onEditClick = useCallback(() => {
    if (!isEditing) {
      setSearchParams((currentParams) => {
        currentParams.set('settingId', name);

        return currentParams;
      });
    }
  }, [isEditing, name, setSearchParams]);

  const onCancelClick = () => {
    // TODO (improvement) refactor: wait for isDirty to be false using useStateWithCallback or ignore guard by adding shouldIgnoreGuard
    setSearchParams(
      (currentParams) => {
        if (settingId) {
          currentParams.delete('settingId');
        }

        return currentParams;
      },
      { state: { shouldIgnoreGuard: true } }
    );
    setEditedSetting(undefined);
    setIsDirty(false);
  };

  return { isEditing, onEditClick, onCancelClick };
};
