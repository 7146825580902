import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import TextsmsRoundedIcon from '@mui/icons-material/TextsmsRounded';

import { content } from '../../content';
import { CHANNEL_TYPE, IAlert } from '../../types';
import { RecipientList } from '../recipient-list.component';

interface IAlertRecipientsSectionProps {
  alert: IAlert;
}

export const RecipientsSection = ({ alert }: IAlertRecipientsSectionProps) => {
  return (
    <div data-testid="recipients-section">
      <RecipientList
        icon={<EmailRoundedIcon />}
        label={content.CARD.CURRENT.LABEL.EMAIL}
        users={alert.channels[CHANNEL_TYPE.EMAIL]?.recipients}
        channelType={CHANNEL_TYPE.EMAIL}
      />
      <RecipientList
        icon={<TextsmsRoundedIcon />}
        label={content.CARD.CURRENT.LABEL.TEXT}
        users={alert.channels[CHANNEL_TYPE.SMS]?.recipients}
        channelType={CHANNEL_TYPE.SMS}
      />
    </div>
  );
};
