import { queryClient } from '@marlin/shared/utils/react-query';
import { UseMutationResult, useMutation } from '@marlin/shared/utils/react-query';

import { toggleDeviceDetailsPinnedCache } from '../cache/toggle-device-details-pinned-cache';
import { deletePinDevice } from '../infrastructure/delete-pin-device';
import { IPinDevice } from '../pin-device.model';
import { queryKey } from './query-key.enum';

interface IDeleteDevice {
  previousData: IPinDevice | undefined;
}

export const useDeletePinDevice = (): UseMutationResult<unknown, unknown, string, IDeleteDevice> => {
  return useMutation<unknown, unknown, string, IDeleteDevice>({
    onMutate: async (id: string) => {
      await queryClient.cancelQueries({ queryKey: [queryKey.PIN_DEVICES(), queryKey.DEVICE(id)], exact: false });
      queryClient.removeQueries({ queryKey: queryKey.PIN_DEVICE(id) });

      const previousData = queryClient
        .getQueryData<IPinDevice[]>(queryKey.PIN_DEVICES())
        ?.find((device: IPinDevice) => device.id === id);

      queryClient.setQueriesData<IPinDevice[] | undefined>(
        { queryKey: queryKey.PIN_DEVICES() },
        (data?: IPinDevice[]) => {
          if (!data) {
            return data;
          }

          return data.filter((device: IPinDevice) => device.id !== id) || [];
        }
      );

      toggleDeviceDetailsPinnedCache(id);

      return { previousData };
    },
    onError: (_: unknown, id: string, context: IDeleteDevice | undefined) => {
      const previousData = context?.previousData;

      if (!previousData) {
        return;
      }

      queryClient.setQueriesData({ queryKey: queryKey.PIN_DEVICE(id) }, previousData);

      queryClient.setQueriesData<IPinDevice[] | undefined>(
        { queryKey: queryKey.PIN_DEVICES() },
        (data?: IPinDevice[]) => {
          if (!data) {
            return data;
          }

          return [...(data || []), previousData];
        }
      );

      queryClient.invalidateQueries({ queryKey: queryKey.DEVICE(id) });
      queryClient.setQueriesData({ queryKey: queryKey.PIN_DEVICE(id) }, previousData);
    },
    onSuccess: (_: unknown, id: string) => {
      queryClient.invalidateQueries({ queryKey: queryKey.PIN_DEVICES() });
      queryClient.invalidateQueries({ queryKey: queryKey.DEVICE(id) });
      queryClient.invalidateQueries({ queryKey: queryKey.HOME_LOCATIONS() });
    },
    mutationFn: deletePinDevice,
  });
};
