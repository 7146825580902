import { useCallback, useState } from 'react';

export const useSessionToken = () => {
  const [sessionToken, setSessionToken] = useState<google.maps.places.AutocompleteSessionToken | undefined>();

  const createSessionToken = useCallback(() => {
    let token;
    try {
      token = new google.maps.places.AutocompleteSessionToken();
    } catch (error) {
      token = Math.floor(Math.random() * 1000000);
    }

    setSessionToken(token);
  }, []);

  const cancelSessionToken = useCallback(() => {
    setSessionToken(undefined);
  }, []);

  return { sessionToken, createSessionToken, cancelSessionToken };
};
