import { MarlinTheme } from '@marlin/shared/theme';
import { alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface IStyles {
  fullCardMobile?: boolean;
}

export const useStyles = makeStyles<IStyles>()((theme: MarlinTheme, { fullCardMobile }) => ({
  grid: {
    paddingTop: theme.typography.pxToRem(8),
    paddingLeft: theme.typography.pxToRem(8),
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.typography.pxToRem(16),
    },
  },
  gap: {
    marginBottom: theme.typography.pxToRem(16),
    justifyContent: 'space-between',
  },
  secondGap: {
    justifyContent: 'space-between',
  },
  card: {
    height: '100%',
    justifyContent: 'space-around',
    padding: theme.typography.pxToRem(16),
    [theme.breakpoints.down('md')]: {
      width: `calc( ${fullCardMobile ? '100' : '90'}vw - ${theme.typography.pxToRem(32)} );`,
    },
  },
  rightSideOfCard: {
    textAlign: 'right',
  },
  title: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  noCriticalityContainer: {
    width: 'fit-content',
    float: 'right',
    padding: `${theme.typography.pxToRem(4)} ${theme.typography.pxToRem(8)}`,
    backgroundColor: theme.palette.background.alternative,
    borderRadius: theme.typography.pxToRem(4),
  },
  lowCriticalityContainer: {
    width: 'fit-content',
    float: 'right',
    padding: `${theme.typography.pxToRem(4)} ${theme.typography.pxToRem(8)}`,
    color: theme.palette.warning.main,
    backgroundColor: alpha(theme.palette.warning.main, 0.14),
    borderRadius: theme.typography.pxToRem(4),
  },
  highCriticalityContainer: {
    width: 'fit-content',
    float: 'right',
    padding: `${theme.typography.pxToRem(4)} ${theme.typography.pxToRem(8)}`,
    color: theme.palette.error.main,
    backgroundColor: alpha(theme.palette.error.main, 0.14),
    borderRadius: theme.typography.pxToRem(4),
  },
}));
