import { TExtractFnReturnType, queryClient, useQuery } from '@marlin/shared/utils/react-query';

import { getUserPortfolios } from '../infrastructure/get-portfolios';
import { queryKey } from './query-key.enum';

export const useUserPortfolios = (enabled = true) => {
  return useQuery<TExtractFnReturnType<typeof getUserPortfolios>>({
    queryKey: queryKey.USER_PORTFOLIOS(),
    queryFn: getUserPortfolios,
    enabled,
    staleTime: Infinity,
    cacheTime: Infinity,
    retryOnMount: false,
  });
};

export const invalidateUserPortfolios = () => {
  return queryClient.invalidateQueries({ queryKey: queryKey.USER_PORTFOLIOS() });
};
