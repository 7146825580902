import { PERMISSIONS, TPermission } from '@marlin/shared/utils-permission';

import { adminPermissions } from './admin.permission';

export const csmPermissions: TPermission[] = [
  ...adminPermissions.filter((permission) => permission !== PERMISSIONS.SHOW_SETTINGS_PROFILE_PAGE),
  PERMISSIONS.CREATE_ORGANIZATION,
  PERMISSIONS.SHOW_SUPPORT_PAGE,
  PERMISSIONS.SHOW_MONNIT_API_KEY_SETTINGS_PAGE,
  PERMISSIONS.SHOW_SUPPORT_PAGE_ANALYTICS,
  PERMISSIONS.INVITE_OTHER_SUPPORT_USERS,
];
