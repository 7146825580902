import { content } from './content';
import type { TStatusMap } from './types';

export const statusIdToKeyAdapter = (id: string): string | undefined =>
  ({
    '0': 'Disabled',
    '1': 'Standby',
    '2': 'Manual',
    '3': 'Remote',
    '4': 'Auto',
    '5': 'Fault',
  }[id]);

export const aercoEquipmentModeLabelMap: TStatusMap = new Map([
  [
    'Disabled',
    {
      mode: content.EQUIPMENT_MODE_LABELS_AERCO.DISABLED,
      equipmentLabel: content.EQUIPMENT_STATUS_LABELS.LOST_COMMUNICATION,
      iconTag: 'signal_wifi_off',
    },
  ],
  ['Standby', { mode: content.EQUIPMENT_MODE_LABELS_AERCO.STANDBY, iconTag: 'pending' }],
  ['Manual', { mode: content.EQUIPMENT_MODE_LABELS_AERCO.MANUAL, iconTag: 'pan_tool' }],
  ['Remote', { mode: content.EQUIPMENT_MODE_LABELS_AERCO.REMOTE, iconTag: 'directions_walk' }],
  ['Auto', { mode: content.EQUIPMENT_MODE_LABELS_AERCO.AUTO, iconTag: 'smart_toy' }],
  [
    'Fault',
    {
      mode: content.EQUIPMENT_MODE_LABELS_AERCO.FAULT,
      equipmentLabel: content.EQUIPMENT_STATUS_LABELS.FAULT,
      iconTag: 'warning_rounded',
    },
  ],
]);
