import { TEquipmentAlert } from '@marlin/asset/data-access/equipment';
import { MarlinTheme } from '@marlin/shared/theme';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { TControlState } from '@marlin/shared/utils/datapoint-mappers';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface IDatapointDisplayValue {
  value?: string | number;
  controlState?: TControlState;
  alerts?: TEquipmentAlert[];
  separator?: boolean;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  value: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: theme.typography.pxToRem(0.15),
    display: 'flex',
    alignItems: 'center',
  },
  active: {
    color: theme.palette.primary.main,
  },
  inactive: {
    color: theme.palette.text.secondary,
  },
  error: {
    color: theme.palette.error.main,
  },
  success: {
    color: theme.palette.success.main,
  },
  tooltipIcon: {
    width: theme.typography.pxToRem(16),
    height: theme.typography.pxToRem(16),
    marginLeft: theme.typography.pxToRem(4),
  },
  alertText: {
    whiteSpace: 'pre-line',
  },
}));

const numberWithCommas = (value: number | string) => {
  // first replace add thousand separatos, second remove decimal zeros
  return value
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    .replace(/(\.[0-9]*?)[0-9]+/g, '');
};

export const DatapointDisplayValue = ({ value, controlState, alerts, separator }: IDatapointDisplayValue) => {
  const { classes, cx } = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const alertText = Array.from(new Set(alerts?.map((alert) => alert.title))).join('\n');

  const localValue = separator && value ? numberWithCommas(value) : value;

  switch (controlState) {
    case 'active':
      return <span className={cx(classes.value, classes.active)}>{localValue}</span>;
    case 'inactive':
      return <span className={cx(classes.value, classes.inactive)}>{localValue}</span>;
    case 'success':
      return <span className={cx(classes.value, classes.success)}>{localValue}</span>;
    case 'error':
      return (
        <span className={cx(classes.value, classes.error)}>
          {localValue}
          {alerts?.length && alertText ? (
            <Tooltip text={<div className={classes.alertText}>{alertText}</div>} placement={isMobile ? 'top' : 'right'}>
              <ErrorRoundedIcon className={classes.tooltipIcon} />
            </Tooltip>
          ) : null}
        </span>
      );
    default:
      return <span className={classes.value}>{localValue}</span>;
  }
};
