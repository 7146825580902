import { SettingsPayload, TSettingPayload, TSettingsPayload } from '@marlin/shared/utils/datapoint-mappers';
import { getHttpClient, queryClient, useMutation } from '@marlin/shared/utils/react-query';
import { z } from 'zod';

import { paths } from '../infrastructure/api';
import { queryKey } from '../query-key.enum';

type TUpdateEquipmentSettings = {
  data: TSettingsPayload;
  gatewayId: string;
};

export const updateGatewaySettings = async ({ data, gatewayId }: TUpdateEquipmentSettings) => {
  const { datapoints } = SettingsPayload.parse({ ...data });

  return await getHttpClient().patch(paths.GATEWAY_SETTINGS, { datapoints }, { params: { gatewayId } });
};

export const useUpdateGatewaySettings = ({
  gatewayId,
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: () => void;
  gatewayId: string;
}) => {
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.SETTINGS(gatewayId) });

      onSuccess && onSuccess();
    },
    onError: (error: z.ZodError | unknown) => {
      // eslint-disable-next-line no-console
      if (error instanceof z.ZodError) console.log(error.issues);

      onError && onError();
    },
    mutationFn: async (modifiedSetting: TSettingPayload) => {
      await updateGatewaySettings({
        data: { datapoints: [modifiedSetting] },
        gatewayId,
      });
    },
  });
};
