import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { Typography } from '@mui/material';

import { useStyles } from './header.component.styles';
import { StatusIcon } from './status-icon.component';
import { useHeaderStyle } from './use-header-style.hook';
import { useHeader } from './use-header.hook';

const DeviceDrawerHeader = () => {
  const { classes } = useStyles();
  const { deviceName, batteryLevel, isActive } = useHeader();
  const { headerStyle, deviceNameTextStyle, isDesktopShortDeviceName } = useHeaderStyle({ deviceName });

  return (
    <div className={headerStyle}>
      <Tooltip
        disableTouchListener={true}
        disableHoverListener={isDesktopShortDeviceName}
        text={deviceName}
        placement="bottom"
      >
        <Typography className={deviceNameTextStyle} id="device-title" variant="h4">
          {deviceName}
        </Typography>
      </Tooltip>
      <div className={classes.infoWrapper}>
        <StatusIcon batteryLevel={batteryLevel} isActive={isActive} />
      </div>
    </div>
  );
};
export { DeviceDrawerHeader };
