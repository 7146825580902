import ChevronUpIcon from '@mui/icons-material/ExpandLess';
import ChevronDownIcon from '@mui/icons-material/ExpandMore';
import { Menu, MenuItem, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { useState } from 'react';

import { CompareToStartDate } from './compare-to-date-select.component';
import { useStyles } from './compare-to-select.styles';
import { compareToOptions, defaultCompareToOption } from './constants';
import { content } from './content';
import { COMPARE_TO_MODE, TCompareToValue } from './types';

interface ICompareToSelectProps {
  onChange: (value: TCompareToValue) => void;
  value?: TCompareToValue;
  organizationTimezone?: string;
  currentPeriodStart?: string;
  timezone?: string;
}

export const CompareToSelect = ({
  onChange,
  value = defaultCompareToOption,
  organizationTimezone,
  currentPeriodStart,
  timezone,
}: ICompareToSelectProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [customDate, setCustomDate] = useState(false);
  const { classes } = useStyles();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (value?: TCompareToValue) => {
    setAnchorEl(null);
    setCustomDate(false);
    value && onChange(value);
  };

  const handleCustomDateClick = () => {
    setCustomDate(true);
  };

  const handleBackClick = () => {
    setCustomDate(false);
  };

  return (
    <div>
      <ToggleButtonGroup value="modal" exclusive onChange={handleClick} color="primary">
        <ToggleButton className={classes.toggleButton} value="modal" size="small">
          <div className={classes.toggleCompareItem}>
            <Typography className={classes.buttonText} fontWeight={500}>
              {value.label}
            </Typography>
          </div>
          {anchorEl ? (
            <ChevronUpIcon color="primary" className={classes.buttonIcon} />
          ) : (
            <ChevronDownIcon color="primary" className={classes.buttonIcon} />
          )}
        </ToggleButton>
      </ToggleButtonGroup>

      <Menu
        id="period-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => handleClose()}
        slotProps={{ paper: { className: classes.menu } }}
      >
        {!customDate ? (
          <div>
            {compareToOptions.map((option) => (
              <MenuItem key={option.mode} onClick={() => handleClose(option)}>
                {option.label}
              </MenuItem>
            ))}
            <MenuItem onClick={handleCustomDateClick}>{content.COMPARE_TO.CUSTOM_START_DATE}</MenuItem>
          </div>
        ) : (
          <CompareToStartDate
            handleBack={handleBackClick}
            handleCancel={handleBackClick}
            organizationTimezone={organizationTimezone}
            timezone={timezone}
            handleSet={(date) => {
              handleClose({
                mode: COMPARE_TO_MODE.CUSTOM_START_DATE,
                label: content.COMPARE_TO.CUSTOM_START_DATE,
                date,
              });
            }}
            comparisonPeriodStart={value.mode === COMPARE_TO_MODE.CUSTOM_START_DATE ? value.date : undefined}
            currentPeriodStart={currentPeriodStart}
          />
        )}
      </Menu>
    </div>
  );
};
