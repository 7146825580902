import { FormField, Input } from '@marlin/shared/ui-form-common';

import { content } from './content';
import { TRenameTeeValues } from './rename-tee-form.schema';

export const RenameTeeForm = () => {
  return (
    <FormField<TRenameTeeValues> fieldName="name">
      {(props) => <Input label={content.RENAME_TEE_LABEL} placeholder={content.RENAME_TEE_LABEL} required {...props} />}
    </FormField>
  );
};
