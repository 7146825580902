import { MarlinTheme } from '@marlin/shared/theme';
import BatteryFullRoundedIcon from '@mui/icons-material/BatteryFullRounded';
import { makeStyles } from 'tss-react/mui';

import { highBatteryLevel, mediumBatteryLevel } from '../../consts';
import { content } from '../../content';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  cell: {
    display: 'flex',
    alignItems: 'center',
  },

  icon: {
    marginRight: theme.typography.pxToRem(6),
  },

  successIcon: {
    color: theme.palette.success.light,
  },

  warningIcon: {
    color: theme.palette.warning.main,
  },

  errorIcon: {
    color: theme.palette.error.main,
  },
}));

interface IBatteryProps {
  batteryLevel: number;
  isExtendedDrawer?: boolean;
}

export function Battery({ batteryLevel, isExtendedDrawer = false }: IBatteryProps) {
  const { classes } = useStyles();

  const getBatteryIconClass = (): string => {
    if (batteryLevel >= highBatteryLevel) {
      return classes.successIcon;
    }
    if (batteryLevel >= mediumBatteryLevel) {
      return classes.warningIcon;
    }

    return classes.errorIcon;
  };

  return (
    <div className={classes.cell}>
      <BatteryFullRoundedIcon className={`${classes.icon} ${getBatteryIconClass()}`} />
      {isExtendedDrawer
        ? content.EXTENDED_POWER_CELL_BATTERY_LEVEL(batteryLevel)
        : content.POWER_CELL_BATTERY_LEVEL(batteryLevel)}
    </div>
  );
}
