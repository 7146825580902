import { MarlinTheme } from '@marlin/shared/theme';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import { IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export interface IMobileFilterToggleButton {
  onToggle: () => void;
  isOpen: boolean;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  open: {
    color: theme.palette.primary.main,
  },

  closed: {
    color: theme.palette.action.active,
  },
}));

export function MobileFilterToggleButton({ onToggle, isOpen }: IMobileFilterToggleButton) {
  const { classes } = useStyles();

  return (
    <IconButton className={isOpen ? classes.open : classes.closed} disableRipple color="primary" onClick={onToggle}>
      <FilterAltRoundedIcon />
    </IconButton>
  );
}
