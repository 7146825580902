import { Loader } from '@googlemaps/js-api-loader';
import { environment } from '@marlin/environment';

import { AddressValidationService, initValidationService } from './address-validation/address-validation.service';

let autocompleteService: google.maps.places.AutocompleteService;
let placesService: google.maps.places.PlacesService;
let addressValidationService: AddressValidationService;

export const initMapsServices = () => {
  const loader = new Loader({
    apiKey: environment.module.googleMapsApiKey,
    version: 'weekly',
    libraries: ['places'],
    language: 'en',
  });
  loader.importLibrary('places').then((api) => {
    autocompleteService = new api.AutocompleteService();
    // note: we need to create empty node to initialize places service, as we don't use a map
    placesService = new api.PlacesService(document.createElement('div'));
  });
};

export const getAutocompleteService = () => {
  if (!autocompleteService) {
    initMapsServices();
  }
  return autocompleteService;
};

export const getPlacesService = () => {
  if (!placesService) {
    initMapsServices();
  }
  return placesService;
};

export const getAddressValidationService = () => {
  if (!addressValidationService) {
    addressValidationService = initValidationService();
  }
  return addressValidationService;
};
