import { useNavigate } from 'react-router-dom';

import { useNavigation } from './use-navigation.hook';

interface IState {
  [key: string]: unknown;
}

interface IUseStore {
  goTo: (url: string, state?: IState) => void;
  goToNewEquipmentPage: () => void;
}

export const useRouter = (): IUseStore => {
  const navigate = useNavigate();
  const { getNewEquipmentPageLink } = useNavigation();
  const goTo = (url: string, state?: IState): void => {
    navigate(url, { state });
  };

  return {
    goTo,
    goToNewEquipmentPage: () => {
      goTo(getNewEquipmentPageLink());
    },
  };
};
