import debounce from 'lodash/debounce';
import { useCallback, useMemo, useState } from 'react';

interface ISearchFilter {
  term: string;
  debouncedTerm: string;
  debouncedTrimmedTerm: string;
  search: (value: string) => void;
}

const defaultDebounceTimeInMs = 300;

export const useSearchFilter = (debounceTimeInMs?: number): ISearchFilter => {
  const [term, setTerm] = useState<string>('');
  const [debouncedTerm, setDebouncedTerm] = useState<string>('');

  const debouncedSearch = useMemo(
    () =>
      debounce(
        (value: string) => {
          setDebouncedTerm(value);
        },
        Number.isInteger(debounceTimeInMs) ? debounceTimeInMs : defaultDebounceTimeInMs
      ),
    [debounceTimeInMs]
  );

  const debouncedTrimmedTerm = useMemo(() => debouncedTerm.trim(), [debouncedTerm]);

  const search = useCallback(
    (value: string) => {
      setTerm(value);
      debouncedSearch(value);
    },
    [debouncedSearch]
  );

  const searchFilter: ISearchFilter = useMemo(
    (): ISearchFilter => ({
      term,
      debouncedTerm,
      debouncedTrimmedTerm,
      search,
    }),
    [term, debouncedTerm, debouncedTrimmedTerm, search]
  );

  return searchFilter;
};
