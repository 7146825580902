import { MarlinTheme } from '@marlin/shared/theme';
import { useTheme } from '@mui/material';
import { useMemo } from 'react';

import { getChartingColors } from '../utils/get-charting-colors';

export const useChartingColors = () => {
  const theme = useTheme<MarlinTheme>();
  return useMemo(() => getChartingColors(theme), [theme]);
};
