import { TAutomation } from '@marlin/alert/data-access/automated-action';
import {
  CHANNEL_TYPE,
  IChannelExtendedRecipient,
  IChannelRecipient,
  IEmailChannel,
  IExtendedAutomation,
  IExtendedChannels,
  IHotSosChannel,
  IInAppChannel,
  IRecipient,
  ISmsChannel,
} from '@marlin/alert/ui/automated-action-list';

import { content } from './content';

type TChannel = IHotSosChannel | IInAppChannel | ISmsChannel | IEmailChannel;

const getRecipients = (recipients: IChannelRecipient[], recipientList?: IRecipient[]): IChannelExtendedRecipient[] => {
  return recipients.map(({ recipientId }) => {
    const recipient = recipientList?.find((recipient) => recipient.id === recipientId);

    return {
      id: recipient?.id ?? recipientId,
      firstName: recipient?.firstName ?? content.RECIPIENTS_UNKNOWN,
      lastName: recipient?.lastName ?? content.RECIPIENTS_UNKNOWN,
      recipientType: recipient?.recipientType ?? 'Recipient',
    };
  });
};

const mapChannel = (
  type: CHANNEL_TYPE,
  channel?: TChannel,
  recipientList?: IRecipient[]
): IExtendedChannels | undefined => {
  switch (channel?.type) {
    case CHANNEL_TYPE.SMS:
    case CHANNEL_TYPE.EMAIL: {
      return {
        [channel.type]: {
          ...channel,
          recipients: getRecipients(channel?.recipients || [], recipientList),
        },
      };
    }

    case CHANNEL_TYPE.IN_APP:
    case CHANNEL_TYPE.HOT_SOS: {
      return {
        [channel.type]: {
          ...channel,
        },
      };
    }

    default: {
      return undefined;
    }
  }
};

export const mapExtendedAutomation = (automation: TAutomation, recipientList?: IRecipient[]): IExtendedAutomation => {
  return {
    ...automation,
    rootLocation: {
      id: automation.location?.parentLocationId ?? null,
      name: automation.location?.parentLocationName ?? null,
    },
    channels: {
      ...mapChannel(CHANNEL_TYPE.IN_APP, automation.channels[CHANNEL_TYPE.IN_APP]),
      ...mapChannel(CHANNEL_TYPE.HOT_SOS, automation.channels[CHANNEL_TYPE.HOT_SOS]),
      ...mapChannel(CHANNEL_TYPE.EMAIL, automation.channels[CHANNEL_TYPE.EMAIL], recipientList),
      ...mapChannel(CHANNEL_TYPE.SMS, automation.channels[CHANNEL_TYPE.SMS], recipientList),
    },
  };
};
