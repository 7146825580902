import { MarlinTheme } from '@marlin/shared/theme';
import { Button } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../content';

interface IToggleModalProps {
  value: boolean;
  checked: boolean;
  closeModal: () => void;
  submitChange: (checked: boolean) => Promise<void>;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  footer: {
    display: 'flex',
    gap: theme.typography.pxToRem(8),
  },
}));

export const ToggleModalBody = ({ value }: { value: boolean }) => {
  return (
    <>{value ? content.CONFIRMATION_MODAL_BODY_MESSAGE_DISABLE : content.CONFIRMATION_MODAL_BODY_MESSAGE_ENABLE}</>
  );
};

export const ToggleModalFooter = ({ value, closeModal, submitChange, checked }: IToggleModalProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.footer}>
      <Button onClick={closeModal} data-testid="cancel-toggling">
        {content.CANCEL}
      </Button>
      <Button variant="contained" onClick={() => submitChange(checked)} data-testid="submit-toggling">
        {value ? content.DISABLE : content.ENABLE}
      </Button>
    </div>
  );
};
