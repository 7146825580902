import { MarlinTheme } from '@marlin/shared/theme';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { useDesktopSideNavbarCollapse } from '@marlin/shared/utils-common-desktop-sidenav';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import { Button } from '@mui/material';
import { Icon } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';

interface IStyles {
  desktopNavbarLocked: boolean;
}

export const useStyles = makeStyles<IStyles>()((theme: MarlinTheme, { desktopNavbarLocked }) => ({
  container: {
    borderRadius: '50%',
    height: 48,
    width: 48,
    padding: 0,
    margin: 0,
    backgroundColor: theme.palette.background.primary,
    '&:hover': {
      backgroundColor: theme.palette.background.primary,
    },
    '& > svg': {
      fontSize: theme.typography.pxToRem(24),
      color: desktopNavbarLocked ? theme.palette.primary.main : theme.palette.alternative.main,
    },
    animation: 'fade-in 1s',
    animationDelay: '0.5s',
    minWidth: 'unset', //override MuiButtonBase width
  },
  icon: {
    color: desktopNavbarLocked ? theme.palette.primary.main : theme.palette.alternative.main,
  },
}));

export const LockNavbar = () => {
  const { handleLockDesktopNavbar, handleUnlockDesktopNavbar, desktopNavbarLocked } = useDesktopSideNavbarCollapse();
  const { classes } = useStyles({ desktopNavbarLocked });

  const handleLock = () => {
    if (desktopNavbarLocked) {
      handleUnlockDesktopNavbar();
    } else {
      handleLockDesktopNavbar();
    }
  };

  return (
    <Button data-testid="nav-lock-button" onClick={handleLock} variant="outlined" className={classes.container}>
      <Tooltip placement="right" text={desktopNavbarLocked ? content.COLLAPSE_NAV : content.LOCK_NAV}>
        {!desktopNavbarLocked ? (
          <Icon className={classes.icon} baseClassName="material-symbols-outlined">
            lock_open
          </Icon>
        ) : (
          <LockRoundedIcon />
        )}
      </Tooltip>
    </Button>
  );
};
