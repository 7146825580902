import { MarlinTheme } from '@marlin/shared/theme';
import { Dialog, Divider } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

import { MobileFilterFormProvider } from './mobile-filter-form-provider.component';
import { MobileFilterForm } from './mobile-filter-form.component';
import { MobileFilterHeader } from './mobile-filter-header.component';
import { IBaseFilters, IMobileFilterFormProps } from './model';

export { useMobileFilterFormContext } from './mobile-filter-form-provider.component';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    backgroundColor: theme.palette.background.secondary,
  },
}));

export interface IMobileFilterProps<TSort extends string, TFilters extends IBaseFilters<TSort>>
  extends IMobileFilterFormProps<TSort, TFilters>,
    PropsWithChildren {
  title?: ReactNode;
  chips?: ReactNode;
  rows?: ReactNode;
}

export function MobileFilterModal<TSort extends string, TFilters extends IBaseFilters<TSort>>({
  title,
  chips,
  children,
  rows,
  ...mobileFilterForm
}: IMobileFilterProps<TSort, TFilters>) {
  const { classes } = useStyles();
  if (!mobileFilterForm.open) {
    return null;
  }

  return (
    <MobileFilterFormProvider<TSort, TFilters> {...mobileFilterForm}>
      <Dialog open fullScreen>
        <MobileFilterHeader<TSort, TFilters>>{title}</MobileFilterHeader>
        <MobileFilterForm<TSort, TFilters>>{children}</MobileFilterForm>
        <Divider />
        <div className={classes.container}>
          {chips}
          {rows}
        </div>
      </Dialog>
    </MobileFilterFormProvider>
  );
}
