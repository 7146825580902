import {
  dateAdapter,
  millisecondsInDay,
  millisecondsInHour,
  millisecondsInYear,
} from '@marlin/shared/utils-common-date';

import { EXTENDED_RANGE_FILTER } from '../charts/collapsed-chart/chart-constants';
import { RANGE_FILTER } from '../constant';
import { IRange } from '../context/chart-range.context';

export const rangeFilterToRange = (rangeFilter: RANGE_FILTER | EXTENDED_RANGE_FILTER): IRange => {
  const now = dateAdapter.date()?.valueOf() ?? 0;

  switch (rangeFilter) {
    case 'DAYS_7': {
      return {
        from: now - millisecondsInDay * 7,
        to: now,
      };
    }
    case 'HOURS_24': {
      return {
        from: now - millisecondsInDay,
        to: now,
      };
    }
    case 'MONTHS_12': {
      return {
        from: now - millisecondsInYear,
        to: now,
      };
    }
    default: {
      return {
        from: now - millisecondsInHour * 8,
        to: now,
      };
    }
  }
};

export const rangeToRangeFilter = (range: IRange): RANGE_FILTER | EXTENDED_RANGE_FILTER => {
  const now = dateAdapter.date()?.valueOf() ?? 0;
  const diff = now - range.from;

  if (diff <= millisecondsInHour * 8) {
    return RANGE_FILTER.HOURS_8;
  }

  if (diff <= millisecondsInDay) {
    return RANGE_FILTER.HOURS_24;
  }

  if (diff <= millisecondsInDay * 7.5) {
    // 7.5 to have a margin for the last day
    return RANGE_FILTER.DAYS_7;
  }

  return EXTENDED_RANGE_FILTER.CUSTOM;
};
