export const content = {
  FARENHEIT_SYMBOL: '°F',
  CELSIUS_SYMBOL: '°C',
  PSI_SYMBOL: 'psi',
  KPA_SYMBOL: 'kPa',
  GPM_SYMBOL: 'gpm',
  LPM_SYMBOL: 'L/min',
  M3PH_SYMBOL: 'm³/h',
  BTU_SYMBOL: 'MBTU',
  KWH_SYMBOL: 'kWh',
  TH_SYMBOL: 'thm',
  GJ_SYMBOL: 'GJ',
  PERCENT_SYMBOL: '%',
  HOURS_SYMBOL: 'hours',
  SHORT_HOURS_SYMBOL: 'h',
  VOLTAGE_SYMBOL: 'V',
  GALLONS_SYMBOL: 'gal',
  LITERS_SYMBOL: 'L',
  METERS_SYMBOL: 'm³',
  FT3_SYMBOL: 'ft³',
  FT3PM_SYMBOL: 'ft³/min',
  MICRO_AMPERE: 'µA',
  SHORT_HOURS_VALUE: (value: number | string) => `${value} h`,
  HOURS_VALUE: (value: number | string) => `${value} hours`,
  PERCENT_VALUE: (value: number | string) => `${value}%`,
  FARENHEIT_VALUE: (value: number | string) => `${value}°F`,
  CELSIUS_VALUE: (value: number | string) => `${value}°C`,
  PSI_VALUE: (value: number | string) => `${value} psi`,
  KPA_VALUE: (value: number | string) => `${value} kPa`,
  GPM_VALUE: (value: number | string) => `${value} gpm`,
  LPM_VALUE: (value: number | string) => `${value} L/min`,
  M3PH_VALUE: (value: number | string) => `${value} m³/h`,
  MBTU_VALUE: (value: number | string) => `${value} MBTU`,
  KWH_VALUE: (value: number | string) => `${value} kWh`,
  TH_VALUE: (value: number | string) => `${value} thm`,
  GJ_VALUE: (value: number | string) => `${value} GJ`,
  VOLTAGE_VALUE: (value: number | string) => `${value} V`,
  GALLONS_VALUE: (value: number | string) => `${value} gal`,
  LITERS_VALUE: (value: number | string) => `${value} L`,
  METERS_VALUE: (value: number | string) => `${value} m³`,
  LEAK_VALUE: (value: number | string) =>
    value === 'True' || value === 'on' || parseInt(value.toString()) === 1 ? 'Leak' : 'No Leak',
  VALVE_POSITION_VALUE: (value: number | string) =>
    value === 'True' || value === 'on' || parseInt(value.toString()) === 1 ? 'Open' : 'Closed',
  FT3_VALUE: (value: number | string) => `${value} ft³`,
  FT3PM_VALUE: (value: number | string) => `${value} ft³/min`,
  MICRO_AMPERE_VALUE: (value: number | string) => `${value} µA`,
  EMPTY_DATAPOINT_VALUE: '--',
  PER_PULSE: '/pulse',
};
