import { useGraph } from '@marlin/system-map/data-access/system-map';
import { reactFlowAdapter } from '@marlin/system-map/utils/system-map-adapters';
import { useMemo } from 'react';

export const useSystemMapData = () => {
  const graphQuery = useGraph();

  const nodes = useMemo(() => reactFlowAdapter.nodesFromCyto(graphQuery.data?.nodes || []), [graphQuery.data?.nodes]);

  const edges = useMemo(() => reactFlowAdapter.edgesFromCyto(graphQuery.data?.links || []), [graphQuery.data?.links]);
  return {
    isGraphLoading: graphQuery.isLoading,
    nodes,
    edges,
  };
};
