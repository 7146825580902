import { MarlinTheme } from '@marlin/shared/theme';
import { FormField, IFormControlProps, IFormTypedProps } from '@marlin/shared/ui-form-common';
import EggRoundedIcon from '@mui/icons-material/EggRounded';
import PanoramaPhotosphereOutlinedIcon from '@mui/icons-material/PanoramaPhotosphereOutlined';
import SpeedRoundedIcon from '@mui/icons-material/SpeedRounded';
import ThermostatRoundedIcon from '@mui/icons-material/ThermostatRounded';
import { FormControl, SvgIconProps, alpha } from '@mui/material';
import { FC } from 'react';
import { ControllerProps, ControllerRenderProps, FieldError, FieldValues } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { ChipButton } from '../chips/chip-button.component';
import { content } from '../content';
import { TDeviceType } from './device-type.schema';

type TRenderProps = Partial<Omit<ControllerRenderProps, 'ref'>>;

interface IDeviceTypeOption {
  id: TDeviceType;
  value: TDeviceType;
  name: string;
  Icon: FC<SvgIconProps>;
}

interface ISelectChildren {
  children?: (props: {
    options: IDeviceTypeOption[];
    value: TRenderProps['value'];
    onDelete: (id: string) => void;
  }) => JSX.Element;
}

interface ISelectProps extends ISelectChildren {
  multiple?: boolean;
}

interface ISelectControlProps extends ISelectProps, TRenderProps {
  error?: FieldError;
  disabled?: boolean;
  defaultValue?: TDeviceType;
  required?: boolean;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  chips: {
    overflowX: 'auto',
    display: 'flex',
    marginLeft: theme.typography.pxToRem(-16),
    marginRight: theme.typography.pxToRem(-16),
    '-ms-overflow-style': 'none' /* hide scrollbar on Internet Explorer 10+ */,
    'scrollbar-width': 'none' /* hide scrollbar on Firefox */,
    '&::-webkit-scrollbar': {
      display: 'none' /* hide scrollbar on Safari and Chrome */,
    },
    '& > .MuiChip-root:first-child': {
      marginLeft: theme.typography.pxToRem(16),
    },
    '& > .MuiChip-root:not(:last-child)': {
      marginRight: theme.typography.pxToRem(8),
    },
  },

  activeChip: {
    backgroundColor: alpha(theme.palette.secondary.main, 0.08),
  },
}));

const deviceTypeOptions: IDeviceTypeOption[] = [
  {
    id: 'TEMPERATURE',
    value: 'TEMPERATURE',
    name: content.SENSOR_TYPE_TEMPERATURE_LABEL,
    Icon: ThermostatRoundedIcon,
  },
  {
    id: 'PRESSURE',
    value: 'PRESSURE',
    name: content.SENSOR_TYPE_PRESSURE_LABEL,
    Icon: SpeedRoundedIcon,
  },
  {
    id: 'LEAK',
    value: 'LEAK',
    name: content.SENSOR_TYPE_LEAK_LABEL,
    Icon: EggRoundedIcon,
  },
  {
    id: 'PULSE_METER',
    value: 'PULSE_METER',
    name: content.SENSOR_TYPE_FLOW_LABEL,
    Icon: PanoramaPhotosphereOutlinedIcon,
  },
];

const ChipsControl = ({
  error,
  disabled = false,
  required = false,
  multiple = false,
  children,
  ...rest
}: ISelectControlProps) => {
  const { classes } = useStyles();
  const values = (rest.value || []) as TDeviceType[];

  const isDeviceTypeActive = (value: TDeviceType) => values.includes(value);

  const addValue = (value: TDeviceType) => {
    const newDeviceTypes = [...values, value];
    rest.onChange && rest.onChange(newDeviceTypes);
  };

  const removeValue = (deviceTypeToRemove: TDeviceType) => {
    const newDeviceTypes = values.filter((deviceType) => deviceType !== deviceTypeToRemove);
    rest.onChange && rest.onChange(newDeviceTypes);
  };

  const changeValueFactory = (value: TDeviceType) => () => {
    if (isDeviceTypeActive(value)) {
      return removeValue(value);
    }

    return addValue(value);
  };

  return (
    <FormControl error={!!error} fullWidth={true} data-testid="device-type-chip-control">
      <div className={classes.chips}>
        {deviceTypeOptions.map(({ value, name }) => (
          <ChipButton label={name} onClick={changeValueFactory(value)} active={isDeviceTypeActive(value)} />
        ))}
      </div>
    </FormControl>
  );
};

export function DeviceTypeChipControl<TFieldValues extends FieldValues>(
  props: IFormControlProps<TFieldValues> & ISelectProps
): JSX.Element;
export function DeviceTypeChipControl<
  TFieldValues extends FieldValues = object,
  TName extends ControllerProps<TFieldValues>['name'] = ControllerProps<TFieldValues>['name']
>(props: IFormTypedProps<TFieldValues, TName> & ISelectProps): JSX.Element;

export function DeviceTypeChipControl<
  TFieldValues extends FieldValues,
  TName extends ControllerProps<TFieldValues>['name'] = ControllerProps<TFieldValues>['name']
>(params: (IFormControlProps<TFieldValues> | IFormTypedProps<TFieldValues, TName>) & ISelectProps) {
  if (params.control) {
    return (
      <FormField {...params}>
        {(props) => (
          <ChipsControl
            {...props}
            disabled={params?.disabled}
            defaultValue={params?.defaultValue}
            required={params?.required}
            multiple={params?.multiple}
            children={params.children}
          />
        )}
      </FormField>
    );
  }

  return (
    <FormField<TFieldValues> {...params}>
      {(props) => (
        <ChipsControl
          {...props}
          disabled={params?.disabled}
          required={params?.required}
          multiple={params?.multiple}
          children={params.children}
        />
      )}
    </FormField>
  );
}
