import { routes } from '@marlin/shared/utils-routes';

interface IFeatureNavigation {
  getNewLocationPageLink: () => string;
  getLocationDetailsPageLink: (id: string) => string;
}

export const useNavigation = (): IFeatureNavigation => {
  return {
    getNewLocationPageLink: () => routes.locations.create.path,
    getLocationDetailsPageLink: (id: string) => routes.locations.details.url(id),
  };
};
