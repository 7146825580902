import { TReading } from '@marlin/asset/data-access/telemetry';
import { TDateString, dateAdapter, formatDate as formatDateUtil } from '@marlin/shared/utils-common-date';
import { parseDisplayedValue } from '@marlin/shared/utils-format-reading';
import isNil from 'lodash/isNil';
import { useMemo } from 'react';

export type TFormatDateMode = 'durationString' | 'dateString';

export interface IFormattedReading {
  formattedValue: string;
  formattedDate: string;
}

export interface IUseTelemetryFormattedReadingOptions {
  formatDateMode: TFormatDateMode;
}

const formatDate = (date: TDateString): string => formatDateUtil(date, 'HH:mm');

const formatDurationString = (dateParam: TDateString): string => {
  if (!dateParam) {
    return '';
  }

  const date = dateAdapter.date(dateParam);
  const now = dateAdapter.date();

  const seconds = Math.round(now?.diff(date, 'seconds') ?? 0);
  const minutes = Math.round(seconds / 60);
  const hours = Math.round(minutes / 60);
  const days = Math.round(hours / 24);

  const readingString = 'Reading as of ';
  if (seconds < 5) {
    return readingString + 'now';
  } else if (seconds < 60) {
    return readingString + `${seconds} seconds ago`;
  } else if (minutes === 1) {
    return readingString + `${minutes} minute ago`;
  } else if (minutes < 60) {
    return readingString + `${minutes} minutes ago`;
  } else if (hours === 1) {
    return readingString + `${hours} hour ago`;
  } else if (hours < 24) {
    return readingString + `${hours} hours ago`;
  } else if (days === 1) {
    return readingString + `${days} day ago`;
  } else if (days) {
    return readingString + `${days} days ago`;
  }
  return '';
};

const dateFormatters: Record<TFormatDateMode, (date: TDateString) => string> = {
  durationString: formatDurationString,
  dateString: formatDate,
};

export const formatReadingsDate = (date: TDateString, mode: TFormatDateMode) => {
  const dateFormatter = dateFormatters[mode];
  return dateFormatter(date);
};

export const useTelemetryFormattedReading = (
  telemetryReading: TReading | null | undefined,
  options: IUseTelemetryFormattedReadingOptions
): IFormattedReading | null => {
  const uom = telemetryReading?.deviceType === 'LEAK' ? 'WaterDetect' : telemetryReading?.uoM;

  const deviceReading =
    telemetryReading?.formattedReading ??
    parseDisplayedValue(telemetryReading?.value?.toString() ?? '', uom ?? '', undefined, 'detail');
  const dateFormatter = useMemo(() => dateFormatters[options.formatDateMode], [options.formatDateMode]);

  return useMemo(() => {
    if (isNil(telemetryReading)) {
      return null;
    }

    return {
      formattedValue: deviceReading,
      formattedDate: dateFormatter(telemetryReading.eventDateTime),
    };
  }, [dateFormatter, deviceReading, telemetryReading]);
};
