import { IFilterParams, useAutomations } from '@marlin/alert/data-access/automated-action';
import { useMemo } from 'react';

interface IStore {
  totalItems?: number;
}

const defaultParams: IFilterParams = {
  deviceId: [],
  equipmentId: [],
  locationId: [],
  page: 1,
  pageSize: 1,
  isEnabled: null,
};

// TODO: temporary, after desktop is refactored (with backend), this hook should be removed
export const useAutomationsTotal = (equipmentId?: string): IStore => {
  const automationsQuery = useAutomations({
    params: { ...defaultParams, equipmentId: equipmentId ? [equipmentId] : [] },
    enabled: !!equipmentId,
  });

  const totalItems = useMemo(() => {
    if (!automationsQuery?.data) {
      return undefined;
    }

    return automationsQuery.data.pagination.totalItems;
  }, [automationsQuery]);

  return {
    totalItems,
  };
};
