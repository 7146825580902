import { ControlSwitcher, SettingsSectionSkeleton } from '@marlin/asset/ui/settings';
import { MarlinTheme } from '@marlin/shared/theme';
import { PERMISSIONS, usePermission } from '@marlin/shared/utils-permission';
import { TSettingsGroup } from '@marlin/shared/utils/datapoint-mappers';
import { useIdFromPathname } from '@marlin/shared/utils/url-params';
import { Typography } from '@mui/material';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useDatapointsContext } from '../../../../shared/context/datapoints.context';
import { useEditSettingContext } from '../../../../shared/context/edit-setting.context';
import { useEquipmentDetailsConfigContext } from '../../../../shared/context/equipment-details-config-context';
import { content } from '../../../content';
import { useEquipmentSettings } from '../../../hooks/settings/use-settings-datapoints.hook';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    width: '100%',
    marginBottom: theme.typography.pxToRem(16),
  },
  sectionTitle: {
    fontSize: theme.typography.pxToRem(14),
    letterSpacing: theme.typography.pxToRem(0.15),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
    marginBottom: theme.typography.pxToRem(22),
    // TODO: remove this padding when we add padding to section container
    padding: `0 ${theme.typography.pxToRem(16)}`,
  },
  displayedValue: {
    color: theme.palette.text.secondary,
  },
}));

export const ScheduleTemperatureSection = ({
  updateSettings,
  temperatureSettingGroup,
}: {
  updateSettings: (name: string, value: string, prevValue: string) => Promise<void>;
  temperatureSettingGroup: TSettingsGroup | null;
}) => {
  const { classes } = useStyles();
  const equipmentId = useIdFromPathname();

  const { config } = useEquipmentDetailsConfigContext();
  const isReadonly = useMemo(() => config.settings?.schedule?.tempGroupReadonly ?? false, [config.settings]);
  const datapointNames = useMemo(() => config.settings?.schedule?.scheduleDatapoints ?? [], [config.settings]);

  const { data: settings = [], isLoading } = useEquipmentSettings({
    equipmentId,
    expandedGroup: temperatureSettingGroup,
  });
  const editSettingContext = useEditSettingContext();
  const { getDatapoint } = useDatapointsContext();
  const overrideMode = getDatapoint('overrideMode');
  const isEditable = usePermission(PERMISSIONS.UPDATE_EQUIPMENT_SETTINGS);
  const isSanitize = overrideMode?.value === 'sanitize';

  const datapoints = useMemo(
    () => settings.filter((setting) => (datapointNames.length ? datapointNames.includes(setting.name) : true)),
    [settings, datapointNames]
  );

  if (!temperatureSettingGroup) {
    return null;
  }

  return (
    <div className={classes.container} data-testid="schedule-temperature-section">
      <Typography className={classes.sectionTitle}>{content.TEMPERATURE}</Typography>
      {isLoading ? (
        <SettingsSectionSkeleton />
      ) : (
        <ControlSwitcher
          datapoints={datapoints}
          updateSettings={updateSettings}
          disabled={isReadonly || isSanitize || !isEditable}
          disabledTooltipText={content.SETTINGS_DISABLED_TOOLTIP}
          displayDisabledTooltip={isSanitize && isEditable}
          {...editSettingContext}
        />
      )}
    </div>
  );
};
