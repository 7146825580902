import { TExtractFnReturnType, queryClient } from '@marlin/shared/utils/react-query';
import { Query } from '@marlin/shared/utils/react-query';
import { useMemo } from 'react';

import { getRangeTelemetry } from '../infrastructure/telemetry';
import { QUERY_KEY } from './query-key.enum';

interface IRangeQueryKey {
  id: string;
  to: string;
  from: string;
  excludeAlerts?: boolean;
}

const getSortedRangeCache = (): Query<TExtractFnReturnType<typeof getRangeTelemetry>>[] => {
  const rangeTelemetryCache: Query[] = queryClient
    .getQueryCache()
    .findAll({ queryKey: [QUERY_KEY.RANGE_TELEMETRY], exact: false });

  return rangeTelemetryCache.sort((a, b) => {
    const aQueryKey = a.queryKey[1] as IRangeQueryKey;
    const bQueryKey = b.queryKey[1] as IRangeQueryKey;
    return new Date(bQueryKey.from).getTime() - new Date(aQueryKey.from).getTime();
  }) as Query<TExtractFnReturnType<typeof getRangeTelemetry>>[];
};

export const useRangeFromCache = (manufacturerId: string, to: string, from: string) => {
  return useMemo(() => {
    const rangeTelemetryCache: Query<TExtractFnReturnType<typeof getRangeTelemetry>>[] = getSortedRangeCache();

    return rangeTelemetryCache.find((query) => {
      const queryKey = query.queryKey[1] as IRangeQueryKey;
      const chartData = query.state.data?.chartData;
      return (
        queryKey.id === manufacturerId &&
        query.state.data &&
        chartData &&
        chartData.length &&
        new Date(chartData[chartData.length - 1]?.eventDateTime ?? queryKey.to).getTime() >= new Date(to).getTime() &&
        new Date(chartData[0]?.eventDateTime ?? queryKey.from).getTime() <= new Date(from).getTime()
      );
    });
  }, [from, manufacturerId, to]);
};
