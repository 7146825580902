import { Box, Skeleton } from '@mui/material';
import { useCallback } from 'react';

import { skeletons, useStyles } from './home-dashboard-layout.styles';

interface IHomeDashboardLayoutProps {
  performance: JSX.Element | null;
  health: JSX.Element | null;
  systemMap?: JSX.Element;
  topOpenAlerts: JSX.Element;
  totalization?: JSX.Element;
  analytics?: JSX.Element;
  pinned?: JSX.Element;
  isMobile?: boolean;
  isLoading?: boolean;
}

export const HomeDashboardLayout = ({
  performance,
  health,
  systemMap,
  topOpenAlerts,
  totalization,
  analytics,
  pinned,
  isMobile,
  isLoading,
}: IHomeDashboardLayoutProps) => {
  const { classes, theme } = useStyles();

  const smallSkeleton = theme.typography.pxToRem(skeletons.defaultHeight);
  const bigSkeleton = theme.typography.pxToRem(2 * skeletons.defaultHeight + skeletons.rowGap);

  const withLoading = useCallback(
    (element: JSX.Element | null | undefined, height: string | number = smallSkeleton) => {
      return isLoading ? <Skeleton variant="rectangular" height={height} /> : element;
    },
    [isLoading, smallSkeleton]
  );

  return (
    <Box className={isMobile ? classes.mobileContainer : classes.container}>
      <Box className={classes.performance}> {withLoading(performance)} </Box>
      <Box className={classes.health}>{withLoading(health)} </Box>
      {systemMap && <Box className={classes.systemMap}>{systemMap}</Box>}
      <Box className={classes.topAlerts}>{withLoading(topOpenAlerts, bigSkeleton)}</Box>
      <Box className={classes.totalization}>{totalization}</Box>
      {pinned && <Box className={classes.pinned}>{pinned}</Box>}
      {analytics && <Box className={classes.analytics}>{analytics}</Box>}
    </Box>
  );
};
