import { MarlinTheme } from '@marlin/shared/theme';
import { useNavigation } from '@marlin/shared/ui-device-drawer-mobile';
import { PERMISSIONS, Restricted } from '@marlin/shared/utils-permission';
import { useRouter } from '@marlin/shared/utils-router';
import { Button, Skeleton } from '@mui/material';
import isNil from 'lodash/isNil';
import { useCallback, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';
import { useDeviceDetailsContext } from '../../context/device-details.context';
import { useExtendedDeviceDrawer } from '../container/context';
import { useManageAutomations } from './use-manage-automations.hook';
import { ViewerFallbackButton } from './viewer-falback.button';

interface IStyles {
  isExtended: boolean;
}

export const useStyles = makeStyles<IStyles>()((theme: MarlinTheme, { isExtended }) => ({
  manageAutomationsContainer: {
    width: !isExtended ? '100%' : 'unset',
    margin: isExtended ? theme.typography.pxToRem(8) : theme.typography.pxToRem(16),
  },
}));

export function ManageAutomations() {
  const isExtended = useExtendedDeviceDrawer();
  const { classes } = useStyles({ isExtended });
  const router = useRouter();
  const { id, name } = useDeviceDetailsContext();
  const { getListPageQueryLink, getCreatePageQueryLink } = useNavigation();
  const { totalItems } = useManageAutomations(id);

  const buttonContent = useMemo(() => {
    if (totalItems === undefined) {
      return '';
    }
    if (totalItems === 0) {
      return content.MANAGE_AUTOMATIONS_BUTTON_CREATE;
    }
    return content.MANAGE_AUTOMATIONS_BUTTON_MANAGE;
  }, [totalItems]);

  const handleClick = useCallback(() => {
    if (!isNil(totalItems)) {
      if (totalItems === 0) {
        window.open(getCreatePageQueryLink(id), '_blank');
      } else {
        router.goTo(getListPageQueryLink(id), { state: { clearFilters: true, devices: [{ id, name }] } });
      }
    }
  }, [getCreatePageQueryLink, getListPageQueryLink, id, name, router, totalItems]);

  if (isNil(totalItems)) {
    return (
      <div className={classes.manageAutomationsContainer} data-testid="drawer-manage-automations-loader">
        <Skeleton variant="rectangular" width="100%" height={36} />
      </div>
    );
  }

  return (
    <div className={classes.manageAutomationsContainer} data-testid="drawer-manage-automations">
      <Restricted
        to={PERMISSIONS.DEVICE_DRAWER_MANAGE_AUTOMATION}
        fallback={<ViewerFallbackButton totalItems={totalItems} onClick={handleClick} />}
      >
        <Button fullWidth variant="outlined" onClick={handleClick} data-testid="drawer-manage-automations-button">
          {buttonContent}
        </Button>
      </Restricted>
    </div>
  );
}
