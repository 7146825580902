import { TDateString } from '@marlin/shared/utils-common-date';
import { TUnitOfMeasure } from '@marlin/shared/utils-format-reading';
import { ISorting } from '@marlin/shared/utils-sorting';
import { CRITICALITY } from '@marlin/shared/utils/zod';

import { ALERT_CAUSE, CRITICALITY_RESPONSE, TRuleCategory } from './infrastructure/alert.model';

export enum CHANNEL_TYPE {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  HOT_SOS = 'HOT_SOS',
}

export interface ICriticality {
  low: boolean;
  high: boolean;
}

export enum ERROR_TYPES {
  LOWER = 'LOWER',
  HIGHER = 'HIGHER',
  UNKNOWN = 'UNKNOWN',
  LEAK_DETECTION = 'LEAK_DETECTION',
  LOST_COMMUNICATION = 'LOST_COMMUNICATION',
  LOW_BATTERY = 'LOW_BATTERY',
  DEVICE_ALERT = 'DEVICE_ALERT',
  DEVICE_ERROR = 'DEVICE_ERROR',
}

export enum ALERT_STATUS_FILTER_REQUEST {
  CURRENT = 'Current',
  SNOOZED = 'Snoozed',
  RESOLVED = 'Resolved',
  ALL = 'All',
}

export enum ALERT_STATUS_FILTER {
  CURRENT = 'CURRENT',
  SNOOZED = 'SNOOZED',
  RESOLVED = 'RESOLVED',
  ALL = 'ALL',
}

export interface IPagination {
  totalItems: number;
  page: number;
  pageSize: number;
}

export interface IAlert {
  id: string;
  status: ALERT_STATUS_FILTER;
  device: {
    id: string;
    name: string;
    type: DEVICE_TYPE;
    isDeleted?: boolean;
  };
  location: {
    id: string;
    name: string;
    isDeleted?: boolean;
  } | null;
  equipment: {
    id: string;
    name: string;
    isDeleted?: boolean;
  };
  automation: {
    id: string;
    isSystemRule: boolean;
    name: string;
  };
  startTime: TDateString;
  resolveTime: TDateString;
  lastReading: TDateString;
  ruleCategory: TRuleCategory;
  readingValue: number;
  thresholdMin: number;
  thresholdMax: number;
  criticality: CRITICALITY;
  errorType: ERROR_TYPES;
  absoluteDifferenceValue: number | null;
  channels: IChannels;
  snoozeUntil: TDateString;
  snoozeIntervalInMinutes: number;
  description: string | null;
  title: string | null;
  hyperlink: string | null;
  deviceEventCode: string | null;
  additionalValues: Partial<{
    settingName: string;
    newSettingValue: string;
    settingUom: TUnitOfMeasure;
    prevSettingValue: string;
    firstName: string;
    lastName: string;
    userId: string;
  }>;
  formattedReading: string | null;
  uoM: TUnitOfMeasure | null;
}

export interface IChannels {
  [CHANNEL_TYPE.SMS]?: ISmsChannel;
  [CHANNEL_TYPE.EMAIL]?: IEmailChannel;
  [CHANNEL_TYPE.HOT_SOS]?: IHotSosChannel;
}

interface IHotSosChannel {
  type: CHANNEL_TYPE.HOT_SOS;
  errorMessage: string;
  status: ALERT_STATUS | null;
}

interface IEmailChannel extends IChannel {
  type: CHANNEL_TYPE.EMAIL;
}

interface ISmsChannel extends IChannel {
  type: CHANNEL_TYPE.SMS;
}

export enum ALERT_STATUS {
  FAILED = 'FAILED',
  SENT = 'SENT',
  SNOOZED = 'SNOOZED',
  IN_PROGRESS = 'IN_PROGRESS',
}

export interface IRecipient {
  id: string;
  status: ALERT_STATUS | null;
  error: string;
}

interface IChannel {
  recipientIds: IRecipient[];
}

export interface IPagedAlerts {
  data: IAlert[];
  pagination: IPagination;
  totalItems: {
    current: number;
    resolved: number;
    all: number;
    snoozed: number;
  };
}

export interface IFilterParams {
  status: ALERT_STATUS_FILTER;
  locationIds: string[];
  equipmentIds: string[];
  deviceIds: string[];
  deviceTypes: string[];
  search?: string;
  criticality?: ICriticality;
  ruleCategories?: TRuleCategory[];
  eventCodes?: string[];
  sorting?: ISorting;
}

export interface IPagedParams extends IFilterParams {
  pageSize: number;
  page: number;
}

export enum DEVICE_TYPE {
  TEMPERATURE = 'TEMPERATURE',
  PRESSURE = 'PRESSURE',
  LEAK = 'LEAK',
  PULSE_METER = 'PULSE_METER',
  GATEWAY = 'GATEWAY',
  UNKNOWN = 'UNKNOWN',
  EQUIPMENT = 'EQUIPMENT',
}

export type TCriticality = 'LOW' | 'HIGH';

export interface IAlertNotification {
  organizationId: string;
  deviceId: string;
  equipmentId: string;
  deviceName: string;
  deviceType: `${DEVICE_TYPE}` | 'GATEWAY';
  criticality: TCriticality | null;
  message?: string;
  ruleId: string;
  ruleName: string;
  thresholdMin: number | null;
  thresholdMax: number | null;
  cause: ERROR_TYPES;
  uoM: TUnitOfMeasure | null;
}

export interface IAlertNotificationResponse {
  organizationId: string;
  deviceId: string;
  equipmentId: string;
  deviceName: string;
  deviceType: 'Temperature' | 'Pressure' | 'Leak' | 'FlowMeter' | 'Unknown' | 'PulseMeter' | 'Gateway';
  criticality: CRITICALITY_RESPONSE;
  message?: string;
  ruleId: string;
  ruleName: string;
  thresholdMin: number | null;
  thresholdMax: number | null;
  cause: ALERT_CAUSE;
  uoM: TUnitOfMeasure | null;
}
