import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

interface IStyle {
  isExtended?: boolean;
  desktopNavbarLocked: boolean;
}

export const useStyles = makeStyles<IStyle>()((theme: MarlinTheme, { isExtended = false, desktopNavbarLocked }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: isExtended
      ? desktopNavbarLocked
        ? `calc(100vw - ${theme.typography.pxToRem(256)})`
        : `calc(100vw - ${theme.typography.pxToRem(80)})`
      : theme.typography.pxToRem(524),
    minHeight: !isExtended ? '100vh' : 'unset',
    height: isExtended ? '100vh' : 'unset',
    top: 0,
    right: 0,
    zIndex: 1300, // note: needs to be over system map full screen z-index: 1299
    left: 'auto',
    borderRadius: 0,
    [theme.breakpoints.down('md')]: {
      width: '100vw',
    },
    transition: 'width 0.5s ease-in-out',
  },
  pullTab: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  paperShadow: {
    boxShadow: theme.shadows[12],
  },
}));
