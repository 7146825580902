import { TCalibrationParamsSchema } from '@marlin/asset/data-access/device';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

const twoHoursInMs = 2 * 60 * 60 * 1000;

export const notEnoughTime = (startDate: string, endDate: string) => {
  return Math.abs(new Date(endDate).getTime() - new Date(startDate).getTime()) < twoHoursInMs;
};

export const useNotEnoughTime = () => {
  const [timeWarning, setTimeWarning] = useState(false);
  const { watch } = useFormContext<TCalibrationParamsSchema>();

  const startDate = watch('startMeasurementTimestamp');
  const endDate = watch('endMeasurementTimestamp');

  useEffect(() => {
    if (startDate && endDate && notEnoughTime(startDate, endDate)) {
      setTimeWarning(true);
    } else {
      setTimeWarning(false);
    }
  }, [endDate, startDate]);

  return timeWarning;
};
