import { MarlinTheme } from '@marlin/shared/theme';
import { SxProps, Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const createFullScreenStyles = (isFullScreen: boolean) => {
  return {
    display: !isFullScreen ? 'none' : undefined,
    zIndex: 1299, // it should be lower than material Dialog component zIndex: 1300
  };
};

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  floatingMenu: {
    position: 'absolute',
    bottom: theme.typography.pxToRem(24),
    right: theme.typography.pxToRem(24),
    backgroundColor: theme.palette.background.primary,
  },
}));

export const useSx = (): SxProps<Theme> => {
  const theme = useTheme<MarlinTheme>();

  return {
    '.MuiDialog-container': {
      padding: theme.typography.pxToRem(24),
      backgroundColor: theme.palette.background.secondary,
    },

    '.MuiDialog-paper': {
      borderRadius: theme.typography.pxToRem(4),
      border: `${theme.typography.pxToRem(1)} solid #E0E0E0`, // TODO use color from palette
      boxShadow: 'none',
    },
  };
};
