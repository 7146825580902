import { TExtractFnReturnType, useQuery } from '@marlin/shared/utils/react-query';

import { getAssetLinks } from '../infrastructure/asset-links';
import { queryKey } from './query-key.enum';

type TUseAssetLinksOptions = {
  assetId: string;
  enabled: boolean;
};

export const useAssetLinks = ({ assetId, enabled }: TUseAssetLinksOptions) => {
  return useQuery<TExtractFnReturnType<typeof getAssetLinks>>({
    queryKey: queryKey.ASSET_LINKS(assetId),
    queryFn: () => getAssetLinks({ assetId }),
    enabled,
  });
};
