import { useMemo } from 'react';

import { content } from '../content';

interface IHealthStatusLabelProps {
  checkErrors?: boolean;
  checkCommunication?: boolean;
  performSystemMaintenance?: boolean;
}

export const HealthStatusLabel = ({
  checkErrors,
  checkCommunication,
  performSystemMaintenance,
}: IHealthStatusLabelProps) => {
  return useMemo(() => {
    if (checkErrors && checkCommunication) {
      return content.HEALTH_STATUS.CHECK_COMMUNICATION_AND_ERRORS;
    } else if (checkErrors) {
      return content.HEALTH_STATUS.CHECK_ERRORS;
    } else if (checkCommunication) {
      return content.HEALTH_STATUS.CHECK_COMMUNICATION;
    } else if (performSystemMaintenance) {
      return content.HEALTH_STATUS.PERFORM_SYSTEM_MAINTENANCE;
    }
    return content.HEALTH_STATUS.ALL_SENSORS_OPERATIONAL;
  }, [checkCommunication, checkErrors, performSystemMaintenance]);
};
