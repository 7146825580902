import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import { TUpdateOrgAddress, updateOrgAddressSchema } from '../schemas/org';
import { organizationStorageService } from '../storage/organization-storage.service';
import { paths } from './api';

export const updateOrganizationDetails = async (data: TUpdateOrgAddress): Promise<unknown> => {
  const body = safeParseData(data, updateOrgAddressSchema);
  const organizationId = organizationStorageService.get('localStorage');

  return await getHttpClient().put(paths.ORGANIZATION_DETAILS, body, { params: { organizationId } });
};
