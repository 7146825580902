import {
  TLocationDetails,
  TSublocationAssetSchema,
  TSublocationParamsSchema,
  useAssetsInSublocationWithLoadMore,
} from '@marlin/asset/data-access/location';
import { TAssetType, TDeviceType } from '@marlin/system-map/shared/data-access-schemas';
import { useCallback, useMemo, useState } from 'react';

import { useLocationDetails } from '../desktop/use-location-details.hook';
import { TSetMobileLocationDetailsFilters, TSublocationsObjectList } from './types';

interface IUseLocationDetails {
  location?: TLocationDetails;
  isLoading: boolean;
  isListLoading: boolean;
  rows: TSublocationAssetSchema[];
  deviceDrawerRows: TSublocationAssetSchema[];
  refetch: () => void;
  setMobileFilters: TSetMobileLocationDetailsFilters;
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  totalItems?: number;
}

export const useMobileLocationDetails = (
  externalFilters?: Omit<TSublocationParamsSchema, 'page' | 'pageSize'>,
  refetchOnMount?: boolean
): IUseLocationDetails => {
  const { location, isLoading } = useLocationDetails();

  const [requestParams, setRequestParams] = useState<Omit<TSublocationParamsSchema, 'page' | 'pageSize'>>({});

  const reqParams = useMemo(
    () => ({
      locationId: location?.id || '',
      enabled: !!location?.id,
      refetchOnMount,
      params: {
        ...(externalFilters ? externalFilters : requestParams),
        pageSize: 10,
      },
    }),
    [location?.id, requestParams, externalFilters, refetchOnMount]
  );

  const { data, refetch, hasNextPage, fetchNextPage, isFetching, isFetchingNextPage } =
    useAssetsInSublocationWithLoadMore(reqParams);

  const setMobileFilters = useCallback(
    (locations: TSublocationsObjectList[], deviceTypes: TDeviceType[], assetTypes: TAssetType[]) => {
      setRequestParams(() => ({
        subLocationIds: locations?.map((location) => location.id),
        deviceTypes,
        types: deviceTypes && deviceTypes?.length > 0 ? ['DEVICE', ...assetTypes] : assetTypes,
      }));
    },
    []
  );

  const rows: TSublocationAssetSchema[] = useMemo(() => {
    const unfilteredRows: (TSublocationAssetSchema | null)[] = data?.pages?.flatMap((i) => i.data) || [];
    return unfilteredRows.filter((i: TSublocationAssetSchema | null): i is TSublocationAssetSchema => i !== null);
  }, [data?.pages]);

  const totalItems = data?.pages[0].pagination.totalItems || 0;

  const deviceDrawerRows = useMemo(() => rows.filter((row) => row.type !== 'EQUIPMENT'), [rows]);

  return {
    location,
    isLoading,
    isListLoading: isFetching && !isFetchingNextPage,
    setMobileFilters,
    refetch,
    hasNextPage,
    fetchNextPage,
    rows,
    totalItems,
    deviceDrawerRows,
  };
};
