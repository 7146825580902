import { useQuery } from '@marlin/shared/utils/react-query';

import { getOrganizationSettings } from '../infrastructure/organization-settings';
import { queryKey } from './query-key.enum';

export const useGetOrgSettings = () => {
  return useQuery({
    queryKey: queryKey.ORGANIZATION_SETTINGS(),
    queryFn: getOrganizationSettings,
  });
};
