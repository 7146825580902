import { useSearchParamsRepository } from '@marlin/shared/utils-router';
import { useCallback, useMemo } from 'react';

import { useSensorId } from '../../hooks/use-sensor-id.hook';

interface IDevice {
  id: string;
}

export interface IControlsProps {
  rows: IDevice[];
}

export interface IControls {
  shouldRender: boolean;
  goToPreviousDevice: () => void;
  goToNextDevice: () => void;
  isGoToPreviousDeviceDisabled: boolean;
  isGoToNextDeviceDisabled: boolean;
}

interface IDeviceDrawersParams {
  sensorId?: string;
}

export const useControls = ({ rows }: IControlsProps): IControls => {
  const deviceId = useSensorId();
  const rowIndex = useMemo(() => rows.findIndex((row) => row.id === deviceId), [deviceId, rows]);
  const { setSearchParam } = useSearchParamsRepository<IDeviceDrawersParams>();

  const goToPreviousDevice = useCallback(() => {
    if (rowIndex > 0) {
      setSearchParam('sensorId', rows[rowIndex - 1].id);
    }
  }, [rowIndex, rows, setSearchParam]);

  const goToNextDevice = useCallback(() => {
    if (rowIndex < rows.length) {
      setSearchParam('sensorId', rows[rowIndex + 1].id);
    }
  }, [setSearchParam, rowIndex, rows]);

  const isGoToPreviousDeviceDisabled = useMemo(() => rowIndex <= 0, [rowIndex]);
  const isGoToNextDeviceDisabled = useMemo(() => rowIndex >= rows.length - 1, [rowIndex, rows.length]);

  return {
    shouldRender: !!deviceId && rowIndex > -1,
    goToPreviousDevice: goToPreviousDevice,
    goToNextDevice: goToNextDevice,
    isGoToPreviousDeviceDisabled,
    isGoToNextDeviceDisabled,
  };
};
