import { useCallback, useMemo } from 'react';
import { NavigateOptions } from 'react-router';
import { useLocation, useSearchParams as useRouterSearchParams } from 'react-router-dom';

export const useSearchParams = () => {
  const [, setSearchParamsRouter] = useRouterSearchParams();
  // TODO refactor (improvement): using searchParams.get('settingGroupId') makes troubles and two sections are expanded at the same time
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const setSearchParams = useCallback(
    (callback: (searchParams: URLSearchParams) => URLSearchParams, navigateOpts?: NavigateOptions) => {
      setSearchParamsRouter(
        (prev) => {
          return callback(prev);
        },
        { replace: true, ...navigateOpts }
      );
    },
    [setSearchParamsRouter]
  );

  return [searchParams, setSearchParams] as const;
};

export const useSearchParamsRepository = <TParamsType>() => {
  const [searchParams, setSearchParams] = useSearchParams();

  const searchParamsObject: TParamsType = useMemo(() => {
    return Object.fromEntries(searchParams.entries()) as TParamsType;
  }, [searchParams]);

  const getSearchParam = useCallback(
    <K extends keyof TParamsType>(key: K) => searchParamsObject[key],
    [searchParamsObject]
  );

  const setSearchParam = useCallback(
    <K extends keyof TParamsType>(key: K, value: TParamsType[K] & string) =>
      setSearchParams((prev) => {
        prev.set(key as string, value);

        return prev;
      }),
    [setSearchParams]
  );

  const removeSearchParam = useCallback(
    <K extends keyof TParamsType>(key: K) =>
      setSearchParams((prev) => {
        prev.delete(key as string);
        return prev;
      }),
    [setSearchParams]
  );

  const clearSearchParams = useCallback(() => setSearchParams(() => new URLSearchParams()), [setSearchParams]);

  return useMemo(
    () => ({ getSearchParam, setSearchParam, removeSearchParam, setSearchParams, clearSearchParams }),
    [getSearchParam, removeSearchParam, setSearchParam, setSearchParams, clearSearchParams]
  );
};
