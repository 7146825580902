import { TVolumeSchema } from '@marlin/asset/data-access/home-dashboard';
import { parseDeviceReadings } from '@marlin/shared/utils-format-reading';
import { Typography } from '@mui/material';
import isNil from 'lodash/isNil';

import { ArrowIndicator } from './arrow-indicator/arrow-indicator.component';
import { content } from './content';
import { useStyles } from './totalization-card.styles';
import { transformBigNumber } from './utils';

interface ITrendProps {
  variant: 'WEEK' | 'MONTH';
  volume?: TVolumeSchema | null;
}

export const Trend = ({ variant, volume }: ITrendProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.trendWrapper} data-testid={`${variant.toLowerCase()}-total-section`}>
      <div>
        <Typography className={classes.trendsText} data-testid={`${variant.toLowerCase()}-trends-text`}>
          {variant === 'MONTH' ? content.MONTH_RUNNING_TREND : content.WEEK_RUNNING_TREND}
        </Typography>
      </div>

      <div className={classes.icon}>
        <div>
          <Typography className={classes.trendsValueText} data-testid={`${variant.toLowerCase()}-trends-value-text`}>
            {!isNil(volume?.trend) &&
              content.VALUE_TREND(
                transformBigNumber(parseDeviceReadings(volume?.trend, 'PULSE_METER', 'integer').value ?? 0)
              )}
          </Typography>
        </div>
        <ArrowIndicator value={volume?.trend} />
      </div>
    </div>
  );
};
