import { TChartType, TDatapointTypeValues, TGroupNames } from '../types';

export const mapChartTypeToGroupName = (type: TChartType): TGroupNames | undefined => {
  const typeToGroupMap = new Map<TChartType, TGroupNames | undefined>([
    ['temperature', 'temperature'],
    ['pressure', 'pressure'],
    ['onOff', 'pumpvalve'],
    ['flow', 'flow'],
    ['leak', 'leak'],
    ['custom', undefined],
  ]);

  return typeToGroupMap.get(type);
};

export const mapGroupNameToDatapointType = (groupName: TGroupNames | undefined): TDatapointTypeValues | undefined => {
  if (!groupName) return undefined;

  const groupToTypeMap = new Map<TGroupNames, TDatapointTypeValues>([
    ['temperature', 'temperature'],
    ['pressure', 'pressure'],
    ['pumpvalve', 'onOff'],
    ['flow', 'flow'],
    ['leak', 'leak'],
  ]);

  return groupToTypeMap.get(groupName);
};
