import { DEVICE_TYPE, useAutomationHistory } from '@marlin/alert/data-access/automated-action';
import { MarlinTheme } from '@marlin/shared/theme';
import { useAnnotationTooltip } from '@marlin/shared/ui/annotation-tooltip';
import { timestampToIsoString } from '@marlin/shared/utils-common-date';
import { TUnitOfMeasure } from '@marlin/shared/utils-format-reading';
import { useTheme } from '@mui/material';
import { useMemo } from 'react';

import { useSensorId } from '../../../../../hooks/use-sensor-id.hook';
import { TChartAutomation } from '../../../../manage-automations/use-manage-automations.hook';
import { ICharTicks } from '../../../types';
import { IAnnotationTooltip } from '../annotations/types';
import { IAnnotationForm } from '../extended-chart-form.component';
import { IRange } from '../hooks/use-main-chart.hook';
import { defaultAutomationOptions } from './const';
import { IAutomationOptions, IAutomationSeries, IPointAnnotation, TRulesSeriesHistory } from './types';
import {
  createSeriesForGivenRule,
  reduceAutomationSeriesTypesToOptionsFactory,
  reduceAutomationSeriesTypesToPointAnnotationFactory,
  sortSeriesByType,
} from './utils';

export interface IUseEnabledAutomationRulesHistory {
  automationRulesSeries: IAutomationSeries[];
  automationRulesSeriesOptions: IAutomationOptions;
  automationRulesSeriesAnnotation: IPointAnnotation[];
  currentAnnotationTooltip: IAnnotationTooltip | null;
}

export const useEnabledAutomationRulesHistory = (
  automationsSettings: IAnnotationForm,
  range: IRange | null,
  ticks: ICharTicks,
  automations: TChartAutomation[],
  uoM?: TUnitOfMeasure | null
): IUseEnabledAutomationRulesHistory => {
  const theme = useTheme();
  const deviceId = useSensorId();
  const automationsHistoryQuery = useAutomationHistory({
    deviceId,
    from: range?.from ? timestampToIsoString(range?.from) : '',
    to: range?.to ? timestampToIsoString(range?.to) : '',
    enabled: !!range?.from && !!range?.to,
    keepPreviousData: true,
  });

  const { currentAnnotationTooltip, onAnnotationLabelEnter, onAnnotationLabelLeave } =
    useAnnotationTooltip<IAnnotationTooltip>();

  const enabledAutomationsRulesHistory: TRulesSeriesHistory = useMemo(() => {
    if (!automationsHistoryQuery.isLoading && automationsHistoryQuery.data) {
      const reduceToEnabledAutomation = (acc: TRulesSeriesHistory, automationId: string): TRulesSeriesHistory => {
        const currentAutomationHistory = automationsHistoryQuery.data[automationId];
        const automation = automations.find(({ id }) => id === automationId);

        if (currentAutomationHistory && automation && automation.device) {
          return {
            ...acc,
            [automationId]: {
              ...currentAutomationHistory,
              criticality: automation.criticality,
              label: automation.label,
              automationName: automation.name,
              deviceType: automation.device.type ?? DEVICE_TYPE.UNKNOWN,
              id: automationId,
            },
          };
        }

        return acc;
      };

      return automationsSettings.automations.reduce(reduceToEnabledAutomation, {});
    }

    return {};
  }, [automations, automationsHistoryQuery.data, automationsHistoryQuery.isLoading, automationsSettings.automations]);

  const automationRulesSeries = useMemo(() => {
    const enabledAutomationsRulesHistoryKeys = Object.keys(enabledAutomationsRulesHistory);

    if (!enabledAutomationsRulesHistoryKeys.length) {
      return [];
    }

    return Object.values(enabledAutomationsRulesHistory)
      .map((ruleHistory) => createSeriesForGivenRule(ruleHistory, ticks, automationsSettings, range))
      .flat()
      .sort(sortSeriesByType);
  }, [automationsSettings, enabledAutomationsRulesHistory, range, ticks]);

  const automationRulesSeriesOptions = useMemo(() => {
    const optionsReducer = reduceAutomationSeriesTypesToOptionsFactory(theme as unknown as MarlinTheme);

    return automationRulesSeries.reduce(optionsReducer, defaultAutomationOptions);
  }, [automationRulesSeries, theme]);

  const automationRulesSeriesAnnotation: IPointAnnotation[] = useMemo(() => {
    const annotationReducer = reduceAutomationSeriesTypesToPointAnnotationFactory(
      theme as unknown as MarlinTheme,
      range,
      onAnnotationLabelEnter,
      onAnnotationLabelLeave,
      uoM
    );

    return automationRulesSeries.reduce(annotationReducer, []);
  }, [automationRulesSeries, onAnnotationLabelEnter, onAnnotationLabelLeave, range, theme, uoM]);

  return useMemo(
    () => ({
      automationRulesSeries,
      automationRulesSeriesOptions,
      automationRulesSeriesAnnotation,
      currentAnnotationTooltip,
    }),
    [automationRulesSeries, automationRulesSeriesOptions, automationRulesSeriesAnnotation, currentAnnotationTooltip]
  );
};
