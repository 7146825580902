export const content = {
  CREATE_ORG: 'Create Organization',
  CREATE_PORTFOLIO: 'Create Portfolio',
  CREATE_ORG_PLACEHOLDER: 'Organization name',
  CREATE_BTN: 'Create',
  CREATE_ORG_SUCCESS: 'Organization has been created',
  CREATE_ORG_ERROR: 'Error during organization creation',
  VALIDATION_ERROR_MESSAGE: 'Must be between 3 and 80 characters',
  ORG_NAME_ALREADY_EXISTS: 'Unable to create an Organization with that name',
};
