import { UnitOfMeasureType } from '@marlin/shared/utils-format-reading';
import { z } from 'zod';

export const Datapoint = z.object({
  name: z.string(),
  value: z.string(),
  unitOfMeasure: UnitOfMeasureType,
  lastReadingTime: z.string(),
  status: z.string().optional().nullable(),
});
export type TDatapoint = z.infer<typeof Datapoint>;

export const DatapointsApiResponse = z.object({
  manufacturerId: z.string(),
  lastReadingTime: z.string(),
  datapoints: z.array(Datapoint),
});

// TODO: Try use this transform inside signalR pipe
// .transform((data) => ({
//   manufacturerId: data.manufacturerId,
//   lastReadingTime: data.lastReadingTime.slice(-1) === 'Z' ? data.lastReadingTime : `${data.lastReadingTime}Z`,
//   datapoints: data.datapoints,
// }));

export const DatapointsResponse = z.object({
  manufacturerId: z.string(),
  lastReadingTime: z.string(),
  datapoints: z.array(Datapoint),
});

export type TDatapointsResponse = z.infer<typeof DatapointsResponse>;
export type TDatapointsAPIResponse = z.infer<typeof DatapointsApiResponse>;

const controlStateList = ['active', 'inactive', 'error', 'success', 'outdated'] as const;
export const ControlState = z.enum(controlStateList);
export type TControlState = z.infer<typeof ControlState>;

const controlTypeList = ['option', 'value', 'boolean', 'status', 'notSpecified', 'occupancy'] as const;
export const ControlType = z.enum(controlTypeList);
export type TControlType = z.infer<typeof ControlType>;

export const DatapointWithMetadata = z.object({
  name: z.string(),
  value: z.string(),
  unitOfMeasure: UnitOfMeasureType,
  controlState: ControlState,
  controlType: ControlType,
  lastReadingTime: z.string(),
});
export type TDatapointWithMetadata = z.infer<typeof DatapointWithMetadata>;

export const DatapointWithUiMetadata = DatapointWithMetadata.extend({
  label: z.string(),
  displayedValue: z.string(),
});
export type TDatapointWithUiMetadata = z.infer<typeof DatapointWithUiMetadata>;
