import { Input } from '@marlin/shared/ui-form-common';
import { PropsWithChildren } from 'react';

import { useContent } from '../../../context';
import { FormGroupHeader } from '../../shared/form-group-header.component';
import { SectionHeader } from '../../shared/section-header.component';

interface ISystemRuleTemplateProps extends PropsWithChildren {
  ruleName: string;
  isInformationType?: boolean;
  isEquipment?: boolean;
}

export function SystemRuleTemplate({
  children,
  ruleName,
  isInformationType,
  isEquipment,
}: ISystemRuleTemplateProps): JSX.Element {
  const content = useContent();

  return (
    <>
      <FormGroupHeader title={content.TRIGGER.TITLE} tooltip={content.TRIGGER.TOOLTIP} prefix="action" />
      <SectionHeader title={isEquipment ? content.SENSOR.EQUIPMENT_TITLE : content.SENSOR.TITLE} prefix="device" />
      <Input value={ruleName} disabled />
      <SectionHeader
        title={content.CONDITIONS.TITLE}
        prefix="conditions"
        tooltip={isInformationType ? content.CONDITIONS.INFORMATION_TOOLTIP : content.CONDITIONS.TOOLTIP}
        required
      />
      {children}
    </>
  );
}
