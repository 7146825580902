import { queryClient } from '@marlin/shared/utils/react-query';

import { queryKey } from '../query-key.enum';
import { IListFilters, IRecipientList } from '../recipient.model';

export const updatePreviousRecipientListCache = (previousData: IRecipientList | undefined, params?: IListFilters) => {
  if (!previousData) {
    return;
  }

  queryClient.setQueryData<IRecipientList | undefined>(queryKey.RECIPIENTS_FILTER(params), (data?: IRecipientList) => {
    if (!data) {
      return data;
    }

    return previousData;
  });
};
