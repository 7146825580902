import { getHttpClient, queryClient } from '@marlin/shared/utils/react-query';
import { useMutation } from '@marlin/shared/utils/react-query';
import { TEquipment } from '@marlin/shared/utils/zod';
import pickBy from 'lodash/pickBy';
import { z } from 'zod';

import { paths } from '../infrastructure/api';
import { queryKey } from './query-key.enum';

type TUpdateEquipment = {
  data: TEquipment;
  equipmentId: string;
};

export const updateEquipment = ({ data, equipmentId }: TUpdateEquipment): Promise<TEquipment> => {
  return getHttpClient().put(
    paths.EQUIPMENT,
    pickBy(data, (value) => Boolean(value)),
    { params: { equipmentId } }
  );
};

export const useUpdateEquipment = () => {
  return useMutation<TEquipment, unknown, TUpdateEquipment>({
    mutationFn: updateEquipment,
    onError: (error) => {
      // eslint-disable-next-line no-console
      if (error instanceof z.ZodError) console.log(error.issues);
    },
    onSuccess: (_, { equipmentId }) => {
      queryClient.invalidateQueries({ queryKey: queryKey.EQUIPMENT(equipmentId) });
      queryClient.invalidateQueries(queryKey.EQUIPMENT_DEVICES(equipmentId));
      queryClient.invalidateQueries(queryKey.GRAPH());
    },
  });
};
