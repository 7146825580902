import { queryClient, useMutation } from '@marlin/shared/utils/react-query';
import { AxiosError } from 'axios';

import { updateWebhook } from '../infrastructure/update-webhook';
import { TUpdateWebhookParams } from '../schemas/update-webhook.schema';
import { TWebhook } from '../schemas/webhook.schema';
import { queryKey } from './query-key.enum';

export const useUpdateWebhook = () => {
  return useMutation<unknown, AxiosError, TUpdateWebhookParams, TWebhook>({
    mutationFn: updateWebhook,

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.WEBHOOK() });
      queryClient.invalidateQueries({ queryKey: queryKey.FILTERED_WEBHOOKS() });
    },
  });
};
