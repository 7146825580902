import { createDateString, isBefore } from '@marlin/shared/utils-common-date';
import { z } from 'zod';

import { content } from './content';

export const CustomPeriodSchema = z
  .object({
    from: z.string(),
    to: z.string(),
  })
  .superRefine(({ from, to }, ctx) => {
    if (!isBefore(createDateString(from), createDateString(to))) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: content.FROM_DATE_ERROR,
        path: ['from'],
      });
    }
  });

export type TCustomPeriodSchema = z.infer<typeof CustomPeriodSchema>;
