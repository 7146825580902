import { routes } from '@marlin/shared/utils-routes';

export interface IFeatureNavigation {
  getLocationDetailsPageLink: (id: string) => string;
  getNewLocationPageLink: () => string;
  getEquipmentDetailsPageLink: (id: string) => string;
  getEquipmentHubPageLink: () => string;
  getNewEquipmentPageLink: () => string;
  getDeviceEditPageLink: (id: string) => string;
  getLocationEditPageLink: (id: string) => string;
  getEditEquipmentPageLink: (id: string) => string;
  getNewDevicePageLink: () => string;
  getDeviceLink: () => string;
  getEquipmentLink: () => string;
  getLocationsLink: () => string;
  getLocationsHubPageLink: () => string;
  getDeviceDrawerOnPageLink: (id: string) => string;
  getNewAutomationsPageLink: () => string;
}

export const useNavigation = (): IFeatureNavigation => {
  return {
    getDeviceEditPageLink: (id) => routes.sensors.update.url(id),
    getNewDevicePageLink: () => routes.sensors.create.globalPath,
    getDeviceDrawerOnPageLink: (id) => routes.sensors.details.url(id),
    getLocationDetailsPageLink: (id) => routes.locations.details.url(id),
    getNewLocationPageLink: () => routes.locations.create.url(),
    getEquipmentDetailsPageLink: (id) => routes.equipments.details.url(id),
    getEquipmentHubPageLink: () => routes.equipments.root,
    getNewEquipmentPageLink: () => routes.equipments.create.url(),
    getEditEquipmentPageLink: (id: string) => routes.equipments.update.url(id),
    getLocationEditPageLink: (id) => routes.locations.update.url(id),
    getLocationsHubPageLink: () => routes.locations.root,
    getDeviceLink: () => routes.sensors.root,
    getEquipmentLink: () => routes.equipments.root,
    getLocationsLink: () => routes.locations.root,
    getNewAutomationsPageLink: () => routes.automations.create.url(),
  };
};
