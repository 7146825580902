import { Button } from '@mui/material';

import { content } from '../../../content';
import { useStyles } from './change-system-tag.styles';

interface ISummaryModalFooterProps {
  onCancel: () => void;
  onConfirm: () => void;
}

export const ChangeSystemTagFooter = ({ onCancel, onConfirm }: ISummaryModalFooterProps) => {
  const { classes } = useStyles();
  return (
    <div className={classes.buttonWrapper}>
      <Button variant="text" onClick={onCancel}>
        {content.SYSTEM_SOURCE_TAG.CANCEL_BUTTON_LABEL}
      </Button>
      <Button variant="contained" color="primary" onClick={onConfirm}>
        {content.SYSTEM_SOURCE_TAG.YES_BUTTON_LABEL}
      </Button>
    </div>
  );
};
