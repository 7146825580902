import { TConnectionResult, TExecuteOperationResponse, TOperationResults } from '@marlin/asset/data-access/gateway';
import { MarlinTheme } from '@marlin/shared/theme';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';
import { JsonViewer } from '../json-viewer.component';

interface ITestResultProps {
  operationResults: TExecuteOperationResponse[];
}

const mapConnectionResults = (operationResults: TExecuteOperationResponse[]) => {
  return operationResults.reduce<TConnectionResult>((acc, result) => {
    result.operations.forEach((operation) => {
      let connection = acc.find((conn) => conn.connectionId === operation.connectionId);

      if (!connection) {
        connection = {
          connectionId: operation.connectionId,
          addresses: [],
        };
        acc.push(connection);
      }

      let address = connection.addresses.find((addr) => addr.addressId === operation.addressId);

      if (!address) {
        address = {
          addressId: operation.addressId,
          clientId: operation.clientId,
          operations: [],
        };
        connection.addresses.push(address);
      }

      address.operations.push({
        code: operation.opCode,
        errors: operation.errors,
        telemetry: operation.telemetry,
        timestamp: operation.timestamp,
      });
    });

    return acc;
  }, []);
};
export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  copyButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.typography.pxToRem(8),
  },
}));

export const TestResult = ({ operationResults }: ITestResultProps) => {
  const { classes } = useStyles();
  const [testResultsCombined, setTestResultCombined] = useState<TOperationResults>({} as TOperationResults);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (operationResults.length === 0) return;

    setTestResultCombined({
      gatewayId: operationResults[0].gatewayId,
      configurationId: operationResults[0].configurationId,
      connections: mapConnectionResults(operationResults),
    });
  }, [operationResults]);

  let parsedValue: string;
  try {
    parsedValue = JSON.stringify(testResultsCombined, null, 2);
  } catch {
    parsedValue = 'Invalid JSON';
  }

  const onCopyClick = async () => {
    await navigator.clipboard.writeText(parsedValue).then(() => {
      enqueueSnackbar(content.TEST_CONFIGURATION.COPY_SUCCESS, { variant: 'success' });
    });
  };

  return (
    <div>
      <JsonViewer value={parsedValue} label={content.TEST_CONFIGURATION.TEST_RESULTS} height={256} />
      <div className={classes.copyButtonWrapper}>
        <Button variant="outlined" onClick={onCopyClick}>
          {content.TEST_CONFIGURATION.COPY}
        </Button>
      </div>
    </div>
  );
};
