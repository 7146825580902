import { z } from 'zod';

import { content } from '../../content';

export const feedbackCategorySchema = z.union([
  z.literal('Sensor Issue'),
  z.literal('Software Issue'),
  z.literal('Sales Question'),
  z.literal('New Feature Request'),
  z.literal('Other Question'),
]);

const HelpFormDataSchema = z.object({
  name: z.string(),
  category: feedbackCategorySchema,
  description: z.string().min(1, content.REQUIRED),
});

type TFormData = z.infer<typeof HelpFormDataSchema>;
type TCategory = z.infer<typeof feedbackCategorySchema>;

export { HelpFormDataSchema, TFormData, TCategory };
