export const content = {
  VIEW: 'View',
  SYSTEM_MAP: 'System Map',
  ANALYTICS: 'Analytics',
  SYSTEM_MAP_INFO: 'View your real-time plumbing system schematic',
  SYSTEM_MAP_EMPTY: 'Add a sensor to set up your System Map',
  ANALYTICS_INFO: 'Dig into your data',
  ANALYTICS_EMPTY: 'Add a sensor to dig into your data',
  TIME_IN_ALERT: 'Time in alert:',
};
