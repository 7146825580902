import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { useDeleteLink } from '@marlin/system-map/data-access/system-map';
import { Edge, Node, ReactFlowInstance, useReactFlow } from '@xyflow/react';
import { useCallback } from 'react';

import { content } from './content';

const minTeeEdgesCount = 1;
export const getConnectedTees = (edge: Edge | undefined, reactFlow: ReactFlowInstance): (Node | undefined)[] => {
  if (!edge) {
    return [];
  }
  const connectedNodes = [reactFlow.getNode(edge.source), reactFlow.getNode(edge.target)];

  return connectedNodes.filter((node: Node | undefined) => {
    if (!node) {
      return false;
    }
    return node.data.nodeType === 'TEE';
  });
};

export const canDeleteLink = (edgeToDeleteId: string, reactFlow: ReactFlowInstance): boolean => {
  const connectedTees = getConnectedTees(reactFlow.getEdge(edgeToDeleteId), reactFlow);

  if (connectedTees.length !== 1) {
    return true;
  }

  const edges = reactFlow.getEdges();
  return connectedTees.every((tee: Node | undefined) => {
    if (!tee) {
      return false;
    }
    const incomingEdges = edges.filter((edge) => edge.target === tee.data.id);
    const outgoingEdges = edges.filter((edge) => edge.source === tee.data.id);
    const isIncomingEdge = !!incomingEdges.find((incomingEdge) => {
      return incomingEdge.id === edgeToDeleteId;
    });

    if (isIncomingEdge) {
      return incomingEdges.length > minTeeEdgesCount;
    }

    return outgoingEdges.length > minTeeEdgesCount;
  });
};

export const useDeleteLinkContextMenu = () => {
  const deleteLinkMutation = useDeleteLink();
  const { enqueueSnackbar } = useSnackbar();
  const reactFlow = useReactFlow();

  const deleteLink = useCallback(
    async (id: string) => {
      const canDelete = canDeleteLink(id, reactFlow);

      if (!canDelete) {
        enqueueSnackbar(content.DELETE_LINK_VALIDATION_MESSAGE, {
          variant: 'error',
          preventDuplicate: true,
        });

        return;
      }

      try {
        await deleteLinkMutation.mutateAsync({ flowLinkId: id });
        enqueueSnackbar(content.DELETE_LINK_SUCCESS_MESSAGE, {
          variant: 'success',
          preventDuplicate: true,
        });
      } catch (e) {
        enqueueSnackbar(content.DELETE_LINK_ERROR_MESSAGE, {
          variant: 'error',
          preventDuplicate: true,
        });
      }
    },
    [deleteLinkMutation, enqueueSnackbar, reactFlow]
  );

  return {
    deleteLink,
  };
};
