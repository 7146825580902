import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  tableHead: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.background.alternative,
    height: theme.typography.pxToRem(32),
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.systemMap.device}`,
    borderRadius: `${theme.typography.pxToRem(4)} ${theme.typography.pxToRem(4)} 0 0`,
    borderBottom: 'none',
  },

  tableTitle: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: theme.typography.pxToRem(24),
  },

  textWrapper: {
    padding: theme.typography.pxToRem(16),
  },

  row: {
    borderBottom: `${theme.typography.pxToRem(1)} solid ${theme.palette.systemMap.device}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },

  cell: {
    minHeight: theme.typography.pxToRem(36),
    paddingTop: theme.typography.pxToRem(8),
    paddingBottom: theme.typography.pxToRem(8),
    paddingLeft: theme.typography.pxToRem(16),
    paddingRight: theme.typography.pxToRem(16),
    width: theme.typography.pxToRem(512),
    borderBottom: 'none',
  },

  bodyWrapper: {
    maxHeight: theme.typography.pxToRem(198),
    overflow: 'scroll',
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.systemMap.device}`,
    borderRadius: `0 0 ${theme.typography.pxToRem(4)} ${theme.typography.pxToRem(4)}`,
  },
}));

export const useRowStyles = makeStyles()((theme: MarlinTheme) => ({
  assetLinkWrapper: {
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.typography.pxToRem(8),
    flexWrap: 'nowrap',
  },
}));
