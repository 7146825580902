import { TCommand } from '@marlin/asset/data-access/gateway';
import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import { useCallback, useContext } from 'react';

import { Footer } from '../../components/settings/bradley-partition/footer.component';
import { content } from '../../content';

export const useResetModal = (params: TCommand) => {
  const { modalDispatch } = useContext(ModalContext);

  const handleCloseModal = useCallback(() => {
    modalDispatch({
      type: MODAL_ACTION_TYPE.DISMISS,
    });
  }, [modalDispatch]);

  const modalReset = () => ({
    title: <div>{content.RESET_MODAL.TITLE}</div>,
    body: (
      <div>
        <div>{content.RESET_MODAL.BODY_TEXT}</div>
        <div>
          <b>{content.RESET_MODAL.BODY_CONFIRMATION}</b>
        </div>
      </div>
    ),
    footer: <Footer handleCloseModal={handleCloseModal} params={params} />,
  });

  const openResetModal = () => {
    modalDispatch({
      type: MODAL_ACTION_TYPE.SHOW,
      payload: modalReset(),
    });
  };

  return { openResetModal };
};
