import { MarlinTheme } from '@marlin/shared/theme';
import { TDatapointWithMetadata } from '@marlin/shared/utils/datapoint-mappers';
import { TGatewayInfoUpdateResponsePayload } from '@marlin/shared/utils/zod';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, alpha, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';
import { useGatewayDetailsSectionData } from '../../hooks/use-details-data.hook';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(12),
    [theme.breakpoints.down('md')]: {
      gap: 0,
    },
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.typography.pxToRem(24),
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
      gap: 0,
    },
  },
  value: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: theme.typography.pxToRem(24),
    letterSpacing: theme.typography.pxToRem(0.15),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('md')]: {
      textAlign: 'left',
    },
  },
  valueDescription: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(16),
  },
  row: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
      padding: theme.typography.pxToRem(16),
      border: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
      borderWidth: `0 0 ${theme.typography.pxToRem(1)}`,
    },
  },
  rowWrapper: {
    display: 'grid',
    width: '100%',
    rowGap: theme.typography.pxToRem(12),
  },
  rowDescription: {
    order: 999,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
      gap: theme.typography.pxToRem(8),
    },
  },
  label: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.typography.pxToRem(8),
    color: alpha(theme.palette.text.primary, 0.8),
  },
  labelDescription: {
    alignItems: 'flex-start',
  },
  description: {
    display: '-webkit-inline-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    lineClamp: '2',
    whiteSpace: 'unset',
    overflowWrap: 'anywhere',
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(22),
      display: 'block',
    },
  },
  accordion: {
    '&.Mui-expanded': {
      margin: 0,
    },
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
  },
  accordionSummary: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(16),
    },
    [theme.breakpoints.up('md')]: {
      '&.Mui-expanded:hover': {
        cursor: 'default',
      },
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  mobilePaper: {
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
}));

interface IGatewayDetailsSection {
  datapoints: TDatapointWithMetadata[];
  gateway: TGatewayInfoUpdateResponsePayload;
}

export const GatewayDetailsSection = ({ datapoints, gateway }: IGatewayDetailsSection) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { classes, cx } = useStyles();
  const rows = useGatewayDetailsSectionData({ datapoints, gateway });

  return (
    <Accordion
      className={cx(classes.accordion)}
      data-testid="equipment-details-section"
      defaultExpanded
      expanded={!isMobile ? true : undefined}
      elevation={0}
    >
      <AccordionSummary className={classes.accordionSummary} expandIcon={isMobile ? <ExpandMoreIcon /> : undefined}>
        {content.GATEWAY_DETAILS}
      </AccordionSummary>
      <AccordionDetails className={cx(classes.paper, classes.mobilePaper, classes.details)}>
        <div className={classes.rowWrapper}>
          {rows
            .filter((row) => row.column === 1)
            .map((row, index) => (
              <div className={cx(classes.row, classes.rowDescription)}>
                <div className={classes.label}>{row.label}</div>
                <div className={cx(classes.value)} id={`${index}`}>
                  {row.value || content.EMPTY_DATAPOINT_VALUE}
                </div>
              </div>
            ))}
        </div>
        <div className={classes.rowWrapper}>
          {rows
            .filter((row) => row.column === 2)
            .map((row, index) => (
              <div className={cx(classes.row, classes.rowDescription)}>
                <div className={cx(classes.label, classes.labelDescription)}>{row.label}</div>
                <div className={cx(classes.valueDescription)} id={`${index}`}>
                  {row.value || content.EMPTY_DATAPOINT_VALUE}
                </div>
              </div>
            ))}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
