import { useCallback } from 'react';

import { StepHeader } from '../../../components/step-header';
import { content } from '../../../content';
import { ONBOARDING_STEP } from '../steps';
import { completedSteps } from './const';
import { InvitationForm } from './forms/invitation-form.component';
import { useStyles } from './invite-users.styles';

interface IInviteUsersProps {
  onNextStep: (step: ONBOARDING_STEP, completeSteps?: ONBOARDING_STEP[]) => void;
}

export const InviteUsers = ({ onNextStep }: IInviteUsersProps) => {
  const { classes } = useStyles();

  const handleSuccess = useCallback(() => {
    onNextStep(ONBOARDING_STEP.INVITATIONS_SENT, completedSteps);
  }, [onNextStep]);

  const handleCancel = useCallback(() => {
    onNextStep(ONBOARDING_STEP.INVITATIONS_COMPLETED, completedSteps);
  }, [onNextStep]);

  const handleError = useCallback(() => {
    onNextStep(ONBOARDING_STEP.INVITATION_FAILED_ERROR, completedSteps);
  }, [onNextStep]);

  return (
    <div className={classes.container}>
      <StepHeader title={content.INVITE_USER_TITLE} subtitle={content.INVITE_USER_SUBTITLE} />
      <InvitationForm handleSuccess={handleSuccess} handleCancel={handleCancel} handleError={handleError} />
    </div>
  );
};
