import { Button } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { content } from './content';
import { TRenameTeeValues } from './rename-tee-form.schema';
import { useStyles } from './rename-tee-modal.component.styles';

export interface IRenameTeeButtons {
  onCancel: () => void;
  onSubmit: (values: TRenameTeeValues) => void;
}

export const RenameTeeButtons = ({ onCancel, onSubmit }: IRenameTeeButtons) => {
  const { classes } = useStyles();
  const { handleSubmit, formState } = useFormContext<TRenameTeeValues>();

  return (
    <>
      <Button variant="text" onClick={onCancel} type="button">
        {content.RENAME_TEE_CANCEL_BUTTON}
      </Button>
      <Button
        variant="contained"
        className={classes.submitButton}
        disabled={!formState.isDirty || !formState.isValid}
        onClick={handleSubmit(onSubmit)}
      >
        {content.RENAME_TEE_UPDATE_BUTTON}
      </Button>
    </>
  );
};
