import { createContext, useContext } from '@marlin/shared/utils-component-context';
import { useState } from 'react';

type TEditedSetting = { updatedValue: string | undefined; currentValue: string };

interface IEditSettingContext {
  editedSetting: TEditedSetting | undefined;
  setEditedSetting: (option: TEditedSetting | undefined) => void;
  isDirty: boolean;
  setIsDirty: (isDirty: boolean) => void;
}

export const EditSettingContext = createContext<IEditSettingContext>();

export const EditSettingContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [editedSetting, setEditedSetting] = useState<TEditedSetting | undefined>(undefined);
  const [isDirty, setIsDirty] = useState(false);

  return (
    <EditSettingContext.Provider
      value={{
        editedSetting,
        setEditedSetting,
        isDirty,
        setIsDirty,
      }}
    >
      {children}
    </EditSettingContext.Provider>
  );
};

export const useEditSettingContext = () => useContext(EditSettingContext);
