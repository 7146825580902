import { MarlinTheme } from '@marlin/shared/theme';
import { Divider, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    padding: theme.typography.pxToRem(8),
  },

  text: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: theme.typography.fontWeightLight,
    fontSize: 12,
    lineHeight: '266%',
    textTransform: 'uppercase',
  },
}));

interface ISeparatorProps {
  text: string;
}

export const Separator = ({ text }: ISeparatorProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <Typography className={classes.text} variant="body2">
        {text.toUpperCase()}
      </Typography>
      <Divider />
    </div>
  );
};
