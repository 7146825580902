import { useRecipients } from '@marlin/account-data-access-recipient';
import { CHANNEL_TYPE } from '@marlin/alert/data-access/automated-action';
import { Channel, ExtendedChannel } from '@marlin/alert/ui/automated-action-create';
import { RepeatingAlertLimit } from '@marlin/alert/ui/automated-action-create';
import { MarlinTheme } from '@marlin/shared/theme';
import { Paper } from '@marlin/shared/ui-page';
import noop from 'lodash/noop';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  channelContainer: {
    width: theme.typography.pxToRem(400),
    padding: theme.typography.pxToRem(8),
  },
}));

export const InAppChannel = () => {
  const { classes } = useStyles();
  return (
    <Paper className={classes.channelContainer}>
      <Channel type={CHANNEL_TYPE.IN_APP} id="automation-inapp-checkbox" />
    </Paper>
  );
};

export const EmailChannel = () => {
  const recipientsQuery = useRecipients();
  const { classes } = useStyles();
  return (
    <Paper className={classes.channelContainer}>
      <ExtendedChannel
        type={CHANNEL_TYPE.EMAIL}
        recipients={recipientsQuery?.data ?? []}
        onAddRecipientButtonClick={noop}
        checkboxId="automation-email-checkbox"
        selectId="automation-email-recipients"
      />
    </Paper>
  );
};

export const SmsChannel = () => {
  const recipientsQuery = useRecipients();
  const { classes } = useStyles();
  return (
    <Paper className={classes.channelContainer}>
      <ExtendedChannel
        type={CHANNEL_TYPE.SMS}
        recipients={recipientsQuery?.data ?? []}
        onAddRecipientButtonClick={noop}
        checkboxId="automation-sms-checkbox"
        selectId="automation-sms-recipients"
      />
    </Paper>
  );
};

export const AlertLimit = () => {
  return (
    <Paper>
      <RepeatingAlertLimit />
    </Paper>
  );
};
