import { queryClient, useMutation } from '@marlin/shared/utils/react-query';

import { TPortfolio, TPortfolioListResponse } from '../schema/portfolio.schema';
import { updatePortfolioId } from '../storage/portfolio.utils';
import { queryKey } from './query-key.enum';

const snackbarDelay = 1500;

interface IUpdatePortfolioProps {
  shouldRefetch: boolean;
  onSuccess?: (name?: string) => void;
}

export const useUpdatePortfolio = ({ shouldRefetch = true, onSuccess }: IUpdatePortfolioProps) => {
  return useMutation({
    mutationFn: async (portfolioId: string) => await updatePortfolioId(portfolioId), // updatePortfolioId is sync
    onSuccess: (currentPortfolio: TPortfolio | null) => {
      const portfolios: TPortfolioListResponse | undefined = queryClient.getQueryData(queryKey.USER_PORTFOLIOS());
      const currentData = portfolios?.portfolios.find((portfolio) => portfolio.id === currentPortfolio?.id);
      if (portfolios && currentData) {
        queryClient.setQueryData(queryKey.USER_PORTFOLIOS(), {
          ...portfolios,
          currentPortfolio: currentData,
        });
      }

      if (shouldRefetch) {
        // clear cache and refetch all active queries after organization change
        queryClient.cancelQueries();
        queryClient.clear();
      }

      if (onSuccess) {
        setTimeout(() => {
          onSuccess(currentData?.name);
        }, snackbarDelay);
      }
    },
  });
};
