import { useMemo } from 'react';

import { content } from '../content';

interface ICalibrationModalTitleProps {
  title?: string;
}

export const CalibrationModalTitle = ({ title }: ICalibrationModalTitleProps) => {
  const modalTitle = useMemo(() => {
    switch (title) {
      case 'CH1': {
        return content.CALIBRATION_MODAL.TITLE_SMALL;
      }
      case 'CH2': {
        return content.CALIBRATION_MODAL.TITLE_LARGE;
      }
      default: {
        return content.CALIBRATION_MODAL.TITLE;
      }
    }
  }, [title]);

  return <div data-testid="calibration-modal-title">{modalTitle}</div>;
};
