export type TSortingOrder = 'Ascending' | 'Descending';

export interface ISorting<TSort> {
  direction: TSortingOrder;
  sortBy: TSort | '';
}

export interface IBaseFilters<TSort> extends Record<string, unknown> {
  sorting?: ISorting<TSort>;
}

export interface ISortParams<TSort extends string> {
  id: TSort | '';
  name: string;
}

export interface IMobileFilterFormProps<TSort extends string, TFilters extends IBaseFilters<TSort>> {
  filters: TFilters;
  open: boolean;
  toggleOpen: () => void;
  clearFilters: () => void | Promise<unknown>;
  setFilters: (filters: TFilters) => void | Promise<unknown>;
  isAnyFilterSet: boolean;
  sortList: ISortParams<TSort>[];
}

export const defaultSorting = {
  sortBy: '',
  direction: 'Descending',
};
