import { useReactFlow } from '@xyflow/react';
import { useCallback } from 'react';

import { TSystemMapTeeNode, TTeeGraphAsset } from '../../../types';

interface IRenameTeeContextMenuProps {
  openRenameTeeModal: (teeToRename: TTeeGraphAsset) => void;
}

export const useRenameTeeContextMenu = ({ openRenameTeeModal }: IRenameTeeContextMenuProps) => {
  const reactFlow = useReactFlow<TSystemMapTeeNode>();

  const renameTee = useCallback(
    (teeId: string) => {
      const tee = reactFlow.getNode(teeId);
      if (!tee) {
        return;
      }
      openRenameTeeModal(tee.data);
    },
    [openRenameTeeModal, reactFlow]
  );

  return {
    renameTee,
  };
};
