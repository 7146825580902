import { GatewayPayloadBase, TGatewayPayloadBase, TGatewayUpdatePayload } from '@marlin/asset/data-access/gateway';
import { GatewayUpdatePayload } from '@marlin/asset/data-access/gateway';
import { safeParseData } from '@marlin/shared/utils/zod';

import {
  TUpsertConnectionFormSchemaType,
  TUpsertConnectionFormSchemaTypeUpdate,
  UpsertConnectionFormSchemaUpdate,
} from './upsert-connection-form.schema';

export const getConfigurationPayload = (
  data: TUpsertConnectionFormSchemaType | TUpsertConnectionFormSchemaTypeUpdate,
  deviceId: string
): TGatewayUpdatePayload | TGatewayPayloadBase => {
  if (isUpdating(data)) {
    return safeParseData(
      {
        deviceId,
        name: deviceId,
        configurationId: data.id,
        connections: [
          ...data.connections.map((connection) => ({
            id: connection.id,
            name: connection.connectionType,
            network: {
              baudRate: connection.baudRate,
              dataBits: connection.dataBits,
              stopBits: connection.stopBits,
              parity: connection.parity,
            },
            addresses: [
              ...connection.addresses.map((address) => ({
                id: address.id,
                address: Number(address.modbusAddress),
                name: address.name,
                modelId: address.equipmentModel,
                locationId: address.locationId,
              })),
            ],
          })),
        ],
      },
      GatewayUpdatePayload
    );
  }

  return safeParseData(
    {
      deviceId,
      name: deviceId,
      connections: [
        ...data.connections.map((connection) => ({
          name: connection.connectionType,
          network: {
            baudRate: connection.baudRate,
            dataBits: connection.dataBits,
            stopBits: connection.stopBits,
            parity: connection.parity,
          },
          addresses: [
            ...connection.addresses.map((address) => ({
              address: Number(address.modbusAddress),
              name: address.name,
              modelId: address.equipmentModel,
              locationId: address.locationId,
            })),
          ],
        })),
      ],
    },
    GatewayPayloadBase
  );
};

const isUpdating = (
  data: TUpsertConnectionFormSchemaType | TUpsertConnectionFormSchemaTypeUpdate
): data is TUpsertConnectionFormSchemaTypeUpdate => {
  return UpsertConnectionFormSchemaUpdate.safeParse(data).success;
};
