import { Link } from '@marlin/shared/ui-page';

import { content } from '../../content';
import { BaseError } from './base-error.component';

export interface IOrganizationCreationErrorProps {
  handleButtonClick?: () => void;
}

export const OrganizationCreationError = ({ handleButtonClick }: IOrganizationCreationErrorProps) => {
  return (
    <BaseError
      title={content.ERROR.ORGANIZATION_CREATION.TITLE}
      subtitle={
        <>
          {content.ERROR.ORGANIZATION_CREATION.SUBTITLE1}{' '}
          <Link to={`mailto:${content.ERROR.ORGANIZATION_CREATION.LINK}`} external>
            {content.ERROR.ORGANIZATION_CREATION.LINK}
          </Link>{' '}
          {content.ERROR.ORGANIZATION_CREATION.SUBTITLE2}
        </>
      }
      buttonText={content.ERROR.ORGANIZATION_CREATION.BUTTON}
      handleButtonClick={handleButtonClick}
    />
  );
};
