import { EQUIPMENT_TYPE } from '@marlin/shared/utils/zod';

import { content } from '../content';

export const EquipmentTypeMap = new Map([
  [EQUIPMENT_TYPE.DIGITAL_MIXING_VALVE, content.EQUIPMENT_TYPE.DIGITAL_MIXING_VALVE],
  [EQUIPMENT_TYPE.BOILER, content.EQUIPMENT_TYPE.BOILER],
  [EQUIPMENT_TYPE.STEAM_BOILER, content.EQUIPMENT_TYPE.STEAM_BOILER],
  [EQUIPMENT_TYPE.HOT_WATER_HEATER, content.EQUIPMENT_TYPE.HOT_WATER_HEATER],
  [EQUIPMENT_TYPE.LEAK_DEFENSE_SYSTEM, content.EQUIPMENT_TYPE.LEAK_PREVENTION],
  [EQUIPMENT_TYPE.NONE, content.EQUIPMENT_TYPE.NONE],
  [EQUIPMENT_TYPE.PRESSURE_REDUCING_VALVE, content.EQUIPMENT_TYPE.PRESSURE_REDUCING_VALVE],
  [EQUIPMENT_TYPE.PUMP, content.EQUIPMENT_TYPE.PUMP],
  [EQUIPMENT_TYPE.SHUT_OFF_VALVE, content.EQUIPMENT_TYPE.SHUT_OFF_VALVE],
  [EQUIPMENT_TYPE.WATER_TANK, content.EQUIPMENT_TYPE.WATER_TANK],
  [EQUIPMENT_TYPE.PARTITION_CONTROLLER, content.EQUIPMENT_TYPE.PARTITION_CONTROLLER],
]);
