import { TRecipientType } from '../automation.model';

export enum CHANNEL_TYPE_RESPONSE {
  EMAIL = 'Email',
  SMS = 'SMS',
  IN_APP = 'InApp',
  HOT_SOS = 'HotSOS',
}

export enum CRITICALITY_RESPONSE {
  LOW = 'Low',
  HIGH = 'High',
}

export interface IRecipientResponse {
  recipientId: string;
  recipientType: TRecipientType;
}

export interface IChannelResponse {
  channelType: CHANNEL_TYPE_RESPONSE;
  recipients?: IRecipientResponse[];
}
