import { TEquipmentDashboardSectionMetadataItem } from '@marlin/asset/shared/equipment-config';
import { TDatapointWithUiMetadata } from '@marlin/shared/utils/datapoint-mappers';

interface IDatapointWithDependencies {
  dependencies: TEquipmentDashboardSectionMetadataItem['dependencies'];
  getDatapoint: (name: string) => TDatapointWithUiMetadata | undefined;
  datapoint?: TDatapointWithUiMetadata;
}

export const getDatapointWithDependencies = ({ dependencies, datapoint, getDatapoint }: IDatapointWithDependencies) => {
  if (!dependencies) {
    return datapoint;
  }

  if (dependencies.visibility) {
    const dependency = getDatapoint(dependencies.visibility.datapointName);
    const isVisible = dependencies.visibility.value.get(dependency?.value ?? '');

    return isVisible ? datapoint : undefined;
  }

  if (dependencies.override) {
    const overrideValue = getDatapoint(dependencies.override.datapointName)?.value;
    const displayedOverrideValue = dependencies.override.value.get(overrideValue ?? '');

    return {
      ...datapoint,
      value: displayedOverrideValue ? overrideValue : datapoint?.value,
      displayedValue: displayedOverrideValue ? displayedOverrideValue : datapoint?.displayedValue,
    };
  }

  if (dependencies.statusMapping) {
    const statusMappingValue = dependencies.statusMapping.get(datapoint?.value ?? '');

    return {
      ...datapoint,
      displayedValue: statusMappingValue ? statusMappingValue : datapoint?.displayedValue,
    };
  }

  return datapoint;
};
