import { z } from 'zod';

export enum CRITICALITY_RESPONSE {
  LOW = 'Low',
  HIGH = 'High',
}

export enum CRITICALITY {
  LOW = 'LOW',
  HIGH = 'HIGH',
}

export const AlertCriticality = z
  .nativeEnum(CRITICALITY_RESPONSE)
  .transform((val) => (val === CRITICALITY_RESPONSE.LOW ? CRITICALITY.LOW : CRITICALITY.HIGH));
export type TAlertCriticality = z.infer<typeof AlertCriticality>;
