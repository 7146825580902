export const content = {
  CREATE_SENSOR_ERROR: `Something went wrong. Your sensor hasn't been created`,
  NOT_RECOGNISED: 'Manufacturer ID not recognized.',
  ALREADY_SIGNALED:
    'This Manufacturer ID is already registered. Please check your registered sensors, or contact your administrator.',
  EMPTY_LOCATION_HELPER_TEXT: 'Location is required for equipment selection.',
  EMPTY_EQUIPMENT: 'No results found.',
  TITLE: 'New Sensor',
  SUBTITLE: 'Use the form below to create a new sensor.',
  SENSOR_DETAILS: 'Sensor Details',
  SENSOR_NAME_LABEL: 'Sensor Name',
  MANUFACTURER_ID_LABEL: 'Manufacturer ID',
  SENSOR_TYPE_LABEL: 'Sensor Type',
  TEMPERATURE_LABEL: 'Temperature Sensor',
  PRESSURE_LABEL: 'Pressure Sensor',
  LEAK_LABEL: 'Leak Detector',
  LOCATION_LABEL: 'Location',
  EQUIPMENT_LABEL: 'Linked Equipment',
  PIN_SENSOR_LABEL: 'Pin Sensor to Home',
  PIN_SENSOR_HELPER: 'Use the pinning feature to pull a specific sensor to the top of the home screen.',
  ADDITIONAL_INFORMATION: 'Additional Information',
  MANUFACTURER_LABEL: 'Manufacturer',
  MODEL_LABEL: 'Model',
  DESCRIPTION_LABEL: 'Description',
  DESCRIPTION_PLACEHOLDER: 'Add a short description about sensor',
  BUTTON_CANCEL: 'Cancel',
  BUTTON_CONFIRM: 'Create',
  CONFIRMATION_MESSAGE_CREATE_DEVICE_SUCCESS: 'Your sensor has been created',
};
