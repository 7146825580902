import { FullLogo } from '@marlin/shared/theme';
import { Button, useMediaQuery, useTheme } from '@mui/material';

import { TEASER_MODAL_ACTION_TYPE } from '../../teaser-modal-provider/types';
import { useTeaserModal } from '../../teaser-modal-provider/use-teaser-modal';
import { useStyles } from './book-demo-side.styles';
import { content } from './content';
import { DotItems } from './dot-items/dot-items.component';
import { ScheduleDemo } from './schedule-demo/schedule-demo.component';
import { Title } from './title/title.component';

export const BookDemoSide = () => {
  const { classes } = useStyles();
  const { modalDispatch } = useTeaserModal();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const handleNotNow = () => {
    modalDispatch({
      type: TEASER_MODAL_ACTION_TYPE.DISMISS,
    });
  };

  return (
    <div className={classes.container}>
      {isMobile && (
        <div className={classes.logo}>
          <FullLogo />
        </div>
      )}
      <Title />
      <DotItems />
      <ScheduleDemo />
      <Button className={classes.button} variant="text" color="secondary" onClick={handleNotNow}>
        {content.BUTTONS.NOT_NOW}
      </Button>
    </div>
  );
};
