import EggRoundedIcon from '@mui/icons-material/EggRounded';
import PanoramaPhotosphereOutlinedIcon from '@mui/icons-material/PanoramaPhotosphereOutlined';
import SpeedRoundedIcon from '@mui/icons-material/SpeedRounded';
import ThermostatRoundedIcon from '@mui/icons-material/ThermostatRounded';

import { content } from './content';

export type TDeviceType = 'TEMPERATURE' | 'PRESSURE' | 'LEAK' | 'PULSE_METER' | 'UNKNOWN';
export enum DEVICE_TYPE {
  TEMPERATURE = 'TEMPERATURE',
  PRESSURE = 'PRESSURE',
  LEAK = 'LEAK',
  PULSE_METER = 'PULSE_METER',
  UNKNOWN = 'UNKNOWN',
}

export const deviceTypeOptions = [
  {
    id: DEVICE_TYPE.TEMPERATURE,
    name: content.TEMPERATURE_ITEM,
    Icon: ThermostatRoundedIcon,
  },
  {
    id: DEVICE_TYPE.PRESSURE,
    name: content.PRESSURE_ITEM,
    Icon: SpeedRoundedIcon,
  },
  {
    id: DEVICE_TYPE.PULSE_METER,
    name: content.FLOW_METER,
    Icon: PanoramaPhotosphereOutlinedIcon,
  },
  {
    id: DEVICE_TYPE.LEAK,
    name: content.LEAK_ITEM,
    Icon: EggRoundedIcon,
  },
];

export enum LAST_READING_VALUE_NAME {
  BATTERY_LEVEL = 'BatteryLevel',
}
