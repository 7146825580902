import { Menu, MenuItem } from '@mui/material';
import { MouseEvent } from 'react';

import { useStyles } from './context-menu.styles';

export interface IOption {
  label: string;
  onClick: (id: string, event?: MouseEvent<HTMLLIElement>) => void;
  divider?: boolean;
}

interface IMenuProps {
  menu: {
    id: string;
    top?: number;
    left?: number;
    right?: number;
    bottom?: number;
    options: IOption[];
  };
  onClose: () => void;
}

export const ContextMenu = ({ menu: { id, top, left, options }, onClose }: IMenuProps) => {
  const { classes } = useStyles();

  return (
    <>
      <Menu
        open={Boolean(top && left)}
        onClose={onClose}
        anchorReference="anchorPosition"
        anchorPosition={top && left ? { top, left } : undefined}
        classes={{ list: classes.menu }}
        data-testid="context-menu"
      >
        {options.map((option) => {
          return (
            <MenuItem
              data-testid={`context-menu-${option.label.split(' ').join('-').toLowerCase()}`}
              key={option.label}
              onClick={(event) => {
                option.onClick(id, event);
              }}
              divider={option.divider}
              dense
            >
              {option.label}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
