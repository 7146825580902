import { createContext, useContext } from '@marlin/shared/utils-component-context';

import { IExpandedRows } from '../types';

type TInfiniteTableContext = IExpandedRows;

export const InfiniteTableContext = createContext<TInfiniteTableContext>();

export const InfiniteTableContextProvider = InfiniteTableContext.Provider;

export const useInfiniteTableContext = () => useContext(InfiniteTableContext);
