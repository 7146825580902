import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    '& .MuiPaper-root': {
      height: '90vh',
      width: '100%',
      maxWidth: 1021,
    },
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.alternative,
  },
}));
