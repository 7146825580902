import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.typography.pxToRem(8),
    alignItems: 'center',
  },
  text: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: theme.typography.pxToRem(22),
  },
  icon: {
    height: theme.typography.pxToRem(24),
    width: theme.typography.pxToRem(24),
  },
}));
