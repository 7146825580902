import { MarlinTheme } from '@marlin/shared/theme';
import { Dot } from '@marlin/shared/ui-criticality';
import { useRouter } from '@marlin/shared/utils-router';
import { ISensorHubParams, routes } from '@marlin/shared/utils-routes';
import { makeStyles } from 'tss-react/mui';

import { CRITICALITY, TCriticality } from '../../../shared/types';
import { transformCounter } from '../../../shared/utils';
import { content } from './content';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    cursor: 'pointer',
  },
}));

interface ICriticalityControl {
  severity: TCriticality;
  inactiveGatewaysCount?: number;
  inactiveDevicesCount?: number;
  batteries?: boolean;
}

export const CriticalityControl = ({
  severity,
  inactiveDevicesCount,
  inactiveGatewaysCount,
  batteries,
}: ICriticalityControl) => {
  const { classes } = useStyles();
  const { goTo } = useRouter();

  const navigateToAlertsList = (criticality?: 'HIGH' | 'LOW', alertType?: 'Sensor' | 'Equipment') => {
    goTo(routes.alerts.list.url({ criticality, category: 'Health', alertType }));
  };

  const navigateToSensors = (params?: ISensorHubParams) => {
    goTo(routes.sensors.list.url(params));
  };

  switch (severity) {
    case CRITICALITY.HIGH: {
      if (batteries) {
        return (
          <Dot variant={CRITICALITY.HIGH}>
            <div
              data-testid={`${severity}-${content.BATTERIES_CRITICAL}`}
              className={classes.container}
              onClick={() => navigateToSensors({ batteryLevelStatus: 'CRITICAL' })}
            >
              {content.BATTERIES_CRITICAL}
            </div>
          </Dot>
        );
      }

      if (inactiveGatewaysCount === 0 || !inactiveGatewaysCount) {
        return (
          <Dot variant={CRITICALITY.NO}>
            <div
              data-testid={`${severity}-${content.GATEWAYS}`}
              className={classes.container}
              onClick={() => navigateToAlertsList()}
            >
              {content.GATEWAYS}
            </div>
          </Dot>
        );
      }

      return (
        <Dot variant={CRITICALITY.HIGH}>
          <div
            data-testid={`${severity}-${transformCounter(inactiveGatewaysCount)}-${content.HIGH_SENSORS}`}
            className={classes.container}
            onClick={() => navigateToAlertsList('HIGH')}
          >
            {transformCounter(inactiveGatewaysCount)} {content.HIGH_SENSORS}
          </div>
        </Dot>
      );
    }
    case CRITICALITY.LOW: {
      if (batteries) {
        return (
          <Dot variant={CRITICALITY.LOW}>
            <div
              data-testid={`${severity}-${content.BATTERIES_LOW}`}
              className={classes.container}
              onClick={() => navigateToSensors({ batteryLevelStatus: 'LOW' })}
            >
              {content.BATTERIES_LOW}
            </div>
          </Dot>
        );
      }

      if (inactiveDevicesCount === 0 || !inactiveDevicesCount) {
        return (
          <Dot variant={CRITICALITY.NO}>
            <div
              data-testid={`${severity}-${content.SENSORS}`}
              className={classes.container}
              onClick={() => navigateToAlertsList()}
            >
              {content.SENSORS}
            </div>
          </Dot>
        );
      }
      return (
        <Dot variant={CRITICALITY.LOW}>
          <div
            data-testid={`${severity}-${transformCounter(inactiveDevicesCount)}-${content.LOW_SENSORS}`}
            className={classes.container}
            onClick={() => navigateToAlertsList('LOW')}
          >
            {transformCounter(inactiveDevicesCount)} {content.LOW_SENSORS}
          </div>
        </Dot>
      );
    }
    case CRITICALITY.NO: {
      if (batteries) {
        return (
          <Dot variant={CRITICALITY.NO}>
            <div
              data-testid={`${severity}-${content.BATTERIES_GOOD}`}
              className={classes.container}
              onClick={() => navigateToSensors()}
            >
              {content.BATTERIES_GOOD}
            </div>
          </Dot>
        );
      }

      return (
        <>
          <Dot variant={CRITICALITY.NO}>
            <div
              data-testid={`${severity}-${content.GATEWAYS}`}
              className={classes.container}
              onClick={() => navigateToAlertsList()}
            >
              {content.GATEWAYS}
            </div>
          </Dot>
          <Dot variant={CRITICALITY.NO}>
            <div
              data-testid={`${severity}-${content.SENSORS}`}
              className={classes.container}
              onClick={() => navigateToAlertsList()}
            >
              {content.SENSORS}
            </div>
          </Dot>
        </>
      );
    }
    default: {
      return null;
    }
  }
};
