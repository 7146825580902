export enum SIGNAL_STRENGTH {
  EXCELLENT = 'Excellent',
  GOOD = 'Good',
  FAIR = 'Fair',
  POOR = 'Poor',
  NO_SIGNAL = 'NoSignal',
}

export const signalBoundaries = {
  nexa: {
    high: -65,
    medium: -75,
    low: -85,
  },
  monit: {
    high: 75,
    medium: 50,
    low: 11,
  },
};
