import { RoleSchema } from '@marlin/shared/utils-role';
import { getHttpClient } from '@marlin/shared/utils/react-query';
import { z } from 'zod';

import { IInvitationUserResponseProps } from '../invitation-user.model';
import { paths } from './api';

const createInvitationSchema = z
  .object({
    firstName: z.string(),
    lastName: z.string(),
    email: z.string(),
    title: z.string().nullish(),
    role: RoleSchema,
  })
  .transform((data) => ({
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    title: !data.title?.length ? undefined : data.title,
    role: data.role,
  }));

type TCreateInvitationSchema = z.infer<typeof createInvitationSchema>;

export const createInvitationUser = (data: TCreateInvitationSchema): Promise<IInvitationUserResponseProps> => {
  return getHttpClient().post(paths.INVITE_USER, createInvitationSchema.parse(data));
};
