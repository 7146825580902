import { useEffect, useState } from 'react';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { LOADER_TYPE } from './loader-type.enum';

type TLoaderState = {
  [key in LOADER_TYPE | 'counter']: number;
};

const initialLoaderState: TLoaderState = {
  [LOADER_TYPE.DATA]: 0,
  [LOADER_TYPE.PAGE]: 0,
  [LOADER_TYPE.APP]: 0,
  counter: 0,
};

const loaderState$ = new BehaviorSubject(initialLoaderState);
const currentLoader$ = loaderState$.pipe(distinctUntilChanged((prev, curr) => prev.counter === curr.counter));

const isHighestLoader = (loaderType: LOADER_TYPE, loaderState: TLoaderState): boolean => {
  switch (loaderType) {
    case LOADER_TYPE.APP: {
      return true;
    }

    case LOADER_TYPE.PAGE: {
      return loaderState[LOADER_TYPE.APP] === 0;
    }

    case LOADER_TYPE.DATA: {
      return loaderState[LOADER_TYPE.APP] === 0 && loaderState[LOADER_TYPE.PAGE] === 0;
    }

    default: {
      return false;
    }
  }
};

const isSingleLoader = (loaderType: LOADER_TYPE, loaderState: TLoaderState): boolean => {
  return loaderState[loaderType] === 0;
};

const clear = (currentLoader: LOADER_TYPE, currentState: TLoaderState): void => {
  loaderState$.next({
    ...currentState,
    [currentLoader]: 0,
    counter: currentState.counter - currentState[currentLoader],
  });
};

const update = (currentLoader: LOADER_TYPE, currentState: TLoaderState): void => {
  loaderState$.next({
    ...currentState,
    [currentLoader]: currentState[currentLoader] + 1,
    counter: currentState.counter + 1,
  });
};

export const useLoader = (currentLoader: LOADER_TYPE): boolean => {
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    const subscription$ = currentLoader$.subscribe((currentState: TLoaderState): void => {
      if (!isHighestLoader(currentLoader, currentState)) {
        setShowLoader(false);
        clear(currentLoader, currentState);
      } else if (isSingleLoader(currentLoader, currentState)) {
        update(currentLoader, currentState);
        setShowLoader(true);
      }
    });
    return (): void => {
      subscription$.unsubscribe();
      clear(currentLoader, loaderState$.getValue());
    };
  }, [currentLoader]);

  useEffect(() => {
    const currentState = loaderState$.getValue();
    if (isHighestLoader(currentLoader, currentState)) {
      update(currentLoader, currentState);
      setShowLoader(true);
    } else {
      setShowLoader(false);
    }
  }, [currentLoader]);

  return showLoader;
};
