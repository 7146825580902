import { z } from 'zod';

export enum ERROR_TYPES {
  LOWER = 'LOWER',
  HIGHER = 'HIGHER',
  LEAK_DETECTION = 'LEAK_DETECTION',
  LOST_COMMUNICATION = 'LOST_COMMUNICATION',
  LOW_BATTERY = 'LOW_BATTERY',
  UNKNOWN = 'UNKNOWN',
  DEVICE_ERROR = 'DEVICE_ERROR',
  DEVICE_ALERT = 'DEVICE_ALERT',
}

export type TCriticality = 'LOW' | 'HIGH';

const getErrorType = (
  alertCause:
    | 'Below'
    | 'Above'
    | 'LeakDetect'
    | 'LostCommunication'
    | 'LowBatteryLevel'
    | 'EquipmentInformation'
    | 'DeviceError'
    | 'DeviceAlert'
): ERROR_TYPES => {
  switch (alertCause) {
    case 'Above': {
      return ERROR_TYPES.HIGHER;
    }
    case 'Below': {
      return ERROR_TYPES.LOWER;
    }
    case 'LeakDetect': {
      return ERROR_TYPES.LEAK_DETECTION;
    }
    case 'LostCommunication': {
      return ERROR_TYPES.LOST_COMMUNICATION;
    }
    case 'LowBatteryLevel': {
      return ERROR_TYPES.LOW_BATTERY;
    }
    case 'DeviceError':
      return ERROR_TYPES.DEVICE_ERROR;
    case 'DeviceAlert':
      return ERROR_TYPES.DEVICE_ALERT;
    default: {
      return ERROR_TYPES.UNKNOWN;
    }
  }
};

const getCriticality = (criticality: 'Low' | 'High'): TCriticality => {
  switch (criticality) {
    case 'Low': {
      return 'LOW';
    }
    case 'High': {
      return 'HIGH';
    }
  }
};

export const AlertCauseSchema = z
  .union([
    z.literal('Below'),
    z.literal('Above'),
    z.literal('LeakDetect'),
    z.literal('LostCommunication'),
    z.literal('LowBatteryLevel'),
    z.literal('EquipmentInformation'),
    z.literal('DeviceError'),
    z.literal('DeviceAlert'),
  ])
  .transform(getErrorType);

export const CriticalitySchema = z.union([z.literal('High'), z.literal('Low')]).transform(getCriticality);
