import { content } from '../../../content';

export const waterHeaterSectionConfig = [
  {
    title: content.SYSTEM_STATUS,
    sectionDatapoints: [
      {
        sectionName: 'temperatureSensors',
        title: content.EQUIPMENT_READINGS_AERCO.TEMPERATURES,
        items: [
          { name: 'InletTemp', label: content.DATAPOINT_LABELS_AERCO.INLET },
          { name: 'OutdoorTemp', label: content.DATAPOINT_LABELS_AERCO.OUTDOOR_TEMPERATURE },
          { name: 'ExhaustTemp', label: content.DATAPOINT_LABELS_AERCO.EXHAUST_TEMP },
        ],
      },
      {
        title: content.EQUIPMENT_READINGS_AERCO.WATER_HEATER_PERFORMANCE,
        sectionName: 'waterHeaterPerformance',
        items: [
          { name: 'FlameStrength', label: content.DATAPOINT_LABELS_AERCO.FLAME_STRENGTH },
          { name: 'CurrentBlowerVoltage', label: content.DATAPOINT_LABELS_AERCO.BLOWER_VOLTAGE },
        ],
      },
    ],
    style: { fullWidth: true },
    dataTestId: 'system-status-section',
  },
  {
    sectionDatapoints: [
      {
        sectionName: 'waterHeaterMetrics',
        title: content.EQUIPMENT_READINGS_AERCO.WATER_HEATER_METRICS,
        items: [
          { name: 'RunHours', label: content.DATAPOINT_LABELS_AERCO.RUN_HOURS },
          { name: 'RunCycles', label: content.DATAPOINT_LABELS_AERCO.CYCLE_COUNT },
        ],
      },
      {
        sectionName: 'waterHeaterInfo',
        title: content.EQUIPMENT_READINGS_AERCO.ADDITIONAL_WATER_HEATER_INFO,
        items: [{ name: 'StartValvePosition', label: content.DATAPOINT_LABELS_AERCO.START_LEVEL }],
      },
    ],
    title: content.EQUIPMENT_METRICS,
    style: { fullWidth: true },
    dataTestId: 'equipment-metrics-section',
  },
];
