import { environment } from '@marlin/environment';

export const content = {
  UPDATE_USER_PROFILE_PAGE_TITLE: 'Create your profile',
  UPDATE_USER_PROFILE_PAGE_SUBTITLE: `Create your profile to get started with ${environment.app.name}.`,
  SUCCESS_MESSAGE: 'You were successfully added to the organization',
  ERROR_DEFAULT: 'An error occurred, please try again',
  ERROR_WRONG_EMAIL_ADDRESS: 'This value must be a valid email address',
  INVITATION_EXPIRED_TITLE: 'Invitation Expired',
  INVITATION_EXPIRED_BODY: (orgName: string) =>
    `Your invitation to the ${orgName} has expired. Please ask the ${orgName} administrator to resend your invitation.`,
  INVITATION_NOT_ACCEPTED_TITLE: 'Invitation Invalid',
  INVITATION_NOT_ACCEPTED_BODY: 'Your invitation is invalid. For more information, please reach out to',
  SUPPRORT_TEXT: 'If you still have issues, please reach out to',
  SUPPRORT_LINK: 'support@nexaplatform.com',
  BUTTONS: {
    CONFIRM: 'create',
    CANCEL: 'cancel',
  },
  ORGANIZATION_HAS_BEEN_CHANGED: 'Organization has been changed',
  LOADER: 'Please wait, you are being added to Organization',
  INVALID_EMAIL_ADDRESS: {
    TITLE: 'Invalid Email Address',
    BODY: 'Please verify if your email is the same as in the footer of the invitation.',
  },
};
