import {
  TDayName,
  TEquipmentSchedulePayload,
  TParsedDailySchedule,
  queryKey,
  useEquipmentSchedule,
} from '@marlin/asset/data-access/equipment';
import { SettingsSectionSkeleton } from '@marlin/asset/ui/settings';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { PERMISSIONS, usePermission } from '@marlin/shared/utils-permission';
import { useSearchParams } from '@marlin/shared/utils-router';
import { TSettingsGroup } from '@marlin/shared/utils/datapoint-mappers';
import { queryClient } from '@marlin/shared/utils/react-query';
import { useIdFromPathname } from '@marlin/shared/utils/url-params';

import { useDatapointsContext } from '../../../../shared/context/datapoints.context';
import { content } from '../../../content';
import { ScheduleRow } from './row-view.component';
import { ScheduleHeader } from './schedule-header.component';
import { ScheduleTemperatureSection } from './schedule-temperature-section.component';

interface IScheduleSettings {
  saveSchedule: (equipmentId: string, value: TEquipmentSchedulePayload) => Promise<void>;
  updateSettings: (name: string, value: string, prevValue: string) => Promise<void>;
  temperatureSettingGroup: TSettingsGroup | null;
}

export const ScheduleSettings = ({ saveSchedule, updateSettings, temperatureSettingGroup }: IScheduleSettings) => {
  const equipmentId = useIdFromPathname();
  const { enqueueSnackbar } = useSnackbar();
  const [, setSearchParams] = useSearchParams();
  const onError = () => {
    enqueueSnackbar(content.ERROR, {
      variant: 'error',
      preventDuplicate: true,
    });

    setSearchParams((currentParams) => {
      if (currentParams.has('settingGroupId')) {
        currentParams.delete('settingGroupId');
      }
      return currentParams;
    });
  };
  const { data: schedule, isLoading } = useEquipmentSchedule({ equipmentId, onError });
  const { getDatapoint } = useDatapointsContext();
  const overrideMode = getDatapoint('overrideMode');
  const isEditable = usePermission(PERMISSIONS.UPDATE_EQUIPMENT_SCHEDULE);
  const isSanitize = overrideMode?.value === 'sanitize';

  if (isLoading) {
    return <SettingsSectionSkeleton />;
  }

  if (!schedule) return null;

  const days = Object.entries(schedule.days);
  const onSubmit = async (data: TParsedDailySchedule, dayName: TDayName) =>
    await saveSchedule(equipmentId, {
      prevSchedule: schedule,
      newSchedule: {
        scheduleEnabled: schedule.scheduleEnabled,
        days: {
          ...schedule.days,
          [dayName]: data,
        },
      },
    });

  const onSubmitToggleSchedule = async (checked: boolean) => {
    await saveSchedule(equipmentId, {
      prevSchedule: schedule,
      newSchedule: {
        days: {
          ...schedule.days,
        },
        scheduleEnabled: checked,
      },
    });

    queryClient.invalidateQueries({ queryKey: queryKey.SETTINGS(temperatureSettingGroup, equipmentId) });
  };

  return (
    <>
      <ScheduleTemperatureSection updateSettings={updateSettings} temperatureSettingGroup={temperatureSettingGroup} />
      <ScheduleHeader
        isEditable={isEditable}
        isSanitize={isSanitize}
        scheduleEnabled={schedule.scheduleEnabled}
        onSubmitToggleSchedule={onSubmitToggleSchedule}
      />
      {schedule.scheduleEnabled &&
        days.map(([dayName, day], index) => (
          <ScheduleRow
            isLast={index === days.length - 1}
            isFirst={index === 0}
            key={dayName}
            day={day}
            name={dayName as TDayName}
            onSubmit={onSubmit}
            isEditable={isEditable}
            isSanitize={isSanitize}
          />
        ))}
    </>
  );
};
