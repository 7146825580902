import { IAlert } from '@marlin/alert/data-access/alert-action';
import { MarlinTheme } from '@marlin/shared/theme';
import { CriticalityControl } from '@marlin/shared/ui-criticality';
import { Paper } from '@marlin/shared/ui-page';
import { Details, DetailsSection } from '@marlin/shared/ui/collapsible-details-mobile';
import { routes } from '@marlin/shared/utils-routes';
import { TDeviceMetadataDatapointsResponse } from '@marlin/shared/utils/datapoint-mappers';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import EmailIcon from '@mui/icons-material/Email';
import PlaceIcon from '@mui/icons-material/Place';
import TextsmsIcon from '@mui/icons-material/Textsms';
import { Grid, Typography } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../../content';
import { Changelog } from '../shared/changelog.component';
import { Chips } from '../shared/chips.component';
import { CounterWithIcon } from '../shared/counter-with-icon.component';
import { DetailsLink } from '../shared/details-link.component';
import { IconSwitcher } from '../shared/icon-switcher.component';
import { LinkWithIcon } from '../shared/icon-with-link.component';
import { TimeSection } from './time-section.component';

interface IAlertCurrent {
  alert: IAlert;
  metadata: TDeviceMetadataDatapointsResponse[];
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: theme.typography.pxToRem(8),
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.typography.pxToRem(12),
  },

  paper: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },

  hyperLinkWrapper: {
    marginTop: theme.typography.pxToRem(8),
    marginBottom: theme.typography.pxToRem(8),
  },

  description: {
    fontFamily: 'Inter',
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.text.primary,
  },

  headerRow: {
    justifyContent: 'space-between',
  },

  title: {
    fontWeight: theme.typography.fontWeightBold,
    marginTop: 0,
    marginBottom: theme.typography.pxToRem(8),
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.body1.fontSize,
      marginBottom: 0,
    },
  },

  detailsWrapper: { display: 'flex', flexDirection: 'column', gap: theme.typography.pxToRem(8) },
}));

export function MobileEventLogCard({ alert, metadata }: IAlertCurrent) {
  const { classes } = useStyles();

  return (
    <Grid item xs={12} data-testid="event-log-mobile-card">
      <Paper testId="alert-card" className={classes.paper}>
        <div className={classes.row}>
          <CriticalityControl severity={alert.criticality} />
          <Chips ruleCategory={alert.ruleCategory} status={alert.status} />
        </div>
        <div className={classes.row} data-testid="event-log-mobile-card-header">
          <IconSwitcher alert={alert} />
          <Grid className={classes.headerRow} container>
            <Grid item>
              <Typography variant="h5" className={classes.title} data-testid="event-log-mobile-card-title">
                {alert.title}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <TimeSection label={content.EVENT_LOG_DETAILS.START_TIME} datetime={alert.startTime} />
        {alert.resolveTime && (
          <TimeSection label={content.EVENT_LOG_DETAILS.RESOLVE_TIME} datetime={alert.resolveTime} />
        )}
      </Paper>
      <Details>
        <DetailsSection title={content.EVENT_LOG_DETAILS.ACTIONS} withDivider>
          <div className={classes.detailsWrapper}>
            <CounterWithIcon
              iconElement={<EmailIcon />}
              text={content.EVENT_LOG_DETAILS.EMAIL_MSG}
              counter={alert.channels['EMAIL']?.recipientIds.length ?? 0}
            />
            <CounterWithIcon
              iconElement={<TextsmsIcon />}
              text={content.EVENT_LOG_DETAILS.TEXT_MSG}
              counter={alert.channels['SMS']?.recipientIds.length ?? 0}
            />
          </div>
        </DetailsSection>
        <DetailsSection title={content.EVENT_LOG_DETAILS.HIERARCHY} withDivider>
          <div className={classes.detailsWrapper}>
            {alert.location?.name && (
              <LinkWithIcon
                iconElement={<PlaceIcon />}
                text={alert.location.name}
                to={routes.locations.details.url(alert.location.id)}
              />
            )}
            <LinkWithIcon
              iconElement={<DeviceHubIcon />}
              text={alert.equipment.name}
              to={routes.equipments.details.url(alert.equipment.id)}
            />
          </div>
        </DetailsSection>
        {alert.ruleCategory === 'Information' && !isEmpty(alert.additionalValues) ? (
          <DetailsSection title={content.EVENT_LOG_DETAILS.CHANGELOG}>
            <Changelog alert={alert} metadata={metadata} />
          </DetailsSection>
        ) : (
          <DetailsSection title={content.EVENT_LOG_DETAILS.DESCRIPTION}>
            {alert.hyperlink && <DetailsLink hyperlink={alert.hyperlink} />}
            {alert.description}
          </DetailsSection>
        )}
      </Details>
    </Grid>
  );
}
