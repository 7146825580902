import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { CREATE_RECIPIENT_ERROR_CODE } from '@marlin/account-data-access-recipient';
import { ModalLarge } from '@marlin/shared/ui-modal';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Buttons } from './buttons.component';
import { errorCodeMessages } from './constants';
import { content } from './content';
import { CreateRecipientFormValidationSchema } from './create-recipient-form.schema';
import { FormContent } from './form-content.component';
import { ICreateRecipient } from './types';

interface ICreateRecipientProps {
  onSubmit: (data: ICreateRecipient) => void;
  onClose: () => void;
  errorCode?: CREATE_RECIPIENT_ERROR_CODE;
}

const defaultValues: ICreateRecipient = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
};

export function CreateRecipient({ onSubmit, onClose, errorCode }: ICreateRecipientProps) {
  const form = useForm<ICreateRecipient>({
    defaultValues,
    mode: 'onTouched',
    resolver: yupResolver(CreateRecipientFormValidationSchema),
  });

  useEffect(() => {
    if (errorCode) {
      form.setError('email', { type: 'custom', message: errorCodeMessages[errorCode] });
    }
  }, [errorCode, form]);

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <ModalLarge
          onClose={onClose}
          title={content.TITLE}
          body={<FormContent />}
          footer={<Buttons onSubmit={onSubmit} onCancel={onClose} />}
        />
      </form>
    </FormProvider>
  );
}
