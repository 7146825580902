import { TGetFilteredWebhooksParams } from '../schemas/get-filtered-webhooks.schema';
import { TGetWebhookParams } from '../schemas/get-webhook.schema';

export enum QUERY_KEY {
  WEBHOOKS = 'webhooks',
  WEBHOOK = 'webhook',
}

export const queryKey = {
  FILTERED_WEBHOOKS: (filterParams?: Partial<TGetFilteredWebhooksParams>) =>
    filterParams ? [QUERY_KEY.WEBHOOKS, filterParams] : [QUERY_KEY.WEBHOOKS],
  WEBHOOK: (params?: TGetWebhookParams) => [QUERY_KEY.WEBHOOK, params],
};
