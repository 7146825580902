import { Typography } from '@mui/material';

import { content } from '../../../content';
import { useStyles } from './change-system-tag.styles';

export const ChangeSystemTagTitle = () => {
  const { classes } = useStyles();

  return <Typography className={classes.titleText}>{content.SYSTEM_SOURCE_TAG.SET_TAG_CONFIRMATION_TITLE}</Typography>;
};
