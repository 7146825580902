import { MarlinTheme } from '@marlin/shared/theme';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  logo: {
    height: theme.typography.pxToRem(40),
    width: theme.typography.pxToRem(162),
  },
  setupSuccessLogo: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.typography.pxToRem(16)} 0`,
    justifyContent: 'center',
    marginTop: theme.typography.pxToRem(96),
    [theme.breakpoints.down('lg')]: {
      textAlign: 'center',
      padding: `${theme.typography.pxToRem(16)} 0 ${theme.typography.pxToRem(8)}`,
      '& svg': {
        width: theme.typography.pxToRem(198),
        height: theme.typography.pxToRem(112),
      },
    },
  },
  container: {
    maxWidth: theme.typography.pxToRem(800),
    margin: '0 auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.typography.pxToRem(48)} 0`,
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.typography.pxToRem(8),
  },
  noSensors: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    marginTop: theme.typography.pxToRem(24),
  },
  footer: {
    marginTop: 'auto',
    color: theme.palette.text.secondary,
  },
}));

export const NoSensorsConnected = () => {
  const { classes, theme } = useStyles();
  const router = useRouter();

  const onAddSensorClick = () => {
    router.goTo(routes.sensors.create.globalPath);
  };

  return (
    <div className={classes.container}>
      <div className={classes.logo}>{theme.icon.fullLogo}</div>
      <div className={classes.setupSuccessLogo}>{theme.icon.setupSuccessLogo}</div>
      <div className={classes.noSensors}>
        <Typography variant="h4" align="center" className={classes.title}>
          {content.NO_SENSORS_TITLE}
        </Typography>
        <Typography variant="subtitle1" align="center">
          {content.NO_SENSORS_SUBTITLE}
        </Typography>
        <Button className={classes.button} variant="contained" onClick={onAddSensorClick}>
          {content.ADD_SENSOR}
        </Button>
      </div>
      <Typography className={classes.footer} variant="body2">
        {content.FOOTER}
      </Typography>
    </div>
  );
};
