import { ALERT_STATUS_FILTER, useFilteredAlertsWithPaging } from '@marlin/alert/data-access/alert-action';
import { TEquipmentAlert, useLiveAlerts } from '@marlin/asset/data-access/equipment';
import { createContext, useContext } from '@marlin/shared/utils-component-context';
import { useCallback, useMemo } from 'react';

import { useEquipmentDetailsConfigContext } from './equipment-details-config-context';
import { useEquipmentContext } from './equipment.context';

const maxAlertCount = 5;

interface IEquipmentAlertsContext {
  alerts: TEquipmentAlert[];
  getAlertsForDatapoint: (datapointName: string) => TEquipmentAlert[];
  isLoading: boolean;
}

export const EquipmentAlertsContext = createContext<IEquipmentAlertsContext>();

export const EquipmentAlertsContextProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    config: {
      dashboard: { sections, productDetails },
    },
  } = useEquipmentDetailsConfigContext();
  const { equipment } = useEquipmentContext();

  const sectionDatapoints = useMemo(() => {
    return sections.flatMap((section) =>
      section.sectionDatapoints?.flatMap((sectionDatapoint) => sectionDatapoint.items)
    );
  }, [sections]);

  const { data, isLoading } = useFilteredAlertsWithPaging({
    params: {
      equipmentIds: [equipment?.id ?? ''],
      locationIds: [],
      status: ALERT_STATUS_FILTER.CURRENT,
      deviceIds: [],
      pageSize: maxAlertCount,
      page: 1,
      deviceTypes: [],
      ruleCategories: [],
      eventCodes: [],
    },
  });

  const { currentAlerts: liveAlerts } = useLiveAlerts();

  const alerts = useMemo((): TEquipmentAlert[] => {
    if (!liveAlerts) {
      return (data?.data || []).map<TEquipmentAlert>((alert) => ({
        deviceEventCode: alert.deviceEventCode || '',
        startTime: alert.startTime || '',
        title: alert.title || '',
        criticality: alert.criticality,
        uoM: alert.uoM,
      }));
    }

    return liveAlerts.slice(0, maxAlertCount);
  }, [data?.data, liveAlerts]);

  const getAlertsForDatapoint = useCallback(
    (datapointName: string) => {
      const sectionDatapoint = sectionDatapoints?.find(
        (sectionDatapoint) => sectionDatapoint?.name.toLowerCase() === datapointName.toLowerCase()
      );
      const eventCodes = sectionDatapoint?.eventCodes?.map((eventCode) => eventCode.toLowerCase());

      if (eventCodes) {
        return alerts.filter(
          (alert) => alert.deviceEventCode && eventCodes?.includes(alert.deviceEventCode.toLowerCase())
        );
      }

      const readingsDatapointNames = productDetails?.datapoints.map((datapoint) => datapoint.name.toLowerCase()) || [];

      if (readingsDatapointNames.includes(datapointName.toLowerCase())) {
        const eventCodes = productDetails?.datapoints
          .find((reading) => reading.name.toLowerCase() === datapointName.toLowerCase())
          ?.eventCodes?.map((eventCode) => eventCode.toLowerCase());

        return alerts.filter((alert) => {
          return alert.deviceEventCode && eventCodes?.includes(alert.deviceEventCode.toLowerCase());
        });
      }

      return [];
    },
    [alerts, sectionDatapoints, productDetails?.datapoints]
  );

  return (
    <EquipmentAlertsContext.Provider value={{ alerts, getAlertsForDatapoint, isLoading }}>
      {children}
    </EquipmentAlertsContext.Provider>
  );
};

export const useEquipmentAlertsContext = () => useContext(EquipmentAlertsContext);
