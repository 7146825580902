import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';
interface IStyles {
  disabled?: boolean;
}

export const useStyles = makeStyles<IStyles>()((theme: MarlinTheme, { disabled }) => ({
  control: {
    display: 'flex',
    alignItems: 'center',
    color: disabled ? theme.palette.action.disabled : theme.palette.action.active,
    width: '100%',
    paddingTop: theme.typography.pxToRem(8),
    paddingBottom: theme.typography.pxToRem(8),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  rightSection: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  },

  message: {
    marginRight: theme.typography.pxToRem(16),
  },

  title: {
    color: disabled ? theme.palette.text.disabled : theme.palette.text.primary,
    marginLeft: theme.typography.pxToRem(8),
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));
