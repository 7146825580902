import { TExtractFnReturnType } from '@marlin/shared/utils/react-query';
import { queryClient, useInfiniteQuery, useQuery } from '@marlin/shared/utils/react-query';
import { useCallback } from 'react';

import { IPagedParams } from '../alert.model';
import { getFilteredAlerts } from '../infrastructure/alerts';
import { queryKey } from './query-key.enum';

type TQueryFnType = typeof getFilteredAlerts;

type TUseAlertsOptions = {
  params: IPagedParams;
  enabled?: boolean;
  staleTime?: number;
};

export const useFilteredAlertsWithPaging = ({ params, enabled, staleTime }: TUseAlertsOptions) => {
  return useQuery<TExtractFnReturnType<TQueryFnType>>({
    queryKey: queryKey.ALERTS(params),
    queryFn: () => getFilteredAlerts(params),
    keepPreviousData: true,
    enabled,
    staleTime,
  });
};

type TUseInfiniteAlertsProps = {
  params: Omit<IPagedParams, 'page'>;
  enabled?: boolean;
  staleTime?: number;
};

export const useFilteredAlertsWithLoadMore = ({ params, enabled, staleTime }: TUseInfiniteAlertsProps) => {
  return useInfiniteQuery<TExtractFnReturnType<TQueryFnType>>({
    queryKey: queryKey.LOAD_MORE_ALERTS(params),
    queryFn: (queryFnParams) => getFilteredAlerts({ ...params, page: queryFnParams.pageParam || 1 }),
    getNextPageParam: (lastPage) => {
      return lastPage.pagination.page * lastPage.pagination.pageSize < lastPage.pagination.totalItems
        ? lastPage.pagination.page + 1
        : undefined;
    },
    enabled,
    keepPreviousData: true,
    staleTime,
  });
};

export const useRefetchFilteredAlertsWithLoadMore = () => {
  return useCallback((params: Omit<IPagedParams, 'page'>) => {
    return queryClient.refetchQueries({ queryKey: queryKey.LOAD_MORE_ALERTS(params) });
  }, []);
};
