import { CircularProgress } from '@mui/material';
import { ReactNode } from 'react';

import { LOADER_TYPE } from '../loader-type.enum';
import { useLoader } from '../use-loader';
import { useStyles } from './page-loader.component.styles';

interface IPageLoaderProps {
  children?: ReactNode;
}

export function PageLoader({ children }: IPageLoaderProps): JSX.Element | null {
  const { classes } = useStyles();
  const showLoader = useLoader(LOADER_TYPE.PAGE);

  if (!showLoader) {
    return null;
  }

  return (
    <>
      <div className={classes.overlay}>
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      </div>
      {children && <div>{children}</div>}
    </>
  );
}
