import { TBatteryLevelStatus } from '@marlin/asset/data-access/device';
import { CommonChip } from '@marlin/shared/ui/chips';

import { content } from '../../content';

const batteryStatusLabel: Partial<Record<TBatteryLevelStatus, string>> = {
  CRITICAL: content.BATTERIES_CRITICAL,
  LOW: content.BATTERIES_LOW,
  OK: content.BATTERIES_GOOD,
};

interface IBatteryStatusChipProps {
  onDelete: () => void;
  batteryStatus: TBatteryLevelStatus;
}

export const BatteryStatusChip = ({ batteryStatus, onDelete }: IBatteryStatusChipProps) => {
  return (
    <CommonChip
      testId={`filterChip-${batteryStatus}`}
      key={batteryStatus}
      active={true}
      label={batteryStatusLabel[batteryStatus] || ''}
      onDelete={onDelete}
    />
  );
};
