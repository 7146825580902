import { IPinDevice, useDeletePinDevice, usePinDevices } from '@marlin/asset/data-access/pin-device';
import { useCallback, useMemo } from 'react';

export interface IHome {
  pinnedDevices?: IPinDevice[];
  // TODO: add modal for confirmation
  deletePinnedDevice: (id: string) => void;
}

export const usePinnedDevices = (): IHome => {
  const pinsQuery = usePinDevices();

  const pinnedDevices = useMemo(() => {
    return pinsQuery.data;
  }, [pinsQuery.data]);

  const deletePinnedDeviceMutation = useDeletePinDevice();

  const deletePinnedDevice = useCallback(
    (id: string) => {
      deletePinnedDeviceMutation.mutateAsync(id);
    },
    [deletePinnedDeviceMutation]
  );

  return {
    pinnedDevices,
    deletePinnedDevice,
  };
};
