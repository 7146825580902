import { MarlinTheme } from '@marlin/shared/theme';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface IMinMaxReadingProps {
  reading: {
    formattedValue: string;
    formattedDate: string;
  };
  label: string;
  testId: string;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  minMaxReading: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.typography.pxToRem(12),
    minWidth: theme.typography.pxToRem(132),
    backgroundColor: theme.palette.background.alternative,
    borderRadius: theme.typography.pxToRem(4),
  },
  minMaxTempContainer: {
    display: 'flex',
  },
  minMaxValue: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(18),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(16),
    },
  },
  readingDate: {
    marginLeft: theme.typography.pxToRem(4),
  },
}));

export const MinMaxReading = ({ reading, testId, label }: IMinMaxReadingProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.minMaxReading}>
      <div className={classes.minMaxTempContainer} data-testid={testId}>
        <Typography variant="overline">{label}</Typography>
        <Typography variant="overline" className={classes.readingDate}>
          {reading.formattedDate}
        </Typography>
      </div>
      <Typography variant="h6" className={classes.minMaxValue}>
        {reading.formattedValue}
      </Typography>
    </div>
  );
};
