import { TChartDisplayType } from '@marlin/shared/utils-chart';
import { TAnalyticsTelemetryResponse } from '@marlin/shared/utils-format-reading';

export const squashEmptyValues = (
  telemetryData: TAnalyticsTelemetryResponse['telemetryData'] | undefined,
  chartDisplayType: TChartDisplayType
): TAnalyticsTelemetryResponse['telemetryData'] => {
  if (!telemetryData || !telemetryData.length) return [];

  if (chartDisplayType === 'bar') return telemetryData;

  const dataLength = telemetryData[0].values.length;
  const areLengthsEqual = telemetryData.every((data) => data.values.length === telemetryData[0].values.length);

  if (!areLengthsEqual) return telemetryData;

  const indexesToSquash: Array<number> = [];

  const checkIfShouldSquash = (datapoint: TAnalyticsTelemetryResponse['telemetryData'][0], index: number) =>
    datapoint.values[index].y === undefined &&
    datapoint.values[index - 1].y === undefined &&
    datapoint.values[index + 1].y === undefined;

  for (let i = 1; i < dataLength - 1; i++) {
    const shouldSquashValue = checkIfShouldSquash(telemetryData[0], i);

    if (shouldSquashValue) {
      const shouldSquashValueInAll = telemetryData.every((data) => checkIfShouldSquash(data, i));

      if (shouldSquashValueInAll) {
        indexesToSquash.push(i);
      }
    }
  }

  return telemetryData.map((datapoint) => ({
    ...datapoint,
    values: datapoint.values.filter((_, index) => !indexesToSquash.includes(index)),
  }));
};
