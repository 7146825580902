import { TDateString, dateAdapter } from '@marlin/shared/utils-common-date';

import { getTimeRange } from './get-time-range';
import { RANGE_FILTER } from './get-time-range-with-format';

export interface IUseTimeAxis {
  lastTick?: number;
  rangeFilter: RANGE_FILTER;
}

export interface ITimeAxis {
  timeRange: number[];
}

const getTimeStamp = (date: TDateString): number => dateAdapter.date(date)?.toDate().getTime() ?? 0;

// TODO: to remove last tick - APEX OFFSET bug
const lastTickOffset = 1000;

export const getSelectionTimeAxis = ({
  lastTick = dateAdapter.date()?.valueOf() ?? 0,
  rangeFilter,
}: IUseTimeAxis): ITimeAxis => {
  const timeRange: TDateString[] = getTimeRange(rangeFilter, lastTick, false);

  const timeRangeTimestamps = [getTimeStamp(timeRange[0]), getTimeStamp(timeRange[1]) - lastTickOffset];

  return {
    timeRange: timeRangeTimestamps,
  };
};
