import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import { paths } from './api';
import {
  TSublocationParamsSchema,
  TSublocationResponseSchema,
  sublocationRequestParamsSchema,
  sublocationsResponseSchema,
} from './schema/sublocations.schema';

export const getAssetsInSublocations = async (
  locationId: string,
  params: TSublocationParamsSchema
): Promise<TSublocationResponseSchema> => {
  const body = safeParseData(params, sublocationRequestParamsSchema);
  const res = await getHttpClient().post<unknown, TSublocationResponseSchema>(paths.SUBLOCATIONS, body, {
    params: { locationId },
  });

  return sublocationsResponseSchema.parse(res);
};
