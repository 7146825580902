import {
  FormField,
  IFormControlProps,
  IFormTypedProps,
  INPUT_TYPE,
  Input,
  parseIntValue,
} from '@marlin/shared/ui-form-common';
import isNil from 'lodash/isNil';
import { ChangeEvent } from 'react';
import { ControllerProps, ControllerRenderProps, FieldValues } from 'react-hook-form';

import { content } from '../content';

type TInputChangeEventHandler = (event: ChangeEvent<HTMLInputElement>) => void;

const handleChangeFactory =
  (onChange: ControllerRenderProps['onChange']): TInputChangeEventHandler =>
  (event: ChangeEvent<HTMLInputElement>) => {
    const parsedValue = parseIntValue(event.target.value);
    const value = typeof parsedValue === 'number' ? parsedValue : null;

    onChange({ ...event, target: { value } });
  };

export function DurationValueControl<TFieldValues extends FieldValues>(
  props: IFormControlProps<TFieldValues>
): JSX.Element;
export function DurationValueControl<
  TFieldValues extends FieldValues = object,
  TName extends ControllerProps<TFieldValues>['name'] = ControllerProps<TFieldValues>['name']
>(props: IFormTypedProps<TFieldValues, TName>): JSX.Element;

export function DurationValueControl<
  TFieldValues extends FieldValues = object,
  TName extends ControllerProps<TFieldValues>['name'] = ControllerProps<TFieldValues>['name']
>(params: IFormControlProps<TFieldValues> | IFormTypedProps<TFieldValues, TName>) {
  if (params.control) {
    return (
      <FormField {...params}>
        {(props) => (
          <Input
            {...props}
            label={content.TIME_UNIT_VALUE}
            placeholder={content.TIME_UNIT_VALUE}
            type={INPUT_TYPE.NUMBER}
            onChange={props.onChange ? handleChangeFactory(props.onChange) : undefined}
            value={isNil(props.value) ? '' : props.value.toString()}
          />
        )}
      </FormField>
    );
  }

  return (
    <FormField<TFieldValues> {...params}>
      {(props) => (
        <Input
          {...props}
          label={content.TIME_UNIT_VALUE}
          placeholder={content.TIME_UNIT_VALUE}
          type={INPUT_TYPE.NUMBER}
          onChange={props.onChange ? handleChangeFactory(props.onChange) : undefined}
          value={isNil(props.value) ? '' : props.value.toString()}
        />
      )}
    </FormField>
  );
}
