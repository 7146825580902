import { TLocationDetails } from '@marlin/asset/data-access/location';
import {
  DeleteLocationModalBody,
  DeleteLocationModalFooter,
  DeleteLocationModalTitle,
} from '@marlin/asset/ui/delete-location-modal';
import { PageHeader } from '@marlin/shared/ui-page';
import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import { PERMISSIONS, Restricted } from '@marlin/shared/utils-permission';
import { Button } from '@mui/material';
import { useCallback, useContext, useMemo } from 'react';

import { content } from '../../content';
import { useNavigation } from '../../use-navigation.hook';
import { useRouter } from '../../use-router.hook';
import { useStyles } from './header.component.styles';

type TLocationDetailsHeaderProps = {
  location: TLocationDetails;
};

const LocationDetailsHeader = ({ location }: TLocationDetailsHeaderProps) => {
  const { classes } = useStyles();
  const { modalDispatch } = useContext(ModalContext);
  const { goToLocationsHubPage, goToWithPrevious } = useRouter();

  const handleDelete = useCallback(() => {
    const canDelete = !location.isParent && location.deviceCount === 0 && location.equipmentCount === 0;
    const reason = !canDelete ? (location.isParent ? 'parent' : 'assets') : undefined;
    modalDispatch({
      type: MODAL_ACTION_TYPE.SHOW,
      payload: {
        title: <DeleteLocationModalTitle canDelete={canDelete} />,
        body: (
          <DeleteLocationModalBody
            assetId={location.id}
            deleteAssetName={location.name}
            canDelete={canDelete}
            reason={reason}
          />
        ),
        footer: (
          <DeleteLocationModalFooter
            assetId={location.id}
            handleAfterDelete={goToLocationsHubPage}
            canDelete={canDelete}
          />
        ),
      },
    });
  }, [
    location.isParent,
    location.deviceCount,
    location.equipmentCount,
    location.id,
    location.name,
    modalDispatch,
    goToLocationsHubPage,
  ]);

  const handleOpenModal = useCallback(() => handleDelete(), [handleDelete]);
  const { getLocationEditPageLink } = useNavigation();
  const detailEditLink = useMemo(() => getLocationEditPageLink(location.id), [getLocationEditPageLink, location.id]);
  const handleEditButtonClick = useCallback(() => goToWithPrevious(detailEditLink), [detailEditLink, goToWithPrevious]);

  return (
    <PageHeader
      prefix="location-details"
      title={location.name}
      subtitle={location.description}
      actions={
        <>
          <div className={classes.editDeleteButtonsWrapper}>
            <Restricted to={PERMISSIONS.DELETE_LOCATION}>
              <Button className={classes.editDeleteButton} onClick={handleOpenModal}>
                {content.DELETE_BUTTON}
              </Button>
            </Restricted>

            <Restricted to={PERMISSIONS.EDIT_LOCATION}>
              <Button className={classes.editDeleteButton} variant="outlined" onClick={handleEditButtonClick}>
                {content.EDIT_BUTTON}
              </Button>
            </Restricted>
          </div>
        </>
      }
    />
  );
};

export { LocationDetailsHeader };
