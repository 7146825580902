import { z } from 'zod';

export enum PORTFOLIO_ROLE {
  MANAGER = 'Manager',
  VIEWER = 'Viewer',
  DELETED = 'Deleted',
}

const orgRoleSchema = z.union([z.literal('Support'), z.literal('Admin'), z.literal('Viewer'), z.literal('Deleted')]);

const OrganizationSchema = z.object({
  portfolioId: z.string(),
  organizationId: z.string(),
  name: z.string(),
  role: orgRoleSchema,
});

export type TOrganizationsSchema = z.infer<typeof OrganizationSchema>;

export type TPortfolioRole = `${PORTFOLIO_ROLE}`;

export const PortfolioRoleSchema = z.union([z.literal(PORTFOLIO_ROLE.MANAGER), z.literal(PORTFOLIO_ROLE.VIEWER)]);
