export const content = {
  FILTERS_SAVE_BUTTON: 'SAVE',
  FILTERS_CLEAR_ALL_BUTTON: 'CLEAR ALL',
  FILTERED_RESULTS: 'Filtered Results',
  SORTING_LABEL: 'Sort By',
  SORTING_EMPTY: ' ',
  LABEL_ASCENDING: 'Ascending',
  LABEL_DESCENDING: 'Descending',
  HIDE_FILTERS_BUTTON: 'Hide filters',
};
