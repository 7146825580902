import { TWebhookStatusAction, useChangeWebhookStatus } from '@marlin/account-data-access-webhook';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { AxiosError } from 'axios';
import { ChangeEvent, useCallback } from 'react';

import { content } from './content';

interface IWebhookToggleProps {
  webhookId: string;
  isSuspended: boolean;
  isEnabled: boolean;
}

interface IWebhookToggle {
  handleSwitchChange: (event: ChangeEvent<HTMLInputElement>) => Promise<unknown>;
  isChangingStatus: boolean;
}

const successMessages: Record<TWebhookStatusAction, string> = {
  enable: content.WEBHOOK_TOGGLE.ENABLED_SUCCESS_MESSAGE,
  disable: content.WEBHOOK_TOGGLE.DISABLED_SUCCESS_MESSAGE,
  unsuspend: content.WEBHOOK_TOGGLE.UNSUSPENDED_SUCCESS_MESSAGE,
};

const errorMessages: Record<TWebhookStatusAction, string> = {
  enable: content.WEBHOOK_TOGGLE.ENABLED_ERROR_MESSAGE,
  disable: content.WEBHOOK_TOGGLE.DISABLED_ERROR_MESSAGE,
  unsuspend: content.WEBHOOK_TOGGLE.UNSUSPENDED_ERROR_MESSAGE,
};

export const useWebhookToggle = ({ webhookId, isSuspended, isEnabled }: IWebhookToggleProps): IWebhookToggle => {
  const { mutateAsync: changeWebhookStatus, isLoading: isChangingStatus } = useChangeWebhookStatus();
  const { enqueueSnackbar } = useSnackbar();

  const getWebhookStatusAction = useCallback((checked: boolean, isSuspended: boolean): TWebhookStatusAction => {
    if (isSuspended) {
      return 'unsuspend';
    }

    return checked ? 'enable' : 'disable';
  }, []);

  const handleSwitchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const action: TWebhookStatusAction = getWebhookStatusAction(event.target.checked, isSuspended);

      return changeWebhookStatus({ action, webhookId })
        .then(() => {
          enqueueSnackbar(successMessages[action], {
            variant: 'success',
            preventDuplicate: true,
          });
        })
        .catch((error: AxiosError<{ errorMessage?: string }>) => {
          enqueueSnackbar(error.response?.data.errorMessage ?? errorMessages[action], {
            variant: 'error',
            preventDuplicate: true,
          });
        });
    },
    [changeWebhookStatus, enqueueSnackbar, getWebhookStatusAction, isSuspended, webhookId]
  );

  return { handleSwitchChange, isChangingStatus };
};
