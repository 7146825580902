import { MarlinTheme } from '@marlin/shared/theme';
import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { IPinnedDevice } from '../types';
import { PinnedDeviceCard } from './pinned-device-card.component';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    marginTop: 8,
  },
}));

interface IPinnedDeviceProps {
  devices: IPinnedDevice[];
  onUnpin: (id: string) => void;
}

export function PinnedDeviceList({ devices, onUnpin }: IPinnedDeviceProps) {
  const { classes } = useStyles();
  return (
    <Grid container spacing={3} className={classes.wrapper}>
      {devices.map((device) => (
        <PinnedDeviceCard key={device.id} onUnpin={onUnpin} pinnedDevice={device} />
      ))}
    </Grid>
  );
}
