import { useChartingColors } from '@marlin/asset/ui/charts';
import { IChartSeries, RANGE_FILTER, getSelectionTimeAxis, parseToChartData } from '@marlin/shared/utils-chart';
import { TAnalyticsTelemetryResponse } from '@marlin/shared/utils-format-reading';
import { useMemo } from 'react';

interface ITranslation {
  name: string;
  label?: string;
}

interface IUseChartDataProps {
  datapointsGroupMetadata: ITranslation[] | undefined;
  rangeFilter: RANGE_FILTER;
  telemetryData: TAnalyticsTelemetryResponse | undefined;
  datapointNames: Array<string>;
}

export const useConverterChartData = ({
  datapointsGroupMetadata,
  rangeFilter,
  telemetryData,
  datapointNames,
}: IUseChartDataProps) => {
  const {
    timeRange: [minSelection, maxSelection],
  } = getSelectionTimeAxis({
    rangeFilter: rangeFilter as RANGE_FILTER,
  });

  const convertedDatapoints = useMemo(
    () => parseToChartData(telemetryData?.telemetryData),
    [telemetryData?.telemetryData]
  );

  const colors = useChartingColors();

  const chartData = useMemo<IChartSeries[]>(() => {
    return datapointNames.map((datapointName, idx) => {
      const datapointMetadata = datapointsGroupMetadata?.find(
        ({ name }) => name.toLowerCase() === datapointName.toLowerCase()
      );
      const datapointItem = convertedDatapoints.find(
        ({ datapointName: convertedDatapointName }) =>
          convertedDatapointName.toLowerCase() === datapointName.toLowerCase()
      );

      const emptyValues = [
        {
          x: new Date().valueOf(),
          y: null,
        },
      ];

      return {
        name: datapointMetadata?.label ?? '',
        data: datapointItem?.values || emptyValues,
        uom: datapointItem?.unitOfMeasure || null,
        color: colors[idx],
        type: 'line',
        id: datapointName,
      };
    });
  }, [colors, convertedDatapoints, datapointNames, datapointsGroupMetadata]);

  return {
    chartData,
    to: maxSelection,
    from: minSelection,
  };
};
