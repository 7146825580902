import { DateSchema } from '@marlin/shared/utils-common-date';
import { ApiUnitOfMeasures, TransformApiUnitOfMeasuresToDevice } from '@marlin/shared/utils-format-reading';
import { DeviceTypeSchemaParser } from '@marlin/system-map/shared/data-access-schemas';
import { z } from 'zod';

import { mapReading } from './flow-map.utils';
import { NodeAlertSchema } from './node-alerts.model.schema';

const LastReadingValueSchema = z
  .object({
    isDefault: z.boolean().nullish(),
    value: z.string(),
    name: z.string(),
    unitOfMeasure: ApiUnitOfMeasures.nullish()
      .or(z.string())
      .transform((unitOfMeasureResponse) => {
        const unitOfMeasure = TransformApiUnitOfMeasuresToDevice.safeParse(unitOfMeasureResponse);

        return unitOfMeasure.success ? unitOfMeasure.data : undefined;
      }),
    timestamp: DateSchema.nullish(),
  })
  .transform(({ isDefault, value, name, unitOfMeasure, timestamp }) => {
    return {
      isDefault,
      value,
      name,
      unitOfMeasure,
      lastReadingTime: timestamp,
    };
  });

export const systemMapUpdateDataSchema = z
  .object({
    lastReadingValue: z.number().nullish(),
    lastReadingTime: DateSchema.nullish(),
    deviceType: DeviceTypeSchemaParser,
    alerts: z.array(NodeAlertSchema).nullish(),
    datapoints: z.array(LastReadingValueSchema),
  })
  .transform(({ lastReadingValue, deviceType, lastReadingTime, alerts, datapoints }) => {
    const reading = mapReading(lastReadingValue, lastReadingTime, deviceType);

    return {
      lastReading: lastReadingValue,
      lastReadingTime: lastReadingTime,
      reading,
      alerts,
      lastReadingValues: datapoints,
    };
  });

export const systemMapUpdateSchema = z.object({
  data: systemMapUpdateDataSchema,
  nodeId: z.string(),
});

export const systemMapUpdatesSchema = z.object({
  nodes: z.array(systemMapUpdateSchema),
});

export type TSystemMapUpdate = z.infer<typeof systemMapUpdateSchema>;
export type TSystemMapUpdates = z.infer<typeof systemMapUpdatesSchema>;
