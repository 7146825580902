import { ChartHeader, useHeader } from '@marlin/asset/ui/charts';

import { TChartType } from '../types';

interface IHeaderProps {
  chartType: TChartType | null;
  title: string;
  hasNoCharts?: boolean;
}

export const Header = ({ chartType, title, hasNoCharts }: IHeaderProps) => {
  const {
    range,
    handleRefresh,
    onRangeFilterChange,
    onCustomDurationChange,
    onAveragingFunctionFilterChange,
    averagingFunctionFilter,
  } = useHeader();

  return (
    <ChartHeader
      range={range}
      title={title}
      chartType={chartType}
      handleRefresh={handleRefresh}
      onRangeFilterChange={onRangeFilterChange}
      hasNoCharts={hasNoCharts}
      onCustomDurationChange={onCustomDurationChange}
      onAveragingFunctionFilterChange={onAveragingFunctionFilterChange}
      averagingFunctionFilter={averagingFunctionFilter}
    />
  );
};
