import { useChartOptionsV2 } from '@marlin/asset/shared/ui/chart-options';
import { IRequestedTelemetry, useDataPoints } from '@marlin/asset/ui/charts';
import { usePeriodState } from '@marlin/shared/ui/chart';
import { AVERAGING_FUNCTION_FILTER } from '@marlin/shared/utils-chart';
import { dateAdapter } from '@marlin/shared/utils-common-date';
import { useCallback, useMemo } from 'react';

import { useAccumulatedVolumesChartData } from './use-accumulated-volumes-chart-data.hook';

interface IAccumulatedVolumesProps {
  requestedTelemetry: IRequestedTelemetry[];
  isFullScreen: boolean;
}

export const useAccumulatedVolumes = ({ requestedTelemetry, isFullScreen }: IAccumulatedVolumesProps) => {
  const {
    selectedPeriod,
    handleChangePeriod,
    range,
    handleCloseDurationModal,
    durationModalOpened,
    handleCustomRangeSet,
    rangeFilter,
    rangeFilterState,
  } = usePeriodState();

  const { activeRequestedTelemetry, onActiveDatapointsChange, clearAllActiveDatapoints, isDatapointActive } =
    useDataPoints(requestedTelemetry);

  const { chartData, isFetching, isLoading, min, max, handleZoomChange, totalVolume, refetch } =
    useAccumulatedVolumesChartData({
      requestedTelemetry,
      activeRequestedTelemetry,
      from: range.from,
      to: range.to,
      rangeFilter: rangeFilterState,
      averagingFunctionFilter: AVERAGING_FUNCTION_FILTER.SUM,
      chartDisplayType: 'bar',
      chartType: 'flow',
    });

  const handleZoom = useCallback(
    (zoom?: { min: number; max: number } | undefined) => {
      handleZoomChange(zoom);
      handleCustomRangeSet({ fromDate: dateAdapter.date(zoom?.min), toDate: dateAdapter.date(zoom?.max) });
    },
    [handleZoomChange, handleCustomRangeSet]
  );

  const { from, to } = useMemo(
    () => ({
      from: chartData[0]?.data[0].x ?? range.from?.toDate().getTime(),
      to: chartData[0]?.data[chartData[0].data.length - 1].x ?? range.to?.toDate().getTime(),
    }),
    [chartData, range.from, range.to]
  );

  const { layout, config, handleRelayout, handleHover, handleUnhover, tooltip, isEmpty } = useChartOptionsV2({
    max,
    min,
    chartData,
    rangeFilter: rangeFilter,
    from,
    to,
    handleZoomChange: handleZoom,
    isFullScreen,
    chartType: 'flow',
    chartId: 'chart',
    isZoomed: true,
    chartDisplayType: 'bar',
  });

  return {
    layout: {
      ...layout,
      dragmode: false as const,
      yaxis: {
        ...layout.yaxis,
        fixedrange: false,
        range: undefined,
      },
    },
    config: {
      ...config,
      displayModeBar: false,
    },
    handleRelayout,
    handleHover,
    handleUnhover,
    tooltip,
    isEmpty,
    handleCloseDurationModal,
    durationModalOpened,
    handleCustomRangeSet,
    isFetching,
    isLoading,
    refetch,
    onActiveDatapointsChange,
    clearAllActiveDatapoints,
    isDatapointActive,
    selectedPeriod,
    handleChangePeriod,
    totalVolume,
    range,
    from,
    to,
    chartData,
  };
};
