import { SECONDS_IN, createDateString, getTimeFromNow } from '@marlin/shared/utils-common-date';
import { datapointToUom, parseDisplayedValue } from '@marlin/shared/utils-format-reading';
import { TLastReadingValue, TSensor } from '@marlin/system-map/data-access/system-map';
import { useMemo } from 'react';

import { content } from '../../../content';
import { TStatus } from '../types';
import { useRegisteredEquipmentContext } from './use-registered-equipment-context';

interface IUseSensorProps {
  sensor: TSensor;
  lastReadingValues: TLastReadingValue[];
}

interface IUseSensor {
  status: TStatus;
  tooltipMessage: string | null;
  lastReadingValue: TLastReadingValue | undefined;
  statusValue: TLastReadingValue | undefined;
  value: string;
  readingStatus: TStatus;
}

export const useSensor = ({ sensor, lastReadingValues }: IUseSensorProps): IUseSensor => {
  const { getStatusByDisplayDataPointNames, getAlertMessagesByDataPointNames } = useRegisteredEquipmentContext();

  const status: TStatus = useMemo(
    () => getStatusByDisplayDataPointNames([sensor.displayDataPointName || '']),
    [getStatusByDisplayDataPointNames, sensor]
  );

  const tooltipMessage: string | null = useMemo(() => {
    const messages = getAlertMessagesByDataPointNames([sensor.displayDataPointName || '']);

    return messages.length ? messages.join('\n') : null;
  }, [getAlertMessagesByDataPointNames, sensor.displayDataPointName]);

  const lastReadingValue: TLastReadingValue | undefined = useMemo(
    () => lastReadingValues.find((value) => value.name.toLowerCase() === sensor.displayDataPointName?.toLowerCase()),
    [lastReadingValues, sensor.displayDataPointName]
  );

  const statusValue: TLastReadingValue | undefined = useMemo(
    () => lastReadingValues.find((value) => value.name.toLowerCase() === sensor.statusDataPointName?.toLowerCase()),
    [lastReadingValues, sensor.statusDataPointName]
  );

  const isTelemetryOlderThanEightHours: boolean = useMemo(() => {
    if (!lastReadingValue?.lastReadingTime) {
      return false;
    }

    const timeSinceLastReading = getTimeFromNow(createDateString(lastReadingValue?.lastReadingTime || ''), 'seconds');
    return (timeSinceLastReading || 0) > 8 * SECONDS_IN.HOUR;
  }, [lastReadingValue?.lastReadingTime]);

  const value: string = useMemo(() => {
    if (isTelemetryOlderThanEightHours || !lastReadingValue) {
      return content.EQUIPMENT.NOT_APPLICABLE;
    }

    const uom = datapointToUom(sensor.displayDataPointName, lastReadingValue.unitOfMeasure);

    return parseDisplayedValue(lastReadingValue.value, uom);
  }, [isTelemetryOlderThanEightHours, lastReadingValue, sensor.displayDataPointName]);

  const readingStatus: TStatus = useMemo(() => {
    if (!lastReadingValue) {
      return 'notAvailable';
    }

    if (isTelemetryOlderThanEightHours) {
      return 'error';
    }

    return status;
  }, [isTelemetryOlderThanEightHours, lastReadingValue, status]);

  return useMemo(
    () => ({
      status,
      tooltipMessage,
      lastReadingValue,
      statusValue,
      value,
      readingStatus,
    }),
    [lastReadingValue, readingStatus, status, statusValue, tooltipMessage, value]
  );
};
