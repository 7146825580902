import { TEquipmentDashboardSectionMetadataItem } from '@marlin/asset/shared/equipment-config';
import { DatapointDisplayValue } from '@marlin/asset/shared/ui/datapoint-display';
import { MarlinTheme } from '@marlin/shared/theme';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { useDatapointsContext } from '../../../../../shared/context/datapoints.context';
import { useEquipmentAlertsContext } from '../../../../../shared/context/equipment-alerts.context';
import { getDatapointWithDependencies } from '../../../../../shared/utils/get-datapoint-with-dependencies.utils';
import { content } from '../../../../content';

type TDatapointBoxProps = {
  label: string;
  name: string;
  dependencies?: TEquipmentDashboardSectionMetadataItem['dependencies'];
};

type TStyles = {
  emptyValue: boolean;
};

const useStyles = makeStyles<TStyles>()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.typography.pxToRem(8),
  },
  label: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const DatapointInfo = ({ label, name, dependencies }: TDatapointBoxProps) => {
  const { getDatapoint } = useDatapointsContext();
  const { getAlertsForDatapoint } = useEquipmentAlertsContext();
  const datapoint = getDatapoint(name);
  const datapointAlerts = getAlertsForDatapoint(name);
  const { classes } = useStyles({ emptyValue: !datapoint?.value });
  const controlState = datapointAlerts?.length ? 'error' : datapoint?.controlState;
  const datapointWithDependencies = getDatapointWithDependencies({ dependencies, datapoint, getDatapoint });

  if (dependencies?.visibility && !datapointWithDependencies) {
    return null;
  }

  return (
    <div className={classes.container} data-testid={label}>
      <DatapointDisplayValue
        value={datapointWithDependencies?.displayedValue ?? content.EMPTY_DATAPOINT_VALUE}
        controlState={controlState}
        alerts={datapointAlerts}
      />
      <Typography variant="subtitle2" className={classes.label}>
        {label}
      </Typography>
    </div>
  );
};

export default DatapointInfo;
