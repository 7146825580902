import { MarlinTheme } from '@marlin/shared/theme';
import { alpha } from '@mui/system';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },

  filterWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },

  filters: {
    marginLeft: theme.typography.pxToRem(16),
    marginTop: theme.typography.pxToRem(16),
    marginBottom: theme.typography.pxToRem(16),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  filterFields: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },

  searchFilter: {
    width: theme.typography.pxToRem(308),
    marginRight: theme.typography.pxToRem(16),
  },

  parentLocationFilter: {
    width: theme.typography.pxToRem(324),
  },

  clearAll: {
    marginRight: theme.typography.pxToRem(16),
  },

  parentLocationChipsWrapper: {
    marginLeft: theme.typography.pxToRem(16),
    marginBottom: theme.typography.pxToRem(16),
  },

  parentLocationChip: {
    marginRight: theme.typography.pxToRem(8),
    background: alpha(theme.palette.secondary.main, 0.08),
  },

  dataGridWrapper: {
    height: theme.typography.pxToRem(600),
    width: '100%',
  },

  dataGrid: {
    borderRadius: 0,
    borderBottom: 0,
    borderLeft: 0,
    borderRight: 0,
  },
}));
