import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { Typography } from '@mui/material';
import { FC, PropsWithChildren, ReactNode } from 'react';

import { useStyles } from './list-title-component.styles';

export interface IUserListProps {
  tooltipContent?: ReactNode;
}

export const ListTitle: FC<PropsWithChildren<IUserListProps>> = ({ tooltipContent, children }) => {
  const { classes } = useStyles();

  return (
    <div>
      <Typography variant="h5" className={classes.title}>
        {children}
        {tooltipContent && (
          <span className={classes.tooltip}>
            <Tooltip text={tooltipContent} />
          </span>
        )}
      </Typography>
    </div>
  );
};
