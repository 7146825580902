import { getTimeRange } from '@marlin/shared/utils-chart';
import { TDateString, dateAdapter } from '@marlin/shared/utils-common-date';
import { useMemo } from 'react';

import { RANGE_FILTER } from '../../constant';
import { IChartPoint } from '../../types';
import { EXTENDED_RANGE_FILTER } from './chart-constants';

export interface IUseTimeAxis {
  data: IChartPoint[];
  rangeFilter: RANGE_FILTER | EXTENDED_RANGE_FILTER;
}

export interface ITimeAxis {
  timeRange: number[];
}

const getTimeStamp = (date: TDateString): number => dateAdapter.date(date)?.toDate().getTime() ?? new Date().getTime();

// TODO: to remove last tick - APEX OFFSET bug
const lastTickOffset = 1000;

export const useTimeAxis = ({ data, rangeFilter }: IUseTimeAxis): ITimeAxis => {
  const timeRange: TDateString[] = useMemo(() => {
    const lastTick = data[data.length - 1]?.timestamp;
    const currentDate = dateAdapter.date()?.toDate().getTime();
    const lastTimestamp = lastTick && currentDate && lastTick > currentDate ? lastTick : currentDate;

    return getTimeRange(rangeFilter as RANGE_FILTER, lastTimestamp ?? new Date().getTime());
  }, [data, rangeFilter]);

  const timeRangeTimestamps = useMemo(
    () => [getTimeStamp(timeRange[0]), getTimeStamp(timeRange[1]) - lastTickOffset],
    [timeRange]
  );

  return {
    timeRange: timeRangeTimestamps,
  };
};
