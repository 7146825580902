import { MarlinTheme } from '@marlin/shared/theme';
import { EmailControl, FirstNameControl, JobTitleControl, LastNameControl, RoleControl } from '@marlin/shared/ui-form';
import { IFormInputProps } from '@marlin/shared/ui-form-common';
import { Button, FormHelperText, Grid } from '@mui/material';
import { FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.typography.pxToRem(32),
  },

  errorTextContainer: {
    marginTop: theme.typography.pxToRem(12),
    marginBottom: theme.typography.pxToRem(12),
  },

  btnItem: {
    width: '50%',
  },
}));

interface IInviteNewUserFormProps {
  form: UseFormReturn<IFormInputProps, object>;
  onSubmit: SubmitHandler<IFormInputProps>;
  onCancel: () => void;
  resetError: () => void;
  errorMessage: string;
}

export const InviteNewUserForm = ({ form, onSubmit, onCancel, resetError, errorMessage }: IInviteNewUserFormProps) => {
  const { classes } = useStyles();
  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} onChange={resetError}>
        <div className={classes.formWrapper}>
          <FirstNameControl<IFormInputProps> fieldName="firstName" />
          <LastNameControl<IFormInputProps> fieldName="lastName" />
          <EmailControl<IFormInputProps> fieldName="email" />
          <JobTitleControl<IFormInputProps> fieldName="title" />
          <RoleControl<IFormInputProps> fieldName="role" />
          <div className={classes.errorTextContainer}>
            {errorMessage.length > 0 && <FormHelperText error>{errorMessage}</FormHelperText>}
          </div>
        </div>

        <Grid container spacing={2}>
          <Grid className={classes.btnItem} item>
            <Button fullWidth variant="outlined" onClick={onCancel}>
              {content.CANCEL}
            </Button>
          </Grid>
          <Grid className={classes.btnItem} item>
            <Button
              fullWidth
              variant="contained"
              type="submit"
              data-testid="invite-user-submit"
              disabled={!form.formState.isValid || form.formState.isSubmitting}
            >
              {content.INVITE}
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
