import { MarlinTheme } from '@marlin/shared/theme';
import { Link } from '@marlin/shared/ui-page';
import { TAB_VALUES, routes } from '@marlin/shared/utils-routes';
import { TEquipment } from '@marlin/shared/utils/zod';
import DeviceHubRoundedIcon from '@mui/icons-material/DeviceHubRounded';
import TreeItem from '@mui/lab/TreeItem';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { useStyles as useHierarchyLevelStyles } from './hierarchy-level-label.component';

interface IHierarchyLevelProps {
  equipment: TEquipment;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  treeItem: {
    paddingTop: theme.typography.pxToRem(8),
    paddingBottom: theme.typography.pxToRem(8),
  },
}));

export const EquipmentLevelLabel = ({ equipment }: IHierarchyLevelProps) => {
  const { classes } = useHierarchyLevelStyles();

  return (
    <div className={classes.wrapper}>
      <DeviceHubRoundedIcon className={classes.icon} />
      <Link className={classes.link} to={routes.equipments.details.url(equipment.id, TAB_VALUES.HierarchyView)}>
        {equipment.name}
      </Link>
    </div>
  );
};

export const EquipmentHierarchyLevel = ({ equipment }: IHierarchyLevelProps) => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  return (
    <TreeItem
      classes={{ content: classes.treeItem }}
      nodeId={equipment.id}
      label={<EquipmentLevelLabel equipment={equipment} />}
      onClick={() => navigate(routes.equipments.details.url(equipment.id, TAB_VALUES.HierarchyView))}
    />
  );
};
