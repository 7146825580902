import { queryClient } from '@marlin/shared/utils/react-query';

import { queryKey } from '../queries/query-key.enum';

interface IDeviceDetails {
  pinned: boolean;
}

export const toggleDeviceDetailsPinnedCache = (id: string) =>
  queryClient.setQueriesData<IDeviceDetails | undefined>({ queryKey: queryKey.DEVICE(id) }, (data?: IDeviceDetails) => {
    if (!data) {
      return data;
    }

    return { ...data, pinned: !data.pinned };
  });
