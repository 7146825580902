import { LocationSearchWrapper } from '@marlin/alert/data-access/automated-action';
import { FilterChips } from '@marlin/asset/shared/ui/filter-chips';
import { FormField, InfiniteAutocompleteControl, Input, useSubmitOnChange } from '@marlin/shared/ui-form-common';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Button, IconButton } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import { content } from '../../content';
import { ILocationHubFilters } from '../../shared/types';
import { IOption } from '../../shared/types';
import { useStyles } from '../locations-hub.component.styles';

const defaultFilters: ILocationHubFilters = {
  locations: [],
  search: '',
};

interface IFiltersProps {
  onFiltersChange: (data: ILocationHubFilters) => void;
  parentLocationsList: { id: string; name: string }[];
}

export const Filters = ({ onFiltersChange, parentLocationsList }: IFiltersProps) => {
  const { classes } = useStyles();

  const form = useForm<ILocationHubFilters>({
    defaultValues: defaultFilters,
  });
  const { submit, resetForm } = useSubmitOnChange<ILocationHubFilters>(form, onFiltersChange);

  return (
    <FormProvider {...form}>
      <form onSubmit={submit} data-testid="filters-form">
        <div className={classes.filters}>
          <div className={classes.filterFields}>
            <FormField<ILocationHubFilters> fieldName="search">
              {(props) => (
                <Input
                  className={classes.searchFilter}
                  label={content.SEARCH_LOCATION}
                  externalEndAdornment={{
                    endAdornment: (
                      <IconButton>
                        <SearchRoundedIcon color="action" />
                      </IconButton>
                    ),
                  }}
                  {...props}
                />
              )}
            </FormField>
            <LocationSearchWrapper showUsedByLocations>
              {(props) => (
                <InfiniteAutocompleteControl<ILocationHubFilters, IOption>
                  fieldName="locations"
                  testId="parent-location-multi-select"
                  label="Parent Location"
                  className={classes.parentLocationFilter}
                  {...props}
                />
              )}
            </LocationSearchWrapper>
          </div>
          <div>
            <Button className={classes.clearAll} variant="outlined" onClick={() => resetForm()} data-testid="clearAll">
              {content.CLEAR_ALL}
            </Button>
          </div>
        </div>
        <FilterChips />
      </form>
    </FormProvider>
  );
};
