import { THomeLocationSchema } from '@marlin/asset/data-access/home-dashboard';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { Button } from '@mui/material';
import { useCallback } from 'react';

import { content } from '../../content';
import { useStyles } from './card-no-devices.component.styles';

type THomeCardnoSensorsProps = {
  location: THomeLocationSchema;
};

const sensorsHubPage = routes.sensors.list.url();

const locationDetailsPage = (locationId: string) => routes.locations.details.url(locationId);

export const HomeCardNoSensors = ({ location }: THomeCardnoSensorsProps) => {
  const { classes } = useStyles();
  const router = useRouter();
  const routeToLocationDetailsPage = useCallback(() => locationDetailsPage(location.locationId), [location.locationId]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.noSensorsWrapper}>
        <div>
          <div className={classes.noSensorsLine}>
            {content.NO_SENSORS_LINE} {location.locationName}.
          </div>
        </div>
        <div>
          <Button className={classes.noSensorsCta} variant="contained" onClick={() => router.goTo(sensorsHubPage)}>
            {content.NO_SENSORS_CTA}
          </Button>
          <Button
            className={classes.manageLocationButton}
            variant="outlined"
            onClick={() => router.goTo(routeToLocationDetailsPage())}
          >
            {content.MANAGE_LOCATION}
          </Button>
        </div>
      </div>
    </div>
  );
};
