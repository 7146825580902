export const sankeyStyle = { alignSelf: 'center' };

export const sankeyLayout = {
  font: { size: 16, family: 'Inter' },
  margin: {
    t: 40,
    b: 140,
    l: 50,
    r: 280,
  },
};

export const sankeyConfig = { displaylogo: false, displayModeBar: false };
