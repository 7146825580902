import { MarlinTheme } from '@marlin/shared/theme';
import { useAuth } from '@marlin/shared/utils-auth';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  logoutButton: {
    display: 'flex',
    height: theme.typography.pxToRem(46),
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },

  logoutIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: theme.typography.pxToRem(24),
    height: theme.typography.pxToRem(24),
    marginRight: theme.typography.pxToRem(32),
    marginLeft: theme.typography.pxToRem(18),
  },
  logoutText: {
    whiteSpace: 'nowrap',
  },
}));

interface ILogoutProps {
  isCollapsed: boolean;
  desktopNavbarLocked: boolean;
}

export const Logout = ({ isCollapsed, desktopNavbarLocked }: ILogoutProps) => {
  const { classes } = useStyles();
  const { logOut } = useAuth();

  return (
    <div data-testid="nav-link--logout" onClick={() => logOut()} className={classes.logoutButton}>
      <div className={classes.logoutIcon}>
        <LogoutRoundedIcon color="action" />
      </div>
      <div className={classes.logoutText}>
        {desktopNavbarLocked ? content.SIDE_NAV_SIGN_OUT : !isCollapsed && content.SIDE_NAV_SIGN_OUT}
      </div>
    </div>
  );
};
