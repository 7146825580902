import { useSubmitOnChange } from '@marlin/shared/ui-form-common';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { useCallback } from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';

import { IFilterParams } from './types';

export const useFiltering = (
  initialParams: IFilterParams,
  onChangeParams: (params: IFilterParams) => void
): {
  clearAllFilters: () => void;
  submit: () => void;
  form: UseFormReturn<IFilterParams>;
} => {
  const { goTo } = useRouter();

  const navigateToNoFilters = useCallback(() => {
    goTo(routes.alerts.list.url());
  }, [goTo]);
  const form = useForm<IFilterParams>({
    defaultValues: initialParams,
  });

  const onFiltersChange = (data: IFilterParams) => {
    onChangeParams({
      search: data.search,
      locationIds: data.locationIds,
      equipmentIds: data.equipmentIds,
      deviceIds: data.deviceIds,
      deviceTypes: data.deviceTypes,
      criticality: {
        low: data.criticality.low,
        high: data.criticality.high,
      },
      ruleCategories: data.ruleCategories,
    });
  };

  const { submit, resetForm } = useSubmitOnChange<IFilterParams>(form, onFiltersChange);

  const clearAllFilters = useCallback(() => {
    onChangeParams({
      deviceIds: [],
      equipmentIds: [],
      locationIds: [],
      deviceTypes: [],
      criticality: {
        low: false,
        high: false,
      },
      search: undefined,
      ruleCategories: [],
    });
    resetForm({
      deviceIds: [],
      equipmentIds: [],
      locationIds: [],
      deviceTypes: [],
      criticality: {
        low: false,
        high: false,
      },
      search: undefined,
      ruleCategories: [],
    });
    navigateToNoFilters();
  }, [navigateToNoFilters, onChangeParams, resetForm]);

  return {
    clearAllFilters,
    submit,
    form,
  };
};
