import { MarlinTheme } from '@marlin/shared/theme';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    marginTop: theme.typography.pxToRem(5),
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
  },

  icon: {
    height: theme.typography.pxToRem(16),
    '& > svg': {
      color: theme.palette.action.active,
    },
    marginRight: theme.typography.pxToRem(3),
  },

  label: {
    fontSize: theme.typography.pxToRem(14),
    marginRight: theme.typography.pxToRem(3),
    marginTop: theme.typography.pxToRem(6),
  },
}));

interface IAlertIntervalSection {
  snoozeIntervalInMinutes: number;
}

export const AlertIntervalSection = ({ snoozeIntervalInMinutes }: IAlertIntervalSection): JSX.Element => {
  const { classes } = useStyles();

  return (
    <div className={classes.container} data-testid="snooze-interval-section">
      <span className={classes.icon}>
        <NotificationsRoundedIcon />
      </span>
      <span className={classes.label} data-testid="snooze-interval-section-label">
        {content.ALERT_INTERVAL(String(snoozeIntervalInMinutes))}
      </span>
    </div>
  );
};
