import { Tooltip } from '@marlin/shared/ui-common-tooltip';

import { HotSosContent } from './hot-sos-content.component';
import { ALERT_STATUS, IHotSosChannel } from './types';

interface IDetailsItemProps {
  channel?: IHotSosChannel;
}

const HotSos = ({ channel }: IDetailsItemProps): JSX.Element | null => {
  if (!channel?.status) {
    return null;
  }

  switch (channel?.status) {
    case ALERT_STATUS.FAILED: {
      return (
        <Tooltip placement="right" text={channel.errorMessage}>
          <HotSosContent status={channel.status} />
        </Tooltip>
      );
    }
    case ALERT_STATUS.SENT: {
      return <HotSosContent status={ALERT_STATUS.SENT} />;
    }
    default: {
      return null;
    }
  }
};

export { HotSos };
