import { createContext } from 'react';

import { TModalAction, TModalState, initialState } from './teaser-modal-reducer';

interface ITeaserModalContextProps {
  modalState: TModalState;
  modalDispatch: TModalAction;
}

export const TeaserModalContext = createContext<ITeaserModalContextProps>({
  modalState: initialState,
  modalDispatch: () => initialState,
});
