import { TMutationConfig, getHttpClient, queryClient } from '@marlin/shared/utils/react-query';
import { useMutation } from '@marlin/shared/utils/react-query';
import { GatewayInfoUpdateResponsePayload, TGatewayInfoUpdatePayload } from '@marlin/shared/utils/zod';
import { AxiosError } from 'axios';
import { z } from 'zod';

import { paths } from '../infrastructure/api';
import { queryKey } from '../query-key.enum';

type TUpdateGatewayType = {
  data: TGatewayInfoUpdatePayload;
  gatewayId: string;
};

export const updateRegisteredGateway = async ({ data, gatewayId }: TUpdateGatewayType) => {
  const result = await getHttpClient().put(paths.GATEWAY_UPDATE, data, {
    params: {
      gatewayId: gatewayId,
    },
  });

  return GatewayInfoUpdateResponsePayload.parse(result);
};

type TUseUpdateGatewayOptions = {
  config?: TMutationConfig<typeof updateRegisteredGateway>;
  onSuccess?: (params: { deviceId: string; configurationId: string }) => void;
  onError?: (error: AxiosError<{ errorMessage: string; status: string }>) => void;
};

export const useUpdateRegisteredGateway = ({ config, onError, onSuccess }: TUseUpdateGatewayOptions = {}) => {
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey.GATEWAY());
    },
    onError: (error: AxiosError<{ errorMessage: string; status: string }>) => {
      // eslint-disable-next-line no-console
      if (error instanceof z.ZodError) console.log(error.issues);

      onError?.(error);
    },
    mutationFn: updateRegisteredGateway,
  });
};
