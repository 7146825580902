import { environment } from '@marlin/environment';

export const content = {
  CHANGE: 'Change',
  ORGANIZATION: 'Organization',
  PORTFOLIO: 'Portfolio',
  PORTFOLIO_OVERVIEW: 'Portfolio Overview',
  PORTFOLIO_CREATE: 'Create Portfolio',
  ORGANIZATION_OVERVIEW: 'Organization Overview',
  MARLIN_SUPPORT: `${environment.app.name} Support`,
  SUPPORT: 'support',
  CREATE_ORG: 'Create New Organization',
  CREATE_ORG_TEST_ID: 'create-organization-id',
  ORGANIZATION_HAS_BEEN_CHANGED: 'Organization has been changed',
  PORTFOLIO_HAS_BEEN_CHANGED: (portfolioName?: string) =>
    portfolioName ? `Portfolio has been changed to ${portfolioName}` : 'Portfolio has been changed',
  NEXA_SYSTEM: 'Nexa System',
  NEXA_EQUIPMENT: 'Nexa Equipment',
};
