import { useLocation, useNavigate } from 'react-router-dom';

import { useNavigation } from './use-navigation.hook';

interface IState {
  [key: string]: unknown;
}

interface IUseStore {
  goTo: (url: string, state?: IState) => void;
  goToWithPrevious: (url: string) => void;
  goToLocationsHubPage: () => void;
}

export const useRouter = (): IUseStore => {
  const navigate = useNavigate();
  const location = useLocation();
  const { getLocationsHubPageLink } = useNavigation();
  const goTo = (url: string, state?: IState): void => {
    navigate(url, { state });
  };

  return {
    goTo,
    goToWithPrevious: (url) => {
      navigate(url, { state: { prevLocation: location.pathname + location.search } });
    },
    goToLocationsHubPage: () => {
      goTo(getLocationsHubPageLink());
    },
  };
};
