import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => {
  return {
    group: {
      alignItems: 'center',
    },
    selected: {
      '&.Mui-selected': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
        },
      },
    },
    root: {
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.primary.main,
      alignItems: 'center',
      textTransform: 'none',
      '&&': {
        border: '1px solid rgba(0, 111, 214, 0.50)',
      },
    },
    icon: {
      fontSize: theme.typography.pxToRem(20),
      marginRight: theme.typography.pxToRem(8),
      alignItems: 'center',
      display: 'flex',
      '& > span': {
        fontSize: theme.typography.pxToRem(20),
      },
      '& > svg': {
        height: theme.typography.pxToRem(20),
        width: theme.typography.pxToRem(20),
      },
    },
  };
});
