import { ReactFlowJsonObject } from '@xyflow/react';
import { useObservableState } from 'observable-hooks';
import { Subject } from 'rxjs';

const viewModeDiagramState$ = new Subject<ReactFlowJsonObject | undefined>();

export const useViewModeState$ = () => {
  const [diagramCache] = useObservableState<ReactFlowJsonObject | undefined>(() => viewModeDiagramState$, undefined);

  const restoreState = (restoreReactSystemMap: ({ nodes, edges, viewport }: ReactFlowJsonObject) => void) => {
    if (diagramCache) {
      restoreReactSystemMap(diagramCache);
    }
  };

  const updateState = (rfInstanceJsonObject?: ReactFlowJsonObject) => {
    viewModeDiagramState$.next(rfInstanceJsonObject);
  };

  const clearState = () => {
    viewModeDiagramState$.next(undefined);
  };

  return {
    restoreState,
    updateState,
    clearState,
  };
};
