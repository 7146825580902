import { queryClient, useMutation } from '@marlin/shared/utils/react-query';

import { createInvitationUser } from '../infrastructure/invite-user';
import { queryKey } from './query-key.enum';

export const useInviteUser = () => {
  return useMutation({
    onMutate: () => {
      queryClient.cancelQueries({ queryKey: queryKey.INVITATION_USER() });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.USER() });
    },
    mutationFn: createInvitationUser,
  });
};
