import { useObservableState } from 'observable-hooks';

import { getLiveAlerts } from '../infrastructure/equipment';
import { TEquipmentAlerts } from '../schema/equipment-alerts.schema';

export const useLiveAlerts = () => {
  const [alerts] = useObservableState(() => getLiveAlerts(), {} as TEquipmentAlerts);

  return alerts;
};
