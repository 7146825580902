import { content } from '../content';
import { TLocationType } from './location-type.schema';

export type TLocationTypeOption = {
  value: TLocationType;
  name: string;
};

export const locationTypeOptions: TLocationTypeOption[] = [
  {
    value: '',
    name: content.NOT_CLASSIFIED,
  },
  {
    value: 'MechanicalRoom',
    name: content.MECHANICAL_ROOM,
  },
  {
    value: 'GuestRoom',
    name: content.GUEST_ROOM,
  },
  {
    value: 'Laundry',
    name: content.LAUNDRY,
  },
  {
    value: 'Kitchen',
    name: content.KITCHEN,
  },
];
