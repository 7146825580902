import { getHttpClient } from '@marlin/shared/utils/react-query';

import { IRecipientList, IRecipientListResponse } from '../recipient.model';
import { paths } from './api';
import { createRecipientListDto } from './dtos/recipient.response';

export interface IRecipientsRequestBody {
  search?: string;
  page: number;
  pageSize: number;
  sorting?: {
    direction: 'Ascending' | 'Descending';
    sortBy: string;
  };
}

export const getRecipients = async (body: IRecipientsRequestBody): Promise<IRecipientList> => {
  const res = await getHttpClient().post<unknown, IRecipientListResponse>(paths.RECIPIENTS_FILTER, body);

  return createRecipientListDto(res);
};

export const deleteRecipient = async (recipientId: string): Promise<void> => {
  return getHttpClient().delete(paths.RECIPIENT, { params: { recipientId } });
};
