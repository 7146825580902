import { MouseEvent, useCallback, useState } from 'react';

export interface IUnitOption<Option extends string = string> {
  value: Option;
  label: string;
}

interface IUseUnitSelector<Option extends string = string> {
  unitOptions: IUnitOption<Option>[];
}

export const useUnitSelector = <Option extends string = string>({ unitOptions }: IUseUnitSelector<Option>) => {
  const [selectedOption, setSelectedOption] = useState<IUnitOption<Option>>(unitOptions[0]);

  const handleChange = useCallback((_: MouseEvent<HTMLElement>, option: IUnitOption<Option>) => {
    setSelectedOption(option);
  }, []);

  return {
    selectedOption,
    handleChange,
  };
};
