import { MarlinTheme } from '@marlin/shared/theme';
import { PageContainer, PageHeader } from '@marlin/shared/ui-page';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button, useMediaQuery, useTheme } from '@mui/material';
import { Navigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { UpsertLocation } from '../form/upsert-location.component';
import { content } from './content';
import { useNewLocation } from './use-new-location.hook';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  button: {
    marginLeft: theme.typography.pxToRem(16),
    marginTop: theme.typography.pxToRem(8),
  },
}));

const NewLocation = () => {
  const { classes } = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { successfullyUpdatedLocation, loadingCreatedLocation, nextRoute, onSubmit, goToNextRoute } = useNewLocation();

  if (successfullyUpdatedLocation) {
    return <Navigate to={nextRoute} />;
  }
  return (
    <PageContainer prefix="edit-location">
      <PageHeader
        icon={null}
        title={content.TITLE}
        subtitle={content.SUBTITLE}
        prefix="device-update-header"
        topActions={
          isMobile ? (
            <Button
              className={classes.button}
              startIcon={<CloseRoundedIcon />}
              variant="outlined"
              onClick={goToNextRoute}
            >
              {content.BUTTON_CANCEL}
            </Button>
          ) : undefined
        }
      />
      <UpsertLocation
        disableSubmit={loadingCreatedLocation}
        onSubmit={onSubmit}
        onCancel={goToNextRoute}
        isEdit={false}
      />
    </PageContainer>
  );
};

export { NewLocation };
