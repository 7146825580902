import { getHttpClient } from '@marlin/shared/utils/react-query';

import {
  TFlowLinkFilterParams,
  TFlowLinkListResponseSchema,
  flowLinkFilterParamsSchema,
  flowLinkListResponseDataSchema,
} from '../flow-link.model.schema';
import { paths } from './api';

export const getFlowLinks = async (queryParams: TFlowLinkFilterParams): Promise<TFlowLinkListResponseSchema> => {
  const payload = flowLinkFilterParamsSchema.parse(queryParams);
  const res = await getHttpClient().post<unknown, TFlowLinkListResponseSchema>(paths.SYSTEM_MAP_LINK_FILTER, payload);
  return flowLinkListResponseDataSchema.parse(res);
};
