import { createPipe } from 'imask';
import * as yup from 'yup';
import * as z from 'zod';

import { content } from '../content';

export const phoneValueMask = `{+1} (000) 000 0000`;

// todo remove yup
export const phoneSchema = yup
  .string()
  .required(content.PHONE_NUMBER_CONTROL_VALIDATION)
  .length(12, content.PHONE_NUMBER_CONTROL_VALIDATION)
  .matches(/^\+1[0-9]{10}$/, content.PHONE_NUMBER_CONTROL_VALIDATION);

export const phoneNumberMask = createPipe({
  mask: phoneValueMask,
});

export const phoneZodSchema = z
  .string()
  .max(12, content.PHONE_NUMBER_CONTROL_VALIDATION)
  .regex(/^\+1[0-9]{10}$/, content.PHONE_NUMBER_CONTROL_VALIDATION);
