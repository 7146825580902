import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.typography.pxToRem(8),
  },
  titleText: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightBold,
  },
}));
