import { useFilteredAlertsWithPaging } from '@marlin/alert/data-access/alert-action';
import { mapSortDirection } from '@marlin/shared/utils-sorting';
import { TDeviceMetadataResponse } from '@marlin/shared/utils/datapoint-mappers';
import { usePagination } from '@marlin/shared/utils/react-query';
import { GridSortModel } from '@mui/x-data-grid';
import { useState } from 'react';

import EventLogDetailsModal from '../details-modal/event-log-details.modal.component';
import { IFilterParams } from '../event-log.component';
import { EventLogGrid } from './event-log-grid.component';

const defaultParams = {
  initialPage: 1,
  initialPageSize: 10,
};

interface IEventLogListProps {
  filterParams: IFilterParams;
  equipmentId: string;
  isLoadingMetadata: boolean;
  metadata?: TDeviceMetadataResponse;
  setParams: React.Dispatch<React.SetStateAction<IFilterParams>>;
}

export const EventLogList = ({
  filterParams,
  equipmentId,
  metadata,
  isLoadingMetadata,
  setParams,
}: IEventLogListProps) => {
  const { page, pageSize, changePage: paginationChangePage, changePageSize } = usePagination(defaultParams);
  const [alertDetailsId, setAlertDetailsId] = useState<string | null>(null);
  const alertsQuery = useFilteredAlertsWithPaging({
    params: {
      ...filterParams,
      page,
      pageSize,
      equipmentIds: equipmentId ? [equipmentId] : [],
      locationIds: [],
      deviceIds: [],
      deviceTypes: [],
      ruleCategories: ['Health', 'Performance', 'Information'],
    },
  });

  const openDetails = (id: string) => {
    setAlertDetailsId(id);
  };

  const handleDetailsClose = () => {
    setAlertDetailsId(null);
  };

  const onSortModelChange = ([sortModel]: GridSortModel) => {
    setParams((prev) => ({
      ...prev,
      sorting: {
        direction: mapSortDirection(sortModel?.sort ?? 'desc'),
        sortBy: sortModel?.field ?? 'startTime',
      },
    }));
    paginationChangePage(1);
  };

  return (
    <>
      <EventLogGrid
        page={page}
        alerts={alertsQuery.data}
        onPageChange={paginationChangePage}
        onPageSizeChange={changePageSize}
        isLoading={alertsQuery.isFetching || isLoadingMetadata}
        openDetails={openDetails}
        metadata={metadata?.datapoints || []}
        onSortModelChange={onSortModelChange}
      />
      <EventLogDetailsModal
        isOpen={!!alertDetailsId}
        onClose={handleDetailsClose}
        alert={alertsQuery?.data?.data.find((alert) => alert.id === alertDetailsId)}
        metadata={metadata?.datapoints || []}
      />
    </>
  );
};
