import { z } from 'zod';

import { paginationSchema, sortingSchema } from './utils.schema';
import { webhookSchema } from './webhook.schema';

export const getFilteredWebhooksParamsSchema = z.object({
  search: z.string().nullish(),
  page: z.number(),
  pageSize: z.number(),
  sorting: sortingSchema.optional(),
});

export const getFilteredWebhooksRsponseSchema = z.object({
  pagination: paginationSchema,
  data: z.array(webhookSchema).nullable(),
});

export type TGetFilteredWebhooksParams = z.infer<typeof getFilteredWebhooksParamsSchema>;
export type TGetFilteredWebhooksResponse = z.infer<typeof getFilteredWebhooksRsponseSchema>;

export const defaultGetFilteredWebhooksParams: TGetFilteredWebhooksParams = {
  page: 1,
  pageSize: 10,
};
