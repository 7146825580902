import {
  AVERAGING_FUNCTION_FILTER,
  RANGE_FILTER,
  TChartType,
  useCustomPeriodModalContextStore,
} from '@marlin/shared/utils-chart';
import { SelectChangeEvent } from '@mui/material';
import { useCallback } from 'react';

import { BaseHeader } from './base-header.component';

interface IChartHeaderProps {
  range: RANGE_FILTER;
  chartType: TChartType | null;
  handleRefresh: () => void;
  title: string;
  hasNoCharts?: boolean;
  onCustomDurationChange: (option: RANGE_FILTER) => void;
  onRangeFilterChange: (event: SelectChangeEvent<RANGE_FILTER>) => void;
  onAveragingFunctionFilterChange: (event: SelectChangeEvent<AVERAGING_FUNCTION_FILTER>) => void;
  averagingFunctionFilter: AVERAGING_FUNCTION_FILTER;
}

export const ChartHeader = ({
  range,
  title,
  chartType,
  handleRefresh,
  onRangeFilterChange,
  hasNoCharts,
  onCustomDurationChange,
  onAveragingFunctionFilterChange,
  averagingFunctionFilter,
}: IChartHeaderProps) => {
  // TODO: Remove the coupling between the header and the modal
  const [open] = useCustomPeriodModalContextStore((store) => store.open);

  const handleReset = useCallback(() => {
    onRangeFilterChange({ target: { value: RANGE_FILTER.DAYS_7 } } as SelectChangeEvent<RANGE_FILTER>);
    onAveragingFunctionFilterChange({
      target: { value: AVERAGING_FUNCTION_FILTER.AVERAGE },
    } as SelectChangeEvent<AVERAGING_FUNCTION_FILTER>);
  }, [onAveragingFunctionFilterChange, onRangeFilterChange]);

  return (
    <BaseHeader
      range={range}
      title={title}
      chartType={chartType}
      handleRefresh={handleRefresh}
      onRangeFilterChange={onRangeFilterChange}
      hasNoCharts={hasNoCharts}
      onCustomDurationChange={onCustomDurationChange}
      onAveragingFunctionFilterChange={onAveragingFunctionFilterChange}
      open={open}
      handleReset={handleReset}
      averagingFunctionFilter={averagingFunctionFilter}
    />
  );
};
