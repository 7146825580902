import { TExtractFnReturnType, TQueryConfig } from '@marlin/shared/utils/react-query';
import { useQuery } from '@marlin/shared/utils/react-query';

import { getPinDevices } from '../infrastructure/get-pin-devices';
import { queryKey } from './query-key.enum';

type TQueryFnType = typeof getPinDevices;

type TUseDevicesOptions = {
  config?: TQueryConfig<TQueryFnType>;
};

export const usePinDevices = ({ config }: TUseDevicesOptions = {}) => {
  return useQuery<TExtractFnReturnType<TQueryFnType>>({
    ...config,
    queryKey: queryKey.PIN_DEVICES(),
    queryFn: () => getPinDevices(),
  });
};
