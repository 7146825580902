import {
  DeviceSearchWrapper,
  EquipmentSearchWrapper,
  LocationSearchWrapper,
} from '@marlin/alert/data-access/automated-action';
import { CRITICALITY } from '@marlin/shared/ui-criticality';
import { FormField, InfiniteAutocompleteControl, Search, SearchWrapper } from '@marlin/shared/ui-form-common';
import { Paper } from '@marlin/shared/ui-page';
import { PERMISSIONS, usePermission } from '@marlin/shared/utils-permission';
import { Button, Grid } from '@mui/material';
import { FormProvider } from 'react-hook-form';

import { content } from '../content';
import { deviceTypeOptions } from './constants';
import { CriticalityButton } from './criticality-button.component';
import { FilterChips } from './filter-chips';
import { useStyles } from './filters.styles';
import { IFilterParams } from './types';
import { useFiltering } from './use-filtering.hook';

interface IFiltersProps {
  onChangeParams: (params: IFilterParams) => void;
  initialParams: IFilterParams;
}

export function AlertsFilters({ onChangeParams, initialParams }: IFiltersProps) {
  const { classes } = useStyles();
  const { clearAllFilters, submit, form } = useFiltering(initialParams, onChangeParams);

  const allowedToDevices = usePermission(PERMISSIONS.SENSOR_DEVICES);

  return (
    <FormProvider {...form}>
      <form onSubmit={submit} data-testid="filters-form">
        <Grid className={classes.wrapper}>
          <Paper className={classes.paper}>
            <div data-testid="alerts-filters-container" className={classes.container}>
              <div className={classes.filterItem}>
                <FormField<IFilterParams> control={form.control} fieldName="search">
                  {(props) => (
                    <Search
                      label={content.FILTERS.LABEL_SEARCH}
                      value={props.value}
                      prefix="alerts"
                      data-testid="search"
                      {...props}
                    />
                  )}
                </FormField>
              </div>
              <div className={classes.filterItem}>
                <LocationSearchWrapper>
                  {(props) => (
                    <InfiniteAutocompleteControl<IFilterParams>
                      fieldName="locationIds"
                      testId="alerts-filters-location"
                      label={content.FILTERS.LABEL_LOCATION}
                      {...props}
                    />
                  )}
                </LocationSearchWrapper>
              </div>
              <div className={classes.filterItem}>
                <EquipmentSearchWrapper>
                  {(props) => {
                    return (
                      <InfiniteAutocompleteControl<IFilterParams>
                        fieldName="equipmentIds"
                        testId="alerts-filters-equipment"
                        label={content.FILTERS.LABEL_EQUIPMENT}
                        {...props}
                      />
                    );
                  }}
                </EquipmentSearchWrapper>
              </div>
              {allowedToDevices && (
                <div className={classes.filterItem}>
                  <DeviceSearchWrapper>
                    {(props) => {
                      return (
                        <InfiniteAutocompleteControl<IFilterParams>
                          fieldName="deviceIds"
                          testId="alerts-filters-device"
                          label={content.FILTERS.LABEL_SENSOR}
                          {...props}
                        />
                      );
                    }}
                  </DeviceSearchWrapper>
                </div>
              )}
            </div>
            <div data-testid="alerts-filters-container" className={classes.filterContainer}>
              {allowedToDevices && (
                <div className={classes.filterDeviceTypeItem}>
                  <FormField<IFilterParams> control={form.control} fieldName="deviceTypes">
                    {(props) => (
                      <SearchWrapper>
                        {({ search, term }) => (
                          <InfiniteAutocompleteControl<IFilterParams>
                            fieldName="deviceTypes"
                            testId="alerts-filters-device-types"
                            label={content.FILTERS.LABEL_SENSOR_TYPE}
                            options={deviceTypeOptions}
                            term={term}
                            search={search}
                            isFetchingNextPage={false}
                            hasNextPage={false}
                            isLoading={false}
                            isFetching={false}
                            fetchNextPage={() => {}}
                            {...props}
                          />
                        )}
                      </SearchWrapper>
                    )}
                  </FormField>
                </div>
              )}
              <FormField<IFilterParams> control={form.control} fieldName="criticality.high">
                {() => <CriticalityButton severity={CRITICALITY.HIGH} />}
              </FormField>
              <FormField<IFilterParams> control={form.control} fieldName="criticality.low">
                {() => <CriticalityButton severity={CRITICALITY.LOW} />}
              </FormField>
              <FilterChips />
              <div className={classes.filterChipWrapper}>
                <Button
                  className={classes.btnWrapper}
                  data-testid="alerts-filters-clear"
                  variant="outlined"
                  onClick={clearAllFilters}
                >
                  {content.FILTERS.CLEAR_ALL}
                </Button>
              </div>
            </div>
          </Paper>
        </Grid>
      </form>
    </FormProvider>
  );
}
