import { createStoreContext, useContext } from '@marlin/shared/utils-component-context';
import { Moment } from 'moment/moment';

interface ICustomPeriodModalContext {
  fromDate: Moment | null;
  toDate: Moment | null;
  open: boolean;
}

const {
  Provider: CustomPeriodModalProvider,
  useStore: useCustomPeriodModalContextStore,
  StoreContext: CustomPeriodModalContext,
} = createStoreContext<ICustomPeriodModalContext>({
  fromDate: null,
  toDate: null,
  open: false,
});

export { CustomPeriodModalProvider, useCustomPeriodModalContextStore };

export const useSetCustomPeriodModalContextStore = () => {
  const context = useContext(CustomPeriodModalContext);
  return context.set;
};
