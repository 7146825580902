import { MarlinTheme } from '@marlin/shared/theme';
import { getChartIcon } from '@marlin/shared/utils-chart';
import DeviceHubRounded from '@mui/icons-material/DeviceHubRounded';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LocationOnRounded from '@mui/icons-material/LocationOnRounded';
import { Checkbox, alpha } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { PropsWithChildren, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { maxDatapointsCount, sensorsGroupId } from '../../consts';
import { useSensorsSelection } from '../../hooks/use-sensors-selection.hook';
import { TChartType, TEquipmentList, TSelectedDatapoint } from '../../types';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  listHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: alpha(theme.palette.primary.main, 0.04),
  },
  listItemIcon: {
    minWidth: 'unset',
    marginRight: theme.typography.pxToRem(8),
  },
  listItemButton: { flexGrow: 0, minWidth: theme.typography.pxToRem(60) },
  equipmentList: {
    padding: 0,
  },
  equipmentListHeader: {
    backgroundColor: alpha(theme.palette.action.hover, 0.04),
    paddingLeft: theme.typography.pxToRem(16),
  },
  sensorContainer: {
    marginLeft: theme.typography.pxToRem(32),
    backgroundColor: theme.palette.background.primary,
    width: '100%',
  },
  wrapper: {
    paddingTop: 0,
  },
  listItemText: {
    '& > span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: '100%',
    },
  },
  listItemTextSensor: {
    '& > span': {
      width: `calc(100% - ${theme.typography.pxToRem(32)})`,
    },
  },
}));

const useToggle = (defaultValue?: boolean) => {
  const [isOpen, setIsOpen] = useState(defaultValue ?? true);

  const handleClick = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  return { isOpen, handleClick };
};

interface ILocationProps {
  equipmentList: TEquipmentList;
  locationName: string;
  locationId: string;
  allChartSelectedSensors: TSelectedDatapoint[];
  handleOnSelect: (value: TSelectedDatapoint[]) => void;
}

export const Location = ({
  equipmentList,
  locationName,
  locationId,
  allChartSelectedSensors,
  handleOnSelect,
}: ILocationProps) => {
  const { classes } = useStyles();
  const { handleClick, isOpen } = useToggle();
  const { toggleSensorsInEquipment, toggleSensorsInLocation, selectedSensors, getSensorSelectionInfo } =
    useSensorsSelection({ locationId, allChartSelectedSensors, handleOnSelect });

  const areAllSensorsSelected = getSensorSelectionInfo({ mode: 'allSensors' });
  const areNoneSensorsSelected = getSensorSelectionInfo({ mode: 'noneSensors' });

  const locationItemsCount = equipmentList.reduce((acc, equipment) => acc + equipment.sensorList.length, 0);

  return (
    <List classes={{ root: classes.wrapper }}>
      <div className={classes.listHeader}>
        <Checkbox
          checked={areAllSensorsSelected}
          indeterminate={!areNoneSensorsSelected && !areAllSensorsSelected}
          onChange={toggleSensorsInLocation}
          disabled={
            areAllSensorsSelected ? false : allChartSelectedSensors.length + locationItemsCount > maxDatapointsCount
          }
        />
        <ListItemIcon className={classes.listItemIcon}>
          <LocationOnRounded />
        </ListItemIcon>
        <ListItemText className={classes.listItemText} primary={locationName} />
        <ListItemButton onClick={handleClick} className={classes.listItemButton}>
          {isOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </div>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {equipmentList.map((equipment) => {
            const areAllSelected = getSensorSelectionInfo({
              mode: 'allSensorsInEquipment',
              equipmentId: equipment.id,
            });

            return (
              <Equipment
                key={equipment.id}
                name={equipment.name}
                areNonSelected={getSensorSelectionInfo({
                  mode: 'noneSensorsInEquipment',
                  equipmentId: equipment.id,
                })}
                areAllSelected={areAllSelected}
                onChange={() => toggleSensorsInEquipment(equipment.id)}
                disabled={
                  areAllSelected
                    ? false
                    : allChartSelectedSensors.length + equipment.sensorList.length > maxDatapointsCount
                }
                id={equipment.id}
              >
                {equipment.sensorList.map((sensor) => (
                  <Sensor
                    key={sensor.id}
                    name={sensor.label}
                    isChecked={Boolean(selectedSensors.find((selectedSensor) => selectedSensor.id === sensor.id))}
                    toggleSensor={() => handleOnSelect([sensor])}
                    type={sensor.type}
                    disabled={allChartSelectedSensors.length === maxDatapointsCount}
                  />
                ))}
              </Equipment>
            );
          })}
        </List>
      </Collapse>
    </List>
  );
};

interface IEquipmentProps {
  name: string;
  areAllSelected: boolean;
  areNonSelected: boolean;
  onChange: () => void;
  disabled: boolean;
  id: string;
}

const Equipment = ({
  children,
  name,
  areNonSelected,
  areAllSelected,
  onChange,
  disabled,
  id,
}: PropsWithChildren<IEquipmentProps>) => {
  const { classes, cx } = useStyles();
  const shouldBeOpenedByDefault = id === sensorsGroupId;
  const { handleClick, isOpen } = useToggle(shouldBeOpenedByDefault);

  return (
    <List className={classes.equipmentList}>
      <div className={cx(classes.listHeader, classes.equipmentListHeader)}>
        <Checkbox
          checked={areAllSelected}
          indeterminate={!areNonSelected && !areAllSelected}
          onChange={onChange}
          disabled={disabled}
        />
        <ListItemIcon className={classes.listItemIcon}>
          <DeviceHubRounded />
        </ListItemIcon>
        <ListItemText className={classes.listItemText} primary={name} />
        <ListItemButton onClick={handleClick} className={classes.listItemButton}>
          {isOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </div>

      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children}
        </List>
      </Collapse>
    </List>
  );
};

interface ISensorProps {
  name: string;
  toggleSensor: () => void;
  isChecked: boolean;
  type?: TChartType;
  disabled: boolean;
}

const Sensor = ({ name, toggleSensor, isChecked, type = 'custom', disabled }: ISensorProps) => {
  const { classes, cx } = useStyles();
  const icon = getChartIcon(type);

  return (
    <div className={cx(classes.listHeader, classes.sensorContainer)}>
      <Checkbox checked={isChecked} onChange={toggleSensor} disabled={isChecked ? false : disabled} />
      <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
      <ListItemText className={cx(classes.listItemText, classes.listItemTextSensor)} primary={name} />
    </div>
  );
};
