import { z } from 'zod';

const volumeSchema = z.object({
  averageVolume: z.number().nullish(),
  totalVolume: z.number().nullish(),
  trend: z.number().nullish(),
});

export const waterUsageDeviceSchema = z.object({
  deviceId: z.string(),
  deviceName: z.string(),
  locationId: z.string(),
  locationName: z.string(),
  manufacturerId: z.string(),
});

export const waterUsageSummarySchema = z
  .object({
    devices: z.array(waterUsageDeviceSchema),
    month: volumeSchema.nullish(),
    week: volumeSchema.nullish(),
    year: volumeSchema.nullish(),
  })
  .nullish();

export type TWaterUsageSummarySchema = z.infer<typeof waterUsageSummarySchema>;
export type TVolumeSchema = z.infer<typeof volumeSchema>;
export type TWaterUsageDevice = z.infer<typeof waterUsageDeviceSchema>;
