import { checkIfDeviceHasLostCommunication } from './check-if-device-has-lost-communication';
import { content } from './content';
import { parseDisplayedValue } from './datapoint-display/displayed-value-utils';
import { TLastReadingValuesSchema } from './zod/sensor-readings.schema';

export const formatSensorDatapoints = (lastReadingValues?: TLastReadingValuesSchema[]) => {
  const defaultDatapoint = lastReadingValues?.find((reading) => reading.isDefault);
  if (!defaultDatapoint) {
    return '';
  }
  if (checkIfDeviceHasLostCommunication(defaultDatapoint.lastReadingTime)) {
    return content.LOST_COMMUNICATION;
  }
  const uom = defaultDatapoint.name === 'Leak' ? 'WaterDetect' : defaultDatapoint.unitOfMeasure;
  return (
    defaultDatapoint.formattedReading ?? parseDisplayedValue(defaultDatapoint.value, uom ?? '', undefined, 'detail')
  );
};
