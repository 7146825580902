import { ITab, ScrollableTabs } from '@marlin/shared/ui-tabs-mobile';

import { content } from '../../../content';

export enum TAB_VALUES {
  LinkedItems = 'linked-items',
  Details = 'details',
}

const tabs: ITab<TAB_VALUES>[] = [
  {
    id: TAB_VALUES.LinkedItems,
    label: content.LINKED_ITEMS,
  },
  { id: TAB_VALUES.Details, label: content.DETAILS },
];

export interface ITabsProps {
  tabValue: TAB_VALUES;
  updateTab: (newValue: TAB_VALUES) => void;
}

export const LocationTabs = ({ tabValue, updateTab }: ITabsProps) => {
  return <ScrollableTabs tabs={tabs} tabValue={tabValue} updateTab={updateTab} />;
};
