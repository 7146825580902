import { useRouter } from '@marlin/shared/utils-router';
import { EQUIPMENT_TAB_VALUES } from '@marlin/shared/utils-routes';
import { useIdFromPathname } from '@marlin/shared/utils/url-params';
import { useMediaQuery, useTheme } from '@mui/material';
import { ReactNode, useCallback } from 'react';

import { content } from './content';
import { Tab, Tabs } from './tabs.component';
import { useTab } from './use-tab.hook';

interface IEquipmentDetailTabs {
  tabs: {
    [key in EQUIPMENT_TAB_VALUES]?: ReactNode;
  };
  getRoute: (id: string, tabId?: EQUIPMENT_TAB_VALUES) => string;
}

export const DetailTabs = ({ tabs, getRoute }: IEquipmentDetailTabs) => {
  const tab = useTab();
  const router = useRouter();
  const equipmentId = useIdFromPathname();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleChange = useCallback(
    (_: unknown, newTab: EQUIPMENT_TAB_VALUES) => {
      if (tab !== newTab) {
        const newTabUrl = getRoute(equipmentId, newTab);
        router.goTo(newTabUrl);
      }
    },
    [equipmentId, router, tab, getRoute]
  );

  return (
    <Tabs value={tab} onChange={handleChange}>
      <Tab data-testid="dashboard-tab" label={content['dashboard']} value={EQUIPMENT_TAB_VALUES.DASHBOARD} />
      {Object.keys(tabs)
        .filter((key) => key !== EQUIPMENT_TAB_VALUES.HISTORICAL_CHART || !isMobile)
        .map((key) => {
          const tabKey = key as EQUIPMENT_TAB_VALUES;

          return <Tab key={tabKey} data-testid={`${tabKey}-tab`} label={content[tabKey]} value={tabKey} />;
        })}
    </Tabs>
  );
};
