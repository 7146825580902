import { Divider } from '@mui/material';
import { ReactNode } from 'react';

import { useStyles } from './device-drawer-mobile.styles';

export const DetailItem = ({ children }: { children: ReactNode }) => {
  const { classes } = useStyles();

  return (
    <>
      <div className={classes.itemContainer}>{children}</div>
      <Divider />
    </>
  );
};
