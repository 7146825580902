import { startDatapointCommunication, stopDatapointCommunication } from '@marlin/asset/data-access/equipment';
import { useGetGateway } from '@marlin/asset/data-access/gateway';
import { HierarchyBreadcrumbs } from '@marlin/asset/shared/ui/hierarchy-breadcrumbs';
import { DetailTabs, useTab } from '@marlin/asset/shared/ui/tabs';
import { MarlinTheme } from '@marlin/shared/theme';
import { ActionEdit, ContextMenu } from '@marlin/shared/ui-context-menu';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { PageContainer, PageHeader, SystemConstraintsPage } from '@marlin/shared/ui-page';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { EQUIPMENT_TAB_VALUES, routes } from '@marlin/shared/utils-routes';
import { useIdFromPathname } from '@marlin/shared/utils/url-params';
import { Box, Button, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { ReactElement, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { Dashboard } from './components/dashboard/dashboard.component';
import { GatewaySettings } from './components/settings/gateway-settings.component';
import { content } from './content';
import { EditSettingContextProvider } from './context/edit-settings.context';
import { GatewayDatapointsContextProvider } from './context/gateway-datapoints.context';
import { useNavigation } from './hooks/use-navigation.hook';

interface IStyles {
  noHierarchyDisplayed: boolean;
}

export const useStyles = makeStyles<IStyles>()((theme: MarlinTheme, { noHierarchyDisplayed }) => ({
  title: {
    marginTop: 0,
    marginBottom: 0,
  },
  page: {
    minHeight: `calc(100% - ${theme.typography.pxToRem(64)})`,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.typography.pxToRem(16),
    width: '100%',
  },
  equipment: {
    fontSize: theme.typography.pxToRem(16),
    marginRight: theme.typography.pxToRem(5),
  },
  time: {
    fontSize: theme.typography.pxToRem(14),
  },
  editDeleteButton: {
    width: theme.typography.pxToRem(96),
  },
  actionsWrapper: {
    display: 'flex',
    justifyContent: noHierarchyDisplayed ? 'flex-end' : 'space-between',
    padding: `0 0 ${theme.typography.pxToRem(16)} 0`,
    alignItems: 'flex-start',
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    width: theme.typography.pxToRem(76),
    marginLeft: theme.typography.pxToRem(16),
    padding: theme.typography.pxToRem(5),
  },
  buttons: {
    display: 'flex',
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const tabs: { [key in EQUIPMENT_TAB_VALUES]?: ReactElement } = {
  settings: <GatewaySettings />,
};

export const GatewayDashboard = () => {
  const gatewayId = useIdFromPathname();
  const { data: gateway, isLoading } = useGetGateway({ gatewayId });
  const navigation = useNavigation();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const detailEditLink = useMemo(() => navigation.getEditGatewayPageLink(gateway?.id || ''), [navigation, gateway?.id]);
  const { classes } = useStyles({ noHierarchyDisplayed: !gateway?.locationId });
  const navigate = useNavigate();
  const tab = useTab();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const manufacturerId = gateway?.manufacturerId;

    if (manufacturerId) {
      startDatapointCommunication(manufacturerId).subscribe({
        error: (err) => {
          enqueueSnackbar('content.DASHBOARD_CONNECTION_ERROR', {
            variant: 'error',
            preventDuplicate: true,
          });
        },
      });
    }

    return () => {
      if (manufacturerId) {
        stopDatapointCommunication(manufacturerId).subscribe({
          error: (err) => {
            return;
          },
        });
      }
    };
  }, [gateway?.manufacturerId, enqueueSnackbar]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!gateway) {
    return <SystemConstraintsPage />;
  }

  return (
    <GatewayDatapointsContextProvider gateway={gateway}>
      <EditSettingContextProvider>
        <PageContainer className={classes.page}>
          <div className={classes.actionsWrapper}>
            {gateway?.locationId && (
              <HierarchyBreadcrumbs locationId={gateway.locationId} selectedItemId={gateway.id ?? ''} />
            )}
            {!isMobile && (
              <div className={classes.buttons}>
                <Button
                  variant="outlined"
                  className={classes.button}
                  onClick={() => navigate(detailEditLink, { state: { prevLocation: location } })}
                  data-testid="edit-gateway-button"
                >
                  {content.EDIT}
                </Button>
              </div>
            )}
          </div>
          <Box className={classes.headerWrapper}>
            {isMobile ? (
              <div className={classes.wrapper}>
                <div className={classes.titleWrapper} data-testid="header-container">
                  <h1 data-testid="header-title" className={classes.title}>
                    {gateway?.name}
                  </h1>
                  <ContextMenu>
                    <ActionEdit onClick={() => navigate(detailEditLink, { state: { prevLocation: location } })} />
                  </ContextMenu>
                </div>
              </div>
            ) : (
              <PageHeader prefix="equipment-gateway-header" title={gateway?.name ?? ''} />
            )}
          </Box>
          <DetailTabs tabs={tabs} getRoute={routes.gateway.details.url} />
          {tab === EQUIPMENT_TAB_VALUES.DASHBOARD ? <Dashboard gateway={gateway} /> : tabs[tab]}
        </PageContainer>
      </EditSettingContextProvider>
    </GatewayDatapointsContextProvider>
  );
};
