import { DateValidationError } from '@mui/x-date-pickers';

import { content } from './content';

export const getDateValidationErrorMessage = (error: DateValidationError | undefined) => {
  switch (error) {
    case 'disableFuture':
      return content.DATE_VALIDATION_FUTURE;
    case 'minDate':
      return content.DATE_VALIDATION_MIN;
    case 'invalidDate':
      return content.DATE_VALIDATION_INVALID;
    default:
      return '';
  }
};
