import { useCurrentUser } from '@marlin/account-data-access-organization';
import { HomeDashboardHub } from '@marlin/asset/feature/home-dashboard-hub';
import { Home } from '@marlin/asset/feature/home-hub';

export const HomeLayout = (): JSX.Element | null => {
  const { data } = useCurrentUser();

  if (!data) {
    return null;
  }

  switch (data.settings.defaultHomePage) {
    case 'DASHBOARD': {
      return <HomeDashboardHub />;
    }
    case 'LOCATIONS':
    default: {
      return <Home />;
    }
  }
};
