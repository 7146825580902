export const content = {
  EQUIPMENT: 'equipment',
  CANCEL: 'Cancel',
  DELETE: 'Delete',
  DEREGISTER: 'Deregister',
  GO_BACK: 'Go Back',
  DELETE_DEFAULT_CONFIRMATION_MESSAGE: (assetType: string) =>
    `You must remove the asset(s) attached to this ${assetType} before you can delete`,
  ACTION_CAN_NOT_BE_UNDONE: 'This action cannot be undone.',
  DELETE_EQUIPMENT_CONFIRMATION: (assetName: string) => (
    <div>
      Are you sure you want to delete <span style={{ fontWeight: 'bold', wordBreak: 'break-all' }}>{assetName}</span>?
    </div>
  ),
  DEREGISTER_EQUIPMENT_CONFIRMATION: () => (
    <div>
      By deregistering, the equipment will be deleted from your Location.
      <br />
      Any attached automations will also be deleted.
      <br />
      <div style={{ fontWeight: 'bold', marginTop: '2rem' }}>Are you sure you want to delete this equipment?</div>
    </div>
  ),
  DEREGISTER_SENTINEL_EQUIPMENT_CONFIRMATION: () => (
    <div>
      By deregistering, the equipment will also:
      <ul style={{ marginTop: 0, paddingTop: 0, paddingLeft: 24 }}>
        <li>Be deleted from your location</li>
        <li>Any child equipment associated with the controller will be deleted</li>
        <li>All associated automations and alerts will be deleted</li>
        <li>All associated flow links will also be deleted</li>
      </ul>
      <div style={{ fontWeight: 'bold', marginTop: '1rem' }}>
        Are you sure you want to delete this equipment? This action cannot be undone.
      </div>
    </div>
  ),

  FLOW_LINKS_INFO: 'The following flow link attachments will also be deleted.',
  FLOW_LINKS_CONFIRMATION: 'Are you sure you want to delete this equipment and all of its flow link attachments?',
  FLOW_LINKS_TABLE: 'Flow Links',
  REMOVED_ATTACHED: 'Remove attached assets',
  DELETE_EQUIPMENT: 'Delete Equipment?',
  DEREGISTER_AND_DELETE_EQUIPMENT: 'Deregister & Delete Equipment',
  DEREGISTER_FLOW_LINKS_INFO:
    'By deregistering, the equipment will be deleted from your organization.\nThe following flow links will also be deleted.',
  DEREGISTER_FLOW_LINKS_CONFIRMATION:
    'Are you sure you want to delete this equipment and all of its flow link? This action cannot be undone.',
  DEREGISTER_SUCCESS: 'Your equipment has been deleted.',
  DEREGISTER_ERROR: 'Equipment deletion failed, please try again.',
  DELETE_SUCCESS: 'Your equipment has been deleted.',
  DELETE_ERROR: 'Equipment deletion failed, please try again.',
};
