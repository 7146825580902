import { THomeLocationSchema } from '@marlin/asset/data-access/home-dashboard';

import { HomeCardAssets } from '../card-assets';
import { HomeCardHeader } from '../card-header';
import { useStyles } from './card.component.styles';

type THomeCardProps = {
  homeLocation: THomeLocationSchema;
};

const HomeCard = ({ homeLocation }: THomeCardProps) => {
  const { classes } = useStyles();
  return (
    <div className={classes.wrapper}>
      <HomeCardHeader location={homeLocation} />
      <div className={classes.assetsWrapper}>
        <HomeCardAssets homeLocation={homeLocation} />
      </div>
    </div>
  );
};

export { HomeCard };
