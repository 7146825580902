import {
  DeleteDeviceModalBody,
  DeleteDeviceModalFooter,
  DeleteDeviceModalTitle,
} from '@marlin/asset/ui/delete-device-modal';
import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import { useCallback, useContext } from 'react';

import { IDeviceExtended } from '../shared/types';

export const useDeleteDevice = () => {
  const { modalDispatch } = useContext(ModalContext);

  const deleteDevice = useCallback(
    (device: IDeviceExtended) => {
      modalDispatch({
        type: MODAL_ACTION_TYPE.SHOW,
        payload: {
          title: <DeleteDeviceModalTitle />,
          body: <DeleteDeviceModalBody deviceName={device.name} deviceId={device.id} />,
          footer: <DeleteDeviceModalFooter deviceId={device.id} />,
        },
      });
    },
    [modalDispatch]
  );

  return {
    deleteDevice,
  };
};
