import { z } from 'zod';

export enum BOILERS_SETTINGS_GROUP {
  BOILER_1 = 'Boiler1',
  BOILER_2 = 'Boiler2',
  BOILER_3 = 'Boiler3',
  BOILER_4 = 'Boiler4',
  BOILER_5 = 'Boiler5',
  BOILER_6 = 'Boiler6',
  BOILER_7 = 'Boiler7',
  BOILER_8 = 'Boiler8',
  BOILER_9 = 'Boiler9',
  BOILER_10 = 'Boiler10',
  BOILER_11 = 'Boiler11',
  BOILER_12 = 'Boiler12',
  BOILER_13 = 'Boiler13',
  BOILER_14 = 'Boiler14',
  BOILER_15 = 'Boiler15',
  BOILER_16 = 'Boiler16',
}

export enum SETTINGS_GROUP {
  TEMPERATURE = 'Temp',
  SYSTEM = 'System',
  ALERT = 'Alert',
  SCHEDULE = 'Schedule',
  SETTINGS = 'Settings',
  CENTRAL_HEATING = 'Heat',
  DOMESTIC_HOT_WATER = 'DHW',
  CONSTANT_SETPOINT = 'Setpoint',
  REMOTE_SETPOINT = 'Remote',
  SCHEDULE_TEMPERATURE = 'ScheduleTemp',
  GENERAL = 'General',
}

export const SettingsGroup = z.nativeEnum({
  ...SETTINGS_GROUP,
  ...BOILERS_SETTINGS_GROUP,
});
export type TSettingsGroup = z.infer<typeof SettingsGroup>;

const SettingPayload = z.object({
  name: z.string(),
  value: z.string(),
  prevValue: z.string().optional(),
});

export type TSettingPayload = z.infer<typeof SettingPayload>;

export const SettingsPayload = z.object({
  datapoints: SettingPayload.array(),
});
export const SettingsPayloadValidation = z.object({
  datapoints: SettingPayload.array(),
  settingGroupId: z.string(),
});
export type TSettingsPayload = z.infer<typeof SettingsPayload>;
