import {
  TLocationParamsSchema,
  TLocationSchema,
  useFilteredLocationsWithPaging,
} from '@marlin/asset/data-access/location';
import { mapSortDirection } from '@marlin/shared/utils-sorting';
import { usePagination } from '@marlin/shared/utils/react-query';
import { GridSortModel } from '@mui/x-data-grid';
import { useCallback, useMemo, useState } from 'react';

import { ILocationHubFilters } from '../shared/types';

interface IPagination {
  page: number;
  pageSize: number;
  rowCount: number;
}

export interface ILocationsHub {
  rows: TLocationSchema[];
  parentLocationsList: {
    id: string;
    name: string;
  }[];
  isLoading: boolean;
  isError: boolean;
  pagination: IPagination;
  changePage: (newPage: number) => void;
  changePageSize: (newPageSize: number) => void;
  refetch: () => void;
  onFiltersChange: (filters: ILocationHubFilters) => void;
  onSortChange: (sortModel: GridSortModel) => void;
}

const defaultParams = {
  initialPage: 1,
  initialPageSize: 10,
};

const dataGridPageCounterOffset = 1;

export const useLocationsHub = (): ILocationsHub => {
  const { page, pageSize, changePage: paginationChangePage, changePageSize } = usePagination(defaultParams);
  const [requestParams, setRequestParams] = useState<Omit<TLocationParamsSchema, 'page' | 'pageSize'>>({});

  const devicesQuery = useFilteredLocationsWithPaging({
    params: { ...requestParams, page, pageSize },
  });

  const onFiltersChange = useCallback(
    (data: ILocationHubFilters) => {
      paginationChangePage(1);
      setRequestParams({
        search: data.search?.trim() || undefined,
        parentLocationIds: data.locations?.map((location) => location.id),
      });
    },
    [paginationChangePage]
  );

  const onSortChange = useCallback(
    (sortModel: GridSortModel) => {
      if (!sortModel[0] || !sortModel[0].sort) {
        setRequestParams({ ...requestParams, sorting: undefined });
      } else {
        const sorting = {
          sortBy: sortModel[0]?.field,
          direction: mapSortDirection(sortModel[0]?.sort),
        };
        setRequestParams({
          ...requestParams,
          sorting,
        });
      }
    },
    [requestParams]
  );

  const rows = useMemo(() => devicesQuery.data?.data || [], [devicesQuery.data]);

  const parentLocationsList = useMemo(() => {
    const locationIds: string[] = [];
    rows.forEach((location) => {
      if (location?.parentLocationId) {
        locationIds.push(location?.parentLocationId);
      }
    });
    const uniqueLocationsList: string[] = [...new Set(locationIds)];
    return uniqueLocationsList.map((id: string) => {
      return {
        id,
        name: rows.find((location) => location.id === id)?.name ?? '',
      };
    });
  }, [rows]);

  const dataGridPage = useMemo(() => page - dataGridPageCounterOffset, [page]);
  const changeDataGridPage = (newPage: number) => paginationChangePage(newPage + dataGridPageCounterOffset);

  const isLoading = useMemo(
    () => devicesQuery.isLoading || devicesQuery.isInitialLoading || devicesQuery.isFetching,
    [devicesQuery.isFetching, devicesQuery.isInitialLoading, devicesQuery.isLoading]
  );

  const isError = useMemo(() => devicesQuery.isError, [devicesQuery.isError]);

  return {
    rows,
    parentLocationsList,
    isLoading,
    isError,
    pagination: {
      page: dataGridPage,
      pageSize,
      rowCount: devicesQuery.data?.pagination.totalItems || 0,
    },
    changePage: changeDataGridPage,
    changePageSize,
    refetch: devicesQuery.refetch,
    onFiltersChange,
    onSortChange,
  };
};
