import { TExtractFnReturnType, useQuery } from '@marlin/shared/utils/react-query';
import { useMemo } from 'react';

import { getNodes } from '../infrastructure/nodes';
import { TNodeListFilters, nodeListFiltersSchema } from '../node.model.schema';
import { queryKey } from './query-key.enum';

export const useNodes = (filters: TNodeListFilters, enabled = false) => {
  const queryParams: TNodeListFilters = useMemo(() => nodeListFiltersSchema.parse(filters), [filters]);

  return useQuery<TExtractFnReturnType<typeof getNodes>>({
    queryKey: queryKey.NODES(queryParams),
    queryFn: () => getNodes(queryParams),
    enabled,
    keepPreviousData: true,
  });
};
