import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import { TCreateFlowLink, createSystemMapLinkDtoSchema } from '../flow-link.model.schema';
import { GraphSchema, TGraph } from '../flow-map.model.schema';
import { paths } from './api';

export const createAttachment = async (data: TCreateFlowLink): Promise<TGraph> => {
  const body = safeParseData(data, createSystemMapLinkDtoSchema);

  const res = await getHttpClient().post<unknown, TGraph>(paths.SYSTEM_MAP_LINK, body);

  return safeParseData(res, GraphSchema);
};
