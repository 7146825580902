import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  editDeleteButtonsWrapper: {
    display: 'flex',
  },

  editDeleteButton: {
    width: theme.typography.pxToRem(56),
    marginLeft: theme.typography.pxToRem(16),
  },
}));
