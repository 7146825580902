import { MarlinTheme } from '@marlin/shared/theme';
import { CriticalityControl } from '@marlin/shared/ui-criticality';
import { Paper } from '@marlin/shared/ui-page';
import { Details, DetailsSection } from '@marlin/shared/ui/collapsible-details-mobile';
import { getRecipientCardTitle } from '@marlin/shared/utils/format-alert';
import { Grid } from '@mui/material';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';
import { IAlert } from '../../types';
import { CardHeader } from '../header.component';
import { DescriptionComponent } from '../helper-components/description.component';
import { HyperlinkComponent } from '../helper-components/hyperlink.component';
import { CheckIcon } from '../sections/check-icon.component';
import { MetadataSection } from './metadata-section.component';
import { RecipientsSection } from './recipients-section.component';
import { TimeSection } from './time-section.component';

interface IAlertCurrent {
  alert: IAlert;
  displayIconColumn: boolean;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: theme.typography.pxToRem(8),
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.typography.pxToRem(12),
  },
  hyperLinkWrapper: {
    marginTop: theme.typography.pxToRem(8),
    marginBottom: theme.typography.pxToRem(8),
  },
}));

export function MobileAlertResolvedCard({ alert, displayIconColumn }: IAlertCurrent) {
  const { classes } = useStyles();
  const checkIcon = displayIconColumn ? <CheckIcon /> : undefined;
  const isEquipmentAlert = alert.errorType === 'DEVICE_ALERT' || alert.errorType === 'DEVICE_ERROR';

  const title = useMemo(() => {
    if (isEquipmentAlert) {
      return `${alert.title}`;
    }
    return getRecipientCardTitle(alert);
  }, [alert, isEquipmentAlert]);

  return (
    <Grid item xs={12}>
      <Paper testId="alert-card">
        <div className={classes.row}>
          <CriticalityControl severity={alert.criticality} />
        </div>
        <div className={classes.row} data-testid="card-header">
          {checkIcon}
          <CardHeader title={title} />
        </div>
        <TimeSection label={content.CARD.CURRENT.LABEL.START_TIME} datetime={alert.startTime} />
        <TimeSection label={content.CARD.CURRENT.LABEL.RESOLVED_TIME} datetime={alert.resolveTime} />
      </Paper>
      <Details>
        <DetailsSection title={content.CARD.DETAILS.ACTIONS} withDivider>
          <RecipientsSection alert={alert} />
        </DetailsSection>
        <DetailsSection withDivider={isEquipmentAlert} title={content.CARD.DETAILS.HIERARCHY}>
          <MetadataSection isEquipmentAlert={isEquipmentAlert} alert={alert} />
        </DetailsSection>
        {isEquipmentAlert && (
          <DetailsSection title={content.CARD.DETAILS.DESCRIPTION}>
            <div className={classes.hyperLinkWrapper}>
              <HyperlinkComponent link={alert.hyperlink} />
            </div>
            <DescriptionComponent isMobile description={alert.description} />
          </DetailsSection>
        )}
      </Details>
    </Grid>
  );
}
