import { TSubtypesType } from '@marlin/asset/data-access/device';
import { IToggleButtonsOption } from '@marlin/shared/ui-form-common';
import { Icon } from '@mui/material';
import React from 'react';

export const meterTypeOptions: IToggleButtonsOption<TSubtypesType>[] = [
  {
    value: 'Water',
    label: 'Water Meter'.toUpperCase(),
    icon: <Icon baseClassName="material-symbols-outlined">water_drop</Icon>,
  },
  {
    value: 'Gas',
    label: 'Gas Meter'.toUpperCase(),
    icon: <Icon baseClassName="material-symbols-outlined">gas_meter</Icon>,
  },
];
