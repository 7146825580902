import { content } from './content';
import { getTitleErrorText } from './get-title-error-text';
import { DEVICE_TYPE, ERROR_TYPES, IAlert } from './types';

export const getCardTitle = (alert: IAlert): string => {
  if (alert.errorType === ERROR_TYPES.LOW_BATTERY) {
    return `${alert.device?.name} ${content.TITLE.LOW_BATTERY}`;
  }
  if (alert.errorType === ERROR_TYPES.LOST_COMMUNICATION) {
    return `${alert.device?.name} ${content.TITLE.LOST_COMMUNICATION}`;
  }

  if (alert.device?.type === DEVICE_TYPE.LEAK) {
    return `${alert.device?.name} ${content.TITLE.LEAK}`;
  }

  const errorText = getTitleErrorText(alert.errorType, alert.uoM, alert.thresholdMin, alert.thresholdMax);

  return `${alert.device?.name} ${errorText}`;
};
