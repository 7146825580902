import { content } from '../../content';

export const steps = [
  {
    id: 'setup-organization',
    label: 'Set up your organization',
    index: 0,
  },
  {
    id: 'register-equipment',
    label: 'Register equipment',
    index: 1,
  },
  {
    id: 'invite-users',
    label: 'Invite users',
    index: 2,
  },
];

export enum ONBOARDING_STEP {
  SETUP_ORGANIZATION,
  REGISTER_EQUIPMENT,
  INVITE_USERS,
  SETUP_COMPLETE,
  INVITATIONS_SENT,
  INVITATIONS_COMPLETED,
  ALREADY_REGISTERED_ERROR,
  LOCATION_ERROR,
  ORGANIZATION_CREATION_ERROR,
  REGISTRATION_DATA_ERROR,
  REGISTRATION_FAILED_ERROR,
  INVITATION_FAILED_ERROR,
}

export const LoggerTextMapper = {
  [ONBOARDING_STEP.SETUP_ORGANIZATION]: content.LOGGER.SELF_SERVICE.SETUP_ORGANIZATION,
  [ONBOARDING_STEP.REGISTER_EQUIPMENT]: content.LOGGER.SELF_SERVICE.REGISTER_EQUIPMENT,
  [ONBOARDING_STEP.INVITE_USERS]: content.LOGGER.SELF_SERVICE.INVITE_USERS,
  [ONBOARDING_STEP.SETUP_COMPLETE]: content.LOGGER.SELF_SERVICE.SETUP_COMPLETE,
  [ONBOARDING_STEP.INVITATIONS_SENT]: content.LOGGER.SELF_SERVICE.INVITATIONS_SENT,
  [ONBOARDING_STEP.INVITATIONS_COMPLETED]: content.LOGGER.SELF_SERVICE.INVITATIONS_COMPLETED,
  [ONBOARDING_STEP.REGISTRATION_FAILED_ERROR]: content.LOGGER.SELF_SERVICE.ERRORS.REGISTRATION_FAILED_ERROR,
  [ONBOARDING_STEP.ALREADY_REGISTERED_ERROR]: content.LOGGER.SELF_SERVICE.ERRORS.ALREADY_REGISTERED_ERROR,
  [ONBOARDING_STEP.REGISTRATION_DATA_ERROR]: content.LOGGER.SELF_SERVICE.ERRORS.REGISTRATION_DATA_ERROR,
  [ONBOARDING_STEP.LOCATION_ERROR]: content.LOGGER.SELF_SERVICE.ERRORS.LOCATION_ERROR,
  [ONBOARDING_STEP.ORGANIZATION_CREATION_ERROR]: content.LOGGER.SELF_SERVICE.ERRORS.ORGANIZATION_CREATION_ERROR,
  [ONBOARDING_STEP.INVITATION_FAILED_ERROR]: content.LOGGER.SELF_SERVICE.ERRORS.INVITATION_FAILED_ERROR,
};
