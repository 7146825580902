import { getHttpClient } from '@marlin/shared/utils/react-query';
import { z } from 'zod';

import { IUpsertRecipientProfile } from '../recipient.model';
import { TCreateRecipient } from './recipient.model.schema';

const recipientSchema = z
  .object({
    id: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    phoneNumber: z.string(),
    email: z.string(),
  })
  .transform((r) => {
    return {
      id: r.id,
      firstName: r.firstName,
      lastName: r.lastName,
      phone: r.phoneNumber,
      email: r.email,
    };
  });

const updateRecipientRequestSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  phoneNumber: z.string(),
  email: z.string(),
});

export const updateRecipient = async ({
  id,
  data,
}: {
  id: string;
  data: TCreateRecipient;
}): Promise<IUpsertRecipientProfile> => {
  const updateRecipientData = updateRecipientRequestSchema.parse(data);
  const res = await getHttpClient().put<unknown, IUpsertRecipientProfile>('/api/recipients/{id}', updateRecipientData, {
    params: { id },
  });

  return recipientSchema.parse(res);
};
