import { MarlinTheme } from '@marlin/shared/theme';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  statusHeaderCell: {
    display: 'flex',
    alignItems: 'center',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontWeight: theme.typography.fontWeightMedium,
  },

  title: {
    marginLeft: theme.typography.pxToRem(6),
  },
}));

export const StatusHeaderCell = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.statusHeaderCell}>
      <Tooltip text={content.STATUS_HEADER_CELL_TOOLTIP} placement="top" />
      <span className={classes.title}>{content.GATEWAY_TABLE_STATUS_COLUMN}</span>
    </div>
  );
};
