export const content = {
  WATER_USAGE: 'Water Usage',
  WEEK_RUNNING_TREND: 'WEEK RUNNING TREND',
  MONTH_RUNNING_TREND: 'MONTH RUNNING TREND',
  WEEK: 'Week',
  MONTH: 'Month',
  YEAR: 'Year',
  SHOW_HISTORY: 'SHOW HISTORY',
  VIEW_FLOW_METER: 'VIEW FLOW METER',
  VALUE_TREND: (value?: string) => (Number(value) !== 0 ? (Number(value) > 0 ? `+${value}%` : `${value}%`) : '--'),
  VALUE_TOTAL: (value?: string, uom?: string) => `${value} ${uom}`,
  EXPORT: 'Export',
  SELECT_FLOW_METER: 'Select water flow meter',
  FILE_EXPORTED: 'File has been exported',
  ERROR_MESSAGE: "Something went wrong. File hasn't been exported.",
  ERROR_NO_DATA: "Water usage telemetry not found. File hasn't been exported.",
  GPM_SYMBOL: 'gpm',
  LPM_SYMBOL: 'L/min',
  M3PH_SYMBOL: 'm³/h',
  SUMMARY_GPM_SYMBOL: 'gal',
  SUMMARY_LPM_SYMBOL: 'L',
  SUMMARY_M3PH_SYMBOL: 'm³',
};
