import { MarlinTheme } from '@marlin/shared/theme';
import ApexChart from 'react-apexcharts';
import { makeStyles } from 'tss-react/mui';

import { IChart } from './chart-props';
import { useChartData } from './use-chart-data.hook';
import { useChartOptions } from './use-chart-options.hook';
import { useTimeAxis } from './use-time-axis.hook';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  chart: {
    flexGrow: 1,
    paddingBottom: theme.typography.pxToRem(16),
  },
}));

export const CollapsedChart = ({
  deviceType,
  data,
  timeZoneAbbr,
  ticks,
  rangeFilter,
  uoM,
}: IChart): JSX.Element | null => {
  const { classes } = useStyles();
  const { timeRange } = useTimeAxis({ data, rangeFilter });
  const chartType = data.length === 1 ? 'scatter' : 'line';
  const apexOptions = useChartOptions({
    deviceType,
    timeRange,
    ticks,
    rangeFilter,
    chartType,
    timeZoneAbbr,
    uoM,
  });
  const apexData = useChartData(data, deviceType);

  return (
    <div className={classes.chart}>
      <ApexChart series={apexData} options={apexOptions} data-testid="collapsed-chart" height="100%" />
    </div>
  );
};
