import { getHttpClient, useQuery } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import { paths } from '../infrastructure/api';
import { queryKey } from '../query-key.enum';
import { UserData, UserDataResponse } from '../schema/user-data.schema';

export const getUserData = async (area: string) => {
  const res = await getHttpClient().get<unknown, unknown>(paths.USER_DATA, {
    params: {
      area,
    },
  });

  const parsed = safeParseData(res, UserDataResponse);

  try {
    const data = JSON.parse(parsed.data);
    return safeParseData(data, UserData);
  } catch {
    throw new Error('Invalid JSON format from UserData');
  }
};

export const useUserData = (area: string) => {
  return useQuery({
    queryKey: queryKey.USER_DATA(area),
    queryFn: () => getUserData(area),
  });
};
