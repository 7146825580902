import { CardHeader } from '@marlin/asset/ui-home-hub-cards';
import { PERMISSIONS, Restricted } from '@marlin/shared/utils-permission';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { Button, Typography } from '@mui/material';

import { content } from '../../content';
import { useStyles } from './list-no-mechanical-rooms.component.styles';

const newLocationPage = routes.locations.create.url();
const locationsHubPage = routes.locations.list.url();

export interface IHomeListNoMechanicalRoomsProps {
  isMobile?: boolean;
}

const HomeListNoMechanicalRooms = ({ isMobile }: IHomeListNoMechanicalRoomsProps) => {
  const { classes } = useStyles({ isMobile });
  const router = useRouter();

  return (
    <div className={classes.root}>
      <CardHeader title={content.NO_MECHANICAL_ROOMS_TITLE} isMobile={isMobile} compact />
      <div className={classes.wrapper}>
        <Typography variant="body2" className={classes.noMechanicalRoomsBody}>
          {content.NO_MECHANICAL_ROOMS_BODY}
        </Typography>
        <div>
          <Restricted to={PERMISSIONS.CREATE_NEW_MECHANICAL_ROOM}>
            <Button
              className={classes.createRoomButton}
              variant="contained"
              onClick={() => router.goTo(newLocationPage, { state: { isMechanicalRoom: true } })}
            >
              {content.CREATE_NEW_MECHANICAL_ROOM}
            </Button>
          </Restricted>
          <Button
            className={classes.goToLocationsHubButton}
            variant="outlined"
            onClick={() => router.goTo(locationsHubPage)}
          >
            {content.GO_TO_LOCATIONS_PAGE}
          </Button>
        </div>
      </div>
    </div>
  );
};

export { HomeListNoMechanicalRooms };
