import { MarlinTheme } from '@marlin/shared/theme';
import { Chip, alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';
import { TInvitationStatus } from './types';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  EXPIRED: {
    color: theme.palette.alternative.main,
    borderColor: theme.palette.alternative.main,
  },
  PENDING: {
    color: theme.palette.warning.main,
    borderColor: alpha(theme.palette.warning.main, 0.5),
  },
}));

export interface IInvitationStatusProps {
  invitationStatus: TInvitationStatus;
}

const roleLabels: Record<TInvitationStatus, string> = {
  PENDING: content.invitationStatusPendingLabel,
  EXPIRED: content.invitationStatusExpiredLabel,
};

export const InvitationStatusChip = ({ invitationStatus }: IInvitationStatusProps) => {
  const { classes } = useStyles();

  return (
    <Chip className={classes[invitationStatus]} label={roleLabels[invitationStatus]} size="small" variant="outlined" />
  );
};
