import { useEquipmentDetails, useMetadata } from '@marlin/asset/data-access/equipment';
import { useAnalyticsTelemetry } from '@marlin/asset/data-access/telemetry';
import { IChartConfigOptions } from '@marlin/asset/shared/equipment-config';
import { useChartingColors } from '@marlin/asset/ui/charts';
import {
  IChartSeries,
  IRangeFilter,
  RANGE_FILTER,
  getNumberOfBuckets,
  isAercoDevice,
  parseToChartData,
  removeEmptyChartData,
  useDuration,
} from '@marlin/shared/utils-chart';
import { useIdFromPathname } from '@marlin/shared/utils/url-params';
import { useMemo } from 'react';

interface IUseChartDataProps {
  manufacturerId: string | undefined;
  rangeFilter: IRangeFilter;
  datapointGroupNames: string[];
  datapointNames: Array<string>;
  useLastReadings?: boolean;
  options?: IChartConfigOptions;
}

interface ITranslation {
  name: string;
  label?: string;
}

export const useChartData = ({
  manufacturerId = '',
  rangeFilter,
  datapointGroupNames,
  datapointNames,
  options,
}: IUseChartDataProps) => {
  const equipmentId = useIdFromPathname();
  const { data: metadataResponse } = useMetadata({ equipmentId, enabled: false });
  const datapointGroupNamesLower = useMemo(
    () => datapointGroupNames.map((name) => name.toLowerCase()),
    [datapointGroupNames]
  );
  const datapointsGroupMetadata = useMemo(
    () =>
      metadataResponse?.chartDatapoints.reduce<ITranslation[]>((acc, group) => {
        if (datapointGroupNamesLower.includes(group.groupName.toLowerCase())) {
          return [...acc, ...group.datapoints];
        }

        return acc;
      }, []),
    [datapointGroupNamesLower, metadataResponse?.chartDatapoints]
  );

  const {
    fromDate: from,
    toDate: to,
    maxSelection,
    minSelection,
  } = useDuration({ rangeFilter, fromDate: null, toDate: null });
  const { data: { model } = {} } = useEquipmentDetails({ equipmentId });

  const numberOfBuckets = useMemo(
    () =>
      getNumberOfBuckets({
        minSelection,
        maxSelection,
        rangeFilter: rangeFilter.range,
        shouldHaveSpecialCalculations: false,
      }),
    [minSelection, maxSelection, rangeFilter.range]
  );

  const {
    data,
    isLoading: isDataLoading,
    refetch,
    isFetching,
  } = useAnalyticsTelemetry(
    {
      dateFrom: from,
      dateTo: to,
      requestedTelemetry: [{ manufacturerId, datapoints: datapointNames }],
      numberOfBuckets,
      averagingFunctionFilter: options?.averagingFunctionFilter,
      withoutMargins: true,
    },
    {
      select: (data) =>
        rangeFilter.range === RANGE_FILTER.HOURS_8 && isAercoDevice(model) ? removeEmptyChartData({ data }) : data,
    }
  );

  const convertedDatapoints = useMemo(() => parseToChartData(data?.telemetryData), [data]);

  const colors = useChartingColors();

  const chartData = useMemo<IChartSeries[]>(() => {
    return datapointNames.map((datapointName, idx) => {
      const datapointMetadata = datapointsGroupMetadata?.find(
        ({ name }) => name.toLowerCase() === datapointName.toLowerCase()
      );
      const datapointItem = convertedDatapoints.find(
        ({ datapointName: convertedDatapointName }) =>
          convertedDatapointName.toLowerCase() === datapointName.toLowerCase()
      );

      const emptyValues = [
        {
          x: new Date().valueOf(),
          y: null,
        },
      ];

      return {
        id: datapointName,
        name: datapointMetadata?.label ?? datapointItem?.datapointName ?? '',
        data: datapointItem?.values || emptyValues,
        uom: datapointItem?.unitOfMeasure || null,
        color: colors[idx],
        type: 'line',
      };
    });
  }, [colors, convertedDatapoints, datapointNames, datapointsGroupMetadata]);

  return {
    chartData,
    to: maxSelection,
    from: minSelection,
    isLoading: isDataLoading || isFetching,
    refetch,
  };
};
