import { MarlinTheme } from '@marlin/shared/theme';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { makeStyles } from 'tss-react/mui';

import { Subtitle } from './header-subtitle.component';

interface IPageHeaderProps {
  icon?: JSX.Element | null;
  prefix: string;
  actions?: JSX.Element | null;
  title: string;
  subtitle?: string | JSX.Element;
  bottomActions?: JSX.Element;
  topActions?: JSX.Element;
  rightSideActions?: JSX.Element;
  centerText?: boolean;
  inlineActions?: boolean;
  isTitleInline?: boolean;
  transparent?: boolean;
  showIconOnMobile?: boolean;
  marginBottom?: boolean;
}

interface IStylesProps {
  transparent: boolean;
  marginBottom: boolean;
}

interface IIconStylesProps {
  showIconOnMobile: boolean;
}

export const useStyles = makeStyles<IStylesProps>()((theme: MarlinTheme, { transparent, marginBottom }) => ({
  mobileContainer: {
    width: '100%',
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.typography.pxToRem(8),
      backgroundColor: transparent ? undefined : theme.palette.background.primary,
      borderBottom: transparent ? undefined : `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
      position: 'sticky',
      top: 0,
      left: 0,
      zIndex: theme.zIndex.appBar,
    },
  },

  wrapper: {
    marginBottom: marginBottom ? theme.typography.pxToRem(24) : 0,
    [theme.breakpoints.down('md')]: {
      marginBottom: 0,
      padding: transparent ? 'undefined' : theme.typography.pxToRem(16),
      paddingBottom: theme.typography.pxToRem(8),
    },
  },

  container: {
    display: 'flex',
    alignContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
    },
  },

  containerCenter: {
    display: 'flex',
    alignContent: 'space-between',
    alignItems: 'baseline',
    width: '100%',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
    },
  },

  title: {
    fontSize: theme.typography.pxToRem(34),
    lineHeight: theme.typography.pxToRem(42),
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(20),
      lineHeight: theme.typography.pxToRem(26),
    },
  },
  titleWrapper: {
    marginTop: 0,
    marginBottom: theme.typography.pxToRem(8),
    display: 'flex',
    alignItems: 'center',
    wordBreak: 'break-all',
    [theme.breakpoints.down('md')]: {
      marginBottom: 0,
    },
  },

  inlineTitle: {
    wordBreak: 'keep-all',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'block',
    marginTop: 0,
    marginBottom: theme.typography.pxToRem(8),
    [theme.breakpoints.down('md')]: {
      marginBottom: 0,
    },
  },
  inlineTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '60%',
  },

  subtitleContainer: {
    marginBottom: marginBottom ? theme.typography.pxToRem(24) : 0,
    [theme.breakpoints.down('md')]: {
      marginBottom: 0,
    },
  },

  subtitleContainerCenter: {
    marginBottom: marginBottom ? theme.typography.pxToRem(24) : 0,
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      marginBottom: 0,
    },
  },

  rightSideContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  inlinedActions: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
    },
  },
}));

const useIconStyles = makeStyles<IIconStylesProps>()((theme: MarlinTheme, { showIconOnMobile }) => ({
  icon: {
    marginRight: theme.typography.pxToRem(10),
    '& svg': {
      height: theme.typography.pxToRem(26),
    },
    [theme.breakpoints.down('md')]: {
      display: showIconOnMobile ? 'flex' : 'none',
      alignItems: 'center',
    },
  },
}));

const Icon = ({
  icon,
  showIconOnMobile = false,
}: {
  icon: JSX.Element | null;
  showIconOnMobile?: boolean;
}): JSX.Element | null => {
  const { classes } = useIconStyles({ showIconOnMobile });

  if (!icon) {
    return null;
  }

  return (
    <div data-testid="header-icon" className={classes.icon}>
      {icon}
    </div>
  );
};

export const PageHeader = ({
  icon,
  actions,
  title,
  subtitle,
  prefix,
  topActions,
  rightSideActions,
  bottomActions,
  centerText,
  inlineActions,
  isTitleInline = false,
  transparent = false,
  showIconOnMobile = false,
  marginBottom = true,
}: IPageHeaderProps) => {
  const { classes, cx } = useStyles({ transparent, marginBottom });

  return (
    <div className={classes.mobileContainer}>
      <div className={cx({ [classes.inlinedActions]: inlineActions })}>
        {rightSideActions ? (
          <div className={classes.rightSideContainer}>
            <div>{topActions}</div>
            <div>{rightSideActions}</div>
          </div>
        ) : (
          topActions
        )}
        <div className={classes.wrapper}>
          <div data-testid="header-container" className={centerText ? classes.containerCenter : classes.container}>
            {isTitleInline ? (
              <Tooltip text={title}>
                <div className={classes.inlineTitleWrapper}>
                  {icon && <Icon icon={icon} showIconOnMobile={showIconOnMobile} />}
                  <h1
                    data-testid="header-title"
                    id={`${prefix}-title`}
                    className={cx(classes.inlineTitle, classes.title)}
                  >
                    {title}
                  </h1>
                </div>
              </Tooltip>
            ) : (
              <h1 data-testid="header-title" id={`${prefix}-title`} className={cx(classes.title, classes.titleWrapper)}>
                {icon && <Icon icon={icon} showIconOnMobile={showIconOnMobile} />}
                {title}
              </h1>
            )}
            <div data-testid="header-actions">{actions}</div>
          </div>

          <div
            data-testid="header-subtitle"
            className={centerText ? classes.subtitleContainerCenter : classes.subtitleContainer}
          >
            <Subtitle subtitle={subtitle} prefix={prefix} />
          </div>
        </div>
      </div>
      {bottomActions}
    </div>
  );
};
