import { routes } from '@marlin/shared/utils-routes';

export interface IFeatureNavigation {
  getGatewaysPageLink: () => string;
  getNewEquipmentPageLink: () => string;
  getEquipmentDetailsPageLink: (id: string, isRegistered: boolean) => string;
  getLocationDetailsPageLink: (id: string, name: string) => string;
  getGatewayDetailsPageLink: (id: string) => string;
}

export const useNavigation = (): IFeatureNavigation => {
  return {
    getGatewaysPageLink: () => routes.gateway.list.path,
    getGatewayDetailsPageLink: (id: string) => routes.gateway.details.url(id),
    getNewEquipmentPageLink: () => routes.equipments.create.path,
    getEquipmentDetailsPageLink: (id: string, isDashboardEquipment: boolean) => {
      if (isDashboardEquipment) {
        return routes.equipmentDashboard.details.url(id);
      }
      return routes.equipments.details.url(id);
    },
    getLocationDetailsPageLink: (id: string, name: string) => {
      return routes.locations.details.url(id);
    },
  };
};
