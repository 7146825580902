import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(36),
    justifyContent: 'center',
    flexGrow: 1,
    paddingTop: theme.typography.pxToRem(64),
    paddingRight: theme.typography.pxToRem(48),
    paddingBottom: theme.typography.pxToRem(32),
    paddingLeft: theme.typography.pxToRem(48),
    width: theme.typography.pxToRem(514),
  },
  logo: {
    alignSelf: 'center',
  },
  img: {
    width: theme.typography.pxToRem(400),
    height: theme.typography.pxToRem(259),
  },
  imgLabel: {
    color: theme.palette.text.primary,
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    lineHeight: '160%',
    letterSpacing: theme.typography.pxToRem(0.17),
  },
  stepper: {
    backgroundColor: 'inherit',
  },
}));
