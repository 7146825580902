export const content = {
  TITLE: 'System Map',
  BUTTON_SAVE: 'Save',
  UNSAVED_CHANGES: 'You have unsaved changes',
  SAVED_CHANGES: 'All changes saved',
  MODAL_TITLE: 'You have unsaved changes in your System Map',
  MODAL_CONTENT: 'You are about to leave the page with unsaved changes. Save now or leave without saving.',
  MODAL_CONFIRM: 'leave without saving',
  MODAL_CANCEL: 'stay on page',
  CONTEXT_MENU_ADD_TEE: 'Add tee',
  CONTEXT_MENU_ADD_TEE_TOOLTIP: 'Add tee',
  CONTEXT_MENU_DELETE_TEE: 'Delete tee',
  CONTEXT_MENU_DELETE_TEE_TOOLTIP: 'Delete tee',
  CONTEXT_MENU_ADD_NODE_TO_TEE: 'Add flow link',
  CONTEXT_MENU_ADD_NODE_TO_TEE_TOOLTIP: 'Delete flow link',
  CONTEXT_MENU_DELETE_LINK: 'Delete flow link',
  CONTEXT_MENU_DELETE_LINK_TOOLTIP: 'Add flow link',
  ADD_TEE_SUCCESS_MESSAGE: 'Tee has been created.',
  ADD_TEE_ERROR_MESSAGE: "Something went wrong. Tee hasn't been created.",
  DELETE_TEE_SUCCESS_MESSAGE: 'Tee has been deleted.',
  DELETE_TEE_ERROR_MESSAGE: "Something went wrong. Tee hasn't been deleted.",
  DELETE_LINK_SUCCESS_MESSAGE: 'Flow link has been deleted.',
  DELETE_LINK_ERROR_MESSAGE: 'Error during deleting flow link.',
  DELETE_TEE_REMOVE_EXISTING_FLOW_LINKS_ERROR_MESSAGE: (linksCount: number) =>
    `Remove ${linksCount} flow links first before deleting tee`,
  DELETE_TEE_REMOVE_EXISTING_FLOW_LINK_ERROR_MESSAGE: 'Remove 1 flow link first before deleting tee',
  CONTEXT_MENU_ADD_FLOW_LINK: 'Add flow link',
  CONTEXT_MENU_ADD_FLOW_LINK_TOOLTIP: 'Add flow link',
  TOO_MANY_LINKS_ON_SELECTED_TEE: 'Too many links on selected Tee. Create a new Tee.',
  CONTEXT_MENU_RENAME_TEE: 'Rename tee',
  CONTEXT_MENU_RENAME_TEE_TOOLTIP: 'Rename tee',
  RENAME_TEE_SUCCESS_MESSAGE: 'Tee has been renamed.',
  RENAME_TEE_ERROR_MESSAGE: "Something went wrong. Tee hasn't been renamed.",
  DELETE_LINK_VALIDATION_MESSAGE: 'Cannot remove a link connected to a tee. First, remove the tee, and try again.',
  READING_AS: (lastReadingTimer: string) => `Readings as of ${lastReadingTimer} ago`,
  MORE_ALERTS: (alertsNo: number) => `+${alertsNo - 1} alerts`,
  OVERLAPPING_TEES: 'Overlapping tee automatically moved',
  EDIT: 'Edit',
  VIEW: 'View',
  SAVE_MODAL_TITLE: 'You have unsaved changes in your System Map',
  SAVE_MODAL_BODY: 'You are about to switch to view mode with unsaved changes. Save now or leave without saving.',
  SAVE_MODAL_CONFIRM: 'Save',
  SAVE_MODAL_CANCEL: 'Leave Without Saving',
  SAVE_GRAPH_ERROR: "Something went wrong. System Map hasn't been saved.",
};
