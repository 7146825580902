import { statusIdToKeyAdapterIntellistation } from '@marlin/shared/utils/datapoint-mappers';
import { useMemo } from 'react';

import { TNodeData, TStatus } from '../types';

export const useEquipmentMode = (nodeData: TNodeData) => {
  const mode = useMemo(
    () => nodeData.lastReadingValues.find((reading) => reading.name.toLowerCase() === 'overridemode'),
    [nodeData.lastReadingValues]
  );
  const modeValue = useMemo(() => statusIdToKeyAdapterIntellistation(mode?.value || ''), [mode]);
  const status: TStatus = modeValue === 'sanitize' ? 'error' : modeValue === 'startup' ? 'warning' : 'success';

  return useMemo(
    () => ({
      modeValue,
      status,
    }),
    [modeValue, status]
  );
};
