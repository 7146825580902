import { formatTimezone, getUserTimezone } from '@marlin/shared/utils-common-date';
import { useMemo } from 'react';

export const useGetSustainabilityFormattedTimezone = (timezoneName?: string | null) => {
  const timezone = useMemo(() => {
    const userTimezoneName = timezoneName || getUserTimezone();

    return `${formatTimezone(userTimezoneName)?.nameComponents[1]}, ${
      formatTimezone(userTimezoneName)?.nameComponents[2]
    }`;
  }, [timezoneName]);

  return {
    timezone,
  };
};
