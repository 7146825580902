import { getHttpClient } from '@marlin/shared/utils/react-query';
import { QueryClient } from '@marlin/shared/utils/react-query';

import { TOperation } from '../gateway.model.schema';
import { paths } from '../infrastructure/api';
import { queryKey } from '../query-key.enum';
import { ExecuteOperationResponse, TExecuteOperationResponse } from '../schema/gateway.schema';

export const queryClient = new QueryClient();

export const executeOperation = async (
  params: TOperation,
  signal?: AbortSignal
): Promise<TExecuteOperationResponse> => {
  const response = await getHttpClient().get(paths.EXECUTES_OPERATION, { params, signal });

  return ExecuteOperationResponse.parse(response);
};

type TExecuteOperationParams = {
  params: TOperation;
};

export const executeOperationQuery = ({ params }: TExecuteOperationParams) => {
  return queryClient.fetchQuery({
    retry: (failureCount) => failureCount < 3,
    retryDelay: 1000,
    queryKey: queryKey.EXECUTE_OPERATION(params),
    queryFn: ({ signal }) => executeOperation(params, signal),
  });
};
