import { ALERT_STATUS_FILTER, TRuleCategory } from '@marlin/alert/data-access/alert-action';
import { MarlinTheme } from '@marlin/shared/theme';
import { Chip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../../content';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.typography.pxToRem(8),
  },
}));

interface IChips {
  status: ALERT_STATUS_FILTER;
  ruleCategory: TRuleCategory;
}

export const Chips = ({ status, ruleCategory }: IChips) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <Chip size="small" label={ruleCategory} />
      {ruleCategory === 'Information' ? (
        <Chip size="small" label={content.EVENT_LOG_DETAILS.SETTING_CHANGE} />
      ) : (
        <Chip
          size="small"
          label={
            status === ALERT_STATUS_FILTER.RESOLVED ? content.EVENT_LOG_DETAILS.RESOLVED : content.EVENT_LOG_DETAILS.NEW
          }
        />
      )}
    </div>
  );
};
