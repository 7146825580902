import { MarlinTheme } from '@marlin/shared/theme';
import { Chip, Typography, alpha } from '@mui/material';
import { ReactElement } from 'react';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<{ error?: boolean }>()((theme: MarlinTheme, { error }) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  chip: {
    marginLeft: theme.typography.pxToRem(8),
    backgroundColor: error ? theme.palette.error.main : alpha(theme.palette.primary.main, 0.08),
    color: error ? theme.palette.error.contrastText : theme.palette.primary.dark,
  },
  icon: {
    '& > svg': {
      width: theme.typography.pxToRem(20),
      height: theme.typography.pxToRem(20),
      fill: alpha(theme.palette.action.active, 0.54),
    },
    maxHeight: theme.typography.pxToRem(20),
    marginRight: theme.typography.pxToRem(4),
  },
}));

interface ISummaryItemProps {
  title: string;
  value: number;
  icon: ReactElement;
  error?: boolean;
}

export const SummaryItem = ({ icon, value, title, error }: ISummaryItemProps) => {
  const { classes } = useStyles({ error });
  return (
    <div className={classes.container}>
      <div className={classes.icon}>{icon}</div>
      <Typography variant="body2">{title}</Typography>
      <Chip className={classes.chip} label={value} size="small" />
    </div>
  );
};
