import { MarlinTheme } from '@marlin/shared/theme';
import { RecipientTags } from '@marlin/shared/ui-recipient-tags';
import { useFeatureFlagsContext } from '@marlin/shared/utils-common-feature-flags-context';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import PendingActionsRoundedIcon from '@mui/icons-material/PendingActionsRounded';
import TextsmsRoundedIcon from '@mui/icons-material/TextsmsRounded';
import { makeStyles } from 'tss-react/mui';

import { ChannelWrapper } from './channel-wrapper.component';
import { content } from './content';
import { CHANNEL_TYPE, IExtendedChannels } from './types';

interface IChannelsProps {
  channels: IExtendedChannels;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  channelRow: {
    display: 'flex',
    marginBottom: theme.typography.pxToRem(5),
  },
}));

export function Channels({ channels }: IChannelsProps): JSX.Element {
  const { classes } = useStyles();
  const { hotSos } = useFeatureFlagsContext();

  return (
    <>
      <ChannelWrapper channel={channels[CHANNEL_TYPE.IN_APP]} label={content.ACTIONS.IN_APP_ALERT} />
      <div className={classes.channelRow}>
        <ChannelWrapper
          channel={channels[CHANNEL_TYPE.EMAIL]}
          icon={<EmailRoundedIcon />}
          label={content.ACTIONS.EMAIL}
        >
          <RecipientTags
            channelType={CHANNEL_TYPE.EMAIL}
            users={channels[CHANNEL_TYPE.EMAIL]?.recipients ?? []}
            numberOfUsersToDisplay={0}
            automation
          />
        </ChannelWrapper>
        <ChannelWrapper channel={channels[CHANNEL_TYPE.SMS]} icon={<TextsmsRoundedIcon />} label={content.ACTIONS.TEXT}>
          <RecipientTags
            channelType={CHANNEL_TYPE.SMS}
            users={channels[CHANNEL_TYPE.SMS]?.recipients ?? []}
            numberOfUsersToDisplay={0}
            automation
          />
        </ChannelWrapper>
      </div>
      {/* TODO: unify HotSoS component */}
      {hotSos && (
        <ChannelWrapper
          channel={channels[CHANNEL_TYPE.HOT_SOS]}
          icon={<PendingActionsRoundedIcon />}
          label={content.ACTIONS.HOT_SOS}
        />
      )}
    </>
  );
}
