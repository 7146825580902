import {
  AuthenticationResult,
  BrowserAuthError,
  IPublicClientApplication,
  InteractionRequiredAuthError,
  RedirectRequest,
} from '@azure/msal-browser';
import { Observable, from } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { RequestService } from './request.service';

export class TokenService {
  public constructor(
    private readonly authMsalService: IPublicClientApplication,
    private readonly requestService: RequestService
  ) {}

  public acquireToken(): Observable<AuthenticationResult | void> {
    return from(this.authMsalService.acquireTokenSilent(this.requestService.getSilentRequest())).pipe(
      tap((authenticationResult: AuthenticationResult) => this.setActiveAccount(authenticationResult)),
      catchError((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          return this.authMsalService.acquireTokenRedirect(this.requestService.getRedirectRequest() as RedirectRequest);
        } else if (error instanceof BrowserAuthError) {
          return this.authMsalService.acquireTokenRedirect(this.requestService.getRedirectRequest() as RedirectRequest);
        }
        throw error;
      })
    );
  }

  private setActiveAccount = (authenticationResult: AuthenticationResult): void => {
    if (!authenticationResult || !authenticationResult.account) {
      return;
    }

    this.authMsalService.setActiveAccount(authenticationResult.account);
  };
}
