import { mockedIds } from './id';

export const krakowDetails = {
  html_attributions: [],
  result: {
    geometry: {
      location: {
        lat: 25.7877778,
        lng: -80.3805556,
      },
    },
    address_components: [
      {
        long_name: 'Apt. 768',
        short_name: 'Apt. 768',
        types: ['subpremise'],
      },
      {
        long_name: '23601',
        short_name: '23601',
        types: ['street_number'],
      },
      {
        long_name: 'Kuhn Mountains',
        short_name: 'Kuhn Mountains',
        types: ['route'],
      },
      {
        long_name: 'Krakow',
        short_name: 'Krakow',
        types: ['locality', 'political'],
      },
      {
        long_name: 'Ohio',
        short_name: 'OH',
        types: ['administrative_area_level_1', 'political'],
      },
      {
        long_name: 'United States',
        short_name: 'US',
        types: ['country', 'political'],
      },
      {
        long_name: '79123-3701',
        short_name: '79123-3701',
        types: ['postal_code'],
      },
    ],
    formatted_address: 'Apt. 768 23601 Kuhn Mountains, Krakow, OH 79123-3701',
    name: mockedIds.krakow,
  },
  status: 'OK',
};

export const wroclawDetails = {
  html_attributions: [],
  result: {
    geometry: {
      location: {
        lat: 25.7877778,
        lng: -80.3805556,
      },
    },
    address_components: [
      {
        long_name: '32230',
        short_name: '32230',
        types: ['street_number'],
      },
      {
        long_name: 'Konopelski Ranch',
        short_name: 'Konopelski Ranch',
        types: ['route'],
      },
      {
        long_name: 'Wroclaw',
        short_name: 'Wroclaw',
        types: ['locality', 'political'],
      },
      {
        long_name: 'Ohio',
        short_name: 'OH',
        types: ['administrative_area_level_1', 'political'],
      },
      {
        long_name: 'United States',
        short_name: 'US',
        types: ['country', 'political'],
      },
      {
        long_name: '07151',
        short_name: '07151',
        types: ['postal_code'],
      },
    ],
    formatted_address: '32230 Konopelski Ranch, Wroclaw, OH 07151',
    name: mockedIds.wroclaw,
  },
  status: 'OK',
};

export const defaultDetails = {
  html_attributions: [],
  result: {},
  status: 'ZERO_RESULTS',
};

export const createDetailsResponse = (placeId: string) => {
  switch (placeId) {
    case mockedIds.krakow: {
      return krakowDetails;
    }
    case mockedIds.wroclaw: {
      return wroclawDetails;
    }
    default: {
      return defaultDetails;
    }
  }
};
