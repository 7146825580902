export function getSeriesForRangeBar<
  TSeries extends {
    data: Array<{
      x: number;
      y: number | null;
    }>;
  }
>(series: TSeries[]): TSeries[] {
  return series.map<TSeries>((s) => {
    return {
      ...s,
      data: [...s.data, { x: new Date().valueOf(), y: 0 }],
    };
  });
}
