import { LeaveRouteGuard } from '@marlin/shared/utils-router';

import { ConfirmationModal } from './shared/confirmation-modal.component';

interface IConfigureGatewayLeaveGuardProps {
  isDirty: boolean;
}

export const ConfigureGatewayLeaveGuard = ({ isDirty }: IConfigureGatewayLeaveGuardProps) => {
  const handleOnConfirm = (onConfirm: () => void) => {
    onConfirm();
  };

  return (
    <LeaveRouteGuard when={isDirty}>
      {({ onCancel, onConfirm }) => {
        return <ConfirmationModal onCancel={onCancel} onConfirm={() => handleOnConfirm(onConfirm)} />;
      }}
    </LeaveRouteGuard>
  );
};
