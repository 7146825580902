import { getUnitType, parseDeviceReadings } from '@marlin/shared/utils-format-reading';
import { useMediaQuery, useTheme } from '@mui/material';
import { Typography } from '@mui/material';
import { useMemo } from 'react';

import { getCorrectUnit, transformBigNumber } from '../utils';
import { useStyles } from './average.styles';
import { content } from './content';

interface IAverageProps {
  variant: 'WEEK' | 'MONTH' | 'YEAR';
  averageVolume?: number | null;
  isExpanded?: boolean;
}

export const Average = ({ averageVolume, variant, isExpanded = false }: IAverageProps) => {
  const { classes } = useStyles({ isExpanded });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const averageValue = useMemo(() => {
    switch (variant) {
      case 'WEEK': {
        return content.AVERAGE_VALUE_WEEK(
          transformBigNumber(parseDeviceReadings(averageVolume, 'PULSE_METER', 'integer').value ?? 0),
          getCorrectUnit(getUnitType('PULSE_METER'))
        );
      }
      case 'MONTH': {
        return content.AVERAGE_VALUE_MONTH(
          transformBigNumber(parseDeviceReadings(averageVolume, 'PULSE_METER', 'integer').value ?? 0),
          getCorrectUnit(getUnitType('PULSE_METER'))
        );
      }
      case 'YEAR': {
        return content.AVERAGE_VALUE_YEAR(
          transformBigNumber(parseDeviceReadings(averageVolume, 'PULSE_METER', 'integer').value ?? 0),
          getCorrectUnit(getUnitType('PULSE_METER'))
        );
      }
      default:
        return null;
    }
  }, [variant, averageVolume]);

  return (
    <div className={classes.container}>
      <div>
        <Typography className={classes.averagePropText} data-testid={`${variant.toLowerCase()}-average-prop-text`}>
          {isMobile ? content.AVERAGE_MOBILE : content.AVERAGE}
        </Typography>
      </div>
      <div>
        <Typography className={classes.averageValueText} data-testid={`${variant.toLowerCase()}-average-value`}>
          {averageValue}
        </Typography>
      </div>
    </div>
  );
};
