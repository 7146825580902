import { MarlinTheme } from '@marlin/shared/theme';
import { FormField, IFormControlProps, IFormTypedProps } from '@marlin/shared/ui-form-common';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { ControllerProps, ControllerRenderProps, FieldError, FieldValues } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';
import { TUnits } from './duration.schema';

type TRenderProps = Partial<Omit<ControllerRenderProps, 'ref'>>;

interface IDurationUnitOption {
  id: TUnits;
  value: TUnits;
  name: string;
}

interface ISelectChildren {
  children?: (props: {
    options: IDurationUnitOption[];
    value: TRenderProps['value'];
    onDelete: (id: string) => void;
  }) => JSX.Element;
}

interface ISelectProps extends ISelectChildren {
  multiple?: boolean;
}

interface ISelectControlProps extends ISelectProps, TRenderProps {
  error?: FieldError;
  disabled?: boolean;
  defaultValue?: TUnits;
  required?: boolean;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  deviceTypeOption: {
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.typography.pxToRem(10),
    flexWrap: 'wrap',
  },

  active: {
    color: theme.palette.action.active,
  },

  disabled: {
    color: theme.palette.text.secondary,
  },

  emptyOption: {
    height: '36px',
  },
}));

const unitOptions: IDurationUnitOption[] = [
  {
    id: 'MINUTES',
    value: 'MINUTES',
    name: content.TIME_UNITS_MINUTES,
  },
  {
    id: 'HOURS',
    value: 'HOURS',
    name: content.TIME_UNITS_HOURS,
  },
  {
    id: 'DAYS',
    value: 'DAYS',
    name: content.TIME_UNITS_DAYS,
  },
];

const SelectControl = ({ error, disabled = false, required = false, children, ...rest }: ISelectControlProps) => {
  const { classes } = useStyles();

  return (
    <FormControl error={!!error} fullWidth={true}>
      <InputLabel id="duration-select-label" required={required}>
        {content.TIME_UNIT_LABEL}
      </InputLabel>
      <Select
        id="duration-select"
        labelId="duration-select-label"
        label={content.TIME_UNIT_LABEL}
        required={required}
        fullWidth={true}
        disabled={disabled}
        error={!!error}
        {...rest}
      >
        {unitOptions.map(({ value, name }) => (
          <MenuItem value={value} key={value}>
            <div className={classes.deviceTypeOption}>{name}</div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export function DurationUnitControl<TFieldValues extends FieldValues>(
  props: IFormControlProps<TFieldValues> & ISelectProps
): JSX.Element;
export function DurationUnitControl<
  TFieldValues extends FieldValues = object,
  TName extends ControllerProps<TFieldValues>['name'] = ControllerProps<TFieldValues>['name']
>(props: IFormTypedProps<TFieldValues, TName> & ISelectProps): JSX.Element;

export function DurationUnitControl<
  TFieldValues extends FieldValues,
  TName extends ControllerProps<TFieldValues>['name'] = ControllerProps<TFieldValues>['name']
>(params: (IFormControlProps<TFieldValues> | IFormTypedProps<TFieldValues, TName>) & ISelectProps) {
  if (params.control) {
    return (
      <FormField {...params}>
        {(props) => (
          <SelectControl
            {...props}
            disabled={params?.disabled}
            defaultValue={params?.defaultValue}
            required={params?.required}
            multiple={params?.multiple}
            children={params.children}
          />
        )}
      </FormField>
    );
  }

  return (
    <FormField<TFieldValues> {...params}>
      {(props) => (
        <SelectControl
          {...props}
          disabled={params?.disabled}
          required={params?.required}
          multiple={params?.multiple}
          children={params.children}
        />
      )}
    </FormField>
  );
}
