import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    flexWrap: 'wrap',
    '& > div': {
      marginBottom: theme.typography.pxToRem(12),
    },
    columnGap: theme.typography.pxToRem(16),
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    flexWrap: 'wrap',
    columnGap: theme.typography.pxToRem(16),
    '& > div': {
      marginBottom: theme.typography.pxToRem(8),
    },
  },
  filterItem: {
    width: theme.typography.pxToRem(245),
    height: theme.typography.pxToRem(56),
  },
  filterDeviceTypeItem: {
    width: theme.typography.pxToRem(245),
    height: theme.typography.pxToRem(56),
  },
  filterChipWrapper: {
    alignSelf: 'baseline',
  },
  wrapper: {
    marginTop: theme.typography.pxToRem(16),
  },
  btnWrapper: {
    margin: `${theme.typography.pxToRem(10)} 0 ${theme.typography.pxToRem(10)} 0`,
  },
  paper: {
    borderEndEndRadius: 0,
    borderEndStartRadius: 0,
  },
}));
