import { z } from 'zod';

import { sortingSchema } from './utils.schema';

export const getLocationParamsSchema = z.object({
  search: z.string().nullish(),
  parentLocationIds: z.array(z.string()).nullish(),
  page: z.number(),
  pageSize: z.number(),
  sorting: sortingSchema.optional(),
});

export type TLocationParamsSchema = z.infer<typeof getLocationParamsSchema>;
