import { TWaterPriceSetting } from '@marlin/account-data-access-organization';
import { TSankeyDevice, useWaterSankey } from '@marlin/asset/data-access/home-dashboard';
import { ISankeyProps, TPeriodDate } from '@marlin/shared/ui/chart';
import { displayVolumePriceUtil } from '@marlin/shared/utils-format-reading';
import { useCallback, useMemo } from 'react';

import { content } from '../content';
import { TSankeyUnit } from './constants';
import { createArrayIndexMap } from './utils';

export interface IUseSankeyDataProps {
  waterPrice?: TWaterPriceSetting;
  selectedUnit: TSankeyUnit;
  currentPeriod: {
    from: TPeriodDate;
    to: TPeriodDate;
  };
}

export interface ISankeyData {
  data: ISankeyProps;
  isLoading: boolean;
  isError: boolean;
}

export const useSankeyData = ({ waterPrice, selectedUnit, currentPeriod }: IUseSankeyDataProps): ISankeyData => {
  const waterSankeyParams = useMemo(() => {
    return {
      periodStart: currentPeriod.from?.toISOString() || '',
      periodEnd: currentPeriod.to?.toISOString() || '',
    };
  }, [currentPeriod.from, currentPeriod.to]);

  const { data, isLoading, isError } = useWaterSankey(waterSankeyParams);

  const nodeIdIndexMap = useMemo(() => {
    return createArrayIndexMap((data?.devices || []).map(({ id }) => id));
  }, [data?.devices]);

  const calculateNodeValue = useCallback(
    (value: number) => {
      if (!waterPrice || selectedUnit === 'volume') {
        return value;
      }

      return displayVolumePriceUtil(value, waterPrice.price, waterPrice.uoM);
    },
    [selectedUnit, waterPrice]
  );

  const calculateLinkValue = useCallback(
    (device: TSankeyDevice) => {
      if (!device) {
        return 0;
      }

      const numberOfSourceConnections = data?.sources.filter((source) => source === device.id).length || 0;

      if (numberOfSourceConnections > 0) {
        // TODO: Currently there is no way to get the value per connection based on the system map
        return device.volume / numberOfSourceConnections;
      }

      return 0;
    },
    [data?.targets]
  );

  const formatNodeLabel = useCallback(
    (nodeLabel: string, value: number) => {
      if (!waterPrice || selectedUnit === 'volume') {
        // TODO: use unit of measure from the API
        return content.SANKEY_LABEL_VOLUME(nodeLabel, value, 'gal');
      }

      return content.SANKEY_LABEL_DOLLARS(nodeLabel, value);
    },
    [selectedUnit, waterPrice]
  );

  const nodes = useMemo(() => {
    return (
      data?.devices.map(({ name, volume }) => {
        return formatNodeLabel(name, calculateNodeValue(volume));
      }) || []
    );
  }, [calculateNodeValue, data?.devices, formatNodeLabel]);

  const sources: number[] = useMemo(() => {
    return data?.sources.map((source) => nodeIdIndexMap.get(source) || 0) || [];
  }, [data?.sources, nodeIdIndexMap]);

  const targets: number[] = useMemo(() => {
    return data?.targets.map((target) => nodeIdIndexMap.get(target) || 0) || [];
  }, [data?.targets, nodeIdIndexMap]);

  const values = useMemo(() => {
    return (data?.sources || []).map((source) => {
      const sourceDevice = data?.devices.find(({ id }) => id === source);

      if (!sourceDevice) {
        return 0;
      }

      return calculateLinkValue(sourceDevice);
    });
  }, [calculateLinkValue, data?.devices, data?.sources]);

  return useMemo(
    () => ({
      data: {
        sources,
        targets,
        values,
        nodes,
      },
      isLoading,
      isError,
    }),
    [nodes, sources, targets, values, isLoading, isError]
  );
};
