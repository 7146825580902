import { useMemo } from 'react';

import { content } from '../../../content';
import { Buttons } from '../../buttons/buttons.component';
import { EQUIPMENT_STATUS } from '../../onboarding.schema';
import { useOnboardingState } from '../../use-onboarding-state.hook';
import { checkIfDuplicatedNames, checkIfDuplicatedSerialNumbers } from './utils';

interface IActionButtonProps {
  onNext: () => void;
  onCancel: () => void;
  isLoading: boolean;
}

export const RegisterEquipmentButtons = ({ onNext, onCancel, isLoading }: IActionButtonProps) => {
  const { onboardingState } = useOnboardingState();

  const isDisabled = useMemo(() => {
    return (
      onboardingState.equipment.length === 0 ||
      onboardingState.equipment.some((eq) => eq.status === EQUIPMENT_STATUS.ERROR) ||
      checkIfDuplicatedNames(onboardingState.equipment) ||
      checkIfDuplicatedSerialNumbers(onboardingState.equipment)
    );
  }, [onboardingState]);

  return (
    <Buttons
      onNext={onNext}
      onCancel={onCancel}
      isDisabled={isLoading || isDisabled}
      nextLabel={content.COMPLETE}
      cancelLabel={content.BACK}
    />
  );
};
