import { THomeLocationSchema } from '@marlin/asset/data-access/home-dashboard';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { Button } from '@mui/material';
import { useCallback } from 'react';

import { content } from '../../content';
import { useStyles } from './card-no-assets.component.styles';

type THomeCardNoAssetsProps = {
  location: THomeLocationSchema;
};

const equipmentHubPage = routes.equipments.list.url();
const locationDetailsPage = (locationId: string) => routes.locations.details.url(locationId);

const HomeCardNoAssets = ({ location }: THomeCardNoAssetsProps) => {
  const { classes } = useStyles();
  const router = useRouter();
  const routeToLocationDetailsPage = useCallback(() => locationDetailsPage(location.locationId), [location.locationId]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.noAssetsWrapper}>
        <div>
          <div className={classes.noAssetsLine1}>
            {content.NO_ASSETS_LINE} {location.locationName}.
          </div>
        </div>
        <div>
          <Button className={classes.noAssetsCta} variant="contained" onClick={() => router.goTo(equipmentHubPage)}>
            {content.NO_ASSETS_CTA}
          </Button>
          <Button
            className={classes.manageLocationButton}
            variant="outlined"
            onClick={() => router.goTo(routeToLocationDetailsPage())}
          >
            {content.MANAGE_LOCATION}
          </Button>
        </div>
      </div>
    </div>
  );
};

export { HomeCardNoAssets };
