import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { useEffect, useMemo } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';

const debouncedTime = 500;

export const useSubmitOnChange = <TFieldValues extends FieldValues>(
  form: UseFormReturn<TFieldValues, object>,
  onSubmit: (props: TFieldValues) => void,
  customDebouncedTime?: number
) => {
  const submit = useMemo(
    () =>
      debounce(
        form.handleSubmit((values) => {
          onSubmit(values as TFieldValues);
        }),
        customDebouncedTime || debouncedTime
      ),
    [customDebouncedTime, form, onSubmit]
  );

  const resetForm = (values?: TFieldValues) => {
    form.reset(values);
    submit();
  };

  useEffect(() => {
    const callback = (values: unknown) => {
      if (!isEqual(form.formState.defaultValues, values) || form.formState.isDirty) {
        submit();
      }
    };

    const subscription = form.watch(callback);
    return () => subscription.unsubscribe();
  }, [form, form.formState.isDirty, form.watch, submit]);

  return {
    submit,
    resetForm,
  };
};
