import { MarlinTheme } from '@marlin/shared/theme';
import { useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';
import { Dot } from '../shared/dot.component';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  legend: {
    display: 'flex',
    gap: theme.typography.pxToRem(12),
    flexWrap: 'wrap',
  },
}));

export const Legend = () => {
  const { classes } = useStyles();
  const theme = useTheme<MarlinTheme>();
  const chartColors = Array.from(Object.values(theme.palette.charting));

  return (
    <div className={classes.legend}>
      <Dot color={chartColors[0]}>{content.SELECTED}</Dot>
      <Dot color={chartColors[1]}>{content.PREVIOUS}</Dot>
    </div>
  );
};
