const homePortfolioBasePath = '/';
const createPortfolioPath = '/create';
const removedPortfolioPath = '/removed';

const settingsBasePortfolioPath = '/settings';
const settingsPeoplePath = 'people';
const settingsDetailsPath = 'details';
const settingsOrganizationsPath = 'organizations';
const settingsInvitePath = 'invite';
const settingsEditUserBasePath = 'user/:userId/update';
const invitationPath = `/:portfolioId/invitation/:invitationId/validate`;

export const routes = {
  home: {
    root: homePortfolioBasePath,
  },
  create: {
    root: createPortfolioPath,
  },
  removed: {
    root: removedPortfolioPath,
  },
  settings: {
    root: settingsBasePortfolioPath,
    people: {
      path: settingsPeoplePath,
      url: () => `${settingsBasePortfolioPath}/${settingsPeoplePath}`,
    },
    editUser: {
      path: settingsEditUserBasePath,
      details: {
        url: (userId: string) => `${settingsBasePortfolioPath}/${settingsEditUserBasePath}`.replace(':userId', userId),
      },
    },
    organizations: {
      path: settingsOrganizationsPath,
      url: () => `${settingsBasePortfolioPath}/${settingsOrganizationsPath}`,
    },
    details: {
      path: settingsDetailsPath,
      url: () => `${settingsBasePortfolioPath}/${settingsDetailsPath}`,
    },
    invite: {
      path: settingsInvitePath,
      url: () => `${settingsBasePortfolioPath}/${settingsInvitePath}`,
    },
  },
  invitation: {
    path: invitationPath,
  },
};
