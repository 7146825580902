import { MarlinTheme } from '@marlin/shared/theme';
import { Link } from '@marlin/shared/ui-page';
import MiscellaneousServicesRoundedIcon from '@mui/icons-material/MiscellaneousServicesRounded';
import { useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { ERROR_TYPES } from '../types';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },

  icon: {
    marginRight: theme.typography.pxToRem(8),
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(20),
  },

  link: {
    fontSize: theme.typography.pxToRem(14),
  },
}));

interface IAutomationLinkSectionProps {
  name: string;
  link: string;
  errorType: ERROR_TYPES;
}

export function AutomationLinkSection({ name, link, errorType }: IAutomationLinkSectionProps) {
  const { classes } = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (isMobile || errorType === ERROR_TYPES.LOST_COMMUNICATION) {
    return null;
  }

  return (
    <div className={classes.container}>
      <MiscellaneousServicesRoundedIcon className={classes.icon} />
      <Link className={classes.link} to={link}>
        {name}
      </Link>
    </div>
  );
}
