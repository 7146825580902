import { PORTFOLIO_ROLE } from '@marlin/shared/utils-role';
import MapsHomeWorkRoundedIcon from '@mui/icons-material/MapsHomeWorkRounded';
import { FormHelperText, MenuItem } from '@mui/material';
import { ReactNode } from 'react';
import { ControllerRenderProps, FieldError } from 'react-hook-form';

import { content } from '../content';
import { MenuItemSelect } from '../menu-item-select/menu-item-select.component';
import { RoleListSelect } from '../role-list-select/role-list-select.component';

type TRenderProps = Partial<Omit<ControllerRenderProps, 'ref'>>;

interface ISelectControlProps extends TRenderProps {
  error?: FieldError;
  disabled?: boolean;
  icon?: ReactNode;
  title?: ReactNode;
}

const availableRoles = [
  {
    value: PORTFOLIO_ROLE.MANAGER,
    name: content.PORTFOLIO_ROLE_MANAGER_LABEL,
    helperText: content.PORTFOLIO_ROLE_MANAGER_HELPER_TEXT,
  },
  {
    value: PORTFOLIO_ROLE.VIEWER,
    name: content.PORTFOLIO_ROLE_VIEWER_LABEL,
    helperText: content.PORTFOLIO_ROLE_VIEWER_HELPER_TEXT,
  },
];

export const PortfolioRoleSelect = ({ icon = <MapsHomeWorkRoundedIcon />, title, ...props }: ISelectControlProps) => {
  return (
    <RoleListSelect icon={icon} title={title}>
      <MenuItemSelect {...props}>
        {availableRoles.map((role) => (
          <MenuItem key={role.value} value={role.value}>
            <div>
              <span>{role.name}</span>
              <FormHelperText>{role.helperText}</FormHelperText>
            </div>
          </MenuItem>
        ))}
      </MenuItemSelect>
    </RoleListSelect>
  );
};
