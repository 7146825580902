import { MarlinTheme } from '@marlin/shared/theme';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.typography.pxToRem(4),
    alignItems: 'center',
  },
}));

interface IHyperlinkComponentProps {
  link: string | null;
}

export const HyperlinkComponent = ({ link }: IHyperlinkComponentProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container} data-testid="hyperlink-component">
      <OpenInNewIcon fontSize="small" />
      {link && (
        <a href={link}>
          <Typography>{content.GO_TO_HELP_MANUAL}</Typography>
        </a>
      )}
    </div>
  );
};
