export interface ICsvRow {
  [key: string]: string | number | null;
}

export type TCsvLabels<TRow extends ICsvRow> = {
  [key in keyof TRow]: string;
};

export interface ICsvData<TRow extends ICsvRow> {
  data: TRow[];
  labels: TCsvLabels<TRow>;
}

export function csvDownload<TRow extends ICsvRow>(csvData: ICsvData<TRow>) {
  const csvLabels =
    Object.keys(csvData.labels)
      .map((key) => csvData.labels[key])
      .join(',') + '\n';
  const csvRows = csvData.data
    .map((row) =>
      Object.keys(csvData.labels)
        .map((key) => row[key])
        .join(',')
    )
    .join('\n');
  const csvFile = 'data:text/csv;charset=utf-8,' + encodeURI(csvLabels + csvRows);
  window.open(csvFile);
}
