import { Link } from '@marlin/shared/ui-page';

import { content } from '../translation-keys';
import { BaseError } from './base-error.component';

interface IInvitationNotAcceptedProps {
  organizationName?: string;
}

export const InvitationExpired = ({ organizationName }: IInvitationNotAcceptedProps): JSX.Element => {
  return (
    <BaseError
      title={content.INVITATION_EXPIRED_TITLE}
      subtitle={
        <>
          {content.INVITATION_EXPIRED_BODY(organizationName || 'Organization')}
          <br />
          <br />
          {content.SUPPRORT_TEXT}&nbsp;
          {
            <Link to={`mailto:${content.SUPPRORT_LINK}`} external>
              {content.SUPPRORT_LINK}
            </Link>
          }
          {'.'}
        </>
      }
    />
  );
};
