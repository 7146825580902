import { MarlinTheme } from '@marlin/shared/theme';
import { Paper } from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { ALERT_STATUS_FILTER, IAlert } from '../types';
import { CurrentAlertContent } from './alert-content-current.component';
import { ResolvedAlertContent } from './alert-content-resolved.component';
import { SnoozedAlertContent } from './alert-content-snoozed.component';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  alertPaper: {
    //TODO: try to remove minus margin in way which not cause disappear line between current and resolved card
    marginTop: -1,
    padding: theme.typography.pxToRem(24),
    borderLeft: 0,
    borderRight: 0,
  },

  alertPaperExtended: {
    marginTop: -1,
    padding: theme.typography.pxToRem(24),
    borderLeft: 0,
    borderRight: 0,

    margin: theme.typography.pxToRem(16),
    borderRadius: theme.typography.pxToRem(4),
    border: `${theme.typography.pxToRem(1)} solid`,
    borderColor: theme.palette.divider,

    width: `calc(50% - ${theme.typography.pxToRem(24)})`,

    '&:nth-child(1n)': {
      marginRight: theme.typography.pxToRem(8),
    },

    '&:nth-child(2n)': {
      marginLeft: theme.typography.pxToRem(8),
    },
  },
}));

interface IAlertCardProps {
  alert: IAlert;
  automationLink: (deviceId: string, isSystemRule: boolean) => string;
  isExtended?: boolean;
}

interface IAlertContentSwitcherProps {
  alert: IAlert;
  isMobile: boolean;
  automationLink: (deviceId: string, isSystemRule: boolean) => string;
}

function AlertContentSwitcher({
  alert,
  isMobile = false,
  automationLink,
}: IAlertContentSwitcherProps): JSX.Element | null {
  switch (alert.status) {
    case ALERT_STATUS_FILTER.CURRENT: {
      return <CurrentAlertContent alert={alert} isMobile={isMobile} automationLink={automationLink} />;
    }
    case ALERT_STATUS_FILTER.RESOLVED: {
      return <ResolvedAlertContent alert={alert} isMobile={isMobile} automationLink={automationLink} />;
    }
    case ALERT_STATUS_FILTER.SNOOZED: {
      return <SnoozedAlertContent alert={alert} isMobile={isMobile} automationLink={automationLink} />;
    }
    case ALERT_STATUS_FILTER.ALL:
    default: {
      return null;
    }
  }
}

export function AlertCard({ alert, automationLink, isExtended }: IAlertCardProps): JSX.Element | null {
  const { classes } = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
      <Paper
        variant="outlined"
        square
        className={isExtended ? classes.alertPaperExtended : classes.alertPaper}
        data-testid="card"
      >
        <AlertContentSwitcher alert={alert} isMobile={isMobile} automationLink={automationLink} />
      </Paper>
    </>
  );
}
