import { queryClient, useMutation } from '@marlin/shared/utils/react-query';

import { updateSettings } from '../infrastructure/settings';
import { queryKey } from './query-key.enum';

export const useUpdateSettings = () => {
  return useMutation({
    mutationFn: updateSettings,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKey.CURRENT_USER() }),
  });
};
