import { IChartSeries, ICsvRow, csvDownload } from '@marlin/shared/utils-chart';
import { dateAdapter, defaultDateTime } from '@marlin/shared/utils-common-date';
import { useCallback } from 'react';

interface IAccumulatedVolumesCsvRow extends ICsvRow {
  id: string;
  name: string;
  timestamp: string;
  value: number | null;
}

export const useAccumulatedVolumesDownload = (chartData: IChartSeries[]) => {
  const handleDownloadClick = useCallback(() => {
    const csvRows: IAccumulatedVolumesCsvRow[] = chartData.reduce((acc, series) => {
      return [
        ...acc,
        ...series.data
          .filter((datapoint) => datapoint.y !== null)
          .map((datapoint) => ({
            id: series.manufacturerId ?? '',
            name: series.name,
            timestamp: dateAdapter.date(datapoint.x)?.format(defaultDateTime) ?? '',
            value: datapoint.y,
          })),
      ];
    }, [] as IAccumulatedVolumesCsvRow[]);
    const csvLabels = {
      id: 'ID',
      name: 'Name',
      timestamp: 'Timestamp',
      value: 'Value',
    };
    csvDownload<IAccumulatedVolumesCsvRow>({ data: csvRows, labels: csvLabels });
  }, [chartData]);

  return { handleDownloadClick };
};
