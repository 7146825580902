import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { useCallback } from 'react';
import { makeStyles } from 'tss-react/mui';

import { AlertCard } from '../card/alert-card.component';
import { IAlert } from '../types';
import { LoadMore } from './load-more.component';
import { LoadingSpinner } from './loading-spinner.component';

export const useStyles = makeStyles()((theme) => ({
  header: {
    padding: `0 ${theme.typography.pxToRem(24)} 0 ${theme.typography.pxToRem(24)}`,
  },

  headerTitle: {
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },

  accordionContainer: {
    boxShadow: 'none',
  },

  accordionContainerExtended: {
    boxShadow: 'none',
    margin: theme.typography.pxToRem(16),
    borderRadius: theme.typography.pxToRem(4),
    border: `${theme.typography.pxToRem(1)} solid`,
    borderColor: theme.palette.divider,
  },

  accordionDetails: {
    padding: 0,
  },

  accordionDetailsExtended: {
    padding: 0,

    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
}));

interface IAlertAccordionProps {
  title: string;
  alerts: IAlert[];
  totalAlertsCount: number;
  defaultExpanded?: boolean;
  isLoading: boolean;
  onOpen: () => void;
  automationLink: (deviceId: string, isSystemRule: boolean) => string;
  onLoadMore: () => void;
  prefix: 'current' | 'snoozed' | 'resolved';
  isExtended?: boolean;
}

export function AlertAccordion({
  title,
  alerts,
  totalAlertsCount,
  defaultExpanded = false,
  onOpen,
  automationLink,
  onLoadMore,
  isLoading,
  prefix,
  isExtended,
}: IAlertAccordionProps) {
  const { classes } = useStyles();

  const handleChange = useCallback(
    (event: unknown, expanded: boolean) => {
      if (expanded) {
        onOpen();
      }
    },
    [onOpen]
  );

  return (
    <Accordion
      square
      onChange={handleChange}
      disableGutters
      defaultExpanded={defaultExpanded}
      className={isExtended ? classes.accordionContainerExtended : classes.accordionContainer}
      data-testid={`drawer-${prefix}-alerts`}
    >
      <AccordionSummary className={classes.header} expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6" className={classes.headerTitle}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={isExtended ? classes.accordionDetailsExtended : classes.accordionDetails}>
        {alerts.map((alert, index) => (
          <AlertCard alert={alert} automationLink={automationLink} key={index} isExtended={isExtended} />
        ))}
        <LoadingSpinner isLoading={isLoading} />
      </AccordionDetails>
      <LoadMore alerts={alerts} totalAlertsCount={totalAlertsCount} onLoadMore={onLoadMore} />
    </Accordion>
  );
}
