import { Skeleton } from '@mui/material';
import { ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

interface IPageLoaderProps {
  children: ReactNode;
  loading: boolean;
  skeletonsCount?: number;
}

export const useStyles = makeStyles()(() => ({
  skeleton: {
    height: '100%',
    width: '100%',
  },
}));

export function SkeletonLoader({ children, loading, skeletonsCount = 1 }: IPageLoaderProps): JSX.Element | null {
  const { classes } = useStyles();

  return (
    <>
      {loading
        ? Array.from({ length: skeletonsCount }).map((_, index) => (
            <Skeleton key={index} className={classes.skeleton} />
          ))
        : children}
    </>
  );
}
