import { MarlinTheme } from '@marlin/shared/theme';
import { modelContent } from '@marlin/shared/utils/datapoint-mappers';
import DeviceHubRoundedIcon from '@mui/icons-material/DeviceHubRounded';
import { ReactElement } from 'react';
import { makeStyles } from 'tss-react/mui';

import { maxLabelLength } from '../../../const';
import { NodeTooltip } from '../../utils/node-tooltip.component';
import { TStatus } from '../types';
import { getBorderColorByEquipmentStatus } from '../utils/utils';
import { BaseHeader } from './base-header.component';

interface IStyles {
  status?: TStatus;
}

const useStyles = makeStyles<IStyles>()((theme: MarlinTheme, { status }) => ({
  name: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: theme.typography.pxToRem(16),
    svg: {
      height: theme.typography.pxToRem(20),
    },
    '& > span': {
      height: theme.typography.pxToRem(26),
      color: getBorderColorByEquipmentStatus(status, theme),
      marginLeft: theme.typography.pxToRem(8),
    },
    '& > svg': {
      height: theme.typography.pxToRem(26),
      color: getBorderColorByEquipmentStatus(status, theme),
    },
  },
  model: {
    fontSize: theme.typography.pxToRem(10),
    fontWeight: theme.typography.fontWeightMedium,
  },
  icon: {
    height: theme.typography.pxToRem(20),
  },
}));

export interface IEquipmentHeaderProps {
  name: string;
  model: string;
  icon?: ReactElement;
  status?: TStatus;
  isModeEnabled?: boolean;
  id: string;
  testId?: string;
}

export const EquipmentHeader = ({
  name,
  model,
  status,
  icon = <DeviceHubRoundedIcon />,
  isModeEnabled,
  id,
  testId,
}: IEquipmentHeaderProps) => {
  const { classes } = useStyles({ status });
  const sliceName = name?.length > maxLabelLength;

  return (
    <BaseHeader status={status} testid="main-header" isModeEnabled={isModeEnabled} variant="max" equipmentId={id}>
      <div className={classes.name}>
        <NodeTooltip text={name || ''} when={sliceName}>
          <span data-testid={`equipment-node-${testId}-label`}>
            {sliceName ? `${name?.slice(0, maxLabelLength)}...` : name}
          </span>
        </NodeTooltip>
        {icon}
      </div>
      <div className={classes.model}>{modelContent.MODELS.get(model) ?? model}</div>
    </BaseHeader>
  );
};
