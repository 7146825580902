import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    width: '100%',
    minHeight: theme.typography.pxToRem(80),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: theme.typography.pxToRem(4),
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.primary,
  },
  trendContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  mobileContainer: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: theme.typography.pxToRem(4),
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.primary,
  },
  contentWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.typography.pxToRem(24),
    alignItems: 'center',
  },
  waterUsage: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.typography.pxToRem(8),
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between',
      padding: theme.typography.pxToRem(12),
      gap: 0,
    },
  },
  trends: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: theme.typography.pxToRem(24),
    alignItems: 'center',
  },
  divider: {
    margin: 0,
  },
  waterUsageText: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: theme.typography.pxToRem(20),
  },
  trendsValueText: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: theme.typography.pxToRem(20),
    textTransform: 'uppercase',
    [theme.breakpoints.down('md')]: {
      textTransform: 'none',
      fontSize: theme.typography.pxToRem(16),
      wordBreak: 'break-all',
      maxWidth: theme.typography.pxToRem(250),
    },
  },
  valueText: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: theme.typography.pxToRem(20),
  },
  trendsText: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightLight,
    lineHeight: theme.typography.pxToRem(20),
    textTransform: 'uppercase',
    [theme.breakpoints.down('md')]: {
      textTransform: 'none',
      fontSize: theme.typography.pxToRem(16),
    },
  },
  trendWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.typography.pxToRem(8),
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between',
      padding: theme.typography.pxToRem(12),
      gap: 0,
    },
  },
  icon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.typography.pxToRem(8),
  },
  wrapperTotal: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.typography.pxToRem(4),
  },
  averageWrapper: {
    padding: theme.typography.pxToRem(12),
    paddingTop: 0,
  },
  expandedContent: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.typography.pxToRem(16),
    width: '100%',
    paddingLeft: theme.typography.pxToRem(24),
    paddingRight: theme.typography.pxToRem(24),
    paddingBottom: theme.typography.pxToRem(24),
  },
  iconButton: {
    margin: theme.typography.pxToRem(-8),
  },
  contextMenuMobileWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));
