import { useEffect, useState } from 'react';

import { PERIOD_ENUM } from './period-map';
import { IRange, calculateFromDate } from './utils';

const getPreviousPeriod = (period: PERIOD_ENUM, currentRange: IRange): IRange => {
  if (period === PERIOD_ENUM.CUSTOM) {
    const diffRange = currentRange.to?.diff(currentRange.from, 'minutes');
    const previousPeriodEnd = currentRange.from?.clone().subtract(1, 'minute');
    const previousPeriodStart = previousPeriodEnd?.clone().subtract(diffRange, 'minutes');
    return {
      from: previousPeriodStart ?? null,
      to: previousPeriodEnd ?? null,
    };
  }

  const previousPeriodEnd = currentRange.from?.clone().subtract(1, 'minute');
  const previousPeriodStart = calculateFromDate(period, previousPeriodEnd);

  return {
    from: previousPeriodStart ?? null,
    to: previousPeriodEnd ?? null,
  };
};

export const usePreviousPeriodState = (period: PERIOD_ENUM, currentRange: IRange) => {
  const [range, setRange] = useState<IRange>({
    from: null,
    to: null,
  });

  useEffect(() => {
    setRange(getPreviousPeriod(period, currentRange));
  }, [currentRange, period]);

  return {
    previousPeriod: range,
  };
};
