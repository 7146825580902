import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  topTableWrapper: {
    marginTop: theme.typography.pxToRem(24),
  },
  tableWrapper: {
    marginTop: theme.typography.pxToRem(16),
  },
}));
