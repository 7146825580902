import { routes } from '@marlin/shared/utils-routes';
import { matchPath } from 'react-router-dom';

export const isEditUserPage = (pathname: string) => {
  const match = matchPath(`${routes.settings.root}/${routes.settings.editUser.path}`, pathname);

  return !!match;
};

export const isEditRecipientPage = (pathname: string): boolean => {
  const match = matchPath(`${routes.settings.root}/${routes.settings.editRecipient.path}`, pathname);

  return !!match;
};
