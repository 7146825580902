import { PERMISSIONS, usePermission } from '@marlin/shared/utils-permission';
import { routes } from '@marlin/shared/utils-routes';
import { useCallback, useMemo } from 'react';

import { useRouter } from './hooks/use-router.hook';

interface IUseDeviceHubContextMenu {
  allowDelete: boolean;
  allowEdit: boolean;
  editDevice: (deviceId: string) => void;
}

export const useDeviceHubContextMenu = (): IUseDeviceHubContextMenu => {
  const { goTo } = useRouter();
  const allowDelete = usePermission(PERMISSIONS.DELETE_DEVICE);
  const allowEdit = usePermission(PERMISSIONS.EDIT_DEVICE);

  const editDevice = useCallback(
    (deviceId: string) => {
      const editDeviceUrl = routes.sensors.update.url(deviceId);
      goTo(editDeviceUrl);
    },
    [goTo]
  );

  return useMemo(
    () => ({
      allowDelete,
      allowEdit,

      editDevice,
    }),
    [allowDelete, allowEdit, editDevice]
  );
};
