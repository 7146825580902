import { z } from 'zod';

const layoutSchema = z.object({
  rows: z.number(),
  columns: z.number(),
});

const sensorSchema = z.object({
  label: z.string().nullish(),
  displayDataPointName: z.string().nullish(),
  statusDataPointName: z.string().nullish(),
});

const tagSchema = z.object({
  model: z.string().nullish(),
});

const metadataGroupSchema = z.object({
  row: z.number(),
  column: z.number(),
  handleName: z.string().nullish(),
  handleId: z.string(),
  merged: z.union([z.literal('column'), z.literal('row')]).nullish(),
  sensors: z.array(sensorSchema),
  repeatable: z.union([z.literal('column'), z.literal('row'), z.literal('group')]).nullish(),
});

export const elementTagsSchema = z.object({
  name: z.string().nullish(),
  value: z.string().nullish(),
});

export const metadataSchema = z.object({
  layout: layoutSchema.nullish(),
  metadataGroups: z.array(metadataGroupSchema).nullish(),
  tags: tagSchema.nullish(),
});

export type TMetadataLayout = z.infer<typeof layoutSchema>;
export type TMetadataGroup = z.infer<typeof metadataGroupSchema>;
export type TMetadata = z.infer<typeof metadataSchema>;
export type TSensor = z.infer<typeof sensorSchema>;
