import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    '& .MuiPaper-root': {
      display: 'inline-table',
    },
    '& .MuiList-root': {
      padding: 0,
    },
    marginLeft: theme.typography.pxToRem(24),
    zIndex: 1303, // it should be lower than material Dialog component zIndex: 1300
  },
}));
