import { MarlinTheme } from '@marlin/shared/theme';
import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import { Button } from '@mui/material';
import { useCallback, useContext } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../../content';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  cancelButton: {
    marginLeft: theme.typography.pxToRem(8),
  },
}));

export interface IDeleteEquipmentModalFooterProps {
  onConfirm: () => void;
}

export const DeregisterEquipmentModalFooter = ({ onConfirm }: IDeleteEquipmentModalFooterProps) => {
  const { classes } = useStyles();
  const { modalDispatch } = useContext(ModalContext);

  const handleCloseModal = useCallback(() => {
    modalDispatch({
      type: MODAL_ACTION_TYPE.DISMISS,
    });
  }, [modalDispatch]);

  const handleConfirm = useCallback(() => {
    onConfirm();
    modalDispatch({
      type: MODAL_ACTION_TYPE.DISMISS,
    });
  }, [modalDispatch, onConfirm]);

  return (
    <div data-testid="deregister-modal-footer" className={classes.footer}>
      <Button className={classes.cancelButton} variant="text" onClick={handleCloseModal}>
        {content.DELETE_MODAL.CANCEL}
      </Button>
      <Button className={`${classes.cancelButton}`} variant="contained" color="error" onClick={handleConfirm}>
        {content.DELETE_MODAL.CONFIRM}
      </Button>
    </div>
  );
};
