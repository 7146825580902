import { TGateway } from '@marlin/asset/data-access/gateway';
import { MarlinTheme } from '@marlin/shared/theme';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';
import { GatewayCard } from './gateway-card.component';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  emptyList: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 1,
    backgroundColor: theme.palette.background.secondary,
  },
  container: {
    backgroundColor: theme.palette.background.secondary,
    padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(16)}`,
  },
}));

interface IGatewayListMobileProps {
  data: TGateway[];
  isLoading: boolean;
  isError: boolean;
  refreshData: () => void;
}

export const GatewayListMobile = ({ isLoading, isError, data, refreshData }: IGatewayListMobileProps) => {
  const { classes } = useStyles();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError || !data.length) {
    return <div className={classes.emptyList}>{content.GATEWAY_TABLE_NO_DATA}</div>;
  }

  return (
    <div className={classes.container}>
      {data.map((gateway) => (
        <GatewayCard key={gateway.id} gateway={gateway} refreshData={refreshData} />
      ))}
    </div>
  );
};
