import { TExtractFnReturnType, useQuery } from '@marlin/shared/utils/react-query';

import { waterSummary } from '../infrastructure/water-summary';
import { queryKey } from './query-key.enum';

type TQueryFnType = typeof waterSummary;

export const useWaterSummary = () => {
  return useQuery<TExtractFnReturnType<TQueryFnType>>({
    queryKey: queryKey.WATER_USAGE(),
    queryFn: () => waterSummary(),
  });
};
