import { MarlinTheme } from '@marlin/shared/theme';
import { TDateString } from '@marlin/shared/utils-common-date';
import Popper from '@mui/material/Popper';
import React from 'react';

import { TBucketedAlert } from '../../../../types';
import { MainChartTooltip } from './main-chart-tooltip.component';

export interface ITooltipData {
  dataSeries: { timestamp: TDateString; value: string } | null;
  alertSeries: { timestamp: TDateString; value: string; alert: TBucketedAlert }[];
}

export interface ITooltipComponentProps extends ITooltipData {
  open: boolean;
  anchorEl: HTMLElement | null;
  deviceName: string;
  theme: MarlinTheme;
}

export const ChartTooltip = ({
  open,
  anchorEl,
  dataSeries,
  alertSeries,
  deviceName,
  theme,
}: ITooltipComponentProps) => {
  return (
    <Popper open={open} anchorEl={anchorEl} placement="top" sx={{ zIndex: 5000 }}>
      <MainChartTooltip dataSeries={dataSeries} alertSeries={alertSeries} deviceName={deviceName} theme={theme} />
    </Popper>
  );
};
