import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import { paths } from './api';
import { TCreateRecipient, TRecipient, createRecipientSchema, recipientSchema } from './recipient.model.schema';

export const createRecipientRequest = async (data: TCreateRecipient): Promise<TRecipient> => {
  const createRecipientData = safeParseData(data, createRecipientSchema);

  const res = await getHttpClient().post<unknown, TRecipient>(paths.RECIPIENTS, createRecipientData);

  return safeParseData(res, recipientSchema);
};
