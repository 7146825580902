import { queryClient, useMutation } from '@marlin/shared/utils/react-query';

import { updateRecipient } from '../infrastructure/update-recipient';
import { queryKey } from '../query-key.enum';

type TUseRecipientOptions = {
  recipientId: string;
};

export const useUpdateRecipient = ({ recipientId }: TUseRecipientOptions) => {
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.RECIPIENTS() });
      queryClient.invalidateQueries({ queryKey: queryKey.RECIPIENTS_ID(recipientId) });
    },
    mutationFn: updateRecipient,
  });
};
