import { TLastReadingValue, TSensor } from '@marlin/system-map/data-access/system-map';

import { useSensor } from '../hooks/use-sensor.hook';
import { TDirection } from '../types';
import { Burner } from './burner.component';
import { Reading } from './reading.component';
import { Scene } from './scene.component';
import { StatusMode } from './status-mode.component';

interface IMetadataProps {
  sensor: TSensor;
  lastReadingValues: TLastReadingValue[];
  direction: TDirection;
  isDetailed?: boolean;
}

export const Sensor = ({ sensor, lastReadingValues, direction, isDetailed = false }: IMetadataProps) => {
  const { statusValue, status, tooltipMessage, value, readingStatus } = useSensor({
    sensor,
    lastReadingValues,
  });

  switch (sensor.displayDataPointName?.toLowerCase()) {
    case 'Scene'.toLowerCase(): {
      return <Scene value={value} label={sensor.label} />;
    }
    default: {
      break;
    }
  }

  switch (sensor.statusDataPointName?.toLowerCase()) {
    case 'overrideMode'.toLowerCase(): {
      return <StatusMode statusReadingValue={statusValue} />;
    }
    case 'UnitStatusIdx'.toLowerCase(): {
      return <Burner statusReadingValue={statusValue} status={status} direction={direction} />;
    }
    default: {
      return (
        <Reading
          isDetailed={isDetailed}
          status={readingStatus}
          direction={direction}
          label={sensor.label}
          tooltipMessage={tooltipMessage}
        >
          {value}
        </Reading>
      );
    }
  }
};
