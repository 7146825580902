import { useAnalyticsTelemetry, useAnalyticsTelemetryExport } from '@marlin/asset/data-access/telemetry';
import { onZoomChange } from '@marlin/asset/shared/ui/chart-options';
import {
  createTelemetryRequestParams,
  useChartDataProcessing,
  useMultiChartsStore,
  useSetDurationDetailsStore,
} from '@marlin/asset/ui/charts';
import {
  AVERAGING_FUNCTION_FILTER,
  TBucketOption,
  TChartDisplayType,
  TDurationDetails,
  getBucketSize,
  getChartName,
  getNumberOfBuckets,
  useCustomPeriodModalContextStore,
  useDuration,
} from '@marlin/shared/utils-chart';
import moment from 'moment/moment';
import { useCallback, useMemo } from 'react';

import { useSelectedSensorsStore } from '../context/selected-sensors.context';
import { TChartType } from '../types';
import { useBucketOptions } from './use-bucket-options.hook';

interface IUseChartData {
  chartId: string;
  chartType: TChartType;
  chartDisplayType: TChartDisplayType;
}

interface ITelemetryDeviceParam {
  manufacturerId: string;
  datapoints: string[];
}

export const useChartData = ({ chartId, chartType, chartDisplayType }: IUseChartData) => {
  const [rangeFilter] = useMultiChartsStore((store) => store.rangeFilter);
  const [selectedSensors] = useSelectedSensorsStore((store) => store);
  const [averagingFunctionFilter] = useMultiChartsStore((store) => store.averagingFunctionFilter);
  const [fromDateModal] = useCustomPeriodModalContextStore((store) => store.fromDate);
  const [toDateModal] = useCustomPeriodModalContextStore((store) => store.toDate);
  const setDurationDetailsStore = useSetDurationDetailsStore();

  const { bucketOption, setBucketOption } = useBucketOptions(chartId);

  const chartDatapoints = useMemo(() => selectedSensors?.[chartId]?.chartDatapoints || [], [selectedSensors, chartId]);

  const updateDetails = useCallback(
    (key: string, details: TDurationDetails<string>[string]) => {
      setDurationDetailsStore({ [key]: details });
    },
    [setDurationDetailsStore]
  );

  const {
    fromDate: from,
    toDate: to,
    maxSelection,
    minSelection,
    isZoomed = false,
    handleZoomChange: handleDurationZoomChange,
  } = useDuration<string>({
    key: chartId,
    rangeFilter,
    fromDate: fromDateModal,
    toDate: toDateModal,
    updateDetails,
    isFlowVolumeChart: chartType === 'flow' && chartDisplayType === 'bar',
  });

  const parsedActiveDatapoints = useMemo((): ITelemetryDeviceParam[] => {
    return chartDatapoints.reduce<ITelemetryDeviceParam[]>((acc, { manufacturerId, name, isActive }) => {
      const isManufacturerAlreadyAdded = acc.findIndex((item) => item.manufacturerId === manufacturerId) > -1;

      if (isManufacturerAlreadyAdded) {
        return acc.map((manufaturerData) =>
          manufaturerData.manufacturerId === manufacturerId
            ? {
                manufacturerId: manufaturerData.manufacturerId,
                datapoints: [...manufaturerData.datapoints, name],
              }
            : manufaturerData
        );
      }

      return [...acc, { manufacturerId, datapoints: [name] }];
    }, []);
  }, [chartDatapoints]);

  const numberOfBuckets = useMemo(
    () =>
      getNumberOfBuckets({
        minSelection,
        maxSelection,
        rangeFilter: rangeFilter.range,
        shouldHaveSpecialCalculations: chartType === 'flow',
        bucketOption,
      }),
    [minSelection, maxSelection, rangeFilter.range, chartType, bucketOption]
  );

  const bucketPerMinute = useMemo(() => getBucketSize(from, to, numberOfBuckets), [from, numberOfBuckets, to]);

  const telemetryRequestParams = useMemo(() => {
    return createTelemetryRequestParams({
      activeTelemetry: parsedActiveDatapoints,
      chartDisplayType,
      chartType,
      dateFrom: from,
      dateTo: to,
      numberOfBuckets,
      averagingFunctionFilter,
      bucketOption,
      rangeFilter,
      isZoomed,
    });
  }, [
    parsedActiveDatapoints,
    chartDisplayType,
    chartType,
    from,
    to,
    numberOfBuckets,
    averagingFunctionFilter,
    bucketOption,
    rangeFilter,
    isZoomed,
  ]);

  const { data, isFetching, isInitialLoading } = useAnalyticsTelemetry(telemetryRequestParams);

  const { mutateAsync } = useAnalyticsTelemetryExport();

  const getTelemetryExport = useCallback(
    async (averagingFunctionFilter?: AVERAGING_FUNCTION_FILTER) => {
      if (averagingFunctionFilter) {
        return mutateAsync({ ...telemetryRequestParams, averagingFunctionFilter });
      }
      return mutateAsync(telemetryRequestParams);
    },
    [mutateAsync, telemetryRequestParams]
  );

  const { chartData, chartDataWithoutMargins, min, max, totalVolume } = useChartDataProcessing({
    data: data?.telemetryData || [],
    chartDisplayType,
    bucketPerMinute,
    minSelection,
    maxSelection,
    chartDatapoints,
    parsedActiveDatapoints,
    chartType,
  });

  const handleZoomChange = useCallback(
    (zoom?: { min: number; max: number }, bucketOption?: TBucketOption | undefined) => {
      handleDurationZoomChange(zoom);

      if (zoom?.min && zoom?.max && bucketOption) {
        const dateDifferenceInMinutes = moment(zoom?.max).diff(moment(zoom?.min), 'minutes');
        onZoomChange(bucketOption, setBucketOption, dateDifferenceInMinutes);
      }
    },
    [handleDurationZoomChange, setBucketOption]
  );

  return {
    chartData,
    chartDataWithoutMargins,
    to: maxSelection,
    from: minSelection,
    isLoading: isInitialLoading,
    isFetching,
    max,
    min,
    totalVolume,
    name: selectedSensors?.[chartId]?.chartName ?? getChartName(chartType),
    handleZoomChange,
    isZoomed,
    getTelemetryExport,
  };
};
