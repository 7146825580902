import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import { Button, ButtonProps, IconButton, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { ArrayPath, Control, FieldArray, FieldValues, useFieldArray, useFormContext } from 'react-hook-form';

import { useStyles } from './array-field.component.styles';

type TRenderControlProps<TFieldValues extends FieldValues> = {
  fieldName: ArrayPath<TFieldValues>;
  control: Control<TFieldValues>;
  index: number;
};

type TFieldArrayControlProps<TFieldValues extends FieldValues> = {
  fieldName: ArrayPath<TFieldValues>;
  defaultValue: FieldArray<TFieldValues, ArrayPath<TFieldValues>>;
  renderControl: ({ fieldName, index, control }: TRenderControlProps<TFieldValues>) => ReactNode;
  addButtonProps?: Omit<ButtonProps, 'onClick'> & { children: ReactNode; buttonPosition?: 'left' | 'center' | 'right' };
  handleDeleteItem?: (index?: number) => void;
  absoluteRemoveButton?: boolean;
  minLength?: number;
  maxLength?: number;
  enumerate?: boolean;
};

export const FieldArrayControl = <TFieldValues extends FieldValues>({
  fieldName,
  defaultValue,
  renderControl,
  addButtonProps,
  handleDeleteItem,
  absoluteRemoveButton = true,
  minLength = 1,
  maxLength,
  enumerate = false,
}: TFieldArrayControlProps<TFieldValues>) => {
  const { buttonPosition, ...restAddButtonProps } = addButtonProps || { disabled: false };

  const { classes, cx } = useStyles({ buttonPosition });
  const { control } = useFormContext<TFieldValues>();
  const { fields, append, remove } = useFieldArray<TFieldValues>({
    control,
    name: fieldName,
  });

  const isMaxLengthExceeded = maxLength ? fields.length >= maxLength : false;

  return (
    <div>
      <div>
        {fields.map((field, index) => (
          <div className={classes.arrayFieldControl} key={field.id}>
            {enumerate && <Typography>{index + 1}</Typography>}
            {renderControl({ fieldName, index, control })}
            {fields.length > minLength && (
              <div
                className={cx({
                  [classes.removeButtonAbsolute]: absoluteRemoveButton,
                })}
              >
                <IconButton
                  onClick={() => {
                    remove(index);
                    handleDeleteItem?.(index);
                  }}
                  color="primary"
                >
                  <RemoveCircleRoundedIcon />
                </IconButton>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className={classes.buttonWrapper}>
        <Button
          {...restAddButtonProps}
          disabled={isMaxLengthExceeded || restAddButtonProps?.disabled}
          onClick={() => append(defaultValue)}
        >
          {addButtonProps?.children}
        </Button>
      </div>
    </div>
  );
};
