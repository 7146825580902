import { useQuery } from '@marlin/shared/utils/react-query';

import { getOrganizationDetails } from '../infrastructure/get-org-details';
import { queryKey } from './query-key.enum';

export const useGetOrganizationDetails = () => {
  return useQuery({
    queryKey: queryKey.ORGANIZATION_DETAILS(),
    queryFn: getOrganizationDetails,
  });
};
