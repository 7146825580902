import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import { paths } from './api';
import { TSankeyParams, TSankeyResponse, sankeyParamsSchema, sankeyResponseSchema } from './schema/water-sankey.schema';

export const getWaterSankey = async (params: TSankeyParams): Promise<TSankeyResponse> => {
  const requestParams = safeParseData(params, sankeyParamsSchema);
  const res = await getHttpClient().get<unknown, TSankeyResponse>(paths.WATER_SANKEY, { params: requestParams });
  return safeParseData(res, sankeyResponseSchema);
};
