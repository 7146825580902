import { UIEventHandler, useCallback } from 'react';

const optionHeight = 50;

interface IUseInfiniteAutocompleteProps {
  fetchNextPage: () => void;
  hasNextPage?: boolean;
  loading?: boolean;
}

interface IUseInfiniteAutocomplete {
  loadMore: UIEventHandler<HTMLDivElement>;
}

export const useInfiniteAutocomplete = ({
  fetchNextPage,
  hasNextPage,
  loading,
}: IUseInfiniteAutocompleteProps): IUseInfiniteAutocomplete => {
  const loadMore: UIEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      const optionsContainerElement = event.target as HTMLDivElement;

      const scrollY = optionsContainerElement.scrollHeight - optionsContainerElement.scrollTop;
      const scrollOffset = optionsContainerElement.offsetHeight - scrollY;

      if (scrollOffset < 4 * optionHeight && hasNextPage && !loading) {
        fetchNextPage();
      }
    },
    [fetchNextPage, hasNextPage, loading]
  );

  return {
    loadMore,
  };
};
