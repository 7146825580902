import { useRecipients } from '@marlin/account-data-access-recipient';
import {
  ALERT_STATUS_FILTER,
  IAlert,
  ICriticality,
  useFilteredAlertsWithPaging,
} from '@marlin/alert/data-access/alert-action';
import { TAsset } from '@marlin/alert/data-access/automated-action';
import { IAlert as IExtendedAlerts } from '@marlin/alert/ui/alert-list';
import { IPagination } from '@marlin/shared/ui-page';
import { isDataLoading } from '@marlin/shared/utils/react-query';
import { useCallback, useMemo, useState } from 'react';

import { mapExtendedAlerts } from './utils/map-extended-alerts';

interface ITotalItems {
  current: number;
  resolved: number;
  all: number;
  snoozed: number;
}

interface IStore {
  alerts: IExtendedAlerts[];
  isLoading: boolean;
  isListLoading: boolean;
  isError: boolean;
  updateStatus: (tab: ALERT_STATUS_FILTER) => void;
  pagination: IPagination | undefined;
  totalItems: ITotalItems | undefined;
  refetch: () => Promise<unknown>;
}

interface IFilters {
  pageSize: number;
  page: number;
  locationIds: TAsset[];
  equipmentIds: TAsset[];
  deviceIds: TAsset[];
  deviceTypes: TAsset[];
  search?: string;
  criticality: ICriticality;
}

export const useStore = (filterParams: IFilters): IStore => {
  const [status, setStatus] = useState<ALERT_STATUS_FILTER>(ALERT_STATUS_FILTER.CURRENT);
  const params = useMemo(
    () => ({
      params: {
        ...filterParams,
        locationIds: filterParams.locationIds.map(({ id }) => id),
        equipmentIds: filterParams.equipmentIds.map(({ id }) => id),
        deviceIds: filterParams.deviceIds.map(({ id }) => id),
        deviceTypes: filterParams.deviceTypes.map((type) => type.id),
        status,
      },
    }),
    [filterParams, status]
  );
  const alertsQuery = useFilteredAlertsWithPaging(params);
  const recipientQuery = useRecipients({ addDeleted: true });

  const isLoading = useMemo(() => alertsQuery.isLoading || recipientQuery.isLoading, [alertsQuery, recipientQuery]);

  const isError = useMemo(
    () => alertsQuery.isError || recipientQuery.isError,
    [alertsQuery.isError, recipientQuery.isError]
  );

  const extendedAlerts: IExtendedAlerts[] = useMemo(() => {
    if (!alertsQuery?.data) {
      return [];
    }
    return alertsQuery?.data.data.map((alert: IAlert) => mapExtendedAlerts(alert, recipientQuery.data));
  }, [alertsQuery?.data, recipientQuery.data]);

  const isListLoading = useMemo(() => isDataLoading([alertsQuery, recipientQuery]), [alertsQuery, recipientQuery]);

  const pagination = useMemo(() => {
    if (!alertsQuery?.data) {
      return undefined;
    }

    return alertsQuery.data.pagination;
  }, [alertsQuery]);

  const totalItems = useMemo(() => {
    if (!alertsQuery?.data) {
      return undefined;
    }

    return alertsQuery.data.totalItems;
  }, [alertsQuery]);

  const refetch = useCallback(() => {
    return Promise.all([recipientQuery.refetch(), alertsQuery.refetch()]);
  }, [recipientQuery, alertsQuery]);

  return {
    alerts: extendedAlerts,
    isLoading,
    isListLoading,
    isError,
    pagination,
    refetch,
    updateStatus: (status: ALERT_STATUS_FILTER) => setStatus(status),
    totalItems,
  };
};
