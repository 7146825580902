import { z } from 'zod';

export const deviceTypeSchema = z.union([
  z.literal('PRESSURE'),
  z.literal('TEMPERATURE'),
  z.literal('LEAK'),
  z.literal('PULSE_METER'),
]);

export type TDeviceType = z.infer<typeof deviceTypeSchema>;
