export const paths = {
  EQUIPMENTS: '/api/Equipment',
  EQUIPMENTS_FILTER: '/api/Equipment/filter',
  EQUIPMENT: '/api/Equipment/{equipmentId}',
  EQUIPMENT_METADATA: '/api/equipment/{equipmentId}/metadata',
  DYNAMIC_EQUIPMENT_METADATA: '/api/equipment/{equipmentId}/metadata/dashboard',
  EQUIPMENT_DETAILS: '/api/Equipment/{equipmentId}/details',
  EQUIPMENT_DEVICES: '/api/equipment/{equipmentId}/devices',
  EQUIPMENTS_START_SEND_DATA: 'api/equipment/{equipmentId}/livedata/start',
  EQUIPMENTS_STOP_SEND_DATA: 'api/equipment/{equipmentId}/livedata/stop',
  EQUIPMENTS_WITH_REGISTER_CODE: '/api/equipment/register',
  EQUIPMENT_DEREGISTER: '/api/equipment/{equipmentId}/deregister',
  CANCEL_SANITIZATION: '/api/equipment/{equipmentId}/sanitization/cancel',
  EQUIPMENT_SETTINGS_GROUP: '/api/equipment/{equipmentId}/settings/{settingGroupId}',
  EQUIPMENT_SCHEDULE: '/api/equipment/{equipmentId}/settings/schedule',
  EQUIPMENT_CALCULATION_VALUES: '/api/equipment/{equipmentId}/calculatedvalues/{from}/{to}',
  EQUIPMENT_METADATA_BY_MODELS: '/api/equipment/metadata',
  EQUIPMENT_AVAILABLE_BOILERS: '/api/equipment/{equipmentId}/boilers',
};
