import { MarlinTheme } from '@marlin/shared/theme';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { Link } from '@marlin/shared/ui-page';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../content';
import { useSensorId } from '../../../hooks/use-sensor-id.hook';

interface IAlertTooltip {
  isAlert: boolean;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  viewMore: {
    marginLeft: theme.typography.pxToRem(2),
    color: theme.palette.primary.contrastText,
    textDecorationLine: 'underline',
  },

  tooltip: {
    display: 'inline-flex',
    alignItems: 'center',
    marginLeft: theme.typography.pxToRem(6),
    color: theme.palette.error.main,
    cursor: 'pointer',
  },
}));

const TooltipContent = (): JSX.Element => {
  const { classes } = useStyles();
  const deviceId = useSensorId();
  const router = useRouter();
  const currentUrl = useMemo(() => router.getUrl(), [router]);
  const alertTabLink = useMemo(() => routes.deviceDrawer.open(currentUrl, deviceId, 'alerts'), [deviceId, currentUrl]);

  return (
    <div>
      {content.TOOLTIP}
      <Link to={alertTabLink} className={classes.viewMore}>
        {content.VIEW_MORE}
      </Link>
    </div>
  );
};

export const AlertTooltip = ({ isAlert }: IAlertTooltip): JSX.Element | null => {
  const { classes } = useStyles();
  if (!isAlert) {
    return null;
  }

  return (
    <span className={classes.tooltip}>
      <Tooltip placement="top" text={<TooltipContent />}>
        <ErrorRoundedIcon />
      </Tooltip>
    </span>
  );
};
