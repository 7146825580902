import {
  DeviceSearchWrapper,
  EquipmentSearchWrapper,
  LocationSearchWrapper,
} from '@marlin/alert/data-access/automated-action';
import { MarlinTheme } from '@marlin/shared/theme';
import { FormField, InfiniteAutocompleteControl, Search } from '@marlin/shared/ui-form-common';
import { Paper } from '@marlin/shared/ui-page';
import { PERMISSIONS, usePermission } from '@marlin/shared/utils-permission';
import { Button, alpha } from '@mui/material';
import { useCallback } from 'react';
import { FormProvider } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';
import { IFilterParams } from '../types';
import { FilterChips } from './filter-chips';
import { StatusSelect } from './status-select.component';
import { IAutomationHubFilters } from './types';
import { useAutomationFilterForm } from './use-automation-filter-form.hook';

interface IFiltersProps {
  onChangeParams: (params: IFilterParams) => void;
  onClearParams: () => void;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    marginTop: theme.typography.pxToRem(16),
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },

  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.typography.pxToRem(16),
  },

  filterItem: {
    width: '100%',
    height: theme.typography.pxToRem(56),
  },

  chipContainer: {
    width: theme.static.defaultWidth,
    display: 'flex',
    flexWrap: 'wrap',
    '& > div': {
      marginTop: theme.typography.pxToRem(10),
    },
    alignItems: 'baseline',
  },

  clearButton: {
    marginTop: theme.typography.pxToRem(10),
    marginRight: theme.typography.pxToRem(16),
    '&.Mui-disabled': {
      color: alpha(theme.palette.primary.main, 0.5),
      borderColor: alpha(theme.palette.primary.main, 0.5),
    },
  },

  paper: {
    width: '100%',
    borderEndEndRadius: 0,
    borderEndStartRadius: 0,
  },
}));

export function AutomatedActionFilters({ onClearParams, onChangeParams }: IFiltersProps) {
  const { classes } = useStyles();
  const { form, submit, resetForm } = useAutomationFilterForm({
    handleFiltersChange: onChangeParams,
  });

  const handleClearFilters = useCallback(() => {
    resetForm();
    onClearParams();
  }, [onClearParams, resetForm]);

  const allowedToDevices = usePermission(PERMISSIONS.SENSOR_DEVICES);

  return (
    <FormProvider {...form}>
      <form onSubmit={submit} data-testid="filters-form">
        <div data-testid="automations-filters-container" className={classes.container}>
          <Paper className={classes.paper}>
            <div className={classes.filterContainer}>
              <div className={classes.filterItem}>
                <LocationSearchWrapper>
                  {(props) => (
                    <InfiniteAutocompleteControl<IAutomationHubFilters>
                      fieldName="locations"
                      testId="location-search"
                      label="Location"
                      {...props}
                    />
                  )}
                </LocationSearchWrapper>
              </div>
              <div className={classes.filterItem}>
                <EquipmentSearchWrapper>
                  {(props) => {
                    return (
                      <InfiniteAutocompleteControl<IAutomationHubFilters>
                        fieldName="equipments"
                        testId="equipment-search"
                        label="Equipment"
                        {...props}
                      />
                    );
                  }}
                </EquipmentSearchWrapper>
              </div>
              {allowedToDevices && (
                <div className={classes.filterItem}>
                  <DeviceSearchWrapper>
                    {(props) => {
                      return (
                        <InfiniteAutocompleteControl<IAutomationHubFilters>
                          fieldName="devices"
                          testId="device-search"
                          label="Sensor"
                          {...props}
                        />
                      );
                    }}
                  </DeviceSearchWrapper>
                </div>
              )}
              <div className={classes.filterItem}>
                <StatusSelect />
              </div>
              <div className={classes.filterItem}>
                <FormField<IAutomationHubFilters> control={form.control} fieldName="search">
                  {(props) => (
                    <Search
                      label={content.FILTERS_LABEL_SEARCH}
                      value={props.value}
                      data-testid="search"
                      prefix="automations"
                      {...props}
                    />
                  )}
                </FormField>
              </div>
            </div>
            <div className={classes.chipContainer}>
              <FilterChips />
              <Button
                data-testid="automations-filters-clear"
                className={classes.clearButton}
                variant="outlined"
                disabled={false}
                onClick={handleClearFilters}
              >
                {content.FILTERS_CLEAR_ALL}
              </Button>
            </div>
          </Paper>
        </div>
      </form>
    </FormProvider>
  );
}
