import { useMemo } from 'react';

import { content } from './content';
import { ALERT_STATUS, CHANNEL_TYPE, IUser } from './model';
import { TChannelType } from './model';

export interface IAvatar {
  user: IUser;
  channelType: TChannelType;
}

const getFirstLetter = (name: string) => {
  return name[0]?.toUpperCase() ?? '';
};

const getErrorMessage = (channelType: TChannelType) => {
  switch (channelType) {
    case CHANNEL_TYPE.SMS: {
      return content.errorMessageText;
    }
    case CHANNEL_TYPE.EMAIL: {
      return content.errorMessageEmail;
    }
    default: {
      return '';
    }
  }
};

export const useAvatar = (user: IUser, channelType: TChannelType) => {
  const error = user.status === ALERT_STATUS.FAILED;
  const initials = `${getFirstLetter(user.firstName)}${getFirstLetter(user.lastName)}`;
  const userNames = `${user.firstName} ${user.lastName}`;
  const tooltip = useMemo(() => {
    let prefix = '';
    if (error) {
      prefix = getErrorMessage(channelType);
    }

    return `${prefix} ${userNames}`.trim();
  }, [error, userNames, channelType]);

  return {
    initials,
    tooltip,
    error,
  };
};
