import { MarlinTheme } from '@marlin/shared/theme';
import { useEffect, useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { getJsonValueSequences } from '../utils/get-json-value-sequences';

export const useStyles = makeStyles<{ height: number }>()((theme: MarlinTheme, { height }) => ({
  wrapper: {
    position: 'relative',
  },
  container: {
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
    borderRadius: theme.typography.pxToRem(4),
    marginTop: theme.typography.pxToRem(16),
    padding: `${theme.typography.pxToRem(16)} 0`,
    overflowY: 'scroll',
    overflowX: 'clip',
    height: theme.typography.pxToRem(height),
  },
  label: {
    position: 'absolute',
    lineHeight: theme.typography.pxToRem(12),
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.text.secondary,
    left: theme.typography.pxToRem(8),
    top: theme.typography.pxToRem(-6),
    zIndex: theme.zIndex.drawer - 1,
  },
  value: {
    padding: `0 ${theme.typography.pxToRem(12)}`,
    whiteSpace: 'pre-wrap',
  },
  notchCover: {
    position: 'absolute',
    left: theme.typography.pxToRem(8),
    top: theme.typography.pxToRem(-6),
    zIndex: theme.zIndex.drawer - 2,
    backgroundColor: theme.palette.background.primary,
    height: theme.typography.pxToRem(12),
    width: theme.typography.pxToRem(70),
  },
  error: {
    color: theme.palette.error.main,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export const JsonViewer = ({ value, label, height }: { value: string; label: string; height: number }) => {
  const { classes, cx } = useStyles({ height });
  const [parsedValue, setParsedValue] = useState<string>('');

  useEffect(() => {
    try {
      const parsed = JSON.parse(value);
      setParsedValue(JSON.stringify(parsed, null, 2));
    } catch {
      setParsedValue('Invalid JSON');
    }
  }, [value]);

  const valueSequences = useMemo(() => getJsonValueSequences(parsedValue), [parsedValue]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <span className={classes.label}>{label}</span>
        <div className={classes.notchCover} />
        <div className={classes.value}>
          {valueSequences.map((sequence, index) => {
            return (
              <span key={index} className={cx({ [classes.error]: sequence.isError })}>
                {sequence.value}
              </span>
            );
          })}
        </div>
      </div>
    </div>
  );
};
