const gateways = '/api/gateway';
const searchableGateways = `${gateways}/filter`;
const registerGateway = `${gateways}/register`;
const updateGateway = `${gateways}/{gatewayId}`;
const createGatewayConfiguration = `${gateways}/{deviceId}/configuration`;
const updateGatewayConfiguration = `${createGatewayConfiguration}/{configurationId}`;
const gatewayMode = `${gateways}/{deviceId}/mode`;
const loadGatewayConfiguration = `${createGatewayConfiguration}/{configurationId}/load`;
const executeOperation = `${gateways}/{gatewayId}/connection/{connectionId}/address/{addressId}/operation/{operationCode}`;
const command = `${gateways}/{gatewayId}/connection/{connectionId}/address/{addressId}/command`;
const gatewayModels = `/api/device/models`;
const deregisterGateway = `${gateways}/{gatewayId}/deregister`;
const gatewayConfigration = `${gateways}/{deviceId}/configuration`;
const getGateway = `${gateways}/{gatewayId}`;
const gatewaySettings = `${gateways}/{gatewayId}/settings`;

export const paths = {
  SEARCHABLE_GATEWAYS: searchableGateways,
  GATEWAY_REGISTRATION: registerGateway,
  GATEWAY_UPDATE: updateGateway,
  CREATE_GATEWAY_CONFIGURATION: createGatewayConfiguration,
  DEREGISTER_GATEWAY: deregisterGateway,
  GATEWAY_MODELS: gatewayModels,
  GATEWAY_MODE: gatewayMode,
  LOAD_CONFIGURATION_GATEWAY: loadGatewayConfiguration,
  GATEWAY_CONFIGURATION: gatewayConfigration,
  GET_GATEWAY: getGateway,
  UPDATE_GATEWAY_CONFIGURATION: updateGatewayConfiguration,
  EXECUTES_OPERATION: executeOperation,
  COMMAND: command,
  GATEWAY_SETTINGS: gatewaySettings,
};
