import { TAssetLink } from '@marlin/asset/data-access/asset';
import { AttachmentsTable } from '@marlin/asset/shared/ui/attachment-validation';
import { LoadingSpinner } from '@marlin/shared/ui-loader';

import { content } from './content';
import { useStyles } from './delete-location-modal.styles';

interface IModalBodyProps {
  isLoading: boolean;
  deleteAssetId?: string;
  deleteAssetName?: string;
  canDelete: boolean;
  assetLinks?: TAssetLink;
  reason?: 'parent' | 'assets';
}

export function ModalBody({
  isLoading,
  deleteAssetName,
  deleteAssetId,
  canDelete,
  assetLinks,
  reason,
}: IModalBodyProps): JSX.Element | null {
  const { classes } = useStyles();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!canDelete) {
    switch (reason) {
      case 'parent': {
        return <div>{content.DELETE_LOCATION_PARENT}</div>;
      }
      case 'assets':
      default: {
        return <div>{content.DELETE_LOCATION_ASSETS}</div>;
      }
    }
  }

  if (assetLinks?.links && assetLinks.links.length > 0) {
    return (
      <div>
        <div>{content.FLOW_LINKS_INFO}</div>
        <div className={classes.linksTable}>
          <AttachmentsTable assetId={deleteAssetId} title={content.FLOW_LINKS_TABLE} data={assetLinks.links} />
        </div>
        <div className={classes.confirmText}>{content.FLOW_LINKS_CONFIRMATION}</div>
      </div>
    );
  }

  return (
    <div className={classes.assetName}>
      {content.DELETE_LOCATION_CONFIRMATION(deleteAssetName || '')}
      <span className={classes.confirmText}>{content.ACTION_CAN_NOT_BE_UNDONE}</span>
    </div>
  );
}
