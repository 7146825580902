import { TUnitOfMeasure, parseDisplayedValue } from '@marlin/shared/utils-format-reading';

import { content } from './content';
import { ERROR_TYPES } from './types';

export const getErrorTextWithUnit = (
  alertType: ERROR_TYPES,
  absoluteDifferenceValue: string | null,
  unit: TUnitOfMeasure | null
): string => {
  switch (alertType) {
    case ERROR_TYPES.LOWER: {
      return content.ABSOLUTE_DIFFERENCE.LABEL.LOWER(
        absoluteDifferenceValue ? parseDisplayedValue(absoluteDifferenceValue, unit, undefined, 'detail') : ''
      );
    }
    case ERROR_TYPES.HIGHER: {
      return content.ABSOLUTE_DIFFERENCE.LABEL.UPPER(
        absoluteDifferenceValue ? parseDisplayedValue(absoluteDifferenceValue, unit, undefined, 'detail') : ''
      );
    }
    case ERROR_TYPES.LOST_COMMUNICATION: {
      return '';
    }
    case ERROR_TYPES.LOW_BATTERY: {
      return '';
    }
    default: {
      return '';
    }
  }
};
