import { Equipment } from '@marlin/shared/utils/zod';
import { z } from 'zod';

import { paginationSchema } from './utils.schema';

export const getEquipmentResponseSchema = z.object({
  pagination: paginationSchema,
  data: z.array(Equipment).nullable(),
});

export type TEquipmentResponseSchema = z.infer<typeof getEquipmentResponseSchema>;
export type TEquipmentSchema = z.infer<typeof Equipment>;
