import { useMemo } from 'react';

import { content } from '../content';
import { TStatus } from '../types';

interface IPerformanceStatusLabelProps {
  status: TStatus;
}

const openAlertsStatuses: TStatus[] = ['warning', 'error'];

export const PerformanceStatusLabel = ({ status }: IPerformanceStatusLabelProps) => {
  return useMemo(() => {
    if (openAlertsStatuses.includes(status)) {
      return content.PERFORMANCE_STATUS.OPEN_ALERTS;
    }
    return content.PERFORMANCE_STATUS.SYSTEM_OPERATIONAL;
  }, [status]);
};
