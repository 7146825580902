export const content = {
  LOST_COMMUNICATION: 'Lost Communication',
  SENSOR_OPERATIONAL: 'Sensor Operational',
  LOW_BATTERY: 'Low Battery',
  REPLACE_BATTERY: 'Replace Battery',

  POWER_CELL_BATTERY_LEVEL: (batteryLevel: number) => `${batteryLevel}%`,
  EXTENDED_POWER_CELL_BATTERY_LEVEL: (batteryLevel: number) => `Battery (${batteryLevel}%)`,
  POWER_CELL_LINE: 'Line',
};
