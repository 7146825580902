import { MarlinTheme } from '@marlin/shared/theme';
import { CustomSizedModal } from '@marlin/shared/ui-modal';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { ChangeEvent, useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';
import { useSelectedSensorsStore } from '../../context/selected-sensors.context';
import { useEditChartName } from '../../hooks/use-edit-name-chart.hook';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    width: '100%',
    marginLeft: theme.typography.pxToRem(8),
  },
  stepperButtons: {
    display: 'flex',
    gap: theme.typography.pxToRem(8),
  },
  content: {
    width: '100%',
  },
  wrapper: {
    width: '100%',
    margin: `${theme.typography.pxToRem(16)} 0 ${theme.typography.pxToRem(16)}`,
  },
}));

interface IEditNameModal {
  onClose: () => void;
  title: string;
  id?: string;
}

export const EditNameModal = ({ title, id, onClose }: IEditNameModal) => {
  const { classes } = useStyles();
  const [chartName, setChartName] = useState(title);
  const { editChartName } = useEditChartName({ chartId: id ?? '' });
  const { enqueueSnackbar } = useSnackbar();

  const [selectedSensors] = useSelectedSensorsStore((store) => store);
  const names = useMemo(
    () =>
      Object.values(selectedSensors)
        .map((chart) => chart.chartName.toLowerCase())
        .filter((name) => name !== title.toLowerCase()),
    [selectedSensors, title]
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChartName(event.target.value.trim());
  };

  const submitSaveChart = (chartName: string) => {
    editChartName({ chartName });
    onClose();
    enqueueSnackbar(content.EDIT_MODAL.TOAST_MESSAGE, {
      variant: 'success',
      preventDuplicate: true,
    });
  };

  const isUniqueNameError = names.includes(chartName.toLowerCase());

  const isError = !chartName || isUniqueNameError;

  const getHelperText = () => {
    if (!chartName) {
      return content.EDIT_MODAL.CHART_NAME_ERROR;
    }

    if (isUniqueNameError) {
      return content.EDIT_MODAL.CHART_NAME_UNIQUE;
    }

    return '';
  };

  return (
    <CustomSizedModal
      width={800}
      onClose={onClose}
      title={content.EDIT_MODAL.TITLE}
      body={
        <div className={classes.content}>
          <TextField
            className={classes.wrapper}
            label={content.CHART_NAME}
            data-testId="chart-name-field"
            error={isError}
            helperText={getHelperText()}
            required
            name="chartName"
            defaultValue={title}
            onChange={handleChange}
            InputProps={{
              inputProps: {
                maxLength: 80,
              },
            }}
          />
        </div>
      }
      footer={
        <div>
          <Button variant="text" data-testid="chart-edit-modal-cancel-button" onClick={onClose}>
            {content.EDIT_MODAL.CANCEL}
          </Button>
          <Button
            variant="contained"
            color="primary"
            data-testid="chart-edit-modal-submit-button"
            disabled={isError}
            onClick={() => submitSaveChart(chartName)}
          >
            {content.EDIT_MODAL.SAVE}
          </Button>
        </div>
      }
    />
  );
};
