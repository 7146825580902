export const isSystemAutomation = (automationName: string) => {
  switch (automationName) {
    case 'Gateway Lost Communication':
    case 'Device Lost Communication':
    case 'Device Low Battery': {
      return true;
    }
    default: {
      return false;
    }
  }
};
