type TDatapointValidationError =
  | 'NO_METADATA'
  | 'INCORRECT_VALUE'
  | 'TYPE_NOT_EXISTS'
  | 'INCORRECT_STEP_VALUE'
  | 'INCORRECT_RANGE_VALUE'
  | 'INCORRECT_OPTION';

type TMetadataValidationError =
  | 'NO_METADATA'
  | 'TYPE_NOT_EXISTS'
  | 'INCORRECT_MIN'
  | 'INCORRECT_MAX'
  | 'INCORRECT_STEP'
  | 'OPTIONS_NOT_EXISTS'
  | 'DUPLICATED_OPTIONS';

export const logValidationIssue = (
  datapointName: string,
  issue: TDatapointValidationError | TMetadataValidationError
) => {
  // eslint-disable-next-line no-console
  console.warn(`VALIDATION ERROR FOR DATAPOINT ${datapointName} = ${issue}`);
};
