import { TDataPointsSchema } from '@marlin/shared/utils-format-reading';
import { TExtractFnReturnType, queryClient, useQuery } from '@marlin/shared/utils/react-query';
import { useCallback } from 'react';

import { getDeviceDetails } from '../infrastructure/device';
import { queryKey } from './query-key.enum';

type TUseDeviceOptions = {
  deviceId: string;
  datapoints?: TDataPointsSchema[];
  enabled?: boolean;
};

export const useDeviceDetails = ({ deviceId, enabled, datapoints }: TUseDeviceOptions) => {
  return useQuery<TExtractFnReturnType<typeof getDeviceDetails>>({
    queryKey: queryKey.DEVICE_DETAILS(deviceId),
    queryFn: () => getDeviceDetails({ deviceId, datapoints }),
    enabled,
  });
};

export const useRefetchDeviceDetails = () => {
  return useCallback((deviceId: string) => {
    return queryClient.refetchQueries({ queryKey: queryKey.DEVICE_DETAILS(deviceId) });
  }, []);
};
