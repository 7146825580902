import { useObservable, useSubscription } from 'observable-hooks';

import { IAlertNotification } from '../alert.model';
import { getAlertNotification } from '../infrastructure/alerts';

interface IAlertSubscriptionProps {
  onAlert: (alert: IAlertNotification | undefined) => void;
}

export const useAlertSubscription = ({ onAlert }: IAlertSubscriptionProps) => {
  const alert$ = useObservable(() => getAlertNotification());

  return useSubscription<IAlertNotification | undefined>(alert$, onAlert);
};
