import { MarlinTheme } from '@marlin/shared/theme';
import PushPinRoundedIcon from '@mui/icons-material/PushPinRounded';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  icon: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(16),
    cursor: 'pointer',
  },
}));

interface IUnpinProps {
  onUnpinClick: () => void;
}

export function Unpin({ onUnpinClick }: IUnpinProps) {
  const { classes } = useStyles();

  return (
    <span className={classes.icon}>
      <PushPinRoundedIcon
        data-testid="upin-device-btn"
        fontSize="inherit"
        onClick={(event) => {
          event.stopPropagation();
          onUnpinClick();
        }}
      />
    </span>
  );
}
