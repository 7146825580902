import { useNavigate } from 'react-router-dom';

interface IState {
  [key: string]: unknown;
}

interface IUseStore {
  goTo: (url: string, state?: IState) => void;
}

export const useRouter = (): IUseStore => {
  const navigate = useNavigate();
  const goTo = (url: string, state?: IState): void => {
    navigate(url, state);
  };

  return {
    goTo,
  };
};
