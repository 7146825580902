import {
  AuthenticationResult,
  EventError,
  EventMessage,
  EventType,
  IPublicClientApplication,
  InteractionType,
} from '@azure/msal-browser';
import { Subscription, of, timer } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { ConfigService } from './config.service';
import { RequestService } from './request.service';
import { TokenService } from './token.service';

type TAuthenticationResult = AuthenticationResult & { expiresOn: Date };

interface IAcquireTokenSuccessEvent extends EventMessage {
  eventType: EventType;
  interactionType: InteractionType | null;
  payload: TAuthenticationResult;
  error: EventError;
  timestamp: number;
}

export class RefreshTokenService {
  private callbackId: string | null = null;
  private timer$: Subscription | null = null;
  private accessToken: string | null = null;

  public constructor(
    private readonly authMsalService: IPublicClientApplication,
    private readonly configService: ConfigService,
    private readonly requestService: RequestService,
    private readonly tokenService: TokenService
  ) {}

  public initialize = (): void => {
    if (!this.configService.get().system.setupAutomaticSilentRefresh || this.callbackId) {
      return;
    }

    this.timer$ = null;
    this.callbackId = this.authMsalService.addEventCallback(this.handleEvent);
  };

  public clear = (): void => {
    if (this.callbackId) {
      this.authMsalService.removeEventCallback(this.callbackId);
    }

    if (this.timer$) {
      this.timer$.unsubscribe();
    }
  };

  private handleEvent = (message: EventMessage): void => {
    if (!this.isAuthenticationResult(message)) {
      return;
    }

    this.refreshToken(message.payload);
  };

  private refreshToken = (authenticationResult: AuthenticationResult, forceRefresh = false): void => {
    if (!forceRefresh && this.accessToken === authenticationResult.accessToken) {
      return;
    }

    this.accessToken = authenticationResult.accessToken;

    if (this.timer$) {
      this.timer$.unsubscribe();
      this.timer$ = null;
    }

    const timeout = this.getTimeout(authenticationResult.expiresOn?.getTime());

    this.timer$ = timer(timeout)
      .pipe(
        switchMap(() => this.tokenService.acquireToken()),
        tap({
          next: (newAuthenticationResult: AuthenticationResult | void) => {
            if (this.isTokenResponse(newAuthenticationResult)) {
              this.refreshToken(newAuthenticationResult, true);
            }
          },
        }),
        catchError(() => of(undefined))
      )
      .subscribe();
  };

  private isTokenResponse = (
    authenticationResult: AuthenticationResult | void
  ): authenticationResult is AuthenticationResult => !!authenticationResult;

  private isAuthenticationResult = (message: EventMessage): message is IAcquireTokenSuccessEvent => {
    return message.eventType === EventType.ACQUIRE_TOKEN_SUCCESS;
  };

  private getTimeout = (expiresOn?: number): number => {
    if (!expiresOn || expiresOn <= 0) {
      return 0;
    }

    const now = Date.now();
    const tokenRenewalOffsetSeconds = this.configService.get().system.tokenRenewalOffsetSeconds || 60;
    const tokenRenewalOffsetInMilliseconds = tokenRenewalOffsetSeconds * 1000;

    return expiresOn - now - tokenRenewalOffsetInMilliseconds;
  };
}
