import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { useCreateLink } from '@marlin/system-map/data-access/system-map';
import { IFlowLink, TFlowLinkForm, formAdapterSchema } from '@marlin/system-map/ui/flow-link-upsert-form';
import { AxiosError } from 'axios';
import { useCallback } from 'react';

import { IFlowLinkError } from './constants';
import { content } from './content';
import { handleFlowLinkError } from './helpers';

export const debounceTimeInMs = 300;

interface IUseFlowLinkForm {
  onSubmit: (link: IFlowLink) => Promise<void>;
}

interface ITag {
  name: string;
  value?: string | null;
}

const createLinkTags = (link: TFlowLinkForm): ITag[] => {
  const tags: ITag[] = [];
  const inletHandleTag = link.inletData?.tags?.find((tag) => tag.name === 'HandleId');
  const outletHandleTag = link.outletData?.tags?.find((tag) => tag.name === 'HandleId');

  if (inletHandleTag) {
    tags.push({ name: 'InletHandleId', value: inletHandleTag.value });
  }
  if (outletHandleTag) {
    tags.push({ name: 'OutletHandleId', value: outletHandleTag.value });
  }

  return tags;
};

export const useFlowLinkForm = (): IUseFlowLinkForm => {
  const createLinkMutation = useCreateLink();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = useCallback(
    async (formData: IFlowLink): Promise<void> => {
      const data = formAdapterSchema.parse(formData);

      if (!data.inletData || !data.outletData) {
        return;
      }
      const tags = createLinkTags(data);

      return createLinkMutation
        .mutateAsync({
          ...data,
          linkOptions: tags.length ? { tags } : undefined,
        })
        .then(() => {
          enqueueSnackbar(content.ADD_FLOW_LINK_SUCCESS, {
            variant: 'success',
            preventDuplicate: true,
          });
        })
        .catch((error: AxiosError<IFlowLinkError>) => {
          handleFlowLinkError({ error, enqueueSnackbar });
        });
    },
    [createLinkMutation, enqueueSnackbar]
  );

  return {
    onSubmit,
  };
};
