import { MarlinTheme } from '@marlin/shared/theme';
import { PropsWithChildren } from 'react';
import { makeStyles } from 'tss-react/mui';

interface IStyleProps {
  autoHide?: boolean;
}

export interface IFilterChipsContainerProps extends PropsWithChildren, IStyleProps {}

export const useStyles = makeStyles<IStyleProps>()((theme: MarlinTheme, { autoHide }) => ({
  container: {
    display: 'flex',
    minHeight: autoHide ? undefined : theme.typography.pxToRem(32),

    [theme.breakpoints.up('md')]: {
      rowGap: theme.typography.pxToRem(16),
      flexWrap: 'wrap',
    },
    [theme.breakpoints.down('md')]: {
      overflowX: 'auto',
      alignItems: 'center',
      '-ms-overflow-style': 'none' /* hide scrollbar on Internet Explorer 10+ */,
      'scrollbar-width': 'none' /* hide scrollbar on Firefox */,
      '&::-webkit-scrollbar': {
        display: 'none' /* hide scrollbar on Safari and Chrome */,
      },
      '& > .MuiChip-root:first-child': {
        marginLeft: theme.typography.pxToRem(16),
      },
      minHeight: theme.typography.pxToRem(32),
      marginTop: theme.typography.pxToRem(16),
      marginBottom: theme.typography.pxToRem(16),
    },
  },
}));

export function FilterChipsContainer({ children, autoHide }: IFilterChipsContainerProps) {
  const { classes } = useStyles({ autoHide });

  return <div className={classes.container}>{children}</div>;
}
