import { Link } from '@marlin/shared/ui-page';
import { routes } from '@marlin/shared/utils-routes';
import { makeStyles } from 'tss-react/mui';

interface ILinkedItemProps {
  item?: IAutomationMetadata;
  goTo: (value: string) => string;
}

interface ILinkedLocationItemProps {
  location?: IAutomationMetadata;
  rootLocation?: IAutomationMetadata;
}

import { IAutomationMetadata } from './types';

export const useStyles = makeStyles()(() => ({
  link: {
    wordBreak: 'break-word',
  },
}));

export function LinkedItem({ item, goTo }: ILinkedItemProps) {
  const { classes } = useStyles();
  if (!item || !item.id || !item.name) {
    return <span>--</span>;
  }

  return (
    <Link className={classes.link} to={goTo(item.id)}>
      {item.name}
    </Link>
  );
}

export function LinkedLocationItem({ location, rootLocation }: ILinkedLocationItemProps) {
  if (rootLocation?.name && location) {
    return (
      <span>
        <LinkedItem item={rootLocation} goTo={(id: string) => routes.locations.details.url(id)} />
        {' > '}
        <LinkedItem item={location} goTo={(id: string) => routes.locations.details.url(id)} />
      </span>
    );
  }
  return <LinkedItem item={location} goTo={(id: string) => routes.locations.details.url(id)} />;
}
