import { TDeviceDetails } from '@marlin/asset/data-access/device';
import { useMemo } from 'react';

import { useDeviceDetailsContext } from '../../context/device-details.context';

interface ILocation {
  id: string;
  name: string;
}

export interface IDetails {
  deviceDetails: TDeviceDetails | undefined;
  parentLocation: ILocation | undefined;
  deviceLocation: ILocation | undefined;
  gatewayId?: string;
}

export const useDetails = (): IDetails => {
  const deviceDetails = useDeviceDetailsContext();

  const deviceLocation = useMemo(
    () =>
      deviceDetails?.locationId
        ? {
            id: deviceDetails?.locationId || '',
            name: deviceDetails?.locationName || '',
          }
        : undefined,
    [deviceDetails?.locationId, deviceDetails?.locationName]
  );
  const parentLocation = useMemo(
    () =>
      deviceDetails?.parentLocationId
        ? {
            id: deviceDetails?.parentLocationId || '',
            name: deviceDetails?.parentLocationName || '',
          }
        : undefined,
    [deviceDetails?.parentLocationId, deviceDetails?.parentLocationName]
  );

  const gatewayId = useMemo(() => {
    return deviceDetails?.gatewayId;
  }, [deviceDetails?.gatewayId]);

  return {
    deviceDetails,
    gatewayId,
    parentLocation,
    deviceLocation,
  };
};
