import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import {
  TCreateWebhookParams,
  createWebhookParamsSchema,
  createWebhookResponseSchema,
} from '../schemas/create-webhook.schema';
import { paths } from './api';

export const createWebhook = async (data: TCreateWebhookParams) => {
  const body = safeParseData(data, createWebhookParamsSchema);

  const response = await getHttpClient().post(paths.WEBHOOK, body);

  return safeParseData(response, createWebhookResponseSchema);
};
