export enum CRITICALITY {
  LOW = 'LOW',
  HIGH = 'HIGH',
  NO = 'NO',
}

export type TCriticality = `${CRITICALITY.LOW}` | `${CRITICALITY.HIGH}` | `${CRITICALITY.NO}`;

export interface ICriticality {
  criticality: TCriticality;
}
