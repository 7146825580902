import { useCurrentUser, useOrganizationList } from '@marlin/account-data-access-organization';
import { useUserPortfolios } from '@marlin/portfolio-data-access-portfolio';
import { useMemo } from 'react';

import { content } from './content';
import { OrganizationSwitcher as OrganizationSwitcherUI } from './organization-switcher-ui.component';

interface IOrganizationSwitcherProps {
  isCollapsed: boolean;
  desktopNavbarLocked: boolean;
  variant: 'marlin' | 'portfolio' | 'support';
}

export const OrganizationSwitcher = ({ isCollapsed, desktopNavbarLocked, variant }: IOrganizationSwitcherProps) => {
  const { data: orgData } = useCurrentUser();
  const { data: portfolioData } = useUserPortfolios();
  const { data, hasNextPage, handleOrgSearchValue, term, fetchNextPage, isFetchingNextPage, isLoading } =
    useOrganizationList({
      enabled: variant === 'marlin',
    });

  const { entityId, entityName, entities } = useMemo(() => {
    if (variant === 'support') {
      return {
        entityId: portfolioData?.currentPortfolio?.id,
        entityName: content.MARLIN_SUPPORT,
        entities: [],
      };
    }
    if (variant === 'portfolio') {
      return {
        entityId: portfolioData?.currentPortfolio?.id,
        entityName: portfolioData?.currentPortfolio?.name,
        entities: portfolioData?.portfolios || [],
      };
    }
    return {
      entityId: orgData?.currentOrganization.organizationId,
      entityName: orgData?.currentOrganization.organizationName,
      entities: (data || []).map((org) => ({
        id: org.id,
        name: org.name,
        tierName: org.tierName,
      })),
    };
  }, [
    orgData?.currentOrganization.organizationId,
    orgData?.currentOrganization.organizationName,
    portfolioData?.currentPortfolio?.id,
    portfolioData?.currentPortfolio?.name,
    portfolioData?.portfolios,
    variant,
    data,
  ]);

  const showChangeButton =
    variant === 'marlin' ? (orgData?.totalOrganizationsCount || entities.length) > 1 : entities.length > 1;

  return (
    <OrganizationSwitcherUI
      variant={variant}
      isCollapsed={isCollapsed}
      desktopNavbarLocked={desktopNavbarLocked}
      entityId={entityId}
      entityName={entityName}
      entities={entities}
      handleOrgSearchValue={handleOrgSearchValue}
      orgSearchValue={term}
      fetchNextPage={fetchNextPage}
      isLoading={isLoading || isFetchingNextPage}
      hasNextPage={variant === 'marlin' && hasNextPage}
      showChangeButton={showChangeButton}
    />
  );
};
