import { dateAdapter } from '@marlin/shared/utils-common-date';
import noop from 'lodash/noop';
import { Moment } from 'moment';
import { createContext, useContext, useMemo } from 'react';
import { ReactNode, useCallback, useState } from 'react';

export interface IReloadContext {
  reload: () => void;
  now: Moment | null;
}

export const ReloadContext = createContext<IReloadContext>({
  reload: noop,
  now: dateAdapter.date(),
});

export const ReloadContextProvider = ({ children }: { children: ReactNode }) => {
  const [now, setNow] = useState<Moment | null>(dateAdapter.date());
  const reload = useCallback(() => {
    setNow(dateAdapter.date());
  }, []);
  return <ReloadContext.Provider value={{ reload, now }}>{children}</ReloadContext.Provider>;
};

export const useNow = () => {
  const reloadContext = useContext(ReloadContext);
  const now = useMemo(() => reloadContext?.now ?? dateAdapter.date() ?? null, [reloadContext.now]);
  return { now };
};
