import { MarlinTheme } from '@marlin/shared/theme';
import { Typography } from '@mui/material';
import { PropsWithChildren } from 'react';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  sectionTitle: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export interface ISectionTitle extends PropsWithChildren {
  testId: string;
}

export const SectionTitle = ({ testId, children }: ISectionTitle) => {
  const { classes } = useStyles();
  return (
    <Typography className={classes.sectionTitle} variant="subtitle1" textAlign="left" data-testid={testId}>
      {children}
    </Typography>
  );
};
