import { MarlinTheme } from '@marlin/shared/theme';
import { PropsWithChildren } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  stickyHeader: {
    position: 'sticky',
    top: 0,
    left: 0,
    zIndex: 2, // Note: so that the header is above the page content
    padding: theme.typography.pxToRem(32),
    margin: theme.typography.pxToRem(-32),
    paddingTop: theme.typography.pxToRem(16),
    marginTop: theme.typography.pxToRem(-16),
    paddingBottom: 0,
    marginBottom: 0,
    backgroundColor: theme.palette.background.secondary,
    borderBottom: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
    boxSizing: 'border-box',
    maxHeight: theme.typography.pxToRem(72), // Note: align to Figma design
  },
}));

/**
 * StickyHeaderWrapper component
 * @description if you need any header to be sticky, wrap it with this component
 */
export const StickyHeaderWrapper = ({ children }: PropsWithChildren) => {
  const { classes } = useStyles();

  return (
    <div data-testid="sticky-header" className={classes.stickyHeader}>
      {children}
    </div>
  );
};
