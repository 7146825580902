import { IChart } from '@marlin/asset/shared/equipment-config';
import { ChartTypeSwitcher } from '@marlin/asset/shared/ui/chart-type-switcher';
import { MarlinTheme } from '@marlin/shared/theme';
import { TChartDisplayType } from '@marlin/shared/utils-chart';
import { Typography } from '@mui/material';
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { Chart } from './chart.component';

interface IChartsSection {
  chartSettings: IChart;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  chartHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${theme.typography.pxToRem(8)} 0`,
    gap: theme.typography.pxToRem(16),
  },
  sectionTitle: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: theme.typography.pxToRem(25.6),
    letterSpacing: theme.typography.pxToRem(0.15),
    padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(0)}`,
  },
}));

export const ChartsSection = ({
  chartSettings: { name, mainDatapoint, label, additionalAxisUom, hideUomOnAxis, type },
}: IChartsSection) => {
  const { classes } = useStyles();
  const [chartDisplayType, setChartDisplayType] = useState<TChartDisplayType>(type);

  return (
    <>
      {name === 'flow' ? (
        <div className={classes.chartHeader}>
          <Typography className={classes.sectionTitle}>{label}</Typography>
          <ChartTypeSwitcher chartDisplayType={chartDisplayType} setChartDisplayType={setChartDisplayType} />
        </div>
      ) : (
        <Typography className={classes.sectionTitle}>{label}</Typography>
      )}
      <Chart
        chartId={name}
        additionalAxisUom={additionalAxisUom}
        mainDatapoint={mainDatapoint ?? ''}
        hideUomOnAxis={!!hideUomOnAxis}
        chartType={chartDisplayType}
      />
    </>
  );
};
