import { MarlinTheme } from '@marlin/shared/theme';
import { Link } from '@marlin/shared/ui-page';
import { makeStyles } from 'tss-react/mui';

import { IMetadata } from '../types';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  link: {
    color: 'inherit',
    textDecoration: 'none',
  },
}));

interface ILinkedItemProps {
  item?: IMetadata;
  goTo: (value: string) => string;
}

export function LinkedItem({ item, goTo }: ILinkedItemProps) {
  const { classes } = useStyles();

  if (!item || !item.id || !item.name) {
    return null;
  }

  return (
    <Link
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={classes.link}
      to={goTo(item.id)}
    >
      {item.name}
    </Link>
  );
}
