import { useCancelSanitization } from '@marlin/asset/data-access/equipment';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import { useCallback, useContext, useMemo, useState } from 'react';

import { content } from '../../content';

export const useStopSanitizationModalDispatch = ({
  isSanitization,
  equipmentId,
  enqueueSnackbarContent,
}: {
  isSanitization: boolean;
  equipmentId: string;
  enqueueSnackbarContent?: string;
}) => {
  const [isStoppingSanitization, setIsStoppingSanitization] = useState(false);
  const { modalDispatch } = useContext(ModalContext);
  const { enqueueSnackbar } = useSnackbar();

  const cancelSanitizationMutation = useCancelSanitization({
    onError: () => {
      setIsStoppingSanitization(false);
      enqueueSnackbar(content.ERROR, {
        variant: 'error',
        preventDuplicate: true,
      });
    },
  });

  useMemo(() => {
    if (isStoppingSanitization && !isSanitization) {
      setIsStoppingSanitization(false);
      enqueueSnackbar(enqueueSnackbarContent || content.SANITIZATION_STOPPED, {
        variant: 'success',
        preventDuplicate: true,
      });
    }
  }, [enqueueSnackbar, enqueueSnackbarContent, isSanitization, isStoppingSanitization]);

  const closeModal = useCallback(() => modalDispatch({ type: MODAL_ACTION_TYPE.DISMISS }), [modalDispatch]);

  const submitStopSanitization = useCallback(() => {
    setIsStoppingSanitization(true);
    cancelSanitizationMutation.mutate({ equipmentId });
    closeModal();
  }, [cancelSanitizationMutation, closeModal, equipmentId]);

  return { isStoppingSanitization, modalDispatch, closeModal, submitStopSanitization };
};
