import { IStorageService } from '../models/auth.model';

export interface IKeyStorageService {
  getKey: () => string;
}

export class StorageService implements IStorageService {
  private keys: string[] = [];

  public constructor(private services: IKeyStorageService[]) {
    services.forEach((service) => {
      this.keys.push(service.getKey());
    });
  }

  public clearKey(key: string) {
    sessionStorage.removeItem(key);
    localStorage.removeItem(key);
  }

  public clear() {
    const savedIds = this.keys.map((key) => ({
      key,
      value: sessionStorage.getItem(key) ?? localStorage.getItem(key),
    }));
    sessionStorage.clear();
    localStorage.clear();
    savedIds.forEach(({ key, value }) => {
      if (value) {
        localStorage.setItem(key, value);
      }
    });
  }
}
