import { TOperation, TOperationError } from '@marlin/asset/data-access/gateway';
import React, { PropsWithChildren, createContext, useContext, useState } from 'react';

export interface IConnectionInfo {
  name: string;
  id: string;
  addresses: IAddressInfo[];
}

interface IAddressInfo {
  id: string;
  address: number;
}

export type TErrors = TOperationError & { addressId: string };
export type TAddressErrors = {
  invalidData: {
    connectionIndex: number;
    addressIndex: number;
  }[];
};

interface ITestGatewaySteps {
  step: TEST_GATEWAY_STEPS;
  setStep: (step: TEST_GATEWAY_STEPS) => void;
  operations: TOperation[];
  setOperations: (operations: TOperation[]) => void;
  connections: IConnectionInfo[];
  setConnections: (connections: IConnectionInfo[]) => void;
  isTestOnly: boolean;
  setIsTestOnly: (operations: boolean) => void;
  operationErrors: TErrors[];
  setOperationErrors: React.Dispatch<React.SetStateAction<TErrors[]>>;
  addressErrors: TAddressErrors | null;
  setAddressErrors: React.Dispatch<React.SetStateAction<TAddressErrors | null>>;
}

const TestGatewayStepsContext = createContext<ITestGatewaySteps | null>(null);

export enum TEST_GATEWAY_STEPS {
  CONFIGURE = 'configure',
  TEST_RESULTS = 'testResults',
}

export const TestGatewayProvider = ({ children }: PropsWithChildren) => {
  const [step, setStep] = useState(TEST_GATEWAY_STEPS.CONFIGURE);
  const [operations, setOperations] = useState<TOperation[]>([]);
  const [connections, setConnections] = useState<IConnectionInfo[]>([]);
  const [isTestOnly, setIsTestOnly] = useState<boolean>(false);
  const [operationErrors, setOperationErrors] = useState<TErrors[]>([]);
  const [addressErrors, setAddressErrors] = useState<TAddressErrors | null>(null);

  return (
    <TestGatewayStepsContext.Provider
      value={{
        step,
        setStep,
        operations,
        setOperations,
        setConnections,
        connections,
        isTestOnly,
        setIsTestOnly,
        operationErrors,
        setOperationErrors,
        addressErrors,
        setAddressErrors,
      }}
    >
      {children}
    </TestGatewayStepsContext.Provider>
  );
};

export const useTestGatewayContext = () => {
  const context = useContext(TestGatewayStepsContext);

  if (!context?.step || !context?.setStep) {
    throw new Error('useTestGatewayStepsContext must be used within a TestGatewayStepsProvider');
  }

  return context;
};
