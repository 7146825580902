import { queryClient, useMutation } from '@marlin/shared/utils/react-query';
import { z } from 'zod';

import { profileUpdate } from '../infrastructure/profile-update';
import { QUERY_KEY } from './query-key.enum';

export const useUpdateProfile = () => {
  return useMutation({
    mutationFn: profileUpdate,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [QUERY_KEY.PROFILE] }),
    onError: (error) => {
      // eslint-disable-next-line no-console
      if (error instanceof z.ZodError) console.log(error.issues);
    },
  });
};
