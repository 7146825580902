import { MouseEvent, PropsWithChildren, useCallback, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { Tooltip } from './tooltip.component';

export const useStyles = makeStyles()(() => ({
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  hideSafariNativeTooltip: {
    '&::after': {
      content: '""',
      display: 'block',
    },
  },
  ellipsisTooltip: {
    '& .MuiTooltip-tooltip': {
      maxWidth: '60ch',
    },
  },
}));

export const EllipsisTooltip = ({ children }: PropsWithChildren) => {
  const { classes, cx } = useStyles();
  const [tooltipEnabled, setTooltipEnabled] = useState(false);

  const handleTextOver = useCallback(({ currentTarget }: MouseEvent<Element>) => {
    if (currentTarget.scrollWidth > currentTarget.clientWidth) {
      setTooltipEnabled(true);
    }
  }, []);

  if (!children) {
    return null;
  }

  return (
    <Tooltip
      text={children}
      open={tooltipEnabled}
      onClose={() => setTooltipEnabled(false)}
      className={classes.ellipsisTooltip}
    >
      <div onMouseOver={handleTextOver} className={cx(classes.ellipsis, classes.hideSafariNativeTooltip)}>
        {children}
      </div>
    </Tooltip>
  );
};
