import { routes } from '@marlin/shared/utils-routes';

export interface IFeatureNavigation {
  getBackLink: () => string;
}

export const useNavigation = (): IFeatureNavigation => {
  return {
    getBackLink: () => routes.automations.list.url(),
  };
};
