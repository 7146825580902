import { MetadataLabel } from '@marlin/shared/utils-chart';
import { z } from 'zod';

export const MetadataOption = z.object({
  id: z.union([z.string(), z.number(), z.null()]).transform((value) => value?.toString()),
  name: z.string(),
  label: z.array(MetadataLabel).nullish(),
});

export const MetadataDynamicOption = z.object({
  id: z.union([z.string(), z.number()]).transform((value) => value?.toString()),
  name: z.string(),
});
export type TSourceMetadataOption = z.infer<typeof MetadataOption>;
export type TMetadataDynamicOption = z.infer<typeof MetadataDynamicOption>;
