import { IUserListFilters } from './user.model';

export enum QUERY_KEY {
  USER = 'user',
  USER_DATA = 'user_data',
}

export const queryKey = {
  USER: (userId: string) => [QUERY_KEY.USER, userId],
  USER_LIST: (params?: IUserListFilters) => [QUERY_KEY.USER, params || {}],
  USER_DATA: (area: string) => [QUERY_KEY.USER_DATA, area],
};
