import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'start',
    flexWrap: 'wrap',
    gap: theme.typography.pxToRem(24),
    marginTop: theme.typography.pxToRem(28),
  },
  mobileWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(8),
  },
}));
