import { MarlinTheme } from '@marlin/shared/theme';
import { FormField, IFormControlProps, IFormTypedProps } from '@marlin/shared/ui-form-common';
import { INTERNAL_ROLE, ROLE } from '@marlin/shared/utils-role';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { ControllerProps, ControllerRenderProps, FieldError, FieldValues } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';
import { availableRoles, csmRoles, supportRoles } from './organization-role-options';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  helperText: {
    whiteSpace: 'break-spaces',
  },
}));

type TRenderProps = Partial<Omit<ControllerRenderProps, 'ref'>>;

interface ISelectControlProps extends TRenderProps {
  error?: FieldError;
  disabled?: boolean;
  helperTextDisabled?: boolean;
  className?: string;
  variant?: 'standard' | 'support' | 'csm';
}

const variantRoleMap = new Map<
  ISelectControlProps['variant'],
  {
    value: INTERNAL_ROLE | ROLE;
    name: string;
    helperText: string;
  }[]
>([
  ['standard', availableRoles],
  ['support', supportRoles],
  ['csm', csmRoles],
]);

const SelectControl = ({
  error,
  disabled = false,
  helperTextDisabled,
  className,
  variant = 'standard',
  ...rest
}: ISelectControlProps) => {
  const { classes } = useStyles();
  const roles = variantRoleMap.get(variant);

  return (
    <FormControl error={!!error} fullWidth={true}>
      <InputLabel id="role-select-label" required={true}>
        {content.ROLE_CONTROL_LABEL}
      </InputLabel>
      <Select
        id="role-select"
        labelId="role-select-label"
        data-testid="role-select"
        label={content.ROLE_CONTROL_LABEL}
        required
        fullWidth={true}
        disabled={disabled}
        multiple={false}
        error={!!error}
        className={className}
        {...rest}
        renderValue={
          helperTextDisabled
            ? (selected) => {
                return selected;
              }
            : undefined
        }
      >
        {roles?.map((role) => (
          <MenuItem key={role.value} value={role.value}>
            <div>
              <span>{role.name}</span>
              <FormHelperText className={classes.helperText}>{role.helperText}</FormHelperText>
            </div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export function RoleControl<TFieldValues extends FieldValues>(
  props: IFormControlProps<TFieldValues> & ISelectControlProps
): JSX.Element;
export function RoleControl<
  TFieldValues extends FieldValues = object,
  TName extends ControllerProps<TFieldValues>['name'] = ControllerProps<TFieldValues>['name']
>(props: IFormTypedProps<TFieldValues, TName> & ISelectControlProps): JSX.Element;

export function RoleControl<
  TFieldValues extends FieldValues,
  TName extends ControllerProps<TFieldValues>['name'] = ControllerProps<TFieldValues>['name']
>(params: (IFormControlProps<TFieldValues> | IFormTypedProps<TFieldValues, TName>) & ISelectControlProps) {
  if (params.control) {
    return (
      <FormField {...params}>
        {(props) => (
          <SelectControl
            {...props}
            className={params.className}
            disabled={params?.disabled}
            helperTextDisabled={params.helperTextDisabled}
            variant={params.variant}
          />
        )}
      </FormField>
    );
  }

  return (
    <FormField<TFieldValues> {...params}>
      {(props) => (
        <SelectControl
          {...props}
          className={params.className}
          disabled={params?.disabled}
          helperTextDisabled={params.helperTextDisabled}
          variant={params.variant}
        />
      )}
    </FormField>
  );
}
