import { THomeLocationsParamsSchema } from '../infrastructure/schema/home-locations.schema';
import { TSankeyParams } from '../infrastructure/schema/water-sankey.schema';
import { TWaterTotalParams } from '../infrastructure/schema/water-total.schema';

enum QUERY_KEY {
  HOME_DASHBOARD = 'dashboard',
  HOME_LOCATIONS = 'home_locations',
  WATER_USAGE = 'water_usage',
  WATER_TOTAL = 'water_total',
}

export const queryKey = {
  HOME_DASHBOARD: () => [QUERY_KEY.HOME_DASHBOARD],
  HOME_LOCATIONS: ({ isMechanicalRoom, withAlerts }: THomeLocationsParamsSchema) => [
    QUERY_KEY.HOME_LOCATIONS,
    isMechanicalRoom,
    withAlerts,
  ],
  WATER_USAGE: () => [QUERY_KEY.WATER_USAGE],
  WATER_TOTAL: (params: TWaterTotalParams) => [QUERY_KEY.WATER_TOTAL, params],
  WATER_SANKEY: (params: TSankeyParams) => [QUERY_KEY.WATER_USAGE, params],
};
