import { MODEL } from '@marlin/shared/utils/zod';

import conquest100 from './Conquest_100.webp';
import conquest1100_1200 from './Conquest_1100-1200.webp';
import conquest130_40_80 from './Conquest_130_40L-80L.webp';
import conquest130_90_100 from './Conquest_130_90L-100L.webp';
import conquestOutdoor from './Conquest_Outdoor.webp';

export const getConquestLogo = (type: string) => {
  switch (type) {
    case MODEL.CONQUEST_100_20L100A_GCL:
    case MODEL.CONQUEST_100_25L100A_GCL:
    case MODEL.CONQUEST_100_30L100A_GCL:
      return conquest100;
    case MODEL.CONQUEST_130_40L130A_GCML:
    case MODEL.CONQUEST_130_50L130A_GCML:
    case MODEL.CONQUEST_130_60L130A_GCML:
    case MODEL.CONQUEST_130_70L130A_GCML:
    case MODEL.CONQUEST_130_80L130A_GCML:
      return conquest130_40_80;
    case MODEL.CONQUEST_130_90L130A_GCML:
    case MODEL.CONQUEST_130_100L130A_GCML:
      return conquest130_90_100;
    case MODEL.CONQUEST_1100_CQT1100:
    case MODEL.CONQUEST_1200_CQT1200:
      return conquest1100_1200;
    case MODEL.CONQUEST_130_40L130A_GCMLW:
    case MODEL.CONQUEST_130_50L130A_GCMLW:
    case MODEL.CONQUEST_130_60L130A_GCMLW:
    case MODEL.CONQUEST_130_70L130A_GCMLW:
    case MODEL.CONQUEST_130_80L130A_GCMLW:
    case MODEL.CONQUEST_1100_CQTO1100:
    case MODEL.CONQUEST_1200_CQTO1200:
      return conquestOutdoor;
    default:
      return conquest100;
  }
};
