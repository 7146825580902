// based on: https://github.com/TexasSwede/stateAbbreviations

export enum CONVERSION_TYPE {
  ABBREVIATED_TO_NAME = 1,
  NAME_TO_ABBREVIATED = 2,
}

const states = [
  ['Alabama', 'AL'],
  ['Alaska', 'AK'],
  ['American Samoa', 'AS'],
  ['Arizona', 'AZ'],
  ['Arkansas', 'AR'],
  ['Armed Forces Americas', 'AA'],
  ['Armed Forces Europe', 'AE'],
  ['Armed Forces Pacific', 'AP'],
  ['California', 'CA'],
  ['Colorado', 'CO'],
  ['Connecticut', 'CT'],
  ['Delaware', 'DE'],
  ['District Of Columbia', 'DC'],
  ['Florida', 'FL'],
  ['Georgia', 'GA'],
  ['Guam', 'GU'],
  ['Hawaii', 'HI'],
  ['Idaho', 'ID'],
  ['Illinois', 'IL'],
  ['Indiana', 'IN'],
  ['Iowa', 'IA'],
  ['Kansas', 'KS'],
  ['Kentucky', 'KY'],
  ['Louisiana', 'LA'],
  ['Maine', 'ME'],
  ['Marshall Islands', 'MH'],
  ['Maryland', 'MD'],
  ['Massachusetts', 'MA'],
  ['Michigan', 'MI'],
  ['Minnesota', 'MN'],
  ['Mississippi', 'MS'],
  ['Missouri', 'MO'],
  ['Montana', 'MT'],
  ['Nebraska', 'NE'],
  ['Nevada', 'NV'],
  ['New Hampshire', 'NH'],
  ['New Jersey', 'NJ'],
  ['New Mexico', 'NM'],
  ['New York', 'NY'],
  ['North Carolina', 'NC'],
  ['North Dakota', 'ND'],
  ['Northern Mariana Islands', 'NP'],
  ['Ohio', 'OH'],
  ['Oklahoma', 'OK'],
  ['Oregon', 'OR'],
  ['Pennsylvania', 'PA'],
  ['Puerto Rico', 'PR'],
  ['Rhode Island', 'RI'],
  ['South Carolina', 'SC'],
  ['South Dakota', 'SD'],
  ['Tennessee', 'TN'],
  ['Texas', 'TX'],
  ['US Virgin Islands', 'VI'],
  ['Utah', 'UT'],
  ['Vermont', 'VT'],
  ['Virginia', 'VA'],
  ['Washington', 'WA'],
  ['West Virginia', 'WV'],
  ['Wisconsin', 'WI'],
  ['Wyoming', 'WY'],
];

const mxStates = [
  ['Aguascalientes', 'AGU'],
  ['Baja California', 'BCN'],
  ['Baja California Sur', 'BCS'],
  ['Campeche', 'CAM'],
  ['Chiapas', 'CHP'],
  ['Chihuahua', 'CHH'],
  ['Coahuila', 'COA'],
  ['Colima', 'COL'],
  ['Durango', 'DUR'],
  ['Guanajuato', 'GUA'],
  ['Guerrero', 'GRO'],
  ['Hidalgo', 'HID'],
  ['Jalisco', 'JAL'],
  ['Estado de México', 'MEX'],
  ['Ciudad de México', 'CDMX'],
  ['Michoacán', 'MIC'],
  ['Morelos', 'MOR'],
  ['Nayarit', 'NAY'],
  ['Nuevo León', 'NLE'],
  ['Oaxaca', 'OAX'],
  ['Puebla', 'PUE'],
  ['Querétaro', 'QUE'],
  ['Quintana Roo', 'ROO'],
  ['San Luis Potosí', 'SLP'],
  ['Sinaloa', 'SIN'],
  ['Sonora', 'SON'],
  ['Tabasco', 'TAB'],
  ['Tamaulipas', 'TAM'],
  ['Tlaxcala', 'TLA'],
  ['Veracruz', 'VER'],
  ['Yucatán', 'YUC'],
  ['Zacatecas', 'ZAC'],
];

const provinces = [
  ['Alberta', 'AB'],
  ['British Columbia', 'BC'],
  ['Manitoba', 'MB'],
  ['New Brunswick', 'NB'],
  ['Newfoundland', 'NF'],
  ['Northwest Territory', 'NT'],
  ['Nova Scotia', 'NS'],
  ['Nunavut', 'NU'],
  ['Ontario', 'ON'],
  ['Prince Edward Island', 'PE'],
  ['Quebec', 'QC'],
  ['Saskatchewan', 'SK'],
  ['Yukon', 'YT'],
];

const regions = states.concat(provinces).concat(mxStates);

export function convertRegion(input: string, to: CONVERSION_TYPE): string | undefined {
  if (to === CONVERSION_TYPE.NAME_TO_ABBREVIATED) {
    input = input.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
    });
    for (const region of regions) {
      if (region[0] === input) {
        return region[1];
      }
    }
  } else if (to === CONVERSION_TYPE.ABBREVIATED_TO_NAME) {
    input = input.toUpperCase();
    for (const region of regions) {
      if (region[1] === input) {
        return region[0];
      }
    }
  }
  return undefined;
}
