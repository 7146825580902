import { useMutation } from '@marlin/shared/utils/react-query';
import { AxiosError } from 'axios';

import { getEquipmentAssets } from '../infrastructure/assets';
import { TAssetLocationParamsSchema, TAssetResponseSchema } from '../schemas/assets/assets.schema';

export const useGetAssetsEquipment = () => {
  return useMutation<unknown, AxiosError, TAssetLocationParamsSchema, TAssetResponseSchema>({
    mutationFn: getEquipmentAssets,
  });
};
