import { MarlinTheme } from '@marlin/shared/theme';
import { CriticalityControl } from '@marlin/shared/ui-criticality';
import { Paper } from '@marlin/shared/ui-page';
import { Details, DetailsSection } from '@marlin/shared/ui/collapsible-details-mobile';
import { getRecipientCardTitle } from '@marlin/shared/utils/format-alert';
import { Grid } from '@mui/material';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';
import { ALERT_STATUS_FILTER, IAlert } from '../../types';
import { CardHeader } from '../header.component';
import { AlertIntervalSection } from '../sections/alert-interval-section';
import { LastReadingSection } from '../sections/last-reading-section.component';
import { SnoozeSection } from '../sections/snooze-section.component';
import { WarningIcon } from '../sections/warning-icon.component';
import { MetadataSection } from './metadata-section.component';
import { RecipientsSection } from './recipients-section.component';
import { TimeSection } from './time-section.component';

interface IAlertCurrent {
  alert: IAlert;
  displayIconColumn: boolean;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: theme.typography.pxToRem(8),
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.typography.pxToRem(12),
  },

  paper: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
}));

export function MobileAlertLostCommunicationCard({ alert, displayIconColumn }: IAlertCurrent) {
  const { classes } = useStyles();
  const warningIcon = displayIconColumn ? <WarningIcon /> : undefined;

  const title = useMemo(() => getRecipientCardTitle(alert), [alert]);
  const isResolvedAlert = alert.status === ALERT_STATUS_FILTER.RESOLVED;
  return (
    <Grid item xs={12}>
      <Paper testId="alert-card" className={classes.paper}>
        <div className={classes.row}>
          <CriticalityControl severity={alert.criticality} />
          {!isResolvedAlert && (
            <SnoozeSection
              id={alert.id}
              deviceId={alert.device.id}
              automationName={alert.automation.name}
              snoozeUntil={alert.snoozeUntil}
              automationId={alert.automation.id}
              deviceName={alert.device.name}
              alertSeverity={alert.criticality}
              alertStartTime={alert.startTime}
            />
          )}
        </div>
        <div className={classes.row} data-testid="card-header">
          {warningIcon}
          <CardHeader title={title} />
        </div>
        <TimeSection label={content.CARD.CURRENT.LABEL.START_TIME} datetime={alert.startTime} />
        <LastReadingSection alert={alert} />
      </Paper>
      <Details>
        <DetailsSection title={content.CARD.DETAILS.ACTIONS} withDivider={!!alert.device.type}>
          <RecipientsSection alert={alert} />
          {!!alert.device.type && <AlertIntervalSection snoozeIntervalInMinutes={alert.snoozeIntervalInMinutes} />}
        </DetailsSection>
        {!!alert.device.type && (
          <DetailsSection title={content.CARD.DETAILS.HIERARCHY}>
            <MetadataSection alert={alert} />
          </DetailsSection>
        )}
      </Details>
    </Grid>
  );
}
