import { zodResolver } from '@hookform/resolvers/zod';
import { ModalLarge } from '@marlin/shared/ui-modal';
import { FormProvider, useForm } from 'react-hook-form';

import { content } from './content';
import { RenameTeeButtons } from './rename-tee-buttons.component';
import { RenameTeeForm } from './rename-tee-form.component';
import { TRenameTeeValues, renameTeeSchema } from './rename-tee-form.schema';

export interface IRenameTeeModal {
  onCancel: () => void;
  onSubmit: (values: TRenameTeeValues) => void;
  isOpen: boolean;
  defaultValues: TRenameTeeValues;
}

export const RenameTeeModal = ({ onCancel, onSubmit, defaultValues, isOpen }: IRenameTeeModal) => {
  const form = useForm<TRenameTeeValues>({
    defaultValues,
    mode: 'onTouched',
    resolver: zodResolver(renameTeeSchema),
  });

  if (!isOpen) {
    return null;
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <ModalLarge
          title={content.RENAME_TEE_TITLE}
          onClose={onCancel}
          body={<RenameTeeForm />}
          footer={<RenameTeeButtons onSubmit={onSubmit} onCancel={onCancel} />}
        />
      </form>
    </FormProvider>
  );
};
