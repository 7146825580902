import { AERCO_UNIT_STATUS } from '@marlin/shared/utils/zod';

import { content } from '../content';

interface ILegendItem {
  label: string;
  color: string;
  icon: string;
  order: number;
  divider?: boolean;
}

export const legendContent: Record<AERCO_UNIT_STATUS, ILegendItem> = {
  [AERCO_UNIT_STATUS.Auto]: { label: content.PLANT_LIST.MODES.AUTO, icon: 'smart_toy', color: 'regular', order: 2 },
  [AERCO_UNIT_STATUS.Remote]: {
    label: content.PLANT_LIST.MODES.REMOTE,
    icon: 'directions_walk',
    color: 'regular',
    order: 3,
  },
  [AERCO_UNIT_STATUS.Manual]: { label: content.PLANT_LIST.MODES.MANUAL, icon: 'pan_tool', color: 'regular', order: 4 },
  [AERCO_UNIT_STATUS.Standby]: { label: content.PLANT_LIST.MODES.STANDBY, icon: 'pending', color: 'regular', order: 5 },
  [AERCO_UNIT_STATUS.Disabled]: {
    label: content.PLANT_LIST.MODES.DISABLED,
    icon: 'signal_wifi_off',
    color: 'regular',
    divider: true,
    order: 1,
  },
  [AERCO_UNIT_STATUS.Fault]: {
    label: content.PLANT_LIST.MODES.FAULT,
    icon: 'warning_rounded',
    color: 'error',
    order: 0,
  },
};

export const getLegendItemByStatus = (status: AERCO_UNIT_STATUS) => legendContent[status];
