import { MarlinTheme } from '@marlin/shared/theme';
import { CriticalityControl } from '@marlin/shared/ui-criticality';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';
import { IAlert } from '../types';
import { SnoozeSection } from './snooze-section.component';
import { TimeSection } from './time-section.component';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    columnGap: theme.typography.pxToRem(24),
  },
}));

interface IAlertContentHeaderProps {
  alert: IAlert;
}

export function SnoozedAlertContentHeader({ alert }: IAlertContentHeaderProps) {
  const { classes } = useStyles();

  return (
    <>
      <TimeSection label={content.CARD.TIME_SNOOZED} datetime={alert.snoozeUntil}>
        <div className={classes.row}>
          <CriticalityControl severity={alert.criticality} />
          <SnoozeSection
            id={alert.id}
            deviceId={alert.device.id}
            automationName={alert.automation.name}
            snoozeUntil={alert.snoozeUntil}
            automationId={alert.automation.id}
            deviceName={alert.device.name}
            alertStartTime={alert.startTime}
            alertSeverity={alert.criticality}
          />
        </div>
      </TimeSection>
    </>
  );
}
