import { useMediaQuery, useTheme } from '@mui/material';

import { DesktopEquipmentHub } from './desktop/equipment-hub.component';
import { MobileEquipmentHub } from './mobile/equipment-hub.component';

export function EquipmentHub() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (isMobile) {
    return <MobileEquipmentHub />;
  }

  return <DesktopEquipmentHub />;
}
