import { TVolumeDataPoint } from '@marlin/asset/data-access/home-dashboard';
import { ICsvRow, csvDownload } from '@marlin/shared/utils-chart';
import { dateAdapter, defaultDateTime } from '@marlin/shared/utils-common-date';
import { useCallback } from 'react';

import { content } from '../../content';

interface ITotalWaterCsvRow extends ICsvRow {
  name: string;
  timestamp: string;
  value: number | null;
}

export const useTotalWaterDownload = (
  volumeTotal: TVolumeDataPoint[],
  previousPeriodVolumeTotal: TVolumeDataPoint[]
) => {
  const handleDownloadClick = useCallback(() => {
    const csvRows: ITotalWaterCsvRow[] = volumeTotal.map((datapoint) => ({
      name: content.TOTAL_WATER_CONSUMPTION_CURRENT_SERIES,
      timestamp: dateAdapter.date(datapoint.eventDateTime)?.format(defaultDateTime) ?? '',
      value: datapoint.volume,
    }));
    const csvRowsPreviousPeriod: ITotalWaterCsvRow[] = previousPeriodVolumeTotal.map((datapoint) => ({
      name: content.TOTAL_WATER_CONSUMPTION_PREVIOUS_SERIES,
      timestamp: dateAdapter.date(datapoint.eventDateTime)?.format(defaultDateTime) ?? '',
      value: datapoint.volume,
    }));
    const csvLabels = {
      name: 'Name',
      timestamp: 'Timestamp',
      value: 'Value',
    };
    csvDownload<ITotalWaterCsvRow>({ data: [...csvRowsPreviousPeriod, ...csvRows], labels: csvLabels });
  }, [previousPeriodVolumeTotal, volumeTotal]);

  return { handleDownloadClick };
};
