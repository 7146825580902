import { getHttpClient } from '@marlin/shared/utils/react-query';

import {
  TCancelSnoozeAutomationParams,
  TSnoozeAutomationParams,
  snoozeAutomationParamsDtoSchema,
} from '../schemas/snooze.model.schema';
import { paths } from './api';

export type TSnoozeActionResponse = Promise<void>;

export const snoozeAutomation = async (params: TSnoozeAutomationParams): TSnoozeActionResponse => {
  const parseDtoResult = snoozeAutomationParamsDtoSchema.safeParse(params);

  if (!parseDtoResult.success) {
    throw parseDtoResult.error;
  }

  const { minutes, automationId, deviceId } = parseDtoResult.data;

  return await getHttpClient().post<unknown, void>(
    paths.AUTOMATION_SNOOZE,
    { minutes, deviceId },
    {
      params: { automationId },
    }
  );
};

export const cancelSnoozeAutomation = async ({
  automationId,
  deviceId,
}: TCancelSnoozeAutomationParams): Promise<TSnoozeActionResponse> => {
  return await getHttpClient().post<unknown, TSnoozeActionResponse>(paths.AUTOMATION_SNOOZE_CANCEL, null, {
    params: { automationId, deviceId },
  });
};
