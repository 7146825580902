import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import hljs from 'highlight.js';

import { content } from '../content';

const printStyles = new CSSStyleSheet();
printStyles.insertRule('.hljs-string {color: #008000}');
printStyles.insertRule('.hljs-number, .hljs-keyword {color: #aa5d00}');
printStyles.insertRule('code.hljs {padding: 3px 5px}');
printStyles.insertRule('pre code.hljs {display: block;overflow-x: auto; padding: 1em}');

const css =
  '<style>\n' +
  Array.from(printStyles.cssRules)
    .map((rule) => rule.cssText)
    .join('\n') +
  '\n</style>';

const iFrameOnLoad = (
  configValue: string,
  iframe: HTMLIFrameElement,
  enqueueSnackbar: ReturnType<useSnackbar>['enqueueSnackbar']
) => {
  const removeIframe = () => {
    document.body.removeChild(iframe);
  };

  try {
    const value = hljs.highlight(JSON.stringify(JSON.parse(configValue), null, 2), { language: 'json' }).value;

    if (!iframe.contentWindow) throw new Error('Empty iframe');

    const body = iframe.contentWindow.document.body;
    body.innerHTML = `<pre><code>${value}</code></pre>`;

    const styles = document.createElement('style');
    styles.textContent = css;

    iframe.contentWindow.document.head.appendChild(styles);

    iframe.contentWindow.onbeforeunload = removeIframe;
    iframe.contentWindow.onafterprint = removeIframe;
    iframe.contentWindow.print();
  } catch (error) {
    enqueueSnackbar(content.ERROR_MESSAGE, { variant: 'error' });
  }
};

export const usePrintIframe = (configValue: string) => {
  const { enqueueSnackbar } = useSnackbar();

  const onPrintClick = () => {
    const hiddenIframe = document.createElement('iframe');
    hiddenIframe.onload = () => iFrameOnLoad(configValue, hiddenIframe, enqueueSnackbar);
    hiddenIframe.style.display = 'hidden';

    hiddenIframe.srcdoc = `<!DOCTYPE html>${document.createElement('body')}`;
    document.body.appendChild(hiddenIframe);
  };

  return { onPrintClick };
};
