import isNil from 'lodash/isNil';

export interface ICharTicks {
  lowest: number;
  highest: number;
  gap: number;
}

export type TChartTick = {
  value: number;
} | null;

export interface IChartTicks {
  high: TChartTick;
  low: TChartTick;
}

const offset = 10;
const emptyTicks: ICharTicks = {
  lowest: 0,
  highest: 0,
  gap: 0,
};

const roundValue = (value?: number) => {
  if (isNil(value)) {
    return 0;
  }

  return Math.round(value / 10) * 10;
};

const getLowestTick = (low: number) => {
  const isLowNegative = low < 0;
  const lowest = roundValue(low) - offset;

  if (isLowNegative) {
    return lowest;
  }

  return lowest >= 0 ? lowest : 0;
};

export const getChartTicks = (telemetry: IChartTicks | undefined): ICharTicks => {
  if (!telemetry?.low || !telemetry?.high) {
    return emptyTicks;
  }

  const highest = roundValue(telemetry?.high.value) + offset;
  const lowest = getLowestTick(telemetry?.low.value);
  const gap = (highest - lowest) / 5;

  return {
    lowest,
    highest,
    gap,
  };
};
