import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/700.css';
import { TAppName, environment } from '@marlin/environment';
import { ColorMode, MarlinTheme } from '@marlin/shared/theme';
import { ButtonProps } from '@mui/material';
import { Breakpoints, ThemeOptions, createTheme } from '@mui/material/styles';
import { Palette } from '@mui/material/styles/createPalette';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { alpha } from '@mui/system';

import { breakpoint } from './breakpoints';
import { createDisabledButtonColors } from './buttons';
import { colorPalette as marlinColorPalette } from './colors';
import { ReactComponent as AercoLogo } from './common-icon/aerco-logo.svg';
import { defaultConfig } from './default-config';
import { ReactComponent as ChartLogo } from './icon/chart-logo.svg';
import { ReactComponent as ErrorLogo } from './icon/error.svg';
import { ReactComponent as FullLogo } from './icon/full-logo.svg';
import { ReactComponent as Logo } from './icon/logo.svg';
import { ReactComponent as NotFoundLogo } from './icon/not-found-logo.svg';
import { ReactComponent as SetupSuccessLogo } from './icon/setup-success.svg';
import { ReactComponent as SystemErrorLogo } from './icon/system-error-logo.svg';
import { ReactComponent as TextLogo } from './icon/text-logo.svg';
import { colorPalette as nexaColorPalette } from './nexa-colors';
import { ReactComponent as NexaFullLogoReversed } from './nexa-icon/full-logo-eversed.svg';
import { ReactComponent as NexaFullLogo } from './nexa-icon/full-logo.svg';
import { ReactComponent as NexaLogoReversed } from './nexa-icon/logo-reversed.svg';
import { ReactComponent as NexaLogo } from './nexa-icon/logo.svg';

export const typography = (palette: Palette): TypographyOptions => ({
  fontFamily: `Inter`, // todo: side nav is: Roboto, Helvetica, Arial, sans-serif - why?
  h1: {
    fontWeight: 'light',
    fontSize: 96,
    letterSpacing: '-1.5px',
  },
  h2: {
    fontWeight: 'light',
    fontSize: 60,
    letterSpacing: '-1=0.5px',
  },
  h3: {
    fontWeight: 'normal',
    fontSize: 48,
    letterSpacing: '0px',
  },
  h4: {
    fontWeight: 'normal',
    fontSize: 34,
    letterSpacing: '0.25px',
  },
  h5: {
    fontWeight: 'normal',
    fontSize: 24,
    letterSpacing: '0px',
  },
  h6: {
    fontWeight: 'medium',
    fontSize: 20,
    letterSpacing: '0.15px',
  },
  subtitle1: {
    fontWeight: '400',
    fontSize: 16,
    letterSpacing: '0.15px',
    color: palette.text.secondary,
  },
  subtitle2: {
    fontWeight: 'normal',
    fontSize: 14,
    letterSpacing: '0.1px',
  },
  body1: {
    fontWeight: 'normal',
    fontSize: 16,
    letterSpacing: '0.15px',
  },
  body2: {
    fontWeight: 'normal',
    fontSize: 14,
    letterSpacing: '0.15px',
  },
  button: { textTransform: 'uppercase' },
  caption: {
    fontWeight: 'normal',
    fontSize: 12,
    letterSpacing: '0.4px',
  },
  overline: {
    fontWeight: 'normal',
    fontSize: 12,
    letterSpacing: '1px',
    textTransform: 'uppercase',
  },
});

const indexPosition = {
  default: 999,
  foreground: 999999,
  middleground: 99999,
  background: 9999,
};

const getIcons = (appName: TAppName) => {
  switch (appName) {
    case 'Nexa':
      return {
        logo: <NexaLogo />,
        fullLogo: <NexaFullLogo />,
        logoReversed: <NexaLogoReversed />,
        fullLogoReversed: <NexaFullLogoReversed />,
        aercoLogo: <AercoLogo />,
        setupSuccessLogo: <SetupSuccessLogo />,
        chartLogo: <ChartLogo />,
        errorLogo: <ErrorLogo />,
        notFoundLogo: <NotFoundLogo />,
        systemErrorLogo: <SystemErrorLogo />,
      };
    case 'Marlin':
    default:
      return {
        logo: <Logo />,
        textLogo: <TextLogo />,
        fullLogo: <FullLogo />,
        aercoLogo: <AercoLogo />,
      };
  }
};

const getColorPalette = (appName: TAppName) => {
  switch (appName) {
    case 'Nexa':
      return nexaColorPalette;
    case 'Marlin':
    default:
      return marlinColorPalette;
  }
};

const getButtonStyles = (palette: Palette, appName?: TAppName) => {
  switch (appName) {
    case 'Nexa':
      return {
        root: ({ ownerState, theme }: { ownerState: ButtonProps; theme: MarlinTheme }) => {
          const disabledButtonColors = createDisabledButtonColors(theme);
          return {
            '&.Mui-disabled': disabledButtonColors[`${ownerState.color}_${ownerState.variant}`],
            '&.MuiButton-containedInherit': {
              backgroundColor: palette.alternative.main,
              color: palette.alternative.contrastText,
            },
            '&.MuiButton-containedInherit:hover': {
              backgroundColor: palette.alternative.dark,
              color: palette.alternative.contrastText,
            },
            '&.MuiButton-containedInherit.Mui-disabled':
              disabledButtonColors[`${ownerState.color}_${ownerState.variant}`],
            '&.MuiButton-outlinedInherit': {
              color: palette.alternative.main,
              borderColor: palette.alternative.main,
            },
            '&.MuiButton-outlinedInherit.Mui-disabled':
              disabledButtonColors[`${ownerState.color}_${ownerState.variant}`],
            '&.MuiButton-textInherit': {
              color: palette.alternative.main,
            },
            '&.MuiButton-textInherit.Mui-disabled': disabledButtonColors[`${ownerState.color}_${ownerState.variant}`],
          };
        },
      };
    case 'Marlin':
    default:
      return undefined;
  }
};

const createThemeFromPalette = (palette: Palette, breakpoints: Breakpoints, mode: ColorMode): ThemeOptions => ({
  ...defaultConfig,
  typography: typography(palette),
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            backgroundColor: palette.background.alternative,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: palette.alternative.dark,
        },
        arrow: {
          color: palette.alternative.dark,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: palette.error.main,
          '&$error': {
            color: palette.error.main,
          },
        },
      },
    },
    MuiTreeItem: {
      styleOverrides: {
        content: {
          '&.Mui-selected': {
            backgroundColor: alpha(palette.primary.main, 0.04),
            '&:hover': {
              backgroundColor: alpha(palette.primary.main, 0.04),
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: getButtonStyles(palette, environment.app.name),
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
            '&:focus, &:focus-within': {
              outline: 'none',
            },
          },
        },
      },
    },
  },
  breakpoints: {
    values: breakpoints.values,
  },
  palette: palette as ThemeOptions['palette'],
});

const defaultThemeArgs = {
  indexPosition,
  static: {
    defaultWidth: 1080,
    mobileHeaderHeight: 56,
    marginCommon: 16,
    marginBig: 32,
  },
  icon: getIcons(environment.app.name),
};

const colorPalette = getColorPalette(environment.app.name);

export const theme = (mode: ColorMode): MarlinTheme =>
  createTheme(createThemeFromPalette(colorPalette(mode), breakpoint, mode), defaultThemeArgs);
