import { MarlinTheme } from '@marlin/shared/theme';
import { TMetadataGroup, TMetadataLayout } from '@marlin/system-map/data-access/system-map';
import { makeStyles } from 'tss-react/mui';

interface IStylesProps {
  metadataLayout: TMetadataLayout;
  isDetailed?: boolean;
}

export const getGridPosition = (group: TMetadataGroup) => ({
  gridColumn: group.merged === 'row' ? '1 / -1' : group.column,
  gridRow: group.merged === 'column' ? '1 / -1' : group.row,
  zIndex: group.merged === 'column' ? 0 : group.column,
});

export const useStyles = makeStyles<IStylesProps>()((theme: MarlinTheme, { metadataLayout, isDetailed = false }) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: `repeat(${metadataLayout.columns}, 1fr)`,
    gridTemplateRows: `${theme.typography.pxToRem(isDetailed ? 32 : 62)} repeat(${
      metadataLayout.rows - 1
    }, ${theme.typography.pxToRem(isDetailed ? 30 : 64)})`,
    gap: theme.typography.pxToRem(1),
    backgroundColor: theme.palette.systemMap.divider,
    paddingTop: isDetailed ? 0 : theme.typography.pxToRem(1),
    paddingBottom: isDetailed ? 0 : theme.typography.pxToRem(1),
  },
  gridItem: {
    background: theme.palette.background.primary,
    '&:not(:has(*))': {
      display: 'none',
    },
  },
}));
