import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { Node, useReactFlow, useUpdateNodeInternals } from '@xyflow/react';
import { useCallback } from 'react';

import { content } from '../content';
import { gridSize } from './const';
import { calculateCeilSnappedValue, calculateSnappedValue, useSnapToGrid } from './use-snap-to-grid.hook';

const getGridSize = (n: number) => {
  return gridSize * n;
};

const calculateNewPosition = (
  node: Node,
  nodes: Node[],
  grid: number,
  i: number,
  j: number
): { x: number; y: number } => {
  const newPosition = {
    x: calculateCeilSnappedValue(node.position.x + grid * i * 2),
    y: calculateCeilSnappedValue(node.position.y + grid * j * 2),
  };
  const willOverlap = nodes.find(
    (existingNode) => existingNode.position.x === newPosition.x && existingNode.position.y === newPosition.y
  );
  return willOverlap ? calculateNewPosition(node, nodes, grid, i + 1, j) : newPosition;
};

export const useTeeIntersection = () => {
  const { getNodes, setNodes, getIntersectingNodes } = useReactFlow();
  const updateNodeInternals = useUpdateNodeInternals();
  const { snapNodeToGrid } = useSnapToGrid();
  const { enqueueSnackbar } = useSnackbar();
  const grid = gridSize;

  const getAllOverlappingTees = useCallback(() => {
    const teeNodes = getNodes().filter((node) => node.type === 'TEE');
    return teeNodes
      .flatMap((teeNode) => {
        return getIntersectingNodes(teeNode).filter((node) => node.type === 'TEE');
      })
      .filter((node, index, array) => array.findIndex((node2) => node2.id === node.id) === index);
  }, [getIntersectingNodes, getNodes]);

  const moveTees = useCallback(
    (intersectingTeeNodes: Node[], originTeeNode?: Node) => {
      const x = getGridSize(intersectingTeeNodes.length);
      let i = 1,
        j = 1;
      setNodes((nodes) =>
        nodes.map((node) => {
          if (node.id === originTeeNode?.id) {
            return {
              ...node,
              position: {
                x: calculateSnappedValue(node.position.x),
                y: calculateSnappedValue(node.position.y),
              },
            };
          }
          const teeNode = intersectingTeeNodes.find((tNode) => tNode.id === node.id);
          if (teeNode) {
            if (i++ >= x) {
              i = 1;
              j++;
            }
            const newPosition = calculateNewPosition(node, nodes, grid, i, j);
            return {
              ...node,
              position: newPosition,
            };
          }
          return node;
        })
      );
      intersectingTeeNodes.forEach((teeNode) => updateNodeInternals(teeNode.id));
      enqueueSnackbar(content.OVERLAPPING_TEES, {
        variant: 'info',
        preventDuplicate: true,
      });
    },
    [enqueueSnackbar, grid, setNodes, updateNodeInternals]
  );

  const moveAllOverlappingTees = useCallback(() => {
    const intersectingTeeNodes = getAllOverlappingTees();
    if (intersectingTeeNodes.length) {
      moveTees(intersectingTeeNodes);
    }
  }, [getAllOverlappingTees, moveTees]);

  const moveSingleOverlappingTee = useCallback(
    (teeNode: Node) => {
      const intersectingTeeNodes = getIntersectingNodes(teeNode).filter((node) => node.type === 'TEE');
      if (intersectingTeeNodes.length) {
        moveTees(intersectingTeeNodes, teeNode);
      } else {
        snapNodeToGrid(teeNode);
      }
    },
    [getIntersectingNodes, moveTees, snapNodeToGrid]
  );

  return { moveAllOverlappingTees, moveSingleOverlappingTee };
};
