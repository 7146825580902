import { LocationSearchWrapper } from '@marlin/alert/data-access/automated-action';
import { HierarchyBreadcrumbs } from '@marlin/asset/shared/ui/hierarchy-breadcrumbs';
import {
  MobileFilterModal,
  MobileFilterToggleButton,
  useMobileFilterForm,
} from '@marlin/asset/shared/ui/mobile-filter-modal';
import { IBaseFilters } from '@marlin/asset/shared/ui/mobile-filter-modal';
import {
  DeleteLocationModalBody,
  DeleteLocationModalFooter,
  DeleteLocationModalTitle,
} from '@marlin/asset/ui/delete-location-modal';
import { ActionDelete, ActionEdit, ContextMenu } from '@marlin/shared/ui-context-menu';
import { ChipButton, DeviceTypeChipControl, FieldFiltersChips, FormFilterChips } from '@marlin/shared/ui-form';
import { TDeviceType } from '@marlin/shared/ui-form';
import { InfiniteAutocompleteControl } from '@marlin/shared/ui-form-common';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { PageContainer, PageHeader } from '@marlin/shared/ui-page';
import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { useQueryParameters } from '@marlin/shared/utils/url-params';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

import { content } from '../content';
import { useLocationsDetailsContextMenu } from '../desktop/components/grid/use-location-details-context-menu.hook';
import { FilteredAssetList } from './components/linked-items/filtered-asset-list.component';
import { LocationTabs, TAB_VALUES } from './components/tabs/tabs.component';
import { LocationDetailsContent } from './location-details-content.component';
import { useStyles } from './location-details.styles';
import { useMobileLocationDetails } from './use-location-details.hook';

export interface IOption {
  id: string;
  name: string;
}

export type TDeviceSort = '';
export type TAssetType = 'EQUIPMENT';

export interface ILocationDetailsFilters extends IBaseFilters<TDeviceSort> {
  locations: IOption[];
  deviceTypes?: TDeviceType[];
  assetTypes?: TAssetType[];
}

export const LocationDetailsMobile = () => {
  const { classes } = useStyles();
  const { goTo } = useRouter();
  const [tabValue, setTabValue] = useState<TAB_VALUES>(TAB_VALUES.LinkedItems);
  const queryParams = useQueryParameters();
  const tabQueryParam = queryParams.get('tab');

  const {
    location,
    isLoading,
    isListLoading,
    setMobileFilters,
    refetch,
    hasNextPage,
    fetchNextPage,
    deviceDrawerRows,
    rows,
    totalItems,
  } = useMobileLocationDetails();

  const { allowDelete, allowEdit, editAsset } = useLocationsDetailsContextMenu();

  const { modalDispatch } = useContext(ModalContext);

  const defaultFilters: ILocationDetailsFilters = {
    locations: [],
    deviceTypes: [],
    assetTypes: [],
  };

  const mobileFilterForm = useMobileFilterForm<'', ILocationDetailsFilters>({ defaultFilters, sortList: [] });

  useEffect(() => {
    setMobileFilters(
      mobileFilterForm.filters.locations,
      mobileFilterForm.filters.deviceTypes || [],
      mobileFilterForm.filters.assetTypes || []
    );
  }, [mobileFilterForm.filters, setMobileFilters]);

  const handleEditAsset = () => {
    location && goTo(routes.locations.update.url(location?.id));
  };

  const handleAfterDelete = () => {
    goTo(routes.locations.list.path);
  };

  const openDeleteModal = () => {
    if (location && location.id) {
      const canDelete = !location.isParent && location.deviceCount === 0 && location.equipmentCount === 0;
      const reason = !canDelete ? (location.isParent ? 'parent' : 'assets') : undefined;
      modalDispatch({
        type: MODAL_ACTION_TYPE.SHOW,
        payload: {
          title: <DeleteLocationModalTitle canDelete={canDelete} />,
          body: (
            <DeleteLocationModalBody
              assetId={location.id}
              deleteAssetName={location.name}
              canDelete={canDelete}
              reason={reason}
            />
          ),
          footer: <DeleteLocationModalFooter assetId={location.id} canDelete={canDelete} />,
        },
      });
    }
  };

  const updateTab = useCallback(
    (tab: TAB_VALUES) => {
      location && goTo(routes.locations.details.query(tab));
    },
    [goTo, location]
  );

  useEffect(() => {
    switch (tabQueryParam) {
      case TAB_VALUES.LinkedItems:
        setTabValue(TAB_VALUES.LinkedItems);
        break;
      case TAB_VALUES.Details:
        setTabValue(TAB_VALUES.Details);
        break;
      default:
        setTabValue(TAB_VALUES.LinkedItems);
        break;
    }
  }, [tabQueryParam]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!location) {
    return null;
  }

  return (
    <PageContainer>
      <HierarchyBreadcrumbs locationId={location.id} selectedItemId={''} locationStyle />
      <PageHeader
        title={location.name || ''}
        prefix="location-details-mobile"
        rightSideActions={
          <div className={classes.sideActions}>
            <MobileFilterToggleButton onToggle={mobileFilterForm.toggleOpen} isOpen={mobileFilterForm.open} />
            <ContextMenu className={classes.mobileMenu}>
              {allowEdit && <ActionEdit onClick={handleEditAsset} />}
              {allowDelete && <ActionDelete onClick={openDeleteModal} />}
            </ContextMenu>
          </div>
        }
        bottomActions={<LocationTabs tabValue={tabValue} updateTab={updateTab} />}
        inlineActions
      />
      <LocationDetailsContent
        tab={tabValue}
        rows={rows}
        allowDelete={allowDelete}
        allowEdit={allowEdit}
        handleAfterDelete={handleAfterDelete}
        editAsset={editAsset}
        refetch={refetch}
        location={location}
        setMobileFilters={setMobileFilters}
        totalItems={totalItems}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isListLoading={isListLoading}
        deviceDrawerRows={deviceDrawerRows}
        mobileFilterForm={mobileFilterForm}
      />
      <MobileFilterModal<TDeviceSort, ILocationDetailsFilters>
        {...mobileFilterForm}
        title={content.LINKED_ITEMS}
        chips={
          <FormFilterChips<ILocationDetailsFilters> defaultFilters={defaultFilters}>
            <FieldFiltersChips<ILocationDetailsFilters, 'locations'>
              fieldName="locations"
              mapFieldToChips={(value) => value}
              deleteFilter={(id, values) => values.filter((value) => id !== value.id)}
            />
          </FormFilterChips>
        }
        rows={
          <FilteredAssetList
            editAsset={editAsset}
            allowDelete={allowDelete}
            allowEdit={allowEdit}
            handleAfterDelete={handleAfterDelete}
          />
        }
      >
        <div>
          <Controller<ILocationDetailsFilters>
            name="assetTypes"
            render={({ field }) => {
              const currentValue = (field.value as TAssetType[]) || [];

              const isActive = (value: TAssetType) => currentValue.includes(value);

              const onAssetFilterChangeFactory = (value: TAssetType) => () => {
                const newValue = isActive(value)
                  ? currentValue.filter((assetType) => assetType !== value)
                  : [...currentValue, value];

                field.onChange(newValue);
              };

              return (
                <ChipButton
                  label={content.EQUIPMENT_CHIP}
                  onClick={onAssetFilterChangeFactory('EQUIPMENT')}
                  active={isActive('EQUIPMENT')}
                />
              );
            }}
          />
        </div>
        <div>
          <DeviceTypeChipControl<ILocationDetailsFilters> fieldName="deviceTypes" multiple />
        </div>

        <LocationSearchWrapper parentLocation={location.id}>
          {(props) => (
            <InfiniteAutocompleteControl<ILocationDetailsFilters>
              fieldName="locations"
              testId="location-search"
              label="Location"
              {...props}
            />
          )}
        </LocationSearchWrapper>
      </MobileFilterModal>
    </PageContainer>
  );
};
