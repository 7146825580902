import { LocationHierarchy } from '@marlin/asset/ui/hierarchy-mobile';
import { MarlinTheme } from '@marlin/shared/theme';
import { SectionHeader, SectionTitle } from '@marlin/shared/ui-asset-card-mobile';
import { TEquipment } from '@marlin/shared/utils/zod';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    height: '100%',
  },
}));

interface IHierarchyViewProps {
  equipment?: TEquipment;
}

export const HierarchyView = ({ equipment }: IHierarchyViewProps) => {
  const { classes } = useStyles();

  if (!equipment) {
    return null;
  }

  return (
    <div className={classes.container}>
      <SectionHeader>
        <SectionTitle testId="hierarchy-tab">{content.HIERARCHY_TAB_TITLE}</SectionTitle>
      </SectionHeader>
      <LocationHierarchy locationId={equipment.locationId} equipment={equipment} />
    </div>
  );
};
