import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

interface IHomeListNoMechanicalRoomsStyles {
  isMobile?: boolean;
}

export const useStyles = makeStyles<IHomeListNoMechanicalRoomsStyles>()((theme: MarlinTheme, { isMobile }) => ({
  root: {
    marginTop: !isMobile ? theme.typography.pxToRem(24) : 0,
    marginBottom: !isMobile ? theme.typography.pxToRem(24) : 0,

    width: !isMobile ? theme.typography.pxToRem(416) : 'auto',
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
    borderRadius: theme.typography.pxToRem(4),
    overflow: 'hidden',
  },

  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.typography.pxToRem(16),
    backgroundColor: theme.palette.background.alternative,
  },

  noMechanicalRoomsTitle: {
    marginBottom: theme.typography.pxToRem(12),
  },

  noMechanicalRoomsBody: {
    marginBottom: theme.typography.pxToRem(16),
    color: theme.palette.text.primary,
  },

  createRoomButton: {
    width: '100%',
    marginBottom: theme.typography.pxToRem(12),
  },

  goToLocationsHubButton: {
    width: '100%',
    backgroundColor: theme.palette.primary.contrastText,
  },
}));
