import {} from '@marlin/alert/data-access/automated-action';
import { AUTOMATION_RULE_TYPE, TCreateAutomation, useAutomations } from '@marlin/alert/data-access/automated-action';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { content } from '../../../content/validation-content';
import { isGatewayDurationLonger } from './utils';
import { Warning } from './warning.component';

export function DeviceDurationWarning() {
  const { watch } = useFormContext<TCreateAutomation>();

  const automationsQuery = useAutomations({
    params: {
      isSystemRule: true,
      locationId: [],
      equipmentId: [],
      deviceId: [],
      isEnabled: null,
      page: 1,
      pageSize: 10,
    },
  });

  const deviceDurationValue = watch('duration.value');
  const deviceDurationUnit = watch('duration.unit');

  const gatewayDuration = useMemo(
    () =>
      automationsQuery.data?.data.find(({ ruleType }) => ruleType === AUTOMATION_RULE_TYPE.GATEWAY_LOST_COMMUNICATION)
        ?.duration,
    [automationsQuery.data?.data]
  );

  const showWarning = useMemo(
    () =>
      isGatewayDurationLonger({
        gatewayDuration,
        deviceDuration: { value: deviceDurationValue, unit: deviceDurationUnit },
      }),
    [deviceDurationValue, deviceDurationUnit, gatewayDuration]
  );

  return showWarning ? <Warning message={content.SENSOR_DURATION_WARNING} /> : null;
}
