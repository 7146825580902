import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: `calc(33% - ${theme.typography.pxToRem(12)})`,
    marginBottom: theme.typography.pxToRem(24),
    backgroundColor: theme.palette.background.primary,
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
    borderRadius: theme.typography.pxToRem(4),
    height: 'fit-content',
    maxHeight: theme.typography.pxToRem(508),
  },
  assetsWrapper: {
    padding: theme.typography.pxToRem(16),
    paddingBottom: 0,
    overflowY: 'hidden',
    backgroundColor: theme.palette.background.alternative,
    height: 'fit-content',
  },
}));
