import { Button } from '@mui/material';

import { content } from '../content';

export const ModalFooter = ({ handleCloseModal }: { handleCloseModal: () => void }) => {
  return (
    <Button variant="text" onClick={handleCloseModal}>
      {content.CLOSE}
    </Button>
  );
};
