import { MarlinTheme } from '@marlin/shared/theme';
import { ModalFullScreen } from '@marlin/shared/ui-modal';
import { Paper } from '@marlin/shared/ui-page';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { getLogger } from '@marlin/shared/utils/logger';
import {
  TCreateSystemMapLinkParams,
  TCreateSystemMapNodeParams,
  useCreateLink,
} from '@marlin/system-map/data-access/system-map';
import { FlowLinkEditModal } from '@marlin/system-map/shared/flow-link-edit-modal';
import { SystemMapLinkForm } from '@marlin/system-map/shared/system-map-link-form';
import { ReactFlowProvider } from '@xyflow/react';
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { Diagram } from './diagram.component';
import { LastReadingTimer } from './last-reading-timer/last-reading-timer.component';
import { TTeeGraphAsset } from './types';

interface ITag {
  name: string;
  value?: string | null;
}

export const createLinkTags = (link: TCreateSystemMapLinkParams): ITag[] => {
  const tags: ITag[] = [];
  const inletHandleTag = link.inletData?.tags?.find((tag) => tag.name === 'HandleId');
  const outletHandleTag = link.outletData?.tags?.find((tag) => tag.name === 'HandleId');

  if (inletHandleTag) {
    tags.push({ name: 'InletHandleId', value: inletHandleTag.value });
  }
  if (outletHandleTag) {
    tags.push({ name: 'OutletHandleId', value: outletHandleTag.value });
  }

  return tags;
};

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    padding: 0,
    marginBottom: theme.typography.pxToRem(24),
    height: theme.typography.pxToRem(664),
    position: 'relative',
    zIndex: 0,
  },
  header: {
    padding: theme.typography.pxToRem(24),
    display: 'flex',
    justifyContent: 'space-between',
  },
  canvas: {
    height: '100%',
    position: 'relative',
  },
  floatingMenu: {
    position: 'absolute',
    bottom: theme.typography.pxToRem(24),
    right: theme.typography.pxToRem(24),
    backgroundColor: theme.palette.background.primary,
  },
}));

export interface ISystemMapProps {
  openRenameTeeModal: (teeToRename: TTeeGraphAsset) => void;
}

const content = {
  ADD_LINK_SUCCESS_MESSAGE: 'Link added successfully',
  ADD_LINK_ERROR_MESSAGE: 'Link could not be added',
};

export function SystemMap({ openRenameTeeModal }: ISystemMapProps) {
  const { classes } = useStyles();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [modal, setModal] = useState<TCreateSystemMapNodeParams | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const createLinkMutation = useCreateLink();

  const closeModal = () => setModal(null);

  const toggleFullScreen = () => {
    setIsFullScreen((prevState) => !prevState);
  };

  const onAddNewNode = async (value: TCreateSystemMapLinkParams) => {
    try {
      const tags = createLinkTags(value);
      await createLinkMutation.mutateAsync({
        ...value,
        linkOptions: tags.length ? { tags } : undefined,
      });
      closeModal();
      enqueueSnackbar(content.ADD_LINK_SUCCESS_MESSAGE, {
        variant: 'success',
        preventDuplicate: true,
      });
      getLogger()?.track('AddNewNode', { ...value });
    } catch (e) {
      enqueueSnackbar(content.ADD_LINK_ERROR_MESSAGE, {
        variant: 'error',
        preventDuplicate: true,
      });
    }
  };

  return (
    <>
      <Paper className={classes.container}>
        <ReactFlowProvider>
          <Diagram
            externalClasses={classes}
            isFullScreen={isFullScreen}
            toggleFullScreen={toggleFullScreen}
            onAddNodeToTee={setModal}
            openRenameTeeModal={openRenameTeeModal}
          />
        </ReactFlowProvider>
      </Paper>
      <LastReadingTimer />
      {isFullScreen && (
        <ModalFullScreen isFullScreen={isFullScreen} dataTestId="system-map-full-screen">
          <ReactFlowProvider>
            <Diagram
              externalClasses={classes}
              isFullScreen={isFullScreen}
              toggleFullScreen={toggleFullScreen}
              onAddNodeToTee={setModal}
              openRenameTeeModal={openRenameTeeModal}
            />
          </ReactFlowProvider>
        </ModalFullScreen>
      )}
      {modal && (
        <FlowLinkEditModal onClose={closeModal}>
          <SystemMapLinkForm closeModal={closeModal} defaultValues={modal} onSubmit={onAddNewNode} />
        </FlowLinkEditModal>
      )}
    </>
  );
}
