import { LeaveRouteGuard, useSearchParams } from '@marlin/shared/utils-router';
import { SETTINGS_GROUP } from '@marlin/shared/utils/datapoint-mappers';

import { TEditedSetting } from '../types';
import { ConfirmationModal } from './confirmation-modal.component';

interface ISettingsLeaveGuardProps {
  saveSetting: (name: string, value: string, prevValue: string) => Promise<void>;
  editedSetting: TEditedSetting | undefined;
  setEditedSetting: (option: TEditedSetting | undefined) => void;
  isDirty: boolean;
  setIsDirty: (isDirty: boolean) => void;
}

export const SettingsLeaveGuard = ({
  saveSetting,
  setEditedSetting,
  isDirty,
  setIsDirty,
  editedSetting,
}: ISettingsLeaveGuardProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const settingGroupId = searchParams.get('settingGroupId');
  const settingId = searchParams.get('settingId');

  const handleOnConfirm = (onConfirm: () => void) => {
    setSearchParams((currentParams) => {
      if (settingId) {
        currentParams.delete('settingId');
      } else if (settingGroupId) {
        currentParams.delete('settingGroupId');
      }

      return currentParams;
    });

    setEditedSetting(undefined);
    setIsDirty(false);
    onConfirm();
  };

  const handleOnSave = async (onCancel: () => void, onConfirm: () => void) => {
    if (settingId && editedSetting?.updatedValue) {
      try {
        await saveSetting(settingId, editedSetting.updatedValue, editedSetting.currentValue);
        handleOnConfirm(onConfirm);
      } catch {
        onCancel();
      }
    }
  };

  return (
    <LeaveRouteGuard
      when={isDirty && settingGroupId !== SETTINGS_GROUP.SCHEDULE}
      watchForSearchParamsChange
      watchForShouldIgnoreGuard
    >
      {({ onCancel, onConfirm }) => {
        return (
          <ConfirmationModal
            onCancel={onCancel}
            onSave={() => handleOnSave(onCancel, onConfirm)}
            onConfirm={() => handleOnConfirm(onConfirm)}
          />
        );
      }}
    </LeaveRouteGuard>
  );
};
