import { z } from 'zod';

import { meterTypeSchema } from './device.response.schema';
import { DeviceTypeSchema, emptyTextSchema, sortingSchema } from './utils.schema';

const createMeterTypesSchema = z
  .array(meterTypeSchema)
  .nullish()
  .transform((data) => (!data?.length ? undefined : data));

export const createDeviceTypeSchema = DeviceTypeSchema.nullish().transform((deviceType) => {
  switch (deviceType) {
    case 'TEMPERATURE': {
      return 'Temperature';
    }
    case 'PRESSURE': {
      return 'Pressure';
    }
    case 'LEAK': {
      return 'Leak';
    }
    case 'PULSE_METER': {
      return 'FlowMeter';
    }
    default: {
      return undefined;
    }
  }
});

export const batteryLevelStatusSchema = z.union([
  z.literal('CRITICAL'),
  z.literal('LOW'),
  z.literal('OK'),
  z.literal('LINE'),
  z.literal('NONE'),
]);

export const batteryLevelStatusParamsSchema = batteryLevelStatusSchema.transform((criticality) => {
  switch (criticality) {
    case 'CRITICAL': {
      return 'Critical';
    }
    case 'LOW': {
      return 'Low';
    }
    case 'OK': {
      return 'Ok';
    }
    case 'LINE': {
      return 'Line';
    }
    case 'NONE':
    default: {
      return 'None';
    }
  }
});

export const createDeviceSchema = z.object({
  name: z.string(),
  locationId: z.string(),
  equipmentId: emptyTextSchema,
  deviceType: createDeviceTypeSchema.nullish(),
  manufacturerId: z.string(),
  manufacturer: emptyTextSchema,
  model: emptyTextSchema,
  description: emptyTextSchema,
  commissionDate: emptyTextSchema,
  pulseMeterTypeId: emptyTextSchema,
  pulseMeterTypeIds: z
    .array(
      z.object({
        pulseMeterTypeId: z.string(),
        order: z.number(),
      })
    )
    .nullish(),
});

export const getDeviceParamsSchema = z.object({
  search: z.string().nullish(),
  locations: z.array(z.string()).nullish(),
  deviceTypes: z.array(DeviceTypeSchema).nullish(),
  meterTypes: createMeterTypesSchema,
  page: z.number(),
  pageSize: z.number(),
  sorting: sortingSchema.optional(),
});

export const getDeviceRequestSchema = z.object({
  search: z.string().nullish(),
  locations: z.array(z.string()).nullish(),
  deviceTypes: z
    .array(createDeviceTypeSchema)
    .nullish()
    .transform((data) => (!data?.length ? undefined : data)),
  meterTypes: createMeterTypesSchema,
  page: z.number(),
  pageSize: z.number(),
  sorting: sortingSchema.optional(),
  batteryLevelStatus: batteryLevelStatusParamsSchema.nullish(),
  lastReadingTo: z.string().nullish(),
});

export type TDeviceParamsSchema = z.infer<typeof getDeviceParamsSchema>;
export type TDeviceRequestSchema = z.infer<typeof getDeviceRequestSchema>;
export type TBatteryLevelStatus = z.infer<typeof batteryLevelStatusSchema>;
