import { TExtractFnReturnType, useQuery } from '@marlin/shared/utils/react-query';

import { getFilteredWebhooks } from '../infrastructure/get-filtered-webhooks';
import { TGetFilteredWebhooksParams } from '../schemas/get-filtered-webhooks.schema';
import { queryKey } from './query-key.enum';

type TQueryFnType = typeof getFilteredWebhooks;

type TUseFilteredWebhooksWithPaging = {
  params: TGetFilteredWebhooksParams;
};

export const useFilteredWebhooksWithPaging = ({ params }: TUseFilteredWebhooksWithPaging) => {
  return useQuery<TExtractFnReturnType<TQueryFnType>>({
    queryKey: queryKey.FILTERED_WEBHOOKS(params),
    queryFn: () => getFilteredWebhooks(params),
    keepPreviousData: true,
  });
};

type TUseFilteredWebhooksWithLoadMoreProps = {
  params: Omit<TGetFilteredWebhooksParams, 'page'>;
  enabled: boolean;
};

export const useFilteredWebhooksWithLoadMore = ({ params, enabled }: TUseFilteredWebhooksWithLoadMoreProps) => {
  return useQuery<TExtractFnReturnType<TQueryFnType>>({
    queryKey: queryKey.FILTERED_WEBHOOKS(params),
    queryFn: (queryFnParams) => getFilteredWebhooks({ ...params, page: queryFnParams.pageParam || 1 }),
    getNextPageParam: (lastPage) => {
      return lastPage.pagination.page * lastPage.pagination.pageSize < lastPage.pagination.totalItems
        ? lastPage.pagination.page + 1
        : undefined;
    },
    enabled,
  });
};
