import { createContext, useContext } from '@marlin/shared/utils-component-context';
import { PropsWithChildren } from 'react';

import { boilerConfig } from '../config/boiler';
import { waterHeaterConfig } from '../config/water-heater';
import { PLANT_TYPE } from '../types';

// TODO: Adjust config once new sections are added
export interface IPlantConfig {
  plantListTitle: string;
  title: string;
  subtitle: string;
  overviewTitle: string;
  managerDatapointName: string;
  backupManagerDatapointName: string;
  performance: { name: string; label: string; eventCodes?: string[] }[];
  calculatedValue?: boolean;
  chartData: {
    datapointNames: string[];
    datapointGroupNames: string[];
  };
  activeSetpointDatapointName: string;
}

interface IPlantConfigContext {
  config: IPlantConfig;
}

const PlantConfigContext = createContext<IPlantConfigContext>();

const getPlantConfig = (model: PLANT_TYPE) => {
  switch (model) {
    case PLANT_TYPE.WaterHeater:
      return waterHeaterConfig;

    default:
      return boilerConfig;
  }
};

export const PlantConfigContextProvider = ({ model, children }: PropsWithChildren<{ model: PLANT_TYPE }>) => {
  const config = getPlantConfig(model);

  return <PlantConfigContext.Provider value={{ config }}>{children}</PlantConfigContext.Provider>;
};

export const usePlantConfigContext = () => useContext(PlantConfigContext);
