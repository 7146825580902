import { TDateString, getTimeFromNow } from '@marlin/shared/utils-common-date';
import { formatDeviceReadings, formatReading, parseDeviceReadings } from '@marlin/shared/utils-format-reading';
import { TDeviceType } from '@marlin/system-map/shared/data-access-schemas';
import isNil from 'lodash/isNil';

const telemetryRangeDays = 7;

export const mapReading = (lastReading?: number | null, lastReadingTime?: TDateString, deviceType?: TDeviceType) => {
  const roundedValue = parseDeviceReadings(lastReading, deviceType);

  if (lastReadingTime && !isRecentReading(lastReadingTime)) {
    return formatReading({ value: null, type: 'UNKNOWN' });
  }

  if (roundedValue.type === 'LEAK') {
    return formatReading(roundedValue);
  }

  return formatReading(formatDeviceReadings(roundedValue.value, roundedValue.type));
};

const isRecentReading = (lastReadingTime: TDateString): boolean => {
  const daysSinceLastReading = getTimeFromNow(lastReadingTime, 'days');

  return !isNil(daysSinceLastReading) && daysSinceLastReading <= telemetryRangeDays;
};
