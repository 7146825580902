import { TEquipmentSchema, useFilteredEquipmentsWithLoadMore } from '@marlin/asset/data-access/equipment';
import { statusDatapoints } from '@marlin/asset/shared/ui/equipment-list';
import { useMemo } from 'react';

const defaultParams = {
  pageSize: 10,
  datapoints: statusDatapoints,
};

export const useEquipmentsList = (gatewayIds: string[]) => {
  const params = useMemo(
    () => ({
      params: {
        ...defaultParams,
        gatewayIds,
      },
    }),
    [gatewayIds]
  );
  const equipmentsQuery = useFilteredEquipmentsWithLoadMore(params);

  const rows: TEquipmentSchema[] = useMemo(() => {
    const unfilteredRows: (TEquipmentSchema | null)[] = equipmentsQuery.data?.pages?.flatMap((i) => i.data) || [];
    return unfilteredRows.filter((i: TEquipmentSchema | null): i is TEquipmentSchema => i !== null);
  }, [equipmentsQuery.data?.pages]);

  return {
    rows,
    totalItems: equipmentsQuery.data?.pages[0].pagination.totalItems || 0,
    hasNextPage: equipmentsQuery.hasNextPage,
    fetchNextPage: equipmentsQuery.fetchNextPage,
    refetch: equipmentsQuery.refetch,
    isLoading: equipmentsQuery.isLoading,
    isError: equipmentsQuery.isError,
    error: equipmentsQuery.error,
  };
};
