import { FC, PropsWithChildren } from 'react';

import { ButtonExpandComponent } from './button/button.component';
import { useStyles } from './pull-tab.styles';

interface IPullTabContainerProps extends PropsWithChildren {
  onCollapse: () => void;
  onExpand: () => void;
  isExtended: boolean;
  hovered: boolean;
}

export const PullTabContainer: FC<IPullTabContainerProps> = ({
  children,
  isExtended,
  onCollapse,
  onExpand,
  hovered,
}) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      {isExtended && (
        <div className={classes.pullTab}>
          <ButtonExpandComponent onClick={onCollapse} isOpen={true} />
        </div>
      )}
      {!isExtended && hovered && (
        <div className={classes.pullTab}>
          <ButtonExpandComponent onClick={onExpand} />
        </div>
      )}
      <div>{children}</div>
    </div>
  );
};
