import { MarlinTheme } from '@marlin/shared/theme';
import { formatTimestamp } from '@marlin/shared/utils-common-date';
import { alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';

export const customFormat = 'MM/DD/YY HH:mm A z';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  noData: {
    fontSize: theme.typography.pxToRem(12),
    backgroundColor: theme.palette.background.primary,
    padding: theme.typography.pxToRem(12),
    borderWidth: theme.typography.pxToRem(1),
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
    borderRadius: theme.typography.pxToRem(4),
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
  },
  noDataInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.typography.pxToRem(4),
  },
  dot: {
    width: theme.typography.pxToRem(8),
    height: theme.typography.pxToRem(8),
    borderRadius: '50%',
    backgroundColor: alpha(theme.palette.error.main, 0.12),
    marginRight: theme.typography.pxToRem(4),
  },
  range: {
    fontSize: theme.typography.pxToRem(10),
  },
}));

interface IEmptyChartMessage {
  from?: string | number;
  to?: string | number;
}

export const EmptyChartMessage = ({ from, to }: IEmptyChartMessage) => {
  const { classes } = useStyles();

  return (
    <div className={classes.noData}>
      <div className={classes.noDataInfo}>
        <div className={classes.dot} />
        {content.NO_DATA_ON_MAINCHART}
      </div>
      <div className={classes.range}>
        {content.NO_DATA_ON_MAINCHART_RANGE(formatTimestamp(from, customFormat), formatTimestamp(to, customFormat))}
      </div>
    </div>
  );
};
