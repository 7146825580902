import { content } from './content';
import { COMPARE_TO_MODE, TCompareToValue } from './types';

export const defaultCompareToOption: TCompareToValue = {
  mode: COMPARE_TO_MODE.PREVIOUS_PERIOD,
  label: content.COMPARE_TO.PREVIOUS_PERIOD,
};

export const compareToOptions: TCompareToValue[] = [
  defaultCompareToOption,
  {
    mode: COMPARE_TO_MODE.MONTH_OVER_MONTH,
    label: content.COMPARE_TO.MONTH_OVER_MONTH,
  },
  {
    mode: COMPARE_TO_MODE.QUARTER_OVER_QUARTER,
    label: content.COMPARE_TO.QUARTER_OVER_QUARTER,
  },
  {
    mode: COMPARE_TO_MODE.YEAR_OVER_YEAR,
    label: content.COMPARE_TO.YEAR_OVER_YEAR,
  },
];
