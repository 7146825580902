import { AVERAGING_FUNCTION_FILTER, RANGE_FILTER } from '@marlin/shared/utils-chart';
import moment from 'moment';
import { useCallback, useReducer } from 'react';

export interface IRangeFilterState {
  range: RANGE_FILTER;
  currentDate: moment.Moment;
}

interface IRangeState {
  rangeFilter: IRangeFilterState;
  averagingFunctionFilter: AVERAGING_FUNCTION_FILTER;
}

const initialState: IRangeState = {
  rangeFilter: {
    range: RANGE_FILTER.DAYS_7,
    currentDate: moment().utc(),
  },
  averagingFunctionFilter: AVERAGING_FUNCTION_FILTER.AVERAGE,
};

type TAction =
  | { type: 'SET_RANGE_FILTER'; payload: { range: RANGE_FILTER; currentDate: moment.Moment } }
  | { type: 'SET_AVERAGING_FUNCTION_FILTER'; payload: AVERAGING_FUNCTION_FILTER }
  | { type: 'RESET' };

const chartModalReducer = (state: IRangeState, action: TAction): IRangeState => {
  switch (action.type) {
    case 'SET_RANGE_FILTER':
      return {
        ...state,
        rangeFilter: action.payload,
      };
    case 'SET_AVERAGING_FUNCTION_FILTER':
      return {
        ...state,
        averagingFunctionFilter: action.payload,
      };
    case 'RESET':
      return initialState;
    default:
      return state;
  }
};

interface IUseChartRange {
  state: IRangeState;
  onRangeFilterChange: (range: RANGE_FILTER) => void;
  onAveragingFunctionFilterChange: (averagingFunctionFilter: AVERAGING_FUNCTION_FILTER) => void;
  onReset: () => void;
}

export const useChartRange = (): IUseChartRange => {
  const [state, dispatch] = useReducer(chartModalReducer, initialState);

  const onRangeFilterChange = useCallback(
    (range: RANGE_FILTER) => {
      dispatch({ type: 'SET_RANGE_FILTER', payload: { range, currentDate: moment().utc() } });
    },
    [dispatch]
  );

  const onAveragingFunctionFilterChange = useCallback(
    (averagingFunctionFilter: AVERAGING_FUNCTION_FILTER) => {
      dispatch({ type: 'SET_AVERAGING_FUNCTION_FILTER', payload: averagingFunctionFilter });
    },
    [dispatch]
  );

  const onReset = useCallback(() => {
    dispatch({ type: 'RESET' });
  }, []);

  return { state, onRangeFilterChange, onAveragingFunctionFilterChange, onReset };
};
