import { TGraphLink } from '@marlin/system-map/data-access/system-map';
import { BaseEdge, Edge, EdgeProps, useInternalNode, useStore } from '@xyflow/react';
import { useContext } from 'react';

import { EditModeContext } from '../../edit-mode.context';
import { TSystemMapInternalNode } from '../../types';
import { ControlPoint } from './path/control-point.component';
import { TEditableEdgeData } from './path/types';
import { usePath } from './path/use-path.hook';
import { useEdgeProps } from './use-edge-props.hook';
import { MARKER_TYPE } from './use-marker-definitions.hook';

const createMarkerEnd = (type: string = MARKER_TYPE.ARROW_DEFAULT) => `url(#${type})`;

export function SystemMapEdge({
  id,
  style,
  source,
  target,
  data,
  sourceHandleId,
  targetHandleId,
  selected: showControlPoints,
}: EdgeProps<Edge<TGraphLink['data'] & TEditableEdgeData>>) {
  const { isEditMode } = useContext(EditModeContext);

  const sourceNode = useInternalNode<TSystemMapInternalNode>(source);
  const targetNode = useInternalNode<TSystemMapInternalNode>(target);

  const edgeProps = useEdgeProps(data, sourceNode, targetNode);

  const ancestors = useStore((store) => {
    const nodes = Array.from(store.nodeLookup.values());
    const getAncestorsList = (nodeId: string): string[] => {
      const node = nodes.find((n) => n.id === nodeId);
      if (node && node.parentId) {
        return [node.parentId, ...getAncestorsList(node.parentId)];
      }
      return [nodeId];
    };

    return {
      sourceAncestors: getAncestorsList(source),
      targetAncestors: getAncestorsList(target),
    };
  });

  const { path, controlPoints, setControlPoint } = usePath({
    id,
    data,
    sourceHandleId,
    targetHandleId,
    sourceNode,
    targetNode,
  });

  if (!sourceNode || !targetNode) {
    return null;
  }

  if (ancestors.sourceAncestors.includes(target) || ancestors.targetAncestors.includes(source)) {
    return null;
  }

  return (
    <>
      <BaseEdge
        id={id}
        path={path}
        style={{
          ...style,
          stroke: edgeProps.color,
        }}
        markerEnd={createMarkerEnd(edgeProps.type)}
      />
      {showControlPoints &&
        isEditMode &&
        controlPoints.map((point, index) => (
          <ControlPoint key={point.id} index={index} setControlPoint={setControlPoint} {...point} />
        ))}
    </>
  );
}
