import { MarlinTheme } from '@marlin/shared/theme';
import { Link } from '@marlin/shared/ui-page';
import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { TAssetType, TDeviceType } from '@marlin/system-map/shared/data-access-schemas';
import CommitRoundedIcon from '@mui/icons-material/CommitRounded';
import DeviceHubRoundedIcon from '@mui/icons-material/DeviceHubRounded';
import EggRoundedIcon from '@mui/icons-material/EggRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import PanoramaPhotosphereOutlinedIcon from '@mui/icons-material/PanoramaPhotosphereOutlined';
import SensorsRoundedIcon from '@mui/icons-material/SensorsRounded';
import SpeedRoundedIcon from '@mui/icons-material/SpeedRounded';
import ThermostatRoundedIcon from '@mui/icons-material/ThermostatRounded';
import { useMediaQuery } from '@mui/material';
import { useCallback, useContext, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  assetLinkWrapper: {
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.typography.pxToRem(8),
    flexWrap: 'wrap',
    color: theme.palette.primary.main,
    '& > a': {
      color: theme.palette.primary.main,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: theme.typography.pxToRem(280),
    },
  },

  assetLinkWrapperDisabled: {
    color: theme.palette.text.primary,
    '& > a': {
      color: theme.palette.text.primary,
    },
  },

  assetLinkWrapperNoIcon: {
    color: theme.palette.primary.main,
    marginLeft: theme.typography.pxToRem(32),
    '& > a': {
      color: theme.palette.primary.main,
    },
  },
}));

const DeviceTypeLabel = ({ deviceType }: { deviceType: TDeviceType | null }) => {
  switch (deviceType) {
    case 'TEMPERATURE': {
      return <ThermostatRoundedIcon color="disabled" />;
    }
    case 'PRESSURE': {
      return <SpeedRoundedIcon color="disabled" />;
    }
    case 'LEAK': {
      return <EggRoundedIcon color="disabled" />;
    }
    case 'PULSE_METER': {
      return <PanoramaPhotosphereOutlinedIcon color="disabled" />;
    }
    default: {
      return <SensorsRoundedIcon color="disabled" />;
    }
  }
};

export interface IAssetLinkProps {
  asset: {
    id: string | null;
    type?: TAssetType | null;
    name: string | null;
    deviceType?: TDeviceType | null;
    isTee?: boolean;
    linkedId?: string | null;
  };
}

export const AssetLink = ({ asset }: IAssetLinkProps) => {
  const { classes, cx, theme } = useStyles();
  const router = useRouter();
  const currentUrl = useMemo(() => router.getUrl(), [router]);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { modalDispatch } = useContext(ModalContext);

  const handleCloseModal = useCallback(() => {
    modalDispatch({
      type: MODAL_ACTION_TYPE.DISMISS,
    });
  }, [modalDispatch]);

  if (!asset.id) {
    return null;
  }

  if (asset.isTee) {
    return (
      <div className={cx(classes.assetLinkWrapper, { [classes.assetLinkWrapperDisabled]: isMobile })}>
        <CommitRoundedIcon color="disabled" />
        {isMobile ? (
          asset.name
        ) : (
          <Link onClick={handleCloseModal} to={routes.systemMap.root}>
            {asset.name}
          </Link>
        )}
      </div>
    );
  }

  switch (asset.type) {
    case 'LOCATION': {
      return (
        <div className={classes.assetLinkWrapper}>
          <LocationOnRoundedIcon color="disabled" />
          <Link onClick={handleCloseModal} to={routes.locations.details.url(asset.linkedId ?? asset.id)}>
            {asset.name}
          </Link>
        </div>
      );
    }
    case 'EQUIPMENT': {
      return (
        <div className={classes.assetLinkWrapper}>
          <DeviceHubRoundedIcon color="disabled" />
          <Link onClick={handleCloseModal} to={routes.equipments.details.url(asset.linkedId ?? asset.id)}>
            {asset.name}
          </Link>
        </div>
      );
    }
    case 'DEVICE': {
      if (asset.deviceType) {
        if (asset.deviceType === 'EQUIPMENT') {
          return (
            <div className={classes.assetLinkWrapper}>
              <DeviceHubRoundedIcon color="disabled" />
              <Link onClick={handleCloseModal} to={routes.equipmentDashboard.details.url(asset.linkedId ?? asset.id)}>
                {asset.name}
              </Link>
            </div>
          );
        }
        return (
          <div className={classes.assetLinkWrapper}>
            <DeviceTypeLabel deviceType={asset.deviceType} />
            <Link
              onClick={handleCloseModal}
              to={routes.deviceDrawer.open(currentUrl, asset.linkedId ?? (asset.id || ''))}
            >
              {asset.name}
            </Link>
          </div>
        );
      }

      return (
        <div className={classes.assetLinkWrapperNoIcon}>
          <Link
            onClick={handleCloseModal}
            to={routes.deviceDrawer.open(currentUrl, asset.linkedId ?? (asset.id || ''))}
          >
            {asset.name}
          </Link>
        </div>
      );
    }

    default: {
      return <div>{asset.type}</div>;
    }
  }
};
