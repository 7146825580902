export const content = {
  SIDE_NAV_HOME: 'Home',
  SIDE_NAV_SYSTEM_MAP: 'System Map',
  SIDE_NAV_LOCATIONS: 'Locations',
  SIDE_NAV_EQUIPMENT: 'Equipment',
  SIDE_NAV_SENSORS: 'Sensors',
  SIDE_NAV_GATEWAYS: 'Gateways',
  SIDE_NAV_FLOW_MAP: 'System Map',
  SIDE_NAV_AUTOMATIONS: 'Automations',
  SIDE_NAV_ANALYTICS_V2: 'Analytics v2 (Plotly)',
  SIDE_NAV_ALERTS: 'Alerts',
  SIDE_NAV_ANALYTICS: 'Analytics',
  SIDE_NAV_SUSTAINABILITY: 'Sustainability',
  SIDE_NAV_SETTINGS: 'Settings',
  SIDE_NAV_HELP: 'Help',
};
