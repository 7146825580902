import { useMediaQuery, useTheme } from '@mui/material';

import { DesktopDevicesHub } from './desktop/devices-hub.component';
import { MobileDevicesHub } from './mobile/devices-hub.component';

export const DevicesHub = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (isMobile) {
    return <MobileDevicesHub />;
  }

  return <DesktopDevicesHub />;
};
