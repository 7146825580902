export enum DEVICE_TYPE {
  MOBILE = 'mobile',
  TABLET = 'tablet',
}

export enum DEVICE_VENDOR {
  APPLE = 'Apple',
}

export enum BROWSER_NAME {
  FIREFOX = 'Firefox',
}
