import { formatDeviceReadings } from './format-device-reading';
import {
  IFlowMeterReading,
  ILeakReading,
  IPressureReading,
  ITemperatureReading,
  IUnknownReading,
  TDeviceType,
  TRound,
} from './model';
import { parseDeviceReadings } from './parse-device-readings';

/**
 * @deprecated useDeviceReadings - use deviceReadingsFormatter instead. This is not a hook anymore, needs to be deleted
 */
export function useDeviceReadings<T extends 'PRESSURE'>(
  value: number | undefined | null,
  deviceType: T,
  round?: TRound
): IPressureReading;
/**
 * @deprecated useDeviceReadings - use deviceReadingsFormatter instead. This is not a hook anymore, needs to be deleted
 */
export function useDeviceReadings<T extends 'LEAK'>(
  value: number | undefined | null,
  deviceType: T,
  round?: TRound
): ILeakReading;
/**
 * @deprecated useDeviceReadings - use deviceReadingsFormatter instead. This is not a hook anymore, needs to be deleted
 */
export function useDeviceReadings<T extends 'UNKNOWN'>(
  value: number | undefined | null,
  deviceType: T,
  round?: TRound
): IUnknownReading;
/**
 * @deprecated useDeviceReadings - use deviceReadingsFormatter instead. This is not a hook anymore, needs to be deleted
 */
export function useDeviceReadings<T extends 'TEMPERATURE' | 'PRESSURE' | 'PULSE_METER'>(
  value: number | undefined | null,
  deviceType: T,
  round?: TRound
): ITemperatureReading | IPressureReading | IFlowMeterReading;
/**
 * @deprecated useDeviceReadings - use deviceReadingsFormatter instead. This is not a hook anymore, needs to be deleted
 */
export function useDeviceReadings<
  T extends 'TEMPERATURE' | 'PRESSURE' | 'LEAK' | 'PULSE_METER' | 'UNKNOWN' | undefined
>(
  value: number | undefined | null,
  deviceType: T,
  round?: TRound
): ILeakReading | ITemperatureReading | IPressureReading | IUnknownReading | IFlowMeterReading;

/**
 * @deprecated useDeviceReadings - use deviceReadingsFormatter instead. This is not a hook anymore, needs to be deleted
 */
export function useDeviceReadings<T extends TDeviceType | undefined>(
  value: number | undefined | null,
  deviceType: T,
  round: TRound = 'decimal'
): ILeakReading | ITemperatureReading | IPressureReading | IUnknownReading | IFlowMeterReading {
  return deviceReadingsFormatter<T>(value, deviceType, round);
}

/**
 * @deprecated deviceReadingsFormatter - use parseDisplayedValue instead
 */
export function deviceReadingsFormatter<T extends 'PRESSURE'>(
  value: number | undefined | null,
  deviceType: T,
  round?: TRound
): IPressureReading;
export function deviceReadingsFormatter<T extends 'LEAK'>(
  value: number | undefined | null,
  deviceType: T,
  round?: TRound
): ILeakReading;
export function deviceReadingsFormatter<T extends 'UNKNOWN'>(
  value: number | undefined | null,
  deviceType: T,
  round?: TRound
): IUnknownReading;
export function deviceReadingsFormatter<T extends 'TEMPERATURE' | 'PRESSURE' | 'PULSE_METER'>(
  value: number | undefined | null,
  deviceType: T,
  round?: TRound
): ITemperatureReading | IPressureReading | IFlowMeterReading;
export function deviceReadingsFormatter<
  T extends 'TEMPERATURE' | 'PRESSURE' | 'LEAK' | 'PULSE_METER' | 'EQUIPMENT' | 'UNKNOWN' | undefined
>(
  value: number | undefined | null,
  deviceType: T,
  round?: TRound
): ILeakReading | ITemperatureReading | IPressureReading | IFlowMeterReading | IUnknownReading;

export function deviceReadingsFormatter<T extends TDeviceType | undefined>(
  value: number | undefined | null,
  deviceType: T,
  round: TRound = 'decimal'
): ILeakReading | ITemperatureReading | IPressureReading | IFlowMeterReading | IUnknownReading {
  const roundedValue = parseDeviceReadings(value, deviceType, round);

  if (roundedValue.type === 'LEAK') {
    return roundedValue;
  }

  return formatDeviceReadings(roundedValue.value, roundedValue.type, round);
}
