import { content } from './content';
import { useStyles } from './coordinates.styles';

interface ICoordinatesProps {
  latitude?: number | null;
  longitude?: number | null;
}

export const Coordinates = ({ latitude, longitude }: ICoordinatesProps) => {
  const { classes } = useStyles();

  return (
    <>
      <span className={classes.coordinates} data-testid="organization-settings-readonly-latitude">
        {content.LATITUDE(latitude)}
      </span>
      <span className={classes.coordinates} data-testid="organization-settings-readonly-longitude">
        {content.LONGITUDE(longitude)}
      </span>
    </>
  );
};
