import { useDeregisterEquipmentMutation } from '@marlin/asset/data-access/equipment';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import { Button } from '@mui/material';
import { useCallback, useContext, useMemo } from 'react';

import { content } from '../content';
import { useStyles } from '../delete-modal.styles';

export interface IDeregisterEquipmentModalFooterProps {
  assetId?: string;
  goToEquipmentList: () => void;
}

export const DeregisterEquipmentModalFooter = ({
  assetId,
  goToEquipmentList,
}: IDeregisterEquipmentModalFooterProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { classes } = useStyles();
  const { modalDispatch } = useContext(ModalContext);
  const onSuccess = () => {
    enqueueSnackbar(content.DEREGISTER_SUCCESS, { variant: 'success', preventDuplicate: true });
  };
  const onError = () => {
    enqueueSnackbar(content.DEREGISTER_ERROR, {
      variant: 'error',
      preventDuplicate: true,
    });
  };
  const deregisterEquipmentMutation = useDeregisterEquipmentMutation({
    onSuccess,
    onError,
  });

  const handleCloseModal = useCallback(() => {
    modalDispatch({
      type: MODAL_ACTION_TYPE.DISMISS,
    });
  }, [modalDispatch]);

  const deregisterAssetMethod = useMemo(() => {
    return async () => {
      try {
        await deregisterEquipmentMutation.mutateAsync({ equipmentId: assetId || '' });
        handleCloseModal();

        goToEquipmentList();
      } catch (error) {
        handleCloseModal();
      }
    };
  }, [assetId, deregisterEquipmentMutation, handleCloseModal, goToEquipmentList]);

  return (
    <div data-testid="deregister-modal-footer">
      <Button className={classes.cancelButton} variant="text" onClick={handleCloseModal}>
        {content.CANCEL}
      </Button>
      <Button
        className={`${classes.button} ${classes.cancelButton}`}
        variant="contained"
        color="error"
        onClick={deregisterAssetMethod}
      >
        {content.DEREGISTER}
      </Button>
    </div>
  );
};
