import { MarlinTheme } from '@marlin/shared/theme';
import { Divider, Icon, useMediaQuery } from '@mui/material';
import { HTMLAttributes } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  addIcon: {
    color: theme.palette.primary.main,
  },
  addAddressLabel: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(14),
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightMedium,
    marginLeft: theme.typography.pxToRem(8),
  },
  divider: {
    marginTop: theme.typography.pxToRem(8),
    width: '100%',
  },
  wrapper: {
    width: '100%',
  },
  addAddress: {
    display: 'flex',
    alignItems: 'center',
  },
}));

interface IAutocompleteOptionProps {
  props: HTMLAttributes<HTMLLIElement>;
  onClick: () => void;
}

export const AddAddressAutocompleteOption = ({ props, onClick }: IAutocompleteOptionProps) => {
  const { classes, theme } = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <li {...props} onClick={onClick}>
      <div className={classes.wrapper}>
        <div className={classes.addAddress}>
          <Icon baseClassName="material-symbols-outlined" className={classes.addIcon} data-testid="add_circle-circle">
            add_circle
          </Icon>
          <p className={classes.addAddressLabel}>{content.ADDRESS_FORM.ADD_ADDRESS}</p>
        </div>
        {isMobile && <Divider className={classes.divider} />}
      </div>
    </li>
  );
};
