import { Button } from '@mui/material';

import { content } from '../../content';

interface IViewerFallbackButtonProps {
  totalItems?: number;
  onClick: () => void;
}

export const ViewerFallbackButton = ({ totalItems, onClick }: IViewerFallbackButtonProps) => {
  if (!totalItems || totalItems <= 0) {
    return null;
  }

  return (
    <Button fullWidth variant="outlined" onClick={onClick}>
      {content.MANAGE_AUTOMATIONS_BUTTON_VIEW}
    </Button>
  );
};
