import { TAutomation } from '@marlin/alert/data-access/automated-action';
import { TAssetLink, useAssetLinks } from '@marlin/asset/data-access/asset';
import { AttachmentsTable, AutomationsTable } from '@marlin/asset/shared/ui/attachment-validation';
import { MarlinTheme } from '@marlin/shared/theme';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import isNil from 'lodash/isNil';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';
import { useManageAutomations } from './use-manage-automations.hook';

interface IModalBodyProps {
  deviceName: string;
  deviceId: string;
}

interface IModalContent {
  info: string;
  question: string;
}

const getModalContent = (deviceName: string, automations?: TAutomation[], assetLinks?: TAssetLink): IModalContent => {
  if (automations && !!automations.length && assetLinks?.links && !!assetLinks.links.length) {
    return {
      info: content.MODAL_DELETE_FLOW_LINKS_AUTOMATIONS,
      question: content.MODAL_DELETE_FLOW_LINKS_AUTOMATIONS_QUESTION,
    };
  }

  if (automations && !!automations.length) {
    return {
      info: content.MODAL_DELETE_AUTOMATIONS,
      question: content.MODAL_DELETE_EXISTING_AUTOMATION_QUESTION,
    };
  }

  if (assetLinks?.links && !!assetLinks.links.length) {
    return {
      info: content.MODAL_DELETE_FLOW_LINKS,
      question: content.MODAL_DELETE_FLOW_LINKS_QUESTION,
    };
  }

  return {
    info: content.MODAL_DELETE_NO_AUTOMATION_QUESTION(deviceName),
    question: content.MODAL_DELETE_NO_AUTOMATION_CONFIRMATION,
  };
};

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  confirmText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  linksTable: {
    marginTop: theme.typography.pxToRem(32),
    marginBottom: theme.typography.pxToRem(32),
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.typography.pxToRem(8),
  },
}));

export const ModalBody = ({ deviceName, deviceId }: IModalBodyProps): JSX.Element | null => {
  const { classes } = useStyles();
  const { isLoading, automations } = useManageAutomations(deviceId || '');
  const { data: assetLinks } = useAssetLinks({ assetId: deviceId ?? '', enabled: !!deviceId });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isNil(deviceName)) {
    return null;
  }

  const { info, question } = getModalContent(deviceName, automations, assetLinks);

  if (!assetLinks?.links?.length && !automations?.length) {
    return (
      <>
        <div>{info}</div>
        <div className={classes.confirmText}>{question}</div>
      </>
    );
  }

  return (
    <>
      <div>{info}</div>
      <div className={classes.linksTable}>
        {!!assetLinks?.links?.length && (
          <AttachmentsTable assetId={deviceId} title={content.FLOW_LINK_TABLE} data={assetLinks.links} />
        )}
        {!!automations?.length && <AutomationsTable title={content.AUTOMATION_TABLE} data={automations} />}
      </div>
      <div className={classes.confirmText}>{question}</div>
    </>
  );
};
