import { rolePermission } from '@marlin/account/shared/permission-organization';
import { TPermission } from '@marlin/shared/utils-permission';

import { useCurrentUser } from './get-my-user.query';

export const useMarlinPermissions = () => {
  const { isLoading, data } = useCurrentUser();

  const currentOrganization = data?.currentOrganization; // TODO - add service for handling multiple orgs

  const permissionsByRole = currentOrganization ? currentOrganization.roles.map((role) => rolePermission[role]) : [];
  const permissions: TPermission[] = [...new Set(permissionsByRole.length ? permissionsByRole.flat(1) : [])];

  return {
    isLoading,
    data: {
      permissions,
    },
  };
};
