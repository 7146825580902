import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

interface IStylesProps {
  buttonPosition?: 'left' | 'center' | 'right';
}

const buttonPositionsToFlexJustify = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

export const useStyles = makeStyles<IStylesProps>()((theme: MarlinTheme, { buttonPosition = 'center' }) => ({
  arrayFieldControl: {
    position: 'relative',
    minWidth: '100%',
    marginBottom: theme.typography.pxToRem(32),
    display: 'flex',
    alignItems: 'center',
    gap: theme.typography.pxToRem(16),
  },

  removeButtonAbsolute: {
    position: 'absolute',
    top: theme.typography.pxToRem(8),
    right: theme.typography.pxToRem(-48),
  },

  buttonWrapper: {
    display: 'flex',
    justifyContent: buttonPositionsToFlexJustify[buttonPosition],
  },
}));
