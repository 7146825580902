import { TDayName, TParsedDailySchedule } from '@marlin/asset/data-access/equipment';
import { MarlinTheme } from '@marlin/shared/theme';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { PERMISSIONS, Restricted } from '@marlin/shared/utils-permission';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../content';
import { useSettingListItem } from '../../../hooks/settings/use-setting-list-item.hook';
import { OccupiedTime } from './occupied-section.component';
import { EditMode } from './row-edit.component';

interface IStyles {
  isEditing: boolean;
}

const useStyles = makeStyles<IStyles>()((theme: MarlinTheme, { isEditing }) => {
  const borderStyle = `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`;

  return {
    section: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
      marginTop: theme.typography.pxToRem(8),
      width: '100%',
    },
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      justifyItems: 'center',
      borderBottom: borderStyle,
      borderRight: isEditing ? borderStyle : 'none',
      borderLeft: isEditing ? borderStyle : 'none',
      borderTop: 'none',
      padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(16)}`,
      [theme.breakpoints.down('md')]: {
        display: 'grid',
        gridTemplateColumns: '5fr',
        gridTemplateRows: `1fr fit-content(${theme.typography.pxToRem(22)})`,
        gridTemplateAreas: `"title button" "value button"`,
        height: '100%',
        borderRight: 'none',
        borderLeft: 'none',
        margin: 0,
      },
    },
    lastItem: {
      borderBottom: !isEditing ? 'none' : borderStyle,
      marginBottom: theme.typography.pxToRem(16),
    },
    firstItem: {
      borderTop: isEditing ? borderStyle : 'none',
    },
    label: {
      fontSize: theme.typography.pxToRem(16),
      textTransform: 'capitalize',
    },
  };
});

interface IScheduleRow {
  isLast: boolean;
  isFirst: boolean;
  day: TParsedDailySchedule;
  name: TDayName;
  onSubmit: (data: TParsedDailySchedule, dayName: TDayName) => Promise<void>;
  isSanitize: boolean;
  isEditable: boolean;
}

export const ScheduleRow = ({ isLast, isFirst, day, name, onSubmit, isEditable, isSanitize }: IScheduleRow) => {
  const { onEditClick, isEditing, onCancelClick } = useSettingListItem({ name });
  const { classes, cx } = useStyles({ isEditing });
  const classNames = cx([classes.container, isLast ? classes.lastItem : '', isFirst ? classes.firstItem : '']);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xl'));

  if (isSanitize && isEditable) {
    return (
      <Tooltip text={content.SETTINGS_DISABLED_TOOLTIP} placement={isMobile ? 'top' : 'right'}>
        <div className={classNames} data-testid={`schedule-${name}`} key={name}>
          <OccupiedTimeInternal name={name} day={day} />
        </div>
      </Tooltip>
    );
  }

  if (!isEditable) {
    return (
      <div className={classNames} data-testid={`schedule-${name}`} key={name}>
        <OccupiedTimeInternal name={name} day={day} />
      </div>
    );
  }

  return (
    <div className={classNames} data-testid={`schedule-${name}`} key={name}>
      {isEditing ? (
        <EditMode name={name} defaultValues={day} onSubmit={onSubmit} onCancelClick={onCancelClick} />
      ) : (
        <>
          <OccupiedTimeInternal name={name} day={day} />
          <Restricted to={[PERMISSIONS.UPDATE_EQUIPMENT_SCHEDULE]}>
            <IconButton onClick={onEditClick} data-testid={`schedule-item-button-${day}`}>
              <ChevronRightIcon />
            </IconButton>
          </Restricted>
        </>
      )}
    </div>
  );
};

const OccupiedTimeInternal = ({ name, day }: { name: TDayName; day: TParsedDailySchedule }) => {
  const { classes } = useStyles({ isEditing: false });

  return (
    <div>
      <Typography className={classes.label} variant="body1" data-testid="setting-list-item-title">
        {name}
      </Typography>
      <div className={classes.section} data-testid={`schedule-section-${day}`}>
        <OccupiedTime label={content.OCCUPIED_ONE} from={day.occupied1.from} to={day.occupied1.to} />
        <OccupiedTime label={content.OCCUPIED_TWO} from={day.occupied2.from} to={day.occupied2.to} />
      </div>
    </div>
  );
};
