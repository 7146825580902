import { getHttpClient } from '@marlin/shared/utils/react-query';

import { ICreateProfile, IUser } from '../user.model';
import { paths } from './api';

export const profileCreate = (data: ICreateProfile): Promise<IUser> => {
  const title = data.title || '';
  return getHttpClient().post(paths.USER, { ...data, title });
};

export const supportProfileCreate = (data: ICreateProfile): Promise<IUser> => {
  return getHttpClient().post(paths.SUPPORT_USER, { ...data });
};
