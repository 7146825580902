import { Link } from '@marlin/shared/ui-page';

interface IHierarchy {
  id: string;
  name: string;
}

interface IHierarchyLinkProps {
  parent: IHierarchy | undefined;
  children: IHierarchy | undefined;
  goTo: (value: string) => string;
}

export const HierarchyItem = ({ parent, children, goTo }: IHierarchyLinkProps): JSX.Element | null => {
  if (!children) {
    return null;
  }

  if (parent && parent.name) {
    return (
      <div>
        <Link to={goTo(parent.id)}>{parent.name}</Link>
        {' > '}
        <Link to={goTo(children.id)}>{children.name}</Link>
      </div>
    );
  }

  return <Link to={goTo(children.id)}>{children.name}</Link>;
};
