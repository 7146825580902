import { MarlinTheme } from '@marlin/shared/theme';
import { alpha } from '@mui/system';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.typography.pxToRem(16),
    borderTopLeftRadius: theme.typography.pxToRem(4),
    borderTopRightRadius: theme.typography.pxToRem(4),
  },
  headerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '90%',
  },
  mobileWrapper: {
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
    borderRadius: theme.typography.pxToRem(4),
  },
  desktopWrapper: {
    minHeight: theme.typography.pxToRem(112),
  },
  compact: {
    minHeight: 'auto',
  },
  header: {
    backgroundColor: theme.palette.background.alternative,
    borderBottom: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
    borderLeft: `${theme.typography.pxToRem(8)} solid ${theme.palette.divider}`,
  },
  headerWithHighAlert: {
    backgroundColor: alpha(theme.palette.error.main, 0.12),
    borderBottom: `${theme.typography.pxToRem(1)} solid ${theme.palette.error.main}`,
    borderLeft: `${theme.typography.pxToRem(8)} solid ${theme.palette.error.main}`,
  },
  headerWithLowAlert: {
    backgroundColor: alpha(theme.palette.warning.main, 0.12),
    borderBottom: `${theme.typography.pxToRem(1)} solid ${theme.palette.warning.main}`,
    borderLeft: `${theme.typography.pxToRem(8)} solid ${theme.palette.warning.main}`,
  },
  headerWithSuccess: {
    backgroundColor: alpha(theme.palette.success.light, 0.12),
    borderBottom: `${theme.typography.pxToRem(1)} solid ${theme.palette.success.light}`,
    borderLeft: `${theme.typography.pxToRem(8)} solid ${theme.palette.success.light}`,
  },
  subtitle: {
    marginBottom: theme.typography.pxToRem(8),
    cursor: 'pointer',
  },
  titleWrapper: {
    display: 'flex',
    maxWidth: '100%',
  },
  title: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(15),
    },
  },
  impactAmountAlert: {
    fontWeight: theme.typography.fontWeightBold,
  },
  icon: {
    '& > svg': {
      color: theme.palette.text.primary,
    },
  },
  iconSuccess: {
    '& > svg': {
      color: theme.palette.success.light,
    },
  },
  iconWarning: {
    '& > svg': {
      color: theme.palette.warning.main,
    },
  },
  iconError: {
    '& > svg': {
      color: theme.palette.error.main,
    },
  },
}));
