import { MarlinTheme } from '@marlin/shared/theme';
import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import { Button } from '@mui/material';
import { useCallback, useContext } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  cancelButton: {
    marginRight: theme.typography.pxToRem(8),
  },
}));

export const useRemoveConnectionModal = ({ onRemove }: { onRemove: (index: number) => void }) => {
  const { classes } = useStyles();
  const { modalDispatch } = useContext(ModalContext);

  const handleCloseModal = useCallback(() => {
    modalDispatch({
      type: MODAL_ACTION_TYPE.DISMISS,
    });
  }, [modalDispatch]);

  const handleRemove = useCallback(
    (index: number) => {
      onRemove(index);
      modalDispatch({
        type: MODAL_ACTION_TYPE.DISMISS,
      });
    },
    [modalDispatch, onRemove]
  );

  const modalRemoveConnection = (index: number) => ({
    title: <div>{content.REMOVE_CONNECTION_MODAL.TITLE}</div>,
    body: (
      <div>
        <div>{content.REMOVE_CONNECTION_MODAL.BODY_TEXT_1ST_PART}</div>
        <br />
        <div>
          <b>{content.REMOVE_CONNECTION_MODAL.BODY_TEXT_2ND_PART}</b>
        </div>
      </div>
    ),
    footer: (
      <div data-testid="remove-connection-modal-footer">
        <Button
          className={classes.cancelButton}
          variant="text"
          data-testid="remove-connection-modal-footer-cancel"
          onClick={handleCloseModal}
        >
          {content.REMOVE_CONNECTION_MODAL.CANCEL}
        </Button>
        <Button
          variant="contained"
          color="error"
          data-testid="remove-connection-modal-footer-confirm"
          onClick={() => handleRemove(index)}
        >
          {content.REMOVE_CONNECTION_MODAL.DELETE}
        </Button>
      </div>
    ),
  });

  const removeConnection = (index: number) => {
    modalDispatch({
      type: MODAL_ACTION_TYPE.SHOW,
      payload: modalRemoveConnection(index),
    });
  };

  return { removeConnection, closeModal: handleCloseModal };
};
