import { TCreateAutomation } from '@marlin/alert/data-access/automated-action';
import { MarlinTheme } from '@marlin/shared/theme';
import { FormField, INPUT_TYPE, Input } from '@marlin/shared/ui-form-common';
import { roundValue } from '@marlin/shared/utils-format-reading';
import isNil from 'lodash/isNil';
import { ChangeEvent, useMemo } from 'react';
import { useController } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { TDeadbandFieldName } from '../paths.model';
import { parseValue } from '../utils';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  input: {
    width: theme.typography.pxToRem(200),
  },

  endAdornment: {
    marginRight: theme.typography.pxToRem(16),
  },
}));

interface IDeadbandControlProps {
  disabled: boolean;
  name: `${TDeadbandFieldName}.value`;
  label: string;
  unit: string;
}

const conditionValueMask = /^\d{0,3}$/;

export const DeadbandControl = ({ name, label, unit, disabled }: IDeadbandControlProps) => {
  const { classes } = useStyles();
  const { field, fieldState } = useController({ name });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const parsedValue = parseValue(event.target.value);
    const value = typeof parsedValue === 'number' ? parsedValue : null;
    field.onChange({ ...event, target: { value } });
  };

  const endAdornment = useMemo(
    () => <span className={classes.endAdornment}>{unit}</span>,
    [classes.endAdornment, unit]
  );

  return (
    <FormField<TCreateAutomation> fieldName={name}>
      {(props) => (
        <Input
          {...props}
          className={classes.input}
          disabled={disabled}
          label={label}
          type={INPUT_TYPE.NUMBER}
          fullWidth={false}
          mask={conditionValueMask}
          onChange={handleChange}
          value={isNil(props.value) ? '' : roundValue(props.value.toString(), 'decimal')}
          externalEndAdornment={{
            endAdornment,
          }}
          error={fieldState.isDirty ? fieldState?.error : undefined}
        />
      )}
    </FormField>
  );
};
