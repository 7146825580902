import * as yup from 'yup';
import * as z from 'zod';

// todo adjust translation keys
const content = { EMAIL_CONTROL_VALIDATION: 'Please enter a valid email address' };

// todo remove yup
export const emailSchema = yup
  .string()
  .email(content.EMAIL_CONTROL_VALIDATION)
  .required(content.EMAIL_CONTROL_VALIDATION);

export const emailZodSchema = z.string().email(content.EMAIL_CONTROL_VALIDATION);
