import { TChartId } from '@marlin/asset/shared/equipment-config';
import { TChartDatapointsGroup } from '@marlin/shared/utils-chart';
import { createContext, useContext } from '@marlin/shared/utils-component-context';
import { TDeviceMetadataResponse } from '@marlin/shared/utils/datapoint-mappers';

import { useActiveChartingDatapoints } from '../hooks/use-active-charting-datapoints.hook';

interface ISelectedDatapointsContext {
  datapointGroups: Record<TChartId, TChartDatapointsGroup>;
  activeDatapoints: Record<TChartId, string[]>;
  activeThresholds: Record<TChartId, string[]>;
  toggleDatapointVisibility: (chartId: TChartId, name: string) => void;
  toggleThresholdVisibility: (chartId: TChartId, name: string) => void;
  setDefault: () => void;
  clearAll: () => void;
  clearGroup: (chartId: TChartId) => void;
}

interface ISelectedDatapointsProviderProps {
  children: React.ReactNode;
  chartDatapointsMetadata: TDeviceMetadataResponse['chartDatapoints'];
}

export const SelectedDatapointsContext = createContext<ISelectedDatapointsContext>();

export const SelectedDatapointsProvider = ({ children, chartDatapointsMetadata }: ISelectedDatapointsProviderProps) => {
  const {
    datapointGroups,
    activeDatapoints,
    activeThresholds,
    clearGroup,
    clearAll,
    toggleDatapointVisibility,
    toggleThresholdVisibility,
    setDefault,
  } = useActiveChartingDatapoints(chartDatapointsMetadata);

  return (
    <SelectedDatapointsContext.Provider
      value={{
        datapointGroups,
        activeDatapoints,
        activeThresholds,
        clearGroup,
        clearAll,
        setDefault,
        toggleDatapointVisibility,
        toggleThresholdVisibility,
      }}
    >
      {children}
    </SelectedDatapointsContext.Provider>
  );
};

export const useSelectedDatapointsContext = () => useContext(SelectedDatapointsContext);
