import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    height: theme.typography.pxToRem(510),
    width: theme.typography.pxToRem(640),
  },
  dateWrapper: {
    display: 'flex',
    paddingBottom: theme.typography.pxToRem(16),
    width: '100%',
    justifyContent: 'space-between',
  },
  calendar: {
    backgroundColor: theme.palette.background.secondary,
    border: `1px solid ${theme.palette.divider}`,
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > button:first-of-type': {
      marginRight: theme.typography.pxToRem(16),
    },
  },
  title: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
    paddingTop: theme.typography.pxToRem(16),
    paddingBottom: theme.typography.pxToRem(8),
  },
  input: {
    width: theme.typography.pxToRem(300),
    '& > div': {
      height: theme.typography.pxToRem(40),
    },
    '& > label': {
      top: theme.typography.pxToRem(-6),
    },
    '& > .MuiInputLabel-shrink': {
      top: theme.typography.pxToRem(0),
    },
  },
}));
