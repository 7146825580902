import { FormField, IFormControlProps, IFormTypedProps, Input } from '@marlin/shared/ui-form-common';
import { ControllerProps, ControllerRenderProps, FieldValues } from 'react-hook-form';

import { content } from '../content';
import { inputMask, postalCodeLabels, zipCodeMask } from './constants';

type TRenderProps = Partial<Omit<ControllerRenderProps, 'ref'>>;

interface IStateSelectProps {
  country?: string;
  className?: string;
  required?: boolean;
}

interface IStateSelectControlProps extends TRenderProps, IStateSelectProps {
  disabled?: boolean;
}

export const PostalCodeInput = ({
  country,
  required,
  className,
  disabled = false,
  ...props
}: IStateSelectControlProps) => {
  const postalCodeMask = country ? inputMask[country] || zipCodeMask : zipCodeMask;
  const postalCodeLabel = country ? postalCodeLabels[country] || content.ZIP_CODE : content.ZIP_CODE;

  return (
    <Input
      className={className}
      mask={postalCodeMask}
      label={postalCodeLabel}
      required={required}
      {...props}
      disabled={disabled}
      prepare={(str) => str.toUpperCase()}
    />
  );
};

export function PostalCodeControl<TFieldValues extends FieldValues>(
  props: IFormControlProps<TFieldValues> & IStateSelectProps
): JSX.Element;
export function PostalCodeControl<
  TFieldValues extends FieldValues = object,
  TName extends ControllerProps<TFieldValues>['name'] = ControllerProps<TFieldValues>['name']
>(props: IFormTypedProps<TFieldValues, TName> & IStateSelectProps): JSX.Element;

export function PostalCodeControl<
  TFieldValues extends FieldValues,
  TName extends ControllerProps<TFieldValues>['name'] = ControllerProps<TFieldValues>['name']
>(params: (IFormControlProps<TFieldValues> | IFormTypedProps<TFieldValues, TName>) & IStateSelectProps) {
  if (params.control) {
    return (
      <FormField {...params}>
        {(props) => (
          <PostalCodeInput
            {...props}
            country={params.country}
            className={params.className}
            required={params.required}
            disabled={params.disabled}
          />
        )}
      </FormField>
    );
  }

  return (
    <FormField<TFieldValues> {...params}>
      {(props) => (
        <PostalCodeInput
          {...props}
          country={params.country}
          className={params.className}
          required={params.required}
          disabled={params.disabled}
        />
      )}
    </FormField>
  );
}
