import { MarlinTheme } from '@marlin/shared/theme';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: theme.typography.pxToRem(16),
  },
  text: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.text.secondary,
    textAlign: 'center',
  },
}));

export const CopyrightFooter = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.container}>
      <Typography className={classes.text}>{content.FOOTER_TEXT}</Typography>
    </div>
  );
};
