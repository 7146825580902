import type { XYPosition } from '@xyflow/react';

import { isControlPoint } from './points.utils';
import type { TControlPointData } from './types';

export function getLinearPath(points: XYPosition[]) {
  if (points.length < 1) return '';

  let path = `M ${points[0].x} ${points[0].y}`;

  for (let i = 1; i < points.length; i++) {
    path += ` L ${points[i].x} ${points[i].y}`;
  }

  return path;
}

export function getLinearControlPoints(points: (TControlPointData | XYPosition)[]) {
  const controlPoints = [] as TControlPointData[];

  for (let i = 1; i < points.length - 1; i++) {
    const p1 = points[i];

    if (isControlPoint(p1)) {
      controlPoints.push(p1);
    }
  }

  return controlPoints;
}
