import { MarlinTheme } from '@marlin/shared/theme';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../../content';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.typography.pxToRem(32),
    marginBottom: theme.typography.pxToRem(32),
  },
}));

export const EquipmentLimit = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant="caption" color="text.disabled" align="center">
        {content.EQUIPMENT_LIMIT_1} <br />
        {content.EQUIPMENT_LIMIT_2}
      </Typography>
    </div>
  );
};
