import { TOrgAddress } from '@marlin/shared/utils/zod';

export const countryList = [
  { id: 'United States', name: 'United States' },
  { id: 'Canada', name: 'Canada' },
  { id: 'Mexico', name: 'Mexico' },
];

export const emptyAddress: TOrgAddress = {
  address1: '',
  address2: '',
  city: '',
  state: '',
  postalCode: '',
  country: '',
};
