import { routes } from '@marlin/shared/utils-routes';

export interface IFeatureNavigation {
  getEditEquipmentPageLink: (id: string) => string;
  getLocationsLink: () => string;
  getDeviceDrawerOnPageLink: (id: string) => string;
  getEquipmentLink: () => string;
  getEquipmentDetailsLink: (id: string) => string;
}

export const useNavigation = (): IFeatureNavigation => {
  return {
    getEditEquipmentPageLink: (id: string) => routes.equipments.update.url(id),
    getDeviceDrawerOnPageLink: (id) => routes.sensors.details.url(id),
    getEquipmentDetailsLink: (id) => routes.equipments.details.url(id),
    getEquipmentLink: () => routes.equipments.root,
    getLocationsLink: () => routes.locations.root,
  };
};
