import { getHttpClient } from '@marlin/shared/utils/react-query';

import { User } from '../schemas/user';
import { paths } from './api';

export const getProfile = async () => {
  const res = await getHttpClient().get(paths.USER_PROFILE);

  return User.parse(res);
};
