import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import { GraphSchema, TGraph, TGraphResponse } from '../flow-map.model.schema';
import { TUpdateSystemMapNodeParams, updateSystemMapNodeRequestSchema } from '../system-map.model.schema';
import { paths } from './api';

export interface IUpdateSystemMapNode {
  nodeId: string;
  data: TUpdateSystemMapNodeParams;
}

export const updateSystemMapNode = async (params: IUpdateSystemMapNode): Promise<TGraph> => {
  const body = safeParseData(params.data, updateSystemMapNodeRequestSchema);

  const res = await getHttpClient().patch<unknown, TGraphResponse>(paths.SYSTEM_MAP_NODE_DETAILS, body, {
    params: { nodeId: params.nodeId },
  });
  const parsedRes = safeParseData(res, GraphSchema);

  return parsedRes;
};
