import { z } from 'zod';

import { paginationSchema } from './pagination.schema';

export const assetLocationParamsSchema = z.object({
  search: z.string().optional(),
  parentLocation: z.string().optional(),
  page: z.number(),
  pageSize: z.number(),
  showUsedByLocations: z.boolean().optional(),
});

export const assetEquipmentParamsSchema = z.object({
  search: z.string().optional(),
  location: z.string().optional(),
  page: z.number(),
  pageSize: z.number(),
});

const assetSchema = z.object({
  id: z.string(),
  name: z.string(),
});

export const assetResponseSchema = z.object({
  pagination: paginationSchema,
  data: z.array(assetSchema).nullable(),
});

export type TAssetLocationParamsSchema = z.infer<typeof assetLocationParamsSchema>;
export type TAssetEquipmentParamsSchema = z.infer<typeof assetEquipmentParamsSchema>;

export type TAssetResponseSchema = z.infer<typeof assetResponseSchema>;
export type TAsset = z.infer<typeof assetSchema>;
