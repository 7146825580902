import { MarlinTheme } from '@marlin/shared/theme';
import { ErrorMessage } from '@marlin/shared/ui-form-common';
import { GridPagination } from '@mui/x-data-grid';
import { FieldError } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';

const useStyle = makeStyles()((theme: MarlinTheme) => ({
  organizationListFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: `1px solid ${theme.palette.divider}`,
    //TODO: It's temporary breakpoint. Long term solution assume mobile edit mode
    [theme.breakpoints.down(737)]: {
      flexDirection: 'column',
      paddingTop: theme.typography.pxToRem(16),
      alignItems: 'center',
    },
  },
  organizationListFooterCount: {
    paddingLeft: theme.typography.pxToRem(24),
    //TODO: It's temporary breakpoint. Long term solution assume mobile edit mode
    [theme.breakpoints.down(737)]: {
      paddingLeft: theme.typography.pxToRem(0),
    },
  },
}));

export interface IOrganizationListFooterProps {
  count: number;
  fieldError?: FieldError;
}

export const OrganizationListFooter = ({ count, fieldError }: IOrganizationListFooterProps) => {
  const { classes } = useStyle();

  return (
    <div className={classes.organizationListFooter}>
      <div className={classes.organizationListFooterCount}>
        {fieldError?.message ? (
          <ErrorMessage>{fieldError?.message}</ErrorMessage>
        ) : (
          content.SELECTED_ORG_COUNT_LABEL(count)
        )}
      </div>
      <GridPagination />
    </div>
  );
};
