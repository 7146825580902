import { routes } from '@marlin/shared/utils-routes';

export interface IFeatureNavigation {
  getCreatePageLink: () => string;
  getBuilderPageLink: () => string;
  getUpdatePageLink: (automationId: string) => string;
  getListPageLink: () => string;
}

export const useNavigation = (): IFeatureNavigation => {
  return {
    getCreatePageLink: () => routes.automations.create.url(),
    getBuilderPageLink: () => routes.automations.builder.url(),
    getUpdatePageLink: (automationId: string) => routes.automations.update.url(automationId),
    getListPageLink: () => routes.automations.list.url(),
  };
};
