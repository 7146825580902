import { MarlinTheme } from '@marlin/shared/theme';
import { createDateString, dateAdapter } from '@marlin/shared/utils-common-date';
import { DateValidationError } from '@mui/x-date-pickers';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { Controller } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';
import { getDateValidationErrorMessage } from './utils';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  input: {
    marginBottom: theme.typography.pxToRem(32),
    flexBasis: '100%',
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.typography.pxToRem(16),
    },
  },
}));

export interface ICommissionDateFieldProps {
  dateValidationErrorMessage: string | undefined;
  setDateValidationErrorMessage: (message: string | undefined) => void;
}

export function CommissionDateField({
  setDateValidationErrorMessage,
  dateValidationErrorMessage,
}: ICommissionDateFieldProps) {
  const { classes } = useStyles();

  const handleDateError = (error: DateValidationError | null) => {
    if (error) {
      setDateValidationErrorMessage(getDateValidationErrorMessage(error));
    } else {
      setDateValidationErrorMessage(undefined);
    }
  };

  return (
    <Controller<{ commissionDate: string | null | undefined }>
      name="commissionDate"
      render={({ field }) => {
        return (
          <DatePicker
            {...field}
            className={classes.input}
            label={content.COMMISSION_DATE}
            value={field.value ? dateAdapter.date(field.value)?.toDate() : null}
            onChange={(date) => {
              field.onChange?.(dateAdapter.isValid(date) ? createDateString(date || undefined) : undefined);
            }}
            onError={handleDateError}
            disableFuture
            slotProps={
              {
                textField: {
                  'data-testid': 'commission-date-field',
                  helperText: dateValidationErrorMessage ?? '',
                },
                openPickerButton: {
                  'data-testid': 'commission-date-picker-button',
                },
              } as DatePickerProps<Date>['slotProps']
            }
          />
        );
      }}
    />
  );
}
