import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  loader: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
