import { useMemo } from 'react';

import { content } from './content';

export const DeleteLocationModalTitle = ({ canDelete }: { canDelete: boolean }) => {
  const deleteModalTitle = useMemo(() => {
    if (!canDelete) {
      return content.REMOVE_ATTACHED_ASSETS;
    }

    return content.DELETE_LOCATION;
  }, [canDelete]);

  return <>{deleteModalTitle}</>;
};
