import { Observable, Subject, Subscription, interval } from 'rxjs';
import { map, take } from 'rxjs/operators';

const secondsToForceLogout = 5;

export class LogoutTimerService {
  private forceLogout$ = new Subject<void>();
  private timeToLogout$: Subject<number> = new Subject<number>();
  private logoutInterval$: Subscription | undefined;

  public readonly getTimeToLogout = (): Observable<number> => this.timeToLogout$.asObservable();

  public readonly resetTimer = (): void => {
    this.logoutInterval$?.unsubscribe();
    this.timeToLogout$?.next(0);
  };

  public readonly startTimer = (): Observable<void> => {
    if (this.logoutInterval$) {
      this.logoutInterval$.unsubscribe();
    }
    if (this.forceLogout$.closed) {
      this.forceLogout$ = new Subject<void>();
    }

    this.logoutInterval$ = interval(1000)
      .pipe(
        take(secondsToForceLogout),
        map((time: number) => Math.abs(time - secondsToForceLogout))
      )
      .subscribe({
        next: (val: number) => {
          this.timeToLogout$?.next(val);
        },
        complete: () => {
          this.forceLogout$.next();
          this.forceLogout$.complete();
        },
      });

    return this.forceLogout$.asObservable();
  };
}
