import { Button, Dialog, DialogTitle } from '@mui/material';

import { TEASER_MODAL_ACTION_TYPE } from '../teaser-modal-provider/types';
import { useTeaserModal } from '../teaser-modal-provider/use-teaser-modal';
import { useStyles } from './booking.styles';
import { content } from './content';

export const Booking = () => {
  const { classes } = useStyles();
  const {
    modalDispatch,
    modalState: { bookingModalVisible, msBookingsUrl },
  } = useTeaserModal();

  const handleClose = () => {
    modalDispatch({
      type: TEASER_MODAL_ACTION_TYPE.DISMISS,
    });
  };

  if (!bookingModalVisible) {
    return null;
  }

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      className={classes.container}
      PaperProps={{
        'data-testid': 'booking-modal-paper',
      }}
    >
      <DialogTitle className={classes.title}>
        {content.SCHEDULE_DEMO}
        <Button onClick={handleClose}>{content.BUTTONS.CLOSE}</Button>
      </DialogTitle>
      <iframe
        title={'msbooking'}
        src={msBookingsUrl}
        width="100%"
        height="100%"
        scrolling="yes"
        style={{ border: 0 }}
      ></iframe>
    </Dialog>
  );
};
