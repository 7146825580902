import { getFormattedValue } from '@marlin/shared/utils-format-reading';
import { TDatapointsResponse } from '@marlin/shared/utils/datapoint-mappers';
import { TLastReadingValue } from '@marlin/shared/utils/zod';

export const getDatapoint = (name: string, lastReadingValues: TLastReadingValue[], data?: TDatapointsResponse) => {
  const datapoints = data?.datapoints?.length ? data.datapoints : lastReadingValues;
  const datapoint = datapoints.find((datapoint) => datapoint.name.toLowerCase() === name.toLowerCase());

  return datapoint ? getFormattedValue(datapoint.value, datapoint.unitOfMeasure) : '';
};
