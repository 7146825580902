import { useMemo } from 'react';

import { content } from '../content';

export interface IDeleteEquipmentModalTitleProps {
  deviceCount?: number | null;
}

export const DeleteEquipmentModalTitle = ({ deviceCount }: IDeleteEquipmentModalTitleProps) => {
  const canDelete = useMemo(() => {
    return deviceCount === 0;
  }, [deviceCount]);

  const deleteModalTitle = useMemo(() => {
    if (!canDelete) {
      return content.REMOVED_ATTACHED;
    }

    return content.DELETE_EQUIPMENT;
  }, [canDelete]);

  return <div data-testid="delete-modal-title">{deleteModalTitle}</div>;
};
