import { useMultiChartsStore } from '@marlin/asset/ui/charts';
import { MarlinTheme } from '@marlin/shared/theme';
import { Select } from '@marlin/shared/ui-form-common';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';
import { useSensorFiltering } from '../../hooks/use-sensor-filtering.hook';
import { TSelectedDatapoint } from '../../types';
import { getSelectedDatapoints } from '../../utils/get-selected-datapoints';
import { SelectSensorsAutocomplete } from '../chart-form/select-sensors-autocomplete.component';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    width: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.typography.pxToRem(32),
  },
  selectHalfWidth: {
    width: '50%',
  },
  filters: {
    display: 'flex',
    gap: theme.typography.pxToRem(24),
    margin: `${theme.typography.pxToRem(32)} 0 ${theme.typography.pxToRem(16)}`,
  },
}));

export const SensorsWrapper = () => {
  const { classes } = useStyles();

  const [addChartParams, setStore] = useMultiChartsStore((store) => store.addChart);

  const {
    selectedEquipmentId,
    equipmentSelectData,
    locationsSelectData,
    selectedLocationId,
    onEquipmentChange,
    onLocationChange,
    selectedChartType,
  } = useSensorFiltering();

  const handleOnSelect = (selected: TSelectedDatapoint[]) => {
    const newSelectedSensors = getSelectedDatapoints(selected, addChartParams.selectedDatapoints);
    setStore({
      addChart: {
        ...addChartParams,
        selectedDatapoints: newSelectedSensors,
      },
    });
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.filters}>
        <Select
          data={locationsSelectData}
          label={content.LOCATION}
          prefix="locationId"
          emptyOption=" "
          value={selectedLocationId}
          onChange={onLocationChange}
          className={classes.selectHalfWidth}
        />
        <Select
          className={classes.selectHalfWidth}
          data={equipmentSelectData}
          label={content.EQUIPMENT}
          prefix="equipmentId"
          onChange={onEquipmentChange}
          emptyOption=" "
          value={selectedEquipmentId}
        />
      </div>
      {selectedChartType && (
        <SelectSensorsAutocomplete
          handleOnSelect={handleOnSelect}
          allChartSelectedSensors={addChartParams.selectedDatapoints}
        />
      )}
    </div>
  );
};
