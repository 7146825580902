import { ALERT_STATUS_FILTER } from '@marlin/alert/data-access/alert-action';
import { MarlinTheme } from '@marlin/shared/theme';
import { CRITICALITY, CriticalityButton } from '@marlin/shared/ui-criticality';
import { Search } from '@marlin/shared/ui-form-common';
import { Button, useMediaQuery, useTheme } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../../content';
import { useFiltering } from '../../../../hooks/event-log/use-filtering.hook';
import { IFilterParams } from '../event-log.component';
import { FilterChips } from './filter-chips.component';
import { MobileFilters } from './mobile-filters.component';

interface IFiltersProps {
  onChangeParams: Dispatch<SetStateAction<IFilterParams>>;
  params: IFilterParams;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'space-between',
    width: '100%',
    '& > div': {
      marginBottom: theme.typography.pxToRem(12),
    },
    columnGap: theme.typography.pxToRem(16),
  },
  filterItem: {
    height: theme.typography.pxToRem(56),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: theme.typography.pxToRem(16),
    '& > div': {
      marginRight: theme.typography.pxToRem(5),
    },
    '& > div:nth-of-type(2)': {
      marginRight: theme.typography.pxToRem(16),
    },
  },
  searchText: {
    minWidth: theme.typography.pxToRem(248),
  },
  filterContainar: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: theme.typography.pxToRem(8),
  },
  button: {
    marginTop: theme.typography.pxToRem(10),
    paddingRight: theme.typography.pxToRem(5),
    flexShrink: 0,
  },
}));

export const Filters = ({ onChangeParams, params }: IFiltersProps) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { criticalityChange, statusChange, handleClearAll, status, onChangeSearchValue, textSearchValue } =
    useFiltering(onChangeParams);

  if (isMobile)
    return (
      <MobileFilters
        criticalityChange={criticalityChange}
        statusChange={statusChange}
        handleClearAll={handleClearAll}
        status={status}
        onChangeSearchValue={onChangeSearchValue}
        textSearchValue={textSearchValue}
        params={params}
        onChangeParams={onChangeParams}
      />
    );

  return (
    <div data-testid="event-log-filters-container" className={classes.container}>
      <div className={classes.filterContainar}>
        <div className={classes.filterItem}>
          <Search
            label={content.FILTERS.LABEL_SEARCH}
            prefix="event-log"
            className={classes.searchText}
            value={textSearchValue}
            onChange={onChangeSearchValue}
          />
        </div>
        <div className={classes.filterItem}>
          <FilterChips
            label={content.NEW}
            isActive={status.new}
            status={ALERT_STATUS_FILTER.CURRENT}
            onClick={statusChange}
          />
          <FilterChips
            label={content.RESOLVED}
            isActive={status.resolved}
            status={ALERT_STATUS_FILTER.RESOLVED}
            onClick={statusChange}
          />
          <CriticalityButton
            onClick={criticalityChange}
            severity={CRITICALITY.HIGH}
            selected={params?.criticality?.high ?? false}
          />
          <CriticalityButton
            onClick={criticalityChange}
            severity={CRITICALITY.LOW}
            selected={params?.criticality?.low ?? false}
          />
        </div>
      </div>
      <div className={classes.button}>
        <Button variant="outlined" data-testid="clear-all-button" onClick={handleClearAll}>
          {content.CLEAR_ALL}
        </Button>
      </div>
    </div>
  );
};
