import { PageHeader } from '@marlin/shared/ui-page';
import { useFeatureFlagsContext } from '@marlin/shared/utils-common-feature-flags-context';
import { PERMISSIONS, Restricted } from '@marlin/shared/utils-permission';
import { routes } from '@marlin/shared/utils-routes';
import RouterRoundedIcon from '@mui/icons-material/RouterRounded';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';

export const useStyles = makeStyles()(() => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export const GatewayHubHeader = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { gateway } = useFeatureFlagsContext();

  return (
    <PageHeader
      prefix="gateway"
      title={content.GATEWAY_HUB_TITLE}
      subtitle={content.GATEWAY_HUB_SUBTITLE}
      icon={<RouterRoundedIcon />}
      actions={
        gateway ? (
          <div className={classes.wrapper}>
            <Restricted to={PERMISSIONS.CREATE_NEW_GATEWAY}>
              <Button variant="contained" onClick={() => navigate(routes.gateway.registration.url())}>
                {content.CREATE}
              </Button>
            </Restricted>
          </div>
        ) : null
      }
    />
  );
};
