import { MarlinTheme } from '@marlin/shared/theme';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import { Button, FormHelperText, IconButton, Paper, Typography, alpha, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../content';
import { EQUIPMENT_ENUM, TEquipment } from '../../onboarding.schema';
import { useDeleteModal } from './delete-modal/delete-modal.hook';
import { EquipmentCardStatus } from './equipment-card-status.component';
import { IEquipmentFormProps } from './equipment-switcher.component';
import { equipmentTilesConfig } from './equipment.config';
import { useEquipmentStatus } from './use-equipment-status.hook';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  cardContainer: {
    marginBottom: theme.typography.pxToRem(4),
    marginTop: theme.typography.pxToRem(4),
  },
  cardRow: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.typography.pxToRem(8),
  },
  containerHover: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.typography.pxToRem(8),
    backgroundColor: alpha(theme.palette.primary.main, 0.04),
  },
  formContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.typography.pxToRem(16),
    borderTop: '0px',
    marginTop: theme.typography.pxToRem(-16),
  },
  metadata: {
    display: 'flex',
  },
  iconContainer: {
    backgroundColor: alpha(theme.palette.primary.main, 0.04),
    width: theme.typography.pxToRem(48),
    height: theme.typography.pxToRem(48),
    padding: theme.typography.pxToRem(4),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.typography.pxToRem(8),
  },
  name: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(14),
  },
  config: {
    marginLeft: theme.typography.pxToRem(16),
  },
  removeIcon: {
    position: 'absolute',
    left: '100%',
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      left: 0,
    },
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'baseline',
  },
  editButton: {
    marginRight: theme.typography.pxToRem(16),
  },
}));

interface IEquipmentCardProps {
  equipment: TEquipment;
  index: number;
  equipmentForm: (data: IEquipmentFormProps) => JSX.Element;
  onDeleteClick: () => void;
  setSelectedEditEquipment: React.Dispatch<React.SetStateAction<EQUIPMENT_ENUM | null>>;
}

const getEquipmentConfig = (equipmentType: EQUIPMENT_ENUM) => {
  return equipmentTilesConfig.find((tile) => tile.id === equipmentType);
};

export const EquipmentCard = ({
  equipment,
  index,
  equipmentForm,
  onDeleteClick,
  setSelectedEditEquipment,
}: IEquipmentCardProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { classes } = useStyles();
  const equipmentConfig = getEquipmentConfig(equipment.equipmentType);
  const { status, error } = useEquipmentStatus(equipment, index);
  const [hover, setHover] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const { openDeleteModal } = useDeleteModal(index, onDeleteClick);

  if (!equipmentConfig) {
    return null;
  }

  const openEditForm = () => {
    setFormOpen(true);
    setSelectedEditEquipment(equipment.equipmentType);
  };

  const onFormCancel = () => {
    setFormOpen(false);
    setSelectedEditEquipment(null);
  };

  return (
    <div className={classes.cardContainer}>
      <div className={classes.cardRow}>
        <Paper
          variant="outlined"
          className={!formOpen && hover ? classes.containerHover : classes.container}
          onMouseEnter={(e) => {
            setHover(true);
          }}
          onMouseLeave={(e) => {
            setHover(false);
          }}
        >
          <div className={classes.metadata}>
            <div className={classes.iconContainer}>{equipmentConfig.icon}</div>
            <div className={classes.config}>
              <div className={classes.name}>{equipment.equipmentName}</div>
              <Typography variant="caption" color="text.secondary">
                {isMobile ? '' : content.EQUIPMENT_TYPE} {equipmentConfig.name}
              </Typography>
            </div>
          </div>
          <div className={classes.actionContainer}>
            {!formOpen && hover && (
              <Button
                variant="outlined"
                className={classes.editButton}
                data-testid="cancel-button"
                onClick={openEditForm}
              >
                {content.EDIT}
              </Button>
            )}
            <EquipmentCardStatus status={status} />
            {isMobile && (
              <IconButton className={classes.removeIcon} onClick={openDeleteModal} color="primary">
                <RemoveCircleRoundedIcon />
              </IconButton>
            )}
          </div>
        </Paper>
        {!isMobile && (
          <IconButton className={classes.removeIcon} onClick={openDeleteModal} color="primary">
            <RemoveCircleRoundedIcon />
          </IconButton>
        )}
      </div>

      {formOpen && (
        <div className={classes.cardRow}>
          <Paper variant="outlined" className={classes.formContainer}>
            {equipmentForm({
              equipmentType: equipment.equipmentType,
              equipment,
              isEditForm: true,
              onCancel: onFormCancel,
              index,
            })}
          </Paper>
        </div>
      )}
      {error && <FormHelperText error>{error}</FormHelperText>}
    </div>
  );
};
