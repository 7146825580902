import { useSubscription } from 'observable-hooks';
import { useState } from 'react';
import { interval } from 'rxjs';

const lastReadingTimeInterval$ = interval(1000);

type TUseLastReadingTimeCounterOptions = {
  disableCounter: boolean;
} | void;

export const useLastReadingTimeCounter = (options: TUseLastReadingTimeCounterOptions) => {
  const [, setSecondsPassed] = useState(0);
  useSubscription(lastReadingTimeInterval$, (value) => {
    if (options?.disableCounter) return;
    setSecondsPassed(value);
  });
};
