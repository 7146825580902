import { AVERAGING_FUNCTION_FILTER, TChartDisplayType, TChartType } from '@marlin/shared/utils-chart';

export const getAveragingFunction = (
  chartType: TChartType,
  chartDisplayType: TChartDisplayType,
  averagingFunctionFilter: AVERAGING_FUNCTION_FILTER
) => {
  if (chartType === 'leak' && chartDisplayType === 'heatmap') {
    return AVERAGING_FUNCTION_FILTER.MAX;
  }
  if (chartType === 'flow' && chartDisplayType === 'bar') {
    return AVERAGING_FUNCTION_FILTER.SUM;
  }

  return averagingFunctionFilter;
};
