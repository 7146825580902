import { Context, createContext as reactCreateContext, useContext as useReactContext } from 'react';

export type TDefaultValue = undefined;

export function createContext<T>(defaultValue?: T): Context<T | TDefaultValue> {
  if (defaultValue === undefined) {
    return reactCreateContext(defaultValue);
  }

  return reactCreateContext<T>(defaultValue) as Context<T | TDefaultValue>;
}

export function useContext<T>(Context: Context<T>): T extends undefined ? never : T {
  const value: T | undefined = useReactContext<T>(Context);

  if (value === undefined) {
    throw new Error(`No ${Context.displayName} found when calling useContext.`);
  }

  return value as T extends undefined ? never : T;
}
