import { TSideNavElement } from '@marlin/account-feature-navbar';
import { SLIDE, TEASER_MODAL_ACTION_TYPE, useTeaserModal } from '@marlin/account/feature/teaser';
import { UnreadAlertsBadge } from '@marlin/alert/ui/alert-unread-badge';
import { environment } from '@marlin/environment';
import { PERMISSIONS, usePermission } from '@marlin/shared/utils-permission';
import { routes } from '@marlin/shared/utils-routes';
import AutoGraphRoundedIcon from '@mui/icons-material/AutoGraphRounded';
import BarChartRounded from '@mui/icons-material/BarChartRounded';
import BoltRoundedIcon from '@mui/icons-material/BoltRounded';
import DeviceHubRoundedIcon from '@mui/icons-material/DeviceHubRounded';
import HelpRounded from '@mui/icons-material/HelpRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import MediationRoundedIcon from '@mui/icons-material/MediationRounded';
import MiscellaneousServicesRoundedIcon from '@mui/icons-material/MiscellaneousServicesRounded';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import RouterRoundedIcon from '@mui/icons-material/RouterRounded';
import SensorsRoundedIcon from '@mui/icons-material/SensorsRounded';
import SettingsIcon from '@mui/icons-material/Settings';
import { Icon, IconProps, SvgIcon } from '@mui/material';

import { content } from './content';
import { useStyles } from './options.styles';
import { useSustainabilityPermissions } from './use-sustainability-permissions.hook';

export const optionMap = {
  home: {
    to: routes.home.root,
    Icon: HomeRoundedIcon,
    title: content.SIDE_NAV_HOME,
  },
  systemMap: {
    to: routes.systemMap.root,
    Icon: MediationRoundedIcon,
    title: content.SIDE_NAV_SYSTEM_MAP,
  },
  locations: {
    to: routes.locations.root,
    Icon: LocationOnRoundedIcon,
    title: content.SIDE_NAV_LOCATIONS,
  },
  equipments: {
    to: routes.equipments.root,
    Icon: DeviceHubRoundedIcon,
    title: content.SIDE_NAV_EQUIPMENT,
  },
  devices: {
    to: routes.sensors.root,
    Icon: SensorsRoundedIcon,
    title: content.SIDE_NAV_SENSORS,
  },
  gateways: {
    to: routes.gateway.root,
    Icon: RouterRoundedIcon,
    title: content.SIDE_NAV_GATEWAYS,
  },
  automations: {
    to: routes.automations.root,
    Icon: MiscellaneousServicesRoundedIcon,
    title: content.SIDE_NAV_AUTOMATIONS,
  },
  alerts: {
    to: routes.alerts.root,
    Icon: NotificationsRoundedIcon,
    title: content.SIDE_NAV_ALERTS,
    Badge: UnreadAlertsBadge,
  },
  analytics: {
    to: routes.analytics.root,
    Icon: BarChartRounded,
    title: content.SIDE_NAV_ANALYTICS,
  },
  sustainability: {
    to: routes.analytics.sustainability.root,
    Icon: ((props: IconProps) => {
      return (
        <Icon {...props} baseClassName="material-symbols-outlined">
          news
        </Icon>
      );
    }) as typeof SvgIcon,
    title: content.SIDE_NAV_SUSTAINABILITY,
  },
  help: {
    to: routes.help.root,
    Icon: HelpRounded,
    title: content.SIDE_NAV_HELP,
  },
  settings: {
    to: routes.settings.people.url(),
    Icon: SettingsIcon,
    title: content.SIDE_NAV_SETTINGS,
  },
  analyticsv2: {
    to: routes.analyticsv2.root,
    Icon: AutoGraphRoundedIcon,
    title: content.SIDE_NAV_ANALYTICS_V2,
  },
};

const showGateway = (isTier: boolean) => {
  if (isTier || (!isTier && environment.module.features.gateway)) return true;
  return environment.module.features.gateway;
};

export const useSideNavOptions = () => {
  const { classes } = useStyles();
  const allowedToDevices = usePermission(PERMISSIONS.SENSOR_DEVICES);
  const allowedToGateways = showGateway(allowedToDevices);
  const allowedToSystemMap = usePermission(PERMISSIONS.SYSTEM_MAP);
  const allowedToExport = usePermission(PERMISSIONS.DATA_EXPORT);
  const allowedToAnalyticsv2 = environment.module.features.analyticsv2;
  const allowedToSustainability = useSustainabilityPermissions();
  const { modalDispatch } = useTeaserModal();
  const handleUpgradeSystemMapOption = () => {
    modalDispatch({
      type: TEASER_MODAL_ACTION_TYPE.SHOW_TEASER_MODAL,
      payload: {
        slide: SLIDE.SYSTEM_MAP,
      },
    });
  };

  const handleUpgradeAnalyticsOption = () => {
    modalDispatch({
      type: TEASER_MODAL_ACTION_TYPE.SHOW_TEASER_MODAL,
      payload: {
        slide: SLIDE.ANALYTICS,
      },
    });
  };

  const topNavLinks: TSideNavElement[] = [optionMap.home];
  const bottomNavLinks: TSideNavElement[] = [
    {
      key: 'desktop-separator-1',
    },
    optionMap.locations,
    optionMap.equipments,
  ];

  if (allowedToDevices) {
    bottomNavLinks.push(optionMap.devices);
  }
  bottomNavLinks.push(optionMap.automations);
  if (allowedToGateways) {
    bottomNavLinks.push(optionMap.gateways);
  }
  bottomNavLinks.push(
    {
      key: 'desktop-separator-2',
    },
    optionMap.settings,
    optionMap.help
  );

  topNavLinks.push(
    allowedToSystemMap
      ? optionMap.systemMap
      : {
          ...optionMap.systemMap,
          endAdornment: <BoltRoundedIcon className={classes.icon} />,
          onClick: handleUpgradeSystemMapOption,
        }
  );

  topNavLinks.push(optionMap.alerts);

  const analyticsOption = allowedToSustainability
    ? { ...optionMap.analytics, nestedOptions: [optionMap.sustainability] }
    : optionMap.analytics;

  // todo: refactor so SideNav uses allowed prop
  topNavLinks.push(
    allowedToExport
      ? analyticsOption
      : {
          ...analyticsOption,
          endAdornment: <BoltRoundedIcon className={classes.icon} />,
          onClick: handleUpgradeAnalyticsOption,
        }
  );

  if (allowedToAnalyticsv2) {
    topNavLinks.push(optionMap.analyticsv2);
  }

  return {
    topNavLinks,
    bottomNavLinks,
  };
};

export const useMobileSideNavOptions = () => {
  const allowedToDevices = usePermission(PERMISSIONS.SENSOR_DEVICES);
  const allowedToGateways = showGateway(allowedToDevices);
  const topLinks: TSideNavElement[] = [optionMap.home, optionMap.alerts];
  const bottomLinks: TSideNavElement[] = [
    {
      key: 'mobile-separator-1',
    },
    optionMap.locations,
    optionMap.equipments,
  ];

  if (allowedToDevices) {
    bottomLinks.push(optionMap.devices);
  }
  if (allowedToGateways) {
    bottomLinks.push(optionMap.gateways);
  }

  bottomLinks.push(
    {
      key: 'mobile-separator-2',
    },
    optionMap.help
  );

  return {
    topLinks,
    bottomLinks,
  };
};
