import {
  ALERT_STATUS_FILTER,
  ERROR_TYPES,
  IAlert,
  useFilteredAlertsWithPaging,
} from '@marlin/alert/data-access/alert-action';
import { useMemo } from 'react';

import { useSensorId } from './use-sensor-id.hook';

const defaultParams = {
  status: ALERT_STATUS_FILTER.CURRENT,
  pageSize: 10,
  locationIds: [],
  equipmentIds: [],
  deviceIds: [],
  deviceTypes: [],
  page: 1,
};

export const useDeviceCurrentAlerts = () => {
  const deviceId = useSensorId();
  const deviceIds = useMemo(() => (deviceId ? [deviceId] : []), [deviceId]);

  const currentAlertQuery = useFilteredAlertsWithPaging({
    params: { ...defaultParams, deviceIds },
    enabled: deviceIds.length > 0,
    staleTime: 1000,
  });

  const isAlert = useMemo(() => !!currentAlertQuery.data?.pagination.totalItems || false, [currentAlertQuery]);

  const communicationLostAlert = useMemo(
    () => currentAlertQuery.data?.data.find((alert: IAlert) => alert.errorType === ERROR_TYPES.LOST_COMMUNICATION),
    [currentAlertQuery]
  );

  const totalCurrent = useMemo(() => {
    return currentAlertQuery.data?.pagination.totalItems ?? 0;
  }, [currentAlertQuery]);

  return { totalCurrent, isAlert, communicationLostAlert };
};
