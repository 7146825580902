import { MarlinTheme } from '@marlin/shared/theme';
import { getFormattedValue } from '@marlin/shared/utils-format-reading';
import { TLastReadingValue } from '@marlin/system-map/data-access/system-map';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../content';
import { formatRemainingTime } from '../utils/utils';
import { BaseHeader } from './base-header.component';
import { ModeValue } from './mode-value.component';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  defaultTitle: {
    display: 'flex',
    color: theme.palette.error.main,
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: theme.typography.pxToRem(4),
    lineHeight: '160%',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold,
  },
  values: {
    display: 'flex',
    gap: theme.typography.pxToRem(8),
  },
}));

interface ISanitizationHeaderProps {
  lastReadingValues: TLastReadingValue[];
  variant: 'default' | 'max';
  id: string;
}

export const SanitizationHeader = ({ lastReadingValues, variant, id }: ISanitizationHeaderProps) => {
  const { classes } = useStyles();

  const setpointTarget = lastReadingValues.find((reading) => reading.name.toLowerCase() === 'setpointtarget');
  const formattedSetpointTarget = getFormattedValue(setpointTarget?.value || '', setpointTarget?.unitOfMeasure);

  const overrideTime = lastReadingValues.find((reading) => reading.name.toLowerCase() === 'overridetime');
  const formattedRemainingTime = overrideTime?.value ? formatRemainingTime(overrideTime.value) : undefined;

  return (
    <BaseHeader variant={variant} status="error" testid="sanitization-header" equipmentId={id}>
      <div className={classes.defaultTitle}>
        <SettingsSuggestIcon />
        {content.EQUIPMENT.SANITIZATION_IN_PROGRESS}
      </div>
      {variant === 'max' && (
        <div className={classes.values}>
          <ModeValue label={content.EQUIPMENT.SETPOINT}>{formattedSetpointTarget}</ModeValue>
          {formattedRemainingTime && (
            <ModeValue label={content.EQUIPMENT.TIME_REMAINING}>{formattedRemainingTime}</ModeValue>
          )}
        </div>
      )}
    </BaseHeader>
  );
};
