import { MarlinTheme } from '@marlin/shared/theme';
import { HotSos } from '@marlin/shared/ui-hot-sos';
import { useFeatureFlagsContext } from '@marlin/shared/utils-common-feature-flags-context';
import { getRecipientCardTitle } from '@marlin/shared/utils/format-alert';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import TextsmsRoundedIcon from '@mui/icons-material/TextsmsRounded';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';
import { CHANNEL_TYPE, IAlert } from '../../types';
import { CardHeader } from '../header.component';
import { RecipientList } from '../recipient-list.component';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  channelRow: {
    display: 'flex',
  },
  channelResolvedRow: {
    marginLeft: theme.typography.pxToRem(-44),
  },
  wrapper: {
    display: 'flex',
  },
  data: {
    width: `calc(100% - ${theme.typography.pxToRem(50)})`,
  },
}));

interface IRecipientsSection {
  alert: IAlert;
  icon?: JSX.Element;
  isCurrentAlert?: boolean;
  isEquipmentAlert?: boolean;
}

export const RecipientsSection = ({
  alert,
  icon,
  isCurrentAlert = false,
  isEquipmentAlert = false,
}: IRecipientsSection): JSX.Element => {
  const { classes, cx } = useStyles();

  const title = useMemo(() => {
    if (isEquipmentAlert) {
      return `${alert.title}`;
    }
    return getRecipientCardTitle(alert);
  }, [alert, isEquipmentAlert]);

  const { hotSos } = useFeatureFlagsContext();

  return (
    <div className={classes.wrapper} data-testid="recipients-section">
      {icon}
      <div className={classes.data}>
        <CardHeader title={title} />
        <div className={cx(classes.channelRow, icon && classes.channelResolvedRow)}>
          <RecipientList
            icon={<EmailRoundedIcon />}
            label={content.CARD.CURRENT.LABEL.EMAIL}
            users={alert.channels[CHANNEL_TYPE.EMAIL]?.recipients}
            channelType={CHANNEL_TYPE.EMAIL}
          />
          <RecipientList
            icon={<TextsmsRoundedIcon />}
            label={content.CARD.CURRENT.LABEL.TEXT}
            users={alert.channels[CHANNEL_TYPE.SMS]?.recipients}
            channelType={CHANNEL_TYPE.SMS}
          />
        </div>
        {/* TODO: unify HotSoS component */}
        {hotSos && <HotSos channel={alert.channels[CHANNEL_TYPE.HOT_SOS]} />}
      </div>
    </div>
  );
};
