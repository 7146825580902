import MiscellaneousServicesRoundedIcon from '@mui/icons-material/MiscellaneousServicesRounded';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';

import { useRowStyles, useStyles } from './table.styles';

interface IAutomation {
  id: string;
  name: string;
}

interface IAutomationsTableProps {
  data?: IAutomation[];
  title: string;
}

export const AutomationsTable = ({ data, title }: IAutomationsTableProps) => {
  const { classes } = useStyles();
  const { classes: rowClasses } = useRowStyles();

  return (
    <Table aria-label="automations table">
      <TableHead className={classes.tableHead}>
        <div className={classes.textWrapper}>
          <Typography className={classes.tableTitle}>{title}</Typography>
        </div>
      </TableHead>
      <TableBody>
        <div className={classes.bodyWrapper}>
          {data?.map((automation) => {
            return (
              <TableRow className={classes.row} key={automation.id}>
                <TableCell className={classes.cell} component="td" scope="row">
                  <div className={rowClasses.assetLinkWrapper}>
                    <MiscellaneousServicesRoundedIcon color="disabled" />
                    {automation.name}
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </div>
      </TableBody>
    </Table>
  );
};
