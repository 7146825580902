import { content } from '../content';
import { CONNECTION_TYPE } from '../types';

const useConnectionTypeOptions = () => {
  const options = [
    {
      id: CONNECTION_TYPE.MODBUS_1,
      name: content.FORM.CONNECTION_OPTIONS.MODBUS_1,
    },
    {
      id: CONNECTION_TYPE.MODBUS_2,
      name: content.FORM.CONNECTION_OPTIONS.MODBUS_2,
    },
  ];

  return (currentConnections: string[]) =>
    options.map((option) => ({ ...option, disabled: currentConnections.includes(option.id) }));
};

const useBaudRateOptions = () => {
  return [
    {
      id: 1200,
      name: '1200',
    },
    {
      id: 2400,
      name: '2400',
    },
    {
      id: 4800,
      name: '4800',
    },
    {
      id: 9600,
      name: '9600',
    },
    {
      id: 19200,
      name: '19200',
    },
    {
      id: 38400,
      name: '38400',
    },
    {
      id: 57600,
      name: '57600',
    },
    {
      id: 115200,
      name: '115200',
    },
  ];
};

const useDataBitsOptions = () => {
  return [
    {
      id: 7,
      name: '7',
    },
    {
      id: 8,
      name: '8',
    },
  ];
};

const useParityOptions = () => {
  return [
    {
      id: 'None',
      name: 'None',
    },
    {
      id: 'Even',
      name: 'Even',
    },
    {
      id: 'Odd',
      name: 'Odd',
    },
  ];
};

const useStopBitsOptions = () => {
  return [
    {
      id: 1,
      name: '1',
    },
    {
      id: 2,
      name: '2',
    },
  ];
};

export const useAddressOptions = () => {
  const options = Array.from({ length: 100 }, (_, idx) => ({
    id: idx + 1,
    name: `Address ${idx + 1}`,
  }));

  const getAddressesOptions = (currentAddresses: number[]) =>
    options.map((option) => ({ ...option, disabled: currentAddresses.includes(option.id) }));

  const totalAddresses = options.length;

  return { getAddressesOptions, totalAddresses };
};

export const useUpsertFormOptions = () => {
  return {
    connectionTypeOptions: useConnectionTypeOptions(),
    baudRateOptions: useBaudRateOptions(),
    dataBitsOptions: useDataBitsOptions(),
    stopBitsOptions: useStopBitsOptions(),
    parityOptions: useParityOptions(),
  };
};
