import { z } from 'zod';

export const ZodLiteralRecord = <KeyType extends string, ZodValueType extends z.ZodTypeAny>(
  keys: Readonly<KeyType[]>,
  zodValueType: ZodValueType
) =>
  z.object(
    keys.reduce(
      (agg, k) => ({
        ...agg,
        [k]: zodValueType,
      }),
      {} as Record<KeyType, z.ZodUnion<[ZodValueType]>>
    )
  );
