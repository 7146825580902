import { Box, Modal, Typography } from '@mui/material';

import { useStyles } from './modal-medium.styles';

interface IModalMediumProps {
  title?: JSX.Element | string;
  body?: JSX.Element;
  footer?: JSX.Element;
  onClose: () => void;
  presentationClassName?: string;
  modalClassName?: string;
}

const ModalMedium = ({ title, body, footer, onClose, presentationClassName, modalClassName }: IModalMediumProps) => {
  const { classes, cx } = useStyles();

  return (
    <Modal
      open={true}
      onClose={onClose}
      data-testid="modal-root"
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={presentationClassName}
    >
      <Box className={cx(classes.container, modalClassName)} data-testid="modal-body">
        <div className={classes.body}>
          <Typography variant="h6" component="h2" data-testid="modal-title">
            {title}
          </Typography>
          <div className={classes.contentWrapper} data-testid="modal-content">
            {body}
          </div>
        </div>
        <div className={classes.footer} data-testid="modal-footer">
          {footer}
        </div>
      </Box>
    </Modal>
  );
};

export { ModalMedium };
