import * as z from 'zod';

import { content } from './content';

const minTeeNameLength = 1;
const maxTeeNameLength = 120;

export const renameTeeSchema = z.object({
  name: z
    .string()
    .min(minTeeNameLength, { message: content.RENAME_TEE_VALIDATION_REQUIRED })
    .max(maxTeeNameLength, { message: content.RENAME_TEE_VALIDATION_MAX(maxTeeNameLength) }),
});

export type TRenameTeeValues = z.infer<typeof renameTeeSchema>;
