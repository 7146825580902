import { MarlinTheme } from '@marlin/shared/theme';
import { useFeatureFlagsContext } from '@marlin/shared/utils-common-feature-flags-context';
import { PERMISSIONS, Restricted } from '@marlin/shared/utils-permission';
import { Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  button: {
    width: theme.typography.pxToRem(230),
  },
  builderButton: {
    marginRight: theme.spacing(2),
  },
}));

interface IAutomationHeaderActionsProps {
  onHeaderButtonClick: () => void;
  onAutomationBuilderClick: () => void;
}

export function AutomationHeaderActions({
  onHeaderButtonClick,
  onAutomationBuilderClick,
}: IAutomationHeaderActionsProps): JSX.Element {
  const { automationBuilder } = useFeatureFlagsContext();

  const { classes } = useStyles();
  return (
    <Restricted to={[PERMISSIONS.CREATE_NEW_AUTOMATION, PERMISSIONS.CUSTOM_AUTOMATIONS]}>
      <>
        {automationBuilder && (
          <Button
            onClick={onAutomationBuilderClick}
            data-testid="automations-header-button-builder"
            className={classes.builderButton}
            variant="contained"
          >
            Go to Automation Workflow Builder
          </Button>
        )}
        <Button
          onClick={onHeaderButtonClick}
          data-testid="automations-header-button"
          className={classes.button}
          variant="contained"
        >
          {content.HEADER_BUTTON_TITLE}
        </Button>
      </>
    </Restricted>
  );
}
