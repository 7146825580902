import { IAlert } from '@marlin/alert/data-access/alert-action';
import { CriticalityControl } from '@marlin/shared/ui-criticality';
import { formatTimestamp } from '@marlin/shared/utils-common-date';
import { routes } from '@marlin/shared/utils-routes';
import { TDeviceMetadataDatapointsResponse } from '@marlin/shared/utils/datapoint-mappers';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import EmailIcon from '@mui/icons-material/Email';
import PlaceIcon from '@mui/icons-material/Place';
import TextsmsIcon from '@mui/icons-material/Textsms';
import { Box, Button, Divider, Modal as MuiModal, Typography } from '@mui/material';
import isEmpty from 'lodash/isEmpty';

import { content } from '../../../../content';
import { Changelog } from '../shared/changelog.component';
import { Chips } from '../shared/chips.component';
import { CounterWithIcon } from '../shared/counter-with-icon.component';
import { DetailsLink } from '../shared/details-link.component';
import { IconSwitcher } from '../shared/icon-switcher.component';
import { LinkWithIcon } from '../shared/icon-with-link.component';
import { useStyles } from './event-log-details.modal.styles';

const dateTimeFormat = 'MM/DD/YY HH:mm z';

interface IModalMediumProps {
  alert: IAlert | undefined;
  isOpen: boolean;
  onClose: () => void;
  metadata: TDeviceMetadataDatapointsResponse[];
}

const EventLogDetailsModal = ({ alert, isOpen, onClose, metadata }: IModalMediumProps) => {
  const { classes, cx } = useStyles();

  if (!alert) {
    return null;
  }

  return (
    <MuiModal
      open={isOpen}
      onClose={onClose}
      data-testid="modal-root"
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={classes.container} data-testid="modal-container">
        <div className={classes.header} data-testid="modal-header">
          <div className={classes.headerSection} data-testid="modal-header-section-title">
            <IconSwitcher alert={alert} />
            <Typography variant="h6" component="h2" data-testid="modal-title">
              {alert.title}
            </Typography>
          </div>
          <div className={classes.headerSection} data-testid="modal-header-section-status">
            <div className={classes.headerStatus} data-testid="modal-header-status">
              <CriticalityControl severity={alert.criticality} />
            </div>
            <Chips ruleCategory={alert.ruleCategory} status={alert.status} />
          </div>
        </div>
        <Divider />
        <div className={classes.body} data-testid="modal-body">
          <div className={classes.bodySectionOne} data-testid="modal-section-one">
            <div className={cx(classes.sectionContent, classes.sectionWide)} data-testid="modal-counter-container">
              <CounterWithIcon
                iconElement={<EmailIcon />}
                text={content.EVENT_LOG_DETAILS.EMAIL_MSG}
                counter={alert.channels['EMAIL']?.recipientIds.length ?? 0}
              />
              <CounterWithIcon
                iconElement={<TextsmsIcon />}
                text={content.EVENT_LOG_DETAILS.TEXT_MSG}
                counter={alert.channels['SMS']?.recipientIds.length ?? 0}
              />
            </div>
            <div className={classes.sectionContent} data-testid="modal-links-container">
              {alert.location?.name && (
                <LinkWithIcon
                  iconElement={<PlaceIcon />}
                  text={alert.location.name}
                  to={routes.locations.details.url(alert.location.id)}
                />
              )}
              <LinkWithIcon
                iconElement={<DeviceHubIcon />}
                text={alert.equipment.name}
                to={routes.equipments.details.url(alert.equipment.id)}
              />
            </div>
            <div className={classes.sectionDate} data-testid="modal-date-container">
              {!!alert.startTime && (
                <div data-testid="modal-date-start">
                  <span className={classes.dateLabel}>{content.EVENT_LOG_DETAILS.START_TIME.toUpperCase()}:</span>
                  {formatTimestamp(alert.startTime, dateTimeFormat)}
                </div>
              )}
              {!!alert.resolveTime && (
                <div data-testid="modal-date-resolve">
                  <span className={classes.dateLabel}>{content.EVENT_LOG_DETAILS.RESOLVE_TIME.toUpperCase()}:</span>
                  {formatTimestamp(alert.resolveTime, dateTimeFormat)}
                </div>
              )}
            </div>
          </div>
          <Divider orientation="vertical" flexItem />
          <div className={classes.bodySectionTwo} data-testid="modal-section-two">
            {alert.ruleCategory === 'Information' && !isEmpty(alert.additionalValues) ? (
              <Changelog alert={alert} metadata={metadata} />
            ) : (
              <>
                {alert.hyperlink && <DetailsLink hyperlink={alert.hyperlink} />}
                {alert.description}
              </>
            )}
          </div>
        </div>
        <Divider />
        <div className={classes.footer} data-testid="modal-footer">
          <Button onClick={onClose}>{content.EVENT_LOG_DETAILS.CLOSE}</Button>
        </div>
      </Box>
    </MuiModal>
  );
};

export default EventLogDetailsModal;
