import { environment } from '@marlin/environment';

export const content = {
  NO_DATA: 'No results found.',
  ERROR: 'Something went wrong. Please try again later.',
  ID: 'ID',
  BUTTON_CANCEL: 'Back',
  SENSOR_NAME: 'Sensor Name',
  SENSOR_TYPE: 'Sensor Type',
  LAST_READING: 'Last Reading',
  LOCATION: 'Location',
  HIERARCHY_VIEW_TITLE: 'Hierarchy View',
  LINKED_SENSORS_TITLE: 'Linked Sensors',
  SERIAL_NUMBER_TITLE: 'Serial Number',
  MODEL_TITLE: 'Model',
  AUTOMATIONS_TITLE: 'Automations',
  ID_TITLE: 'ID',
  SENSOR_TEMPERATURE: 'Temperature Sensor',
  SENSOR_PRESSURE: 'Pressure Sensor',
  SENSOR_LEAK: 'Leak Sensor',
  LABEL_LOCATION: 'Location',
  LABEL_SEARCH: 'Search',
  CLEAR_ALL: 'Clear All',
  DELETE_BUTTON: 'Delete',
  EDIT_BUTTON: 'Edit',
  LINKED_ITEMS: 'Linked items',
  DETAILS: 'Details',
  HIERARCHY_VIEW: 'Hierarchy view',
  EQUIPMENT_DETAILS: 'Equipment Details',
  ADDITIONAL_INFORMATION: 'Additional Information',
  EQUIPMENT_NAME: 'Equipment name',
  ID_DESC: `Unique identifier assigned within the ${environment.app.name} platform`,
  BRAND_TITLE: 'Brand',
  HIERARCHY_TAB_TITLE: 'Hierarchy',
};
