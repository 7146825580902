import { MODEL } from '@marlin/shared/utils/zod';
import { RULE_CATEGORY } from '@marlin/system-map/data-access/system-map';
import { useCallback, useMemo } from 'react';

import { TNodeData, TStatus } from '../types';
import {
  getAlertMessagesByDatapointNames as getAlertMessages,
  getAlertsByDataPointNames,
  getHighestStatus,
  mapAlertsToStatus,
} from '../utils/alerts';
import { useEquipmentMode } from './use-equipment-mode.hook';
import { useRuleCategoryStatusHook } from './use-rule-category-status.hook';

export interface IUseRegisteredEquipment {
  modeValue?: string;
  status: TStatus;
  performanceStatus: TStatus;
  healthStatus: TStatus;
  headerStatus: TStatus;
  getStatusByDisplayDataPointNames: (deviceEventCodes: string[]) => TStatus;
  getAlertMessagesByDataPointNames: (deviceEventCodes: string[]) => string[];
  model: MODEL;
}

export const useRegisteredEquipment = (nodeData: TNodeData) => {
  const { modeValue, status: modeStatus } = useEquipmentMode(nodeData);
  const { ruleCategoryStatus: healthStatus } = useRuleCategoryStatusHook({
    alerts: nodeData.alerts || [],
    ruleCategory: RULE_CATEGORY.HEALTH,
  });
  const { ruleCategoryStatus: performanceStatus } = useRuleCategoryStatusHook({
    alerts: nodeData.alerts || [],
    ruleCategory: RULE_CATEGORY.PERFORMANCE,
  });

  const status: TStatus = useMemo(
    () => getHighestStatus([modeStatus, healthStatus, performanceStatus]),
    [modeStatus, healthStatus, performanceStatus]
  );

  const headerStatus: TStatus = useMemo(
    () => getHighestStatus([performanceStatus, healthStatus]),
    [performanceStatus, healthStatus]
  );

  const getStatusByDisplayDataPointNames = useCallback(
    (dataPointNames: string[]): TStatus => {
      const alerts = getAlertsByDataPointNames(nodeData.alerts || [], dataPointNames);
      return alerts.length ? mapAlertsToStatus(alerts) : 'success';
    },
    [nodeData.alerts]
  );

  const getAlertMessagesByDataPointNames = useCallback(
    (dataPointNames: string[]): string[] => {
      return getAlertMessages(nodeData.alerts || [], dataPointNames);
    },
    [nodeData.alerts]
  );

  const model = useMemo(() => nodeData?.metadata?.tags?.model as MODEL, [nodeData?.metadata?.tags?.model]);

  return useMemo<IUseRegisteredEquipment>(
    () => ({
      modeValue,
      status,
      performanceStatus,
      healthStatus,
      headerStatus,
      getStatusByDisplayDataPointNames,
      getAlertMessagesByDataPointNames,
      model,
    }),
    [
      headerStatus,
      healthStatus,
      modeValue,
      performanceStatus,
      status,
      getStatusByDisplayDataPointNames,
      getAlertMessagesByDataPointNames,
      model,
    ]
  );
};
