import { TChartDatapointsGroup } from '@marlin/shared/utils-chart';
import { useMemo } from 'react';

import { flowVolumeDatapointNames } from '../const';
import { IChartMetadata } from '../types';
import { useChartingColors } from './use-charting-colors.hook';

export const useChartMetadata = (datapointsGroup: TChartDatapointsGroup): IChartMetadata => {
  const chartingColors = useChartingColors();

  const datapointsMetadata = useMemo(
    () =>
      (datapointsGroup?.datapoints || [])
        .filter(
          (datapoint) => datapoint.displayType === 'datapoint' && !flowVolumeDatapointNames.includes(datapoint.name)
        )
        .map((datapoint, index) => ({
          name: datapoint.name,
          label: datapoint.label ?? '',
          color: chartingColors.at(index) ?? '',
          isDefault: datapoint.isDefault,
        })),
    [chartingColors, datapointsGroup]
  );

  const thresholdsMetadata = useMemo(() => {
    const filteredMetadata = (datapointsGroup?.datapoints || []).filter(
      (datapoint) => datapoint.displayType === 'threshold' && datapoint.alias && datapoint.position
    );
    return filteredMetadata.map((datapoint) => ({
      name: datapoint.name,
      label: datapoint.label ?? '',
      isDefault: datapoint.isDefault,
      alias: datapoint.alias!,
      position: datapoint.position!,
    }));
  }, [datapointsGroup]);

  return useMemo(() => ({ datapointsMetadata, thresholdsMetadata }), [datapointsMetadata, thresholdsMetadata]);
};
