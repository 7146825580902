import { MarlinTheme } from '@marlin/shared/theme';
import { Avatar } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useChipAliasLabelStyles = makeStyles()((theme: MarlinTheme) => ({
  chipLabelContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.typography.pxToRem(4),
  },
  chipLabel: {
    color: theme.palette.primary.main,
    padding: `${theme.typography.pxToRem(4)} ${theme.typography.pxToRem(6)}`,
  },
  avatar: {
    maxWidth: theme.typography.pxToRem(24),
    maxHeight: theme.typography.pxToRem(24),
    backgroundColor: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(12),
    textAlign: 'center',
  },
}));

interface IChipAliasLabel {
  label: string;
  alias: string;
}

export const ChipAliasLabel = ({ label, alias }: IChipAliasLabel) => {
  const { classes } = useChipAliasLabelStyles();

  return (
    <div className={classes.chipLabelContainer}>
      <Avatar className={classes.avatar}>{alias}</Avatar>
      <span className={classes.chipLabel}>{label}</span>
    </div>
  );
};
