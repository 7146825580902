import { MarlinTheme } from '@marlin/shared/theme';
import { PERMISSIONS, usePermission } from '@marlin/shared/utils-permission';
import { ErrorOutline, WarningAmberRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Typography, alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { useSanitizationDetails } from '../../../../shared/hooks/intellistation/use-sanitization-details.hook';
import { content } from '../../../content';
import { useStopSanitization } from '../../../hooks/intellistation/use-stop-sanitization.hook';

interface IStyles {
  isSanitization: boolean;
}

export const useStyles = makeStyles<IStyles>()((theme: MarlinTheme, { isSanitization }) => ({
  wrapper: {
    background: isSanitization ? alpha(theme.palette.error.main, 0.12) : alpha(theme.palette.warning.main, 0.12),
    height: theme.typography.pxToRem(48),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      padding: theme.typography.pxToRem(16),
      height: 'fit-content',
    },
  },
  controls: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    alignItems: 'center',
    paddingLeft: theme.typography.pxToRem(32),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      paddingLeft: theme.typography.pxToRem(0),
    },
  },
  error: {
    color: isSanitization ? theme.palette.error.main : theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(14),
    [theme.breakpoints.between('md', 'lg')]: {
      fontSize: theme.typography.pxToRem(12),
    },
  },
  errorWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  controlWrapper: {
    display: 'flex',
  },
  icon: {
    height: theme.typography.pxToRem(22),
    marginRight: theme.typography.pxToRem(8),
    [theme.breakpoints.between('md', 'lg')]: {
      height: theme.typography.pxToRem(16),
    },
  },
  button: {
    height: theme.typography.pxToRem(30),
    marginRight: theme.typography.pxToRem(32),
    minWidth: theme.typography.pxToRem(214),
    [theme.breakpoints.down('lg')]: {
      fontSize: theme.typography.pxToRem(12),
      minWidth: theme.typography.pxToRem(180),
    },
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(14),
      marginTop: theme.typography.pxToRem(4),
    },
  },
  loadingIndicator: {
    position: 'static',
    marginRight: theme.typography.pxToRem(8),
  },
  typography: {
    marginLeft: theme.typography.pxToRem(16),
    lineHeight: 'normal',
  },
}));

const useValueControlStyles = makeStyles()((theme: MarlinTheme) => ({
  control: {
    display: 'flex',
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    paddingLeft: theme.typography.pxToRem(8),
    [theme.breakpoints.down('lg')]: {
      fontSize: theme.typography.pxToRem(12),
      paddingLeft: theme.typography.pxToRem(6),
    },
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(14),
      padding: 0,
    },
  },
  label: {
    padding: theme.typography.pxToRem(2),
  },
  value: {
    padding: theme.typography.pxToRem(2),
    fontWeight: theme.typography.fontWeightBold,
  },
}));

interface IValueControl {
  label: string;
  value: string;
}

export const ValueControl = ({ label, value }: IValueControl) => {
  const { classes } = useValueControlStyles();
  return (
    <div className={classes.control}>
      <span className={classes.label}>{label}:</span>
      <span className={classes.value}>{value}</span>
    </div>
  );
};

export const OverrideModeBanner = () => {
  const { remainingTime, shouldDisplayBanner, isSanitization, setpointTargetValue } = useSanitizationDetails();
  const { classes } = useStyles({ isSanitization });
  const { isStoppingSanitization, handleStopSanitization } = useStopSanitization({ isSanitization });
  const canStopSanitization = usePermission(PERMISSIONS.UPDATE_EQUIPMENT_SETTINGS);

  if (!shouldDisplayBanner) {
    return null;
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.controls}>
        <div className={classes.errorWrapper} data-testid="sanitization-banner">
          {isSanitization ? (
            <ErrorOutline className={classes.icon} color="error" data-testid="sanitization-icon" />
          ) : (
            <WarningAmberRounded className={classes.icon} color="warning" data-testid="startup-icon" />
          )}
          <span className={classes.error}>
            {isSanitization ? content.SANITIZATION_ERROR(isStoppingSanitization) : content.START_UP_WARNING}
          </span>
        </div>
        <div className={classes.controlWrapper} data-testid="sanitization-values">
          {isSanitization && !isStoppingSanitization && (
            <ValueControl label={content.SETPOINT} value={setpointTargetValue} />
          )}
          {remainingTime && !isStoppingSanitization && (
            <ValueControl label={content.REMAINING_TIME} value={remainingTime} />
          )}
          {isStoppingSanitization && (
            <Typography className={classes.typography} variant="body2" data-testid="stopping-sanitization-error-body">
              {content.STOPPING_SANITIZATION_ERROR_BODY}
            </Typography>
          )}
        </div>
      </div>
      {isSanitization && (
        <LoadingButton
          className={classes.button}
          variant="contained"
          color="error"
          data-testid="sanitization-cancel-button"
          disabled={!canStopSanitization}
          onClick={handleStopSanitization}
          loading={isStoppingSanitization}
          loadingPosition="start"
          classes={{
            loadingIndicator: classes.loadingIndicator,
          }}
        >
          <span>{isStoppingSanitization ? content.STOPPING_SANITIZATION : content.STOP_SANITIZATION}</span>
        </LoadingButton>
      )}
    </div>
  );
};
