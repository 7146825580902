import { IExtendedAutomation } from '@marlin/alert/ui/automated-action-list';
import { MarlinTheme } from '@marlin/shared/theme';
import { ModalMedium } from '@marlin/shared/ui-modal';
import { useRouter } from '@marlin/shared/utils-router';
import { Button } from '@mui/material';
import { useCallback } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';
import { useDeleteAutomationStore } from './use-store.hook';

interface IDeleteModalProps {
  automation: IExtendedAutomation | null;
  onClose: () => void;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  confirmText: {
    fontWeight: theme.typography.fontWeightBold,
  },

  button: {
    width: theme.typography.pxToRem(88),
  },

  cancelButton: {
    marginLeft: theme.typography.pxToRem(8),
  },
}));

const DeleteModal = ({ automation, onClose }: IDeleteModalProps) => {
  const { classes } = useStyles();
  const router = useRouter();
  const { deleteAutomation } = useDeleteAutomationStore();

  const handleDeleteAutomation = useCallback(async (): Promise<void> => {
    if (!automation) {
      return;
    }

    onClose();
    await deleteAutomation(automation);
    router.refresh();
  }, [automation, deleteAutomation, onClose, router]);

  if (!automation) {
    return null;
  }

  return (
    <ModalMedium
      onClose={onClose}
      title={content.MODAL_TITLE}
      body={
        <>
          <div>{content.MODAL_DELETE_AUTOMATION_QUESTION(automation.name)}</div>
          <div className={classes.confirmText}>{content.MODAL_DELETE_AUTOMATION_CONFIRMATION}</div>
        </>
      }
      footer={
        <>
          <Button className={classes.button} variant="text" onClick={onClose}>
            {content.MODAL_BUTTON_CANCEL_TEXT}
          </Button>
          <Button
            className={`${classes.button} ${classes.cancelButton}`}
            variant="contained"
            color="error"
            onClick={handleDeleteAutomation}
          >
            {content.MODAL_BUTTON_SUBMIT_TEXT}
          </Button>
        </>
      }
    />
  );
};

export { DeleteModal };
