import { createContext, useContext } from '@marlin/shared/utils-component-context';
import { TDatapointWithUiMetadata } from '@marlin/shared/utils/datapoint-mappers';
import moment from 'moment';
import { ReactNode, useCallback } from 'react';

import { content } from '../../desktop/content';
import { useEquipmentDetailsConfigContext } from './equipment-details-config-context';

interface IDatapointsContext {
  datapoints: TDatapointWithUiMetadata[];
  timestamp: string | undefined;
  getDatapoint: (name: string, options?: { displayOutdatedValues: boolean }) => TDatapointWithUiMetadata | undefined;
  isLoading?: boolean;
}

export const DatapointsContext = createContext<IDatapointsContext>();

interface IDatapointsContextProviderProps {
  datapoints: TDatapointWithUiMetadata[];
  timestamp: string | undefined;
  children: ReactNode;
  isLoading?: boolean;
}

export const DatapointsContextProvider = ({
  datapoints,
  timestamp,
  children,
  isLoading = false,
}: IDatapointsContextProviderProps) => {
  const {
    config: {
      dashboard: { liveDataExpirationSeconds },
    },
  } = useEquipmentDetailsConfigContext();

  const getDatapoint = useCallback(
    (name: string, options?: { displayOutdatedValues: boolean }) => {
      const datapoint = datapoints.find((datapoint) => {
        return datapoint.name.toLowerCase() === name.toLowerCase();
      });

      const isLiveData = moment(timestamp)
        .subtract(liveDataExpirationSeconds, 'second')
        .isBefore(datapoint?.lastReadingTime);

      if (!timestamp || isLiveData || options?.displayOutdatedValues) {
        return datapoint;
      }

      return datapoint ? { ...datapoint, displayedValue: content.EMPTY_DATAPOINT_VALUE } : undefined;
    },
    [datapoints, timestamp, liveDataExpirationSeconds]
  );

  return (
    <DatapointsContext.Provider value={{ datapoints, timestamp, getDatapoint, isLoading }}>
      {children}
    </DatapointsContext.Provider>
  );
};

export const useDatapointsContext = () => useContext(DatapointsContext);
