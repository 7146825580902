import { queryClient, useMutation } from '@marlin/shared/utils/react-query';

import { updateOrganizationSettings } from '../infrastructure/organization-settings';
import { queryKey } from './query-key.enum';

export const useUpdateOrgSettings = () => {
  return useMutation({
    mutationFn: updateOrganizationSettings,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: queryKey.ORGANIZATION_SETTINGS() });
      queryClient.setQueryData(queryKey.ORGANIZATION_SETTINGS(), data);
      queryClient.invalidateQueries(queryKey.WATER_USAGE());
    },
  });
};
