import { useObservableState } from 'observable-hooks';
import { BehaviorSubject } from 'rxjs';

const pullTabState$ = new BehaviorSubject<boolean>(false);

export const useChartHover = () => {
  const [pullTab] = useObservableState<boolean>(() => pullTabState$, false);

  const showPullTab = () => {
    pullTabState$.next(true);
  };

  const hidePullTab = () => {
    pullTabState$.next(false);
  };

  return {
    pullTab,
    showPullTab,
    hidePullTab,
  };
};
