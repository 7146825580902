const userPath = '/api/User';
const user = `${userPath}/{userId}`;
const filterableUser = `${userPath}/filter`;
const feedback = '/api/feedback';
const userData = '/api/user/data/{area}';

export const paths = {
  USER: user,
  FILTERABLE_USER: filterableUser,
  FEEDBACK: feedback,
  USER_DATA: userData,
};
