import { IChartConfigOptions } from '@marlin/asset/shared/equipment-config';
import { useDashboardChartOptions } from '@marlin/asset/shared/ui/chart-options';

import { useEquipmentContext } from '../../../../../shared/context/equipment.context';
import { EquipmentChart } from '../../../../components/chart/equipment-chart.component';
import { useChartData } from '../../../../hooks/charts/use-equipment-dashboard-chart-data.hook';

interface IChartWrapper {
  datapointGroupNames: string[];
  datapointNames: string[];
  title: string;
  subTitle?: string;
  chartDatapoints?: string[];
  options?: IChartConfigOptions;
}

export const ChartWrapper = ({ datapointGroupNames, datapointNames, title, subTitle, options }: IChartWrapper) => {
  const { rangeFilter, equipment: { devices = [] } = {} } = useEquipmentContext();
  const { chartData, from, to, isLoading } = useChartData({
    manufacturerId: devices[0]?.manufacturerId,
    rangeFilter,
    datapointGroupNames,
    datapointNames,
    options,
  });
  const { chartOptions, tooltip } = useDashboardChartOptions({ chartData, from, to });

  return (
    <div>
      <EquipmentChart
        title={title}
        subTitle={subTitle}
        isLoading={isLoading}
        chartData={chartData}
        chartOptions={chartOptions}
        from={from}
        to={to}
        tooltip={tooltip}
      />
    </div>
  );
};
