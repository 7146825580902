import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme.typography.pxToRem(24),
    paddingTop: theme.typography.pxToRem(24),
    paddingRight: theme.typography.pxToRem(24),
    paddingBottom: theme.typography.pxToRem(24),
    borderBottom: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.secondary,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(14),
      paddingLeft: theme.typography.pxToRem(16),
      paddingTop: theme.typography.pxToRem(16),
      paddingRight: theme.typography.pxToRem(16),
      paddingBottom: theme.typography.pxToRem(16),
    },
  },

  desktopLongHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme.typography.pxToRem(24),
    paddingTop: theme.typography.pxToRem(24),
    paddingRight: theme.typography.pxToRem(24),
    paddingBottom: theme.typography.pxToRem(24),
    borderBottom: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.secondary,
  },

  mobileShortHeader: {
    backgroundColor: theme.palette.background.secondary,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
    fontSize: theme.typography.pxToRem(14),
    paddingLeft: theme.typography.pxToRem(24),
    paddingTop: theme.typography.pxToRem(16),
    paddingRight: theme.typography.pxToRem(24),
    paddingBottom: theme.typography.pxToRem(16),
  },

  mobileLongHeader: {
    display: 'flex',
    paddingLeft: theme.typography.pxToRem(16),
    paddingTop: theme.typography.pxToRem(16),
    paddingRight: theme.typography.pxToRem(16),
    paddingBottom: theme.typography.pxToRem(16),
    borderBottom: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.secondary,
    flexDirection: 'column-reverse',
    alignItems: 'flex-end',
    rowGap: theme.typography.pxToRem(12),
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  deviceName: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(14),
      maxWidth: theme.typography.pxToRem(236),
      '-webkit-line-clamp': '1',
      alignSelf: 'center',
    },
  },
  shortDeviceNameMobile: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(14),
    alignSelf: 'center',
  },
  deviceNameLongMobile: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    wordBreak: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: theme.typography.pxToRem(14),
    alignSelf: 'flex-start',
  },

  deviceNameLongDesktop: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    wordBreak: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: theme.typography.pxToRem(268),
  },
}));
