import { TEquipmentSetting } from '@marlin/shared/utils/datapoint-mappers';

import { TEditedSetting } from '../../types';
import { DropdownList } from './dropdown/dropdown-list.component';
import { SettingRow } from './setting-row.component';
import { DatapointError } from './shared/datapoint-error.component';
import { ToggleSetting } from './toggle/toggle-setting.component';
import { RowControl } from './value-control/row-control.component';
import { ValueSetting } from './value-control/value-setting.component';

interface IControlSwitcher {
  datapoints: TEquipmentSetting[];
  updateSettings: (name: string, value: string, prevValue: string) => Promise<void>;
  disabled: boolean;
  setEditedSetting: (editedSetting: TEditedSetting | undefined) => void;
  setIsDirty: (isDirty: boolean) => void;
  editedSetting: TEditedSetting | undefined;
  isDirty: boolean;
  displayDisabledTooltip?: boolean;
  disabledTooltipText?: string;
}

export const ControlSwitcher = ({
  datapoints,
  updateSettings,
  disabled,
  setEditedSetting,
  editedSetting,
  setIsDirty,
  isDirty,
  displayDisabledTooltip,
  disabledTooltipText,
}: IControlSwitcher) => {
  const getMappedSettingControl = (datapoint: TEquipmentSetting) => {
    if (datapoint.controlType === 'option') {
      return (
        <RowControl
          settingItem={datapoint}
          disabled={disabled}
          setEditedSetting={setEditedSetting}
          setIsDirty={setIsDirty}
          outdated={datapoint.controlState === 'outdated'}
        >
          {(props) => (
            <DropdownList
              settingItem={datapoint}
              saveSetting={updateSettings}
              onCancelClick={props.onCancelClick}
              setEditedSetting={setEditedSetting}
              setIsDirty={setIsDirty}
              editedSetting={editedSetting}
            />
          )}
        </RowControl>
      );
    }

    if (datapoint.controlType === 'boolean') {
      return <ToggleSetting settingItem={datapoint} onChange={updateSettings} disabled={disabled} isDirty={isDirty} />;
    }

    if (datapoint.controlType === 'value') {
      return (
        <RowControl
          settingItem={datapoint}
          disabled={disabled}
          setEditedSetting={setEditedSetting}
          setIsDirty={setIsDirty}
          outdated={datapoint.controlState === 'outdated'}
        >
          {(props) => (
            <ValueSetting
              settingItem={datapoint}
              saveSetting={updateSettings}
              onCancelClick={props.onCancelClick}
              setEditedSetting={setEditedSetting}
              setIsDirty={setIsDirty}
              editedSetting={editedSetting}
            />
          )}
        </RowControl>
      );
    }

    return <DatapointError />;
  };

  return (
    <>
      {datapoints.map((datapoint, index) => {
        return (
          <SettingRow
            key={datapoint.name}
            label={datapoint.label}
            name={datapoint.name}
            isLast={index === datapoints.length - 1}
            dataTestId={datapoint.label.toLowerCase().replace(/\s+/g, '-')}
            isFirst={index === 0}
            displayedValue={datapoint.controlType !== 'boolean' ? datapoint.displayedValue : undefined}
            disabled={displayDisabledTooltip}
            tooltipText={disabledTooltipText}
            outdated={datapoint.controlState === 'outdated'}
          >
            {getMappedSettingControl(datapoint)}
          </SettingRow>
        );
      })}
    </>
  );
};
