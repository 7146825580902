import { http } from 'msw';

import { defaultHandler } from './handler-default';
import { detailsHandler } from './handler-details';
import { predictionsHandler } from './handler-predictions';

export const handlers = [
  http.get(
    'https://maps.googleapis.com/maps/api/place/js/AutocompletionService.GetPredictionsJson*',
    predictionsHandler
  ),
  http.get('https://maps.googleapis.com/maps/api/place/js/PlaceService.GetPlaceDetails*', detailsHandler),
  http.get('https://maps.googleapis.com/*', defaultHandler),
];
