import { MarlinTheme } from '@marlin/shared/theme';
import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material';
import React, { ForwardedRef, forwardRef } from 'react';
// eslint-disable-next-line local/restricted-imports-warning,ordered-imports/ordered-imports
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  root: {},
  disabledLink: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.disabled,
    textDecoration: 'none',
  },
}));

interface ILinkProps extends MuiLinkProps, RouterLinkProps {
  to: string;
  color?: 'inherit' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error';
  disabled?: boolean;
  external?: boolean;
}

export const Link = forwardRef((props: ILinkProps, ref: ForwardedRef<HTMLAnchorElement>) => {
  const { classes, cx } = useStyles();

  return (
    <MuiLink
      component={props.external ? undefined : RouterLink}
      ref={ref}
      className={cx(classes.root, props.className, {
        [classes.disabledLink]: props.disabled,
      })}
      {...props}
      style={{ ...props.style, pointerEvents: props.disabled ? 'none' : undefined }}
      href={props.external ? props.to : undefined}
    />
  );
});
