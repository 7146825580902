import { createContext } from 'react';

import { TModalAction, TModalState, initialState } from './modal-reducer';

interface IModalContextProps {
  modalState: TModalState;
  modalDispatch: TModalAction;
  drawerState: TModalState;
  drawerDispatch: TModalAction;
}

export const ModalContext = createContext<IModalContextProps>({
  modalState: initialState,
  modalDispatch: () => initialState,
  drawerState: initialState,
  drawerDispatch: () => initialState,
});
