import { MarlinTheme } from '@marlin/shared/theme';
import { Divider } from '@mui/material';
import { PropsWithChildren } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  subheader: {
    fontSize: theme.typography.pxToRem(12),
    marginBottom: theme.typography.pxToRem(12),
    textTransform: 'uppercase',
    color: theme.palette.text.secondary,
  },

  divider: {
    marginTop: theme.typography.pxToRem(12),
    marginBottom: theme.typography.pxToRem(12),
  },
}));

interface IAlertDetailsSectionProps extends PropsWithChildren {
  title: string;
  withDivider?: boolean;
}

export const DetailsSection = ({ title, children, withDivider = false }: IAlertDetailsSectionProps) => {
  const { classes } = useStyles();

  return (
    <>
      <div className={classes.subheader} data-testid="details-section">
        {title}
      </div>
      {children}
      {withDivider && <Divider className={classes.divider} />}
    </>
  );
};
