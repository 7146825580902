import { environment } from '@marlin/environment';
import moment from 'moment-timezone';

import { content } from './content';

const currentDate = new Date();

export const formatTimezone = (zoneName: string) => {
  const momentZone = moment.tz.zone(zoneName);
  if (!momentZone) {
    return null;
  }
  const readableName = zoneName.replace(/_/g, ' ');
  const abbr = momentZone.abbr(currentDate.getTime());
  const offset = ((momentZone.utcOffset(currentDate.getTime()) ?? 0) * -1) / 60;
  const sign = offset >= 0 ? '+' : '';
  return {
    id: zoneName,
    name: `${readableName} (${abbr}, UTC${sign}${offset})`,
    nameComponents: [readableName, abbr, `UTC${sign}${offset}`],
  };
};

const usTimezones: moment.MomentZoneOffset[] = moment.tz.zonesForCountry('US', true);
const europeTimezones: moment.MomentZoneOffset[] = [
  ...moment.tz.zonesForCountry('PL', true),
  ...moment.tz.zonesForCountry('HR', true),
  ...moment.tz.zonesForCountry('GB', true),
  ...moment.tz.zonesForCountry('UA', true),
  ...moment.tz.zonesForCountry('IS', true).filter((z) => z.name.includes('Atlantic')),
];

export const groupTimezone = (timezone: { id: string; name: string }): string => {
  if (usTimezones.find((z) => z.name === timezone.id)) {
    return content.US_TIMEZONES;
  }
  return content.EU_TIMEZONES;
};

const timezones = [...usTimezones, ...(environment.module.features.europeTimezones ? europeTimezones : [])];

export const timezoneList = timezones
  .sort((a, b) => a.offset - b.offset)
  .map((z) => formatTimezone(z.name))
  .filter((z) => z !== null) as { id: string; name: string }[];

export const timezoneLink = 'https://www.iana.org/time-zones';

export const formatDateByTimezone = (date: string, timezoneName: string) => moment.tz(date, timezoneName);
