import { CreateRecipient } from '@marlin/account/ui/recipient-create';
import { AUTOMATION_RULE_TYPE } from '@marlin/alert/data-access/automated-action';
import { AutomatedActionForm as UIAutomatedActionForm } from '@marlin/alert/ui/automated-action-create';
import { MarlinTheme } from '@marlin/shared/theme';
import { PageLoader } from '@marlin/shared/ui-loader';
import { useModal } from '@marlin/shared/ui-modal';
import { PageContainer, PageHeader, SystemConstraintsPage } from '@marlin/shared/ui-page';
import { useRouter } from '@marlin/shared/utils-router';
import MiscellaneousServicesRoundedIcon from '@mui/icons-material/MiscellaneousServicesRounded';
import { useCallback } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { uiContent } from './content';
import { useNavigation } from './use-navigation.hook';
import { useStore } from './use-store.hook';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: theme.static.defaultWidth, // TODO: (RWD) this should be automated, but side nav is shrinking without it!
  },
}));

export function UpdateAutomation() {
  const { classes } = useStyles();
  const router = useRouter();
  const { automationId } = useParams();
  const { getBackLink } = useNavigation();

  const [recipientModal, openRecipientModal, closeRecipientModal] = useModal();

  const {
    locations,
    recipients,
    defaultValues,
    isSuccess,
    isLoading,
    save,
    saveRecipient,
    saveRecipientErrorCode,
    ruleType,
    isError,
    closeModal,
  } = useStore(automationId || '', closeRecipientModal);

  const isInformationType = ruleType === AUTOMATION_RULE_TYPE.EQUIPMENT_INFORMATION;
  const onCancel = useCallback(() => {
    router.goTo(getBackLink(ruleType, defaultValues.isSystemRule));
  }, [getBackLink, router, ruleType, defaultValues.isSystemRule]);

  if (isError) {
    return <SystemConstraintsPage />;
  }

  if (isLoading) {
    return <PageLoader />;
  }

  if (isSuccess) {
    return <Navigate to={getBackLink(ruleType, defaultValues.isSystemRule)} />;
  }

  return (
    <PageContainer data-testid="automations-page" className={classes.container}>
      <PageHeader
        icon={<MiscellaneousServicesRoundedIcon />}
        title={uiContent.TITLE}
        subtitle={uiContent.SUBTITLE}
        prefix="automations-header"
      />
      <UIAutomatedActionForm
        content={uiContent}
        locations={locations}
        recipients={recipients}
        onCancel={onCancel}
        onSubmit={save}
        onAddRecipientButtonClick={openRecipientModal}
        defaultValues={defaultValues}
        isUpdateForm={true}
        isInformationType={isInformationType}
      />
      {recipientModal && (
        <CreateRecipient onSubmit={saveRecipient} onClose={closeModal} errorCode={saveRecipientErrorCode} />
      )}
    </PageContainer>
  );
}

export default UpdateAutomation;
