import { MarlinTheme } from '@marlin/shared/theme';
import { TGraphAsset, TGraphLink } from '@marlin/system-map/data-access/system-map';
import { useTheme } from '@mui/material/styles';
import { Node } from '@xyflow/react';
import { useMemo } from 'react';

import {
  hasHighPrioAlerts,
  hasLostCommunicationAlert,
  hasLowPrioAlerts,
  isTelemetryError,
} from '../nodes/utils/node-errors.utils';
import { TEditableEdgeData } from './path/types';
import { MARKER_TYPE } from './use-marker-definitions.hook';

type TNodeData = TGraphAsset['data'];

export const useEdgeProps = (
  data?: TGraphLink['data'] & TEditableEdgeData,
  sourceNode?: Node<TNodeData>,
  targetNode?: Node<TNodeData>
) => {
  const theme = useTheme<MarlinTheme>();

  return useMemo(() => {
    if (sourceNode && targetNode) {
      const hasHighAlerts = [sourceNode, targetNode].some(
        (node) => hasHighPrioAlerts(node.data) || hasLostCommunicationAlert(node.data) || isTelemetryError(node.data)
      );
      if (hasHighAlerts) {
        return {
          color: theme.palette.error.main,
          type: data?.isAttachment ? MARKER_TYPE.CIRCLE_ERROR : MARKER_TYPE.ARROW_ERROR,
        };
      }
      const hasLowAlerts = [sourceNode, targetNode].some((node) => hasLowPrioAlerts(node.data));
      if (hasLowAlerts) {
        return {
          color: theme.palette.warning.main,
          type: data?.isAttachment ? MARKER_TYPE.CIRCLE_WARNING : MARKER_TYPE.ARROW_WARNING,
        };
      }
    }
    return {
      color: theme.palette.systemMap.main,
      type: data?.isAttachment ? MARKER_TYPE.CIRCLE_DEFAULT : MARKER_TYPE.ARROW_DEFAULT,
    };
  }, [
    data?.isAttachment,
    sourceNode,
    targetNode,
    theme.palette.error.main,
    theme.palette.systemMap.main,
    theme.palette.warning.main,
  ]);
};
