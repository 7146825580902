import { FormField, IFormControlProps, IFormTypedProps } from '@marlin/shared/ui-form-common';
import { ControllerProps, FieldValues } from 'react-hook-form';

import { LocationTypeSelect } from './location-type-select';

export function LocationTypeControl<TFieldValues extends FieldValues>(
  props: IFormControlProps<TFieldValues>
): JSX.Element;

export function LocationTypeControl<
  TFieldValues extends FieldValues = object,
  TName extends ControllerProps<TFieldValues>['name'] = ControllerProps<TFieldValues>['name']
>(props: IFormTypedProps<TFieldValues, TName>): JSX.Element;

export function LocationTypeControl<
  TFieldValues extends FieldValues = object,
  TName extends ControllerProps<TFieldValues>['name'] = ControllerProps<TFieldValues>['name']
>(params: IFormControlProps<TFieldValues> | IFormTypedProps<TFieldValues, TName>) {
  if (params.control) {
    return <FormField {...params}>{(props) => <LocationTypeSelect {...props} />}</FormField>;
  }

  return <FormField<TFieldValues> {...params}>{(props) => <LocationTypeSelect {...props} />}</FormField>;
}
