import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  card: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    padding: theme.typography.pxToRem(0),
    borderBottom: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
    maxHeight: theme.typography.pxToRem(120),
    width: '100%',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: theme.typography.pxToRem(16),
      maxHeight: 'unset',
    },
  },

  criticality: {
    padding: `0px ${theme.typography.pxToRem(16)}`,
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },

  text: {
    width: `calc( 100% - ${theme.typography.pxToRem(218)} );`,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold,
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '& > p': {
      paddingLeft: 0,
      marginTop: 0,
      marginBottom: 0,
      whiteSpace: 'break-spaces',
      wordBreak: 'break-word',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      overflow: 'hidden',
      marginTop: 0,
      marginBottom: 0,
      '& > p': {
        paddingLeft: 0,
        marginTop: theme.typography.pxToRem(12),
        marginBottom: 0,
      },
    },
  },

  time: {
    width: theme.typography.pxToRem(132),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightRegular,
    paddingLeft: theme.typography.pxToRem(16),
    paddingRight: theme.typography.pxToRem(24),
    [theme.breakpoints.down('md')]: {
      width: '100%',
      paddingLeft: 0,
      display: 'inline-flex',
      alignItems: 'center',
    },
  },

  timeText: {
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    [theme.breakpoints.down('md')]: {
      '& > p': {
        paddingLeft: 0,
        marginTop: 0,
        marginBottom: 0,
      },
    },
  },

  divider: {
    marginTop: theme.typography.pxToRem(38),
    marginBottom: theme.typography.pxToRem(38),
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
      marginBottom: 0,
    },
  },

  timeValue: {
    color: theme.palette.text.primary,
    marginTop: theme.typography.pxToRem(-10),
    [theme.breakpoints.down('md')]: {
      color: theme.palette.text.secondary,
      marginTop: 0,
      marginBottom: 0,

      '&::before': {
        content: '"\xa0"', // NOTE: Space separator
      },
    },
  },
}));
