import { TLocationSchema, useFilteredLocationsWithLoadMore } from '@marlin/asset/data-access/location';
import { IBaseFilters } from '@marlin/asset/shared/ui/mobile-filter-modal';
import { useMemo } from 'react';

import { IOption } from '../shared/types';
import { TLocationSort } from './sorting';

const defaultParams = {
  pageSize: 10,
};

export interface ILocationsHubFilters extends IBaseFilters<TLocationSort> {
  parentLocationIds: IOption[];
}

export const useLocationsHub = (filters: ILocationsHubFilters) => {
  const params = useMemo(
    () => ({
      params: { ...defaultParams, ...filters, parentLocationIds: filters.parentLocationIds.map(({ id }) => id) },
    }),
    [filters]
  );
  const locationsQuery = useFilteredLocationsWithLoadMore(params);

  const rows: TLocationSchema[] = useMemo(() => {
    const unfilteredRows: (TLocationSchema | null)[] = locationsQuery.data?.pages?.flatMap((i) => i.data) || [];
    return unfilteredRows.filter((i: TLocationSchema | null): i is TLocationSchema => i !== null);
  }, [locationsQuery.data?.pages]);

  return {
    rows,
    totalItems: locationsQuery.data?.pages[0].pagination.totalItems || 0,
    hasNextPage: locationsQuery.hasNextPage,
    fetchNextPage: locationsQuery.fetchNextPage,
    isLoading: locationsQuery.isLoading,
    isError: locationsQuery.isError,
  };
};
