import { content } from './content';
import { IGlobalEquipmentConfig } from './model';

export const globalConfigAerco: IGlobalEquipmentConfig = {
  dashboard: {
    liveDataExpirationSeconds: 720,
  },
  calculationValues: false,
  chart: [
    {
      name: 'temperature',
      label: content.temperature,
      type: 'rangeArea',
    },
    {
      name: 'other',
      label: content.other,
      type: 'rangeArea',
      hideUomOnAxis: true,
    },
  ],
};
