import { useObservable, useObservableState, useSubscription } from 'observable-hooks';

import { getSystemLoopNotificationSubject, initSubject } from '../infrastructure/get-loop-detected-notification';
import { TFlowLoopNotification } from '../loop-detected-notification.model.schema';

export const useInitFlowLoopNotificationSubject = () => {
  return useObservableState(initSubject);
};

type TOnFlowLoopNotification = (flowLoopNotification: TFlowLoopNotification | undefined) => void;

export const useFlowLoopSubscription = (onLoopNotification: TOnFlowLoopNotification) => {
  const flowLoop$ = useObservable(getSystemLoopNotificationSubject);

  return useSubscription<TFlowLoopNotification | undefined>(
    flowLoop$,
    (notification: TFlowLoopNotification | undefined) => {
      onLoopNotification(notification);

      // Note: this may cause a problem when we will subscribe to this subject in multiple places
      if (notification) {
        getSystemLoopNotificationSubject().next(undefined);
      }
    }
  );
};
