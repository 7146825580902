import { TExtractFnReturnType, useQuery } from '@marlin/shared/utils/react-query';

import { startSendData, stopSendData } from '../infrastructure/equipment';
import { queryKey } from './query-key.enum';

type TStartSendingDataQueryFnType = typeof startSendData;
type TStopSendingDataQueryFnType = typeof stopSendData;

interface IUseEquipmentsProps {
  equipmentId: string;
  onError?: () => void;
  enabled?: boolean;
}

export const useStartSendingData = ({ equipmentId, enabled, onError }: Required<IUseEquipmentsProps>) => {
  return useQuery<TExtractFnReturnType<TStartSendingDataQueryFnType>>({
    queryKey: queryKey.START_SEND_DATA(equipmentId),
    queryFn: () => startSendData(equipmentId),
    enabled: Boolean(equipmentId) && enabled,
    onError,
  });
};

export const useStopSendingData = ({ equipmentId }: IUseEquipmentsProps) => {
  return useQuery<TExtractFnReturnType<TStopSendingDataQueryFnType>>({
    queryKey: queryKey.STOP_SEND_DATA(equipmentId),
    queryFn: () => stopSendData(equipmentId),
    enabled: false,
  });
};
