import { useUpdateOrgSettings } from '@marlin/account-data-access-organization';
import { useWaterSummary } from '@marlin/asset/data-access/home-dashboard';
import { CustomSizedModal } from '@marlin/shared/ui-modal';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { Button } from '@mui/material';
import { SyntheticEvent, useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { content } from './content';
import { ModalBody } from './select-flow-meter-modal.body.component';
import { useStyles } from './select-flow-meter-modal.styles';
import { IFlowMeter, IFlowMeterFormValues } from './types';

interface IDeleteModalProps {
  open: boolean;
  onClose: () => void;
}

export const SelectFlowMeterModal = ({ open, onClose }: IDeleteModalProps) => {
  const { classes } = useStyles();
  const { data } = useWaterSummary();
  const { mutateAsync: updateFlowMeter } = useUpdateOrgSettings();

  const { enqueueSnackbar } = useSnackbar();

  const selectedFlowMeters: IFlowMeter[] | undefined = useMemo(() => {
    return data?.devices.map((device) => ({
      id: device.deviceId || '',
      name: device.deviceName || '',
      locationName: device.locationName || '',
    }));
  }, [data]);

  const form = useForm<IFlowMeterFormValues>({
    defaultValues: { flowMeterIds: selectedFlowMeters || [] },
  });

  const updatedFlowMeters = form.watch('flowMeterIds');

  const submitForm = useCallback(
    (values: IFlowMeterFormValues) =>
      updateFlowMeter({
        defaultFlowMeters: values.flowMeterIds.map((f) => f.id) || [],
      })
        .then(() => {
          enqueueSnackbar(content.CHANGE_FLOW_METER_SUCCESS, {
            variant: 'success',
            preventDuplicate: true,
          });
          onClose();
        })
        .catch(() => {
          enqueueSnackbar(content.CHANGE_FLOW_METER_ERROR, {
            variant: 'error',
            preventDuplicate: true,
          });
        }),
    [enqueueSnackbar, onClose, updateFlowMeter]
  );

  const handleChange = (event: SyntheticEvent, value: IFlowMeter[]) => {
    form.setValue('flowMeterIds', value);
  };

  if (!open) {
    return null;
  }

  return (
    <FormProvider {...form}>
      <CustomSizedModal
        onClose={onClose}
        width={600}
        title={content.TITLE}
        body={<ModalBody onChane={handleChange} selectedFlowMeters={updatedFlowMeters} submitForm={submitForm} />}
        footer={
          <div className={classes.buttons} data-testid="choose-flow-meter-modal-footer">
            <Button variant="text" onClick={onClose} data-testid="cancel-button">
              {content.CANCEL}
            </Button>
            <Button variant="contained" onClick={form.handleSubmit(submitForm)} data-testid="save-button">
              {content.SAVE}
            </Button>
          </div>
        }
      />
    </FormProvider>
  );
};
