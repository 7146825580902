import { Checkbox, FormControlLabel, FormHelperText, Icon, MenuItem } from '@mui/material';
import debounce from 'lodash/debounce';
import React, { ReactNode, useCallback, useMemo } from 'react';
import { ControllerRenderProps, FieldError } from 'react-hook-form';
import { Virtuoso } from 'react-virtuoso';

import { content } from '../content';
import { MenuItemSelect } from '../menu-item-select/menu-item-select.component';
import { RoleListSelect } from '../role-list-select/role-list-select.component';
import { availableRoles } from '../role/organization-role-options';
import { AlreadyExistsMessage } from './already-exists-message.component';
import { useMultiRoleSelect } from './use-multi-role-select.hook';

type TRenderProps = Partial<Omit<ControllerRenderProps, 'ref'>>;

interface ISelectControlProps extends TRenderProps {
  error?: FieldError;
  disabled?: boolean;
  icon?: ReactNode;
  title?: ReactNode;
  fetchNextPage?: () => void;
  hasNextPage?: boolean;
}

const itemSize = 60;
const listHeight = 270;
const virtualizedListOffset = 150;

const roleOptions = availableRoles.map((role) => (
  <MenuItem key={role.value} value={role.value}>
    <div>
      <span>{role.name}</span>
      <FormHelperText>{role.helperText}</FormHelperText>
    </div>
  </MenuItem>
));

export const MultiRoleSelect = ({
  icon = <Icon baseClassName="material-symbols-outlined">location_home</Icon>,
  title,
  fetchNextPage,
  hasNextPage,
  ...props
}: ISelectControlProps) => {
  const { value, onChange, ...field } = props;

  const {
    isAnyRoleAlreadyExists,
    isSetRoleIndividuallyEnabled,
    allOrgRole,
    onSetRoleIndividuallyEnabledChange,
    onAllOrgRoleChange,
    onChangeFactory,
  } = useMultiRoleSelect({ value, onChange });

  const debouncedFetchNextPage = useMemo(
    () => (fetchNextPage ? debounce(fetchNextPage, 500) : undefined),
    [fetchNextPage]
  );

  const renderItem = useCallback(
    (index: number) => {
      const organization = props.value[index];

      return (
        <div>
          <RoleListSelect
            title={organization.name}
            disabled={!!organization.alreadyExists}
            message={organization.alreadyExists ? <AlreadyExistsMessage /> : undefined}
          >
            <MenuItemSelect
              {...field}
              value={organization.role}
              onChange={onChangeFactory(organization.id)}
              disabled={!!organization.alreadyExists}
            >
              {roleOptions}
            </MenuItemSelect>
          </RoleListSelect>
        </div>
      );
    },
    [field, onChangeFactory, props.value]
  );

  const loadNextPage = useCallback(() => {
    if (debouncedFetchNextPage) {
      if (hasNextPage) {
        debouncedFetchNextPage();
      }
    }
  }, [debouncedFetchNextPage, hasNextPage]);

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={isSetRoleIndividuallyEnabled || isAnyRoleAlreadyExists}
            onChange={onSetRoleIndividuallyEnabledChange}
            defaultChecked={true}
          />
        }
        label={content.SET_ROLE_INDIVIDUALLY}
        disabled={isAnyRoleAlreadyExists}
      />

      {!isSetRoleIndividuallyEnabled && (
        <RoleListSelect icon={icon} title={content.ALL_ORGANIZATIONS} disabled={isSetRoleIndividuallyEnabled}>
          <MenuItemSelect value={allOrgRole} disabled={isSetRoleIndividuallyEnabled} onChange={onAllOrgRoleChange}>
            {roleOptions}
          </MenuItemSelect>
        </RoleListSelect>
      )}

      {isSetRoleIndividuallyEnabled && (
        <Virtuoso
          style={{ height: `${Math.min(props.value.length * itemSize, listHeight)}px`, width: '100%' }}
          totalCount={props.value.length}
          itemContent={renderItem}
          increaseViewportBy={virtualizedListOffset}
          endReached={loadNextPage}
        />
      )}
    </>
  );
};
