export interface ISettingsSelectOptions<TValue> {
  id: TValue;
  value: TValue;
  name: string;
}

export type TSettingsOptions<TValue extends string> = Record<TValue, ISettingsSelectOptions<TValue>>;

export const useOptions = <TValue extends string>(
  options: TSettingsOptions<TValue>
): { selectOptions: ISettingsSelectOptions<TValue>[] } => {
  return {
    selectOptions: Object.values(options),
  };
};
