import { BaseSyntheticEvent, PropsWithChildren, createContext, useContext } from 'react';
import { FormProvider, UseFormProps, useForm } from 'react-hook-form';

import { IBaseFilters, IMobileFilterFormProps, defaultSorting } from './model';

export interface IMobileFilterFormContext<TSort extends string, TFilters extends IBaseFilters<TSort>>
  extends IMobileFilterFormProps<TSort, TFilters> {
  submit: (event?: BaseSyntheticEvent) => Promise<unknown>;
}

const defaultValues: IMobileFilterFormContext<string, IBaseFilters<string>> = {
  filters: {
    sorting: {
      sortBy: '',
      direction: 'Descending',
    },
  },
  open: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleOpen: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  clearFilters: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setFilters: () => {},
  isAnyFilterSet: false,
  sortList: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  submit: async () => {},
};

const MobileFilterFormContext = createContext<unknown>(defaultValues);

export const useMobileFilterFormContext = <
  TSort extends string,
  TFilters extends IBaseFilters<TSort>
>(): IMobileFilterFormContext<TSort, TFilters> =>
  useContext(MobileFilterFormContext) as IMobileFilterFormContext<TSort, TFilters>;

export const MobileFilterFormProvider = <TSort extends string, TFilters extends IBaseFilters<TSort>>({
  children,
  ...mobileFilterForm
}: IMobileFilterFormProps<TSort, TFilters> & PropsWithChildren) => {
  const { filters, toggleOpen, setFilters } = mobileFilterForm;

  const form = useForm<TFilters>({
    defaultValues: {
      ...filters,
      sorting: filters.sorting || defaultSorting,
    } as UseFormProps<TFilters>['defaultValues'],
  });

  const submit = form.handleSubmit(async (values) => {
    const newFilters = values as TFilters;
    if (!newFilters.sorting || newFilters.sorting.sortBy === '') {
      delete newFilters.sorting;
    }
    const data = await setFilters(newFilters);
    toggleOpen();
    return data;
  });

  return (
    <MobileFilterFormContext.Provider value={{ submit, ...mobileFilterForm }}>
      <FormProvider {...form}>{children}</FormProvider>
    </MobileFilterFormContext.Provider>
  );
};
