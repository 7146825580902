import { TFlowLink } from '@marlin/asset/data-access/asset';
import { TAssetType, TDeviceType } from '@marlin/system-map/shared/data-access-schemas';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';

import { AssetLink } from './attachment-link.component';
import { useStyles } from './table.styles';

interface IAttachmentsTableProps {
  data?: TFlowLink[];
  title: string;
  assetId?: string;
}

interface IGetAssetDataProps {
  id: string | null;
  type?: TAssetType | null;
  name: string | null;
  deviceType?: TDeviceType | null;
  isTee?: boolean;
  linkedId?: string | null;
}

const getAssetData = (link: TFlowLink, assetId: string): IGetAssetDataProps => {
  if (link.inletNodeType === 'TEE' || link.outletNodeType === 'TEE') {
    if (link.inletAssetId === assetId) {
      return {
        id: link.outletId ?? '',
        name: link.outletName ?? '',
        isTee: true,
        linkedId: link.outletLinkedId,
      };
    }
    return {
      id: link.inletId ?? '',
      name: link.inletName ?? '',
      isTee: true,
      linkedId: link.inletLinkedId,
    };
  }

  if (link.inletAssetId === assetId) {
    return {
      id: link.outletAssetId ?? '',
      name: link.outletAssetName ?? '',
      type: link.outletAssetType,
      deviceType: link.outletDeviceType,
      linkedId: link.outletLinkedId,
    };
  }
  return {
    id: link.inletAssetId ?? '',
    name: link.inletAssetName ?? '',
    type: link.inletAssetType,
    deviceType: link.inletDeviceType,
    linkedId: link.inletLinkedId,
  };
};

export const AttachmentsTable = ({ data, title, assetId }: IAttachmentsTableProps) => {
  const { classes } = useStyles();

  return (
    <Table aria-label="simple table">
      <TableHead className={classes.tableHead}>
        <div className={classes.textWrapper}>
          <Typography className={classes.tableTitle}>{title}</Typography>
        </div>
      </TableHead>
      <TableBody>
        <div className={classes.bodyWrapper}>
          {data?.map((link) => {
            return (
              <TableRow className={classes.row} key={link.id}>
                <TableCell className={classes.cell} component="td" scope="row">
                  <AssetLink asset={getAssetData(link, assetId ?? '')} />
                </TableCell>
              </TableRow>
            );
          })}
        </div>
      </TableBody>
    </Table>
  );
};
