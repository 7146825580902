import { TDependencies } from '@marlin/asset/shared/equipment-config';
import { HeaderDatapoint } from '@marlin/asset/shared/ui/datapoint-display';
import { MarlinTheme } from '@marlin/shared/theme';
import { TDatapointWithUiMetadata } from '@marlin/shared/utils/datapoint-mappers';
import { memo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useDatapointsContext } from '../../../../../shared/context/datapoints.context';
import { useEquipmentAlertsContext } from '../../../../../shared/context/equipment-alerts.context';
import { getDatapointWithDependencies } from '../../../../../shared/utils/get-datapoint-with-dependencies.utils';
import { content } from '../../../../content';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    display: 'grid',
    gridTemplateColumns: `repeat(2, minmax(auto, ${theme.typography.pxToRem(128)}))`,
    gridTemplateRows: `repeat(2, 1fr)`,
    gap: theme.typography.pxToRem(8),
    width: '100%',
    margin: 0,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: `repeat(1, 1fr)`,
    },
  },
  tileOverride: {
    // override default HeaderDatapoint styles
    '& > div, & > span': {
      minWidth: 'unset',
    },
    '& > span': {
      padding: 0,
    },
  },
}));

interface IEquipmentProductDetailsDatapointTilesProps {
  datapoints: Array<TDatapointWithUiMetadata & { dependencies?: TDependencies }>;
  isLoading?: boolean;
}

export const EquipmentProductDetailsDatapointTiles = memo(
  ({ datapoints, isLoading }: IEquipmentProductDetailsDatapointTilesProps) => {
    const { classes, cx } = useStyles();
    const { getAlertsForDatapoint } = useEquipmentAlertsContext();
    const { getDatapoint } = useDatapointsContext();
    const mappedDatapoints = datapoints
      .map((datapoint) => {
        return getDatapointWithDependencies({
          getDatapoint,
          dependencies: datapoint.dependencies,
          datapoint: datapoint,
        });
      })
      .filter(Boolean);

    return (
      <div data-testid="equipment-details-datapoint-tiles" className={cx(classes.wrapper, classes.tileOverride)}>
        {mappedDatapoints.map((datapointItem) => {
          return (
            <HeaderDatapoint
              key={datapointItem?.name}
              label={datapointItem?.label}
              value={datapointItem?.displayedValue ?? content.EMPTY_DATAPOINT_VALUE}
              controlState={datapointItem?.controlState}
              isLoading={isLoading}
              datapointAlerts={getAlertsForDatapoint(datapointItem?.name ?? '')}
              withSpacings={false}
              unitOfMeasure={datapointItem?.unitOfMeasure}
            />
          );
        })}
      </div>
    );
  }
);
