import { getPlacesService, placesFields } from '@marlin/shared/utils/maps-api';
import { TOrgAddress, orgAddressSchema } from '@marlin/shared/utils/zod';
import { useCallback, useEffect, useState } from 'react';

import { mapAddressComponents } from './address-components.utils';

export const usePlaceDetails = (
  sessionToken: google.maps.places.AutocompleteSessionToken | undefined,
  value: google.maps.places.AutocompletePrediction | null,
  cancelSessionToken: () => void,
  setEditable?: (editable: boolean) => void
) => {
  const [address, setAddress] = useState<TOrgAddress | null>(null);
  const [name, setName] = useState<string | null>(null);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (!getPlacesService() || !sessionToken) {
      return;
    }
    if (!value) {
      setAddress(null);
      return;
    }

    if (value.place_id === undefined || value.place_id === '') {
      return;
    }

    getPlacesService().getDetails(
      {
        placeId: value.place_id,
        fields: placesFields,
        sessionToken,
      },
      (place, status) => {
        if (status === 'OK' && place) {
          const geometry = JSON.parse(JSON.stringify(place.geometry?.location));

          const validatedAddress = orgAddressSchema.safeParse({
            ...mapAddressComponents(place.address_components || []),
            latitude: geometry?.lat,
            longitude: geometry?.lng,
          });
          if (validatedAddress.success) {
            setError(false);
            setAddress(validatedAddress.data);
            setName(place.name || null);
            cancelSessionToken();
            setEditable?.(false);
          } else {
            setError(true);
          }
        }
      }
    );
  }, [cancelSessionToken, sessionToken, value, setEditable]);

  const clearAddress = useCallback(() => {
    setAddress(null);
    setName(null);
  }, []);

  const setData = useCallback((data: { address?: TOrgAddress | null; name?: string | null }) => {
    setAddress(data.address || null);
    setName(data.name || null);
  }, []);

  return { address, name, clearAddress, setData, error };
};
