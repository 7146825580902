import { createStoreContext, useContext } from '@marlin/shared/utils-component-context';
import { FC } from 'react';

import { ISensorFiltering } from '../types';

const {
  useStore: useSensorFilteringStore,
  Provider: SensorFilteringProvider,
  StoreContext: SensorFilteringContext,
} = createStoreContext<ISensorFiltering>({
  selectedChartType: '',
  selectedLocationId: '',
  selectedEquipmentId: '',
});

export function useSetSensorFiltering() {
  const context = useContext(SensorFilteringContext);
  return context.set;
}

export function withSensorFilteringContext(Component: FC) {
  return () => (
    <SensorFilteringProvider>
      <Component />
    </SensorFilteringProvider>
  );
}

export { useSensorFilteringStore };
