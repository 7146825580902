import { MarlinTheme } from '@marlin/shared/theme';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    alignItems: 'center',
  },

  title: {
    fontWeight: theme.typography.fontWeightBold,
    marginTop: 0,
    marginBottom: theme.typography.pxToRem(8),
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(26),
    },
  },
}));

interface ITitleSectionProps {
  title: string;
  icon: JSX.Element;
}

export function TitleSection({ title, icon }: ITitleSectionProps) {
  const { classes } = useStyles();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={1}>
        {icon}
      </Grid>
      <Grid item xs={11}>
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
}
