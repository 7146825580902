import {
  TCalibrationParamsSchema,
  TSubtype,
  useLatestProcessedCalibration,
  useLatestSavedCalibration,
} from '@marlin/asset/data-access/device';
import { MarlinTheme } from '@marlin/shared/theme';
import { Input } from '@marlin/shared/ui-form-common';
import { defaultDateTime, formatDateByTimezone } from '@marlin/shared/utils-common-date';
import { symbolPerPulse } from '@marlin/shared/utils-format-reading';
import SaveIcon from '@mui/icons-material/Save';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { Button, FormControl, FormHelperText, InputLabel } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';
import { channelId, useCalibration } from './use-calibration.hook';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  inputRow: {
    gridColumn: '1 / -1',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.typography.pxToRem(16),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: theme.typography.pxToRem(16),
    },
  },
  warningRow: {
    display: 'flex',
    gap: theme.typography.pxToRem(8),
  },
  nestedInputRow: {
    gridColumn: '1 / -1',
  },
  calibrateButton: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  warningIcon: {
    marginTop: theme.typography.pxToRem(4),
  },
  endAdornment: {
    marginRight: theme.typography.pxToRem(16),
  },
  modal: {
    width: theme.typography.pxToRem(824),
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxHeight: '100%',
      overflowY: 'auto',
    },
  },
  multiplierLabel: {
    marginBottom: `-${theme.typography.pxToRem(8)}`,
  },
  channelSection: {
    display: 'flex',
    width: '100%',
    gap: theme.typography.pxToRem(16),
    alignItems: 'center',
  },
}));

interface IMeterMultiplierProps {
  subtype: TSubtype;
  childrenIds: string[];
  timezoneName: string;
  isCompound?: boolean;
  index: number;
  manufacturerId: string;
}

export const MeterMultiplier = ({
  subtype,
  index,
  manufacturerId,
  timezoneName,
  childrenIds,
  isCompound,
}: IMeterMultiplierProps) => {
  const { classes } = useStyles();
  const { handleCalibrationButtonPress, calibrationValue, setCalibrationValue } = useCalibration({
    manufacturerId,
    timezoneName,
    modalClassName: classes.modal,
  });

  const { data: savedCalibration } = useLatestSavedCalibration({
    manufacturerId: childrenIds[index] ?? manufacturerId,
  });

  const { data: processedCalibration } = useLatestProcessedCalibration({
    manufacturerId: childrenIds[index] ?? manufacturerId,
  });

  const defaultValues: TCalibrationParamsSchema | undefined = useMemo(() => {
    if (savedCalibration) {
      return savedCalibration;
    } else if (processedCalibration) {
      return processedCalibration;
    }
    return undefined;
  }, [processedCalibration, savedCalibration]);

  const endAdornment = useCallback(
    (subtype: TSubtype) => (
      <span className={classes.endAdornment}>
        {calibrationValue
          ? symbolPerPulse(calibrationValue?.uom ?? '')
          : subtype?.uoM
          ? symbolPerPulse(subtype?.uoM)
          : ''}
      </span>
    ),
    [calibrationValue, classes.endAdornment]
  );

  useEffect(() => {
    if (processedCalibration) {
      setCalibrationValue({
        value: processedCalibration.unitsPerPulse ?? undefined,
        timestamp: processedCalibration.calculationTimestamp ?? undefined,
        uom: processedCalibration.measurementUnit,
      });
    }
  }, [setCalibrationValue, processedCalibration, subtype.id]);

  return (
    <div className={classes.nestedInputRow}>
      <div key={subtype.id} className={classes.inputRow}>
        <div className={classes.channelSection}>
          {isCompound && <span>{channelId(manufacturerId, childrenIds[index])}</span>}
          <FormControl fullWidth>
            <InputLabel shrink={!!subtype?.unitsPerPulse} id={'meter-multiplier'}>
              {content.METER_MULTIPLIER_LABEL}
            </InputLabel>
            <Input
              id={'meter-multiplier'}
              label={content.METER_MULTIPLIER_LABEL}
              value={calibrationValue?.value ?? subtype?.unitsPerPulse ?? ''}
              disabled
              fullWidth
              externalEndAdornment={{
                endAdornment: endAdornment(subtype),
              }}
            />
          </FormControl>
        </div>
        <div className={classes.calibrateButton}>
          <Button
            size="small"
            variant="outlined"
            color={calibrationValue ? 'primary' : 'warning'}
            onClick={() =>
              handleCalibrationButtonPress(subtype.id, childrenIds[index] ?? manufacturerId, defaultValues)
            }
            endIcon={savedCalibration ? <SaveIcon /> : undefined}
          >
            {calibrationValue ? content.RECALIBRATE : content.CALIBRATE}
          </Button>
          {calibrationValue && (
            <FormHelperText>
              {content.CALIBRATED_AT(
                formatDateByTimezone(calibrationValue?.timestamp ?? '', timezoneName).format(defaultDateTime)
              )}
            </FormHelperText>
          )}
        </div>
      </div>
      {!calibrationValue && (
        <div className={classes.warningRow}>
          <WarningRoundedIcon color="warning" className={classes.warningIcon} fontSize="small" />
          <FormHelperText>{content.CALIBRATION_WARNING}</FormHelperText>
        </div>
      )}
    </div>
  );
};
