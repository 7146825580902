import { z } from 'zod';

export const feedbackCategorySchema = z.union([
  z.literal('Sensor Issue'),
  z.literal('Software Issue'),
  z.literal('Sales Question'),
  z.literal('New Feature Request'),
  z.literal('Other Question'),
  z.literal(''),
]);

export const exportFeedbackPayload = z.object({
  category: feedbackCategorySchema,
  description: z.string(),
});

const UserSchema = z.object({
  firstName: z.string().nullish(),
  lastName: z.string().nullish(),
  email: z.string().nullish(),
  title: z.string().nullish(),
  organizationName: z.string().nullish(),
});

export const exportFeedbackResponseSchema = z.object({
  emailType: z.string(),
  sender: UserSchema,
  recipients: z.array(UserSchema).optional(),
});

export type TExportFeedbackPayload = z.infer<typeof exportFeedbackPayload>;
export type TExportFeedbackResponseSchema = z.infer<typeof exportFeedbackResponseSchema>;
