import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.typography.pxToRem(8),
    alignSelf: 'stretch',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.typography.pxToRem(16),
    padding: theme.typography.pxToRem(8),
  },
  dot: {
    height: theme.typography.pxToRem(8),
    width: theme.typography.pxToRem(8),
    color: theme.palette.primary.main,
    borderRadius: '50%',
  },
  dotText: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.text.primary,
    lineHeight: '133%',
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },
}));
