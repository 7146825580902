import { LoadingSpinner } from '@marlin/shared/ui-loader';

import { useDeviceCurrentAlerts } from '../../hooks/use-device-current-alerts.hook';
import { Chart } from '../readings/charts/chart.component';
import { ChartRangeContextProvider } from '../readings/context/chart-range.context';
import { useRange } from '../readings/hooks/use-range.hook';
import { useReadings } from '../readings/hooks/use-readings.hook';
import { ReadingsHeader } from '../readings/readings/readings-header.component';
import { useStyles } from './readings-tab-mobile.styles';

export const ReadingsTabMobile = () => {
  const { classes } = useStyles();
  const rangeControl = useRange();

  const {
    isLoading,
    isLeakUnitOfMeasure,
    recentReading,
    lowReading,
    highReading,
    refreshData,

    telemetry,
  } = useReadings({ range: rangeControl.range });
  const { isAlert, communicationLostAlert } = useDeviceCurrentAlerts();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className={classes.container}>
      <ChartRangeContextProvider value={rangeControl}>
        <ReadingsHeader
          isExtended={false}
          refreshData={refreshData}
          isLoading={isLoading}
          isAlert={isAlert}
          recentReading={recentReading}
          lowReading={lowReading}
          highReading={highReading}
          isLeakUnitOfMeasure={isLeakUnitOfMeasure}
          communicationLostAlert={communicationLostAlert}
        />
        <Chart telemetry={telemetry} />
      </ChartRangeContextProvider>
    </div>
  );
};
