import { TEquipmentSchema } from '@marlin/asset/data-access/equipment';
import { ISortParams } from '@marlin/asset/shared/ui/mobile-filter-modal';

import { content } from '../content';

export type TEquipmentSort = keyof TEquipmentSchema;

export const sortList: ISortParams<TEquipmentSort>[] = [
  {
    id: 'name',
    name: content.EQUIPMENT_SORTING_NAME,
  },
  {
    id: 'locationName',
    name: content.LOCATION_SORTING_NAME,
  },
];
