import { TAutomation } from '@marlin/alert/data-access/automated-action';
import { AutomationCard } from '@marlin/alert/shared/ui/automation-card';
import { MarlinTheme } from '@marlin/shared/theme';
import { DataLoader } from '@marlin/shared/ui-loader';
import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';
import { IExtendedAutomation } from './types';

interface IAutomatedActionListProps extends Record<string, unknown> {
  isLoading: boolean;
  isError: boolean;
  emptyState: boolean;
  data?: IExtendedAutomation[];
  onAutomationEdit: (id: string) => void;
  onAutomationToggle: (item: TAutomation) => void;
  onAutomationDelete: (automation: IExtendedAutomation) => void;
  allowEdit?: boolean;
  allowDelete?: boolean;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    overflowWrap: 'break-word',
  },

  emptyState: {
    height: theme.typography.pxToRem(400),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export function AutomatedActionList({
  emptyState,
  data,
  isLoading,
  isError,
  onAutomationEdit,
  onAutomationToggle,
  onAutomationDelete,
  allowEdit,
  allowDelete,
  featureFlags,
}: IAutomatedActionListProps) {
  const { classes } = useStyles();

  if (isError) {
    return (
      <div className={classes.emptyState} data-testid="automations-page-error">
        {content.ERROR}
      </div>
    );
  }

  if (isLoading) {
    return <DataLoader />;
  }

  if (emptyState || data?.length === 0) {
    return (
      <div className={classes.emptyState} data-testid="automations-page-empty">
        {content.NO_DATA}
      </div>
    );
  }

  return (
    <div data-testid="automation-list-container" className={classes.container}>
      <Grid container>
        {data?.map((item: IExtendedAutomation, index: number) => (
          <AutomationCard
            item={item}
            key={item.id}
            onItemEdit={onAutomationEdit}
            onItemToggle={onAutomationToggle}
            onItemDelete={onAutomationDelete}
            allowDelete={allowDelete}
            allowEdit={allowEdit}
            featureFlags={featureFlags}
          />
        ))}
      </Grid>
    </div>
  );
}
