import { TExtractFnReturnType, useQuery } from '@marlin/shared/utils/react-query';

import { TFlowLinkFilterParams } from '../flow-link.model.schema';
import { getFlowLinks } from '../infrastructure/flow-link';
import { queryKey } from './query-key.enum';

type TQueryFnType = typeof getFlowLinks;

interface IFlowLinksProps {
  queryParams: TFlowLinkFilterParams;
}

export const useFlowLinks = ({ queryParams }: IFlowLinksProps) => {
  return useQuery<TExtractFnReturnType<TQueryFnType>>({
    queryKey: queryKey.FLOW_LINKS(queryParams),
    queryFn: () => getFlowLinks(queryParams),
  });
};
