import { useInvalidateRangeTelemetry } from '@marlin/asset/data-access/telemetry';
import { MarlinTheme } from '@marlin/shared/theme';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { ChipGroup, IChip } from '@marlin/shared/ui-form';
import { FormField, useSubmitOnChange } from '@marlin/shared/ui-form-common';
import { Button, FormControlLabel, Grid, SelectChangeEvent, Switch, Typography } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../../content';
import { ManageAutomations } from '../../../manage-automations/manage-automations.component';
import { RANGE_FILTER } from '../../constant';
import { useExtendedChartContext } from './context/extended-chart.context';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  form: {
    marginTop: theme.typography.pxToRem(8),
  },
  label: {
    marginLeft: theme.typography.pxToRem(8), // TODO: remove later?
    marginRight: theme.typography.pxToRem(16),
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
  },
  switch: {
    '&.Mui-disabled': {
      pointerEvents: 'all',
    },
    '&.Mui-disabled:hover': {
      backgroundColor: 'transparent',
    },
  },
  // NOTE: Leaving space for the apex charts toolbar
  chips: {
    minHeight: theme.typography.pxToRem(32),
    marginRight: theme.typography.pxToRem(120),
  },
}));

export interface IAnnotationForm {
  automations: string[];
  trigger: boolean;
  alerts: boolean;
  deadband: boolean;
}

export const defaultValues: IAnnotationForm = {
  automations: [],
  trigger: true,
  alerts: true,
  deadband: true,
};

export const ExtendedChartForm = (): JSX.Element => {
  const { classes } = useStyles();
  const {
    formDefaultValues,
    automations,
    setAutomationsSettings: onSubmit,
    setCustomRangeFilter,
    onRangeFilterChange,
  } = useExtendedChartContext();

  const invalidate = useInvalidateRangeTelemetry();
  const initialValues = useMemo(() => formDefaultValues || defaultValues, [formDefaultValues]);

  const automationsExists = useMemo(() => automations.length > 0, [automations]);
  const automationChips: IChip<string>[] = useMemo(
    () =>
      automations.map((automation) => ({
        label: automation.name,
        value: automation.id,
        avatar: automation.label,
        state: automation.criticality === 'HIGH' ? 'error' : 'warning',
      })),
    [automations]
  );

  const form = useForm<IAnnotationForm>({
    defaultValues: initialValues,
    mode: 'onChange',
    resetOptions: {
      keepDirty: true,
    },
  });
  const selectedAutomations = form.watch('automations');

  const deadbandExists = useMemo(() => {
    return automations
      .filter((automation) => automation.conditions.deadbandMax || automation.conditions.deadbandMin)
      .some((automation) => selectedAutomations.includes(automation.id));
  }, [selectedAutomations, automations]);

  const conditionsExists = useMemo(() => {
    return automations
      .filter((automation) => automation.conditions.thresholdMax || automation.conditions.thresholdMin)
      .some((automation) => selectedAutomations.includes(automation.id));
  }, [selectedAutomations, automations]);

  const { submit } = useSubmitOnChange<IAnnotationForm>(form, onSubmit, 200);

  const handleResetButtonClick = () => {
    form.reset(initialValues);
    if (onRangeFilterChange && setCustomRangeFilter) {
      setCustomRangeFilter(RANGE_FILTER.HOURS_8);
      onRangeFilterChange({ target: { value: RANGE_FILTER.HOURS_8 } } as SelectChangeEvent<RANGE_FILTER>);
      invalidate();
    }
    return submit();
  };

  const handleClearAllButtonClick = () => {
    form.reset({
      automations: [],
      trigger: false,
      alerts: false,
      deadband: false,
    });
    return submit();
  };

  const checkIfDisableTrigger = (name: string, value: boolean) => {
    if (name === 'trigger' && value === false) {
      form.setValue('alerts', false);
      form.setValue('deadband', false);
    } else if (
      (name === 'alerts' || name === 'deadband') &&
      form.getValues('alerts') === false &&
      form.getValues('deadband') === false
    ) {
      form.setValue('trigger', false);
    }
  };

  useEffect(() => {
    form.reset(initialValues);
  }, [initialValues, form, form.reset]);

  return (
    <FormProvider {...form}>
      <form onSubmit={submit}>
        <Grid container justifyContent="space-between" alignItems="center" className={classes.form}>
          <Grid item>
            <Typography variant="overline" className={classes.label}>
              {content.SHOW_AUTOMATIONS}
            </Typography>
            <FormField<IAnnotationForm> fieldName="trigger">
              {({ value, onChange }) => (
                <FormControlLabel
                  control={
                    !automationsExists || !conditionsExists ? (
                      <Tooltip placement="bottom" text={content.NO_ACTIVE_AUTOMATIONS}>
                        <Switch
                          checked={false}
                          disabled={true}
                          data-testid="trigger-toggle"
                          classes={{ disabled: classes.switch }}
                        />
                      </Tooltip>
                    ) : (
                      <Switch
                        checked={value}
                        onChange={(event) => {
                          onChange && onChange(event.target.checked);
                          checkIfDisableTrigger('trigger', event.target.checked);
                        }}
                        data-testid="trigger-toggle"
                      />
                    )
                  }
                  label={content.TRIGGER}
                />
              )}
            </FormField>
            <FormField<IAnnotationForm> fieldName="alerts">
              {({ value, onChange }) => (
                <FormControlLabel
                  control={
                    !automationsExists || !conditionsExists ? (
                      <Tooltip placement="bottom" text={content.NO_ACTIVE_AUTOMATIONS}>
                        <Switch
                          checked={false}
                          disabled={true}
                          data-testid="alerts-toggle"
                          classes={{ disabled: classes.switch }}
                        />
                      </Tooltip>
                    ) : (
                      <Switch
                        checked={value}
                        onChange={(event) => {
                          onChange && onChange(event.target.checked);
                          checkIfDisableTrigger('alerts', event.target.checked);
                        }}
                        data-testid="alerts-toggle"
                      />
                    )
                  }
                  label={content.ALERTS}
                />
              )}
            </FormField>
            <FormField<IAnnotationForm> fieldName="deadband">
              {({ value, onChange }) => (
                <FormControlLabel
                  control={
                    !automationsExists || !deadbandExists ? (
                      <Tooltip placement="bottom" text={content.NO_ACTIVE_AUTOMATIONS}>
                        <Switch
                          checked={false}
                          disabled={true}
                          data-testid="deadband-toggle"
                          classes={{ disabled: classes.switch }}
                        />
                      </Tooltip>
                    ) : (
                      <Switch
                        checked={value}
                        onChange={(event) => {
                          onChange && onChange(event.target.checked);
                          checkIfDisableTrigger('deadband', event.target.checked);
                        }}
                        data-testid="deadband-toggle"
                      />
                    )
                  }
                  label={content.DEADBAND}
                />
              )}
            </FormField>
          </Grid>
          <Grid item>
            <div className={classes.buttons}>
              <ManageAutomations />
              <div>
                <Button variant="outlined" onClick={handleResetButtonClick} data-testid="extended-chart-reset-button">
                  {content.RESET_VIEW}
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} className={classes.chips}>
            <FormField<IAnnotationForm> fieldName="automations">
              {(props) => <ChipGroup<string> {...props} chips={automationChips} multi />}
            </FormField>
            {automationsExists && (
              <Button variant="text" onClick={handleClearAllButtonClick} data-testid="extended-chart-clear-all-button">
                {content.CLEAR_ALL}
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
