import { StringUtils, UrlString } from '@azure/msal-browser';

interface IQueryParams {
  [key: string]: string;
}

const removeQueryParamsFromUrl = (url: string): string => {
  return url.split('?').shift() || url;
};

const addQueryParam = (queryParams: IQueryParams, key: string, value: string): IQueryParams => {
  return { ...queryParams, [key]: value };
};

export const removeQueryParam = (queryParams: IQueryParams, paramToRemove: string): IQueryParams => {
  const { [paramToRemove]: removedParam, ...restQueryParams } = queryParams;

  return restQueryParams;
};

export const getQueryParamsFromUrl = (url: string): IQueryParams => {
  url = UrlString.removeHashFromUrl(url);
  url = url.endsWith('/') ? url.slice(0, -1) : url;

  const tmpUrl = url.split('?');
  const queryParams = tmpUrl.length > 1 ? tmpUrl[1] : '';

  if (!queryParams.length) {
    return {};
  }

  return StringUtils.queryStringToObject<IQueryParams>(queryParams);
};

export const buildUrl = (url: string, queryParams: IQueryParams): string => {
  const hash = url.split('#').pop() || ''; // TODO
  const queryString = Object.keys(queryParams)
    .map((key: string) => `${key}=${queryParams[key]}`)
    .join('&');

  url = UrlString.removeHashFromUrl(url);
  url = removeQueryParamsFromUrl(url);
  url = url.endsWith('/') ? url.slice(0, -1) : url;
  url = UrlString.appendQueryString(url, queryString);

  return hash.length ? `${url}#${hash}` : url;
};

export const appendQueryString = (url: string, key: string, value: string): string => {
  const queryParams = getQueryParamsFromUrl(url);

  return buildUrl(url, addQueryParam(queryParams, key, value));
};
