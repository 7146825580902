import { TDashboardSystemSchema } from '@marlin/asset/data-access/home-dashboard';
import { CardHeader } from '@marlin/asset/ui-home-hub-cards';
import { PerformanceStatusIcon, PerformanceStatusLabel } from '@marlin/shared/ui/system-view-status';
import { Divider } from '@mui/material';

import { content } from '../../content';
import { usePerformanceDashboardStatus } from '../../shared/use-dashboard-status';
import { CriticalityAlertsSection } from './components/criticality-alerts-section.component';
import { useStyles } from './performance-card-styles';

interface ISystemHealthCard {
  performance: TDashboardSystemSchema;
}

export const PerformanceCard = ({ performance }: ISystemHealthCard) => {
  const { classes } = useStyles();
  const { isSystemWithLowAlerts, isSystemWithHighAlerts } = usePerformanceDashboardStatus({
    performance,
  });

  const performanceStatus = isSystemWithHighAlerts ? 'error' : isSystemWithLowAlerts ? 'warning' : 'success';

  return (
    <div className={classes.container} data-testid="performance-container">
      <CardHeader
        status={performanceStatus}
        title={<PerformanceStatusLabel status={performanceStatus} />}
        subtitle={content.PERFORMANCE}
        icon={<PerformanceStatusIcon status={performanceStatus} className={classes.icon} testId={'tooltip'} />}
        cardType="performance"
      />
      {performance.equipment.deviceCount > 0 && (
        <div className={classes.section} data-testid="performance-section">
          <CriticalityAlertsSection type="Equipment" data={performance.equipment} />
        </div>
      )}
      <Divider />
      {performance.sensors.deviceCount > 0 && (
        <div className={classes.section} data-testid="performance-section">
          <CriticalityAlertsSection type="Sensor" data={performance.sensors} />
        </div>
      )}
      <Divider />
    </div>
  );
};
