import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  pageWrapper: {
    [theme.breakpoints.down('md')]: {
      padding: theme.typography.pxToRem(16),
      paddingTop: 0,
    },
  },
  sectionWrapper: {
    marginTop: theme.typography.pxToRem(16),
    maxWidth: theme.typography.pxToRem(712),
  },
}));
