import { AUTOMATION_RULE_TYPE } from '@marlin/alert/data-access/automated-action';
import { routes } from '@marlin/shared/utils-routes';

export interface IFeatureNavigation {
  getBackLink: (ruleType?: AUTOMATION_RULE_TYPE, isSystemRule?: boolean) => string;
}

export const useNavigation = (): IFeatureNavigation => {
  return {
    getBackLink: (ruleType?: AUTOMATION_RULE_TYPE, isSystemRule?: boolean) => {
      if (isSystemRule && ruleType === AUTOMATION_RULE_TYPE.DEVICE_ALERT) {
        return routes.automations.root;
      }
      if (ruleType === AUTOMATION_RULE_TYPE.EQUIPMENT_INFORMATION) {
        return routes.automations.information.root;
      }
      if (isSystemRule) {
        return routes.automations.health.root;
      }
      return routes.automations.root;
    },
  };
};
