import { MarlinTheme } from '@marlin/shared/theme';
import { PropsWithChildren } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useMobileFilterFormContext } from './mobile-filter-form-provider.component';
import { MobileFilterSorting } from './mobile-filter-sorting.component';
import { IBaseFilters } from './model';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    padding: `0 ${theme.typography.pxToRem(16)} ${theme.typography.pxToRem(16)}`,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(16),
  },
}));

export function MobileFilterForm<TSort extends string, TFilters extends IBaseFilters<TSort>>({
  children,
}: PropsWithChildren) {
  const { classes } = useStyles();
  const { submit, sortList } = useMobileFilterFormContext<TSort, TFilters>();

  return (
    <form onSubmit={submit} data-testid="filters-form">
      <div className={classes.container}>
        {children}
        {!!sortList.length && <MobileFilterSorting<TSort, TFilters> />}
      </div>
    </form>
  );
}
