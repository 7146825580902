import { matchPath } from 'react-router';

// TODO: Create list for url not to restore
const invitationPath = `portfolio/:portfolioId/invitation/:invitationId/validate`;

export interface IPersistentLocationState {
  [key: string]: string;
}

export class LocationStateService {
  private readonly locationStateKey = 'baseUrl';

  public constructor(private readonly persistentLocationState: IPersistentLocationState) {}

  public saveLocationState = (path?: string): void => {
    localStorage.setItem(this.locationStateKey, path ? path : window.location.pathname);
  };

  public restoreLocationState = (): void => {
    const baseUrl = localStorage.getItem(this.locationStateKey);
    const isPortfolioInvitation = !!matchPath(invitationPath, window.location.pathname);

    if (baseUrl && !isPortfolioInvitation) {
      localStorage.removeItem(this.locationStateKey);

      Object.values(this.persistentLocationState).forEach((value) => {
        if (baseUrl.includes(value)) {
          window.location.assign(value);
          return;
        }
      });
    }
  };
}
