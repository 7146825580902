const user = '/api/user';
const updateProfile = `${user}/me`;
const organizationList = `${user}/organizations`;
const paginatedOrganizationList = `${organizationList}/filter`;
const organizationSettings = `/api/organization/settings`;
const organization = `api/organization`;
const account = `api/account`;
const userSettings = `${user}/settings`;
const tier = '/api/tier/types';
const analyticsReports = '/api/analytics-reports';
const supportUser = `${user}/support`;

export const paths = {
  CREATE_ORGANIZATION: organization,
  ORGANIZATION: organizationList,
  ORGANIZATION_LIST: paginatedOrganizationList,
  USER: user,
  USER_PROFILE: updateProfile,
  ORGANIZATION_SETTINGS: organizationSettings,
  SETTINGS: userSettings,
  TIER: tier,
  ORGANIZATION_DETAILS: `${organization}/{organizationId}`,
  USER_IN_ORGANIZATION_STATUS: `gateway${user}/{organizationId}`,
  ANALYTICS_REPORTS: analyticsReports,
  ACCOUNT_DELETE: `${account}`,
  DEFAULT_ORGANIZATION: `${organizationList}/default`,
  ORGANIZATION_BY_ID: `${organizationList}/{organizationId}`,
  SUPPORT_USER: supportUser,
};
