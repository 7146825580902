import { TExtractFnReturnType, useQuery } from '@marlin/shared/utils/react-query';
import { useMemo } from 'react';

import { getUsers } from '../infrastructure/users';
import { queryKey } from '../query-key.enum';
import { IUserListFilters } from '../user.model';

export interface IUseUsersParams {
  search?: string;
  page: number;
  pageSize: number;
  sorting?: {
    direction: 'Ascending' | 'Descending';
    sortBy: string;
  };
}

export const mapGetUserParams = (params: IUseUsersParams): IUserListFilters => ({
  search: params.search?.trim(),
  page: params.page,
  pageSize: params.pageSize,
  sorting: params.sorting,
});

export const useUsers = (params: IUseUsersParams) => {
  const queryParams = useMemo(() => mapGetUserParams(params), [params]);

  return useQuery<TExtractFnReturnType<typeof getUsers>>({
    queryKey: queryKey.USER_LIST(queryParams),
    queryFn: () => getUsers(queryParams),
  });
};
