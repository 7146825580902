import { TAutomation } from '@marlin/alert/data-access/automated-action';

interface IConditionValue {
  enabled: boolean;
  value: number;
}

export interface ICondition {
  thresholdMin?: IConditionValue;
  thresholdMax?: IConditionValue;
  deadbandMin?: IConditionValue;
  deadbandMax?: IConditionValue;
}

export type TRecipientType = 'Recipient' | 'User';

export interface IRecipient {
  id: string;
  firstName: string;
  lastName: string;
  recipientType: TRecipientType;
}

export enum CHANNEL_TYPE {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  IN_APP = 'IN_APP',
  HOT_SOS = 'HOT_SOS',
}

export interface IInAppChannel {
  type: CHANNEL_TYPE.IN_APP;
}

export interface IHotSosChannel {
  type: CHANNEL_TYPE.HOT_SOS;
}

export interface IChannelRecipient {
  recipientId: string;
  recipientType: TRecipientType;
}

interface IChannel {
  recipients: IChannelRecipient[];
}

interface IExtendedChannel {
  recipients: IRecipient[];
}

export interface IEmailChannel extends IChannel {
  type: CHANNEL_TYPE.EMAIL;
}

export interface ISmsChannel extends IChannel {
  type: CHANNEL_TYPE.SMS;
}

export interface IChannels {
  [CHANNEL_TYPE.SMS]?: ISmsChannel;
  [CHANNEL_TYPE.EMAIL]?: IEmailChannel;
  [CHANNEL_TYPE.IN_APP]?: IInAppChannel;
  [CHANNEL_TYPE.HOT_SOS]?: IHotSosChannel;
}

export interface IExtendedChannels {
  [CHANNEL_TYPE.SMS]?: Omit<ISmsChannel, 'recipients'> & IExtendedChannel;
  [CHANNEL_TYPE.EMAIL]?: Omit<IEmailChannel, 'recipients'> & IExtendedChannel;
  [CHANNEL_TYPE.IN_APP]?: IInAppChannel;
  [CHANNEL_TYPE.HOT_SOS]?: IHotSosChannel;
}

export enum DEVICE_TYPE {
  TEMPERATURE = 'TEMPERATURE',
  PRESSURE = 'PRESSURE',
  LEAK = 'LEAK',
  PULSE_METER = 'PULSE_METER',
  GATEWAY = 'GATEWAY',
  EQUIPMENT = 'EQUIPMENT',
  UNKNOWN = 'UNKNOWN',
}

export interface IAutomationMetadata {
  name?: string | null;
  id?: string | null;
}

export interface IExtendedAutomation extends Omit<TAutomation, 'channels'> {
  rootLocation?: IAutomationMetadata;
  channels: IExtendedChannels;
}
