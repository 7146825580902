import { AUTOMATION_RULE_TYPE } from '@marlin/alert/data-access/automated-action';
import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

import { useContent } from '../../../../context';
import { DeviceDurationWarning } from '../../warnings/device-duration-warning.component';
import { Duration } from '../condition/duration.component';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  warning: {
    marginTop: theme.typography.pxToRem(16),
    paddingLeft: theme.typography.pxToRem(32),
  },
}));

interface IDeviceLostCommunicationConditionsProps {
  isEquipment?: boolean;
}

export function DeviceLostCommunicationConditions({
  isEquipment,
}: IDeviceLostCommunicationConditionsProps): JSX.Element {
  const content = useContent();
  const { classes } = useStyles();

  return (
    <>
      <Duration
        label={content.CONDITIONS.DURATION.SENSOR_INACTIVITY_DURATION}
        ruleType={AUTOMATION_RULE_TYPE.DEVICE_LOST_COMMUNICATION}
      />
      {!isEquipment && (
        <div className={classes.warning}>
          <DeviceDurationWarning />
        </div>
      )}
    </>
  );
}
