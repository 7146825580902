import { MarlinTheme } from '@marlin/shared/theme';
import ElectricalServicesRoundedIcon from '@mui/icons-material/ElectricalServicesRounded';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  cell: {
    display: 'flex',
    alignItems: 'center',
  },

  icon: {
    marginRight: theme.typography.pxToRem(6),
  },

  successIcon: {
    color: theme.palette.success.light,
  },
}));

export const Line = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.cell}>
      <ElectricalServicesRoundedIcon className={`${classes.icon} ${classes.successIcon}`} />
      {content.POWER_CELL_LINE}
    </div>
  );
};
