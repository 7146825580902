import { ChartDatapointsGroup } from '@marlin/shared/utils-chart';
import { UnitOfMeasureType } from '@marlin/shared/utils-format-reading';
import { ModelEnum } from '@marlin/shared/utils/zod';
import { z } from 'zod';

import { MetadataOption } from './metadata-option.schema';
import { SettingsGroup } from './settings-group.schema';
import { MetadataStatus } from './statuses.schema';

const datapointResponseTypeList = ['number', 'bool', 'string', 'datetime', 'bitmask'] as const;

export const DatapointResponseType = z.enum(datapointResponseTypeList);
export type TDatapointResponseType = z.infer<typeof DatapointResponseType>;

export const DeviceMetadataDatapointsResponseRaw = z.object({
  def: z.string().nullable(),
  isDynamic: z.boolean().nullable(),
  label: z
    .array(
      z.object({
        language: z.string(),
        translation: z.string(),
      })
    )
    .nullable(),
  max: z.string().nullable(),
  min: z.string().nullable(),
  maxThreshold: z.string().nullable(),
  minThreshold: z.string().nullable(),
  name: z.string(),
  options: MetadataOption.array().nullable(),
  status: MetadataStatus.array().nullable(),
  orderNumber: z.number().nullable(),
  type: DatapointResponseType,
  uom: UnitOfMeasureType,
  step: z.string().nullable(),
});

export type TDeviceMetadataDatapointsResponseRaw = z.infer<typeof DeviceMetadataDatapointsResponseRaw>;

export const DeviceMetadataDatapointsResponse = DeviceMetadataDatapointsResponseRaw.transform((item) => {
  return {
    name: item.name,
    defaultValue: item.def,
    min: item.min,
    max: item.max,
    step: item.step,
    type: item.options?.length ? 'enum' : item.type,
    label: item.label?.find((label) => label.language === 'en-US')?.translation ?? item.name,
    unitOfMeasure: item.uom,
    availableStatuses: item.status ?? [],
    options: item.options
      ? item.options.map((option) => ({
          ...option,
          label: option.label?.find((label) => label.language === 'en-US')?.translation ?? option.name,
        }))
      : [],
    orderNumber: item.orderNumber,
    maxThreshold: item.maxThreshold,
    minThreshold: item.minThreshold,
  };
});

export type TDeviceMetadataDatapointsResponse = z.infer<typeof DeviceMetadataDatapointsResponse>;

export const DeviceMetadataDatapointGroupResponse = z.object({
  groupName: SettingsGroup,
  datapoints: z.array(z.string()),
  label: z
    .array(
      z.object({
        language: z.string(),
        translation: z.string(),
      })
    )
    .nullable(),
});

export const DeviceMetadataResponseRaw = z.object({
  datapoints: z.array(DeviceMetadataDatapointsResponseRaw),
  datapointGroups: z
    .array(DeviceMetadataDatapointGroupResponse.extend({ groupName: z.string() }))
    .transform((datapointGroups) =>
      datapointGroups.filter((datapointGroup) => DeviceMetadataDatapointGroupResponse.safeParse(datapointGroup).success)
    )
    .nullable(),
  chartDatapoints: z.array(ChartDatapointsGroup),
});
export type TDeviceMetadataResponseRaw = z.infer<typeof DeviceMetadataResponseRaw>;

export const DeviceMetadataResponse = DeviceMetadataResponseRaw.extend({
  datapoints: z.array(DeviceMetadataDatapointsResponse),
});
export type TDeviceMetadataResponse = z.infer<typeof DeviceMetadataResponse>;

export const ModelsMetadataResponse = z.array(
  z.object({
    model: ModelEnum.or(z.string()),
    metadata: DeviceMetadataResponse,
  })
);
export type TModelsMetadataResponse = z.infer<typeof ModelsMetadataResponse>;
