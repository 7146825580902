import EggRoundedIcon from '@mui/icons-material/EggRounded';
import PanoramaPhotosphereOutlinedIcon from '@mui/icons-material/PanoramaPhotosphereOutlined';
import SpeedRoundedIcon from '@mui/icons-material/SpeedRounded';
import ThermostatRoundedIcon from '@mui/icons-material/ThermostatRounded';

import { content } from '../content';
import { DEVICE_TYPE } from '../types';
import { IFilterDevice } from './types';

export const deviceTypeOptions: IFilterDevice[] = [
  {
    id: DEVICE_TYPE.TEMPERATURE,
    name: content.FILTERS.SENSOR_TYPES.TEMPERATURE,
    Icon: ThermostatRoundedIcon,
  },
  {
    id: DEVICE_TYPE.PRESSURE,
    name: content.FILTERS.SENSOR_TYPES.PRESSURE,
    Icon: SpeedRoundedIcon,
  },
  {
    id: DEVICE_TYPE.LEAK,
    name: content.FILTERS.SENSOR_TYPES.LEAK,
    Icon: EggRoundedIcon,
  },
  {
    id: DEVICE_TYPE.PULSE_METER,
    name: content.FILTERS.SENSOR_TYPES.FLOW_METER,
    Icon: PanoramaPhotosphereOutlinedIcon,
  },
];

export const equipmentTypeOptions: IFilterDevice[] = [
  {
    id: DEVICE_TYPE.EQUIPMENT,
    name: content.FILTERS.EQUIPMENT,
  },
];

export const gatewayTypeOptions: IFilterDevice[] = [
  {
    id: DEVICE_TYPE.GATEWAY,
    name: content.FILTERS.GATEWAY,
  },
];
