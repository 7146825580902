import { queryClient, useMutation } from '@marlin/shared/utils/react-query';

import { updateOrganizationDetails } from '../infrastructure/update-org-details';
import { queryKey } from './query-key.enum';

export const useUpdateOrganizationDetails = () => {
  return useMutation({
    mutationFn: updateOrganizationDetails,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.ORGANIZATION_DETAILS() });
      queryClient.invalidateQueries({ queryKey: queryKey.CURRENT_USER() });
    },
  });
};
