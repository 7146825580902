import { ComponentType, Context } from 'react';

export const withProviders =
  <P extends Record<string, unknown>>(Cmp: ComponentType<P>) =>
  <C extends string = Extract<keyof P, string>>(contexts: { [key in C]: Context<P[key] | undefined> }) =>
  (props: P): JSX.Element => {
    return (Object.keys(contexts) as C[]).reduce((Acc: JSX.Element, val: C): JSX.Element => {
      const Context: Context<P[C] | undefined> = contexts[val];
      const value: P[C] = props[val];

      return <Context.Provider value={value}>{Acc}</Context.Provider>;
    }, <Cmp {...props} />);
  };
