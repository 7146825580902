import { useMediaQuery, useTheme } from '@mui/material';

import { DeviceDrawerReadings } from './components';
import { DeviceDetailsMobile } from './components/details-mobile';
import { useDeviceCurrentAlerts } from './hooks/use-device-current-alerts.hook';
import { useSensorId } from './hooks/use-sensor-id.hook';

const DeviceReadingsTab = () => {
  const deviceId = useSensorId();
  const { isAlert, communicationLostAlert } = useDeviceCurrentAlerts();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (!deviceId || deviceId.length === 0) {
    return null;
  }

  if (isMobile) {
    return <DeviceDetailsMobile />;
  }

  return (
    <>
      <DeviceDrawerReadings deviceId={deviceId} isAlert={isAlert} communicationLostAlert={communicationLostAlert} />
    </>
  );
};

export { DeviceReadingsTab };
