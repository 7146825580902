import { content } from './content';

export const aercoDatapointLabelsMap = new Map([
  ['InletTemp', content.DATAPOINT_LABELS_AERCO.UPPER_INLET],
  ['Inlet2Temp', content.DATAPOINT_LABELS_AERCO.LOWER_INLET],
  ['ExhaustTemp', content.DATAPOINT_LABELS_AERCO.EXHAUST_TEMP],
  ['Efficiency', content.DATAPOINT_LABELS_AERCO.EFFICIENCY],
  ['OxygenLevel', content.DATAPOINT_LABELS_AERCO.OXYGEN_LEVEL],
  ['FlameStrength', content.DATAPOINT_LABELS_AERCO.FLAME_STRENGTH],
  ['BlowerOutput', content.DATAPOINT_LABELS_AERCO.BLOWER_VOLTAGE],
  ['RunHours', content.DATAPOINT_LABELS_AERCO.RUN_HOURS],
  ['RunCycles', content.DATAPOINT_LABELS_AERCO.CYCLE_COUNT],
  ['RunCyclesPerHour', content.DATAPOINT_LABELS_AERCO.CYCLES_PER_HOUR],
  ['PeakLoad', content.DATAPOINT_LABELS_AERCO.PEAK_LOAD],
  ['StartLevel', content.DATAPOINT_LABELS_AERCO.START_LEVEL],
  ['Condensing', content.DATAPOINT_LABELS_AERCO.CONDENSING],
  ['Calibration', content.DATAPOINT_LABELS_AERCO.CALIBRATION],
]);
