import { environment } from '@marlin/environment';

export const content = {
  CHANGE: 'Change',
  CANCEL: 'Cancel',
  ORGANIZATION: 'Organization',
  NEXA_SUPPORT: 'Nexa Support',
  SEARCH_PLACEHOLDER_BLURED: 'Search list',
  SEARCH_PLACEHOLDER_FOCUSED: 'Start typing',
  NO_FILTER_RESULTS: 'No filter results.',
  CREATE_ORG: 'Create Organization',
  CREATE_ORG_TEST_ID: 'create-organization-id',
  CREATE_ORG_PLACEHOLDER: 'Organization name',
  CREATE_BTN: 'Create',
  CREATE_ORG_SUCCESS: 'Organization has been created',
  CREATE_ORG_ERROR: 'Error during organization creation',
  VALIDATION_ERROR_MESSAGE: 'Must be between 3 and 80 characters',
  SUPPORT: 'support',
  PORTFOLIO_OVERVIEW: 'Portfolio Overview',
  PORTFOLIO_CREATE: 'Create Portfolio',
  ORGANIZATION_OVERVIEW: 'Organization Overview',
  MARLIN_SUPPORT: `${environment.app.name} Support`,
  ORGANIZATION_HAS_BEEN_CHANGED: 'Organization has been changed',
  NEXA_SYSTEM: 'Nexa System',
  NEXA_EQUIPMENT: 'Nexa Equipment',
};
