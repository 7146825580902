import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  loader: {
    height: 'inherit',
    width: '100%',
    position: 'absolute',
    backgroundColor: theme.palette.background.primary,
    top: 0,
    left: 0,
  },
  panel: {
    '&.react-flow__panel': {
      margin: theme.typography.pxToRem(24),
    },
  },
}));
