import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((_theme: MarlinTheme) => ({
  sankeyWrapper: {
    '.sankey-node > .node-label': {
      textShadow: 'none!important',
      textAnchor: 'start',
      transform: 'translate(36px, 12px)',
    },
    '.sankey-node > .node-rect': {
      stroke: 'none !important',
    },
  },
}));
