import { MarlinTheme } from '@marlin/shared/theme';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import { IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    marginRight: theme.typography.pxToRem(20),
  },

  deviceDrawerContainer: {},
}));

interface ISnoozeAlertIconProps {
  isSnoozed: boolean;
  onClick: () => void;
  id: string;
  externalClass?: string;
  isDeviceDrawer?: boolean;
  disabled: boolean;
}

export const SnoozeAlertIcon = ({
  id,
  isSnoozed,
  disabled,
  onClick,
  externalClass,
  isDeviceDrawer = false,
}: ISnoozeAlertIconProps) => {
  const { classes } = useStyles();

  return (
    <Tooltip placement="top" text={isSnoozed ? content.SNOOZE_STOP : content.SNOOZE_ALERTS}>
      <IconButton
        key={id}
        onClick={onClick}
        disabled={disabled}
        className={`${isDeviceDrawer ? classes.deviceDrawerContainer : classes.container} ${externalClass}`}
      >
        <NotificationsOffIcon color={isSnoozed ? 'error' : 'primary'} />
      </IconButton>
    </Tooltip>
  );
};
