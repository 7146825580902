import { useMediaQuery, useTheme } from '@mui/material';

import { EquipmentDetailsDesktop } from './desktop/equipment-details.component';
import { EquipmentDetailsMobile } from './mobile/equipment-details.component';

export const EquipmentDetails = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (isMobile) {
    return <EquipmentDetailsMobile />;
  }

  return <EquipmentDetailsDesktop />;
};
