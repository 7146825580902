import { AUTOMATION_RULE_TYPE, CHANNEL_TYPE, CRITICALITY } from '@marlin/alert/data-access/automated-action';
import { z } from 'zod';

import { content } from '../content/validation-content';
import { conditionsSchema } from './conditions.schema';
import { durationSchema, isDurationRuleType, refineDurationRules } from './duration.schema';

const nameFieldMaxLength = 200;

const nameSchema = z
  .string()
  .min(1, { message: content.REQUIRED })
  .max(nameFieldMaxLength, { message: content.MAX_LENGTH(nameFieldMaxLength) });

const deviceIdSchema = z
  .string({ required_error: content.REQUIRED })
  .min(1, { message: content.REQUIRED })
  .transform((value) => (value === null ? undefined : value));

const criticalitySchema = z.nativeEnum(CRITICALITY, { required_error: content.REQUIRED });

const manufacturerIdSchema = z
  .string({ required_error: content.REQUIRED })
  .transform((value) => (value === null ? undefined : value));

const recipientSchema = z.object({
  recipientId: z.string(),
  recipientType: z.enum(['Recipient', 'User']),
});

const channelSchema = z.object({
  type: z.nativeEnum(CHANNEL_TYPE),
});

const channelWithRecipientsSchema = z.object({
  type: z.nativeEnum(CHANNEL_TYPE),
  recipients: z.array(recipientSchema).min(1).default([]),
});

const channelsSchema = z.object({
  IN_APP: channelSchema.optional(),
  HOT_SOS: channelSchema.optional(),
  SMS: channelWithRecipientsSchema
    .refine((channel) => channel?.recipients?.length, {
      message: content.EMPTY_RECIPIENTS,
    })
    .nullish()
    .transform((value) => (value === null ? undefined : value)),
  EMAIL: channelWithRecipientsSchema
    .refine((channel) => channel?.recipients?.length, {
      message: content.EMPTY_RECIPIENTS,
    })
    .nullish()
    .transform((value) => (value === null ? undefined : value)),
});

const snoozeIntervalInMinutesSchema = z.number();

export const AutomatedActionFormValidationSchema = z
  .object({
    name: nameSchema,
    device: z
      .object({
        id: deviceIdSchema,
      })
      .nullable(),
    criticality: criticalitySchema,
    manufacturerId: manufacturerIdSchema.nullable(),
    channels: channelsSchema,
    snoozeIntervalInMinutes: snoozeIntervalInMinutesSchema,
    conditions: conditionsSchema,
    isSystemRule: z.boolean().default(false),
    ruleType: z.nativeEnum(AUTOMATION_RULE_TYPE).nullish(),
    duration: durationSchema.nullable(),
  })
  .superRefine(({ duration, ruleType }, ctx): never | undefined => {
    if (isDurationRuleType(ruleType)) {
      refineDurationRules({ addIssue: ctx.addIssue, duration });
    }

    return undefined;
  });

export const AutomatedActionFormValidationSchemaDeviceErrorAndAlerts = z.object({
  name: nameSchema,
  device: z
    .object({
      id: deviceIdSchema,
    })
    .nullable(),
  criticality: criticalitySchema,
  manufacturerId: manufacturerIdSchema.nullable(),
  channels: channelsSchema,
  snoozeIntervalInMinutes: snoozeIntervalInMinutesSchema,
  isSystemRule: z.boolean().default(false),
  ruleType: z.nativeEnum(AUTOMATION_RULE_TYPE).nullish(),
});
