import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import { paths } from './api';
import { TSubtypes, TSubtypesParams, subtypesRequestParamsSchema, subtypesSchema } from './schema/subtypes.schema';

export const getDeviceSubtypes = async (params: TSubtypesParams): Promise<TSubtypes> => {
  const subtypeParams = safeParseData(params, subtypesRequestParamsSchema);

  const response = await getHttpClient().get(paths.SUBTYPES, { params: subtypeParams });
  return safeParseData(response, subtypesSchema);
};
