import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import {
  Observable,
  ReplaySubject,
  Subscriber,
  catchError,
  filter,
  finalize,
  from,
  fromEventPattern,
  of,
  switchMap,
} from 'rxjs';

import { REALTIME_COMMUNICATION_LOG_LEVEL } from './model';

const serverTimeout = 60000;

export interface IRealtimeCommunicationConnection {
  nativeConnection: HubConnection | null;
  start(): Observable<HubConnection>;
  invoke(methodName: string, connectionId: string, oldConnectionId?: string | null): Observable<unknown>;
  stop(): void;
  state(): HubConnectionState;
  listenOn(eventName: string): Observable<unknown>;
  onReconnected(callback: (connectionId?: string) => void): void;
}

export class RealtimeCommunicationConnection implements IRealtimeCommunicationConnection {
  private connection$ = new ReplaySubject<HubConnection>(1);
  private readonly connection: HubConnection;

  public constructor(
    url: string,
    accessTokenFactory: () => Promise<string>,
    logLevel: REALTIME_COMMUNICATION_LOG_LEVEL
  ) {
    this.connection = new HubConnectionBuilder()
      .withUrl(url, {
        accessTokenFactory,
      })
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: (retryContext) => {
          if (retryContext.retryReason) return null;
          if (retryContext.elapsedMilliseconds >= serverTimeout) {
            return null;
          }
          // https://learn.microsoft.com/en-us/aspnet/core/signalr/javascript-client?view=aspnetcore-8.0&tabs=visual-studio
          return Math.random() * 10000;
        },
      })
      .withServerTimeout(serverTimeout)
      .configureLogging(this.getMappedSignalRLogLevel(logLevel))
      .build();
  }

  public get nativeConnection(): HubConnection {
    return this.connection;
  }

  public start = (): Observable<HubConnection> => {
    return new Observable((subscriber: Subscriber<HubConnection>) => {
      if (this.connection.state === HubConnectionState.Disconnected) {
        return from(this.connection.start())
          .pipe(
            switchMap(() => {
              this.connection$.next(this.connection);
              return this.connection$;
            }),
            catchError((error) => {
              this.connection$.error(error);
              return this.connection$;
            }),
            finalize(() => {
              this.resetConnectionObservableState();
            })
          )
          .subscribe(subscriber);
      }

      return this.connection$.subscribe(subscriber);
    });
  };

  public invoke = (methodName: string, connectionId: string, oldConnectionId?: string): Observable<unknown> => {
    return of(this.connection.state).pipe(
      filter((state) => state === HubConnectionState.Connected),
      switchMap(() => {
        if (oldConnectionId !== null) {
          return from(this.connection.invoke(methodName, connectionId, oldConnectionId || null, {}));
        }
        return from(this.connection.invoke(methodName, connectionId, {}));
      })
    );
  };

  public stop = async (): Promise<void> => {
    await this.connection.stop();
    this.resetConnectionObservableState();
  };

  public state = (): HubConnectionState => this.connection.state;

  public listenOn = (eventName: string): Observable<unknown> => {
    return fromEventPattern(
      (handler): void => this.connection.on(eventName, handler),
      (handler): void => this.connection.off(eventName, handler)
    );
  };

  public onReconnected(callback: (connectionId?: string) => void): void {
    this.connection.onreconnected(callback);
  }

  private resetConnectionObservableState = () => {
    this.connection$.complete();
    this.connection$ = new ReplaySubject<HubConnection>(1);
  };

  private getMappedSignalRLogLevel = (logLevel: REALTIME_COMMUNICATION_LOG_LEVEL): number => {
    switch (logLevel) {
      case REALTIME_COMMUNICATION_LOG_LEVEL.ALL:
        return LogLevel.Trace;
      case REALTIME_COMMUNICATION_LOG_LEVEL.ERROR:
        return LogLevel.Error;
      case REALTIME_COMMUNICATION_LOG_LEVEL.WARNING:
        return LogLevel.Warning;
      case REALTIME_COMMUNICATION_LOG_LEVEL.INFORMATION:
        return LogLevel.Information;
      case REALTIME_COMMUNICATION_LOG_LEVEL.NONE:
      default:
        return LogLevel.None;
    }
  };
}
