import { z } from 'zod';

import { content } from '../content';

const maxCharacters = 100;

const optionalTextFieldSchema = z.string().max(maxCharacters, content.MAX_LENGTH(maxCharacters)).nullish();

export const timeZoneSchema = z
  .object({
    id: optionalTextFieldSchema,
    name: optionalTextFieldSchema,
  })
  .nullish();

export type TTimeZone = z.infer<typeof timeZoneSchema>;
