import { Button } from '@mui/material';
import { MarkerType, Panel, ReactFlow, useEdgesState, useNodesState, useReactFlow } from '@xyflow/react';

import ComponentNode from './component-node.component';
import { initialEdges, initialNodes } from './default-nodes';
import { EquipmentNode } from './nodes/equipment.component';
import { SensorNode } from './nodes/sensor.component';

const nodeTypes = {
  componentNode: ComponentNode,
  sensorNode: SensorNode,
  equipmentNode: EquipmentNode,
};

export const Canvas = () => {
  const [nodes, , onNodesChange] = useNodesState(initialNodes);
  const [edges, , onEdgesChange] = useEdgesState(initialEdges);
  const { toObject } = useReactFlow();
  return (
    <div style={{ height: '100%', width: '100%', minHeight: '100vh' }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        nodeTypes={nodeTypes}
        fitView
        preventScrolling
        proOptions={{
          hideAttribution: true,
        }}
        defaultEdgeOptions={{
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 32,
            height: 32,
          },
        }}
      >
        <Panel position="top-right">
          <Button
            onClick={() => {
              const data = toObject();
              // eslint-disable-next-line no-console
              console.log(data);
            }}
          >
            Export
          </Button>
        </Panel>
      </ReactFlow>
    </div>
  );
};
