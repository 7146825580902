export const content = {
  SENSOR_READINGS_TEMPERATURE: (value: string) => `${value}°F`,
  SENSOR_READINGS_PRESSURE: (value: string) => `${value} PSI`,
  SENSOR_READINGS_FLOW_METER: (value: string) => `${value} GPM`,
  SENSOR_READINGS_LEAK: 'Leak',
  SENSOR_READINGS_NO_LEAK: 'No Leak',
  SENSOR_READINGS_NOT_APPLICABLE: 'N/A',
  UNITS: {
    TEMPERATURE: '°F',
    PRESSURE: 'PSI',
    FLOW_METER: 'GPM',
  },
  LOST_COMMUNICATION: 'Lost Comm',
};
