import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import { paths } from './api';
import { TOrganizationClaimsResponse, organizationClaimsResponseSchema } from './organization.schema';

export const getUserOrganizationStatus = async (organizationId: string): Promise<TOrganizationClaimsResponse> => {
  const res = await getHttpClient().get(paths.USER_IN_ORGANIZATION_STATUS, {
    params: { organizationId },
    headers: { organizationId },
  });

  return safeParseData(res, organizationClaimsResponseSchema);
};
