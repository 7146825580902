import { MarlinTheme } from '@marlin/shared/theme';
import { ModalMedium } from '@marlin/shared/ui-modal';
import { Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  confirmText: {
    fontWeight: theme.typography.fontWeightBold,
  },

  cancelButton: {
    marginLeft: theme.typography.pxToRem(8),
    backgroundColor: theme.palette.error.main,
    '&:hover': { backgroundColor: theme.palette.error.main },
  },
}));

interface IDeleteModalProps {
  isDeleteModalVisible: boolean;
  flowLinkId: string | undefined;
}

export interface IDeleteFlowLinkModal {
  onCancel: () => void;
  onSubmit: () => void;
  modalProps: IDeleteModalProps;
}

export const DeleteFlowLinkModal = ({ onCancel, onSubmit, modalProps }: IDeleteFlowLinkModal) => {
  const { classes } = useStyles();

  if (!modalProps.isDeleteModalVisible) {
    return null;
  }

  return (
    <ModalMedium
      title={content.DELETE_FLOW_LINK_TITLE}
      onClose={onCancel}
      body={
        <>
          <div>{content.DELETE_FLOW_LINK_MESSAGE}</div>
          <div className={classes.confirmText}>{content.DELETE_FLOW_LINK_MESSAGE_BOLD}</div>
        </>
      }
      footer={
        <>
          <Button variant="text" onClick={onCancel}>
            {content.DELETE_FLOW_LINK_CANCEL}
          </Button>
          <Button className={classes.cancelButton} variant="contained" onClick={onSubmit}>
            {content.DELETE_FLOW_LINK_CONFIRM}
          </Button>
        </>
      }
    />
  );
};
