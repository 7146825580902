import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  controls: {
    background: theme.palette.primary.dark,
    height: theme.typography.pxToRem(48),
    minHeight: theme.typography.pxToRem(48),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  closeIcon: {
    color: theme.palette.primary.contrastText,
    marginLeft: theme.typography.pxToRem(12),
  },

  upArrow: {
    color: theme.palette.primary.contrastText,
    marginLeft: theme.typography.pxToRem(12),
  },

  downArrow: {
    color: theme.palette.primary.contrastText,
    marginLeft: theme.typography.pxToRem(8),
  },

  disabled: {
    opacity: 0.5,
  },

  deleteIcon: {
    color: theme.palette.primary.contrastText,
    marginRight: theme.typography.pxToRem(8),
  },

  editIcon: {
    color: theme.palette.primary.contrastText,
    marginRight: theme.typography.pxToRem(16),
  },
}));
