import { MarlinTheme } from '@marlin/shared/theme';
import { IChartRangeOption, chartRangeTypeOptions } from '@marlin/shared/ui-form';
import { DateRangePicker, IDateRangeModalReturnValue } from '@marlin/shared/ui/date-range-picker';
import { RANGE_FILTER, useSetCustomPeriodModalContextStore } from '@marlin/shared/utils-chart';
import EditIcon from '@mui/icons-material/Edit';
import { Button } from '@mui/material';
import { Moment } from 'moment';
import { ReactNode, useCallback } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';

interface ICustomPeriodModalProps {
  fromDate: Moment | null;
  toDate: Moment | null;
  open: boolean;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    height: theme.typography.pxToRem(510),
    width: theme.typography.pxToRem(640),
  },
  dateWrapper: {
    display: 'flex',
    paddingBottom: theme.typography.pxToRem(16),
    width: '100%',
    justifyContent: 'space-between',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    textTransform: 'none',
    color: theme.palette.text.secondary,
    width: '100%',
    paddingLeft: theme.typography.pxToRem(16),
  },
  icon: {
    fontSize: theme.typography.pxToRem(24),
    marginRight: theme.typography.pxToRem(5),
    color: theme.palette.text.secondary,
  },
  calendar: {
    backgroundColor: theme.palette.background.secondary,
    border: `1px solid ${theme.palette.divider}`,
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > button:first-of-type': {
      marginRight: theme.typography.pxToRem(16),
    },
  },
  title: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
    paddingTop: theme.typography.pxToRem(16),
    paddingBottom: theme.typography.pxToRem(8),
  },
  input: {
    width: theme.typography.pxToRem(300),
    '& > div': {
      height: theme.typography.pxToRem(40),
    },
    '& > label': {
      top: theme.typography.pxToRem(-6),
    },
    '& > .MuiInputLabel-shrink': {
      top: theme.typography.pxToRem(0),
    },
  },
}));

interface IDurationModal {
  setModalStore: (value: Partial<ICustomPeriodModalProps>) => void;
  setRangeFilter?: (option: RANGE_FILTER) => void;
  toDate: Moment | null;
  fromDate: Moment | null;
}

export const DurationModal = ({ setModalStore, setRangeFilter, toDate, fromDate }: IDurationModal) => {
  const handleClose = useCallback(() => {
    setModalStore({
      open: false,
    });
  }, [setModalStore]);

  const handleSave = useCallback(
    (value: Partial<IDateRangeModalReturnValue>) => {
      const { fromDate, toDate } = value;

      if (!fromDate || !toDate) return;

      setModalStore({
        open: false,
        fromDate,
        toDate,
      });
      setRangeFilter && setRangeFilter(RANGE_FILTER.CUSTOM);

      handleClose();
    },
    [handleClose, setModalStore, setRangeFilter]
  );

  return (
    <DateRangePicker
      toDate={toDate}
      fromDate={fromDate}
      onClose={handleClose}
      onSetPeriod={handleSave}
      content={{
        CANCEL: content.CANCEL,
        FROM: content.FROM,
        SET_PERIOD: content.SET_PERIOD,
        TITLE: content.TITLE,
        TO: content.TO,
      }}
    />
  );
};

const CustomOption = (): ReactNode => {
  const { classes } = useStyles();
  const setCustomModalStore = useSetCustomPeriodModalContextStore();

  const handleOpen = useCallback(() => {
    setCustomModalStore({
      open: true,
    });
  }, [setCustomModalStore]);

  return (
    <Button className={classes.button} onClick={handleOpen}>
      <EditIcon className={classes.icon} /> {content.CUSTOM}
    </Button>
  );
};

export const durationOptions: IChartRangeOption[] = [
  ...chartRangeTypeOptions,
  {
    id: RANGE_FILTER.DAYS_30,
    name: content.DAYS_30,
  },
  {
    id: RANGE_FILTER.MONTHS_12,
    name: content.MONTH_12,
  },
  {
    id: RANGE_FILTER.CUSTOM,
    name: <CustomOption />,
  },
];
