import isNil from 'lodash/isNil';

import { TDeviceType, TRound } from './model';
export interface ILeakReading {
  value: boolean | null;
  type: 'LEAK';
}

export interface ITemperatureReading {
  value: number | null;
  type: 'TEMPERATURE';
}

export interface IPressureReading {
  value: number | null;
  type: 'PRESSURE';
}

export interface IFlowMeterReading {
  value: number | null;
  type: 'PULSE_METER';
}

export interface IEquipmentReading {
  value: number | null;
  type: 'EQUIPMENT';
}

export interface IUnknownReading {
  value: null;
  type: 'UNKNOWN';
}

/**
 * @deprecated parseDeviceReadings - use parseDisplayedValue instead
 */
export function parseDeviceReadings<T extends 'TEMPERATURE'>(
  value: number | undefined | null,
  deviceType: T,
  round?: TRound
): ITemperatureReading;
export function parseDeviceReadings<T extends 'PRESSURE'>(
  value: number | undefined | null,
  deviceType: T,
  round?: TRound
): IPressureReading;
export function parseDeviceReadings<T extends 'LEAK'>(
  value: number | undefined | null,
  deviceType: T,
  round?: TRound
): ILeakReading;
export function parseDeviceReadings<T extends 'EQUIPMENT'>(
  value: number | undefined | null,
  deviceType: T,
  round?: TRound
): IEquipmentReading;
export function parseDeviceReadings<T extends 'UNKNOWN'>(
  value: number | undefined | null,
  deviceType: T,
  round?: TRound
): IUnknownReading;
export function parseDeviceReadings<T extends 'TEMPERATURE' | 'PRESSURE' | 'PULSE_METER'>(
  value: number | undefined | null,
  deviceType: T,
  round?: TRound
): ITemperatureReading | IPressureReading | IFlowMeterReading;
export function parseDeviceReadings<
  T extends 'TEMPERATURE' | 'PRESSURE' | 'LEAK' | 'PULSE_METER' | 'EQUIPMENT' | 'UNKNOWN' | undefined
>(
  value: number | undefined | null,
  deviceType: T,
  round?: TRound
): ILeakReading | ITemperatureReading | IPressureReading | IFlowMeterReading | IUnknownReading;

/**
 * @deprecated parseDeviceReadings - use parseDisplayedValue instead
 */
export function parseDeviceReadings<T extends TDeviceType | undefined>(
  value: number | undefined | null,
  deviceType: T,
  round: TRound = 'decimal'
): ILeakReading | ITemperatureReading | IPressureReading | IFlowMeterReading | IEquipmentReading | IUnknownReading {
  let formattedValue: number | null = !isNil(value) ? value : null;

  switch (round) {
    case 'integer': {
      formattedValue = value || value === 0 ? Math.round(value) : null;
      break;
    }

    default:
    case 'decimal': {
      formattedValue = value || value === 0 ? Math.round(value * 10) / 10 : null;
      break;
    }
  }

  switch (deviceType) {
    case 'TEMPERATURE': {
      return {
        value: formattedValue,
        type: 'TEMPERATURE',
      };
    }

    case 'PRESSURE': {
      return {
        value: formattedValue,
        type: 'PRESSURE',
      };
    }

    case 'PULSE_METER': {
      return {
        value: formattedValue,
        type: 'PULSE_METER',
      };
    }

    case 'EQUIPMENT': {
      return {
        value: formattedValue,
        type: 'EQUIPMENT',
      };
    }

    case 'LEAK': {
      return {
        value: isNil(value) ? null : !!value,
        type: 'LEAK',
      };
    }

    case 'UNKNOWN':
    default: {
      return {
        value: null,
        type: 'UNKNOWN',
      };
    }
  }
}
