import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  button: {
    marginLeft: theme.typography.pxToRem(16),
    marginTop: theme.typography.pxToRem(8),
  },

  mobileMenu: {
    padding: 0,
  },

  sideActions: {
    marginRight: theme.typography.pxToRem(16),
    marginTop: theme.typography.pxToRem(8),
    display: 'flex',
  },
}));
