import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.typography.pxToRem(16),
    [theme.breakpoints.down('md')]: {
      marginBottom: 0,
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },

  attachmentInfo: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
  },

  attachmentError: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.error.main,
    fontWeight: theme.typography.fontWeightMedium,
  },

  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
    columnGap: theme.typography.pxToRem(8),
  },

  successContainer: {
    marginTop: theme.typography.pxToRem(8),
    marginBottom: theme.typography.pxToRem(32),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.typography.pxToRem(16),
    },
  },

  warningIcon: {
    display: 'flex',
    '& > svg': {
      color: theme.palette.warning.main,
    },
  },

  errorIcon: {
    display: 'flex',
    '& > svg': {
      color: theme.palette.error.main,
    },
  },

  successIcon: {
    display: 'flex',
    '& > svg': {
      color: theme.palette.success.light,
    },
  },
}));
