import { RolesSchema } from '@marlin/shared/utils-role';
import { z } from 'zod';

export const User = z
  .object({
    id: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    email: z.string(),
    phone: z.string(),
    title: z.string().nullable(),
    roles: RolesSchema,
    deviceAccessCode: z.string().nullish(),
    hasDevicesWithUserAccessCode: z.boolean().optional(),
  })
  .transform(({ roles, ...data }) => {
    const role = roles.shift();

    return {
      ...data,
      role,
      title: data.title || '',
      deviceAccessCode: data.deviceAccessCode || '',
    };
  });

export type TUser = z.infer<typeof User>;
export type TUserProfileForm = Omit<TUser, 'id' | 'hasDevicesWithUserAccessCode'>;
