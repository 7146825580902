import { z } from 'zod';

const baseLocationHierarchyLevelSchema = z.object({
  name: z.string(),
  id: z.string(),
});

const deviceHierarchySchema = z.object({
  name: z.string(),
  locationId: z.string(),
  equipmentId: z.string().nullable(),
  description: z.string().nullable(),
  id: z.string(),
});
type TDeviceHierarchySchema = z.infer<typeof deviceHierarchySchema>;

const equipmentHierarchySchema = z.object({
  name: z.string(),
  locationId: z.string(),
  description: z.string().nullable(),
  id: z.string(),
  locationName: z.string().nullable(),
});
type TEquipmentHierarchySchema = z.infer<typeof equipmentHierarchySchema>;

export type TLocationHierarchyLevel = z.infer<typeof baseLocationHierarchyLevelSchema> & {
  children: TLocationHierarchyLevel[];
  devices: TDeviceHierarchySchema[];
  equipment: TEquipmentHierarchySchema[];
};

export const locationHierarchySchema: z.ZodType<TLocationHierarchyLevel> = baseLocationHierarchyLevelSchema.extend({
  children: z.lazy(() => locationHierarchySchema.array()),
  devices: deviceHierarchySchema.array(),
  equipment: equipmentHierarchySchema.array(),
});

export type TLocationHierarchy = z.infer<typeof locationHierarchySchema>;
