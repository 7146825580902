import { z } from 'zod';

export const inactivityDurationTimeUnitResponseSchema = z.union([
  z.literal('Minutes'),
  z.literal('Hours'),
  z.literal('Days'),
]);
export type TInactivityDurationTimeUnitResponse = z.infer<typeof inactivityDurationTimeUnitResponseSchema>;
export const inactivityDurationTimeUnitSchema = z.union([z.literal('MINUTES'), z.literal('HOURS'), z.literal('DAYS')]);
export type TInactivityDurationTimeUnit = z.infer<typeof inactivityDurationTimeUnitSchema>;

export const mapInactivityDurationTimeUnit = (
  unit: TInactivityDurationTimeUnitResponse | null
): TInactivityDurationTimeUnit | null => {
  switch (unit) {
    case 'Minutes': {
      return 'MINUTES';
    }
    case 'Hours': {
      return 'HOURS';
    }
    case 'Days': {
      return 'DAYS';
    }
    default: {
      return null;
    }
  }
};

export const mapInactivityDurationTimeUnitResponse = (
  unit: TInactivityDurationTimeUnit | null
): TInactivityDurationTimeUnitResponse | null => {
  switch (unit) {
    case 'MINUTES': {
      return 'Minutes';
    }
    case 'HOURS': {
      return 'Hours';
    }
    case 'DAYS': {
      return 'Days';
    }
    default: {
      return null;
    }
  }
};

export const durationSchema = z.object({
  value: z.number().nullable(),
  unit: inactivityDurationTimeUnitSchema.nullable(),
});

interface IMapDurationProps {
  inactivityDuration: number | null;
  inactivityDurationTimeUnit: TInactivityDurationTimeUnit | null;
}

export const mapDuration = ({ inactivityDuration, inactivityDurationTimeUnit }: IMapDurationProps) => ({
  value: inactivityDuration,
  unit: inactivityDurationTimeUnit,
});
