import { useAvailableBoilers, useMetadata } from '@marlin/asset/data-access/equipment';
import { TSettingsGroup } from '@marlin/shared/utils/datapoint-mappers';
import { useMemo } from 'react';

import { useEquipmentDetailsConfigContext } from '../../../shared/context/equipment-details-config-context';
import { content } from '../../content';

interface IUseEquipmentSettingsGroups {
  settingGroups: { id: TSettingsGroup; title: string }[];
  isSettingsGroupsLoading: boolean;
  isSettingsGroupError: boolean;
  checkAvailableBoilers: boolean;
}

export const useEquipmentSettingsGroups = (equipmentId: string): IUseEquipmentSettingsGroups => {
  const {
    config: { settings },
  } = useEquipmentDetailsConfigContext();

  const checkAvailableBoilers = useMemo(() => Boolean(settings?.checkAvailableBoilers), [settings]);
  const { data: metadataResponse, isFetching: isMetadataLoading } = useMetadata({ equipmentId, enabled: false });

  const boilerGroupNames = useMemo(
    () =>
      metadataResponse?.datapointGroups
        ?.filter((group) => group.groupName.includes('Boiler'))
        .map((group) => group.groupName)
        .sort((a, b) => {
          return extractBoilerNumber(a) - extractBoilerNumber(b);
        }) ?? [],
    [metadataResponse?.datapointGroups]
  );

  const {
    data: availableBoilers,
    isLoading: isBoilersLoading,
    isError,
  } = useAvailableBoilers({
    equipmentId,
    checkAvailableBoilers,
  });

  const settingGroups = useMemo<{ id: TSettingsGroup; title: string }[]>(() => {
    const settingGroupsFromConfig = settings?.settingGroups || [];

    if (!checkAvailableBoilers || !availableBoilers?.datapoints.length) {
      return settingGroupsFromConfig;
    }

    return [
      ...settingGroupsFromConfig,
      ...availableBoilers.datapoints.map((boiler) => {
        const boilerNumber = extractBoilerNumber(boiler.name);

        return {
          id: boilerGroupNames[boilerNumber - 1] as TSettingsGroup,
          title: content.BOILER_SETTING(boilerNumber),
        };
      }),
    ];
  }, [availableBoilers?.datapoints, boilerGroupNames, checkAvailableBoilers, settings?.settingGroups]);

  return {
    settingGroups,
    isSettingsGroupsLoading: (checkAvailableBoilers ? isBoilersLoading : false) || isMetadataLoading,
    isSettingsGroupError: isError,
    checkAvailableBoilers,
  };
};

function extractBoilerNumber(input: string): number {
  const match = input.match(/\d+/);
  return match ? Number(match[0]) : 0;
}
