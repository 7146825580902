import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import { TGatewayFilters, TGatewayList, gatewayResponse } from '../gateway.model.schema';
import { paths } from './api';

export const getGateways = async (params: TGatewayFilters): Promise<TGatewayList> => {
  const res = await getHttpClient().post<unknown, unknown>(paths.SEARCHABLE_GATEWAYS, params);

  return safeParseData(res, gatewayResponse);
};
