import { TNode } from '@marlin/system-map/data-access/system-map';
import { useMemo } from 'react';

interface IEquipmentHandleHook {
  assets: TNode[];
  assetSearch: string | undefined;
}

export const useEquipmentHandle = ({ assetSearch, assets }: IEquipmentHandleHook) => {
  const equipmentHandlers = useMemo(() => {
    return assets
      .map((asset) => {
        if (asset.metadata?.metadataGroups && asset.name === assetSearch) {
          return asset?.metadata?.metadataGroups
            ?.map((group) => {
              if (group.handleName && group.handleId) {
                return {
                  id: group.handleId,
                  name: group.handleName,
                };
              }
              return {
                id: '',
                name: '',
              };
            })
            .filter((item) => item.name !== '');
        }
        return undefined;
      })
      .filter((item) => item !== undefined);
  }, [assets, assetSearch]);

  return {
    equipmentHandlers: equipmentHandlers[0],
  };
};
