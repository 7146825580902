import { MultiOrgRoleSchema, MultiOrgRolesSchema } from '@marlin/shared/utils-role';
import { z } from 'zod';

import { paginationSchema, sortingSchema } from './utils.schema';

enum HOMEPAGE {
  Locations = 0,
  Dashboard = 1,
}

const HomepageSchema = z.union([z.literal('LOCATIONS'), z.literal('DASHBOARD')]);

const parseHomepageResponse = (homepage: HOMEPAGE): z.infer<typeof HomepageSchema> => {
  switch (homepage) {
    case HOMEPAGE.Locations: {
      return 'LOCATIONS';
    }
    case HOMEPAGE.Dashboard: {
      return 'DASHBOARD';
    }
  }
};

const parseHomepageRequest = (homepage: z.infer<typeof HomepageSchema>): HOMEPAGE => {
  switch (homepage) {
    case 'LOCATIONS': {
      return HOMEPAGE.Locations;
    }
    case 'DASHBOARD': {
      return HOMEPAGE.Dashboard;
    }
  }
};

export const SettingsResponseSchema = z.object({
  defaultHomePage: z.nativeEnum(HOMEPAGE),
  temperatureUnit: z.string().optional(),
  flowUnit: z.string().optional(),
  energyUnit: z.string().optional(),
  pressureUnit: z.string().optional(),
  timeFormat: z.string().optional(),
  volumeUnit: z.string().optional(),
});

export const SettingsSchema = SettingsResponseSchema.transform((settings) => {
  return {
    ...settings,
    defaultHomePage: parseHomepageResponse(settings.defaultHomePage),
  };
});

export const SettingsParamsSchema = z.object({
  defaultHomePage: HomepageSchema.transform(parseHomepageRequest).optional(),
  temperatureUnit: z.string().optional(),
  flowUnit: z.string().optional(),
  energyUnit: z.string().optional(),
  pressureUnit: z.string().optional(),
  timeFormat: z.string().optional(),
  volumeUnit: z.string().optional(),
});

export const FeaturesSchema = z.object({
  tierId: z.string(),
  features: z.array(z.string()),
});

export const OrganizationSchema = z.object({
  isDefault: z.boolean(),
  roles: MultiOrgRolesSchema,
  organizationId: z.string(),
  createdDateTime: z.string(),
  organizationName: z.string(),
  features: FeaturesSchema,
});

export const OrganizationResponseSchema = z.object({
  id: z.string(),
  email: z.string(),
  organizations: z.array(OrganizationSchema),
  settings: SettingsSchema,
  totalOrganizationsCount: z.number().nullish(),
});

export const CurrentOrganizationResponseSchema = OrganizationResponseSchema.extend({
  currentOrganization: OrganizationSchema,
});

export const organizationListParamsSchema = z.object({
  search: z.string().optional(),
});

export const filteredOrganizationRequestSchema = z.object({
  search: z.string().nullish(),
  page: z.number(),
  pageSize: z.number(),
  sorting: sortingSchema.optional(),
});

export const organizationRowSchema = z.object({
  name: z.string(),
  organizationId: z.string(),
  role: MultiOrgRoleSchema,
  tierName: z.string(),
});

export const filteredOrganizationResponseSchema = z.object({
  pagination: paginationSchema,
  data: z.array(organizationRowSchema),
});

export const organizationClaimsSchema = z.object({
  id: z.string(),
  email: z.string(),
  isSupport: z.boolean(),
  userOrganizations: z.array(
    z.object({
      organizationId: z.string(),
      isActive: z.boolean(),
      userOrganizationClaims: z.array(
        z.object({
          name: z.string(),
          value: z.string(),
        })
      ),
    })
  ),
});

export const organizationClaimsErrorSchema = z.object({
  status: z.number(),
  errorCode: z.number(),
});

export const organizationClaimsResponseSchema = z.union([organizationClaimsSchema, organizationClaimsErrorSchema]);

export const organizationByIdParamsSchema = z.object({
  organizationId: z.string(),
});

export type TOrganizationSchema = z.infer<typeof OrganizationSchema>;
export type TOrganizationRowSchema = z.infer<typeof organizationRowSchema>;
export type TOrganizationResponse = z.infer<typeof OrganizationResponseSchema>;
export type TOrganizationResponseSchema = z.infer<typeof CurrentOrganizationResponseSchema>;
export type THomepage = z.infer<typeof HomepageSchema>;
export type TSettings = z.infer<typeof SettingsSchema>;
export type TSettingsResponse = z.infer<typeof SettingsResponseSchema>;
export type TOrganizationListParams = z.infer<typeof organizationListParamsSchema>;
export type TFilteredOrganizationRequest = z.infer<typeof filteredOrganizationRequestSchema>;
export type TFilteredOrganizationResponse = z.infer<typeof filteredOrganizationResponseSchema>;
export type TOrganizationClaims = z.infer<typeof organizationClaimsSchema>;
export type TOrganizationClaimsError = z.infer<typeof organizationClaimsErrorSchema>;
export type TOrganizationClaimsResponse = z.infer<typeof organizationClaimsResponseSchema>;
export type TOrganizationByIdParams = z.infer<typeof organizationByIdParamsSchema>;
