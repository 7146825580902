import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  label: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(24),
    marginTop: theme.typography.pxToRem(5),
    marginRight: theme.typography.pxToRem(8),
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export const LeakSection = (): JSX.Element => {
  const { classes } = useStyles();

  return (
    <div className={classes.label} data-testid="reading-leak">
      {content.CARD.CURRENT.SENSOR_TYPES.LEAK}
    </div>
  );
};
