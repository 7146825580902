import { getHttpClient, queryClient } from '@marlin/shared/utils/react-query';
import { useMutation } from '@marlin/shared/utils/react-query';
import { AxiosError } from 'axios';

import { paths } from '../infrastructure/api';
import { queryKey } from '../query-key.enum';
import { GatewayConfigurationResponse } from '../schema/gateway-configuration.schema';
import { GatewayPayloadBase, TGatewayPayloadBase } from '../schema/gateway.schema';

export const createGateway = async ({ data }: { data: TGatewayPayloadBase }) => {
  const parsedData = GatewayPayloadBase.parse(data);

  const result = await getHttpClient().post(paths.CREATE_GATEWAY_CONFIGURATION, parsedData, {
    params: {
      deviceId: data.deviceId,
    },
  });

  return GatewayConfigurationResponse.parse(result);
};

type TUseCreateGatewayOptions = {
  onSuccess?: (params: { deviceId: string; configurationId: string }) => void;
  onError?: (error: AxiosError<{ errorMessage: string; status: string }>) => void;
};

export const useCreateGateway = ({ onError, onSuccess }: TUseCreateGatewayOptions = {}) => {
  return useMutation({
    onSuccess: ({ deviceId, id }) => {
      queryClient.invalidateQueries(queryKey.GATEWAY());

      onSuccess && onSuccess({ deviceId: deviceId ?? '', configurationId: id ?? '' });
    },
    onError,
    mutationFn: createGateway,
  });
};
