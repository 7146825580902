import { TSelectedGroupsWithDatapoints } from './types';

export class MultiChartStorageService {
  private selectedKey = 'analytics-multi-chart-selected';

  public setSelectedSensors = ({
    selectedSensors,
    userId,
    organizationId,
  }: {
    selectedSensors: TSelectedGroupsWithDatapoints;
    userId?: string;
    organizationId?: string;
  }) => {
    if (!userId || !organizationId) {
      return;
    }

    const currentValue = this.getParsedSelectedSensorsValue();

    localStorage.setItem(
      this.selectedKey,
      JSON.stringify({ ...currentValue, [`${userId}__${organizationId}`]: selectedSensors })
    );
  };

  public getSelectedSensors = ({
    userId,
    organizationId,
  }: {
    userId?: string;
    organizationId?: string;
  }): TSelectedGroupsWithDatapoints | null => {
    if (!userId || !organizationId) {
      return null;
    }

    const currentValue = this.getParsedSelectedSensorsValue();
    if (!currentValue) {
      return null;
    }

    return currentValue[`${userId}__${organizationId}`] || null;
  };

  public getKey = () => this.selectedKey;

  private getParsedSelectedSensorsValue = () => {
    const value = localStorage.getItem(this.selectedKey);

    if (!value) {
      return null;
    }

    try {
      return JSON.parse(value);
    } catch {
      return null;
    }
  };
}

export const multiChartStorageService = new MultiChartStorageService();
