import { TAutomation, useAutomations } from '@marlin/alert/data-access/automated-action';
import { IFilterParams } from '@marlin/alert/ui/automated-action-list';
import { IRequestPagination } from '@marlin/shared/ui-page';
import { useMemo } from 'react';

export type TRequestParams = IFilterParams & IRequestPagination;

interface IStore {
  automations: TAutomation[];
}

export const useInformationStore = (params?: TRequestParams): IStore => {
  const automationsQuery = useAutomations({
    params,
  });

  const automations: TAutomation[] = useMemo(() => {
    if (!automationsQuery?.data) {
      return [];
    }
    return automationsQuery?.data.data;
  }, [automationsQuery]);

  return {
    automations,
  };
};
