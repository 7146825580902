import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  header: {
    padding: theme.typography.pxToRem(16),
    display: 'flex',
    flexDirection: 'column',
  },
}));
