import { z } from 'zod';

import { MetadataLabel } from './metadata-label.schema';
import { ChartDatapointDataType, ChartDatapointDisplayType, ChartThresholdPosition } from './metadata.schema';

export const ChartDatapoint = z.object({
  name: z.string(),
  isDefault: z.boolean(),
  displayType: ChartDatapointDisplayType,
  dataType: ChartDatapointDataType,
  label: z.array(MetadataLabel).transform((data) => data.find((item) => item.language === 'en-US')?.translation),
  alias: z.string().nullable(),
  orderNumber: z.number(),
  position: ChartThresholdPosition.nullable(),
});
export type TChartDatapoint = z.infer<typeof ChartDatapoint>;

export const ChartDatapointsGroup = z.object({
  groupName: z.string(),
  datapoints: z.array(ChartDatapoint),
});
export type TChartDatapointsGroup = z.infer<typeof ChartDatapointsGroup>;
