import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import { GraphSchema, TGraph, TGraphResponse } from '../flow-map.model.schema';
import { TCreateSystemMapNodeParams, createSystemMapNodeRequestSchema } from '../system-map.model.schema';
import { paths } from './api';

export interface ICreateSystemMapNode {
  flowLinkId: string;
  data: TCreateSystemMapNodeParams;
}

export const createSystemMapNode = async (params: ICreateSystemMapNode): Promise<TGraph> => {
  const body = safeParseData(params.data, createSystemMapNodeRequestSchema);

  const res = await getHttpClient().post<unknown, TGraphResponse>(paths.SYSTEM_MAP_NODE, body, {
    params: { flowLinkId: params.flowLinkId },
  });
  const parsedRes = safeParseData(res, GraphSchema);

  return parsedRes;
};
