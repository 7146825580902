import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { MenuItem, Typography } from '@mui/material';

import { useStyles } from './switcher-action-item.styles';

interface ISwitcherActionItemProps {
  icon: JSX.Element;
  label: string;
  action: () => void;
  testId: string;
}

export const SwitcherActionItem = ({ icon, label, action, testId }: ISwitcherActionItemProps) => {
  const { classes } = useStyles();

  return (
    <div>
      <MenuItem onClick={action} className={classes.container} data-testid={`${testId}-toggle`}>
        <div className={classes.createContainer}>
          {icon}
          <Typography className={classes.createText}>{label}</Typography>
        </div>
        <div className={classes.iconContainer}>
          <KeyboardArrowRightRoundedIcon fontSize="small" dadata-testid={`${testId}-open-icon`} />
        </div>
      </MenuItem>
    </div>
  );
};
