import { TLastReadingValue } from './last-reading-values.schema';
import { TMetadata, TMetadataGroup, TMetadataLayout, TSensor } from './metadata-model.schema';

interface IDynamicDatapointConfig {
  name: string;
  matches: TLastReadingValue[];
  ids: string[];
}

interface IGroupConfig {
  row: (index: number) => number;
  column: (index: number) => number;
  handleId: (index: number) => string;
  type: 'row' | 'column';
}

const dynamicTemplateMark = '{X}';
const regexGroup = '(?<id>\\d+)';

let maxColumn = 0;
let maxRow = 0;

const sortSensors = (a: TSensor, b: TSensor) => {
  if (!a.displayDataPointName) {
    return -1;
  }
  if (!b.displayDataPointName) {
    return 1;
  }
  return a.displayDataPointName.localeCompare(b.displayDataPointName);
};

const mergeGroups = (groups: TMetadataGroup[], val: TMetadataGroup): TMetadataGroup[] => {
  const currentGroup = groups.find((group) => group.handleId === val.handleId);
  if (!currentGroup) {
    return [...groups, val];
  }
  const filterOutCurrentAcc = groups.filter((group) => group.handleId !== val.handleId);
  return [
    ...filterOutCurrentAcc,
    { ...currentGroup, sensors: [...currentGroup.sensors, ...val.sensors].sort(sortSensors) },
  ];
};

const filterDynamicSensors = (datapointName: string, dynamicSensors: string[]) => (sensor: TSensor) =>
  sensor.displayDataPointName !== datapointName && !dynamicSensors.includes(sensor.displayDataPointName ?? '');

const getStatusDatapointName = (
  sensor: TSensor | undefined,
  match: TLastReadingValue,
  datapointConfig: IDynamicDatapointConfig
) => {
  const sensorId = datapointConfig.ids.find((id) => match.name.includes(id));
  if (!sensorId) {
    return sensor?.statusDataPointName;
  }
  return sensor?.statusDataPointName?.replace(dynamicTemplateMark, sensorId);
};

const repeatGroup = (
  group: TMetadataGroup,
  datapointConfigs: IDynamicDatapointConfig[],
  repeatableSensors: string[],
  config: IGroupConfig
): TMetadataGroup[] => {
  return datapointConfigs
    .flatMap((datapoint) => {
      const matchedSensor = group.sensors.find((sensor) => sensor.displayDataPointName === datapoint.name);
      const matches = datapoint.matches;

      if (matches.length === 1) {
        // note: if the cell is repeatable, but has only one match, we want to repeat the cell as many times, as other cells are repeated
        const maxRepeats = config.type === 'column' ? maxColumn : maxRow;
        for (let i = 1; i < maxRepeats - 1; i++) {
          matches.push(matches[0]);
        }
      }

      return matches.map((match, index) => {
        if (config.column(index) > maxColumn) {
          maxColumn = config.column(index);
        }
        if (config.row(index) > maxRow) {
          maxRow = config.row(index);
        }
        return {
          ...group,
          column: config.column(index),
          row: config.row(index),
          handleId: config.handleId(index),
          sensors: [
            ...group.sensors.filter(filterDynamicSensors(datapoint.name, repeatableSensors)),
            {
              ...matchedSensor,
              displayDataPointName: match.name,
              statusDataPointName: getStatusDatapointName(matchedSensor, match, datapoint),
            },
          ],
        };
      });
    })
    .reduce(mergeGroups, [] as TMetadataGroup[]);
};

const calculateLayout = (groups?: TMetadataGroup[], layout?: TMetadataLayout | null): TMetadataLayout | undefined => {
  if (!layout || !groups) {
    return undefined;
  }

  return {
    columns: groups.reduce((acc, val) => (val.column > acc ? val.column : acc), 0),
    rows: groups.reduce((acc, val) => (val.row > acc ? val.row : acc), 0),
  };
};

const matchReadingsToIds = (regex: RegExp) => (ids: string[], reading: TLastReadingValue) => {
  const match = reading.name.match(regex);
  if (!match) {
    return ids;
  }
  return Array.from(new Set([...ids, match.groups?.['id'] ?? '']));
};

export const mapDynamicMetadata = (
  metadata?: TMetadata | null,
  readings?: TLastReadingValue[]
): TMetadata | null | undefined => {
  if (!metadata || !readings) {
    return metadata;
  }

  const groups = metadata.metadataGroups
    ?.flatMap((group) => {
      if (!group.repeatable) {
        return group;
      }
      const repeatableType = group.repeatable;
      const dynamicSensors = group.sensors
        .map((sensor) => sensor.displayDataPointName)
        .filter((sensor) => !!sensor) as string[];

      const dynamicDatapointConfig = dynamicSensors.map((datapoint) => {
        const regex = new RegExp(`^${datapoint.replace(dynamicTemplateMark, regexGroup)}$`, 'i');
        return {
          name: datapoint,
          matches: readings.filter((reading) => regex.test(reading.name)),
          ids: readings.reduce(matchReadingsToIds(regex), [] as string[]),
        };
      });

      switch (repeatableType) {
        case 'column': {
          return repeatGroup(group, dynamicDatapointConfig, dynamicSensors, {
            column: (index: number) => group.column + index,
            row: () => group.row,
            handleId: (index: number) => `${group.row}:${group.column + index}`,
            type: 'column',
          });
        }
        case 'row': {
          return repeatGroup(group, dynamicDatapointConfig, dynamicSensors, {
            column: () => group.column,
            row: (index: number) => group.row + index,
            handleId: (index: number) => `${group.row + index}:${group.column}`,
            type: 'row',
          });
        }
        case 'group': {
          return {
            ...group,
            sensors: dynamicDatapointConfig.flatMap((datapoint) => {
              const matchedSensor = group.sensors.find((sensor) => sensor.displayDataPointName === datapoint.name);
              return datapoint.matches.map((match) => ({
                ...matchedSensor,
                displayDataPointName: match.name,
                statusDataPointName: getStatusDatapointName(matchedSensor, match, datapoint),
              }));
            }),
          };
        }
      }

      return group;
    })
    .reduce(mergeGroups, [] as TMetadataGroup[]);

  return {
    ...metadata,
    layout: calculateLayout(groups, metadata.layout),
    metadataGroups: groups,
  };
};
