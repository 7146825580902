export const content = {
  SUCCESS_MESSAGE: 'Profile successfully updated',
  ERROR_DEFAULT: 'An error occurred, please try again',
  BUTTONS: {
    DONE: 'Update',
    CANCEL: 'Cancel',
    CLOSE: 'Close',
  },
  ACCESS_CODE_TITLE: 'IntelliStation Equipment Access Code',
  ACCESS_CODE_SUBTITLE: 'Equipment access code needed to be able to change your IntelliStation settings.',
  ACCESS_CODE_HELPER: 'All 5 digits cannot be the same',
  ACCESS_CODE_LABEL: '5 Digit Numeric Access Code',
  REQUIRED: 'This is a required field',
  SAME_DIGITS_ERROR: 'All 5 digits cannot be the same',
  MIN_MAX_DIGITS_ERROR: 'Value must have 5 characters',
  ACCESS_CODE_MODAL_TITLE: 'IntelliStation Equipment Access Code',
  ACCESS_CODE_MODAL_BODY: 'There may be a delay before the equipment has the new information.',
  DISCARD_MESSAGE: 'Changes have been discarded',
};
