import type {
  TDeviceBrandsCompatibleWithWatts,
  TGatewayConfigurationResponse,
} from '@marlin/asset/data-access/gateway';

import { TUpsertConnectionFormSchemaTypeUpdate } from './upsert-connection-form.schema';

export const mapConfigurationResponseToDefaultValues = (
  data?: TGatewayConfigurationResponse,
  brands?: TDeviceBrandsCompatibleWithWatts,
  isError?: boolean
): TUpsertConnectionFormSchemaTypeUpdate | undefined => {
  if (!data || !brands || isError) return undefined;

  return {
    id: data.id,
    connections: data.connections.map((connection) => {
      const addresses = connection.addresses.map((address) => {
        const brand = brands.find((b) => b.models.find((m) => m.id === address.modelId));
        const equipmentModel = brand?.models.find((m) => m.id === address.modelId)?.id ?? '';
        const equipmentBrand = brand?.id ?? '';
        return {
          id: address.id,
          modbusAddress: address.address,
          equipmentBrand,
          equipmentModel,
          name: address.name,
          locationId: address.locationId,
        };
      });

      return {
        id: connection.id,
        connectionType: connection.name,
        baudRate: connection.network.baudRate,
        dataBits: connection.network.dataBits,
        stopBits: connection.network.stopBits,
        parity: connection.network.parity,
        addresses: addresses.sort((a, b) => a.modbusAddress - b.modbusAddress),
      };
    }),
  };
};
