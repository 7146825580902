import { PopperOwnProps } from '@mui/base';
import { Popper, PopperProps, useMediaQuery, useTheme } from '@mui/material';

const useModifiers = (): PopperOwnProps['modifiers'] => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (!isMobile) return [];

  return [
    {
      name: 'sizes',
      enabled: true,
      phase: 'beforeWrite',
      requires: ['computeStyles'],
      effect: ({ state }) => {
        state.elements.popper.style.width = '100vw';
        if ('clientHeight' in state.elements.reference) {
          state.elements.popper.style.height = `calc(100vh - ${
            state.elements.reference.clientHeight
          }px - ${theme.typography.pxToRem(36)})`;
          state.elements.popper.style.zIndex = `${theme.zIndex.modal - 1}`;
        }
      },
    },
    {
      name: 'offset',
      options: {
        offset: [0, 12],
      },
    },
  ];
};

export const CustomPopper = (props: PopperProps) => {
  const { children, ...other } = props;
  const modifiers = useModifiers();

  return (
    <Popper {...other} modifiers={modifiers}>
      {children}
    </Popper>
  );
};
