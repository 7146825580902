import { TDateString, getTimeFromNow } from '@marlin/shared/utils-common-date';
import isNil from 'lodash/isNil';

const lostCommunicationPeriodInHours = 8;

export const checkIfDeviceHasLostCommunication = (lastReadingTime: TDateString): boolean => {
  const durationSinceLastReading = getTimeFromNow(lastReadingTime, 'hours');

  if (isNil(durationSinceLastReading)) {
    return true;
  }

  return durationSinceLastReading >= lostCommunicationPeriodInHours;
};
