import { HttpResponse, ResponseResolver } from 'msw';

import { defaultHandler } from './handler-default';
import { createPredictionsResponse } from './mock-predictions';
import { createGoogleResponse, getSearchInput } from './mock-utils';

export const predictionsHandler: ResponseResolver = ({ request, ...rest }) => {
  if (request) {
    const url = new URL(request.url);
    const { decodedInput, isMockRequested } = getSearchInput(url.search);
    if (isMockRequested) {
      const response = createPredictionsResponse(decodedInput);
      return HttpResponse.text(
        createGoogleResponse(JSON.stringify(response), url.searchParams.get('callback') || 'callback')
      );
    }
  }
  return defaultHandler({ request, ...rest });
};
