import { TRecipient as TRecipientExtended } from '@marlin/account-data-access-recipient';
import { IAlert, IRecipient } from '@marlin/alert/data-access/alert-action';
import {
  CHANNEL_TYPE,
  IAlert as IExtendedAlert,
  IRecipient as IRecipientUi,
  content,
} from '@marlin/alert/ui/alert-list';

const getRecipients = (recipients: IRecipient[], recipientList?: TRecipientExtended[]): IRecipientUi[] => {
  return recipients.map((recipient) => {
    const currentRecipient = recipientList?.find((recipientExtended) => recipientExtended.id === recipient.id);

    return {
      id: currentRecipient?.id ?? recipient.id,
      firstName: currentRecipient?.firstName ?? content.CARD.RECIPIENTS.UNKNOWN,
      lastName: currentRecipient?.lastName ?? content.CARD.RECIPIENTS.UNKNOWN,
      status: recipient.status,
      error: recipient.error,
    };
  });
};

export const mapExtendedAlerts = (alert: IAlert, recipientList?: TRecipientExtended[]): IExtendedAlert => {
  return {
    ...alert,
    channels: {
      [CHANNEL_TYPE.EMAIL]: {
        type: CHANNEL_TYPE.EMAIL,
        recipients: getRecipients(alert.channels[CHANNEL_TYPE.EMAIL]?.recipientIds ?? [], recipientList),
      },
      [CHANNEL_TYPE.SMS]: {
        type: CHANNEL_TYPE.SMS,
        recipients: getRecipients(alert.channels[CHANNEL_TYPE.SMS]?.recipientIds ?? [], recipientList),
      },
      [CHANNEL_TYPE.HOT_SOS]: {
        type: CHANNEL_TYPE.HOT_SOS,
        status: alert.channels[CHANNEL_TYPE.HOT_SOS]?.status ?? null,
        errorMessage: alert.channels[CHANNEL_TYPE.HOT_SOS]?.errorMessage ?? '',
      },
    },
  };
};
