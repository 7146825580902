import { useLocation } from '@marlin/asset/data-access/location';
import { LOCATION_TYPE } from '@marlin/asset/data-access/location';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { PageContainer } from '@marlin/shared/ui-page';
import { routes } from '@marlin/shared/utils-routes';
import { EQUIPMENT_TYPE } from '@marlin/shared/utils/zod';
import { Navigate, useParams } from 'react-router-dom';

import { Header } from './components/header.component';
import { Performance } from './components/performance.component';
import { PlantList } from './components/plant-list/plant-list.component';
import { EquipmentsTelemetryContextProvider } from './context/equipments-telemetry.context';
import { PlantConfigContextProvider } from './context/plant-config.context';
import { useLocationEquipments } from './hooks/use-location-equipments';
import { PLANT_TYPE } from './types';

export function Plant() {
  const { locationId = '' } = useParams();
  const { data, isFetching } = useLocation({ locationId });
  const { data: equipmentList, isFetching: isEquipmentListFetching } = useLocationEquipments(locationId);
  const isPlant = data?.locationType === LOCATION_TYPE.PLANT;

  if (isFetching || isEquipmentListFetching) return <LoadingSpinner />;
  if (!isPlant) return <Navigate to={routes.locations.details.url(locationId)} replace />;

  const plantType = equipmentList?.[0]?.type === EQUIPMENT_TYPE.BOILER ? PLANT_TYPE.Boiler : PLANT_TYPE.WaterHeater;

  return (
    <PlantConfigContextProvider model={plantType}>
      <EquipmentsTelemetryContextProvider>
        <PageContainer>
          <Header id={locationId} name={data?.name || ''} />
          <Performance equipmentList={equipmentList} />
          <PlantList equipmentList={equipmentList} plantType={plantType} />
        </PageContainer>
      </EquipmentsTelemetryContextProvider>
    </PlantConfigContextProvider>
  );
}
