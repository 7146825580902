import { brushChartId, mainChartId } from '../const';
import { CHART_SELECTION_TYPE, IChartSelectionEvent, ISelection } from './use-chart-selection.hook';

export const handleMainRangeChange = (selection: ISelection) => {
  ApexCharts.exec(mainChartId, 'updateOptions', {
    xaxis: {
      min: selection.from,
      max: selection.to,
    },
  });
};

export const handleBrushSelectionChange = (selection: ISelection) => {
  ApexCharts.exec(brushChartId, 'updateOptions', {
    chart: {
      selection: {
        xaxis: {
          min: selection.from,
          max: selection.to,
        },
      },
    },
  });
};

export const handleBrushRangeChange = ({ selection }: IChartSelectionEvent) => {
  ApexCharts.exec(brushChartId, 'updateOptions', {
    xaxis: {
      min: selection.from,
      max: selection.to,
    },
  });
};

export const onMainChartSelectionChange = (event: IChartSelectionEvent) => {
  if ([CHART_SELECTION_TYPE.BRUSH_CHART, CHART_SELECTION_TYPE.EXTERNAL].includes(event.type)) {
    handleMainRangeChange(event.selection);
  }

  if ([CHART_SELECTION_TYPE.MAIN_CHART, CHART_SELECTION_TYPE.EXTERNAL].includes(event.type)) {
    handleBrushSelectionChange(event.selection);
  }
};
