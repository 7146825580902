import { MarlinTheme } from '@marlin/shared/theme';
import { SxProps, useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  dataGridWrapper: {
    height: theme.typography.pxToRem(600),
    width: '100%',
  },
  cellStyle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flexWrap: 'nowrap',
  },
  gatewayName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flexWrap: 'nowrap',
  },
  paper: {
    padding: 0,
  },
  column: {
    '&.MuiDataGrid-columnHeader': {
      padding: theme.typography.pxToRem(16),
      backgroundColor: theme.palette.background.alternative,
    },
    '& > .MuiDataGrid-columnSeparator': {
      visibility: 'hidden',
    },
  },
}));

export const useDataGridSx = (): SxProps<MarlinTheme> => {
  const theme = useTheme();

  return {
    border: 0,
    '& .MuiDataGrid-cell': {
      padding: theme.typography.pxToRem(16),
    },
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: theme.palette.grey.A100, // TODO use color from palette
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
  };
};
