import { zodResolver } from '@hookform/resolvers/zod';
import { MarlinTheme } from '@marlin/shared/theme';
import { FormField, IFormFieldChildrenProps, Input, Select } from '@marlin/shared/ui-form-common';
import { CustomSizedModal } from '@marlin/shared/ui-modal';
import { TOrgAddress } from '@marlin/shared/utils/zod';
import { Button } from '@mui/material';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';
import { PostalCodeControl } from '../../postal-code-control/postal-code-control.component';
import { StateControl } from '../../state-select/state-select.component';
import { countryList, emptyAddress } from './consts';
import { AddressFormDataSchema } from './manual-address-entry.schema';

interface IManualAddressEntryModalProps {
  onClose: () => void;
  onSave: (address: TOrgAddress) => void;
  defaultValue: TOrgAddress | null;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  modalFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    columnGap: theme.typography.pxToRem(8),
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between',
      '& button': {
        width: `calc(50% - ${theme.typography.pxToRem(8)})`,
      },
    },
  },
  formItem: {
    width: '100%',
    marginBottom: theme.typography.pxToRem(32),
  },
  inputRow: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.typography.pxToRem(24),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: 0,
    },
  },
  postalCode: {
    maxWidth: theme.typography.pxToRem(260),
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      marginTop: theme.typography.pxToRem(32),
    },
  },
}));

export const ManualAddressEntryModal = ({ onSave, onClose, defaultValue }: IManualAddressEntryModalProps) => {
  const { classes } = useStyles();

  const form = useForm<TOrgAddress>({
    mode: 'onTouched',
    resolver: zodResolver(AddressFormDataSchema),
    defaultValues: defaultValue ? defaultValue : emptyAddress,
  });

  const selectedCountry = form.watch('country');

  const onSaveClick = () => {
    onSave(form.getValues());
  };

  const onCountryChange = (e: React.ChangeEvent<HTMLInputElement>, props: IFormFieldChildrenProps) => {
    if ((e?.target?.value as string) !== selectedCountry) {
      form.setValue('state', '');
      form.setValue('postalCode', '');
      setTimeout(() => {
        form.clearErrors('state');
        form.clearErrors('postalCode');
      }, 0);
    }

    return props.onChange && props.onChange(e);
  };

  return (
    <CustomSizedModal
      onClose={onClose}
      width={680}
      title={content.ADDRESS_FORM.NEW_LOCATION_ADDRESS}
      mobileFullScreen
      footer={
        <div className={classes.modalFooter}>
          <Button variant="text" data-testid="manual-address-modal-cancel-button" onClick={onClose}>
            {content.ADDRESS_FORM.CANCEL}
          </Button>
          <Button
            variant="contained"
            data-testid="manual-address-modal-export-button"
            onClick={onSaveClick}
            disabled={!form.formState.isDirty || !form.formState.isValid}
          >
            {content.ADDRESS_FORM.SAVE}
          </Button>
        </div>
      }
      body={
        <FormProvider {...form}>
          <form>
            <FormField<TOrgAddress> fieldName="country">
              {(props) => (
                <Select
                  {...props}
                  prefix="country"
                  label={content.ADDRESS_FORM.COUNTRY_LABEL}
                  data={countryList}
                  className={classes.formItem}
                  required
                  onChange={(e) => onCountryChange(e, props)}
                />
              )}
            </FormField>
            <FormField<TOrgAddress> fieldName="address1">
              {(props) => (
                <Input
                  className={classes.formItem}
                  required
                  testId="address-1"
                  label={content.ADDRESS_FORM.ADDRESS_1_LABEL.get(selectedCountry ?? '')}
                  {...props}
                />
              )}
            </FormField>
            <FormField<TOrgAddress> fieldName="address2">
              {(props) => (
                <Input
                  className={classes.formItem}
                  testId="address-2"
                  label={content.ADDRESS_FORM.ADDRESS_2_LABEL.get(selectedCountry ?? '')}
                  {...props}
                />
              )}
            </FormField>
            <div className={classes.inputRow}>
              <FormField<TOrgAddress> fieldName="city">
                {(props) => (
                  <Input className={classes.formItem} label={content.ADDRESS_FORM.CITY_LABEL} required {...props} />
                )}
              </FormField>
              <StateControl<TOrgAddress> fieldName="state" country={selectedCountry} required />
            </div>
            <div className={classes.postalCode}>
              <PostalCodeControl<TOrgAddress>
                required
                fieldName="postalCode"
                country={selectedCountry}
                className={classes.formItem}
              />
            </div>
          </form>
        </FormProvider>
      }
    />
  );
};
