import { FullLogo, MarlinTheme } from '@marlin/shared/theme';
import { CopyrightFooter } from '@marlin/shared/ui-page';
import { Typography } from '@mui/material';
import { ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

import { ReactComponent as ErrorIcon } from './warning-icon.svg';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  errorWrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.typography.pxToRem(16),
    paddingTop: theme.typography.pxToRem(48),
    'overflow-y': 'auto',
  },
  icon: {
    marginBottom: theme.typography.pxToRem(24),
  },
  title: {
    fontSize: theme.typography.pxToRem(34),
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(20),
    },
  },
  subtitle: {
    marginTop: theme.typography.pxToRem(8),
    color: theme.palette.text.secondary,
    textAlign: 'center',
    maxWidth: theme.typography.pxToRem(676),
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.typography.pxToRem(8),
    width: '100%',
  },
}));

interface IBaseErrorProps {
  title: ReactNode;
  subtitle: ReactNode;
}

export const BaseError = ({ title, subtitle }: IBaseErrorProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.errorWrapper}>
      <FullLogo mobileHeight={26} />
      <div className={classes.content}>
        <ErrorIcon className={classes.icon} />
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.subtitle}>{subtitle}</Typography>
      </div>
      <CopyrightFooter />
    </div>
  );
};
