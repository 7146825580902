import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';

export const useVisualViewportHeight = () => {
  const [viewportHeight, setViewportHeight] = useState<number | undefined>(window.visualViewport?.height);

  useEffect(() => {
    const onResize = debounce((event: Event) => {
      setViewportHeight((event.target as VisualViewport).height);
    }, 1);

    window.visualViewport?.addEventListener('resize', onResize);
    return () => window.visualViewport?.removeEventListener('resize', onResize);
  }, []);

  return viewportHeight;
};
