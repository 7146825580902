import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  dataGridWrapper: {
    height: theme.typography.pxToRem(369),
    width: '100%',
  },

  container: {
    marginTop: theme.typography.pxToRem(24),
    padding: 0,
  },

  header: {
    padding: theme.typography.pxToRem(24),
  },

  column: {
    '&.MuiDataGrid-columnHeader': {
      padding: theme.typography.pxToRem(16),
      backgroundColor: theme.palette.background.alternative,
    },
    '& > .MuiDataGrid-columnSeparator': {
      visibility: 'hidden',
    },
  },

  arrows: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)', // TODO use color from palette, needs to be rgba, because MUI highlight
  },

  dataGrid: {
    border: 0,
    '& .MuiDataGrid-cell': {
      padding: theme.typography.pxToRem(16),
    },
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: theme.palette.background.alternative,
    },
  },

  cellTypeWrapper: {
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.typography.pxToRem(10),
    flexWrap: 'wrap',
    color: theme.palette.action.active,
  },

  attachmentLabel: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(14),
  },
}));
