export const content = {
  SENSOR_HEALTH: {
    ALL_SENSORS_OPERATIONAL: 'All Sensors Operational',
    PERFORM_SENSOR_MAINTENANCE: 'Perform Sensor Maintenance',
    CHECK_COMMUNICATION: 'Check Communication',
  },
  SYSTEM_HEALTH: {
    SYSTEM_OPERATIONAL: 'System Operational',
    HIGH_AND_LOW_ALERTS_OPEN: 'High and Low Criticality Alerts Open',
    HIGH_ALERTS_OPEN: 'High Criticality Alerts Open',
    LOW_ALERTS_OPEN: 'Low Criticality Alerts Open',
  },
  NO_AUTOMATIONS: 'No Performance Automations Created Yet',
  NO_SENSORS: 'No Sensors Connected Yet',

  PERFORMANCE_STATUS: {
    OPEN_ALERTS: 'Open Alerts',
    SYSTEM_OPERATIONAL: 'All Operational',
  },
  HEALTH_STATUS: {
    CHECK_COMMUNICATION: 'Check Communication',
    CHECK_ERRORS: 'Check Errors',
    CHECK_COMMUNICATION_AND_ERRORS: 'Check Communication and Errors',
    ALL_SENSORS_OPERATIONAL: 'All Sensors Operational',
    PERFORM_SYSTEM_MAINTENANCE: 'Perform Sensor Maintenance',
  },
};
