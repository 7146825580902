import { useDashboardChartOptions } from '@marlin/asset/shared/ui/chart-options';
import { Tooltip } from '@marlin/asset/shared/ui/chart-switcher-wrapper';
import { HeaderDatapoint } from '@marlin/asset/shared/ui/datapoint-display';
import { MarlinTheme } from '@marlin/shared/theme';
import { SelectDuration } from '@marlin/shared/ui-form';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { EmptyChartData } from '@marlin/shared/ui/chart';
import { RANGE_FILTER, isOfChartRangeType, plotlyAdapterSchema, useRangeFilter } from '@marlin/shared/utils-chart';
import { getFormattedValue } from '@marlin/shared/utils-format-reading';
import { TEquipment, TLastReadingValue } from '@marlin/shared/utils/zod';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  SelectChangeEvent,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import uniq from 'lodash/uniq';
import React, { useCallback, useMemo } from 'react';
import Plot from 'react-plotly.js';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';
import { useEquipmentsTelemetryContext } from '../context/equipments-telemetry.context';
import { usePlantConfigContext } from '../context/plant-config.context';
import { useChartData } from '../hooks/use-chart-data.hook';
import { useConverterChartData } from '../hooks/use-equipment-dashboard-chart-data.hook';
import { getDatapoint } from '../utils/get-datapoint';
import { TopAlertsWrapper } from './top-alerts-wrapper.component';

const chartHeight = {
  mobile: 200,
  desktop: 300,
};

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  accordion: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '&:before': {
      content: 'none',
    },
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  accordionDetails: {
    padding: 0,
  },
  productDetailsWrapper: {
    display: 'flex',
    gap: theme.typography.pxToRem(12),
    marginBottom: theme.typography.pxToRem(12),
    flexBasis: '50%',
    [theme.breakpoints.down('md')]: {
      flexBasis: '100%',
      flexWrap: 'wrap',
      marginBottom: theme.typography.pxToRem(8),
    },
  },
  container: {
    display: 'flex',
    gap: theme.typography.pxToRem(24),
    margin: ` 0 0 ${theme.typography.pxToRem(24)} 0`,
    [theme.breakpoints.down('md')]: {
      marginBottom: 0,
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: theme.palette.background.primary,
  },
  paper: {
    width: '100%',
    height: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.typography.pxToRem(24),
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
    borderBottomLeftRadius: theme.typography.pxToRem(4),
    borderBottomRightRadius: theme.typography.pxToRem(4),
    borderTop: 'none',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      columnGap: theme.typography.pxToRem(16),
    },
  },
  headerWrapper: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      padding: theme.typography.pxToRem(16),
    },
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.typography.pxToRem(2),
    [theme.breakpoints.down('md')]: {
      lineHeight: theme.typography.pxToRem(32),
    },
  },
  titleMobile: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.typography.pxToRem(2),
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(26),
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(12),
    marginBottom: theme.typography.pxToRem(12),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.typography.pxToRem(4),
      lineHeight: theme.typography.pxToRem(20),
    },
  },
  overviewTitle: {
    fontSize: theme.typography.pxToRem(16),
    marginBottom: theme.typography.pxToRem(12),
  },
  mainChart: {
    minHeight: theme.typography.pxToRem(chartHeight.desktop),
    width: '100%',
    [theme.breakpoints.down('md')]: {
      minHeight: theme.typography.pxToRem(chartHeight.mobile),
    },
  },
  chartContainer: {
    position: 'relative',
    width: '100%',
    '.nsewdrag.drag.cursor-pointer': {
      cursor: 'default',
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.typography.pxToRem(16),
    },
  },
  chartTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightBold,
  },
  alertsWrapper: {
    flexBasis: '80%',
  },
  chartWrapper: {
    marginBottom: 0,
    paddingBottom: 0,
    [theme.breakpoints.down('md')]: {
      padding: 0,
      border: 'none',
    },
  },
  durationWrapper: {
    padding: `0 ${theme.typography.pxToRem(16)}`,
    '& > div': {
      width: '100%',
    },
  },
}));

interface IPerformance {
  equipmentList: TEquipment[];
}

interface IPerformanceValue {
  performance: { name: string; label: string }[];
  lastReadingValues: TLastReadingValue[];
  manufacturerId: string | undefined;
}

const usePerformanceValue = ({ performance, lastReadingValues, manufacturerId = '' }: IPerformanceValue) => {
  const { equipmentsTelemetry } = useEquipmentsTelemetryContext();
  const data = equipmentsTelemetry[manufacturerId] ?? undefined;

  const performanceTilesData = useMemo(() => {
    return (performance || []).map((performanceDatapoint) => {
      const datapointValues = getDatapoint(performanceDatapoint.name, lastReadingValues, data);

      return {
        value: datapointValues,
        ...performanceDatapoint,
      };
    });
  }, [performance, lastReadingValues, data]);

  return { performanceTilesData };
};

const getManufacturerId = (manager: TEquipment | undefined) => {
  return manager?.devices?.[0]?.manufacturerId ?? '';
};

export const Performance = ({ equipmentList }: IPerformance) => {
  const { classes, cx } = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const equipmentIds = equipmentList.map((equipment) => equipment.id);
  const { equipmentsTelemetry } = useEquipmentsTelemetryContext();
  const fireRate =
    equipmentList.reduce((acc, equipment) => {
      const [{ manufacturerId = '', lastReadingValues = [] }] = equipment.devices || [];
      const telemetry = equipmentsTelemetry[manufacturerId];
      const fireRate = getDatapoint('FireRateOut', lastReadingValues, telemetry);

      const currentFireRate = fireRate ? parseInt(fireRate) : 0;

      return acc + currentFireRate;
    }, 0) / equipmentList.length;

  const {
    config: {
      title,
      overviewTitle,
      subtitle,
      managerDatapointName,
      performance,
      calculatedValue,
      chartData: { datapointNames, datapointGroupNames },
    },
  } = usePlantConfigContext();

  const { handleRangeFilterChange, rangeFilter } = useRangeFilter(RANGE_FILTER.HOURS_8);
  const onRangeFilterChange = useCallback(
    (event: SelectChangeEvent<RANGE_FILTER>) => {
      const newRangeFilter = event.target.value;

      if (isOfChartRangeType(newRangeFilter)) {
        handleRangeFilterChange(newRangeFilter);
      }
    },
    [handleRangeFilterChange]
  );

  const manager = equipmentList.find(({ devices }) => {
    const managerId =
      devices?.[0].lastReadingValues?.find(
        (value: { name: string }) => value.name.toLowerCase() === managerDatapointName.toLowerCase()
      )?.value || '';

    if (managerId === devices?.[0].manufacturerId) return true;

    const currentEquipmentDatapointName = 'commAddr';
    const commAddr =
      devices?.[0].lastReadingValues?.find(
        (value: { name: string }) => value.name.toLowerCase() === currentEquipmentDatapointName.toLowerCase()
      )?.value || '';

    return managerId === commAddr;
  });

  const { isLoading, refetch, datapointsGroupMetadata, telemetryData } = useChartData({
    manufacturerId: getManufacturerId(manager),
    datapointGroupNames,
    datapointNames,
    rangeFilter,
    equipmentId: manager?.id,
  });
  const { chartData, from, to } = useConverterChartData({
    rangeFilter: rangeFilter.range,
    datapointNames,
    datapointsGroupMetadata,
    telemetryData,
  });
  const uomsForSeries = useMemo(() => uniq([...chartData].map((s) => s?.uom)).filter(Boolean), [chartData]);
  const data = useMemo(() => {
    return plotlyAdapterSchema.parse({
      series: chartData,
      uoms: uomsForSeries,
    });
  }, [chartData, uomsForSeries]);
  const {
    chartOptions: { layout, config, handleUnhover, handleHover },
    tooltip,
  } = useDashboardChartOptions({ chartData, from, to });
  const [{ lastReadingValues = [] } = {}] = manager?.devices || [];
  const { performanceTilesData } = usePerformanceValue({
    performance,
    lastReadingValues,
    manufacturerId: getManufacturerId(manager),
  });

  return (
    <div className={classes.container}>
      <div className={classes.wrapper} data-testid="equipment-product-details">
        <Box className={cx(classes.paper, classes.headerWrapper)}>
          <Typography variant="h5" className={classes.title}>
            {title}
          </Typography>
          <Typography variant="body1" className={classes.subtitle}>
            {subtitle}
          </Typography>
          {!isMobile && (
            <Typography variant="h6" className={classes.overviewTitle}>
              {overviewTitle}
            </Typography>
          )}
          <div className={classes.productDetailsWrapper}>
            {performanceTilesData.map(({ name, label, value }) => (
              <HeaderDatapoint
                key={name}
                label={label}
                value={value ? value : content.EMPTY_DATAPOINT_VALUE}
                controlState={value ? 'active' : 'inactive'}
                isLoading={false}
              />
            ))}
            {calculatedValue && (
              <HeaderDatapoint
                key={'firing-rate'}
                label={content.DATAPOINT_LABELS.FIRING_RATE}
                value={getFormattedValue(`${fireRate}`, '%')}
                controlState={'active'}
                isLoading={false}
              />
            )}
          </div>
        </Box>
        <Accordion className={classes.accordion} defaultExpanded expanded={!isMobile ? true : undefined}>
          {isMobile && (
            <AccordionSummary expandIcon={isMobile ? <ExpandMoreIcon /> : undefined}>
              <Typography variant="h5" className={classes.titleMobile}>
                {content.MOBILE_CHART_TITLE}
              </Typography>
            </AccordionSummary>
          )}
          <AccordionDetails className={classes.accordionDetails}>
            <Box className={cx(classes.paper, classes.chartWrapper)}>
              <div className={classes.mainChart} data-testid="equipment-dashboard-chart">
                {isMobile ? (
                  <div className={classes.durationWrapper}>
                    <SelectDuration
                      rangeFilter={rangeFilter.range}
                      onRangeFilterChange={onRangeFilterChange}
                      onRefresh={refetch}
                    />
                  </div>
                ) : (
                  <div className={classes.chartTitle}>
                    <Typography variant="h5" className={classes.title}>
                      {content.CHART_TITLE}
                    </Typography>
                    <SelectDuration
                      rangeFilter={rangeFilter.range}
                      onRangeFilterChange={onRangeFilterChange}
                      onRefresh={refetch}
                    />
                  </div>
                )}
                {isLoading ? (
                  <LoadingSpinner />
                ) : (
                  <div className={classes.chartContainer}>
                    <Plot
                      data={data}
                      layout={layout}
                      config={config}
                      useResizeHandler={true}
                      style={{
                        width: '100%',
                        height: chartHeight[isMobile ? 'mobile' : 'desktop'],
                      }}
                      onHover={handleHover}
                      onUnhover={handleUnhover}
                    />
                    <Tooltip {...(tooltip || {})} />
                    <EmptyChartData chartData={chartData} from={from} to={to} />
                  </div>
                )}
              </div>
            </Box>
          </AccordionDetails>
        </Accordion>
        {isMobile && <Divider />}
      </div>
      {!isTablet && (
        <TopAlertsWrapper equipmentList={equipmentList} equipmentIds={equipmentIds} classes={classes.alertsWrapper} />
      )}
    </div>
  );
};
