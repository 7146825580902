import { MarlinTheme } from '@marlin/shared/theme';
import { Input } from '@marlin/shared/ui-form-common';
import { TNode } from '@marlin/system-map/data-access/system-map';
import { TNodeType } from '@marlin/system-map/shared/data-access-schemas';
import { Autocomplete as AutocompleteMui, alpha } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { mapAssetGroup } from './constants';
import { content } from './content';
import { ASSET_TYPE } from './types';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  groupHeader: {
    backgroundColor: alpha(theme.palette.primary.main, 0.04),
    padding: `${theme.typography.pxToRem(12)} ${theme.typography.pxToRem(16)}`,
  },

  groupList: {
    padding: 0,
  },
}));

interface INodeProps {
  id: string;
  options: TNode[];
  value?: TNode;
  inputValue?: string;
  onChange?: (value: TNode | null) => void;
  onInputChange?: (value: string | undefined) => void;
  error?: FieldError;
  showGroup: boolean;
}

export const NodeAutocomplete = ({
  id,
  options,
  showGroup,
  value,
  onChange,
  inputValue,
  onInputChange,
  error,
}: INodeProps) => {
  const { classes } = useStyles();

  const { trigger } = useFormContext();

  const handleAssetChange = useCallback(
    (event: unknown, newValue: TNode | null | string) => {
      if (typeof newValue === 'string') {
        return;
      }
      if (onChange) {
        onChange(newValue ?? null);
        trigger();
      }
    },
    [onChange, trigger]
  );
  const handleInputChange = useCallback(
    (_event: unknown, newValue: string) => {
      if (onInputChange) {
        return onInputChange(newValue.length ? newValue : undefined);
      }
    },
    [onInputChange]
  );

  const currentError = useMemo(() => {
    if (error && 'equipmentHandle' in error) {
      return undefined;
    }
    return error;
  }, [error]);

  return (
    <AutocompleteMui
      data-testid="node-autocomplete"
      value={value ?? null}
      inputValue={inputValue}
      onChange={handleAssetChange}
      onInputChange={handleInputChange}
      options={options.sort((a, b) => (b.assetType || '').localeCompare(a.assetType || ''))}
      groupBy={(option: TNode) => option.assetType || option.nodeType || ''}
      filterOptions={(x) => x} /* NOTE: Search as you type needs native autocomplete search disabled */
      getOptionLabel={(option: TNode | string) => (typeof option === 'string' ? option : option.name || '')}
      renderOption={(props, option: TNode) => (
        <li {...props} key={option.id}>
          {option.name}
        </li>
      )}
      renderInput={(params) => <Input label={content.ASSET_LABEL} {...params} error={currentError} />}
      renderGroup={(params) => (
        <li key={params.key}>
          {showGroup && (
            <div className={classes.groupHeader}>{mapAssetGroup(params.group as ASSET_TYPE | TNodeType)}</div>
          )}
          <ul className={classes.groupList}>{params.children}</ul>
        </li>
      )}
      isOptionEqualToValue={(option: TNode, value: TNode) => option.id === value.id}
      noOptionsText={content.NO_RESULTS}
      loading
      freeSolo
    />
  );
};
