import { MarlinTheme } from '@marlin/shared/theme';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  stepperWrapper: {
    width: '100%',
  },
  stepper: {
    padding: `${theme.typography.pxToRem(16)} 0`,
  },
  label: {
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(23),
    letterSpacing: theme.typography.pxToRem(0.17),
  },
  title: {
    color: theme.palette.text.primary,
    [theme.breakpoints.down('lg')]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  stepLabel: {
    [theme.breakpoints.down('lg')]: {
      padding: `${theme.typography.pxToRem(3)} 0`,
    },
  },
  stepLabelActive: {
    color: theme.palette.primary.main,
  },
}));

interface IVerticalLinearStepperProps {
  steps: {
    title: string;
    component: JSX.Element | null;
    additionalLabel?: string;
  }[];
  activeStep: number;
}

export default function VerticalLinearStepper({ steps, activeStep }: IVerticalLinearStepperProps) {
  const { classes, cx } = useStyles();

  return (
    <div className={classes.stepperWrapper}>
      <Stepper activeStep={activeStep} orientation="vertical" className={classes.stepper}>
        {steps.map(({ title, component, additionalLabel }, index) => (
          <Step key={title} data-testid={`step-${index}`}>
            <StepLabel
              className={cx(classes.stepLabel)}
              optional={additionalLabel && <Typography className={classes.label}>{additionalLabel}</Typography>}
            >
              <Typography
                className={cx(classes.title, { [classes.stepLabelActive]: index === activeStep })}
                variant="subtitle1"
              >
                {title}
              </Typography>
            </StepLabel>
            <StepContent data-testid={`step-${index}-content`}>{component}</StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
