import { MarlinTheme } from '@marlin/shared/theme';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  label: {
    marginRight: theme.typography.pxToRem(4),
  },

  labelItem: {
    flexBasis: '20%',
    maxWidth: '20%',
    minWidth: theme.typography.pxToRem(104),
    color: theme.palette.text.secondary,
  },

  value: {
    fontSize: theme.typography.pxToRem(14),
  },

  detailsRow: {
    alignItems: 'baseline',
  },
}));

interface IDetailsItemProps {
  label: string;
  value: string | JSX.Element;
  testId: string;
}

export function DetailsItem({ label, value, testId }: IDetailsItemProps) {
  const { classes } = useStyles();
  return (
    <Grid wrap="nowrap" container className={classes.detailsRow} data-testid={testId}>
      <Grid item className={classes.labelItem}>
        <Typography className={classes.label} variant="overline">
          {label}:
        </Typography>
      </Grid>
      <Grid item>
        <span className={classes.value}>{value}</span>
      </Grid>
    </Grid>
  );
}
