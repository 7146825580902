import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import {
  TAssetDeviceParamsSchema,
  TAssetDeviceResponseSchema,
  assetDeviceParamsSchema,
  assetDeviceResponseSchema,
} from '../schemas/assets/assets-device.schema';
import {
  TAssetLocationParamsSchema,
  TAssetResponseSchema,
  assetLocationParamsSchema,
  assetResponseSchema,
} from '../schemas/assets/assets.schema';
import { paths } from './api';

export const getDeviceAssets = async (params?: TAssetDeviceParamsSchema): Promise<TAssetDeviceResponseSchema> => {
  const body = safeParseData(params, assetDeviceParamsSchema);
  const res = await getHttpClient().post(paths.ASSETS_DEVICE_LIST, body);
  return assetDeviceResponseSchema.parse(res);
};

export const getLocationAssets = async (params?: TAssetLocationParamsSchema): Promise<TAssetResponseSchema> => {
  const body = safeParseData(params, assetLocationParamsSchema);

  const res = await getHttpClient().post(paths.ASSETS_LOCATION_LIST, body);
  return assetResponseSchema.parse(res);
};

export const getEquipmentAssets = async (params?: TAssetLocationParamsSchema): Promise<TAssetResponseSchema> => {
  const body = safeParseData(params, assetLocationParamsSchema);
  const res = await getHttpClient().post(paths.ASSETS_EQUIPMENT_LIST, body);
  return assetResponseSchema.parse(res);
};
