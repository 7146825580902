import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.typography.pxToRem(8),
    position: 'relative',
  },
  togglePeriod: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  togglePeriodItem: {
    display: 'flex',
    alignItems: 'center',
  },
  toggleButton: {
    paddingLeft: theme.typography.pxToRem(12),
  },
  customToggleBtn: {
    gap: theme.typography.pxToRem(4),
  },
  customToggleBtnSelected: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  periodText: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(12),
    fontStyle: 'normal',
    fontWeight: '400',
    color: theme.palette.text.primary,
    textTransform: 'uppercase',
    letterSpacing: theme.typography.pxToRem(1),
    lineHeight: theme.typography.pxToRem(24),
  },
  buttonText: {
    textTransform: 'none',
    fontSize: theme.typography.pxToRem(14),
  },
  modal: {
    backgroundColor: theme.palette.background.secondary,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: theme.typography.pxToRem(4),
    padding: theme.typography.pxToRem(16),
    [theme.breakpoints.down('md')]: {
      width: `calc(100% - ${theme.typography.pxToRem(32)})`,
      marginRight: theme.typography.pxToRem(16),
    },
  },
}));
