import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import { PostalCodeControl, StateControl, TimezoneControl } from '@marlin/shared/ui-form';
import { FormField, Input, Select } from '@marlin/shared/ui-form-common';
import { Button, useMediaQuery, useTheme } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { AddressFormDataSchema, TAddressForm } from './address-form-data-schema';
import { countryList, emptyDefaultValues } from './constants';
import { content } from './content';
import { useStyles } from './upsert-address.component.styles';

interface IUpsertLocationProps {
  disableSubmit?: boolean;
  onSubmit: (value: TAddressForm) => Promise<void>;
  onCancel?: () => void;
  defaultValues?: TAddressForm;
  editMode: boolean;
  toggleEditMode: () => void;
}

export const UpsertAddress = ({
  disableSubmit,
  onSubmit: onSubmitFromProps,
  onCancel,
  defaultValues: defaultValuesFromProps,
  editMode,
  toggleEditMode,
}: IUpsertLocationProps) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const defaultValues = useMemo(() => defaultValuesFromProps || emptyDefaultValues, [defaultValuesFromProps]);

  const form = useForm<TAddressForm>({
    mode: 'onTouched',
    resolver: zodResolver(AddressFormDataSchema),
    defaultValues,
  });

  const selectedCountry = form.watch('country');

  const timezone = form.watch('timeZone');

  useEffect(() => {
    // note: form does not trigger validation on timezone change
    form.trigger('timeZone');
  }, [form, timezone]);

  const onSubmit = useCallback(
    (data: TAddressForm) =>
      onSubmitFromProps(data).then(() => {
        form.reset(data);
      }),
    [onSubmitFromProps, form]
  );

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className={classes.fullWidth}>
        <div className={classes.row}>
          <FormField<TAddressForm> fieldName="country">
            {(props) => (
              <Select
                {...props}
                prefix="country"
                label={content.COUNTRY_LABEL}
                data={countryList}
                className={classes.fullWidth}
                required
                disabled={!editMode}
                onChange={(e) => {
                  if ((e?.target?.value as string) !== selectedCountry) {
                    form.setValue('state', '');
                    form.setValue('postalCode', '');
                    setTimeout(() => {
                      form.clearErrors('state');
                      form.clearErrors('postalCode');
                    }, 0);
                  }

                  return props.onChange && props.onChange(e);
                }}
              />
            )}
          </FormField>
        </div>
        <div className={classes.row}>
          <FormField<TAddressForm> fieldName="address1">
            {(props) => (
              <Input
                label={content.ADDRESS_1_LABEL.get(selectedCountry ?? '')}
                {...props}
                required
                disabled={!editMode}
              />
            )}
          </FormField>
          <FormField<TAddressForm> fieldName="address2">
            {(props) => (
              <Input label={content.ADDRESS_2_LABEL.get(selectedCountry ?? '')} {...props} disabled={!editMode} />
            )}
          </FormField>
        </div>
        <div className={classes.row}>
          <FormField<TAddressForm> fieldName="city">
            {(props) => <Input label={content.CITY_LABEL} {...props} required disabled={!editMode} />}
          </FormField>
          <StateControl<TAddressForm> fieldName="state" country={selectedCountry} required disabled={!editMode} />
        </div>
        <div className={classes.row}>
          <PostalCodeControl<TAddressForm>
            fieldName="postalCode"
            country={selectedCountry}
            required
            disabled={!editMode}
          />
          <div className={classes.fullWidth} />
        </div>
        <div className={classes.row}>
          <div className={classes.subsectionTitle}>{content.ORGANIZATION_TIME_ZONE}</div>
        </div>
        <div className={classes.row}>
          <div className={classes.fullWidth}>
            <TimezoneControl<TAddressForm> fieldName="timeZone" />
          </div>
        </div>
        <div className={classes.row}>
          {!isMobile && (
            <Button
              data-testid="organization-address-cancel"
              variant="outlined"
              onClick={
                onCancel
                  ? onCancel
                  : () => {
                      form.reset(defaultValues);
                      toggleEditMode();
                    }
              }
            >
              {content.BUTTON_CANCEL}
            </Button>
          )}
          <Button
            data-testid="organization-address-submit"
            variant="contained"
            onClick={form.handleSubmit(onSubmit)}
            disabled={disableSubmit || !form.formState.isDirty || !form.formState.isValid}
          >
            {content.BUTTON_UPDATE}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
