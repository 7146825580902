import { TExtractFnReturnType, useQuery } from '@marlin/shared/utils/react-query';

import { getDefaultFlowMeters } from '../infrastructure/get-default-flow-meters';
import { queryKey } from './query-key.enum';

export const useDefaultFlowMeters = () => {
  return useQuery<TExtractFnReturnType<typeof getDefaultFlowMeters>>({
    queryFn: () => getDefaultFlowMeters(),
    queryKey: queryKey.DEFAULT_FLOW_METERS(),
  });
};
