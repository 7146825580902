import { TFlowLinkType } from '@marlin/system-map/data-access/system-map';
import { AttachementForm as UIAttachementForm } from '@marlin/system-map/ui/attachement-upsert-form';

import { useAttachmentForm } from './use-attachment-form.hook';

const defaultValues = {
  inlet: undefined,
  outlet: undefined,
};

export interface IAttachementFormProps {
  changeFlowLinkFormType: (flowLinkFormType: TFlowLinkType) => void;
}

export const AttachementForm = ({ changeFlowLinkFormType }: IAttachementFormProps) => {
  const { onSubmit: createAttachment } = useAttachmentForm();

  return (
    <UIAttachementForm
      defaultValues={defaultValues}
      onSubmit={createAttachment}
      changeFlowLinkFormType={changeFlowLinkFormType}
    />
  );
};
