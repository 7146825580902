import { queryClient } from '@marlin/shared/utils/react-query';

import { queryKey } from '../query-key.enum';
import { IUser, IUserList, IUserListFilters } from '../user.model';

export const deleteUserFromListCache = (id: string, params?: IUserListFilters): IUserList | undefined => {
  const previousUsers: IUserList | undefined = queryClient.getQueryData(queryKey.USER_LIST(params));

  queryClient.setQueryData<IUserList | undefined>(queryKey.USER_LIST(params), (data?: IUserList) => {
    if (!data) {
      return data;
    }

    return {
      ...data,
      data: data.data.filter((recipient: IUser) => recipient.id !== id) || [],
    };
  });

  return previousUsers;
};
