import { TFlowLink } from '@marlin/asset/data-access/asset';
import { routes } from '@marlin/shared/utils-routes';

import { content } from './content';

export const getAttachmentUrl = (attachment: TFlowLink, deviceId?: string) => {
  if (attachment.inletAssetId === deviceId) {
    return attachment.outletAssetType === 'EQUIPMENT'
      ? routes.equipments.details.url(attachment.outletAssetId ?? '')
      : routes.locations.details.url(attachment.outletAssetId ?? '');
  }

  return attachment.inletAssetType === 'EQUIPMENT'
    ? routes.equipments.details.url(attachment.inletAssetId ?? '')
    : routes.locations.details.url(attachment.inletAssetId ?? '');
};

export const getAttachmentName = (attachment: TFlowLink, deviceId?: string) => {
  if (!deviceId) {
    return content.NOT_APPLICABLE;
  }

  if (attachment.inletAssetId === deviceId) {
    return attachment.outletAssetName;
  }

  return attachment.inletAssetName;
};

export const extractAtachementLink = (links: TFlowLink[]): TFlowLink | null => {
  return links.filter((link) => link.inletNodeType === 'ATTACHMENT' || link.outletNodeType === 'ATTACHMENT')[0] || null; // INFO: We are taking first element, because attachment can have only one flow link
};
