import { useMultiChartsStore } from '@marlin/asset/ui/charts';
import { MarlinTheme } from '@marlin/shared/theme';
import { CustomSizedModal } from '@marlin/shared/ui-modal';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { HorizontalLinearStepper } from '@marlin/shared/ui/steppers';
import Button from '@mui/material/Button';
import { useCallback, useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';
import { useSelectedSensorsStore, useSetSelectedSensors } from '../../context/selected-sensors.context';
import { useSetSensorFiltering } from '../../context/sensor-filtering.context';
import { generateChartId } from '../../utils/generate-chart-id';
import { ChartNameInput } from './chart-name-input.component';
import { SelectChartTypeWrapper } from './select-chart-type-wrapper.component';
import { SensorsWrapper } from './sensors-wrapper.component';

interface IStep {
  component: JSX.Element;
  title: string;
  isDisabled?: (args: { chartType: string; sensorsCount: number; chartName: string }) => boolean;
}

const steps: IStep[] = [
  {
    component: <SelectChartTypeWrapper />,
    title: content.ADD_CHART.SELECT_CHART_TYPE,
    isDisabled: ({ chartType }) => !chartType,
  },
  {
    component: <SensorsWrapper />,
    title: content.ADD_CHART.SELECT_SENSORS,
    isDisabled: ({ sensorsCount }) => !sensorsCount,
  },
  { component: <ChartNameInput />, title: content.ADD_CHART.NAME_CHART, isDisabled: ({ chartName }) => !chartName },
];

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    width: '100%',
    marginLeft: theme.typography.pxToRem(8),
  },
  stepperButtons: {
    display: 'flex',
    gap: theme.typography.pxToRem(8),
  },
  content: {
    width: '100%',
  },
}));

export const AddChartModal = ({ onClose }: { onClose: () => void }) => {
  const { classes } = useStyles();
  const [activeStep, setActiveStep] = useState(0);

  const [{ selectedDatapoints, chartName = '', chartType }, setStore] = useMultiChartsStore((store) => store.addChart);
  const setSelectedSensors = useSetSelectedSensors();
  const [selectedSensors] = useSelectedSensorsStore((store) => store);

  const setSensorFiltering = useSetSensorFiltering();

  const names = useMemo(
    () => Object.values(selectedSensors).map((chart) => chart.chartName.toLowerCase()),
    [selectedSensors]
  );
  const isNameUnique = Boolean(chartName) && !names.includes(chartName.toLowerCase());

  const { enqueueSnackbar } = useSnackbar();

  const clearChartForm = useCallback(() => {
    setStore({
      addChart: {
        selectedDatapoints: [],
        chartName: '',
        chartType: '',
      },
    });
    setSensorFiltering({ selectedChartType: '', selectedEquipmentId: '', selectedLocationId: '' });
  }, [setSensorFiltering, setStore]);

  const handleOnClose = () => {
    clearChartForm();

    onClose();
  };

  const handleNextClick = () => {
    if (activeStep === steps.length - 1) {
      const selectedChartType = chartType ? chartType : 'custom';
      const chartId = generateChartId();

      setSelectedSensors({
        [chartId]: {
          chartType: selectedChartType,
          chartName,
          chartDatapoints: selectedDatapoints,
        },
      });

      handleOnClose();

      enqueueSnackbar(content.ADD_CHART_SUCCESSFULLY, {
        variant: 'success',
        preventDuplicate: true,
      });

      return;
    }

    setActiveStep((prevStep) => prevStep + 1);
  };

  return (
    <CustomSizedModal
      width={800}
      onClose={handleOnClose}
      title={content.ADD_CHART.TITLE}
      disableCloseOnBackdropClick
      body={
        <div className={classes.content}>
          <HorizontalLinearStepper activeStep={activeStep} steps={steps} />
        </div>
      }
      footer={
        <div className={classes.footer}>
          <Button variant="text" onClick={handleOnClose}>
            {content.ADD_CHART.CANCEL}
          </Button>
          <div className={classes.stepperButtons}>
            {activeStep !== 0 && (
              <Button variant="text" onClick={() => setActiveStep((prevStep) => prevStep - 1)}>
                {content.ADD_CHART.BACK}
              </Button>
            )}
            <Button
              variant="contained"
              onClick={handleNextClick}
              data-testid="add-chart-modal-btn"
              disabled={
                steps[activeStep]
                  ? steps[activeStep].isDisabled?.({
                      chartType: chartType,
                      sensorsCount: selectedDatapoints.length,
                      chartName,
                    }) ||
                    (activeStep === 2 && !isNameUnique)
                  : false
              }
            >
              {activeStep === steps.length - 1 ? content.ADD_CHART.SUBMIT : content.ADD_CHART.NEXT}
            </Button>
          </div>
        </div>
      }
    />
  );
};
