import { getHttpClient } from '@marlin/shared/utils/react-query';

import { IPinDevice, IPinDeviceResponse } from '../pin-device.model';
import { paths } from './api';
import { createDevicePinDto } from './dtos/pin-device.response';

export const getPinDevices = async (): Promise<IPinDevice[]> => {
  const res = await getHttpClient().get<unknown, IPinDeviceResponse[]>(paths.PIN_DEVICES);

  return res.map((value) => createDevicePinDto(value));
};
