import { useMediaQuery, useTheme } from '@mui/material';

import { DesktopLocationHub } from './desktop/locations-hub.component';
import { MobileLocationHub } from './mobile/location-hub.component';

export function LocationHub() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (isMobile) {
    return <MobileLocationHub />;
  }

  return <DesktopLocationHub />;
}
