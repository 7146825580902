import { environment } from '@marlin/environment';

export const content = {
  API_KEY_TITLE: 'Monnit API Key',
  API_KEY_HEADER: 'API Key Header',
  API_KEY_VALUE: 'API Key Value',
  API_KEY_COPY_SUCCESS: 'Copied text to clipboard',
  ORGANIZATION_ID: 'Organization ID',
  ORGANIZATION_ID_HEADER: `${environment.app.name} Organization ID`,
};
