import { IUser } from './user.model';
import { userService } from './user.service';

interface IUserService {
  getUser(): IUser | null;
  setUserOrganization(organizationId: string): void;
}

export class UserFacade {
  public constructor(private userService: IUserService) {}

  public getUser = (): IUser | null => this.userService.getUser();

  public setUserOrganization = (organizationId: string): void => this.userService.setUserOrganization(organizationId);
}

export const userFacade = new UserFacade(userService);
