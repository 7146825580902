export const content = {
  SUBTITLE: 'Use the form below to edit your equipment.',
  BUTTON_CANCEL: 'Cancel',
  CONFIRMATION_MESSAGE_UPDATE_EQUIPMENT_ERROR: `Something went wrong. Your equipment hasn't been updated`,
  CONFIRMATION_MESSAGE_UPDATE_EQUIPMENT_SUCCESS: 'Your equipment has been updated',
  ASSIGNED_SENSORS_ERROR: 'Cannot change Location if equipment has assigned sensors',
  DELETE: 'Delete',
  DEREGISTER: 'Deregister',
  EQUIPMENT_FETCHING_ERROR: 'Something went wrong. Equipment details have not been fetched.',
  DEREGISTER_GATEWAY_EQUIPMENT: (link: string) => (
    <div>
      You must remove the equipment from the gateway configuration to deregister it.
      <br />
      <a href={link}>Go to Gateway Configuration</a>
    </div>
  ),
};
