import { content } from './content';

export const predefinedIssueCategories = [
  {
    id: content.CATEGORIES.SENSOR_ISSUE,
    name: content.CATEGORIES.SENSOR_ISSUE,
  },
  {
    id: content.CATEGORIES.SOFTWARE_ISSUE,
    name: content.CATEGORIES.SOFTWARE_ISSUE,
  },
  {
    id: content.CATEGORIES.SALES_QUESTION,
    name: content.CATEGORIES.SALES_QUESTION,
  },
  {
    id: content.CATEGORIES.NEW_FEATURE_REQUEST,
    name: content.CATEGORIES.NEW_FEATURE_REQUEST,
  },
  {
    id: content.CATEGORIES.OTHER_QUESTION,
    name: content.CATEGORIES.OTHER_QUESTION,
  },
];
