import { MarlinTheme } from '@marlin/shared/theme';
import { CommonChip } from '@marlin/shared/ui/chips';
import { useSearchParamsRepository } from '@marlin/shared/utils-router';
import { ISensorHubParams } from '@marlin/shared/utils-routes';
import { useController } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { BatteryStatusChip } from '../../shared/components/battery-status-chip.component';
import { LostCommunicationChip } from '../../shared/components/lost-communication-chip.component';
import { IAsset, IDeviceHubFilters } from './types';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  locationChipsWrapper: {
    marginLeft: theme.typography.pxToRem(16),
    marginBottom: theme.typography.pxToRem(16),
  },
}));

export const FilterChips = () => {
  const { classes } = useStyles();

  const { getSearchParam, removeSearchParam } = useSearchParamsRepository<ISensorHubParams>();

  const batteryLevelStatus = getSearchParam('batteryLevelStatus');
  const filterByLostCommunication = !!getSearchParam('lostCommunication');

  const {
    field: { value: locations, onChange: onChangeLocations },
  } = useController<IDeviceHubFilters>({ name: 'locations' });

  const removeLocationChip = (id: string): IAsset[] => {
    return (locations as IAsset[])?.filter((asset) => asset.id !== id);
  };

  const removeBatteryUrlParam = (): void => {
    if (batteryLevelStatus) {
      removeSearchParam('batteryLevelStatus');
    }
  };

  const removeLostCommunicationUrlParam = (): void => {
    if (filterByLostCommunication) {
      removeSearchParam('lostCommunication');
    }
  };

  if ((locations as IAsset[]).length === 0 && !batteryLevelStatus && !filterByLostCommunication) {
    return null;
  }

  return (
    <div className={classes.locationChipsWrapper}>
      {(locations as IAsset[])?.map((value: IAsset) => (
        <CommonChip
          testId={`filterChip-${value.id}`}
          key={value.id}
          active={true}
          label={value.name}
          onDelete={() => onChangeLocations && onChangeLocations({ target: { value: removeLocationChip(value.id) } })}
        />
      ))}
      {batteryLevelStatus && <BatteryStatusChip batteryStatus={batteryLevelStatus} onDelete={removeBatteryUrlParam} />}
      {filterByLostCommunication && <LostCommunicationChip onDelete={removeLostCommunicationUrlParam} />}
    </div>
  );
};
