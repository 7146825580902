import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { MenuItem, Typography } from '@mui/material';

import { useStyles } from './organization-details-create.styles';

interface ICreateMenuItemProps {
  opened?: boolean;
  handleToggle: () => void;
  buttonText: string;
  variant: 'organization' | 'portfolio' | 'support';
}

export const CreateMenuItem = ({ opened = false, handleToggle, buttonText, variant }: ICreateMenuItemProps) => {
  const { classes } = useStyles();

  return (
    <MenuItem
      disableRipple={opened}
      onClick={handleToggle}
      className={classes.container}
      data-testid={`create-${variant}-toggle`}
    >
      <div className={classes.createContainer}>
        <AddCircleRoundedIcon />
        <Typography className={classes.createText}>{buttonText}</Typography>
      </div>
      <div className={classes.iconContainer}>
        {opened ? (
          <CloseRoundedIcon fontSize="small" data-testid={`create-${variant}-close-icon`} />
        ) : (
          <KeyboardArrowRightRoundedIcon fontSize="small" dadata-testid={`create-${variant}-open-icon`} />
        )}
      </div>
    </MenuItem>
  );
};
