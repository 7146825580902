import { TAlertChartData } from '@marlin/asset/data-access/telemetry';
import { MarlinTheme } from '@marlin/shared/theme';
import { dateAdapter } from '@marlin/shared/utils-common-date';
import { useTheme } from '@mui/material';
import { useMemo } from 'react';

import { ICharTicks, IChartPoint, IRangedAreaSeries, TAlertBuckets, TGroupedAlertAnnotation } from '../../../../types';
import { defaultAutomationOptions } from '../../automations/const';
import { IAutomationOptions } from '../../automations/types';
import { reduceAlertGroupsToOptionsFactory } from '../../automations/utils';
import { groupAlerts } from './group-alerts';

interface IUseGroupedAlerts {
  groupedAlerts: TAlertBuckets;
  groupedAlertData: IRangedAreaSeries[];
  groupedAlertOptions: IAutomationOptions;
  groupedAlertsAnnotations: TGroupedAlertAnnotation[];
}

export const useGroupedAlerts = (
  alerts: TAlertChartData[],
  data: IChartPoint[],
  ticks: ICharTicks
): IUseGroupedAlerts => {
  const theme = useTheme();

  const groupedAlerts: TAlertBuckets = useMemo(() => {
    return groupAlerts(alerts, data);
  }, [data, alerts]);

  const groupedAlertData: IRangedAreaSeries[] = useMemo(() => {
    return Object.values(groupedAlerts).map((alert) => {
      return {
        name: `alert-${alert.alertId}`,
        type: 'rangeArea',
        ruleId: alert.ruleId,
        data: alert.buckets.map((timestamp) => {
          return {
            x: timestamp,
            y: [ticks.lowest, ticks.highest],
          };
        }),
      };
    });
  }, [groupedAlerts, ticks.highest, ticks.lowest]);

  const groupedAlertOptions: IAutomationOptions = useMemo(() => {
    const optionsReducer = reduceAlertGroupsToOptionsFactory(theme as unknown as MarlinTheme);

    return Object.values(groupedAlerts).reduce(optionsReducer, defaultAutomationOptions);
  }, [groupedAlerts, theme]);

  const groupedAlertsAnnotations = useMemo(() => {
    return Object.values(groupedAlerts).map((alert) => {
      const color = alert.criticality === 'HIGH' ? theme.palette.error.main : theme.palette.warning.main;

      return {
        id: `alert-${alert.alertId}`,
        ruleId: alert.ruleId,
        x: dateAdapter.date(alert.startTime)?.valueOf() || dateAdapter.date(0)?.valueOf(),
        strokeDashArray: 0,
        borderColor: color,
      };
    });
  }, [groupedAlerts, theme.palette.error.main, theme.palette.warning.main]);

  return {
    groupedAlerts,
    groupedAlertData,
    groupedAlertOptions,
    groupedAlertsAnnotations,
  };
};
