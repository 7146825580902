import { queryClient, useMutation } from '@marlin/shared/utils/react-query';
import { AxiosError } from 'axios';

import { createWebhook } from '../infrastructure/create-webhook';
import { TCreateWebhookParams } from '../schemas/create-webhook.schema';
import { TWebhook } from '../schemas/webhook.schema';
import { queryKey } from './query-key.enum';

export const useCreateWebhook = () => {
  return useMutation<unknown, AxiosError, TCreateWebhookParams, TWebhook>({
    mutationFn: createWebhook,

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.WEBHOOK() });
      queryClient.invalidateQueries({ queryKey: queryKey.FILTERED_WEBHOOKS() });
    },
  });
};
