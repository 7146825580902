import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  content: {
    display: 'flex',
    width: '100%',
    height: '100%',
    padding: theme.typography.pxToRem(16),
    flexDirection: 'column',
    gap: theme.typography.pxToRem(16),
  },
  chart: {
    height: theme.typography.pxToRem(400),
  },
  modal: {
    backgroundColor: theme.palette.background.secondary,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: theme.typography.pxToRem(4),
    padding: theme.typography.pxToRem(16),
    [theme.breakpoints.down('md')]: {
      width: `calc(100% - ${theme.typography.pxToRem(32)})`,
      marginRight: theme.typography.pxToRem(16),
    },
  },
  chips: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: theme.typography.pxToRem(8),
    flexWrap: 'wrap',
  },
  text: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(12),
    fontStyle: 'normal',
    fontWeight: '400',
    color: theme.palette.text.primary,
    textTransform: 'uppercase',
    letterSpacing: theme.typography.pxToRem(1),
    lineHeight: theme.typography.pxToRem(24),
  },
  topWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexFlow: 'row wrap',
    gap: theme.typography.pxToRem(8),
  },
}));
