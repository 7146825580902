import { IChartSeries, TChartType } from '@marlin/shared/utils-chart';
import { getFormattedValue } from '@marlin/shared/utils-format-reading';

export const getTotalVolume = ({ chartData, chartType }: { chartData: IChartSeries[]; chartType: TChartType }) => {
  if (chartType !== 'flow') {
    return { totalVolume: null, maxTotalVolumeForDatapoint: null };
  }

  const uom = chartData.find((datapoint) => datapoint.data.length)?.uom;
  const maxYaxisValue: Record<number, number> = {};
  const allData = chartData.map((datapoint) => datapoint.data).flat();

  const totalVolume = allData.reduce((acc, point) => {
    if (!point.y) return acc;
    const currentMax = maxYaxisValue[point.x] || 0;
    maxYaxisValue[point.x] = currentMax + point.y;

    return acc + point.y;
  }, 0);

  const totalVolumeDisplayValue = getFormattedValue(String(totalVolume), uom);

  const allMaxes = Object.values(maxYaxisValue).filter((value) => !isNaN(value));

  const maxTotalVolumeForTimestamp = allMaxes.length ? Math.max(...allMaxes) : 0;

  return { totalVolume: totalVolumeDisplayValue, maxTotalVolumeForTimestamp };
};
