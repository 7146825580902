import { MarlinTheme } from '@marlin/shared/theme';
import { Chip as MuiChip, alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { ChipAliasLabel } from './components/chip-alias-label.component';
import { ChipColorLabel } from './components/chip-color-label.component';

type TChipBase = {
  label: string;
  toggleChip: (label: string) => void;
  isActive: boolean;
  name: string;
  dataTestId?: string;
};

type TChipColor = TChipBase & {
  color: string;
};

type TChipAlias = TChipBase & {
  alias: string;
};

type TChip = TChipBase & (TChipColor | TChipAlias);

const useChipStyles = makeStyles<{ isActive: boolean }>()((theme: MarlinTheme, { isActive }) => ({
  chip: {
    backgroundColor: isActive ? alpha(theme.palette.primary.main, 0.12) : theme.palette.primary.contrastText,
    borderColor: alpha(theme.palette.primary.main, 0.5),
    '&.MuiChip-clickable:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.04),
    },
  },
  label: {
    padding: 0,
  },
}));

export const ChartChip = ({ label, toggleChip, isActive, name, dataTestId, ...props }: TChip) => {
  const { classes } = useChipStyles({ isActive });

  if ('alias' in props) {
    return (
      <MuiChip
        variant={'outlined'}
        label={<ChipAliasLabel label={label} alias={props.alias} />}
        onClick={() => {
          toggleChip(name);
        }}
        classes={{
          root: classes.chip,
          label: classes.label,
        }}
        data-testid={`chart-chip-${label.replace(/\s/g, '-').replace(/-{2,}/g, '-')}`}
      />
    );
  }

  return (
    <MuiChip
      variant={'outlined'}
      label={<ChipColorLabel label={label} color={props.color} />}
      onClick={() => {
        toggleChip(name);
      }}
      classes={{
        root: classes.chip,
      }}
      data-testid={`chart-chip-${label.replace(/\s/g, '-').replace(/-{2,}/g, '-')}`}
    />
  );
};
