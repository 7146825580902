import { ZodLiteralRecord } from '@marlin/shared/utils/zod';
import { z } from 'zod';

const DayNameList = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'] as const;
const DayName = z.enum(DayNameList);
export type TDayName = z.infer<typeof DayName>;

const ScheduleDetails = z.object({
  isSkipped: z.boolean(),
  minutes: z.number(),
});

const DailySchedule = z.object({
  from1: ScheduleDetails,
  from2: ScheduleDetails,
  to1: ScheduleDetails,
  to2: ScheduleDetails,
});
export type TDailySchedule = z.infer<typeof DailySchedule>;

export const EquipmentScheduleRaw = z.object({
  days: ZodLiteralRecord(DayNameList, DailySchedule),
  enabled: z.boolean(),
});
export type TEquipmentScheduleRaw = z.infer<typeof EquipmentScheduleRaw>;

export const EquipmentSchedule = EquipmentScheduleRaw.transform(({ days, enabled }) => {
  const parseDay = (day: TDailySchedule) => ({
    occupied1: {
      from: { skip: day.from1.isSkipped, time: day.from1.minutes },
      to: { skip: day.to1.isSkipped, time: day.to1.minutes },
    },
    occupied2: {
      from: { skip: day.from2.isSkipped, time: day.from2.minutes },
      to: { skip: day.to2.isSkipped, time: day.to2.minutes },
    },
  });

  return {
    days: {
      monday: parseDay(days.monday),
      tuesday: parseDay(days.tuesday),
      wednesday: parseDay(days.wednesday),
      thursday: parseDay(days.thursday),
      friday: parseDay(days.friday),
      saturday: parseDay(days.saturday),
      sunday: parseDay(days.sunday),
    },
    scheduleEnabled: enabled,
  };
});

export type TEquipmentSchedule = z.infer<typeof EquipmentSchedule>;

export const EquipmentSchedulePayload = z.object({
  newSchedule: EquipmentSchedule,
  prevSchedule: EquipmentSchedule,
});
export type TEquipmentSchedulePayload = z.infer<typeof EquipmentSchedulePayload>;

const ParsedScheduleDetails = z.object({
  skip: z.boolean(),
  time: z.number(),
});
export type TParsedScheduleDetails = z.infer<typeof ParsedScheduleDetails>;

export const ParsedDailySchedule = z.object({
  occupied1: z.object({ from: ParsedScheduleDetails, to: ParsedScheduleDetails }),
  occupied2: z.object({ from: ParsedScheduleDetails, to: ParsedScheduleDetails }),
});
export type TParsedDailySchedule = z.infer<typeof ParsedDailySchedule>;
