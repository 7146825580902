import { IFilterParams } from '../alert.model';

enum QUERY_KEY {
  ALERTS = 'alerts',
  LOAD_MORE_ALERTS = 'loadMore',
  ALERT = 'alert',
}

export const queryKey = {
  ALERTS: (filters?: IFilterParams) => (filters ? [QUERY_KEY.ALERTS, filters] : [QUERY_KEY.ALERTS]),
  LOAD_MORE_ALERTS: (filters?: IFilterParams) =>
    filters ? [QUERY_KEY.ALERTS, QUERY_KEY.LOAD_MORE_ALERTS, filters] : [QUERY_KEY.ALERTS, QUERY_KEY.LOAD_MORE_ALERTS],
  ALERT: (alertId: string) => [QUERY_KEY.ALERT, alertId],
};
