import { useRouter } from '@marlin/shared/utils-router';
import { useCallback } from 'react';

export interface IFeatureNavigation {
  closeDeviceDrawer: () => void;
}

export const useDeviceDrawerContainer = (): IFeatureNavigation => {
  const router = useRouter();

  const closeDeviceDrawer = useCallback(() => {
    router.refresh();
  }, [router]);

  return {
    closeDeviceDrawer,
  };
};
