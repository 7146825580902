import { useCurrentUser } from '@marlin/account-data-access-organization';
import { THomepage, TSettings } from '@marlin/account-data-access-organization';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import ViewWeekRoundedIcon from '@mui/icons-material/ViewWeekRounded';
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { content } from './content';
import { useStyles } from './toggle-buttons-group.styles';

type THomepageButton = {
  [key in THomepage]: string;
};

const createSelectedPages = (settings?: TSettings): THomepageButton => {
  switch (settings?.defaultHomePage) {
    case 'DASHBOARD': {
      return {
        LOCATIONS: routes.home.locations,
        DASHBOARD: routes.home.root,
      };
    }
    default:
    case 'LOCATIONS': {
      return {
        LOCATIONS: routes.home.root,
        DASHBOARD: routes.home.dashboard,
      };
    }
  }
};

interface IToggleButtonsProps {
  isMobile?: boolean;
}

export const ToggleButtons = ({ isMobile }: IToggleButtonsProps) => {
  const { classes } = useStyles();
  const { pathname } = useLocation();
  const { data: userData } = useCurrentUser();

  const selectedPages = useMemo(() => createSelectedPages(userData?.settings), [userData?.settings]);
  const getInitialPage = useCallback(
    (pathname: string) => (pathname === selectedPages['LOCATIONS'] ? 'LOCATIONS' : 'DASHBOARD'),
    [selectedPages]
  );

  const [selectedPage, setSelectedPage] = useState<THomepage>(getInitialPage(pathname));

  const { goTo } = useRouter();

  const handleChange = (event: MouseEvent<HTMLElement>, newSelectedPage: THomepage) => {
    if (!newSelectedPage) {
      return;
    }

    setSelectedPage(newSelectedPage);
    goTo(selectedPages[newSelectedPage]);
  };

  useEffect(() => {
    setSelectedPage(getInitialPage(pathname));
  }, [getInitialPage, pathname]);

  const pageLabel = selectedPage === 'LOCATIONS' ? content.LOCATIONS : content.DASHBOARD;
  const buttonSize = isMobile ? 'small' : 'medium';

  return (
    <div className={classes.toggleButtonWrapper}>
      {!isMobile && (
        <Typography variant="subtitle1" className={classes.toggleBtnTextStyle}>
          {pageLabel}
        </Typography>
      )}

      <ToggleButtonGroup value={selectedPage} exclusive onChange={handleChange} aria-label="Platform">
        <ToggleButton className={classes.toggleBtnStyle} value="DASHBOARD" key="DASHBOARD" size={buttonSize}>
          <DashboardRoundedIcon />
        </ToggleButton>
        <ToggleButton className={classes.toggleBtnStyle} value="LOCATIONS" key="LOCATIONS" size={buttonSize}>
          <ViewWeekRoundedIcon />
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};
