import { TExtractFnReturnType, useQuery } from '@marlin/shared/utils/react-query';

import { TWaterTotalParams } from '../infrastructure/schema/water-total.schema';
import { getTotalWaterInsights } from '../infrastructure/water-total';
import { queryKey } from './query-key.enum';

type TQueryFnType = typeof getTotalWaterInsights;

type TUseWaterTotalParams = TWaterTotalParams & { enabled?: boolean };

export const useWaterTotal = ({ enabled, ...params }: TUseWaterTotalParams) => {
  return useQuery<TExtractFnReturnType<TQueryFnType>>({
    queryKey: queryKey.WATER_TOTAL(params),
    queryFn: () => getTotalWaterInsights(params),
    enabled,
  });
};
