import { useMemo } from 'react';

import { IDeviceTypeOption, deviceTypeOptions } from './const';
import { TDeviceType } from './device-type.schema';

const deviceTypeOptionsFactory: () => IDeviceTypeOption[] = () => {
  return Object.values(deviceTypeOptions);
};

export const useDeviceTypeOptions = () => {
  const deviceTypeOptions = useMemo(() => deviceTypeOptionsFactory(), []);
  const filterSelectedOptions = (options: IDeviceTypeOption[], selectedOptions: TDeviceType[]) =>
    options.filter(({ value }) => !selectedOptions.includes(value));

  return {
    deviceTypeOptions,
    filterSelectedOptions,
  };
};
