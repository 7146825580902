import { History, createBrowserHistory } from 'history';

export let history: History | History<unknown>;

export const initHistory = (baseName: string | null): void => {
  if (baseName) {
    history = createBrowserHistory({ basename: baseName });
    return;
  }

  history = createBrowserHistory();
};

export const getHistory = (): History => {
  if (!history) {
    throw new Error(`History isn't initialized`);
  }

  return history;
};

export class Router {
  public readonly navigateToUrl = (url: string, state?: unknown): void => getHistory().push(url, state);

  public readonly getParamFromUrl = (param: string): string | null => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get(param);
  };

  public readonly goBack = (): void => getHistory().goBack();

  public readonly clearState = (): void => window.history.replaceState(null, '');
}

export const router = new Router();
