import NorthEastRoundedIcon from '@mui/icons-material/NorthEastRounded';
import SouthEastRoundedIcon from '@mui/icons-material/SouthEastRounded';

import { useStyles } from './arrow-indicator.styles';

interface IArrowIndicatorProps {
  value?: number | null;
  isExpanded?: boolean;
}
export const ArrowIndicator = ({ value, isExpanded = false }: IArrowIndicatorProps) => {
  const { classes } = useStyles({ isExpanded });
  if (!value) {
    return null;
  }

  return value > 0 ? (
    <div className={classes.northIcon}>
      <NorthEastRoundedIcon />
    </div>
  ) : (
    <div className={classes.southIcon}>
      <SouthEastRoundedIcon />
    </div>
  );
};
