import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
  },
  dateRangeWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.typography.pxToRem(8),
    backgroundColor: 'transparent',
    borderRadius: theme.typography.pxToRem(4),
    marginTop: theme.typography.pxToRem(8),
  },
  dateRangeWrapperIn: {
    display: 'flex',
    flexDirection: 'column',
    animation: `in 3s ${theme.transitions.easing.easeIn}`,
    padding: theme.typography.pxToRem(8),
    backgroundColor: 'transparent',
    borderRadius: theme.typography.pxToRem(4),
    marginTop: theme.typography.pxToRem(8),
    '@keyframes in': {
      '0%': {
        backgroundColor: '#006FD614',
      },
      '100%': {
        backgroundColor: 'transparent',
      },
    },
  },
  dateRangeWrapperOut: {
    display: 'flex',
    flexDirection: 'column',
    animation: `out 3s ${theme.transitions.easing.easeOut}`,
    padding: theme.typography.pxToRem(8),
    backgroundColor: 'transparent',
    borderRadius: theme.typography.pxToRem(4),
    marginTop: theme.typography.pxToRem(8),
    '@keyframes out': {
      '0%': {
        backgroundColor: '#006FD614',
      },
      '100%': {
        backgroundColor: 'transparent',
      },
    },
  },
  headerTitle: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 400,
    color: theme.palette.text.secondary,
    lineHeight: theme.typography.pxToRem(24),
  },
  text: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 400,
    color: theme.palette.text.primary,
    lineHeight: theme.typography.pxToRem(24),
  },
  datesWrapper: {
    marginTop: theme.typography.pxToRem(8),
  },
}));
