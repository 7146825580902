import {
  TControlType,
  TDatapoint,
  TDeviceMetadataDatapointsResponse,
  isOccupancySensorDatapoint,
} from '@marlin/shared/utils/datapoint-mappers';

export const getControlType = (
  metadata: TDeviceMetadataDatapointsResponse | undefined,
  status?: TDatapoint['status']
): TControlType => {
  if (metadata?.type === 'bool') {
    if (isOccupancySensorDatapoint(metadata.name)) {
      return 'occupancy';
    }

    return 'boolean';
  }

  if (status && status !== '0') {
    return 'status';
  }

  return !metadata?.options.length ? 'value' : 'option';
};
