import { CommissionDateField, ICommissionDateFieldProps } from '@marlin/shared/ui-form';
import { FormField, Input } from '@marlin/shared/ui-form-common';
import { TEquipment } from '@marlin/shared/utils/zod';

import { content } from '../content';
import { useStyles } from '../upsert-equipment.styles';

interface IBradleyPartitionProps extends ICommissionDateFieldProps {}

export const BradleyPartitionForm = ({
  setDateValidationErrorMessage,
  dateValidationErrorMessage,
}: IBradleyPartitionProps) => {
  const { classes } = useStyles();

  return (
    <>
      <div className={classes.formSection}>{content.ADDITIONAL_INFORMATION}</div>
      <FormField<TEquipment> fieldName="brand">
        {(props) => <Input className={classes.input} label={content.BRAND} {...props} testId="brand-field" disabled />}
      </FormField>
      <FormField<TEquipment> fieldName="model">
        {(props) => <Input {...props} className={classes.input} label={content.MODEL} testId="model-field" disabled />}
      </FormField>
      <CommissionDateField
        setDateValidationErrorMessage={setDateValidationErrorMessage}
        dateValidationErrorMessage={dateValidationErrorMessage}
      />
      <FormField<TEquipment> fieldName="description">
        {(props) => (
          <Input
            {...props}
            className={classes.input}
            label={content.DESCRIPTION}
            testId="description-field"
            maxLength={500}
            multiline
          />
        )}
      </FormField>
    </>
  );
};
