import { MarlinTheme } from '@marlin/shared/theme';
import { getCardTitle } from '@marlin/shared/utils/format-alert';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';
import { IAlert } from '../types';
import { TimeSection } from './time-section.component';
import { TitleSection } from './title-section.component';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  icon: {
    color: theme.palette.success.main,
  },
}));

interface IAlertContentHeaderMobileProps {
  alert: IAlert;
}

export function ResolvedAlertContentHeaderMobile({ alert }: IAlertContentHeaderMobileProps) {
  const { classes } = useStyles();
  const title = useMemo(() => getCardTitle(alert), [alert]);

  return (
    <>
      <TitleSection title={title} icon={<CheckCircleRoundedIcon className={classes.icon} />} />
      <TimeSection label={content.CARD.TIME_START} datetime={alert.startTime} />
      <TimeSection label={content.CARD.TIME_RESOLVED} datetime={alert.resolveTime} />
    </>
  );
}
