import { TelemetryParamsSchema } from './bucket.types';

const milisecondsToMinutes = 1000 * 60;

const calculateBucketSize = (from: number, to: number, numberOfBuckets: number): number => {
  // we want to have at least 1 minute to avoid falling into default BE bucketing
  return Math.ceil((to - from) / numberOfBuckets / milisecondsToMinutes);
};

export const getBucketSize = (from: string, to: string, numberOfBuckets: number = 500): number => {
  const rangeDates = TelemetryParamsSchema.safeParse({ from, to });
  if (!rangeDates.success) throw new Error('Invalid range dates');

  return calculateBucketSize(rangeDates.data.from.getTime(), rangeDates.data.to.getTime(), numberOfBuckets);
};

export const getRangeWithMargins = (
  from: string,
  to: string,
  numberOfBuckets: number = 500,
  marginPercentage?: number
): { from: string; to: string; bucketSize: number } => {
  const bucketSize = getBucketSize(from, to, numberOfBuckets);
  // NOTE: margins are 100% on both size - to handle quick zoom outs, by using marginPercentage you can reduce/increase it's size
  const marginRatio = marginPercentage ? marginPercentage / 100 : 1;
  const margin = (bucketSize === 0 ? 1 : bucketSize) * numberOfBuckets * milisecondsToMinutes * marginRatio;
  const fromWithTimezone = from && !from.endsWith('Z') ? `${from}Z` : from;
  const toWithTimezone = to && !to.endsWith('Z') ? `${to}Z` : to;

  return {
    from: new Date(new Date(fromWithTimezone).getTime() - margin).toISOString(),
    to: new Date(new Date(toWithTimezone).getTime() + margin).toISOString(),
    bucketSize,
  };
};
