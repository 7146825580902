import { UseMutationResult, queryClient, useMutation } from '@marlin/shared/utils/react-query';

import { createRecipientRequest } from '../infrastructure/create-recipient';
import { TCreateRecipient, TRecipient } from '../infrastructure/recipient.model.schema';
import { queryKey } from '../query-key.enum';

export const useCreateRecipient = (): UseMutationResult<TRecipient, unknown, TCreateRecipient> => {
  return useMutation<TRecipient, unknown, TCreateRecipient>({
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: queryKey.RECIPIENTS() });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.RECIPIENTS() });
    },
    mutationFn: createRecipientRequest,
  });
};
