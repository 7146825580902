import { PowerSource } from '@marlin/asset/shared/ui/battery';
import { MarlinTheme } from '@marlin/shared/theme';
import { TLastReadingValuesSchema, formatSensorDatapoints } from '@marlin/shared/utils-format-reading';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { LAST_READING_VALUE_NAME } from '../constant';
import { ReadingCard } from './reading-card.component';

interface IReadingProps {
  lastReadingValues?: TLastReadingValuesSchema[];
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  readings: {
    display: 'flex',
    flex: '1 1 0',
    gap: theme.typography.pxToRem(8),
  },
}));

export const Readings = ({ lastReadingValues }: IReadingProps) => {
  const { classes } = useStyles();

  const reading = useMemo(() => {
    return formatSensorDatapoints(lastReadingValues);
  }, [lastReadingValues]);

  const batteryReadingValue = useMemo(() => {
    const batteryReading = lastReadingValues?.find((reading) => reading.name === LAST_READING_VALUE_NAME.BATTERY_LEVEL);

    return Number(batteryReading?.value);
  }, [lastReadingValues]);

  if (!lastReadingValues || !lastReadingValues.length) {
    return null;
  }

  return (
    <div className={classes.readings}>
      <ReadingCard title={'last reading'} testId={'reading'}>
        {reading}
      </ReadingCard>
      <ReadingCard title={'power source'} testId={'power-source'}>
        <PowerSource batteryLevel={batteryReadingValue} />
      </ReadingCard>
    </div>
  );
};
