import { IAdditionalInformation } from '@marlin/asset/data-access/location';
import { SectionEntry, SectionHeader, SectionTitle } from '@marlin/shared/ui-asset-card-mobile';

import { content } from '../../../content';

interface IDetailsProps {
  additionalInformation: IAdditionalInformation;
}

const additionalInformationDataTestId = 'additional-information';
const additionalInformationDataEntryTestId = `${additionalInformationDataTestId}-entry`;

export const AdditionalInformation = ({ additionalInformation }: IDetailsProps) => {
  return (
    <>
      <SectionHeader>
        <SectionTitle testId={additionalInformationDataTestId}>{content.ADDITIONAL_INFORMATION}</SectionTitle>
      </SectionHeader>
      <SectionEntry testId={additionalInformationDataEntryTestId} title={content.EQUIPMENT_COUNT}>
        {additionalInformation.numberOfEquipments}
      </SectionEntry>
      <SectionEntry testId={additionalInformationDataEntryTestId} title={content.SENSORS_COUNT}>
        {additionalInformation.numberOfDevices}
      </SectionEntry>
      <SectionEntry testId={additionalInformationDataEntryTestId} title={content.AUTOMATIONS}>
        {additionalInformation.numberOfAutomations}
      </SectionEntry>
      <SectionEntry testId={additionalInformationDataEntryTestId} title={content.ID} subtitle={content.ID_DESC}>
        {additionalInformation.locationId}
      </SectionEntry>
    </>
  );
};
