import { THomeLocationSchema } from '@marlin/asset/data-access/home-dashboard';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { useCallback, useMemo } from 'react';

const locationDetailsPage = (locationId: string) => routes.locations.details.url(locationId);

export interface ICardHeader {
  parentLocationName: string;
  goToParentLocation: () => void;
  goToLocation: () => void;
}

export const useCardHeader = (location: THomeLocationSchema): ICardHeader => {
  const parentLocationName = useMemo(() => location.parentLocationName || '', [location.parentLocationName]);
  const { goTo } = useRouter();
  const goToParentLocation = useCallback(
    () => goTo(locationDetailsPage(location?.parentLocationId as string)),
    [goTo, location.parentLocationId]
  );
  const goToLocation = useCallback(
    () => goTo(locationDetailsPage(location?.locationId as string)),
    [goTo, location?.locationId]
  );
  return {
    parentLocationName,
    goToParentLocation,
    goToLocation,
  };
};
