import { environment } from '@marlin/environment';

export const content = {
  NO_DATA: 'No results found.',
  ERROR: 'Something went wrong. Please try again later.',
  DELETE_BUTTON: 'Delete',
  EDIT_BUTTON: 'Edit',
  HIERARCHY_VIEW_TITLE: 'Hierarchy View',
  LINKED_EQUIPMENTS_TITLE: 'Equipment Count',
  LINKED_SENSORS_TITLE: 'Sensor Count',
  AUTOMATIONS_TITLE: 'Automations',
  ADDRESS_TITLE: 'Address',
  ID_TITLE: 'ID',
  EQUIPMENT_LABEL: 'Equipment',
  TEMPERATURE_LABEL: 'Temperature Sensor',
  PRESSURE_LABEL: 'Pressure Sensor',
  LEAK_LABEL: 'Leak Sensor',
  FLOW_METER: 'Flow Meter',
  LEAK_DETECTOR: 'Leak Detector',
  LOCATION_LABEL: 'Location',
  SEARCH_LABEL: 'Search',
  CLEAR_ALL: 'Clear All',
  ID_HEADER_NAME: 'ID',
  LOCATION_NAME_HEADER_NAME: 'Location Name',
  PARENT_LOCATION_HEADER_NAME: 'Parent Location',
  EQUIPMENT_HEADER_NAME: 'Equipment',
  SENSORS_HEADER_NAME: 'Sensors',
  BUTTON_CANCEL: 'Back',
  LINKED_ITEMS: 'Child assets',
  DETAILS: 'Details',
  HIERARCHY_VIEW: 'Hierarchy view',
  HIERARCHY_TAB: 'Hierarchy',
  DETAILS_TAB: 'Location Details',
  ADDITIONAL_INFORMATION: 'Additional Information',
  LOCATION_ADDRESS: 'Location Address',
  ADDRESS_1: 'Address 1',
  ADDRESS_2: 'Address 2',
  PROVINCE: 'Province/Territory',
  ZIP_CODE: 'ZIP Code',
  POSTAL_CODE: 'Postal Code',
  STATE: 'State',
  CITY: 'City',
  COUNTRY: 'Country',
  EQUIPMENT_COUNT: 'Equipment Count',
  SENSORS_COUNT: 'Sensors Count',
  AUTOMATIONS: 'Automations',
  ID: 'ID',
  ID_DESC: `Unique identifier assigned within the ${environment.app.name} platform`,
  PARENT_LOCATION_NAME: 'Parent Location',
  LOCATION_NAME: 'Location Name',
  DESCRIPTION: 'Description',
  EQUIPMENT_CHIP: 'Equipment',
};
