import { TExtractFnReturnType, useQuery } from '@marlin/shared/utils/react-query';

import { getGraph } from '../infrastructure/graph';
import { queryKey } from './query-key.enum';

export const useGraph = () => {
  return useQuery<TExtractFnReturnType<typeof getGraph>>({
    queryKey: queryKey.GRAPH(),
    queryFn: () => getGraph(),
    keepPreviousData: true,
    refetchOnMount: true,
  });
};
