import { queryClient } from '@marlin/shared/utils/react-query';

import { queryKey } from '../query-key.enum';
import { IListFilters, IRecipient, IRecipientList } from '../recipient.model';

export const deleteRecipientFromListCache = (id: string, params?: IListFilters): IRecipientList | undefined => {
  const previousRecipients: IRecipientList | undefined = queryClient.getQueryData(queryKey.RECIPIENTS_FILTER(params));

  queryClient.setQueryData<IRecipientList | undefined>(queryKey.RECIPIENTS_FILTER(params), (data?: IRecipientList) => {
    if (!data) {
      return data;
    }

    return {
      ...data,
      data: data.data.filter((recipient: IRecipient) => recipient.id !== id) || [],
    };
  });

  return previousRecipients;
};
