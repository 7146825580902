import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  confirmText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  cancelButton: {
    marginLeft: theme.typography.pxToRem(16),
  },
  confirmButton: {
    marginRight: theme.typography.pxToRem(8),
    marginLeft: theme.typography.pxToRem(8),
  },
}));
