import { MarlinTheme } from '@marlin/shared/theme';
import Icon from '@mui/material/Icon';
import { makeStyles } from 'tss-react/mui';

interface IStatusIconProps {
  icon: string;
  color: string;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  icon: {
    marginRight: theme.typography.pxToRem(8),
    color: theme.palette.action.active,
  },
  errorColor: {
    color: theme.palette.error.main,
  },
}));

export const StatusIcon = ({ icon, color }: IStatusIconProps) => {
  const { classes, cx } = useStyles();

  return (
    <Icon
      baseClassName="material-symbols-outlined"
      className={cx(classes.icon, { [classes.errorColor]: color === 'error' })}
      data-testid="equipment-mode-icon"
    >
      {icon}
    </Icon>
  );
};
