import { getHttpClient, queryClient } from '@marlin/shared/utils/react-query';
import { useMutation } from '@marlin/shared/utils/react-query';
import { AxiosError } from 'axios';

import { paths } from '../infrastructure/api';
import { queryKey } from '../query-key.enum';
import { GatewayConfigurationResponse } from '../schema/gateway-configuration.schema';
import { GatewayUpdatePayload, TGatewayUpdatePayload } from '../schema/gateway.schema';

export const updateGateway = async ({ data }: { data: TGatewayUpdatePayload }) => {
  const { configurationId, deviceId, ...parsedData } = GatewayUpdatePayload.parse(data);

  const result = await getHttpClient().put(paths.UPDATE_GATEWAY_CONFIGURATION, parsedData, {
    params: {
      deviceId: deviceId,
      configurationId: configurationId,
    },
  });

  return GatewayConfigurationResponse.parse(result);
};

type TUseUpdateGatewayOptions = {
  onSuccess?: (params: { deviceId: string; configurationId: string }) => void;
  onError?: (error: AxiosError<{ errorMessage: string; status: string }>) => void;
};

export const useUpdateGateway = ({ onError, onSuccess }: TUseUpdateGatewayOptions = {}) => {
  return useMutation({
    onSuccess: ({ deviceId, id }) => {
      queryClient.invalidateQueries(queryKey.GATEWAY());

      onSuccess && onSuccess({ deviceId: deviceId ?? '', configurationId: id ?? '' });
    },
    onError,
    mutationFn: updateGateway,
  });
};
