import { useEffect } from 'react';

export const useHandleUnload = (enabled: boolean) => {
  useEffect(() => {
    const handleUnloadEvent = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = '';
    };
    if (enabled) {
      window.addEventListener('beforeunload', handleUnloadEvent);
    } else {
      window.removeEventListener('beforeunload', handleUnloadEvent);
    }

    return () => window.removeEventListener('beforeunload', handleUnloadEvent);
  }, [enabled]);
};
