import { MarlinTheme } from '@marlin/shared/theme';
import { Paper } from '@marlin/shared/ui-page';
import { FC, PropsWithChildren } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useExtendedDeviceDrawer } from '../container/context';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  paper: {
    backgroundColor: theme.palette.background.primary,
    marginTop: theme.typography.pxToRem(4),
    borderRadius: theme.typography.pxToRem(4),
  },
}));

type TExtendedWrapperProps = {
  className?: string;
};

export const ExtendedWrapper: FC<PropsWithChildren<TExtendedWrapperProps>> = ({ className, children }) => {
  const { classes, cx } = useStyles();
  const isExtended = useExtendedDeviceDrawer();

  if (isExtended) {
    return <Paper className={className ? cx(classes.paper, className) : classes.paper}> {children} </Paper>;
  }
  return children;
};
