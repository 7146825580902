import { useStyles } from './people.component.styles';
import { RecipientList } from './recipient-list.component';
import { UserList } from './user-list.component';

export const People = () => {
  const { classes } = useStyles();

  return (
    <>
      <div className={classes.topTableWrapper}>
        <UserList />
      </div>
      <div className={classes.tableWrapper}>
        <RecipientList />
      </div>
    </>
  );
};
