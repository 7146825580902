import { MarlinTheme } from '@marlin/shared/theme';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../content';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  scheduleTooltipList: {
    padding: `${theme.typography.pxToRem(4)} ${theme.typography.pxToRem(16)}`,
    margin: 0,
  },
  tooltipRow: {
    listStyle: 'disc',
  },
}));

export const IntellistationScheduleHeaderTooltip = () => {
  const { classes } = useStyles();

  return (
    <>
      <span>{content.SCHEDULE_TOOLTIP_TITLE}</span>
      <ul className={classes.scheduleTooltipList}>
        <li className={classes.tooltipRow}>{content.SCHEDULE_TOOLTIP_ROW_1}</li>
        <li className={classes.tooltipRow}>{content.SCHEDULE_TOOLTIP_ROW_2}</li>
      </ul>
    </>
  );
};
