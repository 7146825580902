import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import isNaN from 'lodash/isNaN';
import React, { useCallback, useState } from 'react';

import { content } from '../content';
import { TEditedSetting } from '../types';
import { useSettingListItem } from './use-setting-list-item.hook';

interface IUseValueSetting {
  currentValue: string;
  min: number;
  max: number;
  saveSetting: (name: string, value: string, prevValue: string) => Promise<void>;
  name: string;
  step: number;
  setEditedSetting: (editedSetting: TEditedSetting | undefined) => void;
  setIsDirty: (isDirty: boolean) => void;
  editedSetting: TEditedSetting | undefined;
}

export const useValueSetting = ({
  min,
  max,
  currentValue,
  saveSetting,
  name,
  step,
  setEditedSetting,
  editedSetting,
  setIsDirty,
}: IUseValueSetting) => {
  const { enqueueSnackbar } = useSnackbar();
  const { onCancelClick } = useSettingListItem({ name, setEditedSetting, setIsDirty });
  const [value, setValue] = useState<string>(editedSetting?.updatedValue ?? currentValue);
  const minValue = min;
  const maxValue = max;

  const handleOnValueChange = useCallback(
    (updatedValue: string) => {
      const hasChanges = updatedValue !== currentValue;

      setIsDirty(hasChanges);
      setEditedSetting(hasChanges ? { updatedValue, currentValue } : undefined);
    },
    [currentValue, setEditedSetting, setIsDirty]
  );

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let inputValue = e.target.value;
      let match = inputValue.match(/^\d+$/);

      // TODO: wonder how to manage other steps like 0.7 etc
      if (step === 0.5) {
        inputValue = inputValue.replace(/[^\d.]/g, '');
        match = inputValue.match(/^(?:\d+(\.(5|0))?|\d+\.$|\d+)?$/);
      }

      if (match) {
        setValue(inputValue);
        handleOnValueChange(inputValue);
      }
    },
    [handleOnValueChange, step]
  );

  const onSliderChange = useCallback(
    (_e: Event, value: number | number[]) => {
      if (typeof value === 'number') {
        if (step === 0.5) {
          setValue(value.toFixed(1));
          handleOnValueChange(value.toFixed(1));
          return;
        }
        setValue(value.toString());
        handleOnValueChange(value.toString());
      }
    },
    [handleOnValueChange, step]
  );

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const newValue = parseInt(e.target.value, 10);

    if (newValue > maxValue) {
      setValue(maxValue.toString());
      handleOnValueChange(maxValue.toString());

      return;
    }

    if (newValue < minValue || isNaN(newValue)) {
      setValue(minValue.toString());
      handleOnValueChange(minValue.toString());

      return;
    }

    if (step === 0.5) {
      setValue(parseFloat(value).toFixed(1));
      handleOnValueChange(parseFloat(value).toFixed(1));

      return;
    }
  };

  const onSave = async () => {
    try {
      await saveSetting(name, String(value), currentValue);
      onCancelClick();
    } catch (error) {
      enqueueSnackbar(content.ERROR, {
        variant: 'error',
        preventDuplicate: true,
      });
    }
  };

  const onRemoveClick = (step: number) => {
    setValue((prev) => {
      const decrementedValue = parseFloat(prev) - step;
      const updatedValue = step === 0.5 ? decrementedValue.toFixed(1) : decrementedValue.toString();

      handleOnValueChange(updatedValue);

      return updatedValue;
    });
  };

  const onAddClick = (step: number) => {
    setValue((prev) => {
      const incrementedValue = parseFloat(prev) + step;
      const updatedValue = step === 0.5 ? incrementedValue.toFixed(1) : incrementedValue.toString();
      handleOnValueChange(updatedValue);

      return updatedValue;
    });
  };

  return { value, onChange, onSliderChange, onSave, onBlur, onRemoveClick, onAddClick };
};
