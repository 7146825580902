import { IEquipmentSharedProps } from '@marlin/asset/shared/ui/datapoint-display';
import { IRangeFilter, RANGE_FILTER, useRangeFilter } from '@marlin/shared/utils-chart';
import { createContext, useContext } from '@marlin/shared/utils-component-context';
import moment from 'moment';
import { PropsWithChildren } from 'react';

interface IEquipmentContext extends IEquipmentSharedProps {
  handleRangeFilterChange: (option?: RANGE_FILTER) => void;
  rangeFilter: IRangeFilter;
  lastReadingTime: moment.Moment | null;
}

type TEquipmentContextProps = Omit<IEquipmentContext, 'handleRangeFilterChange' | 'rangeFilter'>;

export const EquipmentContext = createContext<IEquipmentContext>();

export const EquipmentContextProvider = ({
  children,
  equipment,
  ...contextValueProps
}: PropsWithChildren<TEquipmentContextProps>) => {
  const { handleRangeFilterChange, rangeFilter } = useRangeFilter(RANGE_FILTER.HOURS_8);

  return (
    <EquipmentContext.Provider
      value={{
        ...contextValueProps,
        rangeFilter,
        handleRangeFilterChange,
        equipment,
      }}
    >
      {children}
    </EquipmentContext.Provider>
  );
};

export const useEquipmentContext = () => useContext(EquipmentContext);
