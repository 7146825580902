import { useCurrentUser, useGetOrgSettings } from '@marlin/account-data-access-organization';
import { SystemConstraintsPage } from '@marlin/shared/ui-page';
import { PERMISSIONS, usePermission } from '@marlin/shared/utils-permission';

import { ChangeOrgNameControl } from './change-org-control/change-org-name.control';
import { ApiKey } from './components/api-key.component';
import { OrganizationId } from './components/organization-id.component';
import { OrganizationAddress } from './organization-address/organization-address.component';
import { useStyles } from './settings-organization.styles';

export const SettingsOrganization = () => {
  const { classes } = useStyles();
  const { data: orgSettings } = useGetOrgSettings();
  const allowedToOrgsSection = usePermission(PERMISSIONS.SHOW_ORGANIZATION_ADDRESS_SETTINGS_PAGE);
  const allowedToMonnitApiKey = usePermission(PERMISSIONS.SHOW_MONNIT_API_KEY_SETTINGS_PAGE);
  const { data: orgData, isError } = useCurrentUser();

  if (isError) {
    return <SystemConstraintsPage onlyContent />;
  }

  return (
    <div className={classes.pageWrapper}>
      {allowedToOrgsSection && (
        <>
          <div className={classes.sectionWrapper} data-testid="organization-settings-organization-name">
            <ChangeOrgNameControl />
          </div>
          <div className={classes.sectionWrapper} data-testid="organization-settings-organization-address">
            <OrganizationAddress />
          </div>
        </>
      )}
      {allowedToMonnitApiKey && orgSettings && orgSettings.settings.monnit.isActive && (
        <div className={classes.sectionWrapper} data-testid="organization-settings-api-key">
          <ApiKey
            keyHeader={orgSettings.settings.monnit.apiKeyHeader}
            keyValue={orgSettings.settings.monnit.apiKeyValue}
          />
        </div>
      )}
      {orgData && (
        <div className={classes.sectionWrapper} data-testid="organization-settings-organization-id">
          <OrganizationId organizationId={orgData.currentOrganization.organizationId} />
        </div>
      )}
    </div>
  );
};
