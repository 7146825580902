import { content } from '../../content';
import { useStyles } from './lost-communication-section.component';

export const LowBatterySection = (): JSX.Element => {
  const { classes } = useStyles();

  return (
    <div className={classes.label} data-testid="reading-low-battery">
      {content.CARD.CURRENT.LOW_BATTERY}
    </div>
  );
};
