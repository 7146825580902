import { createDateString, formatDate } from '@marlin/shared/utils-common-date';
import { TUnitOfMeasure } from '@marlin/shared/utils-format-reading';
import { alpha } from '@mui/system';
import { ApexOptions } from 'apexcharts';
import isNil from 'lodash/isNil';
import { ColorScale, Data, PlotData, PlotType } from 'plotly.js';
import { z } from 'zod';

import { TChartThresholdPosition } from './schemas/metadata.schema';
import { getSeriesForRangeBar } from './utils/get-series-for-range-bar';

export const chartDisplayType = z.union([
  z.literal('line'),
  z.literal('area'),
  z.literal('bar'),
  z.literal('pie'),
  z.literal('donut'),
  z.literal('radialBar'),
  z.literal('scatter'),
  z.literal('bubble'),
  z.literal('heatmap'),
  z.literal('candlestick'),
  z.literal('boxPlot'),
  z.literal('radar'),
  z.literal('polarArea'),
  z.literal('rangeBar'),
  z.literal('rangeArea'),
  z.literal('treemap'),
  z.literal('heatmap'),
]);

export type TChartDisplayType = z.infer<typeof chartDisplayType>;

const chartDisplayTypeToPlotly: Record<TChartDisplayType, PlotType> = {
  line: 'scatter',
  area: 'scatter',
  bar: 'bar',
  pie: 'pie',
  donut: 'pie',
  radialBar: 'scatter',
  scatter: 'scatter',
  bubble: 'scatter',
  heatmap: 'heatmap',
  candlestick: 'candlestick',
  boxPlot: 'box',
  radar: 'scatter',
  polarArea: 'scatter',
  rangeBar: 'bar',
  rangeArea: 'scatter',
  treemap: 'scatter',
};

export interface IChartData {
  x: number;
  y: number | null;
}

export const chartDataSchema = z.object({
  x: z.number(),
  y: z.number().nullable(),
});

export interface IThresholdsSeriesNullable {
  x: number;
  y: number | [number | null, number | null] | null;
}

export const chartSeriesSchema = z.object({
  name: z.string(),
  id: z.string(),
  data: z.array(chartDataSchema),
  color: z.string(),
  uom: z.union([z.string(), z.null()]),
  type: chartDisplayType,
});

const mapBoolValue = (value: number) => (value > 0 ? 1 : 0);

export const plotlyAdapterSchema = z
  .object({
    series: z.array(chartSeriesSchema),
    uoms: z.array(z.string()),
    options: z
      .object({
        hideUomOnAxis: z.boolean().optional(),
      })
      .optional(),
  })
  .transform(({ uoms, series, options }): Partial<Data>[] => {
    if (series.every((s) => s.type === 'rangeBar')) {
      return getSeriesForRangeBar(series).map((s) => {
        const baseTimestamps = s.data.filter(({ y }) => !!y).map(({ x }) => x);
        const base = baseTimestamps.map((x) => formatDate(createDateString(new Date(x))));
        const zeroValues = s.data.filter(({ y }) => y === 0);

        const xValues = baseTimestamps
          .map((baseTimestamp) => {
            const zeroValueForBase = zeroValues
              .map(({ x }) => x)
              .filter((x) => x > baseTimestamp)
              .sort((x1, x2) => x1 - x2);
            if (!zeroValueForBase.filter(Boolean).length) {
              return null;
            }
            return zeroValueForBase[0] - baseTimestamp;
          })
          .filter(Boolean);

        const yValues = base.map((_) => s.name);

        return {
          type: 'bar',
          y: yValues,
          x: xValues,
          orientation: 'h',
          base,
          name: s.name,
          width: 0.4,
          xaxis: 'x',
          yaxis: 'y',
          hoveron: 'fills',
          hoverinfo: 'none',
          line: { color: s.color },
          marker: { color: s.color, size: 2 },
        } as Partial<PlotData>;
      });
    }

    if (series.every((s) => s.type === 'heatmap')) {
      const xValues = new Set(
        series
          .map((s) => s.data)
          .reduce((acc, val) => acc.concat(val), [])
          .map((p) => p.x)
          .sort((a, b) => a - b)
      );
      const yValues = series.map((s) => s.name);
      const uoms = series.map((s) => s.uom);
      const colors = series.map((s) => s.color);

      const zValues: number[][] = [];
      series
        .map((s) => s.data)
        .forEach((leakData) => {
          const zValue = [] as number[];
          xValues.forEach((date) => {
            const value = leakData.find((p) => p.x === date)?.y;
            zValue.push(isNil(value) ? 0 : mapBoolValue(value));
          });
          zValues.push(zValue);
        });

      return yValues.map((yValue, index) => ({
        x: Array.from(xValues).map((x) => formatDate(createDateString(new Date(x)))),
        y: [yValue],
        z: [zValues[index]],
        type: 'heatmap' as const,
        showscale: false,
        yaxis: index > 0 ? `y${index + 1}` : 'y',
        xgap: 0,
        ygap: 5,
        zmin: 0,
        zmax: 1,
        customdata: [uoms[index]],
        marker: { color: colors[index], size: 2 },
        colorscale: [
          [0, 'transparent'],
          [1, colors[index]],
        ] as ColorScale,
        hoverinfo: 'none',
      }));
    }
    const shouldUseY2 = (uom: string | null) => !options?.hideUomOnAxis && uoms.length > 1 && uom === uoms[1];

    return series.map((s): Partial<Data> => {
      return {
        x: s.data.map((point) => formatDate(createDateString(new Date(point.x)))),
        y: s.data.map((point) => point.y),
        customdata: [s.uom],
        type: chartDisplayTypeToPlotly[s.type] || 'scatter',
        name: s.name,
        line: { color: s.color },
        marker: { color: s.color, size: 2, symbol: 'circle' },
        hoverinfo: 'none',
        mode: 'lines+markers' as const,
        yaxis: shouldUseY2(s.uom) ? 'y2' : undefined,
      };
    });
  });

export const plotlyThresholdAdapterSchema = z.array(chartSeriesSchema).transform((series): Partial<PlotData>[] => {
  return series.map(
    (s): Partial<PlotData> => ({
      x: s.data.map((point) => formatDate(createDateString(new Date(point.x)))),
      y: s.data.map((point) => point.y),
      customdata: [s.uom],
      name: s.name,
      line: { color: s.color, dash: 'dot' },
      fill: 'tonexty',
      marker: { color: s.color, size: 2 },
      mode: 'lines' as const,
      hoverinfo: 'none',
      fillcolor: alpha(s.color, 0.3),
    })
  );
});

export interface IChartSeries {
  name: string;
  id: string;
  data: IChartData[];
  color: string;
  uom: TUnitOfMeasure | null;
  type: TChartDisplayType;
  manufacturerId?: string;
}

export interface IChartThresholdSeries extends IChartSeries {
  position: TChartThresholdPosition;
  alias: string;
  label: string;
}

export interface IThresholdsSeries {
  x: number;
  y: number | null | [number | null, number | null];
}

export interface IApexAxisChartThresholdSeries extends Omit<ApexOptions['series'], 'data'> {
  name: string;
  data: IThresholdsSeries[];
  alias: string;
  uom: TUnitOfMeasure | null;
  type: TChartDisplayType;
  color: string;
}

export type TToolbarOptions = NonNullable<ApexOptions['chart']>['toolbar'];
export type TEventOptions = NonNullable<ApexOptions['chart']>['events'];

const chartTypeValues = ['temperature', 'pressure', 'onOff', 'flow', 'leak', 'custom'] as const;
export const ChartType = z.enum(chartTypeValues);
export type TChartType = z.infer<typeof ChartType>;
