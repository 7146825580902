import { ChartChip } from '@marlin/asset/shared/ui/chart-chips';
import { MarlinTheme } from '@marlin/shared/theme';
import { Button, useTheme } from '@mui/material';
import { useCallback } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';
import { useSelectedSensorsStore, useSetSelectedSensors } from '../context/selected-sensors.context';

const useChipsStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    gap: theme.typography.pxToRem(8),
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.typography.pxToRem(16),
  },
}));

export const Chips = ({ chartId }: { chartId: string }) => {
  const theme = useTheme<MarlinTheme>();
  const { classes } = useChipsStyles();

  const [selectedSensorsForChartId] = useSelectedSensorsStore((store) => store[chartId]);
  const setSelectedSensors = useSetSelectedSensors();

  const clearActiveDatapoints = useCallback(() => {
    setSelectedSensors({
      [chartId]: {
        ...selectedSensorsForChartId,
        chartDatapoints: selectedSensorsForChartId.chartDatapoints.map((dp) => ({ ...dp, isActive: false })),
      },
    });
  }, [chartId, selectedSensorsForChartId, setSelectedSensors]);

  const toggleActiveDatapoint = useCallback(
    ({ datapointId, chartId }: { chartId: string; datapointId: string }) => {
      const datapoint = selectedSensorsForChartId.chartDatapoints.find((datapoint) => datapoint.id === datapointId);

      if (!datapoint) {
        return;
      }

      setSelectedSensors({
        [chartId]: {
          ...selectedSensorsForChartId,
          chartDatapoints: selectedSensorsForChartId.chartDatapoints.map((dp) =>
            dp.id === datapointId ? { ...dp, isActive: !dp.isActive } : dp
          ),
        },
      });
    },
    [selectedSensorsForChartId, setSelectedSensors]
  );

  if (!selectedSensorsForChartId) {
    return null;
  }

  const chips = selectedSensorsForChartId.chartDatapoints || [];

  return (
    <div className={classes.wrapper} data-testid="chart-chips">
      {chips.map(({ equipmentName, name, label, color, id, parentType }) => {
        const isActive = chips.find((datapoint) => datapoint.id === id)?.isActive || false;
        const labelName = parentType === 'equipment' ? `${label} - ${equipmentName}` : label;

        return (
          <ChartChip
            key={id}
            label={labelName}
            color={color || theme.palette.charting.aquaFusion}
            isActive={isActive}
            toggleChip={() => toggleActiveDatapoint({ chartId, datapointId: id })}
            name={name}
            dataTestId={`chart-chip-${labelName.replace(/\s/g, '-').replace(/-{2,}/g, '-')}`}
          />
        );
      })}
      {!!chips.length && (
        <Button variant="text" onClick={clearActiveDatapoints} data-testid="clear-all-button">
          {content.CLEAR_ALL}
        </Button>
      )}
    </div>
  );
};
