import moment from 'moment';

import { AVERAGING_FUNCTION_FILTER } from '../model';

export const getRawDataChartFunctionType = (
  fromDate: string,
  toDate: string,
  defaultAvgFunction?: AVERAGING_FUNCTION_FILTER,
  disableRawData?: boolean
) => {
  const from = moment(fromDate);
  const to = moment(toDate);
  const diff = to.diff(from, 'hours');

  if (diff < 2 && !disableRawData) return AVERAGING_FUNCTION_FILTER.RAW;

  return defaultAvgFunction;
};
