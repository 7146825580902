import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import { paths } from './api';
import {
  TWaterTotalParams,
  TWaterTotalResponse,
  waterTotalParamsSchema,
  waterTotalResponseSchema,
} from './schema/water-total.schema';

export const getTotalWaterInsights = async (params: TWaterTotalParams): Promise<TWaterTotalResponse> => {
  const body = safeParseData(params, waterTotalParamsSchema);

  const response = await getHttpClient().post(paths.WATER_TOTAL, body);

  return safeParseData(response, waterTotalResponseSchema);
};
