import { TSettingsOptions } from '@marlin/shared/ui-form';

import { content } from '../content';
import { TEnergy, TFlow, TGas, TPressure, TTemperature } from './schema';

export interface ISettingsRadioOption<TValue> {
  value: TValue;
  label: string;
}

export const gasOptions: ISettingsRadioOption<TGas>[] = [
  {
    label: content.GAS_FEET,
    value: 'Ft3',
  },
  {
    label: content.GAS_METERS,
    value: 'M3',
  },
];

export const temperatureOptions: ISettingsRadioOption<TTemperature>[] = [
  {
    label: content.TEMPERATURE_FAHRENHEIT,
    value: 'F',
  },
  {
    label: content.TEMPERATURE_CELCIUS,
    value: 'C',
  },
];

export const pressureOptions: ISettingsRadioOption<TPressure>[] = [
  {
    label: content.PRESSURE_POUNDS,
    value: 'PSI',
  },
  {
    label: content.PRESSURE_KILOPASCAL,
    value: 'KPA',
  },
];

export const energyOptions: TSettingsOptions<TEnergy> = {
  MBTU: {
    id: 'MBTU',
    value: 'MBTU',
    name: content.ENERGY_MBTU,
  },
  GJ: {
    id: 'GJ',
    value: 'GJ',
    name: content.ENERGY_GIGAJOULE,
  },
  KWH: {
    id: 'KWH',
    value: 'KWH',
    name: content.ENERGY_KWH,
  },
  THM: {
    id: 'THM',
    value: 'THM',
    name: content.ENERGY_THERM,
  },
};

export const flowOptions: TSettingsOptions<TFlow> = {
  LPM: {
    id: 'LPM',
    value: 'LPM',
    name: content.FLOW_LITERS,
  },
  GPM: {
    id: 'GPM',
    value: 'GPM',
    name: content.FLOW_GALLONS,
  },
  M3PH: {
    id: 'M3PH',
    value: 'M3PH',
    name: content.FLOW_CUBIC_METER,
  },
};
