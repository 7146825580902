import { queryClient, useMutation } from '@marlin/shared/utils/react-query';

import { processCalibration, saveCalibration } from '../infrastructure/calibration';
import { queryKey } from './query-key.enum';

export const useProcessCalibration = () => {
  return useMutation({
    mutationFn: processCalibration,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.CALIBRATIONS(), exact: false });
    },
  });
};

export const useSaveCalibration = () => {
  return useMutation({
    mutationFn: saveCalibration,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.CALIBRATIONS(), exact: false });
    },
  });
};
