import { environment } from '@marlin/environment';
import { Dispatch, Reducer } from 'react';

import { SLIDE, TEASER_MODAL_ACTION_TYPE } from './types';

type TAction =
  | {
      type: TEASER_MODAL_ACTION_TYPE.SHOW_TEASER_MODAL;
      payload: { slide: SLIDE };
    }
  | {
      type: TEASER_MODAL_ACTION_TYPE.SHOW_BOOKING_MODAL;
    }
  | {
      type: TEASER_MODAL_ACTION_TYPE.DISMISS;
    };

export type TModalState = {
  teaserModalVisible: boolean;
  bookingModalVisible: boolean;
  slide: SLIDE;
  msBookingsUrl: string;
};

export type TModalAction = Dispatch<TAction>;

export const initialState: TModalState = {
  teaserModalVisible: false,
  bookingModalVisible: false,
  slide: SLIDE.SYSTEM_MAP,
  msBookingsUrl: environment.module.msBookingsUrl,
};

export const modalReducer: Reducer<TModalState, TAction> = (state, action) => {
  switch (action.type) {
    case TEASER_MODAL_ACTION_TYPE.SHOW_TEASER_MODAL: {
      const { slide } = action.payload;
      return {
        ...state,
        teaserModalVisible: true,
        slide,
      };
    }
    case TEASER_MODAL_ACTION_TYPE.SHOW_BOOKING_MODAL: {
      return {
        ...state,
        teaserModalVisible: false,
        bookingModalVisible: true,
      };
    }
    case TEASER_MODAL_ACTION_TYPE.DISMISS: {
      return {
        ...initialState,
      };
    }
  }
};
