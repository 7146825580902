import { ChartType, TChartType } from '../chart-data-types';
import { content } from '../content';
import { chartTypesIcons } from './chart-types-icons';

const mappedChartName: Record<TChartType, string> = {
  [ChartType.Enum.temperature]: content.TEMPERATURE,
  [ChartType.Enum.pressure]: content.PRESSURE,
  [ChartType.Enum.onOff]: content.ON_OFF_STATUS,
  [ChartType.Enum.flow]: content.FLOW,
  [ChartType.Enum.leak]: content.LEAK,
  [ChartType.Enum.custom]: content.CUSTOM,
};

export const getChartName = (groupId: TChartType) => {
  return `${mappedChartName[groupId]}`;
};

export const getChartIcon = (groupId: TChartType): JSX.Element | null => {
  const Icon = chartTypesIcons[groupId];

  return Icon ? <Icon /> : null;
};
