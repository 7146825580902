import * as yup from 'yup';
import * as z from 'zod';

import { content } from '../content';

// todo remove yup
export const firstNameSchema = yup
  .string()
  .label(content.FIRST_NAME_CONTROL_LABEL)
  .max(40)
  .required(content.FIRST_NAME_CONTROL_VALIDATION);

export const firstNameZodSchema = z
  .string()
  .max(40, content.LAST_NAME_CONTROL_VALIDATION)
  .min(1, content.FIRST_NAME_CONTROL_VALIDATION);
