export const content = {
  UPGRADE: 'Upgrade to Nexa System',
  EXPANSIVE_DATA_ANALYTICS: 'Expansive data analytics and export capabilities',
  SYSTEM_VIEW: 'Comprehensive plumbing system view',
  EXPERT_ADVICE: 'Expert advice and recommendations',
  WEEKLY_REPORTS: 'Weekly performance reports',
  SCHEDULE_DEMO: 'Schedule a demo with our experts to get started',
  BUTTONS: {
    NOT_NOW: 'Not now',
    BOOK_DEMO: 'Book a demo',
  },
};
