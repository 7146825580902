import { content } from '../../content';

const eightHours = 8;
const daysInWeek = 7;
const hoursInDay = 24;

export enum RANGE_FILTER {
  HOURS_8 = 'HOURS_8',
  HOURS_24 = 'HOURS_24',
  DAYS_7 = 'DAYS_7',
}

export const isOfChartRangeType = (value: string): value is RANGE_FILTER =>
  Object.values(RANGE_FILTER).includes(value as RANGE_FILTER);

export interface IChartRangeOption {
  id: RANGE_FILTER;
  name: string;
}

export const chartRangeTypeOptions: IChartRangeOption[] = [
  {
    id: RANGE_FILTER.HOURS_8,
    name: content.SENSOR_READINGS_RANGE_HOURS_8,
  },
  {
    id: RANGE_FILTER.HOURS_24,
    name: content.SENSOR_READINGS_RANGE_HOURS_24,
  },
  {
    id: RANGE_FILTER.DAYS_7,
    name: content.SENSOR_READINGS_RANGE_DAYS_7,
  },
];

export const telemetryFilterValue: Record<RANGE_FILTER, number> = {
  [RANGE_FILTER.HOURS_8]: eightHours,
  [RANGE_FILTER.HOURS_24]: hoursInDay,
  [RANGE_FILTER.DAYS_7]: daysInWeek,
};
