export const content = {
  AVERAGING_FUNCTION_LABEL: 'Data Averaging',
  AVERAGING_FUNCTION_AVERAGE: 'Average',
  AVERAGING_FUNCTION_MIN: 'Min',
  AVERAGING_FUNCTION_MAX: 'Max',
  AVERAGING_FUNCTION_MEDIAN: 'Median',
  AVERAGING_FUNCTION_SUM: 'Sum',
  TOOLTIP_LABEL: 'Show the dataset values averaged by:',
  TOOLTIP_AVERAGE: 'Average: Arithmetic mean across the dataset',
  TOOLTIP_MIN: 'Min: Returns the minimum values',
  TOOLTIP_MAX: 'Max: Returns the maximum values',
  TOOLTIP_MEDIAN: 'Median: Returns the middle values',
  TOOLTIP_NOTE: 'To view raw data, zoom into 2-hours duration',
};
