import { MarlinTheme } from '@marlin/shared/theme';
import { PropsWithChildren } from 'react';
import { makeStyles } from 'tss-react/mui';

export interface IModeValueProps extends PropsWithChildren {
  label: string;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  control: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.typography.pxToRem(4),
    color: theme.palette.text.primary,
  },
  label: {
    fontSize: theme.typography.pxToRem(10),
    fontWeight: theme.typography.fontWeightMedium,
  },
  value: {
    fontSize: theme.typography.pxToRem(10),
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export const ModeValue = ({ label, children }: IModeValueProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.control}>
      <span className={classes.label}>{label}:</span>
      <span className={classes.value}>{children}</span>
    </div>
  );
};
