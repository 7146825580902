import { IRangeFilter, RANGE_FILTER, getSelectionTimeAxis } from '@marlin/shared/utils-chart';
import moment, { Moment } from 'moment/moment';
import { useCallback, useMemo, useState } from 'react';

const addZ = (date?: string) => (date?.endsWith('Z') ? date : `${date}Z`);

interface IUseDuration {
  fromDate: Moment | null;
  toDate: Moment | null;
  rangeFilter: IRangeFilter;
}

export const useAccumulatedVolumesDuration = ({
  toDate,
  fromDate,
  rangeFilter: { range, currentDate },
}: IUseDuration) => {
  const { to, from } = useMemo(() => {
    return {
      to: toDate?.toISOString(),
      from: fromDate?.toISOString(),
    };
  }, [fromDate, toDate]);

  const timeRange = useMemo(() => {
    const { timeRange } = getSelectionTimeAxis({
      rangeFilter: range,
    });

    return timeRange;
    // currentData is needed to update the timeRange on refresh withouth changing the range
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range, currentDate]);

  const [zoom, setZoom] = useState<{ zoomFrom: string; zoomTo: string; min: number; max: number } | undefined>();

  const handleZoomChange = useCallback((zoom?: { min: number; max: number }) => {
    if (!zoom) {
      setZoom(undefined);

      return;
    }

    setZoom({
      min: zoom.min,
      max: zoom.max,
      zoomFrom: moment(zoom.min).toISOString(),
      zoomTo: moment(zoom.max).toISOString(),
    });
  }, []);

  return useMemo(() => {
    if (zoom) {
      return {
        fromDate: `${zoom.zoomFrom}Z`,
        toDate: `${zoom.zoomTo}Z`,
        minSelection: zoom.min,
        maxSelection: zoom.max,
        handleZoomChange,
        isZoomed: true,
        zoomedRange: moment(zoom.zoomTo).diff(moment(zoom.zoomFrom)),
      };
    }

    if (range !== RANGE_FILTER.CUSTOM || !fromDate || !toDate) {
      return {
        fromDate: addZ(from),
        toDate: addZ(to),
        minSelection: timeRange[0],
        maxSelection: timeRange[1],
        handleZoomChange,
      };
    }

    const toDateEndOfDay = toDate.clone().set('hour', 23).set('minute', 59).set('second', 59);

    return {
      fromDate: fromDate.clone().toISOString(),
      toDate: toDateEndOfDay.toISOString(),
      minSelection: fromDate.valueOf(),
      maxSelection: toDateEndOfDay.valueOf(),
      handleZoomChange,
    };
  }, [from, fromDate, handleZoomChange, range, timeRange, to, toDate, zoom]);
};
