import { MarlinTheme } from '@marlin/shared/theme';
import { useEffect, useRef } from 'react';
import { makeStyles } from 'tss-react/mui';

interface IPaginationMobile {
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  totalItems?: number;
  loadedItems?: number;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    height: theme.typography.pxToRem(8),
  },
}));

export const InfinitePagination = ({ loadedItems, totalItems, hasNextPage, fetchNextPage }: IPaginationMobile) => {
  const { classes } = useStyles();

  const paginationRef = useRef(null);

  useEffect(() => {
    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        fetchNextPage();
      }
    };
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };
    const observer = new IntersectionObserver(observerCallback, options);
    if (paginationRef.current) {
      observer.observe(paginationRef.current);
    }
    return () => {
      if (paginationRef.current) {
        observer.unobserve(paginationRef.current);
      }
    };
  }, []);

  if (!hasNextPage || !loadedItems || !totalItems) {
    return null;
  }

  return <div className={classes.container} ref={paginationRef} />;
};
