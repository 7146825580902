import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import {
  TGetWebhookParams,
  TWebhookResponse,
  getWebhookParamsSchema,
  getWebhookResponseSchema,
} from '../schemas/get-webhook.schema';
import { paths } from './api';

export const getWebhook = async (webhookParams: TGetWebhookParams): Promise<TWebhookResponse> => {
  const params = safeParseData(webhookParams, getWebhookParamsSchema);
  const response = await getHttpClient().get(paths.WEBHOOK_ID, { params });

  return safeParseData(response, getWebhookResponseSchema);
};
