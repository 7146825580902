// todo (TRANSLATIONS) merge this one with one from FEATURE project
export const content = {
  AUTOMATIONS_TITLE: 'Automations',
  AUTOMATIONS_SUBTITLE: 'Use the cards below to manage your automations.',
  NO_DATA: 'No results found.',
  ERROR: 'Something went wrong. Please try again later.',
  HEADER_BUTTON_TITLE: 'Create new automation',
  FILTERS_LABEL_LOCATION: 'Locations',
  FILTERS_LABEL_EQUIPMENT: 'Equipment',
  FILTERS_LABEL_SENSOR: 'Sensors',
  FILTERS_CLEAR_ALL: 'Clear All',
  FILTERS_LABEL_SEARCH: 'Search',
  FILTERS_AUTOMATION: {
    LABEL: 'Status',
    PLACEHOLDER: 'On/Off',
    VALUE: {
      ALL: 'All Automations',
      ON: 'On Automations',
      OFF: 'Off Automations',
    },
  },
  UNKNOWN_SENSOR: 'N/A',
};
