import { THomeLocationSchema } from '@marlin/asset/data-access/home-dashboard';
import { LoadingSpinner } from '@marlin/shared/ui-loader';

import { content } from '../../content';
import { TAB_VALUES } from '../../use-home.hook';
import { MobileHomeCard } from '../card';
import { MobileHomeListEmpty } from '../list-empty';
import { HomeListNoMechanicalRooms } from '../list-no-mechanical-rooms';
import { useStyles } from './list.component.styles';

export interface IHomeListProps {
  isLoading: boolean;
  isError: boolean;
  viewLocations: THomeLocationSchema[];
  tabValue?: TAB_VALUES;
}

export const MobileHomeList = ({ isLoading, viewLocations, isError, tabValue }: IHomeListProps) => {
  const { classes } = useStyles();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!viewLocations.length) {
    if (tabValue === TAB_VALUES.MechanicalRooms) {
      return <HomeListNoMechanicalRooms isMobile />;
    }

    return <MobileHomeListEmpty message={isError ? content.ERROR : content.EMPTY_LIST} />;
  }

  return (
    <div className={classes.mobileWrapper}>
      {viewLocations.map((homeLocation) => (
        <MobileHomeCard homeLocation={homeLocation} key={homeLocation.locationId} />
      ))}
    </div>
  );
};
