import { TDateString, formatDate } from '@marlin/shared/utils-common-date';

import { content } from '../content';

export const ModalBody = ({ commissionDate }: { commissionDate: TDateString }) => {
  return (
    <div>
      {content.COMMISSION_DATE_MODAL_BODY_1} <b>{formatDate(commissionDate, 'MM/DD/YY')}</b>.{' '}
      {content.COMMISSION_DATE_MODAL_BODY_2}
    </div>
  );
};
