import { content } from './content';
import { IGlobalEquipmentConfig } from './model';

export const globalConfigIntellistation: IGlobalEquipmentConfig = {
  dashboard: {
    liveDataExpirationSeconds: 720,
  },
  calculationValues: true,
  chart: [
    {
      name: 'temperature',
      label: content.temperature,
      type: 'rangeArea',
      additionalAxisUom: '%',
      mainDatapoint: 'mixingTemp',
    },
    {
      name: 'pressure',
      label: content.pressure,
      type: 'rangeArea',
      mainDatapoint: 'outletPressure',
    },
    {
      name: 'flow',
      label: content.flow,
      type: 'rangeArea',
      mainDatapoint: 'outletFlow',
    },
    {
      name: 'pumpvalve',
      label: content.pumpValve,
      type: 'rangeBar',
    },
  ],
};
