import { Subscription } from 'rxjs';

export enum REALTIME_COMMUNICATION_LOG_LEVEL {
  NONE = 0,
  INFORMATION = 1,
  WARNING = 2,
  ERROR = 3,
  ALL = 4,
}

export interface IConfig {
  url: string;
  logLevel: REALTIME_COMMUNICATION_LOG_LEVEL;
}

export interface IAuthService {
  getAccessTokenPromise(): Promise<string>;
  subscribeToLogout(complete: () => void): Subscription | undefined;
}
