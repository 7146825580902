import { MarlinTheme } from '@marlin/shared/theme';
import { routes } from '@marlin/shared/utils-routes';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { IMetadata } from '../types';
import { LinkedItem } from './linked-item.component';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  breadcrumbs: {
    color: theme.palette.text.secondary,
  },
}));

interface ILinkedLocationItemProps {
  location?: IMetadata;
  equipment?: IMetadata;
}

export function Breadcrumbs({ location, equipment }: ILinkedLocationItemProps) {
  const { classes } = useStyles();

  if (location?.id && location?.name && equipment?.id && equipment?.name) {
    return (
      <span>
        <Typography className={classes.breadcrumbs} variant="caption">
          <LinkedItem item={location} goTo={(id: string) => routes.locations.details.url(id)} />
          {' > '}
          <LinkedItem item={equipment} goTo={(id: string) => routes.equipments.details.url(id)} />
        </Typography>
      </span>
    );
  }
  return (
    <Typography className={classes.breadcrumbs} variant="caption">
      <LinkedItem item={location} goTo={(id: string) => routes.locations.details.url(id)} />
    </Typography>
  );
}
