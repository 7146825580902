import { mockedIds } from './id';

export const krakowPrediction = {
  description: mockedIds.krakow,
  matched_substrings: [
    {
      length: 6,
      offset: 0,
    },
  ],
  place_id: mockedIds.krakow,
  reference: mockedIds.krakow,
  structured_formatting: {
    main_text: mockedIds.krakow,
    main_text_matched_substrings: [
      {
        length: 6,
        offset: 0,
      },
    ],
    secondary_text: 'Selenium Street, Krakow, OH, USA',
  },
  terms: [
    {
      offset: 0,
      value: mockedIds.krakow,
    },
    {
      offset: 21,
      value: 'Selenium Street',
    },
    {
      offset: 33,
      value: 'Chrome',
    },
    {
      offset: 40,
      value: 'OH',
    },
    {
      offset: 44,
      value: 'USA',
    },
  ],
  types: ['food', 'point_of_interest', 'store', 'establishment'],
};

export const wroclawPrediction = {
  description: mockedIds.wroclaw,
  matched_substrings: [
    {
      length: 6,
      offset: 0,
    },
  ],
  place_id: mockedIds.wroclaw,
  reference: mockedIds.wroclaw,
  structured_formatting: {
    main_text: mockedIds.wroclaw,
    main_text_matched_substrings: [
      {
        length: 6,
        offset: 0,
      },
    ],
    secondary_text: 'Cypress Street, Wroclaw, OH, USA',
  },
  terms: [
    {
      offset: 0,
      value: mockedIds.wroclaw,
    },
    {
      offset: 21,
      value: 'Cypress Street',
    },
    {
      offset: 33,
      value: 'Cypress',
    },
    {
      offset: 40,
      value: 'OH',
    },
    {
      offset: 44,
      value: 'USA',
    },
  ],
  types: ['food', 'point_of_interest', 'store', 'establishment'],
};

export const createPredictionsResponse = (search: string) => {
  let predictions: google.maps.places.AutocompletePrediction[];
  if ('$'.includes(search.toLowerCase())) {
    predictions = [krakowPrediction, wroclawPrediction];
  } else if (mockedIds.wroclaw.includes(search.toLowerCase())) {
    predictions = [wroclawPrediction];
  } else if (mockedIds.krakow.includes(search.toLowerCase())) {
    predictions = [krakowPrediction];
  } else {
    predictions = [];
  }
  return {
    predictions,
    status: 'OK',
  };
};
