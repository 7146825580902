import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { useCallback, useMemo } from 'react';

interface INavigate {
  goToInvitationPage: () => void;
}

export const useNavigate = (): INavigate => {
  const { goTo } = useRouter();

  const goToInvitationPage = useCallback(() => {
    goTo(routes.settings.inviteNewUser.url());
  }, [goTo]);

  const navigate: INavigate = useMemo(() => ({ goToInvitationPage }), [goToInvitationPage]);

  return navigate;
};
