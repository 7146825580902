import { dateAdapter } from '@marlin/shared/utils-common-date';
import { useObservableState } from 'observable-hooks';
import { useCallback, useMemo, useState } from 'react';
import { BehaviorSubject, debounceTime, filter, map, pairwise, switchMap, timer } from 'rxjs';

export interface ITooltipData {
  color: string;
  displayedValue: string;
  name: string;
  formattedDate: string;
  date: string;
}

export interface ITooltip {
  top: number;
  left: number;
  tooltipData: ITooltipData[] | null;
}

export type TChartTooltip = (Partial<ITooltip> | null) & {
  direction: 'right' | 'left';
  showTooltip: boolean;
};

export const useChartTooltip = () => {
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltip$ = useMemo(() => new BehaviorSubject<ITooltip | null>(null), []);
  const [tooltip] = useObservableState<ITooltip | null>(
    () =>
      tooltip$.pipe(
        debounceTime(10),
        switchMap((tooltip) => {
          if (tooltip !== null) {
            return [tooltip];
          }
          return timer(300).pipe(switchMap(() => [null]));
        })
      ),
    null
  );

  const [tooltipDirection] = useObservableState<'left' | 'right'>(
    () =>
      tooltip$.pipe(
        debounceTime(5),
        filter((value) => !!value),
        pairwise(),
        map(([prev, curr]): 'right' | 'left' => {
          if (!prev || !curr) {
            return 'right';
          }
          const maxTooltipWidth = 400;
          if (curr.left < maxTooltipWidth) {
            return 'right';
          }

          const isPrevDateAfterCurrDate = dateAdapter
            ?.date(prev?.tooltipData?.[0].date)
            ?.isAfter(dateAdapter?.date(curr?.tooltipData?.[0].date));

          return isPrevDateAfterCurrDate ? 'right' : 'left';
        }),
        debounceTime(10)
      ),
    'left'
  );
  const setTooltip = useCallback((value: ITooltip | null) => tooltip$.next(value), [tooltip$]);

  return { showTooltip, setShowTooltip, setTooltip, tooltipDirection, tooltip };
};
