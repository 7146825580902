import { DeviceState } from '@marlin/asset/shared/ui/battery';

import { useStyles } from './status-icon.component.styles';

interface IPinDeviceProps {
  batteryLevel: number | null;
  isActive: boolean;
}

const StatusIcon = ({ batteryLevel, isActive }: IPinDeviceProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.activeBatteryContainer}>
      <div className={classes.activeContainer} data-testid="status-icon-container">
        <DeviceState batteryLevel={batteryLevel} isActive={isActive} />
      </div>
    </div>
  );
};

export { StatusIcon };
