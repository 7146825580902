import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { TSystemTagParams, useSetSystemTag } from '@marlin/system-map/data-access/system-map';
import { TSystemTag } from '@marlin/system-map/shared/data-access-schemas';
import { useReactFlow } from '@xyflow/react';
import { MouseEvent, useCallback, useState } from 'react';

import { TSystemMapNode } from '../../../types';
import { content } from '../../content';

export interface ISetSystemTagMenuState {
  systemTagMenuAnchor: HTMLElement | null;
  currentNodeId: string | null;
  currentSystemTag: TSystemTag | null;
}

interface IUseSetSystemTagContextMenu {
  systemTagMenuState: ISetSystemTagMenuState | null;
  handleSystemTagMenuOpen: (nodeId: string, event: MouseEvent<HTMLElement>) => void;
  handleSystemTagMenuClose: () => void;
  setSystemTag: (systemTag: TSystemTag | null, onClose: () => void) => void;
}

const availableSystemTags: TSystemTag[] = ['DOMESTIC_COLD_WATER', 'DOMESTIC_HOT_WATER', 'HEATING_HOT_WATER'];

export const useSetSystemTagContextMenu = (): IUseSetSystemTagContextMenu => {
  const [systemTagMenuState, setSystemTagMenuState] = useState<null | ISetSystemTagMenuState>(null);

  const { getNode } = useReactFlow<TSystemMapNode>();
  const { mutateAsync: mutateSystemTag } = useSetSystemTag();
  const { enqueueSnackbar } = useSnackbar();

  const handleSystemTagMenuOpen = (nodeId: string, event: MouseEvent<HTMLElement>) => {
    setSystemTagMenuState({
      systemTagMenuAnchor: event.currentTarget,
      currentNodeId: nodeId,
      currentSystemTag:
        (getNode(nodeId)?.data?.systemTags || []).filter((tag) => availableSystemTags.includes(tag))[0] || null,
    });
  };

  const handleSystemTagMenuClose = () => {
    setSystemTagMenuState(null);
  };

  const setNewSourceTag = useCallback(
    async ({ assetId, assetType, systemTag, onClose }: TSystemTagParams & { onClose: () => void }) => {
      return mutateSystemTag({
        assetType,
        assetId,
        systemTag,
      })
        .then(() => {
          enqueueSnackbar(
            systemTag ? content.SYSTEM_TAG.SET_TAG_SUCCESS_MESSAGE : content.SYSTEM_TAG.REMOVE_TAG_SUCCESS_MESSAGE,
            { variant: 'success' }
          );
          onClose();
        })
        .catch(() => {
          enqueueSnackbar(
            systemTag ? content.SYSTEM_TAG.SET_TAG_ERROR_MESSAGE : content.SYSTEM_TAG.REMOVE_TAG_ERROR_MESSAGE,
            { variant: 'error' }
          );
        });
    },
    [enqueueSnackbar, mutateSystemTag]
  );

  const setSystemTag = useCallback(
    (systemTag: TSystemTag | null, onClose: () => void) => {
      if (!systemTagMenuState?.currentNodeId) {
        return;
      }

      const currentNode = getNode(systemTagMenuState?.currentNodeId);
      const assetId = currentNode?.data.assetId;
      const assetType = currentNode?.data.assetType;

      if (!assetId || !assetType) {
        return;
      }

      return setNewSourceTag({ assetId, assetType, systemTag, onClose });
    },
    [systemTagMenuState, getNode, setNewSourceTag]
  );

  return {
    systemTagMenuState,
    handleSystemTagMenuOpen,
    handleSystemTagMenuClose,
    setSystemTag,
  };
};
