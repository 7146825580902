import { MarlinTheme } from '@marlin/shared/theme';
import { ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  headerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerTitle: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: theme.typography.pxToRem(0.15),
    margin: 0,
  },
}));

interface ISectionHeaderProps {
  title: string;
  options?: ReactNode;
  testId?: string;
}

export const SectionHeader = ({ title, options, testId }: ISectionHeaderProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.headerContent} data-testid={testId}>
      <h2 className={classes.headerTitle}>{title}</h2>
      {options}
    </div>
  );
};
