import { ElkNode, LayoutOptions } from 'elkjs/lib/elk.bundled';

import { TSystemMapNode } from '../cytoscape-react-flow/schema/types';
import { arrayToTreeByKey } from './utils/array-to-tree-by-key';
import { treeToFlatArray } from './utils/tree-to-flat-array';

export const elkAdapter = {
  nodesFromReactFlow: (Nodes: TSystemMapNode[], layoutOptions?: LayoutOptions): ElkNode[] =>
    arrayToTreeByKey(Nodes, 'parentId', layoutOptions),

  nodesToReactFlow: (Nodes: ElkNode[]): TSystemMapNode[] =>
    treeToFlatArray(Nodes)
      .map(
        ({ x, y, ...node }): TSystemMapNode => ({
          ...(node as TSystemMapNode),
          position: { x: x || 0, y: y || 0 },
        })
      )
      .map(({ width, height, ...node }) => ({
        ...node,
        ...(node.data?.assetType === 'LOCATION' || node.data?.assetType === 'EQUIPMENT' ? {} : { width, height }),
      })),
};
