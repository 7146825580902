import { useObservableState } from 'observable-hooks';
import { useCallback } from 'react';
import { BehaviorSubject } from 'rxjs';

const onboardingEquipmentErrors$ = new BehaviorSubject<boolean[]>([]);

export const useEquipmentErrorsState = () => {
  // todo: set to false again after error is resolved
  const [onboardingEquipmentErrors] = useObservableState<boolean[]>(() => onboardingEquipmentErrors$, []);

  const addEquipmentError = useCallback(({ data, index }: { data: boolean; index: number }) => {
    const errors = [...onboardingEquipmentErrors$.value];
    errors[index] = data;
    onboardingEquipmentErrors$.next(errors);
  }, []);

  const updateEquipmentErrors = useCallback(({ data }: { data: boolean[] }) => {
    onboardingEquipmentErrors$.next(data);
  }, []);

  return {
    addEquipmentError,
    updateEquipmentErrors,
    onboardingEquipmentErrors,
  };
};
