import { environment } from '@marlin/environment';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import App from './app/app';
import './app/module.config';

async function enableMocking() {
  if (!environment.module.features.mockMapsApi) {
    return;
  }

  const { worker } = await import('./mocks/browser');
  if (environment.module.automationHelper.extendedQaLogging) {
    worker.events.on('response:bypass', async ({ request, response }) => {
      if (request.url.includes('nexaplatform')) {
        const reqBody = request.body ? await request.json() : 'empty';
        const resBody = await response.json();
        // eslint-disable-next-line no-console
        console.log(
          `[Network Log of Page ${window.location.href}]\n${request.method} ${request.url} received ${
            response.status
          } ${response.statusText}\n${JSON.stringify(resBody, null, 2)}\n sent ${JSON.stringify(reqBody, null, 2)}`
        );
      }
    });
  }
  return worker.start({
    onUnhandledRequest: (request) => {
      return request;
    },
  });
}

const container = document.getElementById('root');
// note: as per React documentation
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

enableMocking().then(() => {
  root.render(
    <StrictMode>
      <App />
    </StrictMode>
  );
});
