import { routes } from '@marlin/shared/utils-routes';

export interface IFeatureNavigation {
  getLocationEditPageLink: (id: string) => string;
  getLocationsHubPageLink: () => string;
  getDeviceDrawerOnPageLink: (id: string) => string;
}

export const useNavigation = (): IFeatureNavigation => {
  return {
    getLocationEditPageLink: (id) => routes.locations.update.url(id),
    getLocationsHubPageLink: () => routes.locations.root,
    getDeviceDrawerOnPageLink: (id) => routes.sensors.details.url(id),
  };
};
