import { UnitOfMeasure } from '@marlin/shared/utils-format-reading';
import { z } from 'zod';

import { content } from './content';

export const FlowMeterSchema = z.object({
  id: z.string(),
  name: z.string(),
  locationName: z.string(),
});

export const SustainabilitySettingsSchema = z.object({
  price: z
    .string()
    .min(1, content.PRICE_CAN_NOT_BE_EMPTY)
    .default('')
    .refine((val) => !isNaN(Number(val)), { message: content.INVALID_PRICE }),
  uoM: UnitOfMeasure,
  defaultFlowMeters: z.array(FlowMeterSchema).min(1, content.AT_LEAST_ONE_FLOW_METER_REQUIRED),
});

export type TSustainabilitySettingsSchema = z.infer<typeof SustainabilitySettingsSchema>;
