import { z } from 'zod';

import { LocationTypes } from '../../location.model';
import { emptyTextSchema } from './utils.schema';

export const locationDetailsSchema = z
  .object({
    name: z.string(),
    parentLocationId: emptyTextSchema,
    parentLocationName: emptyTextSchema,
    country: emptyTextSchema.transform((country) => {
      // TODO: Remove this when the migration is done
      if (country === 'US') {
        return 'United States';
      }
      return country;
    }),
    addressId: emptyTextSchema,
    address1: emptyTextSchema,
    address2: emptyTextSchema,
    city: emptyTextSchema,
    state: emptyTextSchema,
    postalCode: emptyTextSchema,
    deviceCount: z.number(),
    equipmentCount: z.number(),
    automationCount: z.number(),
    description: emptyTextSchema,
    id: z.string(),
    organizationId: z.string(),
    isParent: z.boolean(),
    locationType: LocationTypes,
  })
  .transform((data) => ({
    ...data,
    parentLocation: {
      id: data.parentLocationId,
      name: data.parentLocationName,
    },
  }));

export type TLocationDetails = z.infer<typeof locationDetailsSchema>;
