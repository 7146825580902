import { queryClient, useMutation } from '@marlin/shared/utils/react-query';
import merge from 'lodash/merge';

import { updateAutomation } from '../infrastructure/automation';
import { TUpdateAutomation } from '../schemas/automation-upsert.schema';
import { TAutomation, TPagedAutomation } from '../schemas/automation.schema';
import { queryKey } from './query-key.enum';

interface IUpdateAutomationContext {
  previousData: TAutomation | undefined;
}

const mapAutomation = (automation: TAutomation, data: TUpdateAutomation): TAutomation => ({
  ...automation,
  name: data.name,
  device:
    data?.device && automation.device
      ? {
          ...automation.device,
          id: data?.device?.id ?? '',
        }
      : null,
  manufacturerId: data.manufacturerId,
  criticality: data.criticality,
  conditions: data.conditions,
  channels: data.channels,
  isSystemRule: data.isSystemRule ?? false,
  snoozeIntervalInMinutes: data.snoozeIntervalInMinutes,
});

const updateAutomationQueryClient = (updatingAutomation: TUpdateAutomation) => {
  queryClient.setQueryData<TAutomation | undefined>(
    queryKey.AUTOMATION(updatingAutomation.id),
    (data?: TAutomation) => {
      if (!data) {
        return data;
      }

      return mapAutomation(data, updatingAutomation);
    }
  );

  queryClient.setQueriesData<TPagedAutomation | undefined>(
    { queryKey: queryKey.AUTOMATIONS() },
    (data?: TPagedAutomation) => {
      if (!data) {
        return data;
      }

      const newAutomations = data.data.map((automation: TAutomation) => {
        if (automation.id !== updatingAutomation.id) {
          return automation;
        }

        return { ...merge(automation, updatingAutomation) };
      });

      return {
        pagination: data.pagination,
        data: newAutomations,
      };
    }
  );
};

export const useUpdateAutomation = () => {
  return useMutation({
    onMutate: async (updatingAutomation: TUpdateAutomation) => {
      await queryClient.cancelQueries({ queryKey: queryKey.AUTOMATION(updatingAutomation.id) });
      const previousData = queryClient.getQueryData<TAutomation>(queryKey.AUTOMATION(updatingAutomation.id));

      updateAutomationQueryClient(updatingAutomation);

      return { previousData };
    },
    onError: (error: unknown, updatingAutomation: TUpdateAutomation, context: IUpdateAutomationContext | undefined) => {
      if (!context?.previousData) {
        return;
      }

      updateAutomationQueryClient({
        id: context.previousData.id,
        isEnabled: context.previousData.isEnabled,
        isSystemRule: context.previousData.isSystemRule,
        device: context.previousData.device,
        manufacturerId: context.previousData.manufacturerId,
        name: context.previousData.name,
        snoozeIntervalInMinutes: context.previousData.snoozeIntervalInMinutes,
        criticality: context.previousData.criticality,
        conditions: context.previousData.conditions,
        channels: context.previousData.channels,
        ruleType: context.previousData.ruleType,
        duration: context.previousData.duration,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.AUTOMATIONS() });
    },
    mutationFn: updateAutomation,
  });
};
