import { MarlinTheme } from '@marlin/shared/theme';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import { useTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TimePicker as MuiTimePicker } from '@mui/x-date-pickers/TimePicker';
import moment from 'moment';
import { ControllerRenderProps } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { TimeItem } from './time-item.component';

interface IStyles {
  disabled: boolean;
}

const useStyles = makeStyles<IStyles>()((theme: MarlinTheme, { disabled }) => ({
  dialog: {
    width: theme.typography.pxToRem(232),
  },
  disabled: {
    '& > div.Mui-disabled': {
      backgroundColor: disabled ? theme.palette.background.alternative : theme.palette.background.primary,
      '& > input': {
        '-webkit-text-fill-color': disabled ? theme.palette.text.disabled : theme.palette.text.primary,
      },
    },
  },
  timeInput: {
    maxWidth: theme.typography.pxToRem(232),
    marginTop: theme.typography.pxToRem(6),
    marginRight: theme.typography.pxToRem(24),
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
      maxWidth: theme.typography.pxToRem(600),
    },
  },
  divider: {
    paddingTop: theme.typography.pxToRem(12),
    '& ul.MuiMultiSectionDigitalClock-root.MuiList-root': {
      borderLeft: 'none',
      width: theme.typography.pxToRem(61),
      marginLeft: 0,
      marginRight: theme.typography.pxToRem(10),
      '&:first-child': {
        marginLeft: theme.typography.pxToRem(16),
        marginRight: theme.typography.pxToRem(10),
      },
      '&:last-child': {
        marginLeft: 0,
        marginRight: theme.typography.pxToRem(16),
        fontWeight: theme.typography.fontWeightBold,
      },
    },
  },
}));

interface ICustomTimePicker extends Partial<ControllerRenderProps> {
  label: string;
  disabled: boolean;
}

export const TimePicker = ({ label, value, disabled, onChange }: ICustomTimePicker) => {
  const { classes } = useStyles({ disabled });
  const theme = useTheme<MarlinTheme>();
  const dateTime = moment.utc().set('hour', 0).set('minute', 0).minutes(value);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MuiTimePicker
        timezone={'UTC'}
        desktopModeMediaQuery={theme.breakpoints.up(theme.breakpoints.values.xs)}
        slots={{
          digitalClockSectionItem: TimeItem,
          openPickerIcon: WatchLaterIcon,
        }}
        slotProps={{
          layout: {
            classes: {
              root: classes.divider,
            },
          },
          desktopPaper: {
            classes: {
              root: classes.dialog,
            },
          },
          field: {
            disabled: true,
            className: classes.disabled,
          },
        }}
        className={classes.timeInput}
        label={label}
        minutesStep={10}
        disabled={disabled}
        skipDisabled
        value={dateTime}
        closeOnSelect
        onChange={(value) => {
          if (value) {
            const newValue = value.hours() * 60 + value.minutes();

            if (onChange) {
              onChange(newValue);
            }
          }
        }}
      />
    </LocalizationProvider>
  );
};
