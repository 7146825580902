import { queryClient, useMutation } from '@marlin/shared/utils/react-query';

import { cancelSnoozeAutomation } from '../infrastructure/snooze';
import { queryKey } from './query-key.enum';

export const useCancelSnoozeAutomation = () => {
  return useMutation({
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: queryKey.AUTOMATIONS() });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.AUTOMATIONS() });
      queryClient.invalidateQueries({ queryKey: queryKey.ALERTS() });
    },
    mutationFn: cancelSnoozeAutomation,
  });
};
