import { useQuery } from '@marlin/shared/utils/react-query';

import { getUserOrganizationStatus } from '../infrastructure/get-user-organization-status';
import { queryKey } from './query-key.enum';

export const useGetOrganizationStatus = ({ organizationId }: { organizationId: string }) => {
  return useQuery({
    queryKey: queryKey.ORGANIZATION_STATUS(organizationId),
    queryFn: () => getUserOrganizationStatus(organizationId),
    retry: 10,
    retryDelay: 1000,
  });
};
