import { IChip } from '@marlin/shared/ui-form';
import { TAssetType, TDeviceType } from '@marlin/system-map/shared/data-access-schemas';

import { content } from '../../../content';

type TAssetTypeOption = {
  [key in TAssetType | TDeviceType]?: string;
};

export const assetTypeOptions: TAssetTypeOption = {
  TEMPERATURE: content.TEMPERATURE_LABEL,
  PRESSURE: content.PRESSURE_LABEL,
  PULSE_METER: content.FLOW_METER,
  LEAK: content.LEAK_DETECTOR,
  EQUIPMENT: content.EQUIPMENT_LABEL,
};

export const filteringChips: IChip<TAssetType>[] = [
  {
    value: 'EQUIPMENT' as const,
    label: content.EQUIPMENT_LABEL,
  },
];
