import { ChartChip } from '@marlin/asset/shared/ui/chart-chips';
import { MarlinTheme } from '@marlin/shared/theme';
import { TChartDatapointsGroup } from '@marlin/shared/utils-chart';
import { Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';
import { useChartMetadata } from '../../hooks/use-chart-metadata.hook';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  row: {
    display: 'flex',
    gap: theme.typography.pxToRem(8),
    marginBottom: theme.typography.pxToRem(24),
    flexWrap: 'wrap',
  },
  button: {
    padding: `${theme.typography.pxToRem(4)} ${theme.typography.pxToRem(5)}`,
    marginLeft: theme.typography.pxToRem(8),
  },
}));

interface IChartChips {
  activeDatapoints: string[];
  activeThresholds: string[];
  toggleDatapointVisibility: (name: string) => void;
  toggleThresholdVisibility: (name: string) => void;
  datapointsGroup: TChartDatapointsGroup;
  clearActiveDatapoints: () => void;
}

export const ChartChips = ({
  activeThresholds,
  toggleThresholdVisibility,
  datapointsGroup,
  toggleDatapointVisibility,
  activeDatapoints,
  clearActiveDatapoints,
}: IChartChips) => {
  const { classes } = useStyles();

  const { datapointsMetadata, thresholdsMetadata } = useChartMetadata(datapointsGroup);

  return (
    <div>
      <div className={classes.row}>
        {datapointsMetadata.map((datapoint) => (
          <ChartChip
            key={datapoint.name}
            label={datapoint.label}
            color={datapoint.color}
            isActive={activeDatapoints.includes(datapoint.name)}
            toggleChip={toggleDatapointVisibility}
            name={datapoint.name}
          />
        ))}
        {!thresholdsMetadata.length ? <ClearAllButton clearActiveDatapoints={clearActiveDatapoints} /> : null}
      </div>
      <div className={classes.row}>
        {thresholdsMetadata.map((threshold) => (
          <ChartChip
            key={threshold.name}
            label={threshold.label}
            alias={threshold.alias}
            isActive={activeThresholds.includes(threshold.name)}
            toggleChip={toggleThresholdVisibility}
            name={threshold.name}
          />
        ))}
        {thresholdsMetadata.length ? <ClearAllButton clearActiveDatapoints={clearActiveDatapoints} /> : null}
      </div>
    </div>
  );
};

const ClearAllButton = ({ clearActiveDatapoints }: { clearActiveDatapoints: () => void }) => {
  const { classes } = useStyles();

  return (
    <Button className={classes.button} variant="text" onClick={clearActiveDatapoints}>
      {content.CLEAR_ALL}
    </Button>
  );
};
