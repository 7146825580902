import { IHttpParams } from './endpoint.models';

const removeLeadingSlashes = (url: string): string => url.replace(/^\/+/g, '');

const removeTrailingSlashes = (url: string): string => url.replace(/\/+$/, '');

const removeLeadingAndTrailingSlashes = (url: string): string => removeLeadingSlashes(removeTrailingSlashes(url));

const parseUrl = (url: string): string => `/${removeLeadingSlashes(url)}`;

export const getFullUrl = (baseUrl: string, url: string, useHash: boolean): string => {
  baseUrl = `${removeTrailingSlashes(baseUrl)}/`;
  if (useHash) {
    baseUrl = `${baseUrl}#/`;
  }

  if (url && url.startsWith(baseUrl)) {
    return url;
  }

  return `${removeTrailingSlashes(baseUrl)}${parseUrl(url)}`;
};

export const replaceParams = (url: string, httpParams?: IHttpParams): string => {
  if (!httpParams || !httpParams.keys().length) {
    return url;
  }

  httpParams.keys().forEach((key: string): void => {
    if (httpParams.has(key) && httpParams.getAll(key)?.length) {
      url = url.replace(`{${key}}`, httpParams.get(key) as string);
    }
  });
  return url;
};

export const replaceAppBaseHref = (appBaseHref: string, url: string): string => {
  return url.replace(removeLeadingAndTrailingSlashes(appBaseHref), '');
};

export const getParamsFromUrl = (url: string): string[] => {
  const matches = url.match(/{([^}]+)}/g);

  if (!matches) {
    return [];
  }

  return matches.map((match: string): string => match.replace('{', '').replace('}', ''));
};
