import * as z from 'zod';

const CoordinateSchema = z.object({
  latitude: z.number().nullish(),
  longitude: z.number().nullish(),
});

const PostalAddressSchema = z.object({
  revision: z.number().nullish(),
  regionCode: z.string().nullish(),
  languageCode: z.string().nullish(),
  postalCode: z.string().nullish(),
  sortingCode: z.string().nullish(),
  administrativeArea: z.string().nullish(),
  locality: z.string().nullish(),
  sublocality: z.string().nullish(),
  addressLines: z.array(z.string()),
});

export const AddressRequestSchema = z.object({
  address: PostalAddressSchema,
  previousResponseId: z.string().nullish(),
  enableUspsCass: z.boolean().nullish(),
  sessionToken: z.string().nullish(),
});

const VerdictSchema = z.object({
  inputGranularity: z.string(),
  validationGranularity: z.string(),
  geocodeGranularity: z.string(),
  hasUnconfirmedComponents: z.boolean().nullish(),
  addressComplete: z.boolean().nullish(),
  hasInferredComponents: z.boolean().nullish(),
  hasReplacedComponents: z.boolean().nullish(),
});

const AddressComponentSchema = z.object({
  componentName: z.object({
    text: z.string(),
    languageCode: z.string(),
  }),
  componentType: z.string(),
  confirmationLevel: z.string(),
});

export const AddressResponseSchema = z.object({
  result: z.object({
    verdict: VerdictSchema,
    address: z.object({
      formattedAddress: z.string(),
      postalAddress: z.object({
        regionCode: z.string(),
        languageCode: z.string(),
        postalCode: z.string(),
      }),
      addressComponents: z.array(AddressComponentSchema),
      missingComponentTypes: z.array(z.string()),
      unconfirmedComponentTypes: z.array(z.string()),
    }),
    geocode: z
      .object({
        location: CoordinateSchema.nullish(),
      })
      .nullish(),
  }),
  responseId: z.string(),
});

export const AddressToAddressLinesSchema = z
  .object({
    address1: z.string(),
    address2: z.string().nullish(),
    city: z.string(),
    state: z.string(),
    postalCode: z.string(),
    country: z.string(),
  })
  .transform((address) => {
    const addressLines: string[] = [];

    addressLines.push(address.address1);

    if (address.address2) {
      addressLines.push(address.address2);
    }

    addressLines.push(`${address.city}, ${address.state} ${address.postalCode}`);
    addressLines.push(address.country);

    return addressLines;
  });

export type TValidationAddressComponent = z.infer<typeof AddressComponentSchema>;
export type TAddressRequest = z.infer<typeof AddressRequestSchema>;
export type TAddressResponse = z.infer<typeof AddressResponseSchema>;
export type TCoordinates = z.infer<typeof CoordinateSchema>;
export type TVerdict = z.infer<typeof VerdictSchema>;
