import { TUnitOfMeasure } from '../zod/uom.schema';
import { convertValue } from './convert-value';

export const displayVolumePriceUtil = (
  value: number,
  price: number,
  uomTo: TUnitOfMeasure | null,
  uomFrom: TUnitOfMeasure | null = 'gal'
) => {
  const convertedValue = convertValue(value, uomFrom, uomTo);
  return Number(convertedValue?.value) * price;
};
