import { PERMISSIONS, Restricted } from '@marlin/shared/utils-permission';
import { Button, Typography } from '@mui/material';

import { useStyles } from './empty-card.styles';

interface IEmptyCard {
  title: string;
  subtitle: string;
  btnText: string;
  action: () => void;
  actionPermission?: PERMISSIONS | PERMISSIONS[];
  titleTestId?: string;
  subtitleTestId?: string;
}

export const EmptyCard = ({
  title,
  subtitle,
  btnText,
  action,
  actionPermission,
  titleTestId,
  subtitleTestId,
}: IEmptyCard) => {
  const { classes } = useStyles();
  const actionButton = (
    <div>
      <Button variant="contained" onClick={action}>
        {btnText}
      </Button>
    </div>
  );

  return (
    <div className={classes.container}>
      <div>
        <Typography data-testid={titleTestId ? titleTestId : 'card-title'} className={classes.title}>
          {title}
        </Typography>
      </div>
      <div>
        <Typography data-testid={subtitleTestId ? subtitleTestId : 'card-title'} className={classes.subtitle}>
          {subtitle}
        </Typography>
      </div>
      {actionPermission ? <Restricted to={actionPermission}>{actionButton}</Restricted> : actionButton}
    </div>
  );
};
