import { ReadingCriticality } from '@marlin/shared/ui-criticality';
import { Paper } from '@marlin/shared/ui-page';
import { formatReading, useDeviceReadings } from '@marlin/shared/utils-format-reading';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { Grid } from '@mui/material';
import { useMemo } from 'react';

import { content } from '../content';
import { IPinnedDevice } from '../types';
import { Breadcrumbs } from './breadcrumbs.component';
import { CardTitle } from './card-title.component';
import { useStyles } from './pinned-device-card.styles';
import { Unpin } from './unpin.component';

interface IPinnedDeviceCardProps {
  pinnedDevice: IPinnedDevice;
  onUnpin: (id: string) => void;
  deviceNumbers?: number;
}

export function PinnedDeviceCard({ pinnedDevice, onUnpin, deviceNumbers }: IPinnedDeviceCardProps) {
  const fullCardMobile = deviceNumbers === 1;
  const { classes } = useStyles({ fullCardMobile });
  const lastReading = useDeviceReadings(pinnedDevice.device.lastReading.value, pinnedDevice.device.type, 'decimal');
  const { goTo, getUrl } = useRouter();
  const currentUrl = useMemo(() => getUrl(), [getUrl]);

  const criticalityClass = useMemo(() => {
    if (!pinnedDevice.alert.criticality) {
      return classes.noCriticalityContainer;
    }
    if (pinnedDevice.alert.criticality === 'LOW') {
      return classes.lowCriticalityContainer;
    }
    return classes.highCriticalityContainer;
  }, [classes, pinnedDevice]);

  return (
    <Grid
      item
      xs={12}
      md={4}
      className={classes.grid}
      data-testid="pinned-sensor-card"
      onClick={() => {
        goTo(routes.deviceDrawer.open(currentUrl, pinnedDevice.device.id));
      }}
    >
      <Paper className={classes.card}>
        <Grid container className={classes.gap}>
          <Grid item xs={9} md={9}>
            <Breadcrumbs location={pinnedDevice.location} equipment={pinnedDevice.equipment} />
          </Grid>
          <Grid item xs={3} md={3} className={classes.rightSideOfCard}>
            <Unpin onUnpinClick={() => onUnpin(pinnedDevice.device.id)} />
          </Grid>
        </Grid>
        <Grid container className={classes.secondGap}>
          <Grid item xs={9} md={9} className={classes.title}>
            <CardTitle device={pinnedDevice.device} />
          </Grid>
          <Grid item xs={3} md={3} className={classes.rightSideOfCard}>
            <div className={criticalityClass}>
              <ReadingCriticality
                tooltipContent={content.SENSOR_ALERT}
                criticalities={[{ criticality: pinnedDevice.alert.criticality }]}
              >
                {formatReading(lastReading)}
              </ReadingCriticality>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
