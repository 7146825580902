import { INPUT_TYPE } from '@marlin/shared/ui-form-common';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';

interface IVisibilityProps {
  inputType: INPUT_TYPE;
  onInputTypeChange: (inputType: INPUT_TYPE) => void;
}

export const Visibility = ({ inputType, onInputTypeChange }: IVisibilityProps) => {
  const hidden = inputType === INPUT_TYPE.PASSWORD;
  const hide = () => onInputTypeChange(INPUT_TYPE.PASSWORD);
  const show = () => onInputTypeChange(INPUT_TYPE.TEXT);

  return hidden ? (
    <VisibilityRoundedIcon color="primary" onClick={show} />
  ) : (
    <VisibilityOffRoundedIcon color="primary" onClick={hide} />
  );
};
