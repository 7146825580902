import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import { GraphSchema, TGraph, TGraphResponse } from '../flow-map.model.schema';
import { paths } from './api';

export interface IDeleteSystemMapLink {
  flowLinkId: string;
}

export const deleteLink = async (params: IDeleteSystemMapLink): Promise<TGraph> => {
  const res = await getHttpClient().delete<unknown, TGraphResponse>(paths.SYSTEM_MAP_LINK_DETAILS, {
    params: { flowLinkId: params.flowLinkId },
  });

  return safeParseData(res, GraphSchema);
};
