import { FormField, IFormControlProps, IFormTypedProps, Input } from '@marlin/shared/ui-form-common';
import { ControllerProps, FieldValues } from 'react-hook-form';

import { content } from '../content';

export function FirstNameControl<TFieldValues extends FieldValues>(props: IFormControlProps<TFieldValues>): JSX.Element;
export function FirstNameControl<
  TFieldValues extends FieldValues = object,
  TName extends ControllerProps<TFieldValues>['name'] = ControllerProps<TFieldValues>['name']
>(props: IFormTypedProps<TFieldValues, TName>): JSX.Element;

export function FirstNameControl<
  TFieldValues extends FieldValues = object,
  TName extends ControllerProps<TFieldValues>['name'] = ControllerProps<TFieldValues>['name']
>(params: IFormControlProps<TFieldValues> | IFormTypedProps<TFieldValues, TName>) {
  if (params.control) {
    return (
      <FormField {...params}>
        {(props) => (
          <Input
            label={content.FIRST_NAME_CONTROL_LABEL}
            placeholder={content.FIRST_NAME_CONTROL_LABEL}
            required
            testId={props.name}
            disabled={params.disabled}
            {...props}
          />
        )}
      </FormField>
    );
  }

  return (
    <FormField<TFieldValues> {...params}>
      {(props) => (
        <Input
          label={content.FIRST_NAME_CONTROL_LABEL}
          placeholder={content.FIRST_NAME_CONTROL_LABEL}
          required
          testId={props.name}
          disabled={params.disabled}
          {...props}
        />
      )}
    </FormField>
  );
}
