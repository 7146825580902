import { THomeLocationSchema } from '@marlin/asset/data-access/home-dashboard';
import { LoadingSpinner } from '@marlin/shared/ui-loader';

import { content } from '../../content';
import { TAB_VALUES } from '../../use-home.hook';
import { HomeCard } from '../card';
import { HomeListEmpty } from '../list-empty';
import { HomeListNoMechanicalRooms } from '../list-no-mechanical-rooms';
import { useStyles } from './list.component.styles';

export interface IHomeListProps {
  isLoading: boolean;
  isError: boolean;
  tabValue: number;
  viewLocations: THomeLocationSchema[];
}

const HomeList = ({ tabValue, isLoading, viewLocations, isError }: IHomeListProps) => {
  const { classes } = useStyles();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!viewLocations.length) {
    if (isError) {
      return <HomeListEmpty message={content.ERROR} />;
    }

    switch (tabValue) {
      case TAB_VALUES.MechanicalRooms: {
        return <HomeListNoMechanicalRooms />;
      }
      case TAB_VALUES.OpenAlerts: {
        return <HomeListEmpty message={content.NO_ALERTS} />;
      }
      case TAB_VALUES.AllLocations:
      default: {
        return <HomeListEmpty message={content.NO_DATA} />;
      }
    }
  }

  return (
    <div className={classes.wrapper}>
      {viewLocations.map((homeLocation) => (
        <HomeCard homeLocation={homeLocation} key={homeLocation.locationId} />
      ))}
    </div>
  );
};

export { HomeList };
