import { TExtractFnReturnType, useQuery } from '@marlin/shared/utils/react-query';

import { getCurrentUser } from '../infrastructure/user';
import { queryKey } from './query-key.enum';

type TQueryFnType = typeof getCurrentUser;

export const useCurrentUser = (enabled = true) => {
  return useQuery<TExtractFnReturnType<TQueryFnType>>({
    queryKey: queryKey.CURRENT_USER(),
    queryFn: getCurrentUser,
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled,
  });
};
