import { useMediaQuery, useTheme } from '@mui/material';
import { useMemo } from 'react';

import { useStyles } from './header.component.styles';

const maxAllowedCharactersDeviceNameHeaderMobileOneRow = 12;
const maxAllowedCharactersDeviceNameHeaderOneRow = 24;
const maxAllowedCharactersDeviceNameHeaderTwoRow = 42;

interface IUseHeaderStyleProps {
  deviceName: string;
}

export const useHeaderStyle = ({ deviceName }: IUseHeaderStyleProps) => {
  const theme = useTheme();
  const { classes } = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const isDesktopLongDeviceName = useMemo(() => {
    return deviceName.length >= maxAllowedCharactersDeviceNameHeaderTwoRow && !isMobile;
  }, [deviceName.length, isMobile]);

  const isDesktopShortDeviceName = useMemo(() => {
    return deviceName.length <= maxAllowedCharactersDeviceNameHeaderOneRow && !isMobile;
  }, [deviceName.length, isMobile]);

  const isMobileLongDeviceName = useMemo(() => {
    return deviceName.length >= maxAllowedCharactersDeviceNameHeaderTwoRow && isMobile;
  }, [deviceName.length, isMobile]);

  const isMobileShortDeviceName = useMemo(() => {
    return deviceName.length <= maxAllowedCharactersDeviceNameHeaderMobileOneRow && isMobile;
  }, [deviceName.length, isMobile]);

  const headerStyle = useMemo(() => {
    return isDesktopLongDeviceName
      ? classes.desktopLongHeader
      : isDesktopShortDeviceName
      ? classes.header
      : isMobileLongDeviceName
      ? classes.mobileLongHeader
      : isMobileShortDeviceName
      ? classes.mobileShortHeader
      : classes.header;
  }, [classes, isDesktopShortDeviceName, isDesktopLongDeviceName, isMobileLongDeviceName, isMobileShortDeviceName]);

  const deviceNameTextStyle = useMemo(() => {
    return isDesktopLongDeviceName
      ? classes.deviceNameLongDesktop
      : isMobileLongDeviceName
      ? classes.deviceNameLongMobile
      : isMobileShortDeviceName
      ? classes.shortDeviceNameMobile
      : classes.deviceName;
  }, [classes, isDesktopLongDeviceName, isMobileLongDeviceName, isMobileShortDeviceName]);

  return { headerStyle, deviceNameTextStyle, isDesktopShortDeviceName };
};
