import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    paddingTop: theme.typography.pxToRem(64),
    paddingRight: theme.typography.pxToRem(48),
    paddingBottom: theme.typography.pxToRem(32),
    paddingLeft: theme.typography.pxToRem(48),
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.typography.pxToRem(26),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.typography.pxToRem(32),
      paddingRight: theme.typography.pxToRem(24),
      paddingBottom: theme.typography.pxToRem(16),
      paddingLeft: theme.typography.pxToRem(24),
      gap: theme.typography.pxToRem(16),
    },
  },
  button: {
    color: theme.palette.secondary.light,
    alignSelf: 'center',
  },
  logo: {
    alignSelf: 'center',
  },
}));
