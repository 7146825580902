export const content = {
  SHOW_FLOW_LINK_ATTACHMENT: 'Show flow link attachments',
  FLOW_LINK_FOUND: 'Flow link attachments found',
  NO_FLOW_LINK_ATTACHMENTS: 'No flow link attachments',
  MODAL_TITLE: 'List of Flow Link Attachments',
  MODAL_CANCEL: 'Close window',
  TEE: 'Tee',
  NOT_APPLICABLE: 'N/A',
  FLOW_LINK_ATTACHMENTS: 'Flow Link Attachments',
  UPDATE_VALIDATION: 'Clicking update will change the position of the location and attached sensors.',
  FLOW_LINK_ATTACHMENTS_ERROR: (link: JSX.Element) => (
    <>
      Flow link attachment found. Flow link attachments must be in the same location as the location or equipment they
      are attached to. Move {link} to move this sensor.
    </>
  ),
};
