import { MarlinTheme } from '@marlin/shared/theme';
import { FormField } from '@marlin/shared/ui-form-common';
import { IFormControlProps, IFormTypedProps } from '@marlin/shared/ui-form-common';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useState } from 'react';
import { ControllerProps, ControllerRenderProps, FieldError, FieldValues } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';
import { IDeviceTypeOption } from './const';
import { TDeviceType } from './device-type.schema';
import { useDeviceTypeOptions } from './use-device-type-options.hook';

type TRenderProps = Partial<Omit<ControllerRenderProps, 'ref'>>;

interface ISelectChildren {
  children?: (props: {
    options: IDeviceTypeOption[];
    value: TRenderProps['value'];
    onDelete: (id: string) => void;
  }) => JSX.Element;
}

interface ISelectProps extends ISelectChildren {
  multiple?: boolean;
  className?: string;
  featureFlags?: { intellistation: boolean };
}

interface ISelectControlProps extends ISelectProps, TRenderProps {
  error?: FieldError;
  disabled?: boolean;
  defaultValue?: TDeviceType;
  required?: boolean;
  featureFlags?: { intellistation: boolean };
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  deviceTypeOption: {
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.typography.pxToRem(10),
    flexWrap: 'wrap',
  },

  active: {
    color: theme.palette.action.active,
  },

  disabled: {
    color: theme.palette.text.secondary,
  },

  emptyOption: {
    height: '36px',
  },
}));

const SelectControl = ({
  error,
  disabled = false,
  required = false,
  multiple = false,
  children,
  ...rest
}: ISelectControlProps) => {
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);
  const { deviceTypeOptions, filterSelectedOptions } = useDeviceTypeOptions();

  const options = multiple ? filterSelectedOptions(deviceTypeOptions, rest.value) : deviceTypeOptions;

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    if (options.length) {
      setOpen(true);
    }
  };

  const onDelete = (idToRemove: string) => {
    const newDeviceTypes = ((rest.value || []) as string[]).filter((id) => id !== idToRemove);
    rest.onChange && rest.onChange(newDeviceTypes);
  };

  const selectValueAdapter = () => {
    if (multiple) {
      return rest.value;
    }

    return rest.value ? rest.value.toString() : '';
  };

  return (
    <FormControl error={!!error} fullWidth={true}>
      <InputLabel
        id="device-type-select-label"
        required={required}
        disabled={disabled}
        shrink={multiple ? false : undefined}
      >
        {content.SENSOR_TYPE_LABEL}
      </InputLabel>
      <Select
        notched={multiple ? false : undefined}
        id="device-type-select"
        labelId="device-type-select-label"
        data-testid="device-type-select"
        label={content.SENSOR_TYPE_LABEL}
        required={required}
        fullWidth={true}
        disabled={disabled}
        multiple={multiple}
        error={!!error}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        {...rest}
        value={selectValueAdapter()}
      >
        {!multiple && <MenuItem key={''} value={''} className={classes.emptyOption} onClick={handleClose} />}
        {options.map(({ value, Icon, name }) => (
          <MenuItem value={value} key={value} onClick={handleClose}>
            <div className={classes.deviceTypeOption}>
              <Icon className={disabled ? classes.disabled : classes.active} />
              {name}
            </div>
          </MenuItem>
        ))}
      </Select>
      {multiple && children && children({ options: deviceTypeOptions, value: rest.value || [], onDelete })}
    </FormControl>
  );
};

export function DeviceTypeControl<TFieldValues extends FieldValues>(
  props: IFormControlProps<TFieldValues> & ISelectProps
): JSX.Element;
export function DeviceTypeControl<
  TFieldValues extends FieldValues = object,
  TName extends ControllerProps<TFieldValues>['name'] = ControllerProps<TFieldValues>['name']
>(props: IFormTypedProps<TFieldValues, TName> & ISelectProps): JSX.Element;

export function DeviceTypeControl<
  TFieldValues extends FieldValues,
  TName extends ControllerProps<TFieldValues>['name'] = ControllerProps<TFieldValues>['name']
>(params: (IFormControlProps<TFieldValues> | IFormTypedProps<TFieldValues, TName>) & ISelectProps) {
  if (params.control) {
    // TODO: Remove support for the defaultValue after device forms refactor
    return (
      <FormField {...params}>
        {(props) => (
          <SelectControl
            {...props}
            className={params?.className}
            disabled={params?.disabled}
            defaultValue={params?.defaultValue}
            required={params?.required}
            multiple={params?.multiple}
            children={params.children}
          />
        )}
      </FormField>
    );
  }

  return (
    <FormField<TFieldValues> {...params}>
      {(props) => (
        <SelectControl
          className={params?.className}
          disabled={params?.disabled}
          required={params?.required}
          multiple={params?.multiple}
          children={params.children}
          {...props}
        />
      )}
    </FormField>
  );
}
