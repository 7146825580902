import { zodResolver } from '@hookform/resolvers/zod';
import { LocationSearchWrapper } from '@marlin/alert/data-access/automated-action';
import { useAssetLinks } from '@marlin/asset/data-access/asset';
import { EQUIPMENT_UPSERT_ERROR } from '@marlin/asset/data-access/equipment';
import {
  AttachmentUpdateValidation,
  AttachmentValidation,
  getAttachments,
  useHasAttachments,
} from '@marlin/asset/shared/ui/attachment-validation';
import {
  EquipmentFormTypes,
  editEquipmentFormDataSchema,
  equipmentFormDataSchema,
} from '@marlin/asset/shared/utils/equipment-upsert';
import { EquipmentModelControl, TEquipmentFormTypes } from '@marlin/shared/ui-form';
import { FormField, InfiniteAutocompleteControl, Input, Select } from '@marlin/shared/ui-form-common';
import { Paper } from '@marlin/shared/ui-page';
import { EquipmentTypeEnum, TEquipment, TUpsertEquipmentForm, UpsertEquipmentForm } from '@marlin/shared/utils/zod';
import { Button, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { getEquipmentTypeByModel } from '../utils';
import { AercoForm } from './components/aerco-form.component';
import { BradleyPartitionForm } from './components/bradley-partition-form.component';
import { IntellistationForm } from './components/intellistation-form.component';
import { OtherForm } from './components/other-form.component';
import { PviForm } from './components/pvi-form.component';
import { SentinelForm } from './components/sentinel-form.component';
import { content } from './content';
import { useStyles } from './upsert-equipment.styles';
import { useEquipmentTypeOptions } from './use-equipment-type-options.hook';
import { useUpsertEquipment } from './use-upsert.equipment-form.hook';

interface IUpsertEquipmentProps {
  defaultValues?: TEquipment;
  equipmentId?: string;
  disableSubmit: boolean;
  onSubmit: (value: TUpsertEquipmentForm) => void;
  onCancel: () => void;
  upsertEquipmentErrorCode?: EQUIPMENT_UPSERT_ERROR | undefined;
}

export const UpsertEquipment = ({
  defaultValues,
  equipmentId,
  disableSubmit,
  onSubmit,
  onCancel,
  upsertEquipmentErrorCode,
}: IUpsertEquipmentProps) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isEdit = useMemo(() => !!equipmentId, [equipmentId]);

  const form = useForm<TUpsertEquipmentForm>({
    mode: 'onTouched',
    resolver: zodResolver(isEdit ? editEquipmentFormDataSchema : equipmentFormDataSchema),
    defaultValues: defaultValues ? UpsertEquipmentForm.parse(defaultValues) : undefined,
  });

  const {
    handleSubmit,
    watch,
    setError,
    reset,
    clearErrors,
    formState: { isDirty, isValid, errors, dirtyFields },
  } = form;

  const locationId = watch('location.id');
  const equipmentType = watch('formType');
  const type = watch('type');
  const locationChanged = dirtyFields.locationId;
  const assetId = defaultValues?.devices?.[0]?.id ?? equipmentId;

  const { dateValidationErrorMessage, setDateValidationErrorMessage } = useUpsertEquipment({
    setError,
    upsertEquipmentErrorCode,
    equipmentType,
  });

  const { data } = useAssetLinks({ assetId: assetId ?? '', enabled: !!locationChanged && isEdit });
  const shouldDisplayAttachmentValidation = isEdit && data && locationChanged && !!locationId;
  const attachmentsFound = useHasAttachments(data);

  const equipmentTypesOptions = useEquipmentTypeOptions();

  const isGateway = defaultValues?.parentDeviceType === 'Gateway';
  const shouldLocationBeDisabled = isEdit && (defaultValues?.locationType === 'Plant' || isGateway);

  const disableSubmitButton =
    disableSubmit ||
    !isDirty ||
    !isValid ||
    !!dateValidationErrorMessage ||
    !!Object.keys(errors).length ||
    !equipmentType;

  const getFormFields = () => {
    if (isGateway && type === EquipmentTypeEnum.enum.PARTITION_CONTROLLER) {
      return (
        <BradleyPartitionForm
          dateValidationErrorMessage={dateValidationErrorMessage}
          setDateValidationErrorMessage={setDateValidationErrorMessage}
        />
      );
    }

    if (isGateway && type === EquipmentTypeEnum.enum.HOT_WATER_HEATER) {
      return (
        <PviForm
          dateValidationErrorMessage={dateValidationErrorMessage}
          setDateValidationErrorMessage={setDateValidationErrorMessage}
        />
      );
    }

    switch (equipmentType) {
      case EquipmentFormTypes.enum.tekmarSmartSteam:
      case EquipmentFormTypes.enum.intellistation:
      case EquipmentFormTypes.enum.tekmarSmartBoiler: {
        return (
          <IntellistationForm
            isEdit={isEdit}
            dateValidationErrorMessage={dateValidationErrorMessage}
            setDateValidationErrorMessage={setDateValidationErrorMessage}
          />
        );
      }

      case EquipmentFormTypes.enum.sentinel: {
        return (
          <SentinelForm
            isEdit={isEdit}
            dateValidationErrorMessage={dateValidationErrorMessage}
            setDateValidationErrorMessage={setDateValidationErrorMessage}
          />
        );
      }

      case EquipmentFormTypes.enum.other: {
        return (
          <OtherForm
            dateValidationErrorMessage={dateValidationErrorMessage}
            setDateValidationErrorMessage={setDateValidationErrorMessage}
          />
        );
      }

      case EquipmentFormTypes.enum.aercoBenchmark:
      case EquipmentFormTypes.enum.aercoInnovation: {
        return (
          <AercoForm
            isEdit={isEdit}
            dateValidationErrorMessage={dateValidationErrorMessage}
            setDateValidationErrorMessage={setDateValidationErrorMessage}
            key={equipmentType}
            upsertEquipmentErrorCode={upsertEquipmentErrorCode}
          />
        );
      }

      default: {
        return null;
      }
    }
  };

  useEffect(() => {
    if (isEdit && defaultValues) {
      form.setValue('formType', getEquipmentTypeByModel(defaultValues?.model));
    }
  }, [defaultValues]);

  return (
    <Paper className={classes.formContainer}>
      <div className={classes.formSection}>{content.EQUIPMENT_LABEL}</div>
      <FormProvider {...form}>
        <FormField<TUpsertEquipmentForm> fieldName="name">
          {(props) => (
            <Input
              {...props}
              className={classes.input}
              label={content.EQUIPMENT_NAME}
              required
              disabled={isGateway}
              testId="equipment-name-field"
              error={
                errors.name && {
                  type: 'custom',
                  message: errors.name.message,
                }
              }
            />
          )}
        </FormField>
        <div className={classes.fieldWrapper}>
          <LocationSearchWrapper enableSearch={false}>
            {(props) => (
              <>
                <InfiniteAutocompleteControl<TEquipment>
                  {...props}
                  fieldName="location"
                  testId="select-filters-location"
                  label={content.LOCATION_NAME}
                  className={classes.selectMargins}
                  disabled={shouldLocationBeDisabled}
                  multiple={false}
                  required
                />
                {shouldDisplayAttachmentValidation && (
                  <AttachmentValidation
                    assetId={assetId}
                    data={getAttachments(data)}
                    variant={attachmentsFound ? 'warning' : 'success'}
                  />
                )}
              </>
            )}
          </LocationSearchWrapper>
        </div>
        {!isEdit && (
          <div className={classes.equipmentTypeWrapper}>
            <EquipmentModelControl<TUpsertEquipmentForm>
              fieldName="formType"
              required
              requiredErrorMessageOnBlur={content.REQUIRED}
              onChange={(e) => {
                reset(
                  {
                    name: watch('name'),
                    location: watch('location'),
                    formType: e.target.value as TEquipmentFormTypes,
                  },
                  { keepDirty: true, keepErrors: true }
                );
                clearErrors('registrationCode');
                clearErrors('serialNumber');
              }}
            />
          </div>
        )}
        {isEdit && (isGateway || defaultValues?.isRegistered) && (
          <FormField<TEquipment> fieldName="type">
            {(props) => (
              <Select
                {...props}
                disabled
                prefix="type"
                emptyOption=" "
                value={props.value}
                label={content.EQUIPMENT_TYPE}
                data={equipmentTypesOptions}
                className={classes.input}
              />
            )}
          </FormField>
        )}
        {getFormFields()}
        <div className={classes.buttonWrapper}>
          {!isMobile && (
            <Button
              data-testid="equipment-cancel-button"
              className={classes.button}
              variant="outlined"
              onClick={onCancel}
            >
              {content.CANCEL}
            </Button>
          )}
          <Button
            data-testid="equipment-submit-button"
            className={classes.button}
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            disabled={disableSubmitButton}
          >
            {isEdit ? content.UPDATE : content.CREATE}
          </Button>
        </div>
        {isEdit && locationChanged && attachmentsFound && (
          <div className={classes.attachmentUpdateValidation}>
            <AttachmentUpdateValidation />
          </div>
        )}
      </FormProvider>
    </Paper>
  );
};
