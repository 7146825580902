import { AUTOMATION_RULE_TYPE } from '@marlin/alert/data-access/automated-action';
import { AutomationName } from '@marlin/alert/ui/automated-action-create';
import { Paper } from '@marlin/shared/ui-page';

export const AutomationInfo = () => {
  return (
    <Paper>
      <AutomationName ruleType={AUTOMATION_RULE_TYPE.PRESSURE} isSystemRule={false} />
    </Paper>
  );
};
