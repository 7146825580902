import { TExtractFnReturnType } from '@marlin/shared/utils/react-query';
import { useInfiniteQuery, useQuery } from '@marlin/shared/utils/react-query';

import { getFilteredLocations } from '../infrastructure/location';
import { TLocationParamsSchema } from '../infrastructure/schema/location.request.schema';
import { queryKey } from './query-key.enum';

type TQueryFnType = typeof getFilteredLocations;

type TUseLocationOptions = {
  params: TLocationParamsSchema;
  select?: (data: TExtractFnReturnType<TQueryFnType>) => TExtractFnReturnType<TQueryFnType>;
  enabled?: boolean;
};

export const useFilteredLocationsWithPaging = ({ params, select, enabled = true }: TUseLocationOptions) => {
  return useQuery<TExtractFnReturnType<TQueryFnType>>({
    queryKey: queryKey.LOCATIONS(params),
    queryFn: () => getFilteredLocations(params),
    keepPreviousData: true,
    select,
    enabled,
  });
};

type TUseInfiniteLocationsProps = {
  params: Omit<TLocationParamsSchema, 'page'>;
};

export const useFilteredLocationsWithLoadMore = ({ params }: TUseInfiniteLocationsProps) => {
  return useInfiniteQuery<TExtractFnReturnType<TQueryFnType>>({
    queryKey: queryKey.LOCATIONS(params),
    queryFn: (queryFnParams) => getFilteredLocations({ ...params, page: queryFnParams.pageParam || 1 }),
    getNextPageParam: (lastPage) => {
      return lastPage.pagination.page * lastPage.pagination.pageSize < lastPage.pagination.totalItems
        ? lastPage.pagination.page + 1
        : undefined;
    },
  });
};
