import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    background: theme.palette.primary.main,
    height: theme.typography.pxToRem(48),
    minHeight: theme.typography.pxToRem(48),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  chartTypeSwitcher: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: theme.typography.pxToRem(16),
  },
  chips: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: theme.typography.pxToRem(8),
    marginBottom: theme.typography.pxToRem(16),
    flexWrap: 'wrap',
  },
  chart: {
    marginTop: theme.typography.pxToRem(16),
  },
}));
