export const content = {
  SENSORS_HUB_TITLE: 'Sensors',
  SENSORS_HUB_SUBTITLE: 'Manage your sensors.',
  NO_DATA: 'No results found.',
  ERROR: 'Something went wrong. Please try again later.',
  CREATE_NEW_BUTTON: 'Create New',
  SEARCH_LOCATION: 'Search by sensor or equipment',
  LOCATION_LABEL: 'Location',
  SENSOR_TYPE_LABEL: 'Sensor Type',
  CLEAR_ALL: 'Clear All',
  ID_HEADER_NAME: 'ID',
  SENSOR_HEADER_NAME: 'Sensor',
  EQUIPMENT_HEADER_NAME: 'Equipment',
  LOCATION_HEADER_NAME: 'Location',
  MANUFACTURER_ID_HEADER_NAME: 'Manufacturer ID',
  TYPE_HEADER_NAME: 'Type',
  LAST_READING_HEADER_NAME: 'Last Reading',
  LAST_READING_TIME_HEADER_NAME: 'Last Reading Time',
  POWER_SOURCE_HEADER_NAME: 'Power Source',
  TEMPERATURE_ITEM: 'Temperature Sensor',
  FLOW_METER: 'Flow Meter',
  PRESSURE_ITEM: 'Pressure Sensor',
  LEAK_ITEM: 'Leak Detector',
  UNKNOWN_OPTION: 'Unknown option',
  EMPTY_LIST: 'No results found.',
  SENSOR_SORTING_NAME: 'Sensor Name',
  EQUIPMENT_SORTING_NAME: 'Equipment Name',
  LOCATION_SORTING_NAME: 'Location Name',
  ACTION_COPY: 'Copy ID',
  LOST_COMMUNICATION: 'Lost Comm',
  EMPTY_CELL: '--',
  BATTERIES_GOOD: 'Batteries Good',
  BATTERIES_LOW: 'Batteries Low',
  BATTERIES_CRITICAL: 'Batteries Critical',
};
