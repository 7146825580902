import { TNode } from '@marlin/system-map/data-access/system-map';
import { ReactNode } from 'react';

import { Node } from './node.component';
import { TeeNode } from './tee-node.component';
import { NODE_TYPE } from './types';
import { useFlowNodeSelect } from './use-flow-node-select.hook';

interface INodeProps {
  defaultValue?: TNode;
  title: ReactNode;
  nodeType: NODE_TYPE;
  editMode?: boolean;
}

export const FlowNodeSelect = ({ title, nodeType, defaultValue = undefined, editMode = false }: INodeProps) => {
  const { assets, onAssetFilterChange } = useFlowNodeSelect();

  if (defaultValue?.nodeType === 'TEE') {
    return <TeeNode title={title} defaultValue={defaultValue} />;
  }

  return (
    <Node
      title={title}
      nodeType={nodeType}
      assets={assets}
      onAssetFilterChange={onAssetFilterChange}
      defaultValue={defaultValue}
      editMode={editMode}
    />
  );
};
