import { RULE_CATEGORY, TNodeAlert } from '@marlin/system-map/data-access/system-map';
import { useMemo } from 'react';

import { getAlertsByRuleCategory, mapAlertsToStatus } from '../utils/alerts';

interface IUseRuleCategoryStatusHook {
  alerts: TNodeAlert[];
  ruleCategory: RULE_CATEGORY;
}

export const useRuleCategoryStatusHook = ({ alerts, ruleCategory }: IUseRuleCategoryStatusHook) => {
  const ruleCategoryAlerts = useMemo(() => getAlertsByRuleCategory(alerts, ruleCategory), [alerts, ruleCategory]);
  const ruleCategoryStatus = useMemo(() => mapAlertsToStatus(ruleCategoryAlerts), [ruleCategoryAlerts]);

  return {
    ruleCategoryStatus,
    ruleCategoryAlerts,
  };
};
