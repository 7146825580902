import { IPublicClientApplication } from '@azure/msal-browser';

import { TIIdentityClaims } from '../models/auth.model';
import { TAccountInfo } from '../models/facade.model';

export class AccountService<T extends TIIdentityClaims = TIIdentityClaims> {
  public constructor(private readonly authService: IPublicClientApplication) {}

  public getIdentityClaims = (): T | null => {
    const currentAccount = this.getCurrentAccount();

    if (!currentAccount) {
      return null;
    }

    return currentAccount.idTokenClaims;
  };

  public getCurrentAccount(): TAccountInfo<T> | null {
    const accounts = this.authService.getAllAccounts();
    let currentAccount = this.authService.getActiveAccount() as TAccountInfo<T> | null;

    if (!accounts.length) {
      return null;
    }

    if (currentAccount) {
      return currentAccount;
    }

    currentAccount = accounts[0] as TAccountInfo<T>;

    return currentAccount ?? null;
  }
}
