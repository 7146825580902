import { ModelEnum } from '@marlin/shared/utils/zod';
import { z } from 'zod';

import { LocationTypes } from '../../location.model';
import { emptyTextSchema, paginationSchema } from './utils.schema';

export const locationResponseSchema = z.object({
  id: z.string(),
  name: z.string(),
  parentLocationId: emptyTextSchema,
  parentLocationName: emptyTextSchema,
  deviceCount: z.number(),
  equipmentCount: z.number(),
  isParent: z.boolean(),
  locationType: LocationTypes,
  devices: z.array(
    z.object({
      manufacturerId: z.string(),
      model: ModelEnum.or(z.string()),
      name: z.string(),
      type: z.string(),
    })
  ),
});

export const getLocationResponseSchema = z.object({
  pagination: paginationSchema,
  data: z.array(locationResponseSchema).nullable(),
});

export type TLocationResponseSchema = z.infer<typeof getLocationResponseSchema>;
export type TLocationSchema = z.infer<typeof locationResponseSchema>;
