import { MarlinTheme } from '@marlin/shared/theme';
import PendingActionsRoundedIcon from '@mui/icons-material/PendingActionsRounded';
import { ForwardedRef, forwardRef, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';
import { ALERT_STATUS } from './types';

interface IHotSosContent {
  status?: ALERT_STATUS | null;
}

interface IStyles {
  isError: boolean;
}

const useStyles = makeStyles<IStyles>()((theme: MarlinTheme, { isError }) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    width: 'fit-content',
    marginTop: theme.typography.pxToRem(5),
  },

  icon: {
    height: theme.typography.pxToRem(16),
    '& > svg': {
      color: isError ? theme.palette.error.main : theme.palette.action.active,
      height: '100%',
    },
  },

  label: {
    fontSize: theme.typography.pxToRem(14),
    marginRight: theme.typography.pxToRem(3),
    color: isError ? theme.palette.error.main : theme.palette.text.primary,
  },
}));
export const HotSosContent = forwardRef((props: IHotSosContent, ref: ForwardedRef<HTMLDivElement>) => {
  const isError = useMemo(() => props.status === ALERT_STATUS.FAILED, [props.status]);
  const {
    classes: { wrapper, icon, label },
  } = useStyles({ isError });

  return (
    <div {...props} className={wrapper} ref={ref}>
      <span className={icon}>
        <PendingActionsRoundedIcon />
      </span>
      <span className={label}>{content.HOT_SOS}</span>
    </div>
  );
});
