export const content = {
  TITLE: 'Add Flow Link',
  EDIT_TITLE: 'Edit Attachment',

  INLET: 'Attached to',
  OUTLET: 'Attachment',

  ASSET_TYPE_LABEL: 'Asset type',
  ASSET_TYPE_PLACEHOLDER: 'No filter',

  ASSET_LABEL: 'Choose Asset',

  ASSET_LOCATION: 'Location',
  ASSET_EQUIPMENT: 'Equipment',
  ASSET_SENSOR: 'Sensor',

  GROUP_LOCATION: 'Locations',
  GROUP_EQUIPMENT: 'Equipments',
  GROUP_SENSOR: 'Sensors',

  BTN_CANCEL: 'Cancel',
  BTN_ADD: 'Add',
  BTN_EDIT: 'Update',

  VALIDATION_INLET_REQUIRED: 'Inlet asset is missing',
  VALIDATION_OUTLET_REQUIRED: 'Outlet asset is missing',
  VALIDATION_LOOP:
    'Cannot link an asset to itself. Create another sensor and link it between the asset to create a loop.',

  NO_RESULTS: 'No results found',
  VALIDATION_LOCATION_ATTACHMENT: 'Location may not be attachments.',
  VALIDATION_EQUIPMENT_ATTACHMENT: 'Equipment may not be attachments.',
  VALIDATION_SENSOR_ATTACHMENT: 'Sensors may not be attached to.',
  VALIDATION_INLET_OUTLET_REQUIRED: 'Inlet and outlet are required',
  VALIDATION_EQUIPMENT_MODEL_REQUIRED: 'Equipment model is required',
  VALIDATION_EQUIPMENT_HANDLE_REQUIRED: 'Equipment handle is required',
};
