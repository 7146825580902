import { TLocationHierarchyLevel } from '@marlin/asset/data-access/location';
import { MarlinTheme } from '@marlin/shared/theme';
import { TAB_VALUES, routes } from '@marlin/shared/utils-routes';
import { TEquipment } from '@marlin/shared/utils/zod';
import TreeItem from '@mui/lab/TreeItem';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { EquipmentHierarchyLevel } from './equipment-hierarchy-level.component';
import { HierarchyLevelLabel } from './hierarchy-level-label.component';

interface IHierarchyLevelProps {
  hierarchyLevel: TLocationHierarchyLevel;
  equipment?: TEquipment;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  treeItem: {
    paddingTop: theme.typography.pxToRem(8),
    paddingBottom: theme.typography.pxToRem(8),
  },
}));

export const RecursiveHierarchyLevel = ({ hierarchyLevel, equipment }: IHierarchyLevelProps) => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  return (
    <TreeItem
      classes={{ content: classes.treeItem }}
      nodeId={hierarchyLevel.id}
      label={<HierarchyLevelLabel hierarchyLevel={hierarchyLevel} />}
      onClick={() => navigate(routes.locations.details.url(hierarchyLevel.id, TAB_VALUES.HierarchyView))}
    >
      {hierarchyLevel.children.length > 0 &&
        hierarchyLevel.children.map((childHierarchyLevel) => {
          return (
            <RecursiveHierarchyLevel
              hierarchyLevel={childHierarchyLevel}
              key={childHierarchyLevel.id}
              equipment={equipment}
            />
          );
        })}
      {equipment && equipment.locationId === hierarchyLevel.id && <EquipmentHierarchyLevel equipment={equipment} />}
    </TreeItem>
  );
};
