import { useSubmitOnChange } from '@marlin/shared/ui-form-common';
import { useRouter } from '@marlin/shared/utils-router';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { IFilterParams } from '../types';
import { IAutomationHubFilters } from './types';

interface IAutomationFiltersProps {
  handleFiltersChange: (params: IFilterParams) => void;
}

interface IRouterState {
  devices?: IAutomationHubFilters['devices'];
}

export const useAutomationFilterForm = ({ handleFiltersChange }: IAutomationFiltersProps) => {
  const router = useRouter<IRouterState>();
  const currentRouterState = useMemo(() => router.getState(), [router]);

  const form = useForm<IAutomationHubFilters>({
    defaultValues: {
      locations: [],
      equipments: [],
      devices: currentRouterState?.devices || [],
      isEnabled: null,
      search: '',
    },
  });

  const onFiltersChange = (data: IAutomationHubFilters) => {
    const locationIds = data.locations?.map((location) => {
      return location.id;
    });

    const equipmentIds = data.equipments?.map((equipment) => {
      return equipment.id;
    });

    const devicesIds = data.devices?.map((device) => {
      return device.id;
    });

    handleFiltersChange({
      locationId: locationIds || [],
      equipmentId: equipmentIds || [],
      deviceId: devicesIds || [],
      isEnabled: data.isEnabled,
      term: data.search,
    });
  };

  const { submit, resetForm } = useSubmitOnChange<IAutomationHubFilters>(form, onFiltersChange);

  return {
    form,
    submit,
    resetForm,
  };
};
