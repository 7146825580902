import { Button } from '@mui/material';
import { DateCalendar, DateField, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Moment } from 'moment';
import { useCallback, useMemo, useState } from 'react';

import { useStyles } from './date-range-picker.styles';
import { validateDateRange } from './validate-date-range';

export interface IDateRangeModalReturnValue {
  fromDate: Moment | null;
  toDate: Moment | null;
  open: boolean;
}

interface IContent {
  TITLE: string;
  FROM: string;
  TO: string;
  SET_PERIOD: string;
  CANCEL: string;
}

interface IDateRangePicker {
  content: IContent;
  onSetPeriod: (value: Partial<IDateRangeModalReturnValue>) => void;
  onClose?: () => void;
  toDate?: Moment | null;
  fromDate?: Moment | null;
  testIdPrefix?: string;
  isValid?: boolean;
}

export const DateRangePicker = ({
  onSetPeriod,
  onClose,
  content,
  toDate,
  fromDate,
  testIdPrefix,
}: IDateRangePicker) => {
  const { classes } = useStyles();
  const [currentDateFrom, setCurrentDateFrom] = useState<Moment | null | undefined>(fromDate);
  const [currentDateTo, setCurrentDateTo] = useState<Moment | null | undefined>(toDate);

  const testId = testIdPrefix ? `${testIdPrefix}-` : '';

  const handleSave = useCallback(() => {
    onSetPeriod({
      open: false,
      fromDate: currentDateFrom,
      toDate: currentDateTo,
    });
  }, [currentDateFrom, currentDateTo, onSetPeriod]);

  const handleClose = useCallback(() => {
    onClose && onClose();
  }, [onClose]);

  const disableSaveButton = useMemo(() => {
    const isValid = validateDateRange(currentDateFrom, currentDateTo);
    return !isValid;
  }, [currentDateFrom, currentDateTo]);

  return (
    <div className={classes.container} data-testid={`${testId}header-section-duration-modal-body-container`}>
      <div className={classes.title}>{content.TITLE}</div>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <div className={classes.dateWrapper} data-testid={`${testId}header-section-duration-modal-body-inputs`}>
          <DateField
            className={classes.input}
            label={content.FROM}
            value={currentDateFrom}
            maxDate={currentDateTo ?? undefined}
            onChange={setCurrentDateFrom}
          />
          <DateField
            className={classes.input}
            label={content.TO}
            value={currentDateTo}
            minDate={currentDateFrom ?? undefined}
            onChange={setCurrentDateTo}
          />
        </div>
        <div className={classes.dateWrapper} data-testid={`${testId}header-section-duration-modal-body-calendards`}>
          <DateCalendar
            className={classes.calendar}
            maxDate={currentDateTo ?? undefined}
            disableFuture
            value={currentDateFrom}
            onChange={setCurrentDateFrom}
          />
          <DateCalendar
            className={classes.calendar}
            minDate={currentDateFrom ?? undefined}
            disableFuture
            value={currentDateTo}
            onChange={setCurrentDateTo}
          />
        </div>
      </LocalizationProvider>
      <div className={classes.footer}>
        <Button onClick={handleClose} data-testid={`${testId}modal-footer-button-close`}>
          {content.CANCEL}
        </Button>
        <Button
          onClick={handleSave}
          disabled={disableSaveButton}
          variant="contained"
          data-testid={`${testId}modal-footer-button-save`}
        >
          {content.SET_PERIOD}
        </Button>
      </div>
    </div>
  );
};
