import { DevicesSearchWrapper } from '@marlin/asset/data-access/device';
import { MarlinTheme } from '@marlin/shared/theme';
import { Input } from '@marlin/shared/ui-form-common';
import { Autocomplete, FormHelperText, MenuItem, Paper, PaperProps, Typography } from '@mui/material';
import { AutocompleteRenderGetTagProps } from '@mui/material/Autocomplete/Autocomplete';
import { SyntheticEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';
import { FlowMeterTag } from './flow-meter-tag.component';
import { IFlowMeter, IFlowMeterFormValues } from './types';

export interface IModalBodyProps {
  selectedFlowMeters?: IFlowMeter[];
  submitForm: (values: IFlowMeterFormValues) => Promise<unknown>;
  onChane: (event: SyntheticEvent, value: IFlowMeter[]) => void;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  select: {
    flex: '70%',
  },
  subtitleContainer: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  subtitle: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 400,
    marginBottom: theme.typography.pxToRem(24),
  },
  input: {
    '&> .MuiOutlinedInput-root.MuiAutocomplete-inputRoot': {
      // TODO: find different way to overwrite autocomplete input
      paddingRight: `${theme.typography.pxToRem(10)} !important`,
    },
  },
}));

export function ModalBody({ selectedFlowMeters, submitForm, onChane }: IModalBodyProps): JSX.Element | null {
  const { handleSubmit } = useFormContext<IFlowMeterFormValues>();
  const { classes } = useStyles();

  return (
    <form onSubmit={handleSubmit(submitForm)} data-testid="choose-flow-meter-modal-form">
      <div className={classes.subtitleContainer}>
        <Typography className={classes.subtitle}>{content.CHANGING_FLOW_METER_SUBTITLE}</Typography>
      </div>
      <DevicesSearchWrapper params={{ deviceTypes: ['PULSE_METER'], meterTypes: ['Water'] }}>
        {(props) => {
          return (
            <Autocomplete
              multiple
              className={classes.select}
              value={selectedFlowMeters}
              isOptionEqualToValue={(option: IFlowMeter, value: IFlowMeter) => option.id === value.id}
              id="select-water-device"
              options={props.options as IFlowMeter[]}
              disabled={false}
              getOptionLabel={(option: IFlowMeter) => `${option.name} ${option.locationName}`}
              renderOption={(props, option: IFlowMeter) => (
                <MenuItem key={option.id} {...props}>
                  <div>
                    <span>{option.name}</span>
                    <FormHelperText>{option.locationName}</FormHelperText>
                  </div>
                </MenuItem>
              )}
              renderTags={(flowMeters: IFlowMeter[], getTagProps: AutocompleteRenderGetTagProps) =>
                flowMeters.map((flowMeter, index) => (
                  <FlowMeterTag flowMeter={flowMeter} {...getTagProps({ index })} key={index} />
                ))
              }
              limitTags={2}
              onChange={onChane}
              filterSelectedOptions
              renderInput={(params) => (
                <Input className={classes.input} label={content.SELECTED_FLOW_METERS} {...params} />
              )}
              PaperComponent={(props: PaperProps) => <Paper {...props} />}
            />
          );
        }}
      </DevicesSearchWrapper>
    </form>
  );
}
