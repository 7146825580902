import { MarlinTheme } from '@marlin/shared/theme';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { Paper, SectionHeader } from '@marlin/shared/ui-page';
import { Button } from '@mui/material';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';
import { useWebhookList } from './use-webhook-list.hook';
import { WebhookTile } from './webhook-tile.component';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    maxWidth: theme.typography.pxToRem(746),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(16),
  },
}));

export const WebhookList = () => {
  const { classes } = useStyles();
  const { webhooks, isLoading, isRefetching, onEditWebhook, onDeleteWebhook, goToCreateNewWebhook } = useWebhookList();

  const disabledButton = useMemo(() => {
    if (isLoading) {
      return true;
    }
    return !!webhooks.length && webhooks.length >= 3;
  }, [webhooks, isLoading]);

  return (
    <Paper testId="webhook-list" className={classes.wrapper}>
      <SectionHeader
        testId="webhook-list-header"
        title={content.WEBHOOKS_TITLE}
        options={
          <Button
            variant="contained"
            onClick={goToCreateNewWebhook}
            disabled={disabledButton}
            data-testid="webhook-create-button"
          >
            {content.CREATE_NEW_BUTTON}
          </Button>
        }
      />
      {isLoading || isRefetching ? (
        <LoadingSpinner />
      ) : (
        webhooks.map((webhook) => (
          <WebhookTile webhook={webhook} key={webhook.id} onEdit={onEditWebhook} onDelete={onDeleteWebhook} />
        ))
      )}
    </Paper>
  );
};
