import { ReactComponent as Analytics } from './analytics.svg';
import { ReactComponent as AutomationLogo } from './automations.svg';
import { ReactComponent as SystemMapLogo } from './system-map.svg';

export const images = [
  {
    label: 'Upgrade to Nexa System to unveil the power of our System Map insights.',
    Img: SystemMapLogo,
  },
  {
    label:
      'Gain insight into the performance of your system with sensors to measure temperature, pressure, leak, and flow.',
    Img: Analytics,
  },
  {
    label:
      'Create custom alerts for any sensor or telemetry to understand critical operations, diagnosis, or record keeping.',
    Img: AutomationLogo,
  },
];
export const maxSteps = images.length;
