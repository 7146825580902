import { TDeviceTypeSchema } from '@marlin/asset/data-access/device';
import { TDateString } from '@marlin/shared/utils-common-date';
import { TUnitOfMeasure, parseDisplayedValue } from '@marlin/shared/utils-format-reading';
import { getChartAlertTitle } from '@marlin/shared/utils/format-alert';

import { TAlertBuckets } from '../../../../types';

interface ITimestampIndex {
  series: number;
  datapoint: number;
}

export const getAlertId = (seriesName: string) => seriesName.split('alert-')[1];

export const getIndexesAtTimestamp = (seriesX: TDateString[][], seriesIndex: number, dataPointIndex: number) =>
  seriesX.reduce((agg, el, ind) => {
    const elInd = el.indexOf(seriesX[seriesIndex][dataPointIndex]);
    if (elInd >= 0) {
      agg.push({ series: ind, datapoint: elInd });
    }
    return agg;
  }, [] as ITimestampIndex[]);

export const getDataSeries = (
  series: number[][],
  seriesIndex: number,
  dataPointIndex: number,
  deviceType: TDeviceTypeSchema,
  seriesX: TDateString[][],
  seriesNames: string[],
  indexAtTimestamp: ITimestampIndex[],
  uoM?: TUnitOfMeasure | null
) => {
  const dataSeriesIndex = indexAtTimestamp.find((el) => seriesNames[el.series].startsWith('data'));
  return dataSeriesIndex
    ? {
        timestamp: seriesX[seriesIndex][dataPointIndex],
        value: parseDisplayedValue(
          series[dataSeriesIndex?.series][dataSeriesIndex?.datapoint].toString(),
          deviceType === 'LEAK' ? 'WaterDetect' : uoM ?? '',
          undefined,
          'detail'
        ),
      }
    : null;
};

export const getAlertSeries = (
  indexAtTimestamp: ITimestampIndex[],
  deviceType: TDeviceTypeSchema,
  seriesX: TDateString[][],
  seriesNames: string[],
  groupedAlerts: TAlertBuckets
) => {
  return indexAtTimestamp
    .filter((el) => seriesNames[el.series].startsWith('alert'))
    .map((el) => {
      const alertId = getAlertId(seriesNames[el.series]);
      return {
        timestamp: seriesX[el.series][el.datapoint],
        value: getChartAlertTitle(groupedAlerts[alertId], deviceType),
        alert: groupedAlerts[alertId],
      };
    });
};
