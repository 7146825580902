import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import { createWebhookResponseSchema } from '../schemas/create-webhook.schema';
import { TUpdateWebhookParams, updateWebhookSchema } from '../schemas/update-webhook.schema';
import { paths } from './api';

export const updateWebhook = async (data: TUpdateWebhookParams) => {
  const { webhookId, ...request } = safeParseData(data, updateWebhookSchema);
  const response = await getHttpClient().put(paths.WEBHOOK_ID, request, { params: { webhookId } });

  return safeParseData(response, createWebhookResponseSchema);
};
