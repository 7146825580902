import { LoadingSpinner } from '@marlin/shared/ui-loader';
import ApexChart from 'react-apexcharts';
import Plot from 'react-plotly.js';

import { IChartSettings } from './types';
import { TTelemetryType, useAnalyticsData } from './use-data';

interface IChartProps {
  type: TTelemetryType;
  chartSettings: IChartSettings;
  libraryType?: 'plotly' | 'apex';
}

export const Chart = ({ type, chartSettings, libraryType }: IChartProps): JSX.Element => {
  const { data, isInitialLoading, isFetching, apexData } = useAnalyticsData(type, chartSettings);

  if (isInitialLoading) {
    return <LoadingSpinner />;
  }

  const ticks =
    type === 'leak'
      ? {
          tickmode: 'array' as const,
          tickvals: [0, 1],
          ticktext: ['No Leak', 'Leak'],
          range: [-0.1, 1.1],
          zeroline: false,
        }
      : {};

  if (libraryType === 'apex') {
    return (
      <>
        {isFetching && (
          <div style={{ display: 'flex', alignItems: 'center', gap: 16, marginTop: 16 }}>
            <LoadingSpinner /> Please wait, chart data is fetching...
          </div>
        )}
        <ApexChart
          series={apexData}
          options={{
            xaxis: {
              min: chartSettings.minDate?.valueOf(),
              max: chartSettings.maxDate?.valueOf(),
              type: 'datetime',
            },
          }}
          data-testid="collapsed-chart"
          height="400"
        />
      </>
    );
  }

  return (
    <>
      {isFetching && (
        <div style={{ display: 'flex', alignItems: 'center', gap: 16, marginTop: 16 }}>
          <LoadingSpinner /> Please wait, chart data is fetching...
        </div>
      )}
      <Plot
        data={data}
        layout={{
          barmode: 'stack',
          hovermode: 'x unified',
          xaxis: {
            range: [chartSettings.minDate?.valueOf(), chartSettings.maxDate?.valueOf()],
            title: `${chartSettings.minDate?.format()} - ${chartSettings.maxDate?.format()}`,
          },
          yaxis: { fixedrange: true, ...ticks },
          title: `${type} chart`,
        }}
        config={{ displaylogo: false }}
      />
    </>
  );
};
