import { TCreateAutomation } from '@marlin/alert/data-access/automated-action';
import { MarlinTheme } from '@marlin/shared/theme';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  error: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.error.main,
    display: 'flex',
    alignItems: 'center',
  },

  errorText: {
    marginLeft: theme.typography.pxToRem(10),
  },
}));

export function ConditionValidationError(): JSX.Element | null {
  const { classes } = useStyles();

  const { formState } = useFormContext<TCreateAutomation>();
  const errorMessage = formState.errors['conditions']?.message;

  if (!errorMessage) {
    return null;
  }

  return (
    <div className={classes.error}>
      <WarningRoundedIcon />
      <span className={classes.errorText}>{errorMessage}</span>
    </div>
  );
}
