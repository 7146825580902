import { ISection } from '@marlin/asset/shared/equipment-config';

import { content } from '../../../content';

export const boilerSectionConfig: ISection[] = [
  {
    title: content.SYSTEM_STATUS,
    style: { fullWidth: true },
    sectionDatapoints: [
      {
        sectionName: 'temperatureSensors',
        title: content.EQUIPMENT_READINGS_AERCO.TEMPERATURES,
        items: [
          { name: 'InletTemp', label: content.DATAPOINT_LABELS_AERCO.UPPER_INLET },
          { name: 'Inlet2Temp', label: content.DATAPOINT_LABELS_AERCO.LOWER_INLET },
          { name: 'ExhaustTemp', label: content.DATAPOINT_LABELS_AERCO.EXHAUST_TEMP },
        ],
      },
      {
        title: content.EQUIPMENT_READINGS_AERCO.BOILER_PERFORMANCE,
        sectionName: 'boilerPerformance',
        items: [
          { name: 'OxygenLevel', label: content.DATAPOINT_LABELS_AERCO.OXYGEN_LEVEL },
          { name: 'FlameStrength', label: content.DATAPOINT_LABELS_AERCO.FLAME_STRENGTH },
          { name: 'BlowerOutput', label: content.DATAPOINT_LABELS_AERCO.BLOWER_VOLTAGE },
        ],
      },
    ],
    dataTestId: 'system-status-section',
  },
  {
    sectionDatapoints: [
      {
        sectionName: 'boilerMetrics',
        title: content.EQUIPMENT_READINGS_AERCO.BOILER_METRICS,
        items: [
          { name: 'RunHours', label: content.DATAPOINT_LABELS_AERCO.RUN_HOURS },
          { name: 'RunCycles', label: content.DATAPOINT_LABELS_AERCO.CYCLE_COUNT },
        ],
      },
      {
        sectionName: 'boilerInfo',
        title: content.EQUIPMENT_READINGS_AERCO.ADDITIONAL_BOILER_INFO,
        items: [{ name: 'StartLevel', label: content.DATAPOINT_LABELS_AERCO.START_LEVEL }],
      },
    ],
    title: content.EQUIPMENT_METRICS,
    style: { fullWidth: true },
    dataTestId: 'equipment-metrics-section',
  },
];
