import { MarlinTheme } from '@marlin/shared/theme';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../content';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  currentlySet: {
    padding: theme.typography.pxToRem(12),
    backgroundColor: theme.palette.background.alternative,
    display: 'flex',
    alignItems: 'center',
    height: theme.typography.pxToRem(50),
  },
  typographyCurrentlySetValue: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    marginLeft: theme.typography.pxToRem(4),
  },
  title: {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.typography.pxToRem(10),
    },
  },
}));

export const EditHeader = ({ title, displayedValue }: { title: string; displayedValue?: string }) => {
  const { classes } = useStyles();
  return (
    <>
      <Typography variant="body1" className={classes.title} data-testid="setting-list-item-title">
        {title}
      </Typography>
      {displayedValue && (
        <div className={classes.currentlySet}>
          <Typography variant="overline" data-testid="currently-set-title">
            {content.CURRENTLY_SET}
          </Typography>

          <Typography variant="body1" className={classes.typographyCurrentlySetValue} data-testid="currently-set-value">
            {displayedValue}
          </Typography>
        </div>
      )}
    </>
  );
};
