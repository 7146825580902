import { ConfigService } from './config.service';
import { EndpointFacade } from './endpoint.facade';
import { EndpointInterceptor } from './endpoint.interceptor';
import { IEndpointConfig } from './endpoint.models';
import { EndpointService } from './endpoint.service';

const defaultConfig: IEndpointConfig = {
  proxyConfig: undefined,
  baseHref: '',
  baseDomain: '',
};

let endpointModule: IEndpointModule;

interface IEndpointModuleForRootConfig {
  config?: IEndpointConfig;
}

interface IEndpointModule {
  injector: IInjector;
}

interface IInjector {
  get<
    U extends TType,
    T extends typeof ConfigService | typeof EndpointService | typeof EndpointFacade | typeof EndpointInterceptor
  >(
    dependency: T
  ): U | undefined;
}

type TType = ConfigService | EndpointService | EndpointFacade | EndpointInterceptor;
type TEndpointInjector<T extends TType> = T;
type TEndpointInjectorType<T extends TType> = T extends ConfigService
  ? typeof ConfigService
  : T extends EndpointService
  ? typeof EndpointService
  : T extends EndpointFacade
  ? typeof EndpointFacade
  : EndpointInterceptor;

interface IEndpointInjector<T extends TType> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  provide: any;
  useValue: TEndpointInjector<T>;
}

class Injector implements IInjector {
  private readonly dependencies: Map<TEndpointInjectorType<TType>, TEndpointInjector<TType>> = new Map();

  public constructor(dependencies: IEndpointInjector<TType>[]) {
    dependencies.forEach((dependency) => {
      if (!this.dependencies.has(dependency.provide)) {
        this.dependencies.set(dependency.provide, dependency.useValue);
      }
    });
  }

  public get<
    U extends TType,
    T extends typeof ConfigService | typeof EndpointService | typeof EndpointFacade | typeof EndpointInterceptor
  >(dependency: T): U | undefined {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.dependencies.get(dependency as any) as U;
  }
}

export class EndpointModule implements IEndpointModule {
  public static forRoot({ config = defaultConfig }: IEndpointModuleForRootConfig): IEndpointModule {
    const configService = new ConfigService(config);
    const endpointService = new EndpointService(configService);
    const endpointFacade = new EndpointFacade(endpointService, configService);
    const dependencies = [
      {
        provide: ConfigService,
        useValue: configService,
      },
      {
        provide: EndpointService,
        useValue: endpointService,
      },
      {
        provide: EndpointFacade,
        useValue: endpointFacade,
      },
      {
        provide: EndpointInterceptor,
        useValue: new EndpointInterceptor(endpointService, configService),
      },
    ];
    const injector = new Injector(dependencies);

    if (endpointModule) {
      // eslint-disable-next-line no-console
      console.warn('[WARN] EndpointModule already instantiated');
      return endpointModule;
    }

    endpointModule = new EndpointModule(injector);

    return endpointModule;
  }

  public constructor(public injector: IInjector) {}
}
