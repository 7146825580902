import { MarlinTheme } from '@marlin/shared/theme';
import { FieldArrayControl } from '@marlin/shared/ui-form-common';
import { TInvitationFormValues } from '@marlin/shared/utils/zod';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { FormProvider } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../../content';
import { Buttons } from '../../../buttons/buttons.component';
import { InvitationControlField } from './invitation-control-field.component';
import { useInviteUsersForm } from './use-invitation-form.hook';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  usersControlWrapper: {
    marginBottom: theme.typography.pxToRem(32),
  },
  captionWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
}));

export interface IInvitationFormProps {
  handleCancel: () => void;
  handleSuccess: () => void;
  handleError: () => void;
}

export const InvitationForm = ({ handleCancel, handleSuccess, handleError }: IInvitationFormProps) => {
  const { classes } = useStyles();

  const { form, validEmailFieldsCount, onSubmit, isSubmitting } = useInviteUsersForm({ handleSuccess, handleError });

  const triggerOnlyTouchedEmails = useCallback(() => {
    const fields = form.formState.touchedFields;
    const touchedUsers = fields?.users || [];

    for (const key in touchedUsers) {
      if (touchedUsers[key]?.email) {
        form.trigger(`users.${key as unknown as number}.email`);
      }
    }
  }, [form]);

  const usersCount = form.watch('users')?.length;

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit}>
        <div className={classes.usersControlWrapper}>
          <FieldArrayControl<TInvitationFormValues>
            fieldName="users"
            defaultValue={{ email: '', role: 'Viewer' }}
            renderControl={({ fieldName, control, index }) => (
              <InvitationControlField
                control={control}
                fieldName={fieldName}
                index={index}
                handleOnEmailBlur={triggerOnlyTouchedEmails}
              />
            )}
            addButtonProps={{
              children: content.ADD_ANOTHER_USER,
              startIcon: <PersonAddAlt1Icon />,
              variant: 'outlined',
              disabled: usersCount > 3,
            }}
            handleDeleteItem={triggerOnlyTouchedEmails}
          />
        </div>
        <div className={classes.captionWrapper}>
          <Typography variant="caption" color="text.disabled" align="center">
            {content.INVITE_USER_SUPPORT}
          </Typography>
        </div>
        <Buttons
          onNext={onSubmit}
          onCancel={handleCancel}
          cancelLabel={content.I_LL_DO_THIS_LATER}
          nextLabel={content.SEND_INVITE(validEmailFieldsCount)}
          isDisabled={!form.formState.isDirty || !form.formState.isValid || isSubmitting}
          data-testid="add-another-user-button"
        />
      </form>
    </FormProvider>
  );
};
