import { ALERT_STATUS_FILTER } from '@marlin/alert/data-access/alert-action';
import { MarlinTheme } from '@marlin/shared/theme';
import { CRITICALITY, CriticalityButton } from '@marlin/shared/ui-criticality';
import { Search, Select } from '@marlin/shared/ui-form-common';
import { SORT_DIRECTION } from '@marlin/shared/utils-sorting';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Chip, SelectChangeEvent } from '@mui/material';
import { Button, IconButton, alpha } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../../content';
import { IUseFiltering } from '../../../../hooks/event-log/use-filtering.hook';
import { IFilterParams } from '../event-log.component';
import { FilterChips } from './filter-chips.component';

interface IMobileFiltersProps extends IUseFiltering {
  params: IFilterParams;
  onChangeParams: React.Dispatch<React.SetStateAction<IFilterParams>>;
}

interface IStyles {
  isOpen: boolean;
}

export const useStyles = makeStyles<IStyles>()((theme: MarlinTheme, { isOpen }) => ({
  searchText: {
    minWidth: theme.typography.pxToRem(248),
    height: theme.typography.pxToRem(40),
    '& input': {
      padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(12)}`,
    },
    '& .MuiFormLabel-root:not(.MuiFormLabel-filled, .Mui-focused)': {
      transform: `translate(${theme.typography.pxToRem(14)}, ${theme.typography.pxToRem(8)}) scale(1)`,
    },
  },
  chips: {
    display: 'flex',
    gap: theme.typography.pxToRem(8),
    padding: isOpen ? 0 : `${theme.typography.pxToRem(12)} ${theme.typography.pxToRem(16)}`,
    width: isOpen ? '100%' : `calc(100% - ${theme.typography.pxToRem(56)})`,
    overflow: 'auto',
    flexWrap: isOpen ? 'wrap' : 'nowrap',
    '&::-webkit-scrollbar': {
      display: 'none',
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
    },
  },
  chipsWrapper: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: isOpen ? 'none' : `1px solid ${theme.palette.divider}`,
  },
  iconButton: {
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderRadius: '0',
    padding: theme.typography.pxToRem(16),
    background: theme.palette.background.primary,
  },
  openedFilters: {
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.typography.pxToRem(16)} ${theme.typography.pxToRem(16)} 0`,
    gap: theme.typography.pxToRem(16),
  },
  container: {
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  hideFilters: {
    alignSelf: 'flex-end',
  },
  chip: {
    borderColor: alpha(theme.palette.primary.main, 0.5),
    color: theme.palette.primary.main,
    background: alpha(theme.palette.primary.main, 0.12),
  },
  clickableChip: {
    background: theme.palette.background.primary,
  },
  sortingChips: {
    display: 'flex',
    gap: theme.typography.pxToRem(4),
  },
  selectInput: {
    '& .MuiInputBase-root': {
      height: theme.typography.pxToRem(40),
    },
  },
  sortingSection: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(8),
  },
}));

const sortableColumns = [
  { id: 'startTime', name: content.START_TIME },
  { id: 'status', name: content.STATUS },
  { id: 'criticality', name: content.CRITICALITY },
  { id: 'ruleCategory', name: content.EVENT_TYPE },
  { id: 'title', name: content.TITLE },
  { id: 'resolveTime', name: content.RESOLVED_TIME },
  { id: 'valueChanged', name: content.VALUE_CHANGED },
  { id: 'newValue', name: content.NEW_VALUE_LABEL },
  { id: 'originalValue', name: content.ORIGINAL_VALUE },
  { id: 'generatedBy', name: content.GENERATED_BY },
];

export const MobileFilters = ({
  criticalityChange,
  statusChange,
  status,
  onChangeSearchValue,
  textSearchValue,
  params,
  onChangeParams,
}: IMobileFiltersProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { classes, cx } = useStyles({ isOpen });
  const filters = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isOpen) {
      const scrollableContent = window.document.getElementById('appContent');

      if (scrollableContent) {
        const top = filters.current?.getBoundingClientRect()?.top;
        const topWithHeader = top ? top - 56 : 0;

        if (topWithHeader > 0) {
          scrollableContent?.scroll(0, topWithHeader + scrollableContent.scrollTop);
        }
      }
    }
  }, [isOpen]);

  const handleSortingDirectionChange = (direction: SORT_DIRECTION) => {
    onChangeParams((prev) => ({ ...prev, sorting: { ...prev.sorting, direction } }));
  };

  const handleSortingFieldChange = (event: SelectChangeEvent<string>) => {
    onChangeParams((prev) => ({ ...prev, sorting: { ...prev.sorting, sortBy: event.target.value } }));
  };

  return (
    <div
      data-testid="event-log-mobile-filters"
      className={cx(classes.container, { [classes.openedFilters]: isOpen })}
      ref={filters}
    >
      {isOpen && (
        <>
          <Button
            variant="text"
            onClick={() => setIsOpen(false)}
            className={classes.hideFilters}
            data-testid="hide-filters-button"
          >
            {content.MOBILE_FILTERS.HIDE_FILTERS}
          </Button>
          <Search
            label={content.FILTERS.LABEL_SEARCH}
            prefix="event-log"
            className={classes.searchText}
            value={textSearchValue}
            onChange={onChangeSearchValue}
          />
        </>
      )}
      <div className={classes.chipsWrapper}>
        <div className={classes.chips}>
          <CriticalityButton
            onClick={criticalityChange}
            severity={CRITICALITY.HIGH}
            selected={params?.criticality?.high ?? false}
          />
          <CriticalityButton
            onClick={criticalityChange}
            severity={CRITICALITY.LOW}
            selected={params?.criticality?.low ?? false}
          />
          <FilterChips
            label={content.NEW}
            isActive={status.new}
            status={ALERT_STATUS_FILTER.CURRENT}
            onClick={statusChange}
          />
          <FilterChips
            label={content.RESOLVED}
            isActive={status.resolved}
            status={ALERT_STATUS_FILTER.RESOLVED}
            onClick={statusChange}
          />
        </div>
        {!isOpen && (
          <IconButton onClick={() => setIsOpen(true)} className={classes.iconButton} data-testid="open-filters-button">
            <FilterAltIcon />
          </IconButton>
        )}
      </div>
      {isOpen && (
        <div className={classes.sortingSection}>
          <Select
            prefix="sort-by"
            value={params.sorting.sortBy}
            label={content.SORT_BY}
            data={sortableColumns}
            className={classes.selectInput}
            onChange={handleSortingFieldChange}
          />
          <div className={classes.sortingChips}>
            <Chip
              data-testid="sorting-chip-descending"
              label={content.DESCENDING}
              clickable={params.sorting.direction !== SORT_DIRECTION.Descending}
              onClick={() => handleSortingDirectionChange(SORT_DIRECTION.Descending)}
              classes={{ clickable: classes.clickableChip, root: classes.chip }}
              variant="outlined"
            />
            <Chip
              data-testid="sorting-chip-acending"
              label={content.ASCENDING}
              clickable={params.sorting.direction !== SORT_DIRECTION.Ascending}
              onClick={() => handleSortingDirectionChange(SORT_DIRECTION.Ascending)}
              classes={{ clickable: classes.clickableChip, root: classes.chip }}
              variant="outlined"
            />
          </div>
        </div>
      )}
    </div>
  );
};
