import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  userName: {
    fontWeight: theme.typography.fontWeightBold,
    wordWrap: 'break-word',
    '&::before': {
      content: '" "',
    },
  },

  cancelButton: {
    marginLeft: theme.typography.pxToRem(8),
  },
}));
