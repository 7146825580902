import { TCreateAutomation } from '@marlin/alert/data-access/automated-action';
import { MarlinTheme } from '@marlin/shared/theme';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import { Button } from '@mui/material';
import isFunction from 'lodash/isFunction';
import { ReactChild } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { TConditionFieldName } from '../paths.model';
import { content } from './content';

const DeadbandEnablerIcon = ({ enabled }: { enabled: boolean }) => {
  if (enabled) {
    return (
      <>
        {content.REMOVE_DEADBAND}
        <RemoveCircleRoundedIcon />
      </>
    );
  }
  return (
    <>
      {content.ADD_DEADBAND}
      <AddCircleRoundedIcon />
    </>
  );
};

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.typography.pxToRem(24),
  },

  btnWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: theme.typography.pxToRem(16),
  },
}));

type TChildrenCallback = (value: boolean) => ReactChild | null;

interface IDeadbandEnabler {
  name: `${TConditionFieldName}.enabled`;
  disabled: boolean;
  children: TChildrenCallback;
}

export const DeadbandEnabler = ({ name, children, disabled }: IDeadbandEnabler) => {
  const { classes } = useStyles();

  const { trigger } = useFormContext<TCreateAutomation>();
  const { field } = useController<TCreateAutomation>({ name });
  const { resetField } = useFormContext<TCreateAutomation>();

  const toggle = () => {
    resetField(name);
    field.onChange({ target: { value: !field.value } });
    return trigger();
  };

  return (
    <div className={classes.container}>
      <Button disabled={disabled} variant="text" className={classes.btnWrapper} onClick={toggle}>
        <DeadbandEnablerIcon enabled={!!field.value} />
      </Button>
      {isFunction(children) ? children(!!field.value) : children}
    </div>
  );
};
