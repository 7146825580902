import { routes } from '@marlin/shared/utils-routes';

export interface IFeatureNavigation {
  getEditGatewayPageLink: (id: string) => string;
  getGatewayConfigurationPageLink: (id: string) => string;
  getGatewayListPageLink: () => string;
}

export const useNavigation = (): IFeatureNavigation => {
  return {
    getEditGatewayPageLink: (gatewayId: string) => routes.gateway.edit.url(gatewayId),
    getGatewayConfigurationPageLink: (gatewayId: string) => routes.gateway.configuration.url(gatewayId),
    getGatewayListPageLink: () => routes.gateway.list.url(),
  };
};
