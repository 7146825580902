import { ALERT_STATUS_FILTER, useFilteredAlertsWithPaging } from '@marlin/alert/data-access/alert-action';
import { useLiveAlerts } from '@marlin/asset/data-access/equipment';
import { TTopOpenAlert } from '@marlin/shared/ui/top-open-alerts';
import { DEVICE_TYPE, DEVICE_TYPE_RESPONSE, ERROR_TYPES } from '@marlin/shared/utils/format-alert';
import { TEquipment } from '@marlin/shared/utils/zod';
import { useEffect, useState } from 'react';

interface IPlantAlerts {
  equipmentIds: string[];
  equipmentList: TEquipment[];
}

const maxAlertCount = 5;
export const usePlantAlerts = ({ equipmentIds, equipmentList }: IPlantAlerts) => {
  const [liveAlertsPlant, setLiveAlertsPlant] = useState<TTopOpenAlert[]>([]);

  const { data: staticAlerts, isLoading } = useFilteredAlertsWithPaging({
    params: {
      pageSize: 5,
      equipmentIds: [...(equipmentIds ?? '')],
      deviceIds: [],
      locationIds: [],
      page: 1,
      deviceTypes: [],
      status: ALERT_STATUS_FILTER.CURRENT,
    },
  });
  const { currentAlerts: liveAlerts, deviceId } = useLiveAlerts();

  const alerts = (staticAlerts?.data || []).map<TTopOpenAlert>((alert) => ({
    deviceEventCode: alert.deviceEventCode || '',
    startTime: alert.startTime || '',
    title: alert.title || '',
    deviceId: alert.device.id,
    deviceName: alert.device.name,
    alertCause: undefined,
    deviceType: DEVICE_TYPE_RESPONSE.EQUIPMENT,
    criticality: alert.criticality,
    uoM: alert.uoM,
  }));

  useEffect(() => {
    const device = equipmentList?.find((equipment) => equipment.devices?.[0]?.deviceId === deviceId);

    if (!device) return;

    setLiveAlertsPlant((prev) => {
      const prevWithoutCurrentDevice = prev.filter(({ deviceId }) => deviceId !== device.id);
      const currentLiveAlerts = liveAlerts
        .slice(0, maxAlertCount)
        .map(({ criticality, deviceEventCode, startTime, title, uoM }) => ({
          criticality,
          startTime: startTime ?? '',
          deviceEventCode,
          title,
          deviceId: device.id,
          deviceName: device.name,
          deviceType: DEVICE_TYPE_RESPONSE.EQUIPMENT,
          uoM,
        }));

      return [...prevWithoutCurrentDevice, ...currentLiveAlerts].sort(
        ({ startTime }, { startTime: startTimeB }) => new Date(startTimeB).getTime() - new Date(startTime).getTime()
      );
    });
  }, [liveAlerts, deviceId, equipmentList]);

  const topAlerts = (liveAlerts ? liveAlertsPlant.slice(0, maxAlertCount) : alerts).map((alert) => ({
    alert,
    alertErrorType: ERROR_TYPES.DEVICE_ALERT,
    alertDeviceType: DEVICE_TYPE.EQUIPMENT,
  }));

  return { topAlerts, isLoading };
};
