import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import { GraphSchema, TGraph, TGraphResponse } from '../flow-map.model.schema';
import { TUpdateSystemMapLinkData, updateSystemMapLinkDataSchema } from '../system-map.model.schema';
import { paths } from './api';

export interface IUpdateSystemMapLinkData {
  linkId: string;
  data: TUpdateSystemMapLinkData;
}

export const updateSystemMapLinkData = async (params: IUpdateSystemMapLinkData): Promise<TGraph> => {
  const body = safeParseData(params.data, updateSystemMapLinkDataSchema);

  const res = await getHttpClient().patch<unknown, TGraphResponse>(paths.SYSTEM_MAP_LINK_DETAILS, body, {
    params: { flowLinkId: params.linkId },
  });
  const parsedRes = safeParseData(res, GraphSchema);

  return parsedRes;
};
