import { MarlinTheme } from '@marlin/shared/theme';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  icon: {
    '& > svg': {
      color: theme.palette.error.main,
    },
    marginRight: theme.typography.pxToRem(20),
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
    },
  },
}));

export const WarningIcon = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.icon}>
      <WarningRoundedIcon />
    </div>
  );
};
