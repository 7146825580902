import { XYPosition } from '@xyflow/react';

import { TControlPointData } from './types';

export const sameVectors = (a: XYPosition, b: XYPosition) =>
  Math.round(a.x) === Math.round(b.x) && Math.round(a.y) === Math.round(b.y);

export const getShiftVector = (referencePoint: XYPosition, targetPoint: XYPosition): XYPosition => {
  return {
    x: targetPoint.x - referencePoint.x,
    y: targetPoint.y - referencePoint.y,
  };
};

export const shiftPointsByVector = (points: TControlPointData[], vector: XYPosition): TControlPointData[] => {
  return points.map((p) => ({
    ...p,
    x: p.x + vector.x,
    y: p.y + vector.y,
  }));
};
