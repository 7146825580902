import { TEquipment } from '@marlin/shared/utils/zod';

import { Details } from './details/details.component';
import { HierarchyView } from './hierarchy-view.component';
import { TAB_VALUES } from './tabs.component';

interface IEquipmentDetailsContentProps {
  tab: TAB_VALUES;
  equipment?: TEquipment;
}

export const EquipmentDetailsContent = ({ tab, equipment }: IEquipmentDetailsContentProps) => {
  switch (tab) {
    case TAB_VALUES.HierarchyView: {
      return <HierarchyView equipment={equipment} />;
    }
    case TAB_VALUES.Details:
    default: {
      return <Details equipment={equipment} />;
    }
  }
};
