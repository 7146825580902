import { MarlinTheme } from '@marlin/shared/theme';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Box, Button, MobileStepper, Modal, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import { content } from './content';
import { IStepperModalProps, IStepperModalStep } from './model';
import { useStyles } from './stepper-modal.styles';

export const StepperModal = ({ handleClose, isOpen, defaultStep, steps, withStepCount }: IStepperModalProps) => {
  const { classes, theme } = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = steps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onClose = () => {
    handleClose();
    setActiveStep(0);
  };

  useEffect(() => {
    if (defaultStep) {
      setActiveStep(defaultStep);
    }
  }, [defaultStep]);

  if (!maxSteps) return null;

  return (
    <Modal open={isOpen} onClose={onClose} disableAutoFocus data-testid="stepper-modal">
      <div className={classes.container}>
        <Box>
          <Typography variant="h6" component="h2" data-testid="stepper-modal-header">
            {steps[activeStep].title}
          </Typography>
          {steps[activeStep].subtitle && (
            <Typography
              variant="body1"
              className={classes.subtitle}
              component="h3"
              data-testid="stepper-modal-subheader"
            >
              {steps[activeStep].subtitle}
            </Typography>
          )}
          <Typography variant="body1" className={classes.description} data-testid="stepper-modal-description">
            {withStepCount && (
              <Typography variant="body1" component="span" className={classes.activeStep}>
                {content.STEP(activeStep + 1)}
              </Typography>
            )}
            {getDescription(steps[activeStep], theme)}
          </Typography>
          {steps[activeStep].imageSrc && (
            <img
              className={classes.img}
              src={steps[activeStep].imageSrc}
              alt={steps[activeStep].title}
              data-testid="stepper-modal-img"
            />
          )}
          <MobileStepper
            variant="dots"
            steps={steps.length}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
                data-testid="next-button"
              >
                {content.NEXT}
                {<KeyboardArrowRight />}
              </Button>
            }
            backButton={
              <Button size="small" onClick={handleBack} disabled={activeStep === 0} data-testid="prev-button">
                {<KeyboardArrowLeft />}
                {content.PREV}
              </Button>
            }
          />
        </Box>
        <div className={classes.actions}>
          <Button onClick={onClose} variant="contained" color="primary" data-testid="ok-button">
            {content.OK}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const getDescription = ({ description, descriptionKeyword }: IStepperModalStep, theme: MarlinTheme) => {
  if (!descriptionKeyword) return description;

  const parts = description.split(new RegExp(`(${descriptionKeyword})`, 'gi'));
  return parts.map((part, index) =>
    part.toLowerCase() === descriptionKeyword.toLowerCase() ? (
      <b key={index} style={{ color: theme.palette.warning.main }}>
        {part}
      </b>
    ) : (
      part
    )
  );
};
