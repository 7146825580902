import { deviceTypeOptions } from './const';
import { TDeviceType } from './device-type.schema';

export const useDeviceTypeChips = () => {
  const removeDeviceTypeById = (id: string, deciceTypes: TDeviceType[] | undefined) =>
    deciceTypes ? deciceTypes.filter((deviceType) => deviceType !== id) : undefined;

  const mapIdToOption = (id: TDeviceType) => ({
    id: deviceTypeOptions[id].id,
    name: deviceTypeOptions[id].name,
  });

  const mapDeviceTypesToOptions = (values: TDeviceType[] | undefined) => (values || []).map(mapIdToOption);

  return {
    removeDeviceTypeById,
    mapDeviceTypesToOptions,
  };
};
