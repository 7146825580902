import { TUserSchema } from '@marlin/account-data-access-user';
import { IDeleteUser, IResendInvite, createColumns } from '@marlin/account-ui-user-list';
import { MarlinTable } from '@marlin/marlin-table';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { Button } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';

import { content } from './content';
import { DeleteUserModal } from './delete-user-modal.component';
import { ResendModal } from './resend-modal.component';
import { useNavigate } from './use-navigate.hook';
import { useUsersList } from './use-users-list.hook';

export const UserList = () => {
  const { goToInvitationPage } = useNavigate();

  const [resendModalOpen, setResendModalOpen] = useState<IResendInvite | null>(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState<IDeleteUser | null>(null);

  const openResendModal = useCallback((user: IResendInvite): void => {
    setResendModalOpen(user);
  }, []);
  const openDeleteModal = useCallback((user: IDeleteUser): void => {
    setDeleteModalOpen(user);
  }, []);
  const closeResendModal = useCallback(() => setResendModalOpen(null), []);
  const closeDeleteModal = useCallback(() => setDeleteModalOpen(null), []);

  const { goTo } = useRouter();

  const navigateToDetails = (userId: string) => {
    goTo(routes.settings.editUser.details.url(userId));
  };

  const buttons = useMemo(() => {
    return (
      <>
        <Button variant="contained" onClick={goToInvitationPage}>
          {content.INVITE_NEW_USER_BTN}
        </Button>
      </>
    );
  }, [goToInvitationPage]);

  return (
    <>
      <MarlinTable<TUserSchema>
        useDataHook={useUsersList}
        createColumns={createColumns({
          onResend: openResendModal,
          onEdit: navigateToDetails,
          onDelete: openDeleteModal,
        })}
        buttons={buttons}
        texts={{
          searchTitle: content.USER_TABLE_SEARCH_LABEL,
          tableTitle: content.USER_TABLE_TITLE,
          tableTitleTooltip: content.USER_TABLE_TOOLTIP,
        }}
      />
      <ResendModal user={resendModalOpen} onClose={closeResendModal} />
      <DeleteUserModal user={deleteModalOpen} onClose={closeDeleteModal} />
    </>
  );
};
