import { getListener, initializeListenerConnection } from '@marlin/shared/utils-realtime-communication';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { TGetHomeDashboardResponseSchema, homeDashboardSchema } from './schema/home-dashboard.schema';

const dashboardEvent = 'SendDashboardUpdate';

export const getHomeDashboardSubject = (): BehaviorSubject<TGetHomeDashboardResponseSchema | undefined> => {
  return getListener<TGetHomeDashboardResponseSchema | undefined>(dashboardEvent, undefined);
};

const mapUpdateOperator = (
  source$: Observable<TGetHomeDashboardResponseSchema | undefined>
): Observable<TGetHomeDashboardResponseSchema | undefined> =>
  source$.pipe(
    map((notification: TGetHomeDashboardResponseSchema | undefined) => {
      const parsedResult = homeDashboardSchema.safeParse(notification);
      if (!parsedResult.success) {
        // Note: we need information about zod error in console
        // TODO: move it to logger in the future
        // eslint-disable-next-line no-console
        console.error(parsedResult.error);
        return undefined;
      }
      return parsedResult.data;
    }),
    catchError((error) => {
      return throwError(error);
    })
  );

export const initSubject = (): Observable<void> => {
  return initializeListenerConnection<TGetHomeDashboardResponseSchema | undefined>(
    dashboardEvent,
    undefined,
    mapUpdateOperator
  );
};
