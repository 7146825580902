import { AddressAutocomplete } from '@marlin/shared/ui-form';
import { FormField, INPUT_TYPE, Input } from '@marlin/shared/ui-form-common';
import { getLogger } from '@marlin/shared/utils/logger';
import { useEffect } from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';

import { content } from '../../../../content';
import { Buttons } from '../../../buttons/buttons.component';
import { TSetupOrganization } from '../../../onboarding.schema';
import { useOnboardingState } from '../../../use-onboarding-state.hook';
import { ONBOARDING_STEP } from '../../steps';
import { useStyles } from '../setup-organization.styles';

interface ICreateNewOrganization {
  form: UseFormReturn<TSetupOrganization>;
  onCancel: () => void;
  onNextStep: (step: ONBOARDING_STEP, completed?: ONBOARDING_STEP[]) => void;
}

export const CreateNewOrganization = ({ form, onCancel, onNextStep }: ICreateNewOrganization) => {
  const { addAddress, onboardingState } = useOnboardingState();
  const { classes } = useStyles();
  const {
    handleSubmit,
    formState: { isValid, isDirty },
  } = form;

  const onSubmit = (data: TSetupOrganization) => {
    getLogger()?.track(content.LOGGER.SELF_SERVICE.SETUP_ORGANIZATION_SUCCESS, {
      name: data.propertyName,
      address: data.propertyAddress.address,
    });
    addAddress({ data });
    onNextStep(ONBOARDING_STEP.REGISTER_EQUIPMENT, [ONBOARDING_STEP.SETUP_ORGANIZATION]);
  };

  useEffect(() => {
    form.setValue('propertyName', onboardingState.propertyName);
    form.setValue('propertyAddress', onboardingState.propertyAddress);
  }, [form, onboardingState.propertyName, onboardingState.propertyAddress]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.inputsContainer}>
          <FormField<TSetupOrganization> fieldName={'propertyName'}>
            {(field) => (
              <Input
                {...field}
                label={content.PROPERTY_NAME}
                required
                className={classes.input}
                data-testid="onboarding-property-name"
                type={INPUT_TYPE.TEXT}
                shrink={!!onboardingState.propertyName?.length || !!field.value?.length}
                fullWidth
              />
            )}
          </FormField>
          <FormField<TSetupOrganization> fieldName="propertyAddress">
            {(field) => {
              return (
                <AddressAutocomplete
                  {...field}
                  label={content.ADDRESS_LABEL}
                  required
                  fullWidth
                  testid="onboarding-address-autocomplete"
                  defaultValue={onboardingState.propertyAddress}
                />
              );
            }}
          </FormField>
        </div>
        <Buttons onNext={handleSubmit(onSubmit)} onCancel={onCancel} isDisabled={!isDirty || !isValid} />
      </form>
    </FormProvider>
  );
};
