import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { useDesktopSideNavbarCollapse } from '@marlin/shared/utils-common-desktop-sidenav';
import { Drawer, Paper } from '@mui/material';
import { FC, PropsWithChildren, useState } from 'react';

import { useChartHover } from '../../hooks/use-chart-hover';
import { PullTabContainer } from '../pull-tab/pull-tab.container';
import { useStyles } from './container.component.styles';
import { ExtendedDeviceDrawerContext } from './context';
import { useDeviceDrawerContainer } from './use-container.hook';

export const DeviceDrawerContainer: FC<PropsWithChildren> = ({ children }) => {
  const { closeDeviceDrawer } = useDeviceDrawerContainer();
  const { desktopNavbarLocked } = useDesktopSideNavbarCollapse();
  const [isExtended, setIsExtended] = useState(false);
  const { classes } = useStyles({ isExtended, desktopNavbarLocked });
  const { pullTab, showPullTab, hidePullTab } = useChartHover();
  const [isTransition, setIsTransition] = useState(false);

  const handleExpand = () => {
    setIsTransition(true);
    setIsExtended(true);
  };

  const handleCollapse = () => {
    setIsTransition(true);
    setIsExtended(false);
  };

  return (
    <Drawer
      className={classes.container}
      variant="temporary"
      open
      onClose={closeDeviceDrawer}
      anchor="right"
      data-testid="device-drawer"
      classes={{
        paper: classes.pullTab,
      }}
      onTransitionEnd={(event) => {
        if (event.propertyName === 'width') {
          setIsTransition(false);
        }
      }}
    >
      <div onMouseEnter={showPullTab} onMouseLeave={hidePullTab}>
        <PullTabContainer hovered={pullTab} onExpand={handleExpand} onCollapse={handleCollapse} isExtended={isExtended}>
          <Paper elevation={1} className={`${classes.container} ${classes.paperShadow}`} data-testid="card">
            <ExtendedDeviceDrawerContext.Provider value={isExtended}>
              {isTransition ? <LoadingSpinner /> : children}
            </ExtendedDeviceDrawerContext.Provider>
          </Paper>
        </PullTabContainer>
      </div>
    </Drawer>
  );
};
