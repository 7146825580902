import { zodResolver } from '@hookform/resolvers/zod';
import { FormField } from '@marlin/shared/ui-form-common';
import { createDateString, dateAdapter } from '@marlin/shared/utils-common-date';
import { Button, Typography } from '@mui/material';
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers/DateTimePicker';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { ICustomPeriodModalProps } from '../period';
import { content } from './content';
import { useStyles } from './custom-period-selector.styles';
import { CustomPeriodSchema, TCustomPeriodSchema } from './schema';

const defaultValues = {
  from: undefined,
  to: undefined,
};

interface ICustomePeriodSelectorProps {
  onCloseCustomDialog: () => void;
  timezone?: string;
  handleCustomRangeSet: (value: ICustomPeriodModalProps) => void;
}

export const CustomPeriodSelector = ({
  onCloseCustomDialog,
  timezone,
  handleCustomRangeSet,
}: ICustomePeriodSelectorProps) => {
  const { classes } = useStyles();
  const form = useForm<TCustomPeriodSchema>({
    mode: 'onChange',
    resolver: zodResolver(CustomPeriodSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isDirty, isValid },
  } = form;

  const errors = useMemo(() => {
    return form.formState.errors;
  }, [form.formState.errors]);

  const onSubmit = (data: TCustomPeriodSchema) => {
    handleCustomRangeSet &&
      handleCustomRangeSet({
        fromDate: dateAdapter.date(data.from),
        toDate: dateAdapter.date(data.to),
      });
    onCloseCustomDialog();
  };

  return (
    <FormProvider {...form}>
      <div className={classes.container}>
        <Typography className={classes.customPeriodText}>{content.CUSTOM_PERIOD}</Typography>
        <FormField<{ from: string }> fieldName="from">
          {(field) => (
            <DateTimePicker
              {...field}
              label={content.FROM}
              className={classes.input}
              value={field.value ? dateAdapter.date(field.value)?.toDate() : undefined}
              onChange={(date) => {
                field.onChange?.(dateAdapter.isValid(date) ? createDateString(date || undefined) : undefined);
                form.trigger();
              }}
              disableFuture
              slotProps={
                {
                  textField: {
                    'data-testid': 'from-date-field',
                    size: 'small',
                    error: !!errors?.from,
                    helperText: errors?.from?.message ?? '',
                  },
                  openPickerButton: {
                    'data-testid': 'from-date-picker-button',
                  },
                } as DateTimePickerProps<Date>['slotProps']
              }
            />
          )}
        </FormField>
        <FormField<{ to: string }> fieldName="to">
          {(field) => (
            <DateTimePicker
              {...field}
              className={classes.input}
              label={content.TO}
              value={field.value ? dateAdapter.date(field.value)?.toDate() : null}
              onChange={(date) => {
                field.onChange?.(dateAdapter.isValid(date) ? createDateString(date || undefined) : undefined);
                form.trigger();
              }}
              disableFuture
              slotProps={
                {
                  textField: {
                    'data-testid': 'to-date-field',
                    size: 'small',
                  },
                  openPickerButton: {
                    'data-testid': 'to-date-picker-button',
                  },
                } as DateTimePickerProps<Date>['slotProps']
              }
            />
          )}
        </FormField>
        <div className={classes.btnContainer}>
          <div>{timezone && <Typography className={classes.orgText}>{content.ORG_TIMEZONE(timezone)}</Typography>}</div>
          <div>
            <Button style={{ marginRight: 4 }} data-testid="cancel-button" variant="text" onClick={onCloseCustomDialog}>
              {content.CANCEL}
            </Button>
            <Button
              type="submit"
              data-testid="save-submit-button"
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              disabled={!isDirty || !isValid}
            >
              {content.SAVE}
            </Button>
          </div>
        </div>
      </div>
    </FormProvider>
  );
};
