import isObject from 'lodash/isObject';
import { BehaviorSubject, Observable } from 'rxjs';

import { IEndpointConfig, IProxy } from './endpoint.models';

export interface IConfigService {
  config$: BehaviorSubject<IEndpointConfig>;
  get(): Observable<IEndpointConfig>;
  set(config: IEndpointConfig): void;
}

export class ConfigService implements IConfigService {
  public readonly config$ = new BehaviorSubject<IEndpointConfig>({ baseDomain: '', baseHref: '' });

  public constructor(private config: IEndpointConfig) {
    if (this.config) {
      this.config$.next(this.config);
    }
  }

  public get = (): Observable<IEndpointConfig> => this.config$.asObservable();

  public set = (config: IEndpointConfig): void => {
    config.proxyConfig = this.parseProxyConfig(config);
    this.config$.next(config);
  };

  private parseProxyConfig = (config: IEndpointConfig): IProxy | undefined => {
    if (!config.proxyConfig || !isObject(config.proxyConfig)) {
      return undefined;
    }

    const proxy: IProxy = {};

    Object.keys(config.proxyConfig).forEach((context): void => {
      let correctedContext = context.replace(/^\*$/, '**').replace(/\/\*$/, '');
      const contextHasLeadingSlash = correctedContext.startsWith('/');
      correctedContext = contextHasLeadingSlash ? correctedContext.substring(1) : correctedContext;

      if (config.proxyConfig) {
        proxy[correctedContext] = config.proxyConfig[context];
      }
    });

    return proxy;
  };
}
