import { useRecipients } from '@marlin/account-data-access-recipient';
import { ALERT_STATUS_FILTER, IAlert, useFilteredAlertsWithLoadMore } from '@marlin/alert/data-access/alert-action';
import { useCallback, useMemo } from 'react';

import { IAlert as IExtendedAlert } from './types';
import { mapExtendedAlerts } from './utils/map-extended-alerts';
import { useSensorId } from './utils/use-sensor-id.hook';

export interface IAlertsTab {
  isLoadingCurrentAlerts: boolean;
  isLoadingResolvedAlerts: boolean;
  isLoadingSnoozedAlerts: boolean;
  deviceId: string;
  totalCurrent: number;
  currentAlerts: IExtendedAlert[];
  totalResolved: number;
  resolvedAlerts: IExtendedAlert[];
  totalSnoozed: number;
  snoozedAlerts: IExtendedAlert[];
  reloadAlerts: (status: ALERT_STATUS_FILTER) => void;
  loadMoreResolvedAlerts: () => void;
  loadMoreCurrentAlerts: () => void;
  loadMoreSnoozedAlerts: () => void;
}

interface IParams {
  status: ALERT_STATUS_FILTER;
  pageSize: number;
  locationIds: string[];
  equipmentIds: string[];
  deviceIds: string[];
  deviceTypes: string[];
}

const defaultParams: IParams = {
  status: ALERT_STATUS_FILTER.CURRENT,
  pageSize: 10,
  locationIds: [],
  equipmentIds: [],
  deviceIds: [],
  deviceTypes: [],
};

export const useAlerts = (): IAlertsTab => {
  const deviceId = useSensorId();
  const deviceIds = useMemo(() => (deviceId ? [deviceId] : []), [deviceId]);

  const recipientQuery = useRecipients({ addDeleted: true });

  const currentAlertQuery = useFilteredAlertsWithLoadMore({
    params: { ...defaultParams, deviceIds },
    staleTime: 2000,
    enabled: deviceIds.length > 0,
  });

  const resolvedAlertQuery = useFilteredAlertsWithLoadMore({
    params: { ...defaultParams, status: ALERT_STATUS_FILTER.RESOLVED, deviceIds },
    enabled: deviceIds.length > 0,
  });

  const snoozedAlertQuery = useFilteredAlertsWithLoadMore({
    params: { ...defaultParams, status: ALERT_STATUS_FILTER.SNOOZED, deviceIds },
    enabled: deviceIds.length > 0,
  });

  const totalCurrent = useMemo(() => {
    const pages = currentAlertQuery.data?.pages || [];
    if (!pages || !pages.length) {
      return 0;
    }
    return pages[0].pagination.totalItems ?? 0;
  }, [currentAlertQuery]);

  const currentAlerts = useMemo(
    () =>
      currentAlertQuery?.data?.pages
        .map((page) => page.data.map((alert: IAlert) => mapExtendedAlerts(alert, recipientQuery.data)) ?? [])
        .flatMap((i) => i) || [],
    [currentAlertQuery?.data?.pages, recipientQuery.data]
  );

  const totalResolved = useMemo(() => {
    const pages = resolvedAlertQuery.data?.pages || [];
    if (!pages || !pages.length) {
      return 0;
    }
    return pages[0].pagination.totalItems ?? 0;
  }, [resolvedAlertQuery]);

  const resolvedAlerts = useMemo(
    () =>
      resolvedAlertQuery?.data?.pages
        .map((page) => page.data.map((alert: IAlert) => mapExtendedAlerts(alert, recipientQuery.data)))
        .flatMap((i) => i) ?? [],
    [recipientQuery.data, resolvedAlertQuery?.data?.pages]
  );

  const totalSnoozed = useMemo(() => {
    const pages = snoozedAlertQuery.data?.pages || [];
    if (!pages || !pages.length) {
      return 0;
    }
    return pages[0].pagination.totalItems ?? 0;
  }, [snoozedAlertQuery]);

  const snoozedAlerts = useMemo(
    () =>
      snoozedAlertQuery?.data?.pages
        .map((page) => page.data.map((alert: IAlert) => mapExtendedAlerts(alert, recipientQuery.data)))
        .flatMap((i) => i) ?? [],
    [recipientQuery.data, snoozedAlertQuery?.data?.pages]
  );

  const reloadAlerts = useCallback(
    (status: ALERT_STATUS_FILTER) => {
      switch (status) {
        case ALERT_STATUS_FILTER.CURRENT: {
          currentAlertQuery.refetch();
          break;
        }
        case ALERT_STATUS_FILTER.RESOLVED: {
          resolvedAlertQuery.refetch();
          break;
        }
        case ALERT_STATUS_FILTER.SNOOZED: {
          snoozedAlertQuery.refetch();
          break;
        }
      }
    },
    [currentAlertQuery, resolvedAlertQuery, snoozedAlertQuery]
  );

  const loadMoreCurrentAlerts = useCallback(async () => {
    await currentAlertQuery.fetchNextPage();
  }, [currentAlertQuery]);

  const loadMoreResolvedAlerts = useCallback(async () => {
    await resolvedAlertQuery.fetchNextPage();
  }, [resolvedAlertQuery]);

  const loadMoreSnoozedAlerts = useCallback(async () => {
    await snoozedAlertQuery.fetchNextPage();
  }, [snoozedAlertQuery]);

  return {
    currentAlerts,
    totalCurrent,
    resolvedAlerts,
    totalResolved,
    totalSnoozed,
    snoozedAlerts,
    deviceId,
    reloadAlerts,
    loadMoreCurrentAlerts,
    loadMoreResolvedAlerts,
    loadMoreSnoozedAlerts,
    isLoadingCurrentAlerts: currentAlertQuery.isLoading || currentAlertQuery.isFetchingNextPage,
    isLoadingResolvedAlerts: resolvedAlertQuery.isLoading || resolvedAlertQuery.isFetchingNextPage,
    isLoadingSnoozedAlerts: snoozedAlertQuery.isLoading || snoozedAlertQuery.isFetchingNextPage,
  };
};
