import { MarlinTheme } from '@marlin/shared/theme';
import { Skeleton, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { TrendBar } from './trend-bar.component';
import { ITrendBar, TWaterTrends } from './types';

const calculatePercentage = (value: number, total: number) => (total === 0 ? 0 : (value / total) * 100);

const calculateBarsPercentage = (trends: TWaterTrends) => {
  if (trends.totalVolume === 0 && trends.previousPeriodTotalVolume === 0) {
    return {
      totalVolume: 0,
      previousPeriodTotalVolume: 0,
    };
  }
  if (trends.previousPeriodTotalVolume > trends.totalVolume) {
    return {
      totalVolume: calculatePercentage(trends.totalVolume, trends.previousPeriodTotalVolume),
      previousPeriodTotalVolume: 100,
    };
  }
  return {
    totalVolume: 100,
    previousPeriodTotalVolume: calculatePercentage(trends.previousPeriodTotalVolume, trends.totalVolume),
  };
};

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  bars: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(8),
    minWidth: theme.typography.pxToRem(184),
    width: '100%',
    flex: 1,
  },
}));

interface IWaterUsageBarsProps {
  trends?: TWaterTrends | null;
}

export const WaterUsageBars = ({ trends }: IWaterUsageBarsProps) => {
  const { classes } = useStyles();
  const theme = useTheme<MarlinTheme>();
  const chartColors = Array.from(Object.values(theme.palette.charting));
  const trendArray: ITrendBar[] = useMemo(() => {
    if (!trends) {
      return [];
    }
    const barWidth = calculateBarsPercentage(trends);
    return [
      {
        value: trends.totalVolume,
        uom: trends.uoM ?? null,
        start: trends.periodStart,
        end: trends.periodEnd,
        widthPercentage: barWidth.totalVolume,
      },
      {
        value: trends.previousPeriodTotalVolume,
        uom: trends.uoM ?? null,
        start: trends.previousPeriodStart,
        end: trends.previousPeriodEnd,
        widthPercentage: barWidth.previousPeriodTotalVolume,
      },
    ];
  }, [trends]);

  if (!trends) {
    return (
      <div className={classes.bars}>
        <div>
          <Skeleton height={24} width="50%" />
          <Skeleton />
        </div>
        <div>
          <Skeleton height={24} width="50%" />
          <Skeleton />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.bars}>
      {trendArray.map((trend, index) => (
        <TrendBar trend={trend} color={chartColors[index]} key={index} />
      ))}
    </div>
  );
};
