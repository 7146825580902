import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import { TPortfolio, TPortfolioListResponse, portfolioListResponseSchema } from '../schema/portfolio.schema';
import { portfolioService } from '../storage/portfolio.service';
import { getCurrentPortfolio, getStoredPortfolioId } from '../storage/portfolio.utils';
import { paths } from './api';

export const getUserPortfolios = async (): Promise<TPortfolioListResponse> => {
  const res = await getHttpClient().get<unknown, TPortfolio[]>(paths.PORTFOLIO);
  const currentPortfolio = getCurrentPortfolio(res);
  const previousPortfolioId = getStoredPortfolioId();
  portfolioService.setPortfolio(currentPortfolio);
  return safeParseData(
    {
      portfolios: res,
      currentPortfolio,
      previousPortfolioId,
    },
    portfolioListResponseSchema
  );
};
