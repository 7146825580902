import { MarlinTheme } from '@marlin/shared/theme';
import { useAuth } from '@marlin/shared/utils-auth';
import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import { getLogger } from '@marlin/shared/utils/logger';
import { Button } from '@mui/material';
import { useCallback, useContext } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../../content';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  cancelButton: {
    marginLeft: theme.typography.pxToRem(8),
  },
}));

export const CancelModalFooter = () => {
  const { classes } = useStyles();
  const { modalDispatch } = useContext(ModalContext);
  const { logOut } = useAuth();

  const handleCloseModal = useCallback(() => {
    modalDispatch({
      type: MODAL_ACTION_TYPE.DISMISS,
    });
  }, [modalDispatch]);

  const handleConfirm = useCallback(() => {
    getLogger()?.track('OnboardingCanceled');
    logOut();
    modalDispatch({
      type: MODAL_ACTION_TYPE.DISMISS,
    });
  }, [logOut, modalDispatch]);

  return (
    <div data-testid="deregister-modal-footer" className={classes.footer}>
      <Button className={classes.cancelButton} variant="text" onClick={handleCloseModal}>
        {content.CANCEL_MODAL.CANCEL}
      </Button>
      <Button className={`${classes.cancelButton}`} variant="contained" color="error" onClick={handleConfirm}>
        {content.CANCEL_MODAL.CONFIRM}
      </Button>
    </div>
  );
};
