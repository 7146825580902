import Skeleton from '@mui/material/Skeleton';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: theme.typography.pxToRem(24),
    width: '100%',
  },
}));

export const SettingsSectionSkeleton = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </div>
  );
};
