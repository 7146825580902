import { MarlinTheme } from '@marlin/shared/theme';
import { DataLoader } from '@marlin/shared/ui-loader';
import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { MobileAlertCurrentCard } from './card/mobile/alert-current-card.component';
import { MobileAlertLostCommunicationCard } from './card/mobile/alert-lost-communication-card.component';
import { MobileAlertResolvedCard } from './card/mobile/alert-resolved-card.component';
import { content } from './content';
import { ALERT_STATUS_FILTER, ERROR_TYPES, IAlert } from './types';

interface IAlertList extends Record<string, unknown> {
  isLoading: boolean;
  isError: boolean;
  alerts?: IAlert[];
  displayIconColumn: boolean;
}

const headerHeight = 180;

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    minHeight: '100vh',
    overflowWrap: 'break-word',
    padding: theme.typography.pxToRem(16),
  },

  emptyState: {
    height: `calc(100vh - ${theme.typography.pxToRem(headerHeight)})`,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const MobileAlertCard = ({ alert, displayIconColumn }: { alert: IAlert; displayIconColumn: boolean }): JSX.Element => {
  if (alert.errorType === ERROR_TYPES.LOST_COMMUNICATION) {
    return <MobileAlertLostCommunicationCard alert={alert} displayIconColumn={displayIconColumn} />;
  }

  switch (alert.status) {
    case ALERT_STATUS_FILTER.CURRENT:
    case ALERT_STATUS_FILTER.SNOOZED: {
      return <MobileAlertCurrentCard alert={alert} displayIconColumn={displayIconColumn} />;
    }
    case ALERT_STATUS_FILTER.RESOLVED: {
      return <MobileAlertResolvedCard alert={alert} displayIconColumn={displayIconColumn} />;
    }
    default: {
      return <MobileAlertCurrentCard alert={alert} displayIconColumn={displayIconColumn} />;
    }
  }
};

export function MobileAlertList({ alerts, displayIconColumn, isLoading, isError }: IAlertList) {
  const { classes } = useStyles();

  if (isError) {
    return (
      <div className={classes.emptyState} data-testid="alerts-page-error">
        {content.ERROR}
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className={classes.container}>
        <DataLoader />
      </div>
    );
  }

  if (!alerts?.length) {
    return (
      <div className={classes.emptyState} data-testid="alerts-page-empty">
        {content.NO_DATA}
      </div>
    );
  }

  return (
    <div data-testid="alert-list-container" className={classes.container}>
      <Grid container rowGap={1}>
        {alerts?.map((alert: IAlert) => (
          <MobileAlertCard alert={alert} key={alert.id} displayIconColumn={displayIconColumn} />
        ))}
      </Grid>
    </div>
  );
}

export default MobileAlertList;
