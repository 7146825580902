import { DateSchema } from '@marlin/shared/utils-common-date';
import {
  ApiUnitOfMeasures,
  TransformApiUnitOfMeasuresToDevice,
  UnitOfMeasure,
  apiUnitOfMeasuresToDevice,
} from '@marlin/shared/utils-format-reading';
import { z } from 'zod';

export const LastReadingValueResponseSchema = z.object({
  isDefault: z.boolean().nullish(),
  value: z.string(),
  name: z.string(),
  unitOfMeasure: ApiUnitOfMeasures.nullish()
    .or(z.literal(''))
    .transform((unitOfMeasureResponse) => {
      const unitOfMeasure = TransformApiUnitOfMeasuresToDevice.safeParse(unitOfMeasureResponse);

      return unitOfMeasure.success ? unitOfMeasure.data : undefined;
    }),
  lastReadingTime: DateSchema.nullish(),
});

export const LastReadingValueSchema = z.object({
  isDefault: z.boolean().nullish(),
  value: z.string(),
  name: z.string(),
  unitOfMeasure: UnitOfMeasure.or(
    ApiUnitOfMeasures.nullish().transform((item) => {
      if (!item) return undefined;

      const unit = item.toUpperCase();

      if (apiUnitOfMeasuresToDevice.has(unit)) {
        return apiUnitOfMeasuresToDevice.get(unit) ?? undefined;
      }

      return null;
    })
  ),
  lastReadingTime: DateSchema.nullish(),
});

export const filterUnsupportedElementsSchema = z.preprocess((readings: unknown) => {
  const readingsArray = z.array(z.unknown()).parse(readings);

  return readingsArray.filter((reading) => {
    return LastReadingValueResponseSchema.safeParse(reading).success;
  }) as z.infer<typeof LastReadingValueResponseSchema>[];
}, z.array(LastReadingValueResponseSchema));

export type TLastReadingValue = z.infer<typeof LastReadingValueSchema>;
