import { TEquipmentDetailsRow } from '@marlin/asset/shared/equipment-config';
import { dateFormatterValueCallback } from '@marlin/shared/utils-common-date';

import { content } from '../../../content';

export const detailsConfig: TEquipmentDetailsRow[] = [
  { name: 'automationCount', label: content.AUTOMATIONS, valueSource: 'equipment' },
  { name: 'AppFirmwareVersion', label: content.APP_FIRMWARE_VERSION, valueSource: 'datapoints' },
  { name: 'BootloaderFirmwareVersion', label: content.BOOTLOADER_FIRMWARE_VERSION, valueSource: 'datapoints' },
  { name: 'brand', label: content.BRAND, valueSource: 'equipment' },
  {
    name: 'manufacturerId',
    label: content.EQUIPMENT_ID,
    valueSource: 'device',
    tooltip: content.EQUIPMENT_ID_TOOLTIP,
  },
  { name: 'ControllerId', label: content.CONTROLLER_ID, valueSource: 'datapoints' },
  { name: 'SerialNumber', label: content.SERIAL_NUMBER, valueSource: 'datapoints' },
  { name: 'UnitRunTime', label: content.RUNTIME, valueSource: 'datapoints' },
  {
    name: 'commissionDate',
    label: content.COMMISSION_DATE,
    valueSource: 'equipment',
    valueCallback: dateFormatterValueCallback,
  },
  { name: 'description', label: content.DESCRIPTION, valueSource: 'equipment' },
  { name: 'gatewayName', label: content.GATEWAY_NAME, valueSource: 'additionalProperties' },
  { name: 'connectionAddressNumber', label: content.MODBUS_ADDRESS, valueSource: 'additionalProperties' },
];
