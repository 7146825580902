import { RoleSchema } from '@marlin/shared/utils-role';
import { z } from 'zod';

export const organizationSchema = z.object({
  id: z.string(),
  name: z.string(),
  role: RoleSchema,
});

export type TOrganization = z.infer<typeof organizationSchema>;
