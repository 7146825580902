import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { SyntheticEvent, useCallback } from 'react';

import { content } from '../../content';
import { useStyles } from './tabs.styles';

export enum TAB_VALUES {
  Help = 'help',
}
export interface ITabsProps {
  tabValue: TAB_VALUES;
  updateTab: (newValue: TAB_VALUES) => void;
}

export const HelpTabs = ({ tabValue, updateTab }: ITabsProps) => {
  const { classes } = useStyles();

  const handleTableChange = useCallback(
    (_: SyntheticEvent, newValue: TAB_VALUES) => {
      updateTab(newValue);
    },
    [updateTab]
  );

  return (
    <Tabs variant="scrollable" scrollButtons={false} value={tabValue} onChange={handleTableChange}>
      <Tab value={TAB_VALUES.Help} className={classes.tab} label={content.CUSTOMER_FEEDBACK} />
    </Tabs>
  );
};
