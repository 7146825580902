import { TTelemetry } from '@marlin/asset/data-access/telemetry';
import { getChartTicks } from '@marlin/shared/utils-chart';
import { dateAdapter } from '@marlin/shared/utils-common-date';
import { parseDeviceReadings } from '@marlin/shared/utils-format-reading';
import { useMemo } from 'react';

import { useDeviceDetailsContext } from '../../../context/device-details.context';
import { useExtendedDeviceDrawer } from '../../container/context';
import { useChartRangeContext } from '../context/chart-range.context';
import { IChartPoint } from '../types';
import { IChart } from './collapsed-chart/chart-props';

interface IUseChartProps {
  telemetry: TTelemetry | undefined;
}

export const useChart = ({ telemetry }: IUseChartProps): IChart => {
  const deviceDetails = useDeviceDetailsContext();
  const {
    rangeFilter,
    extendedRangeFilter,
    setExtendedRangeFilter: setCustomRangeFilter,
    onRangeFilterChange,
  } = useChartRangeContext();

  const isExtended = useExtendedDeviceDrawer();
  const deviceType = useMemo(() => deviceDetails?.deviceType, [deviceDetails?.deviceType]);
  const deviceName = useMemo(() => deviceDetails?.name, [deviceDetails?.name]);
  const manufacturerId = useMemo(() => deviceDetails?.manufacturerId || '', [deviceDetails?.manufacturerId]);

  const data: IChartPoint[] = useMemo(() => {
    if (!telemetry || !telemetry.chartData) {
      return [];
    }

    return telemetry?.chartData?.map((telemetryData) => {
      return {
        timestamp: dateAdapter.date(telemetryData.eventDateTime)?.toDate().getTime() ?? 0,
        value: parseDeviceReadings(telemetryData.value, deviceType).value,
      };
    });
  }, [deviceType, telemetry]);

  const timeZoneAbbr = useMemo(() => {
    return new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
  }, []);

  const ticks = useMemo(() => getChartTicks(telemetry), [telemetry]);

  return useMemo(
    (): IChart => ({
      data,
      timeZoneAbbr,
      deviceType,
      deviceName,
      manufacturerId,
      ticks,
      rangeFilter: isExtended ? extendedRangeFilter : rangeFilter,
      onRangeFilterChange,
      setCustomRangeFilter,
      uoM: telemetry?.uoM,
    }),
    [
      data,
      deviceName,
      deviceType,
      extendedRangeFilter,
      isExtended,
      manufacturerId,
      onRangeFilterChange,
      rangeFilter,
      setCustomRangeFilter,
      telemetry?.uoM,
      ticks,
      timeZoneAbbr,
    ]
  );
};
