import { MarlinTheme } from '@marlin/shared/theme';
import { alpha } from '@mui/system';
import { makeStyles } from 'tss-react/mui';

interface IStyles {
  isActive?: boolean;
}

export const useStyles = makeStyles<IStyles>()((theme: MarlinTheme, { isActive }) => ({
  locationChip: {
    marginRight: theme.typography.pxToRem(8),
    color: theme.palette.primary.dark,
    backgroundColor: isActive ? alpha(theme.palette.primary.main, 0.12) : 'transparent',
    borderColor: theme.palette.primary.main,
    '& .MuiChip-deleteIcon': {
      color: alpha(theme.palette.primary.main, 0.5),
      ':hover': {
        color: theme.palette.primary.dark,
      },
    },
    '& .MuiTouchRipple-root': {
      backgroundColor: isActive ? alpha(theme.palette.primary.main, 0.12) : 'transparent',
    },
    '& .MuiChip-icon': {
      color: isActive ? theme.palette.primary.main : 'inherit',
    },
  },
}));
