import { TDeadbandFieldName } from '../paths.model';
import { DeadbandControl } from './deadband-control';
import { DeadbandEnabler } from './deadband-enabler';

interface IDeadbandProps {
  name: TDeadbandFieldName;
  disabled: boolean;
  label: string;
  unit: string;
}

export const Deadband = ({ name, label, unit, disabled }: IDeadbandProps) => {
  return (
    <DeadbandEnabler name={`${name}.enabled`} disabled={disabled}>
      {(value: boolean) =>
        value ? <DeadbandControl name={`${name}.value`} disabled={disabled} label={label} unit={unit} /> : null
      }
    </DeadbandEnabler>
  );
};
