import { MarlinTheme } from '@marlin/shared/theme';
import { ModalLarge } from '@marlin/shared/ui-modal';
import { TDateString, defaultDateTime, formatDate as formatDateUtil } from '@marlin/shared/utils-common-date';
import { Button } from '@mui/material';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { isSystemAutomation } from './automation-type.util';
import { content } from './content';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  confirmText: {
    fontWeight: theme.typography.fontWeightBold,
  },

  button: {
    width: theme.typography.pxToRem(88),
  },

  cancelButton: {
    marginLeft: theme.typography.pxToRem(8),
  },
}));

interface ICancelSnoozeModalProps {
  onClose: () => void;
  onSubmit: (isSystemAutomation: boolean, deviceName: string) => void;
  automationName: string;
  deviceName: string;
  snoozeUntil: TDateString;
  buttonDisabled?: boolean;
}

export const CancelSnoozeModal = ({
  onClose,
  onSubmit,
  automationName,
  snoozeUntil,
  buttonDisabled,
  deviceName,
}: ICancelSnoozeModalProps) => {
  const { classes } = useStyles();

  const snoozeUntilDate = useMemo(() => {
    return formatDateUtil(snoozeUntil, defaultDateTime);
  }, [snoozeUntil]);

  return (
    <ModalLarge
      onClose={onClose}
      title={content.CANCEL_SNOOZE_TITLE}
      body={
        <>
          {isSystemAutomation(automationName) ? (
            <div> {content.CANCEL_SNOOZE_MESSAGE_SYSTEM(automationName, deviceName)} </div>
          ) : (
            <div>{content.CANCEL_SNOOZE_MESSAGE(automationName)}</div>
          )}
          <span className={classes.confirmText}>{content.CANCEL_SNOOZE_SNOOZE_TIME(snoozeUntilDate)} </span>
        </>
      }
      footer={
        <>
          <Button className={classes.button} variant="text" onClick={onClose}>
            {content.BTN_CANCEL}
          </Button>
          <Button
            className={`${classes.button} ${classes.cancelButton}`}
            variant="contained"
            color="error"
            onClick={() => onSubmit(isSystemAutomation(automationName), deviceName)}
            disabled={buttonDisabled}
          >
            {content.BTN_STOP}
          </Button>
        </>
      }
    />
  );
};
