import { MarlinTheme } from '@marlin/shared/theme';
import { useVisualViewportHeight } from '@marlin/shared/utils-common-user-agent';
import { makeStyles } from 'tss-react/mui';

const defaultDesktopDropdownHeight = 300;
const defaultMobileDropdownHeight = 102;
const headerHeight = 69;
const inputHeight = 56;
const offset = 16;

const calculateMaxDropdownHeight = (viewportHeight: number | undefined) => {
  if (viewportHeight) {
    const calculatedDropdownHeight = viewportHeight - headerHeight - inputHeight - offset;

    return Math.min(defaultDesktopDropdownHeight, calculatedDropdownHeight);
  }

  return defaultMobileDropdownHeight;
};

interface IStyles {
  viewportHeight: number | undefined;
}

const useClasses = makeStyles<IStyles>()((theme: MarlinTheme, { viewportHeight }) => ({
  paper: {
    maxHeight: theme.typography.pxToRem(defaultDesktopDropdownHeight),
    overflowY: 'scroll',
    [theme.breakpoints.down('md')]: {
      maxHeight: theme.typography.pxToRem(calculateMaxDropdownHeight(viewportHeight)),
    },
  },

  listbox: {
    maxHeight: 'unset',
  },

  noOptions: {
    display: 'none',
  },
}));

export const useStyles = () => {
  const viewportHeight = useVisualViewportHeight();

  return useClasses({ viewportHeight });
};
