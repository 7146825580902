import { PERMISSIONS, usePermission } from '@marlin/shared/utils-permission';
import { routes } from '@marlin/shared/utils-routes';
import { useCallback, useMemo } from 'react';

import { useRouter } from './use-router.hook';

interface IUseEquipmentHubContextMenu {
  allowDelete: boolean;
  allowEdit: boolean;
  allowDeregister: boolean;
  editEquipment: (equipmentId: string) => void;
}

export const useEquipmentHubContextMenu = (): IUseEquipmentHubContextMenu => {
  const { goTo } = useRouter();

  const allowDelete = usePermission(PERMISSIONS.DELETE_EQUIPMENT);
  const allowEdit = usePermission(PERMISSIONS.EDIT_EQUIPMENT);
  const allowDeregister = usePermission(PERMISSIONS.DEREGISTER_EQUIPMENT);

  const editEquipment = useCallback(
    (equipmentId: string) => {
      const editEquipmentUrl = routes.equipments.update.url(equipmentId);
      goTo(editEquipmentUrl);
    },
    [goTo]
  );

  return useMemo(
    () => ({
      allowDelete,
      allowEdit,
      editEquipment,
      allowDeregister,
    }),
    [allowDelete, allowEdit, editEquipment, allowDeregister]
  );
};
