import { content } from './content';
import { getUomSymbol } from './datapoint-display/displayed-value-utils';
import { TDeviceType } from './model';
import { uomSettingsService } from './settings/uom-settings.service';

export const getUnitType = (deviceType?: TDeviceType): string => {
  const uomSettings = uomSettingsService.getUomSettings();

  if (!uomSettings) {
    switch (deviceType) {
      case 'TEMPERATURE': {
        return content.UNITS.TEMPERATURE;
      }
      case 'PRESSURE': {
        return content.UNITS.PRESSURE;
      }
      case 'PULSE_METER': {
        return content.UNITS.FLOW_METER;
      }
      default: {
        return '';
      }
    }
  }
  switch (deviceType) {
    case 'TEMPERATURE': {
      return getUomSymbol(uomSettings.temperatureUnit) || content.UNITS.TEMPERATURE;
    }
    case 'PRESSURE': {
      return getUomSymbol(uomSettings.pressureUnit) || content.UNITS.PRESSURE;
    }
    case 'PULSE_METER': {
      return getUomSymbol(uomSettings.flowUnit) || content.UNITS.FLOW_METER;
    }
    default: {
      return '';
    }
  }
};
