import { useDesktopSideNavbarCollapse } from '@marlin/shared/utils-common-desktop-sidenav';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Drawer, IconButton } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';

import { ExtendedDrawerHeader } from './extended-drawer-header.component';
import { useStyles } from './extended-drawer.styles';

interface IExtendedDrawerProps extends PropsWithChildren {
  open: boolean;
  onClose: () => void;
  header?: ReactNode;
}

export const ExtendedDrawer = ({ children, open, onClose, header }: IExtendedDrawerProps) => {
  const { desktopNavbarLocked } = useDesktopSideNavbarCollapse();
  const { classes } = useStyles({ desktopNavbarLocked });

  return (
    <Drawer
      classes={{
        paper: classes.container,
      }}
      anchor={'right'}
      open={open}
      onClose={onClose}
    >
      {header ? (
        header
      ) : (
        <ExtendedDrawerHeader>
          <IconButton aria-label="close-drawer" onClick={onClose} data-testid="close-button">
            <CloseRoundedIcon className={classes.closeIcon} />
          </IconButton>
        </ExtendedDrawerHeader>
      )}
      <div className={classes.content}>{children}</div>
    </Drawer>
  );
};
