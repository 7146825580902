import { TCreateAutomation } from '@marlin/alert/data-access/automated-action';
import { MarlinTheme } from '@marlin/shared/theme';
import { Button, Grid } from '@mui/material';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { useContent } from './context';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    marginTop: theme.typography.pxToRem(8),
  },
}));

interface IAutomatedActionButtonProps {
  onSubmit: (data: TCreateAutomation) => void;
  onCancel: () => void;
}

export function Buttons({ onCancel, onSubmit }: IAutomatedActionButtonProps) {
  const { classes } = useStyles();
  const content = useContent();
  const { handleSubmit, formState } = useFormContext<TCreateAutomation>();

  const disabled = useMemo(() => !formState.isDirty || !formState.isValid, [formState.isDirty, formState.isValid]);

  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item xs={12} md={6}>
        <Button fullWidth variant="outlined" onClick={onCancel}>
          {content.BUTTONS.CANCEL}
        </Button>
      </Grid>
      <Grid item xs={12} md={6}>
        <Button
          fullWidth
          id="create-automation-button"
          data-testid="create-automation-button"
          variant="contained"
          disabled={disabled}
          onClick={handleSubmit(onSubmit)}
        >
          {content.BUTTONS.CONFIRM}
        </Button>
      </Grid>
    </Grid>
  );
}
