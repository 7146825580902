import { TExtractFnReturnType, useQuery } from '@marlin/shared/utils/react-query';
import { useMemo } from 'react';

import { ASSET_TYPE, TAssetListFilters, assetListFiltersSchema } from '../asset.model.schema';
import { getAssets } from '../infrastructure/assets';
import { queryKey } from './query-key.enum';

export interface IAssetFilter {
  filter?: ASSET_TYPE;
  search?: string;
}

export const useAssets = (filters: IAssetFilter, enabled = false, onError?: () => void) => {
  const queryParams: TAssetListFilters = useMemo(() => assetListFiltersSchema.parse(filters), [filters]);

  return useQuery<TExtractFnReturnType<typeof getAssets>>({
    queryKey: queryKey.ASSETS(queryParams),
    queryFn: () => getAssets(queryParams),
    enabled,
    onError,
  });
};
