import { getHttpClient } from '@marlin/shared/utils/react-query';
import { z } from 'zod';

import { IUpsertRecipientProfile } from '../recipient.model';

const recipientSchema = z
  .object({
    id: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    phoneNumber: z.string(),
    email: z.string(),
  })
  .transform((r) => {
    return {
      id: r.id,
      firstName: r.firstName,
      lastName: r.lastName,
      phone: r.phoneNumber,
      email: r.email,
    };
  });

export const getRecipientById = async (id: string): Promise<IUpsertRecipientProfile> => {
  const res = await getHttpClient().get<unknown, IUpsertRecipientProfile>('/api/recipients/{id}', {
    params: {
      id,
    },
  });
  return recipientSchema.parse(res);
};
