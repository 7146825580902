import { createContext, useContext } from '@marlin/shared/utils-component-context';
import { TDatapointWithUiMetadata } from '@marlin/shared/utils/datapoint-mappers';
import { TGatewayInfoUpdateResponsePayload } from '@marlin/shared/utils/zod';
import { PropsWithChildren, useCallback } from 'react';

import { useGatewayDatapoints } from '../hooks/use-gateway-datapoints.hook';

interface IGatewayDatapointsContext {
  datapoints: TDatapointWithUiMetadata[];
  isLoading: boolean;
  timestamp: string;
  getDatapoint: (name: string) => TDatapointWithUiMetadata | undefined;
  gateway: TGatewayInfoUpdateResponsePayload;
}

export const GatewayDatapointsContext = createContext<IGatewayDatapointsContext>();

export const GatewayDatapointsContextProvider = ({
  gateway,
  children,
}: PropsWithChildren<{
  gateway: TGatewayInfoUpdateResponsePayload;
}>) => {
  const { datapointWithUiMetadata, timestamp, isLoading } = useGatewayDatapoints(gateway.id ?? '', gateway);

  const getDatapoint = useCallback(
    (name: string) => {
      return datapointWithUiMetadata.find((datapoint) => {
        return datapoint.name.toLowerCase() === name.toLowerCase();
      });
    },

    [datapointWithUiMetadata]
  );

  return (
    <GatewayDatapointsContext.Provider
      value={{ datapoints: datapointWithUiMetadata, timestamp, isLoading, getDatapoint, gateway }}
    >
      {children}
    </GatewayDatapointsContext.Provider>
  );
};

export const useGatewayDatapointsContext = () => useContext(GatewayDatapointsContext);
