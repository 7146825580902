import { MarlinTheme } from '@marlin/shared/theme';
import { ALERT_STATUS, IUser, RecipientTags } from '@marlin/shared/ui-recipient-tags';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    marginTop: theme.typography.pxToRem(5),
    marginRight: theme.typography.pxToRem(16),
  },

  icon: {
    height: theme.typography.pxToRem(16),
    '& > svg': {
      color: theme.palette.action.active,
      height: '100%',
    },
    marginRight: theme.typography.pxToRem(3),
  },

  label: {
    fontSize: theme.typography.pxToRem(14),
    marginRight: theme.typography.pxToRem(8),
  },

  disabled: {
    opacity: 0.6,
    filter: 'gray',
    '-webkit-filter': 'grayscale(100%)',
    '-moz-filter': 'grayscale(100%)',
    '-ms-filter': 'grayscale(100%)',
    '-o-filter': 'grayscale(100%)',
  },
}));

interface IDetailsItemProps {
  label: string;
  icon: JSX.Element;
  users?: IUser[];
  channelType: 'SMS' | 'EMAIL';
}

export const RecipientList = ({ label, icon, users, channelType }: IDetailsItemProps) => {
  const { classes } = useStyles();

  const snoozed: boolean = useMemo(
    () => !!users && users.every((user) => user.status === ALERT_STATUS.SNOOZED),
    [users]
  );

  if (!users?.length) {
    return null;
  }

  return (
    <div className={`${classes.wrapper} ${snoozed ? classes.disabled : ''}`}>
      <span className={classes.icon}>{icon}</span>
      <span className={classes.label} data-testid="recipients-section-label">
        {label}
      </span>
      <span>
        <RecipientTags users={users} channelType={channelType} numberOfUsersToDisplay={0} />
      </span>
    </div>
  );
};
