import { RANGE_FILTER } from '@marlin/shared/utils-chart';
import { dateAdapter } from '@marlin/shared/utils-common-date';
import { Moment } from 'moment';

import { PERIOD_ENUM } from './period-map';

export interface IRange {
  from: Moment | null;
  to: Moment | null;
}

export const calculateFromDate = (selectedPeriod: PERIOD_ENUM, date?: Moment) => {
  const newDate = dateAdapter.date(date);
  switch (selectedPeriod) {
    case PERIOD_ENUM.DAY:
      return newDate?.subtract(1, 'day');
    case PERIOD_ENUM.WEEK:
      return newDate?.subtract(7, 'day');
    case PERIOD_ENUM.MONTH:
      return newDate?.subtract(30, 'day');
    case PERIOD_ENUM.QUARTER:
      return newDate?.subtract(90, 'day');
    case PERIOD_ENUM.YEAR:
      return newDate?.subtract(1, 'year');
    default:
      return null;
  }
};

export const calculateRange = (selectedPeriod: PERIOD_ENUM) => {
  switch (selectedPeriod) {
    case PERIOD_ENUM.DAY:
      return RANGE_FILTER.HOURS_24;
    case PERIOD_ENUM.WEEK:
      return RANGE_FILTER.DAYS_7;
    case PERIOD_ENUM.MONTH:
      return RANGE_FILTER.DAYS_30;
    case PERIOD_ENUM.QUARTER:
      return RANGE_FILTER.DAYS_90;
    case PERIOD_ENUM.YEAR:
      return RANGE_FILTER.MONTHS_12;
    case PERIOD_ENUM.CUSTOM:
      return RANGE_FILTER.CUSTOM;
    default:
      return RANGE_FILTER.DAYS_7;
  }
};
