import { MarlinTheme } from '@marlin/shared/theme';
import { EllipsisTooltip } from '@marlin/shared/ui-common-tooltip';
import { RoleChip } from '@marlin/shared/ui/chips';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useState } from 'react';
import { FieldError } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';
import { TOrganization } from '../organization.schema';
import { OrganizationListFooter } from './organization-list-footer.component';

interface IStyles {
  showSelectAllCheckbox: boolean;
}

export const useStyles = makeStyles<IStyles>()((theme: MarlinTheme, { showSelectAllCheckbox }) => ({
  root: {
    border: 'none',
    '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
      display: !showSelectAllCheckbox ? 'none' : 'flex',
    },
  },
  column: {
    '&.MuiDataGrid-columnHeader': {
      padding: theme.typography.pxToRem(16),
      backgroundColor: theme.palette.background.alternative,
    },
    '& > .MuiDataGrid-columnSeparator': {
      visibility: 'hidden',
    },
  },
}));

const createColumns = (): GridColDef[] => [
  {
    field: 'name',
    headerName: content.ORGANIZATION_NAME_LABEL,
    flex: 1,
    renderCell: (cellValues) => <EllipsisTooltip>{cellValues.row.name}</EllipsisTooltip>,
  },
  {
    field: 'role',
    headerName: content.ORGANIZATION_ROLE_LABEL,
    width: 100,
    renderCell: (cellValues) => {
      return <RoleChip role={(cellValues.row as TOrganization).role} />;
    },
  },
];

export interface IOrganizationFieldProps {
  value: (string | number)[];
  onChange: (organizationIds: (string | number)[]) => void;
  fieldError?: FieldError;
}

export interface IOrganizationProps {
  organizations: TOrganization[];
  isLoading?: boolean;
  isLoadingError?: boolean;
  showSelectAllCheckbox?: boolean;
}

export interface IOrganizationListProps extends IOrganizationFieldProps, IOrganizationProps {}

const defaultPageSize = 5;
export const OrganizationList = ({
  value,
  onChange,
  organizations,
  showSelectAllCheckbox = true,
  isLoading,
  isLoadingError,
  fieldError,
}: IOrganizationListProps) => {
  const { classes } = useStyles({ showSelectAllCheckbox });
  const [pageSize, setPageSize] = useState(defaultPageSize);

  return (
    <DataGrid
      columns={createColumns()}
      localeText={{
        noRowsLabel: isLoadingError ? content.TABLE_ERROR : content.TABLE_NO_DATA,
      }}
      rows={organizations}
      checkboxSelection
      keepNonExistentRowsSelected
      loading={isLoading}
      onSelectionModelChange={(newSelectionModel) => {
        onChange(newSelectionModel);
      }}
      selectionModel={value}
      pageSize={pageSize}
      classes={{
        root: classes.root,
        columnHeader: classes.column,
        columnHeaderCheckbox: classes.column,
      }}
      rowsPerPageOptions={[5, 10, 50, 100]}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      disableColumnMenu
      components={{
        Footer: () => <OrganizationListFooter count={value.length || 0} fieldError={fieldError} />,
      }}
    />
  );
};
