import { CircularProgress } from '@mui/material';

import { LOADER_TYPE } from '../loader-type.enum';
import { useLoader } from '../use-loader';
import { useStyles } from './app-loader.component.styles';

export function AppLoader(): JSX.Element | null {
  const { classes } = useStyles();
  const showLoader = useLoader(LOADER_TYPE.APP);

  if (!showLoader) {
    return null;
  }

  return (
    <div className={classes.overlay}>
      <div className={classes.loader}>
        <CircularProgress />
      </div>
    </div>
  );
}
