import { MarlinTheme } from '@marlin/shared/theme';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface IMetadataProps {
  locationName: string | null;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    marginBottom: theme.typography.pxToRem(10),
    alignItems: 'baseline',
  },

  overline: {
    marginRight: theme.typography.pxToRem(4),
  },

  caption: {
    marginRight: theme.typography.pxToRem(20),
  },
}));

function Name({ name }: { name: string | null }): JSX.Element {
  return <>{name ?? '--'}</>;
}

export function Metadata({ locationName }: IMetadataProps) {
  const { classes } = useStyles();

  if (!locationName) {
    return null;
  }

  return (
    <Grid className={classes.container} container>
      <Grid item>
        <Typography className={classes.overline} variant="overline">
          Location:
        </Typography>
      </Grid>

      <Grid item>
        <Typography className={classes.caption} variant="caption">
          <Name name={locationName} />
        </Typography>
      </Grid>
    </Grid>
  );
}
