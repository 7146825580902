import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import { paths } from './api';
import {
  TFilteredOrganizationRequest,
  TFilteredOrganizationResponse,
  filteredOrganizationRequestSchema,
  filteredOrganizationResponseSchema,
} from './organization.schema';

export const getFilteredOrganizations = async (
  data: TFilteredOrganizationRequest
): Promise<TFilteredOrganizationResponse> => {
  const body = safeParseData(data, filteredOrganizationRequestSchema);
  const res = await getHttpClient().post(paths.ORGANIZATION_LIST, body);
  return safeParseData(res, filteredOrganizationResponseSchema);
};
