import { TDeviceTypeSchema } from '@marlin/asset/data-access/device';
import { TAlertChartData, useRangeFromCache, useRangeTelemetry } from '@marlin/asset/data-access/telemetry';
import { getChartTicks } from '@marlin/shared/utils-chart';
import { dateAdapter } from '@marlin/shared/utils-common-date';
import { parseDeviceReadings } from '@marlin/shared/utils-format-reading';
import { useMemo } from 'react';

import { ICharTicks, IChartPoint } from '../../../types';

export interface IRange {
  from: number;
  to: number;
}

interface IUseExtendedChart {
  data: IChartPoint[];
  alertData: TAlertChartData[];
  isLoading: boolean;
  isFetching: boolean;
  ticks: ICharTicks;
}

export const useMainChart = (
  range: IRange | null,
  deviceType?: TDeviceTypeSchema,
  manufacturerId?: string
): IUseExtendedChart => {
  const mainChartQueryParams = useMemo(
    () => ({
      to: dateAdapter.date(range?.to)?.utc().toISOString() ?? '',
      from: dateAdapter.date(range?.from)?.utc().toISOString() ?? '',
      manufacturerId: manufacturerId || '',
      enabled: !!manufacturerId && !!manufacturerId.length && !!range,
      keepPreviousData: true,
    }),
    [manufacturerId, range]
  );

  const mainChartQuery = useRangeTelemetry(mainChartQueryParams);
  const cache = useRangeFromCache(
    mainChartQueryParams.manufacturerId,
    mainChartQueryParams.to,
    mainChartQueryParams.from
  );

  const cacheData: IChartPoint[] | undefined = useMemo(() => {
    if (!cache?.state.data || !cache?.state.data.chartData) {
      return undefined;
    }

    return cache?.state.data?.chartData?.map((telemetryData) => {
      return {
        timestamp: dateAdapter.date(telemetryData.eventDateTime)?.toDate().getTime() ?? 0,
        value: parseDeviceReadings(telemetryData.value, deviceType).value,
      };
    });
  }, [cache, deviceType]);

  const data: IChartPoint[] = useMemo(() => {
    if (!mainChartQuery.data || !mainChartQuery.data.chartData) {
      return [];
    }

    return mainChartQuery.data?.chartData?.map((telemetryData) => {
      return {
        timestamp: dateAdapter.date(telemetryData.eventDateTime)?.toDate().getTime() ?? 0,
        value: parseDeviceReadings(telemetryData.value, deviceType).value,
      };
    });
  }, [deviceType, mainChartQuery.data]);

  return useMemo(
    () => ({
      ticks: getChartTicks(mainChartQuery.data),
      data: mainChartQuery.isRefetching && cacheData ? cacheData : data,
      alertData: mainChartQuery.data?.alertData || [],
      isLoading: mainChartQuery.isLoading || !range,
      isFetching: mainChartQuery.isFetching,
    }),
    [
      mainChartQuery.data,
      mainChartQuery.isRefetching,
      mainChartQuery.isLoading,
      mainChartQuery.isFetching,
      cacheData,
      data,
      range,
    ]
  );
};
