import { IAlert } from '@marlin/alert/data-access/alert-action';
import { MarlinTheme } from '@marlin/shared/theme';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import { IconButton, Skeleton } from '@mui/material';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useDeviceDetailsContext } from '../../../context/device-details.context';
import { IFormattedReading } from '../hooks/use-readings.hook';
import { CurrentReadings } from './current-readings.component';
import { RangeFilterForm } from './range-filter-form.component';

interface IReadingsHeaderProps {
  isExtended: boolean;
  refreshData: () => void;
  isLoading: boolean;
  isAlert: boolean;
  recentReading: IFormattedReading | null;
  lowReading: IFormattedReading | null;
  highReading: IFormattedReading | null;
  isLeakUnitOfMeasure: boolean;
  communicationLostAlert?: IAlert;
}

interface IStyledProps {
  isExtended: boolean;
}

const useStyles = makeStyles<IStyledProps>()((theme: MarlinTheme, { isExtended }) => ({
  readingsHeader: {
    display: 'grid',
    gridTemplateAreas: isExtended
      ? `
      "currentReadings filterForm refreshButton"
    `
      : `
      "currentReadings refreshButton"
      "filterForm filterForm"
    `,
    gridTemplateColumns: isExtended ? `1fr minmax(0, ${theme.typography.pxToRem(334)}) auto` : '1fr auto',
    marginBottom: isExtended ? theme.typography.pxToRem(16) : 0,
    alignItems: 'flex-start',
    gap: `${theme.typography.pxToRem(16)} 0`,
  },
  filterForm: {
    gridArea: 'filterForm',
  },
  refreshButton: {
    gridArea: 'refreshButton',
  },
  currentReadings: {
    gridArea: 'currentReadings',
  },
}));

export const ReadingsHeader = ({
  isExtended,
  highReading,
  lowReading,
  isAlert,
  communicationLostAlert,
  recentReading,
  refreshData,
  isLoading,
  isLeakUnitOfMeasure,
}: IReadingsHeaderProps) => {
  const { classes } = useStyles({ isExtended });
  const deviceDetails = useDeviceDetailsContext();

  const batteryLevelDatapoint = useMemo(() => {
    return deviceDetails.lastReadingValues.find((value) => value.name === 'BatteryLevel');
  }, [deviceDetails.lastReadingValues]);

  const isActive = useMemo(() => {
    const eightHoursInMilliseconds = 8 * 60 * 60 * 1000;
    const eightHoursAgo = new Date(Date.now() - eightHoursInMilliseconds);
    if (!batteryLevelDatapoint) {
      return false;
    }
    const mostRecentReadingDatetime = new Date(batteryLevelDatapoint.lastReadingTime ?? new Date());
    return mostRecentReadingDatetime > eightHoursAgo;
  }, [batteryLevelDatapoint]);

  const showLostCommunicationMessage =
    !(recentReading && lowReading && highReading) && (communicationLostAlert || !isActive);

  return (
    <div className={classes.readingsHeader}>
      <div className={classes.filterForm}>
        <RangeFilterForm isExtended={isExtended} refreshData={refreshData} />
      </div>
      <div className={classes.refreshButton}>
        <IconButton aria-label="refresh-device-readings" onClick={refreshData} data-testid="refresh-button">
          <RefreshRoundedIcon />
        </IconButton>
      </div>
      <div className={classes.currentReadings}>
        {isLoading ? (
          <Skeleton variant={'rectangular'} height={'85px'} width={'520px'} />
        ) : (
          <CurrentReadings
            isAlert={isAlert}
            recentReading={recentReading}
            lowReading={lowReading}
            highReading={highReading}
            isLeakUnitOfMeasure={isLeakUnitOfMeasure}
            isExtended={isExtended}
            communicationLostAlert={communicationLostAlert}
            showLostCommunicationMessage={!!showLostCommunicationMessage}
          />
        )}
      </div>
    </div>
  );
};
