import { createDataTestId } from '@marlin/shared/utils/testing-utils';
import { z } from 'zod';

export const DynamicMetadataDatapoint = z
  .object({
    name: z.string(),
    label: z.string(),
    eventCodes: z.array(z.string()).nullish(),
  })
  .transform((datapoint) => ({ ...datapoint, eventCodes: datapoint.eventCodes ?? undefined }));

export const DynamicMetadataProductDetails = z.object({
  datapoints: z.array(DynamicMetadataDatapoint),
});

export const DynamicMetadataChart = z.object({
  datapointNames: z.array(z.string()),
  datapointGroupNames: z.array(z.string()),
});

export const DynamicMetadataSectionDatapoint = z.object({
  sectionName: z.string(),
  title: z.string(),
  items: z.array(DynamicMetadataDatapoint),
});

export const DynamicMetadataSection = z
  .object({
    title: z.string(),
    sectionDatapoints: z.array(DynamicMetadataSectionDatapoint),
  })
  .transform((section) => ({ ...section, dataTestId: createDataTestId('dynamic-section', section.title) }));

export const DynamicMetadataDashboardConfig = z.object({
  productDetails: DynamicMetadataProductDetails,
  chart: DynamicMetadataChart,
  sections: z.array(DynamicMetadataSection),
});

export type TDynamicMetadataDatapoint = z.infer<typeof DynamicMetadataDatapoint>;
export type TDynamicMetadataProductDetails = z.infer<typeof DynamicMetadataProductDetails>;
export type TDynamicMetadataChart = z.infer<typeof DynamicMetadataChart>;
export type TDynamicMetadataSectionDatapoint = z.infer<typeof DynamicMetadataSectionDatapoint>;
export type TDynamicMetadataSection = z.infer<typeof DynamicMetadataSection>;
export type TDynamicMetadataDashboardConfig = z.infer<typeof DynamicMetadataDashboardConfig>;
