import { useEquipmentDetails } from '@marlin/asset/data-access/equipment';
import { useAnalyticsTelemetry } from '@marlin/asset/data-access/telemetry';
import { TChartId } from '@marlin/asset/shared/equipment-config';
import {
  getNewBucketOption,
  isBucketOptionValid,
  onRangeFilterChange,
  onZoomChange,
} from '@marlin/asset/shared/ui/chart-options';
import { MarlinTheme } from '@marlin/shared/theme';
import {
  AVERAGING_FUNCTION_FILTER,
  IChartData,
  IChartSeries,
  RANGE_FILTER,
  TBucketOption,
  TChartDisplayType,
  TimeRangeToBucketOption,
  findMinMax,
  getDataWithoutMargins,
  getNumberOfBuckets,
  isAercoDevice,
  parseToChartData,
  removeEmptyChartData,
  useCustomPeriodModalContextStore,
  useDuration,
} from '@marlin/shared/utils-chart';
import { getFormattedValue } from '@marlin/shared/utils-format-reading';
import { useIdFromPathname } from '@marlin/shared/utils/url-params';
import { useTheme } from '@mui/material';
import moment from 'moment/moment';
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  flowRateToFlowVolumeDatapointNameMap,
  flowVolumeDatapointNames,
  flowVolumeToFlowRateDatapointNameMap,
} from '../const';
import { useDurationContext } from '../context/context-duration.context';
import { IChartMetadata } from '../types';
import { useMappedThresholdData } from './use-mapped-threshold-data.hook';

interface IUseChartData {
  datapointsWithMetadata: IChartMetadata;
  activeDatapoints: string[];
  activeThresholds: string[];
  chartId: TChartId;
  chartDisplayType: TChartDisplayType;
}

export const useChartData = ({
  datapointsWithMetadata: { datapointsMetadata, thresholdsMetadata },
  activeDatapoints,
  activeThresholds,
  chartId,
  chartDisplayType,
}: IUseChartData) => {
  const equipmentId = useIdFromPathname();
  const theme = useTheme<MarlinTheme>();
  const { data: { devices = [], model } = {} } = useEquipmentDetails({ equipmentId });
  const manufacturerId = useMemo(() => devices[0]?.manufacturerId, [devices]);
  const activeDatapointsLower = useMemo(() => activeDatapoints.map((name) => name.toLowerCase()), [activeDatapoints]);

  const activeDatapointsForRequestLower = useMemo(
    () =>
      chartId === 'flow' && chartDisplayType === 'bar'
        ? activeDatapointsLower.map(
            (item) =>
              new Map(
                [...flowRateToFlowVolumeDatapointNameMap].map(([flowRateDatapointName, flowVolumeDatapointName]) => [
                  flowRateDatapointName.toLowerCase(),
                  flowVolumeDatapointName.toLowerCase(),
                ])
              ).get(item) || item
          )
        : activeDatapointsLower,
    [activeDatapointsLower, chartDisplayType, chartId]
  );
  const activeThresholdsLower = useMemo(() => activeThresholds.map((name) => name.toLowerCase()), [activeThresholds]);

  const { rangeFilter, setDetails } = useDurationContext();

  const [fromDateModal] = useCustomPeriodModalContextStore((store) => store.fromDate);
  const [toDateModal] = useCustomPeriodModalContextStore((store) => store.toDate);

  const {
    fromDate: from,
    toDate: to,
    minSelection,
    maxSelection,
    handleZoomChange: handleDurationZoomChange,
    isZoomed,
    zoomedRange,
  } = useDuration({
    key: chartId,
    rangeFilter,
    setDetailsWithPrev: setDetails,
    fromDate: fromDateModal,
    toDate: toDateModal,
  });

  const [bucketOption, setBucketOption] = useState<TBucketOption | undefined>(
    chartDisplayType === 'bar' ? getNewBucketOption(maxSelection - minSelection) : undefined
  );

  const numberOfBuckets = useMemo(
    () =>
      getNumberOfBuckets({
        minSelection,
        maxSelection,
        rangeFilter: rangeFilter.range,
        shouldHaveSpecialCalculations: chartId === 'flow',
      }),
    [minSelection, maxSelection, rangeFilter.range, chartId]
  );

  const { data, isFetching, isLoading } = useAnalyticsTelemetry(
    {
      dateFrom: from,
      dateTo: to,
      requestedTelemetry: [
        {
          manufacturerId,
          datapoints: [...activeDatapointsForRequestLower, ...activeThresholdsLower],
        },
      ],
      numberOfBuckets,
      bucketOption,
      chartDisplayType,
      averagingFunctionFilter:
        chartId === 'flow' && chartDisplayType === 'bar'
          ? AVERAGING_FUNCTION_FILTER.SUM
          : AVERAGING_FUNCTION_FILTER.AVERAGE,
    },
    {
      select: (data) => {
        const clearedData =
          (rangeFilter.range === RANGE_FILTER.HOURS_8 || (zoomedRange && zoomedRange < 8 * 60 * 60 * 1000)) &&
          isAercoDevice(model)
            ? removeEmptyChartData({ data })
            : data;

        return {
          ...clearedData,
          telemetryData: clearedData.telemetryData.map(({ values, datapointName, ...rest }) => {
            return {
              ...rest,
              datapointName: flowVolumeDatapointNames.includes(datapointName)
                ? flowVolumeToFlowRateDatapointNameMap.get(datapointName) || datapointName
                : datapointName,
              values: activeThresholdsLower.includes(datapointName.toLowerCase())
                ? values.filter(({ x }) => x >= minSelection && x <= maxSelection)
                : values,
            };
          }),
        };
      },
    }
  );

  const datapointsRawData = useMemo(
    () => data?.telemetryData.filter((item) => activeDatapointsLower.includes(item.datapointName.toLowerCase())),
    [activeDatapointsLower, data?.telemetryData]
  );

  const thresholdsRawData = useMemo(
    () => data?.telemetryData.filter((item) => activeThresholdsLower.includes(item.datapointName.toLowerCase())),
    [activeThresholdsLower, data?.telemetryData]
  );

  const convertedDatapoints = useMemo(
    () => parseToChartData(datapointsRawData, chartDisplayType),
    [datapointsRawData, chartDisplayType]
  );

  const chartData = useMemo<IChartSeries[]>(() => {
    const convertedChartData = convertedDatapoints.map(({ datapointName, values, unitOfMeasure }) => {
      const datapointWithMetadata = datapointsMetadata.find(
        (item) => item.name.toLowerCase() === datapointName.toLowerCase()
      );

      return {
        name: datapointWithMetadata?.label ?? datapointName,
        data: values || [],
        color: datapointWithMetadata?.color ?? '#FBAC13',
        type: chartDisplayType,
        uom: unitOfMeasure ?? null,
        id: datapointName,
      };
    });

    return sortByOrder(convertedChartData, datapointsMetadata);
  }, [convertedDatapoints, datapointsMetadata, chartDisplayType]);

  const chartDataWithoutMargins = useMemo(
    () => getDataWithoutMargins(chartData, minSelection, maxSelection),
    [chartData, maxSelection, minSelection]
  );

  const thresholdsData = useMappedThresholdData({
    thresholdsData: thresholdsRawData,
    thresholdsMetadata,
    theme,
  });

  const { max, min } = useMemo(() => {
    const points = [
      ...chartData.reduce<IChartData[]>((acc, datapoint) => [...acc, ...datapoint.data], []),
      ...getThresholdValuesForMinMax(thresholdsData),
    ];

    return findMinMax({
      points,
      onlyThresholds: !chartData.length,
    });
  }, [chartData, thresholdsData]);

  const totalVolume = useMemo<string | undefined>(() => {
    if (chartId !== 'flow') return undefined;

    const uom = chartData.find((datapoint) => datapoint.data.length)?.uom;

    const total = chartData.reduce((acc, datapoint) => {
      datapoint.data.forEach((point) => {
        if (point.y && point.x >= minSelection && point.x <= maxSelection) {
          acc += point.y;
        }
      });

      return acc;
    }, 0);

    return getFormattedValue(String(total), uom);
  }, [chartData, chartId, maxSelection, minSelection]);

  const handleZoomChange = useCallback(
    (zoom?: { min: number; max: number }, bucketOption?: TBucketOption | undefined) => {
      handleDurationZoomChange(zoom);

      if (zoom?.min && zoom?.max && bucketOption) {
        const dateDifferenceInMinutes = moment(zoom?.max).diff(moment(zoom?.min), 'minutes');

        onZoomChange(bucketOption, setBucketOption, dateDifferenceInMinutes);
      }
    },
    [handleDurationZoomChange, setBucketOption]
  );

  const dateDifferenceInMinutes = useMemo(() => moment(to).diff(moment(from), 'minutes'), [from, to]);

  useEffect(() => {
    if (chartDisplayType !== 'bar') return;

    if (rangeFilter.range !== RANGE_FILTER.CUSTOM && !isZoomed) {
      const newBucketOption = TimeRangeToBucketOption.get(rangeFilter.range);
      if (!bucketOption || newBucketOption !== bucketOption) {
        setBucketOption(TimeRangeToBucketOption.get(rangeFilter.range));
      }
      return;
    }

    const shouldChangeBucketOption =
      !bucketOption || !isBucketOptionValid(bucketOption, rangeFilter.range, dateDifferenceInMinutes, !!isZoomed);

    if (rangeFilter.range === RANGE_FILTER.CUSTOM && bucketOption) {
      const newBucketOption = getNewBucketOption(dateDifferenceInMinutes, bucketOption);

      if (newBucketOption !== bucketOption) {
        setBucketOption(newBucketOption);
      }
      return;
    }

    if (!shouldChangeBucketOption || isZoomed) return;

    onRangeFilterChange(rangeFilter.range, bucketOption ?? '', setBucketOption);
  }, [bucketOption, chartDisplayType, dateDifferenceInMinutes, isZoomed, rangeFilter, setBucketOption]);

  return {
    chartData,
    chartDataWithoutMargins,
    thresholdsData,
    to: maxSelection,
    from: minSelection,
    isLoading,
    isFetching,
    max,
    min,
    handleZoomChange,
    isZoomed: !!isZoomed,
    totalVolume,
    bucketOption,
  };
};

const sortByOrder = (
  arrayToSort: IChartSeries[],
  arrayWithProperOrder: { name: string; isDefault: boolean; label: string; color: string }[]
): IChartSeries[] => {
  const properOrderedArray = arrayWithProperOrder.map((item) => item.label);

  return arrayToSort.sort((a, b) => properOrderedArray.indexOf(a.name) - properOrderedArray.indexOf(b.name));
};

const getThresholdValuesForMinMax = (thresholdsData: IChartSeries[]) => {
  let highestThreshold = 0;

  const getThresholdY = (y: number | null) => {
    if (y === 0) return -20;

    const multiplyFactor = y && y < highestThreshold ? 0.8 : 1.2;
    return y && !isNaN(y) ? y * multiplyFactor : y;
  };

  return thresholdsData.reduce<IChartData[]>(
    (acc, datapoint) => [
      ...acc,
      ...datapoint.data.map((datapoint) => {
        if (datapoint.y && datapoint.y > highestThreshold) {
          highestThreshold = datapoint.y;
        }

        return {
          x: datapoint.x,
          y: getThresholdY(datapoint.y),
        };
      }),
    ],
    []
  );
};
