import { EQUIPMENT_TYPE } from '@marlin/shared/utils/zod';
import { TNodeType } from '@marlin/system-map/shared/data-access-schemas';

import { content } from './content';
import { ASSET_TYPE, IAssetType } from './types';

export const equipmentModelOptions = (boiler: boolean, waterHeater: boolean, sentinel: boolean) => [
  {
    id: EQUIPMENT_TYPE.DIGITAL_MIXING_VALVE,
    name: content.INTELLISTATION_TYPE,
  },
  ...(boiler
    ? [
        {
          id: EQUIPMENT_TYPE.BOILER,
          name: content.AERCO_BENCHMARK_TYPE,
        },
      ]
    : []),
  ...(waterHeater
    ? [
        {
          id: EQUIPMENT_TYPE.HOT_WATER_HEATER,
          name: content.AERCO_INNOVATION_TYPE,
        },
      ]
    : []),
  ...(sentinel
    ? [
        {
          id: EQUIPMENT_TYPE.LEAK_DEFENSE_SYSTEM,
          name: content.SENTINEL_TYPE,
        },
      ]
    : []),
  {
    id: EQUIPMENT_TYPE.NONE,
    name: content.OTHER,
  },
];

export const assetTypeOptions: IAssetType[] = [
  {
    id: ASSET_TYPE.LOCATION,
    name: content.ASSET_LOCATION,
  },
  {
    id: ASSET_TYPE.EQUIPMENT,
    name: content.ASSET_EQUIPMENT,
  },
  {
    id: ASSET_TYPE.DEVICE,
    name: content.ASSET_SENSOR,
  },
];

export const mapAssetGroup = (assetType: ASSET_TYPE | TNodeType): string => {
  if (assetType === ASSET_TYPE.EQUIPMENT) {
    return content.GROUP_EQUIPMENT;
  }

  if (assetType === ASSET_TYPE.DEVICE) {
    return content.GROUP_SENSOR;
  }

  if (assetType === ASSET_TYPE.LOCATION) {
    return content.GROUP_LOCATION;
  }

  if (assetType === 'ASSET') {
    return content.GROUP_ASSET;
  }

  if (assetType === 'ATTACHMENT') {
    return content.GROUP_ATTACHMENT;
  }

  if (assetType === 'TEE') {
    return content.GROUP_TEE;
  }

  return '';
};
