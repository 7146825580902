import { z } from 'zod';

export const sankeyParamsSchema = z.object({
  periodStart: z.string(),
  periodEnd: z.string(),
});

export const sourceSchema = z.string();
export const targetSchema = z.string();
export const deviceSchema = z.object({
  id: z.string(),
  manufacturerId: z.string(),
  name: z.string(),
  commissionDate: z.string(),
  volume: z.number(),
});

export const sankeyResponseSchema = z.object({
  sources: z.array(sourceSchema),
  targets: z.array(targetSchema),
  devices: z.array(deviceSchema),
});

export type TSankeyParams = z.infer<typeof sankeyParamsSchema>;
export type TSankeyResponse = z.infer<typeof sankeyResponseSchema>;
export type TSankeyDevice = z.infer<typeof deviceSchema>;
