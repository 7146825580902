import { CriticalityControl } from '@marlin/shared/ui-criticality';
import { timeSince } from '@marlin/shared/utils-common-date';
import { DEVICE_TYPE, DEVICE_TYPE_RESPONSE, ERROR_TYPES } from '@marlin/shared/utils/format-alert';
import { Divider } from '@mui/material';

import { content } from '../content';
import { useRouter } from '../hooks/use-router.hook';
import { TTopOpenAlert, getTopAlertTitle } from '../utils';
import { useStyles } from './top-alerts-card-item.component.style';

type TTopAlertsCardItemProps = {
  alert: TTopOpenAlert;
  alertErrorType: ERROR_TYPES;
  alertDeviceType: DEVICE_TYPE;
  equipmentId?: string;
};

const TopAlertsCardItem = ({ alert, alertErrorType, alertDeviceType, equipmentId }: TTopAlertsCardItemProps) => {
  const { classes } = useStyles();
  const { goToAlertsPageWithId } = useRouter();

  const onClick = () => {
    goToAlertsPageWithId({ alertDeviceId: alert.deviceId, alertDeviceName: alert.deviceName });
  };

  return (
    <div className={classes.card} onClick={onClick} data-testid="alerts-section">
      <div className={classes.criticality} data-testid="alerts-criticality">
        <CriticalityControl severity={alert.criticality} />
      </div>
      <div className={classes.text} data-testid="alerts-text">
        <p>
          {getTopAlertTitle(
            alert,
            alertErrorType,
            alertDeviceType,
            Boolean(equipmentId) || alert.deviceType === DEVICE_TYPE_RESPONSE.EQUIPMENT,
            true
          )}
        </p>
      </div>
      <Divider orientation="vertical" flexItem className={classes.divider} />
      <div className={classes.time} data-testid="alerts-time">
        <p className={classes.timeText}>{content.TIME_IN_ALERT}</p>
        <p className={classes.timeValue}>{timeSince(alert.startTime)}</p>
      </div>
    </div>
  );
};

export { TopAlertsCardItem };
