import { Box, Modal, useMediaQuery, useTheme } from '@mui/material';

import { Booking } from '../booking-modal/booking.component';
import { TEASER_MODAL_ACTION_TYPE } from '../teaser-modal-provider/types';
import { useTeaserModal } from '../teaser-modal-provider/use-teaser-modal';
import { BookDemoSide } from './book-demo-side/book-demo-side.component';
import { Slider } from './slider/slider.component';
import { useStyles } from './teaser-modal.styles';

export const TeaserModal = () => {
  const { classes } = useStyles();
  const {
    modalDispatch,
    modalState: { teaserModalVisible, bookingModalVisible, slide },
  } = useTeaserModal();

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const handleClose = () => {
    modalDispatch({
      type: TEASER_MODAL_ACTION_TYPE.DISMISS,
    });
  };

  if (!teaserModalVisible && !bookingModalVisible) {
    return null;
  }

  if (bookingModalVisible) {
    return <Booking />;
  }

  return (
    <Modal
      open={true}
      onClose={handleClose}
      data-testid="teaser-modal-root"
      aria-labelledby="modal-teaser-title"
      aria-describedby="modal-teaser-modal-description"
    >
      <Box className={classes.container} data-testid="teaser-modal-body">
        <div className={classes.item}>
          <BookDemoSide />
        </div>
        {!isMobile && (
          <div className={classes.item}>
            <Slider slide={slide} />
          </div>
        )}
      </Box>
    </Modal>
  );
};
