import { z } from 'zod';

import { paginationSchema } from './pagination.schema';

export enum ASSET_DEVICE_TYPE {
  TEMPERATURE = 'Temperature',
  PRESSURE = 'Pressure',
  LEAK = 'Leak',
  UNKNOWN = 'UNKNOWN',
}

export const assetDeviceParamsSchema = z.object({
  search: z.string().optional(),
  location: z.string().optional(),
  equipment: z.string().optional(),
  deviceType: z.nativeEnum(ASSET_DEVICE_TYPE),
  page: z.number(),
  pageSize: z.number(),
});

const assetDeviceSchema = z.object({
  id: z.string(),
  name: z.string(),
  deviceType: z.nativeEnum(ASSET_DEVICE_TYPE),
});

export const assetDeviceResponseSchema = z.object({
  pagination: paginationSchema,
  data: z.array(assetDeviceSchema).nullable(),
});

export type TAssetDeviceParamsSchema = z.infer<typeof assetDeviceParamsSchema>;
export type TAssetDeviceResponseSchema = z.infer<typeof assetDeviceResponseSchema>;
