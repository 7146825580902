export enum ALERT_STATUS {
  FAILED = 'FAILED',
  SENT = 'SENT',
  SNOOZED = 'SNOOZED',
  IN_PROGRESS = 'IN_PROGRESS',
}

export type TChannelType = 'SMS' | 'EMAIL';

export interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  status?: ALERT_STATUS | null;
}

export enum CHANNEL_TYPE {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  HOT_SOS = 'HOT_SOS',
}
