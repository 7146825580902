import { MouseEvent, useCallback, useState } from 'react';

interface IUseMenu {
  menuAnchor: null | HTMLElement;
  isMenuOpen: boolean;
  open: (event: MouseEvent<HTMLButtonElement>) => void;
  close: (event: MouseEvent<HTMLButtonElement>) => void;
}

export const useMenu = (): IUseMenu => {
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const isMenuOpen = !!menuAnchor;
  const openMenu = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setMenuAnchor(event.currentTarget);
    },
    [setMenuAnchor]
  );
  const closeMenu = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (event) {
        event.stopPropagation();
      }
      setMenuAnchor(null);
    },
    [setMenuAnchor]
  );

  return {
    menuAnchor,
    isMenuOpen,
    open: openMenu,
    close: closeMenu,
  };
};
