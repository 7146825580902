import { EQUIPMENT_TYPE } from '@marlin/shared/utils/zod';
import { z } from 'zod';

type TNodeType = 'ASSET' | 'ATTACHMENT' | 'TEE';

export enum ASSET_TYPE {
  LOCATION = 'LOCATION',
  EQUIPMENT = 'EQUIPMENT',
  DEVICE = 'DEVICE',
}

export enum NODE_TYPE {
  INLET = 'inlet',
  OUTLET = 'outlet',
}

export interface IAsset {
  id: string;
  name: string;
  type: ASSET_TYPE;
}

export interface IFormAsset {
  id: string;
  name: string;
  type?: ASSET_TYPE;
  nodeType: TNodeType;
}

export interface IAssetType {
  id: ASSET_TYPE | '';
  name: string;
}

export interface IFlowLink {
  inlet?: IFormAsset;
  outlet?: IFormAsset;
}

export interface IAssetFilter {
  filter?: ASSET_TYPE;
  search?: string;
  equipmentType?: EQUIPMENT_TYPE;
}

const tagSchema = z.object({
  name: z.string().nullish(),
  value: z.string().nullish(),
});

export type TTag = z.infer<typeof tagSchema>;
