import { ModalMedium } from '@marlin/shared/ui-modal';
import { Button } from '@mui/material';

import { content } from './content';
import { useStyles } from './pin-device-modal.component.styles';

export interface IUnpinDeviceModal {
  onCancel: () => void;
  onSubmit: () => void;
  isOpen: boolean;
}

export const UnpinDeviceModal = ({ onCancel, onSubmit, isOpen }: IUnpinDeviceModal) => {
  const { classes } = useStyles();

  if (!isOpen) {
    return null;
  }

  return (
    <ModalMedium
      title={content.CONFIRM_UNPIN_SENSOR_TITLE}
      onClose={onCancel}
      body={
        <>
          <div>{content.CONFIRM_UNPIN_SENSOR_TEXT}</div>
          <br />
          <div className={classes.confirmText}>{content.CONFIRM_UNPIN_SENSOR_QUESTION}</div>
        </>
      }
      footer={
        <>
          <Button className={classes.cancelButton} variant="text" onClick={onCancel}>
            {content.CONFIRM_UNPIN_SENSOR_CANCEL_BUTTON}
          </Button>
          <Button className={classes.confirmButton} variant="contained" color="primary" onClick={onSubmit}>
            {content.CONFIRM_UNPIN_SENSOR_PIN_BUTTON}
          </Button>
        </>
      }
    />
  );
};
