import { TDeviceSchema } from '@marlin/asset/data-access/device';
import { ISortParams } from '@marlin/asset/shared/ui/mobile-filter-modal';

import { content } from '../content';

export type TDeviceSort = keyof TDeviceSchema;

export const sortList: ISortParams<TDeviceSort>[] = [
  {
    id: 'name',
    name: content.SENSOR_SORTING_NAME,
  },
  {
    id: 'locationName',
    name: content.LOCATION_SORTING_NAME,
  },
  {
    id: 'equipmentName',
    name: content.EQUIPMENT_SORTING_NAME,
  },
  {
    id: 'deviceType',
    name: content.SENSOR_TYPE_LABEL,
  },
];
