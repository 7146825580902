import { MarlinTheme } from '@marlin/shared/theme';
import { defaultDate } from '@marlin/shared/utils-common-date';
import { getUomSymbol } from '@marlin/shared/utils-format-reading';
import { makeStyles } from 'tss-react/mui';

import { transformBigNumber } from '../utils';
import { ITrendBar } from './types';

interface IStyles {
  color: string;
  widthPercentage: number;
}

export const useStyles = makeStyles<IStyles>()((theme: MarlinTheme, { color, widthPercentage }) => ({
  container: {
    width: '100%',
  },
  valueRow: {
    display: 'flex',
    gap: theme.typography.pxToRem(8),
    alignItems: 'baseline',
  },
  value: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightBold,
  },
  uom: {
    fontSize: theme.typography.pxToRem(14),
  },
  dateRow: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.alternative.light,
    paddingLeft: theme.typography.pxToRem(4),
    paddingRight: theme.typography.pxToRem(4),
    borderRadius: theme.typography.pxToRem(4),
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  coloredBar: {
    width: `${widthPercentage}%`,
    height: '100%',
    backgroundColor: color,
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 0,
  },
  label: {
    position: 'relative',
    zIndex: 1,
  },
}));

interface ITrendBarProps {
  trend: ITrendBar;
  color: string;
}

export const TrendBar = ({ trend, color }: ITrendBarProps) => {
  const { classes } = useStyles({ color, widthPercentage: trend.widthPercentage });

  return (
    <div className={classes.container}>
      <div className={classes.valueRow}>
        <div className={classes.value}>{transformBigNumber(trend.value)}</div>
        <div className={classes.uom}>{getUomSymbol(trend.uom)}</div>
      </div>
      <div className={classes.dateRow}>
        <div className={classes.coloredBar} />
        <span className={classes.label}>
          {trend.start?.format(defaultDate)}-{trend.end?.format(defaultDate)}
        </span>
      </div>
    </div>
  );
};
