import { TDateString, getMinutesFromNow } from '@marlin/shared/utils-common-date';

import { content } from './content';

export const getLastReadingText = (lastReading: TDateString): string => {
  const minutes = getMinutesFromNow(lastReading);

  if (!minutes) {
    return content.LAST_READING.MINUTES(0);
  }

  if (minutes >= 60) {
    const hours = Math.floor(minutes / 60);
    const minute = minutes % 60;
    return content.LAST_READING.HOURS(hours, minute);
  }

  return content.LAST_READING.MINUTES(minutes < 0 ? 0 : minutes);
};
