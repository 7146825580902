import { zodResolver } from '@hookform/resolvers/zod';
import { TUser } from '@marlin/account-data-access-organization';
import {
  EmailControl,
  FirstNameControl,
  JobTitleControl,
  LastNameControl,
  PhoneNumberControl,
  RoleControl,
} from '@marlin/shared/ui-form';
import { Button, Grid } from '@mui/material';
import omit from 'lodash/omit';
import { FormProvider, UseFormReturn, useForm } from 'react-hook-form';

import { content } from '../content';
import { UpsertProfileFormValidationSchema } from './user-profile-upsert-form.schema';
import { useStyles } from './user-profile-upsert-form.styles';

type TUserForm = Omit<TUser, 'id'>;

interface IUserUpdateFormProps {
  user: TUser | undefined;
  onSubmit: (data: TUserForm) => void;
  onCancel: (form: UseFormReturn<TUserForm>) => void;
}

export function UserUpdateForm({ user, onSubmit, onCancel }: IUserUpdateFormProps) {
  const form = useForm<TUserForm>({
    defaultValues: omit(user, ['id']),
    mode: 'onTouched',
    resolver: zodResolver(UpsertProfileFormValidationSchema),
  });
  const { classes } = useStyles();

  const handleCancel = () => {
    onCancel(form);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <FirstNameControl<TUserForm> fieldName="firstName" />
          </Grid>

          <Grid item xs={12}>
            <LastNameControl<TUserForm> fieldName="lastName" />
          </Grid>

          <Grid item xs={12}>
            <EmailControl<TUserForm> fieldName="email" disabled={true} />
          </Grid>

          <Grid item xs={12}>
            <PhoneNumberControl<TUserForm> fieldName="phone" />
          </Grid>

          <Grid item xs={12}>
            <JobTitleControl<TUserForm> fieldName="title" />
          </Grid>

          <Grid item xs={12}>
            <RoleControl<TUserForm> fieldName="role" />
          </Grid>
        </Grid>

        <Grid container spacing={2} className={classes.container}>
          <Grid item xs={12} md={6}>
            <Button fullWidth variant="outlined" data-testid="upsert-user-profile-cancel-button" onClick={handleCancel}>
              {content.BUTTONS.CANCEL}
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              fullWidth
              data-testid="upsert-user-profile-confirm-button"
              variant="contained"
              disabled={!form.formState.isDirty}
              onClick={form.handleSubmit(onSubmit)}
            >
              {content.BUTTONS.DONE}
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}
