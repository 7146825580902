import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    margin: theme.typography.pxToRem(48),
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    userSelect: 'none',
  },

  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },

  header: {
    margin: 0,
    marginBottom: theme.typography.pxToRem(25),
    justifySelf: 'flex-start',
  },

  logo: {
    marginRight: theme.typography.pxToRem(25),
    '& > svg': {
      height: theme.typography.pxToRem(40),
    },
  },

  textLogo: {
    '& > svg': {
      height: theme.typography.pxToRem(55),
    },
  },

  body: {
    margin: 0,
    marginTop: theme.typography.pxToRem(15),
    maxWidth: theme.typography.pxToRem(600),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(18),
  },

  goBackButton: {
    margin: 0,
    marginTop: theme.typography.pxToRem(80),
    minWidth: theme.typography.pxToRem(250),
  },

  goBackLink: {
    textDecoration: 'none',
  },
}));
