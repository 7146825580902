import { useStore } from '@xyflow/react';

export enum ZOOM_LEVEL {
  DEFAULT,
  DETAILED,
  MAX,
}

export const useContextualZoom = () => {
  const variant = useStore((reactFlow) => {
    const currentZoomLevel = reactFlow.transform[2];
    if (currentZoomLevel < 0.5) {
      return ZOOM_LEVEL.DEFAULT;
    }
    if (currentZoomLevel >= 0.5 && currentZoomLevel < 1.2) {
      return ZOOM_LEVEL.DETAILED;
    }
    if (currentZoomLevel >= 1.2) {
      return ZOOM_LEVEL.MAX;
    }
    return ZOOM_LEVEL.DEFAULT;
  });

  return {
    variant,
  };
};
