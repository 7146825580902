import { MarlinTheme } from '@marlin/shared/theme';
import { ButtonProps } from '@mui/material';
import { CSSInterpolation } from '@mui/styled-engine';
import { alpha } from '@mui/system';

type TButtonTypes = `${ButtonProps['color']}_${ButtonProps['variant']}`;

const createContainButtonColors = (color: string) => {
  return {
    backgroundColor: alpha(color, 0.08),
    color: alpha(color, 0.5),
  };
};

const createOutlinedButtonColors = (color: string) => {
  return {
    color: alpha(color, 0.5),
    borderColor: alpha(color, 0.3),
  };
};

const createTextButtonColors = (color: string) => {
  return {
    color: alpha(color, 0.5),
  };
};

export const createDisabledButtonColors = (theme: MarlinTheme): Record<TButtonTypes, CSSInterpolation> => {
  const disabledButtonColors: Record<TButtonTypes, CSSInterpolation> = {
    error_contained: { ...createContainButtonColors(theme.palette.error.main) },
    error_outlined: { ...createOutlinedButtonColors(theme.palette.error.main) },
    error_text: { ...createTextButtonColors(theme.palette.error.main) },
    info_contained: {
      ...createContainButtonColors(theme.palette.info.main),
    },
    info_outlined: {
      ...createOutlinedButtonColors(theme.palette.info.main),
    },
    info_text: {
      ...createTextButtonColors(theme.palette.info.main),
    },
    inherit_contained: { ...createContainButtonColors(theme.palette.alternative.main) },
    inherit_outlined: { ...createOutlinedButtonColors(theme.palette.alternative.main) },
    inherit_text: { ...createTextButtonColors(theme.palette.alternative.main) },
    primary_contained: {
      ...createContainButtonColors(theme.palette.primary.main),
    },
    primary_outlined: {
      ...createOutlinedButtonColors(theme.palette.primary.main),
    },
    primary_text: {
      ...createTextButtonColors(theme.palette.primary.main),
    },
    secondary_contained: {
      ...createContainButtonColors(theme.palette.secondary.main),
    },
    secondary_outlined: {
      ...createOutlinedButtonColors(theme.palette.secondary.main),
    },
    secondary_text: {
      ...createTextButtonColors(theme.palette.secondary.main),
    },
    success_contained: {
      ...createContainButtonColors(theme.palette.success.main),
    },
    success_outlined: {
      ...createOutlinedButtonColors(theme.palette.success.main),
    },
    success_text: {
      ...createTextButtonColors(theme.palette.success.main),
    },
    warning_contained: {
      ...createContainButtonColors(theme.palette.warning.main),
    },
    warning_outlined: {
      ...createOutlinedButtonColors(theme.palette.warning.main),
    },
    warning_text: {
      ...createTextButtonColors(theme.palette.warning.main),
    },
    undefined_undefined: {
      ...createContainButtonColors(theme.palette.primary.main),
    },
    undefined_text: {
      ...createTextButtonColors(theme.palette.primary.main),
    },
    undefined_outlined: {
      ...createOutlinedButtonColors(theme.palette.primary.main),
    },
    undefined_contained: {
      ...createContainButtonColors(theme.palette.primary.main),
    },
    inherit_undefined: { ...createContainButtonColors(theme.palette.alternative.main) },
    primary_undefined: {
      ...createContainButtonColors(theme.palette.primary.main),
    },
    secondary_undefined: {
      ...createContainButtonColors(theme.palette.secondary.main),
    },
    success_undefined: {
      ...createContainButtonColors(theme.palette.success.main),
    },
    error_undefined: {
      ...createContainButtonColors(theme.palette.error.main),
    },
    info_undefined: {
      ...createContainButtonColors(theme.palette.info.main),
    },
    warning_undefined: {
      ...createContainButtonColors(theme.palette.warning.main),
    },
  };

  return disabledButtonColors;
};
