import { queryClient } from '@marlin/shared/utils/react-query';
import { UseMutationResult, useMutation } from '@marlin/shared/utils/react-query';

import { TGraph } from '../flow-map.model.schema';
import { ICreateSystemMapNode, createSystemMapNode } from '../infrastructure/create-node';
import { queryKey } from './query-key.enum';

export const useCreateNode = (): UseMutationResult<TGraph, unknown, ICreateSystemMapNode> => {
  return useMutation<TGraph, unknown, ICreateSystemMapNode>({
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: queryKey.FLOW_LINK() });
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: queryKey.FLOW_LINK() });
      queryClient.invalidateQueries({ queryKey: queryKey.FLOW_LINKS() });
      queryClient.setQueryData(queryKey.GRAPH(), data);
    },
    mutationFn: createSystemMapNode,
  });
};
