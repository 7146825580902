import { IAssetFilter, TNode, useNodes } from '@marlin/system-map/data-access/system-map';
import debounce from 'lodash/debounce';
import { useCallback, useMemo, useState } from 'react';

export enum ASSET_TYPE {
  LOCATION = 'LOCATION',
  EQUIPMENT = 'EQUIPMENT',
  DEVICE = 'DEVICE',
}

export enum NODE_TYPE {
  INLET = 'inlet',
  OUTLET = 'outlet',
}

export interface IAsset {
  id: string;
  name: string;
  type: ASSET_TYPE;
}
export const debounceTimeInMs = 300;

interface IUseFlowNodeSelect {
  assets: TNode[];
  onAssetFilterChange: (node: NODE_TYPE, assetFilter: IAssetFilter) => void;
}

export const useFlowNodeSelect = (): IUseFlowNodeSelect => {
  const [assetFilter, setAssetFilter] = useState({});

  const nodesQuery = useNodes(assetFilter, !!Object.keys(assetFilter).length);

  const debouncedAssetFilter = useMemo(
    () =>
      debounce((value: IAssetFilter) => {
        setAssetFilter(value);
      }, debounceTimeInMs),
    []
  );

  const onAssetFilterChange = useCallback(
    (node: NODE_TYPE, assetFilter: IAssetFilter) => {
      debouncedAssetFilter(assetFilter);
    },
    [debouncedAssetFilter]
  );

  return {
    assets: nodesQuery?.data ?? [],
    onAssetFilterChange,
  };
};
