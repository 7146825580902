import { getHttpClient, queryClient } from '@marlin/shared/utils/react-query';
import { useMutation } from '@marlin/shared/utils/react-query';
import { z } from 'zod';

import { GatewayPayload, TGatewayPayload } from '../gateway.model.schema';
import { paths } from '../infrastructure/api';
import { queryKey } from '../query-key.enum';

export type TCreateGatewayType = {
  data: TGatewayPayload;
};

export const createGateway = async ({ data }: TCreateGatewayType) => {
  const parsedData = GatewayPayload.parse(data);

  await getHttpClient().post(paths.GATEWAY_REGISTRATION, parsedData);
};

interface IUseCreateRegisteredGateway {
  onSuccess?: (payload: TCreateGatewayType) => void;
}

export const useCreateRegisteredGateway = ({ onSuccess }: IUseCreateRegisteredGateway) => {
  return useMutation({
    onSuccess: (data, payload) => {
      queryClient.invalidateQueries({ queryKey: queryKey.GATEWAY_FILTER() });

      onSuccess && onSuccess(payload);
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      if (error instanceof z.ZodError) console.log(error.issues);
    },
    mutationFn: createGateway,
  });
};
