import { ApiId, AuthenticationResult, IPublicClientApplication, NavigationOptions } from '@azure/msal-browser';
import { ServerError } from '@azure/msal-common';
import { Observable, Subject, Subscription, from, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { IStorageService, TIIdentityClaims } from '../models/auth.model';
import { ERROR_CODE, RESPONSE_CODE, STATUS_OPTION, STATUS_TYPE } from '../models/constants';
import { TAccountInfo } from '../models/facade.model';
import { getUrlWithoutAuthParams } from '../utils/status';
import { appendQueryString } from '../utils/url';
import { AccountService } from './account.service';
import { ConfigService } from './config.service';
import { LocationStateService } from './location-state.service';
import { navigationClientService } from './navigation-client.service';
import { RefreshTokenService } from './refresh-token.service';
import { RequestService } from './request.service';
import { TokenService } from './token.service';

interface IAuthService<T extends TIIdentityClaims = TIIdentityClaims> {
  initLoginFlow(redirectUrl: string): Observable<boolean>;
  logOut(): void;
  getIdentityClaims(): T | null;
}

export class AuthService<T extends TIIdentityClaims = TIIdentityClaims> implements IAuthService {
  private readonly unsubscribe$ = new Subject();

  public constructor(
    private readonly authService: IPublicClientApplication,
    private readonly accountService: AccountService<T>,
    private readonly requestService: RequestService,
    private readonly tokenService: TokenService,
    private readonly refreshTokenService: RefreshTokenService,
    private readonly configService: ConfigService,
    private readonly storageService: IStorageService,
    private readonly locationStateService: LocationStateService
  ) {}

  public readonly initLoginFlow = (): Observable<boolean> => this.configureAndTryLogin();

  public readonly getIdentityClaims = (): T | null => this.accountService.getIdentityClaims();

  public readonly getCurrentAccount = (): TAccountInfo<T> | null => this.accountService.getCurrentAccount();

  public readonly getAccessToken = (): Observable<string | undefined> => {
    return from(this.tokenService.acquireToken()).pipe(
      map((resp: AuthenticationResult | void): string | undefined =>
        this.isAuthenticationResult(resp) ? resp.accessToken : undefined
      ),
      catchError(() => of(undefined)),
      takeUntil(this.unsubscribe$)
    );
  };

  public readonly logOut = (path?: string): void => {
    const clientId = this.authService.getConfiguration().auth.clientId;
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
    this.refreshTokenService.clear();
    this.authService.setActiveAccount(null);
    this.storageService.clear();
    this.locationStateService.saveLocationState(path);
    this.authService.logoutRedirect({ extraQueryParameters: { client_id: clientId } }).then(null);
  };

  public readonly subscribeToLogout = (complete: () => void): Subscription => {
    return this.unsubscribe$.subscribe({
      complete,
    });
  };

  private readonly configureAndTryLogin = (): Observable<boolean> => {
    return new Observable<boolean>((subscriber) => {
      this.refreshTokenService.initialize();
      subscriber.next(false);
    }).pipe(
      switchMap(() => this.handleRedirectPromise()),
      switchMap((resp) => this.logIn(resp)),
      map((resp: AuthenticationResult | void) => !!this.isAuthenticationResult(resp)),
      catchError(() => of(false)),
      tap((isAuthorized: boolean): void => {
        if (isAuthorized) {
          this.locationStateService.restoreLocationState();
        }
      })
    );
  };

  private readonly handleRedirectPromise = (): Observable<AuthenticationResult | null> => {
    return from(this.authService.handleRedirectPromise()).pipe(
      catchError((error: unknown) => {
        if (this.isUserCancellationError(error)) {
          return this.redirectWithoutErrorCode();
        } else if (this.isRedirectError(error)) {
          return this.redirectWithErrorCode();
        } else if (this.authService.getAllAccounts()?.length) {
          return of(null);
        }

        throw error;
      })
    );
  };

  private readonly logIn = (
    authenticationResult: AuthenticationResult | null
  ): Observable<AuthenticationResult | void> => {
    if (authenticationResult && !!authenticationResult.accessToken?.length) {
      if (authenticationResult.account) {
        this.authService.setActiveAccount(authenticationResult.account);
      }

      return of(authenticationResult);
    }

    if (this.authService.getAllAccounts()?.length < 1) {
      return from(this.authService.loginRedirect(this.requestService.getRedirectRequest()));
    }

    return this.tokenService.acquireToken();
  };

  private readonly isAuthenticationResult = (resp: AuthenticationResult | void): resp is AuthenticationResult => !!resp;

  private readonly isUserCancellationError = (error: unknown): boolean => {
    return !!(
      error instanceof ServerError &&
      error.errorCode === ERROR_CODE.ACCESS_DENIED &&
      error.errorMessage &&
      error.errorMessage.startsWith(RESPONSE_CODE.USER_CANCELLATION)
    );
  };

  private readonly isRedirectError = (error: unknown): boolean => {
    const accessDeniedError = !!(
      error instanceof ServerError &&
      error.errorCode === ERROR_CODE.ACCESS_DENIED &&
      error.errorMessage &&
      !error.errorMessage.startsWith(RESPONSE_CODE.USER_CANCELLATION)
    );
    const accountNotFoundForProvidedUserIdError = !!(
      error instanceof ServerError &&
      error.errorMessage.startsWith(RESPONSE_CODE.ACCOUNT_NOT_FOUND_FOF_PROVIDED_USER_ID)
    );

    return accessDeniedError || accountNotFoundForProvidedUserIdError;
  };

  private readonly redirectWithErrorCode = (): Observable<null> => {
    const url = appendQueryString(window.location.href, STATUS_TYPE.B2C, STATUS_OPTION.ERROR);
    const navigationOptions: NavigationOptions = {
      apiId: ApiId.handleRedirectPromise,
      timeout: this.authService.getConfiguration().system.redirectNavigationTimeout,
      noHistory: true,
    };

    return of(navigationClientService.navigateInternal(url, navigationOptions)).pipe(map(() => null));
  };

  private readonly redirectWithoutErrorCode = (): Observable<null> => {
    const url = getUrlWithoutAuthParams();
    let baseUrl = this.configService.get().baseUrl;
    baseUrl = baseUrl.endsWith('/') ? baseUrl.slice(0, -1) : baseUrl;
    baseUrl = baseUrl.startsWith('/') ? baseUrl.substring(1) : baseUrl;
    const fullUrl = baseUrl.length ? `/${baseUrl}${url.startsWith('/') ? url : `/${url}`}` : url;
    const navigationOptions: NavigationOptions = {
      apiId: ApiId.handleRedirectPromise,
      timeout: this.authService.getConfiguration().system.redirectNavigationTimeout,
      noHistory: true,
    };

    return of(navigationClientService.navigateInternal(fullUrl, navigationOptions)).pipe(map(() => null));
  };
}
