import { environment } from '@marlin/environment';

export const content = {
  HELP_HUB_TITLE: 'Help',
  HELP_HUB_SUBTITLE: 'Need Help or have a question for our team? Please fill out our customer feedback form below.',
  CUSTOMER_FEEDBACK: 'Customer feedback',
  DESCRIBE_INQUIRY: `Please describe your inquiry to the ${environment.app.name} team. We will get back to you as soon as possible regarding your message.`,
  SEND: 'Send',
  CANCEL: 'Cancel',
  NAME: 'Name',
  DESCRIPTION: 'Description Of Inquiry',
  ISSUE_CATEGORY: 'Issue Category',
  CATEGORIES: {
    SENSOR_ISSUE: 'Sensor Issue',
    SOFTWARE_ISSUE: 'Software Issue',
    SALES_QUESTION: 'Sales Question',
    NEW_FEATURE_REQUEST: 'New Feature Request',
    OTHER_QUESTION: 'Other Question',
  },
  REQUIRED: 'This is a required field',
  MIN_MAX: 'Must be between 3 and 200 characters',
  MESSAGE_SUCCESS: 'Your message has been sent',
  MESSAGE_ERROR: 'Something went wrong.',
  SEND_SUCCESS: {
    TITLE: 'Thank you for reaching out!',
    SUBTITLE: 'We appreciate your valuable input and will review it carefully.',
    TEXT_START: 'Our team will get back to you via ',
    TEXT_END: ' as soon as possible regarding your message.',
    BUTTON: 'New Feedback',
  },
};
