import { FormField, IFormControlProps, IFormTypedProps, Input } from '@marlin/shared/ui-form-common';
import { useCallback } from 'react';
import { ControllerProps, FieldValues } from 'react-hook-form';
import { ControllerRenderProps } from 'react-hook-form/dist/types/controller';

import { content } from '../content';

interface IEmailControlProps {
  handleOnBlur?: ControllerRenderProps['onBlur'];
}

export function EmailControl<TFieldValues extends FieldValues>(
  props: IFormControlProps<TFieldValues> & IEmailControlProps
): JSX.Element;
export function EmailControl<
  TFieldValues extends FieldValues = object,
  TName extends ControllerProps<TFieldValues>['name'] = ControllerProps<TFieldValues>['name']
>(props: IFormTypedProps<TFieldValues, TName> & IEmailControlProps): JSX.Element;

export function EmailControl<
  TFieldValues extends FieldValues = object,
  TName extends ControllerProps<TFieldValues>['name'] = ControllerProps<TFieldValues>['name']
>({
  handleOnBlur,
  ...params
}: (IFormControlProps<TFieldValues> | IFormTypedProps<TFieldValues, TName>) & IEmailControlProps) {
  const onBlurFactory = useCallback(
    (formOnBlur?: ControllerRenderProps['onBlur']) => () => {
      handleOnBlur && handleOnBlur();
      formOnBlur && formOnBlur();
    },
    [handleOnBlur]
  );

  if (params.control) {
    return (
      <FormField {...params}>
        {(props) => (
          <Input
            label={content.EMAIL_CONTROL_LABEL}
            placeholder={content.EMAIL_CONTROL_LABEL}
            required
            testId={props.name}
            {...props}
            className={params.className}
            disabled={params.disabled}
            onBlur={onBlurFactory(props.onBlur)}
          />
        )}
      </FormField>
    );
  }

  return (
    <FormField<TFieldValues> {...params}>
      {(props) => (
        <Input
          label={content.EMAIL_CONTROL_LABEL}
          placeholder={content.EMAIL_CONTROL_LABEL}
          required
          testId={props.name}
          {...props}
          className={params.className}
          disabled={params.disabled}
          onBlur={onBlurFactory(props.onBlur)}
        />
      )}
    </FormField>
  );
}
