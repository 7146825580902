import { getHttpClient } from '@marlin/shared/utils/react-query';

import { paths } from './api';
import {
  TExportTotalTelemetryResponseSchema,
  exportTotalTelemetryResponseSchema,
} from './export-total-flow-telemetry.schema';

export const exportTotalFlowTelementry = async (): Promise<TExportTotalTelemetryResponseSchema> => {
  const response = await getHttpClient().post(paths.EXPORT_TOTAL_FLOW_TELEMETRY);
  return exportTotalTelemetryResponseSchema.parse(response);
};
