import { z } from 'zod';

export const assetTypeValues = ['Location', 'Equipment', 'Device'] as const;
export type TAssetType = (typeof assetTypeValues)[number];

export enum ASSET_TYPE {
  LOCATION = 'LOCATION',
  EQUIPMENT = 'EQUIPMENT',
  DEVICE = 'DEVICE',
}

export enum NODE_TYPE {
  ASSET = 'Asset',
  TEE = 'Tee',
  ATTACHMENT = 'Attachment',
}

export const assetTypeSchema = z.enum(assetTypeValues).transform((assetType: TAssetType): ASSET_TYPE => {
  switch (assetType) {
    case 'Device': {
      return ASSET_TYPE.DEVICE;
    }
    case 'Location': {
      return ASSET_TYPE.LOCATION;
    }
    case 'Equipment': {
      return ASSET_TYPE.EQUIPMENT;
    }
  }
});

export const assetSchema = z.object({
  id: z.string(),
  type: assetTypeSchema,
  name: z.string(),
});

export const assetsSchema = assetSchema.array();

export const assetFilterTypeSchema = z.nativeEnum(ASSET_TYPE).transform((assetType: ASSET_TYPE): TAssetType => {
  switch (assetType) {
    case ASSET_TYPE.DEVICE: {
      return 'Device';
    }
    case ASSET_TYPE.LOCATION: {
      return 'Location';
    }
    case ASSET_TYPE.EQUIPMENT: {
      return 'Equipment';
    }
  }
});

export const assetListFiltersSchema = z
  .object({
    filter: assetFilterTypeSchema.nullish(),
    search: z.string().trim().nullish(),
  })
  .transform(({ search, filter }) => ({
    name: search,
    type: filter,
  }));

export type TAsset = z.infer<typeof assetSchema>;
export type TAssets = z.infer<typeof assetsSchema>;
export type TAssetListFilters = z.infer<typeof assetListFiltersSchema>;
