import { maxBatteryLevel } from './consts';
import { TPowerSource } from './types';

export enum DEVICE_STATE {
  LOST_COMMUNICATION = 'LOST_COMMUNICATION',
  REPLACE_BATTERY = 'REPLACE_BATTERY',
  LOW_BATTERY = 'LOW_BATTERY',
  OPERATIONAL = 'OPERATIONAL',
  ERROR = 'ERROR',
}

//TODO: BATTERY TRESHHOLD - connect this to the backend
const batteryTreshold = 20;

interface IDeviceStateProps {
  batteryLevel: number | null;
  isActive: boolean;
}

export const getDeviceState = ({ batteryLevel, isActive }: IDeviceStateProps) => {
  if (!isActive) {
    return DEVICE_STATE.LOST_COMMUNICATION;
  } else if (isActive && batteryLevel && batteryLevel < batteryTreshold) {
    return DEVICE_STATE.REPLACE_BATTERY;
  } else if (isActive && batteryLevel && batteryLevel >= batteryTreshold && batteryLevel <= 49) {
    return DEVICE_STATE.LOW_BATTERY;
  } else if (isActive && batteryLevel && batteryLevel >= 50) {
    return DEVICE_STATE.OPERATIONAL;
  }
  return DEVICE_STATE.ERROR;
};

export const getPowerSourceType = (batteryLevel: number): TPowerSource => {
  if (batteryLevel > maxBatteryLevel) {
    return 'LINE';
  }

  return 'BATTERY';
};
