import { FC, PropsWithChildren, useReducer } from 'react';

import { DeviceDrawerContext } from './device-drawer-context';
import { deviceDrawerReducer, initialState } from './device-drawer-reducer';

export const DeviceDrawerProvider: FC<PropsWithChildren> = ({ children }) => {
  const [deviceDrawerState, deviceDrawerDispatch] = useReducer(deviceDrawerReducer, initialState);

  return (
    <DeviceDrawerContext.Provider value={{ deviceDrawerState, deviceDrawerDispatch }}>
      {children}
    </DeviceDrawerContext.Provider>
  );
};
