import { MarlinTheme } from '@marlin/shared/theme';
import { Link } from '@marlin/shared/ui-page';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { Typography } from '@mui/material';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useSensorId } from '../../../hooks/use-sensor-id.hook';
import { AlertTooltip } from './alert-tooltip.component';

interface IStyle {
  isAlert: boolean;
}

export const useStyles = makeStyles<IStyle>()((theme: MarlinTheme, isAlert) => ({
  currentTemp: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: isAlert ? theme.typography.pxToRem(32) : theme.typography.pxToRem(48),
    display: 'inline-flex',
    [theme.breakpoints.down('sm')]: {
      fontSize: isAlert ? theme.typography.pxToRem(24) : theme.typography.pxToRem(34),
    },
  },

  currentReading: {
    textDecorationLine: 'none',
    color: theme.palette.primary.main,
  },

  extendedReading: {
    fontSize: theme.typography.pxToRem(48),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
  },
  alert: {
    color: theme.palette.error.main,
  },
}));

interface ICurrentReading {
  isAlert: boolean;
  currentReading: string;
  isExtended?: boolean;
}

const LastReading = ({ isAlert, currentReading }: ICurrentReading) => {
  const {
    classes: { currentReading: currentReadingStyles, alert },
    cx,
  } = useStyles({ isAlert });
  const deviceId = useSensorId();
  const router = useRouter();
  const currentUrl = useMemo(() => router.getUrl(), [router]);
  const alertTabLink = useMemo(() => routes.deviceDrawer.open(currentUrl, deviceId, 'alerts'), [deviceId, currentUrl]);

  if (!isAlert) {
    return <div className={currentReadingStyles}>{currentReading}</div>;
  }

  return (
    <Link to={alertTabLink} className={cx(currentReadingStyles, alert)}>
      {currentReading}
    </Link>
  );
};

export const CurrentReading = ({ isAlert, currentReading, isExtended }: ICurrentReading) => {
  const {
    classes: { currentTemp, extendedReading },
  } = useStyles({ isAlert });

  return (
    <Typography variant="h4" className={isExtended ? extendedReading : `${currentTemp}`}>
      <LastReading isAlert={isAlert} currentReading={currentReading} />
      <AlertTooltip isAlert={isAlert} />
    </Typography>
  );
};
