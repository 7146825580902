import { IChartSeries, IChartThresholdSeries, TChartDisplayType } from '@marlin/shared/utils-chart';
import { ApexOptions } from 'apexcharts';

import { EmptyChartMessage } from './empty-chart-message.component';

interface INoDataContent {
  from?: string | number;
  to?: string | number;
  chartData: Array<IChartSeries | IChartThresholdSeries> | ApexOptions['series'];
  chartDisplayType?: TChartDisplayType;
}

export const isEmptyChartData = ({ chartData, from, to, chartDisplayType }: INoDataContent) => {
  if (chartDisplayType === 'bar' || chartDisplayType === 'rangeBar') {
    return !chartData?.length;
  }

  return (
    !chartData?.length ||
    chartData?.every((item) => {
      if (typeof item === 'number') return item === null;
      // TODO: check if this logic can be simplified after removing margins for bar chart

      // We cannot filter the data, because for the bar chart we need all the data.
      // Because there may be data in the margin that we need to display on the chart, we had to make IF separations for the bar chart.
      // With the specific data, for the bar chart we need to check the current element if it is 1, and the next element if it is 0 and is in the range without margins.
      if (item.type === 'rangeBar') {
        // This handle a case where there is only one value since the registration to not show misleading empty data message
        if (item.data.length === 1) {
          return false;
        }

        return item.data.every((value, index, data) => {
          if (
            value &&
            typeof value === 'object' &&
            'y' in value &&
            from &&
            typeof from === 'number' &&
            to &&
            value.y === 1
          ) {
            if (typeof to === 'number' && value.x > to) return true;

            const valueNextItem = data[index + 1];

            if (!valueNextItem || (typeof valueNextItem === 'object' && 'x' in valueNextItem && valueNextItem.x > from))
              return false;
          }

          return true;
        });
      }

      return item.data.every((value) => {
        if (!value || (typeof from !== 'number' && typeof to !== 'number')) return true;

        if (typeof value === 'number') return value === null;

        return Array.isArray(value)
          ? value.every((v) => v === null)
          : value && value.x && from && to
          ? (typeof from === 'number' && value.x < from) || (typeof to === 'number' && value.x > to) || value.y === null
          : value.y === null;
      });
    })
  );
};

export const EmptyChartData = ({ chartData, from, to, chartDisplayType }: INoDataContent) => {
  const isEmpty = isEmptyChartData({ chartData, from, to, chartDisplayType });

  return isEmpty ? <EmptyChartMessage from={from} to={to} /> : null;
};
