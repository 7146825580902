import { content } from './content';

export const intellistationDatapointLabelsMap = new Map([
  ['outletTemp', content.DATAPOINT_LABELS.OUTLET_TEMP],
  ['recircTemp', content.DATAPOINT_LABELS.RECIRC_TEMP],
  ['hotTemp', content.DATAPOINT_LABELS.HOT_TEMP],
  ['coldTemp', content.DATAPOINT_LABELS.COLD_TEMP],
  ['outletPressure', content.DATAPOINT_LABELS.OUTLET_PRESSURE],
  ['recircPressure', content.DATAPOINT_LABELS.RECIRC_PRESSURE],
  ['hotPressure', content.DATAPOINT_LABELS.HOT_PRESSURE],
  ['coldPressure', content.DATAPOINT_LABELS.COLD_PRESSURE],
  ['mixingValve', content.DATAPOINT_LABELS.MIXING_VALVE],
  ['isolationValve', content.DATAPOINT_LABELS.ISOLATION_VALVE],
  ['pump1Status', content.DATAPOINT_LABELS.SYSTEM_PUMP_1],
  ['pump2Status', content.DATAPOINT_LABELS.SYSTEM_PUMP_2],
  ['outletFlow', content.DATAPOINT_LABELS.OUTLET_FLOW],
  ['recircFlow', content.DATAPOINT_LABELS.RECIRC_FLOW],
  ['mixingTemp', content.DATAPOINT_LABELS.MIXING_TEMP],
  ['setpointTarget', content.DATAPOINT_LABELS.SETPOINT_TEMP],
]);
