import { IAlertsQueryParam } from '@marlin/shared/utils-routes';
import { useNavigate } from 'react-router-dom';

import { useNavigation } from './use-navigation.hook';

interface IUseRouter {
  goToAlertsPage: () => void;
  goToAlertsPageWithId: (params?: IAlertsQueryParam) => void;
}

export const useRouter = (): IUseRouter => {
  const navigate = useNavigate();
  const { getAlertsPageLink, getAlertsPageWithIdLink } = useNavigation();
  const goTo = (url: string) => {
    navigate(url);
  };

  return {
    goToAlertsPage: () => {
      goTo(getAlertsPageLink());
    },
    goToAlertsPageWithId: (params?: IAlertsQueryParam) => {
      goTo(getAlertsPageWithIdLink(params));
    },
  };
};
