import { MarlinTheme } from '@marlin/shared/theme';
import { dateAdapter } from '@marlin/shared/utils-common-date';
import { Skeleton, alpha } from '@mui/material';
import { useEffect, useMemo } from 'react';
import ApexChart from 'react-apexcharts';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../../content';
import { useChartData } from '../collapsed-chart/use-chart-data.hook';
import { brushChartId } from './const';
import { useExtendedChartContext } from './context/extended-chart.context';
import { useBrushChart, useBrushChartOptions } from './hooks/use-brush-chart.hook';

const chartHeight = 150;
const chartWithLegendHeight = 198;
export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  chart: {
    position: 'relative',
    minHeight: theme.typography.pxToRem(chartWithLegendHeight),
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.primary,
  },
  loader: {
    backgroundColor: alpha(theme.palette.background.primary, 0.5),
  },
  label: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: theme.typography.pxToRem(6),
  },
  dot: {
    marginRight: theme.typography.pxToRem(10),
    height: theme.typography.pxToRem(12),
    width: theme.typography.pxToRem(12),
    borderRadius: '50%',
    padding: theme.typography.pxToRem(4),
    display: 'inline-block',
    backgroundColor: alpha(theme.palette.error.main, 0.12),
  },
}));

const fillEmptyApexData = (data: { x: number; y: number | boolean | null }[], min: number, max: number) => {
  const minDate = dateAdapter.date(min)?.startOf('day');
  const maxDate = dateAdapter.date(max)?.startOf('day');
  const maxValue = data.reduce((acc, item) => ((item.y as number) > acc ? item.y : acc) as number, 0);
  const newData: { x: number; y: number }[] = [];
  const adaptedData = data.map((item) => ({ x: dateAdapter.date(item.x), y: item.y }));
  for (let date = minDate; date?.isBefore(maxDate); date = date.add(1, 'day')) {
    const existingDate = adaptedData.find((item) => item.x?.isSame(date, 'day'));
    if (existingDate) {
      // note: we need to add zeros to avoid drawing of the area between two maxValue points,
      // otherwise Apex will draw background in areas with data
      newData.push({ x: date.valueOf(), y: 0 });
    } else {
      newData.push({ x: date.valueOf(), y: maxValue });
    }
  }

  return newData;
};

export const BrushChart = () => {
  const { manufacturerId, deviceType, rangeFilter, setCustomRangeFilter, defaultBrushRange, brushSelection, uoM } =
    useExtendedChartContext();

  const { data, isUpdating, isFetched } = useBrushChart(manufacturerId, deviceType, defaultBrushRange, rangeFilter);

  const isDataEmpty: boolean = useMemo(() => !data.filter((point) => point.value !== null).length, [data]);

  const apexData = useChartData(data, deviceType, 'subchart', 'bar');
  const { classes } = useStyles();

  const apexOptions = useBrushChartOptions(
    deviceType,
    setCustomRangeFilter,
    isFetched,
    uoM,
    isDataEmpty,
    defaultBrushRange
  );

  const brushChartData = useMemo(
    () => [
      ...apexData,
      {
        name: 'empty',
        type: 'area',
        data: fillEmptyApexData(
          apexData[0].data,
          brushSelection.from || dateAdapter.date()?.subtract(12, 'months').valueOf() || 0,
          brushSelection.to || dateAdapter.date()?.valueOf() || 0
        ),
      },
    ],
    [apexData, brushSelection.from, brushSelection.to]
  );

  const showLegend = useMemo(() => {
    return !!brushChartData.find((item) => item.name === 'empty')?.data.some((item) => item.y !== 0);
  }, [brushChartData]);

  const options = useMemo(
    () => ({
      ...apexOptions,
      markers: {
        size: 0,
      },
      grid: {
        padding: {
          left: 0,
          right: 0,
        },
      },
      stroke: {
        curve: 'stepline' as const,
      },
    }),
    [apexOptions]
  );

  useEffect(() => {
    if (isFetched) {
      ApexCharts.exec(brushChartId, 'updateOptions', {
        chart: {
          events: options.chart.events,
        },
      });
    }
    // NOTE: we don't want to update options always, only once after data is fetched
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched]);

  return (
    <div className={classes.chart}>
      <ApexChart type="bar" height={chartHeight} series={brushChartData} options={options} data-testid="brush-chart" />
      {isUpdating && (
        <div className={classes.overlay}>
          <Skeleton variant="rectangular" height={chartWithLegendHeight} width="100%" />
        </div>
      )}
      {showLegend && (
        <div className={classes.label} data-testid="brush-chart-legend">
          <div className={classes.dot}></div>
          {content.NO_DATA_AVAILABLE}
        </div>
      )}
    </div>
  );
};
