import { zodResolver } from '@hookform/resolvers/zod';
import { MarlinTheme } from '@marlin/shared/theme';
import { Paper } from '@marlin/shared/ui-page';
import { TFlowLinkType } from '@marlin/system-map/data-access/system-map';
import { FlowNodeSelect, InletTitle, OutletTitle } from '@marlin/system-map/shared/system-map-link-form';
import { Checkbox, Divider, FormControlLabel, Grid, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { Buttons } from './buttons.component';
import { content } from './content';
import { FlowLinkSchema } from './flow-link-form.schema';
import { ASSET_TYPE, IFlowLink, NODE_TYPE } from './types';

interface IUpdatedFlowLinkProps {
  inletAssetId: string;
  inletNodeType: ASSET_TYPE;
  inletAssetType: ASSET_TYPE;
  outletAssetId: string;
  outletNodeType: ASSET_TYPE;
  outletAssetType: ASSET_TYPE;
}

export interface IUpdateFlowLink {
  params: IUpdatedFlowLinkProps;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    padding: 0,
  },

  header: {
    padding: theme.typography.pxToRem(24),
  },

  form: {
    padding: theme.typography.pxToRem(24),
  },
}));

interface IFlowLinkFormProps {
  editMode?: boolean;
  defaultValues: IFlowLink;
  onSubmit: (link: IFlowLink) => Promise<unknown>;
  onCloseModal?: () => void;
  changeFlowLinkFormType?: (flowLinkType: TFlowLinkType) => void;
}

export const FlowLinkForm = ({
  onSubmit,
  onCloseModal,
  defaultValues,
  editMode = false,
  changeFlowLinkFormType,
}: IFlowLinkFormProps) => {
  const { classes } = useStyles();

  const form = useForm<IFlowLink>({
    defaultValues: defaultValues || { inlet: {}, outlet: {} },
    mode: 'onChange', // NOTE: onchange mode set because of autocomplete
    resolver: zodResolver(FlowLinkSchema),
  });

  const createFlowLinkSubmit = useCallback(
    (link: IFlowLink) => {
      onSubmit(link).then(() => {
        form.reset(defaultValues);
      });
    },
    [form, onSubmit, defaultValues]
  );

  const editFlowLinkSubmit = (link: IFlowLink) => {
    if (link.inlet && link.outlet) {
      onSubmit(link);
      if (onCloseModal) {
        onCloseModal();
      }
    }
  };

  const cancelCreateFlowLink = useCallback(() => form.reset(defaultValues), [form, defaultValues]);

  const editCancel = useCallback(() => {
    form.reset(defaultValues);
    if (onCloseModal) {
      onCloseModal();
    }
  }, [form, onCloseModal, defaultValues]);

  const inletDefaultValues = useMemo(() => {
    return {
      ...defaultValues.inlet,
      linkTags: defaultValues.linkTags,
    };
  }, [defaultValues.linkTags, defaultValues.inlet]);

  const outletDefaultValues = useMemo(() => {
    return {
      ...defaultValues.outlet,
      linkTags: defaultValues.linkTags,
    };
  }, [defaultValues.linkTags, defaultValues.outlet]);

  return (
    <Paper className={classes.container}>
      <div className={classes.header}>
        <Typography variant="h6">{editMode ? content.EDIT_TITLE : content.TITLE}</Typography>
      </div>
      <Divider />
      <div className={classes.form}>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(editMode ? editFlowLinkSubmit : createFlowLinkSubmit)}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={editMode}
                      checked={false}
                      onClick={changeFlowLinkFormType ? () => changeFlowLinkFormType('Attachement') : undefined}
                    />
                  }
                  label="Attached Link"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FlowNodeSelect
                  nodeType={NODE_TYPE.OUTLET}
                  title={<OutletTitle>{content.OUTLET}</OutletTitle>}
                  defaultValue={outletDefaultValues}
                  editMode={editMode}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FlowNodeSelect
                  nodeType={NODE_TYPE.INLET}
                  title={<InletTitle>{content.INLET}</InletTitle>}
                  defaultValue={inletDefaultValues}
                  editMode={editMode}
                />
              </Grid>
            </Grid>
            {editMode ? (
              <Buttons onSubmit={editFlowLinkSubmit} onCancel={editCancel} editMode={editMode} />
            ) : (
              <Buttons onSubmit={createFlowLinkSubmit} onCancel={cancelCreateFlowLink} />
            )}
          </form>
        </FormProvider>
      </div>
    </Paper>
  );
};
