import { MarlinTheme } from '@marlin/shared/theme';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

type TDatapointBoxProps = {
  title: string;
  helperText?: string;
  children?: React.ReactNode;
  icon?: JSX.Element | string;
  id: string;
};

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    padding: theme.typography.pxToRem(12),
    flexDirection: 'column',
    borderRadius: theme.typography.pxToRem(4),
    backgroundColor: theme.palette.background.alternative,
  },
  helperText: {
    fontSize: theme.typography.pxToRem(12),
    fontStyle: 'normal',
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.secondary,
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.typography.pxToRem(4),
    marginBottom: theme.typography.pxToRem(4),
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.typography.pxToRem(8),
  },
  icon: {
    width: theme.typography.pxToRem(24),
    height: theme.typography.pxToRem(24),
    color: theme.palette.action.active,
  },
}));

const DatapointBox = ({ icon, title, helperText, children, id }: TDatapointBoxProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container} data-testid={id}>
      <div className={classes.titleWrapper}>
        <Typography variant="overline" className={classes.title}>
          {icon && <span className={classes.icon}>{icon}</span>}
          {title}
        </Typography>
        {helperText && <span className={classes.helperText}>{helperText}</span>}
      </div>
      {children}
    </div>
  );
};

export { DatapointBox };
