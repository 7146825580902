import { useMediaQuery, useTheme } from '@mui/material';
import { useEffect } from 'react';

import { AutomationHelperService } from './automation-helper';

const dataTestDevice = 'data-testdevice';

enum DEVICE {
  mobile = 'mobile',
  desktop = 'desktop',
}

export const useLayoutBreakpoints = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const automationHelper = AutomationHelperService.getInstance();

  useEffect(() => {
    if (isMobile) {
      automationHelper?.addAttribute(dataTestDevice, DEVICE.mobile);
    } else {
      automationHelper?.addAttribute(dataTestDevice, DEVICE.desktop);
    }
  }, [isMobile, automationHelper]);

  return {
    isMobile,
  };
};
