import { useIdFromPathname } from '@marlin/shared/utils/url-params';
import { useMemo } from 'react';

import { content } from '../content';
import { useDurationContext } from '../context/context-duration.context';
import { useCalculatedValues } from './use-calculated-values.hook';

export const useCalculateValues = (isCalculateValues: boolean) => {
  const { rangeFilter } = useDurationContext();
  const equipmentId = useIdFromPathname();
  const {
    energyUsed,
    loadFlow,
    isLoading: isLoadingMonitorValues,
    refetch,
  } = useCalculatedValues(rangeFilter, isCalculateValues, equipmentId);

  const chartDatapoints = useMemo(
    () => [
      { ...energyUsed, label: content.ENERGY_USED },
      { ...loadFlow, label: content.LOAD_FLOW },
    ],
    [energyUsed, loadFlow]
  );

  return {
    isLoadingMonitorValues,
    chartDatapoints,
    refetch,
  };
};
