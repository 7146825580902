import { TDashboardDevice, TDashboardHealthSystemSchema } from '@marlin/asset/data-access/home-dashboard';
import { CardHeader } from '@marlin/asset/ui-home-hub-cards';
import { HealthStatusIcon, HealthStatusLabel } from '@marlin/shared/ui/system-view-status';
import { Divider } from '@mui/material';
import { useMemo } from 'react';

import { content } from '../../content';
import { useHealthDashboardStatus } from '../../shared/use-dashboard-status';
import { LocationSection } from './components/location-section.component';
import { useStyles } from './health-card.styles';

interface IHealthCard {
  health: TDashboardHealthSystemSchema;
  devices: TDashboardDevice[];
}

export const HealthCard = ({ health, devices }: IHealthCard) => {
  const { classes } = useStyles();
  const { checkCommunication, checkErrors, performSystemMaintenance, batteriesCriticality } = useHealthDashboardStatus({
    health,
    devices,
  });

  const status = useMemo(() => {
    if (checkErrors || checkCommunication) {
      return 'error';
    } else if (performSystemMaintenance) {
      return 'warning';
    }
    return 'success';
  }, [checkCommunication, checkErrors, performSystemMaintenance]);

  return (
    <div className={classes.container} data-testid="health-container">
      <CardHeader
        status={checkErrors || checkCommunication ? 'error' : performSystemMaintenance ? 'warning' : 'success'}
        title={
          <HealthStatusLabel
            checkCommunication={checkCommunication}
            checkErrors={checkErrors}
            performSystemMaintenance={performSystemMaintenance}
          />
        }
        subtitle={content.HEALTH}
        cardType="health"
        icon={<HealthStatusIcon status={status} testId={'tooltip'} />}
      />
      <div>
        <LocationSection health={health} batteriesCriticality={batteriesCriticality} />
      </div>
      <Divider />
    </div>
  );
};
