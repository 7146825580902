export enum VALIDATE_INVITATION_ERROR {
  INVITATION_EXPIRED = 1,
  INVITATION_ALREADY_ACCEPTED = 2,
  INVITATION_INVALID = 3,
  INVALID_EMAIL_ADDRESS = 7,
}

export interface IValidateInvitationError {
  response?: {
    data?: {
      errorCode?: VALIDATE_INVITATION_ERROR;
      OrganizationName?: string;
    };
  };
}
