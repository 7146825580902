export const content = {
  WEBHOOK_CREATE_TITLE: 'Create Webhook',
  WEBHOOK_CREATE_SUBTITLE: 'Use the form below to create a new webhook',
  WEBHOOK_CREATE_SUCCESS: 'Your webhook has been created',
  WEBHOOK_CREATE_ERROR: "Something went wrong. Your webhook hasn't been created.",
  WEBHOOK_DEFAULT_NAME: (count: number) => `Webhook ${count}`,

  WEBHOOK_UPDATE_SUBTITLE: 'Use the form below to edit this webhook',
  WEBHOOK_UPDATE_SUCCESS: 'Your webhook has been updated',
  WEBHOOK_UPDATE_ERROR: "Something went wrong. Your webhook hasn't been updated.",
};
