import { useCurrentUser } from '@marlin/account-data-access-organization';
import { useSaveAnalyticsUserData, useSetMultiChartsStore } from '@marlin/asset/ui/charts';
import { createStoreContext, useContext } from '@marlin/shared/utils-component-context';
import { FC, useCallback } from 'react';

import { useSortDatapointsWithColor } from '../hooks/use-sort-datapoints-with-color';
import { multiChartStorageService } from '../multi-chart.storage';
import { TSelectedGroupsWithDatapoints } from '../types';

const {
  useStore: useSelectedSensorsStore,
  Provider: SelectedSensorsProvider,
  StoreContext: SelectedSensorsContext,
} = createStoreContext<TSelectedGroupsWithDatapoints>({});

export function useSetSelectedSensors() {
  const setMultiChartsStore = useSetMultiChartsStore();
  const { data: userData } = useCurrentUser();
  const context = useContext(SelectedSensorsContext);
  const { onSelectedSensorsSave } = useSaveAnalyticsUserData();
  const sortDatapointsAndSetColor = useSortDatapointsWithColor();

  return useCallback(
    (data: Partial<TSelectedGroupsWithDatapoints>) => {
      if (!data || !Object.keys(data).length) return;

      const currentData = context.get();
      const updatedData = { ...currentData, ...data } as TSelectedGroupsWithDatapoints;

      const updatedSortedData = Object.keys(updatedData).reduce<TSelectedGroupsWithDatapoints>((acc, key) => {
        const currentData = updatedData[key];
        const sortedData = sortDatapointsAndSetColor(currentData.chartDatapoints);
        return {
          ...acc,
          [key]: {
            ...currentData,
            chartDatapoints: sortedData,
          },
        };
      }, {});

      multiChartStorageService.setSelectedSensors({
        userId: userData?.id,
        organizationId: userData?.currentOrganization.organizationId,
        selectedSensors: updatedSortedData,
      });

      onSelectedSensorsSave(updatedSortedData);

      const chartIdList = Object.keys(updatedSortedData);
      setMultiChartsStore({ chartIdList });

      context.set(updatedSortedData);
    },
    [context, onSelectedSensorsSave, setMultiChartsStore, sortDatapointsAndSetColor, userData]
  );
}

export function useRemoveChart() {
  const setMultiChartsStore = useSetMultiChartsStore();
  const { data: userData } = useCurrentUser();
  const { onSelectedSensorsSave } = useSaveAnalyticsUserData();
  const context = useContext(SelectedSensorsContext);

  return useCallback(
    (chartId: string) => {
      context.remove(chartId);

      const currentData = context.get();
      const updatedData = { ...currentData };

      multiChartStorageService.setSelectedSensors({
        userId: userData?.id,
        organizationId: userData?.currentOrganization.organizationId,
        selectedSensors: updatedData,
      });

      const chartIdList = Object.keys(updatedData);
      setMultiChartsStore({ chartIdList });

      onSelectedSensorsSave(updatedData);

      context.set(updatedData);
    },
    [context, setMultiChartsStore, userData, onSelectedSensorsSave]
  );
}

export function withSelectedSensorsContext(Component: FC) {
  return () => (
    <SelectedSensorsProvider>
      <Component />
    </SelectedSensorsProvider>
  );
}

export { useSelectedSensorsStore };
