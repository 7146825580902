import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
    marginTop: 0,
    marginBottom: theme.typography.pxToRem(16),
    wordBreak: 'break-word',
  },

  titleGrid: {
    maxWidth: `calc(100% - ${theme.typography.pxToRem(276)})`,
  },

  headerRow: {
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },

  actions: {
    display: 'flex',
    alignItems: 'center',
  },

  delete: {
    color: theme.palette.error.main,
    '& svg': {
      fill: theme.palette.error.main,
    },
  },

  icon: {
    fontSize: theme.typography.pxToRem(20),
  },

  disabledElements: {
    '& > *': {
      opacity: 0.6,
      filter: 'gray',
      '-webkit-filter': 'grayscale(100%)',
      '-moz-filter': 'grayscale(100%)',
      '-ms-filter': 'grayscale(100%)',
      '-o-filter': 'grayscale(100%)',
    },
  },

  enabledElement: {
    opacity: 'unset',
    filter: 'none',
    '-webkit-filter': 'none',
    '-moz-filter': 'none',
    '-ms-filter': 'none',
    '-o-filter': 'none',
  },
}));
