import { IDashboardChartOptions, TChartTooltip } from '@marlin/asset/shared/ui/chart-options';
import { Tooltip } from '@marlin/asset/shared/ui/chart-switcher-wrapper';
import { MarlinTheme } from '@marlin/shared/theme';
import { SelectDuration } from '@marlin/shared/ui-form';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { EmptyChartData } from '@marlin/shared/ui/chart';
import { IChartSeries, RANGE_FILTER, isOfChartRangeType, plotlyAdapterSchema } from '@marlin/shared/utils-chart';
import { TDatapointWithUiMetadata } from '@marlin/shared/utils/datapoint-mappers';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  SelectChangeEvent,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import uniq from 'lodash/uniq';
import React, { useCallback, useMemo } from 'react';
import Plot from 'react-plotly.js';

import { useEquipmentDetailsConfigContext } from '../../../shared/context/equipment-details-config-context';
import { useEquipmentContext } from '../../../shared/context/equipment.context';
import { equipmentChartSize, useStyles } from './equipment-chart.styles';
import { RangeBarChart } from './range-bar-chart.component';

interface IEquipmentChartProps {
  title: string;
  subTitle?: string;
  datapoints?: Array<Partial<TDatapointWithUiMetadata & { label?: string }>>;
  chartData: IChartSeries[];
  chartOptions: IDashboardChartOptions;
  tooltip: TChartTooltip;
  onAdvancedClick?: () => void;
  isLoading: boolean;
  from?: string | number;
  to?: string | number;
}

const DurationRangeFilter = ({ onRefresh }: { onRefresh: () => void }) => {
  const { rangeFilter, handleRangeFilterChange } = useEquipmentContext();

  const onRangeFilterChange = useCallback(
    (event: SelectChangeEvent<RANGE_FILTER>) => {
      const newRangeFilter = event.target.value;
      if (isOfChartRangeType(newRangeFilter)) {
        handleRangeFilterChange(newRangeFilter);
      }
    },
    [handleRangeFilterChange]
  );

  return (
    <SelectDuration rangeFilter={rangeFilter.range} onRangeFilterChange={onRangeFilterChange} onRefresh={onRefresh} />
  );
};

const EquipmentChartDetails = ({
  chartData,
  chartOptions,
  isLoading,
  from,
  to,
  tooltip,
}: Omit<IEquipmentChartProps, 'title' | 'subtitle' | 'onRefresh'>) => {
  const { classes } = useStyles();
  const theme = useTheme<MarlinTheme>();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const {
    config: {
      dashboard: { chart },
    },
  } = useEquipmentDetailsConfigContext();
  const chartType = chart?.chartType;
  const { layout, config, handleUnhover, handleHover } = chartOptions;

  const uomsForSeries = useMemo(() => uniq([...chartData].map((s) => s?.uom)).filter(Boolean), [chartData]);
  const data = useMemo(() => {
    return plotlyAdapterSchema.parse({
      series: chartData,
      uoms: uomsForSeries,
    });
  }, [chartData, uomsForSeries]);

  if (chartType === 'rangeBar') {
    return <RangeBarChart chartData={chartData} isLoading={isLoading} from={Number(from)} to={Number(to)} />;
  }

  if (!layout) return null;

  return (
    <div className={classes.mainChart} data-testid="equipment-dashboard-chart">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className={classes.chartContainer}>
          <>
            <Plot
              data={data}
              layout={layout}
              config={config}
              useResizeHandler={true}
              style={{
                width: '100%',
                height: equipmentChartSize.height[isMobile ? 'mobile' : 'desktop'],
              }}
              onHover={handleHover}
              onUnhover={handleUnhover}
            />
            <Tooltip {...(tooltip || {})} />
          </>
          <EmptyChartData chartData={chartData} from={from} to={to} />
        </div>
      )}
    </div>
  );
};

export const EquipmentChart = ({
  title,
  subTitle,
  datapoints,
  isLoading,
  ...chartDetailsProps
}: IEquipmentChartProps) => {
  const { classes, cx } = useStyles();
  const theme = useTheme<MarlinTheme>();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { handleRangeFilterChange } = useEquipmentContext();

  const handleRefresh = useCallback(() => {
    handleRangeFilterChange();
  }, [handleRangeFilterChange]);

  return (
    <div className={classes.chartWrapper}>
      <Accordion
        className={classes.accordion}
        data-testid="equipment-chart"
        defaultExpanded
        expanded={!isMobile || undefined}
        elevation={0}
      >
        <AccordionSummary
          className={cx(classes.accordionSummary, classes.title)}
          expandIcon={isMobile ? <ExpandMoreIcon /> : undefined}
          data-testid="equipment-chart-summary"
        >
          {title}
          {!isMobile && <DurationRangeFilter onRefresh={handleRefresh} />}
        </AccordionSummary>
        <AccordionDetails className={classes.paper} data-testid="equpment-chart-details">
          <div className={classes.chartDetailsWrapper}>
            {isMobile && <DurationRangeFilter onRefresh={handleRefresh} />}
            {subTitle && (
              <Typography variant="body1" className={classes.title}>
                {subTitle}
              </Typography>
            )}
            <EquipmentChartDetails {...chartDetailsProps} isLoading={isLoading} />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
