import { EQUIPMENT_TYPE } from '@marlin/shared/utils/zod';
import { AssetTypeSchema, NodeTypeSchema } from '@marlin/system-map/shared/data-access-schemas';
import * as z from 'zod';

import { content } from './content';
import { ASSET_TYPE } from './types';

const tagSchema = z.object({
  name: z.string().nullish(),
  value: z.string().nullish(),
});
const AttachementNodeSchema = (required: string) =>
  z
    .object({
      id: z.string().nullish(),
      assetId: z.string().nullish(),
      type: z.nativeEnum(ASSET_TYPE).nullish(),
      name: z.string(),
      nodeType: NodeTypeSchema,
      assetType: AssetTypeSchema.nullish(),
      tags: z.array(tagSchema).nullish(),
      equipmentModel: z.nativeEnum(EQUIPMENT_TYPE).nullish(),
      equipmentHandle: z.string().nullish(),
    })
    .nullish()
    .superRefine((value, ctx) => {
      if (!value) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: content.VALIDATION_INLET_OUTLET_REQUIRED,
        });
      }

      const isEquipmentWithoutHandle = value?.tags?.some(
        (tag) => tag.name === 'Model' && (tag.value === undefined || tag.value === 'LDS')
      );

      if (isEquipmentWithoutHandle) {
        return;
      }

      const hasHandleIdTag = value?.tags?.some((tag) => tag.name === 'HandleId');

      if (value?.assetType === ASSET_TYPE.EQUIPMENT && !hasHandleIdTag && value?.equipmentHandle === undefined) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: content.VALIDATION_EQUIPMENT_MODEL_REQUIRED,
          path: ['equipmentHandle'],
        });
      }
    });

export const AttachementSchema = z
  .object({
    inlet: AttachementNodeSchema(content.VALIDATION_INLET_REQUIRED),
    outlet: AttachementNodeSchema(content.VALIDATION_OUTLET_REQUIRED),
  })
  .superRefine((value, ctx) => {
    if (value.inlet && value.outlet && value.inlet.assetId === value.outlet?.assetId) {
      // Note: needs to add 2 issues, so error would show on both fields, depending on which one was changed
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: content.VALIDATION_LOOP,
        path: ['inlet'],
      });
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: content.VALIDATION_LOOP,
        path: ['outlet'],
      });
    }

    if (value.outlet?.assetType === ASSET_TYPE.EQUIPMENT) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: content.VALIDATION_EQUIPMENT_ATTACHMENT,
        path: ['outlet'],
      });
    }
    if (value.outlet?.assetType === ASSET_TYPE.LOCATION) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: content.VALIDATION_LOCATION_ATTACHMENT,
        path: ['outlet'],
      });
    }
    if (value.inlet?.assetType === ASSET_TYPE.DEVICE) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: content.VALIDATION_SENSOR_ATTACHMENT,
        path: ['inlet'],
      });
    }
  });
