import { z } from 'zod';

export const ErrorResponseWithCode = z
  .object({
    response: z.object({
      data: z.object({
        errorCode: z.number(),
      }),
    }),
  })
  .passthrough();
