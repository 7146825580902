import { useCurrentUser } from '@marlin/account-data-access-organization';
import { useInitUnreadAlertsCountSubject } from '@marlin/alert/data-access/alert-action';
import { useAlertSnackbarSubscription } from '@marlin/alert/feature/alert-snackbar';
import { useInitHomeDashboardNotificationSubject } from '@marlin/asset/data-access/home-dashboard';
import { realtimeCommunicationFacade } from '@marlin/shared/utils-realtime-communication';
import {
  useInitFlowLoopNotificationSubject,
  useInitSystemMapFullNotificationSubject,
  useInitSystemMapUpdateNotificationSubject,
} from '@marlin/system-map/data-access/system-map';
import { useObservable, useObservableState } from 'observable-hooks';
import { Observable, combineLatest, of } from 'rxjs';
import { filter, pairwise, startWith, switchMap } from 'rxjs/operators';

export const subscribeToOrganizationInAppAlerts = 'SubscribeToOrganizationInAppAlerts';

const invokeAlertSubscription = (organizationId$: Observable<string | undefined>): Observable<unknown> => {
  return combineLatest([
    realtimeCommunicationFacade.getConnection(),
    organizationId$.pipe(
      filter((id) => id !== undefined),
      startWith(undefined),
      pairwise()
    ),
  ]).pipe(
    switchMap(([connection, [oldOrgId, newOrgId]]) => {
      if (!newOrgId) {
        return of(undefined);
      }
      return connection.invoke(subscribeToOrganizationInAppAlerts, newOrgId, oldOrgId);
    })
  );
};

export const useRealtimeCommunication = (): void => {
  const { data: user } = useCurrentUser();

  const orgId$ = useObservable<string | undefined, (string | undefined)[]>(
    (inputs$) => inputs$.pipe(switchMap(([orgId]) => of(orgId))),
    [user?.currentOrganization.organizationId]
  );

  useInitSystemMapFullNotificationSubject();
  useInitSystemMapUpdateNotificationSubject();
  useInitFlowLoopNotificationSubject();
  useInitUnreadAlertsCountSubject();
  useInitHomeDashboardNotificationSubject();
  useObservableState<unknown>(() => invokeAlertSubscription(orgId$), undefined);
  useAlertSnackbarSubscription();
};
