enum QUERY_KEY {
  PIN_DEVICES = 'pin-devices',
  PIN_DEVICE = 'pin-device',
  DEVICE = 'device',
  HOME_LOCATIONS = 'home-locations',
}

export const queryKey = {
  PIN_DEVICES: () => [QUERY_KEY.PIN_DEVICES],
  PIN_DEVICE: (id: string) => [QUERY_KEY.PIN_DEVICE, id],
  DEVICE: (id: string) => [QUERY_KEY.DEVICE, id],
  HOME_LOCATIONS: () => [QUERY_KEY.HOME_LOCATIONS],
};
