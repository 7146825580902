// NOTE: resolve module boundaries config
/* eslint-disable @nx/enforce-module-boundaries */
import {
  AUTOMATION_RULE_TYPE,
  useCancelSnoozeAutomation,
  useSnoozeAutomation,
} from '@marlin/alert/data-access/automated-action';
import { CriticalityControl } from '@marlin/shared/ui-criticality';
import { CancelSnoozeModal, SnoozeModal } from '@marlin/shared/ui-domain-automation-snooze';
import { useModal } from '@marlin/shared/ui-modal';
import { Paper } from '@marlin/shared/ui-page';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { minutesToHours } from '@marlin/shared/utils-common-date';
import { routes } from '@marlin/shared/utils-routes';
import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { AlertIntervalSection } from './alert-interval-section';
import { Channels } from './channels.component';
import { content } from './content';
import { DetailsItem } from './details-item.component';
import { formatSystemTriggers, formatTriggers } from './formatters';
import { HeaderId } from './header-id.component';
import { CardHeaderSimple } from './header-simple.component';
import { CardHeader } from './header.component';
import { LinkedItem, LinkedLocationItem } from './linked-items.component';
import { IExtendedAutomation } from './types';

export const useStyles = makeStyles()(() => ({
  disabled: {
    opacity: 0.6,
    filter: 'gray',
    '-webkit-filter': 'grayscale(100%)',
    '-moz-filter': 'grayscale(100%)',
    '-ms-filter': 'grayscale(100%)',
    '-o-filter': 'grayscale(100%)',
  },
}));

interface IAutomationCardProps extends Record<string, unknown> {
  item: IExtendedAutomation;
  handleToggle: (isEnabled: boolean) => void;
  handleEditItem: () => void;
  handleDeleteItem: () => void;
  currentUrl: string;
  allowEdit?: boolean;
  allowDelete?: boolean;
  healthTab?: boolean;
  informationTab?: boolean;
}

export function AutomationCardUi({
  item,
  handleToggle,
  handleEditItem,
  handleDeleteItem,
  currentUrl,
  allowDelete,
  allowEdit,
  healthTab,
  informationTab,
}: IAutomationCardProps) {
  const { classes, cx } = useStyles();
  const { mutateAsync: snooze } = useSnoozeAutomation();
  const { mutateAsync: cancelSnooze } = useCancelSnoozeAutomation();
  const { enqueueSnackbar } = useSnackbar();
  const [snoozeAlertModal, openSnoozeAlertModal, closeSnoozeAlertModal] = useModal();
  const [cancelSnoozedAlertModal, openCancelSnoozedAlertModal, closeCancelSnoozedAlertModal] = useModal();

  const isDeviceError = item.ruleType === AUTOMATION_RULE_TYPE.DEVICE_ERROR;

  const isDeviceAlert = item.ruleType === AUTOMATION_RULE_TYPE.DEVICE_ALERT;

  const title = isDeviceError ? content.EQUIPMENT_NAME_ERRORS(item.name || '') : item.name;

  const renderTriggerValue = () => {
    return healthTab || informationTab
      ? formatSystemTriggers(item)
      : isDeviceAlert
      ? content.ALL_EQUIPMENT_ALERTS
      : formatTriggers(item.conditions, item.device?.type);
  };

  const handleSnoozeAlertClick = () => {
    openSnoozeAlertModal();
  };

  const handleSubmitSnoozeTime = async (minutes: number, isSystemAutomation: boolean) => {
    closeSnoozeAlertModal();
    try {
      await snooze({ id: item.id, minutes, deviceId: item.device?.id || '' });
      if (isSystemAutomation) {
        enqueueSnackbar(
          content.SNOOZE_SYSTEM_SUCCESS(
            title,
            String(minutesToHours(minutes)),
            minutesToHours(minutes) > 1 ? content.HOURS : content.HOUR,
            item.device?.name || ''
          ),
          {
            variant: 'success',
            preventDuplicate: true,
          }
        );
      } else {
        enqueueSnackbar(
          content.SNOOZE_SUCCESS(
            title,
            String(minutesToHours(minutes)),
            minutesToHours(minutes) > 1 ? content.HOURS : content.HOUR
          ),
          {
            variant: 'success',
            preventDuplicate: true,
          }
        );
      }
    } catch (e) {
      enqueueSnackbar(content.SNOOZE_ERROR, {
        variant: 'error',
        preventDuplicate: true,
      });
    }
  };

  const handleSubmitCancelSnooze = async (isSystemAutomation: boolean, deviceName: string) => {
    closeCancelSnoozedAlertModal();
    try {
      await cancelSnooze({ automationId: item.id, deviceId: item.device?.id || '' });
      if (isSystemAutomation) {
        enqueueSnackbar(content.CANCEL_SNOOZE_SUCCESS_SYSTEM(title, deviceName), {
          variant: 'success',
          preventDuplicate: true,
        });
      } else {
        enqueueSnackbar(content.CANCEL_SNOOZE_SUCCESS(title), {
          variant: 'success',
          preventDuplicate: true,
        });
      }
    } catch (e) {
      enqueueSnackbar(content.CANCEL_SNOOZE_ERROR, {
        variant: 'error',
        preventDuplicate: true,
      });
    }
  };

  return (
    <Grid item xs={12}>
      <Paper testId="card">
        {healthTab || informationTab ? (
          <CardHeaderSimple
            title={title}
            id={item.id}
            enabled={item.isEnabled}
            onToggle={handleToggle}
            onEditButtonClick={handleEditItem}
            allowEdit={allowEdit}
            handleSnoozeAlertClick={handleSnoozeAlertClick}
            openCancelSnoozedAlertModal={openCancelSnoozedAlertModal}
            snoozeUntil={item.snoozeUntil}
            deviceId={item.device?.id}
          />
        ) : (
          <CardHeader
            title={title}
            id={item.id}
            deviceId={item.device?.id}
            enabled={item.isEnabled}
            onToggle={handleToggle}
            onEditButtonClick={handleEditItem}
            onDeleteButtonClick={handleDeleteItem}
            snoozeUntil={item.snoozeUntil}
            allowDelete={allowDelete}
            allowEdit={allowEdit}
            isDeviceAlert={isDeviceAlert}
            handleSnoozeAlertClick={handleSnoozeAlertClick}
            openCancelSnoozedAlertModal={openCancelSnoozedAlertModal}
          />
        )}
        <Grid container className={cx({ [classes.disabled]: !item.isEnabled })}>
          <Grid item xs={6}>
            <DetailsItem label={content.CARD_LABELS.TRIGGER} value={renderTriggerValue()} testId="automation-trigger" />
            <DetailsItem
              label={content.CARD_LABELS.ACTION}
              value={<Channels channels={item.channels} />}
              testId="automation-actions"
            />

            {!healthTab && !informationTab && !isDeviceAlert && (
              <DetailsItem
                label={content.ALERTS}
                testId="automation-alert-interval"
                value={
                  <AlertIntervalSection automationMode={true} snoozeIntervalInMinutes={item.snoozeIntervalInMinutes} />
                }
              />
            )}
          </Grid>
          <Grid item xs={6}>
            {item.location && (
              <DetailsItem
                label={content.CARD_LABELS.LOCATION}
                value={<LinkedLocationItem location={item.location} rootLocation={item.rootLocation} />}
                testId="automation-location"
              />
            )}
            {item.equipment && (
              <DetailsItem
                label={content.CARD_LABELS.EQUIPMENT}
                value={<LinkedItem item={item.equipment} goTo={(id: string) => routes.equipments.details.url(id)} />}
                testId="automation-equipment"
              />
            )}
            {item.device && !informationTab && !healthTab && !isDeviceAlert && (
              <DetailsItem
                label={content.CARD_LABELS.SENSOR}
                value={
                  <LinkedItem item={item.device} goTo={(id: string) => routes.deviceDrawer.open(currentUrl, id)} />
                }
                testId="automation-device"
              />
            )}
            <DetailsItem
              label={content.CARD_LABELS.CRITICALITY}
              value={<CriticalityControl severity={item.criticality} />}
              testId="automation-criticality"
            />
            <HeaderId id={item.id} data-testid="card-id" />
          </Grid>
        </Grid>
      </Paper>
      {snoozeAlertModal && (
        <SnoozeModal
          automationName={title}
          onSubmit={handleSubmitSnoozeTime}
          onClose={closeSnoozeAlertModal}
          deviceName={item.device?.name}
        />
      )}
      {cancelSnoozedAlertModal && item.snoozeUntil && (
        <CancelSnoozeModal
          deviceName={item.device?.name || ''}
          automationName={title}
          snoozeUntil={item.snoozeUntil}
          onSubmit={handleSubmitCancelSnooze}
          onClose={closeCancelSnoozedAlertModal}
        />
      )}
    </Grid>
  );
}
