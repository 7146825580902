export const content = {
  ALERTS_TITLE: 'Alerts',
  NO_DATA: 'No results found.',
  ERROR: 'Something went wrong. Please try again later.',
  TABS: {
    CURRENT: 'Current',
    SNOOZED: 'Snoozed',
    RESOLVED: 'Resolved',
    ALL: 'All',
  },
  FILTERS: {
    LABEL_LOCATION: 'Location',
    LABEL_EQUIPMENT: 'Equipment',
    LABEL_SENSOR: 'Sensor',
    LABEL_SENSOR_TYPE: 'Sensor Type',
    CLEAR_ALL: 'Clear All',
    LABEL_SEARCH: 'Search',
    SENSOR_TYPES: {
      TEMPERATURE: 'Temperature Sensor',
      PRESSURE: 'Pressure Sensor',
      LEAK: 'Leak Detector',
      FLOW_METER: 'Flow Meter',
    },
    EQUIPMENT: 'Equipment',
    GATEWAY: 'Gateway',
  },
  CARD: {
    CURRENT: {
      TITLE: {
        LOWER: (min?: number) => `fell below ${min}`,
        HIGHER: (max?: number) => `exceeded ${max}`,
        LEAK: `detected a leak`,
      },
      LABEL: {
        EMAIL: 'Emailed',
        TEXT: 'Texted',
        START_TIME: 'Start Time',
        RESOLVED_TIME: 'Resolved Time',
      },
      LAST_READING: {
        MINUTES: (minutes: number): string => `LAST READING as of ${minutes}min ago`,
        HOURS: (hours: number, minutes: number): string => `LAST READING as of ${hours}h ${minutes}min ago`,
      },
      SENSOR_TYPES: {
        TEMPERATURE: 'Temperature',
        PRESSURE: 'Pressure',
        LEAK: 'Leak',
      },
      LOST_COMMUNICATION: 'Lost Communication',
      LOW_BATTERY: 'Low Battery',
    },
    RECIPIENTS: {
      UNKNOWN: 'Unknown',
    },
    DETAILS: {
      SUMMARY: 'Details',
      ACTIONS: 'Actions',
      HIERARCHY: 'Hierarchy',
      DESCRIPTION: 'Description',
    },
  },
  UNKNOWN_SENSOR: 'N/A',
  SNOOZED_UNTIL: 'SNOOZED UNTIL',
  CANCEL_SNOOZE_SUCCESS: (automationName: string) => `Snooze for ${automationName} have been successfully canceled`, //TODO - NOT OFFICAL COPY
  CANCEL_SNOOZE_SUCCESS_SYSTEM: (automationName: string, sensorName: string) =>
    `Snooze for ${sensorName} for ${automationName} have been successfully canceled`, //TODO - NOT OFFICAL COPY
  CANCEL_SNOOZE_ERROR: 'Error during canceling snooze.', //TODO - NOT OFFICAL COPY
  SNOOZE_SUCCESS: (automationName: string, hours: string, hourText: string) =>
    `Alert notification will be snoozed for Automation ${automationName} for ${hours} ${hourText}`,
  SNOOZE_SYSTEM_SUCCESS: (automationName: string, hours: string, hourText: string, sensorName: string) =>
    `Alert notification will be snoozed for ${sensorName} for Automation ${automationName} for ${hours} ${hourText}`,
  SNOOZE_ERROR: 'Error during snooze automation.', //TODO - NOT OFFICAL COPY
  HOURS: 'hours',
  HOUR: 'hour',
  ALERT_INTERVAL: (alertLimitValue: string) => `Snooze Interval set to ${alertLimitValue} mins`,
  ALERT_UPDATE_AVAILABLE: 'Update available: Refresh page',
  ALERT_UPDATE_SUCCESS: 'Data is up to date',
};
