import { MarlinTheme } from '@marlin/shared/theme';
import { CRITICALITY, CriticalityControl, TCriticality } from '@marlin/shared/ui-criticality';
import { alpha } from '@mui/material';
import { useController } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { IFilterParams } from './types';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  criticalityWrapper: {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(12)} ${theme.typography.pxToRem(
      10
    )} ${theme.typography.pxToRem(12)}`,
    color: theme.palette.text.primary,
    height: theme.typography.pxToRem(40),
    border: `${theme.typography.pxToRem(1)} solid ${alpha(theme.palette.primary.main, 0.5)}`,
    borderRadius: theme.typography.pxToRem(50),
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.04),
    },
  },
  selected: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
  },
  disabled: {
    cursor: 'not-allowed',
    opacity: 0.5,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

interface ICriticalityButton {
  severity: TCriticality;
  disabled?: boolean;
}

export const CriticalityButton = ({ severity, disabled = false }: ICriticalityButton) => {
  const { classes, cx } = useStyles();

  const {
    field: { value: criticalityHigh, onChange: onChangeCriticalityHigh },
  } = useController<IFilterParams>({ name: 'criticality.high' });

  const {
    field: { value: criticalityLow, onChange: onChangeCriticalityLow },
  } = useController<IFilterParams>({ name: 'criticality.low' });

  return (
    <div
      onClick={() => {
        if (disabled) return;
        if (severity === CRITICALITY.HIGH) {
          onChangeCriticalityHigh(!criticalityHigh);
        }
        if (severity === CRITICALITY.LOW) {
          onChangeCriticalityLow(!criticalityLow);
        }
      }}
      className={cx(
        classes.criticalityWrapper,
        disabled && classes.disabled,
        criticalityHigh && severity === CRITICALITY.HIGH && classes.selected,
        criticalityLow && severity === CRITICALITY.LOW && classes.selected
      )}
    >
      <CriticalityControl isCriticalityButton severity={severity} />
    </div>
  );
};
