import { content } from '../../content';
import { BaseError } from './base-error.component';

export interface IRegistrationDataErrorProps {
  handleButtonClick?: () => void;
}

export const RegistrationDataError = ({ handleButtonClick }: IRegistrationDataErrorProps) => {
  return (
    <BaseError
      title={content.ERROR.REGISTRATION_DATA.TITLE}
      subtitle={content.ERROR.REGISTRATION_DATA.SUBTITLE}
      buttonText={content.ERROR.REGISTRATION_DATA.BUTTON}
      handleButtonClick={handleButtonClick}
    />
  );
};
