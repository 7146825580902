import { AvailableWarrantyModel, TAvailableWarrantyModel } from '@marlin/shared/utils/zod';

export class WarrantyStorageService {
  private key = 'isWarranty';

  public set = (lock: TAvailableWarrantyModel) => {
    sessionStorage.setItem(this.key, lock);
  };

  public get = (): TAvailableWarrantyModel | null => {
    const lock = sessionStorage.getItem(this.key);
    const parsed = AvailableWarrantyModel.safeParse(lock);
    if (parsed.success) {
      return parsed.data;
    }

    return null;
  };

  public clear = () => {
    sessionStorage.removeItem(this.key);
  };
}

export const warrantyStorageService = new WarrantyStorageService();
