import { ISideNavOption } from '../types';
import SideNavLink from './side-nav-link.component';

interface INestedLinksProps {
  options: ISideNavOption[];
  isCollapsed: boolean;
  desktopNavbarLocked: boolean;
}

export const NestedLinks = ({ options, isCollapsed, desktopNavbarLocked }: INestedLinksProps) => {
  return (
    <div>
      {options.map((opt) => {
        return (
          <SideNavLink
            isCollapsed={isCollapsed}
            desktopNavbarLocked={desktopNavbarLocked}
            key={opt.to}
            to={opt.to}
            Icon={opt.Icon}
            title={opt.title}
            isNested
          />
        );
      })}
    </div>
  );
};
