import { MarlinTheme } from '@marlin/shared/theme';
import { Icon, Typography } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.typography.pxToRem(8),
    alignItems: 'center',
  },
  orgNameIcon: {
    color: theme.palette.action.active,
  },
  text: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: theme.typography.pxToRem(22),
  },
}));

interface IOrganizationNameCellProps {
  organizationName: string;
}

export const OrganizationNameCell = ({ organizationName }: IOrganizationNameCellProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <Icon
        className={classes.orgNameIcon}
        baseClassName="material-symbols-outlined"
        data-testid="organization-switcher-icon"
      >
        location_home
      </Icon>
      <Typography className={classes.text}>{organizationName}</Typography>
    </div>
  );
};
