import { z } from 'zod';

const automationIdSchema = z.string();

const snoozeAutomationParamsSchema = z.object({
  id: automationIdSchema,
  minutes: z.number(),
  deviceId: z.string(),
});

const cancelSnoozeAutomationParamsSchema = z.object({
  automationId: automationIdSchema,
  deviceId: z.string(),
});

export const snoozeAutomationParamsDtoSchema = snoozeAutomationParamsSchema.transform((requestParams) => ({
  automationId: requestParams.id,
  minutes: requestParams.minutes,
  deviceId: requestParams.deviceId,
}));

export type TAutomationId = z.infer<typeof automationIdSchema>;
export type TSnoozeAutomationParams = z.infer<typeof snoozeAutomationParamsSchema>;
export type TSnoozeAutomationParamsDto = z.infer<typeof snoozeAutomationParamsDtoSchema>;
export type TCancelSnoozeAutomationParams = z.infer<typeof cancelSnoozeAutomationParamsSchema>;
