import { MarlinTheme } from '@marlin/shared/theme';
import { Link } from '@marlin/shared/ui-page';
import { routes } from '@marlin/shared/utils-routes';
import { TGraphAsset } from '@marlin/system-map/data-access/system-map';
import DeviceHubRoundedIcon from '@mui/icons-material/DeviceHubRounded';
import { Handle, Node, NodeProps, Position, useReactFlow, useStore } from '@xyflow/react';
import { makeStyles } from 'tss-react/mui';

import { maxLabelLength } from '../../const';
import { calculateCeilSnappedValue } from '../../use-snap-to-grid.hook';
import { useCommonStyles } from '../common.styles';
import { NodeTooltip } from '../utils/node-tooltip.component';

interface INodeStyleProps {
  nodeHeight?: number | null;
  nodeWidth?: number | null;
}

export const useStyles = makeStyles<INodeStyleProps>()((theme: MarlinTheme, { nodeWidth, nodeHeight }) => ({
  equipment: {
    borderStyle: 'solid',
    borderColor: theme.palette.systemMap.main,
    borderWidth: theme.typography.pxToRem(1),
    borderRadius: theme.typography.pxToRem(8),
    backgroundColor: theme.palette.background.primary,
    padding: theme.typography.pxToRem(8),
  },
  emptyEquipment: {
    width: nodeWidth ? calculateCeilSnappedValue(nodeWidth) : 'auto',
    height: nodeHeight ? calculateCeilSnappedValue(nodeHeight) : 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  equipmentLabel: {
    marginRight: theme.typography.pxToRem(8),
    whiteSpace: 'nowrap',
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
}));

type TNodeData = Node<TGraphAsset['data'] & { label: string }>;

export const UnregisteredEquipmentNode = ({ data, id }: NodeProps<TNodeData>) => {
  const { getNode } = useReactFlow();
  const node = getNode(id);
  const { classes, cx } = useStyles({ nodeWidth: node?.measured?.width, nodeHeight: node?.measured?.height });
  const { classes: commonClasses } = useCommonStyles();
  const draggable = useStore((store) => store.nodesDraggable);

  const sliceName = !!(data.name && data.name?.length > maxLabelLength);

  const content = (
    <NodeTooltip text={data.name || ''} when={sliceName}>
      <div
        className={cx(commonClasses.node, classes.equipment, classes.emptyEquipment)}
        data-testid={`equipment-node-${id}`}
      >
        <div className={commonClasses.nodeContent}>
          <span className={classes.equipmentLabel} data-testid={`equipment-node-${id}-label`}>
            {sliceName ? `${data?.name?.slice(0, maxLabelLength)}...` : data.name}
          </span>
          <DeviceHubRoundedIcon />
        </div>
        <Handle type="target" id="bottom" position={Position.Bottom} className={commonClasses.handle} />
        <Handle type="target" id="left" position={Position.Left} className={commonClasses.handle} />
        <Handle type="source" id="right" position={Position.Right} className={commonClasses.handle} />
        <Handle type="source" id="top" position={Position.Top} className={commonClasses.handle} />
      </div>
    </NodeTooltip>
  );

  if (draggable) {
    return content;
  }

  return (
    <Link
      to={routes.equipments.details.url(data.assetId || '')}
      target="_blank"
      rel="noopener noreferrer"
      className={classes.link}
      disabled={draggable}
    >
      {content}
    </Link>
  );
};
