import { ChartChip } from '@marlin/asset/shared/ui/chart-chips';
import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

interface IChartSeriesToggleProps {
  series: { name: string; color: string }[];

  toggleDatapointVisibility: (name: string) => void;
  checkIfActive: (name: string) => boolean;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  chartSeriesToggle: {
    display: 'flex',
    gap: theme.typography.pxToRem(8),
    flexWrap: 'wrap',
  },
}));

export const ChartSeriesToggle = ({ series, checkIfActive, toggleDatapointVisibility }: IChartSeriesToggleProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.chartSeriesToggle}>
      {series
        .filter((data) => data.name !== '')
        .map((data) => (
          <ChartChip
            key={data.name}
            label={data.name}
            color={data.color}
            isActive={checkIfActive(data.name)}
            toggleChip={toggleDatapointVisibility}
            name={data.name}
          />
        ))}
    </div>
  );
};
