import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import MediationRoundedIcon from '@mui/icons-material/MediationRounded';
import MovingRounded from '@mui/icons-material/MovingRounded';
import { Button } from '@mui/material';

import { content } from '../content';
import { useStyles } from './feature-card.component.styles';

export enum TYPE {
  SYSTEM_MAP = 'SYSTEM_MAP',
  ANALYTICS = 'ANALYTICS',
}

export type TType = TYPE.SYSTEM_MAP | TYPE.ANALYTICS;

type TSmallCardProps = {
  type: TType;
  devicesCount: number;
};

const selectTextForCard = (type: TType, devicesCount: number): string => {
  if (type === 'SYSTEM_MAP') {
    if (devicesCount > 0) {
      return content.SYSTEM_MAP_INFO;
    }
    return content.SYSTEM_MAP_EMPTY;
  }
  if (devicesCount > 0) {
    return content.ANALYTICS_INFO;
  }
  return content.ANALYTICS_EMPTY;
};

const FeatureCard = ({ type, devicesCount }: TSmallCardProps) => {
  const { classes } = useStyles({ type });
  const { goTo } = useRouter();
  const text = selectTextForCard(type, devicesCount);

  const goToSystemMap = () => {
    goTo(routes.systemMap.root);
  };

  const goToAnalytics = () => {
    goTo(routes.analytics.root);
  };

  return (
    <div
      className={classes.container}
      data-testid={type === TYPE.SYSTEM_MAP ? 'systemMap-container' : 'analytics-container'}
    >
      <div className={classes.leftText}>
        <div
          className={devicesCount > 0 ? classes.title : classes.titleNoDevices}
          data-testid={type === TYPE.SYSTEM_MAP ? 'systemMap-card-subtitle' : 'analytics-card-subtitle'}
        >
          {type === TYPE.SYSTEM_MAP ? content.SYSTEM_MAP : content.ANALYTICS}
        </div>
        <div
          className={classes.text}
          data-testid={type === TYPE.SYSTEM_MAP ? 'systemMap-card-text' : 'analytics-card-text'}
        >
          {text}
        </div>
      </div>
      <div className={classes.rightItems}>
        <div
          className={devicesCount > 0 ? classes.icon : classes.iconNoDevices}
          data-testid={type === TYPE.SYSTEM_MAP ? 'systemMap-card-icon' : 'analytics-card-icon'}
        >
          {type === TYPE.SYSTEM_MAP ? <MediationRoundedIcon /> : <MovingRounded />}
        </div>
        {devicesCount > 0 ? (
          <Button
            className={classes.button}
            variant="outlined"
            onClick={type === TYPE.SYSTEM_MAP ? goToSystemMap : goToAnalytics}
            data-testid={type === TYPE.SYSTEM_MAP ? 'systemMap-card-button' : 'analytics-card-button'}
          >
            {content.VIEW}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export { FeatureCard };
