import { MarlinTheme } from '@marlin/shared/theme';
import { Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  editModeButtons: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.typography.pxToRem(16),
  },
}));

interface IFormFooterProps {
  onSubmit: () => void;
  onCancel: () => void;
}

export const Footer = ({ onCancel, onSubmit }: IFormFooterProps) => {
  const { classes } = useStyles();
  return (
    <div className={classes.editModeButtons}>
      <Button variant="outlined" onClick={onCancel} data-testid="change-org-cancel">
        {content.CANCEL}
      </Button>
      <Button variant="contained" onClick={onSubmit} data-testid="change-org-submit">
        {content.SAVE}
      </Button>
    </div>
  );
};
