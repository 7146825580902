import { RoleSchema } from '@marlin/shared/utils-role';
import { z } from 'zod';

export const invitationSchema = z.object({
  email: z.string().email(),
  role: RoleSchema,
});

export const invitationsDataSchema = z.object({ users: z.array(invitationSchema) });

const invitationResponseSchema = z.object({
  id: z.string().nullish(),
  email: z.string().nullish(),
  role: RoleSchema.nullish(),
  firstName: z.string().nullish(),
  lastName: z.string().nullish(),
  title: z.string().nullish(),
  expired: z.boolean().nullish(),
});

export const invitationsResponseDataSchema = z.array(invitationResponseSchema);

export type TInvitation = z.infer<typeof invitationSchema>;
export type TInvitationsData = z.infer<typeof invitationsDataSchema>;
export type TInvitationResponse = z.infer<typeof invitationResponseSchema>;
