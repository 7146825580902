import { MODEL } from '@marlin/shared/utils/zod';

import { content } from '../content';

const IntellistationModeMap = new Map([
  ['startup', content.MODE.INTELLISTATION.STARTUP],
  ['auto', content.MODE.INTELLISTATION.AUTO],
  ['idle', content.MODE.INTELLISTATION.IDLE],
  ['manual', content.MODE.INTELLISTATION.MANUAL],
  ['sanitize', content.MODE.INTELLISTATION.SANITIZE],
  ['cooldown', content.MODE.INTELLISTATION.COOLDOWN],
]);

const AercoModeMap = new Map([
  ['disabled', content.MODE.AERCO.DISABLED],
  ['standby', content.MODE.AERCO.STANDBY],
  ['manual', content.MODE.AERCO.MANUAL],
  ['remote', content.MODE.AERCO.REMOTE],
  ['auto', content.MODE.AERCO.AUTO],
  ['fault', content.MODE.AERCO.FAULT],
]);

const SentinelModeMap = new Map([
  ['standby', content.MODE.SENTINEL.STANDBY],
  ['home', content.MODE.SENTINEL.HOME],
  ['away', content.MODE.SENTINEL.AWAY],
]);

const SbsModeMap = new Map([
  ['off', content.MODE.SBS.AUTOMATIC],
  ['on', content.MODE.SBS.OVERRIDE],
]);

const SscModeMap = new Map([
  ['wwsd', content.MODE.SSC.WWSD],
  ['establishingsteamtemp', content.MODE.SSC.ESTABLISH_STEAM_MODE],
  ['cooldown', content.MODE.SSC.COOL_DOWN],
  ['heatoff', content.MODE.SSC.OFF],
  ['heaton', content.MODE.SSC.HEAT_ON],
  ['overrideoff', content.MODE.SSC.OVERRIDE_OFF],
  ['overridehand', content.MODE.SSC.OVERRIDE_HAND],
  ['overridemaxheat', content.MODE.SSC.OVERRIDE_MAX_HEAT],
  ['noheatabortcutoff', content.MODE.SSC.NO_HEAT_ABORT_CUTOFF],
  ['noheatnormal', content.MODE.SSC.NO_HEAT_NORMAL],
]);

export const ModeMap = new Map([
  [MODEL.INTELLISTATION, IntellistationModeMap],
  [MODEL.AERCO_WATER_HEATER_N, AercoModeMap],
  [MODEL.AERCO_BOILER_LN, AercoModeMap],
  [MODEL.AERCO_BOILER_DUAL, AercoModeMap],
  [MODEL.AERCO_WATER_HEATER_INNOVATION, AercoModeMap],
  [MODEL.SENTINEL, SentinelModeMap],
  [MODEL.TEKMAR_SMART_BOILER, SbsModeMap],
  [MODEL.TEKMAR_SMART_STEAM, SscModeMap],
]);
