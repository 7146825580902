import { TUnitOfMeasure, getFormattedValue } from '@marlin/shared/utils-format-reading';
import { TDeviceMetadataDatapointsResponse } from '@marlin/shared/utils/datapoint-mappers';

const mergeDatapointMetadata = (metadata: TDeviceMetadataDatapointsResponse[], key: string) =>
  metadata.reduce<TDeviceMetadataDatapointsResponse>((acc, item) => {
    if (item.name.toLowerCase() === key.toLowerCase()) {
      return { ...acc, ...item };
    }

    return acc;
  }, {} as TDeviceMetadataDatapointsResponse);

export const getSettingValueLabel = (
  settingName: string | undefined,
  settingUom: TUnitOfMeasure | undefined,
  metadata: TDeviceMetadataDatapointsResponse[],
  value?: string
) => {
  const mergedDatapointMetadata = mergeDatapointMetadata(metadata, settingName || '');

  if (!value) {
    return mergedDatapointMetadata.label || '';
  }

  if (settingName?.toLowerCase() === 'overridemode') {
    const { label } =
      mergedDatapointMetadata.options?.find((option) => option.name === value || String(option.id) === value) || {};

    //TODO id or name could be removed in the future we will unify the API to return only one of them
    return label || value || '';
  }

  return getFormattedValue(value, settingUom);
};
