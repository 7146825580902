import { MarlinTheme } from '@marlin/shared/theme';
import AvTimerRounded from '@mui/icons-material/AvTimerRounded';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';
import { useLastReadingTimer } from './use-last-reading-timer.hook';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  text: {
    marginTop: theme.typography.pxToRem(-20),
    marginBottom: theme.typography.pxToRem(16),
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

export const LastReadingTimer = () => {
  const { classes } = useStyles();
  const timer = useLastReadingTimer();

  if (!timer) {
    return null;
  }

  return (
    <div className={classes.text}>
      <AvTimerRounded />
      <span data-testid="reading-timer">{content.READING_AS(timer)}</span>
    </div>
  );
};
