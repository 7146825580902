import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { Typography } from '@mui/material';

import { useStyles } from './attachment-validation.styles';
import { content } from './content';

export const AttachmentUpdateValidation = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.iconContainer}>
        <div className={classes.warningIcon}>
          <WarningRoundedIcon />
        </div>
        <div>
          <Typography className={classes.attachmentInfo}>{content.UPDATE_VALIDATION}</Typography>
        </div>
      </div>
    </div>
  );
};
