import { TExtractFnReturnType, getHttpClient } from '@marlin/shared/utils/react-query';
import { useQuery } from '@marlin/shared/utils/react-query';
import {
  GatewayInfoUpdateResponsePayload,
  TGatewayInfoUpdateResponsePayload,
  safeParseData,
} from '@marlin/shared/utils/zod';

import { paths } from '../infrastructure/api';
import { queryKey } from '../query-key.enum';

export const getGateway = async (gatewayId: string): Promise<TGatewayInfoUpdateResponsePayload> => {
  const res = await getHttpClient().get<unknown, void>(paths.GET_GATEWAY, {
    params: { gatewayId },
  });

  return safeParseData(res, GatewayInfoUpdateResponsePayload);
};

type TQueryFnType = typeof getGateway;

export const useGetGateway = ({ gatewayId = '' }: { gatewayId?: string }) => {
  return useQuery<TExtractFnReturnType<TQueryFnType>>({
    queryKey: queryKey.GET_GATEWAY(gatewayId),
    queryFn: () => getGateway(gatewayId),
  });
};
