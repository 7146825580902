import BoltRoundedIcon from '@mui/icons-material/BoltRounded';
import { Typography } from '@mui/material';

import { content } from '../content';
import { useStyles } from './title.styles';

export const Title = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.header}>
      <BoltRoundedIcon className={classes.icon} />
      <Typography className={classes.headerText}>{content.UPGRADE}</Typography>
    </div>
  );
};
