import AllInclusiveRoundedIcon from '@mui/icons-material/AllInclusiveRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import NotificationsOffRoundedIcon from '@mui/icons-material/NotificationsOffRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

import { content } from '../content';
import { ITab, TAB_INDEX } from './tabs.types';

export const tabs: ITab[] = [
  {
    id: TAB_INDEX.CURRENT,
    label: content.TABS.CURRENT,
    icon: <WarningRoundedIcon />,
  },
  {
    id: TAB_INDEX.SNOOZED,
    label: content.TABS.SNOOZED,
    icon: <NotificationsOffRoundedIcon />,
  },
  {
    id: TAB_INDEX.RESOLVED,
    label: content.TABS.RESOLVED,
    icon: <CheckCircleRoundedIcon />,
  },
  {
    id: TAB_INDEX.ALL,
    label: content.TABS.ALL,
    icon: <AllInclusiveRoundedIcon />,
  },
];
