import { EQUIPMENT_TYPE } from '@marlin/shared/utils/zod';
import { AssetTypeSchema, NodeTypeSchema } from '@marlin/system-map/shared/data-access-schemas';
import isNil from 'lodash/isNil';
import * as z from 'zod';

import { content } from './content';
import { ASSET_TYPE } from './types';

const tagSchema = z.object({
  name: z.string().nullish(),
  value: z.string().nullish(),
});

const FlowNodeSchema = (required: string) =>
  z
    .object({
      id: z.string().nullish(),
      assetId: z.string().nullish(),
      type: z.nativeEnum(ASSET_TYPE).nullish(),
      name: z.string(),
      nodeType: NodeTypeSchema,
      assetType: AssetTypeSchema.nullish(),
      tags: z.array(tagSchema).nullish(),
      equipmentModel: z.nativeEnum(EQUIPMENT_TYPE).nullish(),
      equipmentHandle: z.string().nullish(),
    })
    .nullable()
    .superRefine((value, ctx) => {
      if (value === null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: required,
        });
      }

      const isEquipmentWithoutHandle = value?.tags?.some((tag) => {
        const hasModelTag = tag.name === 'Model';
        const registeredEquipmentsWithoutHandle = ['LDS', undefined, null];

        if (hasModelTag && registeredEquipmentsWithoutHandle.includes(tag?.value)) {
          return true;
        }

        if (value?.assetType === ASSET_TYPE.EQUIPMENT && !hasModelTag) {
          return true;
        }

        return false;
      });

      if (isEquipmentWithoutHandle) {
        return;
      }

      const hasHandleIdTag = value?.tags?.some((tag) => tag.name === 'HandleId');

      if (value?.assetType === ASSET_TYPE.EQUIPMENT && !hasHandleIdTag && value?.equipmentHandle === undefined) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: content.EQUIPMENT_HANDLE_REQUIRED,
          path: ['equipmentHandle'],
        });
      }
    });
export const FlowLinkSchema = z
  .object({
    inlet: FlowNodeSchema(content.VALIDATION_INLET_REQUIRED),
    outlet: FlowNodeSchema(content.VALIDATION_OUTLET_REQUIRED),
  })
  .superRefine((value, ctx) => {
    const emptyBothAssets = isNil(value.inlet?.assetId) && isNil(value.outlet?.assetId);
    const emptyBothNodes = isNil(value.inlet?.id) && isNil(value.outlet?.id);

    if (!emptyBothNodes && value.inlet?.id === value.outlet?.id) {
      // Note: needs to add 2 issues, so error would show on both fields, depending on which one was changed
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: content.VALIDATION_LOOP,
        path: ['inlet'],
      });
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: content.VALIDATION_LOOP,
        path: ['outlet'],
      });
    }

    if (!emptyBothAssets && value.inlet?.assetId === value.outlet?.assetId) {
      // Note: needs to add 2 issues, so error would show on both fields, depending on which one was changed
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: content.VALIDATION_LOOP,
        path: ['inlet'],
      });
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: content.VALIDATION_LOOP,
        path: ['outlet'],
      });
    }
  });

export const formAdapterSchema = FlowLinkSchema.transform((value) => ({
  inletData: {
    ...value.inlet,
    nodeId: value?.inlet?.id,
    nodeType: value?.inlet?.nodeType || 'ASSET',
    tags: value?.inlet?.tags,
  },
  outletData: {
    ...value.outlet,
    nodeId: value?.outlet?.id,
    nodeType: value?.outlet?.nodeType || 'ASSET',
    tags: value?.outlet?.tags,
  },
}));

export type TFlowLinkForm = z.infer<typeof formAdapterSchema>;
