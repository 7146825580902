import moment from 'moment/moment';
import { useCallback, useMemo, useState } from 'react';

import { RANGE_FILTER } from '../utils/get-time-range-with-format';

export const useRangeFilter = (defaultRangeFilter: RANGE_FILTER) => {
  const [rangeFilter, setRangeFilter] = useState<{ range: RANGE_FILTER; currentDate: moment.Moment }>({
    range: defaultRangeFilter,
    currentDate: moment().utc(),
  });
  const handleRangeFilterChange = useCallback((option?: RANGE_FILTER) => {
    setRangeFilter((prev) => ({ currentDate: moment.utc(), range: option ? option : prev.range }));
  }, []);

  const memoizedRangeFilter = useMemo(() => rangeFilter, [rangeFilter]);

  return { rangeFilter: memoizedRangeFilter, handleRangeFilterChange };
};
