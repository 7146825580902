import { content } from './content';
import type { TStatusMap } from './types';

export const statusIdToKeyAdapterSbs = (id: string): string | undefined =>
  ({
    '0': 'off',
    '1': 'on',
  }[id]);

export const statusIdToKeyAdapterSsc = (id: string): string | undefined =>
  ({
    '0': 'establishingSteamTemp',
    '1': 'establishingSteamTime',
    '2': 'heatOn',
    '3': 'heatOff',
    '4': 'coolDown',
    '5': 'wwsd',
    '6': 'noHeatNormal',
    '7': 'noHeatAbortCutoff',
    '8': 'overrideMaxHeat',
    '9': 'overrideHand',
    '10': 'overrideOff',
  }[id]);

export const tekmarSbsEquipmentModeLabelMap: TStatusMap = new Map([
  ['on', { mode: content.EQUIPMENT_MODE_SBS_LABELS.OVERRIDE, iconTag: 'sync' }],
  ['off', { mode: content.EQUIPMENT_MODE_SBS_LABELS.AUTOMATIC, iconTag: 'hdr_auto' }],
]);

export const tekmarSscEquipmentModeLabelMap: TStatusMap = new Map([
  ['wwsd', { mode: content.EQUIPMENT_MODE_SSC_LABELS.WWSD, iconTag: 'sunny' }],
  ['overrideOff', { mode: content.EQUIPMENT_MODE_SSC_LABELS.OVERRIDE_OFF, iconTag: 'mode_heat_off' }],
  ['overrideMaxHeat', { mode: content.EQUIPMENT_MODE_SSC_LABELS.OVERRIDE_MAX_HEAT, iconTag: 'mode_heat' }],
  ['overrideHand', { mode: content.EQUIPMENT_MODE_SSC_LABELS.OVERRIDE_HAND, iconTag: 'pan_tool' }],
  ['establishingSteamTemp', { mode: content.EQUIPMENT_MODE_SSC_LABELS.ESTABLISH_STEAM_MODE, iconTag: 'heat' }],
  ['heatOn', { mode: content.EQUIPMENT_MODE_SSC_LABELS.HEAT_ON, iconTag: 'mode_heat' }],
  ['heatOff', { mode: content.EQUIPMENT_MODE_SSC_LABELS.OFF, iconTag: 'mode_heat_off' }],
  ['establishingSteamTime', { mode: content.EQUIPMENT_MODE_SSC_LABELS.ESTABLISH_STEAM_MODE, iconTag: 'heat' }],
  ['coolDown', { mode: content.EQUIPMENT_MODE_SSC_LABELS.COOL_DOWN, iconTag: 'mode_cool' }],
  ['noHeatNormal', { mode: content.EQUIPMENT_MODE_SSC_LABELS.NO_HEAT_NORMAL, iconTag: 'sync' }],
  ['noHeatAbortCutoff', { mode: content.EQUIPMENT_MODE_SSC_LABELS.NO_HEAT_ABORT_CUTOFF, iconTag: 'sync' }],
]);
