import { MarlinTheme } from '@marlin/shared/theme';
import { Divider } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  separation: {
    paddingTop: theme.typography.pxToRem(16),
    paddingBottom: theme.typography.pxToRem(16),
  },
}));
const SideNavSeparator = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.separation}>
      <Divider />
    </div>
  );
};

export default SideNavSeparator;
