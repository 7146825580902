import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: theme.typography.pxToRem(380),
    backgroundColor: theme.palette.background.alternative,
    overflowY: 'auto',
    gap: theme.typography.pxToRem(8),
    paddingBottom: theme.typography.pxToRem(16),
  },
}));
