import { PinDeviceModal, PinItemToggle, UnpinDeviceModal } from '@marlin/asset/shared/ui/pin-button';

import { useDrawerPinDevice } from '../../hooks/use-drawer-pin-device.hook';

export interface IPinDeviceProps {
  deviceId: string;
}

export const PinDevice = ({ deviceId }: IPinDeviceProps) => {
  const { pinned, showModal, openModal, closeModal, handlePinDevice, handleUnpinDevice } = useDrawerPinDevice({
    deviceId,
  });

  return (
    <>
      <PinItemToggle pinned={pinned} onToggle={openModal} />

      {pinned ? (
        <UnpinDeviceModal onCancel={closeModal} onSubmit={handleUnpinDevice} isOpen={showModal} />
      ) : (
        <PinDeviceModal onCancel={closeModal} onSubmit={handlePinDevice} isOpen={showModal} />
      )}
    </>
  );
};
