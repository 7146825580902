import { Link } from '@marlin/shared/ui-page';

interface ILinkedItemProps {
  id?: string | null;
  name?: string | null;
  goTo: (value: string) => string;
}

export function LinkedItem({ id, name, goTo }: ILinkedItemProps) {
  if (!id || !name) {
    return <span>--</span>;
  }

  return (
    <Link
      onClick={(event) => {
        event.stopPropagation();
      }}
      to={goTo(id)}
    >
      {name}
    </Link>
  );
}
