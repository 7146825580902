import { useContext, useMemo } from 'react';

import { TPermission } from './available-permissions';
import PermissionContext from './permission.context';

export const usePermission = (permission: TPermission | TPermission[]): boolean => {
  const { isAllowedTo } = useContext(PermissionContext);

  return useMemo(() => isAllowedTo(permission), [permission, isAllowedTo]);
};
