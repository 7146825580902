import { MarlinTheme } from '@marlin/shared/theme';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { makeStyles } from 'tss-react/mui';

import { ProductDetailsUi } from './product-details-desktop.component';

export interface IProductDetails {
  imageSrc: string | ((type: string) => string) | undefined;
  logoSrc?: string;
  model?: string;
  equipmentModel?: string;
  serialNumber?: string | null;
  role?: string | null;
  children: React.ReactNode;
  statusPanel?: React.ReactNode;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  productDetailsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.typography.pxToRem(24),
    minWidth: '100%',
  },
  container: {
    display: 'flex',
    gap: theme.typography.pxToRem(24),
  },
}));

export const ProductDetails = (props: IProductDetails) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const { classes } = useStyles();

  if (isMobile) {
    return (
      <div className={classes.productDetailsWrapper}>
        <ProductDetailsUi {...props} />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.productDetailsWrapper}>
        <ProductDetailsUi {...props} />
      </div>
    </div>
  );
};
