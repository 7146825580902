import { dateAdapter } from '@marlin/shared/utils-common-date';
import type { TModeMap, TStatusMap } from '@marlin/shared/utils/datapoint-mappers';
import { statusIdToKeyAdapterSentinel, statusMode } from '@marlin/shared/utils/datapoint-mappers';
import moment from 'moment';

import { content } from '../../desktop/content';
import { useDatapointsContext } from '../context/datapoints.context';
import { useEquipmentDetailsConfigContext } from '../context/equipment-details-config-context';
import { useEquipmentContext } from '../context/equipment.context';
import { useLastReadingTimeCounter } from './use-last-reading-time-counter.hook';

interface IUseEquipmentStatus {
  statusDatapointName: string;
  backupStatusDatapointName?: string;
  modeMap: TModeMap;
}

interface IUseEquipmentStatusReturnValues {
  statusLabel: string;
  isSanitizing: boolean;
  displayTime: string;
  isOperational?: boolean;
  operationalStatusLabel?: string;
  operationalIconTag?: string;
}

export const useEquipmentStatus = ({
  statusDatapointName,
  modeMap,
}: IUseEquipmentStatus): IUseEquipmentStatusReturnValues => {
  const { datapoints, timestamp, getDatapoint } = useDatapointsContext();
  const { lastReadingTime } = useEquipmentContext();
  const {
    config: {
      dashboard: { liveDataExpirationSeconds },
    },
  } = useEquipmentDetailsConfigContext();

  const statusDatapoint = getDatapoint(statusDatapointName);

  const time = moment
    .parseZone(datapoints?.find((datapoint) => datapoint.name === 'localTime')?.value)
    ?.format('hh:mm A');

  const isOperational =
    getIsOperational(timestamp, lastReadingTime, liveDataExpirationSeconds) && !!statusDatapoint?.value;

  useLastReadingTimeCounter({ disableCounter: !isOperational });

  const defaultStatus = {
    statusLabel: content.EQUIPMENT_STATUS_LABELS.LOST_COMMUNICATION,
    isSanitizing: statusDatapoint?.value === 'sanitize',
    displayTime: time,
  };

  if (isOperational) {
    const equipmentModeMap: TStatusMap = statusMode[modeMap];

    const value =
      modeMap === 'sentinel' ? statusIdToKeyAdapterSentinel(statusDatapoint?.value) || '' : statusDatapoint?.value;

    const equipmentMode = equipmentModeMap.get(value);

    const statusLabel = equipmentMode?.equipmentLabel || content.EQUIPMENT_STATUS_LABELS.OPERATIONAL;

    return {
      ...defaultStatus,
      statusLabel,
      isOperational,
      operationalStatusLabel: equipmentMode?.mode,
      operationalIconTag: equipmentMode?.iconTag,
    };
  }

  return defaultStatus;
};

export const useBasicEquipmentStatus = (): IUseEquipmentStatusReturnValues => {
  const { datapoints, timestamp } = useDatapointsContext();
  const { lastReadingTime } = useEquipmentContext();
  const {
    config: {
      dashboard: { liveDataExpirationSeconds },
    },
  } = useEquipmentDetailsConfigContext();

  const time = moment
    .parseZone(datapoints?.find((datapoint) => datapoint.name === 'localTime')?.value)
    ?.format('hh:mm A');

  const isOperational = getIsOperational(timestamp, lastReadingTime, liveDataExpirationSeconds);

  useLastReadingTimeCounter({ disableCounter: !isOperational });

  const defaultStatus = {
    statusLabel: content.EQUIPMENT_STATUS_LABELS.LOST_COMMUNICATION,
    isSanitizing: false,
    displayTime: time,
  };

  if (isOperational) {
    return {
      ...defaultStatus,
      statusLabel: content.EQUIPMENT_STATUS_LABELS.OPERATIONAL,
      isOperational,
    };
  }

  return defaultStatus;
};

const getIsOperational = (
  timestamp: string | undefined,
  lastReadingTime: moment.Moment | null,
  liveDataExpirationSeconds: number
) => {
  if (timestamp) {
    const diffSeconds = moment.duration(dateAdapter.date()?.diff(dateAdapter.date(timestamp))).asSeconds();

    return diffSeconds < liveDataExpirationSeconds;
  }

  if (lastReadingTime) {
    const diffSeconds = moment.duration(dateAdapter.date()?.diff(lastReadingTime)).asSeconds();

    return diffSeconds < liveDataExpirationSeconds;
  }

  return false;
};
