import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InsertInvitationRoundedIcon from '@mui/icons-material/InsertInvitationRounded';
import { Popover, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { Moment } from 'moment/moment';
import React, { MouseEvent, useCallback, useMemo, useState } from 'react';

import { content } from './content';
import { CustomPeriodSelector } from './custom-period-selector/custom-period-selector.component';
import { PERIOD_ENUM, periodMap } from './period-map';
import { useStyles } from './period.styles';

export interface ICustomPeriodModalProps {
  fromDate: Moment | null;
  toDate: Moment | null;
}

interface IPeriodProps {
  selectedPeriod: PERIOD_ENUM;
  onChange: (event: MouseEvent<HTMLElement>, newPeriod: PERIOD_ENUM) => void;
  variant?: 'default' | 'popover';
  timezone: string;
  handleCustomRangeSet: (value: ICustomPeriodModalProps) => void;
  currentPeriod: {
    from: moment.Moment | null;
    to: moment.Moment | null;
  };
}

export const Period = ({
  selectedPeriod,
  onChange,
  variant = 'default',
  timezone,
  handleCustomRangeSet,
  currentPeriod,
}: IPeriodProps) => {
  const { classes, cx } = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [anchorCustomDialog, setAnchorCustomDialog] = useState<HTMLElement | null>(null);
  const [customSelected, setCustomSelected] = useState(false); // Custom period selected but not set

  const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onCustomRangeSet = useCallback(
    (value: ICustomPeriodModalProps) => {
      handleCustomRangeSet(value);
      handleClose();
    },
    [handleClose, handleCustomRangeSet]
  );

  const handleChange = useCallback(
    (event: MouseEvent<HTMLElement>, newPeriod: PERIOD_ENUM) => {
      if (newPeriod === PERIOD_ENUM.CUSTOM) {
        setCustomSelected(true);
        return;
      }
      setCustomSelected(false);
      onChange(event, newPeriod);
      handleClose();
    },
    [handleClose, onChange]
  );

  const handleOpenCustomDialogClick = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorCustomDialog(event.currentTarget);
  }, []);

  const handleCloseCustomDialog = useCallback(() => {
    setAnchorCustomDialog(null);
  }, []);

  const open = Boolean(anchorEl);
  const customDialogOpen = Boolean(anchorCustomDialog);

  const labelText = useMemo(
    () => <Typography className={classes.periodText}>{content.PERIOD}:</Typography>,
    [classes.periodText]
  );

  const buttonGroup = useMemo(
    () => (
      <ToggleButtonGroup value={selectedPeriod} exclusive onChange={handleChange} color="primary">
        {Object.values(PERIOD_ENUM)
          .filter((period) => period !== PERIOD_ENUM.CUSTOM)
          .map((period) => (
            <ToggleButton className={classes.toggleButton} value={period} key={period} size="small">
              <div className={classes.togglePeriodItem}>
                <Typography className={classes.buttonText}>{periodMap[period].abbr}</Typography>
              </div>
            </ToggleButton>
          ))}
        <ToggleButton
          className={cx(
            classes.toggleButton,
            classes.customToggleBtn,
            customSelected && classes.customToggleBtnSelected
          )}
          value={PERIOD_ENUM.CUSTOM}
          size="small"
          onClick={(e) => {
            handleOpenCustomDialogClick(e);
          }}
        >
          <div className={classes.togglePeriodItem}>
            {selectedPeriod === PERIOD_ENUM.CUSTOM ? (
              <Typography className={classes.buttonText}>
                {currentPeriod.from?.format('MM/DD/YY hh:mm')} - {currentPeriod.to?.format('MM/DD/YY hh:mm')}
              </Typography>
            ) : (
              <Typography className={classes.buttonText}>{periodMap['Custom'].button}</Typography>
            )}
          </div>
          <InsertInvitationRoundedIcon />
        </ToggleButton>
      </ToggleButtonGroup>
    ),
    [
      classes.toggleButton,
      classes.togglePeriodItem,
      classes.buttonText,
      classes.customToggleBtn,
      customSelected,
      classes.customToggleBtnSelected,
      cx,
      selectedPeriod,
      handleChange,
      handleOpenCustomDialogClick,
      currentPeriod.from,
      currentPeriod.to,
    ]
  );

  if (variant === 'default') {
    return (
      <div className={classes.container}>
        {labelText}
        {buttonGroup}
        <Popover
          open={customDialogOpen}
          onClose={handleCloseCustomDialog}
          anchorPosition={{
            top: anchorCustomDialog?.getBoundingClientRect().bottom ?? 0,
            left: anchorCustomDialog?.getBoundingClientRect().left ?? 0,
          }}
          anchorReference="anchorPosition"
        >
          <CustomPeriodSelector
            handleCustomRangeSet={onCustomRangeSet}
            timezone={timezone}
            onCloseCustomDialog={handleCloseCustomDialog}
          />
        </Popover>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      {labelText}
      <ToggleButtonGroup value="modal" exclusive onChange={handleClick} color="primary">
        <ToggleButton className={classes.toggleButton} value="modal" size="small">
          <div className={classes.togglePeriodItem}>
            {selectedPeriod === PERIOD_ENUM.CUSTOM ? (
              <Typography className={classes.buttonText}>
                {currentPeriod.from?.format('MM/DD/YY hh:mm')} - {currentPeriod.to?.format('MM/DD/YY hh:mm')}
              </Typography>
            ) : (
              <Typography className={classes.buttonText}>{periodMap[selectedPeriod].button}</Typography>
            )}
          </div>
          <ChevronRightIcon color="primary" />
        </ToggleButton>
      </ToggleButtonGroup>
      <Popover
        open={open}
        onClose={handleClose}
        anchorPosition={{
          top: anchorEl?.getBoundingClientRect().top ?? 0,
          left: anchorEl?.getBoundingClientRect().left ?? 0,
        }}
        anchorReference="anchorPosition"
      >
        {buttonGroup}
      </Popover>
      <Popover
        open={customDialogOpen}
        onClose={handleCloseCustomDialog}
        anchorPosition={{
          top: anchorCustomDialog?.getBoundingClientRect().bottom ?? 0,
          left: anchorCustomDialog?.getBoundingClientRect().left ?? 0,
        }}
        anchorReference="anchorPosition"
      >
        <CustomPeriodSelector
          handleCustomRangeSet={onCustomRangeSet}
          timezone={timezone}
          onCloseCustomDialog={handleCloseCustomDialog}
        />
      </Popover>
    </div>
  );
};
