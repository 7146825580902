import { MarlinTheme } from '@marlin/shared/theme';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { PropsWithChildren } from 'react';
import { makeStyles } from 'tss-react/mui';
export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  error: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.error.main,
    display: 'flex',
    alignItems: 'center',
  },

  errorText: {
    marginLeft: theme.typography.pxToRem(10),
  },
}));

export const ErrorMessage = ({ children }: PropsWithChildren) => {
  const { classes } = useStyles();
  return (
    <div className={classes.error}>
      <WarningRoundedIcon />
      <span className={classes.errorText}>{children}</span>
    </div>
  );
};
