import { DefaultOptions, Query, QueryClient } from './react-query-wrapper';

export const queryConfig: DefaultOptions = {
  queries: {
    useErrorBoundary: false,
    refetchOnWindowFocus: false,
    retry: false,
    refetchOnMount: (query: Query) => {
      return query.isStaleByTime(1000 * 5);
    },
  },
};

export const queryClient = new QueryClient({ defaultOptions: queryConfig });
