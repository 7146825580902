import { apiUnitOfMeasuresToDevice } from '@marlin/shared/utils-format-reading';
import { CRITICALITY } from '@marlin/shared/utils/zod';

import { IAlertNotification, IAlertNotificationResponse } from '../../alert.model';
import { getErrorType } from './utils';

enum CRITICALITY_RESPONSE {
  LOW = 'Low',
  HIGH = 'High',
}

enum DEVICE_TYPE_RESPONSE {
  TEMPERATURE = 'Temperature',
  PRESSURE = 'Pressure',
  LEAK = 'Leak',
  FLOW_METER = 'FlowMeter',
  PULSE_METER = 'PulseMeter',
  UNKNOWN = 'Unknown',
  GATEWAY = 'Gateway',
  EQUIPMENT = 'Equipment',
}

enum DEVICE_TYPE {
  TEMPERATURE = 'TEMPERATURE',
  PRESSURE = 'PRESSURE',
  LEAK = 'LEAK',
  PULSE_METER = 'PULSE_METER',
  UNKNOWN = 'UNKNOWN',
  GATEWAY = 'GATEWAY',
  EQUIPMENT = 'EQUIPMENT',
}

type TDeviceType = `${DEVICE_TYPE}`;
type TDeviceResponseType = `${DEVICE_TYPE_RESPONSE}`;

export const getCriticality = (criticality: CRITICALITY_RESPONSE): CRITICALITY => {
  switch (criticality) {
    case CRITICALITY_RESPONSE.HIGH: {
      return CRITICALITY.HIGH;
    }
    case CRITICALITY_RESPONSE.LOW: {
      return CRITICALITY.LOW;
    }
  }
};

export const getDeviceType = (deviceType: TDeviceResponseType): TDeviceType => {
  switch (deviceType) {
    case DEVICE_TYPE_RESPONSE.TEMPERATURE: {
      return DEVICE_TYPE.TEMPERATURE;
    }
    case DEVICE_TYPE_RESPONSE.PRESSURE: {
      return DEVICE_TYPE.PRESSURE;
    }
    case DEVICE_TYPE_RESPONSE.LEAK: {
      return DEVICE_TYPE.LEAK;
    }
    case DEVICE_TYPE_RESPONSE.FLOW_METER:
    case DEVICE_TYPE_RESPONSE.PULSE_METER: {
      return DEVICE_TYPE.PULSE_METER;
    }
    case DEVICE_TYPE_RESPONSE.UNKNOWN: {
      return DEVICE_TYPE.UNKNOWN;
    }
    case DEVICE_TYPE_RESPONSE.GATEWAY: {
      return DEVICE_TYPE.GATEWAY;
    }
    case DEVICE_TYPE_RESPONSE.EQUIPMENT: {
      return DEVICE_TYPE.EQUIPMENT;
    }
  }

  throw new Error('Unsupported type');
};

export const createAlert = (alert: IAlertNotificationResponse): IAlertNotification => {
  return {
    deviceId: alert.deviceId,
    equipmentId: alert.equipmentId,
    deviceName: alert.deviceName,
    message: alert.message,
    deviceType: getDeviceType(alert.deviceType),
    criticality: getCriticality(alert.criticality),
    organizationId: alert.organizationId,
    ruleId: alert.ruleId,
    ruleName: alert.ruleName,
    thresholdMin: alert.thresholdMin,
    thresholdMax: alert.thresholdMax,
    cause: getErrorType(alert.cause),
    uoM: apiUnitOfMeasuresToDevice.get(alert.uoM || '') || alert.uoM,
  };
};
