import * as yup from 'yup';
import * as z from 'zod';

import { content } from '../content';

// todo remove yup
export const lastNameSchema = yup
  .string()
  .label(content.LAST_NAME_CONTROL_LABEL)
  .max(40)
  .required(content.LAST_NAME_CONTROL_VALIDATION);

export const lastNameZodSchema = z
  .string()
  .max(40, content.LAST_NAME_CONTROL_VALIDATION)
  .min(1, content.LAST_NAME_CONTROL_VALIDATION);
