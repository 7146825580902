import { useMultiChartsStore } from '@marlin/asset/ui/charts';
import { MarlinTheme } from '@marlin/shared/theme';
import { SelectChangeEvent } from '@mui/material';
import { useCallback } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';
import { useSetSensorFiltering } from '../../context/sensor-filtering.context';
import { ChartType, TChartType } from '../../types';
import { SensorTypeSelect } from '../chart-form/sensor-type-select.component';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    width: '100%',
    margin: `${theme.typography.pxToRem(32)} 0 ${theme.typography.pxToRem(16)}`,
  },
}));

export const SelectChartTypeWrapper = () => {
  const { classes } = useStyles();

  const [addChart, setMultiChartsStore] = useMultiChartsStore((store) => store.addChart);
  const setSensorFiltering = useSetSensorFiltering();

  const onChartTypeChange = useCallback(
    (event: SelectChangeEvent<TChartType | ''>) => {
      const parsedValue = ChartType.safeParse(event.target.value);
      if (parsedValue.success) {
        const value = parsedValue.data ?? event.target.value;
        setMultiChartsStore({
          addChart: {
            ...addChart,
            chartType: value,
            selectedDatapoints: [],
          },
        });
        setSensorFiltering({ selectedChartType: value });
      }
    },
    [addChart, setMultiChartsStore, setSensorFiltering]
  );

  return (
    <div className={classes.wrapper}>
      <SensorTypeSelect
        label={content.ADD_CHART.CHART_TYPE}
        fullWidth
        selectedChartType={addChart.chartType}
        onChartTypeChange={onChartTypeChange}
      />
    </div>
  );
};
