import { z } from 'zod';

export const uomTypeSchema = z.union([z.literal('ft3'), z.literal('l'), z.literal('gal'), z.literal('m3')]);

export type TUomType = z.infer<typeof uomTypeSchema>;

interface IUomToggleOption {
  value: NonNullable<TUomType>;
  label: string;
}

export const uomToggleOptions: IUomToggleOption[] = [
  {
    value: 'ft3',
    label: 'ft³',
  },
  {
    value: 'l',
    label: 'L',
  },
  {
    value: 'gal',
    label: 'gal',
  },
  {
    value: 'm3',
    label: 'm³',
  },
];
