import { MarlinTheme } from '@marlin/shared/theme';
import { Button, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    minHeight: theme.typography.pxToRem(350),
    margin: '0 auto',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      maxWidth: theme.typography.pxToRem(280),
    },
  },
  button: {
    marginTop: theme.typography.pxToRem(24),
  },
}));

export const SettingsErrorPage = ({ onRefreshClick }: { onRefreshClick: () => void }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <Typography variant="h6">{content.SETTINGS_CONNECTION_ERROR_TITLE}</Typography>
      <Typography variant="subtitle1">{content.SETTINGS_CONNECTION_ERROR_BODY}</Typography>
      <Button variant="contained" color="primary" className={classes.button} onClick={onRefreshClick}>
        {content.REFRESH}
      </Button>
    </div>
  );
};
