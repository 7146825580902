import { content } from '../../content';
import { BaseError } from './base-error.component';

export interface IAlreadyRegisteredErrorProps {
  handleButtonClick?: () => void;
}

export const AlreadyRegisteredError = ({ handleButtonClick }: IAlreadyRegisteredErrorProps) => {
  return (
    <BaseError
      title={content.ERROR.ALREADY_REGISTERED.TITLE}
      subtitle={content.ERROR.ALREADY_REGISTERED.SUBTITLE}
      buttonText={content.ERROR.ALREADY_REGISTERED.BUTTON}
      handleButtonClick={handleButtonClick}
    />
  );
};
