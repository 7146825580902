import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  emptyItemContainer: {
    padding: theme.typography.pxToRem(16),
  },

  emptyItemText: {
    fontFamily: 'Inter',
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightLight,
    color: theme.palette.text.secondary,
  },
}));
