import { TLocationDetails, useLocation } from '@marlin/asset/data-access/location';
import { useParams } from 'react-router-dom';

interface ILocationDetailsHook {
  location?: TLocationDetails;
  isLoading: boolean;
}

export const useLocationDetails = (): ILocationDetailsHook => {
  const { locationId } = useParams();
  const locationDetailsQuery = useLocation({ locationId: locationId || '' });

  return {
    isLoading: locationDetailsQuery.isLoading,
    location: locationDetailsQuery.data,
  };
};
