import { EquipmentTypeEnum } from '@marlin/shared/utils/zod';
import { z } from 'zod';

import { sortingSchema } from './utils.schema';

export const getEquipmentParamsSchema = z.object({
  search: z.string().nullish(),
  locations: z.array(z.string()).nullish(),
  gatewayIds: z.array(z.string()).nullish(),
  equipmentType: z.array(EquipmentTypeEnum).nullish(),
  page: z.number(),
  pageSize: z.number(),
  sorting: sortingSchema.optional(),
  datapoints: z.array(z.string()).nullish(),
});

export type TEquipmentParamsSchema = z.infer<typeof getEquipmentParamsSchema>;
