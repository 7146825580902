import { Navigate } from 'react-router-dom';

import { TPermission } from '../available-permissions';
import { usePermission } from '../use-permission.hook';

interface IGuardProps {
  permissions: TPermission[];
  children: JSX.Element;
  redirectTo?: string;
}

export const PermissionGuard = ({ permissions, children, redirectTo }: IGuardProps): JSX.Element => {
  const allowed = usePermission(permissions);

  if (redirectTo && !allowed) {
    return <Navigate to={redirectTo} />;
  }

  if (!redirectTo && !allowed) {
    return <></>;
  }

  return children;
};
