import { MarlinTheme } from '@marlin/shared/theme';
import { createDataTestId } from '@marlin/shared/utils/testing-utils';
import { Avatar, Chip, alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export interface IChip<TChipValue extends string> {
  label: string;
  value: TChipValue;
  avatar?: string;
  state?: 'warning' | 'error';
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  chip: {
    marginRight: theme.typography.pxToRem(4),
    marginLeft: theme.typography.pxToRem(4),
    borderColor: alpha(theme.palette.primary.main, 0.5),
    background: 'transparent',
    color: theme.palette.primary.main,
    '& .MuiChip-avatar': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
    },
  },
  active: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    '&.MuiChip-clickable:hover': {
      backgroundColor: alpha(theme.palette.secondary.main, 0.08),
    },
    '& .MuiChip-avatar': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
    },
  },
  errorChip: {
    '& .MuiChip-avatar': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.error.main,
    },
  },
  warningChip: {
    '& .MuiChip-avatar': {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.warning.main,
    },
  },
}));

interface IChipGroupProps<TChipValue extends string> {
  value?: TChipValue;
  onChange?: (event: { target: { value: TChipValue } }) => void;
  chips: IChip<TChipValue>[];
  multi?: false;
  disabled?: boolean;
}

interface IChipMultiGroupProps<TChipValue extends string> {
  value?: TChipValue[];
  onChange?: (event: { target: { value: TChipValue[] } }) => void;
  chips: IChip<TChipValue>[];
  multi: true;
  disabled?: boolean;
}

export const ChipGroup = <TChipValue extends string = string>({
  value,
  onChange,
  chips,
  multi,
  disabled,
}: IChipGroupProps<TChipValue> | IChipMultiGroupProps<TChipValue>): JSX.Element => {
  const { classes, cx } = useStyles();
  if (multi) {
    const getChipValues = (chipValue: TChipValue): TChipValue[] => {
      if (value?.includes(chipValue)) {
        return value.filter((v) => v !== chipValue);
      }
      return [...(value || []), chipValue];
    };

    return (
      <>
        {chips.map((chip: IChip<TChipValue>) => (
          <Chip
            avatar={chip.avatar ? <Avatar>{chip.avatar}</Avatar> : undefined}
            key={chip.value}
            label={chip.label}
            variant="outlined"
            onClick={() => onChange && onChange({ target: { value: getChipValues(chip.value) } })}
            data-testid={createDataTestId('chip', chip.label)}
            className={cx(
              classes.chip,
              value?.includes(chip.value) && classes.active,
              chip.state === 'warning' && classes.warningChip,
              chip.state === 'error' && classes.errorChip
            )}
            disabled={disabled}
          />
        ))}
      </>
    );
  }

  return (
    <>
      {chips.map((chip: IChip<TChipValue>) => (
        <Chip
          avatar={chip.avatar ? <Avatar>{chip.avatar}</Avatar> : undefined}
          label={chip.label}
          variant="outlined"
          onClick={() => onChange && onChange({ target: { value: chip.value } })}
          data-testid={createDataTestId('chip', chip.label)}
          className={cx(
            classes.chip,
            value?.includes(chip.value) && classes.active,
            chip.state === 'warning' && classes.warningChip,
            chip.state === 'error' && classes.errorChip
          )}
          disabled={disabled}
        />
      ))}
    </>
  );
};
