import { TMutationConfig, getHttpClient, queryClient } from '@marlin/shared/utils/react-query';
import { useMutation } from '@marlin/shared/utils/react-query';
import { z } from 'zod';

import { paths } from '../infrastructure/api';
import { queryKey } from '../query-key.enum';
import { GatewayConfigurationResponse, TGatewayConfigurationResponse } from '../schema/gateway-configuration.schema';

export const loadGatewayConfiguration = async ({
  deviceId,
  configurationId,
}: {
  deviceId: string;
  configurationId: string;
}) => {
  const res = await getHttpClient().put<unknown, void>(
    paths.LOAD_CONFIGURATION_GATEWAY,
    {},
    { params: { deviceId, configurationId } }
  );

  return GatewayConfigurationResponse.parse(res);
};

type TUseUpdateGatewayOptions = {
  config?: TMutationConfig<typeof loadGatewayConfiguration>;
  onSuccess?: (data: TGatewayConfigurationResponse) => void;
};

export const useLoadGatewayConfiguration = ({ config, onSuccess }: TUseUpdateGatewayOptions = {}) => {
  return useMutation({
    retry: (failureCount, error) => failureCount < 3,
    retryDelay: 1000,
    onSuccess: (data: TGatewayConfigurationResponse) => {
      queryClient.invalidateQueries({
        queryKey: queryKey.LOAD_CONFIGURATION({ deviceId: data.deviceId, configurationId: data.id }),
      });

      onSuccess && onSuccess(data);
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      if (error instanceof z.ZodError) console.log(error.issues);
    },
    ...config,
    mutationFn: loadGatewayConfiguration,
  });
};
