import { z } from 'zod';

export const paginationSchema = z.object({
  pageSize: z.number(),
  page: z.number(),
  totalItems: z.number(),
});

export const sortingSchema = z.object({
  direction: z.union([z.literal('Ascending'), z.literal('Descending')]),
  sortBy: z.string(),
});
