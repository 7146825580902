import { useFormContext } from 'react-hook-form';

import { LocationList } from './location-list.component';
import { ILocationsHubFilters, useLocationsHub } from './use-locations-hub.hook';

export const FilteredLocationList = () => {
  const { watch } = useFormContext();
  const formState = watch();
  const filters = { ...(formState as ILocationsHubFilters) };

  if (!filters.sorting || filters.sorting.sortBy === '') {
    delete filters.sorting;
  }

  const { rows, totalItems, hasNextPage, fetchNextPage, isLoading } = useLocationsHub(filters);

  return (
    <LocationList
      rows={rows}
      totalItems={totalItems}
      hasNextPage={hasNextPage}
      fetchNextPage={fetchNextPage}
      isListLoading={isLoading}
    />
  );
};
