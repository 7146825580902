import { Grid } from '@mui/material';

import { useStyles } from './tooltip-content.styles';

interface ITooltipContent {
  tooltipHeader: string;
  tooltipOptions: string[];
  tooltipFooter?: string;
}

export const TooltipContent = ({ tooltipHeader, tooltipOptions, tooltipFooter }: ITooltipContent) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      {tooltipHeader}
      {tooltipOptions.map((description) => (
        <LineDescription description={description} />
      ))}
      {tooltipFooter}
    </div>
  );
};

const LineDescription = ({ description }: { description: string }) => {
  const { classes } = useStyles();

  return (
    <Grid container className={classes.criticalityRow}>
      <Grid item className={classes.labelItem}>
        <span className={classes.dot} />
      </Grid>
      <Grid item className={classes.descriptionItem}>
        {description}
      </Grid>
    </Grid>
  );
};
