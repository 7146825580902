import { RoleSchema } from '@marlin/shared/utils-role';
import { z } from 'zod';

import { orgAddressSchema } from './address.schema';
import { emailZodSchema } from './email.schema';

export const roleZodSchema = RoleSchema;

// TODO: merge with native keys
const translationKey = {
  DUPLICATE_EMAIL: 'This email has already been entered.',
  ORG_NAME_MUST_BE_VALIDATION: 'Organization name must be at least 3 characters long.',
  MIN_MAX: 'Must be between 3 and 80 characters',
  PROPERTY_NAME_REQUIRED: 'Property name is required',
  ADDRESS_REQUIRED: 'Address is required',
};

export const userSchema = z.object({
  email: emailZodSchema,
  role: roleZodSchema,
});

export const invitationFormSchema = z.object({
  users: z.array(userSchema).superRefine((invitations, ctx) => {
    const uniqueEmails = new Set<string>();

    invitations.forEach((invitation, index) => {
      if (uniqueEmails.has(invitation.email)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: translationKey.DUPLICATE_EMAIL,
          path: [`${index}.email`],
        });
        return;
      }
      uniqueEmails.add(invitation.email);
    });
  }),
});

export const setupOrganizationSchema = z
  .object({
    propertyName: z.string().min(3, translationKey.MIN_MAX).max(80, translationKey.MIN_MAX).nullish(),
    propertyAddress: orgAddressSchema.nullish(),
  })
  .superRefine((value, ctx) => {
    if (value.propertyName === null || value.propertyName === '') {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: translationKey.PROPERTY_NAME_REQUIRED,
        path: ['propertyName'],
      });
    }

    if (value.propertyAddress === null || !orgAddressSchema.safeParse(value.propertyAddress).success) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: translationKey.ADDRESS_REQUIRED,
        path: ['propertyAddress'],
      });
    }
  });

export type TSetupOrganization = z.infer<typeof setupOrganizationSchema>;
export type TInvitationFormValues = z.infer<typeof invitationFormSchema>;
export type TUserSchema = z.infer<typeof userSchema>;
