import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    height: 'fit-content',
  },

  noAssetsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingBottom: theme.typography.pxToRem(16),
  },

  noAssetsLine1: {
    marginBottom: theme.typography.pxToRem(20),
  },

  noAssetsLine2: {
    overflow: 'hidden',
    overflowWrap: 'break-word',
  },

  noAssetsCta: {
    width: '100%',
    marginBottom: '12px',
  },

  manageLocationButton: {
    width: '100%',
  },
}));
