import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  customStartDateWrapper: {
    padding: theme.typography.pxToRem(16),
    display: 'flex',
    gap: theme.typography.pxToRem(16),
    alignItems: 'start',
    flexDirection: 'column',
  },
  form: {
    width: '100%',
  },
  title: {
    fontSize: theme.typography.pxToRem(14),
  },
  input: {
    marginBottom: theme.typography.pxToRem(8),
    width: '100%',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    paddingBottom: theme.typography.pxToRem(8),
  },
  buttons: {
    marginLeft: 'auto',
    display: 'flex',
    gap: theme.typography.pxToRem(8),
    alignItems: 'center',
  },
  orgText: {
    color: theme.palette.text.primary,
    textAlign: 'left',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(12),
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: theme.typography.pxToRem(20),
    letterSpacing: theme.typography.pxToRem(0.4),
    fontFeatureSettings: "'liga' off, 'clig' off",
  },
}));
