import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.primary,
    boxShadow: `0 ${theme.typography.pxToRem(11)} ${theme.typography.pxToRem(15)} ${theme.typography.pxToRem(
      -7
    )} rgba(0, 0, 0, 0.2), 0 ${theme.typography.pxToRem(24)} ${theme.typography.pxToRem(38)} ${theme.typography.pxToRem(
      3
    )} rgba(0, 0, 0, 0.14), 0 ${theme.typography.pxToRem(9)} ${theme.typography.pxToRem(46)} ${theme.typography.pxToRem(
      8
    )} rgba(0, 0, 0, 0.12)`,
    borderRadius: theme.typography.pxToRem(4),
    '&>*:nth-of-type(2)': {
      backgroundColor: 'rgba(0, 111, 214, 0.04)',
    },
    [theme.breakpoints.down('md')]: {
      width: `calc(100% - ${theme.typography.pxToRem(64)})`,
      marginRight: theme.typography.pxToRem(16),
    },
  },

  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: theme.typography.pxToRem(400),
    [theme.breakpoints.down('md')]: {
      minWidth: 'unset',
    },
  },
}));
