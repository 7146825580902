import { TExtractFnReturnType, TQueryConfig, getHttpClient } from '@marlin/shared/utils/react-query';
import { useQuery } from '@marlin/shared/utils/react-query';
import { Equipment, TEquipment, safeParseData } from '@marlin/shared/utils/zod';

import { paths } from '../infrastructure/api';
import { QUERY_KEY } from './query-key.enum';

export const getEquipment = async ({ equipmentId }: { equipmentId: string }): Promise<TEquipment> => {
  const body = await getHttpClient().get(paths.EQUIPMENT, { params: { equipmentId } });
  return safeParseData(body, Equipment);
};

type TQueryFnType = typeof getEquipment;

type TUseEquipmentOptions = {
  equipmentId: string;
  config?: TQueryConfig<TQueryFnType>;
};

export const useEquipment = ({ equipmentId, config }: TUseEquipmentOptions) => {
  return useQuery<TExtractFnReturnType<TQueryFnType>>({
    ...config,
    queryKey: [QUERY_KEY.EQUIPMENT, equipmentId],
    queryFn: () => getEquipment({ equipmentId }),
  });
};
