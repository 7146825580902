import {
  IPagination,
  IPaginationResponse,
  IRecipient,
  IRecipientList,
  IRecipientListResponse,
  IRecipientResponse,
} from '../../recipient.model';

export const createPaginationDto = (pagination: IPaginationResponse): IPagination => ({
  totalItems: pagination.totalItems,
  page: pagination.page,
  pageSize: pagination.pageSize,
});

export const createRecipientDto = (recipient: IRecipientResponse): IRecipient => {
  return {
    id: recipient.id,
    firstName: recipient.firstName,
    lastName: recipient.lastName,
    phoneNumber: recipient.phoneNumber,
    email: recipient.email,
  };
};

export const createRecipientsDto = (users: IRecipientResponse[]): IRecipient[] => users.map(createRecipientDto);

export const createRecipientListDto = (response: IRecipientListResponse): IRecipientList => ({
  pagination: createPaginationDto(response.pagination),
  data: createRecipientsDto(response.data || []),
});
