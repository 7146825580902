import { MarlinTheme } from '@marlin/shared/theme';
import { Typography } from '@mui/material';
import { PropsWithChildren } from 'react';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  sectionTitle: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.text.secondary,
  },
}));

export const SectionSubtitle = ({ children }: PropsWithChildren) => {
  const { classes } = useStyles();
  return (
    <Typography className={classes.sectionTitle} variant="overline" textAlign="left">
      {children}
    </Typography>
  );
};
