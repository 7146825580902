import { ISection } from '@marlin/asset/shared/equipment-config';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import SpeedIcon from '@mui/icons-material/Speed';
import { Icon } from '@mui/material';

import { content } from '../../../content';

export const sectionConfig: ISection[] = [
  {
    title: content.SYSTEM_INPUTS,
    dataTestId: 'system-inputs-section',
    style: { fullWidth: true },
    sectionDatapoints: [
      {
        sectionName: 'heatingCallsAndTarget',
        title: content.HEATING_CALLS_AND_TARGET,
        icon: <DeviceThermostatIcon />,
        items: [
          {
            name: 'heatTarget',
            label: content.DATAPOINT_SBS_LABELS.CENTRAL_HEATING,
            dependencies: {
              override: {
                datapointName: 'heatStatus',
                value: new Map([['off', content.OFF]]),
              },
            },
          },
          {
            name: 'dhwTarget',
            label: content.DATAPOINT_SBS_LABELS.DHW_TARGER,
            dependencies: {
              override: {
                datapointName: 'dhwStatus',
                value: new Map([['off', content.OFF]]),
              },
            },
          },
          {
            name: 'setpointTarget',
            label: content.DATAPOINT_SBS_LABELS.SETPOINT_TARGET,
            dependencies: {
              override: {
                datapointName: 'setpointStatus',
                value: new Map([['off', content.OFF]]),
              },
            },
          },
        ],
      },
      {
        sectionName: 'sensors',
        title: content.SENSORS,
        icon: <SpeedIcon />,
        items: [
          {
            name: 'outdoorTemp',
            label: content.DATAPOINT_SBS_LABELS.OUTDOOR_TEMP,
            dependencies: {
              statusMapping: new Map([
                ['absent', content.EQUIPMENT_STATUS_LABELS.NOT_INSTALLED],
                ['open', content.EQUIPMENT_STATUS_LABELS.ERROR],
                ['short', content.EQUIPMENT_STATUS_LABELS.ERROR],
              ]),
            },
          },
          {
            name: 'dhwTemp',
            label: content.DATAPOINT_SBS_LABELS.DHW_TANK,
            dependencies: {
              statusMapping: new Map([
                ['absent', content.EQUIPMENT_STATUS_LABELS.NOT_INSTALLED],
                ['open', content.EQUIPMENT_STATUS_LABELS.ERROR],
                ['short', content.EQUIPMENT_STATUS_LABELS.ERROR],
              ]),
            },
          },
          {
            name: 'pressure',
            label: content.DATAPOINT_SBS_LABELS.PRESSURE,
            dependencies: {
              statusMapping: new Map([
                ['absent', content.EQUIPMENT_STATUS_LABELS.NOT_INSTALLED],
                ['open', content.EQUIPMENT_STATUS_LABELS.ERROR],
                ['short', content.EQUIPMENT_STATUS_LABELS.ERROR],
              ]),
            },
          },
        ],
      },
    ],
  },
  {
    title: content.SYSTEM_OUTPUTS_AND_CONDITION,
    dataTestId: 'system-output-and-condition-section',
    style: {
      fullWidth: true,
    },
    sectionDatapoints: [
      {
        sectionName: 'outputs',
        title: content.OUTPUTS,
        icon: <SpeedIcon />,
        items: [
          {
            name: 'combustionAirStatus',
            label: content.DATAPOINT_SBS_LABELS.COMBUSTION_AIR,
          },
          {
            name: 'wwsdStatus',
            label: content.DATAPOINT_SBS_LABELS.WARM_WEATHER_SHUTDOWN,
          },
        ],
      },
      {
        sectionName: 'conditions',
        title: content.PUMPS,
        icon: <Icon baseClassName="material-symbols-outlined">water_pump</Icon>,
        items: [
          {
            name: 'sysPumpStatus',
            label: content.DATAPOINT_SBS_LABELS.SYSTEM_PUMP,
          },
          {
            name: 'dhwPumpStatus',
            label: content.DATAPOINT_SBS_LABELS.DHW_PUMP,
          },
          {
            name: 'dhwPriorityStatus',
            label: content.DATAPOINT_SBS_LABELS.DHW_PRIORITY,
          },
        ],
      },
    ],
  },
];
