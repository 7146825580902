import { WebhookUpsertForm } from '@marlin/account-ui-webhook-upsert-form';
import { MarlinTheme } from '@marlin/shared/theme';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { NotFoundPage, PageContainer, PageHeader, Paper } from '@marlin/shared/ui-page';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';
import { useWebhookCreate } from './hooks/use-webhook-create.hook';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    maxWidth: theme.typography.pxToRem(746),
  },
}));

export function WebhookCreate() {
  const { classes } = useStyles();
  const {
    isFlowMetersLoading,
    defaultValues,
    handleSubmit,
    handleCancel,
    isFormSubmitting,
    isError,
    selectedDefaultFlowMeters,
    selectedAllFlowMeters,
  } = useWebhookCreate();

  if (isFlowMetersLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <NotFoundPage />;
  }

  return (
    <PageContainer prefix="create-webhook-page" className={classes.container}>
      <PageHeader
        prefix="create-webhook-page-header"
        title={content.WEBHOOK_CREATE_TITLE}
        subtitle={content.WEBHOOK_CREATE_SUBTITLE}
      />
      <Paper>
        <WebhookUpsertForm
          defaultValues={defaultValues}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          isFormSubmitting={isFormSubmitting}
          selectedDefaultFlowMeters={selectedDefaultFlowMeters}
          selectedAllFlowMeters={selectedAllFlowMeters}
        />
      </Paper>
    </PageContainer>
  );
}
