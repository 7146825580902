import { CopyrightFooter } from '@marlin/shared/ui-page';

import { OnboardingHeader } from './onboarding-header/onboarding-header.component';
import { OnboardingWizard } from './onboarding-wizard/onboarding-wizard.component';
import { useOnboardingWizard } from './onboarding-wizard/use-onboarding-wizard.hook';
import { useStyles } from './onboarding.styles';

export const Onboarding = () => {
  const { classes, cx } = useStyles();
  const onboardingWizard = useOnboardingWizard();

  return (
    <div className={classes.pageWrapper}>
      <div className={cx(classes.container, { [classes.errorContainer]: onboardingWizard.isShowingErrorStep })}>
        <OnboardingHeader
          hideWelcomeMessage={onboardingWizard.isShowingErrorStep}
          onboardingType={onboardingWizard.onboardingType}
        />
        <OnboardingWizard {...onboardingWizard} />
      </div>
      <CopyrightFooter />
    </div>
  );
};
