import { TDeviceTypeSchema } from '@marlin/asset/data-access/device';
import { MarlinTheme } from '@marlin/shared/theme';
import { TDateString, formatDate } from '@marlin/shared/utils-common-date';
import { TUnitOfMeasure, parseDisplayedValue } from '@marlin/shared/utils-format-reading';
import { useMemo } from 'react';
import { renderToString } from 'react-dom/server';
import { makeStyles } from 'tss-react/mui';

import { RANGE_FILTER } from '../../constant';
import { EXTENDED_RANGE_FILTER, TimestampFormat } from './chart-constants';

// ThemeProvider cannot be used here (different react context), theme is passed from parent
interface IStylesProps {
  theme: MarlinTheme;
}

const useStyles = makeStyles<IStylesProps>()((_theme, { theme }) => ({
  tooltipBody: {
    padding: 4,
    border: 'none',
    textAlign: 'center',
  },

  value: {
    fontSize: 14,
    fontWeight: 500,
  },

  date: {
    fontSize: 10,
    fontWeight: 500,
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
  noAlert: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  },
}));

interface IChartTooltipProps {
  value: string;
  date: string;
  theme: MarlinTheme;
  variant?: 'error' | 'warning';
}

const ChartTooltip = ({ value, date, theme, variant }: IChartTooltipProps) => {
  const { classes, cx } = useStyles({ theme });

  const variantClass = useMemo(() => {
    switch (variant) {
      case 'error': {
        return classes.error;
      }
      case 'warning': {
        return classes.warning;
      }
      default: {
        return classes.noAlert;
      }
    }
  }, [classes.error, classes.noAlert, classes.warning, variant]);

  return (
    <div className={cx(classes.tooltipBody, variantClass)}>
      <div className={classes.value}>{value}</div>
      <div className={classes.date}>{date}</div>
    </div>
  );
};

interface IRenderTooltipFuncProps {
  rangeFilter: RANGE_FILTER | EXTENDED_RANGE_FILTER;
  deviceType?: TDeviceTypeSchema;
  deviceName?: string;
  theme: MarlinTheme;
  uoM?: TUnitOfMeasure | null;
}
interface IApexGlobals {
  globals: {
    seriesX: TDateString[][];
    seriesNames: string[];
  };
}

interface IRenderPointTooltipProps {
  series: number[][];
  seriesIndex: number;
  dataPointIndex: number;
  w: IApexGlobals;
}

export const renderTooltip =
  ({ rangeFilter, deviceType, theme, uoM }: IRenderTooltipFuncProps) =>
  ({ series, seriesIndex, dataPointIndex, w }: IRenderPointTooltipProps) => {
    if (!deviceType) {
      return undefined;
    }

    return renderToString(
      <ChartTooltip
        date={formatDate(w.globals.seriesX[seriesIndex][dataPointIndex], TimestampFormat[rangeFilter])}
        value={parseDisplayedValue(
          series[seriesIndex][dataPointIndex].toString(),
          deviceType === 'LEAK' ? 'WaterDetect' : uoM ?? '',
          undefined,
          'detail'
        )}
        theme={theme}
      />
    );
  };
