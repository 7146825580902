import { useDefaultFlowMeters } from '@marlin/asset/data-access/device';
import { IRequestedTelemetry } from '@marlin/asset/ui/charts';
import { environment } from '@marlin/environment';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { PageContainer, SystemConstraintsPage } from '@marlin/shared/ui-page';
import { ReloadContextProvider } from '@marlin/shared/ui/chart';
import { PERMISSIONS, usePermission } from '@marlin/shared/utils-permission';
import { useState } from 'react';

import { AccumulatedVolumes } from './accumulated-volumes/accumulated-volumes';
import { SustaiabilityLayout } from './sustaiability-layout';
import { SustainabilityHeader } from './sustainability-header.component';
import { TotalWaterConsumption } from './total-water-consumption/total-water-consumption.component';
import { TotalWaterUsePerPeriod } from './total-water-use/total-water-use.component';
import { TDashboardSection } from './types';
import { WaterTraceability } from './water-traceability/water-traceability.component';

export function Sustainability() {
  const { data, isError } = useDefaultFlowMeters();
  const [fullScreenChart, setFullScreenChart] = useState<TDashboardSection | null>(null);
  const hasSankeyPermission = usePermission(PERMISSIONS.SHOW_WATER_TRACEABILITY_SECTION);

  const allowedToSeeWaterTraceability = hasSankeyPermission && environment.module.features.waterTraceability;

  const requestedTelemetry: IRequestedTelemetry[] | undefined = data?.map(
    ({ manufacturerId, deviceName: name, locationName, locationId }) => ({
      manufacturerId,
      name,
      locationName,
      locationId,
      datapoints: ['FlowRate'],
    })
  );

  if (isError) {
    return <SystemConstraintsPage />;
  }

  return (
    <ReloadContextProvider>
      <PageContainer prefix="sustainability-page">
        {!fullScreenChart && <SustainabilityHeader />}
        <SustaiabilityLayout
          totalWaterUsePerPeriod={<TotalWaterUsePerPeriod />}
          totalWaterConsumption={
            <TotalWaterConsumption fullScreenChart={fullScreenChart} handleFullScreenClick={setFullScreenChart} />
          }
          waterTraceability={allowedToSeeWaterTraceability ? <WaterTraceability /> : null}
          individualAccumulatedVolumes={
            requestedTelemetry ? (
              <AccumulatedVolumes
                requestedTelemetry={requestedTelemetry}
                fullScreenChart={fullScreenChart}
                handleFullScreenClick={setFullScreenChart}
              />
            ) : (
              <LoadingSpinner />
            )
          }
          fullScreenChart={fullScreenChart}
        />
      </PageContainer>
    </ReloadContextProvider>
  );
}
