import { TModelsMetadataResponse } from '@marlin/shared/utils/datapoint-mappers';
import { TExtractFnReturnType, useQuery } from '@marlin/shared/utils/react-query';
import { z } from 'zod';

import { getDynamicMetadata, getMetadata, getMetadataByModels } from '../infrastructure/equipment';
import { queryKey } from './query-key.enum';

type TMetadataQueryFnType = typeof getMetadata;
type TMetadataByModelsQueryFnType = typeof getMetadataByModels;
type TDynamicMetadataQueryFnType = typeof getDynamicMetadata;

interface IUseEquipmentsProps {
  equipmentId: string;
  enabled?: boolean;
}

export const useMetadata = ({ equipmentId, enabled = true }: IUseEquipmentsProps) => {
  return useQuery<TExtractFnReturnType<TMetadataQueryFnType>>({
    queryKey: queryKey.METADATA(equipmentId),
    queryFn: () => getMetadata(equipmentId),
    onError: (error) => {
      // eslint-disable-next-line no-console
      if (error instanceof z.ZodError) console.log(error.issues);
    },
    enabled: !!equipmentId && enabled,
    staleTime: Infinity,
  });
};

export const useDynamicMetadataConfig = ({ equipmentId, enabled = false }: IUseEquipmentsProps) => {
  return useQuery<TExtractFnReturnType<TDynamicMetadataQueryFnType>>({
    queryKey: queryKey.DYNAMIC_EQUIPMENT_METADATA(equipmentId),
    queryFn: () => getDynamicMetadata(equipmentId),
    onError: (error) => {
      // eslint-disable-next-line no-console
      if (error instanceof z.ZodError) console.log(error.issues);
    },
    enabled,
    staleTime: Infinity,
  });
};

export const useMetadataByModels = (models: string[], onSuccess?: (data: TModelsMetadataResponse) => void) => {
  return useQuery<TExtractFnReturnType<TMetadataByModelsQueryFnType>>({
    queryKey: queryKey.METADATA_BY_MODELS(models),
    queryFn: () => getMetadataByModels(models),
    onError: (error) => {
      // eslint-disable-next-line no-console
      if (error instanceof z.ZodError) console.log(error.issues);
    },
    enabled: !!models.length,
    onSuccess,
  });
};
