import { FormField, IFormControlProps, IFormTypedProps, Input } from '@marlin/shared/ui-form-common';
import { ControllerProps, FieldValues } from 'react-hook-form';

import { content } from '../content';
import { phoneValueMask } from './phone-number.schema';

const mask = {
  mask: phoneValueMask,
  unmask: true,
};

export function PhoneNumberControl<TFieldValues extends FieldValues>(
  props: IFormControlProps<TFieldValues>
): JSX.Element;

export function PhoneNumberControl<
  TFieldValues extends FieldValues = object,
  TName extends ControllerProps<TFieldValues>['name'] = ControllerProps<TFieldValues>['name']
>(props: IFormTypedProps<TFieldValues, TName>): JSX.Element;

export function PhoneNumberControl<
  TFieldValues extends FieldValues = object,
  TName extends ControllerProps<TFieldValues>['name'] = ControllerProps<TFieldValues>['name']
>(params: IFormControlProps<TFieldValues> | IFormTypedProps<TFieldValues, TName>) {
  if (params.control) {
    return (
      <FormField {...params}>
        {(props) => (
          <Input
            label={content.PHONE_NUMBER_CONTROL_LABEL}
            required
            mask={mask}
            disabled={params.disabled}
            testId={props.name}
            {...props}
          />
        )}
      </FormField>
    );
  }

  return (
    <FormField<TFieldValues> {...params}>
      {(props) => (
        <Input
          label={content.PHONE_NUMBER_CONTROL_LABEL}
          required
          mask={mask}
          disabled={params.disabled}
          testId={props.name}
          {...props}
        />
      )}
    </FormField>
  );
}
