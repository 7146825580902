import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import { TWebhookStatusParams, webhookStatusParamsSchema } from '../schemas/change-webhook-status.schema';
import { createWebhookResponseSchema } from '../schemas/create-webhook.schema';
import { paths } from './api';

export const changeWebhookStatus = async (data: TWebhookStatusParams) => {
  const params = safeParseData(data, webhookStatusParamsSchema);
  const response = await getHttpClient().put(paths.WEBHOOK_STATUS, undefined, { params });

  return safeParseData(response, createWebhookResponseSchema);
};
