import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

interface IUseStylesProps {
  isExtended: boolean;
}

export const useStyles = makeStyles<IUseStylesProps>()((theme: MarlinTheme, { isExtended }) => ({
  deviceReadingsSection: {
    display: 'flex',
    flexDirection: 'column',
    margin: isExtended ? 0 : theme.typography.pxToRem(16),
    flex: '1 1 0',
    height: isExtended ? '100%' : `calc(100% - ${theme.typography.pxToRem(32)})`,
    maxHeight: isExtended ? '100%' : `calc(100% - ${theme.typography.pxToRem(32)})`,
  },
  deviceReadingsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: isExtended ? 0 : theme.typography.pxToRem(28),
  },
  paper: {
    marginTop: 0,
    minHeight: '100%',
    border: 'none',
    borderRadius: 0,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  automationContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  soloRangeContainer: {
    display: 'flex',
    marginBottom: theme.typography.pxToRem(24),
    marginTop: theme.typography.pxToRem(-16),
  },
}));
