import { IFilterParams, TAutomation, useAutomations } from '@marlin/alert/data-access/automated-action';
import { isDataLoading } from '@marlin/shared/utils/react-query';
import { useMemo } from 'react';

interface IStore {
  automations?: TAutomation[];
  isLoading: boolean;
}

const defaultParams: IFilterParams = {
  deviceId: [],
  equipmentId: [],
  locationId: [],
  page: 1,
  pageSize: 10,
  isEnabled: null,
};

export const useManageAutomations = (currentDeviceId?: string): IStore => {
  const automationsQuery = useAutomations({
    params: { ...defaultParams, deviceId: currentDeviceId ? [currentDeviceId] : [] },
    enabled: !!currentDeviceId,
  });

  const isLoading = useMemo(() => isDataLoading([automationsQuery]), [automationsQuery]);

  const automations = useMemo(() => {
    if (!automationsQuery?.data) {
      return undefined;
    }

    return automationsQuery.data.data;
  }, [automationsQuery]);

  return {
    isLoading,
    automations,
  };
};
