import { MarlinTheme } from '@marlin/shared/theme';
import { TCycleNode } from '@marlin/system-map/data-access/system-map';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import { makeStyles } from 'tss-react/mui';

import { AssetLink } from './asset-link.component';
import { content } from './content';

interface ISnackbarContent {
  cycleNode: TCycleNode;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  snackbar: {
    display: 'flex',
    flexDirection: 'column',
    textDecorationLine: 'none',
    color: theme.palette.primary.contrastText,
    cursor: 'pointer',
    marginRight: theme.typography.pxToRem(20),
  },

  message: {
    display: 'flex',
    fontSize: theme.typography.pxToRem(16),
    marginBottom: theme.typography.pxToRem(7),
  },

  assets: {
    display: 'inline-flex',
    fontSize: theme.typography.pxToRem(13),
    marginBottom: theme.typography.pxToRem(7),
    alignItems: 'center',
    columnGap: theme.typography.pxToRem(4),
    flexWrap: 'wrap',
  },

  label: {
    marginTop: theme.typography.pxToRem(2),
  },

  icon: {
    height: theme.typography.pxToRem(20),
    '& > svg': {
      color: theme.palette.primary.contrastText,
    },
    marginRight: theme.typography.pxToRem(5),
  },

  sentence: {
    display: 'inline-flex',
    alignItems: 'center',
    '&::after': {
      content: '"."',
    },
  },
}));

export const SnackbarContent = ({ cycleNode }: ISnackbarContent) => {
  const { classes } = useStyles();

  return (
    <div className={classes.snackbar}>
      <div className={classes.message}>
        <span className={classes.icon}>
          <RefreshRoundedIcon />
        </span>
        <div className={classes.label}>{content.MESSAGE}</div>
      </div>
      <div className={classes.assets}>
        {content.ASSET_MESSAGE}
        <div className={classes.sentence}>
          <AssetLink
            asset={{
              type: cycleNode.assetType,
              id: cycleNode.assetId,
              name: cycleNode.name,
              deviceType: cycleNode.deviceType,
            }}
          />
        </div>
        {cycleNode.parentId && (
          <>
            {content.ASSET_PARENT_MESSAGE}
            <AssetLink asset={{ type: 'LOCATION', id: cycleNode.parentAssetId, name: cycleNode.parentLocationName }} />
          </>
        )}
      </div>
    </div>
  );
};
