import { MarlinTheme } from '@marlin/shared/theme';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { TGraphAsset } from '@marlin/system-map/data-access/system-map';
import { Handle, Node, NodeProps, Position } from '@xyflow/react';
import { makeStyles } from 'tss-react/mui';

import { useCommonStyles } from './common.styles';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  tee: {
    width: theme.typography.pxToRem(16),
    height: theme.typography.pxToRem(16),
    backgroundColor: theme.palette.systemMap.main,
    borderRadius: '50%',
    padding: 0,
  },
}));

type TNodeData = Node<TGraphAsset['data'] & { label: string }>;

export const TeeNode = ({ data, id }: NodeProps<TNodeData>) => {
  const { classes } = useStyles();
  const { classes: commonClasses } = useCommonStyles();

  return (
    <Tooltip placement="top" text={data.name || ''}>
      <div className={classes.tee} data-testid={`tee-node-${id}`}>
        <Handle type="target" id="bottom" position={Position.Bottom} className={commonClasses.handle} />
        <Handle type="target" id="left" position={Position.Left} className={commonClasses.handle} />
        <Handle type="source" id="right" position={Position.Right} className={commonClasses.handle} />
        <Handle type="source" id="top" position={Position.Top} className={commonClasses.handle} />
      </div>
    </Tooltip>
  );
};
