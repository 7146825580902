import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { useSearchParams } from '@marlin/shared/utils-router';
import { useOnViewportChange } from '@xyflow/react';
import { ReactNode, useEffect, useState } from 'react';

export const NodeTooltip = ({
  children,
  text,
  when = true,
}: {
  children?: JSX.Element;
  text: NonNullable<ReactNode>;
  when?: boolean;
}) => {
  const [tooltipEnabled, setTooltipEnabled] = useState(true);
  const [open, setOpen] = useState(false);
  // NOTE: Temporary fix for the issue with the tooltip not closing when the sensor drawer is open
  const [params] = useSearchParams();

  useOnViewportChange({
    onStart: (viewport) => {
      setTooltipEnabled(false);
    },
    onEnd: (viewport) => {
      setTooltipEnabled(true);
    },
  });

  useEffect(() => {
    const isSensorDrawerOpen = params?.size > 0;

    if (!isSensorDrawerOpen) {
      setTooltipEnabled(true);
    }
  }, [params.size]);

  const shouldRender = when && tooltipEnabled;

  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  return (
    <Tooltip placement="top" text={text} open={shouldRender && open} onOpen={onOpen} onClose={onClose}>
      <div onClick={onClose} style={{ pointerEvents: 'auto' }}>
        {children}
      </div>
    </Tooltip>
  );
};
