// NOTE: resolve module boundaries config
/* eslint-disable @nx/enforce-module-boundaries */
import { TDeviceType, TMeterType } from '@marlin/asset/data-access/device';
import { CardHeader, DetailsItem, LinkedItem } from '@marlin/asset/shared/ui/hub-card';
import { MarlinTheme } from '@marlin/shared/theme';
import { SensorType } from '@marlin/shared/ui-device-type';
import { Paper } from '@marlin/shared/ui-page';
import { TLastReadingValuesSchema } from '@marlin/shared/utils-format-reading';
import DeviceHubRoundedIcon from '@mui/icons-material/DeviceHubRounded';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';
import { useNavigation } from './hooks/use-navigation.hook';
import { useRouter } from './hooks/use-router.hook';
import { Readings } from './readings/readings.component';

interface IAssetCard {
  id: string;
  name: string;
  deviceType?: TDeviceType;
  meterType?: TMeterType;
  locationId?: string | null;
  locationName?: string | null;
  equipmentId?: string | null;
  equipmentName?: string | null;
  lastReadingValues?: TLastReadingValuesSchema[];
}

interface IDeviceCardProps {
  asset: IAssetCard;
  isEquipment: boolean;
  children: JSX.Element;
  showEquipment?: boolean;
  addSensorsFromContext: () => void;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  card: {
    borderRadius: theme.typography.pxToRem(4),
    margin: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(16)}`,
    '&.MuiPaper-root': {
      padding: theme.typography.pxToRem(16),
    },
  },

  deviceType: {
    marginRight: theme.typography.pxToRem(8),
    '& > svg': {
      fontSize: theme.typography.pxToRem(20),
    },
  },
}));

export const AssetMobileCard = ({ asset, isEquipment, addSensorsFromContext, children }: IDeviceCardProps) => {
  const { classes } = useStyles();
  const { getLocationDetailsPageLink } = useNavigation();
  const { goToDeviceDrawerOnPage, goToEquipmentDetailsPage } = useRouter();

  return (
    <div
      onClick={(event) => {
        if (isEquipment) {
          goToEquipmentDetailsPage(asset.id);
        }

        if (!isEquipment) {
          addSensorsFromContext();
          goToDeviceDrawerOnPage(asset.id);
        }

        event.stopPropagation();
        event.preventDefault();
      }}
    >
      <Paper className={classes.card} testId={`assetMobileCard-${asset.id}`}>
        {isEquipment ? (
          <CardHeader
            asset={asset}
            icon={
              <span className={classes.deviceType}>
                <DeviceHubRoundedIcon />
              </span>
            }
            goTo={goToEquipmentDetailsPage}
            data-testid="equipment-card-header"
          >
            {children}
          </CardHeader>
        ) : (
          <>
            <CardHeader
              asset={asset}
              icon={
                <SensorType
                  deviceType={asset.deviceType}
                  showName={false}
                  className={classes.deviceType}
                  meterType={asset.meterType}
                />
              }
              data-testid="device-card-header"
            >
              {children}
            </CardHeader>
            <Readings lastReadingValues={asset.lastReadingValues} />
          </>
        )}
        <DetailsItem
          label={content.LOCATION_HEADER_NAME}
          testId={isEquipment ? 'equipment-card-location' : 'device-card-location'}
          value={<LinkedItem id={asset.locationId} name={asset.locationName} goTo={getLocationDetailsPageLink} />}
        />
      </Paper>
    </div>
  );
};
