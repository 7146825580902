import { MarlinTheme } from '@marlin/shared/theme';
import ManageAccountsRounded from '@mui/icons-material/ManageAccountsRounded';
import MapsHomeWorkRoundedIcon from '@mui/icons-material/MapsHomeWorkRounded';
import { Icon } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  orgIcon: {
    color: theme.palette.primary.contrastText,

    [theme.breakpoints.down('lg')]: {
      color: 'inherit',
    },
  },
}));

interface IOrganizationSwitcherIconProps {
  variant: 'marlin' | 'portfolio' | 'support';
}

export const OrganizationSwitcherIcon = ({ variant }: IOrganizationSwitcherIconProps) => {
  const { classes } = useStyles();

  switch (variant) {
    case 'marlin':
      return (
        <Icon
          className={classes.orgIcon}
          baseClassName="material-symbols-outlined"
          data-testid="organization-switcher-icon"
        >
          location_home
        </Icon>
      );

    case 'portfolio':
      return <MapsHomeWorkRoundedIcon className={classes.orgIcon} data-testid="portfolio-switcher-icon" />;
    case 'support':
      return <ManageAccountsRounded className={classes.orgIcon} data-testid="support-switcher-icon" />;
  }
};
