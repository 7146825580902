import { MarlinTheme } from '@marlin/shared/theme';
import {
  IChartData,
  IChartSeries,
  TChartDisplayType,
  TChartType,
  findMinMax,
  getDataWithoutMargins,
  parseToChartData,
} from '@marlin/shared/utils-chart';
import { useTheme } from '@mui/material';
import { useMemo } from 'react';

import { flowVolumeDatapointNames, flowVolumeToFlowRateDatapointNameMap } from '../const';
import { IExtendedIChartSeries, TSelectedDatapoint, TTelemetryData } from '../types';
import { sortData } from '../utils/sort-chart-series';
import { squashEmptyValues } from '../utils/squash-empty-values';
import { ITelemetryDeviceParam } from './use-parsed-active-datapoints';
import { useTotalVolume } from './use-total-volume';

interface IUseChartDataProcessing {
  data: TTelemetryData;
  chartDisplayType: TChartDisplayType;
  bucketPerMinute: number;
  minSelection: number;
  maxSelection: number;
  chartDatapoints: TSelectedDatapoint[];
  parsedActiveDatapoints: ITelemetryDeviceParam[];
  chartType: TChartType;
}

export const useChartDataProcessing = ({
  data,
  chartDisplayType,
  bucketPerMinute,
  minSelection,
  maxSelection,
  chartDatapoints,
  parsedActiveDatapoints,
  chartType,
}: IUseChartDataProcessing) => {
  const theme = useTheme<MarlinTheme>();

  const squashedData = useMemo(() => {
    if (chartDisplayType === 'bar') {
      const dataToSquash = data.map((telemetry) => {
        return flowVolumeDatapointNames.includes(telemetry.datapointName)
          ? {
              ...telemetry,
              datapointName:
                flowVolumeToFlowRateDatapointNameMap.get(telemetry.datapointName) ?? telemetry.datapointName,
            }
          : telemetry;
      });
      return squashEmptyValues(dataToSquash, chartDisplayType);
    }

    return squashEmptyValues(data, chartDisplayType);
  }, [chartDisplayType, data]);

  const activeDatapointsLowered = useMemo(
    () =>
      parsedActiveDatapoints.map(({ manufacturerId, datapoints }) => ({
        manufacturerId,
        datapoints: datapoints.map((item) => item.toLowerCase()),
      })),
    [parsedActiveDatapoints]
  );

  const datapointsRawData = useMemo<TTelemetryData>(
    () =>
      squashedData.filter(
        (item) =>
          activeDatapointsLowered.find(
            (activeItem) =>
              activeItem.manufacturerId === item.manufacturerId &&
              activeItem.datapoints.includes(item.datapointName.toLowerCase())
          ) &&
          chartDatapoints.find(
            (chartDatapoint) =>
              item.manufacturerId === chartDatapoint.manufacturerId &&
              item.datapointName === chartDatapoint.name &&
              chartDatapoint.isActive
          )
      ),
    [activeDatapointsLowered, chartDatapoints, squashedData]
  );

  const convertedDatapoints = useMemo(
    () => parseToChartData(datapointsRawData, chartDisplayType),
    [datapointsRawData, chartDisplayType]
  );

  const chartData = useMemo<IChartSeries[]>(() => {
    const convertedChartData: IExtendedIChartSeries[] = convertedDatapoints.map(
      ({ datapointName, values, unitOfMeasure, manufacturerId }) => {
        const datapointWithMetadata = chartDatapoints.find(
          (item) => item.manufacturerId === manufacturerId && item.name.toLowerCase() === datapointName.toLowerCase()
        );

        return {
          name: datapointWithMetadata?.equipmentName
            ? `${datapointWithMetadata?.label} - ${datapointWithMetadata?.equipmentName}`
            : datapointWithMetadata?.label ?? datapointName,
          data: values || [],
          color: datapointWithMetadata?.color ?? theme.palette.charting.aquaFusion,
          type: chartDisplayType,
          uom: unitOfMeasure ?? null,
          id: datapointWithMetadata?.equipmentName
            ? `${datapointWithMetadata?.name} - ${datapointWithMetadata?.manufacturerId}`
            : datapointName,
          manufacturerId: datapointWithMetadata?.manufacturerId,
          parentType: datapointWithMetadata?.parentType || 'sensor',
        };
      }
    );

    return sortData(convertedChartData);
  }, [convertedDatapoints, chartDatapoints, theme.palette.charting.aquaFusion, chartDisplayType]);

  const chartDataWithoutMargins = useMemo(
    () => getDataWithoutMargins(chartData, minSelection, maxSelection),
    [chartData, maxSelection, minSelection]
  );

  const { maxTotalVolumeForTimestamp, totalVolume } = useTotalVolume({
    chartType,
    chartData,
  });

  const { max, min } = useMemo(
    () =>
      findMinMax({
        points: [...chartDataWithoutMargins.reduce<IChartData[]>((acc, datapoint) => [...acc, ...datapoint.data], [])],
        preCalculatedMax: maxTotalVolumeForTimestamp,
        shouldMinBeZero: chartDisplayType === 'bar',
      }),
    [chartDataWithoutMargins, maxTotalVolumeForTimestamp, chartDisplayType]
  );

  return { chartData, chartDataWithoutMargins, min, max, totalVolume };
};
