import { DEVICE_TYPE, ERROR_TYPES } from '@marlin/shared/utils/format-alert';
import { Button } from '@mui/material';

import { content } from '../content';
import { useRouter } from '../hooks/use-router.hook';
import { TTopOpenAlert } from '../utils';
import { EmptyContent } from './empty-content';
import { TopAlertsCardItem } from './top-alerts-card-item.component';
import { useStyles } from './top-alerts-card.component.style';

type TTopAlertsCardProps = {
  topOpenAlerts:
    | Array<{
        alert: TTopOpenAlert;
        alertErrorType: ERROR_TYPES;
        alertDeviceType: DEVICE_TYPE;
        equipmentId?: string;
      }>
    | undefined;
  devicesCount: number | undefined;
  automationsCount?: number;
  equipmentId?: string;
};

const TopAlertsCard = ({ topOpenAlerts, devicesCount, automationsCount, equipmentId }: TTopAlertsCardProps) => {
  const { classes } = useStyles();
  const { goToAlertsPage } = useRouter();

  const isAddDeviceMessage = automationsCount === 0 && devicesCount === 0;
  const isCreateAutomationMessage = automationsCount === 0;

  return (
    <div className={classes.container} data-testid="alerts-container">
      <div className={classes.header}>
        <div className={classes.textContainer}>
          <div className={classes.text} data-testid="alerts-card-subtitle">
            {content.ALERTS}
          </div>
          <div className={classes.title} data-testid="alerts-card-title">
            {content.TOP_OPEN_ALERTS}
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            className={classes.button}
            variant="outlined"
            data-testid="view-alerts-button"
            onClick={goToAlertsPage}
          >
            {content.VIEW_ALERTS}
          </Button>
        </div>
      </div>
      {topOpenAlerts && topOpenAlerts?.length > 0 ? (
        <div className={classes.list}>
          {topOpenAlerts?.map(
            ({ alert, alertErrorType, alertDeviceType, equipmentId: alertEquipmentId }, index: number) => (
              <TopAlertsCardItem
                alert={alert}
                key={`${index}-${alert.deviceId}`}
                alertDeviceType={alertDeviceType}
                alertErrorType={alertErrorType}
                equipmentId={equipmentId ?? alertEquipmentId}
              />
            )
          )}
        </div>
      ) : (
        <div className={classes.listEmpty}>
          <EmptyContent isAddDeviceMessage={isAddDeviceMessage} isCreateAutomationMessage={isCreateAutomationMessage} />
        </div>
      )}
    </div>
  );
};

export { TopAlertsCard };
