import { TMutationConfig, getHttpClient, queryClient } from '@marlin/shared/utils/react-query';
import { useMutation } from '@marlin/shared/utils/react-query';

import { paths } from '../infrastructure/api';
import { queryKey } from '../query-key.enum';

export const deregisterGateway = ({ gatewayId }: { gatewayId: string }) => {
  return getHttpClient().put(paths.DEREGISTER_GATEWAY, {}, { params: { gatewayId } });
};

type TUseDeregisterGatewayOptions = {
  config?: TMutationConfig<typeof deregisterGateway>;
  onSuccess?: () => void;
};

export const useDeregisterGateway = ({ config, onSuccess }: TUseDeregisterGatewayOptions = {}) => {
  return useMutation({
    onSuccess: () => {
      // TODO: check this after everything from gateway will be done
      queryClient.invalidateQueries({ queryKey: queryKey.GATEWAY_FILTER() });
      onSuccess && onSuccess();
    },
    ...config,
    mutationFn: deregisterGateway,
  });
};
