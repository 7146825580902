import { lastReadingValuesSchema } from '@marlin/shared/utils-format-reading';
import {
  AssetTypeResponseSchema,
  AssetTypeSchema,
  AssetTypeSchemaParser,
  DeviceTypeResponseSchema,
  DeviceTypeSchema,
  DeviceTypeSchemaParser,
  UpdateAssetTypeSchema,
  UpdateDeviceTypeSchema,
} from '@marlin/system-map/shared/data-access-schemas';
import { z } from 'zod';

import { emptyTextSchema, paginationSchema, sortingSchema } from './utils.schema';

export const sublocationsParamsSchema = z.object({
  search: z.string().optional(),
  pageSize: z.number(),
  page: z.number(),
  types: z.array(AssetTypeSchema).optional(),
  deviceTypes: z.array(DeviceTypeSchema).optional(),
  subLocationIds: z.array(z.string()).optional(),
  sorting: sortingSchema.optional(),
});

export const sublocationRequestParamsSchema = sublocationsParamsSchema.transform((params) => {
  return {
    ...params,
    types: params.types?.map((type) => UpdateAssetTypeSchema.parse(type)),
    deviceTypes: params.deviceTypes?.map((type) => UpdateDeviceTypeSchema.parse(type)),
  };
});

export const sublocationSchema = z
  .object({
    id: z.string(),
    name: z.string(),
    locationId: emptyTextSchema,
    locationName: emptyTextSchema,
    equipmentId: emptyTextSchema,
    equipmentName: emptyTextSchema,
    model: emptyTextSchema,
    readingValue: z.number().nullish(),
    type: AssetTypeResponseSchema,
    deviceType: DeviceTypeResponseSchema.nullish(),
    deviceCount: z.number().optional(),
    readingValues: z.array(lastReadingValuesSchema),
  })
  .transform((sublocation) => {
    const type = AssetTypeSchemaParser.parse(sublocation.type);
    const deviceType = DeviceTypeSchemaParser.parse(sublocation.deviceType);

    return {
      ...sublocation,
      type,
      deviceType,
      deviceCount: sublocation.deviceCount ?? 0,
      lastReadingValue: sublocation.readingValue,
      lastReadingValues: sublocation.readingValues,
    };
  });

export const sublocationsResponseSchema = z.object({
  pagination: paginationSchema,
  data: z.array(sublocationSchema).nullable(),
});

export type TSublocationParamsSchema = z.infer<typeof sublocationsParamsSchema>;
export type TSublocationAssetSchema = z.infer<typeof sublocationSchema>;
export type TSublocationResponseSchema = z.infer<typeof sublocationsResponseSchema>;
