import { MarlinTheme } from '@marlin/shared/theme';
import DeviceHubRoundedIcon from '@mui/icons-material/DeviceHubRounded';
import EggRoundedIcon from '@mui/icons-material/EggRounded';
import PanoramaPhotosphereOutlinedIcon from '@mui/icons-material/PanoramaPhotosphereOutlined';
import SpeedRoundedIcon from '@mui/icons-material/SpeedRounded';
import ThermostatRoundedIcon from '@mui/icons-material/ThermostatRounded';
import { SvgIconProps } from '@mui/material';
import { FC } from 'react';
import { makeStyles } from 'tss-react/mui';
import { z } from 'zod';

import { content } from './content';

export const deviceTypeSchema = z.union([
  z.literal('PRESSURE'),
  z.literal('TEMPERATURE'),
  z.literal('LEAK'),
  z.literal('PULSE_METER'),
  z.literal('EQUIPMENT'),
]);

export const meterTypeSchema = z.union([z.literal('Gas'), z.literal('Water')]).nullish();

export type TDeviceType = z.infer<typeof deviceTypeSchema>;
export type TMeterType = z.infer<typeof meterTypeSchema>;

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  deviceTypeOption: {
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.typography.pxToRem(10),
    flexWrap: 'wrap',
  },

  deviceTypeIcon: {
    color: theme.palette.action.active,
  },
}));

export const deviceTypeOptions: Record<TDeviceType, { name: string; Icon: FC<SvgIconProps> }> = {
  TEMPERATURE: {
    name: content.SENSOR_TYPE_TEMPERATURE_LABEL,
    Icon: ThermostatRoundedIcon,
  },
  PRESSURE: {
    name: content.SENSOR_TYPE_PRESSURE_LABEL,
    Icon: SpeedRoundedIcon,
  },
  LEAK: {
    name: content.SENSOR_TYPE_LEAK_LABEL,
    Icon: EggRoundedIcon,
  },
  PULSE_METER: {
    name: content.SENSOR_TYPE_FLOW_LABEL,
    Icon: PanoramaPhotosphereOutlinedIcon,
  },
  EQUIPMENT: {
    name: content.EQUIPMENT_LABEL,
    Icon: DeviceHubRoundedIcon,
  },
};

interface ISensorTypeProps {
  deviceType?: TDeviceType;
  showName?: boolean;
  className?: string;
  meterType?: TMeterType;
}

export const SensorType = ({ deviceType, showName = true, className = '' }: ISensorTypeProps) => {
  const { classes, cx } = useStyles();

  if (!deviceType) {
    return null;
  }

  const { name, Icon } = deviceTypeOptions[deviceType];

  return (
    <div className={cx(classes.deviceTypeOption, className)}>
      <Icon className={classes.deviceTypeIcon} />
      {showName && name}
    </div>
  );
};
