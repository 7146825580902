import { TDatapointWithMetadata } from '@marlin/shared/utils/datapoint-mappers';
import moment from 'moment';

import { useDatapointsContext } from '../../context/datapoints.context';

export const useOverrideMode = () => {
  const { getDatapoint } = useDatapointsContext();
  const overrideMode = getDatapoint('modeStatus');
  const overrideTime = getDatapoint('modeVal');
  const remainingTime = overrideTime?.value
    ? moment.utc(parseInt(overrideTime.value, 10) * 1000).format('HH:mm:ss')
    : '--:--:--';

  return {
    shouldDisplayBanner: shouldDisplayBanner(overrideMode),
    remainingTime,
    overrideValue: overrideMode?.value,
  };
};

const shouldDisplayBanner = (overrideMode: TDatapointWithMetadata | undefined) => {
  return !!(overrideMode && ['heaton', 'heatoff', 'establishingsteamtime'].includes(overrideMode?.value.toLowerCase()));
};
