import { useFormContext } from 'react-hook-form';

import { EquipmentList } from './equipment-list.component';
import { IEquipmentsHubFilters, useEquipmentsHub } from './use-equipments-hub.hook';

export const FilteredEquipmentList = () => {
  const { watch } = useFormContext();
  const formState = watch();
  const filters = { ...formState };

  if (!filters.sorting || filters.sorting.sortBy === '') {
    delete filters.sorting;
  }
  const { rows, isLoading, totalItems, fetchNextPage, hasNextPage } = useEquipmentsHub(
    filters as IEquipmentsHubFilters
  );

  return (
    <EquipmentList
      rows={rows}
      isListLoading={isLoading}
      totalItems={totalItems}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
    />
  );
};
