import { useSearchFilter } from '@marlin/shared/utils/url-params';
import { useMemo } from 'react';

import { TDeviceParamsSchema } from '../infrastructure/schema/device.request.schema';
import { TDeviceSchema, TGetDeviceSchema } from '../infrastructure/schema/device.response.schema';
import { useFilteredDevicesWithLoadMore } from '../queries/get-filtered-devices.query';

interface ILocationSearchWrapper {
  options: TDeviceSchema[];
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  totalItems?: number;
  loadedItems?: number;
  isLoading?: boolean;
  isError?: boolean;
  search: (value: string) => void;
  term: string;
  isFetching: boolean;
  isFetchingNextPage: boolean;
}

interface IDeviceSearchWrapperProps {
  children: (props: ILocationSearchWrapper) => JSX.Element;
  params?: Omit<TDeviceParamsSchema, 'page' | 'pageSize' | 'search'>;
}

export function DevicesSearchWrapper({ children, params }: IDeviceSearchWrapperProps) {
  const { term, debouncedTerm, search } = useSearchFilter();
  const devicesQuery = useFilteredDevicesWithLoadMore({
    params: { search: debouncedTerm, pageSize: 10, ...params },
  });

  const options: TDeviceSchema[] = useMemo(() => {
    const unfilteredRows: (TDeviceSchema | null)[] =
      devicesQuery.data?.pages?.flatMap((i: TGetDeviceSchema) => i.data) || [];
    return unfilteredRows.filter((i: TDeviceSchema | null): i is TDeviceSchema => i !== null);
  }, [devicesQuery.data?.pages]);

  return children({
    options,
    hasNextPage: devicesQuery.hasNextPage,
    totalItems: devicesQuery.data?.pages[0].pagination.totalItems || 0,
    fetchNextPage: devicesQuery.fetchNextPage,
    isLoading: devicesQuery.isLoading,
    isError: devicesQuery.isError,
    isFetching: devicesQuery.isFetching,
    isFetchingNextPage: devicesQuery.isFetchingNextPage,
    search,
    term,
  });
}
