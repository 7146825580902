import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    alignContent: 'center',
    columnGap: theme.typography.pxToRem(8),
    paddingTop: theme.typography.pxToRem(8),
    paddingBottom: theme.typography.pxToRem(8),
  },

  orgNameContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 8,
    maxWidth: '100%',
    'overflow-x': 'hidden',
    textOverflow: 'ellipsis',
  },

  orgName: {
    fontFamily: 'Inter',
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: 'left',
    color: theme.palette.text.primary,
  },

  greenDot: {
    color: theme.palette.success.light,
    fontSize: theme.typography.pxToRem(14),
  },
}));
