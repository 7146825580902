import { TWebhook } from '@marlin/account-data-access-webhook';
import { CardHeader } from '@marlin/asset/shared/ui/hub-card';
import { MarlinTheme } from '@marlin/shared/theme';
import { ActionDelete, ActionEdit, ContextMenu } from '@marlin/shared/ui-context-menu';
import { Paper } from '@marlin/shared/ui-page';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Chip, Link, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { formatFrequency, formatWebhookType } from './formatting.utils';
import { WebhookToggle } from './webhook-toggle.component';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  tile: {
    padding: theme.typography.pxToRem(16),
  },
  tags: {
    display: 'flex',
    marginTop: theme.typography.pxToRem(12),
    gap: theme.typography.pxToRem(8),
    flexWrap: 'wrap',
  },
  menu: {
    padding: 0,
  },
}));

interface IWebhookTileProps {
  webhook: TWebhook;
  onEdit: (id: string) => void;
  onDelete: (webhook: TWebhook) => void;
}

export const WebhookTile = ({ webhook, onDelete, onEdit }: IWebhookTileProps) => {
  const { classes } = useStyles();

  return (
    <Paper className={classes.tile} testId="list-tile-item">
      <CardHeader variant="body2" asset={webhook}>
        <>
          <WebhookToggle webhookId={webhook.id} isEnabled={webhook.isEnabled} isSuspended={webhook.suspended} />
          <ContextMenu data-testid="webhook-card-menu" className={classes.menu}>
            <ActionEdit onClick={() => onEdit(webhook.id)} />
            <ActionDelete onClick={() => onDelete(webhook)} />
          </ContextMenu>
        </>
      </CardHeader>
      <Typography variant="body2" data-testid="webhook-url">
        {webhook.url}
      </Typography>
      <div className={classes.tags}>
        <Chip
          label={
            <Link href={`mailto:${webhook.managerEmail}`} data-testid="manager-email">
              {webhook.managerEmail}
            </Link>
          }
          icon={<NotificationsIcon color="action" />}
          size="small"
          data-testid="manager-email-chip"
        />
        <Chip label={formatFrequency(webhook.frequency)} size="small" data-testid="frequency-chip" />
        <Chip label={formatWebhookType(webhook.type)} size="small" data-testid="type-chip" />
      </div>
    </Paper>
  );
};
