import { Dot } from '@marlin/shared/ui-criticality';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';

import { CRITICALITY, TCriticality } from '../../../shared/types';
import { transformCounter } from '../../../shared/utils';
import { content } from './content';
import { useStyles } from './criticality-control.styles';

interface ICriticalityControl {
  severity: TCriticality;
  highAlertsCount?: number;
  lowAlertsCount?: number;
  type?: 'Equipment' | 'Sensor';
}

export const CriticalityControl = ({ severity, highAlertsCount, lowAlertsCount, type }: ICriticalityControl) => {
  const { goTo } = useRouter();
  const { classes } = useStyles();

  const navigateToAlertsList = (criticality?: 'HIGH' | 'LOW') => {
    goTo(routes.alerts.list.url({ criticality, category: 'Performance', alertType: type }));
  };

  switch (severity) {
    case CRITICALITY.HIGH: {
      if (highAlertsCount === 0 || !highAlertsCount) {
        return null;
      }
      return (
        <Dot variant={CRITICALITY.HIGH}>
          <div
            data-testid={`performance-tab-${type}-${severity}-${transformCounter(highAlertsCount)}`}
            className={classes.container}
            onClick={() => navigateToAlertsList('HIGH')}
          >
            {transformCounter(highAlertsCount)} {content.HIGH_ALERTS}
          </div>
        </Dot>
      );
    }
    case CRITICALITY.LOW: {
      if (lowAlertsCount === 0 || !lowAlertsCount) {
        return null;
      }

      return (
        <Dot variant={CRITICALITY.LOW}>
          <div
            data-testid={`performance-tab-${type}-${severity}-${transformCounter(lowAlertsCount)}`}
            className={classes.container}
            onClick={() => navigateToAlertsList('LOW')}
          >
            {transformCounter(lowAlertsCount)} {content.LOW_ALERTS}
          </div>
        </Dot>
      );
    }
    case CRITICALITY.NO: {
      return (
        <Dot variant={CRITICALITY.NO}>
          <div
            data-testid={`performance-tab-${type}-${severity}-${content.NO_OPEN_ALERTS}`}
            className={classes.container}
            onClick={() => navigateToAlertsList()}
          >
            {content.NO_OPEN_ALERTS}
          </div>
        </Dot>
      );
    }
    default: {
      return null;
    }
  }
};
