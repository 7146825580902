import { MarlinTheme } from '@marlin/shared/theme';
import { Link } from '@marlin/shared/ui-page';
import { makeStyles } from 'tss-react/mui';

interface ILinkWithIconProps {
  iconElement: JSX.Element;
  to: string;
  text: string;
  newTab?: boolean;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  link: {
    display: 'block',
    fontSize: theme.typography.pxToRem(14),
  },
  linkWithIcon: {
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginRight: theme.typography.pxToRem(4),
      height: theme.typography.pxToRem(20),
      width: theme.typography.pxToRem(20),
      opacity: '54%',
    },
  },
}));

export const LinkWithIcon = ({ iconElement, to, text, newTab = false }: ILinkWithIconProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.linkWithIcon}>
      {iconElement}
      <Link className={classes.link} to={to} target={newTab ? '_blank' : '_self'}>
        {text}
      </Link>
    </div>
  );
};
