import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  root: {
    '& .MuiInputBase-input': {
      border: 'none',
      boxShadow: 'none',
      fontSize: theme.typography.pxToRem(14),

      '&[aria-expanded="true"]': {
        backgroundColor: theme.palette.action.hover,
        borderColor: theme.palette.action.hover,
      },
    },
    '&.Mui-disabled': {
      backgroundColor: 'transparent',
    },
    '& .MuiOutlinedInput-notchedOutline': { border: 0 },
  },
}));
