import { MarlinTheme } from '@marlin/shared/theme';
import { Chip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';
import { TRole } from './types';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  Support: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.text.alternative,
  },
  CSM: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.text.alternative,
  },
  BUCS: {
    backgroundColor: theme.palette.alternative.main,
    color: theme.palette.alternative.contrastText,
  },
  Admin: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.text.alternative,
  },
  Viewer: {
    backgroundColor: theme.palette.alternative.main,
    color: theme.palette.alternative.contrastText,
  },
  Manager: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  Deleted: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.primary.contrastText,
  },
}));

export interface IRoleChipProps {
  role: TRole;
}

const roleLabels: Record<TRole, string> = {
  CSM: content.csmLabel,
  Support: content.supportLabel,
  Admin: content.adminLabel,
  Viewer: content.viewerLabel,
  Manager: content.managerLabel,
  Deleted: content.deletedLabel,
  BUCS: content.bucsLabel,
};

export const RoleChip = ({ role }: IRoleChipProps) => {
  const { classes } = useStyles();
  return <Chip className={classes[role]} label={roleLabels[role]} size="small" />;
};
