import { environment } from '@marlin/environment';

export const content = {
  SUCCESS_MESSAGE: 'Recipient has been added',
  ERROR_INVITATION_ALREADY_IN_PROGRESS: 'It looks like a user has been already invited and waiting for confirmation',
  ERROR_USER_ALREADY_EXISTS: `It looks like this email is already registered as a user. User’s have all the functionality of Alert Recipients, as well as access to the ${environment.app.name} platform for your organization.`,
  ERROR_INVITATION_EXPIRED: 'It looks like this invitation has been expired',
  ERROR_INVITATION_ALREADY_ACCEPTED: 'It looks like this invitation has been accepted already',
  ERROR_INVALID_INVITATION: 'It looks like this invitation is no longer valid',
  ERROR_RECIPIENT_ALREADY_IN_ORGANIZATION:
    'It looks like an Alert Recipient with the provided email already exist. Please view the table of Alert Recipients to make additional edits.',
  ERROR_UNKNOWN: 'Unknown error',
};
