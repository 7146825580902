import { Accordion, AccordionSummary, Typography } from '@mui/material';

import { useStyles } from './empty-card.styles';

interface IEmptyCard {
  title: string;
  subtitle: string;
  message: string;
  titleTestId?: string;
  subtitleTestId?: string;
}

export const EmptyCard = ({ title, subtitle, message, titleTestId, subtitleTestId }: IEmptyCard) => {
  const { classes } = useStyles();

  return (
    <>
      <div className={classes.container}>
        <div>
          <Typography className={classes.title} data-testid={titleTestId ? titleTestId : 'card-title'}>
            {title}
          </Typography>
        </div>
        <div>
          <Typography className={classes.subtitle} data-testid={subtitleTestId ? subtitleTestId : 'card-subtitle'}>
            {subtitle}
          </Typography>
        </div>
      </div>
      <Accordion elevation={0} className={classes.accordion}>
        <AccordionSummary>
          <div className={classes.subheader}>{message}</div>
        </AccordionSummary>
      </Accordion>
    </>
  );
};
