import { MarlinTheme } from '@marlin/shared/theme';
import { SkeletonLoader } from '@marlin/shared/ui-loader';
import { AnnotationTooltip, IAnnotation } from '@marlin/shared/ui/annotation-tooltip';
import { EmptyChartData } from '@marlin/shared/ui/chart';
import {
  IChartSeries,
  IChartThresholdSeries,
  TChartDisplayType,
  getChartHeight,
  getDataForRangeBarChart,
  plotlyAdapterSchema,
} from '@marlin/shared/utils-chart';
import { CircularProgress, alpha } from '@mui/material';
import uniq from 'lodash/uniq';
import Plotly, { Config, Data, Layout, PlotMouseEvent } from 'plotly.js';
import React, { useMemo } from 'react';
import Plot from 'react-plotly.js';
import { makeStyles } from 'tss-react/mui';

import { Tooltip } from './tooltip.component';
import { useThresholdData } from './use-threshold-data.hook';

interface ITooltipData {
  color: string;
  displayedValue: string;
  name: string;
  formattedDate: string;
  date: string;
}

interface ITooltip {
  top: number;
  left: number;
  tooltipData: ITooltipData[] | null;
}

export interface IChartOptions {
  layout?: Partial<Layout>;
  config?: Partial<Config>;
  handleRelayout?: (e: Plotly.PlotRelayoutEvent) => void;
  handleHover?: (event: Plotly.PlotHoverEvent) => void;
  handleUnhover?: (event: Readonly<PlotMouseEvent>) => void;
  isEmpty?: boolean;
  tooltip?: (Partial<ITooltip> | null) & {
    direction: 'right' | 'left';
    showTooltip: boolean;
  };
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    marginTop: theme.typography.pxToRem(32),
    position: 'relative',

    'div.modebar-group [data-attr="disabled"]': {
      '&:hover > .icon path': {
        // This important is needed to override the default plotly fill color as plotly uses id selector internally
        fill: 'rgba(68, 68, 68, 0.3) !important',
      },
    },

    '.draglayer.cursor-move': {
      '.nsewdrag.drag.cursor-ew-resize': {
        cursor: 'grab',
      },
    },

    '.annotation': {
      '.annotation-text-g': {
        '.cursor-pointer': {
          cursor: 'default',
        },
      },
    },
  },
  barsChart: {
    height: '100%',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: alpha(theme.palette.background.alternative, 0.7),
    zIndex: theme.zIndex.drawer,
  },

  skeleton: {
    height: '100%',
    width: '100%',
  },
}));

interface IChartWrapperProps extends IChartOptions {
  isLoading: boolean;
  isFetching: boolean;
  chartId: string;
  chartData: IChartSeries[];
  currentAnnotationTooltip: IAnnotation | null;
  to: number;
  from: number;
  chartDisplayType: TChartDisplayType;
  isFullScreen?: boolean;
  headerHeight?: number;
  rangeBarDatapoints?: string[];
  paperHeight?: number;
  className?: string;
  height?: number;
  chartOptions?: ApexCharts.ApexOptions;
  thresholdData?: IChartThresholdSeries[];
  hideUomOnAxis?: boolean;
}

export const ChartSwitcherWrapper = ({
  isLoading,
  isFetching,
  chartId,
  chartData,
  currentAnnotationTooltip,
  to,
  from,
  chartDisplayType,
  className,
  layout,
  config,
  handleRelayout,
  isFullScreen,
  rangeBarDatapoints,
  tooltip,
  handleUnhover,
  handleHover,
  isEmpty,
  thresholdData,
  hideUomOnAxis,
  height,
}: IChartWrapperProps) => {
  const { classes, cx } = useStyles();

  return (
    <SkeletonLoader loading={isLoading} skeletonsCount={5}>
      <div
        className={cx(classes.container, className, {
          [classes.barsChart]: chartDisplayType === 'bar' || chartDisplayType === 'rangeBar',
        })}
        data-testid={chartId}
      >
        {isFetching && !isLoading && (
          <div className={classes.overlay}>
            <CircularProgress />
          </div>
        )}
        <ChartSwitcher
          layout={layout}
          config={config}
          chartData={chartData}
          to={to}
          from={from}
          rangeBarDatapoints={rangeBarDatapoints}
          chartDisplayType={chartDisplayType}
          handleRelayout={handleRelayout}
          isFullScreen={isFullScreen}
          handleHover={handleHover}
          handleUnhover={handleUnhover}
          tooltip={tooltip}
          isEmpty={isEmpty}
          thresholdData={thresholdData}
          hideUomOnAxis={hideUomOnAxis}
          height={height}
        />
        {currentAnnotationTooltip && <AnnotationTooltip {...currentAnnotationTooltip} />}

        {!isLoading && (
          <EmptyChartData
            chartData={thresholdData ? [...chartData, ...thresholdData] : chartData}
            from={from}
            to={to}
            chartDisplayType={chartDisplayType}
          />
        )}
      </div>
    </SkeletonLoader>
  );
};

export const ChartSwitcher = ({
  chartData,
  chartDisplayType,
  to,
  from,
  layout,
  config,
  handleRelayout,
  handleHover,
  handleUnhover,
  tooltip,
  thresholdData,
  rangeBarDatapoints,
  height,
  hideUomOnAxis,
}: Omit<IChartWrapperProps, 'chartId' | 'isLoading' | 'isFetching' | 'currentAnnotationTooltip'>) => {
  const uomsForSeries = useMemo(() => {
    const uoms = thresholdData
      ? uniq([...thresholdData, ...chartData].map((s) => s?.uom))
      : uniq([...chartData].map((s) => s?.uom));
    return uoms.filter(Boolean);
  }, [chartData, thresholdData]);

  const data = useMemo(() => {
    return plotlyAdapterSchema.parse({
      series: chartData,
      uoms: uomsForSeries,
      options: {
        hideUomOnAxis,
      },
    });
  }, [chartData, hideUomOnAxis, uomsForSeries]);
  const { data: mappedThresholdData, annotations } = useThresholdData({
    thresholdData,
    yAxisRange: layout?.yaxis?.range,
  });

  const mergedData = useMemo<Partial<Data>[]>(() => [...data, ...mappedThresholdData], [data, mappedThresholdData]);
  const mergedLayout = useMemo(() => ({ ...layout, annotations }), [annotations, layout]);

  const rangeBarChartHeight = useMemo(
    () => getChartHeight(height, rangeBarDatapoints?.length, !!chartData.length),
    [height, rangeBarDatapoints?.length, chartData.length]
  );

  if (chartDisplayType === 'rangeBar') {
    const dataForRangeBar = getDataForRangeBarChart(mergedData, to);
    return (
      <>
        <Plot
          data={dataForRangeBar.reverse()}
          layout={mergedLayout}
          config={config}
          useResizeHandler={true}
          style={{
            width: '100%',
            height: height ?? rangeBarChartHeight,
          }}
          onRelayout={handleRelayout}
          onHover={handleHover}
          onUnhover={handleUnhover}
        />
        <Tooltip {...(tooltip || {})} isPeriodTooltip />
      </>
    );
  }

  if (layout && chartDisplayType === 'heatmap') {
    const yaxisConfig = chartData.reduce(
      (acc, _, index) => ({
        ...acc,
        [index === 0 ? 'yaxis' : `yaxis${index + 1}`]: {
          side: 'right',
          automargin: true,
        },
      }),
      {}
    );
    return (
      <>
        <Plot
          data={data}
          useResizeHandler={true}
          layout={{
            ...layout,
            xaxis: {
              ...layout.xaxis,
              type: 'date',
              range: [from, to],
              showgrid: false,
            },
            ...yaxisConfig,
            grid: { rows: chartData.length, columns: 1 },
          }}
          config={{
            ...config,
            responsive: false,
          }}
          style={{
            width: '100%',
            height: '100%',
          }}
          onRelayout={handleRelayout}
          onHover={handleHover}
          onUnhover={handleUnhover}
        />
        <Tooltip {...(tooltip || {})} />
      </>
    );
  }

  if (layout) {
    return (
      <>
        <Plot
          data={mergedData}
          layout={mergedLayout}
          config={config}
          useResizeHandler={true}
          style={{
            width: '100%',
            height: '100%',
          }}
          onRelayout={handleRelayout}
          onHover={handleHover}
          onUnhover={handleUnhover}
        />
        <Tooltip {...(tooltip || {})} />
      </>
    );
  }

  return null;
};
