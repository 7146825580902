import { useEquipmentDetails as useEquipmentDetailsQuery } from '@marlin/asset/data-access/equipment';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { useRouter } from '@marlin/shared/utils-router';
import { useIdFromPathname } from '@marlin/shared/utils/url-params';
import { useMemo } from 'react';
import { z } from 'zod';

import { content } from '../content';
import { useNavigation } from './use-navigation.hook';

export const useEquipment = () => {
  const router = useRouter();
  const navigation = useNavigation();
  const equipmentId = useIdFromPathname();
  const { enqueueSnackbar } = useSnackbar();

  const equipmentQuery = useEquipmentDetailsQuery({
    equipmentId,
    config: {
      onError: (error) => {
        if (isEquipmentNotFound(error)) {
          enqueueSnackbar(content.ERROR, {
            preventDuplicate: true,
            variant: 'error',
          });
          router.goTo(navigation.getEquipmentLink());
        }
      },
    },
  });

  const equipment = useMemo(() => equipmentQuery?.data, [equipmentQuery?.data]);

  const isLoading = useMemo(
    () => equipmentQuery.isLoading || equipmentQuery.isInitialLoading,
    [equipmentQuery.isInitialLoading, equipmentQuery.isLoading]
  );

  return {
    equipment,
    isLoading,
    isError: equipmentQuery.isError,
    refetchEquipment: equipmentQuery.refetch,
  };
};

const isEquipmentNotFound = (error: unknown) => {
  const parsedError = z
    .object({
      response: z.object({
        data: z.object({
          status: z.number(),
        }),
      }),
    })
    .passthrough()
    .safeParse(error);

  if (parsedError.success) {
    return parsedError.data.response.data.status === 404;
  }

  return false;
};
