import { DeviceUserMeasuresPreferences, uomSettingsService } from '@marlin/shared/utils-format-reading';
import { getHttpClient } from '@marlin/shared/utils/react-query';

import { TUser, User } from '../schemas/user';
import { IEditedUser } from '../user.model';
import { userService } from '../user.service';
import { getCurrentUserOrganization } from './organization';
import { TOrganizationResponseSchema } from './organization.schema';

const getUserData = async () => {
  const currentOrganizationResponse = await getCurrentUserOrganization();
  const [currentOrganization] = currentOrganizationResponse.organizations;

  return { user: currentOrganizationResponse, currentOrganization };
};

export const getCurrentUser = async (): Promise<TOrganizationResponseSchema> => {
  const { user, currentOrganization } = await getUserData();

  uomSettingsService.setUomSettings(DeviceUserMeasuresPreferences.parse(user.settings));

  userService.setUser({
    email: user.email,
    id: user.id,
    organizationId: currentOrganization.organizationId,
  });

  return {
    ...user,
    currentOrganization,
  };
};

export const getUser = async ({ userId }: { userId: string }) => {
  const res = await getHttpClient().get(`/api/User/{userId}`, { params: { userId } });

  return User.parse(res);
};

export const editUser = async ({ userId, newUser }: { userId: string; newUser: Omit<TUser, 'id'> }) => {
  const res = await getHttpClient().put<unknown, IEditedUser>(`/api/User/{userId}`, newUser, { params: { userId } });

  return User.parse(res);
};
