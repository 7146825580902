import { TAvailableWarrantyModel } from '@marlin/shared/utils/zod';
import { ReactNode } from 'react';

import { content } from '../../../content';
import { EQUIPMENT_ENUM } from '../../onboarding.schema';
import { ReactComponent as AercoInnovationIcon } from './icons/aerco_innovation.svg';
import { ReactComponent as IntellistationIcon } from './icons/intellistation.svg';

export interface ITile {
  id: EQUIPMENT_ENUM;
  name: string;
  icon: ReactNode;
}

export const getEquipmentTilesConfig = (warrantyModel: TAvailableWarrantyModel | null): ITile[] => {
  if (warrantyModel === 'intellistation2') {
    return [intellistationTile];
  }

  if (warrantyModel === 'innovationedge') {
    return [aercoInnovationTile];
  }

  return equipmentTilesConfig;
};

const intellistationTile = {
  id: EQUIPMENT_ENUM.INTELLISTATION,
  name: content.EQUIPMENT_INTELLISTATION,
  icon: <IntellistationIcon />,
};

const aercoInnovationTile = {
  id: EQUIPMENT_ENUM.AERCO_INNOVATION,
  name: content.EQUIPMENT_AERCO_INNOVATION,
  icon: <AercoInnovationIcon />,
};

export const equipmentTilesConfig: ITile[] = [
  intellistationTile,
  aercoInnovationTile,
  // HIDE BENCHMARK TILE
  // {
  //   id: EQUIPMENT_ENUM.AERCO_BENCHMARK,
  //   name: content.EQUIPMENT_AERCO_BENCHMARK,
  //   icon: <AercoBenchmarkIcon />,
  // },
];
