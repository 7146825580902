import { z } from 'zod';

const urlSchema = z.string().url().nullish();

export const analyticsReportsSchema = z.object({
  gatewayStatus: urlSchema,
  alertQuantity: urlSchema,
});

export type TAnalyticsReports = z.infer<typeof analyticsReportsSchema>;
