import { zodResolver } from '@hookform/resolvers/zod';
import { TUser, TUserProfileForm } from '@marlin/account-data-access-organization';
import {
  EmailControl,
  FirstNameControl,
  JobTitleControl,
  LastNameControl,
  PhoneNumberControl,
  RoleControl,
} from '@marlin/shared/ui-form';
import { Button, Grid } from '@mui/material';
import omit from 'lodash/omit';
import { FormProvider, useForm } from 'react-hook-form';

import { content } from '../translation-keys';
import { AccessCodeField } from './access-code-field.component';
import {
  TUpsertProfileFormValidationSchema,
  UpsertProfileFormValidationSchema,
} from './user-profile-update-form.schema';
import { useStyles } from './user-profile-update-form.styles';

interface IUserProfileUpdateFormProps {
  user: TUser | undefined;
  onSubmit: (data: TUserProfileForm) => void;
  onCancel: () => void;
}

export function UserProfileUpdateForm({ user, onSubmit, onCancel }: IUserProfileUpdateFormProps) {
  const { classes } = useStyles();
  const form = useForm<TUserProfileForm>({
    defaultValues: omit(user, ['id', 'hasDevicesWithUserAccessCode']),
    mode: 'onTouched',
    resolver: zodResolver(UpsertProfileFormValidationSchema),
  });

  const handleCancel = () => {
    form.reset();
    onCancel();
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <FirstNameControl<TUpsertProfileFormValidationSchema> fieldName="firstName" />
          </Grid>
          <Grid item xs={12}>
            <LastNameControl<TUpsertProfileFormValidationSchema> fieldName="lastName" />
          </Grid>
          <Grid item xs={12}>
            <EmailControl<TUpsertProfileFormValidationSchema> disabled={true} fieldName="email" />
          </Grid>
          <Grid item xs={12}>
            <PhoneNumberControl<TUpsertProfileFormValidationSchema> fieldName="phone" />
          </Grid>
          <Grid item xs={12}>
            <JobTitleControl<TUpsertProfileFormValidationSchema> fieldName="title" />
          </Grid>
          <Grid item xs={12}>
            <RoleControl<TUpsertProfileFormValidationSchema> fieldName="role" disabled />
          </Grid>
          {user?.hasDevicesWithUserAccessCode && (
            <Grid item xs={12}>
              <AccessCodeField />
            </Grid>
          )}
        </Grid>
        <Grid container spacing={2} className={classes.container}>
          <Grid item xs={12} md={6}>
            <Button
              fullWidth
              variant="outlined"
              data-testid="account-update-user-profile-cancel-button"
              onClick={handleCancel}
              disabled={!form.formState.isDirty}
            >
              {content.BUTTONS.CANCEL}
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              fullWidth
              data-testid="account-update-user-profile-confirm-button"
              variant="contained"
              disabled={!form.formState.isDirty || !form.formState.isValid}
              onClick={form.handleSubmit(onSubmit)}
            >
              {content.BUTTONS.DONE}
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
}
