import { useCancelSnoozeAutomation, useSnoozeAutomation } from '@marlin/alert/data-access/automated-action';
import { MarlinTheme } from '@marlin/shared/theme';
import { TCriticality } from '@marlin/shared/ui-criticality';
import { CancelSnoozeModal, SnoozeAlertIcon, SnoozeModal } from '@marlin/shared/ui-domain-automation-snooze';
import { useModal } from '@marlin/shared/ui-modal';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { TDateString, defaultDateTime, formatDate, minutesToHours } from '@marlin/shared/utils-common-date';
import { PERMISSIONS, usePermission } from '@marlin/shared/utils-permission';
import { getLogger } from '@marlin/shared/utils/logger';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  section: {
    alignSelf: 'center',
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.text.secondary,
  },

  alertIconWrapper: {
    marginRight: theme.typography.pxToRem(0),
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
}));

interface ISnoozeSectionProps {
  id: string;
  snoozeUntil: TDateString;
  automationName: string;
  automationId: string;
  deviceId: string;
  deviceName: string;
  alertSeverity: TCriticality;
  alertStartTime: TDateString;
}

export const SnoozeSection = ({
  id,
  snoozeUntil,
  automationId,
  automationName,
  deviceId,
  deviceName,
  alertSeverity,
  alertStartTime,
}: ISnoozeSectionProps) => {
  const { classes } = useStyles();
  const [cancelSnoozedAlertModal, openCancelSnoozedAlertModal, closeCancelSnoozedAlertModal] = useModal();
  const [snoozeAlertModal, openSnoozeAlertModal, closeSnoozeAlertModal] = useModal();
  const { mutateAsync: snooze } = useSnoozeAutomation();
  const { mutateAsync: cancelSnooze } = useCancelSnoozeAutomation();
  const { enqueueSnackbar } = useSnackbar();
  const allowSnoozeAlerts = usePermission(PERMISSIONS.AUTOMATION_SNOOZE_ALERTS);

  const snoozeTime = useMemo(() => {
    return formatDate(snoozeUntil, defaultDateTime);
  }, [snoozeUntil]);

  const handleSubmitSnoozeTime = async (minutes: number, isSystemAutomation: boolean) => {
    closeSnoozeAlertModal();
    try {
      await snooze({ id: automationId, minutes, deviceId });
      if (isSystemAutomation) {
        enqueueSnackbar(
          content.SNOOZE_SYSTEM_SUCCESS(
            automationName,
            String(minutesToHours(minutes)),
            minutesToHours(minutes) > 1 ? content.HOURS : content.HOUR,
            deviceName
          ),
          {
            variant: 'success',
            preventDuplicate: true,
          }
        );
      } else {
        enqueueSnackbar(
          content.SNOOZE_SUCCESS(
            automationName,
            String(minutesToHours(minutes)),
            minutesToHours(minutes) > 1 ? content.HOURS : content.HOUR
          ),
          {
            variant: 'success',
            preventDuplicate: true,
          }
        );
      }
      getLogger()?.track('AlertSnoozedEvent', {
        criticality: alertSeverity,
        alertCreated: alertStartTime,
      });
    } catch (e) {
      enqueueSnackbar(content.SNOOZE_ERROR, {
        variant: 'error',
        preventDuplicate: true,
      });
    }
  };

  const handleSubmitCancelSnooze = async (isSystemAutomation: boolean, deviceName: string) => {
    closeCancelSnoozedAlertModal();
    try {
      await cancelSnooze({ automationId, deviceId });
      if (isSystemAutomation) {
        enqueueSnackbar(content.CANCEL_SNOOZE_SUCCESS_SYSTEM(automationName, deviceName), {
          variant: 'success',
          preventDuplicate: true,
        });
      } else {
        enqueueSnackbar(content.CANCEL_SNOOZE_SUCCESS(automationName), {
          variant: 'success',
          preventDuplicate: true,
        });
      }
    } catch (e) {
      enqueueSnackbar(content.CANCEL_SNOOZE_ERROR, {
        variant: 'error',
        preventDuplicate: true,
      });
    }
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.section} data-testid="snooze-time">
          {!!snoozeUntil && `${content.SNOOZED_UNTIL} ${snoozeTime}`}
        </div>

        <div>
          <SnoozeAlertIcon
            disabled={!allowSnoozeAlerts}
            data-testid="snooze-alert-icon"
            id={id}
            isSnoozed={!!snoozeUntil}
            onClick={snoozeUntil ? openCancelSnoozedAlertModal : openSnoozeAlertModal}
            externalClass={classes.alertIconWrapper}
          />
        </div>
      </div>
      {snoozeAlertModal && (
        <SnoozeModal
          automationName={automationName}
          onSubmit={handleSubmitSnoozeTime}
          onClose={closeSnoozeAlertModal}
          deviceName={deviceName}
        />
      )}
      {cancelSnoozedAlertModal && snoozeUntil && (
        <CancelSnoozeModal
          deviceName={deviceName}
          automationName={automationName}
          snoozeUntil={snoozeUntil}
          onSubmit={handleSubmitCancelSnooze}
          onClose={closeCancelSnoozedAlertModal}
        />
      )}
    </>
  );
};
