import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  link: {
    display: 'block',
    color: theme.palette.primary.main,
    overflowWrap: 'anywhere',
  },
  wrap: {
    overflowWrap: 'anywhere',
  },
}));
