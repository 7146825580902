import { getHttpClient, queryClient } from '@marlin/shared/utils/react-query';
import { useMutation } from '@marlin/shared/utils/react-query';
import { z } from 'zod';

import { paths } from '../infrastructure/api';
import {
  EquipmentScheduleRaw,
  TEquipmentSchedule,
  TEquipmentSchedulePayload,
  TParsedDailySchedule,
} from '../schema/equipment.schedule';
import { queryKey } from './query-key.enum';

type TUpdateEquipmentSchedule = {
  data: TEquipmentSchedulePayload;
  equipmentId: string;
};

export const updateEquipmentSchedule = async ({ data, equipmentId }: TUpdateEquipmentSchedule) => {
  const parsedEquipmentSchedule = Object.fromEntries(
    Object.entries(data).map(([key, value]) => [key, EquipmentScheduleRaw.parse(mapEquipmentSchedule(value))])
  );

  return await getHttpClient().put(paths.EQUIPMENT_SCHEDULE, parsedEquipmentSchedule, { params: { equipmentId } });
};

export const useUpdateEquipmentSchedule = ({
  equipmentId,
  onSuccess,
  onError,
}: {
  equipmentId: string;
  onError?: () => void;
  onSuccess?: () => void;
}) => {
  return useMutation({
    mutationFn: async ({ data }: TUpdateEquipmentSchedule) => {
      await updateEquipmentSchedule({ data, equipmentId });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.EQUIPMENT_SCHEDULE(equipmentId) });

      onSuccess && onSuccess();
    },
    onError: (error: z.ZodError | unknown) => {
      // eslint-disable-next-line no-console
      if (error instanceof z.ZodError) console.log(error.issues);

      onError && onError();
    },
  });
};

const mapEquipmentSchedule = ({ scheduleEnabled, days }: TEquipmentSchedule) => {
  const parseDay = (day: TParsedDailySchedule) => ({
    from1: { isSkipped: day.occupied1.from.skip, minutes: day.occupied1.from.time },
    from2: { isSkipped: day.occupied2.from.skip, minutes: day.occupied2.from.time },
    to1: { isSkipped: day.occupied1.to.skip, minutes: day.occupied1.to.time },
    to2: { isSkipped: day.occupied2.to.skip, minutes: day.occupied2.to.time },
  });

  return {
    days: {
      monday: parseDay(days.monday),
      tuesday: parseDay(days.tuesday),
      wednesday: parseDay(days.wednesday),
      thursday: parseDay(days.thursday),
      friday: parseDay(days.friday),
      saturday: parseDay(days.saturday),
      sunday: parseDay(days.sunday),
    },
    enabled: scheduleEnabled,
  };
};
