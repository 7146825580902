import { TAnalyticsTelemetryRequestParams } from '../infrastructure/schema/telemetry-analytics.request.schema';

export enum QUERY_KEY {
  RANGE_TELEMETRY = 'rangeTelemetry',
  ANALYTICS_TELEMETRY = 'analyticsTelemetry',
  ANALYTICS_TELEMETRY_EXPORT = 'analyticsTelemetryExport',
}

export const queryKey = {
  RANGE_TELEMETRY: (id: string, to: string, from: string, excludeAlerts?: boolean) => [
    QUERY_KEY.RANGE_TELEMETRY,
    { id, to, from, excludeAlerts },
  ],
  ANALYTICS_TELEMETRY: (params?: TAnalyticsTelemetryRequestParams) => [QUERY_KEY.ANALYTICS_TELEMETRY, { ...params }],
  ANALYTICS_TELEMETRY_EXPORT: (params?: TAnalyticsTelemetryRequestParams) => [
    QUERY_KEY.ANALYTICS_TELEMETRY_EXPORT,
    { ...params },
  ],
};
