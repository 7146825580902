import { Observable, lastValueFrom } from 'rxjs';

import { IHttpErrorResponse, IHttpInterceptor, IHttpRequest } from './http';

interface IAuthService {
  getAccessToken(): Observable<string | undefined>;
  logOut(): void;
}

export class AuthInterceptor implements IHttpInterceptor {
  public constructor(private authService: IAuthService) {}

  public async intercept(config: IHttpRequest): Promise<IHttpRequest> {
    const accessToken = await lastValueFrom(this.authService.getAccessToken());
    return this.handleRequest(config, accessToken);
  }

  public handleError = (error: IHttpErrorResponse, logoutTimer = false): Promise<IHttpErrorResponse> => {
    if (error.response?.status === 401) {
      this.authService.logOut();
    }

    return Promise.reject(error);
  };

  private handleRequest = (config: IHttpRequest, accessToken: string | undefined): IHttpRequest => {
    return { ...config, headers: this.getHeaders(config, accessToken) };
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getHeaders = (config: IHttpRequest, accessToken: string | undefined): any => {
    if (!accessToken) {
      return config.headers;
    }

    return { ...config.headers, Authorization: `Bearer ${accessToken}` };
  };
}
