import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  chartTooltip: {
    backgroundColor: theme.palette.background.primary,
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(16)}`,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightMedium,
    width: '100%',
    minWidth: theme.typography.pxToRem(232),
    maxWidth: theme.typography.pxToRem(400),
    // TODO update border color which is set in parent element
  },
  tooltipDate: { fontSize: theme.typography.pxToRem(10), textAlign: 'center', width: '100%', display: 'block' },
  dot: {
    height: theme.typography.pxToRem(12),
    width: theme.typography.pxToRem(12),
    borderRadius: '50%',
    marginTop: theme.typography.pxToRem(2),
    marginRight: theme.typography.pxToRem(4),
    display: 'inline-block',
  },
  datapoint: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    gap: theme.typography.pxToRem(8),
  },
  wrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    width: 'calc(100% - 42px)',
  },
  name: {
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    width: 'calc(100% - 12px)',
    whiteSpace: 'wrap',
  },
  displayedValue: {
    whiteSpace: 'nowrap',
  },
}));

interface IParsedSeriesData {
  color: string;
  displayedValue: string;
  name: string;
}

export const ChartTooltip = ({
  seriesFromCurrenDataPointIndex,
  formattedDate,
  isPeriodTooltip,
}: {
  formattedDate?: string;
  seriesFromCurrenDataPointIndex: IParsedSeriesData[];
  isPeriodTooltip?: boolean;
}) => {
  const { classes } = useStyles();

  if (isPeriodTooltip) {
    const data = seriesFromCurrenDataPointIndex?.[0];
    if (!data) return null;

    return (
      <div className={classes.chartTooltip}>
        <span>
          <strong>{data.name}:</strong> {formattedDate}
        </span>
      </div>
    );
  }

  return seriesFromCurrenDataPointIndex.length ? (
    <div className={classes.chartTooltip}>
      {seriesFromCurrenDataPointIndex.map(({ displayedValue, color, name }, index) => (
        <div key={`${displayedValue}-${index}`} className={classes.datapoint}>
          <div className={classes.wrapper}>
            <div className={classes.dot} style={{ backgroundColor: color }} />
            <span className={classes.name}>{name}: </span>
          </div>
          <strong className={classes.displayedValue}>{displayedValue}</strong>
        </div>
      ))}
      <span className={classes.tooltipDate}>{formattedDate ?? ''}</span>
    </div>
  ) : null;
};
