import {
  CHANNEL_TYPE,
  IHotSosChannel,
  IInAppChannel,
  TCreateAutomation,
} from '@marlin/alert/data-access/automated-action';
import { MarlinTheme } from '@marlin/shared/theme';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { ControllerFieldState, useController, useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { useContent } from '../context';

interface IChannelProps {
  type: CHANNEL_TYPE.IN_APP | CHANNEL_TYPE.HOT_SOS;
  id?: string;
}

interface IChannelController {
  field: {
    value: IHotSosChannel | IInAppChannel;
    onChange: (event: IHotSosChannel | IInAppChannel | null) => void;
  };
  fieldState: ControllerFieldState;
}

interface ICheckboxChangeEvent {
  target: {
    checked: boolean;
  };
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: theme.typography.pxToRem(16),
    paddingBottom: theme.typography.pxToRem(16),
  },

  checkbox: {
    flex: '15%',
  },
}));

function Channel({ type, id }: IChannelProps) {
  const { classes } = useStyles();
  const content = useContent();
  const { trigger, setValue: setFieldValue } = useFormContext();
  const { field, fieldState }: IChannelController = useController<
    TCreateAutomation,
    'channels.HOT_SOS' | 'channels.IN_APP'
  >({
    name: `channels.${type}`,
  });

  const isInAppChannel = useMemo(() => type === CHANNEL_TYPE.IN_APP, [type]);

  const [channelEnabled, setChannelEnabled] = useState<boolean>(isInAppChannel ? true : !!field.value);

  const label = useMemo(() => {
    return isInAppChannel ? content.ACTION.LABEL.IN_APP : content.ACTION.LABEL.HOTSOS;
  }, [content.ACTION.LABEL.HOTSOS, content.ACTION.LABEL.IN_APP, isInAppChannel]);

  const handleCheckboxChange = useCallback(
    (event: ICheckboxChangeEvent) => {
      if (isInAppChannel) {
        return;
      }
      setChannelEnabled(event.target.checked);

      const name = `channels.${type}`;
      const value = event.target.checked ? { type } : null;

      setFieldValue(name, value, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });

      if (fieldState.isTouched || field.value) {
        trigger(name);
      }
    },
    [isInAppChannel, type, setFieldValue, fieldState.isTouched, field.value, trigger]
  );

  return (
    <div className={classes.container}>
      <FormControlLabel
        className={classes.checkbox}
        control={
          <Checkbox
            data-testid={id ? id : 'automation-checkbox'}
            checked={channelEnabled}
            onChange={handleCheckboxChange}
          />
        }
        label={label}
        disabled
      />
    </div>
  );
}

export { Channel };
