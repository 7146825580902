import { AUTOMATION_RULE_TYPE } from '@marlin/alert/data-access/automated-action';
import { TCreateAutomation } from '@marlin/alert/data-access/automated-action';
import { MarlinTheme } from '@marlin/shared/theme';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { DurationUnitControl, DurationValueControl } from '@marlin/shared/ui-form';
import { IRadioButton, RadioButtons } from '@marlin/shared/ui-form-common';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { useContent } from '../../../../context';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  radio: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },

  control: {
    display: 'flex',
    alignItems: 'baseline',
    gap: theme.typography.pxToRem(16),
    marginTop: theme.typography.pxToRem(16),
    paddingLeft: theme.typography.pxToRem(32),
  },

  buttonGroup: {
    '& > label:last-child': {
      marginRight: theme.typography.pxToRem(8),
    },
  },
}));

export interface IDurationProps {
  label: string;
  ruleType: AUTOMATION_RULE_TYPE;
}

export function Duration({ label, ruleType }: IDurationProps) {
  const content = useContent();
  const { classes } = useStyles();
  const { setValue, trigger, watch } = useFormContext<TCreateAutomation>();

  const buttons: IRadioButton[] = useMemo(
    () => [
      {
        label,
        value: ruleType,
      },
    ],
    [label, ruleType]
  );

  const unit = watch('duration.unit');

  useEffect(() => {
    trigger('duration.value');
  }, [unit, trigger]);

  useEffect(() => {
    setValue('conditions.thresholdMin.value', 0);
    setValue('conditions.thresholdMax.value', 0.5);
    setValue('conditions.thresholdMax.enabled', true);
    setValue('conditions.thresholdMin.enabled', true);

    // trigger validation to mark condition fields as touched
    trigger(['conditions.thresholdMin.value', 'conditions.thresholdMax.value']);
  }, [trigger, setValue]);
  return (
    <>
      <div className={classes.radio}>
        <RadioButtons value={ruleType} buttons={buttons} className={classes.buttonGroup} />
        <Tooltip text={content.CONDITIONS.DURATION.DURATION_TOOLTIP} />
      </div>
      <div className={classes.control}>
        <DurationValueControl<TCreateAutomation> fieldName="duration.value" />
        <DurationUnitControl<TCreateAutomation> fieldName="duration.unit" />
      </div>
    </>
  );
}
