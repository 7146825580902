import { getUnitType, getUnitTypeSeparator } from '@marlin/shared/utils-format-reading';

import { content } from './content';
import { getDeviceTypeText } from './get-device-type-text';
import { getTitleErrorText } from './get-title-error-text';
import { DEVICE_TYPE, ERROR_TYPES, ISystemMapAlert, isSensorType } from './types';

export const getSystemMapAlert = (alert: ISystemMapAlert, deviceType: DEVICE_TYPE): string => {
  if (alert.alertCause === ERROR_TYPES.LOW_BATTERY) {
    return content.TITLE.SYSTEM_MAP_LOW_BATTERY;
  }
  if (alert.alertCause === ERROR_TYPES.LOST_COMMUNICATION) {
    return content.TITLE.SYSTEM_MAP_LOST_COMMUNICATION;
  }

  if (deviceType === DEVICE_TYPE.LEAK) {
    return content.TITLE.SYSTEM_MAP_LEAK;
  }

  const errorText = getTitleErrorText(
    alert.alertCause,
    alert.uoM,
    alert.thresholdMin || undefined,
    alert.thresholdMax || undefined
  );
  const unitSeparator = isSensorType(deviceType) ? getUnitTypeSeparator(deviceType) : '';
  const unit = isSensorType(deviceType) ? getUnitType(deviceType) : '';
  const deviceTypeText = getDeviceTypeText(deviceType);

  return `${deviceTypeText} ${errorText}${unitSeparator}${unit}`;
};
