import { Location } from 'history';
import isFunction from 'lodash/isFunction';
import { ReactNode, useMemo } from 'react';
import ReactRouterPrompt from 'react-router-prompt';

import { useRouter } from '../use-router';
import { useCachedGuardModal } from './use-cached-guard-modal.hook';
import { useHandleUnload } from './use-handle-unload.hook';

type TParams = {
  onConfirm: () => void;
  onCancel: () => void;
};

interface IRouteLeavingGuard {
  when: boolean;
  watchForSearchParamsChange?: boolean;
  watchForShouldIgnoreGuard?: boolean;
  // eslint-disable-next-line no-unused-vars
  children: (param: TParams) => ReactNode;
}

export const LeaveRouteGuard = ({
  when,
  children,
  watchForSearchParamsChange = false,
  watchForShouldIgnoreGuard = false,
}: IRouteLeavingGuard): JSX.Element | null => {
  useHandleUnload(when);
  const router = useRouter();
  const currentUrl = useMemo(() => router.getUrl(), [router]);
  const currentSearchParams = useMemo(() => router.getParams(), [router]);
  const { clearCache } = useCachedGuardModal(false, null);

  const isLeavingPage = (nextLocation: Location | null | undefined): boolean => {
    if (!nextLocation || (watchForShouldIgnoreGuard && hasShouldIgnoreGuard(nextLocation))) {
      return false;
    }

    if (watchForSearchParamsChange) {
      return nextLocation?.pathname !== currentUrl || nextLocation?.search !== currentSearchParams.toString();
    }

    return nextLocation?.pathname !== currentUrl;
  };

  if (!isFunction(children)) {
    // eslint-disable-next-line no-console
    console.error('children should be function');
    return null;
  }

  return (
    <>
      <ReactRouterPrompt when={(location) => when && isLeavingPage(location?.nextLocation)}>
        {({ isActive, onConfirm, onCancel }) =>
          isActive
            ? children({
                onConfirm: () => {
                  clearCache();
                  onConfirm();
                },
                onCancel: onCancel,
              })
            : null
        }
      </ReactRouterPrompt>
    </>
  );
};

export default LeaveRouteGuard;

const hasShouldIgnoreGuard = (nextLocation: Location<unknown> | undefined) =>
  nextLocation?.state &&
  typeof nextLocation?.state === 'object' &&
  'shouldIgnoreGuard' in nextLocation.state &&
  nextLocation?.state?.shouldIgnoreGuard;
