import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { DataGrid, GridSortModel } from '@mui/x-data-grid';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';
import { IPayload, useCreateColumns } from './hooks/use-create-columns.hook';
import { useEquipmentHub } from './hooks/use-equipment-hub.hook';
import { IEquipmentParams } from './hooks/use-pagination.hook';

interface IPaginationModel {
  pageSize: number;
  page: number;
}

export interface IEquipmentListUi {
  allowEdit: boolean;
  allowDelete: boolean;
  paginationModel: IPaginationModel;
  filterParams?: IEquipmentParams;
  setPage: (page: number) => void;
  setPageSize: (size: number) => void;
  payloadDelete?: IPayload;
  hideGatewayColumn?: boolean;
  payloadDeregister?: IPayload;
}

export const useStyles = makeStyles()(() => ({
  dataGrid: {
    borderRadius: 0,
    borderBottom: 0,
    borderLeft: 0,
    borderRight: 0,
  },
}));

export function EquipmentListUi({
  payloadDeregister,
  payloadDelete,
  allowEdit,
  allowDelete,
  paginationModel,
  filterParams,
  setPage,
  hideGatewayColumn,
  setPageSize,
}: IEquipmentListUi) {
  const { classes } = useStyles();
  const { isLoading, isError, rows, rowCount, handleSortingDirection, handleSortingColumn } = useEquipmentHub({
    paginationModel,
    filterParams,
  });

  const columns = useCreateColumns({
    payloadDelete,
    payloadDeregister,
  });

  const handleSortModelChange = ([sortModel]: GridSortModel) => {
    handleSortingDirection(sortModel?.sort || 'asc');
    handleSortingColumn(sortModel?.field || 'name');
  };

  const filteredColumns = columns.filter((column) => column.field !== 'gateway');

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <DataGrid
      localeText={{
        noRowsLabel: isError ? content.ERROR : content.NO_DATA,
      }}
      className={classes.dataGrid}
      rows={rows}
      columns={hideGatewayColumn ? filteredColumns : columns}
      rowCount={rowCount}
      disableColumnFilter
      disableColumnMenu
      rowsPerPageOptions={[10, 50, 100]}
      columnVisibilityModel={{
        action: allowEdit || allowDelete,
      }}
      paginationMode="server"
      filterMode="server"
      sortingMode="server"
      initialState={{ pagination: paginationModel }}
      onPageChange={(page) => setPage(page)}
      onPageSizeChange={(size) => setPageSize(size)}
      onSortModelChange={handleSortModelChange}
    />
  );
}
