import { TChartDisplayType, TChartType } from '@marlin/shared/utils-chart';
import { useCallback, useState } from 'react';

import { getDefaultChartDisplayType } from '../utils/get-chart-display-type';

export const useChartDisplayType = (chartType: TChartType) => {
  const [chartDisplayType, setChartDisplayType] = useState<TChartDisplayType>(getDefaultChartDisplayType(chartType));

  const onChartDisplayTypeChange = useCallback((newChartDisplayType: TChartDisplayType) => {
    setChartDisplayType(newChartDisplayType);
  }, []);

  return {
    chartDisplayType,
    onChartDisplayTypeChange,
  };
};
