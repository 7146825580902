import { TExtractFnReturnType, useMutation } from '@marlin/shared/utils/react-query';

import { TAnalyticsTelemetryRequestParams } from '../infrastructure/schema/telemetry-analytics.request.schema';
import { getAnalyticsTelemetryExport } from '../infrastructure/telemetry';

export type TUseAnalyticsTelemetryExportReturnedType = TExtractFnReturnType<typeof getAnalyticsTelemetryExport>;

export const useAnalyticsTelemetryExport = () => {
  return useMutation<TUseAnalyticsTelemetryExportReturnedType, unknown, TAnalyticsTelemetryRequestParams>({
    mutationFn: getAnalyticsTelemetryExport,
  });
};
