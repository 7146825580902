import { MarlinTheme } from '@marlin/shared/theme';
import { SankeyData } from 'plotly.js';

import { TLinkNode, TLinkValue, TNode } from './types';

function* colorGenerator(colors: string[]): Generator<string, never, unknown> {
  let index = 0;
  while (true) {
    yield colors[index % colors.length];
    index++;
  }
}

const hexToRgba = (hex: string, opacity: number) => {
  const hexValue = hex.replace('#', '');
  const r = parseInt(hexValue.substring(0, 2), 16);
  const g = parseInt(hexValue.substring(2, 4), 16);
  const b = parseInt(hexValue.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

interface ICreateSankeyDataProps {
  theme: MarlinTheme;
  sources: TLinkNode[];
  targets: TLinkNode[];
  values: TLinkValue[];
  nodes: TNode[];
}

export const createSankeyData = ({
  theme,
  sources,
  targets,
  nodes,
  values,
}: ICreateSankeyDataProps): Partial<SankeyData> => {
  const colorGen = colorGenerator(Object.values(theme.palette.charting));

  const colorsArray = Array.from({ length: nodes.length }, () => colorGen.next().value);

  return {
    type: 'sankey' as const,
    orientation: 'h' as const,
    arrangement: 'snap' as const,
    node: {
      pad: 20,
      thickness: 30,
      line: {
        color: 'black',
        width: 0.5,
      },
      label: nodes,
      color: colorsArray,
    },
    link: {
      source: sources,
      target: targets,
      color: targets.map((value) => hexToRgba(colorsArray[value], 0.5)),
      value: values,
    },
    textfont: {
      color: 'black',
      size: 14,
    },
  };
};
