import { zodResolver } from '@hookform/resolvers/zod';
import { useGetOrgSettings, useUpdateOrgSettings } from '@marlin/account-data-access-organization';
import { DevicesSearchWrapper, useDefaultFlowMeters } from '@marlin/asset/data-access/device';
import { FlowMeterTag } from '@marlin/asset/ui/totalization';
import { FormField, Input, Select } from '@marlin/shared/ui-form-common';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import { Autocomplete, Button, FormHelperText, MenuItem, Paper, PaperProps, Typography } from '@mui/material';
import { AutocompleteRenderGetTagProps } from '@mui/material/Autocomplete/Autocomplete';
import React, { useEffect, useMemo, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { content } from './content';
import { SustainabilitySettingsSchema, TSustainabilitySettingsSchema } from './schema';
import { useStyles } from './settings.styles';

export interface IFlowMeter {
  id: string;
  name: string;
  locationName: string;
}

const unitsOptions = [
  {
    name: content.PER_LITER,
    id: 'l',
  },
  {
    name: content.PER_GALLON,
    id: 'gal',
  },
  {
    name: content.PER_CUBIC_METER,
    id: 'm3',
  },
  {
    name: content.PER_CUBIC_FOOT,
    id: 'ft3',
  },
];

interface ISettingsModalBodyProps {
  handleCloseModal: () => void;
}

export const SettingsModalBody = ({ handleCloseModal }: ISettingsModalBodyProps) => {
  const { classes, theme } = useStyles();
  const { data, isLoading } = useGetOrgSettings();
  const { mutateAsync: updateFlowMeter } = useUpdateOrgSettings();
  const ref = useRef<HTMLInputElement | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { data: flowMeters, isLoading: isFlowMetersLoading } = useDefaultFlowMeters();
  const selectedFlowMeters: IFlowMeter[] | undefined = useMemo(() => {
    return flowMeters?.map((device) => ({
      id: device.deviceId || '',
      name: device.deviceName || '',
      locationName: device.locationName || '',
    }));
  }, [flowMeters]);

  const form = useForm<TSustainabilitySettingsSchema>({
    mode: 'onChange',
    resolver: zodResolver(SustainabilitySettingsSchema),
    defaultValues: {
      price: String(data?.settings.waterPrice?.price),
      uoM: data?.settings.waterPrice?.uoM || 'gal',
      defaultFlowMeters: selectedFlowMeters || [],
    },
  });

  useEffect(() => {
    if (!isLoading && data) {
      const defaultValues = {
        price: String(data.settings.waterPrice?.price),
        uoM: data.settings.waterPrice?.uoM || 'gal',
        defaultFlowMeters: selectedFlowMeters || [],
      };
      form.reset(defaultValues);
    }
  }, [selectedFlowMeters, form, isLoading, data]);

  const onSubmit = async () => {
    const formValues = form.getValues();
    try {
      await updateFlowMeter({
        defaultFlowMeters: formValues.defaultFlowMeters.map((f) => f.id) || [],
        waterPrice: {
          price: Number(formValues.price),
          uoM: formValues.uoM,
        },
      });
      enqueueSnackbar(content.SETTINGS_UPDATED, {
        variant: 'success',
        preventDuplicate: true,
      });
    } catch (error) {
      enqueueSnackbar(content.CHANGE_FLOW_METER_ERROR, {
        variant: 'error',
        preventDuplicate: true,
      });
    }
    handleCloseModal();
  };

  if (isLoading || isFlowMetersLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className={classes.bodyContainer}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div>
            <Typography className={classes.sectionTitle}>{content.FLOW_METER_DASHBOARD}</Typography>
            <Typography className={classes.description}>{content.FLOW_METER_SECTION}</Typography>
            <FormField fieldName={'defaultFlowMeters'} control={form.control}>
              {(field) => (
                <DevicesSearchWrapper params={{ deviceTypes: ['PULSE_METER'], meterTypes: ['Water'] }}>
                  {(props) => {
                    return (
                      <Autocomplete
                        multiple
                        value={field.value}
                        isOptionEqualToValue={(option: IFlowMeter, value: IFlowMeter) => option.id === value.id}
                        id="select-water-device"
                        options={props.options as IFlowMeter[]}
                        disabled={false}
                        getOptionLabel={(option: IFlowMeter) => `${option.name} ${option.locationName}`}
                        renderOption={(props, option: IFlowMeter) => (
                          <MenuItem key={option.id} {...props}>
                            <div>
                              <span>{option.name}</span>
                              <FormHelperText>{option.locationName}</FormHelperText>
                            </div>
                          </MenuItem>
                        )}
                        renderTags={(flowMeters: IFlowMeter[], getTagProps: AutocompleteRenderGetTagProps) => {
                          return flowMeters.map((flowMeter, index) => (
                            <FlowMeterTag flowMeter={flowMeter} {...getTagProps({ index })} key={index} />
                          ));
                        }}
                        limitTags={2}
                        onChange={(_, value: IFlowMeter[]) => {
                          field.onChange && field.onChange(value);
                          form.trigger('defaultFlowMeters');
                        }}
                        filterSelectedOptions
                        renderInput={(params) => (
                          <Input error={field.error} label={content.SELECTED_FLOW_METERS} {...params} />
                        )}
                        PaperComponent={(props: PaperProps) => <Paper {...props} />}
                      />
                    );
                  }}
                </DevicesSearchWrapper>
              )}
            </FormField>
          </div>
          <div style={{ marginTop: 16 }}>
            <Typography className={classes.sectionTitle}>{content.AVERAGE_WATER_PRICE}</Typography>
            <Typography className={classes.description}>{content.AVERAGE_WATER_PRICE_SECTION}</Typography>
            <div className={classes.inputsWrapper}>
              <FormField fieldName="price" control={form.control}>
                {(props) => (
                  <Input
                    {...props}
                    externalStartAdornment={{
                      startAdornment: (
                        <AttachMoneyRoundedIcon
                          sx={{
                            color: theme.palette.text.secondary,
                            fontSize: theme.typography.pxToRem(18),
                          }}
                        />
                      ),
                    }}
                    label={content.COST_OF_WATER}
                    className={classes.costInput}
                    error={props.error}
                    inputRef={ref}
                  />
                )}
              </FormField>
              <FormField fieldName="uoM" control={form.control}>
                {(props) => (
                  <Select
                    {...props}
                    className={classes.unitInput}
                    data={unitsOptions}
                    value={props.value}
                    label={content.UNIT}
                    prefix={'unit'}
                    onChange={(e) => {
                      props.onChange && props.onChange(e.target.value);
                      form.trigger('uoM');
                      // TODO: find a better way to focus on the input without using setTimeout
                      setTimeout(() => {
                        ref.current?.focus();
                        form.setValue('price', '');
                        form.trigger('price');
                      }, 100);
                    }}
                  />
                )}
              </FormField>
            </div>
          </div>
          <div className={classes.buttons}>
            <Button variant="text" onClick={handleCloseModal}>
              {content.CANCEL}
            </Button>
            <Button variant="contained" type={'submit'} disabled={!form.formState.isDirty || !form.formState.isValid}>
              {content.SAVE}
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
