import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import { TAssetLink, assetLinkSchema } from '../schema/asset-link.model.schema';
import { paths } from './api';

export const getAssetLinks = async ({ assetId }: { assetId: string }): Promise<TAssetLink> => {
  const res = await getHttpClient().get(paths.ASSET_LINKS, { params: { assetId } });
  return safeParseData(res, assetLinkSchema);
};
