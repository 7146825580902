import { content } from './content';
import { IGlobalEquipmentConfig } from './model';

export const globalConfigPvi: IGlobalEquipmentConfig = {
  dashboard: {
    liveDataExpirationSeconds: 720,
  },
  calculationValues: false,
  chart: [
    {
      name: 'temperature',
      label: content.temperature,
      type: 'rangeArea',
      mainDatapoint: 'Setpoint',
    },
    {
      name: 'flamestrength',
      label: content.flameStrength,
      type: 'rangeArea',
      mainDatapoint: 'FlameStrength',
    },
    {
      name: 'pumpstatus',
      label: content.pumpStatus,
      type: 'rangeBar',
    },
  ],
};
