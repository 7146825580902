import { TEquipmentDetailsRow } from '@marlin/asset/shared/equipment-config';
import { dateFormatterValueCallback } from '@marlin/shared/utils-common-date';
import moment from 'moment';

import { content } from '../../../content';

const calculateAgeOfLastHeartBeat = (value: unknown) => {
  if (typeof value === 'string') {
    return formatSeconds(moment().diff(moment(value), 'seconds'));
  }

  return undefined;
};

const firmwareVersionCallback = (value: unknown) => {
  if (Array.isArray(value)) {
    return value.filter(Boolean)[0] ?? content.EMPTY_DATAPOINT_VALUE;
  }

  if (typeof value === 'string') {
    return value;
  }

  return undefined;
};

export const detailsConfig: TEquipmentDetailsRow[] = [
  { name: 'automationCount', label: content.AUTOMATIONS, valueSource: 'equipment' },
  {
    name: ['FirmwareVersion', 'SoftwareVersion'],
    label: content.FIRMWARE_VERSION,
    valueSource: 'datapoints',
    valueCallback: firmwareVersionCallback,
  },
  { name: 'FixedID', label: content.CONTROLLER_FIXED_ID, valueSource: 'datapoints' },
  {
    name: 'registrationStatusChangeDate',
    label: content.REGISTRATION_DATE,
    valueSource: 'equipment',
    valueCallback: dateFormatterValueCallback,
  },
  {
    name: 'commissionDate',
    label: content.COMMISSION_DATE,
    valueSource: 'equipment',
    valueCallback: dateFormatterValueCallback,
  },
  { name: 'lastReadingTime', label: content.LAST_HEARTBEAT, valueSource: 'datapoints' },
  {
    name: 'lastReadingTime',
    label: content.AGE_OF_LAST_HEARTBEAT,
    valueSource: 'datapoints',
    valueCallback: calculateAgeOfLastHeartBeat,
  },
  { name: 'description', label: content.DESCRIPTION, valueSource: 'equipment' },
];

export const detailsConfigWithIgst: TEquipmentDetailsRow[] = [
  { name: 'automationCount', label: content.AUTOMATIONS, valueSource: 'equipment' },
  {
    name: ['FirmwareVersion', 'SoftwareVersion'],
    label: content.FIRMWARE_VERSION,
    valueSource: 'datapoints',
    valueCallback: firmwareVersionCallback,
  },
  { name: 'FixedID', label: content.CONTROLLER_FIXED_ID, valueSource: 'datapoints' },
  { name: 'IGSTVersion', label: content.CONTROLLER_IGST_VERSION, valueSource: 'datapoints' },
  {
    name: 'registrationStatusChangeDate',
    label: content.REGISTRATION_DATE,
    valueSource: 'equipment',
    valueCallback: dateFormatterValueCallback,
  },
  {
    name: 'commissionDate',
    label: content.COMMISSION_DATE,
    valueSource: 'equipment',
    valueCallback: dateFormatterValueCallback,
  },
  { name: 'lastReadingTime', label: content.LAST_HEARTBEAT, valueSource: 'datapoints' },
  {
    name: 'lastReadingTime',
    label: content.AGE_OF_LAST_HEARTBEAT,
    valueSource: 'datapoints',
    valueCallback: calculateAgeOfLastHeartBeat,
  },
  { name: 'description', label: content.DESCRIPTION, valueSource: 'equipment' },
];

function formatSeconds(seconds: number): string {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}
