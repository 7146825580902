import { LocationSearchWrapper } from '@marlin/alert/data-access/automated-action';
import { ChipGroup, DeviceTypeControl } from '@marlin/shared/ui-form';
import { FormField, InfiniteAutocompleteControl, Input, useSubmitOnChange } from '@marlin/shared/ui-form-common';
import { TAssetType } from '@marlin/system-map/shared/data-access-schemas';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Button, IconButton } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import { content } from '../../../content';
import { filteringChips } from './asset-type';
import { FilterChips } from './filter-chips.component';
import { useStyles } from './filters.component.styles';
import { ILocationDetailsFilters } from './types';

const defaultFilters: ILocationDetailsFilters = {
  deviceTypes: [],
  types: [],
  locations: [],
  search: '',
};

interface IFiltersProps {
  locationId: string;
  onFiltersChange: (data: ILocationDetailsFilters) => void;
}

export const Filters = ({ locationId, onFiltersChange }: IFiltersProps) => {
  const { classes } = useStyles();

  const form = useForm<ILocationDetailsFilters>({
    defaultValues: defaultFilters,
  });

  const { submit, resetForm } = useSubmitOnChange<ILocationDetailsFilters>(form, onFiltersChange);

  return (
    <FormProvider {...form}>
      <form onSubmit={submit} data-testid="filters-form">
        <div className={classes.filtersWrapper}>
          <div className={classes.filters}>
            <FormField<ILocationDetailsFilters> fieldName="types">
              {(props) => (
                <div className={classes.chipFilters}>
                  <ChipGroup<TAssetType> {...props} chips={filteringChips} multi />
                </div>
              )}
            </FormField>
            <DeviceTypeControl<ILocationDetailsFilters>
              fieldName="deviceTypes"
              multiple
              className={classes.locationFilter}
            />
            <div className={classes.filterControl}>
              <LocationSearchWrapper parentLocation={locationId}>
                {(props) => (
                  <InfiniteAutocompleteControl<ILocationDetailsFilters>
                    fieldName="locations"
                    testId="location-search"
                    label="Location"
                    className={classes.locationFilter}
                    {...props}
                  />
                )}
              </LocationSearchWrapper>
              <FormField<ILocationDetailsFilters> fieldName="search">
                {(props) => (
                  <Input
                    className={classes.searchFilter}
                    label={content.SEARCH_LABEL}
                    externalEndAdornment={{
                      endAdornment: (
                        <IconButton>
                          <SearchRoundedIcon color="action" />
                        </IconButton>
                      ),
                    }}
                    {...props}
                  />
                )}
              </FormField>
            </div>
          </div>
          <div>
            <Button className={classes.clearAll} variant="outlined" onClick={() => resetForm()}>
              {content.CLEAR_ALL}
            </Button>
          </div>
        </div>
        <FilterChips />
      </form>
    </FormProvider>
  );
};
