import { z } from 'zod';

export const webhookStatusActionsSchema = z.union([z.literal('enable'), z.literal('disable'), z.literal('unsuspend')]);

export const webhookStatusParamsSchema = z.object({
  action: webhookStatusActionsSchema,
  webhookId: z.string(),
});

export type TWebhookStatusAction = z.infer<typeof webhookStatusActionsSchema>;
export type TWebhookStatusParams = z.infer<typeof webhookStatusParamsSchema>;
