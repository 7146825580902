import { OptionsObject, SnackbarKey, SnackbarMessage } from '@marlin/shared/ui/snackbar-wrapper';
import { ASSET_TYPE, TFlowLink } from '@marlin/system-map/data-access/system-map';
import { TAssetType } from '@marlin/system-map/shared/data-access-schemas';
import { AxiosError } from 'axios';

import { FLOW_LINK_ERROR_CODES, IFlowLinkError } from './constants';
import { content } from './content';

export const mapFlowLinkToRowData = (data: TFlowLink[]) => {
  return data.map((link) => {
    return {
      id: link.id,
      typeOutlet: link.outletAssetType,
      nameOutlet: link.outletAssetName,
      idOutlet: link.outletAssetId,
      typeInlet: link.inletAssetType,
      nameInlet: link.inletAssetName,
      idInlet: link.inletAssetId,
      deviceTypeInlet: link.inletDeviceType,
      deviceTypeOutlet: link.outletDeviceType,
      nodeTypeInlet: link.inletNodeType,
      nodeTypeOutlet: link.outletNodeType,
      isAttachment: link.isAttachment,
      outletTags: link.outletTags || [],
      inletTags: link.inletTags || [],
      linkTags: link?.linkTags || [],
      inletLinkedId: link.inletLinkedId,
      outletLinkedId: link.outletLinkedId,
    };
  });
};

export const mapFlowLinkType = (type: TAssetType | undefined): ASSET_TYPE | undefined => {
  switch (type) {
    case 'DEVICE': {
      return ASSET_TYPE.DEVICE;
    }
    case 'EQUIPMENT': {
      return ASSET_TYPE.EQUIPMENT;
    }
    case 'LOCATION': {
      return ASSET_TYPE.LOCATION;
    }
    default: {
      return undefined;
    }
  }
};

const getErrorMessage = (errorCode?: FLOW_LINK_ERROR_CODES, message?: string) => {
  switch (errorCode) {
    case FLOW_LINK_ERROR_CODES.EXISTING_LINK: {
      return content.ADD_EXISTING_FLOW_LINK_ERROR;
    }

    case FLOW_LINK_ERROR_CODES.ATTACHED_LOCATION_HIERARCHY: {
      return content.ADD_ATTACHED_LOCATION_HIERARCHY;
    }

    case FLOW_LINK_ERROR_CODES.ATTACHED_EQUIPMENT_HIERARCHY: {
      return content.ADD_ATTACHED_EQUIPMENT_HIERARCHY;
    }

    default: {
      return message || content.ADD_FLOW_LINK_ERROR;
    }
  }
};

interface IHandleErrorProps {
  error: AxiosError<IFlowLinkError>;
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey;
}

export const handleFlowLinkError = ({ error, enqueueSnackbar }: IHandleErrorProps) => {
  const errorData = error?.response?.data;

  enqueueSnackbar(getErrorMessage(errorData?.errorCode, errorData?.title), {
    variant: 'error',
    preventDuplicate: true,
  });
};
