export const content = {
  MESSAGE: 'A new loop has been detected',
  ASSET_MESSAGE: 'At asset',
  ASSET_PARENT_MESSAGE: 'Parent is',
  SENSOR: 'Sensor',
  EQUIPMENT: 'Equipment',
  LOCATION: 'Location',
  ATTACHMENT_LABEL: 'Att',
  TEMPERATURE_LABEL: 'Temperature Sensor',
  PRESSURE_LABEL: 'Pressure Sensor',
  LEAK_LABEL: 'Leak Detector',
  FLOW_LABEL: 'Flow Meter',
  WATER_SYSTEM: 'Water system',
  REGULATOR: 'Regulator',
};
