import { useRegisterValidate } from '@marlin/account-data-access-onboarding';
import { getLogger } from '@marlin/shared/utils/logger';
import debounce from 'lodash/debounce';
import { useEffect, useMemo, useState } from 'react';

import { content } from '../../../content';
import { EQUIPMENT_STATUS, TEquipment } from '../../onboarding.schema';
import { useOnboardingState } from '../../use-onboarding-state.hook';
import { useEquipmentErrorsState } from './use-equipment-errors-state.hook';

const parseError = (errorCode?: number) => {
  if (errorCode === 405 || errorCode === 402) {
    return content.REGISTERED_ERROR;
  }
  return content.INCORRECT_ERROR;
};

export const useEquipmentStatus = (equipment: TEquipment, index: number) => {
  const [status, setStatus] = useState<EQUIPMENT_STATUS>(equipment.status);
  const [error, setError] = useState<string | null>(null);
  const { mutateAsync: validate, isSuccess, isError } = useRegisterValidate();
  const { addEquipmentError } = useEquipmentErrorsState();
  const { updateEquipment } = useOnboardingState();

  const debounceValidate = useMemo(
    () =>
      debounce((newEquipment: TEquipment, newIndex: number) => {
        validate({ equipment: newEquipment }).catch((reason) => {
          setStatus(EQUIPMENT_STATUS.ERROR);
          setError(parseError(reason.response.data.errorCode));
          getLogger()?.track(content.LOGGER.SELF_SERVICE.SAVE_EQUIPMENT_ERROR, {
            ...equipment,
            error: parseError(reason.response.data.errorCode),
          });
          addEquipmentError({ data: true, index: newIndex });
          updateEquipment({ data: { ...newEquipment, status: EQUIPMENT_STATUS.ERROR }, index: newIndex });
        });
      }, 10),
    [addEquipmentError, updateEquipment, validate]
  );

  useEffect(() => {
    if (status === EQUIPMENT_STATUS.NEW && equipment.status === EQUIPMENT_STATUS.NEW) {
      setStatus(EQUIPMENT_STATUS.CONNECTING);
      debounceValidate(equipment, index);
    }
  }, [equipment, status, index, debounceValidate]);

  useEffect(() => {
    if (equipment.status === EQUIPMENT_STATUS.REFETCH) {
      setStatus(EQUIPMENT_STATUS.CONNECTING);
      debounceValidate(equipment, index);
    }
  }, [equipment, status, index, debounceValidate]);

  useEffect(() => {
    if (isSuccess && status !== EQUIPMENT_STATUS.OK) {
      setStatus(EQUIPMENT_STATUS.OK);
      updateEquipment({ data: { ...equipment, status: EQUIPMENT_STATUS.OK }, index });
      addEquipmentError({ data: false, index });
      setError(null);
      getLogger()?.track(content.LOGGER.SELF_SERVICE.SAVE_EQUIPMENT_SUCCESS, {
        ...equipment,
      });
    } else if (isError && status !== EQUIPMENT_STATUS.ERROR) {
      setStatus(EQUIPMENT_STATUS.ERROR);
      updateEquipment({ data: { ...equipment, status: EQUIPMENT_STATUS.ERROR }, index });
      addEquipmentError({ data: true, index });
    }
  }, [isSuccess, isError, addEquipmentError, index, updateEquipment, equipment, status]);

  return { status, error };
};
