import { MarlinTheme } from '@marlin/shared/theme';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { EQUIPMENT_TYPE } from '@marlin/shared/utils/zod';
import { makeStyles } from 'tss-react/mui';

import { EquipmentTypeMap } from './constants/equipment-type-labels';
import { getEquipmentIcon } from './public-api';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  equipmentTypeOption: {
    display: 'inline-flex',
    alignItems: 'center',
    columnGap: theme.typography.pxToRem(10),
    flexWrap: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  equipmentTypeIcon: {
    color: theme.palette.action.active,
  },
  equipmentTypeName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'inline-block',
    flex: 1,
    minWidth: 0,
    boxSizing: 'border-box',
  },
}));

interface IEquipmentTypeProps {
  equipmentType?: EQUIPMENT_TYPE;
  showName?: boolean;
  className?: string;
}

export const EquipmentType = ({ equipmentType, showName = true, className = '' }: IEquipmentTypeProps) => {
  const { classes, cx } = useStyles();

  if (!equipmentType) {
    return null;
  }

  const name = EquipmentTypeMap.get(equipmentType) ?? 'Equipment';
  return (
    <Tooltip text={name} placement="top">
      <div className={cx(classes.equipmentTypeOption, className)}>
        {getEquipmentIcon(equipmentType, classes.equipmentTypeIcon)}
        {showName && <span className={classes.equipmentTypeName}>{name}</span>}
      </div>
    </Tooltip>
  );
};
