import { TUnitOfMeasure, UnitOfMeasure } from '@marlin/shared/utils-format-reading';
import { z } from 'zod';

import { content } from '../content';
import { TUomType } from './calibration-forms/uom-toggle-options';

export interface ICalibration {
  value?: number;
  timestamp?: string;
  uom?: TUnitOfMeasure;
}

const checkLength = (digits: number) => (value: number) => `${value}`.replace('.', '').length <= digits;

export const CalibrationMeasurementFormSchema = z
  .object({
    startMeasurementValue: z.coerce
      .number({ invalid_type_error: content.CALIBRATION_MODAL.ERRORS.INVALID_NUMBER(12) })
      .refine(checkLength(12), content.CALIBRATION_MODAL.ERRORS.INVALID_NUMBER(12)),
    startMeasurementTimestamp: z.coerce.string(),
    endMeasurementValue: z.coerce
      .number({ invalid_type_error: content.CALIBRATION_MODAL.ERRORS.INVALID_NUMBER(12) })
      .refine(checkLength(12), content.CALIBRATION_MODAL.ERRORS.INVALID_NUMBER(12)),
    endMeasurementTimestamp: z.coerce.string(),
    measurementUnit: UnitOfMeasure,
  })
  .superRefine((data, ctx) => {
    const startDate = new Date(data.startMeasurementTimestamp);
    const endDate = new Date(data.endMeasurementTimestamp);

    if (startDate.getTime() >= endDate.getTime()) {
      ctx.addIssue({
        message: content.CALIBRATION_MODAL.ERRORS.START_BEFORE_END,
        code: z.ZodIssueCode.custom,
        path: ['endMeasurementTimestamp'],
      });
    }
    if (data.startMeasurementValue >= data.endMeasurementValue) {
      ctx.addIssue({
        message: content.CALIBRATION_MODAL.ERRORS.START_READING_BEFORE_END_READING,
        code: z.ZodIssueCode.custom,
        path: ['endMeasurementValue'],
      });
    }
  });

export const CalibrationManualFormSchema = z.object({
  unitsPerPulse: z.coerce
    .number({ invalid_type_error: content.CALIBRATION_MODAL.ERRORS.INVALID_NUMBER(6) })
    .refine(checkLength(6), content.CALIBRATION_MODAL.ERRORS.INVALID_NUMBER(6)),
  measurementUnit: UnitOfMeasure,
});

export const handleErrorCodes = (errorCode?: number) => {
  const genericError = content.CALIBRATION_MODAL.ON_PROCESS_ERROR;
  switch (errorCode) {
    case 101: {
      return `${genericError}: ${content.CALIBRATION_MODAL.ERRORS.DEVICE_NOT_FOUND}`;
    }
    case 60100: {
      return `${genericError}: ${content.CALIBRATION_MODAL.ERRORS.START_BEFORE_COMMISION}`;
    }
    case 60110: {
      return `${genericError}: ${content.CALIBRATION_MODAL.ERRORS.REQUIRED_VALUES}`;
    }
    default: {
      return genericError;
    }
  }
};

export const subtitle = (uomOption: TUomType) => {
  switch (uomOption) {
    case 'ft3':
      return content.CUBIC_FEET;
    case 'l':
      return content.LITERS;
    case 'gal':
      return content.GALLONS;
    case 'm3':
      return content.M3;
    default:
      return content.CUBIC_FEET;
  }
};
