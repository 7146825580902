import { TAlertChartData } from '@marlin/asset/data-access/telemetry';

enum UNIT_OF_MEASURE {
  LEAK = 'WaterDetect',
  FAHRENHEIT = 'Fahrenheit',
  PRESSURE = 'PSI',
  FLOW = 'GPM',
}

export enum DEVICE_TYPE {
  TEMPERATURE = 'TEMPERATURE',
  PRESSURE = 'PRESSURE',
  LEAK = 'LEAK',
  PULSE_METER = 'PULSE_METER',
  UNKNOWN = 'UNKNOWN',
}

export interface IChartData {
  batteryLevel?: number;
  enqueuedDateTime?: string;
  eventDateTime?: string;
  eventId?: string;
  manufacturerId?: string;
  signalStrength?: number;
  dataPoint?: number;
  unitOfMeasure?: UNIT_OF_MEASURE;
  voltage?: number;
}

export interface ITimeSeriesSummary {
  chartData: IChartData[];
  high: IChartData;
  low: IChartData;
  recent: IChartData;
}

export interface ICharTicks {
  lowest: number;
  highest: number;
  gap: number;
}

export interface IChartPoint {
  timestamp: number;
  value: number | boolean | null;
}

export interface IRangedAreaChartPoint {
  x: number;
  y: [number, number];
}

export type TAlertChartPoint = IChartPoint & TAlertChartData;
export type TBucketedAlert = TAlertChartData & { buckets: number[] };
export type TAlertBuckets = Record<string, TBucketedAlert>;
export type TGroupedAlertAnnotation = {
  x?: number;
  borderColor: string;
  strokeDashArray: number;
  id: string;
  ruleId: string;
};

export interface IRangedAreaSeries {
  name: string;
  type: 'rangeArea';
  ruleId: string;
  data: IRangedAreaChartPoint[];
}
