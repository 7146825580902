import { TExtractFnReturnType, useQuery } from '@marlin/shared/utils/react-query';

import { getRecipientById } from '../infrastructure/get-recipient-by-id';
import { queryKey } from '../query-key.enum';

type TQueryFnType = typeof getRecipientById;

export const useRecipientById = (id: string) => {
  return useQuery<TExtractFnReturnType<TQueryFnType>>({
    queryKey: queryKey.RECIPIENTS_ID(id),
    queryFn: () => getRecipientById(id),
  });
};
