import { TLocationHierarchyLevel } from '@marlin/asset/data-access/location';
import { MarlinTheme } from '@marlin/shared/theme';
import { Link } from '@marlin/shared/ui-page';
import { TAB_VALUES, routes } from '@marlin/shared/utils-routes';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import { makeStyles } from 'tss-react/mui';

interface IHierarchyLevelProps {
  hierarchyLevel: TLocationHierarchyLevel;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.typography.pxToRem(8),
      paddingBottom: theme.typography.pxToRem(8),
    },
  },

  link: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.main,
  },

  icon: {
    marginRight: theme.typography.pxToRem(8),
    color: theme.palette.action.active,
    height: theme.typography.pxToRem(16),
    width: theme.typography.pxToRem(16),
  },
}));

export const HierarchyLevelLabel = ({ hierarchyLevel }: IHierarchyLevelProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.wrapper}>
      <LocationOnRoundedIcon className={classes.icon} />
      <Link className={classes.link} to={routes.locations.details.url(hierarchyLevel.id, TAB_VALUES.HierarchyView)}>
        {hierarchyLevel.name}
      </Link>
    </div>
  );
};
