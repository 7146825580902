import { EquipmentDetails } from '@marlin/asset/feature/equipment-details';
import { EquipmentHub } from '@marlin/asset/feature/equipment-hub';
import { CreateEquipment, UpdateEquipment } from '@marlin/asset/feature/equipment-upsert';
import { PERMISSIONS, PermissionGuard } from '@marlin/shared/utils-permission';
import { routes } from '@marlin/shared/utils-routes';
import { Route } from 'react-router-dom';

export const EquipmentRoutes = (): JSX.Element => {
  const path = routes.equipments.root;

  return (
    <Route path={path}>
      <Route index element={<EquipmentHub />} />
      <Route
        path={routes.equipments.create.path}
        element={
          <PermissionGuard redirectTo={path} permissions={[PERMISSIONS.CREATE_NEW_EQUIPMENT]}>
            <CreateEquipment />
          </PermissionGuard>
        }
      />
      <Route
        path={routes.equipments.update.path}
        element={
          <PermissionGuard redirectTo={path} permissions={[PERMISSIONS.EDIT_EQUIPMENT]}>
            <UpdateEquipment />
          </PermissionGuard>
        }
      />
      <Route path={routes.equipments.details.path} element={<EquipmentDetails />} />
    </Route>
  );
};
