import { queryClient } from '@marlin/shared/utils/react-query';
import { UseMutationResult, useMutation } from '@marlin/shared/utils/react-query';

import { TCreateFlowLink } from '../flow-link.model.schema';
import { TGraph } from '../flow-map.model.schema';
import { createAttachment } from '../infrastructure/create-attachment';
import { queryKey } from './query-key.enum';

export const useCreateAttachment = (): UseMutationResult<TGraph, unknown, TCreateFlowLink> => {
  return useMutation<TGraph, unknown, TCreateFlowLink>({
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: queryKey.FLOW_LINK() });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.FLOW_LINK() });
      queryClient.invalidateQueries({ queryKey: queryKey.FLOW_LINKS() });
      queryClient.invalidateQueries({ queryKey: queryKey.GRAPH() });
    },
    mutationFn: createAttachment,
  });
};
