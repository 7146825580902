import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  coordinates: {
    color: theme.palette.text.secondary,
    fontFeatureSettings: "'liga' off",
    fontSize: theme.typography.pxToRem(12),
    letterSpacing: theme.typography.pxToRem(0.4),
    lineHeight: theme.typography.pxToRem(20),
    '&:not(:last-child)': {
      '&::after': {
        content: '" "',
      },
    },
  },
}));
