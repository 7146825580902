import { TDatapointsResponse } from '@marlin/shared/utils/datapoint-mappers';
import { useObservableState } from 'observable-hooks';

import { getDatapoints } from '../infrastructure/equipment';

export const useDatapoints = (callback?: (data: TDatapointsResponse) => void) => {
  const [datapoints] = useObservableState(() => getDatapoints(callback), {} as TDatapointsResponse);

  return datapoints;
};
