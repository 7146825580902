import { useCurrentUser, useUpdateOrg } from '@marlin/account-data-access-organization';
import { ALERT_STATUS_FILTER, useAlert, useFilteredAlertsWithPaging } from '@marlin/alert/data-access/alert-action';
import { useDevice } from '@marlin/asset/data-access/device';
import { Loader } from '@marlin/shared/ui-loader';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { TDateString, createDateString, isBefore } from '@marlin/shared/utils-common-date';
import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import { fixInvalidQueryString, routes } from '@marlin/shared/utils-routes';
import { useQueryParameters } from '@marlin/shared/utils/url-params';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';
import { ModalBody } from './modal/modal-body.component';
import { ModalFooter } from './modal/modal-footer.component';
import { ModalTitle } from './modal/modal-title.component';

const defaultParams = {
  status: ALERT_STATUS_FILTER.CURRENT,
  pageSize: 1,
  page: 1,
  locationIds: [],
  equipmentIds: [],
  deviceIds: [],
  deviceTypes: [],
};

export const useStyles = makeStyles()(() => ({
  modal: {
    zIndex: 1500,
  },
}));

export const AlertLink = () => {
  const { orgId, sensorId: deviceId } = useParams();
  const { classes } = useStyles();
  const queryParams = useQueryParameters();
  const alertId = queryParams.get('alertId');
  const { data, isFetched } = useCurrentUser();
  const shouldRefetch = data?.currentOrganization?.organizationId !== orgId;
  const { modalDispatch } = useContext(ModalContext);

  const { enqueueSnackbar } = useSnackbar();
  const onSuccess = () =>
    enqueueSnackbar(content.ORGANIZATION_HAS_BEEN_CHANGED, {
      variant: 'success',
      preventDuplicate: true,
    });
  const updateOrgMutation = useUpdateOrg({
    shouldRefetch,
    onSuccess: shouldRefetch ? onSuccess : undefined,
  });
  const [enableDevice, setEnableDevice] = useState(false);
  const [redirectLink, setRedirectLink] = useState<string | null>(null);
  const {
    data: deviceData,
    isFetched: isDeviceFetched,
    isError: isDeviceError,
  } = useDevice({
    deviceId: deviceId || '',
    enabled: enableDevice,
  });

  const { data: alertData, isFetched: isAlertFetched } = useAlert(alertId || '', enableDevice && !!alertId);
  const deviceIds = useMemo(() => (deviceId ? [deviceId] : []), [deviceId]);

  const { data: alertsData, isFetched: alertsFetched } = useFilteredAlertsWithPaging({
    params: { ...defaultParams, deviceIds },
    staleTime: 2000,
    enabled: deviceIds.length > 0,
  });

  const homeUrl = routes.home.root;
  const alertSensorUrl = fixInvalidQueryString(
    routes.deviceDrawer.open(
      routes.alerts.list.url({ alertDeviceId: deviceId, alertDeviceName: deviceData?.name }),
      deviceId ?? ''
    )
  );

  const alertEquipmentUrl = fixInvalidQueryString(
    routes.alerts.list.url({ alertDeviceId: deviceId, alertDeviceName: deviceData?.name })
  );

  const alertHubUrl = fixInvalidQueryString(routes.alerts.list.url());

  useEffect(() => {
    if (isFetched) {
      const orgOnList = !!data?.organizations.find((org) => org.organizationId === orgId);
      if (orgOnList) {
        updateOrgMutation.mutateAsync(orgId ?? '').then(() => {
          setEnableDevice(true);
        });
      } else {
        setRedirectLink(homeUrl);
      }
    }
    // note: run it only once, on org data fetched
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isFetched]);

  const handleCloseModal = useCallback(() => {
    modalDispatch({
      type: MODAL_ACTION_TYPE.DISMISS,
    });
  }, [modalDispatch]);

  const handleOpenModal = useCallback(
    (commissionDate: TDateString) => {
      modalDispatch({
        type: MODAL_ACTION_TYPE.SHOW,
        payload: {
          title: <ModalTitle />,
          body: <ModalBody commissionDate={commissionDate} />,
          footer: <ModalFooter handleCloseModal={handleCloseModal} />,
          presentationClassName: classes.modal,
        },
      });
      return;
    },
    [modalDispatch, handleCloseModal, classes.modal]
  );

  const handleCommisionDate = useCallback(() => {
    if (
      deviceData?.deviceType !== 'UNKNOWN' &&
      alertsFetched &&
      alertsData?.pagination.totalItems === 0 &&
      isAlertFetched
    ) {
      const commissionDate = createDateString(deviceData?.commissionDate ?? undefined);
      const startTime = createDateString(alertData?.startTime || undefined);
      const resolveTime = createDateString(alertData?.resolveTime || undefined);
      if (resolveTime && isBefore(resolveTime, commissionDate)) {
        handleOpenModal(commissionDate);
      } else if (isBefore(startTime, commissionDate)) {
        handleOpenModal(commissionDate);
      }
    }
  }, [
    alertData?.resolveTime,
    alertData?.startTime,
    alertsData?.pagination.totalItems,
    alertsFetched,
    deviceData?.commissionDate,
    deviceData?.deviceType,
    handleOpenModal,
    isAlertFetched,
  ]);

  useEffect(() => {
    if (isDeviceFetched && alertsFetched && isAlertFetched) {
      if (isDeviceError) {
        setRedirectLink(alertHubUrl);
      } else {
        handleCommisionDate();
        setRedirectLink(deviceData?.deviceType === 'UNKNOWN' ? alertEquipmentUrl : alertSensorUrl);
      }
    }
  }, [
    isDeviceFetched,
    isAlertFetched,
    alertsFetched,
    handleCommisionDate,
    isDeviceError,
    alertHubUrl,
    deviceData?.deviceType,
    alertEquipmentUrl,
    alertSensorUrl,
  ]);

  if (!redirectLink) {
    return <Loader />;
  }

  return <Navigate to={redirectLink} />;
};
