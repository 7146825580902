import { MarlinTheme } from '@marlin/shared/theme';
import { Chip as MuiChip, ChipProps } from '@mui/material';
import { alpha } from '@mui/system';
import { makeStyles } from 'tss-react/mui';

export interface IChipProps extends ChipProps {}

interface IStyles {
  color?: ChipProps['color'];
}

export const useStyles = makeStyles<IStyles>()((theme: MarlinTheme, { color }) => {
  const chipColor = theme.palette[color || 'primary'];

  return {
    chip: {
      color: chipColor.dark,
      backgroundColor: alpha(chipColor.main, 0.12),
      '& .MuiChip-deleteIcon': {
        color: chipColor.main,
        ':hover': {
          color: chipColor.dark,
        },
      },
    },
  };
});

export const Chip = (props: IChipProps) => {
  const { classes, cx } = useStyles({ color: props.color });

  return <MuiChip {...props} className={cx(classes.chip, props.className)} color={props.color || 'secondary'} />;
};
