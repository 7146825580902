import debounce from 'lodash/debounce';
import { useMemo, useState } from 'react';

import { IAnnotation } from './types';

interface IUseAnnotationTooltip<T> {
  currentAnnotationTooltip: T | null;
  onAnnotationLabelEnter: (annotationTooltip: T) => void;
  onAnnotationLabelLeave: () => void;
}

export const useAnnotationTooltip = <T = IAnnotation>(): IUseAnnotationTooltip<T> => {
  const [currentAnnotationTooltip, setCurrentAnnotationTooltip] = useState<T | null>(null);

  const onAnnotationLabelEnter = useMemo(
    () =>
      debounce((annotationTooltip: T) => {
        setCurrentAnnotationTooltip(annotationTooltip);
      }, 10),
    []
  );

  const onAnnotationLabelLeave = useMemo(
    () =>
      debounce(() => {
        setCurrentAnnotationTooltip(null);
      }, 10),
    []
  );

  return useMemo(
    () => ({ currentAnnotationTooltip, onAnnotationLabelEnter, onAnnotationLabelLeave }),
    [currentAnnotationTooltip, onAnnotationLabelEnter, onAnnotationLabelLeave]
  );
};
