import { FullLogo } from '@marlin/shared/theme';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { Button, Typography } from '@mui/material';
import MobileStepper from '@mui/material/MobileStepper';
import { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { virtualize } from 'react-swipeable-views-utils';

import { SLIDE } from '../../teaser-modal-provider/types';
import { images, maxSteps } from './images';
import { useStyles } from './slider.styles';

const VirtualizeSwipeableViews = virtualize(SwipeableViews);

interface ISliderProps {
  slide: SLIDE;
}

export const Slider = ({ slide }: ISliderProps) => {
  const { classes } = useStyles();
  const [activeStep, setActiveStep] = useState<SLIDE>(slide);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % images.length);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep === 0) {
        return images.length - 1;
      }
      return Math.abs(prevActiveStep - 1) % images.length;
    });
  };

  const slideRenderer = ({ key, index }: { key: number; index: number }) => {
    const dataIndex = Math.abs(index) % images.length;
    const Slide = images[dataIndex].Img;
    return (
      <div key={key}>
        <Slide className={classes.img} />
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.logo}>
        <FullLogo />
      </div>
      <div>
        <VirtualizeSwipeableViews slideRenderer={slideRenderer} index={activeStep} />
      </div>
      <div>
        <Typography className={classes.imgLabel}>{images[activeStep].label}</Typography>
      </div>
      <div>
        <MobileStepper
          steps={maxSteps}
          className={classes.stepper}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button size="small" onClick={handleNext} data-testid="btn_arrow_right">
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button size="small" onClick={handleBack} data-testid="btn_arrow_right">
              <KeyboardArrowLeft />
            </Button>
          }
        />
      </div>
    </div>
  );
};
