import { TEquipmentDetailsRow } from '@marlin/asset/shared/equipment-config';
import { formatTimestamp } from '@marlin/shared/utils-common-date';
import { TDatapointWithUiMetadata } from '@marlin/shared/utils/datapoint-mappers';
import { TEquipment } from '@marlin/shared/utils/zod';
import moment from 'moment/moment';

import { content } from '../../desktop/content';

const lastReadingFormat = 'MM/DD/yyyy hh:mm A';

export const mapEquipmentDataSource = (
  row: TEquipmentDetailsRow & { valueSource: 'equipment' },
  equipment: TEquipment
) => {
  if (!Array.isArray(row.name)) {
    const value = row.valueCallback ? row.valueCallback(equipment?.[row.name]) : equipment?.[row.name];
    if (!value || typeof value === 'number' || typeof value === 'string') {
      return getRowValue(row.label, String(value ?? ''), row.tooltip);
    }
  }

  if (Array.isArray(row.name)) {
    const callbackValues = row.name.map((name) => equipment?.[name]);
    const value = row.valueCallback?.(callbackValues);
    return getRowValue(row.label, value, row.tooltip);
  }

  return undefined;
};

export const mapDatapointsDataSource = (
  row: TEquipmentDetailsRow,
  datapoints: TDatapointWithUiMetadata[],
  getDatapoint: (name: string, options?: { displayOutdatedValues: boolean }) => TDatapointWithUiMetadata | undefined
) => {
  if (!Array.isArray(row.name)) {
    const datapoint = getDatapoint(row.name, { displayOutdatedValues: true });
    return mapDatapointSingleName(row, datapoint);
  }

  return mapDatapointsMultipleNames(row, datapoints);
};

export const mapLastReadingTime = (
  row: TEquipmentDetailsRow,
  equipment: TEquipment,
  timestamp: string | undefined,
  newestDate: moment.Moment | null
) => {
  const rowBase = { label: row.label, tooltip: row.tooltip };

  if (timestamp) {
    return {
      ...rowBase,
      value: row.valueCallback ? row.valueCallback(timestamp) : formatTimestamp(timestamp, lastReadingFormat),
    };
  }

  if (equipment.devices?.[0]?.lastReadingTime) {
    return {
      ...rowBase,
      value: row.valueCallback
        ? row.valueCallback(equipment.devices?.[0].lastReadingTime)
        : formatTimestamp(equipment.devices?.[0].lastReadingTime, lastReadingFormat),
    };
  }

  const value = row.valueCallback
    ? row.valueCallback(newestDate?.toString())
    : formatTimestamp(newestDate?.toString(), lastReadingFormat);

  return {
    ...rowBase,
    value: value ?? content.EMPTY_DATAPOINT_VALUE,
  };
};

const mapDatapointSingleName = (row: TEquipmentDetailsRow, datapoint: TDatapointWithUiMetadata | undefined) => {
  const value = row.valueCallback ? row.valueCallback(datapoint?.displayedValue) : datapoint?.displayedValue;
  return getRowValue(row.label, value, row.tooltip);
};

const mapDatapointsMultipleNames = (row: TEquipmentDetailsRow, datapoints: TDatapointWithUiMetadata[]) => {
  if (Array.isArray(row.name)) {
    const callbackValues = row.name.map(
      (name) => datapoints?.find((datapoint) => datapoint.name.toLowerCase() === name.toLowerCase())?.displayedValue
    );
    const value = row.valueCallback?.(callbackValues);
    return getRowValue(row.label, value, row.tooltip);
  }

  return undefined;
};

export const mapDatapointsMixNames = (
  row: TEquipmentDetailsRow & { valueSource: 'mix' },
  datapoints: TDatapointWithUiMetadata[],
  equipment: TEquipment
) => {
  if (Array.isArray(row.name)) {
    const callbackValues = row.name.map(
      (name) =>
        datapoints?.find((datapoint) => datapoint.name.toLowerCase() === name.toLowerCase())?.displayedValue ??
        equipment?.[name as keyof TEquipment]
    );

    const value = row.valueCallback?.(callbackValues);
    return getRowValue(row.label, value, row.tooltip);
  }

  return undefined;
};

export const mapDeviceDataSource = (row: TEquipmentDetailsRow & { valueSource: 'device' }, equipment: TEquipment) => {
  const device = equipment?.devices?.[0];
  if (!device) return getEmptyRow(row.label, row.tooltip);

  if (!Array.isArray(row.name)) {
    const value = row.valueCallback ? row.valueCallback(device?.[row.name]) : device?.[row.name];
    return getRowValue(row.label, String(value ?? ''), row.tooltip);
  }

  const callbackValues = row.name.map((name) => device?.[name]);
  const value = row.valueCallback?.(callbackValues);
  return getRowValue(row.label, value, row.tooltip);
};

export const mapAdditionalPropertiesDataSource = (
  row: TEquipmentDetailsRow & {
    valueSource: 'additionalProperties';
  },
  equipment: TEquipment
) => {
  const additionalProperties = equipment?.additionalProperties;

  if (!additionalProperties || !additionalProperties[row.name]) {
    return getEmptyRow(row.label, row.tooltip);
  }

  if (!Array.isArray(row.name)) {
    const value = row.valueCallback
      ? row.valueCallback(additionalProperties[row.name])
      : additionalProperties[row.name];
    return getRowValue(row.label, String(value ?? ''), row.tooltip);
  }

  const callbackValues = row.name.map((name) => additionalProperties?.[name]);
  const value = row.valueCallback?.(callbackValues);
  return getRowValue(row.label, value, row.tooltip);
};

const getRowValue = (label: string, value: string | undefined, tooltip?: string) => ({
  label,
  value: value ? value : content.EMPTY_DATAPOINT_VALUE,
  tooltip: tooltip,
});

const getEmptyRow = (label: string, tooltip?: string) => ({
  label,
  value: content.EMPTY_DATAPOINT_VALUE,
  tooltip,
});
