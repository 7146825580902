// eslint-disable-next-line @nx/enforce-module-boundaries
import { useFilteredEquipmentsWithLoadMore } from '@marlin/asset/data-access/equipment';
import { useSearchFilter } from '@marlin/shared/utils/url-params';
import { Box } from '@mui/material';
import { useMemo } from 'react';

import { TAsset, TAssetResponseSchema } from '../schemas/assets/assets.schema';
import { useEnableOption } from './use-enable-option';

interface IEquipmentSearchWrapper {
  options: TAsset[];
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  totalItems?: number;
  loadedItems?: number;
  isLoading?: boolean;
  isError?: boolean;
  search: (value: string) => void;
  term: string;
  isFetching: boolean;
  isFetchingNextPage: boolean;
  enableOptions: () => void;
}

interface IEquipmentSearchWrapperProps {
  children: (props: IEquipmentSearchWrapper) => JSX.Element;
}

export function EquipmentSearchWrapper({ children }: IEquipmentSearchWrapperProps) {
  const { term, debouncedTerm, search } = useSearchFilter();
  const { enabled, enableOptions } = useEnableOption();

  const equipmentQuery = useFilteredEquipmentsWithLoadMore({
    params: { search: debouncedTerm, pageSize: 10 },
    enabled,
  });

  const options: TAsset[] = useMemo(() => {
    const unfilteredRows: (TAsset | null)[] =
      equipmentQuery.data?.pages?.flatMap((i: TAssetResponseSchema) => i.data) || [];
    return unfilteredRows.filter((i: TAsset | null): i is TAsset => i !== null);
  }, [equipmentQuery.data?.pages]);

  return (
    <Box onClick={enableOptions}>
      {children({
        options,
        hasNextPage: equipmentQuery.hasNextPage,
        totalItems: equipmentQuery.data?.pages[0].pagination.totalItems || 0,
        fetchNextPage: equipmentQuery.fetchNextPage,
        isLoading: equipmentQuery.isLoading,
        isError: equipmentQuery.isError,
        isFetching: equipmentQuery.isFetching,
        isFetchingNextPage: equipmentQuery.isFetchingNextPage,
        enableOptions,
        search,
        term,
      })}
    </Box>
  );
}
