import { HierarchyBreadcrumbs } from '@marlin/asset/shared/ui/hierarchy-breadcrumbs';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { PageContainer } from '@marlin/shared/ui-page';
import { useParams } from 'react-router-dom';

import { Details } from './components/details.component';
import { GridDesktop } from './components/grid/grid.component';
import { LocationDetailsHeader } from './components/header.component';
import { useStyles } from './location-details.component.styles';
import { useLocationDetails } from './use-location-details.hook';

export const LocationDetailsDesktop = () => {
  const { classes } = useStyles();
  const { location, isLoading } = useLocationDetails();
  const { locationId = '' } = useParams();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!location) {
    return null;
  }

  return (
    <PageContainer>
      <div className={classes.wrapper}>
        <HierarchyBreadcrumbs locationId={location.id} selectedItemId={locationId} locationStyle />
        <LocationDetailsHeader location={location} />
        <GridDesktop locationId={location.id} />
        <Details location={location} />
      </div>
    </PageContainer>
  );
};
