const appLoaderId = 'app-loader';
let appLoaderVisible = true;

export const hideAppLoader = (): void => {
  if (!appLoaderVisible) {
    return;
  }

  setTimeout(() => {
    const appLoader = document.getElementById(appLoaderId);
    if (appLoader) {
      appLoader.style.display = 'none';
    }

    appLoaderVisible = false;
  }, 1000);
};
