import { Link } from '@marlin/shared/ui-page';

import { content } from '../../content';
import { BaseError } from './base-error.component';

export interface ILocationErrorProps {
  handleButtonClick?: () => void;
}

export const LocationError = ({ handleButtonClick }: ILocationErrorProps) => {
  return (
    <BaseError
      title={content.ERROR.LOCATION.TITLE}
      subtitle={
        <>
          {content.ERROR.LOCATION.SUBTITLE1}{' '}
          <Link to={`mailto:${content.ERROR.LOCATION.LINK}`} external>
            {content.ERROR.LOCATION.LINK}
          </Link>{' '}
          {content.ERROR.LOCATION.SUBTITLE2}
        </>
      }
      buttonText={content.ERROR.LOCATION.BUTTON}
      handleButtonClick={handleButtonClick}
    />
  );
};
