import isNil from 'lodash/isNil';
import { z } from 'zod';

import { content } from '../content/validation-content';
import {
  deadbandMinHigherThanThresholdMaxIssue,
  deadbandMinLowerThanThresholdMinIssue,
  deadbandValueMaxIssue,
  deadbandValueMinIssue,
  deadbandsValueIssue,
  isDeadbandIssue,
  isDeadbandMaxIssue,
  isDeadbandMaxLowerThanThresholdMinIssue,
  isDeadbandMinHigherThanThresholdMaxIssue,
  isDeadbandMinIssue,
} from './utils';

const thresholdSchema = z.number().transform((value) => (value === null ? undefined : value));

const thresholdValueSchema = z.object({
  enabled: z.boolean().nullish(),
  value: thresholdSchema.nullish(),
});

const deadbandValueSchema = z.object({
  enabled: z.boolean().nullish(),
  value: z.number().nullish(),
});

export type TThresholdValueSchema = z.infer<typeof thresholdValueSchema>;
export type TDeadbandValueSchema = z.infer<typeof deadbandValueSchema>;

export const conditionsSchema = z
  .object({
    thresholdMin: thresholdValueSchema.nullish(),
    thresholdMax: thresholdValueSchema.nullish(),
    deadbandMin: deadbandValueSchema.nullish(),
    deadbandMax: deadbandValueSchema.nullish(),
  })
  .partial()
  .refine(
    ({ thresholdMin, thresholdMax }) =>
      (thresholdMin?.enabled && !isNil(thresholdMin?.value)) || (thresholdMax?.enabled && !isNil(thresholdMax?.value)),
    {
      message: content.REQUIRED_ONE,
    }
  )
  .refine(
    ({ thresholdMin, thresholdMax }) => {
      if (thresholdMin?.enabled && thresholdMax?.enabled && !isNil(thresholdMin.value) && !isNil(thresholdMax.value)) {
        return thresholdMin.value < thresholdMax.value;
      }

      return true;
    },
    { message: content.MIN_MAX_RANGE }
  )
  .refine(({ thresholdMin, deadbandMin }) => {
    if (deadbandMin?.enabled && thresholdMin?.enabled) {
      return isDeadbandMinIssue(deadbandMin, thresholdMin);
    }
    return true;
  }, deadbandValueMinIssue)
  .refine(({ thresholdMax, deadbandMax }) => {
    if (deadbandMax?.enabled && thresholdMax?.enabled) {
      return isDeadbandMaxIssue(deadbandMax, thresholdMax);
    }
    return true;
  }, deadbandValueMaxIssue)
  .refine(({ deadbandMin, deadbandMax }) => {
    if (deadbandMin?.enabled && deadbandMax?.enabled) {
      return isDeadbandIssue(deadbandMax, deadbandMin);
    }
    return true;
  }, deadbandsValueIssue)
  .refine(({ thresholdMin, thresholdMax, deadbandMin }) => {
    if (deadbandMin?.enabled && thresholdMin?.enabled && thresholdMax?.enabled) {
      return isDeadbandMinHigherThanThresholdMaxIssue(deadbandMin, thresholdMin, thresholdMax);
    }
    return true;
  }, deadbandMinHigherThanThresholdMaxIssue)
  .refine(({ thresholdMin, deadbandMax }) => {
    if (deadbandMax?.enabled && thresholdMin?.enabled) {
      return isDeadbandMaxLowerThanThresholdMinIssue(deadbandMax, thresholdMin);
    }
    return true;
  }, deadbandMinLowerThanThresholdMinIssue);
