import { TExtractFnReturnType, getHttpClient } from '@marlin/shared/utils/react-query';
import { useQuery } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import { paths } from '../infrastructure/api';
import { queryKey } from '../query-key.enum';
import { GatewayConfigurationResponse, TGatewayConfigurationResponse } from '../schema/gateway-configuration.schema';

export const getGatewayConfiguration = async (deviceId: string): Promise<TGatewayConfigurationResponse> => {
  const res = await getHttpClient().get<unknown, void>(paths.GATEWAY_CONFIGURATION, {
    params: { deviceId },
  });

  return safeParseData(res, GatewayConfigurationResponse);
};

type TQueryFnType = typeof getGatewayConfiguration;

export const useGatewayConfiguration = ({ deviceId = '' }: { deviceId?: string }) => {
  return useQuery<TExtractFnReturnType<TQueryFnType>>({
    enabled: !!deviceId,
    queryKey: queryKey.GATEWAY_CONFIGURATION(deviceId),
    queryFn: () => getGatewayConfiguration(deviceId),
  });
};
