import { MarlinTheme } from '@marlin/shared/theme';
import { useSearchParams } from '@marlin/shared/utils-router';
import { TSettingsGroup } from '@marlin/shared/utils/datapoint-mappers';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

interface IStyles {
  noPadding: boolean;
}

const useStyles = makeStyles<IStyles>()((theme: MarlinTheme, { noPadding }) => ({
  accordionRoot: {
    '::before': {
      content: 'none',
    },
  },
  accordionSummaryRoot: {
    padding: `${theme.typography.pxToRem(16)} ${theme.typography.pxToRem(24)}`,
    minHeight: theme.typography.pxToRem(64),
    [theme.breakpoints.down('md')]: {
      padding: theme.typography.pxToRem(16),
    },
  },
  accordionSummaryContent: {
    margin: 0,
  },
  accordionExpanded: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  accordionDetailsRoot: {
    padding: noPadding ? 0 : `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(16)}`,
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
}));

interface ISettingsSectionAccordionProps {
  title: string;
  children: React.ReactNode;
  noPadding?: boolean;
  id: TSettingsGroup;
}

export const SettingsSectionAccordion = ({
  title,
  children,
  noPadding = false,
  id,
}: ISettingsSectionAccordionProps) => {
  const { classes } = useStyles({ noPadding });
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <Accordion
      elevation={0}
      disableGutters
      classes={{
        root: classes.accordionRoot,
        expanded: classes.accordionExpanded,
      }}
      expanded={searchParams.get('settingGroupId') === id}
      data-testid={`${id.toLocaleLowerCase()}-section`}
      onChange={(_, rawExpanded) => {
        setSearchParams((currentParams) => {
          if (rawExpanded) {
            currentParams.set('settingGroupId', id);
          } else {
            currentParams.delete('settingGroupId');
          }

          if (searchParams.get('settingId')) currentParams.delete('settingId');

          return currentParams;
        });
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        classes={{
          root: classes.accordionSummaryRoot,
          content: classes.accordionSummaryContent,
        }}
        data-testid={`${id.toLocaleLowerCase()}-section-summary`}
      >
        <Typography variant="h6">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails
        classes={{
          root: classes.accordionDetailsRoot,
        }}
        data-testid={`${id.toLocaleLowerCase()}-section-details`}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};
