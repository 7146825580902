import { useMemo } from 'react';

import { content } from '../../content';
import { useDatapointsContext } from '../../context/datapoints.context';
import { useEquipmentDetailsConfigContext } from '../../context/equipment-details-config-context';
import { useEquipmentContext } from '../../context/equipment.context';

const roleMap = new Map([
  ['0', null],
  ['1', content.ROLE.CLIENT],
  ['2', content.ROLE.MANAGER],
]);

export const useProductDetails = () => {
  const { equipment } = useEquipmentContext();
  const { getDatapoint } = useDatapointsContext();
  const { config } = useEquipmentDetailsConfigContext();
  const managerId = getDatapoint('DeviceGroupManagerId')?.value;
  const plantMode = getDatapoint('PlantMode')?.value;

  const role = useMemo(() => {
    if (!config.dashboard.productDetails?.shouldDisplayRole) {
      return null;
    }

    if (managerId === equipment?.devices?.[0]?.manufacturerId) {
      return content.ROLE.MANAGER;
    }

    return roleMap.get(plantMode ?? '1');
  }, [config.dashboard.productDetails?.shouldDisplayRole, managerId, equipment?.devices, plantMode]);

  const serialNumber = useMemo(() => {
    if (!config.dashboard.productDetails?.shouldDisplaySerialNumber) {
      return null;
    }

    return equipment?.devices?.[0]?.manufacturerId;
  }, [config.dashboard.productDetails?.shouldDisplaySerialNumber, equipment?.devices]);

  return {
    role,
    serialNumber,
    equipment,
  };
};
