import {
  startDatapointCommunication,
  stopDatapointCommunication,
  useEquipmentDeregisteredNotification,
  useMetadata,
  useStartSendingData,
  useStopSendingData,
} from '@marlin/asset/data-access/equipment';
import {
  getDatapointWithUiMetadata,
  getUomFromApiDatapoints,
  mergeDatapointsWithMetadata,
} from '@marlin/asset/shared/utils/datapoint-mappers';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { dateAdapter } from '@marlin/shared/utils-common-date';
import { useIdFromPathname } from '@marlin/shared/utils/url-params';
import { TEquipment } from '@marlin/shared/utils/zod';
import { useEffect, useMemo, useState } from 'react';

import { content } from '../../shared/content';
import { useEquipmentDetailsConfigContext } from '../../shared/context/equipment-details-config-context';
import { useEquipmentDatapoints } from '../../shared/hooks/use-equipment-datapoints.hook';

export const useEquipmentDetails = ({ equipment }: { equipment?: TEquipment | undefined }) => {
  const equipmentId = useIdFromPathname();
  const { enqueueSnackbar } = useSnackbar();
  const equipmentDeregisteredNotification = useEquipmentDeregisteredNotification();
  const isEquipmentDeregistered =
    'equipmentId' in equipmentDeregisteredNotification && equipmentDeregisteredNotification.equipmentId === equipmentId;
  const {
    config: {
      dashboard: { enableStartStopData, datapointCallback },
    },
  } = useEquipmentDetailsConfigContext();

  const { datapointWithUiMetadata, timestamp } = useEquipmentDatapoints(equipmentId);

  const onError = () => {
    enqueueSnackbar(content.DASHBOARD_CONNECTION_ERROR, {
      variant: 'error',
      preventDuplicate: true,
    });
  };

  const {
    isError: isStartDataError,
    isLoading: isStartDataLoading,
    refetch: refetchStartData,
  } = useStartSendingData({
    equipmentId,
    enabled: enableStartStopData,
    onError,
  });

  const { refetch: stopSendingData } = useStopSendingData({ equipmentId });

  useEffect(() => {
    return () => {
      if (enableStartStopData) {
        stopSendingData();
      }
    };
  }, [stopSendingData, enableStartStopData]);

  const {
    data: metadataResponse,
    isLoading: isLoadingMetadata,
    isError: isErrorMetadata,
  } = useMetadata({ equipmentId });
  const defaultReadings = mergeDatapointsWithMetadata(
    metadataResponse?.datapoints,
    equipment?.devices?.[0]?.lastReadingValues,
    datapointCallback
  );

  const datapointsWithUiMetadata = !datapointWithUiMetadata.length ? defaultReadings : datapointWithUiMetadata;
  const datapointsWithLabels = datapointsWithUiMetadata
    .map(getUomFromApiDatapoints(defaultReadings))
    .map(getDatapointWithUiMetadata);

  const [isStartDatapointCommunicationError, setIsStartDatapointCommunicationError] = useState(false);
  const isStartDatapointCommunicationLoading =
    !isStartDataError && !isStartDatapointCommunicationError && !datapointWithUiMetadata;

  useEffect(() => {
    const manufacturerId = equipment?.devices?.[0]?.manufacturerId;

    if (manufacturerId) {
      setIsStartDatapointCommunicationError(false);
      startDatapointCommunication(manufacturerId).subscribe({
        error: (err) => {
          setIsStartDatapointCommunicationError(true);
          enqueueSnackbar(content.DASHBOARD_CONNECTION_ERROR, {
            variant: 'error',
            preventDuplicate: true,
          });
        },
      });
    }

    return () => {
      if (manufacturerId) {
        stopDatapointCommunication(manufacturerId).subscribe({
          error: (err) => {
            return;
          },
        });
      }
    };
  }, [equipment?.devices, enqueueSnackbar]);

  const lastReadingTime = useMemo(() => {
    const allTimestamps = equipment?.devices?.[0]?.lastReadingValues?.map((value) => value.lastReadingTime);
    const uniqueDatesFromTimestamps = Array.from(new Set(allTimestamps))
      .filter((timestamp) => !!timestamp)
      .map((timestamp) => dateAdapter.date(timestamp)!.valueOf());
    return dateAdapter.date(Math.max(...uniqueDatesFromTimestamps));
  }, [equipment?.devices]);

  return {
    datapointsWithUiMetadata: datapointsWithLabels,
    timestamp,
    refetchStartData,
    isError: isErrorMetadata || isStartDataError,
    isLoading: isLoadingMetadata,
    isEquipmentDeregistered,
    isStartDatapointCommunicationLoading: isStartDatapointCommunicationLoading && !datapointsWithLabels.length,
    isStartDataLoading,
    lastReadingTime,
  };
};
