import { MarlinTheme } from '@marlin/shared/theme';
import { Paper, PaperProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { ReactComponent as GoogleLogo } from '../shared/google_powered_by.svg';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  logo: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(16)}`,
  },
  root: {
    [theme.breakpoints.down('md')]: {
      height: '100%',
      boxShadow: 'none',
    },
  },
}));

export function CustomPaper(props: PaperProps) {
  const { classes } = useStyles();
  const { children, ...other } = props;
  return (
    <Paper {...other} classes={{ root: classes.root }}>
      {children}
      <div className={classes.logo}>
        <GoogleLogo />
      </div>
    </Paper>
  );
}
