import { TAppName, environment } from '@marlin/environment';
import { MarlinTheme } from '@marlin/shared/theme';
import { Tooltip, VARIANT } from '@marlin/shared/ui-common-tooltip';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { alpha } from '@mui/system';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';
import { ALERT_STATUS, IUser } from './model';

interface IShowMore {
  users: IUser[];
  automation: boolean;
}

interface ISection {
  label: string;
  sectionUsers: IUser[];
}

interface IStyles {
  error: boolean;
  inProgress: boolean;
  appName: TAppName;
}

const getBackgroundColor = (theme: MarlinTheme, error: boolean, inProgress: boolean, appName: TAppName): string => {
  if (error) {
    return `rgba(211, 47, 47, 0.12)`;
  }

  if (inProgress) {
    return `rgba(237, 108, 2, 0.12)`;
  }

  return appName === 'Nexa' ? alpha(theme.palette.primary.main, 0.08) : theme.palette.action.selected;
};

const getColor = (theme: MarlinTheme, error: boolean, inProgress: boolean, appName: TAppName): string => {
  if (error) {
    return theme.palette.error.main;
  }

  if (inProgress) {
    return theme.palette.warning.main;
  }

  return appName === 'Nexa' ? theme.palette.primary.dark : theme.palette.background.primary;
};

const useStyles = makeStyles<IStyles>()((theme: MarlinTheme, { error, inProgress, appName }) => ({
  root: {
    backgroundColor: getBackgroundColor(theme, error, inProgress, appName),
    padding: `${theme.typography.pxToRem(3)} ${theme.typography.pxToRem(4)}`,
    borderRadius: theme.typography.pxToRem(16),
    height: theme.typography.pxToRem(30),
    width: theme.typography.pxToRem(37),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: theme.typography.pxToRem(13),
  },

  user: {
    marginRight: theme.typography.pxToRem(2),
  },

  section: {
    width: '100%',
  },

  counter: {
    width: '100%',
    textAlign: 'center',
    color: getColor(theme, error, inProgress, appName),
  },
}));

export const ShowMore = ({ users, automation }: IShowMore) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const error: boolean = useMemo(
    () =>
      users.reduce((current: boolean, user: IUser) => (user.status === ALERT_STATUS.FAILED ? true : current), false),
    [users]
  );
  const inProgress: boolean = useMemo(
    () =>
      users.reduce(
        (current: boolean, user: IUser) => (user.status === ALERT_STATUS.IN_PROGRESS ? true : current),
        false
      ),
    [users]
  );
  const {
    classes: { user: userClass, root, counter, section: sectionClass },
  } = useStyles({ error, inProgress, appName: environment.app.name });

  const userNames: JSX.Element = useMemo(() => {
    const isLastElement = (index: number, array: IUser[]) => index === array.length - 1;

    const formatUser = (user: IUser, index: number, array: IUser[]): JSX.Element => (
      <span key={user.id} className={userClass}>
        {user.firstName} {user.lastName}
        {!isLastElement(index, array) && ','}
      </span>
    );

    if (automation) {
      return <>{users.map(formatUser)}</>;
    }

    const errorTo = users.filter((user: IUser) => user.status === ALERT_STATUS.FAILED);
    const sentTo = users.filter((user: IUser) => user.status === ALERT_STATUS.SENT);
    const snoozedTo = users.filter((user: IUser) => user.status === ALERT_STATUS.SNOOZED);
    const inProgressTo = users.filter((user: IUser) => user.status === ALERT_STATUS.IN_PROGRESS);

    const Section = ({ label, sectionUsers }: ISection) =>
      sectionUsers.length ? (
        <Grid item className={sectionClass}>
          <b>{label}:</b> {sectionUsers.map(formatUser)}
        </Grid>
      ) : null;

    return (
      <Grid container direction="column" rowGap={2}>
        <Section label={content.errorMessageAlert} sectionUsers={errorTo} />
        <Section label={content.inProgressMessageAlert} sectionUsers={inProgressTo} />
        <Section label={content.snoozedMessageAlert} sectionUsers={snoozedTo} />
        <Section label={content.successMessageAlert} sectionUsers={sentTo} />
      </Grid>
    );
  }, [automation, users, sectionClass, userClass]);

  if (!users.length) {
    return null;
  }

  return (
    <div className={root}>
      <Tooltip variant={VARIANT.LIGHT} placement={isMobile ? 'top' : 'right'} text={<div>{userNames}</div>}>
        <div className={counter} data-testid="recipients-section-counter">
          {users.length}
        </div>
      </Tooltip>
    </div>
  );
};
