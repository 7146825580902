import { z } from 'zod';

import { content } from '../content';

export const usPostalCodeRegex = /^\d*-?\d*$/;
export const mexicoPostalCodeRegex = /^\d{5}$/;
export const canadianPostalCodeRegex =
  /^([ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]) {0,1}(\d[ABCEGHJKLMNPRSTVWXYZ]\d)$/;

export const validateUsPostalCode = (value: string, addPostalCodeIssue: (message: string) => void) => {
  if (value.length < 5) {
    addPostalCodeIssue(content.REQUIRED_CHARACTERS(5));
  }

  if (value.length > 5 && value.length < 10) {
    addPostalCodeIssue(content.REQUIRED_CHARACTERS(9));
  }

  if (!usPostalCodeRegex.test(value)) {
    addPostalCodeIssue(content.ONLY_NUMBER);
  }
};

export const validateCaPostalCode = (value: string, addPostalCodeIssue: (message: string) => void) => {
  if (value.length < 7) {
    addPostalCodeIssue(content.REQUIRED_CHARACTERS(6));
  }

  if (!canadianPostalCodeRegex.test(value)) {
    addPostalCodeIssue(content.INVALID_POSTAL_CODE);
  }
};

export const validateMexicoPostalCode = (value: string, addPostalCodeIssue: (message: string) => void) => {
  if (value.length < 5) {
    addPostalCodeIssue(content.REQUIRED_CHARACTERS(5));
  }

  if (!mexicoPostalCodeRegex.test(value)) {
    addPostalCodeIssue(content.INVALID_POSTAL_CODE);
  }
};

export const validatePostalCodeMessage = (
  postalCode: string | null | undefined,
  country: string | null | undefined,
  ctx: z.RefinementCtx
) => {
  const addPostalCodeIssue = (message: string) => {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ['postalCode'],
      message,
    });
  };

  if (!postalCode) {
    return;
  }

  if (country === 'Canada') {
    validateCaPostalCode(postalCode, addPostalCodeIssue);
    return;
  }

  if (country === 'Mexico') {
    validateMexicoPostalCode(postalCode, addPostalCodeIssue);
    return;
  }

  validateUsPostalCode(postalCode, addPostalCodeIssue);
};
