import { useQueryParameters } from '@marlin/shared/utils/url-params';

export enum TAB_INDEX {
  DEVICE_DETAILS = 'details',
  ALERTS = 'alerts',
  READINGS = 'readings',
}

const tabUrlQueryParam = 'tab';

const mapParam = (param: string | null): TAB_INDEX => {
  switch (param) {
    case 'alerts': {
      return TAB_INDEX.ALERTS;
    }
    case 'details':
      return TAB_INDEX.DEVICE_DETAILS;
    case 'readings':
      return TAB_INDEX.READINGS;
    default: {
      return TAB_INDEX.READINGS;
    }
  }
};

export const useTab = (): TAB_INDEX => {
  const queryParameters = useQueryParameters();
  return mapParam(queryParameters.get(tabUrlQueryParam));
};
