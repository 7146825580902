import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import { useCallback, useContext, useMemo } from 'react';

import { useOnboardingState } from '../../../use-onboarding-state.hook';
import { DeleteEquipmentModalBody } from './delete-equipment-modal-body.component';
import { DeregisterEquipmentModalFooter } from './delete-equipment-modal-footer.component';
import { DeleteEquipmentModalTitle } from './delete-equipment-modal-title.component';

export const useDeleteModal = (index: number, onDeleteClick: () => void) => {
  const { modalDispatch } = useContext(ModalContext);
  const { deleteEquipment } = useOnboardingState();

  const handleDelete = useCallback(() => {
    deleteEquipment({ index });
    onDeleteClick();
  }, [deleteEquipment, index, onDeleteClick]);

  const modalDeletePayload = useMemo(
    () => ({
      title: <DeleteEquipmentModalTitle />,
      body: <DeleteEquipmentModalBody />,
      footer: <DeregisterEquipmentModalFooter onConfirm={handleDelete} />,
    }),
    [handleDelete]
  );

  const openDeleteModal = useCallback(() => {
    modalDispatch({
      type: MODAL_ACTION_TYPE.SHOW,
      payload: modalDeletePayload,
    });
  }, [modalDispatch, modalDeletePayload]);

  return { openDeleteModal };
};
