import { content } from '../content';

export const CommissionDateModalBody = () => {
  return (
    <span>
      {content.COMMISSION_DATE_MODAL.BODY_1}
      <b>{content.COMMISSION_DATE_MODAL.BODY_2}</b>
      {content.COMMISSION_DATE_MODAL.BODY_3}
      <b>{content.COMMISSION_DATE_MODAL.BODY_4}</b>
    </span>
  );
};
