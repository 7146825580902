import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(25),
  },

  tooltip: {
    marginLeft: theme.typography.pxToRem(8),
  },
}));
