import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';

import { content } from './content';

interface IActionEditProps {
  onClick: () => void;
  disabled?: boolean;
}

export const ActionEdit = ({ onClick, disabled = false }: IActionEditProps) => {
  return (
    <MenuItem
      disabled={disabled}
      onClick={(event) => {
        onClick();
        event.stopPropagation();
      }}
    >
      <ListItemIcon>
        <EditRoundedIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>{content.ACTION_EDIT}</ListItemText>
    </MenuItem>
  );
};
