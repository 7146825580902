import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    padding: theme.typography.pxToRem(8),
  },

  criticalityRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.typography.pxToRem(4),
    marginTop: theme.typography.pxToRem(4),
  },

  labelItem: {
    flexBasis: '10%',
    maxWidth: '10%',
  },

  descriptionItem: {
    flexBasis: '90%',
    maxWidth: '90%',
  },

  dot: {
    marginTop: theme.typography.pxToRem(4),
    height: theme.typography.pxToRem(12),
    width: theme.typography.pxToRem(12),
    borderRadius: '50%',
    display: 'inline-block',
  },

  high: {
    backgroundColor: theme.palette.error.main,
  },

  low: {
    backgroundColor: theme.palette.warning.main,
  },

  no: {
    backgroundColor: theme.palette.success.light,
  },
}));
