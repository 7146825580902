import { content } from './content';
import { useStyles } from './empty-organization-item.styles';

export const EmptyOrganizationItem = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.emptyItemContainer}>
      <div className={classes.emptyItemText}>{content.NO_FILTER_RESULTS}</div>
    </div>
  );
};
