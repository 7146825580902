import { TMutationConfig, getHttpClient, queryClient } from '@marlin/shared/utils/react-query';
import { UseMutationOptions, useMutation } from '@marlin/shared/utils/react-query';
import { FieldValues } from 'react-hook-form';

import { ILocation } from '../location.model';

const createLocationDto = (data: FieldValues) => {
  for (const variableKey in data) {
    if (data[variableKey] === '') {
      delete data[variableKey];
    }
  }
  return data;
};

type TCreateLocationType = {
  data: ILocation;
};

export const createLocation = ({ data }: TCreateLocationType): Promise<ILocation> =>
  getHttpClient().post('/api/Location', createLocationDto(data));

type TUseCreateLocationOptions = {
  config?: TMutationConfig<typeof createLocation>;
  onSuccess?: () => void;
  onError?: () => void;
};

export const useCreateLocation = ({ config, onError, onSuccess }: TUseCreateLocationOptions = {}) => {
  return useMutation<unknown, unknown, { data: ILocation }>({
    onMutate: async (newLocation) => {
      await queryClient.cancelQueries(['locations']);

      const previousLocations = queryClient.getQueryData<ILocation[]>(['locations']);

      queryClient.setQueryData(
        ['locations'],
        [...(previousLocations || []), { ...newLocation.data, id: 'processing' }]
      );

      return { previousLocations };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (_, __, context: any) => {
      if (context?.previousLocations) {
        onError && onError();

        queryClient.setQueryData(['locations'], context.previousLocations);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['locations']);
      onSuccess && onSuccess();
    },
    ...config,
    mutationFn: createLocation,
  } as UseMutationOptions<unknown, unknown, TCreateLocationType>);
};
