import { RolesSchema } from '@marlin/shared/utils-role';
import { getHttpClient } from '@marlin/shared/utils/react-query';
import { z } from 'zod';

import { INVITATION_STATUS, IUserListResponse } from '../user.model';
import { paths } from './api';

export interface IUsersRequestBody {
  search?: string;
  page: number;
  pageSize: number;
  sorting?: {
    direction: 'Ascending' | 'Descending';
    sortBy: string;
  };
}

type TDeleteUserDevice = Promise<string>;

const InvitationStatusResponseSchema = z.union([z.literal('Expired'), z.literal('Pending')]).nullish();
const InvitationStatusSchema = z
  .union([z.literal(INVITATION_STATUS.EXPIRED), z.literal(INVITATION_STATUS.PENDING)])
  .nullish();
type TInvitationStatusResponse = z.infer<typeof InvitationStatusResponseSchema>;
type TInvitationStatus = z.infer<typeof InvitationStatusSchema>;

const InvitationStatusSchemaParser = InvitationStatusResponseSchema.nullish().transform(
  (invitationStatus: TInvitationStatusResponse | null | undefined): TInvitationStatus | undefined => {
    switch (invitationStatus) {
      case 'Expired': {
        return INVITATION_STATUS.EXPIRED;
      }
      case 'Pending': {
        return INVITATION_STATUS.PENDING;
      }
      default: {
        return undefined;
      }
    }
  }
);

const userSchema = z
  .object({
    id: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    email: z.string(),
    phone: z.string().nullish(),
    title: z.string().nullish(),
    roles: RolesSchema.refine((roles) => roles.length > 0),
    invitationStatus: InvitationStatusResponseSchema,
  })
  .transform((data) => ({
    id: data.id,
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    phone: data.phone,
    title: data.title,
    role: data.roles[0],
    invitationStatus: InvitationStatusSchemaParser.parse(data.invitationStatus),
  }));

export type TUserSchema = z.infer<typeof userSchema>;

const usersSchema = z.object({
  pagination: z.object({
    totalItems: z.number(),
    page: z.number(),
    pageSize: z.number(),
  }),
  data: z.array(userSchema).nullish(),
});

type TUsersSchema = z.infer<typeof usersSchema>;

export const getUsers = async (body: IUsersRequestBody): Promise<TUsersSchema> => {
  const res = await getHttpClient().post<unknown, IUserListResponse>(paths.FILTERABLE_USER, body);

  return usersSchema.parse(res);
};

export const deleteUser = (userId: string): Promise<TDeleteUserDevice> => {
  return getHttpClient().delete(paths.USER, { params: { userId } });
};
