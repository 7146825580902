import { AVERAGING_FUNCTION_FILTER, RANGE_FILTER, TChartType } from '@marlin/shared/utils-chart';
import { SelectChangeEvent } from '@mui/material';

import { BaseHeader } from '../chart/base-header.component';
import { useStyles } from './chart-modal-header.styles';

export interface IChartModalHeaderProps {
  onRangeFilterChange: (range: RANGE_FILTER) => void;
  onAveragingFunctionFilterChange: (averagingFunction: AVERAGING_FUNCTION_FILTER) => void;
  onReset: () => void;
  open: boolean;
  range: RANGE_FILTER;
  handleRefresh: () => void;
  averagingFunctionFilter: AVERAGING_FUNCTION_FILTER;
  title: string;
  chartType: TChartType | null;
}

export const ChartModalHeader = ({
  onRangeFilterChange,
  onAveragingFunctionFilterChange,
  onReset,
  open,
  range,
  handleRefresh,
  averagingFunctionFilter,
  title,
  chartType,
}: IChartModalHeaderProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <BaseHeader
        range={range}
        title={title}
        chartType={chartType}
        handleRefresh={handleRefresh}
        onRangeFilterChange={(event: SelectChangeEvent<RANGE_FILTER>) =>
          onRangeFilterChange(event.target.value as RANGE_FILTER)
        }
        hasNoCharts={false}
        onCustomDurationChange={onRangeFilterChange}
        onAveragingFunctionFilterChange={(event: SelectChangeEvent<AVERAGING_FUNCTION_FILTER>) =>
          onAveragingFunctionFilterChange(event.target.value as AVERAGING_FUNCTION_FILTER)
        }
        averagingFunctionFilter={averagingFunctionFilter}
        handleReset={onReset}
        open={open}
      />
    </div>
  );
};
