import { MarlinTheme } from '@marlin/shared/theme';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { TDropdownSetting } from '@marlin/shared/utils/datapoint-mappers';
import { Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../content';
import { TEditedSetting } from '../../../types';
import { EditHeader } from '../shared/edit-header.component';

interface IDropdownList {
  settingItem: TDropdownSetting;
  saveSetting: (name: string, value: string, prevValue: string) => Promise<void>;
  onCancelClick: () => void;
  editedSetting: TEditedSetting | undefined;
  setEditedSetting: (option: TEditedSetting | undefined) => void;
  setIsDirty: (isDirty: boolean) => void;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  editContainer: {
    width: '100%',
    padding: theme.typography.pxToRem(24),
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  editHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  actionButtonsContainer: {
    marginTop: theme.typography.pxToRem(40),
    display: 'flex',
    gap: theme.typography.pxToRem(16),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.typography.pxToRem(24),
    },
  },
  actionButton: {
    flex: 1,
  },
  select: {
    marginTop: theme.typography.pxToRem(28),
  },
}));

export const DropdownList = ({
  settingItem,
  saveSetting,
  onCancelClick,
  setEditedSetting,
  editedSetting,
  setIsDirty,
}: IDropdownList) => {
  const { enqueueSnackbar } = useSnackbar();
  const { label, value: currentValue, name } = settingItem;
  const { classes } = useStyles();
  const [value, setValue] = useState(editedSetting?.updatedValue || currentValue);

  const handleValueChange = (event: SelectChangeEvent<string>) => {
    setValue(event.target.value);

    const hasChanges = event.target.value !== currentValue;

    setIsDirty(hasChanges);
    setEditedSetting(hasChanges ? { updatedValue: event.target.value, currentValue } : undefined);
  };

  const onSaveClick = async () => {
    try {
      await saveSetting(name, value, currentValue);
      onCancelClick();
    } catch (error) {
      enqueueSnackbar(content.ERROR, {
        variant: 'error',
        preventDuplicate: true,
      });
    }
  };

  return (
    <div className={classes.editContainer} data-testid={`dropdown-${name}`}>
      <div className={classes.editHeader}>
        <EditHeader title={label} />
      </div>
      <FormControl className={classes.select} fullWidth>
        <InputLabel data-testid={`dropdown-${name}-label`}>{label}</InputLabel>
        <Select onChange={handleValueChange} value={value} label={label} data-testid={`dropdown-${name}-select`}>
          {settingItem.options.map((option) => (
            <MenuItem value={option.id} key={option.id} data-testid={`dropdown-item-${option.name}`}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className={classes.actionButtonsContainer}>
        <Button
          className={classes.actionButton}
          onClick={onCancelClick}
          variant="outlined"
          data-testid="setting-item-cancel-button"
        >
          {content.CANCEL}
        </Button>
        <Button
          className={classes.actionButton}
          onClick={onSaveClick}
          variant="contained"
          disabled={value === currentValue}
          data-testid="setting-item-set-button"
        >
          {content.SET}
        </Button>
      </div>
    </div>
  );
};
