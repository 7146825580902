import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  formSection: {
    marginBottom: theme.typography.pxToRem(16),
  },
  formSectionTitle: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.typography.pxToRem(16),
  },
  formSectionContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(32),
  },
}));
