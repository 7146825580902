import { TDateString } from '@marlin/shared/utils-common-date';
import { TUnitOfMeasure } from '@marlin/shared/utils-format-reading';

export enum ALERT_STATUS {
  FAILED = 'FAILED',
  SENT = 'SENT',
  SNOOZED = 'SNOOZED',
  IN_PROGRESS = 'IN_PROGRESS',
}

export type TDeviceType = 'TEMPERATURE' | 'PRESSURE' | 'LEAK' | 'PULSE_METER' | 'GATEWAY' | 'EQUIPMENT' | 'UNKNOWN';

export type TSensorType = 'TEMPERATURE' | 'PRESSURE' | 'LEAK' | 'PULSE_METER';

export const isSensorType = (type?: string): type is TSensorType => {
  if (!type) {
    return false;
  }
  return ['TEMPERATURE', 'PRESSURE', 'LEAK', 'PULSE_METER'].includes(type);
};

export enum DEVICE_TYPE {
  TEMPERATURE = 'TEMPERATURE',
  PRESSURE = 'PRESSURE',
  LEAK = 'LEAK',
  PULSE_METER = 'PULSE_METER',
  GATEWAY = 'GATEWAY',
  EQUIPMENT = 'EQUIPMENT',
  UNKNOWN = 'UNKNOWN',
}

export enum ERROR_TYPES {
  LOWER = 'LOWER',
  HIGHER = 'HIGHER',
  UNKNOWN = 'UNKNOWN',
  LEAK_DETECTION = 'LEAK_DETECTION',
  LOST_COMMUNICATION = 'LOST_COMMUNICATION',
  LOW_BATTERY = 'LOW_BATTERY',
  DEVICE_ALERT = 'DEVICE_ALERT',
  DEVICE_ERROR = 'DEVICE_ERROR',
}

export enum ALERT_STATUS_FILTER {
  CURRENT = 'CURRENT',
  SNOOZED = 'SNOOZED',
  RESOLVED = 'RESOLVED',
  ALL = 'ALL',
}

export interface ISystemMapAlert {
  alertCause: ERROR_TYPES;
  thresholdMin?: number | null;
  thresholdMax?: number | null;
  criticality: CRITICALITY | null;
  alertId: string;
  ruleId: string;
  uoM: TUnitOfMeasure | null;
}

export interface IAlert {
  id: string;
  status: ALERT_STATUS_FILTER;
  device: {
    id: string;
    name: string;
    type: DEVICE_TYPE;
  };
  location: {
    id: string;
    name: string;
  } | null;
  equipment: {
    id: string;
    name: string;
  };
  startTime: TDateString;
  resolveTime: TDateString;
  lastReading: TDateString;
  readingValue: number;
  errorType: ERROR_TYPES;
  absoluteDifferenceValue: number | null;
  thresholdMin: number;
  thresholdMax: number;
  criticality: CRITICALITY;
  channels: IChannels;
  formattedReading: string | null;
  uoM: TUnitOfMeasure | null;
}

enum CRITICALITY {
  LOW = 'LOW',
  HIGH = 'HIGH',
}

export enum CHANNEL_TYPE {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  HOT_SOS = 'HOT_SOS',
}

export interface IChannels {
  [CHANNEL_TYPE.EMAIL]?: IEmailChannel;
  [CHANNEL_TYPE.SMS]?: ISmsChannel;
  [CHANNEL_TYPE.HOT_SOS]?: IHotSosChannel;
}

interface IEmailChannel extends IChannel {
  type: CHANNEL_TYPE.EMAIL;
}

interface ISmsChannel extends IChannel {
  type: CHANNEL_TYPE.SMS;
}

interface IHotSosChannel {
  type: CHANNEL_TYPE.HOT_SOS;
}

interface IChannel {
  type: string;
  recipients?: IRecipient[];
}

export interface IRecipient {
  id: string;
  firstName: string;
  lastName: string;
  status: ALERT_STATUS | null;
}

export enum ALERT_CAUSE {
  BELOW = 'Below',
  ABOVE = 'Above',
  LEAK_DETECTION = 'LeakDetect',
  LOST_COMMUNICATION = 'LostCommunication',
  LOW_BATTERY = 'LowBatteryLevel',
  DEVICE_ALERT = 'DeviceAlert',
  DEVICE_ERROR = 'DeviceError',
}

export enum DEVICE_TYPE_RESPONSE {
  TEMPERATURE = 'Temperature',
  PRESSURE = 'Pressure',
  EQUIPMENT = 'Equipment',
  LEAK = 'Leak',
  FLOW_METER = 'FlowMeter',
  PULSE_METER = 'PulseMeter',
  GATEWAY = 'Gateway',
  UNKNOWN = 'Unknown',
}
