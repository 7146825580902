import { MarlinTheme } from '@marlin/shared/theme';
import { Select } from '@marlin/shared/ui-form-common';
import CloseRounded from '@mui/icons-material/CloseRounded';
import { Drawer, IconButton, SelectChangeEvent, Typography } from '@mui/material';
import { useCallback } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';
import { useSelectedSensorsStore, useSetSelectedSensors } from '../../context/selected-sensors.context';
import { useSensorFiltering } from '../../hooks/use-sensor-filtering.hook';
import { ChartType, TChartType, TSelectedDatapoint } from '../../types';
import { getSelectedDatapoints } from '../../utils/get-selected-datapoints';
import { SelectSensorsAutocomplete } from '../chart-form/select-sensors-autocomplete.component';
import { SensorTypeSelect } from '../chart-form/sensor-type-select.component';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  paper: {
    width: '100%',
    maxWidth: theme.typography.pxToRem(524),
    padding: `${theme.typography.pxToRem(16)} ${theme.typography.pxToRem(24)}`,
    overflowX: 'hidden',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.typography.pxToRem(32),
  },
  selectHalfWidth: {
    width: '50%',
  },
  filters: {
    display: 'flex',
    gap: theme.typography.pxToRem(24),
    margin: `${theme.typography.pxToRem(16)} 0`,
  },
}));

interface IEditSensorsDrawerProps {
  isEditSensorDrawerOpen: boolean;
  toggleDrawer: (newIsOpen: boolean) => void;
  chartType: TChartType;
  chartId: string;
}

export const EditSensorsDrawer = ({
  isEditSensorDrawerOpen,
  toggleDrawer,
  chartType,
  chartId,
}: IEditSensorsDrawerProps) => {
  const { classes } = useStyles();
  const setSelectedSensors = useSetSelectedSensors();

  const [selectedSensorsForChart] = useSelectedSensorsStore((store) => store[chartId]);

  const {
    selectedEquipmentId,
    equipmentSelectData,
    locationsSelectData,
    selectedLocationId,
    onEquipmentChange,
    onLocationChange,
    setSensorFiltering,
    selectedChartType,
  } = useSensorFiltering();

  const onChartTypeChange = (event: SelectChangeEvent<TChartType | ''>) => {
    const parsedValue = ChartType.safeParse(event.target.value);
    if (parsedValue.success) {
      setSensorFiltering({ selectedChartType: parsedValue.data ?? event.target.value });
    }
  };

  const handleOnClose = () => {
    setSensorFiltering({ selectedLocationId: '', selectedEquipmentId: '', selectedChartType: '' });
    toggleDrawer(false);
  };

  const handleOnSelect = useCallback(
    (chartId: string, selected: TSelectedDatapoint[]) => {
      const newSelectedSensors = getSelectedDatapoints(selected, selectedSensorsForChart.chartDatapoints);
      setSelectedSensors({
        [chartId]: {
          ...selectedSensorsForChart,
          chartDatapoints: newSelectedSensors,
        },
      });
    },
    [selectedSensorsForChart, setSelectedSensors]
  );

  return (
    <Drawer open={isEditSensorDrawerOpen} onClose={handleOnClose} anchor="right" classes={{ paper: classes.paper }}>
      <div className={classes.header}>
        <Typography variant="h6">{content.EDIT_SENSORS}</Typography>
        <IconButton onClick={handleOnClose} data-testid="close-edit-sensors-drawer-button">
          <CloseRounded />
        </IconButton>
      </div>
      <Select
        data={locationsSelectData}
        label={content.LOCATION}
        prefix="locationId"
        emptyOption=" "
        value={selectedLocationId}
        onChange={onLocationChange}
      />
      <div className={classes.filters}>
        <Select
          className={classes.selectHalfWidth}
          data={equipmentSelectData}
          label={content.EQUIPMENT}
          prefix="equipmentId"
          onChange={onEquipmentChange}
          emptyOption=" "
          value={selectedEquipmentId}
        />
        <SensorTypeSelect
          disabled={!!chartType}
          selectedChartType={selectedChartType}
          onChartTypeChange={onChartTypeChange}
        />
      </div>
      <SelectSensorsAutocomplete
        allChartSelectedSensors={selectedSensorsForChart.chartDatapoints || []}
        handleOnSelect={(value: TSelectedDatapoint[]) => {
          handleOnSelect(chartId, value);
        }}
      />
    </Drawer>
  );
};
