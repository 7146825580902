import { TExtractFnReturnType, useQuery } from '@marlin/shared/utils/react-query';
import z from 'zod';

import { getAvailableBoilers } from '../infrastructure/equipment';
import { queryKey } from './query-key.enum';

type TQueryFnType = typeof getAvailableBoilers;

interface IAvailableBoilersParams {
  equipmentId: string;
  checkAvailableBoilers: boolean;
}

export const useAvailableBoilers = ({ equipmentId, checkAvailableBoilers }: IAvailableBoilersParams) => {
  return useQuery<TExtractFnReturnType<TQueryFnType>>({
    queryKey: queryKey.AVAILABLE_BOILERS(equipmentId),
    queryFn: () => getAvailableBoilers(equipmentId),
    onError: (error) => {
      // eslint-disable-next-line no-console
      if (error instanceof z.ZodError) console.log(error.issues);
    },
    enabled: checkAvailableBoilers && !!equipmentId,
  });
};
