import { TCreateAutomation } from '@marlin/alert/data-access/automated-action';
import { MarlinTheme } from '@marlin/shared/theme';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useController } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { useContent } from '../../context';
import { SectionHeader } from '../shared/section-header.component';
import { alertRepeatingFrequencyOptions, defaultAlertRepeatingFrequencyOption } from './constants';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.typography.pxToRem(12),
  },

  tooltip: {
    whiteSpace: 'pre-line',
  },

  defaultOption: {
    color: theme.palette.text.disabled,
  },
}));

export const RepeatingAlertLimit = () => {
  const { classes } = useStyles();
  const content = useContent();
  const { field } = useController<TCreateAutomation>({ name: 'snoozeIntervalInMinutes' });

  return (
    <div className={classes.container}>
      <SectionHeader
        title={content.REPEATING_ALERT_LIMIT.TITLE}
        prefix="repeating-alert-limit"
        tooltip={<div className={classes.tooltip}>{content.REPEATING_ALERT_LIMIT.TOOLTIP}</div>}
      />

      <FormControl>
        <InputLabel>{content.REPEATING_ALERT_LIMIT.SELECT_LABEL}</InputLabel>
        <Select
          labelId="alert-frequency-select-label"
          label={content.REPEATING_ALERT_LIMIT.SELECT_LABEL}
          id="alert-frequency-select"
          data-testid="alert-frequency-select"
          value={field.value}
          onChange={field.onChange}
          className={field.value === defaultAlertRepeatingFrequencyOption.value ? classes.defaultOption : undefined}
        >
          {alertRepeatingFrequencyOptions.map((option) => (
            <MenuItem key={option.label} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
