import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';

import { content } from './content';

const copy = (text: string) => navigator?.clipboard.writeText(text);

interface IActionCopyProps {
  textToCopy: string;
  contentCopy?: string;
  disabled?: boolean;
}

export const ActionCopy = ({ textToCopy, contentCopy, disabled = false }: IActionCopyProps) => {
  return (
    <MenuItem
      disabled={disabled}
      onClick={(event) => {
        copy(textToCopy);
        event?.stopPropagation();
      }}
    >
      <ListItemIcon>
        <ContentCopyRoundedIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>{contentCopy || content.ACTION_COPY}</ListItemText>
    </MenuItem>
  );
};
