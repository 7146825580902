import { MarlinTheme } from '@marlin/shared/theme';
import { ChartTooltip } from '@marlin/shared/ui/chart';
import { Popper, alpha } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

interface ITooltipData {
  color: string;
  displayedValue: string;
  name: string;
  formattedDate: string;
  date: string;
}

interface ITooltip {
  top: number;
  left: number;
  tooltipData: ITooltipData[] | null;
}

type TTooltipProps =
  | Partial<ITooltip> & {
      direction?: 'right' | 'left';
      showTooltip?: boolean;
      isPeriodTooltip?: boolean;
    };

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  tooltip: {
    borderRadius: theme.typography.pxToRem(4),
    opacity: 0,
    transition: 'opacity 0.3s',
    border: `1px solid ${alpha(theme.palette.text.primary, 0.2)}`,
    overflow: 'hidden',
  },

  tooltipActive: {
    opacity: 1,
  },

  tooltipInactive: {
    opacity: 0,
  },
}));

export const Tooltip = ({ tooltipData, top, left, direction, showTooltip, isPeriodTooltip }: TTooltipProps) => {
  const { classes, cx } = useStyles();

  return (
    <Popper
      open={!!tooltipData}
      anchorEl={{
        nodeType: 1,
        getBoundingClientRect: () => {
          return {
            top: top || 0,
            left: (left || 0) + (direction === 'right' ? 1 : -1) * 16,
            right: 0,
            bottom: 0,
            height: 0,
            width: 0,
            x: 0,
            y: 0,
            toJSON: () => '',
          };
        },
      }}
      placement={direction}
      sx={{ zIndex: 1299 }}
      className={cx(classes.tooltip, {
        [classes.tooltipActive]: showTooltip,
        [classes.tooltipInactive]: !showTooltip,
      })}
    >
      <ChartTooltip
        seriesFromCurrenDataPointIndex={tooltipData || []}
        formattedDate={tooltipData?.[0]?.formattedDate || ''}
        isPeriodTooltip={isPeriodTooltip}
      />
    </Popper>
  );
};
