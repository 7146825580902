import { MarlinTheme } from '@marlin/shared/theme';
import { TDateString, formatDate } from '@marlin/shared/utils-common-date';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import SensorsRoundedIcon from '@mui/icons-material/SensorsRounded';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../../../../content';
import { TBucketedAlert } from '../../../../types';
import { TimestampFormat } from '../../../collapsed-chart/chart-constants';

const maxAlerts = 3;

// ThemeProvider cannot be used here (different react context), theme is passed from parent
interface IStylesProps {
  theme: MarlinTheme;
}

const useStyles = makeStyles<IStylesProps>()((_theme, { theme }) => ({
  tooltipBody: {
    backgroundColor: theme.palette.alternative.dark,
    color: theme.palette.text.alternative,
    textAlign: 'left',
  },
  row: {
    paddingTop: theme.typography.pxToRem(8),
    paddingBottom: theme.typography.pxToRem(8),
    paddingLeft: theme.typography.pxToRem(12),
    paddingRight: theme.typography.pxToRem(16),
    display: 'flex',
  },
  icon: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.typography.pxToRem(8),
    minWidth: theme.typography.pxToRem(24),
  },
  alertIcon: {
    top: theme.typography.pxToRem(4),
    position: 'relative',
    zIndex: 1,
  },
  alertIconBackground: {
    position: 'absolute',
    top: theme.typography.pxToRem(8),
    width: theme.typography.pxToRem(18),
    height: theme.typography.pxToRem(18),
    borderRadius: '50%',
    zIndex: 0,
  },
  alertIconBackgroundError: {
    backgroundColor: theme.palette.error.main,
  },
  alertIconBackgroundWarning: {
    backgroundColor: theme.palette.warning.main,
  },
  details: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  alertRow: {
    borderTop: `${theme.typography.pxToRem(1)} solid ${theme.palette.alternative.light}`,
  },
  title: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightBold,
  },
  value: {
    fontSize: theme.typography.pxToRem(10),
    fontWeight: theme.typography.fontWeightBold,
  },
  timestamp: {
    fontSize: theme.typography.pxToRem(10),
  },
  chipRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  chip: {
    fontSize: theme.typography.pxToRem(10),
    padding: `0 ${theme.typography.pxToRem(6)}`,
    borderRadius: theme.typography.pxToRem(16),
    display: 'flex',
    alignItems: 'center',
  },
  linkButton: {
    color: theme.palette.text.alternative,
  },
}));

interface IMainChartTooltipProps {
  dataSeries: { timestamp: TDateString; value: string } | null;
  alertSeries: { timestamp: TDateString; value: string; alert: TBucketedAlert }[];
  deviceName: string;
  theme: MarlinTheme;
}

export const MainChartTooltip = ({ dataSeries, alertSeries, deviceName, theme }: IMainChartTooltipProps) => {
  const { classes, cx } = useStyles({ theme });

  const sortByCriticality = (a: { alert: TBucketedAlert }, b: { alert: TBucketedAlert }) => {
    if (a.alert.criticality === 'HIGH') {
      return -1;
    }
    if (b.alert.criticality === 'HIGH') {
      return 1;
    }
    return 0;
  };

  return (
    <div className={classes.tooltipBody}>
      <div className={classes.row} data-testid="main-alert-tooltip-readings">
        <div className={classes.icon}>
          <SensorsRoundedIcon />
        </div>
        <div className={classes.details}>
          <div className={classes.title}>{deviceName}</div>
          {dataSeries && (
            <div className={classes.timestamp}>
              {content.CHART_TOOLTIP_READING(
                `${dataSeries.value} ${formatDate(dataSeries.timestamp, TimestampFormat.CUSTOM)}`
              )}
            </div>
          )}
        </div>
      </div>
      {alertSeries
        .sort(sortByCriticality)
        .slice(0, maxAlerts)
        .map((alert) => (
          <div
            key={alert.alert.alertId}
            className={cx(classes.row, classes.alertRow)}
            data-testid={`main-alert-tooltip-alert-${alert.alert.alertId}`}
          >
            <div className={classes.icon}>
              <div
                className={cx(
                  classes.alertIconBackground,
                  alert.alert.criticality === 'HIGH'
                    ? classes.alertIconBackgroundError
                    : classes.alertIconBackgroundWarning
                )}
              />
              <div className={classes.alertIcon}>
                <ErrorOutlineRoundedIcon />
              </div>
            </div>
            <div className={classes.details}>
              <div className={classes.value}>{alert.value}</div>
              <div className={classes.timestamp}>
                {content.CHART_TOOLTIP_TRIGGERED(formatDate(alert.alert.startTime, TimestampFormat.CUSTOM))}
              </div>
            </div>
          </div>
        ))}
      {alertSeries.length > maxAlerts && (
        <div
          className={cx(classes.row, classes.alertRow, classes.chipRow)}
          data-testid={`main-alert-tooltip-alert-more-alerts`}
        >
          <div className={classes.chip}>{content.MORE_ALERTS(alertSeries.length - 3)}</div>
        </div>
      )}
    </div>
  );
};
