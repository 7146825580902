import { TWebhook } from '@marlin/account-data-access-webhook';
import { Button } from '@mui/material';

import { content } from '../content';
import { useStyles } from './delete-modal.styles';

interface IModalFooterProps {
  handleClose: () => void;
  handleConfirm: (webHookId: string) => void;
  webhook: TWebhook;
}

export const ModalFooter = ({ handleConfirm, handleClose, webhook }: IModalFooterProps): JSX.Element | null => {
  const { classes } = useStyles();

  return (
    <div>
      <Button className={classes.button} variant="text" onClick={handleClose}>
        {content.MODAL_BUTTON_CANCEL_TEXT}
      </Button>
      <Button
        className={`${classes.button} ${classes.cancelButton}`}
        variant="contained"
        color="error"
        onClick={() => handleConfirm(webhook.id)}
      >
        {content.MODAL_BUTTON_SUBMIT_TEXT}
      </Button>
    </div>
  );
};
