import { MarlinTheme } from '@marlin/shared/theme';
import { ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  time: {
    display: 'flex',
    fontSize: theme.typography.pxToRem(14),
    width: theme.typography.pxToRem(60),
    height: theme.typography.pxToRem(44),
    padding: `${theme.typography.pxToRem(12)} ${theme.typography.pxToRem(17)}`,
    '&:first-of-type': {
      marginTop: theme.typography.pxToRem(4),
    },
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.primary,
    fontWeight: theme.typography.fontWeightBold,
    borderRadius: theme.typography.pxToRem(2),
  },
}));

interface ITimeItem {
  selected: boolean;
  children: ReactNode;
}
export const TimeItem = (props: ITimeItem) => {
  const { children, selected } = props;
  const { classes, cx } = useStyles();

  return (
    <li {...props} className={cx([classes.time, selected ? classes.selected : ''])}>
      {children}
    </li>
  );
};
