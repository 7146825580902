import { z } from 'zod';
import { ZodTypeDef } from 'zod/lib/types';

const DeviceTypeSchema = z.union([
  z.literal('Pressure'),
  z.literal('Temperature'),
  z.literal('Leak'),
  z.literal('FlowMeter'),
  z.literal('PulseMeter'),
]);

export enum DEVICE_TYPE {
  TEMPERATURE = 'TEMPERATURE',
  PRESSURE = 'PRESSURE',
  LEAK = 'LEAK',
  PULSE_METER = 'PULSE_METER',
  UNKNOWN = 'UNKNOWN',
}

const baseManufacturerInfoResponseSchema = z.object({
  manufacturer: z.string(),
  model: z.string(),
  manufacturerId: z.string(),
});

const baseManufacturerInfoOutputResponseSchema = baseManufacturerInfoResponseSchema.extend({
  deviceType: DeviceTypeSchema.transform((val) => {
    switch (val) {
      case 'Pressure':
        return DEVICE_TYPE.PRESSURE;
      case 'Temperature':
        return DEVICE_TYPE.TEMPERATURE;
      case 'Leak':
        return DEVICE_TYPE.LEAK;
      case 'FlowMeter':
      case 'PulseMeter':
        return DEVICE_TYPE.PULSE_METER;
      default:
        return DEVICE_TYPE.UNKNOWN;
    }
  }),
});

const baseManufacturerInfoInputResponseSchema = baseManufacturerInfoResponseSchema.extend({
  deviceType: DeviceTypeSchema,
});

type TBaseManufacturerInfoOutputSchema = z.infer<typeof baseManufacturerInfoOutputResponseSchema> & {
  deviceChildren?: TBaseManufacturerInfoOutputSchema[] | null | undefined;
};

type TBaseManufacturerInfoInputSchema = z.infer<typeof baseManufacturerInfoInputResponseSchema> & {
  deviceChildren?: TBaseManufacturerInfoInputSchema[] | null | undefined;
};

export const manufacturerInfoResponseSchema: z.ZodType<
  TBaseManufacturerInfoOutputSchema,
  ZodTypeDef,
  TBaseManufacturerInfoInputSchema
> = baseManufacturerInfoOutputResponseSchema.extend({
  deviceChildren: z.lazy(() => z.array(manufacturerInfoResponseSchema)).nullish(),
});

export type TManufacturerType = z.infer<typeof manufacturerInfoResponseSchema>;
