import { useStyles } from './list-empty.component.styles';

const HomeListEmpty = ({ message }: { message: string }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.emptyState} data-testid="alerts-page-empty">
      {message}
    </div>
  );
};

export { HomeListEmpty };
