import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    border: `${theme.typography.pxToRem(1)} solid`,
    borderColor: theme.palette.divider,
    borderRadius: `${theme.typography.pxToRem(4)}`,
    backgroundColor: theme.palette.background.primary,
  },

  locationSection: {
    display: 'flex',
    alignContent: 'center',
    flexDirection: 'row',
    columnGap: theme.typography.pxToRem(8),
  },

  text: {
    fontSize: theme.typography.pxToRem(16),
  },

  deviceIcon: {
    '& > svg': {
      color: theme.palette.action.active,
    },
  },

  warningIcon: {
    '& > svg': {
      color: theme.palette.error.contrastText,
    },
  },

  lowAlertIcon: {
    '& > svg': {
      color: theme.palette.warning.contrastText,
    },
  },

  divider: {
    margin: `0 ${theme.typography.pxToRem(16)}`,
  },

  icon: {
    padding: theme.typography.pxToRem(16),
    marginTop: theme.typography.pxToRem(-16),
    marginRight: theme.typography.pxToRem(-16),
    width: theme.typography.pxToRem(56),
    height: theme.typography.pxToRem(56),
  },
}));
