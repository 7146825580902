import { useCurrentUser } from '@marlin/account-data-access-organization';
import {
  CalculatedValues,
  TCalculatedValues,
  TCalculatedValuesList,
  useCalculationValues,
} from '@marlin/asset/data-access/equipment';
import { IRangeFilter, useDuration } from '@marlin/shared/utils-chart';
import {
  DeviceUserMeasuresPreferences,
  TDeviceUserMeasuresPreferences,
  parseDisplayedValue,
} from '@marlin/shared/utils-format-reading';
import { TControlState } from '@marlin/shared/utils/datapoint-mappers';

import { content } from '../content';

export const useCalculatedValues = (rangeFilter: IRangeFilter, isCalculateValues: boolean, equipmentId?: string) => {
  const { data: { settings = {} } = {} } = useCurrentUser();
  const userUnitsSettings = DeviceUserMeasuresPreferences.parse(settings);

  const { toDate: to, fromDate: from } = useDuration({ rangeFilter, fromDate: null, toDate: null });

  const { data, isLoading, isFetching, refetch } = useCalculationValues({
    equipmentId: equipmentId ?? '',
    calculatedValues: [CalculatedValues.enum.IntellistationEnergyUsed, CalculatedValues.enum.IntellistationLoadFlow],
    from,
    to,
    isCalculateValues,
  });

  const energyUsed = getCalculatedValue(data, CalculatedValues.enum.IntellistationEnergyUsed, userUnitsSettings);
  const loadFlow = getCalculatedValue(data, CalculatedValues.enum.IntellistationLoadFlow, userUnitsSettings);

  return {
    energyUsed,
    loadFlow,
    isLoading: isLoading || isFetching,
    refetch,
  };
};

const getCalculatedValue = (
  data: TCalculatedValuesList | undefined,
  name: TCalculatedValues,
  userUnitsSettings: TDeviceUserMeasuresPreferences
): { controlState: TControlState; displayedValue: string } => {
  const calculatedValue = data?.find((item) => item?.calculation === name);

  if (!calculatedValue) {
    return {
      displayedValue: content.DATAPOINT_ERROR_LABEL,
      controlState: 'error',
    };
  }

  const value = calculatedValue.value;
  const uom = calculatedValue.uom ?? null;

  return {
    displayedValue: parseDisplayedValue(value, uom, userUnitsSettings),
    controlState: value ? 'active' : 'inactive',
  };
};
