import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { useCreateAttachment } from '@marlin/system-map/data-access/system-map';
import { ASSET_TYPE, IFlowLink } from '@marlin/system-map/ui/flow-link-upsert-form';
import { AxiosError } from 'axios';
import { useCallback } from 'react';

import { IFlowLinkError } from './constants';
import { content } from './content';
import { handleFlowLinkError } from './helpers';

export const debounceTimeInMs = 300;

interface IUseAttachmentForm {
  onSubmit: (link: IFlowLink) => Promise<void>;
}

export const useAttachmentForm = (): IUseAttachmentForm => {
  const createAttachmentMutation = useCreateAttachment();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = useCallback(
    async (link: IFlowLink): Promise<void> => {
      if (!link.inlet || !link.outlet) {
        return;
      }

      return createAttachmentMutation
        .mutateAsync({
          inlet: {
            id: link.inlet.assetId || '',
            type: link.inlet.assetType as ASSET_TYPE,
            tags: link.inlet.tags,
          },
          outlet: {
            id: link.outlet.assetId || '',
            type: link.outlet.assetType as ASSET_TYPE,
          },
        })
        .then(() => {
          enqueueSnackbar(content.ADD_ATTACHMENT_SUCCESS, {
            variant: 'success',
            preventDuplicate: true,
          });
        })
        .catch((error: AxiosError<IFlowLinkError>) => {
          if (typeof error?.response?.data.errorCode === 'number') {
            handleFlowLinkError({ error, enqueueSnackbar });
            return;
          }
          // note: in case BE returns response without error code, e.g. 500
          enqueueSnackbar(content.ADD_FLOW_LINK_ERROR, {
            variant: 'error',
            preventDuplicate: true,
          });

          throw error;
        });
    },
    [createAttachmentMutation, enqueueSnackbar]
  );

  return {
    onSubmit,
  };
};
