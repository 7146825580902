import { HubConnectionState } from '@microsoft/signalr';
import { Observable, ReplaySubject, Subscription, catchError } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';

import { ConfigService } from './config.service';
import { IAuthService, IConfig } from './model';
import { IRealtimeCommunicationConnection, RealtimeCommunicationConnection } from './realtime-communication-connection';

let instance: RealtimeConnectionService;
let authServiceInstance: IAuthService;
const configService = new ConfigService();

export const init = (config: Partial<IConfig>, authService: IAuthService) => {
  configService.set(config);
  authServiceInstance = authService;
};

export class RealtimeConnectionService {
  private readonly connection: IRealtimeCommunicationConnection;
  private connection$: ReplaySubject<IRealtimeCommunicationConnection> =
    new ReplaySubject<IRealtimeCommunicationConnection>(1);
  private logoutSubscription: Subscription | undefined;

  public static getInstance = () => {
    if (!instance) {
      instance = new RealtimeConnectionService(configService, authServiceInstance.getAccessTokenPromise);
    }

    return instance;
  };

  public constructor(private readonly configService: ConfigService, accessTokenFactory: () => Promise<string>) {
    this.connection = new RealtimeCommunicationConnection(
      this.configService.get().url,
      accessTokenFactory,
      this.configService.get().logLevel
    );

    this.connection$.next(this.connection);
    this.logoutSubscription = authServiceInstance.subscribeToLogout(this.stopConnection);
  }

  public getConnection = (): Observable<IRealtimeCommunicationConnection> => {
    return this.connection.start().pipe(
      switchMap(() => this.connection$),
      shareReplay(1),
      catchError(() => {
        return this.connection$;
      })
    );
  };

  public stopConnection = () => {
    return this.connection.stop();
  };

  public reconnect = async () => {
    if (this.connection.state() === HubConnectionState.Disconnected) {
      this.getConnection();
      return;
    }

    this.connection.onReconnected(() => {
      this.connection$.next(this.connection);
    });
  };

  public destroy = async () => {
    await this.stopConnection();

    this.connection$.complete();
  };
}
