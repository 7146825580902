export const getJsonValueSequences = (parsedValue: string) => {
  let sequenceStartIndex = 0;

  const sequences: Array<{ value: string; isError: boolean }> = [];

  for (let index = 0; index < parsedValue.length; index++) {
    const isErrorResult = isNonEmptyErrorObjectOrArray(parsedValue, index);

    if (isErrorResult) {
      let currentChar = '';
      let nextIndex = index;

      const isEndingChar = (isObj: boolean, char: string) => {
        return isObj ? char === '}' : char === ']';
      };

      while (!isEndingChar(isErrorResult === 'errorObject', currentChar)) {
        currentChar = parsedValue[nextIndex];
        nextIndex++;
      }

      const lastCharBeforeError = parsedValue.slice(sequenceStartIndex, index - 1);
      sequences.push({ value: lastCharBeforeError, isError: false });

      const isClosingErrorArray = currentChar === ']' && parsedValue[nextIndex] === ',';

      if (isClosingErrorArray) {
        const lastCharOfErrorArray = parsedValue.slice(index - 1, nextIndex + 1);
        sequences.push({ value: lastCharOfErrorArray, isError: true });
        sequenceStartIndex = nextIndex + 1;
      } else {
        const lastCharOfErrorObject = parsedValue.slice(index - 1, nextIndex);
        sequences.push({ value: lastCharOfErrorObject, isError: true });
        sequenceStartIndex = nextIndex;
      }
    }

    if (index === parsedValue.length - 1) {
      const firstCharAfterError = parsedValue.slice(sequenceStartIndex, index + 1);
      sequences.push({ value: firstCharAfterError, isError: false });
    }
  }

  return sequences;
};

const isNonEmptyErrorObjectOrArray = (value: string, startIndex: number) => {
  const startingKeyword = ['"errors":', '"error":'].find(
    (keyword) => value.slice(startIndex, startIndex + keyword.length) === keyword
  );
  if (!startingKeyword) {
    return false;
  }

  const isErrorObject = value[startIndex + startingKeyword.length + 1] === '{';
  const isErrorArray =
    value[startIndex + startingKeyword.length + 1] === '[' && value[startIndex + startingKeyword.length + 2] !== ']';

  return isErrorObject ? 'errorObject' : isErrorArray ? 'errorArray' : undefined;
};
