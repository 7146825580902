import { ElkNode, LayoutOptions } from 'elkjs/lib/elk.bundled';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';

import { TSystemMapNode } from '../../cytoscape-react-flow/schema/types';

type TSize = number;

const getNodeSize = (node: TSystemMapNode, isParent: boolean): { width?: TSize; height?: TSize } | undefined => {
  if (node?.measured?.width && node?.measured?.height) {
    return {
      width: node?.measured?.width as TSize,
      height: node?.measured?.height as TSize,
    };
  }
  if (isParent) {
    return undefined;
  }
  if (node.data.assetType === 'LOCATION' || node.data.assetType === 'EQUIPMENT') {
    return {
      width: 304,
      height: 96,
    };
  }
  return undefined;
};

export const arrayToTreeByKey = (
  array: TSystemMapNode[],
  key: string,
  nodeLevelLayoutOptions?: LayoutOptions
): ElkNode[] => {
  const leafsByParentId: Record<string, TSystemMapNode[]> = groupBy(array, (node) => get(node, key) || 'root');

  const insertChildrenIntoTree = (node: ElkNode): ElkNode => {
    const children = leafsByParentId[node.id] as ElkNode[];

    if (children) {
      const conntectedChildren = children.map(insertChildrenIntoTree);

      return {
        ...node,
        ...getNodeSize(node as TSystemMapNode, children.length > 0),
        layoutOptions: nodeLevelLayoutOptions,
        children: conntectedChildren,
      };
    }

    return {
      ...node,
      ...getNodeSize(node as TSystemMapNode, false),
    };
  };

  return leafsByParentId['root'].map((node) => insertChildrenIntoTree(node));
};
