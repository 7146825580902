import { MarlinTheme } from '@marlin/shared/theme';
import { MODAL_ACTION_TYPE } from '@marlin/shared/utils-common-modal-context';
import { useIdFromPathname } from '@marlin/shared/utils/url-params';
import { Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { useStopSanitizationModalDispatch } from '../../../shared/hooks/intellistation/use-stop-sanitization.hook';
import { content } from '../../content';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  modalFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    columnGap: theme.typography.pxToRem(8),
  },
  modalBody: {
    display: 'block',
  },
}));

export const useStopSanitization = ({ isSanitization }: { isSanitization: boolean }) => {
  const equipmentId = useIdFromPathname();
  const { classes } = useStyles();
  const { isStoppingSanitization, modalDispatch, submitStopSanitization, closeModal } =
    useStopSanitizationModalDispatch({
      isSanitization,
      equipmentId,
    });

  const handleStopSanitization = () => {
    modalDispatch({
      type: MODAL_ACTION_TYPE.SHOW,
      payload: {
        title: <>{content.STOP_SANITIZATION}</>,
        body: (
          <>
            <div className={classes.modalBody}>{content.STOP_SANITIZATION_MODAL_BODY_FIRST_LINE}</div>
            <div className={classes.modalBody}>{content.STOP_SANITIZATION_MODAL_BODY_SECOND_LINE}</div>
          </>
        ),
        footer: (
          <div className={classes.modalFooter}>
            <Button variant="text" data-testid="sanitization-modal-cancel-button" onClick={closeModal}>
              {content.CANCEL}
            </Button>
            <Button
              variant="contained"
              color="error"
              data-testid="sanitization-modal-submit-button"
              onClick={submitStopSanitization}
            >
              {content.STOP}
            </Button>
          </div>
        ),
      },
    });
  };

  return { isStoppingSanitization, handleStopSanitization };
};
