import { MarlinTheme } from '@marlin/shared/theme';
import { FormField, Input } from '@marlin/shared/ui-form-common';
import { ArrayPath, Control } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';
import { TWebhookForm } from './schema';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    display: 'flex',
    gap: '24px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    width: '100%',
  },
}));

interface IInvitationControlField {
  fieldName: ArrayPath<Pick<TWebhookForm, 'apiKeys'>>;
  control: Control<TWebhookForm>;
  index: number;
}

export const ApiKeyField = ({ control, index, fieldName }: IInvitationControlField) => {
  const { classes } = useStyles();

  return (
    <div className={classes.wrapper}>
      <FormField control={control} fieldName={`${fieldName}.${index}.name`}>
        {(props) => <Input {...props} label={content.API_KEY_LABELS.HEADER_KEY(index + 1)} required />}
      </FormField>
      <FormField control={control} fieldName={`${fieldName}.${index}.value`}>
        {(props) => <Input {...props} label={content.API_KEY_LABELS.HEADER_VALUE(index + 1)} required />}
      </FormField>
    </div>
  );
};
