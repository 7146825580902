import { useLocation, useNavigate } from 'react-router-dom';

interface IUseStore {
  goToWithPrevious: (url: string) => void;
}

export const useRouter = (): IUseStore => {
  const navigate = useNavigate();
  const location = useLocation();

  return {
    goToWithPrevious: (url) => {
      navigate(url, { state: { prevLocation: location.pathname + location.search } });
    },
  };
};
