export const analyticsSelectedSensorsUserDataAreaKey = 'analytics-selected-sensors';
export const analyticsAveragingFunctionFilterUserDataAreaKey = 'analytics-averaging-function-filter';

export const flowVolumeDatapointNames = ['OutletFlowVolume', 'RecircFlowVolume', 'FlowVolume'];
export const flowVolumeToFlowRateDatapointNameMap = new Map<(typeof flowVolumeDatapointNames)[number], string>([
  ['OutletFlowVolume', 'OutletFlow'],
  ['RecircFlowVolume', 'RecircFlow'],
  ['FlowVolume', 'FlowRate'],
]);
export const flowRateToFlowVolumeDatapointNameMap = new Map(
  [...flowVolumeToFlowRateDatapointNameMap].map(([flowVolumeDatapointName, flowRateDatapointName]) => [
    flowRateDatapointName,
    flowVolumeDatapointName,
  ])
);
