export class OrganizationStorageService {
  private key = 'organizationId';

  public set = (organizationId: string): void => {
    sessionStorage.setItem(this.key, organizationId);
    localStorage.setItem(this.key, organizationId);
    // required to catch this event in test utils
    // because storage event is only fired in other tabs
    // see: https://developer.mozilla.org/en-US/docs/Web/API/Window/storage_event
    window.dispatchEvent(new Event('OrgIdChanged'));
  };

  public get = (storageType: 'sessionStorage' | 'localStorage' | 'mobileStorage'): string | null => {
    switch (storageType) {
      case 'sessionStorage': {
        return sessionStorage.getItem(this.key);
      }
      case 'localStorage': {
        return localStorage.getItem(this.key);
      }
      default: {
        return null;
      }
    }
  };
  public getKey = (): string => this.key;
}

export const organizationStorageService = new OrganizationStorageService();
