import { CommonChip } from '../common-chip/chip';

interface IFilterChipProps {
  id?: string;
  testId: string;
  label: string;
  onDelete?: () => void;
}

export function FilterChip({ testId, label, onDelete }: IFilterChipProps) {
  return <CommonChip active={true} testId={testId} label={label} onDelete={onDelete} />;
}
