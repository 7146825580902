import { MarlinTheme } from '@marlin/shared/theme';
import { Dot } from '@marlin/shared/ui-criticality';
import { CRITICALITY } from '@marlin/shared/ui-criticality';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import { PERMISSIONS, Restricted } from '@marlin/shared/utils-permission';
import { LeaveRouteGuard } from '@marlin/shared/utils-router';
import { getLogger } from '@marlin/shared/utils/logger';
import {
  CytoscapeElementsJsonSchema,
  TGraphLink,
  TGraphSaveAsset,
  TUpdateSystemMapLinkData,
  useUpdateGraph,
  useUpdateLinkData,
} from '@marlin/system-map/data-access/system-map';
import { TGraphEdge, reactFlowAdapter } from '@marlin/system-map/utils/system-map-adapters';
import PanToolRounded from '@mui/icons-material/PanToolRounded';
import { Button, FormHelperText } from '@mui/material';
import { Icon } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Edge, ReactFlowJsonObject, useReactFlow, useStore } from '@xyflow/react';
import { useCallback, useContext, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';
import { SaveModalBody } from '../../save-modal/save-modal.body';
import { SaveModalFooter } from '../../save-modal/save-modal.footer';
import { SaveModalTitle } from '../../save-modal/save-modal.title';
import { TSystemMapInternalNode, TSystemMapNode } from '../../types';
import { ConfirmationModal } from './confirmation-modal.component';
import { useViewModeState$ } from './use-observable-view-mode-state.hook';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 0,
  },
  buttonState: {
    display: 'flex',
    marginRight: theme.typography.pxToRem(16),
  },
  buttonSave: {
    marginRight: theme.typography.pxToRem(32),
  },
  stateIcon: {
    marginLeft: theme.typography.pxToRem(8),
  },
  infoText: {
    marginTop: 0,
  },
  switcher: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.contrastText,
    padding: theme.typography.pxToRem(8) + ' ' + theme.typography.pxToRem(16),

    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  switcherIcon: {
    fontSize: theme.typography.pxToRem(20),
    marginRight: theme.typography.pxToRem(8),
  },
  switcherText: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(24),
    fontWeight: 500,
  },
}));

interface ISaveButtonProps {
  touched: boolean;
  isEditMode: boolean;
  setTouched: (touched: boolean) => void;
  onModeChange: (newAlignment: string) => void;
}

export const SaveButton = ({ touched, isEditMode, setTouched, onModeChange }: ISaveButtonProps) => {
  const { classes } = useStyles();
  const { toObject, setNodes, setEdges, setViewport } = useReactFlow<TSystemMapNode>();
  const { modalDispatch } = useContext(ModalContext);
  const { updateState, restoreState, clearState } = useViewModeState$();
  const [isSuccess, setIsSuccess] = useState(false);
  const graphUpdateMutation = useUpdateGraph();
  const linkDataUpdateMutation = useUpdateLinkData();
  const { enqueueSnackbar } = useSnackbar();
  const internalNodes = useStore<TSystemMapInternalNode[]>(
    (s) => Array.from(s.nodeLookup.values()) as TSystemMapInternalNode[]
  );

  const onSaveButtonClick = useCallback(() => {
    const handleSuccess = () => {
      setIsSuccess(true);
      setTimeout(() => {
        setIsSuccess(false);
      }, 10000);
    };

    const onSave = (newAssets: TGraphSaveAsset[], newLinks: TGraphLink[]) => {
      return graphUpdateMutation
        .mutateAsync({
          nodes: newAssets,
          links: newLinks,
        })
        .then(() => {
          handleSuccess();
          getLogger()?.track('SystemMapSaveChanges', { ...newAssets, ...newLinks });
        })
        .catch(() =>
          enqueueSnackbar(content.SAVE_GRAPH_ERROR, {
            variant: 'error',
            preventDuplicate: true,
          })
        );
    };

    const onPointsSave = (linkId: string, tags: TUpdateSystemMapLinkData) => {
      return linkDataUpdateMutation.mutateAsync({ linkId, data: tags });
    };

    setTouched(false);
    clearState();

    const { edges } = toObject();

    const parsedJsonResult = CytoscapeElementsJsonSchema.safeParse({
      elements: {
        nodes: reactFlowAdapter.nodesToCyto(internalNodes),
        edges: reactFlowAdapter.edgesToCyto(edges as Edge<TGraphEdge['data']>[]),
      },
    });

    if (!parsedJsonResult.success) {
      return;
    }
    const { nodes: nodesToUpdate, edges: edgesToUpdate } = parsedJsonResult.data.elements;
    onSave(nodesToUpdate, edgesToUpdate);
    const edgesWithPoints = edgesToUpdate.filter((edge) => !!edge.data.points);

    // NOTE: this is temporary solution to save points for edges
    const mutationUpdates: Promise<unknown>[] = [];
    edgesWithPoints.forEach((edge) => {
      mutationUpdates.push(
        onPointsSave(edge.data.id, {
          linkOptions: {
            tags: edge.data.tags,
          },
        })
      );
    });
    Promise.all(mutationUpdates)
      .then(() => {
        handleSuccess();
      })
      .catch(() =>
        enqueueSnackbar(content.SAVE_GRAPH_ERROR, {
          variant: 'error',
          preventDuplicate: true,
        })
      );
  }, [clearState, enqueueSnackbar, graphUpdateMutation, internalNodes, linkDataUpdateMutation, setTouched, toObject]);

  const handleCancelEditMode = useCallback(() => {
    modalDispatch({
      type: MODAL_ACTION_TYPE.DISMISS,
    });
    restoreState(({ nodes, edges, viewport }: ReactFlowJsonObject) => {
      setNodes(nodes as TSystemMapNode[]);
      setEdges(edges);
      setViewport(viewport);
    });
    onModeChange(content.VIEW);
    setTouched(false);
  }, [modalDispatch, onModeChange, restoreState, setEdges, setNodes, setTouched, setViewport]);

  const handleConfirmOrgUpdate = useCallback(async () => {
    onSaveButtonClick();
    modalDispatch({
      type: MODAL_ACTION_TYPE.DISMISS,
    });
    onModeChange(content.VIEW);
  }, [modalDispatch, onModeChange, onSaveButtonClick]);

  const onModeChangeClick = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    if (newAlignment === content.VIEW && touched) {
      modalDispatch({
        type: MODAL_ACTION_TYPE.SHOW,
        payload: {
          title: <SaveModalTitle />,
          body: <SaveModalBody />,
          footer: <SaveModalFooter onCancel={handleCancelEditMode} onConfirm={handleConfirmOrgUpdate} />,
        },
      });
    } else {
      const json = toObject();
      updateState(json);
      onModeChange(newAlignment);
    }
  };

  return (
    <>
      <div className={classes.buttonGroup}>
        {touched && (
          <span className={classes.buttonState}>
            <Dot variant={CRITICALITY.HIGH}>
              <FormHelperText error className={classes.infoText}>
                {content.UNSAVED_CHANGES}
              </FormHelperText>
            </Dot>
          </span>
        )}
        {isSuccess && !touched && (
          <span className={classes.buttonState}>
            <Dot variant={CRITICALITY.NO}>
              <FormHelperText className={classes.infoText}>{content.SAVED_CHANGES}</FormHelperText>
            </Dot>
          </span>
        )}

        <Restricted to={PERMISSIONS.SAVE_SYSTEM_MAP}>
          {isEditMode && touched ? (
            <Button
              disabled={!touched}
              onClick={onSaveButtonClick}
              data-testid="flow-map-diagram-save-button"
              variant="outlined"
              size="small"
              className={classes.buttonSave}
            >
              {content.BUTTON_SAVE}
            </Button>
          ) : (
            <></>
          )}
        </Restricted>

        <Restricted to={PERMISSIONS.SAVE_SYSTEM_MAP}>
          <ToggleButtonGroup
            color="primary"
            value={isEditMode ? content.EDIT : content.VIEW}
            exclusive
            onChange={onModeChangeClick}
            aria-label="Platform"
            size="small"
          >
            <ToggleButton className={classes.switcher} value={content.EDIT}>
              <Icon
                baseClassName="material-symbols-outlined"
                data-testid="organization-switcher-icon"
                className={classes.switcherIcon}
              >
                arrow_selector_tool
              </Icon>
              <span className={classes.switcherText}>{content.EDIT}</span>
            </ToggleButton>
            <ToggleButton className={classes.switcher} value={content.VIEW}>
              <PanToolRounded className={classes.switcherIcon} />
              <span className={classes.switcherText}>{content.VIEW}</span>
            </ToggleButton>
          </ToggleButtonGroup>
        </Restricted>
      </div>
      <LeaveRouteGuard when={touched}>
        {({ onCancel, onConfirm }) => <ConfirmationModal onConfirm={onConfirm} onCancel={onCancel} />}
      </LeaveRouteGuard>
    </>
  );
};
