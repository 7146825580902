import { queryClient } from '@marlin/shared/utils/react-query';

import { queryKey } from '../query-key.enum';
import { IUserList, IUserListFilters } from '../user.model';

export const updatePreviousUserListCache = (previousData: IUserList | undefined, params?: IUserListFilters) => {
  if (!previousData) {
    return;
  }

  queryClient.setQueryData<IUserList | undefined>(queryKey.USER_LIST(params), (data?: IUserList) => {
    if (!data) {
      return data;
    }

    return previousData;
  });
};
