import { useCallback, useState } from 'react';

type TModalOpen = boolean;
type TModalOpenFn = () => void;
type TModalCloseFn = () => void;

type TUseModal = [TModalOpen, TModalOpenFn, TModalCloseFn];

// TODO: manage modal showing/hiding here too
export const useModal = (): TUseModal => {
  const [modal, setModal] = useState<TModalOpen>(false);
  const openModal = useCallback(() => setModal(true), [setModal]);
  const closeModal = useCallback(() => setModal(false), [setModal]);

  return [modal, openModal, closeModal];
};
