import EggRounded from '@mui/icons-material/EggRounded';
import PanoramaPhotosphereIcon from '@mui/icons-material/PanoramaPhotosphere';
import SensorsIcon from '@mui/icons-material/Sensors';
import SpeedIcon from '@mui/icons-material/Speed';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import ToggleOnRounded from '@mui/icons-material/ToggleOnRounded';

export const chartTypesIcons = {
  temperature: ThermostatIcon,
  pressure: SpeedIcon,
  onOff: ToggleOnRounded,
  flow: PanoramaPhotosphereIcon,
  leak: EggRounded,
  custom: SensorsIcon,
};
