import { CommonChip } from '@marlin/shared/ui/chips';

import { content } from '../../content';

interface IBatteryStatusChipProps {
  onDelete: () => void;
}

const key = 'lostCommunication';

export const LostCommunicationChip = ({ onDelete }: IBatteryStatusChipProps) => {
  return (
    <CommonChip
      testId={`filterChip-${key}`}
      key={key}
      active={true}
      label={content.LOST_COMMUNICATION}
      onDelete={onDelete}
    />
  );
};
