import { TLocationSchema } from '@marlin/asset/data-access/location';
import { CardHeader, DetailsItem, LinkedItem } from '@marlin/asset/shared/ui/hub-card';
import { MarlinTheme } from '@marlin/shared/theme';
import { Paper } from '@marlin/shared/ui-page';
import { useRouter } from '@marlin/shared/utils-router';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';
import { useNavigation } from '../shared/use-navigation.hook';

interface ILocationCardProps {
  location: TLocationSchema;
  children: JSX.Element;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  card: {
    borderRadius: theme.typography.pxToRem(4),
    margin: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(16)}`,
    '& svg': {
      fontSize: theme.typography.body1.fontSize,
      marginRight: theme.typography.pxToRem(8),
    },
  },
}));

export const LocationCard = ({ location, children }: ILocationCardProps) => {
  const { classes } = useStyles();
  const { getLocationDetailsPageLink } = useNavigation();
  const { goTo } = useRouter();

  return (
    <Paper className={classes.card} data-testid="card">
      <CardHeader
        asset={location}
        goTo={(id: string) => goTo(getLocationDetailsPageLink(id))}
        icon={<LocationOnRoundedIcon />}
        data-testid="location-card-header"
      >
        {children}
      </CardHeader>
      <DetailsItem
        label={content.PARENT_LOCATION_HEADER_NAME}
        data-testid="location-card-parent-locationś"
        value={
          <LinkedItem
            id={location.parentLocationId}
            name={location.parentLocationName}
            goTo={getLocationDetailsPageLink}
          />
        }
      />
      <Grid container>
        <Grid item xs={6}>
          <DetailsItem
            label={content.EQUIPMENT_HEADER_NAME}
            value={location.equipmentCount}
            data-testid="location-card-equipment"
          />
        </Grid>
        <Grid item xs={6}>
          <DetailsItem
            label={content.SENSORS_HEADER_NAME}
            value={location.deviceCount}
            data-testid="location-card-devices"
          />
        </Grid>
      </Grid>
    </Paper>
  );
};
