import { TExtractFnReturnType, getHttpClient } from '@marlin/shared/utils/react-query';
import { useQuery } from '@marlin/shared/utils/react-query';

import { DeviceBrandsCompatibleWithWatts, TDeviceBrandsCompatibleWithWatts } from '../gateway.model.schema';
import { paths } from '../infrastructure/api';
import { queryKey } from '../query-key.enum';

export const getGatewayBrands = async (): Promise<TDeviceBrandsCompatibleWithWatts> => {
  const res = await getHttpClient().get<unknown, void>(paths.GATEWAY_MODELS);

  return DeviceBrandsCompatibleWithWatts.parse(res);
};

type TQueryFnType = typeof getGatewayBrands;

export const useGatewayBrandsAndModels = () => {
  return useQuery<TExtractFnReturnType<TQueryFnType>>({
    queryKey: queryKey.GATEWAY_BRANDS,
    queryFn: () => getGatewayBrands(),
  });
};
