import { MarlinTheme } from '@marlin/shared/theme';
import { Button, Grid } from '@mui/material';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';
import { IFlowLink } from './types';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    marginTop: theme.typography.pxToRem(8),
    justifyContent: 'flex-end',
  },

  button: {
    width: theme.typography.pxToRem(238),
  },

  editModeContainer: {
    marginTop: theme.typography.pxToRem(8),
    justifyContent: 'center',
  },

  editModeButtons: {
    width: theme.typography.pxToRem(119),
  },
}));

interface IActionButtonProps {
  onSubmit: (data: IFlowLink) => void;
  onCancel: () => void;
  editMode?: boolean;
}

export const Buttons = ({ onSubmit, onCancel, editMode = false }: IActionButtonProps) => {
  const { classes } = useStyles();
  const { handleSubmit, formState } = useFormContext();

  const isDisabled = useMemo(() => !formState.isDirty || !formState.isValid, [formState.isDirty, formState.isValid]);

  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item>
        <Button
          className={classes.button}
          variant="outlined"
          data-testid="upsert-user-profile-cancel-button"
          onClick={onCancel}
        >
          {content.BTN_CANCEL}
        </Button>
      </Grid>
      <Grid item>
        <Button
          className={classes.button}
          data-testid="upsert-user-profile-confirm-button"
          variant="contained"
          disabled={isDisabled}
          onClick={handleSubmit(onSubmit)}
        >
          {editMode ? content.BTN_EDIT : content.BTN_ADD}
        </Button>
      </Grid>
    </Grid>
  );
};
