import { Typography } from '@mui/material';

import { content } from './content';
import { useStyles } from './settings.styles';

export const SettingsModalTitle = () => {
  const { classes } = useStyles();

  return <Typography className={classes.title}>{content.SUSTAINABILITY_DASHBOARD}</Typography>;
};
