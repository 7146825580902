import { MarlinTheme } from '@marlin/shared/theme';
import { Box, Button, LinearProgress, LinearProgressProps, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.typography.pxToRem(5),
  },
  progressBar: {
    width: '100%',
    marginRight: theme.typography.pxToRem(1),
  },
  button: {
    margin: theme.typography.pxToRem(5),
  },
  result: {
    margin: theme.typography.pxToRem(5),
    marginLeft: theme.typography.pxToRem(10),
    minWidth: theme.typography.pxToRem(50),
  },
}));

export const LinearProgressBar = (
  props: LinearProgressProps & { value: number; disableCancel: boolean; onRefetch: () => void; onCancel: () => void }
) => {
  const { classes } = useStyles();
  const { value, disableCancel, onRefetch, onCancel } = props;

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.progressBar}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box className={classes.result}>
        <Typography variant="body2" color="text.secondary">
          {value} %
        </Typography>
      </Box>
      <Box className={classes.wrapper}>
        <Button variant="outlined" className={classes.button} onClick={onRefetch} disabled={!disableCancel}>
          {content.RETRY}
        </Button>
        <Button variant="outlined" color="error" onClick={onCancel} disabled={disableCancel}>
          {content.STOP}
        </Button>
      </Box>
    </Box>
  );
};
