import { useGetOrgSettings } from '@marlin/account-data-access-organization';
import { useMemo } from 'react';

export const useWaterPrice = () => {
  const { data } = useGetOrgSettings();

  const waterPrice = useMemo(() => {
    return data?.settings.waterPrice;
  }, [data]);

  return { waterPrice };
};
