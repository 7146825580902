import { TExtractFnReturnType, useQuery } from '@marlin/shared/utils/react-query';

import { getDevice } from '../infrastructure/device';
import { queryKey } from './query-key.enum';

type TUseDeviceOptions = {
  deviceId: string;
  enabled: boolean;
};

export const useDevice = ({ deviceId, enabled }: TUseDeviceOptions) => {
  return useQuery<TExtractFnReturnType<typeof getDevice>>({
    queryKey: queryKey.DEVICE(deviceId),
    queryFn: () => getDevice({ deviceId }),
    enabled,
  });
};
