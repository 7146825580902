import { zodResolver } from '@hookform/resolvers/zod';
import { FormButtons, FormSection } from '@marlin/shared/ui-form';
import { FieldArrayControl, FormField, Input, Select } from '@marlin/shared/ui-form-common';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button } from '@mui/material';
import React from 'react';
import { ArrayPath, FormProvider, useForm } from 'react-hook-form';

import { ApiKeyField } from './api-key-field.component';
import { webhookFrequencyOptions, webhookTypeOptions } from './constants';
import { content } from './content';
import { ReadOnlySelectedFlowMeters } from './read-only-selected-flow-meters.component';
import { TWebhookForm, webhookFormSchema } from './schema';

interface IWebhookUpsertFormProps {
  defaultValues?: TWebhookForm;
  handleSubmit: (data: TWebhookForm) => void;
  handleCancel: () => void;
  isFormSubmitting?: boolean;
  selectedDefaultFlowMeters: TWebhookForm['devices'];
  selectedAllFlowMeters: TWebhookForm['devices'];
}

export const WebhookUpsertForm = ({
  defaultValues,
  handleSubmit,
  handleCancel,
  isFormSubmitting,
  selectedDefaultFlowMeters,
  selectedAllFlowMeters,
}: IWebhookUpsertFormProps) => {
  const form = useForm<TWebhookForm>({
    defaultValues,
    mode: 'onTouched',
    resolver: zodResolver(webhookFormSchema),
  });

  const isSubmitButtonDisabled = isFormSubmitting || !form.formState.isDirty || !form.formState.isValid;

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <FormSection title={content.MESSAGE_CONFIGURATION} testId={'webhook-message-configuration-section'}>
          <FormField<TWebhookForm> fieldName="name">
            {(props) => <Input {...props} label={content.WEBHOOK_NAME_LABEL} required />}
          </FormField>
          <FormField<TWebhookForm> fieldName="managerEmail">
            {(props) => (
              <Input
                {...props}
                label={content.WEBHOOK_MANAGER_EMAIL_LABEL}
                placeholder={content.WEBHOOK_MANAGER_EMAIL_PLACEHOLDER}
                required
              />
            )}
          </FormField>
          <FormField<TWebhookForm> fieldName="url">
            {(props) => (
              <Input
                {...props}
                label={content.WEBHOOK_URL_LABEL}
                placeholder={content.WEBHOOK_URL_PLACEHOLDER}
                required
              />
            )}
          </FormField>
          <FormField<TWebhookForm> fieldName="frequency">
            {(props) => (
              <Select
                {...props}
                prefix="frequency"
                label={content.FREQUENCY_LABEL}
                data={webhookFrequencyOptions}
                required
              />
            )}
          </FormField>
        </FormSection>
        <FormSection title={content.WEBHOOK_KEYS} testId={'webhook-api-keys-section'}>
          <FieldArrayControl<TWebhookForm>
            fieldName="apiKeys"
            defaultValue={{ name: '', value: '' }}
            renderControl={({ fieldName, control, index }) => (
              <ApiKeyField
                fieldName={fieldName as ArrayPath<Pick<TWebhookForm, 'apiKeys'>>}
                control={control}
                index={index}
              />
            )}
            addButtonProps={{
              children: content.API_KEY_LABELS.ADD_KEY_BUTTON,
              title: content.API_KEY_LABELS.ADD_KEY_BUTTON_TOOLTIP,
              startIcon: <AddCircleIcon />,
              buttonPosition: 'left',
            }}
            absoluteRemoveButton={false}
            minLength={0}
            maxLength={10}
            enumerate
          />
        </FormSection>
        <FormSection title={content.DATA_CONFIGURATION} testId={'webhook-message-configuration-section'}>
          <FormField<TWebhookForm> fieldName="type">
            {(props) => (
              <Select
                {...props}
                onChange={(event) => {
                  props.onChange && props.onChange(event);
                  if (event.target.value === 'total-organization-volume') {
                    form.setValue('devices', selectedDefaultFlowMeters);
                  }
                  if (event.target.value === 'all-individual-flow-meters') {
                    form.setValue('devices', selectedAllFlowMeters);
                  }
                }}
                prefix="type"
                label={content.WEBHOOK_TYPE_LABEL}
                data={webhookTypeOptions}
                required
              />
            )}
          </FormField>
          <ReadOnlySelectedFlowMeters />
        </FormSection>
        <FormButtons>
          <Button variant="outlined" color="primary" onClick={handleCancel} disabled={isFormSubmitting} fullWidth>
            {content.CANCEL_BUTTON}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={form.handleSubmit(handleSubmit)}
            disabled={isSubmitButtonDisabled}
            fullWidth
          >
            {content.SUBMIT_BUTTON}
          </Button>
        </FormButtons>
      </form>
    </FormProvider>
  );
};
