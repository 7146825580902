import {
  THomeLocationSchema,
  THomeLocationsParamsSchema,
  useHomeLocations,
} from '@marlin/asset/data-access/home-dashboard';
import { useCallback, useMemo, useState } from 'react';

export interface IDevice {
  id: string;
  name: string;
}

export enum TAB_VALUES {
  MechanicalRooms,
  OpenAlerts,
  AllLocations,
}

export interface IHome {
  viewLocations: THomeLocationSchema[];
  devices: IDevice[];
  isLoading: boolean;
  isError: boolean;
  tabValue: TAB_VALUES;
  updateTab: (newValue: TAB_VALUES) => void;
  allLocationsLength: number;
  mechanicalRoomsLength: number;
  locationsWithOpenAlertsLength: number;
}

const mechanicalRoomTabParams: THomeLocationsParamsSchema = {
  isMechanicalRoom: true,
  withAlerts: false,
};
const alertsTabParams: THomeLocationsParamsSchema = {
  isMechanicalRoom: false,
  withAlerts: true,
};
const allLocationsTabParams: THomeLocationsParamsSchema = {
  isMechanicalRoom: false,
  withAlerts: false,
};

export const useHome = (): IHome => {
  const [tabValue, setTabValue] = useState(0);
  const [params, setParams] = useState<THomeLocationsParamsSchema>(mechanicalRoomTabParams);
  const locationsQuery = useHomeLocations(params);

  const updateTab = useCallback((newValue: TAB_VALUES) => {
    switch (newValue) {
      case TAB_VALUES.MechanicalRooms: {
        setParams(mechanicalRoomTabParams);
        break;
      }
      case TAB_VALUES.OpenAlerts: {
        setParams(alertsTabParams);
        break;
      }
      case TAB_VALUES.AllLocations: {
        setParams(allLocationsTabParams);
        break;
      }
    }
    setTabValue(newValue);
  }, []);

  const devices = useMemo(() => {
    return (
      locationsQuery.data?.locations
        ?.flatMap((location) => {
          return [...(location.unattachedDevices || []), ...(location.equipment || []).flatMap((e) => e.devices || [])];
        })
        .filter((d) => d)
        .map((d) => ({
          id: d.deviceId,
          name: d.deviceName || '',
        })) || []
    );
  }, [locationsQuery.data?.locations]);

  return {
    tabValue,
    updateTab,
    devices,
    viewLocations: locationsQuery.data?.locations || [],
    isLoading: locationsQuery.isLoading || locationsQuery.isInitialLoading || locationsQuery.isFetching,
    isError: locationsQuery.isError,
    allLocationsLength: locationsQuery.data?.locationCount || 0,
    mechanicalRoomsLength: locationsQuery.data?.mechanicalRoomCount || 0,
    locationsWithOpenAlertsLength: locationsQuery.data?.locationWithAlertsCount || 0,
  };
};
