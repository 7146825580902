import { BehaviorSubject, Observable, identity } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { realtimeCommunicationFacade } from './realtime-communication.facade';

const listenerMap = new Map();

export const initializeListenerConnection = <T>(
  eventName: string,
  initValue: T,
  dataOperator?: ($source: Observable<T>) => Observable<T>
): Observable<void> => {
  const $listenerSubject = getListener<T>(eventName, initValue);

  return realtimeCommunicationFacade.getConnection().pipe(
    switchMap((connection) => {
      return connection.listenOn(eventName) as Observable<T>;
    }),
    dataOperator ? dataOperator : identity,
    map((data: T) => {
      $listenerSubject.next(data);
    })
  );
};

export const getListener = <T>(eventName: string, initValue: T): BehaviorSubject<T> => {
  if (!listenerMap.has(eventName)) {
    const $listenerSubject = new BehaviorSubject<T>(initValue);
    listenerMap.set(eventName, $listenerSubject);
  }

  return listenerMap.get(eventName);
};
