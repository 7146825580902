import { TAddressForm } from './address-form-data-schema';

export const emptyDefaultValues: TAddressForm = {
  address1: '',
  address2: '',
  city: '',
  state: '',
  country: 'United States',
  postalCode: '',
  timeZone: undefined,
};

export const countryList = [
  { id: 'United States', name: 'United States' },
  { id: 'Canada', name: 'Canada' },
  { id: 'Mexico', name: 'Mexico' },
];
