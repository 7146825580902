import {
  COMPARE_TO_MODE,
  PERIOD_ENUM,
  TCompareToValue,
  TPeriodDate,
  useCompareToState,
  usePreviousPeriodState,
} from '@marlin/shared/ui/chart';
import { createDateString, dateAdapter, defaultDateTime, formatDate } from '@marlin/shared/utils-common-date';
import { useEffect, useMemo } from 'react';

interface IUseTotalWaterDataCompareProps {
  selectedPeriod: PERIOD_ENUM;
  currentPeriod: { from: TPeriodDate; to: TPeriodDate };
}

interface IUseTotalWaterDataCompare {
  previousPeriodStart: string | undefined;
  onCompareToChange: (compareTo: TCompareToValue) => void;
  compareToValue: TCompareToValue;
  resetCompareTo: () => void;
}

export const useTotalWaterCompare = ({
  selectedPeriod,
  currentPeriod,
}: IUseTotalWaterDataCompareProps): IUseTotalWaterDataCompare => {
  const { value, onChange: onCompareToChange, reset: resetCompareTo } = useCompareToState();

  const { previousPeriod } = usePreviousPeriodState(selectedPeriod, currentPeriod);

  const previousPeriodStart = useMemo(() => {
    const { mode } = value;

    switch (mode) {
      case COMPARE_TO_MODE.PREVIOUS_PERIOD:
        return previousPeriod.from?.toISOString();
      case COMPARE_TO_MODE.MONTH_OVER_MONTH:
        return currentPeriod.from?.clone().subtract(1, 'month').toISOString();
      case COMPARE_TO_MODE.QUARTER_OVER_QUARTER:
        return currentPeriod.from?.clone().subtract(3, 'month').toISOString();
      case COMPARE_TO_MODE.YEAR_OVER_YEAR:
        return currentPeriod.from?.clone().subtract(1, 'year').toISOString();
      case COMPARE_TO_MODE.CUSTOM_START_DATE:
        return dateAdapter.date(value.date)?.toISOString();
      default:
        return previousPeriod.from?.toISOString();
    }
  }, [value, currentPeriod.from, previousPeriod.from]);

  const compareToValue: TCompareToValue = useMemo(() => {
    const { mode } = value;

    if (mode === COMPARE_TO_MODE.CUSTOM_START_DATE) {
      const currentPeriodDiffInMs = currentPeriod.to?.clone().diff(currentPeriod.from?.clone());
      const customToDate = dateAdapter.date(value.date)?.clone().add(currentPeriodDiffInMs);

      return {
        ...value,
        label: `${formatDate(createDateString(value.date), defaultDateTime)} - ${formatDate(
          createDateString(customToDate?.toISOString()),
          defaultDateTime
        )}`,
      };
    }

    return value;
  }, [currentPeriod.from, currentPeriod.to, value]);

  useEffect(() => {
    if (dateAdapter.date(previousPeriodStart || '')?.isAfter(currentPeriod.from)) {
      resetCompareTo();
    }
  }, [currentPeriod.from, previousPeriodStart, resetCompareTo]);

  return {
    previousPeriodStart,
    onCompareToChange,
    compareToValue,
    resetCompareTo,
  };
};
