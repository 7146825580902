import { TRuleCategory } from '@marlin/alert/data-access/alert-action';
import { TAsset } from '@marlin/alert/data-access/automated-action';
import { MarlinTheme } from '@marlin/shared/theme';
import { CommonChip } from '@marlin/shared/ui/chips';
import { useController } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { IFilterParams } from './types';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  locationChipsWrapper: {
    marginBottom: theme.typography.pxToRem(16),
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.typography.pxToRem(8),
  },
}));

export const FilterChips = () => {
  const { classes } = useStyles();
  const {
    field: { value: locations, onChange: onChangeLocations },
  } = useController<IFilterParams>({ name: 'locationIds' });

  const {
    field: { value: equipments, onChange: onChangeEquipments },
  } = useController<IFilterParams>({ name: 'equipmentIds' });

  const {
    field: { value: devices, onChange: onChangeDevices },
  } = useController<IFilterParams>({ name: 'deviceIds' });

  const {
    field: { value: deviceTypes, onChange: onChangeDeviceTypes },
  } = useController<IFilterParams>({ name: 'deviceTypes' });

  const {
    field: { value: ruleCategories, onChange: onChangeRuleCategories },
  } = useController<IFilterParams>({ name: 'ruleCategories' });

  const removeLocationChip = (id: string): TAsset[] => {
    return (locations as TAsset[])?.filter((asset) => asset.id !== id);
  };

  const removeEquipmentChip = (id: string): TAsset[] => {
    return (equipments as TAsset[])?.filter((asset) => asset.id !== id);
  };

  const removeDeviceChip = (id: string): TAsset[] => {
    return (devices as TAsset[])?.filter((asset) => asset.id !== id);
  };

  const removeDeviceTypeChip = (id: string): TAsset[] => {
    return (deviceTypes as TAsset[])?.filter((asset) => asset.id !== id);
  };

  const removeRuleCategories = (id: string): TRuleCategory[] => {
    return (ruleCategories as TRuleCategory[])?.filter((asset) => asset !== id);
  };

  const hasNoLocations = !(locations as TAsset[]).length;
  const hasNoEquipments = !(equipments as TAsset[]).length;
  const hasNoDevices = !(devices as TAsset[]).length;
  const hasNoDeviceTypes = !(deviceTypes as TAsset[]).length;
  const hasNoRuleCategories = !(ruleCategories as TRuleCategory[])?.length;

  if (hasNoLocations && hasNoDevices && hasNoEquipments && hasNoDeviceTypes && hasNoRuleCategories) {
    return null;
  }

  return (
    <div className={classes.locationChipsWrapper}>
      {(locations as TAsset[])?.map((value: TAsset) => (
        <CommonChip
          testId={`filterChip-${value.id}`}
          key={value.id}
          label={value.name}
          active={true}
          onDelete={() => onChangeLocations && onChangeLocations({ target: { value: removeLocationChip(value.id) } })}
        />
      ))}
      {(equipments as TAsset[])?.map((value: TAsset) => (
        <CommonChip
          testId={`filterChip-${value.id}`}
          key={value.id}
          label={value.name}
          active={true}
          onDelete={() =>
            onChangeEquipments && onChangeEquipments({ target: { value: removeEquipmentChip(value.id) } })
          }
        />
      ))}
      {(devices as TAsset[])?.map((value: TAsset) => (
        <CommonChip
          testId={`filterChip-${value.id}`}
          key={value.id}
          label={value.name}
          active={true}
          onDelete={() => onChangeDevices && onChangeDevices({ target: { value: removeDeviceChip(value.id) } })}
        />
      ))}
      {(deviceTypes as TAsset[])?.map((value: TAsset) => (
        <CommonChip
          testId={`filterChip-${value.id}`}
          key={value.id}
          label={value.name}
          active={true}
          onDelete={() =>
            onChangeDeviceTypes && onChangeDeviceTypes({ target: { value: removeDeviceTypeChip(value.id) } })
          }
        />
      ))}
      {(ruleCategories as TRuleCategory[])?.map((value: TRuleCategory) => (
        <CommonChip
          testId={`filterChip-${value}`}
          key={value}
          label={value}
          active={true}
          onDelete={() =>
            onChangeRuleCategories && onChangeRuleCategories({ target: { value: removeRuleCategories(value) } })
          }
        />
      ))}
    </div>
  );
};
