import { getModulo } from '@marlin/shared/utils-format-reading';
import {
  TDatapoint,
  TDeviceMetadataDatapointsResponse,
  TSettingDatapoint,
} from '@marlin/shared/utils/datapoint-mappers';

import { logValidationIssue } from './log-validation-issue';

export const validateDatapoint = (
  datapoint: TSettingDatapoint | TDatapoint,
  metadata: TDeviceMetadataDatapointsResponse | undefined
) => {
  if (!metadata) {
    logValidationIssue(datapoint.name, 'NO_METADATA');
    return false;
  }

  if (!datapoint.value || Array.isArray(datapoint.value)) {
    logValidationIssue(datapoint.name, 'INCORRECT_VALUE');
    return false;
  }

  switch (metadata.type) {
    case 'bool':
      if (datapoint.value !== '0' && datapoint.value !== '1') {
        logValidationIssue(datapoint.name, 'INCORRECT_VALUE');
        return false;
      }
      break;
    case 'number':
      return validateNumberType(datapoint, metadata);
    case 'enum':
      if (!metadata.options.find((option) => option.id === datapoint.value)) {
        logValidationIssue(datapoint.name, 'INCORRECT_OPTION');
        return false;
      }
      break;
    default:
      logValidationIssue(datapoint.name, 'TYPE_NOT_EXISTS');
      return false;
  }

  return true;
};

const validateNumberType = (datapoint: TSettingDatapoint | TDatapoint, metadata: TDeviceMetadataDatapointsResponse) => {
  const value = parseFloat(datapoint.value);
  if (datapoint.value === '' || Number.isNaN(value)) {
    logValidationIssue(datapoint.name, 'INCORRECT_VALUE');
    return false;
  }

  if (getModulo(value, parseFloat(metadata.step || '0')) !== 0) {
    logValidationIssue(datapoint.name, 'INCORRECT_STEP_VALUE');
    return false;
  }

  if (value < parseFloat(metadata.min || '0') || value > parseFloat(metadata.max || '0')) {
    logValidationIssue(datapoint.name, 'INCORRECT_RANGE_VALUE');
    return false;
  }

  return true;
};
