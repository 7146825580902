import { LocationSearchWrapper } from '@marlin/alert/data-access/automated-action';
import { DeviceTypeControl } from '@marlin/shared/ui-form';
import { FormField, InfiniteAutocompleteControl, Input, useSubmitOnChange } from '@marlin/shared/ui-form-common';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Button, IconButton } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import { content } from '../../content';
import { FilterChips } from './filter-chips.component';
import { useStyles } from './filters.component.styles';
import { IDeviceHubFilters } from './types';

const defaultFilters: IDeviceHubFilters = {
  deviceType: null,
  locations: [],
  search: '',
};

interface IFiltersProps {
  onFiltersChange: (data: IDeviceHubFilters) => void;
  clearAdditionalFilters?: () => void;
}

export const Filters = ({ onFiltersChange, clearAdditionalFilters }: IFiltersProps) => {
  const { classes } = useStyles();

  const form = useForm<IDeviceHubFilters>({
    defaultValues: defaultFilters,
  });

  const { submit, resetForm } = useSubmitOnChange<IDeviceHubFilters>(form, onFiltersChange);

  const clearAll = () => {
    resetForm();
    clearAdditionalFilters?.();
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={submit} data-testid="filters-form">
        <div className={classes.filters}>
          <div className={classes.filterFields}>
            <FormField<IDeviceHubFilters> fieldName="search">
              {(props) => (
                <Input
                  className={classes.searchFilter}
                  label={content.SEARCH_LOCATION}
                  externalEndAdornment={{
                    endAdornment: (
                      <IconButton>
                        <SearchRoundedIcon color="action" />
                      </IconButton>
                    ),
                  }}
                  {...props}
                />
              )}
            </FormField>

            <div className={classes.locationFilter}>
              <LocationSearchWrapper>
                {(props) => (
                  <InfiniteAutocompleteControl<IDeviceHubFilters>
                    fieldName="locations"
                    testId="location-multi-select"
                    label={content.LOCATION_LABEL}
                    className={classes.locationFilter}
                    {...props}
                  />
                )}
              </LocationSearchWrapper>
            </div>
            <div>
              <DeviceTypeControl<IDeviceHubFilters> fieldName="deviceType" className={classes.deviceTypeFilter} />
            </div>
          </div>
          <Button className={classes.clearAll} variant="outlined" onClick={clearAll} data-testid="clearAll">
            {content.CLEAR_ALL}
          </Button>
        </div>
        <FilterChips />
      </form>
    </FormProvider>
  );
};
