import { TEquipmentSchema } from '@marlin/asset/data-access/equipment';
import { CardHeader, DetailsItem, LinkedItem } from '@marlin/asset/shared/ui/hub-card';
import { MarlinTheme } from '@marlin/shared/theme';
import { Paper } from '@marlin/shared/ui-page';
import { defaultDateTime, formatDate, getTimeFromNow } from '@marlin/shared/utils-common-date';
import { useRouter } from '@marlin/shared/utils-router';
import { modelContent } from '@marlin/shared/utils/datapoint-mappers';
import { MODEL, ModelEnum, gatewayEquipmentModels, registerableEquipmentModels } from '@marlin/shared/utils/zod';
import DeviceHubRoundedIcon from '@mui/icons-material/DeviceHubRounded';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import { SafeParseReturnType } from 'zod';

import { content } from '../content';
import { useNavigation } from '../hooks/use-navigation.hook';
import { ModeMap } from '../utils/equipment-hub.model';
import { getConnectionTimeout, getModeDatapoint, getStatusAdapter } from '../utils/utils';
import { StatusBar } from './equipment-status-bar';

interface IEquipmentCardProps {
  equipment: TEquipmentSchema;
  children: JSX.Element;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  card: {
    borderRadius: theme.typography.pxToRem(4),
    margin: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(16)}`,
    '& svg': {
      fontSize: theme.typography.body1.fontSize,
      marginRight: theme.typography.pxToRem(8),
    },
  },
  detailsItemWrapper: {
    display: 'flex',
    gap: theme.typography.pxToRem(48),
    marginBottom: theme.typography.pxToRem(8),
    fontSize: theme.typography.pxToRem(14),
  },
  locationLink: {
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    color: theme.palette.primary.main,
  },
}));

export const EquipmentCard = ({ equipment, children }: IEquipmentCardProps) => {
  const { classes } = useStyles();
  const { getLocationDetailsPageLink, getEquipmentDetailsPageLink } = useNavigation();
  const { goTo } = useRouter();
  const isDashboardEquipment = useMemo(
    () =>
      (equipment.isRegistered && registerableEquipmentModels.includes(equipment.model ?? '')) ||
      (equipment.additionalProperties?.['gatewayId'] && gatewayEquipmentModels.includes(equipment.model ?? '')),
    [equipment]
  );
  const equipmentModelParsed: SafeParseReturnType<MODEL, MODEL> = equipment.isRegistered
    ? ModelEnum.safeParse(equipment.model)
    : { success: true, data: equipment.model as unknown as MODEL };

  if (!equipmentModelParsed.success) {
    // eslint-disable-next-line no-console
    console.error('Failed to parse equipment model', equipment.model);
    return null;
  }

  const equipmentModel = equipmentModelParsed.data;

  const connectionTimeout = getConnectionTimeout(equipmentModel);
  const isOperational =
    !!equipment?.devices &&
    !!equipment?.devices[0]?.lastReadingTime &&
    (getTimeFromNow(equipment?.devices[0]?.lastReadingTime, 'seconds') ?? Number.MAX_SAFE_INTEGER) < connectionTimeout;

  const mode =
    equipment.devices &&
    equipment?.devices[0]?.lastReadingValues.find(
      (datapoint: { name: string }) => datapoint.name.toLocaleLowerCase() === getModeDatapoint(equipmentModel)
    )?.value;
  const statusIdToKeyAdapter = getStatusAdapter(equipmentModel);
  const modeDisplayValue = statusIdToKeyAdapter ? statusIdToKeyAdapter(mode) : null;
  const lastReadingTime = equipment.devices && equipment?.devices[0]?.lastReadingTime;

  const model = modelContent.MODELS.get(equipment.model ?? '') ?? equipment.model;
  const modelVariant = equipment.devices[0]?.lastReadingValues.find(
    (datapoint: { name: string }) => datapoint.name.toLocaleLowerCase() === 'modelvariant'
  )?.value;

  const modelDisplayValue =
    model && modelVariant ? `${model} ${modelVariant}` : model ? model : content.EMPTY_DATAPOINT_VALUE;

  const modeDisplayValueMap = ModeMap.get(equipmentModel);

  return (
    <Paper className={classes.card} data-testid="card">
      <CardHeader
        asset={equipment}
        goTo={(id: string) => goTo(getEquipmentDetailsPageLink(id, !!isDashboardEquipment))}
        icon={<DeviceHubRoundedIcon />}
        data-testid="equipment-card-header"
      >
        {children}
      </CardHeader>
      <div className={classes.detailsItemWrapper}>
        {(equipment.isRegistered || equipment.devices[0]?.gatewayId) && <StatusBar isOperational={isOperational} />}
        {modeDisplayValue && (
          <DetailsItem
            testId="equipment-card-mode"
            label={content.MODE_HEADER_NAME}
            value={modeDisplayValueMap?.get(modeDisplayValue.toLocaleLowerCase())}
          />
        )}
      </div>
      <div className={classes.detailsItemWrapper}>
        <DetailsItem
          testId="equipment-card-location"
          label={content.LOCATION_LABEL}
          value={
            <div className={classes.locationLink}>
              <LinkedItem
                id={equipment.locationId}
                name={equipment.locationName}
                goTo={(id: string) => getLocationDetailsPageLink(id, equipment.locationName ?? '')}
              />
            </div>
          }
        />
      </div>
      <div className={classes.detailsItemWrapper}>
        <DetailsItem testId="equipment-card-model" label={content.MODEL_HEADER_NAME} value={modelDisplayValue} />
      </div>
      {equipment.serialNumber && (
        <div className={classes.detailsItemWrapper}>
          <DetailsItem
            testId="equipment-card-serial-number"
            label={content.SERIAL_NUMBER_HEADER_NAME}
            value={equipment.serialNumber}
          />
        </div>
      )}
      <div className={classes.detailsItemWrapper}>
        <DetailsItem
          testId="equipment-card-last-reading-time"
          label={content.LAST_READING_TIME_HEADER_NAME}
          value={lastReadingTime ? formatDate(lastReadingTime, defaultDateTime) : content.EMPTY_DATAPOINT_VALUE}
        />
      </div>
    </Paper>
  );
};
