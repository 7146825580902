import {
  TSublocationAssetSchema,
  TSublocationParamsSchema,
  useAssetsInSublocationWithPaging,
} from '@marlin/asset/data-access/location';
import { mapSortDirection } from '@marlin/shared/utils-sorting';
import { usePagination } from '@marlin/shared/utils/react-query';
import { GridSortModel } from '@mui/x-data-grid';
import { useCallback, useMemo, useState } from 'react';

import { ILocationDetailsFilters } from './types';

const dataGridPageCounterOffset = 1;

const defaultParams = {
  initialPage: 1,
  initialPageSize: 10,
};

interface IPagination {
  page: number;
  pageSize: number;
  rowCount: number;
}

interface IUseDesktopHook {
  pagination: IPagination;
  rows: TSublocationAssetSchema[];
  isError: boolean;
  isLoading: boolean;
  changePage: (newPage: number) => void;
  changePageSize: (newPageSize: number) => void;
  refetch: () => void;
  onFiltersChange: (data: ILocationDetailsFilters) => void;
  onSortChange: (sortModel: GridSortModel) => void;
}

export const useDesktopGridHook = (locationId: string): IUseDesktopHook => {
  const { page, pageSize, changePage: paginationChangePage, changePageSize } = usePagination(defaultParams);
  const [requestParams, setRequestParams] = useState<Omit<TSublocationParamsSchema, 'page' | 'pageSize'>>({});
  const { data, isError, isFetching, refetch } = useAssetsInSublocationWithPaging({
    locationId,
    params: { ...requestParams, page, pageSize },
  });

  const dataGridPage = useMemo(() => page - dataGridPageCounterOffset, [page]);
  const changeDataGridPage = (newPage: number) => paginationChangePage(newPage + dataGridPageCounterOffset);

  const onFiltersChange = useCallback(
    (data: ILocationDetailsFilters) => {
      paginationChangePage(1);
      setRequestParams({
        search: data.search,
        subLocationIds: data.locations?.map((location) => location.id),
        deviceTypes: data.deviceTypes,
        types: data.deviceTypes && data.deviceTypes?.length > 0 ? ['DEVICE', ...(data?.types || [])] : data.types,
      });
    },
    [paginationChangePage]
  );

  const onSortChange = useCallback(
    (sortModel: GridSortModel) => {
      if (!sortModel[0] || !sortModel[0].sort) {
        setRequestParams({ ...requestParams, sorting: undefined });
      } else {
        const sorting = {
          sortBy: sortModel[0]?.field,
          direction: mapSortDirection(sortModel[0]?.sort),
        };
        setRequestParams({
          ...requestParams,
          sorting,
        });
      }
    },
    [requestParams]
  );

  return {
    pagination: {
      page: dataGridPage,
      pageSize,
      rowCount: data?.pagination.totalItems || 0,
    },
    rows: data?.data || [],
    isError,
    isLoading: isFetching,
    changePage: changeDataGridPage,
    changePageSize,
    refetch,
    onFiltersChange,
    onSortChange,
  };
};
