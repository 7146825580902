import {
  useCreateGateway,
  useGatewayBrandsAndModels,
  useGatewayConfiguration,
  useUpdateGateway,
} from '@marlin/asset/data-access/gateway';
import { MarlinTheme } from '@marlin/shared/theme';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { PageContainer, PageHeader } from '@marlin/shared/ui-page';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { useFeatureFlagsContext } from '@marlin/shared/utils-common-feature-flags-context';
import { routes } from '@marlin/shared/utils-routes';
import SettingsIcon from '@mui/icons-material/Settings';
import { AxiosError } from 'axios';
import { useCallback, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { z } from 'zod';

import { content } from '../content';
import { useTestGatewayContext } from '../context/test-flow.context';
import { useTestGatewayModal } from '../hooks/modals/use-test-modal.component';
import { isUpdating } from '../utils/is-updating.utils';
import { getConfigurationPayload } from './form/get-configuration-payload';
import { mapConfigurationResponseToDefaultValues } from './form/map-configuration-response-to-default-values';
import { UpsertConnectionForm } from './form/upsert-connection-form.components';
import {
  TUpsertConnectionFormSchemaType,
  TUpsertConnectionFormSchemaTypeUpdate,
} from './form/upsert-connection-form.schema';
import { STATUS, Status } from './status.component';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  formWrapper: {
    width: '100%',
    maxWidth: theme.typography.pxToRem(600),
  },
  wrapper: {
    display: 'flex',
  },
  rightSideActions: {
    display: 'flex',
    gap: theme.typography.pxToRem(8),
  },
}));

const getStatus = (isCurrentConfig: boolean) => {
  if (!isCurrentConfig) {
    return STATUS.DRAFT;
  }

  if (isCurrentConfig) {
    return STATUS.PUBLISHED;
  }

  return STATUS.NEW;
};

export function ConfigureGateway() {
  const { classes } = useStyles();
  const { gateway } = useFeatureFlagsContext();
  const { deviceId } = useParams();
  const configurationQuery = useGatewayConfiguration({ deviceId });
  const { isTestOnly, setAddressErrors } = useTestGatewayContext();
  const { testGateway } = useTestGatewayModal({
    setAddressErrors,
  });
  const { enqueueSnackbar } = useSnackbar();

  const onError = useCallback(
    (error: AxiosError) => {
      // eslint-disable-next-line no-console
      if (error instanceof z.ZodError) console.log(error.issues);

      enqueueSnackbar(content.ERROR_MESSAGE, { variant: 'error' });
    },
    [enqueueSnackbar]
  );

  const onSubmitSuccess = useCallback(() => {
    enqueueSnackbar(content.SAVE_MESSAGE, { variant: 'success' });
    configurationQuery.refetch();
  }, [enqueueSnackbar, configurationQuery]);

  const createGatewayWithSaveMutation = useCreateGateway({
    onError,
    onSuccess: onSubmitSuccess,
  });

  const updateGatewayWithSaveMutation = useUpdateGateway({
    onError,
    onSuccess: onSubmitSuccess,
  });

  const handleSaveAndTest = useCallback(
    (data: TUpsertConnectionFormSchemaType | TUpsertConnectionFormSchemaTypeUpdate) => {
      const payload = getConfigurationPayload(data, deviceId ?? '');
      if (data && isUpdating(payload)) {
        updateGatewayWithSaveMutation.mutate({ data: { ...payload } });
      } else {
        createGatewayWithSaveMutation.mutate({ data: payload });
      }
    },
    [createGatewayWithSaveMutation, deviceId, updateGatewayWithSaveMutation]
  );

  const brandsQuery = useGatewayBrandsAndModels();
  const { currentConfigurationId, id } = configurationQuery.data ?? {};
  const status = useMemo(
    () => (!id || configurationQuery.isError ? STATUS.NEW : getStatus(currentConfigurationId === id)),
    [configurationQuery.isError, currentConfigurationId, id]
  );
  const onTestGatewayClick = useCallback(
    (data: TUpsertConnectionFormSchemaType | TUpsertConnectionFormSchemaTypeUpdate) => {
      if (data.connections?.some((connection) => connection.addresses.length > 0)) {
        testGateway({
          deviceId: deviceId ?? '',
          configurationId: configurationQuery?.data?.id ?? '',
          data,
          isTestOnly,
          refetch: configurationQuery.refetch,
        });
      } else {
        handleSaveAndTest(data);
      }
    },
    [testGateway, deviceId, configurationQuery?.data?.id, isTestOnly, configurationQuery.refetch, handleSaveAndTest]
  );

  const defaultValues = useMemo(
    () =>
      mapConfigurationResponseToDefaultValues(configurationQuery.data, brandsQuery.data, configurationQuery.isError),
    [configurationQuery.data, brandsQuery.data, configurationQuery.isError]
  );

  if (configurationQuery.isLoading || configurationQuery.isFetching || brandsQuery.isLoading) return <LoadingSpinner />;

  if (!gateway || !deviceId) return <Navigate to={routes.gateway.list.url()} replace />;

  return (
    <PageContainer>
      <PageHeader
        icon={<SettingsIcon />}
        title={content.GATEWAY}
        subtitle={content.CONFIGURE_GATEWAY_SUBTITLE}
        prefix="configure-gateway-header"
        rightSideActions={<Status status={status} configFile={configurationQuery?.data?.configurationFile} />}
      />
      <div className={classes.formWrapper}>
        <UpsertConnectionForm status={status} onSubmit={onTestGatewayClick} defaultValues={defaultValues} />
      </div>
    </PageContainer>
  );
}
