import { Box, Modal } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

import { useStyles } from './edit-modal.styles';

interface IEditModalProps {
  onClose: () => void;
}

const EditModal: FC<PropsWithChildren<IEditModalProps>> = ({ children, onClose }) => {
  const { classes } = useStyles();
  return (
    <Modal
      open={true}
      onClose={onClose}
      data-testid="modal-root"
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={classes.container} data-testid="modal-body">
        {children}
      </Box>
    </Modal>
  );
};

export { EditModal };
