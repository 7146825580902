import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { useMemo } from 'react';

import { useStyles } from '../status.styles';
import { TStatus } from '../types';
import { TooltipContent } from './tooltip/tooltip-content.component';

interface IPerformanceStatusIconProps {
  status: TStatus;
  className?: string;
  testId?: string;
  tooltipPlacement?: 'left' | 'right' | 'top' | 'bottom';
}

export const PerformanceStatusIcon = ({
  status,
  className,
  testId,
  tooltipPlacement = 'right',
}: IPerformanceStatusIconProps) => {
  const { classes, cx } = useStyles();

  const icon = useMemo(() => {
    switch (status) {
      case 'warning':
        return <WarningRoundedIcon className={cx(classes.warning, className)} data-testid={testId} />;
      case 'error':
        return <WarningRoundedIcon className={cx(classes.error, className)} data-testid={testId} />;
      default:
        return <CheckCircleRoundedIcon className={cx(classes.success, className)} data-testid={testId} />;
    }
  }, [className, classes.error, classes.success, classes.warning, cx, status, testId]);

  return (
    <Tooltip placement={tooltipPlacement} text={<TooltipContent />}>
      {icon}
    </Tooltip>
  );
};
