import { Location } from 'history';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import { useOnPageLeave } from '../use-on-page-leave.hook';

interface IGuardCache {
  modal: boolean;
  nextLocation: Location | null;
}

let cache: IGuardCache | undefined = undefined;

type TCachedGuardModalReturn = {
  modal: boolean;
  setModal: Dispatch<SetStateAction<boolean>>;
  nextLocation: Location | null;
  setNextLocation: Dispatch<SetStateAction<Location | null>>;
  clearCache: () => void;
  isCacheEmpty: () => boolean;
};

export const useCachedGuardModal = (
  initialModal: boolean,
  initialNextLocation: Location | null
): TCachedGuardModalReturn => {
  const [modal, setModal] = useState<boolean>(initialModal);
  const [nextLocation, setNextLocation] = useState<Location | null>(initialNextLocation);

  const clearCache = useCallback(() => (cache = undefined), []);

  const isCacheEmpty = useCallback(() => cache === undefined, []);

  useEffect(() => {
    if (cache !== undefined) {
      setModal(cache.modal);
      setNextLocation(cache.nextLocation);
    }
  }, []);

  useOnPageLeave(() => clearCache());

  useEffect(() => {
    if (modal !== initialModal) {
      cache = {
        modal,
        nextLocation,
      };
    }
  }, [initialModal, modal, nextLocation]);

  return { modal, setModal, nextLocation, setNextLocation, clearCache, isCacheEmpty };
};
