import { useMediaQuery, useTheme } from '@mui/material';

import { DeviceDrawerDetails } from './components';
import { useExtendedDeviceDrawer } from './components/container/context';
import { DeviceDetailsMobile } from './components/details-mobile';
import { useSensorId } from './hooks/use-sensor-id.hook';

const DeviceDetailsTab = () => {
  const deviceId = useSensorId();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isExtended = useExtendedDeviceDrawer();

  if (!deviceId || deviceId.length === 0) {
    return null;
  }

  if (isMobile) {
    return <DeviceDetailsMobile />;
  }

  return (
    <>
      <div
        style={{
          padding: isExtended ? theme.typography.pxToRem(16) : 0,
        }}
      >
        <DeviceDrawerDetails />
      </div>
    </>
  );
};

export { DeviceDetailsTab };
