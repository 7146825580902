import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  mobileMenu: {
    padding: 0,
  },

  emptyState: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  filterBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(16)}`,
  },

  filterToggle: {
    marginLeft: 'auto',
  },

  filterChips: {
    marginLeft: theme.typography.pxToRem(-16),
    marginRight: theme.typography.pxToRem(-16),
  },
}));
