import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';
import { z } from 'zod';

import { paths } from './api';

const CreateOrganizationSchema = z.object({
  name: z.string(),
});

export type TCreateOrganization = z.infer<typeof CreateOrganizationSchema>;

export const createOrganization = (data: TCreateOrganization): Promise<string> => {
  const body = safeParseData(data, CreateOrganizationSchema);
  return getHttpClient().post(paths.CREATE_ORGANIZATION, body);
};
