import { Data, PlotData } from 'plotly.js';

const chartDesktopHeight = 360;
const singleRangeBarChartDesktopHeight = 38;
const rangeBarDesktopHeightFactor = 94;

export const getChartHeight = (
  height: number | undefined,
  rangeBarDatapointsLength: number | undefined,
  hasChartData: boolean
) => {
  if (height) {
    return height;
  }

  if (hasChartData && rangeBarDatapointsLength) {
    return rangeBarDatapointsLength * singleRangeBarChartDesktopHeight + rangeBarDesktopHeightFactor;
  }

  return chartDesktopHeight;
};

export const getDataForRangeBarChart = (mergedData: Partial<Data>[], to: number) => {
  return mergedData.map((series) => {
    if (isSeriesEmpty(series)) {
      return series;
    }

    if (isRangeBarData(series)) {
      const xValues = Array.isArray(series.x) ? series.x.filter(Boolean) : [];

      return {
        ...series,
        x: [...xValues, to - new Date(series.base?.at(-1) ?? '').valueOf()],
      };
    }

    return series;
  }) as Partial<PlotData>[];
};

const isRangeBarData = (series: Partial<Data>): series is Partial<PlotData & { base: string[] }> =>
  Boolean(
    'x' in series &&
      series.x &&
      Array.isArray(series.x) &&
      'base' in series &&
      Array.isArray(series.base) &&
      (!series.x.filter(Boolean).length || series.x.length !== series.base.length)
  );

const isSeriesEmpty = (series: Partial<Data>) =>
  'y' in series && series.y && Array.isArray(series.y) && !series.y.length;
