import { TOperationError } from '@marlin/asset/data-access/gateway';
import { MarlinTheme } from '@marlin/shared/theme';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  errors: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(14),
  },
  errorsInfo: {
    marginTop: theme.typography.pxToRem(16),
    fontSize: theme.typography.pxToRem(14),
  },
}));

interface IErrorsProps {
  errors: Array<TOperationError>;
}

export const Errors = ({ errors }: IErrorsProps) => {
  const { classes } = useStyles();

  return (
    <div>
      <p className={classes.errors}>{content.TEST_CONFIGURATION.ERRORS_TITLE}</p>
      {errors.map((error, idx) => (
        <Typography variant="body2">{`${content.TEST_CONFIGURATION.ERROR} ${idx + 1}: ${
          error.description
        }`}</Typography>
      ))}
      <div className={classes.errorsInfo}>{content.TEST_CONFIGURATION.ERRORS_INFO(errors.length)}</div>
    </div>
  );
};
