import { MarlinTheme } from '@marlin/shared/theme';
import { ONBOARDING_TYPE } from '@marlin/shared/utils-routes';
import { useTheme } from '@mui/system';
import { useMemo } from 'react';

import { content } from './content';
import { useStyles } from './onboarding-header.styles';

interface IOnboardingHeader {
  hideWelcomeMessage?: boolean;
  onboardingType?: ONBOARDING_TYPE | null;
}

export const OnboardingHeader = ({ hideWelcomeMessage, onboardingType }: IOnboardingHeader) => {
  const { classes } = useStyles();
  const theme = useTheme<MarlinTheme>();

  const title = useMemo(() => {
    if (onboardingType === ONBOARDING_TYPE.CREATE_NEW_ORGANIZATION) {
      return content.CREATE_NEW_ORGANIZATION;
    }
    return content.WELCOME_ONBOARDING;
  }, [onboardingType]);

  return (
    <div className={classes.container}>
      <div className={classes.logo}>{theme.icon.fullLogo}</div>
      {!hideWelcomeMessage && <div className={classes.title}>{title}</div>}
    </div>
  );
};
