import { CHANNEL_TYPE } from '@marlin/alert/data-access/automated-action';
import { useFeatureFlagsContext } from '@marlin/shared/utils-common-feature-flags-context';

import { useContent } from '../context';
import { IRecipient } from '../types';
import { Channel } from './channel.component';
import { ExtendedChannel } from './extended-channel.component';
import { FormGroupHeader } from './shared/form-group-header.component';

interface IActionProps {
  recipients: IRecipient[];
  onAddRecipientButtonClick: () => void;
  isInformationType?: boolean;
}

export function Action({ recipients, onAddRecipientButtonClick, isInformationType = false }: IActionProps) {
  const content = useContent();
  const { hotSos } = useFeatureFlagsContext();

  return (
    <>
      <FormGroupHeader title={content.ACTION.TITLE} tooltip={content.ACTION.TOOLTIP} prefix="action" />
      {!isInformationType && <Channel type={CHANNEL_TYPE.IN_APP} id="automation-inapp-checkbox" />}
      <ExtendedChannel
        type={CHANNEL_TYPE.EMAIL}
        recipients={recipients}
        onAddRecipientButtonClick={onAddRecipientButtonClick}
        checkboxId="automation-email-checkbox"
        selectId="automation-email-recipients"
      />
      <ExtendedChannel
        type={CHANNEL_TYPE.SMS}
        recipients={recipients}
        onAddRecipientButtonClick={onAddRecipientButtonClick}
        checkboxId="automation-sms-checkbox"
        selectId="automation-sms-recipients"
      />
      {hotSos && !isInformationType && <Channel type={CHANNEL_TYPE.HOT_SOS} id="automation-hotsos-checkbox" />}
    </>
  );
}
