import { queryClient } from '@marlin/shared/utils/react-query';
import { useObservableState } from 'observable-hooks';
import { useEffect } from 'react';

import { getHomeDashboardSubject, initSubject } from '../infrastructure/home-dashboard-notification';
import { TGetHomeDashboardResponseSchema } from '../infrastructure/schema/home-dashboard.schema';
import { queryKey } from './query-key.enum';

export const useDashboardHubNotification = (): TGetHomeDashboardResponseSchema | undefined => {
  const [notification] = useObservableState<TGetHomeDashboardResponseSchema | undefined>(
    getHomeDashboardSubject,
    undefined
  );

  useEffect(() => {
    if (notification) {
      queryClient.setQueryData(queryKey.HOME_DASHBOARD(), notification);
    }
  }, [notification]);

  return notification;
};

export const useInitHomeDashboardNotificationSubject = () => {
  return useObservableState(initSubject);
};
