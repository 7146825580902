import { MarlinTheme } from '@marlin/shared/theme';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { Avatar, Chip, alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { IRecipient } from '../../types';

const getFirstLetter = (name: string): string => {
  return name[0]?.toUpperCase() ?? '';
};

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  chip: {
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    '&> .MuiChip-label': {
      maxWidth: theme.typography.pxToRem(65),
    },
    '&> .MuiChip-avatar': {
      color: theme.palette.background.primary,
      backgroundColor: theme.palette.primary.main,
    },
    '& .MuiChip-deleteIcon': {
      color: alpha(theme.palette.primary.main, 0.5),
      ':hover': {
        color: theme.palette.primary.main,
      },
    },
  },
}));

interface ICustomTagProps {
  user: IRecipient;
  className: string;
  disabled: boolean;
  'data-tag-index': number;
  tabIndex: number;
  onDelete: (event: unknown) => void;
}

export function CustomTag({ user, className, ...props }: ICustomTagProps) {
  const { classes } = useStyles();
  const initials = `${getFirstLetter(user.firstName)}${getFirstLetter(user.lastName)}`;
  const userName = `${user.firstName} ${user.lastName}`;
  return (
    <Tooltip placement="top" text={userName}>
      <Chip
        {...props}
        avatar={<Avatar>{initials}</Avatar>}
        label={user.firstName}
        className={`${className} ${classes.chip}`}
      />
    </Tooltip>
  );
}
