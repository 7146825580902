import step1 from './assets/step-1.webp';
import step2 from './assets/step-2.webp';
import step3 from './assets/step-3.webp';
import step4 from './assets/step-4.webp';
import { content } from './content';

export const howToFindRegisterCodeModalSteps = [
  {
    imageSrc: step1,
    description: content.INSTRUCTIONS_MODAL.STEP_1,
    descriptionKeyword: content.INSTRUCTIONS_MODAL.STEP_1_KEYWORD,
    title: content.INSTRUCTIONS_MODAL.TITLE,
  },
  {
    imageSrc: step2,
    description: content.INSTRUCTIONS_MODAL.STEP_2,
    descriptionKeyword: content.INSTRUCTIONS_MODAL.STEP_2_KEYWORD,
    title: content.INSTRUCTIONS_MODAL.TITLE,
  },
  {
    imageSrc: step3,
    description: content.INSTRUCTIONS_MODAL.STEP_3,
    descriptionKeyword: content.INSTRUCTIONS_MODAL.STEP_3_KEYWORD,
    title: content.INSTRUCTIONS_MODAL.TITLE,
  },
  {
    imageSrc: step4,
    description: content.INSTRUCTIONS_MODAL.STEP_4,
    descriptionKeyword: content.INSTRUCTIONS_MODAL.STEP_4_KEYWORD,
    title: content.INSTRUCTIONS_MODAL.TITLE,
  },
];
