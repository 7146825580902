import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import { useRouter } from '@marlin/shared/utils-router';
import { Button } from '@mui/material';
import { useCallback, useContext } from 'react';

import { content } from './content';
import { useStyles } from './delete-modal.styles';
import { useDeleteDeviceStore } from './use-delete-device-store.hook';
import { useManageAutomations } from './use-manage-automations.hook';

interface IModalFooterProps {
  deviceId: string | null;
  refetch?: () => void;
}

export const DeleteDeviceModalFooter = ({ deviceId, refetch }: IModalFooterProps): JSX.Element | null => {
  const { classes } = useStyles();
  const { isLoading } = useManageAutomations(deviceId || '');
  const { deleteDevice } = useDeleteDeviceStore();
  const router = useRouter();
  const { modalDispatch } = useContext(ModalContext);

  const handleClose = useCallback(() => {
    modalDispatch({ type: MODAL_ACTION_TYPE.DISMISS });
  }, [modalDispatch]);

  const handleDelete = useCallback((): void => {
    if (!deviceId) {
      return;
    }

    deleteDevice(deviceId);
    if (refetch) {
      refetch();
    } else {
      router.refresh();
    }
    handleClose();
  }, [deviceId, handleClose, deleteDevice, router, refetch]);

  return (
    <div>
      <Button className={classes.button} variant="text" onClick={handleClose}>
        {content.MODAL_BUTTON_CANCEL_TEXT}
      </Button>
      <Button
        className={`${classes.button} ${classes.cancelButton}`}
        variant="contained"
        color="error"
        disabled={isLoading}
        onClick={handleDelete}
      >
        {content.MODAL_BUTTON_SUBMIT_TEXT}
      </Button>
    </div>
  );
};
