import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.typography.pxToRem(32),
    alignItems: 'center',
    maxWidth: theme.typography.pxToRem(676),
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  logo: {
    width: theme.typography.pxToRem(150),
    height: theme.typography.pxToRem(36),
  },
  title: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: '175%',
    color: theme.palette.text.primary,
    [theme.breakpoints.down('md')]: {
      width: '100vw',
      paddingLeft: theme.typography.pxToRem(16),
      paddingRight: theme.typography.pxToRem(16),
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(14),
    },
  },
}));
