import { LocationSearchWrapper } from '@marlin/alert/data-access/automated-action';
import { IEquipmentFilter } from '@marlin/asset/shared/ui/equipment-list';
import { EquipmentTypeControl } from '@marlin/shared/ui-device-type';
import { FieldFiltersChips } from '@marlin/shared/ui-form';
import { FormField, InfiniteAutocompleteControl, Input, useSubmitOnChange } from '@marlin/shared/ui-form-common';
import { FilterChipsContainer } from '@marlin/shared/ui/chips';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { Button, IconButton } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import { content } from '../../content';
import { useStyles } from '../equipment-hub.component.styles';

interface IFiltersProps {
  onFiltersChange: (data: IEquipmentFilter) => void;
}
export const Filters = ({ onFiltersChange }: IFiltersProps) => {
  const { classes } = useStyles();

  const form = useForm<IEquipmentFilter>({
    defaultValues: {
      search: '',
      locations: [],
      equipmentType: [],
    },
  });

  const { submit, resetForm } = useSubmitOnChange<IEquipmentFilter>(form, onFiltersChange);

  return (
    <FormProvider {...form}>
      <form onSubmit={submit} data-testid="filters-form">
        <div className={classes.filters}>
          <div className={classes.filterFields}>
            <div className={classes.fieldWrapper}>
              <FormField<IEquipmentFilter> fieldName="search">
                {(props) => (
                  <Input
                    className={classes.searchFilter}
                    label={content.SEARCH_LOCATION}
                    externalEndAdornment={{
                      endAdornment: (
                        <IconButton>
                          <SearchRoundedIcon color="action" />
                        </IconButton>
                      ),
                    }}
                    {...props}
                  />
                )}
              </FormField>
            </div>
            <div className={classes.fieldWrapper}>
              <LocationSearchWrapper>
                {(props) => (
                  <InfiniteAutocompleteControl<IEquipmentFilter>
                    fieldName="locations"
                    testId="location-multi-select"
                    label={content.LOCATION_LABEL}
                    className={classes.locationFilter}
                    {...props}
                  />
                )}
              </LocationSearchWrapper>
            </div>
            <div className={classes.fieldWrapper}>
              <EquipmentTypeControl<IEquipmentFilter> fieldName="equipmentType" />
            </div>
          </div>
          <div>
            <Button className={classes.clearAll} variant="outlined" onClick={() => resetForm()} data-testid="clearAll">
              {content.CLEAR_ALL}
            </Button>
          </div>
        </div>
        <div className={classes.locationChipsWrapper}>
          <FilterChipsContainer autoHide>
            <FieldFiltersChips<IEquipmentFilter, 'locations'>
              fieldName="locations"
              mapFieldToChips={(value) => value}
              deleteFilter={(id, values) => values.filter((value) => id !== value.id)}
            />
            <FieldFiltersChips<IEquipmentFilter, 'equipmentType'>
              fieldName="equipmentType"
              mapFieldToChips={(value) => value}
              deleteFilter={(id, values) => values.filter((value) => id !== value.id)}
            />
          </FilterChipsContainer>
        </div>
      </form>
    </FormProvider>
  );
};
