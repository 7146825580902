import { BehaviorSubject } from 'rxjs';

import { TDeviceUserMeasuresPreferences } from '../zod/uom.schema';

export class UomSettingsService {
  private uomSettings: BehaviorSubject<TDeviceUserMeasuresPreferences | null> =
    new BehaviorSubject<TDeviceUserMeasuresPreferences | null>(null);

  public getUomSettings = (): TDeviceUserMeasuresPreferences | null => this.uomSettings.value;

  public setUomSettings = (user: TDeviceUserMeasuresPreferences): void => {
    this.uomSettings.next(user);
  };
}

export const uomSettingsService = new UomSettingsService();
