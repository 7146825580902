import { content } from './content';

export enum ALERT_REPEATING_FREQUENCY {
  MINUTES_0 = 0,
  MINUTES_10 = 10,
  MINUTES_20 = 20,
  MINUTES_30 = 30,
  MINUTES_60 = 60,
  MINUTES_120 = 120,
  MINUTES_240 = 240,
}

export interface IAlertRepeatingFrequencyOption {
  label: string;
  value: ALERT_REPEATING_FREQUENCY;
}

export const defaultAlertRepeatingFrequencyOption: IAlertRepeatingFrequencyOption = {
  label: content.OPTION_IMMEDIATE_SUFFIX(ALERT_REPEATING_FREQUENCY.MINUTES_0),
  value: ALERT_REPEATING_FREQUENCY.MINUTES_0,
};

export const alertRepeatingFrequencyOptions: IAlertRepeatingFrequencyOption[] = [
  defaultAlertRepeatingFrequencyOption,
  {
    label: content.OPTION_SUFFIX(ALERT_REPEATING_FREQUENCY.MINUTES_10),
    value: ALERT_REPEATING_FREQUENCY.MINUTES_10,
  },
  {
    label: content.OPTION_SUFFIX(ALERT_REPEATING_FREQUENCY.MINUTES_20),
    value: ALERT_REPEATING_FREQUENCY.MINUTES_20,
  },
  {
    label: content.OPTION_SUFFIX(ALERT_REPEATING_FREQUENCY.MINUTES_30),
    value: ALERT_REPEATING_FREQUENCY.MINUTES_30,
  },
  {
    label: content.OPTION_SUFFIX(ALERT_REPEATING_FREQUENCY.MINUTES_60),
    value: ALERT_REPEATING_FREQUENCY.MINUTES_60,
  },
  {
    label: content.OPTION_SUFFIX(ALERT_REPEATING_FREQUENCY.MINUTES_120),
    value: ALERT_REPEATING_FREQUENCY.MINUTES_120,
  },
  {
    label: content.OPTION_SUFFIX(ALERT_REPEATING_FREQUENCY.MINUTES_240),
    value: ALERT_REPEATING_FREQUENCY.MINUTES_240,
  },
];
