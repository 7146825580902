import { TSideNavElement, isOption } from '../types';
import SideNavLink from './side-nav-link.component';
import SideNavSeparator from './side-nav-separator.component';

interface INavLinksProps {
  navLinks: TSideNavElement[];
  onClick?: () => void;
  isCollapsed: boolean;
  desktopNavbarLocked: boolean;
}

export const NavLinks = ({ navLinks, onClick, isCollapsed, desktopNavbarLocked }: INavLinksProps) => {
  return (
    <>
      {navLinks.map((nav, index) =>
        isOption(nav) ? (
          <SideNavLink
            key={nav.to}
            to={nav.to}
            Icon={nav.Icon}
            title={nav.title}
            Badge={nav.Badge}
            onClick={nav.onClick ? nav.onClick : onClick}
            isCollapsed={isCollapsed}
            desktopNavbarLocked={desktopNavbarLocked}
            endAdornment={nav.endAdornment}
            nestedOptions={nav.nestedOptions}
          />
        ) : (
          <SideNavSeparator key={nav.key} />
        )
      )}
    </>
  );
};
