import {
  AssetTypeResponseSchema,
  AssetTypeSchemaParser,
  DeviceTypeResponseSchema,
  DeviceTypeSchemaParser,
  NodeTypeResponseSchema,
  NodeTypeSchema,
  NodeTypeSchemaParser,
  UpdateAssetTypeSchema,
  UpdateNodeTypeSchema,
} from '@marlin/system-map/shared/data-access-schemas';
import { z } from 'zod';

import { ASSET_TYPE, NODE_TYPE, assetFilterTypeSchema } from './asset.model.schema';
import { elementTagsSchema } from './metadata-model.schema';

interface ITag {
  name: string;
  value?: string | null;
}

const tagSchema = z.object({
  name: z.string().nullish(),
  value: z.string().nullish(),
});

const flowLinkAssetSchema = z.object({
  id: z.string(),
  type: z.nativeEnum(ASSET_TYPE).optional(),
  tags: z.array(tagSchema).nullish(),
});

const createFlowLinkSchema = z.object({
  flowMapId: z.string().nullish(),
  inlet: flowLinkAssetSchema,
  outlet: flowLinkAssetSchema,
});

export const flowLinkSchema = z
  .object({
    id: z.string(),
    flowMapId: z.string().nullish(),
    inletNodeId: z.string().nullish(),
    inletNodeType: NodeTypeResponseSchema,
    inletAssetId: z.string().nullish(),
    inletAssetType: AssetTypeResponseSchema.nullish(),
    inletDeviceType: DeviceTypeResponseSchema.nullish(),
    inletAssetName: z.string().nullish(),
    outletNodeId: z.string().nullish(),
    outletNodeType: NodeTypeResponseSchema,
    outletAssetId: z.string().nullish(),
    outletAssetType: AssetTypeResponseSchema.nullish(),
    outletDeviceType: DeviceTypeResponseSchema.nullish(),
    outletAssetName: z.string().nullish(),
    isAttachment: z.boolean(),
    outletTags: z.array(elementTagsSchema).nullish(),
    inletTags: z.array(elementTagsSchema).nullish(),
    tags: z.array(elementTagsSchema).nullish(),
    inletLinkedId: z.string().nullish(),
    outletLinkedId: z.string().nullish(),
  })
  .transform((flowLink) => {
    const inletNodeType = NodeTypeSchemaParser.parse(flowLink.inletNodeType);
    const outletNodeType = NodeTypeSchemaParser.parse(flowLink.outletNodeType);
    const inletAssetType =
      flowLink.inletDeviceType === 'Equipment' ? 'EQUIPMENT' : AssetTypeSchemaParser.parse(flowLink.inletAssetType);
    const outletAssetType =
      flowLink.outletDeviceType === 'Equipment' ? 'EQUIPMENT' : AssetTypeSchemaParser.parse(flowLink.outletAssetType);
    const inletDeviceType = DeviceTypeSchemaParser.parse(flowLink.inletDeviceType);
    const outletDeviceType = DeviceTypeSchemaParser.parse(flowLink.outletDeviceType);

    return {
      ...flowLink,
      linkTags: flowLink.tags,
      inletNodeType,
      outletNodeType,
      inletAssetType,
      outletAssetType,
      inletDeviceType,
      outletDeviceType,
    };
  });

export const flowLinkFilterParamsSchema = z.object({
  flowMapId: z.string().nullish(),
  inletAssetId: z.string().optional(),
  inletNodeType: NodeTypeSchema.optional(),
  inletAssetType: z.string().optional(),
  inletAssetName: z.string().optional(),
  outletAssetId: z.string().optional(),
  outletNodeType: NodeTypeSchema.optional(),
  outletAssetType: z.string().optional(),
  outletAssetName: z.string().optional(),
  page: z.number().optional(),
  pageSize: z.number().optional(),
  sorting: z
    .object({
      direction: z.union([z.literal('Ascending'), z.literal('Descending')]),
      sortBy: z.string(),
    })
    .optional(),
});

export const paginationSchema = z.object({
  totalItems: z.number(),
  page: z.number(),
  pageSize: z.number(),
});

export const flowLinkListResponseDataSchema = z.object({
  pagination: paginationSchema,
  data: z.array(flowLinkSchema),
});

export const flowLinkTypeSchema = z.enum(['FlowLink', 'Attachement']);

export const updateFlowLinkParamsDataSchema = z.object({
  inletAssetId: z.string(),
  inletAssetType: z.nativeEnum(ASSET_TYPE).nullish(),
  inletNodeType: NodeTypeSchema.nullish(),
  outletAssetId: z.string(),
  outletAssetType: z.nativeEnum(ASSET_TYPE).nullish(),
  outletNodeType: NodeTypeSchema.nullish(),
  inletTags: z.array(elementTagsSchema).nullish(),
  outletTags: z.array(elementTagsSchema).nullish(),
  linkOptions: z
    .object({
      tags: z.array(elementTagsSchema).nullish(),
    })
    .nullish(),
});

export const updateSystemMapLinkParamsSchema = z.object({
  flowLinkId: z.string(),
  params: updateFlowLinkParamsDataSchema,
});

export const createFlowLinkDtoSchema = createFlowLinkSchema.transform(({ inlet, outlet, flowMapId }) => {
  return {
    flowMapId,
    inletAssetId: inlet.id,
    inletAssetType: assetFilterTypeSchema.parse(inlet.type),
    inletNodeType: 'Asset', // Note: link added from the form is always the type "Asset"
    outletAssetId: outlet.id,
    outletNodeType: 'Asset', // Note: link added from the form is always the type "Asset"
    outletAssetType: assetFilterTypeSchema.parse(outlet.type),
  };
});

export const createSystemMapLinkDtoSchema = createFlowLinkSchema.transform(({ inlet, outlet }) => {
  if (inlet.type !== 'EQUIPMENT') {
    return {
      inletData: {
        assetId: inlet.id,
        nodeType: 'Asset',
        assetType: assetFilterTypeSchema.parse(inlet.type),
      },
      outletData: {
        assetId: outlet.id,
        nodeType: 'Attachment',
        assetType: assetFilterTypeSchema.parse(outlet.type),
      },
    };
  }

  const isSmartEquipment = inlet.tags?.some((tag) => tag.name === 'Model' && tag.value !== undefined);

  const tags: ITag[] = [];
  const inletHandleTag = inlet.tags?.find((tag) => tag.name === 'HandleId');

  if (inletHandleTag) {
    tags.push({ name: 'InletHandleId', value: inletHandleTag.value });
  }

  return {
    inletData: {
      assetId: inlet.id,
      nodeType: 'Asset',
      assetType: isSmartEquipment ? 'Device' : assetFilterTypeSchema.parse(inlet.type),
      tags: inlet.tags,
    },
    outletData: {
      assetId: outlet.id,
      nodeType: 'Attachment',
      assetType: assetFilterTypeSchema.parse(outlet.type),
    },
    linkOptions: {
      tags,
    },
  };
});

export const createdFlowLinkSchema = z.string();

export const updateSystemMapLinkParamsDataSchema = z.object({
  inletData: z.object({
    nodeId: z.string().nullish(),
    nodeType: z.nativeEnum(NODE_TYPE).nullish(),
    assetId: z.string().nullish(),
    assetType: z.nativeEnum(ASSET_TYPE).nullish(),
    positionX: z.number().nullish(),
    positionY: z.number().nullish(),
    name: z.string().nullish(),
  }),
  outletData: z.object({
    nodeId: z.string().nullish(),
    nodeType: z.nativeEnum(NODE_TYPE).nullish(),
    assetId: z.string().nullish(),
    assetType: z.nativeEnum(ASSET_TYPE).nullish(),
    positionX: z.number().nullish(),
    positionY: z.number().nullish(),
    name: z.string().nullish(),
  }),
  linkOptions: z.object({
    inletName: z.string().nullish(),
    outletName: z.string().nullish(),
    linkName: z.string().nullish(),
  }),
});

export const updateSystemMapLinkDtoSchema = updateFlowLinkParamsDataSchema.transform((link) => {
  const hasInletHandleIdTags = link.inletTags?.some((tag) => tag.name === 'HandleId');
  const hasOutletHandleIdTags = link.outletTags?.some((tag) => tag.name === 'HandleId');

  const tags: ITag[] = [];
  const inletHandleTag = link.inletTags?.find((tag) => tag.name === 'HandleId');
  const outletHandleTag = link.outletTags?.find((tag) => tag.name === 'HandleId');

  if (inletHandleTag) {
    tags.push({ name: 'InletHandleId', value: inletHandleTag.value });
  }
  if (outletHandleTag) {
    tags.push({ name: 'OutletHandleId', value: outletHandleTag.value });
  }

  return {
    inletData: {
      assetId: link.inletAssetId,
      nodeType: UpdateNodeTypeSchema.parse(link.inletNodeType),
      assetType: hasInletHandleIdTags ? 'Device' : UpdateAssetTypeSchema.parse(link.inletAssetType),
      tags: link.inletTags,
    },
    outletData: {
      assetId: link.outletAssetId,
      nodeType: UpdateNodeTypeSchema.parse(link.outletNodeType),
      assetType: hasOutletHandleIdTags ? 'Device' : UpdateAssetTypeSchema.parse(link.outletAssetType),
      tags: link.outletTags,
    },
    linkOptions: {
      tags,
    },
  };
});

export type TFlowLinkAsset = z.infer<typeof flowLinkAssetSchema>;
export type TCreateFlowLink = z.infer<typeof createFlowLinkSchema>;
export type TCreateFlowLinkDto = z.infer<typeof createFlowLinkDtoSchema>;
export type TCreatedFlowLink = z.infer<typeof createdFlowLinkSchema>;
export type TFlowLink = z.infer<typeof flowLinkSchema>;
export type TFlowLinkFilterParams = z.infer<typeof flowLinkFilterParamsSchema>;
export type TPagination = z.infer<typeof paginationSchema>;
export type TFlowLinkListResponseSchema = z.infer<typeof flowLinkListResponseDataSchema>;
export type TFlowLinkType = z.infer<typeof flowLinkTypeSchema>;
export type TUpdateFlowLinkParamsData = z.infer<typeof updateFlowLinkParamsDataSchema>;
export type TUpdateSystemMapLinkParams = z.infer<typeof updateSystemMapLinkParamsSchema>;
