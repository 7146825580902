import { FilterOptionsState, createFilterOptions } from '@mui/material';
import { useMemo } from 'react';

import { IOption } from './autocomplete.component';

const isOptions = <T extends IOption>(item: T | undefined): item is T => !!item;

const mapValues = <T extends IOption>(options: T[], ids: string[]): T[] => {
  return ids.map((id): T | undefined => options?.find((option) => option.id === id)).filter(isOptions);
};

export const useFiltering = <T extends IOption>(
  options: T[],
  value: unknown,
  useObject?: boolean
): {
  filterOptions: (options: T[], state: FilterOptionsState<T>) => T[];
  values: T[];
} => {
  const filterOptions = useMemo(
    () =>
      createFilterOptions({
        stringify: (option: T) => option.name,
      }),
    []
  );

  const values = useMemo(
    () => (useObject ? (value as T[]) : mapValues(options, value as string[])),
    [options, useObject, value]
  );

  return {
    filterOptions,
    values: values || [],
  };
};
