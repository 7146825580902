import { TMutationConfig, getHttpClient, queryClient } from '@marlin/shared/utils/react-query';
import { UseMutationOptions, useMutation } from '@marlin/shared/utils/react-query';
import { TEquipment } from '@marlin/shared/utils/zod';

import { paths } from '../infrastructure/api';
import { OtherEquipmentPayload, TOtherEquipmentPayload } from '../schema/equipment.schema';
import { queryKey } from './query-key.enum';

type TCreateEquipmentType = {
  data: TOtherEquipmentPayload;
};

export const createEquipment = ({ data }: { data: TOtherEquipmentPayload }) => {
  const parsedData = OtherEquipmentPayload.parse(data);

  return getHttpClient().post(paths.EQUIPMENTS, parsedData);
};

type TUseCreateEquipmentOptions = {
  config?: TMutationConfig<typeof createEquipment>;
  onSuccess?: () => void;
};

export const useCreateEquipment = ({ config, onSuccess }: TUseCreateEquipmentOptions = {}) => {
  return useMutation<unknown, unknown, TCreateEquipmentType>({
    onMutate: async (newEquipment) => {
      await queryClient.cancelQueries({ queryKey: queryKey.EQUIPMENTS() });

      const previousEquipments = queryClient.getQueryData<TEquipment[]>(queryKey.EQUIPMENTS());

      queryClient.setQueryData(queryKey.EQUIPMENTS(), [
        ...(previousEquipments || []),
        { ...newEquipment.data, id: 'processing' },
      ]);

      return { previousEquipments };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.EQUIPMENTS() });
      onSuccess && onSuccess();
    },
    ...config,
    mutationFn: createEquipment,
  } as UseMutationOptions<unknown, unknown, TCreateEquipmentType>);
};
