import { EQUIPMENT_TAB_VALUES } from '@marlin/shared/utils-routes';
import { useQueryParameters } from '@marlin/shared/utils/url-params';
import { useMediaQuery, useTheme } from '@mui/material';

const tabUrlQueryParam = 'tab';

const mapParam = (param: string | null, isMobile: boolean): EQUIPMENT_TAB_VALUES => {
  switch (param) {
    case 'alerts':
      return EQUIPMENT_TAB_VALUES.ALERTS;
    case 'details':
      return EQUIPMENT_TAB_VALUES.DASHBOARD;
    case 'settings':
      return EQUIPMENT_TAB_VALUES.SETTINGS;
    case 'event-log':
      return EQUIPMENT_TAB_VALUES.EVENT_LOG;
    case 'historical-chart':
      return isMobile ? EQUIPMENT_TAB_VALUES.DASHBOARD : EQUIPMENT_TAB_VALUES.HISTORICAL_CHART;
    default: {
      return EQUIPMENT_TAB_VALUES.DASHBOARD;
    }
  }
};

export const useTab = (): EQUIPMENT_TAB_VALUES => {
  const queryParameters = useQueryParameters();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return mapParam(queryParameters.get(tabUrlQueryParam), isMobile);
};
