import { CommissionDateField, ICommissionDateFieldProps } from '@marlin/shared/ui-form';
import { FormField, Input, Select } from '@marlin/shared/ui-form-common';
import { EquipmentTypeEnum, TEquipment, TEquipmentType } from '@marlin/shared/utils/zod';
import { SelectChangeEvent } from '@mui/material';

import { content } from '../content';
import { useStyles } from '../upsert-equipment.styles';
import { useEquipmentTypeOptions } from '../use-equipment-type-options.hook';

interface IOtherEquipmentProps extends ICommissionDateFieldProps {}

export const OtherForm = ({ setDateValidationErrorMessage, dateValidationErrorMessage }: IOtherEquipmentProps) => {
  const { classes } = useStyles();

  const equipmentTypesOptions = useEquipmentTypeOptions().filter((option) => !option.shouldBeHiddenInOther);

  return (
    <>
      <div className={classes.formSection}>{content.ADDITIONAL_INFORMATION}</div>
      <FormField<TEquipment> fieldName="type">
        {(props) => (
          <Select
            {...props}
            prefix="type"
            emptyOption=" "
            value={[EquipmentTypeEnum.enum.NONE, ' '].includes(props.value) ? undefined : props.value}
            label={content.EQUIPMENT_TYPE}
            data={equipmentTypesOptions}
            className={classes.input}
            onChange={(e: SelectChangeEvent<TEquipmentType | ''>) => {
              if (!e.target.value) {
                props.onChange?.({ ...e, target: { ...e.target, value: EquipmentTypeEnum.enum.NONE } });

                return;
              }

              props.onChange?.(e);
            }}
          />
        )}
      </FormField>
      <FormField<TEquipment> fieldName="brand">
        {(props) => <Input className={classes.input} label={content.BRAND} {...props} testId="brand-field" />}
      </FormField>
      <FormField<TEquipment> fieldName="model">
        {(props) => <Input {...props} className={classes.input} label={content.MODEL} testId="model-field" />}
      </FormField>
      <FormField<TEquipment> fieldName="serialNumber">
        {(props) => (
          <Input className={classes.input} label={content.SERIAL_NUMBER} {...props} testId="serial-number-field" />
        )}
      </FormField>
      <CommissionDateField
        setDateValidationErrorMessage={setDateValidationErrorMessage}
        dateValidationErrorMessage={dateValidationErrorMessage}
      />
      <FormField<TEquipment> fieldName="description">
        {(props) => (
          <Input
            {...props}
            className={classes.input}
            label={content.DESCRIPTION}
            testId="description-field"
            maxLength={500}
            multiline
          />
        )}
      </FormField>
    </>
  );
};
