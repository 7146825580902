import { getHttpClient, useMutation } from '@marlin/shared/utils/react-query';

import { TCommand, TCommandPayload } from '../gateway.model.schema';
import { paths } from '../infrastructure/api';

export const resetOccupancy = async ({ params, data }: { params: TCommand; data: TCommandPayload }) => {
  const response = await getHttpClient().put(paths.COMMAND, data, { params });

  return response;
};

type TResetOccupancyQuery = {
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
};

export const useResetOccupancyQuery = ({ onSuccess, onError }: TResetOccupancyQuery) => {
  return useMutation({
    mutationFn: resetOccupancy,
    onSuccess,
    onError,
  });
};
