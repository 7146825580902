import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  buttonsSection: {
    display: 'flex',
    gap: theme.typography.pxToRem(16),
    marginTop: theme.typography.pxToRem(40),
  },
}));
