import { useObservableState, useSubscription } from 'observable-hooks';
import { MouseEvent, useRef } from 'react';
import { Subject, Subscription, timer } from 'rxjs';
import { filter } from 'rxjs/operators';

const showAccess$ = new Subject<boolean>();
const timer$ = timer(10000);
const isInputFocused$ = new Subject<boolean>();

export const useHideShowAccessCode = () => {
  const showHideButtonRef = useRef<HTMLButtonElement>(null);
  const [isInputFocused] = useObservableState(() => isInputFocused$, false);
  const [showAccessCode] = useObservableState(() => showAccess$, false);
  const timerRef = useRef<Subscription>();

  const handleClickShowAccessCode = (event: MouseEvent<HTMLButtonElement>) => {
    if (showAccessCode) {
      showAccess$.next(false);
      timerRef.current?.unsubscribe();
    } else {
      showAccess$.next(true);
      timerRef.current = timer$.subscribe({
        complete: () => {
          if (!isInputFocused) {
            showAccess$.next(false);
          }
        },
      });
    }

    event.preventDefault();
  };

  useSubscription(isInputFocused$.pipe(filter((isFocused) => isFocused)), () => {
    timerRef.current?.unsubscribe();
  });

  useSubscription(isInputFocused$.pipe(filter((isFocused) => !isFocused)), () => {
    if (showAccessCode) {
      timerRef.current = timer$.subscribe({
        complete: () => {
          showAccess$.next(false);
        },
      });
    }
  });

  return { showHideButtonRef, handleClickShowAccessCode, isInputFocused$, showAccessCode };
};
