import { MarlinTheme } from '@marlin/shared/theme';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { useTheme } from '@mui/material';

import { content } from './content';
import { ErrorPageLayout } from './page-layout.component';

interface ISystemConstraintsPage {
  onBackClick?: () => void;
  onlyContent?: boolean;
}

export const SystemConstraintsPage = ({ onBackClick, onlyContent = false }: ISystemConstraintsPage) => {
  const { goTo } = useRouter();
  const theme = useTheme<MarlinTheme>();
  const onClick = onBackClick ? onBackClick : () => goTo(routes.home.root);

  return (
    <ErrorPageLayout
      onBackClick={onClick}
      content={content.SYSTEM_CONSTRAINTS}
      onlyContent={onlyContent}
      errorIcon={theme.icon.systemErrorLogo}
    />
  );
};
