import { Paper } from '@marlin/shared/ui-page';
import { useRouter } from '@marlin/shared/utils-router';
import { DEVICE_DRAWER_ACTION_TYPE, useDeviceDrawerContext } from '@marlin/shared/utils/sensor-provider';
import { DataGrid } from '@mui/x-data-grid';
import { useCallback, useMemo } from 'react';

import { content } from '../../../content';
import { createColumns } from './columns';
import { Filters } from './filters.component';
import { useStyles } from './grid.component.styles';
import { useDesktopGridHook } from './use-desktop-grid.hook';
import { useLocationsDetailsContextMenu } from './use-location-details-context-menu.hook';

interface IGridDesktopProps {
  locationId: string;
}

export const rowsPerPageOptions = [5, 10, 50, 100];

export const GridDesktop = ({ locationId }: IGridDesktopProps) => {
  const { classes } = useStyles();
  const router = useRouter();
  const currentUrl = useMemo(() => router.getUrl(), [router]);
  const { deviceDrawerDispatch } = useDeviceDrawerContext();
  const { rows, pagination, isLoading, isError, changePage, changePageSize, onFiltersChange, onSortChange } =
    useDesktopGridHook(locationId);

  const { allowDelete, allowEdit, deleteAsset, editAsset } = useLocationsDetailsContextMenu();

  const deviceRows = useMemo(() => rows.filter((row) => row.type !== 'EQUIPMENT'), [rows]);

  const addSensorsFromContext = useCallback(() => {
    deviceDrawerDispatch({
      type: DEVICE_DRAWER_ACTION_TYPE.SET_SENSORS,
      payload: {
        rows: deviceRows,
      },
    });
  }, [deviceRows, deviceDrawerDispatch]);

  const columns = useMemo(
    () =>
      createColumns({
        allowDelete,
        allowEdit,
        editAsset,
        deleteAsset,
        currentUrl,
        addSensorsFromContext,
      }),
    [addSensorsFromContext, allowDelete, allowEdit, currentUrl, deleteAsset, editAsset]
  );

  return (
    <Paper className={classes.tableWrapper} data-testid="card">
      <Filters locationId={locationId} onFiltersChange={onFiltersChange} />
      <div className={classes.dataGridWrapper} data-testid="grid-table-wrapper">
        <DataGrid
          className={classes.dataGrid}
          localeText={{
            noRowsLabel: isError ? content.ERROR : content.NO_DATA,
          }}
          loading={isLoading}
          autoHeight
          rows={rows}
          columns={columns}
          columnVisibilityModel={{
            action: allowEdit || allowDelete,
          }}
          rowsPerPageOptions={rowsPerPageOptions}
          page={pagination.page}
          pageSize={pagination.pageSize}
          rowCount={pagination.rowCount}
          onPageChange={changePage}
          onPageSizeChange={changePageSize}
          paginationMode="server"
          onSortModelChange={onSortChange}
          disableColumnMenu
        />
      </div>
    </Paper>
  );
};
