import { getLogger } from '@marlin/shared/utils/logger';
import { queryClient, useMutation } from '@marlin/shared/utils/react-query';

import { createAutomation } from '../infrastructure/automation';
import { queryKey } from './query-key.enum';

export const useCreateAutomation = () => {
  return useMutation({
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: queryKey.AUTOMATIONS() });
    },
    onSuccess: () => {
      getLogger()?.track('CreateAutomationSuccess');
      queryClient.invalidateQueries({ queryKey: queryKey.AUTOMATIONS() });
    },
    mutationFn: createAutomation,
  });
};
