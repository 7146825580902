import { environment } from '@marlin/environment';
import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';
import { AxiosError } from 'axios';

import { IUserResponse } from '../user.model';
import { getStoredOrgId } from '../utils/organization.utils';
import { paths } from './api';
import {
  OrganizationResponseSchema,
  TOrganizationByIdParams,
  TOrganizationListParams,
  TOrganizationResponse,
  organizationByIdParamsSchema,
} from './organization.schema';

export const getOrganizationList = async (params?: TOrganizationListParams) => {
  // TODO: Switch to paginated endpoint on creating and editing portfolio
  const res = await getHttpClient().get<unknown, IUserResponse>(paths.ORGANIZATION, { params });

  return OrganizationResponseSchema.parse(res);
};

export const getOrganizationById = async (requestParams: TOrganizationByIdParams) => {
  const params = safeParseData(requestParams, organizationByIdParamsSchema);

  const res = await getHttpClient().get<unknown, IUserResponse>(paths.ORGANIZATION_BY_ID, { params });

  return safeParseData(res, OrganizationResponseSchema);
};

export const getDefaultOrganization = async () => {
  const res = await getHttpClient().get(paths.DEFAULT_ORGANIZATION);

  return safeParseData(res, OrganizationResponseSchema);
};

export const getCurrentUserOrganization = async () => {
  let org: TOrganizationResponse | undefined;

  const organizationId = getStoredOrgId();

  if (organizationId) {
    try {
      org = await getOrganizationById({ organizationId });
    } catch (error: unknown) {
      if (environment.module.automationHelper.extendedQaLogging) {
        // eslint-disable-next-line no-console
        console.log(
          `[Activity Log of getCurrentUserOrganization]\n`,
          'cannot get organization by stored id',
          organizationId,
          '\nerror status: ',
          JSON.stringify((error as AxiosError).response?.status),
          '\nerror message: ',
          JSON.stringify((error as AxiosError).message)
        );
      }
    }
  }

  if (!org || !organizationId) {
    org = await getDefaultOrganization();
  }

  return org;
};
