import { MarlinTheme } from '@marlin/shared/theme';
import { FC, PropsWithChildren } from 'react';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    margin: theme.typography.pxToRem(32),
    [theme.breakpoints.down('md')]: {
      margin: theme.typography.pxToRem(0),
      display: 'flex',
      flexDirection: 'column',
    },
  },
}));

interface IPageContainerProps {
  prefix?: string;
  className?: string;
}

export const PageContainer: FC<PropsWithChildren<IPageContainerProps>> = ({ prefix, className, children }) => {
  const { classes, cx } = useStyles();

  if (prefix) {
    return (
      <div data-testid={`${prefix}-container`} className={cx(classes.container, className)}>
        {children}
      </div>
    );
  }

  return <div className={cx(classes.container, className)}>{children}</div>;
};
