import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import { paths } from './api';
import { TInvitationsData, invitationsDataSchema, invitationsResponseDataSchema } from './invitations.schema';

export const inviteUsers = async (data: TInvitationsData) => {
  const body = safeParseData(data, invitationsDataSchema);
  const response = await getHttpClient().post(paths.INVITATIONS, body);
  return safeParseData(response, invitationsResponseDataSchema);
};
