import { MarlinTheme } from '@marlin/shared/theme';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { Typography } from '@mui/material';
import { FC, PropsWithChildren, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { CRITICALITY, ICriticality } from './models';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  currentReading: {
    fontWeight: theme.typography.fontWeightBold,
  },

  readingWithHighAlert: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.error.main,
  },

  readingWithLowAlert: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.warning.main,
  },
}));

interface IDeviceReadingCriticalityProps {
  criticalities: ICriticality[];
  tooltipContent: string;
}

const ReadingCriticality: FC<PropsWithChildren<IDeviceReadingCriticalityProps>> = ({
  criticalities,
  tooltipContent,
  children,
}) => {
  const { classes } = useStyles();

  const deviceHasHighAlerts = useMemo(
    () => criticalities.find((criticality: ICriticality) => criticality.criticality === CRITICALITY.HIGH),
    [criticalities]
  );

  const deviceHasLowAlerts = useMemo(
    () => criticalities.find((criticality: ICriticality) => criticality.criticality === CRITICALITY.LOW),
    [criticalities]
  );

  const deviceClass = useMemo(() => {
    if (deviceHasHighAlerts) {
      return classes.readingWithHighAlert;
    } else if (deviceHasLowAlerts) {
      return classes.readingWithLowAlert;
    }
    return classes.currentReading;
  }, [
    classes.currentReading,
    classes.readingWithHighAlert,
    classes.readingWithLowAlert,
    deviceHasHighAlerts,
    deviceHasLowAlerts,
  ]);

  return deviceHasLowAlerts || deviceHasHighAlerts ? (
    <Tooltip text={tooltipContent}>
      <Typography className={deviceClass} variant="body2">
        {children}
      </Typography>
    </Tooltip>
  ) : (
    <Typography className={deviceClass} variant="body2">
      {children}
    </Typography>
  );
};

export { ReadingCriticality };
