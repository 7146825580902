const recipients = '/api/recipients';
const recipient = `${recipients}/{recipientId}`;
const searchableRecipients = `${recipients}/filter`;

export const paths = {
  RECIPIENT: recipient,
  RECIPIENTS: recipients,
  RECIPIENTS_FILTER: searchableRecipients,
  RECIPIENTS_SEARCH: '/api/recipients/search',
  RECIPIENTS_LIST: '/api/recipients/list',
};
