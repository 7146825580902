import { createContext, useContext } from '@marlin/shared/utils-component-context';
import { TDatapointsResponse } from '@marlin/shared/utils/datapoint-mappers';
import { PropsWithChildren, useCallback, useState } from 'react';

interface IEquipmentsTelemetryContext {
  equipmentsTelemetry: Record<string, TDatapointsResponse>;
  handleTelemetry: (data: TDatapointsResponse) => void;
}

const EquipmentsTelemetryContext = createContext<IEquipmentsTelemetryContext>();

export const EquipmentsTelemetryContextProvider = ({ children }: PropsWithChildren) => {
  const [equipmentsTelemetry, setEquipmentsTelemetry] = useState<IEquipmentsTelemetryContext['equipmentsTelemetry']>(
    {} as IEquipmentsTelemetryContext['equipmentsTelemetry']
  );

  const handleTelemetry = useCallback((data: TDatapointsResponse) => {
    setEquipmentsTelemetry((prev) => ({ ...prev, [data.manufacturerId]: data }));
  }, []);

  return (
    <EquipmentsTelemetryContext.Provider value={{ equipmentsTelemetry, handleTelemetry }}>
      {children}
    </EquipmentsTelemetryContext.Provider>
  );
};

export const useEquipmentsTelemetryContext = () => useContext(EquipmentsTelemetryContext);
