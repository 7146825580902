import { ONBOARDING_TYPE, isOnboardingType } from '@marlin/shared/utils-routes';
import { getLogger } from '@marlin/shared/utils/logger';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import {
  AlreadyRegisteredError,
  LocationError,
  OrganizationCreationError,
  RegistrationDataError,
  RegistrationFailedError,
} from '../components/errors';
import {
  IRegistrationFailedErrorProps,
  InvitationFailedError,
} from '../components/errors/invitation-failed-error.component';
import { InvitationsComplete } from '../components/invitations-complete.component';
import { InvitationsSent } from '../components/invitations-sent.component';
import { SetupComplete } from '../components/setup-complete.component';
import { InviteUsers } from './steps/invite-users/invite-users.component';
import { RegisterEquipment } from './steps/register-equipment/register-equipment.component';
import { SetupOrganization } from './steps/setup-organization/setup-organization.component';
import { LoggerTextMapper, ONBOARDING_STEP } from './steps/steps';

const errorSteps = [
  ONBOARDING_STEP.REGISTRATION_FAILED_ERROR,
  ONBOARDING_STEP.REGISTRATION_DATA_ERROR,
  ONBOARDING_STEP.LOCATION_ERROR,
  ONBOARDING_STEP.ALREADY_REGISTERED_ERROR,
  ONBOARDING_STEP.ORGANIZATION_CREATION_ERROR,
  ONBOARDING_STEP.INVITATION_FAILED_ERROR,
];

const isErrorStep = (step: ONBOARDING_STEP) => {
  return errorSteps.includes(step);
};

export interface IOnboardingWizard {
  activeStep: ONBOARDING_STEP;
  completedSteps: ONBOARDING_STEP[];
  currentStep: ReactNode;
  ErrorStep: (({ handleButtonClick }: IRegistrationFailedErrorProps) => ReactNode) | null;
  handleButtonClick: () => void;
  isShowingErrorStep: boolean;
  onboardingType: ONBOARDING_TYPE | null;
}

export const useOnboardingWizard = (): IOnboardingWizard => {
  const [activeStep, setActiveStep] = useState<ONBOARDING_STEP>(ONBOARDING_STEP.SETUP_ORGANIZATION);
  const [completedSteps, setCompletedSteps] = useState<ONBOARDING_STEP[]>([]);

  const { search } = useLocation();

  const onboardingType: ONBOARDING_TYPE | null = useMemo(() => {
    const type = new URLSearchParams(search).get('type');
    if (isOnboardingType(type)) {
      return type;
    }
    return null;
  }, [search]);

  const handleNextStep = useCallback((step: ONBOARDING_STEP, completeSteps?: ONBOARDING_STEP[]) => {
    setActiveStep(step);
    if (completeSteps) {
      setCompletedSteps(completeSteps);
    }
  }, []);

  const currentStep = useMemo(() => {
    if (!isErrorStep(activeStep)) {
      getLogger()?.track(LoggerTextMapper[activeStep]);
    }

    switch (activeStep) {
      case ONBOARDING_STEP.SETUP_ORGANIZATION:
        return <SetupOrganization onNextStep={handleNextStep} onboardingType={onboardingType} />;
      case ONBOARDING_STEP.REGISTER_EQUIPMENT:
        return <RegisterEquipment onNextStep={handleNextStep} />;
      case ONBOARDING_STEP.INVITE_USERS:
        return <InviteUsers onNextStep={handleNextStep} />;
      case ONBOARDING_STEP.SETUP_COMPLETE:
        return <SetupComplete onNextStep={handleNextStep} />;
      case ONBOARDING_STEP.INVITATIONS_SENT:
        return <InvitationsSent />;
      case ONBOARDING_STEP.INVITATIONS_COMPLETED:
        return <InvitationsComplete />;
      default:
        return null;
    }
  }, [activeStep, handleNextStep, onboardingType]);

  const ErrorStep = useMemo(() => {
    if (isErrorStep(activeStep)) {
      getLogger()?.track(LoggerTextMapper[activeStep]);
    }
    if (!isErrorStep(activeStep)) {
      return null;
    }
    switch (activeStep) {
      case ONBOARDING_STEP.REGISTRATION_FAILED_ERROR: {
        return RegistrationFailedError;
      }
      case ONBOARDING_STEP.ALREADY_REGISTERED_ERROR: {
        return AlreadyRegisteredError;
      }
      case ONBOARDING_STEP.REGISTRATION_DATA_ERROR: {
        return RegistrationDataError;
      }
      case ONBOARDING_STEP.LOCATION_ERROR: {
        return LocationError;
      }
      case ONBOARDING_STEP.ORGANIZATION_CREATION_ERROR: {
        return OrganizationCreationError;
      }
      case ONBOARDING_STEP.INVITATION_FAILED_ERROR: {
        return InvitationFailedError;
      }
      default: {
        return null;
      }
    }
  }, [activeStep]);

  const handleButtonClick = useCallback(() => {
    handleNextStep(completedSteps[completedSteps.length - 1], completedSteps.slice(0, -1));
  }, [completedSteps, handleNextStep]);

  const isShowingErrorStep = useMemo(() => isErrorStep(activeStep), [activeStep]);

  return {
    activeStep,
    completedSteps,
    currentStep,
    ErrorStep,
    handleButtonClick,
    isShowingErrorStep,
    onboardingType,
  };
};
