import { MarlinTheme } from '@marlin/shared/theme';
import { alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.primary.contrastText,
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
    borderRadius: theme.typography.pxToRem(4),
  },

  equipmentWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.typography.pxToRem(16),
  },

  equipmentRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },

  warningRow: {
    backgroundColor: alpha(theme.palette.warning.main, 0.12),
  },
  errorRow: {
    backgroundColor: alpha(theme.palette.error.main, 0.12),
  },
  equipmentIcon: {
    width: theme.typography.pxToRem(24),
    marginRight: theme.typography.pxToRem(4),
  },

  equipmentNameWrapper: {
    display: 'flex',
    alignItems: 'center',
  },

  equipmentName: {
    cursor: 'pointer',
    fontWeight: theme.typography.fontWeightBold,
  },
  dotLabel: {
    padding: 0,
  },
}));
