import { useCurrentUser } from '@marlin/account-data-access-organization';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import {
  TFlowLoopNotification,
  TSystemMapUpdate,
  useFlowLoopSubscription,
  useSystemMapNotification,
} from '@marlin/system-map/data-access/system-map';
import { useReactFlow } from '@xyflow/react';
import { useCallback, useEffect } from 'react';

export const useRealtimeData = (isEditMode: boolean) => {
  const notification = useSystemMapNotification();
  const reactFlow = useReactFlow();
  const { data: user } = useCurrentUser();
  const { enqueueSnackbar } = useSnackbar();

  const showLoopNotificationSnackbar = useCallback(
    (loopNotification: TFlowLoopNotification | undefined) => {
      if (loopNotification && user?.id === loopNotification?.userId) {
        const cycleNode = loopNotification.cyclicPath.find(({ nodeType }) => nodeType !== 'TEE');

        if (cycleNode) {
          enqueueSnackbar('', {
            variant: 'loopSnackbar',
            key: `loopSnackbar-${cycleNode?.id}`,
            cycleNode,
            preventDuplicate: true,
          });
        }
      }
    },
    [enqueueSnackbar, user?.id]
  );

  useFlowLoopSubscription(showLoopNotificationSnackbar);

  const updateNode = useCallback(
    (updatedNode: TSystemMapUpdate) => {
      reactFlow.setNodes((nds) =>
        nds.map((node) => {
          if (node.id === updatedNode.nodeId) {
            return {
              ...node,
              data: {
                ...node.data,
                reading: updatedNode.data.reading,
                lastReading: updatedNode.data.lastReading,
                lastReadingTime: updatedNode.data.lastReadingTime,
                alerts: updatedNode.data.alerts,
                lastReadingValues: updatedNode.data.lastReadingValues,
              },
            };
          }

          return node;
        })
      );
    },
    [reactFlow]
  );

  useEffect(() => {
    if (reactFlow && notification && !isEditMode) {
      notification?.nodes.forEach(updateNode);
    }
  }, [notification, updateNode, reactFlow, isEditMode]);
};
