import { ILocation, useCreateLocation } from '@marlin/asset/data-access/location';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { routes } from '@marlin/shared/utils-routes';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { content } from './content';

export interface INewLocation {
  successfullyUpdatedLocation: boolean;
  loadingCreatedLocation: boolean;
  nextRoute: string;
  onSubmit: (data: ILocation) => void;
  goToNextRoute: () => void;
}

export const useNewLocation = (): INewLocation => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const nextRoute = routes.locations.list.url();
  const onSuccess = () => {
    enqueueSnackbar(content.CONFIRMATION_MESSAGE_CREATE_LOCATION_SUCCESS, {
      variant: 'success',
      preventDuplicate: true,
    });
  };
  const onError = () => {
    enqueueSnackbar(content.CONFIRMATION_MESSAGE_CREATE_LOCATION_ERROR, { variant: 'error', preventDuplicate: true });
  };
  const createLocationMutation = useCreateLocation({ onError, onSuccess });

  const onSubmit = useCallback(
    (data: ILocation) => {
      return createLocationMutation.mutateAsync({ data: { ...data, parentLocationId: data.parentLocation?.id } });
    },
    [createLocationMutation]
  );

  const successfullyUpdatedLocation = useMemo(
    () => createLocationMutation.isSuccess,
    [createLocationMutation.isSuccess]
  );
  const loadingCreatedLocation = useMemo(() => createLocationMutation.isLoading, [createLocationMutation.isLoading]);

  const goToNextRoute = useCallback(() => {
    navigate(nextRoute);
  }, [navigate, nextRoute]);

  return {
    successfullyUpdatedLocation,
    loadingCreatedLocation,
    onSubmit,
    nextRoute,
    goToNextRoute,
  };
};
