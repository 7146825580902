import { TWebhookSyncFrequency, TWebhookType } from '@marlin/account-data-access-webhook';

import { content } from './content';

export const formatWebhookType = (type: TWebhookType): string => {
  switch (type) {
    case 'AggregatedDeviceVolume': {
      return content.WEBHOOK_TYPE.AGGREGATED_DEVICE_VOLUME;
    }
    case 'AggregatedOrganizationVolume':
    default: {
      return content.WEBHOOK_TYPE.AGGREGATED_ORGANIZATION_VOLUME;
    }
  }
};

export const formatFrequency = (frequency: TWebhookSyncFrequency): string => {
  switch (frequency) {
    case 'Hourly': {
      return content.FREQUENCY.HOURLY;
    }
    case 'Daily': {
      return content.FREQUENCY.DAILY;
    }
    case 'Weekly': {
      return content.FREQUENCY.WEEKLY;
    }
    case 'FourHours': {
      return content.FREQUENCY.FOUR_HOURS;
    }
    case 'EightHours': {
      return content.FREQUENCY.EIGHT_HOURS;
    }
    case 'Realtime':
    default: {
      return content.FREQUENCY.REALTIME;
    }
  }
};
