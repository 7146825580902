import { TExtractFnReturnType, useQuery } from '@marlin/shared/utils/react-query';

import { getWaterTrendsPerPeriod } from '../infrastructure/get-water-trends';
import { TWaterTrendsParams } from '../infrastructure/schema/water-trends.schema';
import { queryKey } from './query-key.enum';

interface IWaterTrends {
  enabled: boolean;
  params: TWaterTrendsParams;
}

export const useWaterTrends = ({ enabled, params }: IWaterTrends) => {
  return useQuery<TExtractFnReturnType<typeof getWaterTrendsPerPeriod>>({
    queryKey: queryKey.WATER_TRENDS(params),
    queryFn: () => getWaterTrendsPerPeriod(params),
    enabled,
  });
};
