import { TAutomationBuilder } from '@marlin/alert/data-access/automated-action';
import { CriticalityControl } from '@marlin/shared/ui-criticality';
import { Paper } from '@marlin/shared/ui-page';
import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { AlertIntervalSection } from './alert-interval-section';
import { Channels } from './channels.component';
import { content } from './content';
import { DetailsItem } from './details-item.component';
import { CardHeaderBuilder } from './header-builder.component';
import { HeaderId } from './header-id.component';

export const useStyles = makeStyles()(() => ({
  disabled: {
    opacity: 0.6,
    filter: 'gray',
    '-webkit-filter': 'grayscale(100%)',
    '-moz-filter': 'grayscale(100%)',
    '-ms-filter': 'grayscale(100%)',
    '-o-filter': 'grayscale(100%)',
  },
}));

interface IAutomationCardProps extends Record<string, unknown> {
  item: TAutomationBuilder;
}

export function AutomationCardBuilder({ item }: IAutomationCardProps) {
  const { classes, cx } = useStyles();

  const title = item.name;

  return (
    <Grid item xs={12}>
      <Paper testId="card">
        <CardHeaderBuilder title={title} enabled={true} />
        <Grid container className={cx({ [classes.disabled]: !item.isEnabled })}>
          <Grid item xs={6}>
            <DetailsItem
              label={content.CARD_LABELS.ACTION}
              value={<Channels channels={item.channels} />}
              testId="automation-actions"
            />
            <DetailsItem
              label={content.ALERTS}
              testId="automation-alert-interval"
              value={
                <AlertIntervalSection automationMode={true} snoozeIntervalInMinutes={item.snoozeIntervalInMinutes} />
              }
            />
          </Grid>
          <Grid item xs={6}>
            <DetailsItem
              label={content.CARD_LABELS.CRITICALITY}
              value={<CriticalityControl severity={item.criticality} />}
              testId="automation-criticality"
            />
            <HeaderId id={item.id} data-testid="card-id" />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
