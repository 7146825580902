import { MarlinTheme } from '@marlin/shared/theme';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Accordion, AccordionDetails, AccordionSummary, Divider, alpha } from '@mui/material';
import { PropsWithChildren, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  accordion: {
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
    borderTop: 0,
    backgroundColor: alpha(theme.palette.primary.main, 0.04),
    '&.Mui-expanded': {
      margin: 0,
    },
    '&:before': {
      display: 'none',
    },
  },
  content: {
    fontSize: theme.typography.body2.fontSize,
    paddingTop: 0,
  },
  subheader: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightBold,
  },
  mainDivider: {
    marginBottom: theme.typography.pxToRem(12),
  },
}));

export const Details = ({ children }: PropsWithChildren) => {
  const { classes } = useStyles();

  const [expanded, setExpanded] = useState(false);

  const onAccordionChange = (_: unknown, newExpanded: boolean) => {
    setExpanded(newExpanded);
  };

  return (
    <Accordion
      elevation={0}
      className={classes.accordion}
      expanded={expanded}
      onChange={onAccordionChange}
      data-testid="details-accordion"
    >
      <AccordionSummary
        expandIcon={expanded ? <CloseRoundedIcon /> : <ExpandMoreRoundedIcon />}
        data-testid="details-accordion-summary"
      >
        <div className={classes.subheader} data-testid="details-accordion-summary-subheader">
          {content.TITLE}
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.content} data-testid="details-accordion-content">
        <Divider className={classes.mainDivider} />
        {children}
      </AccordionDetails>
    </Accordion>
  );
};
