import { useMultiChartsStore } from '@marlin/asset/ui/charts';
import { useMemo } from 'react';

import { getFilteredLocationData, parseOptions } from '../utils/parse-options';
import { useSensorFiltering } from './use-sensor-filtering.hook';

export const useOptions = () => {
  const { selectedEquipmentId, selectedLocationId, locationData, equipmentData } = useSensorFiltering();

  const [metadata] = useMultiChartsStore((store) => store.metadata);

  const filteredLocationsData = useMemo(
    () =>
      getFilteredLocationData({
        equipmentData,
        locationData,
        selectedLocationId,
        selectedEquipmentId,
      }),
    [equipmentData, locationData, selectedLocationId, selectedEquipmentId]
  );

  const options = useMemo(() => parseOptions(filteredLocationsData, metadata), [filteredLocationsData, metadata]);

  return { options };
};
