import {
  aercoImageStep1,
  aercoImageStep2,
  intellistationImageStep1,
  intellistationImageStep2,
  intellistationImageStep3,
  intellistationImageStep4,
} from '@marlin/account/shared/assets';
import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../../content';
import { EQUIPMENT_ENUM, EQUIPMENT_STATUS, TEquipment } from '../../../onboarding.schema';

export const defaultEquipmentValues: TEquipment = {
  registrationFlow: 'Tekmar',
  equipmentName: null,
  registrationCode: null,
  serialNumber: null,
  status: EQUIPMENT_STATUS.NEW,
  equipmentType: EQUIPMENT_ENUM.INTELLISTATION,
};

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  editContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: theme.typography.pxToRem(32),
  },
  inputsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(32),
    [theme.breakpoints.down('md')]: {
      gap: theme.typography.pxToRem(16),
    },
  },
  input: {
    backgroundColor: theme.palette.background.primary,
    '&> .MuiFormHelperText-root': {
      paddingLeft: theme.typography.pxToRem(5),
      marginLeft: 0,
      marginRight: 0,
      backgroundColor: theme.palette.background.secondary,
    },
  },
  button: {
    width: theme.typography.pxToRem(243),
    height: theme.typography.pxToRem(42),
    fontSize: theme.typography.pxToRem(15),
    alignSelf: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.typography.pxToRem(16),
    },
  },
  cancelButton: {
    marginRight: theme.typography.pxToRem(16),
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
    },
  },
  formContainer: {
    width: '100%',
  },
  icon: {
    marginLeft: theme.typography.pxToRem(8),
    marginRight: theme.typography.pxToRem(8),
  },
  smallIcon: {
    width: theme.typography.pxToRem(18),
    height: theme.typography.pxToRem(18),
  },
  link: {
    marginTop: theme.typography.pxToRem(3),
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
    width: '100%',
    display: 'block',
    textAlign: 'end',
    cursor: 'pointer',
  },
  buttonContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column-reverse',
      gap: theme.typography.pxToRem(16),
    },
  },
}));

export interface IEquipmentFormProps {
  resetSwitcher: () => void;
  openInstructionsModal: (defaultStep?: number) => void;
  isEditForm?: boolean;
  onCancel?: () => void;
  isAnotherEquipment?: boolean;
  equipment?: TEquipment | null;
  index: number;
}

export const intellistationDescriptionSteps = [
  {
    imageSrc: intellistationImageStep1,
    description: content.INSTRUCTIONS_MODAL.INTELLISTATION.STEP_1,
    descriptionKeyword: content.INSTRUCTIONS_MODAL.INTELLISTATION.STEP_1_KEYWORD,
    title: content.INSTRUCTIONS_MODAL.INTELLISTATION.TITLE,
  },
  {
    imageSrc: intellistationImageStep2,
    description: content.INSTRUCTIONS_MODAL.INTELLISTATION.STEP_2,
    descriptionKeyword: content.INSTRUCTIONS_MODAL.INTELLISTATION.STEP_2_KEYWORD,
    title: content.INSTRUCTIONS_MODAL.INTELLISTATION.TITLE,
  },
  {
    imageSrc: intellistationImageStep3,
    description: content.INSTRUCTIONS_MODAL.INTELLISTATION.STEP_3,
    descriptionKeyword: content.INSTRUCTIONS_MODAL.INTELLISTATION.STEP_3_KEYWORD,
    title: content.INSTRUCTIONS_MODAL.INTELLISTATION.TITLE,
  },
  {
    imageSrc: intellistationImageStep4,
    description: content.INSTRUCTIONS_MODAL.INTELLISTATION.STEP_4,
    descriptionKeyword: content.INSTRUCTIONS_MODAL.INTELLISTATION.STEP_4_KEYWORD,
    title: content.INSTRUCTIONS_MODAL.INTELLISTATION.TITLE,
  },
];

export const aercoDescriptionSteps = [
  {
    imageSrc: aercoImageStep1,
    description: content.INSTRUCTIONS_MODAL.AERCO.STEP_1,
    descriptionKeyword: content.INSTRUCTIONS_MODAL.AERCO.STEP_1_KEYWORD,
    title: content.INSTRUCTIONS_MODAL.AERCO.TITLE_STEP_1,
  },
  {
    imageSrc: aercoImageStep2,
    description: content.INSTRUCTIONS_MODAL.AERCO.STEP_2,
    descriptionKeyword: content.INSTRUCTIONS_MODAL.AERCO.STEP_2_KEYWORD,
    title: content.INSTRUCTIONS_MODAL.AERCO.TITLE_STEP_2,
  },
];
