// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  DefinedUseQueryResult,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
  useQuery as useBaseQuery,
} from '@tanstack/react-query';
import { useEffect } from 'react';

export function useQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  options: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'initialData'> & {
    initialData?: () => undefined;
  }
): UseQueryResult<TData, TError>;

export function useQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  options: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'initialData'> & {
    initialData: TQueryFnData | (() => TQueryFnData);
  }
): DefinedUseQueryResult<TData, TError>;

export function useQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(options: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>): UseQueryResult<TData, TError>;

export function useQuery<TQueryFnData, TError, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>({
  onSuccess,
  onError,
  ...arg1
}: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>): UseQueryResult<TData, TError> {
  const queryResult = useBaseQuery<TQueryFnData, TError, TData, TQueryKey>(arg1);
  useEffect(() => {
    if (queryResult.isSuccess) {
      onSuccess && onSuccess(queryResult.data);
    }
  }, [onSuccess, queryResult.data, queryResult.isSuccess]);

  useEffect(() => {
    if (queryResult.isError) {
      onError && onError(queryResult.error);
    }
  }, [onError, queryResult.error, queryResult.isError]);

  return queryResult;
}
