import { TWebhookForm } from '@marlin/account-ui-webhook-upsert-form';
import { useDefaultFlowMeters, useFilteredDevicesWithPaging } from '@marlin/asset/data-access/device';
import { useMemo } from 'react';

interface IUseWebhookFlowMeters {
  selectedDefaultFlowMeters: TWebhookForm['devices'];
  selectedAllFlowMeters: TWebhookForm['devices'];
  isFlowMetersLoading: boolean;
  isFlowMetersError: boolean;
}

export const useWebhookFlowMeters = (): IUseWebhookFlowMeters => {
  const {
    data: defaultFlowMeters,
    isLoading: isDefaultFlowMetersLoading,
    isError: isDefaultFlowMetersError,
  } = useDefaultFlowMeters();
  const {
    data: allFlowMeters,
    isLoading: isAllFlowMetersLoading,
    isError: isAllFlowMetersError,
  } = useFilteredDevicesWithPaging({
    params: {
      page: 1,
      pageSize: 100,
      deviceTypes: ['PULSE_METER'],
    },
  });

  const selectedDefaultFlowMeters: TWebhookForm['devices'] = useMemo(() => {
    return (defaultFlowMeters || []).map((device) => ({
      deviceId: device.deviceId || '',
      name: device.deviceName || '',
      datapointName: 'FlowVolume',
    }));
  }, [defaultFlowMeters]);

  const selectedAllFlowMeters: TWebhookForm['devices'] = useMemo(() => {
    return (allFlowMeters?.data || [])
      .filter((device) => device.deviceType === 'PULSE_METER')
      .map((device) => ({
        deviceId: device.id || '',
        name: device.name || '',
        datapointName: 'FlowVolume',
      }));
  }, [allFlowMeters]);

  return useMemo(() => {
    return {
      selectedDefaultFlowMeters,
      selectedAllFlowMeters,
      isFlowMetersLoading: isDefaultFlowMetersLoading || isAllFlowMetersLoading,
      isFlowMetersError: isDefaultFlowMetersError || isAllFlowMetersError,
    };
  }, [
    selectedDefaultFlowMeters,
    selectedAllFlowMeters,
    isDefaultFlowMetersLoading,
    isAllFlowMetersLoading,
    isDefaultFlowMetersError,
    isAllFlowMetersError,
  ]);
};
