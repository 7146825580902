import { IChartSeries } from '@marlin/shared/utils-chart';
import orderBy from 'lodash/orderBy';
import partition from 'lodash/partition';

import { IExtendedIChartSeries } from '../types';

export const sortData = (data: IExtendedIChartSeries[]): IChartSeries[] => {
  const [sensorsInOrder, equipmentDatapointsInOrder] = partition(
    orderBy(data, 'name'),
    ({ parentType }) => parentType === 'sensor'
  );

  return [...sensorsInOrder, ...equipmentDatapointsInOrder];
};
