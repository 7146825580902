import { TAlertChartData } from '@marlin/asset/data-access/telemetry';
import { dateAdapter } from '@marlin/shared/utils-common-date';

import { IChartPoint, TAlertBuckets } from '../../../../types';

export const groupAlerts = (alerts: TAlertChartData[], data: IChartPoint[]): TAlertBuckets => {
  const alertBuckets: TAlertBuckets = {};
  alerts.forEach((alert) => {
    alertBuckets[alert.alertId] = { ...alert, buckets: [] };
    const alertStartTime = dateAdapter.date(alert.startTime) || dateAdapter.date(0);
    const alertResolveTime = dateAdapter.date(alert.resolveTime) || dateAdapter.date(new Date());
    if (alertStartTime) {
      alertBuckets[alert.alertId].buckets.push(alertStartTime.valueOf());
    }
    data.forEach((point) => {
      const pointTimestamp = dateAdapter.date(point.timestamp);
      if (pointTimestamp?.isBetween(alertStartTime, alertResolveTime, undefined, '[]')) {
        alertBuckets[alert.alertId].buckets.push(pointTimestamp.valueOf());
      }
    });
    if (alertResolveTime) {
      alertBuckets[alert.alertId].buckets.push(alertResolveTime.valueOf());
    }
  });
  return alertBuckets;
};
