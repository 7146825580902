import { ALERT_STATUS_FILTER } from '@marlin/alert/data-access/alert-action';

interface ITotalItems {
  current: number;
  resolved: number;
  all: number;
  snoozed: number;
}

export const getTotalItems = (currentStatus: ALERT_STATUS_FILTER, totalItems?: ITotalItems) => {
  if (!totalItems) {
    return 0;
  }
  switch (currentStatus) {
    case ALERT_STATUS_FILTER.ALL:
      return totalItems.all;
    case ALERT_STATUS_FILTER.CURRENT:
      return totalItems.current;
    case ALERT_STATUS_FILTER.RESOLVED:
      return totalItems.resolved;
    case ALERT_STATUS_FILTER.SNOOZED:
      return totalItems.snoozed;
  }
};
