import { firstNameSchema, lastNameSchema, phoneSchema } from '@marlin/shared/ui-form';
import { emailSchema } from '@marlin/shared/utils/zod';
import * as Yup from 'yup';

import { IUpsertRecipientProfile } from './types';

export const UpsertRecipientFormValidationSchema: Yup.SchemaOf<IUpsertRecipientProfile> = Yup.object({
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  email: emailSchema,
  phone: phoneSchema,
});
