import { TFlowLinkType } from '@marlin/system-map/data-access/system-map';
import { FlowLinkForm as UIFlowLinkForm } from '@marlin/system-map/ui/flow-link-upsert-form';

import { useFlowLinkForm } from './use-flow-link-form.hook';

const defaultValues = {
  inlet: undefined,
  outlet: undefined,
};
export interface IFlowLinkFormProps {
  changeFlowLinkFormType: (flowLinkFormType: TFlowLinkType) => void;
}

export const FlowLinkForm = ({ changeFlowLinkFormType }: IFlowLinkFormProps) => {
  const { onSubmit: createFlowLink } = useFlowLinkForm();

  return (
    <UIFlowLinkForm
      defaultValues={defaultValues}
      onSubmit={createFlowLink}
      changeFlowLinkFormType={changeFlowLinkFormType}
    />
  );
};
