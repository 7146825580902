import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  cell: {
    padding: `${theme.typography.pxToRem(16)} ${theme.typography.pxToRem(10)}`,
  },

  headerCell: {
    backgroundColor: theme.palette.background.alternative,
  },
}));
