import { createContext } from 'react';

import { TDeviceDrawerAction, TDeviceDrawerState, initialState } from './device-drawer-reducer';

interface IDeviceDrawerContextProps {
  deviceDrawerState: TDeviceDrawerState;
  deviceDrawerDispatch: TDeviceDrawerAction;
}

export const DeviceDrawerContext = createContext<IDeviceDrawerContextProps>({
  deviceDrawerState: initialState,
  deviceDrawerDispatch: () => initialState,
});
