import { TCriticality } from '@marlin/shared/ui-criticality';
import { TUnitOfMeasure } from '@marlin/shared/utils-format-reading';
import {
  ALERT_CAUSE,
  DEVICE_TYPE,
  ERROR_TYPES,
  getDeviceTypeText,
  getTitleErrorText,
} from '@marlin/shared/utils/format-alert';

import { content } from './content';

export type TTopOpenAlert = {
  alertCause?: ALERT_CAUSE;
  criticality: TCriticality;
  deviceId: string;
  deviceName: string;
  deviceType: string;
  startTime: string;
  thresholdMax?: number | null;
  thresholdMin?: number | null;
  title?: string | null;
  uoM: TUnitOfMeasure | null;
};

export const getTopAlertTitle = (
  alert: TTopOpenAlert,
  alertErrorType: ERROR_TYPES,
  alertDeviceType: DEVICE_TYPE,
  isEquipment = false,
  dashboard = false
): string => {
  if (alert.title && isEquipment) {
    return dashboard ? `${alert.deviceName} - ${alert.title}` : alert.title;
  }

  if (alertErrorType === ERROR_TYPES.LOW_BATTERY) {
    return `${alert.deviceName} ${content.LOW_BATTERY}`;
  }
  if (alertErrorType === ERROR_TYPES.LOST_COMMUNICATION) {
    return `${alert.deviceName} ${content.LOST_COMMUNICATION}`;
  }

  if (alertDeviceType === DEVICE_TYPE.LEAK) {
    return `${alert.deviceName} ${content.LEAK}`;
  }

  const errorText = getTitleErrorText(
    alertErrorType,
    alert.uoM,
    alert.thresholdMin ?? undefined,
    alert.thresholdMax ?? undefined
  );
  const deviceTypeText = getDeviceTypeText(alertDeviceType);

  return `${alert.deviceName} ${deviceTypeText} ${errorText}`;
};
