import { TExtractFnReturnType, useQuery } from '@marlin/shared/utils/react-query';

import { getManufacturerData } from '../infrastructure/get-manufacturer-data';
import { queryKey } from './query-key.enum';

type TUseManufacturerOptions = {
  manufacturerId: string;
  enabled?: boolean;
};

export const useGetManufacturerInfo = ({ manufacturerId, enabled = true }: TUseManufacturerOptions) => {
  return useQuery<TExtractFnReturnType<typeof getManufacturerData>>({
    queryKey: queryKey.MANUFACTURER(manufacturerId),
    queryFn: () => getManufacturerData({ manufacturerId }),
    enabled,
  });
};
