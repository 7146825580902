import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  equipmentTypeSelect: {
    width: '100%',
    marginBottom: theme.typography.pxToRem(32),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.typography.pxToRem(16),
    },
  },
  sharedError: {
    color: theme.palette.error.main,
    fontSize: theme.typography.pxToRem(12),
    verticalAlign: 'top',
    marginTop: theme.typography.pxToRem(4),
    marginBottom: theme.typography.pxToRem(32),
  },
  helperText: {
    whiteSpace: 'break-spaces',
  },
  helper: {
    marginLeft: theme.typography.pxToRem(5),
  },
}));
