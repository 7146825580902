import { TLocationSchema } from '@marlin/asset/data-access/location';
import { MarlinTheme } from '@marlin/shared/theme';
import { ActionDelete, ActionEdit, ContextMenu } from '@marlin/shared/ui-context-menu';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { PaginationMobile } from '@marlin/shared/ui-pagination-mobile';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';
import { useLocationsHubContextMenu } from '../shared/use-location-hub-context-menu.hook';
import { LocationCard } from './location-card.component';
import { useDeleteLocationModal } from './use-delete-location-modal';

export interface IDeleteLocationProps {
  locationId: string;
  name: string;
  isParent: boolean;
  deviceCount: number;
  equipmentCount: number;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  mobileMenu: {
    padding: 0,
  },
  emptyList: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 1,
    backgroundColor: theme.palette.background.secondary,
  },
  container: {
    backgroundColor: theme.palette.background.secondary,
  },
}));

interface ILocationListProps {
  rows: TLocationSchema[];
  fetchNextPage: () => void;
  isListLoading?: boolean;
  totalItems?: number;
  hasNextPage?: boolean;
}

export const LocationList = ({ rows, isListLoading, hasNextPage, fetchNextPage, totalItems }: ILocationListProps) => {
  const { classes } = useStyles();
  const { allowDelete, allowEdit, editLocation } = useLocationsHubContextMenu();
  const { deleteLocation } = useDeleteLocationModal();

  if (isListLoading) {
    return <LoadingSpinner />;
  }

  if (!rows.length) {
    return <div className={classes.emptyList}>{content.EMPTY_LIST}</div>;
  }

  return (
    <div className={classes.container}>
      {rows.map((location: TLocationSchema) => (
        <LocationCard key={location.id} location={location} data-testid="location-card">
          <ContextMenu className={classes.mobileMenu} data-testid="location-card-menu">
            {allowEdit && <ActionEdit onClick={() => editLocation(location.id)} />}
            {allowDelete && (
              <ActionDelete
                onClick={() =>
                  deleteLocation({
                    locationId: location.id,
                    name: location.name,
                    isParent: location.isParent,
                    deviceCount: location.deviceCount,
                    equipmentCount: location.equipmentCount,
                  })
                }
              />
            )}
          </ContextMenu>
        </LocationCard>
      ))}
      <PaginationMobile
        totalItems={totalItems}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        loadedItems={rows.length}
        data-testid="location-pagination"
      />
    </div>
  );
};
