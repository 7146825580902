import { MarlinTheme } from '@marlin/shared/theme';
import LaunchIcon from '@mui/icons-material/Launch';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../../content';
import { LinkWithIcon } from './icon-with-link.component';

interface IDetailsLinkProps {
  hyperlink: string;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  manualLink: {
    padding: theme.typography.pxToRem(16),
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
}));

export const DetailsLink = ({ hyperlink }: IDetailsLinkProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.manualLink} data-testid="manual-link">
      <LinkWithIcon iconElement={<LaunchIcon />} text={content.EVENT_LOG_DETAILS.MANUAL_LINK} to={hyperlink} newTab />
    </div>
  );
};
