import { LocationSearchWrapper } from '@marlin/alert/data-access/automated-action';
import {
  FilteredResultsChip,
  MobileFilterModal,
  MobileFilterToggleButton,
  useMobileFilterForm,
} from '@marlin/asset/shared/ui/mobile-filter-modal';
import { MarlinTheme } from '@marlin/shared/theme';
import { DeviceTypeControl, FieldFiltersChips, FormFilterChips, useDeviceTypeChips } from '@marlin/shared/ui-form';
import { InfiniteAutocompleteControl } from '@marlin/shared/ui-form-common';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { PageContainer, PageHeader } from '@marlin/shared/ui-page';
import { dateAdapter } from '@marlin/shared/utils-common-date';
import { useSearchParamsRepository } from '@marlin/shared/utils-router';
import { ISensorHubParams } from '@marlin/shared/utils-routes';
import SensorsRoundedIcon from '@mui/icons-material/SensorsRounded';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';
import { BatteryStatusChip } from '../shared/components/battery-status-chip.component';
import { LostCommunicationChip } from '../shared/components/lost-communication-chip.component';
import { DeviceList } from './device-list.component';
import { FilteredDeviceList } from './filtered-devices-list.component';
import { TDeviceSort, sortList } from './sorting';
import { IDeviceHubFilters, useDevicesHub } from './use-devices-hub.hook';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  bottomActions: {
    paddingLeft: theme.typography.pxToRem(16),
    paddingBottom: theme.typography.pxToRem(8),
  },
}));

const defaultFilters: IDeviceHubFilters = {
  locations: [],
  deviceTypes: [],
};

export function MobileDevicesHub() {
  const { classes } = useStyles();
  const { getSearchParam, removeSearchParam } = useSearchParamsRepository<ISensorHubParams>();

  const mobileFilterForm = useMobileFilterForm<TDeviceSort, IDeviceHubFilters>({ defaultFilters, sortList });
  const batteryLevelStatus = getSearchParam('batteryLevelStatus');
  const filterByLostCommunication = !!getSearchParam('lostCommunication');

  const lastReadingTo = useMemo(() => {
    if (!filterByLostCommunication) {
      return undefined;
    }
    return dateAdapter?.date()?.subtract(8, 'hours').toISOString();
  }, [filterByLostCommunication]);

  const { rows, totalItems, hasNextPage, fetchNextPage, isLoading } = useDevicesHub({
    ...mobileFilterForm.filters,
    batteryLevelStatus,
    lastReadingTo,
  });

  const removeBatteryUrlParam = (): void => {
    if (batteryLevelStatus) {
      removeSearchParam('batteryLevelStatus');
    }
  };

  const removeLostCommunicationUrlParam = (): void => {
    if (filterByLostCommunication) {
      removeSearchParam('lostCommunication');
    }
  };
  const { mapDeviceTypesToOptions, removeDeviceTypeById } = useDeviceTypeChips();

  const showAdditionalFilters = !!batteryLevelStatus || filterByLostCommunication;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <PageContainer prefix="device-hub-page">
      <PageHeader
        icon={<SensorsRoundedIcon />}
        title={content.SENSORS_HUB_TITLE}
        prefix="devices-header"
        data-testid="devices-hub-header"
        actions={<MobileFilterToggleButton onToggle={mobileFilterForm.toggleOpen} isOpen={mobileFilterForm.open} />}
        bottomActions={
          mobileFilterForm.isAnyFilterSet || showAdditionalFilters ? (
            <div className={classes.bottomActions}>
              <FilteredResultsChip
                onDelete={() => {
                  mobileFilterForm.clearFilters();
                  removeBatteryUrlParam();
                  removeLostCommunicationUrlParam();
                }}
              />
            </div>
          ) : undefined
        }
      />
      <DeviceList
        rows={rows}
        totalItems={totalItems}
        hasNextPage={hasNextPage}
        isListLoading={isLoading}
        fetchNextPage={fetchNextPage}
      />
      <MobileFilterModal<TDeviceSort, IDeviceHubFilters>
        {...mobileFilterForm}
        title={content.SENSORS_HUB_TITLE}
        rows={<FilteredDeviceList />}
        chips={
          <FormFilterChips<IDeviceHubFilters>
            defaultFilters={defaultFilters}
            showEmptyFilters={showAdditionalFilters}
            onClear={() => {
              removeBatteryUrlParam();
              removeLostCommunicationUrlParam();
            }}
          >
            <FieldFiltersChips<IDeviceHubFilters, 'locations'>
              fieldName="locations"
              mapFieldToChips={(value) => value}
              deleteFilter={(id, values) => values.filter((value) => id !== value.id)}
            />
            <FieldFiltersChips<IDeviceHubFilters, 'deviceTypes'>
              fieldName="deviceTypes"
              mapFieldToChips={mapDeviceTypesToOptions}
              deleteFilter={removeDeviceTypeById}
            />
            {batteryLevelStatus && (
              <BatteryStatusChip batteryStatus={batteryLevelStatus} onDelete={removeBatteryUrlParam} />
            )}
            {filterByLostCommunication && <LostCommunicationChip onDelete={removeLostCommunicationUrlParam} />}
          </FormFilterChips>
        }
      >
        <LocationSearchWrapper>
          {(props) => (
            <InfiniteAutocompleteControl<IDeviceHubFilters>
              fieldName="locations"
              testId="location-search"
              label="Location"
              {...props}
            />
          )}
        </LocationSearchWrapper>
        <DeviceTypeControl<IDeviceHubFilters> fieldName="deviceTypes" multiple />
      </MobileFilterModal>
    </PageContainer>
  );
}
