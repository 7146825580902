import type { XYPosition } from '@xyflow/react';

import { TControlPointData, TSegment, TSegmentIndex } from './types';

type TPathSegment = { type: string; values: number[] };

type TMidpoint = XYPosition & { segmentIndexes: number[]; dragging: boolean };

export const isControlPoint = (point: TControlPointData | XYPosition): point is TControlPointData => 'id' in point;

const getId = (edgeId: string, type: string, index: number) => `${edgeId}-${type}-${index}`;

const getSegmentType = (point: XYPosition, prevPoint: XYPosition): 'horizontal' | 'vertical' | null => {
  if (point.x === prevPoint.x) {
    return 'vertical';
  } else if (point.y === prevPoint.y) {
    return 'horizontal';
  }
  return null;
};

export const getControlPoints = (edgePath: string, edgeId: string): TControlPointData[] => {
  const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
  path.setAttribute('d', edgePath);
  const segments = path.getPathData().filter((d: TPathSegment) => d.type === 'M' || d.type === 'L');
  const segmentPoints = segments.map((segment: TPathSegment) => ({ x: segment.values[0], y: segment.values[1] }));
  return mapToControlPoints(segmentPoints, edgeId, 'point');
};

export const mapToControlPoints = (
  points: (XYPosition | TMidpoint)[],
  edgeId: string,
  type: string
): TControlPointData[] => {
  return points.map((point: XYPosition | TMidpoint, index: number) => {
    return {
      ...point,
      id: getId(edgeId, type, index),
      active: true,
      dragging: (point as TMidpoint).dragging ?? false,
    };
  });
};

export const resetPointIds = (points: TControlPointData[], edgeId: string) => {
  return points.map((point, index) => ({ ...point, id: getId(edgeId, 'point', index) }));
};

export const mergePoints = (points: TControlPointData[], canMerge: boolean): TControlPointData[] => {
  if (!canMerge) {
    return points;
  }

  return points.reduce((acc: TControlPointData[], point, ind, arr) => {
    if (ind === 0) {
      return [point];
    }
    const prevPoint = acc[acc.length - 1];
    if (prevPoint && prevPoint.x === point.x && prevPoint.y === point.y) {
      return acc;
    }
    if (ind < arr.length - 2 && ind > 1) {
      const nextPoint = arr[ind + 1];
      const prevSegmentType = getSegmentType(point, prevPoint);
      const currSegmentType = getSegmentType(nextPoint, point);
      if (prevSegmentType && currSegmentType && prevSegmentType === currSegmentType) {
        return [...acc, nextPoint];
      }
    }
    return [...acc, point];
  }, [] as TControlPointData[]);
};

export const getMidpoint = ([{ x: x1, y: y1 }, { x: x2, y: y2 }]: TSegment) => ({ x: (x1 + x2) / 2, y: (y1 + y2) / 2 });

export const mapPointsToSegmentIndex = (points: XYPosition[]): TSegmentIndex[] => {
  return points.reduce((acc: TSegmentIndex[], _: XYPosition, ind: number, arr: XYPosition[]) => {
    if (ind === 0) {
      return [[ind]];
    }
    acc[acc.length - 1].push(ind);
    if (ind !== arr.length - 1) {
      acc.push([ind]);
    }
    return acc;
  }, [] as TSegmentIndex[]);
};

export const mapPointsToSegments = <TSegmentData extends XYPosition = XYPosition>(
  points: TSegmentData[]
): TSegment<TSegmentData>[] => {
  return points.reduce((acc: TSegment<TSegmentData>[], curr: TSegmentData, ind: number, arr: TSegmentData[]) => {
    if (ind === 0) {
      return [[curr]];
    }
    acc[acc.length - 1].push(curr);
    if (ind !== arr.length - 1) {
      acc.push([curr]);
    }
    return acc;
  }, [] as TSegment<TSegmentData>[]);
};

export const mapPointsToMidpoints = (points: TControlPointData[]): TMidpoint[] => {
  const segmentIndexes = mapPointsToSegmentIndex(points);
  return mapPointsToSegments<TControlPointData>(points).map((p, ind) => ({
    ...getMidpoint(p),
    segmentIndexes: segmentIndexes[ind],
    dragging: p[0].dragging && p[1].dragging,
  }));
};

export const moveSegment = (
  movedMidpoint: TControlPointData,
  points: TControlPointData[],
  index: number,
  pointsLength: number
) => {
  if (!movedMidpoint.segmentIndexes) {
    return points;
  }
  const newPoints = [...points];
  let [sIndStart, sIndEnd] = movedMidpoint.segmentIndexes;
  const addSegmentToBeginning = () => {
    newPoints.splice(1, 0, { ...newPoints[1], dragging: false });
    sIndStart += 1;
    sIndEnd += 1;
  };
  const addSegmentToEnd = () => {
    newPoints.splice(newPoints.length - 2, 0, { ...newPoints[newPoints.length - 2], dragging: false });
  };
  if (index === 0) {
    addSegmentToBeginning();
  }
  if (index === pointsLength - 1) {
    addSegmentToEnd();
  }
  const sStart = newPoints[sIndStart];
  const sEnd = newPoints[sIndEnd];
  const isHorizontal = getSegmentType(sStart, sEnd) === 'horizontal';
  if (isHorizontal) {
    newPoints[sIndStart] = {
      ...sStart,
      y: movedMidpoint.y,
      dragging: movedMidpoint.dragging,
    };
    newPoints[sIndEnd] = {
      ...sEnd,
      y: movedMidpoint.y,
      dragging: movedMidpoint.dragging,
    };
  } else {
    newPoints[sIndStart] = {
      ...sStart,
      x: movedMidpoint.x,
      dragging: movedMidpoint.dragging,
    };
    newPoints[sIndEnd] = {
      ...sEnd,
      x: movedMidpoint.x,
      dragging: movedMidpoint.dragging,
    };
  }
  return newPoints;
};
