import { MarlinTheme } from '@marlin/shared/theme';
import { CommonChip } from '@marlin/shared/ui/chips';
import { TDeviceType } from '@marlin/system-map/shared/data-access-schemas';
import { useController } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { assetTypeOptions } from './asset-type';
import { IAsset, ILocationDetailsFilters } from './types';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  locationChipsWrapper: {
    marginLeft: theme.typography.pxToRem(16),
    marginBottom: theme.typography.pxToRem(16),
  },
}));

export const FilterChips = () => {
  const { classes } = useStyles();
  const {
    field: { value: locations, onChange: onChangeLocations },
  } = useController<ILocationDetailsFilters>({ name: 'locations' });
  const {
    field: { value: deviceTypes, onChange: onChangeDeviceTypes },
  } = useController<ILocationDetailsFilters>({ name: 'deviceTypes' });

  const removeLocationChip = (id: string): IAsset[] => {
    return (locations as IAsset[])?.filter((asset) => asset.id !== id);
  };

  const removeDeviceTypeChip = (id: string): TDeviceType[] => {
    return (deviceTypes as TDeviceType[])?.filter((type) => type !== id);
  };

  return (
    <div className={classes.locationChipsWrapper}>
      {(locations as IAsset[])?.map((value: IAsset) => (
        <CommonChip
          key={value.id}
          label={value.name}
          active={true}
          onDelete={() => onChangeLocations && onChangeLocations({ target: { value: removeLocationChip(value.id) } })}
        />
      ))}
      {(deviceTypes as TDeviceType[])?.map((value: TDeviceType) => (
        <CommonChip
          key={value}
          label={assetTypeOptions[value] || ''}
          active={true}
          onDelete={() =>
            onChangeDeviceTypes && onChangeDeviceTypes({ target: { value: removeDeviceTypeChip(value) } })
          }
        />
      ))}
    </div>
  );
};
