import { content } from '../../../content';

interface IChangeSystemTagBodyProps {
  previousAssetName: string;
  newAssetName: string;
}

export const ChangeSystemTagBody = ({ previousAssetName, newAssetName }: IChangeSystemTagBodyProps) => {
  return <div>{content.SYSTEM_SOURCE_TAG.SET_TAG_CONFIRMATION_MESSAGE(previousAssetName, newAssetName)}</div>;
};
