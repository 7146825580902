export const millisecondsInSecond = 1000;
export const secondsInMinute = 60;
export const minutesInHour = 60;
export const hoursInDay = 24;
export const daysInWeek = 7;
export const daysInMonth = 30;
export const monthsInYear = 12;

export const millisecondsInMinute = millisecondsInSecond * secondsInMinute;
export const millisecondsInHour = millisecondsInMinute * minutesInHour;
export const millisecondsInDay = millisecondsInHour * hoursInDay;
export const millisecondsInWeek = millisecondsInDay * daysInWeek;
export const millisecondsInMonth = millisecondsInDay * daysInMonth;
export const millisecondsInYear = millisecondsInMonth * monthsInYear;
