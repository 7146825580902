import { TUseAnalyticsTelemetryReturnedType } from '@marlin/asset/data-access/telemetry';
import {
  ChartType,
  IApexAxisChartThresholdSeries,
  IChartSeries,
  TChartDatapoint,
  TChartType,
} from '@marlin/shared/utils-chart';
import { RANGE_FILTER } from '@marlin/shared/utils-chart';
import { TChartThresholdPosition } from '@marlin/shared/utils-chart';
import { TUnitOfMeasure } from '@marlin/shared/utils-format-reading';
import { z } from 'zod';

export type TTelemetryData = TUseAnalyticsTelemetryReturnedType['telemetryData'];

export interface IExtendedIChartSeries extends IChartSeries {
  parentType: TSelectedDatapoint['parentType'];
}

export const latestAnalyticsJsonSchemaVersion = '1.2';
// On any change in this file, update the schema version

export interface ILocationData {
  id: string;
  name: string;
  equipmentList: { type: string; name: string; manufacturerId: string; model: string }[];
  sensorsList: { type: string; name: string; manufacturerId: string; model: string }[];
}

export type TEquipmentList = Array<{
  name: string;
  id: string;
  sensorList: TSelectedDatapoint[];
}>;

export interface IFullScreen {
  chartId: string | null;
  chartType: TChartType | null;
  title: string | null;
}

export interface ISensorFiltering {
  selectedLocationId: string;
  selectedEquipmentId: string;
  selectedChartType: TChartType | '';
}

export type TSensorMetadata = Record<string, Array<TChartDatapoint & { group: TGroupNames }>>;

export interface IAddChartParams {
  chartType: TChartType | '';
  selectedDatapoints: TSelectedDatapoint[];
  chartName: string;
}

export { ChartType };
export type { TChartDatapoint, TChartType };

const groupsValues = ['temperature', 'pressure', 'flow', 'pumpvalve', 'leak'] as const;
export const GroupNames = z.enum(groupsValues);
export type TGroupNames = z.infer<typeof GroupNames>;

const typesValues = ['sensor', 'equipment'] as const;
export const TypesValues = z.enum(typesValues);
export type TTypesValues = z.infer<typeof TypesValues>;

const datapointTypeValues = ['temperature', 'pressure', 'onOff', 'flow', 'leak'] as const;
export const DatapointTypeValues = z.enum(datapointTypeValues);
export type TDatapointTypeValues = z.infer<typeof DatapointTypeValues>;

export const customGroupAvailableDatapointsTypes = [
  DatapointTypeValues.Enum.temperature,
  DatapointTypeValues.Enum.pressure,
  DatapointTypeValues.Enum.flow,
] as const;

const ChartId = z.string();

const SelectedDatapoint = z.object({
  id: z.string(),
  equipmentName: z.string().optional(),
  manufacturerId: z.string(),
  label: z.string(),
  name: z.string(),
  isDefault: z.boolean().optional(),
  color: z.string().optional(),
  locationId: z.string(),
  locationName: z.string(),
  isActive: z.boolean(),
  parentType: TypesValues,
  type: DatapointTypeValues.optional(),
});
export type TSelectedDatapoint = z.infer<typeof SelectedDatapoint>;

const SubGroupData = z.object({
  chartName: z.string(),
  chartDatapoints: SelectedDatapoint.array(),
  chartType: ChartType,
  chartDisplayType: z.string().optional(),
});

export const SelectedGroupsWithDatapoints = z.record(ChartId, SubGroupData);
export type TSelectedGroupsWithDatapoints = z.infer<typeof SelectedGroupsWithDatapoints>;

export interface IChartOptionsParams {
  chartData: IChartSeries[];
  thresholdsData: IApexAxisChartThresholdSeries[];
  from?: number;
  to?: number;
  timeRange: RANGE_FILTER;
  max: number;
  min: number;
  additionalAxisUom: TUnitOfMeasure | undefined;
  hideUomOnAxis: boolean;
  mainDatapoint: string;
  handleZoomChange: (zoom?: { min: number; max: number }) => void;
  isZoomed?: boolean;
  chartType: string;
  chartId: string;
}

export interface IChartMetadata {
  datapointsMetadata: {
    name: string;
    isDefault: boolean;
    label: string;
    color: string;
  }[];
  thresholdsMetadata: {
    name: string;
    isDefault: boolean;
    label: string;
    alias: string;
    position: TChartThresholdPosition;
  }[];
}

export interface IEquipmentChartThreshold {
  name: string;
  label: string;
  from: number;
  to: number;
  thresholdMin: number | null;
  thresholdMax: number | null;
  uom: TUnitOfMeasure | null;
}

export interface IAddChartParams {
  chartType: TChartType | '';
  selectedDatapoints: TSelectedDatapoint[];
  chartName: string;
}

export interface IRequestedTelemetry {
  manufacturerId: string;
  name: string;
  datapoints: string[];
  locationName: string;
  locationId: string;
}
