import { IEndpointConfig } from './endpoint.models';
import { EndpointModule } from './endpoint.module';

let endpointModule: EndpointModule | null = null;

export const getEndpointModule = (): EndpointModule => {
  if (!endpointModule) {
    throw new Error(`EndpointModule isn't initialized`);
  }

  return endpointModule;
};

export const initEndpointModule = (config: IEndpointConfig): void => {
  if (endpointModule) {
    // eslint-disable-next-line no-console
    console.warn('[WARN] EndpointModule already initialized');
    return;
  }

  endpointModule = EndpointModule.forRoot({ config });
};
