import { queryClient } from '@marlin/shared/utils/react-query';
import { UseMutationResult, useMutation } from '@marlin/shared/utils/react-query';

import { setSystemTag } from '../infrastructure/system-tag';
import { TSystemTagParams } from '../system-tag.schema';
import { queryKey } from './query-key.enum';

export const useSetSystemTag = (): UseMutationResult<unknown, unknown, TSystemTagParams> => {
  return useMutation<unknown, unknown, TSystemTagParams>({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.FLOW_LINK() });
      queryClient.invalidateQueries({ queryKey: queryKey.FLOW_LINKS() });
      queryClient.invalidateQueries({ queryKey: queryKey.GRAPH() });
    },
    mutationFn: setSystemTag,
  });
};
