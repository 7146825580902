import { MODEL } from '@marlin/shared/utils/zod';
import { TSensor } from '@marlin/system-map/data-access/system-map';
import { useCallback } from 'react';

import { useRegisteredEquipmentContext } from './use-registered-equipment-context';

interface IUseDetailedNodeSensorsFilterProps {
  isDetailed: boolean;
}

export const useDetailedNodeSensorsFilter = ({ isDetailed }: IUseDetailedNodeSensorsFilterProps) => {
  const { model } = useRegisteredEquipmentContext();

  return useCallback(
    (sensors: TSensor[]) => {
      if (!isDetailed) {
        return sensors;
      }
      switch (model) {
        case MODEL.SENTINEL: {
          return sensors.filter((sensor) => {
            return !sensor?.displayDataPointName?.includes('Scene');
          });
        }
        default: {
          return sensors.filter((sensor) => {
            return !!sensor?.displayDataPointName?.includes('Temp') || !!sensor?.label?.includes('Setpoint');
          });
        }
      }
    },
    [isDetailed, model]
  );
};
