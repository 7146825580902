import { TOrganizationSchema } from '../infrastructure/organization.schema';
import { organizationStorageService } from '../storage/organization-storage.service';
import { userService } from '../user.service';

const findOrg = (orgs: TOrganizationSchema[], orgId: string | null) => orgs.find((org) => org.organizationId === orgId);

const getOrgFromStorage = (
  orgs: TOrganizationSchema[],
  type: 'sessionStorage' | 'localStorage'
): TOrganizationSchema | null => {
  const currentOrgId = organizationStorageService.get(type);
  const currentOrg = findOrg(orgs, currentOrgId);
  if (currentOrg) {
    return currentOrg;
  }
  return null;
};

export const getCurrentOrg = (orgs: TOrganizationSchema[]): TOrganizationSchema => {
  const orgSessionStorage = getOrgFromStorage(orgs, 'sessionStorage');
  if (orgSessionStorage) {
    return orgSessionStorage;
  }

  const orgLocalStorage = getOrgFromStorage(orgs, 'localStorage');
  if (orgLocalStorage) {
    return orgLocalStorage;
  }

  return orgs.find((org) => org.isDefault) || orgs[0];
};

export const updateOrgId = (organizationId: string) => {
  userService.setUserOrganization(organizationId);
  organizationStorageService.set(organizationId);
  return userService.getUser();
};

export const getStoredOrgId = () =>
  organizationStorageService.get('sessionStorage') || organizationStorageService.get('localStorage');
