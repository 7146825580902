import { getHttpClient } from '@marlin/shared/utils/react-query';

import { TUpdateSystemMapLinkParams, updateSystemMapLinkDtoSchema } from '../flow-link.model.schema';
import { paths } from './api';

export const updateAttachment = async ({ flowLinkId, params }: TUpdateSystemMapLinkParams) => {
  const body = updateSystemMapLinkDtoSchema.parse(params);

  return getHttpClient().put(paths.SYSTEM_MAP_LINK_DETAILS, body, { params: { flowLinkId } });
};
