import { ALERT_STATUS_FILTER } from '@marlin/alert/data-access/alert-action';
import { MarlinTheme } from '@marlin/shared/theme';
import { alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  chipWrapper: {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(12)}`,
    color: theme.palette.text.primary,
    height: theme.typography.pxToRem(40),
    border: `${theme.typography.pxToRem(1)} solid ${alpha(theme.palette.primary.main, 0.5)}`,
    borderRadius: theme.typography.pxToRem(50),
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.04),
    },
    [theme.breakpoints.down('md')]: {
      height: theme.typography.pxToRem(32),
    },
  },
  label: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.primary.main,
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    columnGap: theme.typography.pxToRem(10),
  },
  selected: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
  },
}));

export interface IStatus {
  status: ALERT_STATUS_FILTER;
}

interface IFilterChips {
  label: string;
  isActive: boolean;
  status: ALERT_STATUS_FILTER;
  onClick: (status: ALERT_STATUS_FILTER) => void;
}
export const FilterChips = ({ label, status, onClick, isActive }: IFilterChips) => {
  const { classes, cx } = useStyles();

  return (
    <div
      onClick={() => {
        onClick(status);
      }}
      data-testid={`filter-chip-${status}`}
      className={cx(classes.chipWrapper, isActive && classes.selected)}
    >
      <div className={classes.label}>{label}</div>
    </div>
  );
};
