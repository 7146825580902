import { queryClient } from '@marlin/shared/utils/react-query';
import { useObservable, useObservableState, useSubscription } from 'observable-hooks';

import { TSystemMapFullNotification } from '../flow-map.model.schema';
import { getSystemMapFullNotificationSubject, initSubject } from '../infrastructure/get-system-map-full-notification';
import { queryKey } from './query-key.enum';

type TValidOrgId = (organizationId: string) => boolean;

const updateSystemMapCache = (data: TSystemMapFullNotification, isValidOrgId: TValidOrgId) => {
  if (!data?.organizationId) {
    return;
  }

  if (isValidOrgId(data.organizationId)) {
    queryClient.invalidateQueries({ queryKey: queryKey.FLOW_LINK() });
    queryClient.invalidateQueries({ queryKey: queryKey.FLOW_LINKS() });
    queryClient.invalidateQueries({ queryKey: queryKey.GRAPH() });
  }
};

export const useSystemMapFullNotificationSubscription = (isValidOrgId: TValidOrgId) => {
  const systemMap$ = useObservable(() => getSystemMapFullNotificationSubject());

  return useSubscription<TSystemMapFullNotification>(systemMap$, (data: TSystemMapFullNotification) =>
    updateSystemMapCache(data, isValidOrgId)
  );
};

export const useInitSystemMapFullNotificationSubject = () => {
  return useObservableState(initSubject);
};
