import { Annotations, Shape } from 'plotly.js';
import * as Plotly from 'plotly.js';

import { IRangedAreaSeries } from '../../types';
import { IAutomationSeries, IPointAnnotation } from './automations/types';

export const createAlertSeries = (validAlertData: IRangedAreaSeries[]) => {
  const alertShapes: Array<Partial<Shape>> = validAlertData
    .map((series) => {
      const firstDataPoint = series.data[0];

      return [
        {
          type: 'line' as const,
          xref: 'x',
          yref: 'paper',
          x0: firstDataPoint.x,
          x1: firstDataPoint.x,
          y0: 0,
          y1: 1,
          line: {
            color: '#D32F2F',
            width: 1,
          },
        } as Partial<Shape>,
      ];
    })
    .flat();

  const alertTraces: Array<Plotly.Data> = validAlertData.map((series) => {
    const firstDataPoint = series.data[0];
    const lastDataPoint = series.data[series.data.length - 1];
    const seriesWithoutFirstAndLast = series.data.slice(1, series.data.length - 1);

    return {
      type: 'scatter',
      mode: 'lines',
      x: [
        firstDataPoint.x,
        firstDataPoint.x,
        ...seriesWithoutFirstAndLast.map((value) => value.x),
        lastDataPoint.x,
        lastDataPoint.x,
      ],
      y: [
        firstDataPoint.y[0],
        firstDataPoint.y[1],
        ...seriesWithoutFirstAndLast.map((value) => value.y[1]),
        lastDataPoint.y[1],
        lastDataPoint.y[0],
      ],
      fill: 'tonexty',
      fillcolor: 'rgba(211, 47, 47, 0.3)',
      line: {
        width: 0,
      },
      name: series.name,
      hoverinfo: 'none',
    };
  });

  return { alertShapes, alertTraces };
};

export const createAnnotaionSeries = (
  automationRulesSeriesAnnotation: IPointAnnotation[],
  automationRulesSeries: IAutomationSeries[],
  from: number,
  to: number
) => {
  const pointAnnotations: Array<Partial<Annotations>> = automationRulesSeriesAnnotation.map((annotation) => ({
    x: annotation.x,
    y: annotation.y,

    xref: 'x' as const,
    yref: 'y' as const,
    text: annotation.label.text,
    showarrow: false,
    font: {
      color: annotation.label.style.color,
    },
  }));

  const automationSeriesShapes: Array<Plotly.Data> = automationRulesSeriesAnnotation.map((series) => {
    return {
      type: 'scatter',
      mode: 'markers',
      x: [series.x],
      y: [series.y],
      marker: {
        size: 20,
        color: series.label.style.background,
        line: {
          color: series.label.style.color,
          width: 2,
        },
      },
      name: series.label.text,
    };
  });

  const triggers: Array<Partial<Shape>> = automationRulesSeries.map((series) => {
    if (series.type === 'line') {
      return {
        type: 'line',
        xref: 'x',
        yref: 'y',
        x0: (series.data[0].x as number) < from ? from : series.data[0].x,
        x1: (series.data[1].x as number) > to ? to : series.data[1].x,
        y0: series.data[0].y as number,
        y1: series.data[1].y as number,
        line: {
          color: '#D32F2F',
          width: 1,
          dash: 'dash',
        },
        opacity: 0.12,
      };
    }

    return {
      type: 'rect',
      xref: 'x',
      yref: 'y',
      x0: (series.data[0].x as number) < from ? from : series.data[0].x,
      x1: (series.data[1].x as number) > to ? to : series.data[1].x,
      y0: (series.data[0].y as number[])[0],
      y1: (series.data[1].y as number[])[1],
      line: {
        width: 0,
      },
      fillcolor: '#D32F2F',
      opacity: 0.12,
    };
  });

  return { pointAnnotations, automationSeriesShapes, triggers };
};
