import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { Typography } from '@mui/material';

import { DetailItem } from './detail-item';
import { useStyles } from './device-drawer-mobile.styles';

interface ITooltipDetailRow {
  propName: string;
  propValue?: string;
  tooltipText: string;
}

export const TooltipDetailRow = ({ propName, propValue, tooltipText }: ITooltipDetailRow) => {
  const { classes } = useStyles();

  return (
    <DetailItem>
      <div className={classes.tooltipContainer}>
        <Typography className={classes.detailsPropNameText}>{propName}</Typography>
        <Tooltip placement="bottom" text={tooltipText}>
          <InfoRoundedIcon fontSize="medium" color="primary" />
        </Tooltip>
      </div>
      <div className={classes.textWrapper}>
        <Typography className={classes.detailsValueText}>{propValue}</Typography>
      </div>
    </DetailItem>
  );
};
