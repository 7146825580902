import { DatapointDisplayValue } from '@marlin/asset/shared/ui/datapoint-display';
import { MarlinTheme } from '@marlin/shared/theme';
import { TChartDisplayType } from '@marlin/shared/utils-chart';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.background.alternative,
    padding: theme.typography.pxToRem(12),
    gap: theme.typography.pxToRem(8),
    maxWidth: 'fit-content',
  },
  text: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(14),
    marginTop: theme.typography.pxToRem(8),
  },
}));

export const TotalValue = ({ value, chartType }: { value: string; chartType: TChartDisplayType }) => {
  const { classes } = useStyles();

  if (chartType !== 'bar') {
    return <div className={classes.text}>{content.AVERAGE_FLOW}</div>;
  }

  return (
    <div className={classes.container} data-testid="total-volume">
      <Typography variant="overline">{content.TOTAL_VOLUME}</Typography>
      <DatapointDisplayValue value={value} controlState="active" separator />
    </div>
  );
};
