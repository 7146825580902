// NOTE: resolve module boundaries config
/* eslint-disable @nx/enforce-module-boundaries */
import { SnoozeAlertIcon } from '@marlin/shared/ui-domain-automation-snooze';
import { TDateString } from '@marlin/shared/utils-common-date';
import { PERMISSIONS, Restricted, usePermission } from '@marlin/shared/utils-permission';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import { Grid, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Switch, Typography } from '@mui/material';
import { ChangeEvent, MouseEvent, useCallback, useState } from 'react';

import { ConfirmationModal } from './confirmation-modal.component';
import { content } from './content';
import { useStyles } from './header.styles';

interface ICardHeaderProps {
  title: string;
  id: string;
  enabled: boolean;
  snoozeUntil: TDateString;
  onToggle?: (checked: boolean) => void;
  onEditButtonClick: () => void;
  onDeleteButtonClick: () => void;
  allowEdit?: boolean;
  allowDelete?: boolean;
  isDeviceAlert?: boolean;
  handleSnoozeAlertClick: () => void;
  openCancelSnoozedAlertModal: () => void;
  deviceId?: string;
}

function AutomationLabel({ enabled }: { enabled: boolean }) {
  const { classes } = useStyles();

  return (
    <Typography variant="body2" data-testid="automation-toggle-label" className={classes.enabledElement}>
      {enabled ? content.CARD_AUTOMATION_STATUS_ON : content.CARD_AUTOMATION_STATUS_OFF}
    </Typography>
  );
}

export function CardHeader({
  title,
  id,
  enabled,
  onToggle,
  onEditButtonClick,
  onDeleteButtonClick,
  snoozeUntil = null,
  allowEdit,
  allowDelete,
  isDeviceAlert = false,
  handleSnoozeAlertClick,
  openCancelSnoozedAlertModal,
  deviceId,
}: ICardHeaderProps) {
  const { classes, cx } = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openModal, setOpenModal] = useState(false);

  const allowSnoozeAlerts = usePermission(PERMISSIONS.AUTOMATION_SNOOZE_ALERTS);

  const handleMenuOpen = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, [setOpenModal]);

  const handleTurnOffAutomation = useCallback(() => {
    onToggle && onToggle(false);
    setOpenModal(false);
  }, [onToggle]);

  const handleSwitchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        onToggle && onToggle(event.target.checked);
      } else {
        setOpenModal(true);
      }
    },
    [onToggle]
  );

  const handleEditClick = useCallback(() => {
    handleMenuClose();
    onEditButtonClick();
  }, [handleMenuClose, onEditButtonClick]);

  const handleDeleteClick = useCallback(() => {
    handleMenuClose();
    onDeleteButtonClick();
  }, [handleMenuClose, onDeleteButtonClick]);

  return (
    <>
      <Grid className={classes.headerRow} container>
        <Grid item className={cx({ [classes.disabledElements]: !enabled }, classes.titleGrid)}>
          <Typography variant="h5" className={classes.title} data-testid="card-title">
            {title}
          </Typography>
        </Grid>
        <Grid className={cx(classes.actions, { [classes.disabledElements]: !enabled })}>
          {enabled && !!deviceId && (
            <SnoozeAlertIcon
              disabled={!allowSnoozeAlerts}
              data-testid="snooze-alert-icon"
              id={id}
              isSnoozed={!!snoozeUntil}
              onClick={snoozeUntil ? openCancelSnoozedAlertModal : handleSnoozeAlertClick}
            />
          )}
          <AutomationLabel enabled={enabled} />
          {onToggle && (
            <Restricted to={PERMISSIONS.SHOW_AUTOMATION_TOOGLE}>
              <Switch checked={enabled} onChange={handleSwitchChange} data-testid="card-toggle" />
            </Restricted>
          )}
          {(allowEdit || allowDelete) && (
            <IconButton data-testid="context-menu-wrapper" onClick={handleMenuOpen} className={classes.enabledElement}>
              <MoreHorizRoundedIcon />
            </IconButton>
          )}
        </Grid>
      </Grid>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleMenuClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {allowEdit && (
          <MenuItem onClick={handleEditClick}>
            <ListItemIcon>
              <EditRoundedIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText>{content.MENU.EDIT}</ListItemText>
          </MenuItem>
        )}
        {allowDelete && !isDeviceAlert && (
          <MenuItem onClick={handleDeleteClick} className={classes.delete}>
            <ListItemIcon>
              <DeleteRoundedIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText>{content.MENU.DELETE}</ListItemText>
          </MenuItem>
        )}
      </Menu>
      {openModal && (
        <ConfirmationModal automationName={title} onConfirm={handleTurnOffAutomation} onClose={handleCloseModal} />
      )}
    </>
  );
}
