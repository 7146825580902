import { MarlinTheme } from '@marlin/shared/theme';
import { alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { CriticalityControl } from './criticality-control.component';
import { TCriticality } from './models';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  criticalityWrapper: {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(12)} ${theme.typography.pxToRem(
      10
    )} ${theme.typography.pxToRem(12)}`,
    color: theme.palette.text.primary,
    height: theme.typography.pxToRem(40),
    border: `${theme.typography.pxToRem(1)} solid ${alpha(theme.palette.primary.main, 0.5)}`,
    borderRadius: theme.typography.pxToRem(50),
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.04),
    },
    [theme.breakpoints.down('md')]: {
      height: theme.typography.pxToRem(32),
    },
  },
  selected: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
  },
  disabled: {
    cursor: 'not-allowed',
    opacity: 0.5,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

interface ICriticalityButton {
  severity: TCriticality;
  selected: boolean;
  onClick: (value: TCriticality) => void;
  disabled?: boolean;
}

export const CriticalityButton = ({ onClick, severity, selected, disabled = false }: ICriticalityButton) => {
  const { classes } = useStyles();

  return (
    <div
      onClick={() => {
        if (disabled) return;
        onClick(severity);
      }}
      className={`${classes.criticalityWrapper} ${selected ? classes.selected : ''} ${
        disabled ? classes.disabled : ''
      }`}
    >
      <CriticalityControl isCriticalityButton severity={severity} />
    </div>
  );
};
