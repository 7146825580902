import { TDeviceTypeSchema } from '@marlin/asset/data-access/device';
import { createDateString, dateAdapter } from '@marlin/shared/utils-common-date';
import { getChartAlertTitle } from '@marlin/shared/utils/format-alert';
import debounce from 'lodash/debounce';
import { useCallback, useMemo, useState } from 'react';

import { TAlertBuckets } from '../../../../types';
import { getAlertId } from '../../chart.component';
import { ITooltipData } from './chart-tooltip.component';

interface IUseTooltipProps {
  groupedAlerts: TAlertBuckets;
  deviceType?: TDeviceTypeSchema;
}

interface IUseTooltip {
  tooltipOpen: boolean;
  tooltipData: ITooltipData | null;
  anchorEl: HTMLElement | null;
  openTooltip: (event: Plotly.PlotHoverEvent) => void;
  closeTooltip: () => void;
}

const debounceTimeMs = 10;

export const useTooltip = ({ deviceType, groupedAlerts }: IUseTooltipProps): IUseTooltip => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipData, setTooltipData] = useState<ITooltipData | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const openTooltip = useCallback(
    (event: Plotly.PlotHoverEvent) => {
      const { points, event: mouseEvent } = event;
      if (points.length > 0) {
        const tooltipData = points.reduce(
          (data: ITooltipData, point): ITooltipData => {
            if (point.data.name === 'data') {
              return {
                ...data,
                dataSeries: {
                  timestamp: createDateString(dateAdapter.date(point.x as number)?.toDate()),
                  value: point.y as number,
                },
              } as unknown as ITooltipData;
            }

            if (point.data.name.includes('alert')) {
              const alertId = getAlertId(point.data.name);
              return {
                ...data,
                alertSeries: [
                  ...data.alertSeries,
                  {
                    timestamp: createDateString(dateAdapter.date(point.x as number)?.toDate()),

                    value: getChartAlertTitle(groupedAlerts[alertId], deviceType),
                    alert: groupedAlerts[alertId],
                  },
                ],
              } as unknown as ITooltipData;
            }

            return data as unknown as ITooltipData;
          },

          { alertSeries: [], dataSeries: null }
        );

        setTooltipData(tooltipData);
        const virtualAnchor = document.createElement('div');
        virtualAnchor.style.position = 'absolute';
        virtualAnchor.style.top = `${mouseEvent.clientY}px`;
        virtualAnchor.style.left = `${mouseEvent.clientX}px`;
        document.body.appendChild(virtualAnchor);

        setAnchorEl(virtualAnchor);

        setTooltipOpen(true);
      }
    },
    [deviceType, groupedAlerts]
  );

  const closeTooltip = useCallback(() => {
    setTooltipOpen(false);
    setTooltipData(null);
    setAnchorEl(null);
  }, []);

  const openTooltipDebounced = useMemo(() => debounce(openTooltip, debounceTimeMs), [openTooltip]);
  const closeTooltipDebounced = useMemo(() => debounce(closeTooltip, debounceTimeMs), [closeTooltip]);

  return useMemo(() => {
    return {
      tooltipOpen,
      tooltipData,
      anchorEl,
      openTooltip: openTooltipDebounced,
      closeTooltip: closeTooltipDebounced,
    };
  }, [anchorEl, closeTooltipDebounced, openTooltipDebounced, tooltipData, tooltipOpen]);
};
