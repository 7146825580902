import { MarlinTheme } from '@marlin/shared/theme';
import { alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  detailsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: theme.typography.pxToRem(24),
    paddingRight: theme.typography.pxToRem(24),
  },

  detailsMainContainer: {
    marginTop: theme.typography.pxToRem(24),
    paddingLeft: 0,
    paddingRight: 0,
  },

  detailsTitleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.typography.pxToRem(24),
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    borderBottom: `1px solid ${alpha(theme.palette.divider, 0.12)}`,
    paddingBottom: theme.typography.pxToRem(16),
    paddingLeft: theme.typography.pxToRem(24),
    paddingRight: theme.typography.pxToRem(24),
  },

  hierarchyWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.typography.pxToRem(24),
    width: theme.typography.pxToRem(244),
    flexGrow: 2,
  },

  linkedEquipmentsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.typography.pxToRem(24),
    width: theme.typography.pxToRem(116),
    flexGrow: 1,
  },

  linkedDevicesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.typography.pxToRem(24),
    width: theme.typography.pxToRem(116),
    flexGrow: 1,
  },

  automationsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.typography.pxToRem(24),
    width: theme.typography.pxToRem(116),
    flexGrow: 1,
  },

  addressWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.typography.pxToRem(24),
    width: theme.typography.pxToRem(116),
    flexGrow: 1,
  },

  addressLine: {
    overflowWrap: 'anywhere',
  },

  idWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.typography.pxToRem(24),
    width: theme.typography.pxToRem(116),
    flexGrow: 1,
  },

  detailsTitle: {
    marginBottom: theme.typography.pxToRem(12),
  },
}));
