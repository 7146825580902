import { MarlinTheme } from '@marlin/shared/theme';
import { useTheme } from '@mui/material/styles';
import type { XYPosition } from '@xyflow/react';
import { useReactFlow, useStore } from '@xyflow/react';
import { useCallback, useEffect, useRef } from 'react';
import { makeStyles } from 'tss-react/mui';

import { TControlPointData, TControlPointProps } from './types';

export const useStyles = makeStyles()(() => ({
  controlPoint: {
    '&:focus, &:focus-within': {
      outline: 'none',
    },
  },
}));

export function ControlPoint({ id, index, x, y, active, setControlPoint, dragging }: TControlPointProps) {
  const theme = useTheme<MarlinTheme>();
  const container = useStore((store) => store.domNode);
  const { screenToFlowPosition } = useReactFlow();
  const ref = useRef<SVGCircleElement>(null);
  const { classes } = useStyles();

  const updatePosition = useCallback(
    (newPosition: XYPosition, drag: boolean, event: 'drag' | 'move' | 'release') => {
      setControlPoint(
        (points: TControlPointData[]) => {
          const point = points.find((p: TControlPointData) => p.id === id);
          if (!point) return undefined;
          return { ...point, ...newPosition, dragging: drag };
        },
        { index, event }
      );
    },
    [id, index, setControlPoint]
  );

  useEffect(() => {
    if (!container || !active || !dragging) return;

    const onPointerMove = (e: PointerEvent) => {
      updatePosition(screenToFlowPosition({ x: e.clientX, y: e.clientY }, { snapToGrid: false }), true, 'move');
    };

    const onPointerUp = (e: PointerEvent) => {
      container.removeEventListener('pointermove', onPointerMove);

      if (!active) {
        e.preventDefault();
      }

      updatePosition(screenToFlowPosition({ x: e.clientX, y: e.clientY }, { snapToGrid: true }), false, 'release');
    };

    container.addEventListener('pointermove', onPointerMove);
    container.addEventListener('pointerup', onPointerUp, { once: true });
    container.addEventListener('pointerleave', onPointerUp, { once: true });

    return () => {
      container.removeEventListener('pointermove', onPointerMove);
      container.removeEventListener('pointerup', onPointerUp);
      container.removeEventListener('pointerleave', onPointerUp);
    };
  }, [id, container, dragging, screenToFlowPosition, setControlPoint, updatePosition, active]);

  return (
    <circle
      ref={ref}
      tabIndex={0}
      id={id}
      className={`${classes.controlPoint} nopan nodrag`}
      cx={x}
      cy={y}
      r={dragging ? 4 : 2}
      strokeWidth={dragging ? 4 : 1}
      strokeOpacity={dragging ? 0.3 : 1}
      stroke={theme.palette.primary.main.toString()}
      fill={dragging ? theme.palette.primary.main.toString() : theme.palette.background.primary.toString()}
      style={{ pointerEvents: 'all' }}
      onPointerDown={(e) => {
        if (e.button === 2) return;
        updatePosition({ x, y }, true, 'drag');
      }}
      onPointerUp={(e) => {
        if (e.button === 2) return;
        updatePosition({ x, y }, false, 'release');
      }}
    />
  );
}
