import { MarlinTheme } from '@marlin/shared/theme';
import { ClickAwayListener } from '@mui/base';
import { Autocomplete, TextField } from '@mui/material';
import partition from 'lodash/partition';
import { HTMLAttributes, PropsWithChildren, forwardRef, useCallback, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { maxDatapointsCount, sensorsGroupId } from '../../consts';
import { content } from '../../content';
import { useSensorFilteringStore } from '../../context/sensor-filtering.context';
import { useOptions } from '../../hooks/use-options.hook';
import { TDatapointTypeValues, TSelectedDatapoint, customGroupAvailableDatapointsTypes } from '../../types';
import { Location } from './sensors-list.component';

const useStyles = makeStyles<{ withValidationMessage: boolean }>()((theme: MarlinTheme, { withValidationMessage }) => ({
  listbox: {
    overflowX: 'hidden',
    overflowY: 'scroll',
    height: '100%',
    maxHeight: '30vh',
    paddingTop: withValidationMessage ? theme.typography.pxToRem(34) : 0,
  },
  validationMessage: {
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    background: theme.palette.background.alternative,
    zIndex: theme.zIndex.modal,
    color: theme.palette.error.main,
    padding: theme.typography.pxToRem(8),
    borderBottom: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
    letterSpacing: theme.typography.pxToRem(0.4),
  },
  noDataMessage: {
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    background: theme.palette.background.alternative,
    zIndex: theme.zIndex.modal,
    padding: theme.typography.pxToRem(8),
    borderBottom: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
    letterSpacing: theme.typography.pxToRem(0.4),
  },
  selectedDatapoints: {
    width: '100%',
    lineHeight: theme.typography.pxToRem(38),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
    whiteSpace: 'nowrap',
  },
  inputWrapper: {
    '& input': {
      display: 'none',
    },
  },
}));

export const SelectSensorsAutocomplete = ({
  allChartSelectedSensors,
  handleOnSelect,
}: {
  allChartSelectedSensors: TSelectedDatapoint[];
  handleOnSelect: (value: TSelectedDatapoint[]) => void;
}) => {
  const { cx, classes } = useStyles({ withValidationMessage: allChartSelectedSensors.length === maxDatapointsCount });

  const [selectedChartType] = useSensorFilteringStore((store) => store.selectedChartType);

  const [isOpen, setIsOpen] = useState(false);

  const { options } = useOptions();

  const onClickAway = useCallback(() => {
    setIsOpen(false);
  }, []);

  const hasDatapointSameTypeAsChart = (datapointType?: TDatapointTypeValues, datapointName?: string) => {
    if (selectedChartType === 'custom') {
      return customGroupAvailableDatapointsTypes.find((item) => item === datapointType);
    }

    if (selectedChartType === 'temperature' && datapointName === 'MixingValve') {
      // MixingValve is in temperature group but has '%' uom so it should be only selectable on custom chart
      return false;
    }

    return datapointType === selectedChartType;
  };

  return (
    <Autocomplete
      open={isOpen}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={allChartSelectedSensors}
      multiple
      disableClearable
      data-testid="select-sensors-autocomplete"
      onClose={(_, reason) => {
        if (reason === 'blur') return;

        setIsOpen(false);
      }}
      onOpen={() => {
        setIsOpen(true);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          required
          label={content.SELECT_SENSORS}
          className={cx({ [classes.inputWrapper]: !!allChartSelectedSensors.length })}
        />
      )}
      options={options}
      noOptionsText={content.NO_DATA_MESSAGE}
      groupBy={(option) => option.locationId}
      getOptionLabel={(option) => option.label}
      classes={{
        listbox: classes.listbox,
      }}
      ListboxComponent={useCallback(
        (props: PropsWithChildren<HTMLAttributes<HTMLElement>>) => (
          <ListboxComponent {...props} onClickAway={onClickAway} />
        ),
        [onClickAway]
      )}
      renderTags={(value) => (
        <span className={classes.selectedDatapoints}>
          {value
            .map((option) =>
              option.parentType === 'sensor' ? option.label : `${option.label} - ${option.equipmentName}`
            )
            .join(', ')}
        </span>
      )}
      renderGroup={(params) => {
        const locationId = params.group;
        const filteredData = options.filter((option) => option.locationId === locationId);
        const locationName = filteredData[0].locationName;
        const [sensors, equipments] = partition(filteredData, (equipment) => equipment.parentType === 'sensor');
        const equipmentList = getUniqueEquipment(
          equipments.map((option) => ({
            name: option.equipmentName ?? '',
            manufacturerId: option.manufacturerId,
          }))
        )
          .map(({ manufacturerId, name }) => ({
            name,
            id: manufacturerId,
            locationId,
            sensorList: filteredData.filter(
              (option) =>
                option.manufacturerId === manufacturerId && hasDatapointSameTypeAsChart(option.type, option.name)
            ),
          }))
          .filter((equipment) => equipment.sensorList.length);

        const filteredSensors = sensors.filter((sensor) => hasDatapointSameTypeAsChart(sensor.type, sensor.name));

        const sensorsWithEquipments = filteredSensors.length
          ? [
              {
                id: sensorsGroupId,
                name: content.NEXA_SENSORS,
                locationId,
                sensorList: filteredSensors,
              },
              ...equipmentList,
            ]
          : equipmentList;

        if (sensorsWithEquipments.length === 0) {
          return null;
        }

        return (
          <div key={params.key}>
            {options[0].locationId === locationId && allChartSelectedSensors.length === maxDatapointsCount && (
              <div className={classes.validationMessage}>{content.VALIDATION_MESSAGE}</div>
            )}
            <Location
              locationName={locationName}
              equipmentList={sensorsWithEquipments}
              locationId={locationId}
              allChartSelectedSensors={allChartSelectedSensors}
              handleOnSelect={handleOnSelect}
            />
          </div>
        );
      }}
    />
  );
};

const getUniqueEquipment = (equipment: { name: string; manufacturerId: string }[]) => {
  return new Array(...new Set(equipment.map((equipment) => equipment.manufacturerId))).map((manufacturerId) => ({
    name: equipment.find((equipment) => equipment.manufacturerId === manufacturerId)?.name ?? '',
    manufacturerId: manufacturerId,
  }));
};

type TListboxComponentProps = PropsWithChildren<HTMLAttributes<HTMLElement>> & { onClickAway: () => void };

const ListboxComponent = forwardRef<HTMLDivElement, TListboxComponentProps>(
  ({ children, onClickAway, ...other }, ref) => {
    return (
      <ClickAwayListener onClickAway={onClickAway} mouseEvent={'onMouseDown'}>
        <div {...other} ref={ref}>
          {children}
        </div>
      </ClickAwayListener>
    );
  }
);
