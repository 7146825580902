import { useSearchFilter } from '@marlin/shared/utils/url-params';
import { Box } from '@mui/material';

interface ISearchWrapper {
  search: (value: string) => void;
  term: string;
}

interface ISearchWrapperProps {
  children: (props: ISearchWrapper) => JSX.Element;
}

export function SearchWrapper({ children }: ISearchWrapperProps) {
  const { term, search } = useSearchFilter();

  return (
    <Box>
      {children({
        search,
        term,
      })}
    </Box>
  );
}
