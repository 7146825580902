import { MarlinTheme } from '@marlin/shared/theme';
import ZoomInMapRounded from '@mui/icons-material/ZoomInMapRounded';
import ZoomOutMapRounded from '@mui/icons-material/ZoomOutMapRounded';
import { Button, useTheme } from '@mui/material';

import { content } from './content';

interface IToggleFullScreenButtonProps {
  isFullScreen: boolean;
  onClick: () => void;
}

export const ToggleFullScreenButton = ({ isFullScreen, onClick }: IToggleFullScreenButtonProps) => {
  const theme = useTheme<MarlinTheme>();
  return (
    <Button
      variant="outlined"
      sx={{ background: theme.palette.primary.contrastText, zIndex: 1500 }}
      onClick={onClick}
      endIcon={isFullScreen ? <ZoomInMapRounded /> : <ZoomOutMapRounded />}
      data-testid={isFullScreen ? 'exit-full-screen-button' : 'enter-full-screen-button'}
    >
      {isFullScreen ? content.EXIT_FULL_SCREEN : content.ENTER_FULL_SCREEN}
    </Button>
  );
};
