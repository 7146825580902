import { TFlowLink } from '@marlin/asset/data-access/asset';
import { ModalLarge } from '@marlin/shared/ui-modal';
import { Button } from '@mui/material';

import { AttachmentsTable } from './attachments-table.component';
import { content } from './content';

interface IAttachmentsValidationModalProps {
  onClose: () => void;
  data?: TFlowLink[];
  assetId?: string;
}

export const AttachmentValidationModal = ({ data, assetId, onClose }: IAttachmentsValidationModalProps) => {
  return (
    <ModalLarge
      onClose={onClose}
      title={content.MODAL_TITLE}
      body={<AttachmentsTable assetId={assetId} title={content.FLOW_LINK_ATTACHMENTS} data={data} />}
      footer={
        <Button variant="contained" onClick={onClose}>
          {content.MODAL_CANCEL}
        </Button>
      }
    />
  );
};
