import { getTimeFromNow } from '@marlin/shared/utils-common-date';
import { TGraphAsset, TNodeAlert } from '@marlin/system-map/data-access/system-map';

type TNodeData = TGraphAsset['data'];

const telemetryHourBoundary = 8;

export const isTelemetryError = (data: TNodeData) =>
  data.assetType === 'DEVICE' && (getTimeFromNow(data.lastReadingTime ?? null, 'hours') ?? 0) > telemetryHourBoundary;

export const hasLowPrioAlerts = (data: TNodeData) =>
  data.alerts && !!data.alerts?.length && data.alerts?.every((alert: TNodeAlert) => alert.criticality === 'LOW');

export const hasHighPrioAlerts = (data: TNodeData) =>
  data.alerts && !!data.alerts?.length && data.alerts?.some((alert: TNodeAlert) => alert.criticality === 'HIGH');

export const hasLostCommunicationAlert = (data: TNodeData) =>
  data.alerts &&
  !!data.alerts?.length &&
  data.alerts?.some((alert: TNodeAlert) => alert.alertCause === 'LOST_COMMUNICATION');

export const getAlert = (alerts: TNodeAlert[]): TNodeAlert => {
  const highCriticalityAlert = alerts.find((alert) => alert.criticality === 'HIGH');
  if (highCriticalityAlert) {
    return highCriticalityAlert;
  }
  return alerts[0];
};
