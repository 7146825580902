import { z } from 'zod';

export const locationTypeSchema = z.union([
  z.literal('MechanicalRoom'),
  z.literal(''),
  z.literal('GuestRoom'),
  z.literal('Laundry'),
  z.literal('Kitchen'),
  z.literal('Plant'),
]);

export type TLocationType = z.infer<typeof locationTypeSchema>;
