import { getListener, initializeListenerConnection } from '@marlin/shared/utils-realtime-communication';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { TSystemMapUpdates, systemMapUpdatesSchema } from '../flow-map-notification.model.schema';
import { SYSTEM_MAP_EVENTS } from './system-map-events.enum';

export class TimeoutError extends Error {}

export const getSystemMapUpdateNotificationSubject = (): BehaviorSubject<TSystemMapUpdates | undefined> => {
  return getListener<TSystemMapUpdates | undefined>(SYSTEM_MAP_EVENTS.SEND_SYSTEM_MAP_UPDATE, undefined);
};

const mapUpdateOperator = (
  source$: Observable<TSystemMapUpdates | undefined>
): Observable<TSystemMapUpdates | undefined> =>
  source$.pipe(
    map((notification: TSystemMapUpdates | undefined) => {
      const parsedResult = systemMapUpdatesSchema.safeParse(notification);
      if (!parsedResult.success) {
        return undefined;
      }
      return parsedResult.data;
    }),
    catchError((error) => {
      return throwError(error);
    })
  );

export const initSubject = (): Observable<void> => {
  return initializeListenerConnection<TSystemMapUpdates | undefined>(
    SYSTEM_MAP_EVENTS.SEND_SYSTEM_MAP_UPDATE,
    undefined,
    mapUpdateOperator
  );
};
