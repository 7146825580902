import { TransformApiUnitOfMeasuresToDevice } from '@marlin/shared/utils-format-reading';
import { z } from 'zod';

const calculatedValuesList = ['IntellistationLoadFlow', 'IntellistationEnergyUsed'] as const;

export const CalculatedValues = z.enum(calculatedValuesList);
export type TCalculatedValues = z.infer<typeof CalculatedValues>;

export const CalculatedValuesList = z.array(
  z.object({
    calculation: CalculatedValues,
    value: z.string().or(z.number().transform((val) => val.toString())),
    uom: TransformApiUnitOfMeasuresToDevice,
  })
);
export type TCalculatedValuesList = z.infer<typeof CalculatedValuesList>;
