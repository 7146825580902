import { FormField, Input } from '@marlin/shared/ui-form-common';
import { CommonChip } from '@marlin/shared/ui/chips';
import { Autocomplete, Paper, PaperProps } from '@mui/material';
import { AutocompleteRenderGetTagProps } from '@mui/material/Autocomplete/Autocomplete';
import React from 'react';

import { content } from './content';
import { TSelectedFlowMeter, TWebhookForm } from './schema';

export const ReadOnlySelectedFlowMeters = () => {
  return (
    <FormField<TWebhookForm> fieldName="devices">
      {(props) => (
        <Autocomplete
          multiple
          value={props.value}
          isOptionEqualToValue={(option: TSelectedFlowMeter, value: TSelectedFlowMeter) =>
            option.deviceId === value.deviceId
          }
          id="select-water-device"
          options={props.value as TSelectedFlowMeter[]}
          disabled={true}
          renderTags={(flowMeters: TSelectedFlowMeter[], getTagProps: AutocompleteRenderGetTagProps) =>
            flowMeters.map((flowMeter, index) => <CommonChip key={index} label={flowMeter.name} active />)
          }
          filterSelectedOptions
          limitTags={5}
          renderInput={(params) => <Input {...params} label={content.SELECTED_FLOW_METERS_LABEL} />}
          PaperComponent={(props: PaperProps) => <Paper {...props} />}
        />
      )}
    </FormField>
  );
};
