import { MarlinTheme } from '@marlin/shared/theme';
import { Icon, MenuItem, alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    marginBottom: theme.typography.pxToRem(16),
    pointerEvents: 'none',
  },
  icon: {
    marginRight: theme.typography.pxToRem(12),
    color: alpha(theme.palette.action.active, 0.54),
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export const SettingsChangeInfo = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.container}>
      <MenuItem>
        <div className={classes.iconWrapper}>
          <Icon baseClassName="material-symbols-outlined" className={classes.icon} data-testid="info-icon">
            info
          </Icon>
        </div>
        {content.SETTINGS_CHANGE_INFO}
      </MenuItem>
    </div>
  );
};
