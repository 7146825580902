export interface IFilterParams {
  [key: string]: string | string[] | number | undefined | null | object;
}

enum QUERY_KEY {
  USER_PORTFOLIOS = 'user-portfolios',
  PORTFOLIO_ORGANIZATIONS = 'portfolio-organizations',
  SYSTEM_VIEW = 'system-view',
  TRENDS = 'trends',
}

export const queryKey = {
  USER_PORTFOLIOS: () => [QUERY_KEY.USER_PORTFOLIOS],
  TRENDS: () => [QUERY_KEY.TRENDS],
  PORTFOLIO_ORGANIZATIONS: (filterParams?: IFilterParams) =>
    filterParams ? [QUERY_KEY.PORTFOLIO_ORGANIZATIONS, filterParams] : [QUERY_KEY.PORTFOLIO_ORGANIZATIONS],
  SYSTEM_VIEW: (filterParams?: IFilterParams) =>
    filterParams ? [QUERY_KEY.SYSTEM_VIEW, filterParams] : [QUERY_KEY.SYSTEM_VIEW],
};
