import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useCommonStyles = makeStyles()((theme: MarlinTheme) => ({
  node: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.body1.fontSize,
    paddingTop: theme.typography.pxToRem(8),
    paddingBottom: theme.typography.pxToRem(8),
    paddingLeft: theme.typography.pxToRem(16),
    paddingRight: theme.typography.pxToRem(16),
    width: 'auto',
  },
  handle: {
    visibility: 'hidden',
    '&.react-flow__handle': {
      minHeight: 1,
      minWidth: 1,
      height: 1,
      width: 1,
    },
    '&.react-flow__handle-right': {
      right: 0,
    },
    '&.react-flow__handle-left': {
      left: 0,
    },
    '&.react-flow__handle-top': {
      top: 0,
    },
    '&.react-flow__handle-bottom': {
      bottom: 0,
    },
  },
  nodeContent: {
    lineHeight: 1.6,
    display: 'flex',
  },
  nodeLabel: {
    marginRight: theme.typography.pxToRem(8),
    whiteSpace: 'nowrap',
  },
}));
