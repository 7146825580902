import { MarlinTheme } from '@marlin/shared/theme';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { TooltipContent } from '@marlin/shared/ui-form';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { Button, Chip } from '@mui/material';
import { ChipPropsColorOverrides, ChipPropsVariantOverrides } from '@mui/material/Chip/Chip';
import { alpha } from '@mui/material/styles';
import { OverridableStringUnion } from '@mui/types';
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';
import { ConfigFileDialog } from './config-file-dialog.component';

export enum STATUS {
  NEW = 'New',
  DRAFT = 'Draft',
  PUBLISHED = 'Published',
}

interface IStatus {
  status: STATUS;
  configFile?: string;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  rightSideActions: {
    display: 'flex',
    gap: theme.typography.pxToRem(8),
  },
  itemWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',
    gap: theme.typography.pxToRem(8),
  },
  label: {
    fontSize: theme.typography.pxToRem(13),
  },
  iconWrapper: {
    color: alpha(theme.palette.text.primary, 0.54),
  },
  icon: {
    width: theme.typography.pxToRem(20),
    height: theme.typography.pxToRem(20),
  },
  newChip: {
    color: theme.palette.alternative.contrastText,
    backgroundColor: theme.palette.alternative.main,
    '& .MuiChip-deleteIcon': {
      color: theme.palette.alternative.contrastText,
      ':hover': {
        color: theme.palette.alternative.contrastText,
      },
    },
    '& .MuiTouchRipple-root': {
      backgroundColor: theme.palette.alternative.main,
    },
    '& .MuiChip-icon': {
      color: theme.palette.alternative.contrastText,
    },
  },
}));

interface IItem {
  label: string;
  variant?: OverridableStringUnion<'filled' | 'outlined', ChipPropsVariantOverrides>;
  color?: OverridableStringUnion<
    'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
    ChipPropsColorOverrides
  >;
  configFile?: string;
}

const tooltipOptions = [content.CONFIGURATION_STATUS_TOOLTIP.DRAFT, content.CONFIGURATION_STATUS_TOOLTIP.PUBLISHED];

const TooltipWrapper = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.iconWrapper}>
      <Tooltip
        text={
          <TooltipContent tooltipHeader={content.CONFIGURATION_STATUS_TOOLTIP.TITLE} tooltipOptions={tooltipOptions} />
        }
      >
        <InfoRoundedIcon className={classes.icon} />
      </Tooltip>
    </div>
  );
};

const Item = ({ label, variant, color, configFile }: IItem) => {
  const [isConfigFileDialogOpened, setConfigFileDialogOpened] = useState(false);
  const { classes } = useStyles();

  const openConfigFileDialog = () => setConfigFileDialogOpened(true);
  const closeConfigFileDialog = () => setConfigFileDialogOpened(false);

  return (
    <div className={classes.itemWrapper}>
      <span className={classes.label}>{content.CONFIGURATION_STATUS_LABEL}</span>
      <Chip label={label} variant={variant} color={color} />
      <TooltipWrapper />
      {configFile && (
        <>
          <Button data-testid="open-config-file-modal-button" variant="outlined" onClick={openConfigFileDialog}>
            {content.CONFIG_FILE_DIALOG.SHOW_CONFIG_FILE}
          </Button>
          <ConfigFileDialog
            isOpen={isConfigFileDialogOpened}
            onClose={closeConfigFileDialog}
            configValue={configFile}
          />
        </>
      )}
    </div>
  );
};

const StatusItem = ({ status, configFile }: IStatus) => {
  const { classes } = useStyles();

  switch (status) {
    case STATUS.NEW:
      return (
        <div className={classes.itemWrapper}>
          <span className={classes.label}>{content.CONFIGURATION_STATUS_LABEL}</span>
          <Chip label={content.STATUS_NEW} variant="filled" className={classes.newChip} />
          <TooltipWrapper />
        </div>
      );
    case STATUS.DRAFT:
      return <Item label={content.STATUS_DRAFT} variant="filled" color="error" configFile={configFile} />;
    case STATUS.PUBLISHED:
      return <Item label={content.STATUS_PUBLISHED} variant="filled" color="success" configFile={configFile} />;
  }
};

export const Status = (props: IStatus) => {
  const { classes } = useStyles();

  return (
    <div className={classes.rightSideActions}>
      <StatusItem {...props} />
    </div>
  );
};
