import { AxiosError } from 'axios';

import { content } from './content';

enum INVITATION_ERROR {
  ALREADY_IN_PROGRESS = 'InvitationAlreadyInProgress',
  USER_ALREADY_EXISTS = 'UserWithThisEmailAlreadyExists',
  INVITATION_EXPIRED = 'InvitationExpired',
  INVITATION_ALREADY_ACCEPTED = 'InvitationAlreadyAccepted',
  INVALID_INVITATION = 'InvalidInvitation',
  RECIPENT_ALREADY_IN_ORGANIZATION = 'RecipientWithThisEmailAlreadyInOrganization',
}

export interface ICreateRecipientErrorProps {
  title: string;
}

interface IErrorHandlerProps {
  error: AxiosError<ICreateRecipientErrorProps>;
  onError: (message: string) => void;
}

export const errorHandler = ({ error, onError }: IErrorHandlerProps) => {
  if (error?.response?.status === 422) {
    switch (error.response.data.title) {
      case INVITATION_ERROR.ALREADY_IN_PROGRESS:
        onError(content.ERROR_INVITATION_ALREADY_IN_PROGRESS);
        break;
      case INVITATION_ERROR.USER_ALREADY_EXISTS:
        onError(content.ERROR_USER_ALREADY_EXISTS);
        break;
      case INVITATION_ERROR.INVITATION_EXPIRED:
        onError(content.ERROR_INVITATION_EXPIRED);
        break;
      case INVITATION_ERROR.INVITATION_ALREADY_ACCEPTED:
        onError(content.ERROR_INVITATION_ALREADY_ACCEPTED);
        break;
      case INVITATION_ERROR.INVALID_INVITATION:
        onError(content.ERROR_INVALID_INVITATION);
        break;
      case INVITATION_ERROR.RECIPENT_ALREADY_IN_ORGANIZATION:
        onError(content.ERROR_RECIPIENT_ALREADY_IN_ORGANIZATION);
        break;
      default:
        onError(content.ERROR_UNKNOWN);
    }
  }
};
