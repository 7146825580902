import { content } from './content';
import { DEVICE_CREATE_STATUS } from './types';

export const getErrorMessage = (status?: DEVICE_CREATE_STATUS): string => {
  if (!status) {
    return content.CREATE_SENSOR_ERROR;
  }

  switch (status) {
    case DEVICE_CREATE_STATUS.NOT_RECOGNISED: {
      return content.NOT_RECOGNISED;
    }

    case DEVICE_CREATE_STATUS.ALREADY_REGISTERED: {
      return content.ALREADY_SIGNALED;
    }

    default: {
      return content.CREATE_SENSOR_ERROR;
    }
  }
};
