import { createContext, useContext } from '@marlin/shared/utils-component-context';
import { ReactNode } from 'react';

import { IChart } from '../../collapsed-chart/chart-props';
import { IExtendedChart } from '../hooks/use-extended-chart.hook';

export type TExtendedChart = IChart & IExtendedChart;

export const ExtendedChartContext = createContext<TExtendedChart>();

export const ExtendedChartProvider = ({ children, value }: { children: ReactNode; value: TExtendedChart }) => {
  return <ExtendedChartContext.Provider value={value}>{children}</ExtendedChartContext.Provider>;
};
export const useExtendedChartContext = () => useContext(ExtendedChartContext);
