const homeSupportBasePath = '/';
const settingsBaseSupportPath = '/settings';
const settingsPeoplePath = 'people';
const settingsInviteNewUser = 'invite-user';
const settingsEditUserBasePath = 'user/:userId/update';
const analyticsBaseSupportPath = '/analytics';
const eventLogBasePath = 'event-log';
const equipmentBaseSupportPath = '/equipment';

export const routes = {
  home: {
    root: homeSupportBasePath,
  },
  settings: {
    root: settingsBaseSupportPath,
    people: {
      path: settingsPeoplePath,
      url: () => `${settingsBaseSupportPath}/${settingsPeoplePath}`,
    },
    editUser: {
      path: settingsEditUserBasePath,
      details: {
        url: (userId: string) => `${settingsBaseSupportPath}/${settingsEditUserBasePath}`.replace(':userId', userId),
      },
    },
    invite: {
      path: settingsInviteNewUser,
      url: () => `${settingsBaseSupportPath}/${settingsInviteNewUser}`,
    },
  },
  analytics: {
    root: analyticsBaseSupportPath,
    eventLog: {
      path: eventLogBasePath,
      url: () => `${analyticsBaseSupportPath}/${eventLogBasePath}`,
    },
  },
  equipment: {
    root: equipmentBaseSupportPath,
  },
};
