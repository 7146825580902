import { THomeAlertSchema, THomeLocationSchema } from '@marlin/asset/data-access/home-dashboard';
import { CRITICALITY } from '@marlin/shared/utils/zod';
import { useMemo } from 'react';

interface IUseImpact {
  locationAlertsLength: number;
  locationHasLowAlerts: boolean;
  locationHasHighAlerts: boolean;
  locationAlerts: THomeAlertSchema[];
}

export const useImpact = (location: THomeLocationSchema): IUseImpact => {
  const locationAlerts = useMemo(
    () =>
      [...(location.unattachedDevices || []), ...(location.equipment || []).flatMap((e) => e.devices)]
        .filter((d) => d)
        .flatMap((d) => d?.alerts || []),
    [location]
  );
  const locationAlertsLength = useMemo(() => locationAlerts.length, [locationAlerts.length]);
  const locationHasLowAlerts = useMemo(
    () => !!locationAlerts.find((alert: THomeAlertSchema) => alert.criticality === CRITICALITY.LOW),
    [locationAlerts]
  );
  const locationHasHighAlerts = useMemo(
    () => !!locationAlerts.find((alert: THomeAlertSchema) => alert.criticality === CRITICALITY.HIGH),
    [locationAlerts]
  );

  return useMemo(
    () => ({
      locationAlerts,
      locationAlertsLength,
      locationHasLowAlerts,
      locationHasHighAlerts,
    }),
    [locationAlerts, locationAlertsLength, locationHasLowAlerts, locationHasHighAlerts]
  );
};
