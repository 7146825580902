import { TDeviceType } from './model';

export const getUnitTypeSeparator = (deviceType?: TDeviceType): string => {
  switch (deviceType) {
    case 'TEMPERATURE': {
      return '';
    }

    default: {
      return ' ';
    }
  }
};
