import { TDateString } from '@marlin/shared/utils-common-date';
import { TApiUnitOfMeasures } from '@marlin/shared/utils-format-reading';
import { ISorting } from '@marlin/shared/utils-sorting';

export enum ALERT_CAUSE {
  BELOW = 'Below',
  ABOVE = 'Above',
  LEAK_DETECTION = 'LeakDetect',
  LOST_COMMUNICATION = 'LostCommunication',
  LOW_BATTERY = 'LowBatteryLevel',
  DEVICE_ALERT = 'DeviceAlert',
  DEVICE_ERROR = 'DeviceError',
}

export enum CHANNEL_TYPE_RESPONSE {
  EMAIL = 'Email',
  SMS = 'SMS',
  HOT_SOS = 'HotSOS',
}

export enum ALERT_STATUS_RESPONSE {
  FAILED = 'Failed',
  SENT = 'Sent',
  SNOOZED = 'Snoozed',
  IN_PROGRESS = 'InProgress',
}

export enum DEVICE_TYPE_RESPONSE {
  TEMPERATURE = 'Temperature',
  PRESSURE = 'Pressure',
  LEAK = 'Leak',
  FLOW_METER = 'FlowMeter',
  PULSE_METER = 'PulseMeter',
  GATEWAY = 'Gateway',
  EQUIPMENT = 'Equipment',
  UNKNOWN = 'Unknown',
}

export enum CRITICALITY_RESPONSE {
  LOW = 'Low',
  HIGH = 'High',
}

export type TRuleCategory = 'Health' | 'Performance' | 'Information';

export interface IAlertResponse {
  id: string;
  status: string;
  device: {
    id: string;
    name: string;
    type: string;
    isDeleted: boolean;
  };
  automation: {
    id: string;
    isSystemRule: boolean;
    name: string;
  };
  location: {
    id: string;
    name: string;
    isDeleted: boolean;
  } | null;
  equipment: {
    id: string;
    name: string;
    isDeleted: boolean;
  };
  startTime: string;
  resolveTime: string;
  lastReading: string;
  readingValue: number;
  thresholdMin: number;
  thresholdMax: number;
  ruleCategory: TRuleCategory;
  criticality: CRITICALITY_RESPONSE;
  channels: IChannelResponse[];
  snoozeUntil: TDateString;
  snoozeIntervalInMinutes: number;
  alertCause: ALERT_CAUSE;
  description: string | null;
  title: string | null;
  hyperlink: string | null;
  deviceEventCode: string | null;
  additionalValues: { name: string; value: string }[];
  uoM: TApiUnitOfMeasures;
}

export interface IChannelResponse {
  channelType: CHANNEL_TYPE_RESPONSE;
  recipients: IRecipientResponse[];
}

export interface IRecipientResponse {
  recipientId: string;
  status: string;
  error: string;
}

export interface IPagedAlertsResponse {
  totalItems: {
    Current: number;
    Resolved: number;
    All: number;
    Snoozed: number;
  };
  pagination: {
    page: number;
    pageSize: number;
    totalItems: number;
  };
  data: IAlertResponse[];
}

export type TDeviceType = 'Pressure' | 'Temperature' | 'Leak' | 'PulseMeter' | 'FlowMeter' | 'Gateway' | 'Equipment';

export type TCriticalityRequest = 'Low' | 'High';

export type TAlertStatus = 'Current' | 'Resolved' | 'Snoozed';

export interface IAlertParamsRequest {
  locations: string[];
  devices: string[];
  equipments: string[];
  deviceTypes: TDeviceType[];
  search: string;
  criticality: TCriticalityRequest | null;
  status: TAlertStatus | null;
  ruleCategories: TRuleCategory[];
  pageSize: number;
  page: number;
  eventCodes: string[];
  sorting?: ISorting;
}
