import IMask from 'imask';
import { ComponentType, forwardRef } from 'react';
import { IMaskInput } from 'react-imask';

interface ITextMaskExternalProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  mask: string;
  unmask?: boolean;
  prepare?: (value: string, masked: unknown, flags: IMask.AppendFlags) => string;
  value: string;
  className: string;
}

interface ITextMaskCustomProps extends ITextMaskExternalProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

type TMaskInputProps = {
  value?: number | string;
  unmask?: 'typed' | boolean;
  prepare?: (value: string, masked: IMask.MaskedDynamic, flags: IMask.AppendFlags) => string;
  inputRef?: (el: HTMLInputElement) => void;
  className?: string;
  onAccept?: <T>(value: string, maskRef: IMask.InputMask<IMask.AnyMaskedOptions>, ...args: T[]) => void;
  onComplete?: <T>(value: string, maskRef: IMask.InputMask<IMask.AnyMaskedOptions>, ...args: T[]) => void;
  dispatch?: (
    value: string,
    masked: IMask.MaskedDynamic,
    flags: IMask.AppendFlags
  ) => IMask.AnyMasked | null | undefined;
} & IMask.AnyMaskedOptions;

export function InputMaskComponent(props: ITextMaskCustomProps): JSX.Element {
  const { inputRef, onChange, mask, name, prepare, ...other } = props;
  const MaskInput: ComponentType<TMaskInputProps> = IMaskInput as ComponentType<TMaskInputProps>;

  return (
    <MaskInput
      {...other}
      mask={mask}
      prepare={prepare}
      inputRef={inputRef}
      onAccept={(value: string) => onChange({ target: { name: props.name, value } })}
      className={props.className}
    />
  );
}

export const InputMask = forwardRef((props: ITextMaskExternalProps, ref) => (
  <InputMaskComponent inputRef={ref as (ref: HTMLInputElement | null) => void} {...props} />
));
