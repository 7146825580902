import { TChartId } from '@marlin/asset/shared/equipment-config';
import { IRangeFilter, RANGE_FILTER, TDurationDetails, useRangeFilter } from '@marlin/shared/utils-chart';
import { createContext, useContext } from '@marlin/shared/utils-component-context';
import { useState } from 'react';

interface IDurationContext {
  rangeFilter: IRangeFilter;
  handleRangeFilterChange: (option?: RANGE_FILTER) => void;
  details: TDurationDetails<TChartId>;
  setDetails: React.Dispatch<React.SetStateAction<TDurationDetails<TChartId>>>;
}

export const DurationContext = createContext<IDurationContext>();

export const DurationProvider = ({ children }: { children: React.ReactNode }) => {
  const [details, setDetails] = useState<TDurationDetails<TChartId>>({} as TDurationDetails<TChartId>);
  const { handleRangeFilterChange, rangeFilter } = useRangeFilter(RANGE_FILTER.HOURS_8);

  return (
    <DurationContext.Provider
      value={{
        rangeFilter,
        handleRangeFilterChange,
        details,
        setDetails,
      }}
    >
      {children}
    </DurationContext.Provider>
  );
};

export const useDurationContext = () => useContext(DurationContext);
