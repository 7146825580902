import { MarlinTheme } from '@marlin/shared/theme';
import { RANGE_FILTER } from '@marlin/shared/utils-chart';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import { FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

const content = {
  DEVICE_READINGS_RANGE_PREFIX: 'View the past',
  DEVICE_READINGS_RANGE_HOURS_8: '8 hours',
  DEVICE_READINGS_RANGE_HOURS_24: '24 hours',
  DEVICE_READINGS_RANGE_DAYS_7: '7 days',
};

export interface IChartRangeOption {
  id: RANGE_FILTER;
  name: ReactNode;
}

export const chartRangeTypeOptions: IChartRangeOption[] = [
  {
    id: RANGE_FILTER.HOURS_8,
    name: content.DEVICE_READINGS_RANGE_HOURS_8,
  },
  {
    id: RANGE_FILTER.HOURS_24,
    name: content.DEVICE_READINGS_RANGE_HOURS_24,
  },
  {
    id: RANGE_FILTER.DAYS_7,
    name: content.DEVICE_READINGS_RANGE_DAYS_7,
  },
];

export const useStyles = makeStyles<{ fullWidth?: boolean }>()((theme: MarlinTheme, { fullWidth }) => ({
  viewing: {
    color: theme.palette.text.secondary,
    marginRight: theme.typography.pxToRem(8),
  },
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    whiteSpace: 'nowrap',
    width: fullWidth ? '100%' : 'auto',
  },
  custom: {
    padding: 0,
  },
  menuItem: {
    color: theme.palette.text.secondary,
  },
  iconWrapper: {
    marginLeft: 'auto',
  },
  input: {
    backgroundColor: theme.palette.background.primary,
  },
}));

interface ISelectDuration {
  rangeFilter: RANGE_FILTER;
  onRangeFilterChange: (event: SelectChangeEvent<RANGE_FILTER>) => void;
  onRefresh: () => void;
  options?: IChartRangeOption[];
  customRenderValue?: (id: RANGE_FILTER) => ReactNode;
  fullWidth?: boolean;
}

const id = 'duration-select';

export const SelectDuration = ({
  rangeFilter,
  onRangeFilterChange,
  onRefresh,
  options,
  customRenderValue,
  fullWidth,
}: ISelectDuration) => {
  const { classes, cx } = useStyles({ fullWidth });

  return (
    <div className={classes.wrapper} data-testid="duration-select">
      {!fullWidth && (
        <Typography variant="caption" className={classes.viewing}>
          {content.DEVICE_READINGS_RANGE_PREFIX}
        </Typography>
      )}
      <FormControl size="small" fullWidth={fullWidth}>
        {fullWidth && <InputLabel id={id}>{content.DEVICE_READINGS_RANGE_PREFIX}</InputLabel>}
        <Select
          renderValue={customRenderValue ? (id) => customRenderValue(id) : undefined}
          value={rangeFilter}
          onChange={onRangeFilterChange}
          data-testId={id}
          id={id}
          className={classes.input}
          label={fullWidth ? content.DEVICE_READINGS_RANGE_PREFIX : undefined}
        >
          {(options ?? chartRangeTypeOptions).map(({ name, id }, index) => {
            return (
              <MenuItem
                key={index}
                className={cx(classes.menuItem, { [classes.custom]: id === RANGE_FILTER.CUSTOM })}
                value={id}
              >
                {name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <div className={classes.iconWrapper}>
        <IconButton aria-label="refresh-device-readings" onClick={onRefresh} data-testid="refresh-button">
          <RefreshRoundedIcon />
        </IconButton>
      </div>
    </div>
  );
};
