import { OrganizationSwitcher } from '@marlin/account-ui-organization-switcher';
import { MarlinTheme } from '@marlin/shared/theme';
import { useDesktopSideNavbarCollapse } from '@marlin/shared/utils-common-desktop-sidenav';
import { makeStyles } from 'tss-react/mui';

import { LockNavbar } from './components/lock-navbar.component';
import { SideNav } from './side-nav.component';
import { TSideNavElement } from './types';

interface IStyles {
  isCollapsed: boolean;
  desktopNavbarLocked: boolean;
}

export const useStyles = makeStyles<IStyles>()((theme: MarlinTheme, { isCollapsed, desktopNavbarLocked }) => ({
  lockNavbar: {
    position: 'fixed',
    bottom: 24,
    opacity: desktopNavbarLocked ? 1 : isCollapsed ? 0 : 1,
    left: desktopNavbarLocked
      ? theme.typography.pxToRem(230)
      : isCollapsed
      ? theme.typography.pxToRem(30)
      : theme.typography.pxToRem(230),
    transition: 'left 0.3s ease-in-out, opacity 0.3s ease-in-out',
  },
}));

interface IDesktopSideNavProps {
  topNavLinks: TSideNavElement[];
  bottomNavLinks: TSideNavElement[];
  orgSwitcherVariant: 'marlin' | 'portfolio' | 'support';
}

export const DesktopSideNavComponent = ({ topNavLinks, bottomNavLinks, orgSwitcherVariant }: IDesktopSideNavProps) => {
  const { desktopNavbarCollapsed, desktopNavbarLocked } = useDesktopSideNavbarCollapse();
  const { classes } = useStyles({ isCollapsed: desktopNavbarCollapsed, desktopNavbarLocked });

  return (
    <SideNav
      isCollapsed={desktopNavbarCollapsed}
      topNavLinks={topNavLinks}
      bottomNavLinks={bottomNavLinks}
      app={orgSwitcherVariant}
    >
      <OrganizationSwitcher
        variant={orgSwitcherVariant}
        isCollapsed={desktopNavbarCollapsed}
        desktopNavbarLocked={desktopNavbarLocked}
      />
      <div className={classes.lockNavbar}>
        <LockNavbar />
      </div>
    </SideNav>
  );
};
