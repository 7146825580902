import { MarlinTheme } from '@marlin/shared/theme';
import { PERMISSIONS, usePermission } from '@marlin/shared/utils-permission';
import { Typography } from '@mui/material';
import { ReactNode, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../content';
import { MenuItems } from '../../../menu.component';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  title: {
    marginTop: 0,
    marginBottom: 0,
    [theme.breakpoints.down('md')]: {
      padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(16)}`,
      fontSize: theme.typography.pxToRem(16),
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.typography.pxToRem(16),
    width: '100%',
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  editDeleteButton: {
    width: theme.typography.pxToRem(96),
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  subtitles: {
    [theme.breakpoints.down('md')]: {
      padding: `0 ${theme.typography.pxToRem(16)} ${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(16)}`,
    },
  },
}));

interface IMobilePageHeader {
  title: string;
  detailEditLink: string;
  subtitle?: ReactNode;
}

const useEquipmentMenuItems = (redirectToEdit: () => void) => {
  const allowEdit = usePermission(PERMISSIONS.EDIT_EQUIPMENT);

  return useMemo(
    () =>
      [
        allowEdit
          ? {
              label: content.EDIT_BUTTON,
              iconName: 'edit',
              handleClick: redirectToEdit,
            }
          : null,
      ].filter(Boolean) as { label: string; iconName: string; handleClick: () => void }[],
    [allowEdit, redirectToEdit]
  );
};

export const MobilePageHeader = ({ title, subtitle, detailEditLink }: IMobilePageHeader) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const redirectToEdit = useCallback(
    () => navigate(detailEditLink, { state: { prevLocation: location.pathname } }),
    [navigate, detailEditLink, location]
  );
  const equipmentMenu = useEquipmentMenuItems(redirectToEdit);

  return (
    <div className={classes.wrapper}>
      <div className={classes.titleWrapper} data-testid="header-container">
        <h1 data-testid="header-title" className={classes.title}>
          {title}
        </h1>
        <MenuItems menuItems={equipmentMenu} />
      </div>
      <Typography
        className={classes.subtitles}
        data-testid="mobile-equipment-details-subtitle"
        variant="subtitle1"
        textAlign="left"
      >
        {subtitle}
      </Typography>
    </div>
  );
};
