import * as Yup from 'yup';

import { content } from './content';
import { ICreateRecipient } from './types';

export const CreateRecipientFormValidationSchema: Yup.SchemaOf<ICreateRecipient> = Yup.object({
  firstName: Yup.string().required(content.VALIDATION.REQUIRED).max(200, content.VALIDATION.MAX_LENGTH(200)),
  lastName: Yup.string().required(content.VALIDATION.REQUIRED).max(200, content.VALIDATION.MAX_LENGTH(200)),
  phoneNumber: Yup.string().required(content.VALIDATION.REQUIRED).length(12, content.VALIDATION.REQUIRED_LENGTH(10)),
  email: Yup.string().email(content.VALIDATION.EMAIL).required(content.VALIDATION.REQUIRED),
});
