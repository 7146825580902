import { TAutomation } from '@marlin/alert/data-access/automated-action';
import { useRouter } from '@marlin/shared/utils-router';
import { useCallback, useMemo } from 'react';

import { AutomationCardUi } from './automation-card-ui.component';
import { CHANNEL_TYPE, IChannels, IExtendedAutomation, IExtendedChannels } from './types';

interface IDeviceHealthCardProps extends Record<string, unknown> {
  item: IExtendedAutomation;
  onItemEdit: (id: string) => void;
  onItemToggle: (item: TAutomation) => void;
  allowEdit?: boolean;
  allowDelete?: boolean;
}

const mapAutomation = (automation: IExtendedAutomation): TAutomation => {
  const mapExtendedChannelIntoChannel = (channel: IExtendedChannels[CHANNEL_TYPE]): IChannels => {
    switch (channel?.type) {
      case CHANNEL_TYPE.EMAIL:
      case CHANNEL_TYPE.SMS: {
        return {
          [channel.type]: {
            type: channel.type,
            recipients: channel.recipients?.map((item) => ({
              recipientId: item.id,
              recipientType: item.recipientType,
            })),
          },
        };
      }

      case CHANNEL_TYPE.IN_APP:
      case CHANNEL_TYPE.HOT_SOS: {
        return {
          [channel.type]: {
            type: channel.type,
          },
        };
      }

      default: {
        return {};
      }
    }
  };

  const mapChannels = (channels: IExtendedChannels): IChannels => {
    return Object.entries(channels).reduce(
      (acc: IChannels, [, channel]: [string, IExtendedChannels[CHANNEL_TYPE]]): IChannels => ({
        ...acc,
        ...mapExtendedChannelIntoChannel(channel),
      }),
      {}
    );
  };

  return {
    ...automation,
    isSystemRule: true,
    channels: mapChannels(automation.channels),
  };
};

function DeviceHealthCard({ item, onItemEdit, onItemToggle, allowEdit }: IDeviceHealthCardProps) {
  const handleEditItem = useCallback(() => {
    onItemEdit(item.id);
  }, [item.id, onItemEdit]);
  const router = useRouter();
  const currentUrl = useMemo(() => router.getUrl(), [router]);

  const handleToggle = useCallback(
    (isEnabled: boolean) => {
      onItemToggle(
        mapAutomation({
          ...item,
          isEnabled,
          isSystemRule: true,
        })
      );
    },
    [item, onItemToggle]
  );

  return (
    <AutomationCardUi
      item={item}
      currentUrl={currentUrl}
      handleEditItem={handleEditItem}
      handleToggle={handleToggle}
      handleDeleteItem={() => {}}
      allowEdit={allowEdit}
      healthTab={true}
    />
  );
}

export default DeviceHealthCard;
