import { TLocationHierarchyLevel } from '@marlin/asset/data-access/location';

type TDefaultExpandedIds = string[];

const hierarchyIdReducer = (acc: TDefaultExpandedIds, hierarchyLevel: TLocationHierarchyLevel): TDefaultExpandedIds => {
  if (hierarchyLevel.children.length > 0) {
    return [hierarchyLevel.id, ...hierarchyLevel.children.reduce(hierarchyIdReducer, acc)];
  }

  return [...acc, hierarchyLevel.id];
};

export const getDefaultExpandedIds = (hierarchyLevel?: TLocationHierarchyLevel): TDefaultExpandedIds => {
  if (hierarchyLevel) {
    return hierarchyLevel.children.reduce(hierarchyIdReducer, [hierarchyLevel.id]);
  }

  return [];
};
