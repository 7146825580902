import { useEquipmentDetails } from '@marlin/asset/data-access/equipment';
import { SettingsErrorPage, SettingsSectionSkeleton } from '@marlin/asset/ui/settings';
import { MarlinTheme } from '@marlin/shared/theme';
import { useIdFromPathname } from '@marlin/shared/utils/url-params';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Button, Typography } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../content';
import { useResetModal } from '../../../hooks/settings/use-reset-modal.component';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    maxWidth: theme.typography.pxToRem(746),
  },
  accordionRoot: {
    '::before': {
      content: 'none',
    },
  },
  accordionSummaryRoot: {
    padding: `${theme.typography.pxToRem(16)} ${theme.typography.pxToRem(24)}`,
    minHeight: theme.typography.pxToRem(64),
    [theme.breakpoints.down('md')]: {
      padding: theme.typography.pxToRem(16),
    },
  },
  accordionSummaryContent: {
    margin: 0,
  },
  accordionExpanded: {
    borderBottom: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
  },
  accordionDetailsRoot: {
    margin: `0 ${theme.typography.pxToRem(16)} ${theme.typography.pxToRem(16)} 0`,
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(16)}`,
    borderTop: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
    borderBottom: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
  },
}));

export const BradleyPartitionSettings = () => {
  const { classes } = useStyles();
  const equipmentId = useIdFromPathname();
  const { data: equipmentDetails, isLoading, error, refetch } = useEquipmentDetails({ equipmentId });
  const { gatewayId, connectionId, connectionAddressId } = equipmentDetails?.additionalProperties ?? {};
  const { openResetModal } = useResetModal({
    gatewayId: gatewayId ?? '',
    connectionId: connectionId ?? '',
    addressId: connectionAddressId ?? '',
  });

  if (error) {
    return <SettingsErrorPage onRefreshClick={refetch} />;
  }

  return (
    <div className={classes.container}>
      <Accordion
        elevation={0}
        disableGutters
        classes={{
          root: classes.accordionRoot,
          expanded: classes.accordionExpanded,
        }}
        defaultExpanded
        data-testid="partition-settings"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          classes={{
            root: classes.accordionSummaryRoot,
            content: classes.accordionSummaryContent,
          }}
        >
          <Typography variant="h6">{content.SYSTEM}</Typography>
        </AccordionSummary>
        <AccordionDetails
          classes={{
            root: classes.accordionDetailsRoot,
          }}
          data-testid="partition-settings-details"
        >
          {isLoading && <SettingsSectionSkeleton />}
          <div className={classes.content}>
            {content.OCCUPANCY_COUNT_AND_TIME}
            <Button data-testId="reset" onClick={openResetModal} variant="outlined" color="error">
              {content.RESET}
            </Button>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
