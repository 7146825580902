import { zodResolver } from '@hookform/resolvers/zod';
import { useCurrentUser } from '@marlin/account-data-access-organization';
import { useInviteUser } from '@marlin/account-data-access-user-invite';
import { MarlinTheme } from '@marlin/shared/theme';
import { firstNameZodSchema, jobTitleZodSchema, lastNameZodSchema, roleZodSchema } from '@marlin/shared/ui-form';
import { IFormInputProps } from '@marlin/shared/ui-form-common';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { getLogger } from '@marlin/shared/utils/logger';
import { emailZodSchema } from '@marlin/shared/utils/zod';
import { Paper } from '@mui/material';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';
import * as z from 'zod';

import { content } from './content';
import { IInvitationError, INVITATION_ERROR, errorHandler, isSupportUser } from './error-handler';
import { InviteNewUserForm } from './invite-new-user.form';

export const InviteUserValidationSchema = z.object({
  firstName: firstNameZodSchema,
  lastName: lastNameZodSchema,
  email: emailZodSchema,
  title: jobTitleZodSchema,
  role: roleZodSchema,
});

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: theme.typography.pxToRem(712),
    padding: theme.typography.pxToRem(24),
  },
}));

export const SettingsInviteNewUser = () => {
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync: inviteUser } = useInviteUser();
  const [errorMessage, setErrorMessage] = useState('');
  const { data: userData } = useCurrentUser();

  const { goTo } = useRouter();

  const form = useForm<IFormInputProps>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      title: '',
    },
    mode: 'onTouched',
    resolver: zodResolver(InviteUserValidationSchema),
  });

  const onCancel = () => {
    form.reset();
    goTo(routes.settings.people.url());
  };

  const resetError = () => {
    setErrorMessage('');
  };

  const onError = (message: string) => {
    setErrorMessage(message);
  };

  const onSubmit: SubmitHandler<IFormInputProps> = (data) => {
    if (form.formState.isValid) {
      return inviteUser(data)
        .then(() => {
          enqueueSnackbar(content.SUCCESS_MESSAGE, {
            variant: 'success',
            preventDuplicate: true,
          });
          goTo(routes.settings.people.url());
          getLogger()?.track('UserInvited');
        })
        .catch((error: AxiosError<IInvitationError>) => {
          if (
            error.response?.data.errorCode === INVITATION_ERROR.USER_ALREADY_EXISTS &&
            isSupportUser(data.email, userData)
          ) {
            enqueueSnackbar(content.ERROR_SUPPORT_USER_ALREADY_EXISTS, {
              variant: 'error',
              preventDuplicate: true,
            });
          } else {
            errorHandler({ error, onError });
          }
        });
    }

    return;
  };

  return (
    <div data-testid="invite-user-container">
      <Paper elevation={1} className={classes.formContainer} data-testid="card">
        <InviteNewUserForm
          form={form}
          onSubmit={onSubmit}
          onCancel={onCancel}
          resetError={resetError}
          errorMessage={errorMessage}
        />
      </Paper>
    </div>
  );
};
