import { TExtractFnReturnType, useQuery } from '@marlin/shared/utils/react-query';
import { useMemo } from 'react';

import { getRecipients } from '../infrastructure/recipient';
import { queryKey } from '../query-key.enum';
import { IListFilters } from '../recipient.model';

interface IGetRecipientsParamsRequest {
  search?: string;
  page: number;
  pageSize: number;
  sorting?: {
    direction: 'Ascending' | 'Descending';
    sortBy: string;
  };
}

export const mapGetRecipientsParams = (params: IListFilters): IGetRecipientsParamsRequest => ({
  search: params.search?.trim(),
  page: params.page,
  pageSize: params.pageSize,
  sorting: params.sorting,
});

export const useRecipientsWithFilters = (params: IListFilters) => {
  const queryParams = useMemo(() => mapGetRecipientsParams(params), [params]);

  return useQuery<TExtractFnReturnType<typeof getRecipients>>({
    queryKey: queryKey.RECIPIENTS_FILTER(queryParams),
    queryFn: () => getRecipients(queryParams),
  });
};
