import { useEquipmentDetails } from '@marlin/asset/data-access/equipment';
import { useLocation, useLocationHierarchy } from '@marlin/asset/data-access/location';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TreeItem from '@mui/lab/TreeItem';
import TreeView from '@mui/lab/TreeView';
import { createElement } from 'react';
import { useNavigate } from 'react-router-dom';

interface IEquipmentLocationHierarchyProps {
  equipmentId: string;
}

interface IRawTreeNode {
  id: string;
  name: string;
  children: Array<ITreeNode>;
}

interface ITreeNode {
  nodeId: string;
  label: string;
}

const EquipmentLocationHierarchy = ({ equipmentId }: IEquipmentLocationHierarchyProps) => {
  const equipmentQuery = useEquipmentDetails({ equipmentId });
  const equipmentLocationId = equipmentQuery?.data?.locationId || '';
  const locationHierarchyQuery = useLocationHierarchy({ locationId: equipmentLocationId });
  const equipmentLocationQuery = useLocation({ locationId: equipmentLocationId });
  const navigate = useNavigate();
  if (
    equipmentQuery.isLoading ||
    equipmentQuery.isInitialLoading ||
    locationHierarchyQuery.isLoading ||
    locationHierarchyQuery.isInitialLoading ||
    equipmentLocationQuery.isLoading
  ) {
    return <LoadingSpinner />;
  }
  let treeList: JSX.Element;
  const expanded: string[] = [equipmentLocationId];
  if (!equipmentLocationId) {
    treeList = <TreeItem nodeId={equipmentQuery?.data?.id || ''} label={equipmentQuery?.data?.name} />;
  } else {
    let treeNode = locationHierarchyQuery?.data as unknown as IRawTreeNode;
    const treeNodesList: ITreeNode[] = [];
    while (treeNode?.id !== equipmentQuery?.data?.locationId) {
      treeNodesList.unshift({ label: treeNode.name, nodeId: treeNode.id });
      treeNode = treeNode.children[0] as unknown as IRawTreeNode;
    }
    treeList = (
      <TreeItem
        nodeId={equipmentLocationQuery?.data?.id || ''}
        label={equipmentLocationQuery?.data?.name}
        onClick={() => navigate(`/locations/${equipmentLocationId}`)}
      >
        <TreeItem nodeId={equipmentQuery?.data?.id || ''} label={equipmentQuery?.data?.name} />
      </TreeItem>
    );
    treeNodesList.forEach(({ label, nodeId }) => {
      expanded.unshift(nodeId);
      treeList = createElement(
        TreeItem,
        {
          label,
          nodeId,
          onClick: () => navigate(`/locations/${nodeId}`),
        },
        treeList
      );
    });
  }
  const treeView = createElement(
    TreeView,
    {
      'aria-label': 'equipment location hierarchy',
      defaultCollapseIcon: <ExpandMoreIcon />,
      defaultExpandIcon: <ChevronRightIcon />,
      selected: equipmentQuery?.data?.id,
      expanded,
      sx: { flexGrow: 1, maxWidth: 400, overflowY: 'auto' },
    },
    treeList
  );
  return treeView;
};

export { EquipmentLocationHierarchy };
