import { useSearchParams } from '@marlin/shared/utils-router';
import { useCallback } from 'react';

import { TEditedSetting } from '../types';

interface IUseSettingListItem {
  name: string;
  setEditedSetting: (value: TEditedSetting | undefined) => void;
  setIsDirty: (value: boolean) => void;
}

export const useSettingListItem = ({ name, setEditedSetting, setIsDirty }: IUseSettingListItem) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const settingId = searchParams.get('settingId');
  const isEditing = searchParams.get('settingId') === name;

  const onEditClick = useCallback(() => {
    if (!isEditing) {
      setSearchParams((currentParams) => {
        currentParams.set('settingId', name);

        return currentParams;
      });
    }
  }, [isEditing, name, setSearchParams]);

  const onCancelClick = () => {
    setSearchParams(
      (currentParams) => {
        if (settingId) {
          currentParams.delete('settingId');
        }

        return currentParams;
      },
      { state: { shouldIgnoreGuard: true } }
    );
    setEditedSetting(undefined);
    setIsDirty(false);
  };

  return { isEditing, onEditClick, onCancelClick };
};
