import { content } from './content';

export enum PERIOD_ENUM {
  DAY = '24h',
  WEEK = '7d',
  MONTH = '30d',
  QUARTER = '3m',
  YEAR = '12m',
  CUSTOM = 'Custom',
}

type TPeriodMap = {
  [key in PERIOD_ENUM]: {
    abbr: string;
    curr: string;
    prev: string;
    button: string;
  };
};

export const periodMap: TPeriodMap = {
  [PERIOD_ENUM.DAY]: {
    abbr: content.ABBREVIATIONS.DAY,
    curr: content.LONG.DAY,
    prev: content.SHORT.DAY,
    button: content.LONG.DAY,
  },
  [PERIOD_ENUM.WEEK]: {
    abbr: content.ABBREVIATIONS.WEEK,
    curr: content.LONG.WEEK,
    prev: content.SHORT.WEEK,
    button: content.LONG.WEEK,
  },
  [PERIOD_ENUM.MONTH]: {
    abbr: content.ABBREVIATIONS.MONTH,
    curr: content.LONG.MONTH,
    prev: content.SHORT.MONTH,
    button: content.LONG.MONTH,
  },
  [PERIOD_ENUM.QUARTER]: {
    abbr: content.ABBREVIATIONS.QUARTER,
    curr: content.LONG.QUARTER,
    prev: content.SHORT.QUARTER,
    button: content.LONG.QUARTER,
  },
  [PERIOD_ENUM.YEAR]: {
    abbr: content.ABBREVIATIONS.YEAR,
    curr: content.LONG.YEAR,
    prev: content.SHORT.YEAR,
    button: content.LONG.YEAR,
  },
  [PERIOD_ENUM.CUSTOM]: {
    abbr: content.ABBREVIATIONS.CUSTOM,
    curr: content.SELECTED_PERIOD,
    prev: content.PREVIOUS_PERIOD,
    button: content.LONG.CUSTOM,
  },
};
