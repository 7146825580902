import { MarlinTheme } from '@marlin/shared/theme';
import { PageContainer, PageHeader, Paper } from '@marlin/shared/ui-page';
import { dateAdapter } from '@marlin/shared/utils-common-date';
import AutoGraphRoundedIcon from '@mui/icons-material/AutoGraphRounded';
import { Alert, FormControlLabel, MenuItem, Select, Switch } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { Chart } from './chart.component';
import { LeakHeatMap } from './leak-heatmap.component';
import { Sankey } from './sankey.component';
import { IChartSettings } from './types';

const chartSettingsMap: Record<'normal' | 'normalMonth' | 'stressTest', IChartSettings> = {
  normal: {
    label: 'Normal usage - week, 168 points',
    minDate: dateAdapter.date()?.subtract(1, 'week') ?? null,
    maxDate: dateAdapter.date(),
    buckets: 168,
  },
  normalMonth: {
    label: 'Normal usage - month, 300 points',
    minDate: dateAdapter.date()?.subtract(1, 'month') ?? null,
    maxDate: dateAdapter.date(),
    buckets: 304,
  },
  stressTest: {
    label: 'Stress test - year, 4000 points',
    minDate: dateAdapter.date()?.subtract(1, 'year') ?? null,
    maxDate: dateAdapter.date(),
    buckets: 4000,
  },
};

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  chartIcon: {
    height: theme.typography.pxToRem(32),
    width: 'auto',
  },
}));

export function Analytics() {
  const { classes } = useStyles();

  const [isApex, setApex] = useState(false);
  const [chartSettings, setChartSettings] = useState<'normal' | 'normalMonth' | 'stressTest'>('normal');

  return (
    <PageContainer>
      <PageHeader
        icon={<AutoGraphRoundedIcon className={classes.chartIcon} />}
        prefix="multi-charts"
        title="Analytics v2 (Plotly)"
      />
      <Paper>
        <Sankey />
        <Select
          value={chartSettings}
          onChange={(event) => setChartSettings(event.target.value as 'normal' | 'normalMonth' | 'stressTest')}
        >
          {Object.entries(chartSettingsMap).map(([key, value]) => (
            <MenuItem value={key} key={key}>
              {value.label}
            </MenuItem>
          ))}
        </Select>
        <div style={{ marginTop: 16, marginBottom: 16 }}>
          <FormControlLabel
            control={
              <Switch
                checked={isApex}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setApex(event.target.checked);
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="Enable Apex Charts"
          />
          <Alert severity="warning">Enabling Apex can freeze the page!.</Alert>
        </div>
        <Chart type="flow" chartSettings={chartSettingsMap[chartSettings]} libraryType={isApex ? 'apex' : 'plotly'} />
        <Chart
          type="pressure"
          chartSettings={chartSettingsMap[chartSettings]}
          libraryType={isApex ? 'apex' : 'plotly'}
        />
        <Chart
          type="temperature"
          chartSettings={chartSettingsMap[chartSettings]}
          libraryType={isApex ? 'apex' : 'plotly'}
        />
        <Chart type="leak" chartSettings={chartSettingsMap[chartSettings]} libraryType={isApex ? 'apex' : 'plotly'} />
        <LeakHeatMap chartSettings={chartSettingsMap[chartSettings]} />
      </Paper>
    </PageContainer>
  );
}
