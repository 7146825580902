import { MarlinTheme } from '@marlin/shared/theme';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { CRITICALITY } from '@marlin/shared/utils/zod';
import { Typography } from '@mui/material';
import { alpha } from '@mui/system';
import { FC, PropsWithChildren, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

interface ICriticality {
  criticality: 'HIGH' | 'LOW';
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  currentReading: {
    backgroundColor: theme.palette.background.alternative,
  },
  readingWithHighAlert: {
    backgroundColor: alpha(theme.palette.error.main, 0.12),
  },

  readingWithLowAlert: {
    backgroundColor: alpha(theme.palette.warning.main, 0.12),
  },
  reading: {
    padding: theme.typography.pxToRem(8),
    borderRadius: theme.typography.pxToRem(4),
  },
  readingText: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

interface IDeviceReadingCriticalityProps {
  criticalities: ICriticality[];
  tooltipContent: string;
}

const ReadingCriticality: FC<PropsWithChildren<IDeviceReadingCriticalityProps>> = ({
  criticalities,
  tooltipContent,
  children,
}) => {
  const { classes, cx } = useStyles();

  const deviceHasHighAlerts = useMemo(
    () => criticalities.find((criticality: ICriticality) => criticality.criticality === CRITICALITY.HIGH),
    [criticalities]
  );

  const deviceHasLowAlerts = useMemo(
    () => criticalities.find((criticality: ICriticality) => criticality.criticality === CRITICALITY.LOW),
    [criticalities]
  );

  const deviceClass = useMemo(() => {
    if (deviceHasHighAlerts) {
      return classes.readingWithHighAlert;
    } else if (deviceHasLowAlerts) {
      return classes.readingWithLowAlert;
    }
    return classes.currentReading;
  }, [
    classes.currentReading,
    classes.readingWithHighAlert,
    classes.readingWithLowAlert,
    deviceHasHighAlerts,
    deviceHasLowAlerts,
  ]);

  return deviceHasLowAlerts || deviceHasHighAlerts ? (
    <Tooltip text={tooltipContent}>
      <div className={cx(classes.reading, deviceClass)}>
        <Typography variant="body2" className={classes.readingText}>
          {children}
        </Typography>
      </div>
    </Tooltip>
  ) : (
    <div className={cx(classes.reading, deviceClass)}>
      <Typography variant="body2" className={classes.readingText}>
        {children}
      </Typography>
    </div>
  );
};

export { ReadingCriticality };
