import { zodResolver } from '@hookform/resolvers/zod';
import { FormField, SingleDatePicker } from '@marlin/shared/ui-form-common';
import { dateAdapter } from '@marlin/shared/utils-common-date';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { useStyles } from './compare-to-date-select.styles';
import { content } from './content';

interface ICompareToSelectProps {
  handleBack: () => void;
  handleSet: (date: string) => void;
  handleCancel: () => void;
  currentPeriodStart?: string;
  comparisonPeriodStart?: string;
  organizationTimezone?: string;
  timezone?: string;
}

const minDateInMonths = 15;

const compareToStartDateFormSchema = z.object({
  startDate: z.string().refine(
    (date) => {
      const inputDate = dateAdapter.date(date);
      if (inputDate) {
        const fifteenMonthsAgo = dateAdapter?.date()?.subtract(minDateInMonths, 'months');
        return inputDate.isAfter(fifteenMonthsAgo);
      }

      return true;
    },
    {
      message: content.CUSTOM_START_DATE.OUTDATED_DATE_MESSAGE(minDateInMonths),
    }
  ),
});

type TCompareToStartDateForm = z.infer<typeof compareToStartDateFormSchema>;

export const CompareToStartDate = ({
  handleBack,
  handleSet,
  handleCancel,
  comparisonPeriodStart,
  currentPeriodStart,
  timezone,
}: ICompareToSelectProps) => {
  const { classes } = useStyles();

  const form = useForm<TCompareToStartDateForm>({
    defaultValues: {
      startDate: comparisonPeriodStart,
    },
    resolver: zodResolver(compareToStartDateFormSchema),
  });

  const onSubmit = form.handleSubmit((data) => {
    handleSet(data.startDate);
  });

  const startDate = form.watch('startDate');
  const isSubmitButtonDisabled = !startDate || !form.formState.isValid || startDate === comparisonPeriodStart;

  return (
    <div className={classes.customStartDateWrapper}>
      <Button onClick={handleBack} startIcon={<ArrowBackIcon />}>
        BACK
      </Button>
      <Typography variant="h6" className={classes.title}>
        Custom Start Date
      </Typography>
      <FormField<TCompareToStartDateForm> control={form.control} fieldName="startDate">
        {(props) => (
          <SingleDatePicker
            className={classes.input}
            variant="datetime"
            maxDate={currentPeriodStart}
            label={content.CUSTOM_START_DATE.DATE_LABEL}
            // timezone={timezone?.id}
            {...props}
            onChange={(date) => {
              props.onChange && props.onChange(date);
              form.trigger('startDate');
            }}
          />
        )}
      </FormField>
      <div className={classes.footer}>
        <div>
          {timezone && (
            <Typography className={classes.orgText}>
              {content.CUSTOM_START_DATE.ORGANIZATION_TIME_ZONE(timezone)}
            </Typography>
          )}
        </div>

        <div className={classes.buttons}>
          <Button color="primary" onClick={handleCancel}>
            {content.CUSTOM_START_DATE.CANCEL_BUTTON}
          </Button>
          <Button variant="contained" color="primary" onClick={onSubmit} disabled={isSubmitButtonDisabled}>
            {content.CUSTOM_START_DATE.SET_BUTTON}
          </Button>
        </div>
      </div>
    </div>
  );
};
