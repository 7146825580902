import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import { paths } from './api';
import { TLocationHierarchy, locationHierarchySchema } from './schema/location-hierarchy.schema';

export const getLocationHierarchy = async ({ locationId }: { locationId: string }): Promise<TLocationHierarchy> => {
  const res = await getHttpClient().get(paths.LOCATION_HIERARCHY, { params: { locationId } });
  return safeParseData(res, locationHierarchySchema);
};
