import { MarlinTheme } from '@marlin/shared/theme';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { PageHeader } from '@marlin/shared/ui-page';
import { gatewayEquipmentModels, registerableEquipmentModels } from '@marlin/shared/utils/zod';
import { Paper, Typography } from '@mui/material';
import { Navigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';
import { ActionButtons } from './action-buttons';
import { EquipmentLocationHierarchy } from './equipment-location-hierarchy';
import { useEquipmentDetails } from './use-equipment-details.hook';
import { useNavigation } from './use-navigation.hook';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.typography.pxToRem(40),
    width: theme.typography.pxToRem(theme.static.defaultWidth),
  },
}));

const EquipmentDetailsDesktop = () => {
  const { title, serialNumber, model, equipment, isLoading } = useEquipmentDetails();

  const { classes } = useStyles();
  const navigation = useNavigation();

  if (
    ((equipment?.isRegistered && registerableEquipmentModels.includes(equipment?.model ?? '')) ||
      (equipment?.additionalProperties?.['gatewayId'] && gatewayEquipmentModels.includes(equipment?.model ?? ''))) &&
    equipment?.id
  ) {
    return <Navigate to={navigation.getEquipmentDashboardLink(equipment.id)} replace />;
  }

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '48px' }}>
      <div className={classes.buttonWrapper}>
        <PageHeader prefix="equipment-details-header" title={title} subtitle={equipment?.description} />
        {/*TODO: use navigation hook*/}
        <ActionButtons
          detailEditLink={navigation.getEditEquipmentPageLink(equipment?.id || '')}
          equipment={equipment}
        />
      </div>
      <Paper
        elevation={1}
        style={{
          display: 'flex',
          width: '1080px',
          marginTop: '24px',
          marginBottom: '24px',
          padding: '24px',
        }}
        data-testid="card"
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '276px',
            marginRight: '64px',
          }}
        >
          <Typography variant="caption" style={{ marginBottom: '12px' }}>
            {content.HIERARCHY_VIEW_TITLE}
          </Typography>
          <EquipmentLocationHierarchy equipmentId={equipment?.id || ''} />
        </div>
        {serialNumber && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '116px',
              marginRight: '24px',
            }}
          >
            <Typography variant="caption" style={{ marginBottom: '12px' }}>
              {content.SERIAL_NUMBER_TITLE}
            </Typography>
            <span>{serialNumber}</span>
          </div>
        )}
        {model && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '116px',
              marginRight: '24px',
            }}
          >
            <Typography variant="caption" style={{ marginBottom: '12px' }}>
              {content.MODEL_TITLE}
            </Typography>
            <span>{model}</span>
          </div>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '116px',
            marginRight: '24px',
          }}
        >
          <Typography variant="caption" style={{ marginBottom: '12px' }}>
            {content.AUTOMATIONS_TITLE}
          </Typography>
          <span>0</span>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '116px',
            marginRight: '24px',
          }}
        >
          <Typography variant="caption" style={{ marginBottom: '12px' }}>
            {content.ID_TITLE}
          </Typography>
          <span>{equipment?.id}</span>
        </div>
      </Paper>
    </div>
  );
};

export { EquipmentDetailsDesktop };
