import { MarlinTheme } from '@marlin/shared/theme';
import { PageContainer, PageHeader } from '@marlin/shared/ui-page';
import { routes } from '@marlin/shared/utils-routes';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button, useMediaQuery, useTheme } from '@mui/material';
import { Navigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { UpsertEquipment } from '../form/upsert-equipment.component';
import { content } from './content';
import { useCreateEquipment } from './use-create-equipment.hook';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  button: {
    marginLeft: theme.typography.pxToRem(16),
    marginTop: theme.typography.pxToRem(8),
  },
}));

export const CreateEquipment = () => {
  const { classes } = useStyles();
  const { successfullyCreatedEquipment, loadingCreatedEquipment, goToNextRoute, onSubmit, registrationErrorCode } =
    useCreateEquipment();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (successfullyCreatedEquipment) {
    return <Navigate to={routes.equipments.root} />;
  }

  return (
    <PageContainer prefix="create-equipment">
      <PageHeader
        icon={null}
        title={content.NEW_EQUIPMENT}
        subtitle={content.SUBTITLE}
        prefix="device-update-header"
        topActions={
          isMobile ? (
            <Button
              className={classes.button}
              startIcon={<CloseRoundedIcon />}
              variant="outlined"
              onClick={goToNextRoute}
            >
              {content.BUTTON_CANCEL}
            </Button>
          ) : undefined
        }
      />
      <UpsertEquipment
        onCancel={goToNextRoute}
        onSubmit={onSubmit}
        disableSubmit={loadingCreatedEquipment}
        upsertEquipmentErrorCode={registrationErrorCode}
      />
    </PageContainer>
  );
};
