import { MarlinTheme } from '@marlin/shared/theme';
import { DataLoader } from '@marlin/shared/ui-loader';
import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { AlertCurrentCard } from './card/alert-current-card.component';
import { AlertLostCommunicationCard } from './card/alert-lost-communication-card.component';
import { AlertResolvedCard } from './card/alert-resolved-card.component';
import { content } from './content';
import { ALERT_STATUS_FILTER, ERROR_TYPES, IAlert } from './types';

interface IAlertList extends Record<string, unknown> {
  isLoading: boolean;
  isError: boolean;
  alerts?: IAlert[];
  displayIconColumn: boolean;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    marginBottom: theme.typography.pxToRem(16),
    overflowWrap: 'break-word',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  emptyState: {
    height: theme.typography.pxToRem(400),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const AlertCard = ({ alert, displayIconColumn }: { alert: IAlert; displayIconColumn: boolean }): JSX.Element => {
  if (alert.errorType === ERROR_TYPES.LOST_COMMUNICATION) {
    return <AlertLostCommunicationCard alert={alert} displayIconColumn={displayIconColumn} />;
  }

  switch (alert.status) {
    case ALERT_STATUS_FILTER.CURRENT:
    case ALERT_STATUS_FILTER.SNOOZED: {
      return <AlertCurrentCard alert={alert} displayIconColumn={displayIconColumn} />;
    }
    case ALERT_STATUS_FILTER.RESOLVED: {
      return <AlertResolvedCard alert={alert} displayIconColumn={displayIconColumn} />;
    }
    default: {
      return <AlertCurrentCard alert={alert} displayIconColumn={displayIconColumn} />;
    }
  }
};

export function AlertList({ alerts, displayIconColumn, isLoading, isError }: IAlertList) {
  const { classes } = useStyles();

  if (isError) {
    return (
      <div className={classes.emptyState} data-testid="alerts-page-error">
        {content.ERROR}
      </div>
    );
  }

  if (isLoading) {
    return <DataLoader />;
  }

  if (!alerts?.length) {
    return (
      <div className={classes.emptyState} data-testid="alerts-page-empty">
        {content.NO_DATA}
      </div>
    );
  }

  return (
    <div data-testid="alert-list-container" className={classes.container}>
      <Grid container>
        {alerts?.map((alert: IAlert) => (
          <AlertCard alert={alert} key={alert.id} displayIconColumn={displayIconColumn} />
        ))}
      </Grid>
    </div>
  );
}

export default AlertList;
