import { TLastReadingValue, TMetadataGroup, TMetadataLayout } from '@marlin/system-map/data-access/system-map';

import { useDetailedNodeLayout } from '../hooks/use-detailed-node-layout.hook';
import { getColumnPosition } from '../utils/utils';
import { getGridPosition, useStyles } from './metadata-grid.styles';
import { MetadataGroup } from './metadata-group.component';

interface IMetadataGridProps {
  metadataLayout: TMetadataLayout;
}

interface IMetadataGridProps {
  nodeId: string;
  metadataLayout: TMetadataLayout;
  metadataGroups: TMetadataGroup[];
  lastReadingValues: TLastReadingValue[];
  isDetailed?: boolean;
}

export const MetadataGrid = ({
  metadataLayout,
  metadataGroups,
  lastReadingValues,
  nodeId,
  isDetailed = false,
}: IMetadataGridProps) => {
  const { metadataLayout: layout, metadataGroups: groups } = useDetailedNodeLayout({
    isDetailed,
    metadataLayout,
    metadataGroups,
  });
  const { classes, cx, css } = useStyles({ metadataLayout: layout, isDetailed });

  return (
    <div className={classes.container}>
      {groups.map((group) => {
        return (
          <div className={cx(classes.gridItem, css(getGridPosition(group)))} key={group.handleId}>
            <MetadataGroup
              isDetailed={isDetailed}
              nodeId={nodeId}
              metadataGroup={group}
              lastReadingValues={lastReadingValues}
              direction={getColumnPosition(group.column, metadataLayout.columns)}
            />
          </div>
        );
      })}
    </div>
  );
};
