import { ReactFlowJsonObject } from '@xyflow/react';
import { useObservableState } from 'observable-hooks';
import { useEffect } from 'react';
import { Subject } from 'rxjs';

const diagramState$ = new Subject<ReactFlowJsonObject | undefined>();

interface ISystemMapState {
  restoreReactSystemMap: ({ nodes, edges, viewport }: ReactFlowJsonObject) => void;
}

export const useSystemMapState$ = ({ restoreReactSystemMap }: ISystemMapState) => {
  const [diagramCache] = useObservableState<ReactFlowJsonObject | undefined>(() => diagramState$, undefined);

  useEffect(() => {
    if (diagramCache) {
      restoreReactSystemMap(diagramCache);
    }
  }, [restoreReactSystemMap, diagramCache]);

  const updateState = (rfInstanceJsonObject?: ReactFlowJsonObject) => {
    diagramState$.next(rfInstanceJsonObject);
  };

  const clearState = () => {
    diagramState$.next(undefined);
  };

  return {
    diagramCache,
    updateState,
    clearState,
  };
};
