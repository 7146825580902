import { getHttpClient } from '@marlin/shared/utils/react-query';

import { paths } from './api';
import {
  TExportTotalTelemetryResponseSchema,
  exportTotalTelemetryResponseSchema,
} from './export-total-flow-telemetry.schema';
import { TExportTelemetrySchema, exportTelemetryRequestPayload } from './schema/export-telemetry.schema';

export const exportTelemetry = async (
  requestPayload: TExportTelemetrySchema
): Promise<TExportTotalTelemetryResponseSchema> => {
  const payload = exportTelemetryRequestPayload.parse(requestPayload);
  const response = await getHttpClient().post(paths.EXPORT_TELEMETRY, payload);
  return exportTotalTelemetryResponseSchema.parse(response);
};
