import { getRangeWithMargins } from '@marlin/shared/utils-chart';
import { createDateString, isBefore } from '@marlin/shared/utils-common-date';
import { z } from 'zod';

export const rangeTelemetrySchema = z
  .object({
    manufacturerId: z.string(),
    from: z.string(),
    to: z.string(),
    excludeAlerts: z.boolean().optional(),
  })
  .refine(({ from, to }) => {
    return isBefore(createDateString(from), createDateString(to));
  }, 'From date should be less than to date');

export const bucketedRangeTelemetrySchema = rangeTelemetrySchema.transform((data) => {
  const { from, to, bucketSize } = getRangeWithMargins(data.from, data.to);

  return {
    ...data,
    from,
    to,
    bucketSizeInMinutes: bucketSize,
  };
});

export type TRangeTelemetryParams = z.infer<typeof rangeTelemetrySchema>;
