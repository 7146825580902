import { useMutation } from '@marlin/shared/utils/react-query';

import { registerValidate } from '../infrastructure/register-validate';
import { TRegisterValidateDataSchema } from '../infrastructure/register-validate.schema';

export const useRegisterValidate = () => {
  return useMutation({
    mutationFn: ({ equipment }: { equipment: TRegisterValidateDataSchema }) => registerValidate(equipment),
  });
};
