import { Dot } from '@marlin/shared/ui-criticality';

import { content } from '../content';

interface IEmptyContentProps {
  isAddDeviceMessage: boolean;
  isCreateAutomationMessage: boolean;
}

export const EmptyContent = ({ isAddDeviceMessage, isCreateAutomationMessage }: IEmptyContentProps) => {
  if (isAddDeviceMessage) {
    return <>{content.ADD_A_SENSOR}</>;
  }
  if (isCreateAutomationMessage) {
    return <>{content.CREATE_AN_AUTOMATION}</>;
  }
  return (
    <Dot variant="NO">
      <>{content.NO_OPEN_ALERTS}</>
    </Dot>
  );
};
