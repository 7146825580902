import { CRITICALITY } from '@marlin/shared/utils/zod';

import {
  ALERT_STATUS,
  ALERT_STATUS_FILTER,
  ALERT_STATUS_FILTER_REQUEST,
  CHANNEL_TYPE,
  DEVICE_TYPE,
  ERROR_TYPES,
  IChannels,
  IRecipient,
} from '../../alert.model';
import {
  ALERT_CAUSE,
  ALERT_STATUS_RESPONSE,
  CHANNEL_TYPE_RESPONSE,
  CRITICALITY_RESPONSE,
  DEVICE_TYPE_RESPONSE,
  IChannelResponse,
  IRecipientResponse,
} from '../alert.model';

export const getErrorType = (alertCause: ALERT_CAUSE): ERROR_TYPES => {
  switch (alertCause) {
    case ALERT_CAUSE.ABOVE: {
      return ERROR_TYPES.HIGHER;
    }
    case ALERT_CAUSE.BELOW: {
      return ERROR_TYPES.LOWER;
    }
    case ALERT_CAUSE.LEAK_DETECTION: {
      return ERROR_TYPES.LEAK_DETECTION;
    }
    case ALERT_CAUSE.LOST_COMMUNICATION: {
      return ERROR_TYPES.LOST_COMMUNICATION;
    }
    case ALERT_CAUSE.LOW_BATTERY: {
      return ERROR_TYPES.LOW_BATTERY;
    }
    case ALERT_CAUSE.DEVICE_ALERT: {
      return ERROR_TYPES.DEVICE_ALERT;
    }
    case ALERT_CAUSE.DEVICE_ERROR: {
      return ERROR_TYPES.DEVICE_ERROR;
    }
    default: {
      return ERROR_TYPES.UNKNOWN;
    }
  }
};

const roundToFloat = (number: number): number => {
  return Math.floor(number * 100) / 100;
};

const getAbsoluteDiff = (a: number, b: number) => {
  return Math.abs(a * 100 - b * 100) / 100;
};

export const getAbsoluteDifferenceValue = (
  thresholdMin: number | undefined,
  thresholdMax: number | undefined,
  readingValue: number
): number | null => {
  if ((thresholdMin || thresholdMin === 0) && thresholdMin > readingValue) {
    const diff = getAbsoluteDiff(thresholdMin, readingValue);

    return roundToFloat(diff);
  }

  if ((thresholdMax || thresholdMax === 0) && thresholdMax < readingValue) {
    const diff = getAbsoluteDiff(readingValue, thresholdMax);

    return roundToFloat(diff);
  }

  return null;
};

export const parseAlertStatusResponse = (status: string): ALERT_STATUS_FILTER => {
  switch (status) {
    case ALERT_STATUS_FILTER_REQUEST.CURRENT: {
      return ALERT_STATUS_FILTER.CURRENT;
    }
    case ALERT_STATUS_FILTER_REQUEST.RESOLVED: {
      return ALERT_STATUS_FILTER.RESOLVED;
    }
    case ALERT_STATUS_FILTER_REQUEST.SNOOZED: {
      return ALERT_STATUS_FILTER.SNOOZED;
    }
    case ALERT_STATUS_FILTER_REQUEST.ALL: {
      return ALERT_STATUS_FILTER.ALL;
    }
    default: {
      // TODO: (LOGGER) log error in external logger
      // eslint-disable-next-line no-console
      console.warn(`Alert status (${status}) is missing in response`);
      return ALERT_STATUS_FILTER.ALL;
    }
  }
};

export const parseAlertStatusRequest = (
  status: ALERT_STATUS_FILTER | undefined
): ALERT_STATUS_FILTER_REQUEST | undefined => {
  switch (status) {
    case ALERT_STATUS_FILTER.CURRENT: {
      return ALERT_STATUS_FILTER_REQUEST.CURRENT;
    }
    case ALERT_STATUS_FILTER.RESOLVED: {
      return ALERT_STATUS_FILTER_REQUEST.RESOLVED;
    }
    case ALERT_STATUS_FILTER.ALL: {
      return undefined;
    }
    default: {
      // TODO: (LOGGER) log error in external logger
      // eslint-disable-next-line no-console
      console.warn(`Alert status (${status}) is missing in request`);
      return undefined;
    }
  }
};

export const getDeviceType = (deviceType: string): DEVICE_TYPE => {
  switch (deviceType) {
    case DEVICE_TYPE_RESPONSE.TEMPERATURE: {
      return DEVICE_TYPE.TEMPERATURE;
    }
    case DEVICE_TYPE_RESPONSE.PRESSURE: {
      return DEVICE_TYPE.PRESSURE;
    }
    case DEVICE_TYPE_RESPONSE.LEAK: {
      return DEVICE_TYPE.LEAK;
    }
    case DEVICE_TYPE_RESPONSE.PULSE_METER:
    case DEVICE_TYPE_RESPONSE.FLOW_METER: {
      return DEVICE_TYPE.PULSE_METER;
    }
    case DEVICE_TYPE_RESPONSE.GATEWAY: {
      return DEVICE_TYPE.GATEWAY;
    }
    case DEVICE_TYPE_RESPONSE.EQUIPMENT: {
      return DEVICE_TYPE.EQUIPMENT;
    }
    default: {
      return DEVICE_TYPE.UNKNOWN;
    }
  }
};

const getAlertStatus = (status: string | null): ALERT_STATUS | null => {
  switch (status) {
    case ALERT_STATUS_RESPONSE.SENT: {
      return ALERT_STATUS.SENT;
    }
    case ALERT_STATUS_RESPONSE.FAILED: {
      return ALERT_STATUS.FAILED;
    }
    case ALERT_STATUS_RESPONSE.SNOOZED: {
      return ALERT_STATUS.SNOOZED;
    }
    case ALERT_STATUS_RESPONSE.IN_PROGRESS: {
      return ALERT_STATUS.IN_PROGRESS;
    }
    case null: {
      return null;
    }
    default: {
      // TODO: (LOGGER) log error in external logger
      // eslint-disable-next-line no-console
      console.warn(`Alert status (${status}) is missing in request`);
      return null;
    }
  }
};

const getRecipients = (recipients: IRecipientResponse[]): IRecipient[] => {
  return recipients.map((recipient: IRecipientResponse): IRecipient => {
    return { id: recipient.recipientId, status: getAlertStatus(recipient.status), error: recipient.error };
  });
};

export const getChannels = (channels: IChannelResponse[]): IChannels => {
  return channels.reduce((acc: IChannels, currentValue: IChannelResponse): IChannels => {
    switch (currentValue.channelType) {
      case CHANNEL_TYPE_RESPONSE.SMS: {
        return {
          ...acc,
          [CHANNEL_TYPE.SMS]: {
            type: CHANNEL_TYPE.SMS,
            recipientIds: getRecipients(currentValue.recipients),
          },
        };
      }
      case CHANNEL_TYPE_RESPONSE.EMAIL: {
        return {
          ...acc,
          [CHANNEL_TYPE.EMAIL]: {
            type: CHANNEL_TYPE.EMAIL,
            recipientIds: getRecipients(currentValue.recipients),
          },
        };
      }
      case CHANNEL_TYPE_RESPONSE.HOT_SOS: {
        if (!currentValue?.recipients.length) {
          return acc;
        }
        return {
          ...acc,
          [CHANNEL_TYPE.HOT_SOS]: {
            type: CHANNEL_TYPE.HOT_SOS,
            status: getAlertStatus(currentValue?.recipients[0]?.status),
            errorMessage: currentValue?.recipients[0]?.error,
          },
        };
      }
    }
    return acc;
  }, {} as IChannels);
};

export const getCriticality = (criticality: CRITICALITY_RESPONSE): CRITICALITY => {
  switch (criticality) {
    case CRITICALITY_RESPONSE.HIGH: {
      return CRITICALITY.HIGH;
    }
    case CRITICALITY_RESPONSE.LOW: {
      return CRITICALITY.LOW;
    }
  }
};
