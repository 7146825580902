import { ITab, ScrollableTabs } from '@marlin/shared/ui-tabs-mobile';

import { content } from '../../content';
import { TAB_VALUES } from '../../use-home.hook';

export interface IHomeTabsProps {
  tabValue: TAB_VALUES;
  updateTab: (newValue: number) => void;
  allLocationsLength: number;
  mechanicalRoomsLength: number;
  locationsWithOpenAlertsLength: number;
}

export interface ITabsProps {
  tabValue: TAB_VALUES;
  updateTab: (newValue: TAB_VALUES) => void;
}

export const MobileHomeTabs = ({
  tabValue,
  updateTab,
  mechanicalRoomsLength,
  locationsWithOpenAlertsLength,
  allLocationsLength,
}: IHomeTabsProps) => {
  const tabs: ITab<TAB_VALUES>[] = [
    {
      id: TAB_VALUES.MechanicalRooms,
      label: content.MECHANICAL_ROOMS_TAB(mechanicalRoomsLength),
    },
    { id: TAB_VALUES.OpenAlerts, label: content.OPEN_ALERTS_TAB(locationsWithOpenAlertsLength) },
    { id: TAB_VALUES.AllLocations, label: content.ALL_LOCATIONS_TAB(allLocationsLength) },
  ];
  return <ScrollableTabs tabs={tabs} tabValue={tabValue} updateTab={updateTab} />;
};
