import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  activeBatteryContainer: {
    display: 'flex',
    justifyContent: 'center',
  },

  activeContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.alternative,
    borderRadius: theme.typography.pxToRem(4),
    padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(8)}`,
  },
}));
