export const content = {
  USER_TABLE_NO_DATA: 'No results found.',
  USER_TABLE_ERROR: 'Something went wrong. Please try again later.',

  USER_TABLE_HEADER_NAME: 'Name',
  USER_TABLE_HEADER_TITLE: 'Title',
  USER_TABLE_HEADER_EMAIL: 'Email Address',
  USER_TABLE_HEADER_PHONE: 'Phone Number',
  USER_TABLE_HEADER_ROLE: 'Role',

  USER_TABLE_ACTION_RESEND: 'Resend Invite',

  CONFIRM_DELETE_USER_TITLE: 'Delete User?',
  // NOTE: This phrase is a composition of the CONFIRM_DELETE_USER_TEXT_PART_1, bolded user first name and last name and CONFIRM_DELETE_USER_TEXT_PART_2
  CONFIRM_DELETE_USER_TEXT_PART_1: 'Are you sure you want to delete',
  CONFIRM_DELETE_USER_TEXT_PART_2: '?',
  CONFIRM_DELETE_USER_CONFIRMATION_TEXT:
    'This action cannot be undone and will remove the user from all automations and alerts.',
  CONFIRM_DELETE_USER_CANCEL_BUTTON: 'Cancel',
  CONFIRM_DELETE_USER_DELETE_BUTTON: 'Delete',
  USER_TABLE_INVITATION_CELL_PENDING: 'Pending',
  USER_TABLE_INVITATION_CELL_EXPIRED: 'Expired',
  USER_TABLE_ROLE_CELL_ADMIN: 'Admin',
  USER_TABLE_ROLE_CELL_VIEWER: 'Viewer',
};
