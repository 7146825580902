import { MarlinTheme } from '@marlin/shared/theme';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  stepper: {
    width: '100%',
    color: theme.palette.text.secondary,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
    fontWeight: 500,
    letterSpacing: theme.typography.pxToRem(0.1),
  },
  stepperWrapper: {
    maxWidth: theme.typography.pxToRem(1200),
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.typography.pxToRem(8),
  },
  stepLabel: {
    '&.Mui-active': {
      color: theme.palette.primary.main,
    },
  },
}));

interface IHorizontalLinearStepperProps {
  steps: { title: string; component: JSX.Element | null }[];
  activeStep: number;
  alternativeLabel?: boolean;
}

export const HorizontalLinearStepper = ({
  steps,
  activeStep,
  alternativeLabel = false,
}: IHorizontalLinearStepperProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.stepperWrapper}>
      <Stepper activeStep={activeStep} alternativeLabel={alternativeLabel} className={classes.stepper}>
        {steps.map(({ title }, index) => (
          <Step key={title} data-testid={`step-${index}`}>
            <StepLabel
              classes={{
                label: classes.stepLabel,
              }}
            >
              {title}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      {steps[activeStep]?.component || null}
    </div>
  );
};
