import { useUpdateOrg } from '@marlin/account-data-access-organization';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { PERMISSIONS, usePermission } from '@marlin/shared/utils-permission';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Button } from '@mui/material';

import { IEntity } from '../../../utils/types';
import { content } from '../../content';
import { OrganizationItem } from '../../organization-item';
import { OrganizationsList } from '../../organizations-list';
import { useStyles } from './organization-details-content.styles';
import { SwitcherActions } from './switcher-actions';

interface IOrganizationDetailsContentProps {
  entities: IEntity[];
  entityName?: string;
  isOrganizationListVisible: boolean;
  currentOrgId?: string;
  onClose: () => void;
  onChange: (event: React.MouseEvent<HTMLElement>) => void;
  variant: 'marlin' | 'portfolio' | 'support';
  fetchNextPage?: () => void;
  isLoading: boolean;
  handleOrgSearchValue: (value: string) => void;
  orgSearchValue: string;
  hasNextPage: boolean;
}

export const OrganizationDetailsContent = ({
  entities,
  entityName,
  isOrganizationListVisible,
  currentOrgId,
  onClose,
  onChange,
  variant,
  isLoading,
  fetchNextPage,
  handleOrgSearchValue,
  orgSearchValue,
  hasNextPage,
}: IOrganizationDetailsContentProps) => {
  const { classes } = useStyles();

  const isNexaSystem = usePermission(PERMISSIONS.SYSTEM_MAP);
  const { enqueueSnackbar } = useSnackbar();
  const onSuccess = () =>
    enqueueSnackbar(content.ORGANIZATION_HAS_BEEN_CHANGED, {
      variant: 'success',
      preventDuplicate: true,
    });

  const { goTo } = useRouter();
  const updateOrgMutation = useUpdateOrg({ shouldRefetch: true, onSuccess });

  const handleChangeOrganization = (orgId: string) => {
    updateOrgMutation.mutateAsync(orgId).then(() => {
      goTo(routes.home.root);
      onClose();
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.changeOrgContainer}>
        <div className={classes.title}>{variant === 'support' ? content.NEXA_SUPPORT : content.ORGANIZATION}</div>
        {variant !== 'support' && (
          <>
            {!isOrganizationListVisible ? (
              entities.length > 1 && (
                <Button
                  size="medium"
                  onClick={onChange}
                  variant="outlined"
                  className={classes.changeBtn}
                  endIcon={<MoreHorizIcon />}
                >
                  {content.CHANGE.toUpperCase()}
                </Button>
              )
            ) : (
              <Button
                size="medium"
                onClick={onChange}
                variant="outlined"
                className={classes.changeBtn}
                endIcon={<CloseRoundedIcon />}
              >
                {content.CANCEL.toUpperCase()}
              </Button>
            )}
          </>
        )}
      </div>
      {variant !== 'support' && (
        <>
          {isOrganizationListVisible ? (
            <OrganizationsList
              entityId={currentOrgId}
              entities={entities}
              onChange={handleChangeOrganization}
              isLoading={isLoading}
              fetchNextPage={fetchNextPage}
              handleOrgSearchValue={handleOrgSearchValue}
              orgSearchValue={orgSearchValue}
              isPortfolio={variant === 'portfolio'}
              hasNextPage={hasNextPage}
            />
          ) : (
            <OrganizationItem
              onChange={onClose}
              isCurrentOrg={true}
              orgName={entityName}
              showFullName={true}
              tierName={isNexaSystem ? content.NEXA_SYSTEM : content.NEXA_EQUIPMENT}
              isPortfolio={variant === 'portfolio'}
            />
          )}
        </>
      )}
      <SwitcherActions variant={variant} onClose={onClose} />
    </div>
  );
};
