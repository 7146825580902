import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useOnPageLeave = (callback: () => void) => {
  const location = useLocation();

  useEffect(() => {
    callback();
  }, [location, callback]);
};
