export class NavStorageService {
  private key = 'isLocked';

  public set = (lock: string): void => {
    sessionStorage.setItem(this.key, lock);
    localStorage.setItem(this.key, lock);
  };

  public get = (storageType: 'sessionStorage' | 'localStorage'): string | null => {
    switch (storageType) {
      case 'sessionStorage': {
        return sessionStorage.getItem(this.key);
      }
      case 'localStorage': {
        return localStorage.getItem(this.key);
      }
      default: {
        return null;
      }
    }
  };
}

export const navStorageService = new NavStorageService();
