import { UnitOfMeasureType } from '@marlin/shared/utils-format-reading';
import { z } from 'zod';

export enum ALERT_CAUSE {
  BELOW = 'Below',
  ABOVE = 'Above',
  LEAK_DETECTION = 'LeakDetect',
  LOST_COMMUNICATION = 'LostCommunication',
  LOW_BATTERY = 'LowBatteryLevel',
  DEVICE_ALERT = 'DeviceAlert',
  DEVICE_ERROR = 'DeviceError',
}

export enum CRITICALITY {
  LOW = 'Low',
  HIGH = 'High',
}

type TCriticality = 'LOW' | 'HIGH';

const getCriticality = (criticality: 'Low' | 'High'): TCriticality => {
  switch (criticality) {
    case 'Low': {
      return 'LOW';
    }
    case 'High': {
      return 'HIGH';
    }
  }
};

export const CriticalitySchema = z.union([z.literal('High'), z.literal('Low')]).transform(getCriticality);

const topOpenAlertSchema = z.object({
  deviceId: z.string(),
  deviceName: z.string(),
  deviceType: z.string(),
  criticality: CriticalitySchema,
  alertCause: z.nativeEnum(ALERT_CAUSE),
  thresholdMin: z.number().nullable(),
  thresholdMax: z.number().nullable(),
  lastReadingTime: z.string(),
  startTime: z.string(),
  title: z.string().nullable(),
  uoM: UnitOfMeasureType,
});

const devicesHealthSchema = z.object({
  inactiveGatewaysCount: z.number(),
  inactiveDevicesCount: z.number(),
  gatewaysCount: z.number(),
  devicesCount: z.number(),
});

const systemHealthSchema = z.object({
  highAlertsCount: z.number(),
  lowAlertsCount: z.number(),
  locationsCount: z.number(),
  mechanicalRoomsCount: z.number(),
});

const deviceSchema = z.object({
  deviceId: z.string(),
  batteryLevel: z.number(),
});

const dashboardDeviceAlertsSchema = z.object({
  deviceCount: z.number(),
  highAlertsCount: z.number(),
  lowAlertsCount: z.number(),
});

const dashboardHealthAlertsSchema = z.object({
  deviceCount: z.number(),
  lostCommunicationCount: z.number(),
  deviceErrorsCount: z.number(),
});

const dashboardSystemSchema = z.object({
  equipment: dashboardDeviceAlertsSchema,
  sensors: dashboardDeviceAlertsSchema,
  gateways: dashboardDeviceAlertsSchema,
});

const dashboardHealthSystemSchema = z.object({
  equipment: dashboardHealthAlertsSchema,
  sensors: dashboardHealthAlertsSchema,
  gateways: dashboardHealthAlertsSchema,
});

export const homeDashboardSchema = z.object({
  devices: z.array(deviceSchema),
  topOpenAlerts: z.array(topOpenAlertSchema),
  automationsCount: z.number(),
  health: dashboardHealthSystemSchema,
  performance: dashboardSystemSchema,
});

export type TGetHomeDashboardResponseSchema = z.infer<typeof homeDashboardSchema>;

export type TDashboardDevice = z.infer<typeof deviceSchema>;
export type TDashboardSystemSchema = z.infer<typeof dashboardSystemSchema>;
export type TDashboardHealthSystemSchema = z.infer<typeof dashboardHealthSystemSchema>;
export type TDevicesHealth = z.infer<typeof devicesHealthSchema>;
export type TSystemHealthSchema = z.infer<typeof systemHealthSchema>;
export type TDashboardDeviceAlertsSchema = z.infer<typeof dashboardDeviceAlertsSchema>;
export type TDashboardDHealthAlertsSchema = z.infer<typeof dashboardHealthAlertsSchema>;
export type TDashboardTopOpenAlerts = z.infer<typeof topOpenAlertSchema>;
