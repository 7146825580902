import { TDeviceTypeSchema } from '@marlin/asset/data-access/device';
import { MarlinTheme } from '@marlin/shared/theme';
import { TDateString } from '@marlin/shared/utils-common-date';
import { TUnitOfMeasure } from '@marlin/shared/utils-format-reading';
import { renderToString } from 'react-dom/server';

import { TAlertBuckets } from '../../../../types';
import { MainChartTooltip } from './main-chart-tooltip.component';
import { getAlertSeries, getDataSeries, getIndexesAtTimestamp } from './utils';

interface IRenderTooltipFuncProps {
  deviceType?: TDeviceTypeSchema;
  deviceName?: string;
  theme: MarlinTheme;
  uoM?: TUnitOfMeasure | null;
}

interface IRenderMainChartTooltipFuncProps extends IRenderTooltipFuncProps {
  groupedAlerts: TAlertBuckets;
}

interface IApexGlobals {
  globals: {
    seriesX: TDateString[][];
    seriesNames: string[];
  };
}

interface IRenderPointTooltipProps {
  series: number[][];
  seriesIndex: number;
  dataPointIndex: number;
  w: IApexGlobals;
}

export const renderMainChartTooltip =
  ({ deviceType, deviceName, theme, groupedAlerts, uoM }: IRenderMainChartTooltipFuncProps) =>
  ({ series, seriesIndex, dataPointIndex, w }: IRenderPointTooltipProps) => {
    if (!deviceType) {
      return undefined;
    }

    const indexAtTimestamp = getIndexesAtTimestamp(w.globals.seriesX, seriesIndex, dataPointIndex);

    const dataSeries = getDataSeries(
      series,
      seriesIndex,
      dataPointIndex,
      deviceType,
      w.globals.seriesX,
      w.globals.seriesNames,
      indexAtTimestamp,
      uoM
    );
    const alertSeries = getAlertSeries(
      indexAtTimestamp,
      deviceType,
      w.globals.seriesX,
      w.globals.seriesNames,
      groupedAlerts
    );

    if (!dataSeries && alertSeries.length === 0) {
      return null;
    }

    return renderToString(
      <MainChartTooltip dataSeries={dataSeries} alertSeries={alertSeries} deviceName={deviceName || ''} theme={theme} />
    );
  };
