import { MarlinTheme } from '@marlin/shared/theme';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';

const useStyles = makeStyles<{ inTab: boolean }>()((theme: MarlinTheme, { inTab }) => ({
  logo: {
    height: theme.typography.pxToRem(40),
    width: theme.typography.pxToRem(162),
  },
  errorLogo: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.typography.pxToRem(16)} 0`,
    justifyContent: 'center',
    marginTop: inTab ? 0 : theme.typography.pxToRem(96),
    [theme.breakpoints.down('lg')]: {
      textAlign: 'center',
      padding: `${theme.typography.pxToRem(16)} 0 ${theme.typography.pxToRem(8)}`,
      '& svg': {
        width: theme.typography.pxToRem(198),
        height: theme.typography.pxToRem(112),
      },
    },
  },
  container: {
    maxWidth: theme.typography.pxToRem(800),
    margin: '0 auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.typography.pxToRem(48)} 0`,
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.typography.pxToRem(8),
  },
  noSensors: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    marginTop: theme.typography.pxToRem(24),
  },
  footer: {
    marginTop: theme.typography.pxToRem(inTab ? 64 : 24),
    color: theme.palette.text.secondary,
  },
}));

export const ErrorPage = ({ onRefresh, inTab = false }: { onRefresh: () => void; inTab?: boolean }) => {
  const { classes, theme } = useStyles({ inTab });

  return (
    <div className={classes.container}>
      {!inTab && <div className={classes.logo}>{theme.icon.fullLogo}</div>}
      <div className={classes.errorLogo}>{theme.icon.errorLogo}</div>
      <div className={classes.noSensors}>
        <Typography variant="h4" align="center" className={classes.title}>
          {content.STH_WENT_WRONG}
        </Typography>
        <Typography variant="subtitle1" align="center">
          {content.TRY_AGAIN}
        </Typography>
        <Button className={classes.button} variant="contained" onClick={onRefresh}>
          {content.REFRESH}
        </Button>
      </div>
      <Typography className={classes.footer} variant="body2">
        {content.FOOTER}
      </Typography>
    </div>
  );
};
