import { TVolumeDataPoint } from '@marlin/asset/data-access/home-dashboard';
import { dateAdapter } from '@marlin/shared/utils-common-date';
import { TUnitOfMeasure, getUomSuffix } from '@marlin/shared/utils-format-reading';
import * as Plotly from 'plotly.js';
import { useMemo } from 'react';

interface IUseTotalWaterConsumptionLayoutProps {
  uoM?: TUnitOfMeasure | null;
  volumeTotal: TVolumeDataPoint[];
  selectedUnit: 'volume' | 'dollars';
}

export const useTotalWaterChartLayout = ({
  uoM,
  volumeTotal,
  selectedUnit,
}: IUseTotalWaterConsumptionLayoutProps): Partial<Plotly.Layout> => {
  const range = useMemo(() => {
    return {
      from: dateAdapter.date(volumeTotal[0]?.eventDateTime),
      to: dateAdapter.date(volumeTotal[volumeTotal.length - 1]?.eventDateTime),
    };
  }, [volumeTotal]);
  const layout: Partial<Plotly.Layout> = useMemo(() => {
    return {
      autosize: true,
      responsive: true,
      xaxis: { type: 'date', range: [range?.from?.toISOString(), range?.to?.toISOString()] },
      xaxis2: {
        type: 'date',
        side: 'top',
        overlaying: 'x',
        visible: false,
      },
      yaxis: {
        side: 'right',
        ticksuffix: selectedUnit === 'volume' ? getUomSuffix(uoM) || '' : '',
        tickprefix: selectedUnit === 'dollars' ? '$' : '',
      },
      margin: {
        t: 16,
        b: 32,
        l: 16,
      },
      dragmode: false,
      showlegend: false,
      hovermode: false,
    };
  }, [range?.from, range?.to, selectedUnit, uoM]);

  return layout;
};
