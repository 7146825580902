// todo (TRANSLATIONS) merge this one with one from UI project
export const content = {
  CONFIRMATION_MESSAGE_DELETE_SENSOR_ERROR: `Something went wrong. Your automation hasn't been deleted`,
  CONFIRMATION_MESSAGE_DELETE_SENSOR_SUCCESS: 'Your automation has been deleted',
  CONFIRMATION_MESSAGE_UPDATE_AUTOMATION_ON_SUCCESS: 'Your automation has been turned on',
  CONFIRMATION_MESSAGE_UPDATE_AUTOMATION_OFF_SUCCESS: 'Your automation has been turned off',
  CONFIRMATION_MESSAGE_UPDATE_AUTOMATION_ERROR: 'Your automation has not been updated',
  MODAL_TITLE: 'Delete automation?',
  MODAL_DELETE_AUTOMATION_QUESTION: (name: string) =>
    `Are you sure you want to delete ${name}? If there are outstanding alerts for this automation, they will be resolved.`,
  MODAL_DELETE_AUTOMATION_CONFIRMATION: 'This action cannot be undone.',
  MODAL_BUTTON_CANCEL_TEXT: 'Cancel',
  MODAL_BUTTON_SUBMIT_TEXT: 'Delete',
  RECIPIENTS_UNKNOWN: 'Unknown',
  PERFORMANCE: 'Performance',
  HEALTH: 'Health',
  INFORMATION: 'Information',
};
