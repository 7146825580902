import { FC, PropsWithChildren, useReducer } from 'react';

import { ModalContext } from './modal-context';
import { initialState, modalReducer } from './modal-reducer';

export const ModalProvider: FC<PropsWithChildren> = ({ children }) => {
  const [modalState, modalDispatch] = useReducer(modalReducer, initialState);
  const [drawerState, drawerDispatch] = useReducer(modalReducer, initialState);

  return (
    <ModalContext.Provider value={{ modalState, modalDispatch, drawerState, drawerDispatch }}>
      {children}
    </ModalContext.Provider>
  );
};
