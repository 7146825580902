import { Paper } from '@marlin/shared/ui-page';
import { Button, Typography } from '@mui/material';

import { content } from '../../content';
import { useStyles } from './send-success.component.styles';

interface ISendSuccessProps {
  email: string;
  onSuccess: (value: boolean) => void;
}

export const SendSuccess = ({ email, onSuccess }: ISendSuccessProps) => {
  const { classes } = useStyles();

  const onCancel = () => {
    onSuccess(false);
  };

  return (
    <Paper className={classes.mainContainer}>
      <Typography variant="h5" className={classes.title} data-testid="card-title">
        {content.SEND_SUCCESS.TITLE}
      </Typography>
      <Typography className={classes.label}>{content.SEND_SUCCESS.SUBTITLE}</Typography>
      <Typography className={classes.label}>
        {content.SEND_SUCCESS.TEXT_START} <b>{email}</b> {content.SEND_SUCCESS.TEXT_END}
      </Typography>
      <Button variant="outlined" className={classes.button} onClick={onCancel}>
        {content.SEND_SUCCESS.BUTTON}
      </Button>
    </Paper>
  );
};
