import { MarlinTheme } from '@marlin/shared/theme';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { Tooltip as MuiTooltip } from '@mui/material';
import { ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

type TTooltipPlacement =
  | 'bottom'
  | 'left'
  | 'right'
  | 'top'
  | 'bottom-end'
  | 'bottom-start'
  | 'left-end'
  | 'left-start'
  | 'right-end'
  | 'right-start'
  | 'top-end'
  | 'top-start'
  | undefined;

export type TVariant = 'DARK' | 'LIGHT';

export enum VARIANT {
  DARK = 'DARK',
  LIGHT = 'LIGHT',
}

interface ITooltip {
  placement?: TTooltipPlacement;
  text: NonNullable<ReactNode>;
  children?: JSX.Element;
  variant?: TVariant;
  disableTouchListener?: boolean;
  open?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  disableHoverListener?: boolean;
  className?: string;
}

interface IStyles {
  variant?: TVariant;
}

export const useStyles = makeStyles<IStyles>()((theme: MarlinTheme, { variant }) => ({
  icon: {
    height: theme.typography.pxToRem(16),
    width: theme.typography.pxToRem(16),
    color: theme.palette.primary.main,
    [theme.breakpoints.down('md')]: {
      height: theme.typography.pxToRem(24),
      width: theme.typography.pxToRem(24),
    },
  },

  tooltip: {
    '& .MuiTooltip-tooltip': {
      backgroundColor: variant === VARIANT.DARK ? theme.palette.alternative.main : theme.palette.alternative.dark,
    },
    '& .MuiTooltip-arrow': {
      color: theme.palette.alternative.dark,
    },
  },
}));

export const Tooltip = ({
  text,
  placement = 'right',
  children,
  variant,
  disableTouchListener = false,
  disableHoverListener = false,
  open,
  onOpen,
  onClose,
  className,
}: ITooltip) => {
  const {
    classes: { tooltip, icon },
    cx,
  } = useStyles({ variant });

  return (
    <MuiTooltip
      disableTouchListener={disableTouchListener}
      disableHoverListener={disableHoverListener}
      enterTouchDelay={0}
      leaveTouchDelay={7000}
      title={text}
      placement={placement}
      arrow
      PopperProps={{
        className: className ? cx(className, tooltip) : tooltip,
      }}
      open={open}
      onOpen={onOpen}
      onClose={onClose}
      data-testid="tooltip"
    >
      {children ?? <InfoRoundedIcon className={icon} />}
    </MuiTooltip>
  );
};
