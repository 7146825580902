import { getHttpClient } from '@marlin/shared/utils/react-query';

import { paths } from './api';
import {
  TExportFeedbackPayload,
  TExportFeedbackResponseSchema,
  exportFeedbackPayload,
  exportFeedbackResponseSchema,
} from './export-feedback.schema';

export const exportFeedback = async (payload: TExportFeedbackPayload): Promise<TExportFeedbackResponseSchema> => {
  const payloadData = exportFeedbackPayload.parse(payload);
  const response = await getHttpClient().post(paths.FEEDBACK, payloadData);
  return exportFeedbackResponseSchema.parse(response);
};
