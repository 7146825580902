import { TVolumeSchema } from '@marlin/asset/data-access/home-dashboard';
import { getUnitType, parseDeviceReadings } from '@marlin/shared/utils-format-reading';
import { Typography } from '@mui/material';
import { useMemo } from 'react';

import { ArrowIndicator } from './arrow-indicator/arrow-indicator.component';
import { Average } from './average/average.component';
import { content } from './content';
import { useStyles } from './totalization-card.styles';
import { transformBigNumber } from './utils';

interface ITrendProps {
  variant: 'WEEK' | 'MONTH' | 'YEAR';
  volume?: TVolumeSchema | null;
}

export const TrendMobile = ({ variant, volume }: ITrendProps) => {
  const { classes } = useStyles();

  const trendPeriod = useMemo(() => {
    switch (variant) {
      case 'WEEK': {
        return content.WEEK;
      }
      case 'MONTH': {
        return content.MONTH;
      }
      case 'YEAR': {
        return content.YEAR;
      }
      default:
        return null;
    }
  }, [variant]);

  return (
    <div className={classes.trendContainer} data-testid={`${variant.toLowerCase()}-total-section`}>
      <div className={classes.trendWrapper}>
        <div>
          <div className={classes.wrapperTotal}>
            <div>
              <Typography className={classes.trendsText} data-testid={`${variant.toLowerCase()}-trends-text`}>
                {trendPeriod}
              </Typography>
            </div>
            <div>
              <Typography
                className={classes.trendsValueText}
                data-testid={`${variant.toLowerCase()}-trends-value-text`}
              >
                {content.VALUE_TOTAL(
                  transformBigNumber(parseDeviceReadings(volume?.totalVolume, 'PULSE_METER', 'integer').value ?? 0),
                  getUnitType('PULSE_METER')
                )}
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes.icon}>
          {variant !== 'YEAR' && volume?.trend !== 0 && (
            <div>
              <Typography className={classes.valueText} data-testid={`${variant.toLowerCase()}-value-text`}>
                {volume?.trend &&
                  content.VALUE_TREND(
                    transformBigNumber(parseDeviceReadings(volume?.trend, 'PULSE_METER', 'integer').value ?? 0)
                  )}
              </Typography>
            </div>
          )}
          <ArrowIndicator value={volume?.trend} />
        </div>
      </div>
      {(volume?.averageVolume || 0) > 0 && (
        <div className={classes.averageWrapper}>
          <Average averageVolume={parseDeviceReadings(volume?.averageVolume, 'PULSE_METER').value} variant={variant} />
        </div>
      )}
    </div>
  );
};
