import { ChartSwitcherWrapper } from '@marlin/asset/shared/ui/chart-switcher-wrapper';
import { ChartSeriesToggle } from '@marlin/asset/ui/charts';
import { MarlinTheme } from '@marlin/shared/theme';
import { IChartSeries } from '@marlin/shared/utils-chart';
import { useMediaQuery, useTheme } from '@mui/material';
import zipObject from 'lodash/zipObject';
import { useEffect, useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useEquipmentDetailsConfigContext } from '../../../shared/context/equipment-details-config-context';
import { mapDataPoints, useRangeBarConfig } from './chart-config-utils';

export const equipmentChartSize = {
  height: {
    mobile: 200,
    desktop: 300,
  },

  offset: 16,
};

interface IRangeBarChartProps {
  chartData: IChartSeries[];
  isLoading: boolean;
  from: number;
  to: number;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  mainChart: {
    height: theme.typography.pxToRem(equipmentChartSize.height.desktop),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.typography.pxToRem(24),
      height: theme.typography.pxToRem(equipmentChartSize.height.mobile),
    },
  },

  rangeBar: {
    marginTop: 0,
    maxWidth: '100%',
    '.nsewdrag.drag.cursor-pointer': {
      cursor: 'default',
    },
  },

  wrapper: {
    marginTop: theme.typography.pxToRem(24),
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    height: '80%',
  },
}));

const chartId = 'trend';

export const RangeBarChart = ({ chartData, isLoading, from, to }: IRangeBarChartProps) => {
  const { classes, cx } = useStyles();
  const theme = useTheme<MarlinTheme>();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const rangeColors = useMemo(() => [theme.palette.charting.breezyBlue, theme.palette.charting.flushedPink], [theme]);
  const [activeDatapoints, setActiveDatapoints] = useState<Map<string, boolean>>(new Map());
  const {
    config: {
      dashboard: { chart },
    },
  } = useEquipmentDetailsConfigContext();

  const datapointLabels = useMemo(
    () =>
      zipObject(
        chart?.datapointNames || [],
        chart?.datapointGroupNames.map((name) => name.charAt(0).toUpperCase() + name.slice(1)) || []
      ),
    [chart?.datapointGroupNames, chart?.datapointNames]
  );

  useEffect(() => {
    setActiveDatapoints(new Map(chartData.map((data) => [datapointLabels[data.id], true])));
  }, [chartData, datapointLabels]);

  const data = useMemo(
    () =>
      mapDataPoints({
        data: chartData,
        invertBarDatapointNames: chart?.invertBarDatapointNames,
        colors: rangeColors,
        datapointLabels,
      }),
    [chart?.invertBarDatapointNames, chartData, datapointLabels, rangeColors]
  );

  const activeData = useMemo(
    () =>
      data.filter((data) => {
        return activeDatapoints.get(data.name);
      }),
    [activeDatapoints, data]
  );

  const height = useMemo(
    () => (isMobile ? equipmentChartSize.height.mobile : equipmentChartSize.height.desktop) - equipmentChartSize.offset,
    [isMobile]
  );

  const { layout, config, handleUnhover, handleHover, tooltip } = useRangeBarConfig({ from, to, isMobile, height });

  return (
    <div className={classes.mainChart} data-testid="equipment-dashboard-chart">
      <ChartSeriesToggle
        series={data}
        checkIfActive={(name) => activeDatapoints.get(name) ?? false}
        toggleDatapointVisibility={(name) => {
          const newActiveDatapoints = new Map(activeDatapoints);
          newActiveDatapoints.set(name, !activeDatapoints.get(name));

          setActiveDatapoints(newActiveDatapoints);
        }}
      />
      <div className={cx({ [classes.wrapper]: isLoading })}>
        <ChartSwitcherWrapper
          chartData={activeData}
          layout={layout}
          config={config}
          from={from}
          to={to}
          chartDisplayType={'rangeBar'}
          isLoading={isLoading}
          isFetching={isLoading}
          chartId={chartId}
          currentAnnotationTooltip={null}
          rangeBarDatapoints={data.map((data) => data.name)}
          className={classes.rangeBar}
          height={height}
          handleHover={handleHover}
          handleUnhover={handleUnhover}
          tooltip={tooltip}
        />
      </div>
    </div>
  );
};
