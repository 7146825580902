import {
  ALERT_STATUS_FILTER,
  IAlert,
  TRuleCategory,
  useFilteredAlertsWithLoadMore,
} from '@marlin/alert/data-access/alert-action';
import { useMemo } from 'react';

interface IParams {
  status: ALERT_STATUS_FILTER;
  pageSize: number;
  locationIds: string[];
  equipmentIds: string[];
  deviceIds: string[];
  deviceTypes: string[];
  ruleCategories: TRuleCategory[];
}

const defaultParams: IParams = {
  status: ALERT_STATUS_FILTER.ALL,
  pageSize: 10,
  locationIds: [],
  equipmentIds: [],
  deviceIds: [],
  deviceTypes: [],
  ruleCategories: ['Health', 'Performance', 'Information'],
};

interface IUseAlerts {
  alerts: IAlert[];
  totalItems: number;
  hasNextPage?: boolean;
  isLoading: boolean;
  isError: boolean;
  fetchNextPage: () => void;
  refetchMobile: () => void;
}

export const useMobileAlerts = (filterParams?: IParams): IUseAlerts => {
  const alertsQuery = useFilteredAlertsWithLoadMore({
    params: { ...defaultParams, ...filterParams },
  });

  const alerts = useMemo(() => {
    if (!alertsQuery?.data) {
      return [];
    }

    const flattedAlerts = alertsQuery?.data.pages.flatMap((i) => i);

    return flattedAlerts?.map((alert) => alert.data).reduce((acc, alert) => [...acc, ...alert], []) ?? [];
  }, [alertsQuery?.data]);

  return {
    alerts,
    totalItems: alertsQuery.data?.pages[0]?.pagination.totalItems ?? 0,
    hasNextPage: alertsQuery.hasNextPage,
    isLoading: alertsQuery.isFetching,
    isError: alertsQuery.isError,
    fetchNextPage: alertsQuery.fetchNextPage,
    refetchMobile: alertsQuery.refetch,
  };
};
