import { MarlinTheme } from '@marlin/shared/theme';
import { CriticalityControl } from '@marlin/shared/ui-criticality';
import { Paper } from '@marlin/shared/ui-page';
import { defaultDateTime, formatDate } from '@marlin/shared/utils-common-date';
import { Box, Grid } from '@mui/material';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';
import { IAlert } from '../types';
import { DescriptionComponent } from './helper-components/description.component';
import { HyperlinkComponent } from './helper-components/hyperlink.component';
import { AlertIntervalSection } from './sections/alert-interval-section';
import { LastReadingSection } from './sections/last-reading-section.component';
import { MetadataSection } from './sections/metadata-section.component';
import { RecipientsSection } from './sections/recipients-section.component';
import { SnoozeSection } from './sections/snooze-section.component';
import { WarningIcon } from './sections/warning-icon.component';

interface IAlertCurrent {
  alert: IAlert;
  displayIconColumn: boolean;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  label: {
    fontSize: theme.typography.pxToRem(12),
    marginTop: theme.typography.pxToRem(2),
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
  },

  time: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.secondary,
  },

  lastReadingSection: {
    paddingLeft: theme.typography.pxToRem(24),
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.typography.pxToRem(24),
    borderLeft: `${theme.typography.pxToRem(1)} solid ${theme.palette.systemMap.device}`,
  },
  equipmentCardRightSection: {
    paddingTop: theme.typography.pxToRem(56),
    paddingLeft: theme.typography.pxToRem(24),
    width: theme.typography.pxToRem(336),
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.typography.pxToRem(8),
    borderLeft: `${theme.typography.pxToRem(1)} solid ${theme.palette.systemMap.device}`,
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.typography.pxToRem(24),
    alignItems: 'flex-start',
    paddingTop: theme.typography.pxToRem(10),
    paddingBottom: theme.typography.pxToRem(10),
  },

  paper: {
    borderRadius: 0,
    borderBottom: 'none',
  },
}));

export function AlertCurrentCard({ alert, displayIconColumn }: IAlertCurrent) {
  const { classes } = useStyles();
  const warningIcon = displayIconColumn ? <WarningIcon /> : undefined;

  const isEquipmentAlert = alert.errorType === 'DEVICE_ALERT' || alert.errorType === 'DEVICE_ERROR';

  const startTime = useMemo(() => {
    return formatDate(alert.startTime, defaultDateTime);
  }, [alert.startTime]);

  return (
    <Grid item xs={12}>
      <Paper testId="alert-card" className={classes.paper}>
        <Grid container>
          <Grid item xs={6}>
            <>
              <RecipientsSection
                isEquipmentAlert={isEquipmentAlert}
                alert={alert}
                icon={warningIcon}
                isCurrentAlert={true}
              />
              {!isEquipmentAlert && <AlertIntervalSection snoozeIntervalInMinutes={alert.snoozeIntervalInMinutes} />}
              <MetadataSection isEquipmentAlert={isEquipmentAlert} alert={alert} />
            </>
          </Grid>
          <Grid item xs={2}>
            <div className={classes.container}>
              <div className={classes.label}>{content.CARD.CURRENT.LABEL.START_TIME}</div>
              <div className={classes.time} data-testid="start-time">
                {startTime}
              </div>
              <div>
                <CriticalityControl severity={alert.criticality} />
              </div>
            </div>
          </Grid>
          {!isEquipmentAlert ? (
            <Grid item xs={4} className={classes.lastReadingSection}>
              <SnoozeSection
                id={alert.id}
                deviceId={alert.device.id}
                snoozeUntil={alert.snoozeUntil}
                automationName={alert.automation.name}
                automationId={alert.automation.id}
                deviceName={alert.device.name}
                alertSeverity={alert.criticality}
                alertStartTime={alert.startTime}
              />
              <LastReadingSection alert={alert} />
            </Grid>
          ) : (
            <Box className={classes.equipmentCardRightSection}>
              <HyperlinkComponent link={alert.hyperlink} />
              <DescriptionComponent description={alert.description} />
            </Box>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
}
