// NOTE: resolve module boundaries config
/* eslint-disable @nx/enforce-module-boundaries */
import { HierarchyItem } from '@marlin/asset/shared/ui/hub-card';
import { Typography } from '@mui/material';

import { DetailItem } from './detail-item';
import { useStyles } from './device-drawer-mobile.styles';
import { useNavigation } from './use-navigation.hook';

interface ILocation {
  name: string;
  id: string;
}

interface ILocationDetailRow {
  propName: string;
  deviceLocation: ILocation | undefined;
  parentLocation: ILocation | undefined;
}

export const LocationDetailRow = ({ propName, deviceLocation, parentLocation }: ILocationDetailRow) => {
  const { classes } = useStyles();
  const { getLocationDetailsPageLink } = useNavigation();

  return (
    <DetailItem>
      <div>
        <Typography className={classes.detailsPropNameText}>{propName}</Typography>
      </div>
      <div className={classes.textWrapper}>
        <HierarchyItem parent={parentLocation} children={deviceLocation} goTo={getLocationDetailsPageLink} />
      </div>
    </DetailItem>
  );
};
