import { TLocationDetails } from '@marlin/asset/data-access/location';
import { Paper } from '@marlin/shared/ui-page';
import { Typography } from '@mui/material';

import { content } from '../../content';
import { useStyles } from './details.component.styles';

interface IDetailsProps {
  location: TLocationDetails;
}

export const Details = ({ location }: IDetailsProps) => {
  const { classes } = useStyles();

  return (
    <Paper data-testid="card" className={classes.detailsMainContainer}>
      <div className={classes.detailsTitleWrapper}>{content.DETAILS_TAB}</div>
      <div className={classes.detailsWrapper}>
        <div className={classes.linkedEquipmentsWrapper}>
          <Typography variant="caption" className={classes.detailsTitle}>
            {content.LINKED_EQUIPMENTS_TITLE}
          </Typography>
          <span>{location.equipmentCount}</span>
        </div>
        <div className={classes.linkedDevicesWrapper}>
          <Typography variant="caption" className={classes.detailsTitle}>
            {content.LINKED_SENSORS_TITLE}
          </Typography>
          <span>{location.deviceCount}</span>
        </div>
        <div className={classes.automationsWrapper}>
          <Typography variant="caption" className={classes.detailsTitle}>
            {content.AUTOMATIONS_TITLE}
          </Typography>
          <span>{location.automationCount}</span>
        </div>
        {(location.address1 || location.address2 || location.postalCode) && (
          <div className={classes.addressWrapper}>
            <Typography variant="caption" className={classes.detailsTitle}>
              {content.ADDRESS_TITLE}
            </Typography>
            <span className={classes.addressLine}>{location.address1}</span>
            <span className={classes.addressLine}>
              {location.address2} {location.postalCode}
            </span>
          </div>
        )}
        <div className={classes.idWrapper}>
          <Typography variant="caption" className={classes.detailsTitle}>
            {content.ID_TITLE}
          </Typography>
          <span>{location.id}</span>
        </div>
      </div>
    </Paper>
  );
};
