import { useStyles } from './list-empty.component.styles';

export const MobileHomeListEmpty = ({ message }: { message: string }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.mobileEmptyState} data-testid="alerts-page-empty">
      {message}
    </div>
  );
};
