import { useCurrentUser } from '@marlin/account-data-access-organization';
import { MarlinTheme } from '@marlin/shared/theme';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from '../mobile/content';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  text: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 400,
    textAlign: 'right',
  },
  nexaSystem: {
    color: theme.palette.secondary.main,
  },
  nexaEquipment: {
    color: theme.palette.primary.main,
  },
}));

interface IOrganizationTier {
  tier: string;
}

const getMobileTierName = (tier: string) => {
  if (tier === content.NEXA_EQUIPMENT) {
    return tier.replace('Nexa ', '').substring(0, 5);
  }
  return tier.replace('Nexa ', '').substring(0, 3);
};

export const OrganizationTier = ({ tier }: IOrganizationTier) => {
  const { classes, cx } = useStyles();
  const { data } = useCurrentUser();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const textStyle = cx(classes.text, tier === content.NEXA_SYSTEM ? classes.nexaSystem : classes.nexaEquipment);

  if (!data?.currentOrganization.roles.includes('Support')) {
    return null;
  }

  const label = isMobile ? getMobileTierName(tier) : tier;

  return <Typography className={textStyle}>{label.toUpperCase()}</Typography>;
};
