import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import { paths } from './api';
import {
  CalibrationRequestSchema,
  CalibrationResponseSchema,
  LatestSavedCalibrationResponseSchema,
  SavedCalibrationsRequestSchema,
  SavedCalibrationsResponseSchema,
  TCalibrationParamsSchema,
  TCalibrationResponseSchema,
  TLatestSavedCalibrationResponseSchema,
  TSavedCalibrationsRequestSchema,
  TSavedCalibrationsResponseSchema,
} from './schema/calibration.schema';

interface ICalibrationPayload {
  manufacturerId: string;
  calibration: TCalibrationParamsSchema;
}

export const processCalibration = async ({
  manufacturerId,
  calibration,
}: ICalibrationPayload): Promise<TCalibrationResponseSchema> => {
  const request = safeParseData(calibration, CalibrationRequestSchema);
  const response = await getHttpClient().post(paths.CALIBRATION, request, { params: { manufacturerId } });
  return safeParseData(response, CalibrationResponseSchema);
};

export const saveCalibration = async ({
  manufacturerId,
  calibration,
}: ICalibrationPayload): Promise<TCalibrationResponseSchema> => {
  const request = safeParseData(calibration, CalibrationRequestSchema);
  const response = await getHttpClient().put(paths.CALIBRATION, request, { params: { manufacturerId } });
  return safeParseData(response, CalibrationResponseSchema);
};

export const getSavedCalibrations = async (
  payload: TSavedCalibrationsRequestSchema
): Promise<TSavedCalibrationsResponseSchema> => {
  const request = safeParseData(payload, SavedCalibrationsRequestSchema);
  const response = await getHttpClient().post(paths.SAVED_CALIBRATIONS, request);
  return safeParseData(response, SavedCalibrationsResponseSchema);
};

export const getLatestSavedCalibration = async (
  payload: TSavedCalibrationsRequestSchema
): Promise<TLatestSavedCalibrationResponseSchema> => {
  const request = safeParseData(payload, SavedCalibrationsRequestSchema);
  const response = await getHttpClient().post(paths.SAVED_CALIBRATIONS, request);
  return safeParseData(response, LatestSavedCalibrationResponseSchema);
};
