import { secondsToFormattedTime } from '@marlin/shared/utils-common-date';
import { useGraph, useSystemMapNotification } from '@marlin/system-map/data-access/system-map';
import { useEffect, useMemo, useState } from 'react';

export const useLastReadingTimer = () => {
  const notification = useSystemMapNotification();
  const graphQuery = useGraph();
  const [formattedTimer, setFormattedTimer] = useState('');
  const [timeLeft, setTimeLeft] = useState(0);

  const elements = useMemo(
    () => [...(graphQuery.data?.nodes || []), ...(graphQuery.data?.links || [])],
    [graphQuery.data?.nodes, graphQuery.data?.links]
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((t) => t + 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    setFormattedTimer(secondsToFormattedTime(timeLeft, true));
  }, [timeLeft]);

  useEffect(() => {
    setTimeLeft(0);
  }, [notification, elements]);

  return formattedTimer;
};
