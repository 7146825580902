import { getChartingColors } from '@marlin/asset/ui/charts';
import { MarlinTheme } from '@marlin/shared/theme';
import { MenuItem, Select } from '@mui/material';
import { useMemo, useState } from 'react';
import Plot from 'react-plotly.js';
import { makeStyles } from 'tss-react/mui';

interface ISankeyValues {
  label: string;
  gal: number[];
  percent: string[];
}

type TWeek = 'week1' | 'week2' | 'week3';

const valuesMap: Record<TWeek, ISankeyValues> = {
  week1: {
    label: 'Jul 14 - Jul 20',
    gal: [219006, 2160, 52302, 32880, 131664, 131664 / 2, 131664 / 2],
    percent: ['100%', '1%', '24%', '15%', '60%', '50%', '50%'],
  },
  week2: {
    label: 'Jul 21 - Jul 27',
    gal: [275316, 2388, 58968, 50772, 163188, 163188 / 2, 163188 / 2],
    percent: ['100%', '1%', '21%', '18%', '59%', '50%', '50%'],
  },
  week3: {
    label: 'Jul 28 - Aug 3',
    gal: [207648, 2418, 47358, 33126, 124746, 124746 / 2, 124746 / 2],
    percent: ['100%', '1%', '23%', '16%', '60%', '50%', '50%'],
  },
};

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  sankeySpikeWrapper: {
    '.sankey-node > .node-label': {
      textShadow: 'none!important',
      textAnchor: 'start',
      transform: 'translate(36px, 12px)',
    },
    '.sankey-node > .node-rect': {
      stroke: 'none !important',
    },
  },
}));

function* colorGenerator(colors: string[]): Generator<string, never, unknown> {
  let index = 0;
  while (true) {
    yield colors[index % colors.length];
    index++;
  }
}

const hexToRgba = (hex: string, opacity: number) => {
  const hexValue = hex.replace('#', '');
  const r = parseInt(hexValue.substring(0, 2), 16);
  const g = parseInt(hexValue.substring(2, 4), 16);
  const b = parseInt(hexValue.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

const createData = (theme: MarlinTheme) => {
  const source = [0, 1, 1, 1, 1, 5, 5];
  const target = [1, 2, 3, 4, 5, 1, 0];

  const chartColors = getChartingColors(theme);
  const colorGen = colorGenerator(chartColors);

  const colorsArray = Array.from({ length: target.length }, () => colorGen.next().value);

  return {
    type: 'sankey' as const,
    orientation: 'h' as const,
    arrangement: 'snap' as const,
    node: {
      pad: 40,
      thickness: 30,
      line: {
        color: 'black',
        width: 0.5,
      },
      label: ['Cold Water Inlet', 'Softener', 'Softener discharge', 'Kitchen', 'Guest Rooms', 'DA Tank'],
      color: colorsArray,
    },
    link: {
      source,
      target,
      color: target.map((value) => hexToRgba(colorsArray[value], 0.5)),
    },
    textfont: {
      color: 'black',
      size: 14,
    },
  };
};

export const Sankey = (): JSX.Element => {
  const [week, setWeek] = useState<TWeek>('week1');
  const [unit] = useState<keyof ISankeyValues>('gal');

  const { classes, theme } = useStyles();

  const data = useMemo(() => createData(theme), [theme]);

  const sankeyData = useMemo(
    () => [
      {
        ...data,
        link: {
          ...data.link,
          value: [...valuesMap[week][unit]],
        },
      },
    ],
    [data, unit, week]
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }} className={classes.sankeySpikeWrapper}>
      <div>
        <Select value={week} onChange={(event) => setWeek(event.target.value as TWeek)}>
          {Object.entries(valuesMap).map(([key, value]) => (
            <MenuItem value={key} key={key}>
              {value.label}
            </MenuItem>
          ))}
        </Select>
      </div>
      <Plot
        style={{ alignSelf: 'center' }}
        data={sankeyData}
        layout={{
          title: 'Sankey Flow PoC',
          font: { size: 16, family: 'Inter' },
          width: 1500,
          height: 700,

          margin: {
            l: 200,
            r: 200,
            b: 200,
            t: 200,
            pad: 35,
          },
        }}
        config={{ displaylogo: false }}
      />
    </div>
  );
};
