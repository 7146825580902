import { TBucketOption } from '../model';
import { RANGE_FILTER } from './get-time-range-with-format';

export const TimeRangeToBucketOption = new Map<RANGE_FILTER, TBucketOption>([
  [RANGE_FILTER.HOURS_8, 'hours'],
  [RANGE_FILTER.HOURS_24, 'hours'],
  [RANGE_FILTER.DAYS_7, 'days'],
  [RANGE_FILTER.DAYS_30, 'days'],
  [RANGE_FILTER.MONTHS_12, 'months'],
]);
