import { IChartData, IThresholdsSeriesNullable } from '../chart-data-types';

const getFirstPoint = (points: (IChartData | IThresholdsSeriesNullable)[]) => {
  const { y } = points.find(({ y }) => !!y) || {};

  if (!y) return null;

  if (Array.isArray(y)) {
    return y[0];
  }

  return y;
};

interface IFindMinMaxOptions {
  points: (IChartData | IThresholdsSeriesNullable)[];
  preCalculatedMax?: number;
  shouldMinBeZero?: boolean;
  onlyThresholds?: boolean;
}

export function findMinMax({ points, onlyThresholds, shouldMinBeZero, preCalculatedMax }: IFindMinMaxOptions) {
  if (points.length === 0) {
    return { max: 100, min: 0 };
  }
  const firstPoint = getFirstPoint(points);

  let max = preCalculatedMax ?? firstPoint ?? 100;
  let min = firstPoint ?? 0;

  const setMinMax = (value: number | null) => {
    if (value === null) return;

    if (value > max) {
      max = value;
    }
    if (value < min) {
      min = value;
    }
  };

  for (const point of points) {
    if (Array.isArray(point.y)) {
      point.y.forEach((value) => setMinMax(value));
    } else {
      setMinMax(point.y);
    }
  }
  const diff = max - min || 1;

  if (onlyThresholds) {
    if (min <= 0) {
      return { min: min, max: max + 50 };
    }

    return { min: min - 50, max: max };
  }

  min = Math.floor(min - diff * 0.02);
  max = Math.ceil(max + diff * 0.02);

  if (diff < 6) {
    max += (6 - diff) / 2;
    min -= (6 - diff) / 2;
  }

  return { min: shouldMinBeZero ? 0 : min, max };
}
