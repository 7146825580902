import { useDeleteLocation } from '@marlin/asset/data-access/location';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { useCallback, useContext, useMemo } from 'react';

import { content } from './content';
import { ModalFooter } from './delete-modal.footer';

interface IFooterProps {
  assetId: string;
  handleAfterDelete?: () => void;
  canDelete: boolean;
}

export const DeleteLocationModalFooter = ({ assetId, handleAfterDelete, canDelete }: IFooterProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const onError = () => {
    enqueueSnackbar(content.DELETE_LOCATION_ERROR, { variant: 'error', preventDuplicate: true });
  };
  const onSuccess = () => {
    enqueueSnackbar(content.DELETE_LOCATION_SUCCESS, { variant: 'success', preventDuplicate: true });
  };
  const deleteLocationMutation = useDeleteLocation({ onSuccess, onError });
  const { modalDispatch } = useContext(ModalContext);
  const router = useRouter();

  const handleCloseModal = useCallback(() => {
    modalDispatch({
      type: MODAL_ACTION_TYPE.DISMISS,
    });
  }, [modalDispatch]);

  const postDeleteAction = useCallback(() => {
    if (handleAfterDelete) {
      handleAfterDelete();
    } else {
      router.goTo(routes.locations.list.url());
    }
    handleCloseModal();
  }, [handleAfterDelete, handleCloseModal, router]);

  const deleteAssetMethod = useMemo(() => {
    return async () => {
      return await deleteLocationMutation.mutateAsync({ locationId: assetId }).then(postDeleteAction);
    };
  }, [assetId, deleteLocationMutation, postDeleteAction]);

  return <ModalFooter canDelete={canDelete} onClose={handleCloseModal} deleteAssetMethod={deleteAssetMethod} />;
};
