import { IFilterParams } from './filter-params.utils';

enum QUERY_KEY {
  LOCATIONS = 'locations',
  LOCATIONS_HIERARCHY = 'locations-hierarchy',
}

export const queryKey = {
  LOCATIONS: (filterParams?: IFilterParams) =>
    filterParams ? [QUERY_KEY.LOCATIONS, filterParams] : [QUERY_KEY.LOCATIONS],
  SUBLOCATIONS: (locationId: string, filterParams?: IFilterParams) =>
    filterParams ? [QUERY_KEY.LOCATIONS, locationId, filterParams] : [QUERY_KEY.LOCATIONS, locationId],
  LOCATIONS_HIERARCHY: () => [QUERY_KEY.LOCATIONS_HIERARCHY],
};
