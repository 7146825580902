import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },

  filterWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },

  filters: {
    marginLeft: theme.typography.pxToRem(16),
    marginTop: theme.typography.pxToRem(16),
    marginBottom: theme.typography.pxToRem(16),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  filterFields: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: theme.typography.pxToRem(16),
    width: '100%',
  },

  fieldWrapper: {
    width: '100%',
    maxWidth: theme.typography.pxToRem(320),
  },

  searchFilter: {
    width: '100%',
  },

  locationFilter: {
    minWidth: '100%',
  },

  clearAll: {
    marginRight: theme.typography.pxToRem(16),
    marginLeft: theme.typography.pxToRem(16),
    whiteSpace: 'nowrap',
  },

  locationChipsWrapper: {
    marginLeft: theme.typography.pxToRem(16),
    marginBottom: theme.typography.pxToRem(16),
  },

  dataGridWrapper: {
    height: theme.typography.pxToRem(600),
    width: '100%',
  },
}));
