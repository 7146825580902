import { MarlinTheme } from '@marlin/shared/theme';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { ControllerRenderProps, FieldError } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';
import { locationTypeOptions } from './constants';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  empty: {
    height: theme.typography.pxToRem(32),
    color: theme.palette.text.secondary,
  },
}));

type TRenderProps = Partial<Omit<ControllerRenderProps, 'ref'>>;

interface ILocationTypeSelect extends TRenderProps {
  error?: FieldError;
  disabled?: boolean;
  helperTextDisabled?: boolean;
  className?: string;
  variant?: 'standard' | 'support';
}

export const LocationTypeSelect = ({
  error,
  disabled = false,
  helperTextDisabled,
  className,
  variant = 'standard',
  ...rest
}: ILocationTypeSelect) => {
  const { classes, cx } = useStyles();

  return (
    <FormControl error={!!error} fullWidth={true}>
      <InputLabel id="location-type-label">{content.LOCATION_TYPE_LABEL}</InputLabel>
      <Select
        id="location-type-select"
        labelId="location-type-label"
        data-testid="location-type"
        label={content.LOCATION_TYPE_LABEL}
        fullWidth={true}
        disabled={disabled}
        multiple={false}
        error={!!error}
        className={className}
        {...rest}
        renderValue={
          helperTextDisabled
            ? (selected) => {
                return selected;
              }
            : undefined
        }
      >
        {locationTypeOptions.map((role) => (
          <MenuItem key={role.value} value={role.value} className={cx({ [classes.empty]: role.value === '' })}>
            {role.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
