import { FilterChip } from '@marlin/shared/ui/chips';

import { content } from './content';

export interface IMobileFilterToggleButton {
  onDelete: () => void;
}

export function FilteredResultsChip({ onDelete }: IMobileFilterToggleButton) {
  return <FilterChip label={content.FILTERED_RESULTS} onDelete={onDelete} testId="filtered-results-chip" />;
}
