import { getHttpClient } from '@marlin/shared/utils/react-query';

import { paths } from './api';
import {
  THomeLocationsParamsSchema,
  THomeLocationsSchema,
  homeLocationsParamsSchema,
  homeLocationsSchema,
} from './schema/home-locations.schema';

export const getHomeLocations = async (params: THomeLocationsParamsSchema): Promise<THomeLocationsSchema> => {
  const requestParams = homeLocationsParamsSchema.parse(params);
  const res = await getHttpClient().get<unknown, THomeLocationsSchema>(paths.HOME_LOCATIONS, { params: requestParams });
  return homeLocationsSchema.parse(res);
};
