import { PageHeader } from '@marlin/shared/ui-page';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';

import { content } from '../../content';

const HomeHeader = () => {
  return (
    <PageHeader prefix="home" title={content.HOME_TITLE} subtitle={content.HOME_SUBTITLE} icon={<HomeRoundedIcon />} />
  );
};

export { HomeHeader };
