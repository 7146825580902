import { TExtractFnReturnType, useQuery } from '@marlin/shared/utils/react-query';

import { getDeviceSubtypes } from '../infrastructure/get-device-subtypes';
import { TSubtypesParams } from '../infrastructure/schema/subtypes.schema';
import { queryKey } from './query-key.enum';

interface IUserDeviceSubtypes {
  enabled: boolean;
  params: TSubtypesParams;
}

export const useDeviceSubtypes = ({ enabled, params }: IUserDeviceSubtypes) => {
  return useQuery<TExtractFnReturnType<typeof getDeviceSubtypes>>({
    queryKey: queryKey.DEVICE_SUBTYPES(params),
    queryFn: () => getDeviceSubtypes(params),
    enabled,
  });
};
