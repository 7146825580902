import { MarlinTheme } from '@marlin/shared/theme';
import SubdirectoryArrowRightRoundedIcon from '@mui/icons-material/SubdirectoryArrowRightRounded';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  icon: {
    width: theme.typography.pxToRem(20),
    height: theme.typography.pxToRem(20),
    color: theme.palette.action.active,
  },
}));

export const HierarchyIcon = () => {
  const { classes } = useStyles();
  return <SubdirectoryArrowRightRoundedIcon className={classes.icon} />;
};
