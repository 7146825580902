// NOTE: resolve module boundaries config
// eslint-disable-next-line @nx/enforce-module-boundaries
import { LinkedItem } from '@marlin/asset/shared/ui/hub-card';
import { Typography } from '@mui/material';

import { DetailItem } from './detail-item';
import { useStyles } from './device-drawer-mobile.styles';
import { useNavigation } from './use-navigation.hook';

interface IGatewayIdRow {
  propName: string;
  id?: string;
}

export const GatewayIdRow = ({ propName, id }: IGatewayIdRow) => {
  const { classes } = useStyles();
  const { getGatewayPageLink } = useNavigation();

  return (
    <DetailItem>
      <div>
        <Typography className={classes.detailsPropNameText}>{propName}</Typography>
      </div>
      <div className={classes.textWrapper}>
        <LinkedItem id={id} name={id} goTo={getGatewayPageLink} />
      </div>
    </DetailItem>
  );
};
