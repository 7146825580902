import { MarlinTheme } from '@marlin/shared/theme';
import { Button, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

import { ReactComponent as ErrorIcon } from '../icons/error.svg';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  errorWrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    marginBottom: theme.typography.pxToRem(24),
  },
  title: {
    fontSize: theme.typography.pxToRem(34),
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(20),
    },
  },
  subtitle: {
    marginTop: theme.typography.pxToRem(8),
    color: theme.palette.text.secondary,
    textAlign: 'center',
    maxWidth: theme.typography.pxToRem(436),
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  actionButton: {
    marginTop: theme.typography.pxToRem(24),
  },
}));

interface IBaseErrorProps {
  title: ReactNode;
  subtitle: ReactNode;
  buttonText: ReactNode;
  handleButtonClick?: () => void;
}

export const BaseError = ({ title, subtitle, buttonText, handleButtonClick }: IBaseErrorProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.errorWrapper}>
      <ErrorIcon className={classes.icon} />
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.subtitle}>{subtitle}</Typography>
      <Button variant={'outlined'} className={classes.actionButton} onClick={handleButtonClick}>
        {buttonText}
      </Button>
    </div>
  );
};
