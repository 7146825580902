import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import { TOrgDetails, orgDetailsSchema } from '../schemas/org';
import { organizationStorageService } from '../storage/organization-storage.service';
import { paths } from './api';

export const getOrganizationDetails = async (): Promise<TOrgDetails> => {
  const organizationId = organizationStorageService.get('localStorage');
  const res = await getHttpClient().get(paths.ORGANIZATION_DETAILS, { params: { organizationId } });

  return safeParseData(res, orgDetailsSchema);
};
