import { createDateString } from '@marlin/shared/utils-common-date';
import moment from 'moment';
import { z } from 'zod';

import { UnitOfMeasureType } from './uom.schema';

export const AnalyticsTelemetryResponse = z.object({
  telemetryData: z.array(
    z.object({
      manufacturerId: z.string(),
      datapointName: z.string(),
      unitOfMeasure: UnitOfMeasureType,
      values: z.array(
        z
          .object({
            timestampUtc: z.string(),
            value: z.number().nullable(),
          })
          .transform(({ timestampUtc, value }) => ({
            x: moment(createDateString(timestampUtc)).valueOf(),
            y: value?.toFixed(1),
          }))
      ),
    })
  ),
});
export type TAnalyticsTelemetryResponse = z.infer<typeof AnalyticsTelemetryResponse>;
