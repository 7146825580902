import { Node, useReactFlow } from '@xyflow/react';

import { gridSize } from './const';

export const calculateCeilSnappedValue = (oldVal: number) => {
  const modulo = oldVal % gridSize;
  if (modulo === 0) {
    return oldVal;
  }
  const newVal = oldVal - modulo + gridSize;
  if ((newVal / 2) % gridSize === 0) {
    return newVal;
  }
  return newVal + gridSize;
};

export const calculateSnappedValue = (oldVal: number) => {
  const modulo = oldVal % gridSize;
  if (modulo > gridSize / 2) {
    return oldVal - modulo + gridSize;
  }
  return oldVal - modulo;
};

export const useSnapToGrid = () => {
  const { setNodes } = useReactFlow();

  const snapNodeToGrid = (node: Node) => {
    setNodes((nodes) => {
      return nodes.map((n) => {
        if (n.id === node.id) {
          return {
            ...n,
            position: {
              x: calculateSnappedValue(node.position.x),
              y: calculateSnappedValue(node.position.y),
            },
          };
        }
        return n;
      });
    });
  };

  return { snapNodeToGrid };
};
