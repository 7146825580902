export enum DEVICE_TYPE {
  TEMPERATURE = 'TEMPERATURE',
  PRESSURE = 'PRESSURE',
  LEAK = 'LEAK',
  PULSE_METER = 'PULSE_METER',
  UNKNOWN = 'UNKNOWN',
}

export enum ERROR_TYPES {
  LOWER = 'LOWER',
  HIGHER = 'HIGHER',
  UNKNOWN = 'UNKNOWN',
  LEAK_DETECTION = 'LEAK_DETECTION',
  LOST_COMMUNICATION = 'LOST_COMMUNICATION',
  LOW_BATTERY = 'LOW_BATTERY',
}

export enum ALERT_CAUSE {
  BELOW = 'Below',
  ABOVE = 'Above',
  LEAK_DETECTION = 'LeakDetect',
  LOST_COMMUNICATION = 'LostCommunication',
  LOW_BATTERY = 'LowBatteryLevel',
}

export enum CRITICALITY {
  LOW = 'LOW',
  HIGH = 'HIGH',
  NO = 'NO',
}

export enum HEALTH_TYPES {
  EQUIPMENT = 'EQUIPMENT',
  SENSORS = 'SENSORS',
  GATEWAYS = 'GATEWAYS',
}

export type TCriticality = `${CRITICALITY.LOW}` | `${CRITICALITY.HIGH}` | `${CRITICALITY.NO}`;
