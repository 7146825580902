import { BehaviorSubject } from 'rxjs';

import { IConfig, REALTIME_COMMUNICATION_LOG_LEVEL } from './model';

const defaultConfig: IConfig = {
  url: '',
  logLevel: REALTIME_COMMUNICATION_LOG_LEVEL.INFORMATION,
};

export class ConfigService {
  private config$ = new BehaviorSubject<IConfig>(defaultConfig);

  public set = (config: Partial<IConfig>): void =>
    this.config$.next(this.createConfig({ ...defaultConfig, ...config }));

  public get = (): IConfig => this.config$.value;

  private createConfig = (config: IConfig): IConfig => {
    return {
      ...config,
    };
  };
}

export const configService = new ConfigService();
