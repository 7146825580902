import { queryClient, useMutation } from '@marlin/shared/utils/react-query';

import { deleteNode } from '../infrastructure/delete-node';
import { queryKey } from './query-key.enum';

export const useDeleteNode = () => {
  return useMutation({
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: queryKey.FLOW_LINK() });
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: queryKey.FLOW_LINK() });
      queryClient.invalidateQueries({ queryKey: queryKey.FLOW_LINKS() });
      queryClient.setQueryData(queryKey.GRAPH(), data);
    },
    mutationFn: deleteNode,
  });
};
