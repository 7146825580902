import { Dispatch, Reducer } from 'react';

import { DEVICE_DRAWER_ACTION_TYPE } from './types';

interface IDevice {
  id: string;
}

type TAction =
  | {
      type: DEVICE_DRAWER_ACTION_TYPE.SET_SENSORS;
      payload: { rows: IDevice[] };
    }
  | {
      type: DEVICE_DRAWER_ACTION_TYPE.RESET_SENSORS_CONTEXT;
    };

export type TDeviceDrawerState = {
  rows: IDevice[];
};

export type TDeviceDrawerAction = Dispatch<TAction>;

export const initialState: TDeviceDrawerState = {
  rows: [],
};

export const deviceDrawerReducer: Reducer<TDeviceDrawerState, TAction> = (state, action) => {
  switch (action.type) {
    case DEVICE_DRAWER_ACTION_TYPE.SET_SENSORS: {
      const { rows } = action.payload;
      return {
        ...state,
        rows,
      };
    }
    case DEVICE_DRAWER_ACTION_TYPE.RESET_SENSORS_CONTEXT: {
      return {
        ...initialState,
      };
    }
  }
};
