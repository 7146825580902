import { MarlinTheme } from '@marlin/shared/theme';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  idRow: {
    width: theme.typography.pxToRem(260),
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    wordBreak: 'keep-all',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: theme.typography.pxToRem(20),
    height: theme.typography.pxToRem(22),
  },
  label: {
    marginRight: theme.typography.pxToRem(8),
    color: theme.palette.text.secondary,
  },
  value: {
    fontSize: theme.typography.pxToRem(14),
  },
}));

interface IHeaderIdProps {
  id: string;
}

export function HeaderId({ id }: IHeaderIdProps) {
  const { classes } = useStyles();

  return (
    <div className={classes.idRow}>
      <Typography className={classes.label} variant="overline">
        {content.CARD_LABELS.ID}:
      </Typography>
      <Tooltip placement="top" text={id}>
        <span className={classes.value}>{id}</span>
      </Tooltip>
    </div>
  );
}
