import { MarlinTheme } from '@marlin/shared/theme';
import { statusIdToKeyAdapterSentinel, statusMode } from '@marlin/shared/utils/datapoint-mappers';
import { createDataTestId } from '@marlin/shared/utils/testing-utils';
import { Icon } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface ISceneProps {
  value?: string;
  label?: string | null;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  sceneContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
  },
  scene: {
    border: `1px solid ${theme.palette.divider}`,
    padding: `${theme.typography.pxToRem(2)} ${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(
      2
    )} ${theme.typography.pxToRem(4)}`,
    borderRadius: theme.typography.pxToRem(32),
    fontSize: theme.typography.pxToRem(10),
    fontWeight: theme.typography.fontWeightMedium,
    display: 'inline-flex',
    alignItems: 'center',
    marginTop: theme.typography.pxToRem(6),
    marginBottom: theme.typography.pxToRem(6),
  },
  icon: {
    '&.material-symbols-outlined': {
      marginRight: theme.typography.pxToRem(4),
      fontSize: theme.typography.pxToRem(16),
    },
  },
}));

export const Scene = ({ value, label }: ISceneProps) => {
  const { classes } = useStyles();

  const idToKey = statusIdToKeyAdapterSentinel(value || '');
  const scene = statusMode.sentinel.get(idToKey || '');

  if (!scene) {
    return null;
  }

  return (
    <div className={classes.sceneContainer}>
      <div className={classes.scene} data-testid={createDataTestId('statusMode', scene.mode, scene.equipmentLabel)}>
        <Icon baseClassName="material-symbols-outlined" data-testid="equipment-mode-icon" className={classes.icon}>
          {scene.iconTag}
        </Icon>
        {scene.mode} {label}
      </div>
    </div>
  );
};
