import { PERMISSIONS, usePermission } from '@marlin/shared/utils-permission';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Button } from '@mui/material';

import { IEntity } from '../../../utils/types';
import { content } from '../../content';
import { OrganizationItem } from '../../organization-item';
import { useStyles } from './organization-details-content.styles';
import { SwitcherActions } from './switcher-actions';

interface IOrganizationDetailsContentProps {
  entities: IEntity[];
  entityName?: string;
  onClose: () => void;
  onChange: (event: React.MouseEvent<HTMLElement>) => void;
  variant: 'marlin' | 'portfolio' | 'support';
  showChangeButton?: boolean;
}

const copyOrgName = (orgName: string) => navigator?.clipboard.writeText(orgName);

export const OrganizationDetailsContent = ({
  entities,
  entityName,
  onClose,
  onChange,
  variant,
  showChangeButton,
}: IOrganizationDetailsContentProps) => {
  const { classes } = useStyles();
  const isNexaSystem = usePermission(PERMISSIONS.SYSTEM_MAP);

  const onOrgItemClick = () => {
    if (entityName) {
      copyOrgName(entityName);
    }
    onClose();
  };

  return (
    <div className={classes.container}>
      <div className={classes.changeOrgContainer}>
        {variant !== 'support' ? (
          <div className={classes.title}>{variant === 'marlin' ? content.ORGANIZATION : content.PORTFOLIO}</div>
        ) : null}
        {showChangeButton && (
          <Button
            size="small"
            onClick={onChange}
            variant="outlined"
            className={classes.changeBtn}
            endIcon={<MoreHorizIcon />}
            data-testid="change-organization-button"
          >
            {content.CHANGE.toUpperCase()}
          </Button>
        )}
      </div>
      <OrganizationItem
        onChange={onOrgItemClick}
        isCurrentOrg={variant !== 'support'}
        orgName={entityName}
        showFullName={true}
        tierName={isNexaSystem ? content.NEXA_SYSTEM : content.NEXA_EQUIPMENT}
        isPortfolio={variant === 'portfolio'}
        isSupport={variant === 'support'}
      />
      <SwitcherActions variant={variant} onClose={onClose} />
    </div>
  );
};
