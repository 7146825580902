import { MarlinTheme } from '@marlin/shared/theme';
import { IChartThresholdSeries } from '@marlin/shared/utils-chart';
import { TAnalyticsTelemetryResponse } from '@marlin/shared/utils-format-reading';
import { useMemo } from 'react';

import { IChartMetadata } from '../types';

export const useMappedThresholdData = ({
  thresholdsData,
  thresholdsMetadata,
  theme,
}: {
  thresholdsData: TAnalyticsTelemetryResponse['telemetryData'] | undefined;
  thresholdsMetadata: IChartMetadata['thresholdsMetadata'];
  theme: MarlinTheme;
}): IChartThresholdSeries[] => {
  const values = useMemo(() => {
    if (!thresholdsData) return [];

    return thresholdsData.reduce<TAnalyticsTelemetryResponse['telemetryData']>((acc, data) => {
      return [
        ...acc,
        {
          values: data.values,
          datapointName: data.datapointName,
          unitOfMeasure: data.unitOfMeasure,
          manufacturerId: data.manufacturerId,
        },
      ];
    }, []);
  }, [thresholdsData]);

  return useMemo(
    () =>
      values.map((threshold) => {
        const thresholdWithMetadata = thresholdsMetadata.find(
          (metadataItem) => metadataItem.name.toLowerCase() === threshold.datapointName.toLowerCase()
        );

        return {
          name: threshold.datapointName,
          data: threshold.values.map((value) => ({ x: value.x, y: isNaN(Number(value.y)) ? null : Number(value.y) })),
          color: ['A', 'D'].includes(thresholdWithMetadata?.alias ?? '')
            ? theme.palette.thresholds.persianRed
            : theme.palette.thresholds.clementine,
          type: 'scatter',
          uom: threshold.unitOfMeasure,
          id: threshold.datapointName,
          position: thresholdWithMetadata?.position ?? 'top',
          alias: thresholdWithMetadata?.alias ?? '',
          label: thresholdWithMetadata?.label ?? '',
        };
      }),
    [values, thresholdsMetadata, theme]
  );
};
