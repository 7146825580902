import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: 'fit-content',
    marginTop: theme.typography.pxToRem(16),
  },

  noSensorsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingBottom: theme.typography.pxToRem(16),
  },

  noSensorsLine: {
    marginBottom: theme.typography.pxToRem(20),
  },

  noSensorsCta: {
    width: '100%',
    marginBottom: '12px',
  },

  manageLocationButton: {
    width: '100%',
  },
}));
