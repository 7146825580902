import { useUserPortfolios } from '@marlin/portfolio-data-access-portfolio';
import { useFeatureFlagsContext } from '@marlin/shared/utils-common-feature-flags-context';
import { PERMISSIONS, usePermission } from '@marlin/shared/utils-permission';
import { goToCreatePortfolio, goToMarlin, goToPortfolio, goToSupport } from '@marlin/shared/utils-router';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import MapsHomeWorkRoundedIcon from '@mui/icons-material/MapsHomeWorkRounded';
import { Divider, Icon } from '@mui/material';

import { PortfolioDetailsCreate } from '../../../utils/organization-details-create/portfolio-details-create';
import { SwitcherActionItem } from '../../../utils/switcher-action-item/switcher-action-item';
import { content } from '../../content';
import { useStyles } from './organization-details-content.styles';

interface ISwitcherActionsProps {
  variant: 'marlin' | 'portfolio' | 'support';
  onClose: () => void;
}

export const SwitcherActions = ({ variant, onClose }: ISwitcherActionsProps) => {
  const { classes } = useStyles();

  const { data: portfolioData } = useUserPortfolios();

  const { portfolio, support } = useFeatureFlagsContext();

  const allowedToSeeSupportPage = usePermission(PERMISSIONS.SHOW_SUPPORT_PAGE);

  const switcherActionIcon = (pageVariant: string) => {
    if (pageVariant === content.SUPPORT) {
      return <ManageAccountsRoundedIcon className={classes.orgIcon} data-testid="support-switcher-icon" />;
    }
    if (pageVariant === 'portfolio') {
      return (
        <Icon
          className={classes.orgIcon}
          baseClassName="material-symbols-outlined"
          data-testid="organization-switcher-icon"
        >
          location_home
        </Icon>
      );
    }
    if (!portfolioData?.portfolios.length) {
      return <AddCircleRoundedIcon className={classes.orgIcon} data-testid="portfolio-add-icon" />;
    }
    return <MapsHomeWorkRoundedIcon className={classes.orgIcon} data-testid="portfolio-switcher-icon" />;
  };

  const switcherActionLabel = (pageVariant: string) => {
    if (pageVariant === content.SUPPORT) {
      return content.MARLIN_SUPPORT;
    }
    if (pageVariant === 'portfolio') {
      return content.ORGANIZATION_OVERVIEW;
    }
    if (!portfolioData?.portfolios.length) {
      return content.PORTFOLIO_CREATE;
    }
    return content.PORTFOLIO_OVERVIEW;
  };

  const switcherActionTestId = (pageVariant: string) => {
    if (pageVariant === content.SUPPORT) {
      return content.SUPPORT;
    }
    if (pageVariant === 'portfolio') {
      return 'organization';
    }
    if (!portfolioData?.portfolios.length) {
      return 'create-portfolio';
    }
    return 'portfolio';
  };

  const switcherAction = (pageVariant: string) => {
    if (pageVariant === content.SUPPORT) {
      return () => {
        goToSupport();
      };
    }
    if (pageVariant === 'portfolio') {
      return () => {
        goToMarlin();
      };
    }
    return () => {
      if (!portfolioData?.portfolios.length) {
        goToCreatePortfolio();
      } else {
        goToPortfolio();
      }
    };
  };

  return (
    <>
      {variant === 'marlin' && (
        <div>
          {portfolio && (
            <>
              <Divider className={classes.divider} />
              <SwitcherActionItem
                icon={switcherActionIcon(variant)}
                action={switcherAction(variant)}
                label={switcherActionLabel(variant)}
                testId={switcherActionTestId(variant)}
              />
            </>
          )}
          {allowedToSeeSupportPage && support && (
            <>
              <Divider className={classes.divider} />
              <SwitcherActionItem
                icon={switcherActionIcon(content.SUPPORT)}
                action={switcherAction(content.SUPPORT)}
                label={switcherActionLabel(content.SUPPORT)}
                testId={switcherActionTestId(content.SUPPORT)}
              />
            </>
          )}
        </div>
      )}
      {variant === 'portfolio' && (
        <>
          <Divider className={classes.divider} />
          <PortfolioDetailsCreate />
          <div>
            <Divider className={classes.divider} />
            <SwitcherActionItem
              icon={switcherActionIcon(variant)}
              action={switcherAction(variant)}
              label={switcherActionLabel(variant)}
              testId={switcherActionTestId(variant)}
            />
            {allowedToSeeSupportPage && support && (
              <>
                <Divider className={classes.divider} />
                <SwitcherActionItem
                  icon={switcherActionIcon(content.SUPPORT)}
                  action={switcherAction(content.SUPPORT)}
                  label={switcherActionLabel(content.SUPPORT)}
                  testId={switcherActionTestId(content.SUPPORT)}
                />
              </>
            )}
          </div>
        </>
      )}
      {variant === 'support' && (
        <>
          <div>
            <Divider className={classes.divider} />
            <SwitcherActionItem
              icon={switcherActionIcon('portfolio')}
              action={switcherAction('portfolio')}
              label={switcherActionLabel('portfolio')}
              testId={switcherActionTestId('portfolio')}
            />
            {portfolio && (
              <>
                <Divider className={classes.divider} />
                <SwitcherActionItem
                  icon={switcherActionIcon('marlin')}
                  action={switcherAction('marlin')}
                  label={switcherActionLabel('marlin')}
                  testId={switcherActionTestId('marlin')}
                />
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};
