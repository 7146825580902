import { MarlinTheme } from '@marlin/shared/theme';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { alpha } from '@mui/system';
import { makeStyles } from 'tss-react/mui';

import { IAvatar, useAvatar } from './use-avatar.hook';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  root: {
    backgroundColor: theme.palette.action.selected,
    padding: `${theme.typography.pxToRem(3)} ${theme.typography.pxToRem(4)}`,
    marginRight: theme.typography.pxToRem(5),
    borderRadius: theme.typography.pxToRem(16),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: theme.typography.pxToRem(13),
  },

  avatar: {
    width: theme.typography.pxToRem(24),
    height: theme.typography.pxToRem(24),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.action.disabled,
    textAlign: 'center',
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.background.primary,
    borderRadius: theme.typography.pxToRem(16),
  },

  name: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.primary,
    marginLeft: theme.typography.pxToRem(3),
    marginRight: theme.typography.pxToRem(3),
    maxWidth: theme.typography.pxToRem(72),
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
    wordBreak: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  errorRoot: {
    backgroundColor: alpha(theme.palette.error.main, 0.12),
  },

  errorAvatar: {
    backgroundColor: theme.palette.error.main,
  },

  errorName: {
    color: theme.palette.error.main,
  },
}));

export const Avatar = ({ user, channelType }: IAvatar) => {
  const { classes } = useStyles();

  const { initials, tooltip, error } = useAvatar(user, channelType);

  return (
    <Tooltip placement="top" text={tooltip}>
      <div className={`${classes.root} ${error ? classes.errorRoot : ''}`}>
        <div className={`${classes.avatar} ${error ? classes.errorAvatar : ''}`}>{initials}</div>
        <div className={`${classes.name} ${error ? classes.errorName : ''}`}>{user.firstName}</div>
      </div>
    </Tooltip>
  );
};
