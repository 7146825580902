import { TDashboardDevice } from '@marlin/asset/data-access/home-dashboard';
import { highBatteryLevel, mediumBatteryLevel } from '@marlin/asset/shared/ui/battery';

import { CRITICALITY } from './types';

export const transformCounter = (counter: number): string => {
  if (counter > 99) {
    return '99+';
  }
  return `${counter}`;
};

export const getBatteriesCriticality = (devices: TDashboardDevice[]) => {
  const lowestBatteryLevel = Math.min(...devices.map(({ batteryLevel }) => batteryLevel));

  if (lowestBatteryLevel < mediumBatteryLevel) {
    return CRITICALITY.HIGH;
  }

  if (lowestBatteryLevel < highBatteryLevel) {
    return CRITICALITY.LOW;
  }

  return CRITICALITY.NO;
};
