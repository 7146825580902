import { TCreateAutomation } from '@marlin/alert/data-access/automated-action';
import { MarlinTheme } from '@marlin/shared/theme';
import { INPUT_TYPE, Input } from '@marlin/shared/ui-form-common';
import { roundValue } from '@marlin/shared/utils-format-reading';
import { Checkbox } from '@mui/material';
import isFunction from 'lodash/isFunction';
import isNil from 'lodash/isNil';
import { ChangeEvent, ReactChild, useCallback, useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { TThresholdFieldName } from './paths.model';
import { parseValue } from './utils';

type TChildrenCallback = (props: { enabled: boolean; value: number | undefined }) => ReactChild | null;

interface IConditionProps {
  name: TThresholdFieldName;
  label: string;
  placeholder: string;
  unit: string;
  children: TChildrenCallback | ReactChild;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    fontSize: theme.typography.pxToRem(14), // TODO: (RWD) should be 16, 14 fits better
    marginBottom: theme.typography.pxToRem(24),
  },

  checkbox: {
    lineHeight: theme.typography.pxToRem(56),
  },

  input: {
    width: theme.typography.pxToRem(200),
  },

  endAdornment: {
    marginRight: theme.typography.pxToRem(16),
  },
}));

const conditionValueMask = /^\d*$/;

export const Threshold = ({ name, label, placeholder, unit, children }: IConditionProps) => {
  const { classes } = useStyles();

  const { trigger } = useFormContext<TCreateAutomation>();
  const { field: fieldInput, fieldState } = useController<TCreateAutomation>({ name: `${name}.value` });
  const { field: fieldCheckbox } = useController<TCreateAutomation>({ name: `${name}.enabled` });

  const endAdornment = useMemo(
    () => <span className={classes.endAdornment}>{unit}</span>,
    [classes.endAdornment, unit]
  );

  const handleToggle = useCallback(
    (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      fieldCheckbox.onChange({
        ...event,
        target: {
          value: checked,
        },
      });
      return trigger();
    },
    [fieldCheckbox, trigger]
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const parsedValue = parseValue(event.target.value);
      const value = typeof parsedValue === 'number' ? parsedValue : null;

      fieldInput.onChange({ ...event, target: { value } });
    },
    [fieldInput]
  );

  const handleBlur = () => {
    return trigger();
  };

  return (
    <>
      <div className={classes.container} data-testid={`threshold-${name}`}>
        <span className={classes.checkbox}>
          <Checkbox checked={!!fieldCheckbox.value} onChange={handleToggle} data-testid={`checkbox-${name}`} />
          <span data-testid={`threshold-${name}-label`}>{label}</span>
        </span>
        <Input
          name={name}
          onBlur={handleBlur}
          className={classes.input}
          disabled={!fieldCheckbox.value}
          label={placeholder}
          type={INPUT_TYPE.NUMBER}
          fullWidth={false}
          onChange={handleChange}
          value={isNil(fieldInput.value) ? '' : roundValue(fieldInput.value.toString(), 'decimal')}
          error={fieldState.error}
          mask={conditionValueMask}
          externalEndAdornment={{
            endAdornment,
          }}
          testId={`input-${name}`}
        />
      </div>
      {isFunction(children)
        ? children({ enabled: !!fieldCheckbox.value, value: fieldInput.value as number })
        : children}
    </>
  );
};
