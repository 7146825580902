import { useUpdateGraph } from '@marlin/system-map/data-access/system-map';
import {
  Edge,
  EdgeChange,
  NodeChange,
  ReactFlowInstance,
  ReactFlowJsonObject,
  useEdgesState,
  useNodesInitialized,
  useNodesState,
  useReactFlow,
} from '@xyflow/react';
import { useCallback, useEffect, useState } from 'react';

import { TSystemMapNode } from '../types';
import { useSystemMapState$ } from '../use-observable-system-map-state.hook';
import { useLayout } from './use-layout.hook';
import { useSystemMapData } from './use-system-map-data.hook';

type TReactFlowInstance = ReactFlowInstance<TSystemMapNode>;

interface IFlowDiagram {
  nodes: TSystemMapNode[];
  edges: Edge[];
  rfInstance: TReactFlowInstance | null;
  handleNodeChange: (node: NodeChange<TSystemMapNode>[]) => void;
  handleEdgeChange: (edge: EdgeChange[]) => void;
  saveDiagram: () => void;
  setRfInstance: (instance: TReactFlowInstance) => void;
  isGraphLoading: boolean;
}

export const useFlowDiagram = (): IFlowDiagram => {
  const { nodes: initialNodes, edges: initialEdges, isGraphLoading } = useSystemMapData();
  const graphUpdateMutation = useUpdateGraph();
  const { layoutNodes, layoutEdges } = useLayout(initialNodes, initialEdges);
  const [nodes, setNodes, onNodesChange] = useNodesState<TSystemMapNode>(layoutNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(layoutEdges);
  const [rfInstance, setRfInstance] = useState<TReactFlowInstance | null>(null);
  const { setViewport } = useReactFlow();
  const isInitialized = useNodesInitialized();
  //
  const restoreReactSystemMap = useCallback(
    ({ nodes, edges, viewport }: ReactFlowJsonObject) => {
      setNodes(nodes as TSystemMapNode[]);
      setEdges(edges);
      setViewport(viewport);
    },
    [setNodes, setEdges, setViewport]
  );

  const { updateState, clearState } = useSystemMapState$({
    restoreReactSystemMap,
  });

  const handleNodeChange = (node: NodeChange<TSystemMapNode>[]) => {
    onNodesChange(node);
  };

  const handleEdgeChange = (edge: EdgeChange[]) => {
    onEdgesChange(edge);
  };

  useEffect(() => {
    setNodes(layoutNodes);
  }, [layoutNodes, setNodes]);

  useEffect(() => {
    setEdges(layoutEdges);
  }, [layoutEdges, setEdges]);

  const saveDiagram = () => {
    const json = rfInstance?.toObject();
    updateState(json);
  };

  const onInit = (instance: TReactFlowInstance) => {
    setRfInstance(instance);
  };

  useEffect(() => {
    if (isGraphLoading || graphUpdateMutation.isLoading) {
      clearState();
    }
  }, [isInitialized, clearState, isGraphLoading, graphUpdateMutation.isLoading]);

  return {
    nodes,
    edges,
    rfInstance,
    handleNodeChange,
    handleEdgeChange,
    saveDiagram,
    setRfInstance: onInit,
    isGraphLoading: isGraphLoading || graphUpdateMutation.isLoading,
  };
};
