import { z } from 'zod';

export interface ILocation {
  id: string;
  name: string;
  createdByUserId: string;
  createdDateTime: string;
  modifiedByUserId: string;
  modifiedDateTime: string;
  parentLocation?: {
    id: string;
    name: string;
  };
  parentLocationId?: string;
  locationType?: string;
  createdAt?: number;
  address1?: string;
  address2?: string;
  city?: string;
  country?: string;
  description?: string;
  postalCode?: string;
  state?: string;
  equipmentCount?: number;
}

// TODO: Move to ZOD
export interface IAddress {
  address1?: string;
  address2?: string;
  postalCode?: string;
  state?: string;
  city?: string;
  country?: string;
}

// TODO: Move to ZOD
export interface IAdditionalInformation {
  numberOfEquipments: number;
  numberOfDevices: number;
  numberOfAutomations: number;
  locationId: string;
}

export enum LOCATION_TYPE {
  PLANT = 'Plant',
  MECHANICAL_ROOM = 'MechanicalRoom',
  NOT_CLASSIFIED = 'NotClassified',
  GUEST_ROOM = 'GuestRoom',
  LAUNDRY = 'Laundry',
  KITCHEN = 'Kitchen',
}
export const LocationTypes = z.nativeEnum(LOCATION_TYPE);
