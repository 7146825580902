import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Key, SyntheticEvent, useCallback } from 'react';

import { ITab } from '../types';
import { useStyles } from './tabs.styles';

export interface ITabsProps<TTabId extends Key> {
  tabs: ITab<TTabId>[];
  tabValue: TTabId;
  updateTab: (newValue: TTabId) => void;
}

export function ScrollableTabs<TTabId extends Key>({ tabValue, updateTab, tabs }: ITabsProps<TTabId>) {
  const { classes } = useStyles();

  const handleTableChange = useCallback(
    (_: SyntheticEvent, newValue: TTabId) => {
      updateTab(newValue);
    },
    [updateTab]
  );

  return (
    <Tabs
      variant="scrollable"
      scrollButtons={false}
      value={tabValue}
      onChange={handleTableChange}
      data-testid="scrollableTabs"
    >
      {tabs.map((tab) => (
        <Tab data-testid={`tab-${tab.id}`} key={tab.id} value={tab.id} className={classes.tab} label={tab.label} />
      ))}
    </Tabs>
  );
}
