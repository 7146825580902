import { PageHeader, Paper } from '@marlin/shared/ui-page';
import MiscellaneousServicesRoundedIcon from '@mui/icons-material/MiscellaneousServicesRounded';

export const Header = () => {
  return (
    <Paper testId="card">
      <PageHeader
        icon={<MiscellaneousServicesRoundedIcon />}
        title="Build your automation"
        subtitle="Utilize the power of the graph to create advanced automation"
        prefix="automations-header"
        marginBottom={false}
      />
    </Paper>
  );
};
