import { useExportTelemetry } from '@marlin/asset/data-access/device';
import { MarlinTheme } from '@marlin/shared/theme';
import { CustomSizedModal } from '@marlin/shared/ui-modal';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { AVERAGING_FUNCTION_FILTER, RANGE_FILTER, TChartType, getTimeRangeTimestamp } from '@marlin/shared/utils-chart';
import { Divider, SelectChangeEvent } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useCallback, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';
import { RadioButtons } from './radio-buttons/radio-buttons';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  modalFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    columnGap: theme.typography.pxToRem(8),
  },
  modalBody: {
    display: 'block',
    marginTop: theme.typography.pxToRem(-24),
  },
  content: {
    width: '100%',
  },
  headerTitle: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 400,
    color: theme.palette.text.secondary,
    lineHeight: theme.typography.pxToRem(24),
  },
  headerChartName: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 400,
    lineHeight: theme.typography.pxToRem(24),
    color: theme.palette.text.primary,
  },
  headaerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.typography.pxToRem(8),
    marginBottom: theme.typography.pxToRem(8),
  },
}));

interface IExportCSVModalProps {
  onClose: () => void;
  chartTitle?: string;
  selectedSensors?: string[];
  getTelemetryExport: (averagingFunctionFilter?: AVERAGING_FUNCTION_FILTER) => Promise<{ url: string }>;
  chartType: TChartType;
  chartId: string;
}

export const ExportCsvModal = ({
  onClose,
  chartTitle,
  selectedSensors,
  getTelemetryExport,
  chartType,
  chartId,
}: IExportCSVModalProps) => {
  const { classes } = useStyles();
  const [averagingFunctionFilter, setAveragingFilter] = useState<AVERAGING_FUNCTION_FILTER>(
    AVERAGING_FUNCTION_FILTER.AVERAGE
  );
  const [selectedValue, setSelectedValue] = useState<string>('showAsAggregated');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { mutateAsync: exportTelemetry } = useExportTelemetry();
  const onAveragingFunctionFilterChange = (event: SelectChangeEvent<AVERAGING_FUNCTION_FILTER>) => {
    setAveragingFilter(event?.target.value as AVERAGING_FUNCTION_FILTER);
  };
  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  }, []);

  const submitExport = useCallback(async () => {
    const exportingSnackbarId = enqueueSnackbar({
      variant: 'loadingSnackbar',
      preventDuplicate: true,
      message: content.EXPORTING_DATA_BODY,
      title: content.EXPORTING_DATA_TITLE,
      persist: true,
    });

    try {
      let link = '';
      if (selectedValue === 'showAsAggregated') {
        const { url } = await getTelemetryExport(averagingFunctionFilter);
        link = url;
      }
      if (selectedValue === 'showAllData') {
        const timeRange = getTimeRangeTimestamp(RANGE_FILTER.DAYS_30, new Date().getTime(), false);
        if (timeRange[0] && timeRange[1]) {
          const { url } = await exportTelemetry({
            fromDate: timeRange[0],
            toDate: timeRange[1],
            manufacturerIds: selectedSensors,
          });
          link = url;
        }
      }
      window.open(link, '_blank');
      enqueueSnackbar(content.DATA_EXPORTED, {
        variant: 'success',
        preventDuplicate: true,
      });
    } catch {
      enqueueSnackbar(content.ERROR, {
        variant: 'error',
        preventDuplicate: true,
      });
    } finally {
      closeSnackbar(exportingSnackbarId);
    }
  }, [
    averagingFunctionFilter,
    closeSnackbar,
    enqueueSnackbar,
    exportTelemetry,
    getTelemetryExport,
    selectedSensors,
    selectedValue,
  ]);

  const onExportClick = useCallback(() => {
    submitExport();
    onClose();
  }, [onClose, submitExport]);

  return (
    <CustomSizedModal
      width={500}
      onClose={onClose}
      title={content.EXPORT_CSV_MODAL.TITLE}
      body={
        <div className={classes.modalBody}>
          <div className={classes.headaerWrapper}>
            <Typography className={classes.headerTitle}>{content.EXPORT_CSV_MODAL.DATA_CHART}:</Typography>
            <Typography variant="subtitle1">{chartTitle}</Typography>
          </div>
          <Divider />
          <div className={classes.headaerWrapper}>
            <Typography className={classes.headerTitle}>{content.EXPORT_CSV_MODAL.DATA_POINTS}:</Typography>
            <RadioButtons
              value={selectedValue}
              onChange={onChange}
              averagingFunctionFilter={averagingFunctionFilter}
              onAveragingFunctionFilterChange={onAveragingFunctionFilterChange}
              chartType={chartType}
              chartId={chartId}
            />
          </div>
        </div>
      }
      footer={
        <div className={classes.modalFooter}>
          <Button variant="text" data-testid="export-csv-modal-cancel-button" onClick={onClose}>
            {content.EXPORT_CSV_MODAL.CANCEL}
          </Button>
          <Button variant="contained" data-testid="export-csv-modal-export-button" onClick={onExportClick}>
            {content.EXPORT_CSV_MODAL.EXPORT_SENSOR_DATA}
          </Button>
        </div>
      }
    />
  );
};
