import { TDateString } from '@marlin/shared/utils-common-date';

export enum DEVICE_TYPE {
  TEMPERATURE = 'TEMPERATURE',
  PRESSURE = 'PRESSURE',
  LEAK = 'LEAK',
  PULSE_METER = 'PULSE_METER',
  UNKNOWN = 'UNKNOWN',
}

export type TCriticality = 'Low' | 'High';

export enum CRITICALITY {
  LOW = 'LOW',
  HIGH = 'HIGH',
}

export enum CRITICALITY_RESPONSE {
  LOW = 'Low',
  HIGH = 'High',
}

export interface IPinDevice {
  id: string;
  order: number;
  owner: {
    id: string;
  };
  device: {
    id: string;
    name: string;
    type: DEVICE_TYPE;
    lastReading: {
      date: TDateString;
      value: number;
      unitOfMeasure: string;
    };
  };
  location: {
    id: string;
    name: string;
  };
  equipment: {
    id: string;
    name: string;
  };
  alert: {
    criticality: CRITICALITY;
  };
}

export interface IPinDeviceResponse {
  id: string;
  ownerId: string;
  order: number;
  deviceId: string;
  deviceName: string;
  deviceType: DEVICE_TYPE;
  lastReadingValue: number;
  lastReadingTime: string;
  locationId: string;
  locationName: string;
  equipmentId: string;
  equipmentName: string;
  alertCriticality: CRITICALITY_RESPONSE;
  unitOfMeasure: string;
}
