import { useCallback, useMemo, useState } from 'react';

export interface IUsePaginationProps {
  initialPage?: number;
  initialPageSize?: number;
}

export interface IUsePaginationValue {
  page: number;
  pageSize: number;
  changePage: (newPage: number) => void;
  changePageSize: (newPageSize: number) => void;
  reset: () => void;
}

export const usePagination = ({ initialPage = 1, initialPageSize = 100 }: IUsePaginationProps): IUsePaginationValue => {
  const [page, setPage] = useState(initialPage);
  const [pageSize, setPageSize] = useState(initialPageSize);

  const changePage = useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  const changePageSize = useCallback(
    (newPageSize: number) => {
      setPage(initialPage);
      setPageSize(newPageSize);
    },
    [initialPage]
  );

  const reset = useCallback(() => {
    setPage(initialPage);
    setPageSize(initialPageSize);
  }, [initialPage, initialPageSize]);

  const pagination: IUsePaginationValue = useMemo(
    () => ({
      page,
      pageSize,
      changePage,
      changePageSize,
      reset,
    }),
    [changePage, changePageSize, page, pageSize, reset]
  );

  return pagination;
};
