import { TNode } from '@marlin/system-map/data-access/system-map';
import { TTag } from '@marlin/system-map/shared/system-map-link-form';

export enum ASSET_TYPE {
  LOCATION = 'LOCATION',
  EQUIPMENT = 'EQUIPMENT',
  DEVICE = 'DEVICE',
}

export enum NODE_TYPE {
  INLET = 'inlet',
  OUTLET = 'outlet',
}

export interface IAsset {
  id: string;
  name: string;
  type: ASSET_TYPE;
}

export interface IAssetType {
  id: ASSET_TYPE | '';
  name: string;
}

export interface IFlowLink {
  isAttachment?: boolean;
  inlet?: TNode;
  outlet?: TNode;
  linkTags?: TTag[];
}
