export const content = {
  TITLE: {
    LOWER: (min?: string) => `fell below ${min}`,
    HIGHER: (max?: string) => `exceeded ${max}`,
    LEAK: `detected a leak`,
    LOST_COMMUNICATION: 'has lost communication',
    LOW_BATTERY: 'has low battery',
    SYSTEM_MAP_LEAK: `Leak Detected`,
    SYSTEM_MAP_LOST_COMMUNICATION: 'Lost Communication',
    SYSTEM_MAP_LOW_BATTERY: 'Low Battery',
  },
  SENSOR_TYPES: {
    TEMPERATURE: 'Temperature',
    PRESSURE: 'Pressure',
    LEAK: 'Leak',
    FLOW: 'Flow',
  },
  ABSOLUTE_DIFFERENCE: {
    LABEL: {
      LOWER: (value: string): string => `${value} lower than trigger`,
      UPPER: (value: string): string => `${value} higher than trigger`,
    },
  },

  LAST_READING: {
    MINUTES: (minutes: number): string => `LAST READING as of ${minutes}min ago`,
    HOURS: (hours: number, minutes: number): string => `LAST READING as of ${hours}h ${minutes}min ago`,
  },
};
