import { ITab, TAB_INDEX } from './tabs.types';

export interface ITotalItems {
  current: number;
  resolved: number;
  all: number;
  snoozed: number;
}

export const getTotalItemsForTab = (tab: ITab, totalItems?: ITotalItems): number | undefined => {
  if (totalItems === undefined) {
    return undefined;
  }

  switch (tab.id) {
    case TAB_INDEX.CURRENT: {
      return totalItems.current;
    }

    case TAB_INDEX.SNOOZED: {
      return totalItems.snoozed;
    }

    case TAB_INDEX.RESOLVED: {
      return totalItems.resolved;
    }

    case TAB_INDEX.ALL: {
      return totalItems.all;
    }

    default: {
      return undefined;
    }
  }
};

export const getLabelForTab = (tab: ITab, totalItems?: ITotalItems): string => {
  const tabItemsCount = getTotalItemsForTab(tab, totalItems);

  if (tabItemsCount === undefined) {
    return tab.label;
  }

  return `${tab.label} (${tabItemsCount})`;
};
