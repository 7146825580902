// this function is used to replace all occurrences of '?' in the string except for the first one
// eg. fixQueryString('http://example.com?param1=value1?param2=value2') => 'http://example.com?param1=value1&param2=value2'
// to fix errors caused by applying query string multiple times
export function fixInvalidQueryString(str: string, char = '?', replacement = '&') {
  const firstOccurrenceIndex = str.indexOf(char);
  if (firstOccurrenceIndex === -1) {
    return str;
  }

  const before = str.slice(0, firstOccurrenceIndex + 1);
  const after = str.slice(firstOccurrenceIndex + 1);

  return before + after.replaceAll(char, replacement);
}
