import { MarlinTheme } from '@marlin/shared/theme';
import { goToHelp } from '@marlin/shared/utils-router';
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

import { Link } from '../link.component';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  nexaLogo: {
    height: theme.typography.pxToRem(40),
    width: theme.typography.pxToRem(162),
  },
  errorLogo: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.typography.pxToRem(16)} 0`,
    justifyContent: 'center',
    [theme.breakpoints.down('lg')]: {
      textAlign: 'center',
      padding: `${theme.typography.pxToRem(16)} 0 ${theme.typography.pxToRem(8)}`,
      '& svg': {
        width: theme.typography.pxToRem(198),
        height: theme.typography.pxToRem(112),
      },
    },
  },
  container: {
    maxWidth: theme.typography.pxToRem(800),
    margin: '0 auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${theme.typography.pxToRem(48)} 0`,
    [theme.breakpoints.down('lg')]: {
      padding: `${theme.typography.pxToRem(48)} ${theme.typography.pxToRem(16)}`,
    },
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.typography.pxToRem(8),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.typography.pxToRem(50),
    },
  },
  button: {
    marginTop: theme.typography.pxToRem(24),
  },
  footer: {
    marginTop: theme.typography.pxToRem(24),
    color: theme.palette.text.secondary,
  },
  body2: {
    whiteSpace: 'pre-line',
    marginTop: theme.typography.pxToRem(32),
  },
  body3: {
    marginTop: theme.typography.pxToRem(24),
  },
}));

interface IErrorPageLayoutProps {
  onBackClick: () => void;
  onlyContent?: boolean;
  content: {
    TITLE: string;
    BODY_1: string;
    BODY_2: string;
    BACK_BUTTON: string;
    BODY_3: string;
    HELP_CENTER: string;
    BODY_4: string;
    FOOTER: string;
  };
  errorIcon: ReactNode;
}

export const ErrorPageLayout = ({ onBackClick, onlyContent = false, content, errorIcon }: IErrorPageLayoutProps) => {
  const { classes, theme } = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <div className={classes.container}>
      {!isMobile && !onlyContent && <div className={classes.nexaLogo}>{theme.icon.fullLogo}</div>}
      <div className={classes.content}>
        <div className={classes.errorLogo}>{errorIcon}</div>
        <Typography variant="h4" align="center" className={classes.title}>
          {content.TITLE}
        </Typography>
        <Typography variant="subtitle1" align="center">
          {content.BODY_1}
        </Typography>
        <Typography variant="subtitle1" align="center" className={classes.body2}>
          {content.BODY_2}
        </Typography>
        <Button className={classes.button} variant="contained" onClick={onBackClick}>
          {content.BACK_BUTTON}
        </Button>
        <div className={classes.body3}>
          <Typography variant="subtitle1" align="center">
            {content.BODY_3}
            <Link to="#" onClick={() => goToHelp()}>
              {content.HELP_CENTER}
            </Link>
            {content.BODY_4}
          </Typography>
        </div>
      </div>
      {!onlyContent && (
        <Typography className={classes.footer} variant="body2">
          {content.FOOTER}
        </Typography>
      )}
    </div>
  );
};
