import { TDashboardHealthSystemSchema } from '@marlin/asset/data-access/home-dashboard';
import { MarlinTheme } from '@marlin/shared/theme';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import DeviceHubRounded from '@mui/icons-material/DeviceHubRounded';
import RouterRounded from '@mui/icons-material/RouterRounded';
import SensorsRounded from '@mui/icons-material/SensorsRounded';
import { Divider } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { CRITICALITY, HEALTH_TYPES } from '../../../shared/types';
import { transformCounter } from '../../../shared/utils';
import { content } from './content';
import { CriticalityControl } from './criticality-control.component';
import { DotControl } from './dot-control.component';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    columnGap: theme.typography.pxToRem(24),
    flexDirection: 'column',
  },
  section: {
    padding: theme.typography.pxToRem(16),
    paddingTop: theme.typography.pxToRem(8),
    paddingBottom: theme.typography.pxToRem(8),
    columnGap: theme.typography.pxToRem(16),
    display: 'flex',
  },
  iconContainer: {
    display: 'flex',
    alignSelf: 'center',
    width: theme.typography.pxToRem(160),
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      alignSelf: 'normal',
      flexDirection: 'row',
      marginTop: theme.typography.pxToRem(4),
    },
  },
  icon: {
    marginRight: theme.typography.pxToRem(4),
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
}));

interface ICriticality {
  health: TDashboardHealthSystemSchema;
  batteriesCriticality: CRITICALITY;
}

export const LocationSection = ({ health, batteriesCriticality }: ICriticality) => {
  const { classes } = useStyles();
  const { goTo } = useRouter();

  const navigateToHubs = (type?: 'Equipments' | 'Sensors' | 'Gateways') => {
    switch (type) {
      case 'Equipments': {
        goTo(routes.equipments.list.url());
        break;
      }
      case 'Sensors': {
        goTo(routes.sensors.list.url());
        break;
      }
      case 'Gateways': {
        goTo(routes.gateway.list.url());
        break;
      }
    }
  };

  const inactiveDevices = health.equipment.deviceCount === 0 && health.sensors.deviceCount === 0;

  return (
    <div className={classes.container}>
      {inactiveDevices ? (
        <>
          <div className={classes.section}>
            <CriticalityControl severity={CRITICALITY.NO} />
          </div>
          <Divider />
          <div className={classes.section}>
            <CriticalityControl severity={batteriesCriticality} batteries={!!batteriesCriticality} />
          </div>
          <Divider />
        </>
      ) : (
        <>
          {health.equipment.deviceCount > 0 && (
            <>
              <div className={classes.section} data-testid="health-section">
                <div
                  className={classes.iconContainer}
                  data-testid="health-assetName"
                  onClick={() => navigateToHubs('Equipments')}
                >
                  <DeviceHubRounded className={classes.icon} />
                  {content.EQUIPMENT_ICON(transformCounter(health.equipment.deviceCount))}
                </div>
                <div className={classes.sectionContainer} data-testid="health-assetStatus">
                  <DotControl data={health.equipment} type={HEALTH_TYPES.EQUIPMENT} />
                </div>
              </div>
              <Divider />
            </>
          )}
          {health.sensors.deviceCount > 0 && (
            <>
              <div className={classes.section} data-testid="health-section">
                <div
                  className={classes.iconContainer}
                  data-testid="health-assetName"
                  onClick={() => navigateToHubs('Sensors')}
                >
                  <SensorsRounded className={classes.icon} />
                  {content.SENSORS_ICON(transformCounter(health.sensors.deviceCount))}
                </div>
                <div className={classes.sectionContainer} data-testid="health-assetStatus">
                  <DotControl data={health.sensors} type={HEALTH_TYPES.SENSORS} />
                  <CriticalityControl severity={batteriesCriticality} batteries={!!batteriesCriticality} />
                </div>
              </div>
              <Divider />
            </>
          )}

          {health.gateways.deviceCount > 0 && (
            <>
              <div className={classes.section} data-testid="health-section">
                <div
                  className={classes.iconContainer}
                  data-testid="health-assetName"
                  onClick={() => navigateToHubs('Gateways')}
                >
                  <RouterRounded className={classes.icon} />
                  {content.GATEWAYS_ICON(transformCounter(health.gateways.deviceCount))}
                </div>
                <div className={classes.sectionContainer} data-testid="health-assetStatus">
                  <DotControl data={health.gateways} type={HEALTH_TYPES.GATEWAYS} />
                </div>
              </div>
              <Divider />
            </>
          )}
        </>
      )}
    </div>
  );
};
