import { AUTOMATION_RULE_TYPE } from '@marlin/alert/data-access/automated-action';
import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

import { useContent } from '../../../../context';
import { GatewayDurationWarning } from '../../warnings/gateway-duration-warning.component';
import { Duration } from '../condition/duration.component';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  warning: {
    marginTop: theme.typography.pxToRem(16),
    paddingLeft: theme.typography.pxToRem(32),
  },
}));

export function GatewayLostCommunicationConditions() {
  const content = useContent();
  const { classes } = useStyles();

  return (
    <>
      <Duration
        label={content.CONDITIONS.DURATION.GATEWAY_INACTIVITY_DURATION}
        ruleType={AUTOMATION_RULE_TYPE.GATEWAY_LOST_COMMUNICATION}
      />
      <div className={classes.warning}>
        <GatewayDurationWarning />
      </div>
    </>
  );
}
