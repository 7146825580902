import { TParsedScheduleDetails } from '@marlin/asset/data-access/equipment';
import { MarlinTheme } from '@marlin/shared/theme';
import ArrowRightAltRoundedIcon from '@mui/icons-material/ArrowRightAltRounded';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { getTime } from '../../../../shared/utils/time-formater';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  occupiedTime: {
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',
    padding: theme.typography.pxToRem(6),
    marginRight: theme.typography.pxToRem(16),
    marginTop: theme.typography.pxToRem(8),
    backgroundColor: theme.palette.background.secondary,
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.secondary,
    maxWidth: theme.typography.pxToRem(265),
    '& > span': {
      color: theme.palette.text.primary,
      width: theme.typography.pxToRem(72),
      '&:first-child': {
        paddingLeft: theme.typography.pxToRem(4),
      },
    },
  },
}));

interface IOccupiedSection {
  label: string;
  from: TParsedScheduleDetails;
  to: TParsedScheduleDetails;
}

export const OccupiedTime = ({ label, from, to }: IOccupiedSection) => {
  const { classes } = useStyles();

  return (
    <div className={classes.occupiedTime}>
      {label}: <span>{getTime(from)}</span> <ArrowRightAltRoundedIcon color="action" /> <span>{getTime(to)}</span>
    </div>
  );
};
