import { Analytics } from '@marlin/asset/feature/analytics';
import { environment } from '@marlin/environment';
import { routes } from '@marlin/shared/utils-routes';
import { Route } from 'react-router-dom';

export const AnalyticsV2Routes = (): JSX.Element | null => {
  const analyticsV2 = environment.module.features.analyticsv2;

  if (!analyticsV2) {
    return null;
  }

  return <Route path={routes.analyticsv2.root} element={<Analytics />} />;
};
