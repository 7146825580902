import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

interface IStyles {
  isExpanded: boolean;
}

export const useStyles = makeStyles<IStyles>()((theme: MarlinTheme, { isExpanded }) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: theme.typography.pxToRem(4),
  },
  averageValueText: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: isExpanded ? theme.typography.fontWeightBold : theme.typography.fontWeightRegular,
    wordBreak: 'break-all',
    [theme.breakpoints.down('md')]: {
      maxWidth: theme.typography.pxToRem(250),
      fontSize: theme.typography.pxToRem(12),
    },
  },
  averagePropText: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
    textTransform: 'uppercase',
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(12),
      color: theme.palette.text.secondary,
    },
  },
}));
