import { MarlinTheme } from '@marlin/shared/theme';
import { useTheme } from '@mui/material/styles';
import { ReactNode, useMemo } from 'react';

import { ArrowClosedSymbol, CircleSymbol } from './symbols';

export enum MARKER_TYPE {
  ARROW_DEFAULT = 'arrowDefault',
  ARROW_ERROR = 'arrowError',
  ARROW_WARNING = 'arrowWarning',
  CIRCLE_DEFAULT = 'circleDefault',
  CIRCLE_ERROR = 'circleError',
  CIRCLE_WARNING = 'circleWarning',
}

export type TMarlinMarkerProps = {
  type: MARKER_TYPE;
  color: string;
  children?: ReactNode;
};

export const useMarlinMarkerDefinitions = (): TMarlinMarkerProps[] => {
  const theme = useTheme<MarlinTheme>();

  return useMemo(
    () => [
      {
        type: MARKER_TYPE.ARROW_DEFAULT,
        color: theme.palette.systemMap.main,
        children: <ArrowClosedSymbol color={theme.palette.systemMap.main} />,
      },
      {
        type: MARKER_TYPE.ARROW_ERROR,
        color: theme.palette.error.main,
        children: <ArrowClosedSymbol color={theme.palette.error.main} />,
      },
      {
        type: MARKER_TYPE.ARROW_WARNING,
        color: theme.palette.warning.main,
        children: <ArrowClosedSymbol color={theme.palette.warning.main} />,
      },
      {
        type: MARKER_TYPE.CIRCLE_DEFAULT,
        color: theme.palette.systemMap.main,
        children: <CircleSymbol color={theme.palette.systemMap.main} />,
      },
      {
        type: MARKER_TYPE.CIRCLE_ERROR,
        color: theme.palette.error.main,
        children: <CircleSymbol color={theme.palette.error.main} />,
      },
      {
        type: MARKER_TYPE.CIRCLE_WARNING,
        color: theme.palette.warning.main,
        children: <CircleSymbol color={theme.palette.warning.main} />,
      },
    ],
    [theme.palette.error.main, theme.palette.systemMap.main, theme.palette.warning.main]
  );
};
