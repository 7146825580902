import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

interface IStylesProps {
  tooltip: boolean;
}

// todo: please remove this style after MUI fixes this issue: https://github.com/mui/material-ui/issues/33519. Bug: 15511
export const useStyles = makeStyles<IStylesProps>()((theme: MarlinTheme, { tooltip }) => ({
  input: {
    '&> .MuiOutlinedInput-root': {
      paddingRight: 0,
      '& > input': {
        paddingRight: tooltip ? theme.typography.pxToRem(50) : theme.typography.pxToRem(15),
      },
    },

    '&> .MuiFormHelperText-root': {
      paddingLeft: theme.typography.pxToRem(5),
      marginLeft: 0,
      marginRight: 0,
    },
  },

  tooltip: {
    position: 'absolute',
    right: theme.typography.pxToRem(15),
  },
}));
