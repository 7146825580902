import { TExecuteOperationResponse, TOperation } from '@marlin/asset/data-access/gateway';
import { useMemo } from 'react';

const groupOperationsByAddrId = (operations: TOperation[]) => {
  return operations.reduce((acc, operation) => {
    const { addressId } = operation;

    if (!acc[addressId]) {
      acc[addressId] = [];
    }
    acc[addressId].push(operation);

    return acc;
  }, {} as Record<string, TOperation[]>);
};

const groupOperationsByConnectionId = (operations: TOperation[]) => {
  return operations.reduce((acc, operation) => {
    const { connectionId } = operation;

    if (!acc[connectionId]) {
      acc[connectionId] = [];
    }
    acc[connectionId].push(operation);

    return acc;
  }, {} as Record<string, TOperation[]>);
};

const getLengths = (groupedOperations: Record<string, TOperation[]>) => {
  return Object.keys(groupedOperations).reduce((acc, key) => {
    acc[key] = groupedOperations[key].length;

    return acc;
  }, {} as Record<string, number>);
};

const getCountFinishedConnection = (
  finishedOperations: TExecuteOperationResponse[],
  lengthForEachConnection: Record<string, number>
) => {
  let countFinishedId = 0;

  Object.keys(lengthForEachConnection).forEach((key) => {
    let currentFinishedConnection = 0;

    finishedOperations.forEach(({ operations }) => {
      operations.forEach(({ connectionId }) => {
        if (connectionId === key) {
          currentFinishedConnection += 1;
        }
      });
    });

    if (lengthForEachConnection[key] === currentFinishedConnection) {
      countFinishedId += 1;
    }
  });

  return countFinishedId;
};

const getCountFinishedAddresses = (
  finishedOperations: TExecuteOperationResponse[],
  lengthForEachAddress: Record<string, number>
) => {
  let countFinishedId = 0;

  Object.keys(lengthForEachAddress).forEach((key) => {
    let currentFinishedAddress = 0;

    finishedOperations.forEach(({ operations }) => {
      operations.forEach(({ addressId }) => {
        if (addressId === key) {
          currentFinishedAddress += 1;
        }
      });
    });

    if (lengthForEachAddress[key] === currentFinishedAddress) {
      countFinishedId += 1;
    }
  });

  return countFinishedId;
};

export const useCounterFinishedOperation = (
  operations: TOperation[],
  finishedOperations: TExecuteOperationResponse[]
) => {
  const allOperationsPerAddress = groupOperationsByAddrId(operations);
  const allOperationsPerConnections = groupOperationsByConnectionId(operations);
  const lengthForEachAddress = getLengths(allOperationsPerAddress);
  const lengthForEachConnection = getLengths(allOperationsPerConnections);

  const countAddressesFinishedId = useMemo(
    () => getCountFinishedAddresses(finishedOperations, lengthForEachAddress),
    [finishedOperations, lengthForEachAddress]
  );
  const countConnectionFinishedId = useMemo(
    () => getCountFinishedConnection(finishedOperations, lengthForEachConnection),
    [finishedOperations, lengthForEachConnection]
  );

  return { countAddressesFinishedId, countConnectionFinishedId };
};
