export const content = {
  NOT_FOUND: {
    TITLE: 'Page Not Found',
    BODY_1: 'Requested URL could not be found on your site.',
    BODY_2: 'Check the address for any spelling errors \nor use the button below to return to the home page.',
    BACK_BUTTON: 'Back to Home',
    BODY_3: 'or contact ',
    HELP_CENTER: 'Help Center',
    BODY_4: ' to report it',
    FOOTER: '© 2024 Watts. All rights reserved.',
  },
  SYSTEM_CONSTRAINTS: {
    TITLE: 'System Constraints',
    BODY_1: 'Sorry, there were problems while using Nexa app.',
    BODY_2:
      'We’re trying to fix it. Please try again in a moment \nor use the button below to return to the home page.',
    BACK_BUTTON: 'Back to Home',
    BODY_3: 'or contact ',
    HELP_CENTER: 'Help Center',
    BODY_4: ' to report it',
    FOOTER: '© 2024 Watts. All rights reserved.',
  },
};
