import { Observable } from 'rxjs';

import { ConfigService } from './config.service';
import { IEndpointConfig, IHttpParams } from './endpoint.models';
import { EndpointService, IOption } from './endpoint.service';

export class EndpointFacade {
  public constructor(private endpointService: EndpointService, private configService: ConfigService) {}

  public getUrl = (
    url: string,
    httpParams?: IHttpParams | { [key: string]: string },
    options?: IOption
  ): Observable<string> => this.endpointService.getUrl(url, httpParams, options);

  public setConfig = (config: IEndpointConfig): void => this.configService.set(config);
}
