import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import { GraphSchema, TGraph, TGraphResponse, TUpdateGraphParams, UpdateGraphSchema } from '../flow-map.model.schema';
import { paths } from './api';

export const getGraph = async (): Promise<TGraph> => {
  const res = await getHttpClient().get<unknown, TGraphResponse>(paths.GRAPH);

  return safeParseData(res, GraphSchema);
};

export const updateGraph = async (graph: TUpdateGraphParams): Promise<TGraph> => {
  const graphToUpdate = safeParseData(graph, UpdateGraphSchema);

  const res = await getHttpClient().put<unknown, TGraphResponse>(paths.SYSTEM_MAP_NODES_POSITIONS, graphToUpdate);

  return safeParseData(res, GraphSchema);
};
