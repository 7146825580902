import { MarlinTheme } from '@marlin/shared/theme';
import { Loadable } from '@marlin/shared/ui-loader';
import { SectionContainer } from '@marlin/shared/ui-page';
import { EmptyChartMessage, Period, Sankey } from '@marlin/shared/ui/chart';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';
import { UnitSelector } from '../shared/unit-selector/unit-selector.component';
import { unitOptions } from './constants';
import { useWaterTraceability } from './use-water-traceability.hook';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  sections: {
    padding: theme.typography.pxToRem(16),
    height: '100%',
    position: 'relative',
  },
}));

export function WaterTraceability() {
  const { classes } = useStyles();

  const {
    selectedUnit,
    handleUnitChange,
    timezone,
    handleChangePeriod,
    handleCustomRangeSet,
    selectedPeriod,
    currentPeriod,
    sankeyData,
    isLoading,
    isError,
  } = useWaterTraceability();

  const showEmptyChartMessage = isError || sankeyData.values.every((value) => value === 0);

  return (
    <SectionContainer
      title={content.WATER_TRACEABILITY_TITLE}
      isFullScreen={false}
      selector={
        <UnitSelector selectedOption={selectedUnit} handleChange={handleUnitChange} unitOptions={unitOptions} />
      }
    >
      <div className={classes.sections}>
        <Period
          timezone={timezone}
          onChange={handleChangePeriod}
          handleCustomRangeSet={handleCustomRangeSet}
          selectedPeriod={selectedPeriod}
          currentPeriod={currentPeriod}
        />
        <Loadable
          showLoader={isLoading}
          showError={showEmptyChartMessage}
          errorComponent={
            showEmptyChartMessage ? (
              <EmptyChartMessage
                from={currentPeriod.from?.toISOString() || ''}
                to={currentPeriod.to?.toISOString() || ''}
              />
            ) : null
          }
        >
          <Sankey {...sankeyData} />
        </Loadable>
      </div>
    </SectionContainer>
  );
}
