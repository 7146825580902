import { useObservableState } from 'observable-hooks';

import { getUnreadAlertCountSubject, initSubject } from '../infrastructure/alerts';

interface IUnreadAlertsCount {
  unreadAlerts: number;
  isAlertNumberReady: boolean;
}

export const useUnreadAlertsCount = (): IUnreadAlertsCount => {
  const [unreadAlerts] = useObservableState(() => getUnreadAlertCountSubject(), null);

  return {
    unreadAlerts: unreadAlerts ?? 0,
    isAlertNumberReady: unreadAlerts !== null,
  };
};

export const useInitUnreadAlertsCountSubject = () => {
  return useObservableState(initSubject);
};
