import { zodResolver } from '@hookform/resolvers/zod';
import { FormField, INPUT_TYPE, Input } from '@marlin/shared/ui-form-common';
import { getLogger } from '@marlin/shared/utils/logger';
import { Link } from '@mui/material';
import { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { content } from '../../../../content';
import { EQUIPMENT_ENUM, EQUIPMENT_STATUS, TEquipment, equipmentSchema } from '../../../onboarding.schema';
import { useOnboardingState } from '../../../use-onboarding-state.hook';
import { IEquipmentFormProps, defaultEquipmentValues, useStyles } from './common-parts';
import { FormButtons } from './form-buttons.component';

export const PowersIntellistation = ({
  resetSwitcher,
  isEditForm,
  onCancel,
  equipment,
  openInstructionsModal,
  index,
  isAnotherEquipment,
}: IEquipmentFormProps) => {
  const { classes } = useStyles();
  const { addEquipment, updateEquipment } = useOnboardingState();

  const [equipmentName, setEquipmentName] = useState<string>(
    equipment && equipment.equipmentName ? equipment.equipmentName : defaultEquipmentValues.equipmentName || ''
  );
  const [registrationCode, setRegistrationCode] = useState<string>(
    equipment && equipment.registrationCode ? equipment.registrationCode : defaultEquipmentValues.registrationCode || ''
  );

  const form = useForm<TEquipment>({
    defaultValues: equipment
      ? {
          ...defaultEquipmentValues,
          ...equipment,
        }
      : defaultEquipmentValues,
    mode: 'onTouched',
    resolver: zodResolver(equipmentSchema),
  });

  const isDisabled = useMemo(() => {
    const { isValid } = form.formState;

    return !isValid || equipmentName.length < 4 || registrationCode.length < 4;
  }, [equipmentName, form.formState, registrationCode]);

  const onSubmit = (data: TEquipment) => {
    addEquipment({
      data: {
        equipment: [
          {
            ...data,
            equipmentType: EQUIPMENT_ENUM.INTELLISTATION,
          },
        ],
      },
    });
    getLogger()?.track(content.LOGGER.SELF_SERVICE.ADD_EQUIPMENT);
    resetSwitcher();
  };

  const onEdit = (data: TEquipment) => {
    data.status = EQUIPMENT_STATUS.REFETCH;
    updateEquipment({
      data,
      index: index,
    });
    getLogger()?.track(content.LOGGER.SELF_SERVICE.EDIT_EQUIPMENT);
    if (onCancel) onCancel();
    form.reset();
  };

  const onFormCancel = () => {
    form.reset();
    if (onCancel) onCancel();
  };

  return (
    <div className={isEditForm ? classes.editContainer : classes.container}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(isEditForm ? onEdit : onSubmit)} className={classes.formContainer}>
          <div className={classes.inputsContainer}>
            <FormField<TEquipment> fieldName={'equipmentName'}>
              {(field) => (
                <Input
                  {...field}
                  label={content.EQUPIMENT_NAME}
                  required
                  className={classes.input}
                  data-testid="register-equipment-equipmentName"
                  type={INPUT_TYPE.TEXT}
                  shrink={!!field.value?.length}
                  fullWidth
                  onChange={(value) => {
                    setEquipmentName(value.target.value);
                    form.setValue('equipmentName', value.target.value);
                    form.trigger(['equipmentName']);
                  }}
                />
              )}
            </FormField>
            <div>
              <FormField<TEquipment> fieldName={'registrationCode'}>
                {(field) => (
                  <Input
                    {...field}
                    label={content.REGISTRATION_CODE}
                    required
                    className={classes.input}
                    data-testid="register-equipment-registrationCode"
                    type={INPUT_TYPE.NUMBER}
                    shrink={!!field.value?.length}
                    fullWidth
                    onChange={(value) => {
                      setRegistrationCode(value.target.value);
                      form.setValue('registrationCode', value.target.value);
                      form.trigger(['registrationCode']);
                    }}
                  />
                )}
              </FormField>
              <Link
                onClick={() => openInstructionsModal()}
                className={classes.link}
                data-testid="registration-code-link"
              >
                {content.REGISTRATION_CODE_LINK}
              </Link>
            </div>
            <FormButtons
              isDisabled={isDisabled}
              onFormSubmit={form.handleSubmit(isEditForm ? onEdit : onSubmit)}
              isEditForm={isEditForm}
              isAnotherEquipment={isAnotherEquipment}
              onCancel={onFormCancel}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
