import { z } from 'zod';
import { RefinementCtx } from 'zod/lib/types';

import { content } from '../../content';

const Address = z.object({
  modbusAddress: z.number({ required_error: content.FORM.REQUIRED }),
  equipmentBrand: z.string({ required_error: content.FORM.REQUIRED }),
  equipmentModel: z.string({ required_error: content.FORM.REQUIRED }),
  name: z.string({ required_error: content.FORM.REQUIRED }).nonempty({ message: content.FORM.REQUIRED }),
  locationId: z.string({ required_error: content.FORM.REQUIRED }),
});
const AddressUpdate = Address.extend({ id: z.string().uuid().optional() });

const Connection = z.object({
  connectionType: z.string({ required_error: content.FORM.REQUIRED }),
  baudRate: z.number({ required_error: content.FORM.REQUIRED }).nullish(),
  dataBits: z.number({ required_error: content.FORM.REQUIRED }),
  stopBits: z.number({ required_error: content.FORM.REQUIRED }),
  parity: z.string({ required_error: content.FORM.REQUIRED }),
  addresses: AddressUpdate.array().min(0),
});
const ConnectionUpdate = Connection.extend({
  id: z.string().uuid().optional(),
  addresses: AddressUpdate.array().min(0),
});
type TConnectionUpdate = z.infer<typeof ConnectionUpdate>;

const uniqueAddressNameValidator = (connections: TConnectionUpdate[], ctx: RefinementCtx) => {
  const addresses = connections.flatMap(({ addresses }) => addresses);
  const names = addresses.map(({ name }) => name);
  const duplicates = names.filter(
    (name, index, array) => array.findIndex((currentName) => currentName === name) !== index
  );

  duplicates.forEach((name) => {
    connections.forEach(({ addresses }, connectionIdx) => {
      addresses.forEach((address, index) => {
        if (address.name === name) {
          ctx.addIssue({
            path: [connectionIdx, 'addresses', index, 'name'],
            code: z.ZodIssueCode.custom,
            message: content.ERROR_MESSAGE_ADDRESS_UNIQUE_NAME,
          });
        }
      });
    });
  });
};

export const UpsertConnectionFormSchema = z.object({
  id: z.string().uuid().optional(),
  connections: z.array(Connection).min(1).superRefine(uniqueAddressNameValidator),
});
export type TUpsertConnectionFormSchemaType = z.infer<typeof UpsertConnectionFormSchema>;

export const UpsertConnectionFormSchemaUpdate = z.object({
  id: z.string().uuid(),
  connections: z.array(ConnectionUpdate).min(1).superRefine(uniqueAddressNameValidator),
});
export type TUpsertConnectionFormSchemaTypeUpdate = z.infer<typeof UpsertConnectionFormSchemaUpdate>;
