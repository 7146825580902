import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.typography.pxToRem(16),
    width: theme.typography.pxToRem(712),
    padding: theme.typography.pxToRem(24),
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: theme.typography.pxToRem(16),
      borderRadius: 0,
      fontSize: theme.typography.body2.fontSize,
    },
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    marginTop: 0,
    marginBottom: theme.typography.pxToRem(16),
  },
  label: {
    marginBottom: theme.typography.pxToRem(16),
  },
  button: {
    width: theme.typography.pxToRem(167),
    height: theme.typography.pxToRem(42),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));
