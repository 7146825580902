import { TPortfolio } from '../schema/portfolio.schema';
import { portfolioStorageService } from './portfolio-storage.service';
import { portfolioService } from './portfolio.service';

const findPortfolio = (portfolios: TPortfolio[], portfolioId: string | null) =>
  portfolios.find((portfolio) => portfolio.id === portfolioId);

const getFromStorage = (portfolios: TPortfolio[], type: 'sessionStorage' | 'localStorage'): TPortfolio | null => {
  const currentId = portfolioStorageService.get(type);
  const currentPortfolio = findPortfolio(portfolios, currentId);
  if (currentPortfolio) {
    return currentPortfolio;
  }
  return null;
};

export const getStoredPortfolioId = (): string | null => {
  const sessionStoragePortfolioId = portfolioStorageService.get('sessionStorage');

  if (sessionStoragePortfolioId) {
    return sessionStoragePortfolioId;
  }

  const localStoragePortfolioId = portfolioStorageService.get('localStorage');

  if (localStoragePortfolioId) {
    return localStoragePortfolioId;
  }

  return null;
};

export const clearStoredPortfolioId = () => portfolioStorageService.clear();

export const getCurrentPortfolio = (portfolios: TPortfolio[]): TPortfolio => {
  const orgSessionStorage = getFromStorage(portfolios, 'sessionStorage');
  if (orgSessionStorage) {
    return orgSessionStorage;
  }

  const orgLocalStorage = getFromStorage(portfolios, 'localStorage');
  if (orgLocalStorage) {
    return orgLocalStorage;
  }

  return portfolios[0];
};

export const updatePortfolioId = (portfolioId: string) => {
  portfolioService.setPortfolioId(portfolioId);
  portfolioStorageService.set(portfolioId);
  return portfolioService.getPortfolio();
};
