import { getHttpClient } from '@marlin/shared/utils/react-query';

import { ITierFilters, TiersResponseSchema } from '../schemas/tier';
import { paths } from './api';

export const getTiers = async (params: ITierFilters) => {
  const res = await getHttpClient().post(paths.TIER, params);

  return TiersResponseSchema.parse(res);
};
