import { TExtractFnReturnType, useInfiniteQuery, useMutation } from '@marlin/shared/utils/react-query';
import { AxiosError } from 'axios';

import { getLocationAssets } from '../infrastructure/assets';
import { TAssetLocationParamsSchema, TAssetResponseSchema } from '../schemas/assets/assets.schema';
import { queryKey } from './query-key.enum';

export const useGetAssetsLocation = () => {
  return useMutation<unknown, AxiosError, TAssetLocationParamsSchema, TAssetResponseSchema>({
    mutationFn: getLocationAssets,
  });
};

type TUseInfiniteAssetLocationProps = {
  params: Omit<TAssetLocationParamsSchema, 'page'>;
  enabled?: boolean;
};

type TQueryFnType = typeof getLocationAssets;

export const useFilteredAssetsLocationWithLoadMore = ({ params, enabled = true }: TUseInfiniteAssetLocationProps) => {
  return useInfiniteQuery<TExtractFnReturnType<TQueryFnType>>({
    queryKey: queryKey.LOCATION_ASSETS(params),
    queryFn: (queryFnParams) => getLocationAssets({ ...params, page: queryFnParams.pageParam || 1 }),
    getNextPageParam: (lastPage) => {
      return lastPage.pagination.page * lastPage.pagination.pageSize < lastPage.pagination.totalItems
        ? lastPage.pagination.page + 1
        : undefined;
    },
    enabled,
  });
};
