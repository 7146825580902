import {
  TWebhook,
  defaultGetFilteredWebhooksParams,
  useDeleteWebhook,
  useFilteredWebhooksWithPaging,
} from '@marlin/account-data-access-webhook';
import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { useSnackbar } from 'notistack';
import { useCallback, useContext } from 'react';

import { content } from './content';
import { ModalBody } from './delete-modal/modal-body.component';
import { ModalFooter } from './delete-modal/modal-footer.component';
import { ModalTitle } from './delete-modal/modal-title.component';

interface IUseWebhookList {
  webhooks: TWebhook[];
  isLoading: boolean;
  isRefetching: boolean;
  onEditWebhook: (webhookId: string) => void;
  onDeleteWebhook: (webhook: TWebhook) => void;
  goToCreateNewWebhook: () => void;
}

export const useWebhookList = (): IUseWebhookList => {
  const { goTo } = useRouter();
  const { enqueueSnackbar } = useSnackbar();
  const { modalDispatch } = useContext(ModalContext);

  const { data, isLoading, isRefetching } = useFilteredWebhooksWithPaging({
    params: defaultGetFilteredWebhooksParams,
  });

  const deleteWebhook = useDeleteWebhook();

  const closeModal = useCallback(() => {
    modalDispatch({ type: MODAL_ACTION_TYPE.DISMISS });
  }, [modalDispatch]);

  const handleDeleteWebhook = useCallback(
    async (webhookId: string) => {
      try {
        await deleteWebhook.mutateAsync({ webhookId });
        enqueueSnackbar(content.DELETE_WEBHOOK_SUCCESS, { variant: 'success' });
        closeModal();
      } catch (e) {
        enqueueSnackbar(content.DELETE_WEBHOOK_ERROR, { variant: 'error' });
      }
    },
    [closeModal, deleteWebhook, enqueueSnackbar]
  );

  const onEditWebhook = useCallback(
    (webhookId: string) => {
      goTo(routes.settings.editWebhook.url(webhookId));
    },
    [goTo]
  );

  const onDeleteWebhook = useCallback(
    (webhook: TWebhook) => {
      modalDispatch({
        type: MODAL_ACTION_TYPE.SHOW,
        payload: {
          title: <ModalTitle />,
          body: <ModalBody />,
          footer: <ModalFooter webhook={webhook} handleClose={closeModal} handleConfirm={handleDeleteWebhook} />,
        },
      });
    },
    [closeModal, handleDeleteWebhook, modalDispatch]
  );

  const goToCreateNewWebhook = useCallback(() => {
    goTo(routes.settings.createWebhook.url(), { state: { webhookCount: data?.data?.length } });
  }, [data?.data?.length, goTo]);

  return {
    webhooks: data?.data ?? [],
    isLoading,
    isRefetching,
    onEditWebhook,
    onDeleteWebhook,
    goToCreateNewWebhook,
  };
};
