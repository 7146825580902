import { createDateString } from '@marlin/shared/utils-common-date';
import { apiUnitOfMeasuresToDevice, parseDisplayedValue } from '@marlin/shared/utils-format-reading';
import camelCase from 'lodash/camelCase';

import { IAlert, IPagedAlerts } from '../../alert.model';
import { IAlertResponse, IPagedAlertsResponse } from '../alert.model';
import {
  getAbsoluteDifferenceValue,
  getChannels,
  getCriticality,
  getDeviceType,
  getErrorType,
  parseAlertStatusResponse,
} from './utils';

export const createAlertDto = (data: IAlertResponse): IAlert => {
  const thresholdMin = data.thresholdMin;
  const thresholdMax = data.thresholdMax;
  const readingValue = data.readingValue;
  const uoM = apiUnitOfMeasuresToDevice.get(data.uoM) ?? null;

  return {
    id: data.id,
    status: parseAlertStatusResponse(data.status),
    device: {
      id: data.device.id,
      name: data.device.name,
      type: getDeviceType(data.device.type),
      isDeleted: data.device.isDeleted,
    },
    location: data.location
      ? {
          id: data.location.id,
          name: data.location.name,
          isDeleted: data.location.isDeleted,
        }
      : null,
    equipment: {
      id: data.equipment.id,
      name: data.equipment.name,
      isDeleted: data.equipment.isDeleted,
    },
    criticality: getCriticality(data.criticality),
    channels: getChannels(data.channels),
    startTime: createDateString(data.startTime),
    resolveTime: createDateString(data.resolveTime),
    lastReading: createDateString(data.lastReading),
    automation: {
      id: data.automation.id,
      isSystemRule: data.automation.isSystemRule,
      name: data.automation.name,
    },
    readingValue,
    thresholdMin,
    thresholdMax,
    ruleCategory: data.ruleCategory,
    errorType: getErrorType(data.alertCause),
    absoluteDifferenceValue: getAbsoluteDifferenceValue(
      Math.round(thresholdMin),
      Math.round(thresholdMax),
      readingValue
    ),
    snoozeUntil: data.snoozeUntil,
    snoozeIntervalInMinutes: data.snoozeIntervalInMinutes,
    description: data.description,
    title: data.title,
    hyperlink: data.hyperlink,
    deviceEventCode: data.deviceEventCode?.toLowerCase() || null,
    additionalValues: data.additionalValues.reduce<Record<string, string>>(
      (acc, item) => ({
        ...acc,
        [camelCase(item.name)]: item.value,
      }),
      {}
    ),
    uoM,
    formattedReading: data.readingValue
      ? parseDisplayedValue(data.readingValue.toString(), uoM, undefined, 'detail')
      : null,
  };
};

export const createAlertsListDto = (data: IPagedAlertsResponse): IPagedAlerts => {
  return {
    data: data.data?.map((value) => createAlertDto(value)) ?? [],
    pagination: {
      totalItems: data.pagination.totalItems,
      page: data.pagination.page,
      pageSize: data.pagination.pageSize,
    },
    totalItems: {
      current: data.totalItems.Current,
      resolved: data.totalItems.Resolved,
      all: data.totalItems.All,
      snoozed: data.totalItems.Snoozed,
    },
  };
};
