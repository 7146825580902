import { useMemo } from 'react';

import { content } from '../../../content';
import { TStatus } from '../types';
import { BaseStatus } from './base-status.component';

export interface IHealthStatusProps {
  status: TStatus;
  className?: string;
}

export const HealthStatus = ({ status, className }: IHealthStatusProps) => {
  const message = useMemo(() => {
    switch (status) {
      case 'error':
        return content.EQUIPMENT.HEALTH.error;
      case 'warning':
        return content.EQUIPMENT.HEALTH.warning;
      default:
        return content.EQUIPMENT.HEALTH.success;
    }
  }, [status]);

  return <BaseStatus title={content.EQUIPMENT.HEALTH.label} status={status} message={message} className={className} />;
};
