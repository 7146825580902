import { TEquipmentSchema } from '@marlin/asset/data-access/equipment';
import { MarlinTheme } from '@marlin/shared/theme';
import { PaginationMobile } from '@marlin/shared/ui-pagination-mobile';
import { makeStyles } from 'tss-react/mui';

interface IEquipmentListProps {
  rows: TEquipmentSchema[];
  fetchNextPage: () => void;
  totalItems?: number;
  hasNextPage?: boolean;
  children: (equipment: TEquipmentSchema) => JSX.Element;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  locationLink: {
    width: '100%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    color: theme.palette.primary.main,
  },
  mobileMenu: {
    padding: 0,
  },
  emptyList: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 1,
    backgroundColor: theme.palette.background.secondary,
  },
  container: {
    backgroundColor: theme.palette.background.secondary,
  },
}));

export const EquipmentListMobileUi = ({
  rows,
  fetchNextPage,
  hasNextPage,
  children,
  totalItems,
}: IEquipmentListProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      {rows.map((equipment: TEquipmentSchema) => children(equipment))}
      <PaginationMobile
        totalItems={totalItems}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        loadedItems={rows.length}
        data-testid="equipment-pagination"
      />
    </div>
  );
};
