import { UseMutationResult, queryClient, useMutation } from '@marlin/shared/utils/react-query';

import { IDevice } from '../device.model';
import { deleteDevice } from '../infrastructure/device';
import { queryKey } from './query-key.enum';

interface IDeleteDevice {
  previousData: IDevice | undefined;
}

export const useDeleteDevice = (): UseMutationResult<unknown, unknown, string, IDeleteDevice> => {
  return useMutation<unknown, unknown, string, IDeleteDevice>({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.DEVICES() });
      queryClient.invalidateQueries({ queryKey: queryKey.PIN_DEVICES() });
      queryClient.invalidateQueries({ queryKey: queryKey.HOME_LOCATIONS() });
    },
    mutationFn: deleteDevice,
  });
};
