import { useAssetLinks } from '@marlin/asset/data-access/asset';
import { useMemo } from 'react';

import { content } from '../content';
import { ModalBody } from '../delete-modal.body';

export interface IDeleteEquipmentModalBodyProps {
  deviceCount?: number | null;
  assetId: string | undefined;
  deleteAssetName: string | undefined;
}

export const DeleteEquipmentModalBody = ({ deleteAssetName, deviceCount, assetId }: IDeleteEquipmentModalBodyProps) => {
  const assetLinksQuery = useAssetLinks({ assetId: assetId ?? '', enabled: !!assetId });

  const canDelete = useMemo(() => {
    return deviceCount === 0;
  }, [deviceCount]);

  const isLoading = useMemo(() => {
    return assetLinksQuery.isLoading || assetLinksQuery.isInitialLoading;
  }, [assetLinksQuery.isLoading, assetLinksQuery.isInitialLoading]);

  return (
    <div data-testid="delete-modal-body">
      <ModalBody
        deleteAssetName={deleteAssetName}
        deleteAssetId={assetId}
        canDelete={canDelete}
        isLoading={isLoading}
        assetLinks={assetLinksQuery.data}
        flowLinkInfo={content.FLOW_LINKS_INFO}
        flowLinksConfirmation={content.FLOW_LINKS_CONFIRMATION}
        confirmationMessage={content.DELETE_EQUIPMENT_CONFIRMATION}
      />
    </div>
  );
};
