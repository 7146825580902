import isArray from 'lodash/isArray';
import { FC, PropsWithChildren, useMemo } from 'react';

import { TPermission } from './available-permissions';
import PermissionContext from './permission.context';
import { TPortfolioPermission } from './portfolio-available-permissions';

interface IPermissionProviderProps {
  permissions: (TPermission | TPortfolioPermission)[];
}

interface IPermission {
  [key: string]: boolean;
}

export const PermissionProvider: FC<PropsWithChildren<IPermissionProviderProps>> = ({
  permissions: userPermissions,
  children,
}) => {
  const permissions: IPermission = useMemo(
    () => userPermissions.reduce((acc, val: TPermission | TPortfolioPermission) => ({ ...acc, [val]: true }), {}),
    [userPermissions]
  );

  const isAllowedTo = (requiredPermissions: TPermission | TPermission[]) => {
    if (isArray(requiredPermissions)) {
      if (requiredPermissions.length <= 0) {
        return true;
      }

      if (userPermissions.length <= 0) {
        return false;
      }

      return requiredPermissions.every((permission) => permissions[permission]);
    }

    return !!permissions[requiredPermissions];
  };

  return <PermissionContext.Provider value={{ isAllowedTo }}>{children}</PermissionContext.Provider>;
};
