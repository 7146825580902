import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import {
  TGetFilteredWebhooksParams,
  TGetFilteredWebhooksResponse,
  getFilteredWebhooksParamsSchema,
  getFilteredWebhooksRsponseSchema,
} from '../schemas/get-filtered-webhooks.schema';
import { paths } from './api';

export const getFilteredWebhooks = async (data: TGetFilteredWebhooksParams): Promise<TGetFilteredWebhooksResponse> => {
  const body = safeParseData(data, getFilteredWebhooksParamsSchema);
  const res = await getHttpClient().post(paths.WEBHOOK_FILTER, body);
  return safeParseData(res, getFilteredWebhooksRsponseSchema);
};
