import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';
import { z } from 'zod';

import { TOrgDetails, orgDetailsSchema } from '../schemas/org';
import { paths } from './api';

const CreateOrganizationSchema = z.object({
  name: z.string(),
  tierId: z.string(),
  address: z.object({
    country: z.string(),
    address1: z.string(),
    address2: z.string(),
    city: z.string(),
    state: z.string(),
    postalCode: z.string(),
    latitude: z.number().nullish(),
    longitude: z.number().nullish(),
  }),
  timeZone: z.string(),
});

export type TCreateOrganization = z.infer<typeof CreateOrganizationSchema>;

const createOrganizationHeaders = {
  'x-api-version': '2.0',
};

export const newCreateOrganization = async (data: TCreateOrganization): Promise<TOrgDetails> => {
  const body = safeParseData(data, CreateOrganizationSchema);

  const res = await getHttpClient().post(paths.CREATE_ORGANIZATION, body, {
    headers: createOrganizationHeaders,
  });

  return safeParseData(res, orgDetailsSchema);
};
