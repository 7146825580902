import { useState } from 'react';

import { defaultCompareToOption } from './constants';
import { TCompareToValue } from './types';

export interface ICompareToState {
  value: TCompareToValue;
  onChange: (compareTo: TCompareToValue) => void;
  reset: () => void;
}

export const useCompareToState = () => {
  const [value, setValue] = useState<TCompareToValue>(defaultCompareToOption);

  const onChange = (compareTo: TCompareToValue) => {
    setValue(compareTo);
  };

  const reset = () => {
    setValue(defaultCompareToOption);
  };

  return {
    value,
    reset,
    onChange,
  };
};
