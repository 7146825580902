import { BehaviorSubject, defer, lastValueFrom } from 'rxjs';
import { concatMap, debounceTime, shareReplay, take, tap } from 'rxjs/operators';

import { getTiers } from '../infrastructure/get-tiers';

const data$ = defer(() =>
  getTiers({
    search: '',
    pageSize: 10,
    page: 0,
  })
);
const createShared = () => data$.pipe(shareReplay(1), debounceTime(1000));
const sharedSupplier = new BehaviorSubject(createShared());

const cachedTiers = sharedSupplier.pipe(
  concatMap((shared) => shared.pipe(tap({ complete: () => sharedSupplier.next(createShared()) }))),
  take(1)
);

export const getTierName = async (tierId: string | undefined) => {
  if (!tierId) {
    return undefined;
  }

  const tiers = await lastValueFrom(cachedTiers);

  return tiers.data.find((t) => t.id === tierId)?.tierName;
};
