import { MarlinTheme } from '@marlin/shared/theme';
import { AccordionActions, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';
import { IAlert } from '../types';

interface ILoadMoreProps {
  alerts: IAlert[];
  totalAlertsCount: number;
  onLoadMore: () => void;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  button: {
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: theme.typography.pxToRem(24),
    paddingRight: theme.typography.pxToRem(24),
  },
}));

export const LoadMore = ({ alerts, totalAlertsCount, onLoadMore }: ILoadMoreProps): JSX.Element | null => {
  const { classes } = useStyles();

  if (totalAlertsCount <= alerts.length) {
    return null;
  }

  return (
    <AccordionActions className={classes.button}>
      <Button fullWidth variant="outlined" onClick={onLoadMore}>
        {content.BUTTON_LOAD_MORE}
      </Button>
    </AccordionActions>
  );
};
