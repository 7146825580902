import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(16),
    padding: `${theme.typography.pxToRem(24)} ${theme.typography.pxToRem(16)}`,
    borderRadius: theme.typography.pxToRem(4),
    backgroundColor: theme.palette.background.primary,
    minWidth: theme.typography.pxToRem(348),
    minHeight: theme.typography.pxToRem(274),
  },
  input: {
    marginBottom: theme.typography.pxToRem(16),
    flexBasis: '100%',
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.typography.pxToRem(8),
    },
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.typography.pxToRem(16),
  },
  customPeriodText: {
    color: theme.palette.text.primary,
    fontFeatureSettings: "'liga' off, 'clig' off",
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(14),
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: theme.typography.pxToRem(22.4),
    letterSpacing: theme.typography.pxToRem(0.15),
  },
  orgText: {
    color: theme.palette.text.primary,
    textAlign: 'left',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(12),
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: theme.typography.pxToRem(20),
    letterSpacing: theme.typography.pxToRem(0.4),
    fontFeatureSettings: "'liga' off, 'clig' off",
  },
}));
