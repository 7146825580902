import { z } from 'zod';

import { paginationSchema, sortingSchema } from './utils.schema';

export enum HEALTH_STATUS {
  OK = 0,
  Low = 1,
  High = 2,
}

export const healthStatusSchema = z.union([z.literal('OK'), z.literal('Low'), z.literal('High')]);
export type THealthStatus = z.infer<typeof healthStatusSchema>;

export const HealthStatusSchema = z.nativeEnum(HEALTH_STATUS).transform((val): THealthStatus => {
  switch (val) {
    case HEALTH_STATUS.OK: {
      return 'OK';
    }
    case HEALTH_STATUS.Low: {
      return 'Low';
    }
    case HEALTH_STATUS.High:
    default: {
      return 'High';
    }
  }
});

const healthStatusRequestAdapter = (val: THealthStatus): HEALTH_STATUS => {
  switch (val) {
    case 'OK': {
      return HEALTH_STATUS.OK;
    }
    case 'Low': {
      return HEALTH_STATUS.Low;
    }
    case 'High': {
      return HEALTH_STATUS.High;
    }
  }
};

export const systemViewFiltersSchema = z.object({
  page: z.number(),
  pageSize: z.number(),
  sorting: sortingSchema.optional(),
  deviceHealthStatus: z.array(healthStatusSchema),
  systemHealthStatus: z.array(healthStatusSchema),
  organizations: z.array(z.string()),
});

export const systemViewRequestSchema = systemViewFiltersSchema.transform(
  ({ page, pageSize, systemHealthStatus, deviceHealthStatus, sorting, organizations }) => ({
    systemHealthStatus: systemHealthStatus.map(healthStatusRequestAdapter),
    deviceHealthStatus: deviceHealthStatus.map(healthStatusRequestAdapter),
    organizations,
    page,
    pageSize,
    sorting,
  })
);

const DeviceHealthSchema = z.object({
  healthStatusCriticality: HealthStatusSchema,
  inactiveDevices: z.number(),
  inactiveGateways: z.number(),
  lowestBatteryLevel: z.number(),
});

const SystemHealthSchema = z.object({
  healthStatusCriticality: HealthStatusSchema,
  highAlertsCount: z.number(),
  lowAlertsCount: z.number(),
});

const OrganizationStatusSchema = z.object({
  organizationId: z.string(),
  organizationName: z.string(),
  deviceHealth: DeviceHealthSchema,
  systemHealth: SystemHealthSchema,
  automationsCount: z.number(),
  devicesCount: z.number(),
  hasDefaultFlowMeter: z.boolean(),
});

export const systemViewResponseSchema = z.object({
  pagination: paginationSchema,
  data: z.array(OrganizationStatusSchema),
});

export type TSystemViewResponse = z.infer<typeof systemViewResponseSchema>;
export type TSystemViewRequest = z.infer<typeof systemViewRequestSchema>;
export type TSystemViewFilters = z.infer<typeof systemViewFiltersSchema>;
export type TOrganizationStatusRow = z.infer<typeof OrganizationStatusSchema>;
