import { RedirectRequest, SilentRequest } from '@azure/msal-browser';

import { IConfig } from '../models/auth.model';
import { AccountService } from './account.service';
import { ConfigService } from './config.service';

export class RequestService {
  private readonly config: IConfig;

  public constructor(private readonly configService: ConfigService, private readonly accountService: AccountService) {
    this.config = this.configService.get();
  }

  public getSilentRequest(): SilentRequest {
    return {
      scopes: this.getScopes(),
      account: this.accountService.getCurrentAccount() || undefined,
    };
  }

  public getRedirectRequest = (): RedirectRequest => this.config.requestRedirect;

  private getScopes = (): string[] => this.config.requestRedirect.scopes || [];
}
