import { locationTypeSchema, validatePostalCodeMessage } from '@marlin/shared/ui-form';
import { z } from 'zod';

import { content } from './content';

const maxCharacters = 100;
const maxDescriptionCharacters = 500;

const optionalTextFieldSchema = z.string().max(maxCharacters, content.MAX_LENGTH(maxCharacters)).nullish();

const optionalDescriptionFieldSchema = z
  .string()
  .max(maxDescriptionCharacters, content.MAX_LENGTH(maxDescriptionCharacters))
  .nullish();

export const AddressFormFieldsSchema = {
  country: z.string().nullish(),
  address1: optionalTextFieldSchema,
  address2: optionalTextFieldSchema,
  city: optionalTextFieldSchema,
  state: z.string().nullish(),
  postalCode: z.string().nullish(),
};

export const LocationFormDataSchema = z
  .object({
    name: z.string().min(1, content.REQUIRED).min(3, content.MIN_MAX).max(200, content.MIN_MAX),
    parentLocation: z
      .object({
        id: z.string().nullish(),
        name: z.string().nullish(),
      })
      .nullish(),
    description: optionalDescriptionFieldSchema,
    locationType: locationTypeSchema.nullish(),
    ...AddressFormFieldsSchema,
  })
  .superRefine((data, ctx) => {
    const { postalCode, country } = data;
    validatePostalCodeMessage(postalCode, country, ctx);
  });
