import { TExtractFnReturnType, useQuery } from '@marlin/shared/utils/react-query';
import z from 'zod';

import { getEquipmentSettingsGroup } from '../infrastructure/equipment';
import { queryKey } from './query-key.enum';

type TQueryFnType = typeof getEquipmentSettingsGroup;

interface IUseEquipmentSettingGroupProps {
  equipmentId: string;
  settingGroupId: string | null;
  onError?: () => void;
}

export const useEquipmentSettingsGroup = ({ equipmentId, settingGroupId, onError }: IUseEquipmentSettingGroupProps) => {
  return useQuery<TExtractFnReturnType<TQueryFnType>>({
    queryKey: queryKey.SETTINGS(settingGroupId, equipmentId),
    queryFn: () => getEquipmentSettingsGroup({ equipmentId, settingGroupId }),
    onError: (error) => {
      // eslint-disable-next-line no-console
      if (error instanceof z.ZodError) console.log(error.issues);

      onError?.();
    },
    enabled: !!equipmentId && !!settingGroupId,
  });
};
