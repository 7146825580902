import { MarlinTheme } from '@marlin/shared/theme';
import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import { useSearchParams } from '@marlin/shared/utils-router';
import { TBooleanSetting } from '@marlin/shared/utils/datapoint-mappers';
import { Switch, Typography } from '@mui/material';
import { useContext } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../content';
import { DatapointError } from '../shared/datapoint-error.component';
import { ToggleModalBody, ToggleModalFooter } from './toggle-modal.component';

interface IToggleSettingProps {
  settingItem: TBooleanSetting;
  onChange: (name: string, value: string, prevValue: string) => Promise<void>;
  disabled?: boolean;
  isDirty: boolean;
}

interface IStyles {
  disabled: boolean;
}

export const useStyles = makeStyles<IStyles>()((theme: MarlinTheme, { disabled }) => ({
  label: {
    fontSize: theme.typography.pxToRem(14),
    letterSpacing: theme.typography.pxToRem(0.17),
    textTransform: 'capitalize',
    display: 'inline-flex',
    color: disabled ? theme.palette.text.disabled : theme.palette.text.secondary,
  },
  wrapper: {
    display: 'flex',
    placeItems: 'center',
  },
  footer: {
    display: 'flex',
    gap: theme.typography.pxToRem(8),
  },
}));

export const ToggleSetting = ({
  settingItem: { label: settingName, name, value, controlState },
  onChange,
  disabled = false,
  isDirty,
}: IToggleSettingProps) => {
  const { classes } = useStyles({ disabled });
  const { modalDispatch } = useContext(ModalContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const isEditing = searchParams.has('settingId');

  const closeModal = () => modalDispatch({ type: MODAL_ACTION_TYPE.DISMISS });

  const submitChange = async (checked: boolean) => {
    await onChange(name, Number(checked).toString(), Number(value).toString());

    closeModal();
  };

  const handleChange = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (isEditing) {
      setSearchParams((currentParams) => {
        currentParams.delete('settingId');

        return currentParams;
      });
    }

    if (!isDirty) {
      modalDispatch({
        type: MODAL_ACTION_TYPE.SHOW,
        payload: {
          title: <>{settingName}</>,
          body: <ToggleModalBody value={value} />,
          footer: (
            <ToggleModalFooter value={value} checked={checked} closeModal={closeModal} submitChange={submitChange} />
          ),
        },
      });
    }
  };

  if (controlState === 'error') {
    return <DatapointError />;
  }

  return (
    <div className={classes.wrapper}>
      <Typography className={classes.label}>{value ? content.ON : content.OFF}</Typography>
      <Switch
        size="small"
        checked={value}
        onChange={handleChange}
        inputProps={{ 'data-testid': `${name}-setting-toggle` } as React.InputHTMLAttributes<HTMLInputElement>}
        disabled={disabled}
      />
    </div>
  );
};
