import { TSelectedDatapoint } from '../types';

export const getSelectedDatapoints = (
  selected: TSelectedDatapoint[],
  previousSelected: TSelectedDatapoint[]
): TSelectedDatapoint[] => {
  if (!selected.length) return [];

  if (selected.length === 1) {
    const isSensorAlreadySelected = Boolean(previousSelected.find((item) => item.id === selected[0].id));

    if (isSensorAlreadySelected) {
      return previousSelected.filter(({ id }) => id !== selected[0].id);
    }
    return [...previousSelected, { ...selected[0], isActive: true }];
  }

  const areAllSelected = selected.every((sensor) => Boolean(previousSelected.find((item) => item.id === sensor.id)));

  if (areAllSelected) {
    return previousSelected.filter(({ id }) => !selected.find((sensor) => sensor.id === id));
  }

  const notSelectedItems = selected.filter((sensor) => !previousSelected.find((item) => item.id === sensor.id));
  return [...previousSelected, ...notSelectedItems.map((sensor) => ({ ...sensor, isActive: true }))];
};
