import { useContext } from 'react';

import { TeaserModalContext } from './teaser-modal-context';

export const useTeaserModal = () => {
  const { modalState, modalDispatch } = useContext(TeaserModalContext);

  return {
    modalState,
    modalDispatch,
  };
};
