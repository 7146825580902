import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.typography.pxToRem(8),
    alignSelf: 'stretch',
  },
  headerText: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(24),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
    lineHeight: '133%',
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(20),
    },
  },
  icon: {
    color: theme.palette.secondary.light,
  },
}));
