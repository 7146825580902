import { TDateString } from '@marlin/shared/utils-common-date';
import has from 'lodash/has';

import { TAutomationHistory } from './automation-history.schema';

export interface ITriggerEntry {
  from: TDateString;
  to: TDateString;
  value: number;
}

export interface IRuleHistory {
  thresholdMin: ITriggerEntry[];
  thresholdMax: ITriggerEntry[];
  deadbandMin: ITriggerEntry[];
  deadbandMax: ITriggerEntry[];
}

export type TRulesHistory = Record<string, IRuleHistory>;
type TTriggerKeys = keyof Omit<TAutomationHistory, 'ruleId' | 'from' | 'to' | 'isEnabled'>;
const hasTrigger = ({ thresholdMin, thresholdMax, deadbandMin, deadbandMax }: TAutomationHistory) =>
  [thresholdMin, thresholdMax, deadbandMin, deadbandMax].some((value) => !!value);

const getCurrentRuleEntry = (rulesHistory: TRulesHistory, ruleId: string): IRuleHistory =>
  has(rulesHistory, ruleId)
    ? { ...rulesHistory[ruleId] }
    : {
        thresholdMin: [],
        thresholdMax: [],
        deadbandMin: [],
        deadbandMax: [],
      };

const applyTriggerEntry = (triggerEntries: ITriggerEntry[], newTriggerEntry: ITriggerEntry): ITriggerEntry[] => {
  const lastTriggerEntry = triggerEntries[triggerEntries.length - 1];

  if (lastTriggerEntry && newTriggerEntry.value === lastTriggerEntry.value) {
    return [...triggerEntries.slice(0, -1), { ...lastTriggerEntry, to: newTriggerEntry.to }];
  }

  return [...triggerEntries, newTriggerEntry];
};

const applyRule = (currentRuleEntry: IRuleHistory, automationHistoryEntry: TAutomationHistory, key: TTriggerKeys) => {
  const value = automationHistoryEntry[key];

  if (!value) {
    return currentRuleEntry[key];
  }
  const newTriggerEntry: ITriggerEntry = {
    from: automationHistoryEntry.from,
    to: automationHistoryEntry.to,
    value,
  };

  return applyTriggerEntry(currentRuleEntry[key], newTriggerEntry);
};

export const reduceAutomationHistoryToRulesHistory = (automationHistory: TAutomationHistory[]): TRulesHistory => {
  return automationHistory.reduce((rulesHistory: TRulesHistory, automationHistoryEntry) => {
    if (!hasTrigger(automationHistoryEntry)) {
      return rulesHistory;
    }

    const currentRuleEntry = getCurrentRuleEntry(rulesHistory, automationHistoryEntry.ruleId);

    return {
      ...rulesHistory,
      [automationHistoryEntry.ruleId]: {
        deadbandMax: applyRule(currentRuleEntry, automationHistoryEntry, 'deadbandMax'),
        deadbandMin: applyRule(currentRuleEntry, automationHistoryEntry, 'deadbandMin'),
        thresholdMax: applyRule(currentRuleEntry, automationHistoryEntry, 'thresholdMax'),
        thresholdMin: applyRule(currentRuleEntry, automationHistoryEntry, 'thresholdMin'),
      },
    };
  }, {});
};
