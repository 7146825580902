export const content = {
  CONFIRMATION_MESSAGE_DELETE_SENSOR_ERROR: `Something went wrong. Your sensor hasn't been deleted`,
  CONFIRMATION_MESSAGE_DELETE_SENSOR_SUCCESS: 'Your sensor has been deleted',
  MODAL_TITLE: 'Delete Sensor?',
  MODAL_DELETE_NO_AUTOMATION_QUESTION: (name: string) => `Are you sure you want to delete ${name}?`,
  MODAL_DELETE_NO_AUTOMATION_CONFIRMATION: 'This action cannot be undone.',
  MODAL_DELETE_EXISTING_AUTOMATION_WARNING: (total: number) =>
    `There are ${total} automations linked to this sensor. If you delete this sensor, then its automations will also be deleted.`,
  MODAL_DELETE_EXISTING_AUTOMATION_QUESTION: 'Are you sure you want to delete this sensor and all of its automations?',
  MODAL_BUTTON_CANCEL_TEXT: 'Cancel',
  MODAL_BUTTON_SUBMIT_TEXT: 'Delete',
  MODAL_DELETE_AUTOMATIONS: 'The following automations will also be deleted.',
  MODAL_DELETE_FLOW_LINKS: 'The following flow links will also be deleted.',
  MODAL_DELETE_FLOW_LINKS_QUESTION: 'Are you sure you want to delete this sensor and all of its flow links?',
  MODAL_DELETE_FLOW_LINKS_AUTOMATIONS: 'The following flow links and automations will also be deleted.',
  MODAL_DELETE_FLOW_LINKS_AUTOMATIONS_QUESTION:
    'Are you sure you want to delete this sensor and all of its flow links and automations?',
  FLOW_LINK_TABLE: 'Flow Links',
  AUTOMATION_TABLE: 'Linked Automations',
};
