import { useGetOrganizationDetails } from '@marlin/account-data-access-organization';
import { MarlinTheme } from '@marlin/shared/theme';
import { SectionContainer } from '@marlin/shared/ui-page';
import { PERIOD_ENUM, useGetSustainabilityFormattedTimezone } from '@marlin/shared/ui/chart';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';
import { UnitSelector } from '../shared/unit-selector/unit-selector.component';
import { useUnitSelector } from '../shared/unit-selector/use-unit-selector';
import { Legend } from './legend.component';
import { WaterUseSection } from './water-use-section.component';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  sections: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    overflow: 'hidden',
  },
  unitSelectorContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.typography.pxToRem(8),
    padding: theme.typography.pxToRem(16),
    borderBottom: `1px solid ${theme.palette.divider}`,
    alignItems: 'center',
  },
}));

const unitOptions = [
  {
    value: 'percent',
    label: content.PERCENT,
  },
  {
    value: 'dollars',
    label: content.DOLLARS,
  },
];

export function TotalWaterUsePerPeriod() {
  const { classes } = useStyles();

  const { data: orgDetails } = useGetOrganizationDetails();
  const { selectedOption: selectedUnit, handleChange } = useUnitSelector({ unitOptions });
  const { timezone } = useGetSustainabilityFormattedTimezone(orgDetails?.timeZone);

  return (
    <SectionContainer title={content.TOTAL_WATER_USE_TITLE} options={<Legend />}>
      <div className={classes.unitSelectorContainer}>
        <UnitSelector
          selectedOption={selectedUnit}
          handleChange={handleChange}
          unitOptions={unitOptions}
          title={content.AVERAGE_USE_BY}
        />
      </div>
      <div className={classes.sections}>
        <WaterUseSection timezone={timezone} period={PERIOD_ENUM.WEEK} selectedUnit={selectedUnit} />
        <WaterUseSection timezone={timezone} period={PERIOD_ENUM.MONTH} selectedUnit={selectedUnit} />
        <WaterUseSection timezone={timezone} period={PERIOD_ENUM.QUARTER} selectedUnit={selectedUnit} />
      </div>
    </SectionContainer>
  );
}
