import { useEffect } from 'react';

export const useResizeObserverError = () => {
  // Remove the resizeObserver error, which is a known issue in react flow
  // see https://github.com/xyflow/xyflow/issues/3076
  useEffect(() => {
    const errorHandler = (e: { message: string }) => {
      if (
        e.message.includes(
          'ResizeObserver loop completed with undelivered notifications' || 'ResizeObserver loop limit exceeded'
        )
      ) {
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
        if (resizeObserverErr) {
          resizeObserverErr.style.display = 'none';
        }
      }
    };
    window.addEventListener('error', errorHandler);

    return () => {
      window.removeEventListener('error', errorHandler);
    };
  }, []);
};
