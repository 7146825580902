import { ColorMode } from '@marlin/shared/theme';
import { Palette } from '@mui/material/styles/createPalette';

import { defaultConfig } from './default-config';

export const colorPalette = (mode: ColorMode): Palette => ({
  ...defaultConfig.palette,
  mode,
  primary: {
    main: '#0029B9',
    dark: '#001C80',
    light: '#0039FF',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#157171',
    dark: '#004E53',
    light: '#67CDD4',
    contrastText: '#FFFFFF',
  },
  alternative: {
    main: '#5B5B5B',
    dark: '#323232',
    light: '#ECECEC',
    contrastText: '#FFFFFF',
  },
  text: {
    primary: '#1E1E1E',
    secondary: '#5D5D5E',
    disabled: '#8F9091',
    alternative: '#FFFFFF',
  },
  systemMap: {
    main: '#202020',
    alternative: '#039BE5',
    group: '#F5F7FC',
    location: '#E8EAF6',
    device: '#E0E0E0',
    equipment: '#CFD8DC',
    divider: '#B6BEC2',
  },
  error: {
    main: '#D32F2F',
    dark: '#C62828',
    light: '#EF5350',
    contrastText: '#FFFFFF',
  },
  warning: {
    main: '#ED6C02',
    dark: '#E65100',
    light: '#FF9800',
    contrastText: '#000000',
  },
  success: {
    main: '#2E7D32',
    dark: '#1B5E20',
    light: '#4CAF50',
    contrastText: '#FFFFFF',
  },
  info: {
    main: '#0288D1',
    dark: '#01579B',
    light: '#03A9F4',
    contrastText: '#FFFFFF',
  },
  background: {
    ...defaultConfig.palette.background,
    primary: '#FFFFFF',
    secondary: '#FAFBFD',
    alternative: '#F6F6F6',
  },
  action: {
    ...defaultConfig.palette.action,
  },
  charting: {
    breezyBlue: '#0A6BBC',
    freshGreen: '#8DB86B',
    flushedPink: '#C2185B',
    frostyBlue: '#94B4FF',
    toastyOrange: '#EE842D',
    aquaFusion: '#008886',
    springPink: '#FF92E1',
    sunnyGold: '#CFBC21',
    plasmaLavender: '#7140CB',
    hazyLilac: '#DAADE8',
    oliveDuct: '#827717',
    rustyCopper: '#A43E03',
    charting13: '#E64A19',
    charting14: '#F4A900',
    charting15: '#1C91C0',
    charting16: '#D500F9',
  },
  thresholds: {
    clementine: '#ED6C02',
    persianRed: '#D32F2F',
  },
});
