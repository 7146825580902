import { RoleSchema } from '@marlin/shared/utils-role';
import { z } from 'zod';

const RoleListItemSchema = z.object({
  id: z.string(),
  name: z.string(),
  role: RoleSchema,
  alreadyExists: z.boolean().nullish(),
});

export const RoleListSchema = z.array(RoleListItemSchema);

export type TRoleList = z.infer<typeof RoleListSchema>;
export type TRoleListItem = z.infer<typeof RoleListItemSchema>;
