import { useUnreadAlertsCount } from '@marlin/alert/data-access/alert-action';
import { Badge } from '@mui/material';
import { PropsWithChildren } from 'react';

export function UnreadAlertsBadge({ children }: PropsWithChildren) {
  const { unreadAlerts } = useUnreadAlertsCount();

  if (unreadAlerts <= 0) {
    return <>{children}</>;
  }

  return (
    <Badge badgeContent={unreadAlerts} color="error" max={99}>
      {children}
    </Badge>
  );
}
