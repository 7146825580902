export const content = {
  MODAL_TITLE: 'Snooze Alert?',
  MODAL_MESSAGE: (automationName: string) => (
    <span>
      Snooze alerts for <b>{automationName}</b> automation.
    </span>
  ),
  MODAL_MESSAGE_SYSTEM_AUTOMATION: (automationName: string, deviceName: string) => (
    <span>
      Snooze alerts for <b>{deviceName}</b> for <b> {automationName}</b>.
    </span>
  ),
  BTN_CANCEL: 'cancel',
  BTN_STOP: 'stop',
  BTN_SNOOZE: 'snooze',
  HOUR_1: '1 hour',
  HOURS_8: '8 hours',
  HOURS_12: '12 hours',
  HOURS_24: '24 hours',
  HOURS_48: '48 hours',
  CANCEL_SNOOZE_TITLE: 'Remove Snooze?',
  CANCEL_SNOOZE_MESSAGE: (automationName: string) => (
    <span>
      You are removing the snooze that was set for <b>{automationName} </b> automation.
    </span>
  ),
  CANCEL_SNOOZE_MESSAGE_SYSTEM: (automationName: string, deviceName: string) => (
    <span>
      You are removing the snooze that was set for <b>{deviceName}</b> for <b>{automationName}</b> automation.
    </span>
  ),
  CANCEL_SNOOZE_SNOOZE_TIME: (snoozeTime: string) => {
    return `Snooze is set until ${snoozeTime}.`;
  },
  SNOOZE_DATE_UNTIL: (snoozeTime: string) => {
    return `Snooze until ${snoozeTime}`;
  },
  SNOOZE_SUCCESS: (automationName: string) => `The alerts for ${automationName} have been snoozed.`,
  SNOOZE_ERROR: 'Error during snooze automation.', //TODO - NOT OFFICAL COPY
  SNOOZE_TIME: 'Snooze time',
  SNOOZE_STOP: 'Stop Snooze',
  SNOOZE_ALERTS: 'Snooze Alerts',
  SNOOZE_TOOLTIP:
    'Snoozing allows you to temporarily deactivate alerts from triggering for a specific automation. (Ex: This can be used to reduce repetitive alerts on known issues.',
};
