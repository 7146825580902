import z from 'zod';

import { PortfolioRoleSchema } from './roles.schema';

export const createPortfolioRequestSchema = z.object({
  name: z.string(),
  organizations: z.array(z.string()),
});

export const portfolioResponseSchema = z.object({
  id: z.string(),
  name: z.string(),
  owner: z.string(),
  organizations: z.array(z.string()),
  users: z.array(z.string()),
});

export const portfolioSchema = z.object({
  id: z.string(),
  name: z.string(),
  role: PortfolioRoleSchema,
});

export const portfolioListResponseSchema = z.object({
  portfolios: z.array(portfolioSchema),
  currentPortfolio: portfolioSchema.optional(),
  previousPortfolioId: z.string().nullish(),
});

export type TCreatePortfolioRequest = z.infer<typeof createPortfolioRequestSchema>;
export type TPortfolioResponse = z.infer<typeof portfolioResponseSchema>;
export type TPortfolioListResponse = z.infer<typeof portfolioListResponseSchema>;
export type TPortfolio = z.infer<typeof portfolioSchema>;
