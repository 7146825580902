import { MarlinTheme } from '@marlin/shared/theme';
import { alpha } from '@mui/system';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.typography.pxToRem(16),
    borderTopLeftRadius: theme.typography.pxToRem(4),
    borderTopRightRadius: theme.typography.pxToRem(4),
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '90%',
  },
  mobileWrapper: {
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
    borderRadius: theme.typography.pxToRem(4),
  },
  desktopWrapper: {
    minHeight: theme.typography.pxToRem(112),
  },
  header: {
    backgroundColor: theme.palette.background.alternative,
    borderBottom: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
    borderLeft: `${theme.typography.pxToRem(8)} solid ${theme.palette.divider}`,
  },
  headerWithHighAlert: {
    backgroundColor: alpha(theme.palette.error.main, 0.12),
    borderBottom: `${theme.typography.pxToRem(1)} solid ${theme.palette.error.main}`,
    borderLeft: `${theme.typography.pxToRem(8)} solid ${theme.palette.error.main}`,
  },
  headerWithLowAlert: {
    backgroundColor: alpha(theme.palette.warning.main, 0.12),
    borderBottom: `${theme.typography.pxToRem(1)} solid ${theme.palette.warning.main}`,
    borderLeft: `${theme.typography.pxToRem(8)} solid ${theme.palette.warning.main}`,
  },
  parentLocationName: {
    marginBottom: theme.typography.pxToRem(8),
    cursor: 'pointer',
  },
  locationNameWrapper: {
    display: 'flex',
    maxWidth: '100%',
  },
  locationName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  },
  impactAmountAlert: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));
