import { MarlinTheme } from '@marlin/shared/theme';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { Chip, alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { IFlowMeter } from './types';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  chip: {
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    '&> .MuiChip-label': {
      maxWidth: theme.typography.pxToRem(130),
    },
    '&> .MuiChip-avatar': {
      color: theme.palette.background.primary,
      backgroundColor: theme.palette.primary.main,
    },
    '& .MuiChip-deleteIcon': {
      color: alpha(theme.palette.primary.main, 0.5),
      ':hover': {
        color: theme.palette.primary.main,
      },
    },
  },
}));

interface IFlowMeterTagProps {
  flowMeter: IFlowMeter;
  className: string;
  disabled: boolean;
  'data-tag-index': number;
  tabIndex: number;
  onDelete: (event: unknown) => void;
}

export const FlowMeterTag = ({ flowMeter, className, ...props }: IFlowMeterTagProps) => {
  const { classes } = useStyles();

  return (
    <Tooltip placement="top" text={flowMeter.name}>
      <Chip {...props} label={flowMeter.name} className={`${className} ${classes.chip}`} />
    </Tooltip>
  );
};
