import { Button } from '@mui/material';

import { content } from '../content';
import { useStyles } from './save-modal.styles';

interface ISummaryModalFooterProps {
  onCancel: () => void;
  onConfirm: () => void;
}

export const SaveModalFooter = ({ onCancel, onConfirm }: ISummaryModalFooterProps) => {
  const { classes } = useStyles();
  return (
    <div className={classes.buttonWrapper}>
      <Button variant="text" onClick={onCancel}>
        {content.SAVE_MODAL_CANCEL}
      </Button>
      <Button variant="contained" color="primary" onClick={onConfirm}>
        {content.SAVE_MODAL_CONFIRM}
      </Button>
    </div>
  );
};
