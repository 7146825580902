import { AERCO_UNIT_STATUS } from '@marlin/shared/utils/zod';
import { TLastReadingValue } from '@marlin/system-map/data-access/system-map';
import { useMemo } from 'react';

import { content } from '../../../content';
import { TDirection, TStatus } from '../types';
import { Reading } from './reading.component';

interface IBurnerProps {
  statusReadingValue?: TLastReadingValue;
  status: TStatus;
  direction: TDirection;
}

export const Burner = ({ statusReadingValue, status, direction }: IBurnerProps) => {
  const isBurnerOn = useMemo(() => {
    const onUnitStatuses: AERCO_UNIT_STATUS[] = [
      AERCO_UNIT_STATUS.Manual,
      AERCO_UNIT_STATUS.Auto,
      AERCO_UNIT_STATUS.Remote,
    ];

    return onUnitStatuses.includes(statusReadingValue?.value as AERCO_UNIT_STATUS);
  }, [statusReadingValue?.value]);

  const value = useMemo(() => {
    if (!statusReadingValue?.value) {
      return content.EQUIPMENT.NOT_APPLICABLE;
    }

    if (isBurnerOn) {
      return content.EQUIPMENT.BURNER.ON;
    }

    return content.EQUIPMENT.BURNER.OFF;
  }, [isBurnerOn, statusReadingValue?.value]);

  return (
    <Reading
      status={!statusReadingValue ? 'notAvailable' : status}
      direction={direction}
      label={content.EQUIPMENT.BURNER.LABEL}
    >
      {value}
    </Reading>
  );
};
