import { MarlinTheme } from '@marlin/shared/theme';
import { Link } from '@marlin/shared/ui-page';
import { PERMISSIONS, Restricted } from '@marlin/shared/utils-permission';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { getSnackbarAlertMessage } from '@marlin/shared/utils/format-alert';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';
import { IAlertNotification } from './types';

interface ISnackbarContent {
  alert: IAlertNotification;
  openSnoozeAlertModal: () => void;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  snackbar: {
    display: 'flex',
    flexDirection: 'column',
    textDecorationLine: 'none',
    color: theme.palette.primary.contrastText,
    cursor: 'pointer',
    fontSize: theme.typography.pxToRem(14),
    marginRight: theme.typography.pxToRem(20),
  },

  content: {
    display: 'flex',
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.typography.pxToRem(7),
  },

  redirect: {
    display: 'flex',
    fontSize: theme.typography.pxToRem(14),
  },

  label: {
    marginTop: theme.typography.pxToRem(5),
  },

  icon: {
    height: theme.typography.pxToRem(20),
    '& > svg': {
      color: theme.palette.error.main,
    },
    marginRight: theme.typography.pxToRem(5),
  },

  action: {
    fontSize: theme.typography.pxToRem(14),
    marginLeft: theme.typography.pxToRem(5),
  },

  arrowIcon: {
    '& > svg': {
      marginTop: theme.typography.pxToRem(1),
      height: theme.typography.pxToRem(17),
    },
  },

  device: {
    textDecorationLine: 'underline',
    color: theme.palette.primary.contrastText,
    textDecorationColor: theme.palette.primary.contrastText,
  },

  snooze: {
    textDecorationLine: 'none',
    color: theme.palette.primary.contrastText,
    marginRight: theme.typography.pxToRem(16),
  },
}));

export const SnackbarContent = ({ alert, openSnoozeAlertModal }: ISnackbarContent) => {
  const { classes } = useStyles();
  const router = useRouter();
  const currentUrl = useMemo(() => router.getUrl(), [router]);

  const isGateway = alert.deviceType === 'GATEWAY';
  const isEquipment = alert.deviceType === 'EQUIPMENT';

  const alertTabLink = useMemo(() => {
    if (isGateway || isEquipment) {
      return routes.alerts.list.path;
    }

    return routes.deviceDrawer.open(currentUrl, alert.deviceId, 'alerts');
  }, [isGateway, isEquipment, currentUrl, alert.deviceId]);

  const deviceRedirectUrl = useMemo(() => {
    if (isGateway) {
      return routes.gateway.list.path;
    }

    if (isEquipment) {
      return routes.equipmentDashboard.details.url(alert.equipmentId);
    }

    return routes.deviceDrawer.open(currentUrl, alert.deviceId);
  }, [isGateway, isEquipment, currentUrl, alert.deviceId, alert.equipmentId]);

  // TODO: Switch nested device and snooze links to MUI Link Buttons and prevent redirect to tab
  return (
    <>
      <Link className={classes.snackbar} to={alertTabLink}>
        <div className={classes.content}>
          <span className={classes.icon}>
            <NotificationsRoundedIcon />
          </span>
          <div className={classes.label}>
            <Link className={classes.device} to={deviceRedirectUrl}>
              {alert.deviceName}
            </Link>
            {getSnackbarAlertMessage(alert)}
          </div>
        </div>
        <div className={classes.redirect}>
          {!isEquipment && (
            <Restricted to={PERMISSIONS.AUTOMATION_SNOOZE_ALERTS}>
              <Link className={classes.snooze} to="#" onClick={openSnoozeAlertModal}>
                {content.SNOOZE}
              </Link>
            </Restricted>
          )}
          <span className={classes.action}>{content.VIEW_ALERT}</span>
          <span className={classes.arrowIcon}>
            <ArrowForwardRoundedIcon />
          </span>
        </div>
      </Link>
    </>
  );
};
