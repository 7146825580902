import { LocationDetails } from '@marlin/asset/feature/location-details';
import { LocationHub } from '@marlin/asset/feature/location-hub';
import { EditLocation, NewLocation } from '@marlin/asset/feature/location-upsert';
import { Plant } from '@marlin/asset/feature/plant';
import { useFeatureFlagsContext } from '@marlin/shared/utils-common-feature-flags-context';
import { PERMISSIONS, PermissionGuard } from '@marlin/shared/utils-permission';
import { routes } from '@marlin/shared/utils-routes';
import { Route } from 'react-router-dom';

export const LocationRoutes = (): JSX.Element => {
  const path = routes.locations.root;
  const { plant } = useFeatureFlagsContext();

  return (
    <Route path={path}>
      <Route index element={<LocationHub />} />
      <Route
        path={routes.locations.create.path}
        element={
          <PermissionGuard redirectTo={path} permissions={[PERMISSIONS.CREATE_NEW_LOCATION]}>
            <NewLocation />
          </PermissionGuard>
        }
      />
      <Route
        path={routes.locations.update.path}
        element={
          <PermissionGuard redirectTo={path} permissions={[PERMISSIONS.EDIT_LOCATION]}>
            <EditLocation />
          </PermissionGuard>
        }
      />
      {plant && <Route path={routes.locations.plant.path} element={<Plant />} />}
      <Route path={routes.locations.details.path} element={<LocationDetails />} />
    </Route>
  );
};
