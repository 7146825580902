import { FormField, IFormControlProps, IFormTypedProps } from '@marlin/shared/ui-form-common';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import kebabCase from 'lodash/kebabCase';
import { ControllerProps, ControllerRenderProps, FieldError, FieldValues } from 'react-hook-form';

import { content } from '../content';
import { caStates } from './ca-states';
import { mxStates } from './mx-states';
import { usStates } from './us-states';

const stateOptions: Record<string, string[]> = {
  'United States': usStates,
  Canada: caStates,
  Mexico: mxStates,
};

type TRenderProps = Partial<Omit<ControllerRenderProps, 'ref'>>;

interface IStateSelectProps {
  country?: string;
  required?: boolean;
}

interface IStateSelectControlProps extends TRenderProps, IStateSelectProps {
  error?: FieldError;
  disabled?: boolean;
  testId?: string;
}

export const StateSelect = ({
  country,
  error,
  required,
  testId,
  disabled = false,
  ...rest
}: IStateSelectControlProps) => {
  const states = country ? stateOptions[country] || usStates : usStates;
  const label = country === 'Canada' ? content.CANADIANS_STATE_LABEL : content.STATE_LABEL;

  return (
    <FormControl error={!!error} fullWidth>
      <InputLabel id="state-select-label" required={required}>
        {label}
      </InputLabel>
      <Select
        labelId="state-select-label"
        id="state-select"
        label={label}
        {...rest}
        disabled={disabled}
        data-testid={`select-filters-${kebabCase(label).toLowerCase()}`}
      >
        {states.map((state) => (
          <MenuItem value={state} key={state}>
            {state}
          </MenuItem>
        ))}
      </Select>
      {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
    </FormControl>
  );
};

export function StateControl<TFieldValues extends FieldValues>(
  props: IFormControlProps<TFieldValues> & IStateSelectProps
): JSX.Element;
export function StateControl<
  TFieldValues extends FieldValues = object,
  TName extends ControllerProps<TFieldValues>['name'] = ControllerProps<TFieldValues>['name']
>(props: IFormTypedProps<TFieldValues, TName> & IStateSelectProps): JSX.Element;

export function StateControl<
  TFieldValues extends FieldValues,
  TName extends ControllerProps<TFieldValues>['name'] = ControllerProps<TFieldValues>['name']
>(params: (IFormControlProps<TFieldValues> | IFormTypedProps<TFieldValues, TName>) & IStateSelectProps) {
  if (params.control) {
    return (
      <FormField {...params}>
        {(props) => (
          <StateSelect {...props} country={params.country} required={params.required} disabled={params?.disabled} />
        )}
      </FormField>
    );
  }

  return (
    <FormField<TFieldValues> {...params}>
      {(props) => (
        <StateSelect {...props} country={params.country} required={params.required} disabled={params?.disabled} />
      )}
    </FormField>
  );
}
