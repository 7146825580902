import isArray from 'lodash/isArray';
import { Attributes, Children, ReactElement, cloneElement, isValidElement } from 'react';

type TChildElement<P> = ReactElement<P> | null | undefined | boolean;

export const useChildrenOverride = <P extends Partial<Attributes>>(
  children: TChildElement<P>[] | TChildElement<P>,
  overrideProps: (child: ReactElement<P>) => P
) => {
  if (isArray(children)) {
    return Children.map(children, (child: ReactElement<P> | null | undefined | boolean) => {
      if (isValidElement(child) && typeof child !== 'boolean') {
        return cloneElement(child, overrideProps(child));
      }
      return child;
    });
  }

  if (isValidElement(children) && typeof children !== 'boolean') {
    return cloneElement(children, overrideProps(children));
  }

  return children;
};
