export class PortfolioStorageService {
  private key = 'portfolioId';

  public set = (portfolioId: string): void => {
    sessionStorage.setItem(this.key, portfolioId);
    localStorage.setItem(this.key, portfolioId);
  };

  public get = (storageType: 'sessionStorage' | 'localStorage'): string | null => {
    switch (storageType) {
      case 'sessionStorage': {
        return sessionStorage.getItem(this.key);
      }
      case 'localStorage': {
        return localStorage.getItem(this.key);
      }
      default: {
        return null;
      }
    }
  };

  public getKey = (): string => this.key;

  public clear = (): void => {
    sessionStorage.removeItem(this.key);
    localStorage.removeItem(this.key);
  };
}

export const portfolioStorageService = new PortfolioStorageService();
