import { useRouter } from '@marlin/shared/utils-router';
import { routesPortfolio } from '@marlin/shared/utils-routes';
import React from 'react';

import { content } from './content';
import { CreateMenuItem } from './create-menu-item';

export const PortfolioDetailsCreate = () => {
  const { goTo } = useRouter();

  const handleToggle = () => goTo(routesPortfolio.create.root);

  return <CreateMenuItem handleToggle={handleToggle} buttonText={content.CREATE_PORTFOLIO} variant="portfolio" />;
};
