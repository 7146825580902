import { Typography } from '@mui/material';

import { content } from '../content';
import { useStyles } from './save-modal.styles';

export const SaveModalTitle = () => {
  const { classes } = useStyles();

  return <Typography className={classes.titleText}>{content.SAVE_MODAL_TITLE}</Typography>;
};
