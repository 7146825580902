import isString from 'lodash/isString';
import { lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';

import { IHttpErrorResponse, IHttpInterceptor, IHttpRequest } from '../model';
import { IConfigService } from './config.service';
import { IEndpointService } from './endpoint.service';
import { getParamsFromUrl } from './utils';

export class EndpointInterceptor implements IHttpInterceptor {
  public constructor(private endpointService: IEndpointService, private configService: IConfigService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async intercept(request: IHttpRequest): Promise<IHttpRequest> {
    const url = request.url || '';
    const paramsFromUrl = getParamsFromUrl(url);
    const option = {
      fullUrl: !url.startsWith('http'),
    };

    return lastValueFrom(
      this.endpointService.getUrl(url, request.params, option).pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((url): IHttpRequest => this.createRequest(request, url, paramsFromUrl))
      )
    );
  }

  public handleError = (error: IHttpErrorResponse): Promise<IHttpErrorResponse> => {
    return Promise.reject(error);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private createRequest = (request: IHttpRequest, url: string, paramsFromUrl: string[]): IHttpRequest => {
    const headers = this.getHeaders(request);
    const params = request.params || {};
    const remainingParams: { [key: string]: string } = {};

    // Get params that are in the request, but were not replaced within url and need to be added as query params
    const paramKeysToAdd = Object.keys(params).filter((reqParam): boolean => !paramsFromUrl.includes(reqParam));
    paramKeysToAdd.forEach((param): void => {
      const value = params[param];

      if (value) {
        remainingParams[param] = value;
      }
    });

    return { ...request, url, headers, params: remainingParams };
  };

  private getAllowedDomains = (): string[] => {
    if (
      !this.configService.config$.value?.allowedDomains ||
      !this.configService.config$.value?.allowedDomains?.length
    ) {
      return [];
    }

    const allowedDomains = this.configService.config$.value?.allowedDomains || [];
    return isString(allowedDomains) ? [allowedDomains] : allowedDomains;
  };

  private getHeaders = (request: IHttpRequest): IHttpRequest['headers'] => {
    const allowedDomains = this.getAllowedDomains();
    const headers = request.headers;

    if (allowedDomains.length) {
      headers.set(
        'Content-Security-Policy',
        `default-src ${allowedDomains.join(' ')} 'self'; connect-src ${allowedDomains.join(' ')} 'self'`
      );
      headers.set('Access-Control-Allow-Origin', allowedDomains.join(' '));
    }

    return headers;
  };
}
