import { routes } from '@marlin/shared/utils-routes';
import { useLocation, useNavigate } from 'react-router-dom';

interface IState {
  [key: string]: unknown;
}

interface IUseStore {
  removeParams: () => void;
  removeParamsPush: () => void;
  changeParams: (params: string) => void;
  goTo: (url: string, state?: IState) => void;
  goToWithPrevious: (url: string) => void;
  goToNewSensorPage: () => void;
  goToDeviceDrawerOnPage: (id: string) => void;
  goToDeviceEditPage: (id: string) => void;
}

// TODO: Do we need this?
export const useRouter = (): IUseStore => {
  const navigate = useNavigate();
  const location = useLocation();
  const goTo = (url: string, state?: IState): void => {
    navigate(url, { state });
  };

  return {
    removeParams: () => {
      navigate(location.pathname, { replace: true });
    },
    removeParamsPush: () => {
      navigate(location.pathname);
    },
    changeParams: (params: string) => {
      navigate(`${location.pathname}?${params}`);
    },
    goTo,
    goToWithPrevious: (url) => {
      navigate(url, { state: { prevLocation: location.pathname + location.search } });
    },
    goToNewSensorPage: () => {
      goTo(routes.sensors.create.globalPath);
    },
    goToDeviceDrawerOnPage: (id) => {
      goTo(routes.sensors.details.url(id));
    },
    goToDeviceEditPage: (id: string) => {
      goTo(routes.sensors.update.url(id));
    },
  };
};
