import { MarlinTheme } from '@marlin/shared/theme';
import { PERMISSIONS, Restricted } from '@marlin/shared/utils-permission';
import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../content';

interface IActionButtons {
  detailEditLink: string;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  button: {
    width: theme.typography.pxToRem(76),
    marginLeft: theme.typography.pxToRem(16),
    padding: theme.typography.pxToRem(5),
  },
  buttons: {
    display: 'flex',
  },
}));

export const ActionButtons = ({ detailEditLink }: IActionButtons) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { classes } = useStyles();

  return (
    <div className={classes.buttons}>
      <Restricted to={PERMISSIONS.EDIT_EQUIPMENT}>
        <Button
          variant="outlined"
          className={classes.button}
          onClick={() => navigate(detailEditLink, { state: { prevLocation: location.pathname } })}
          data-testid="edit-equipment-button"
        >
          {content.EDIT_BUTTON}
        </Button>
      </Restricted>
    </div>
  );
};
