import { getHttpClient } from '@marlin/shared/utils/react-query';

import { TAssetListFilters, TAssets, assetsSchema } from '../asset.model.schema';
import { paths } from './api';

export const getAssets = async (body: TAssetListFilters): Promise<TAssets> => {
  const res = await getHttpClient().post<unknown, unknown>(paths.ASSETS, body);

  return assetsSchema.parse(res);
};
