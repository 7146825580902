import { TExtractFnReturnType, useQuery } from '@marlin/shared/utils/react-query';
import { z } from 'zod';

import { getUser } from '../infrastructure/user';
import { queryKey } from './query-key.enum';

type TQueryFnType = typeof getUser;

type TUseUserOptions = {
  userId: string;
  onError?: () => void;
};

export const useUser = ({ userId, onError }: TUseUserOptions) => {
  return useQuery<TExtractFnReturnType<TQueryFnType>>({
    queryKey: queryKey.USER(userId),
    queryFn: () => getUser({ userId }),
    onError: (error) => {
      // eslint-disable-next-line no-console
      if (error instanceof z.ZodError) console.log(error.issues);
      if (onError) onError();
    },
  });
};
