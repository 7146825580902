import {
  getNewBucketOption,
  getNewSelection,
  isBucketOptionValid,
  onRangeFilterChange,
} from '@marlin/asset/shared/ui/chart-options';
import { useMultiChartsStore } from '@marlin/asset/ui/charts';
import { MarlinTheme } from '@marlin/shared/theme';
import { BucketOption, RANGE_FILTER, TBucketOption } from '@marlin/shared/utils-chart';
import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import moment from 'moment';
import { useCallback, useEffect, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';

interface IBucketOptionSelectProps {
  bucketOption: TBucketOption | '';
  setBucketOption: (bucketOption: TBucketOption) => void;
  from: number;
  to: number;
  isZoomed: boolean;
  handleZoomChange: (zoom?: { min: number; max: number } | undefined, bucketOption?: TBucketOption) => void;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  select: {
    width: theme.typography.pxToRem(180),
    maxHeight: theme.typography.pxToRem(40),
  },
  menuItem: {
    color: theme.palette.text.secondary,
  },
}));

const allOptions = [
  { id: 'minutes', name: content.BUCKET_OPTIONS.MINUTES },
  { id: 'hours', name: content.BUCKET_OPTIONS.HOURS },
  { id: 'days', name: content.BUCKET_OPTIONS.DAYS },
  { id: 'weeks', name: content.BUCKET_OPTIONS.WEEKS },
  { id: 'months', name: content.BUCKET_OPTIONS.MONTHS },
] as const;

export const BucketOptionSelect = ({
  setBucketOption,
  bucketOption,
  from,
  to,
  isZoomed,
  handleZoomChange,
}: IBucketOptionSelectProps) => {
  const { classes } = useStyles();
  const [rangeFilter] = useMultiChartsStore((store) => store.rangeFilter.range);

  const onChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      const bucketOption = BucketOption.safeParse(event.target.value);
      if (event.target.value && bucketOption.success) {
        setBucketOption(bucketOption.data);

        const zoom = getNewSelection({ min: from, max: to }, { min: from, max: to }, true, bucketOption.data);

        handleZoomChange(zoom, bucketOption.data);
      }
    },
    [from, handleZoomChange, setBucketOption, to]
  );

  const dateDifferenceInMinutes = useMemo(() => moment(to).diff(moment(from), 'minutes'), [from, to]);

  const filteredOptions = useMemo(() => {
    return allOptions.filter((option) =>
      isBucketOptionValid(option.id, rangeFilter, dateDifferenceInMinutes, isZoomed)
    );
  }, [dateDifferenceInMinutes, isZoomed, rangeFilter]);

  useEffect(() => {
    const shouldChangeBucketOption =
      !bucketOption || !isBucketOptionValid(bucketOption, rangeFilter, dateDifferenceInMinutes, isZoomed);

    if (shouldChangeBucketOption && rangeFilter === RANGE_FILTER.CUSTOM && bucketOption) {
      const newBucketOption = getNewBucketOption(dateDifferenceInMinutes, bucketOption);

      if (newBucketOption !== bucketOption) {
        setBucketOption(newBucketOption);
      }
      return;
    }

    if (!shouldChangeBucketOption || isZoomed) return;

    onRangeFilterChange(rangeFilter, bucketOption, setBucketOption);
  }, [bucketOption, dateDifferenceInMinutes, isZoomed, rangeFilter, setBucketOption]);

  return (
    <FormControl size="small">
      <Select data-testid="bucket-option" fullWidth value={bucketOption} onChange={onChange} className={classes.select}>
        {filteredOptions.map(({ name, id }, index) => {
          return (
            <MenuItem key={index} className={classes.menuItem} value={id}>
              {name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
