export const content = {
  NO_DATA: 'No results found.',
  ERROR: 'Something went wrong. Please try again later.',
  STATUS_LOST_COMMUNICATION: 'Lost Communication',
  STATUS_OPERATIONAL: 'Operational',

  EQUIPMENT_HUB_TITLE: 'Equipment',
  EQUIPMENT_HUB_SUBTITLE: 'Use the data table below to manage your equipment.',
  CREATE_NEW_BUTTON: 'Create New',
  SEARCH_LOCATION: 'Search by equipment or location',
  LOCATION_LABEL: 'Location',
  CLEAR_ALL: 'Clear All',
  EQUIPMENT_HEADER_NAME: 'Equipment',
  LOCATION_HEADER_NAME: 'Location',
  GATEWAY_HEADER_NAME: 'Gateway',
  TYPE_HEADER_NAME: 'Type',
  STATUS_HEADER_NAME: 'Status',
  MODE_HEADER_NAME: 'Mode',
  LAST_READING_TIME_HEADER_NAME: 'Last Reading Time',
  MODEL_HEADER_NAME: 'Model',
  SERIAL_NUMBER_HEADER_NAME: 'Serial Number',
  UNKNOWN_OPTION: 'Unknown option',
  EMPTY_LIST: 'No results found.',
  EQUIPMENT_SORTING_NAME: 'Equipment Name',
  LOCATION_SORTING_NAME: 'Location Name',
  SENSOR_NUMBER_SORTING: 'Sensors Number',
  EMPTY_DATAPOINT_VALUE: '--',
  MODE: {
    INTELLISTATION: {
      STARTUP: 'Startup',
      AUTO: 'Auto',
      IDLE: 'Idle',
      MANUAL: 'Manual',
      SANITIZE: 'Sanitize',
      COOLDOWN: 'Cooldown',
    },
    AERCO: {
      DISABLED: 'Disabled',
      STANDBY: 'StandBy',
      MANUAL: 'Manual',
      REMOTE: 'Remote',
      AUTO: 'Auto',
      FAULT: 'Fault',
    },
    SENTINEL: {
      STANDBY: 'Standby',
      HOME: 'Home',
      AWAY: 'Away',
    },
    SBS: {
      AUTOMATIC: 'Automatic',
      OVERRIDE: 'Override',
    },
    SSC: {
      WWSD: 'Warm Weather Shutdown',
      ESTABLISH_STEAM_MODE: 'Establishing Steam',
      COOL_DOWN: 'Cool Down',
      OFF: 'Off',
      HEAT_ON: 'Heat On',
      OVERRIDE_OFF: 'Override: Off',
      OVERRIDE_HAND: 'Override: Hand',
      OVERRIDE_MAX_HEAT: 'Override: Max Heat',
      NO_HEAT_ABORT_CUTOFF: 'No Heat Abort Cutoff',
      NO_HEAT_NORMAL: 'No Heat Normal',
    },
  },
};
