import { IConfig } from '@marlin/asset/shared/equipment-config';
import { createContext, useContext } from '@marlin/shared/utils-component-context';
import { PropsWithChildren } from 'react';

interface IEquipmentDetailsConfigContext {
  config: IConfig;
}

interface IEquipmentDetailsConfigContextProviderProps extends PropsWithChildren<IEquipmentDetailsConfigContext> {}

export const EquipmentDetailsConfigContext = createContext<IEquipmentDetailsConfigContext>();

export const EquipmentDetailsConfigContextProvider = ({
  config,
  children,
}: IEquipmentDetailsConfigContextProviderProps) => {
  return <EquipmentDetailsConfigContext.Provider value={{ config }}>{children}</EquipmentDetailsConfigContext.Provider>;
};

export const useEquipmentDetailsConfigContext = () => useContext(EquipmentDetailsConfigContext);
