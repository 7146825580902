import { TDatapointWithUiMetadata, TEquipmentSetting } from '@marlin/shared/utils/datapoint-mappers';
import { useMemo } from 'react';
import { z } from 'zod';

import { useGatewayDatapointsContext } from '../context/gateway-datapoints.context';

export const useIsConfigDataCurrent = (settings: TEquipmentSetting[]) => {
  const { getDatapoint } = useGatewayDatapointsContext();

  return useMemo(
    () => checkIsConfigDataCurrent(toLowerCaseKeys(getConfigData(getDatapoint('configdata'))), settings),
    [getDatapoint, settings]
  );
};

const checkIsConfigDataCurrent = (configData: Record<string, unknown> | undefined, settings: TEquipmentSetting[]) => {
  if (!settings.length || !configData) {
    return {
      isCurrent: true,
      nonCurrentDatapointNames: [],
    };
  }

  const loggingSettingNames = ['logginglevel', 'logfilesize'];
  const loggingNameMap = new Map<(typeof loggingSettingNames)[number], string>([
    ['logginglevel', 'level'],
    ['logfilesize', 'filesize'],
  ]);

  const outdatedDatapointNames = settings.reduce((acc, setting) => {
    if (loggingSettingNames.includes(setting.name.toLowerCase())) {
      const parsedLoggingConfigData = z.record(z.string().or(z.number())).safeParse(configData.logging);

      if (!parsedLoggingConfigData.success) {
        return [...acc, setting.name];
      }

      return parsedLoggingConfigData.data[loggingNameMap.get(setting.name.toLowerCase()) ?? '']?.toString() ===
        setting.value
        ? acc
        : [...acc, setting.name];
    }

    return configData[setting.name.toLowerCase()]?.toString() === setting.value ? acc : [...acc, setting.name];
  }, [] as string[]);

  return {
    isCurrent: outdatedDatapointNames.length === 0,
    outdatedDatapointNames,
  };
};

const getConfigData = (datapoint: TDatapointWithUiMetadata | undefined) => {
  if (!datapoint) return undefined;
  try {
    return toLowerCaseKeys(JSON.parse(datapoint.value));
  } catch {
    return undefined;
  }
};

const toLowerCaseKeys = (obj: Record<string, unknown> | undefined): Record<string, unknown> => {
  if (!obj) return {};
  return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];
    const lowercaseKey = key.toLowerCase();
    if (typeof value === 'object' && value !== null) {
      acc[lowercaseKey] = toLowerCaseKeys(value as Record<string, unknown>);
    } else {
      acc[lowercaseKey] = value;
    }
    return acc;
  }, {} as Record<string, unknown>);
};
