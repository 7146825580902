import { MarlinTheme } from '@marlin/shared/theme';
import {
  FormField,
  IFormControlProps,
  IFormTypedProps,
  IRadioButton,
  RadioButtons,
} from '@marlin/shared/ui-form-common';
import { FormControl } from '@mui/material';
import { Typography } from '@mui/material';
import { ControllerProps, ControllerRenderProps, FieldError, FieldValues } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  title: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.secondary,
    marginBottom: theme.typography.pxToRem(8),
  },
}));

type TRenderProps = Partial<Omit<ControllerRenderProps, 'ref'>>;

interface ISelectProps {
  className?: string;
  options: IRadioButton[];
  label: string;
}

interface ISelectControlProps<TFieldValues extends FieldValues> extends ISelectProps, TRenderProps {
  error?: FieldError;
  disabled?: boolean;
  defaultValue?: ControllerProps<TFieldValues>['defaultValue'];
  required?: boolean;
  featureFlags?: { intellistation: boolean };
  options: IRadioButton[];
  label: string;
}

const RadioControl = <TFieldValues extends FieldValues>({
  error,
  disabled = false,
  required = false,
  label,
  options,
  ...rest
}: ISelectControlProps<TFieldValues>) => {
  const { classes } = useStyles();

  return (
    <FormControl error={!!error} fullWidth={true}>
      <Typography className={classes.title}>{label}</Typography>
      <RadioButtons buttons={options} {...rest} />
    </FormControl>
  );
};

export function SettingsRadioGroupControl<TFieldValues extends FieldValues>(
  props: IFormControlProps<TFieldValues> & ISelectProps
): JSX.Element;
export function SettingsRadioGroupControl<
  TFieldValues extends FieldValues = object,
  TName extends ControllerProps<TFieldValues>['name'] = ControllerProps<TFieldValues>['name']
>(props: IFormTypedProps<TFieldValues, TName> & ISelectProps): JSX.Element;
export function SettingsRadioGroupControl<
  TFieldValues extends FieldValues,
  TName extends ControllerProps<TFieldValues>['name'] = ControllerProps<TFieldValues>['name']
>(params: (IFormControlProps<TFieldValues> | IFormTypedProps<TFieldValues, TName>) & ISelectProps) {
  if (params.control) {
    // TODO: Remove support for the defaultValue after device forms refactor
    return (
      <FormField {...params}>
        {(props) => (
          <RadioControl
            {...props}
            className={params?.className}
            disabled={params?.disabled}
            defaultValue={params?.defaultValue}
            required={params?.required}
            options={params.options}
            label={params.label}
          />
        )}
      </FormField>
    );
  }

  return (
    <FormField<TFieldValues> {...params}>
      {(props) => (
        <RadioControl<TFieldValues>
          {...props}
          className={params?.className}
          disabled={params?.disabled}
          required={params?.required}
          options={params.options}
          label={params.label}
        />
      )}
    </FormField>
  );
}
