import { MarlinTheme } from '@marlin/shared/theme';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { Paper, PaperProps, alpha } from '@mui/material';
import { MouseEvent } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useContent } from '../../context';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  addRecipient: {
    width: '100%',
    marginBottom: theme.typography.pxToRem(8),
    padding: theme.typography.pxToRem(12),
    background: alpha(theme.palette.primary.main, 0.04),
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },

  icon: {
    marginRight: theme.typography.pxToRem(12),
  },
}));

interface ICustomPaperProps extends PaperProps {
  onMouseDown: (event: MouseEvent<HTMLDivElement>) => void;
}

export function CustomPaper(props: ICustomPaperProps) {
  const { classes } = useStyles();
  const content = useContent();
  const { children, onMouseDown, ...other } = props;
  return (
    <Paper {...other}>
      {children}
      {/* NOTE: Click events in paper component must be handled in onMouseDown */}
      {/* See: https://github.com/mui/material-ui/discussions/29128 */}
      <div className={classes.addRecipient} onMouseDown={onMouseDown}>
        <AddCircleRoundedIcon className={classes.icon} />
        {content.ACTION.RECIPIENT.BUTTON}
      </div>
    </Paper>
  );
}
