import { SectionEntry, SectionHeader, SectionTitle } from '@marlin/shared/ui-asset-card-mobile';
import { Link } from '@marlin/shared/ui-page';
import { routes } from '@marlin/shared/utils-routes';
import { TEquipment } from '@marlin/shared/utils/zod';

import { content } from '../../content';
import { useStyles } from './details.styles';
import { useAutomationsTotal } from './use-automations.hook';

interface IDetailsProps {
  equipment?: TEquipment;
}

const equipmentDataTestId = 'equipment-details';
const equipmentDataEntryTestId = `${equipmentDataTestId}-entry`;

const additionalInformationDataTestId = 'equipment-additional-information';
const additionalInformationDataEntryTestId = `${additionalInformationDataTestId}-entry`;

export const Details = ({ equipment }: IDetailsProps) => {
  const { classes } = useStyles();
  const { totalItems } = useAutomationsTotal(equipment?.id);

  if (!equipment) {
    return null;
  }

  return (
    <div data-testid="equipment-details-tab">
      <SectionHeader>
        <SectionTitle testId={equipmentDataTestId}>{content.EQUIPMENT_DETAILS}</SectionTitle>
      </SectionHeader>
      <SectionEntry testId={equipmentDataEntryTestId} title={content.EQUIPMENT_NAME}>
        <div className={classes.wrap}>{equipment.name}</div>
      </SectionEntry>
      {equipment.locationId && (
        <SectionEntry testId={equipmentDataEntryTestId} title={content.LOCATION}>
          <Link to={routes.locations.details.url(equipment.locationId)} className={classes.link}>
            {equipment.locationName}
          </Link>
        </SectionEntry>
      )}
      <SectionHeader>
        <SectionTitle testId={additionalInformationDataTestId}>{content.ADDITIONAL_INFORMATION}</SectionTitle>
      </SectionHeader>
      <SectionEntry testId={additionalInformationDataEntryTestId} title={content.AUTOMATIONS_TITLE}>
        {totalItems}
      </SectionEntry>
      <SectionEntry testId={additionalInformationDataEntryTestId} title={content.ID} subtitle={content.ID_DESC}>
        {equipment.id}
      </SectionEntry>
      <SectionEntry testId={additionalInformationDataEntryTestId} title={content.SERIAL_NUMBER_TITLE}>
        {equipment.serialNumber}
      </SectionEntry>
      <SectionEntry testId={additionalInformationDataEntryTestId} title={content.BRAND_TITLE}>
        {equipment.brand}
      </SectionEntry>
      <SectionEntry testId={additionalInformationDataEntryTestId} title={content.MODEL_TITLE}>
        {equipment.model}
      </SectionEntry>
    </div>
  );
};
