import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  pageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: `calc( 100% - ${theme.typography.pxToRem(48)} )`,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.typography.pxToRem(48),
    gap: theme.typography.pxToRem(48),
  },
  errorContainer: {
    gap: 'unset',
    height: '100%',
    flexGrow: 1,
  },
}));
