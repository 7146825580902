import { useGetOrganizationDetails } from '@marlin/account-data-access-organization';
import {
  ISankeyProps,
  PERIOD_ENUM,
  TPeriodDate,
  useGetSustainabilityFormattedTimezone,
  usePeriodState,
} from '@marlin/shared/ui/chart';
import { useMemo } from 'react';

import { useUnitSelector } from '../shared/unit-selector/use-unit-selector';
import { useWaterPrice } from '../shared/use-water-price.hook';
import { IUnitOption, unitOptions } from './constants';
import { useSankeyData } from './use-sankey-data.hook';

interface IUseWaterTraceability {
  selectedUnit: IUnitOption;
  handleUnitChange: (_: React.MouseEvent<HTMLElement, MouseEvent>, value: IUnitOption) => void;
  timezone: string;
  handleChangePeriod: (_: React.MouseEvent<HTMLElement>, newPeriodProps: PERIOD_ENUM) => void;
  handleCustomRangeSet: ({
    fromDate,
    toDate,
  }: Partial<{ fromDate: TPeriodDate; toDate: TPeriodDate; open: boolean }>) => void;
  selectedPeriod: PERIOD_ENUM;
  currentPeriod: {
    from: TPeriodDate;
    to: TPeriodDate;
  };
  sankeyData: ISankeyProps;
  isLoading: boolean;
  isError: boolean;
}

export const useWaterTraceability = (): IUseWaterTraceability => {
  const { waterPrice } = useWaterPrice();
  const { data: orgDetails } = useGetOrganizationDetails();
  const { selectedOption: selectedUnit, handleChange: handleUnitChange } = useUnitSelector({ unitOptions });
  const { timezone } = useGetSustainabilityFormattedTimezone(orgDetails?.timeZone);
  const {
    range: currentPeriod,
    selectedPeriod,
    handleChangePeriod,
    handleCustomRangeSet,
  } = usePeriodState(PERIOD_ENUM.WEEK);

  const sankeyData = useSankeyData({ waterPrice, selectedUnit: selectedUnit.value, currentPeriod });

  return useMemo(
    (): IUseWaterTraceability => ({
      selectedUnit,
      handleUnitChange,
      timezone,
      handleChangePeriod,
      handleCustomRangeSet,
      selectedPeriod,
      currentPeriod,
      sankeyData: sankeyData.data,
      isLoading: sankeyData.isLoading,
      isError: sankeyData.isError,
    }),
    [
      selectedUnit,
      handleUnitChange,
      timezone,
      handleChangePeriod,
      handleCustomRangeSet,
      selectedPeriod,
      currentPeriod,
      sankeyData,
    ]
  );
};
