import { TDashboardDeviceAlertsSchema } from '@marlin/asset/data-access/home-dashboard';
import { MarlinTheme } from '@marlin/shared/theme';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import DeviceHubRounded from '@mui/icons-material/DeviceHubRounded';
import SensorsRounded from '@mui/icons-material/SensorsRounded';
import { makeStyles } from 'tss-react/mui';

import { CRITICALITY } from '../../../shared/types';
import { content } from './content';
import { CriticalityControl } from './criticality-control';

interface ICriticality {
  data: TDashboardDeviceAlertsSchema;
  type?: 'Equipment' | 'Sensor';
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: theme.typography.pxToRem(16),
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  nameContainer: {
    display: 'flex',
    margin: 'auto',
    marginLeft: theme.typography.pxToRem(0),
    marginRight: theme.typography.pxToRem(0),
    width: theme.typography.pxToRem(160),
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.typography.pxToRem(6),
    },
  },
  icon: {
    marginRight: theme.typography.pxToRem(4),
  },
}));

export const CriticalityAlertsSection = ({ data, type }: ICriticality) => {
  const { classes } = useStyles();
  const { goTo } = useRouter();

  const noOpenAlerts = data.highAlertsCount + data.lowAlertsCount === 0;

  const navigateToHubs = (type?: 'Equipment' | 'Sensor') => {
    goTo(type === content.EQUIPMENT ? routes.equipments.list.url() : routes.sensors.list.url());
  };

  return (
    <div className={classes.container}>
      <>
        <div className={classes.nameContainer} data-testid="performance-assetName" onClick={() => navigateToHubs(type)}>
          {type === content.EQUIPMENT ? (
            <>
              <DeviceHubRounded className={classes.icon} />
              {content.EQUIPMENT}
            </>
          ) : (
            <>
              <SensorsRounded className={classes.icon} />
              {content.SENSORS}
            </>
          )}
        </div>
        <div className={classes.sectionContainer} data-testid="performance-assetStatus">
          {noOpenAlerts ? (
            <CriticalityControl severity={CRITICALITY.NO} type={type} />
          ) : (
            <>
              <CriticalityControl severity={CRITICALITY.HIGH} highAlertsCount={data.highAlertsCount} type={type} />
              <CriticalityControl severity={CRITICALITY.LOW} lowAlertsCount={data.lowAlertsCount} type={type} />
            </>
          )}
        </div>
      </>
    </div>
  );
};
