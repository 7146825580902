export const content = {
  TITLE: 'Add Flow Link',
  EDIT_TITLE: 'Edit Flow Link',

  INLET: 'Inlet',
  OUTLET: 'Outlet',

  ASSET_TYPE_LABEL: 'Asset type',
  ASSET_TYPE_PLACEHOLDER: 'No filter',

  ASSET_LABEL: 'Choose Asset',

  ASSET_LOCATION: 'Location',
  ASSET_EQUIPMENT: 'Equipment',
  ASSET_SENSOR: 'Sensor',

  GROUP_LOCATION: 'Locations',
  GROUP_EQUIPMENT: 'Equipments',
  GROUP_SENSOR: 'Sensors',

  BTN_CANCEL: 'Cancel',
  BTN_ADD: 'Add',
  BTN_EDIT: 'Update',

  VALIDATION_INLET_REQUIRED: 'Inlet asset is missing',
  VALIDATION_OUTLET_REQUIRED: 'Outlet asset is missing',
  VALIDATION_LOOP:
    'Cannot link an asset to itself. Create another sensor and link it between the asset to create a loop.',
  EQUIPMENT_HANDLE_REQUIRED: 'Equipment handle is required',

  NO_RESULTS: 'No results found',

  RADIO_OUTLET_LABEL: 'Into Tee',
  RADIO_INLET_LABEL: 'Out Of Tee',
};
