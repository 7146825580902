import { MarlinTheme } from '@marlin/shared/theme';

export const getChartingColors = (theme: MarlinTheme) => [
  theme.palette.charting.breezyBlue,
  theme.palette.charting.freshGreen,
  theme.palette.charting.flushedPink,
  theme.palette.charting.frostyBlue,
  theme.palette.charting.toastyOrange,
  theme.palette.charting.aquaFusion,
  theme.palette.charting.springPink,
  theme.palette.charting.sunnyGold,
  theme.palette.charting.plasmaLavender,
  theme.palette.charting.hazyLilac,
  theme.palette.charting.oliveDuct,
  theme.palette.charting.rustyCopper,
  theme.palette.charting.charting13,
  theme.palette.charting.charting14,
  theme.palette.charting.charting15,
  theme.palette.charting.charting16,
];
