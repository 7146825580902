import { MarlinTheme } from '@marlin/shared/theme';
import { getCardTitle } from '@marlin/shared/utils/format-alert';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { IAlert } from '../types';
import { SnoozedAlertContentHeaderMobile } from './alert-content-snoozed-header-mobile.component';
import { SnoozedAlertContentHeader } from './alert-content-snoozed-header.component';
import { AutomationLinkSection } from './automation-link-section.component';
import { TitleSection } from './title-section.component';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  icon: {
    color: theme.palette.error.dark,
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    columnGap: theme.typography.pxToRem(24),
  },
}));

interface IAlertContentProps {
  alert: IAlert;
  isMobile: boolean;
  automationLink: (deviceId: string, isSystemRule: boolean) => string;
}

export function SnoozedAlertContent({ alert, isMobile, automationLink }: IAlertContentProps) {
  const { classes } = useStyles();
  const title = useMemo(() => getCardTitle(alert), [alert]);

  return (
    <div>
      {isMobile ? <SnoozedAlertContentHeaderMobile alert={alert} /> : <SnoozedAlertContentHeader alert={alert} />}

      <TitleSection title={title} icon={<WarningRoundedIcon className={classes.icon} />} />
      <AutomationLinkSection
        name={alert.automation.name}
        link={automationLink(alert.device.id, alert.automation.isSystemRule)}
        errorType={alert.errorType}
      />
    </div>
  );
}
