import { MarlinTheme } from '@marlin/shared/theme';
import { Button, Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';
import { IUpsertRecipientProfile } from './types';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    marginTop: theme.typography.pxToRem(8),
  },
}));

interface IActionButtonProps {
  onSubmit: (data: IUpsertRecipientProfile) => void;
  onCancel?: () => void;
  editMode?: boolean;
}

export const Buttons = ({ onSubmit, onCancel, editMode = false }: IActionButtonProps) => {
  const { classes } = useStyles();
  const { handleSubmit, formState } = useFormContext<IUpsertRecipientProfile>();

  if (onCancel) {
    return (
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={12} md={6}>
          <Button fullWidth variant="outlined" data-testid="upsert-user-profile-cancel-button" onClick={onCancel}>
            {content.BTN_CANCEL}
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            fullWidth
            data-testid="upsert-user-profile-confirm-button"
            variant="contained"
            disabled={!formState.isDirty || !formState.isValid}
            onClick={handleSubmit(onSubmit)}
          >
            {editMode ? content.BTN_DONE : content.BTN_ADD}
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item xs={12} md={12}>
        <Button
          fullWidth
          data-testid="upsert-user-profile-confirm-button"
          variant="contained"
          disabled={!formState.isDirty || !formState.isValid}
          onClick={handleSubmit(onSubmit)}
        >
          {content.BTN_ADD}
        </Button>
      </Grid>
    </Grid>
  );
};
