import { queryClient, useMutation } from '@marlin/shared/utils/react-query';

import { deleteWebhook } from '../infrastructure/delete-webhook';
import { queryKey } from './query-key.enum';

export const useDeleteWebhook = () => {
  return useMutation<unknown, unknown, { webhookId: string }>({
    mutationFn: deleteWebhook,

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.FILTERED_WEBHOOKS() });
      queryClient.invalidateQueries({ queryKey: queryKey.WEBHOOK() });
    },
  });
};
