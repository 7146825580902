import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';

import { mockMsal } from './__mock__/msal.mock';
import { AuthFacade } from './auth.facade';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { IAuthStorage, IConfig, IStorageService } from './models/auth.model';
import { IAuthFacade } from './models/facade.model';
import { AccountService } from './services/account.service';
import { AuthService } from './services/auth.service';
import { configService } from './services/config.service';
import { IPersistentLocationState, LocationStateService } from './services/location-state.service';
import { LogoutTimerService } from './services/logout-timer.service';
import { navigationClientService } from './services/navigation-client.service';
import { RefreshTokenService } from './services/refresh-token.service';
import { RequestService } from './services/request.service';
import { TokenService } from './services/token.service';

let authFacade: AuthFacade;
let authService: AuthService;
let logoutTimerService: LogoutTimerService;

interface IModuleConfig {
  issuer: string;
  clientId: string;
  allowedUrls: string[];
  redirectUri: string;
  logoutUri: string;
  setupAutomaticSilentRefresh: boolean;
  scopes: string[];
  storage?: IAuthStorage;
  b2cUrls: {
    edit: string;
    resetPassword: string;
  };
  baseUrl: string;
}

export async function init(
  config?: IModuleConfig,
  storageService?: IStorageService,
  persistentLocationState?: IPersistentLocationState
): Promise<void> {
  if (!config || !storageService || !persistentLocationState) {
    throw new Error(`Config is required`);
  }
  const internalConfig = createInternalConfig(config);
  configService.set(internalConfig);
  const authMsalService: IPublicClientApplication = new PublicClientApplication(configService.get());
  await authMsalService.initialize();

  const accountService = new AccountService(authMsalService);
  const requestService = new RequestService(configService, accountService);
  const tokenService = new TokenService(authMsalService, requestService);
  const refreshTokenService = new RefreshTokenService(authMsalService, configService, requestService, tokenService);
  authService = new AuthService(
    authMsalService,
    accountService,
    requestService,
    tokenService,
    refreshTokenService,
    configService,
    storageService,
    new LocationStateService(persistentLocationState)
  );
  logoutTimerService = new LogoutTimerService();
  // TODO: Refine clearing storage after logout
  authFacade = new AuthFacade(authService, logoutTimerService, storageService);
}

// only for testing purposes - storybook
export function mockInit(): void {
  const accountService = new AccountService(mockMsal);
  const requestService = new RequestService(configService, accountService);
  const tokenService = new TokenService(mockMsal, requestService);
  const refreshTokenService = new RefreshTokenService(mockMsal, configService, requestService, tokenService);
  authService = new AuthService(
    mockMsal,
    accountService,
    requestService,
    tokenService,
    refreshTokenService,
    configService,
    {
      clear: (): void => {
        // no-op
      },
      clearKey: (): void => {
        // no-op
      },
    },
    new LocationStateService({})
  );
  logoutTimerService = new LogoutTimerService();
  authFacade = new AuthFacade(authService, logoutTimerService, {
    clear: (): void => {
      // no-op
    },
    clearKey: (): void => {
      // no-op
    },
  });
}

export function getAuthService(): IAuthFacade {
  if (!authFacade) {
    throw new Error(`AuthModule isn't initialized`);
  }

  return authFacade;
}

export function createAuthInterceptor(): AuthInterceptor {
  if (!authService || !logoutTimerService) {
    throw new Error(`AuthModule isn't initialized`);
  }

  return new AuthInterceptor(authService);
}

function createInternalConfig(config: IModuleConfig): IConfig {
  return {
    auth: {
      authority: config.issuer,
      clientId: config.clientId,
      knownAuthorities: config.allowedUrls,
      redirectUri: config.redirectUri,
      postLogoutRedirectUri: config.logoutUri,
    },
    requestRedirect: {
      scopes: config.scopes,
    },
    cache: {
      cacheLocation: config.storage || 'sessionStorage',
    },
    system: {
      tokenRenewalOffsetSeconds: 100,
      setupAutomaticSilentRefresh: config.setupAutomaticSilentRefresh,
      navigationClient: navigationClientService,
    },
    b2cUrls: {
      editProfile: config.b2cUrls.edit,
      resetPassword: config.b2cUrls.resetPassword,
    },
    baseUrl: config.baseUrl,
  };
}
