import { TPortfolioPermission } from '@marlin/shared/utils-permission';
import { INTERNAL_ROLE, PORTFOLIO_ROLE, TPortfolioMultiOrgRole } from '@marlin/shared/utils-role';

import { managerPermissions } from './manager.permission';
import { supportPermissions } from './support.permission';
import { viewerPermissions } from './viewer.permission';

export const rolePermission: { [key in TPortfolioMultiOrgRole]: TPortfolioPermission[] } = {
  [PORTFOLIO_ROLE.MANAGER]: managerPermissions,
  [PORTFOLIO_ROLE.VIEWER]: viewerPermissions,
  [PORTFOLIO_ROLE.DELETED]: viewerPermissions,
  [INTERNAL_ROLE.SUPPORT]: supportPermissions,
  [INTERNAL_ROLE.CSM]: supportPermissions,
  [INTERNAL_ROLE.BUCS]: supportPermissions,
};
