import { MarlinTheme } from '@marlin/shared/theme';
import { FilterChipsContainer } from '@marlin/shared/ui/chips';
import { Button } from '@mui/material';
import isEqual from 'lodash/isEqual';
import { PropsWithChildren, useMemo } from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  clearButton: {
    textWrap: 'nowrap',
    marginLeft: theme.typography.pxToRem(8),
    marginRight: theme.typography.pxToRem(8),
    height: theme.typography.pxToRem(32),
  },
}));

export interface IFormFilterChipsProps<TFormValues> extends PropsWithChildren {
  defaultFilters: TFormValues;
  showEmptyFilters?: boolean;
  onClear?: () => void;
}

export const defaultSorting = {
  sortBy: '',
  direction: 'Descending',
};

export const FormFilterChips = <TFormValues extends FieldValues>({
  defaultFilters,
  children,
  showEmptyFilters,
  onClear,
}: IFormFilterChipsProps<TFormValues>) => {
  const { classes } = useStyles();
  const { watch, reset } = useFormContext<TFormValues>();

  const filters = watch();

  const emptyFormValues = useMemo(
    () => ('sorting' in filters ? { ...defaultFilters, sorting: defaultSorting } : defaultFilters),
    [defaultFilters, filters]
  );
  const isAnyFiltersSet = useMemo(() => !isEqual(emptyFormValues, filters), [emptyFormValues, filters]);

  const clearFilters = () => {
    reset(emptyFormValues);
    onClear?.();
  };

  if (!isAnyFiltersSet && !showEmptyFilters) {
    return null;
  }

  return (
    <FilterChipsContainer>
      {children}
      <Button className={classes.clearButton} onClick={clearFilters}>
        {content.CLEAR_ALL}
      </Button>
    </FilterChipsContainer>
  );
};
