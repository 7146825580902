import { Link } from '@marlin/shared/ui-page';

import { content } from '../translation-keys';
import { BaseError } from './base-error.component';

export const InvalidUserEmail = () => {
  return (
    <BaseError
      title={content.INVALID_EMAIL_ADDRESS.TITLE}
      subtitle={
        <>
          {content.INVALID_EMAIL_ADDRESS.BODY}
          <br />
          <br />
          {content.SUPPRORT_TEXT}&nbsp;
          {
            <Link to={`mailto:${content.SUPPRORT_LINK}`} external>
              {content.SUPPRORT_LINK}
            </Link>
          }
          {'.'}
        </>
      }
    />
  );
};
