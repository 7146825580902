import { z } from 'zod';

const registrationFlowValues = ['Tekmar', 'AercoWaterHeater', 'AercoBoiler'] as const;

export const registerValidateDataSchema = z.object({
  registrationFlow: z.enum(registrationFlowValues),
  equipmentName: z.string().nullish(),
  registrationCode: z.string().nullish(),
  serialNumber: z.string().nullish(),
});

export type TRegisterValidateDataSchema = z.infer<typeof registerValidateDataSchema>;
