import { Breakpoints } from '@mui/material/styles';

import { defaultConfig } from './default-config';

export const breakpoint: Breakpoints = {
  ...defaultConfig.breakpoints,
  values: {
    xs: 0,
    sm: 480,
    md: 601,
    lg: 960,
    xl: 1280,
    xxl: 1920,
  },
};
