import { TSublocationAssetSchema } from '@marlin/asset/data-access/location';
import { FilteredResultsChip } from '@marlin/asset/shared/ui/mobile-filter-modal';
import { IMobileFilterFormProps } from '@marlin/asset/shared/ui/mobile-filter-modal';

import { IAsset } from '../../../desktop/components/grid/types';
import { ILocationDetailsFilters } from '../../location-details.component';
import { TSetMobileLocationDetailsFilters } from '../../types';
import { AssetList } from './asset-list.component';
import { useStyles } from './linked-items.styles';

interface ILinkedItemsProps {
  rows: TSublocationAssetSchema[];
  deviceDrawerRows: TSublocationAssetSchema[];
  allowDelete: boolean;
  allowEdit: boolean;
  handleAfterDelete: () => void;
  refetch: () => void;
  editAsset: (asset: IAsset) => void;
  setMobileFilters: TSetMobileLocationDetailsFilters;
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  totalItems?: number;
  isListLoading: boolean;
  mobileFilterForm: IMobileFilterFormProps<'', ILocationDetailsFilters>;
}

export const LinkedItems = ({
  rows,
  allowDelete,
  allowEdit,
  editAsset,
  handleAfterDelete,
  refetch,
  setMobileFilters,
  hasNextPage,
  fetchNextPage,
  totalItems,
  isListLoading,
  deviceDrawerRows,
  mobileFilterForm,
}: ILinkedItemsProps) => {
  const { classes } = useStyles();

  return (
    <div>
      <div className={classes.filterBar}>
        {mobileFilterForm.isAnyFilterSet && <FilteredResultsChip onDelete={mobileFilterForm.clearFilters} />}
      </div>
      <AssetList
        rows={rows}
        allowDelete={allowDelete}
        allowEdit={allowEdit}
        handleAfterDelete={handleAfterDelete}
        editAsset={editAsset}
        refetch={refetch}
        setMobileFilters={setMobileFilters}
        totalItems={totalItems}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isListLoading={isListLoading}
        deviceDrawerRows={deviceDrawerRows}
      />
    </div>
  );
};
