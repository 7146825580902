import { MarlinTheme } from '@marlin/shared/theme';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { Paper } from '@marlin/shared/ui-page';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  title: {
    fontSize: theme.typography.pxToRem(24),
    marginTop: 0,
    marginBottom: theme.typography.pxToRem(16),
  },

  tooltip: {
    marginRight: theme.typography.pxToRem(8),
  },
}));

interface ISectionHeaderProps {
  title: string;
  tooltip: string;
  prefix: string;
}

export const SectionHeader = ({ title, tooltip, prefix }: ISectionHeaderProps) => {
  const { classes } = useStyles();
  return (
    <Paper>
      <h5 data-testid={`${prefix}-section-title`} id={`${prefix}-section-title`} className={classes.title}>
        {title}
        <span className={classes.tooltip}>
          <Tooltip text={tooltip} />
        </span>
      </h5>
    </Paper>
  );
};
