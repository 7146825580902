import { formatTimestamp } from '@marlin/shared/utils-common-date';
import { Skeleton } from '@mui/material';
import { useCallback, useMemo } from 'react';
import ApexChart from 'react-apexcharts';

import { content } from '../../../../content';
import { customFormat } from '../collapsed-chart/chart-constants';
import { useChartData } from '../collapsed-chart/use-chart-data.hook';
import { AnnotationTooltipWrapper } from './annotations/annotation-tooltip.component';
import { useEnabledAutomationRulesHistory } from './automations/use-automation.hook';
import { useExtendedChartContext } from './context/extended-chart.context';
import { useGroupedAlerts } from './hooks/group-alerts/use-grouped-alerts.hook';
import { useMainChartOptions } from './hooks/use-main-chart-options.hook';
import { useMainChart } from './hooks/use-main-chart.hook';
import { useStyles } from './main-chart.styles';

const chartHeight = '100%';

export const MainChart = (): JSX.Element | null => {
  const { deviceName, deviceType, timeZoneAbbr, manufacturerId, automations, automationsSettings, selection, uoM } =
    useExtendedChartContext();

  const { data, alertData, isLoading, ticks, isFetching } = useMainChart(selection, deviceType, manufacturerId);

  const { groupedAlertData, groupedAlertsAnnotations, groupedAlertOptions, groupedAlerts } = useGroupedAlerts(
    alertData,
    data,
    ticks
  );

  const { classes } = useStyles({ isFetching });

  const {
    automationRulesSeries,
    automationRulesSeriesOptions,
    automationRulesSeriesAnnotation,
    currentAnnotationTooltip,
  } = useEnabledAutomationRulesHistory(automationsSettings, selection, ticks, automations, uoM);

  const filterAlerts = useCallback(
    (alert: { ruleId: string }) =>
      automationsSettings.alerts ? automationsSettings.automations?.includes(alert.ruleId) : false,
    [automationsSettings.alerts, automationsSettings.automations]
  );

  const validAlertData = useMemo(() => groupedAlertData.filter(filterAlerts), [filterAlerts, groupedAlertData]);

  const validAlertAnnotations = useMemo(
    () => groupedAlertsAnnotations.filter(filterAlerts),
    [filterAlerts, groupedAlertsAnnotations]
  );

  const { mainChartOptions } = useMainChartOptions({
    automations,
    automationsSettings,
    selection,
    deviceType,
    deviceName,
    data,
    ticks,
    timeZoneAbbr,
    automationRulesSeriesOptions,
    automationRulesSeriesAnnotation,
    groupedAlertOptions,
    groupedAlertsAnnotations: validAlertAnnotations,
    groupedAlerts,
    uoM,
  });

  const apexData = useChartData(data, deviceType, 'data');

  const chartData = useMemo(
    () => [...apexData, ...automationRulesSeries, ...validAlertData],
    [apexData, automationRulesSeries, validAlertData]
  );

  if (!manufacturerId || !deviceType) {
    return null;
  }

  const isDataEmpty = data.length <= 2 && data.every(({ value }) => value === null);

  return (
    <div className={classes.container}>
      {/* NOTE: rangeArea is missing in ApexChart types, but it is required to work correctly */}
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <ApexChart
        {...{
          type: 'rangeArea',
        }}
        series={chartData}
        options={mainChartOptions}
        data-testid="main-chart"
        className={classes.chart}
        height={chartHeight}
      />
      {currentAnnotationTooltip && <AnnotationTooltipWrapper {...currentAnnotationTooltip} />}
      {isLoading && (
        <div className={classes.overlay}>
          {isLoading && <Skeleton variant="rectangular" height={chartHeight} width="100%" />}
        </div>
      )}
      {!isFetching && isDataEmpty && (
        <div className={classes.noData} data-testid="main-chart-no-data">
          <div className={classes.noDataInfo}>
            <div className={classes.dot} />
            {content.NO_DATA_ON_MAINCHART}
          </div>
          <div className={classes.range}>
            {content.NO_DATA_ON_MAINCHART_RANGE(
              formatTimestamp(selection?.from, customFormat),
              formatTimestamp(selection?.to, customFormat)
            )}
          </div>
        </div>
      )}
    </div>
  );
};
