import { useMediaQuery, useTheme } from '@mui/material';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';

import { useStyles } from './onboarding-wizard.styles';
import { steps } from './steps/steps';
import { IOnboardingWizard } from './use-onboarding-wizard.hook';

export const OnboardingWizard = ({
  isShowingErrorStep,
  ErrorStep,
  handleButtonClick,
  activeStep,
  currentStep,
  completedSteps,
}: IOnboardingWizard) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { classes } = useStyles();

  if (isShowingErrorStep && ErrorStep) {
    return (
      <div className={classes.container}>
        <ErrorStep handleButtonClick={handleButtonClick} />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <Stepper className={classes.stepperWrapper} activeStep={activeStep} alternativeLabel nonLinear>
        {steps.map(({ id, label, index }) => (
          <Step key={id} completed={completedSteps.includes(index)} data-testid={`step-${id}`}>
            <StepLabel
              data-testid={`step-${id}-label`}
              classes={{
                label: classes.stepLabel,
              }}
            >
              {isMobile ? (index === activeStep ? label : '') : label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      {currentStep}
    </div>
  );
};
