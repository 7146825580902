import isString from 'lodash/isString';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { HttpParams } from '../params';
import { IConfigService } from './config.service';
import { IHttpParams, IProxy } from './endpoint.models';
import { getFullUrl, replaceParams } from './utils';

export interface IOption {
  fullUrl?: boolean;
  skipProxy?: boolean;
}

export interface IEndpointService {
  getUrl(url: string, params?: IHttpParams | { [key: string]: string }, options?: IOption): Observable<string>;
}

export class EndpointService implements IEndpointService {
  private readonly baseDomain: string;
  private readonly appBaseHref: string;
  private readonly useHash: boolean = false;

  public constructor(private configService: IConfigService) {
    this.baseDomain = this.configService.config$.value.baseDomain;
    this.appBaseHref = this.configService.config$.value.baseHref;
    this.useHash = !!this.configService.config$.value.useHash;
  }

  public getUrl = (
    url: string,
    params?: IHttpParams | { [key: string]: string },
    { fullUrl = true, skipProxy = false }: IOption = {}
  ): Observable<string> => {
    let httpParams: HttpParams = params as HttpParams;

    if (httpParams && !(httpParams instanceof HttpParams)) {
      httpParams = new HttpParams({ fromObject: httpParams });
    }

    const parsedUrl = replaceParams(fullUrl ? this.getFullUrl(url) : this.getUrlWithoutAppBaseHref(url), httpParams);
    if (skipProxy) {
      return of(parsedUrl);
    }

    return this.configService.get().pipe(
      take(1),
      map((config): string => this.getUrlWithProxy(config.proxyConfig, parsedUrl))
    );
  };

  private getFullUrl = (url: string): string => getFullUrl(this.baseDomain, url, this.useHash);

  private getUrlWithProxy = (proxyConfig: IProxy | undefined, url: string): string => {
    if (!proxyConfig) {
      return url;
    }

    let apiUrl: null | string = null;

    Object.keys(proxyConfig).forEach((key): void => {
      const proxy = proxyConfig[key];
      const target: string = isString(proxy) ? proxy : proxy.target;

      const replace = isString(proxy) ? false : proxy.replace;
      if (apiUrl === null && url.startsWith(key)) {
        const proxyTrailingSlash = target.endsWith('/') ? '' : '/';
        if (replace) {
          url = url.replace(key, '');
        }
        url = url.startsWith('/') ? url.slice(1) : url;
        apiUrl = `${target}${proxyTrailingSlash}${url}`;
      }
    });

    return apiUrl !== null ? apiUrl : url;
  };

  private getUrlWithoutAppBaseHref = (url: string): string => {
    if (!url || !this.appBaseHref.length || this.appBaseHref === '/') {
      return url;
    }

    const urlHasTrailingSlash = url.endsWith('/');
    const appBaseHrefHasLeadingSlash = this.appBaseHref.startsWith('/');
    const appBaseHrefHasTrailingSlash = this.appBaseHref.endsWith('/');
    const appBaseHrefWithLeadingSlash = appBaseHrefHasLeadingSlash ? this.appBaseHref : `/${this.appBaseHref}`;
    const appBaseHrefWithoutLeadingSlash = appBaseHrefHasLeadingSlash ? this.appBaseHref.slice(1) : this.appBaseHref;

    if (url.startsWith(appBaseHrefWithLeadingSlash)) {
      if (urlHasTrailingSlash && appBaseHrefHasTrailingSlash) {
        return `${url.slice(appBaseHrefWithLeadingSlash.length)}/`;
      }

      return url.slice(appBaseHrefWithLeadingSlash.length);
    }

    if (url.startsWith(appBaseHrefWithoutLeadingSlash)) {
      if (urlHasTrailingSlash && appBaseHrefHasTrailingSlash) {
        return `${url.slice(appBaseHrefWithoutLeadingSlash.length)}/`;
      }

      return url.slice(appBaseHrefWithoutLeadingSlash.length);
    }

    return url;
  };
}
