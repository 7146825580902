import { getHttpClient, queryClient } from '@marlin/shared/utils/react-query';
import { useMutation } from '@marlin/shared/utils/react-query';
import { TEquipment } from '@marlin/shared/utils/zod';
import { z } from 'zod';

import { paths } from '../infrastructure/api';
import { queryKey } from './query-key.enum';

export const deleteEquipment = ({ equipmentId }: { equipmentId: string }) => {
  return getHttpClient().delete(paths.EQUIPMENT, { params: { equipmentId } });
};

interface IUseDeleteEquipment {
  onSuccess?: () => void;
  onError?: () => void;
}

export const useDeleteEquipment = ({ onSuccess, onError }: IUseDeleteEquipment) => {
  return useMutation<unknown, unknown, { equipmentId: string }, { previousEquipments: TEquipment[] | undefined }>({
    onMutate: async (deletedEquipment: { equipmentId: string }) => {
      await queryClient.cancelQueries({ queryKey: queryKey.EQUIPMENTS() });

      const previousEquipments = queryClient.getQueryData<TEquipment[]>(queryKey.EQUIPMENTS());

      queryClient.setQueryData(
        queryKey.EQUIPMENTS(),
        previousEquipments?.filter((equipment: TEquipment) => equipment.id !== deletedEquipment.equipmentId)
      );

      return { previousEquipments };
    },
    onError: (error, _, context) => {
      // eslint-disable-next-line no-console
      if (error instanceof z.ZodError) console.log(error.issues);

      if (context?.previousEquipments) {
        queryClient.setQueryData(queryKey.EQUIPMENTS(), context.previousEquipments);
      }

      onError && onError();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.EQUIPMENTS() });
      onSuccess && onSuccess();
    },
    mutationFn: deleteEquipment,
  });
};
