import { content } from '../content';
import { IPlantConfig } from '../context/plant-config.context';

const activeSetpointDatapointName = 'ActiveSetpointTemp';

export const waterHeaterConfig: IPlantConfig = {
  plantListTitle: content.PLANT_LIST.HEADER_WATER_HEATER,
  title: content.WATER_HEATER_TITLE,
  subtitle: content.SUBTITLE,
  overviewTitle: content.OVERVIEW_TITLE,
  managerDatapointName: 'AutoManagerAddr',
  backupManagerDatapointName: 'BackupManagerAddr',
  performance: [
    { name: activeSetpointDatapointName, label: content.DATAPOINT_LABELS.SETPOINT_TEMP },
    {
      name: 'OutletTemp',
      label: content.DATAPOINT_LABELS.OUTLET_TEMP,
    },
    {
      name: 'InletTemp',
      label: content.DATAPOINT_LABELS.INLET,
    },
  ],
  chartData: {
    datapointNames: [activeSetpointDatapointName, 'OutletTemp'],
    datapointGroupNames: ['temperature'],
  },
  activeSetpointDatapointName,
};
