import { z } from 'zod';

export const AvailableBoliers = z.object({
  name: z.string(),
  value: z.string(),
});
export type TAvailableBoliers = z.infer<typeof AvailableBoliers>;

export const AvailableBoliersResponse = z.object({
  datapoints: z.array(AvailableBoliers),
});
export type TAvailableBoliersResponse = z.infer<typeof AvailableBoliersResponse>;
