import { TVolumeSchema } from '@marlin/asset/data-access/home-dashboard';
import { getUnitType, parseDeviceReadings } from '@marlin/shared/utils-format-reading';
import { Typography } from '@mui/material';
import { useMemo } from 'react';

import { ArrowIndicator } from '../arrow-indicator/arrow-indicator.component';
import { Average } from '../average/average.component';
import { getCorrectUnit, transformBigNumber } from '../utils';
import { content } from './content';
import { useStyles } from './total-section.styles';

interface ITotalSectionProps {
  variant: 'WEEK' | 'MONTH' | 'YEAR';
  volume?: TVolumeSchema | null;
}

export const TotalSection = ({ variant, volume }: ITotalSectionProps) => {
  const { classes } = useStyles();

  const trendPeriod = useMemo(() => {
    switch (variant) {
      case 'WEEK': {
        return content.WEEK;
      }
      case 'MONTH': {
        return content.MONTH;
      }
      case 'YEAR': {
        return content.YEAR;
      }
      default:
        return null;
    }
  }, [variant]);

  return (
    <div className={classes.container} data-testid={`${variant.toLowerCase()}-total-section`}>
      <div className={classes.header}>
        <div>
          <Typography className={classes.periodText} data-testid={`${variant.toLowerCase()}-period-text`}>
            {trendPeriod}
          </Typography>
        </div>
        {variant !== 'YEAR' && (
          <div>
            <ArrowIndicator value={volume?.trend} isExpanded />
          </div>
        )}
      </div>
      <div className={classes.content}>
        {variant !== 'YEAR' ? (
          <>
            <div>
              <Typography className={classes.volumeText} data-testid={`${variant.toLowerCase()}-volume-text`}>
                {content.VALUE_TOTAL(
                  transformBigNumber(
                    parseDeviceReadings(volume?.totalVolume ?? 0, 'PULSE_METER', 'integer').value ?? 0
                  ),
                  getCorrectUnit(getUnitType('PULSE_METER'))
                )}
              </Typography>
            </div>
            <div className={classes.trendContainer}>
              <Typography className={classes.trendProp} data-testid={`${variant.toLowerCase()}-running-trend-text`}>
                {content.RUNNING_TREND}
              </Typography>
              <Typography className={classes.trendValue} data-testid={`${variant.toLowerCase()}-trend-value`}>
                {content.VALUE_TREND(
                  transformBigNumber(parseDeviceReadings(volume?.trend, 'PULSE_METER', 'integer').value ?? 0)
                )}
              </Typography>
            </div>
            <Average averageVolume={volume?.averageVolume} variant={variant} isExpanded />
          </>
        ) : (
          <div className={classes.yearWrapper}>
            <div>
              <Typography className={classes.volumeText} data-testid={`${variant.toLowerCase()}-volume-text`}>
                {content.VALUE_TOTAL(
                  transformBigNumber(
                    parseDeviceReadings(volume?.totalVolume ?? 0, 'PULSE_METER', 'integer').value ?? 0
                  ),
                  getCorrectUnit(getUnitType('PULSE_METER'))
                )}
              </Typography>
            </div>
            <div className={classes.trendContainer}>
              <Typography className={classes.infoText} data-testid={`${variant.toLowerCase()}-info-text`}>
                {content.YEAR_INFO_TEXT}
              </Typography>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
