import { MarlinTheme } from '@marlin/shared/theme';
import { Box, Paper, Popper, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { IAnnotation } from './types';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  tooltip: {
    backgroundColor: theme.palette.alternative.dark,
    padding: `${theme.typography.pxToRem(4)} ${theme.typography.pxToRem(8)}`,
    borderRadius: theme.typography.pxToRem(4),
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.alternative.contrastText}`,
  },
  reading: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.alternative.contrastText,
    lineHeight: theme.typography.pxToRem(20),
    textAlign: 'center',
  },
  name: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(10),
    color: theme.palette.alternative.contrastText,
    lineHeight: theme.typography.pxToRem(14),
    textAlign: 'center',
  },
  popper: {
    zIndex: 2000,
  },
}));

const popperModifiers = [
  {
    name: 'flip',
    enabled: false,
    options: {
      altBoundary: true,
      rootBoundary: 'viewport',
      padding: 8,
    },
  },
  {
    name: 'preventOverflow',
    enabled: true,
    options: {
      altAxis: true,
      altBoundary: true,
      tether: false,
      rootBoundary: 'viewport',
      padding: 8,
    },
  },
  {
    name: 'offset',
    enabled: true,
    options: {
      offset: [0, 4],
    },
  },
];

export const AnnotationTooltip = ({ formattedValue, name, currentAnnotation }: IAnnotation): JSX.Element | null => {
  const { classes } = useStyles();

  return (
    <div>
      <Popper
        open={!!currentAnnotation}
        anchorEl={currentAnnotation}
        placement="left"
        className={classes.popper}
        modifiers={popperModifiers}
      >
        <Paper
          sx={{
            boxShadow: 'none',
          }}
        >
          <Paper>
            <Box className={classes.tooltip}>
              <Typography variant="body2" className={classes.reading}>
                {formattedValue}
              </Typography>
              <Typography variant="body2" className={classes.name}>
                {name}
              </Typography>
            </Box>
          </Paper>
        </Paper>
      </Popper>
    </div>
  );
};
