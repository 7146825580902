import { MarlinTheme } from '@marlin/shared/theme';
import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import React, { useCallback, useContext } from 'react';
import { makeStyles } from 'tss-react/mui';

import {
  TUpsertConnectionFormSchemaType,
  TUpsertConnectionFormSchemaTypeUpdate,
} from '../../components/form/upsert-connection-form.schema';
import { FooterModal } from '../../components/test-modal/footer-modal.component';
import { content } from '../../content';
import { TAddressErrors, TEST_GATEWAY_STEPS, useTestGatewayContext } from '../../context/test-flow.context';

interface IModalTestGateway {
  deviceId: string;
  configurationId: string;
  data: TUpsertConnectionFormSchemaType | TUpsertConnectionFormSchemaTypeUpdate;
  isTestOnly: boolean;
  refetch: () => void;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  body: {
    marginBottom: theme.typography.pxToRem(16),
  },
}));

interface IUseTestGatewayModal {
  setAddressErrors: React.Dispatch<React.SetStateAction<TAddressErrors | null>>;
}

export const useTestGatewayModal = ({ setAddressErrors }: IUseTestGatewayModal) => {
  const { modalDispatch } = useContext(ModalContext);
  const { setStep, setOperations, setConnections } = useTestGatewayContext();
  const { classes } = useStyles();

  const handleCloseModal = useCallback(() => {
    modalDispatch({
      type: MODAL_ACTION_TYPE.DISMISS,
    });
  }, [modalDispatch]);

  const modalTestGateway = ({ deviceId, configurationId, data, isTestOnly, refetch }: IModalTestGateway) => {
    return {
      title: <div>{content.TEST_MODAL.TITLE}</div>,
      body: (
        <div>
          {!isTestOnly && <div>{content.TEST_MODAL.BODY_TEXT_TEST}</div>}
          <div>{content.TEST_MODAL.BODY_TEXT}</div>
          <div className={classes.body}>{content.TEST_MODAL.BODY_TEXT_QUESTION}</div>
          <div>{content.TEST_MODAL.BODY_SUBTEXT}</div>
        </div>
      ),
      footer: (
        <FooterModal
          redirectToTestPage={() => setStep(TEST_GATEWAY_STEPS.TEST_RESULTS)}
          onClose={handleCloseModal}
          setOperations={setOperations}
          setConnections={setConnections}
          isTestOnly={isTestOnly}
          deviceId={deviceId}
          refetch={refetch}
          setAddressErrors={setAddressErrors}
          data={data}
          configurationId={configurationId}
        />
      ),
    };
  };

  const testGateway = ({
    deviceId,
    configurationId,
    data,
    isTestOnly,
    refetch,
  }: {
    deviceId: string;
    configurationId: string;
    data: TUpsertConnectionFormSchemaType | TUpsertConnectionFormSchemaTypeUpdate;
    isTestOnly: boolean;
    refetch: () => void;
  }) => {
    modalDispatch({
      type: MODAL_ACTION_TYPE.SHOW,
      payload: modalTestGateway({ deviceId, configurationId, data, isTestOnly, refetch }),
    });
  };

  return { testGateway };
};
