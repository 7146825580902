import { useCurrentUser } from '@marlin/account-data-access-organization';
import { useMetadata } from '@marlin/asset/data-access/equipment';
import { useGatewaySettings } from '@marlin/asset/data-access/gateway';
import { mergeSettingsDatapointsWithMetadata } from '@marlin/asset/shared/utils/datapoint-mappers';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { DeviceUserMeasuresPreferences } from '@marlin/shared/utils-format-reading';
import { useSearchParams } from '@marlin/shared/utils-router';
import { useCallback, useMemo } from 'react';

import { content } from '../content';
import { useIsConfigDataCurrent } from './use-config-data.hook';

interface IUseGatewaySettings {
  gatewayId: string;
}

export const useGatewaySettingsDatapoints = ({ gatewayId }: IUseGatewaySettings) => {
  const { enqueueSnackbar } = useSnackbar();
  const [, setSearchParams] = useSearchParams();
  const { data: { settings = {} } = {} } = useCurrentUser();

  const onError = useCallback(() => {
    enqueueSnackbar(content.ERROR, { variant: 'error', preventDuplicate: true });

    setSearchParams((currentParams) => {
      if (currentParams.has('settingGroupId')) {
        currentParams.delete('settingGroupId');
      }
      return currentParams;
    });
  }, [enqueueSnackbar, setSearchParams]);

  const {
    data: metadataResponse,
    isLoading: isMetadataLoading,
    isError: isMetadataError,
    refetch: refetchMetadata,
  } = useMetadata({ equipmentId: gatewayId });
  const {
    data: settingsResponse,
    isFetching: isGatewaySettingsLoading,
    isError: isGatewaySettingsError,
    refetch: refetchGatewaySettings,
  } = useGatewaySettings({
    gatewayId,
    onError,
  });

  const mergedSettingsDatapointsWithMetadata = useMemo(
    () =>
      mergeSettingsDatapointsWithMetadata(
        metadataResponse?.datapoints,
        settingsResponse?.datapoints,
        DeviceUserMeasuresPreferences.parse(settings)
      ),
    [metadataResponse, settingsResponse, settings]
  );

  const refetch = useCallback(() => {
    refetchMetadata();
    refetchGatewaySettings();
  }, [refetchMetadata, refetchGatewaySettings]);

  const { outdatedDatapointNames, isCurrent } = useIsConfigDataCurrent(mergedSettingsDatapointsWithMetadata);

  const settingsDatapointsWithOutdated = isCurrent
    ? mergedSettingsDatapointsWithMetadata
    : mergedSettingsDatapointsWithMetadata.map((datapoint) => ({
        ...datapoint,
        controlState: outdatedDatapointNames?.includes(datapoint.name) ? 'outdated' : datapoint.controlState,
      }));

  return {
    data: settingsDatapointsWithOutdated,
    isMetadataLoading,
    isGatewaySettingsLoading,
    isError: isMetadataError || isGatewaySettingsError,
    refetch,
    isCurrent,
  };
};
