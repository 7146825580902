import { IChartSeries, TChartType } from '@marlin/shared/utils-chart';
import { useMemo } from 'react';

import { getTotalVolume } from '../utils/get-total-volume';

interface IUseTotalVolume {
  chartData: IChartSeries[];
  chartType: TChartType;
}

export const useTotalVolume = ({ chartData, chartType }: IUseTotalVolume) => {
  return useMemo(() => {
    return getTotalVolume({ chartData, chartType });
  }, [chartData, chartType]);
};
