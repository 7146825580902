import { useFormContext } from 'react-hook-form';

import { IAsset } from '../../../desktop/components/grid/types';
import { ILocationDetailsFilters } from '../../location-details.component';
import { useMobileLocationDetails } from '../../use-location-details.hook';
import { AssetList } from './asset-list.component';

interface IFilteredAssetListProps {
  allowDelete: boolean;
  allowEdit: boolean;
  handleAfterDelete: () => void;
  editAsset: (asset: IAsset) => void;
}

const refetchOnMount = false;

export const FilteredAssetList = ({
  editAsset,
  allowDelete,
  allowEdit,
  handleAfterDelete,
}: IFilteredAssetListProps) => {
  const { watch } = useFormContext<ILocationDetailsFilters>();
  const { locations, deviceTypes, assetTypes } = watch();

  const { deviceDrawerRows, isListLoading, setMobileFilters, refetch, hasNextPage, fetchNextPage, rows, totalItems } =
    useMobileLocationDetails(
      {
        subLocationIds: locations?.map((item) => item.id),
        deviceTypes,
        types: assetTypes?.length
          ? deviceTypes && deviceTypes?.length > 0
            ? ['DEVICE', ...assetTypes]
            : assetTypes
          : undefined,
      },
      refetchOnMount
    );

  return (
    <AssetList
      rows={rows}
      allowDelete={allowDelete}
      allowEdit={allowEdit}
      handleAfterDelete={handleAfterDelete}
      editAsset={editAsset}
      refetch={refetch}
      setMobileFilters={setMobileFilters}
      totalItems={totalItems}
      hasNextPage={hasNextPage}
      fetchNextPage={fetchNextPage}
      isListLoading={isListLoading}
      deviceDrawerRows={deviceDrawerRows}
    />
  );
};
