import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import { paths } from './api';
import { TManufacturerType, manufacturerInfoResponseSchema } from './schema/manufacturer.schema';

export const getManufacturerData = async ({
  manufacturerId,
}: {
  manufacturerId: string;
}): Promise<TManufacturerType> => {
  const response = await getHttpClient().get(paths.GET_MANUFACTURER_DATA, { params: { manufacturerId } });
  return safeParseData(response, manufacturerInfoResponseSchema);
};
