import { ActionDelete, ActionEdit, ContextMenu } from '@marlin/shared/ui-context-menu';
import { Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';

import { content } from './content';
import { CellName } from './flow-link-cell-name';
import { CellType } from './flow-link-cell-type';
import { IFlowLinkRow } from './flow-link-list.component';

export const createColumns = ({
  columnProps,
  removeFlowLink,
  editFlowLink,
  arrowClassName,
  allowDelete,
  allowEdit,
}: {
  columnProps: Partial<GridColDef>;
  arrowClassName: string;
  removeFlowLink: (id: string) => void;
  editFlowLink: (flowLink: IFlowLinkRow) => void;
  allowDelete: boolean;
  allowEdit: boolean;
}): GridColDef[] => [
  {
    field: 'typeOutlet',
    sortable: false,
    headerName: content.FLOW_LINK_OUTLET_TYPE_TABLE_HEADER_NAME,
    renderCell: (cellValues) => {
      return (
        <CellType
          nodeType={cellValues.row.nodeTypeOutlet}
          assetType={cellValues.row.typeOutlet}
          deviceType={cellValues.row.deviceTypeOutlet}
          isAttachment={cellValues.row.isAttachment}
        />
      );
    },
    flex: 1,
    ...columnProps,
  },
  {
    field: 'nameOutlet',
    sortable: false,
    headerName: content.FLOW_LINK_OUTLET_NAME_TABLE_HEADER_NAME,
    renderCell: (cellValues) => {
      return (
        <CellName
          assetId={cellValues.row.outletLinkedId ?? cellValues.row.idOutlet}
          name={cellValues.row.nameOutlet}
          assetType={cellValues.row.typeOutlet}
        />
      );
    },
    flex: 1,
    ...columnProps,
  },
  {
    field: 'arrows',
    sortable: false,
    headerName: '',
    renderCell: () => {
      return (
        <Typography variant="body2" component="h2">
          {content.ARROWS}
        </Typography>
      );
    },
    cellClassName: arrowClassName,
    align: 'center',
    width: 70,
    ...columnProps,
  },
  {
    field: 'typeInlet',
    sortable: false,
    headerName: content.FLOW_LINK_INLET_TYPE_TABLE_HEADER_NAME,
    renderCell: (cellValues) => {
      return (
        <CellType
          nodeType={cellValues.row.nodeTypeInlet}
          assetType={cellValues.row.typeInlet}
          deviceType={cellValues.row.deviceTypeInlet}
          isAttachment={cellValues.row.isAttachment}
        />
      );
    },
    flex: 1,
    ...columnProps,
  },
  {
    field: 'nameInlet',
    sortable: false,
    headerName: content.FLOW_LINK_INLET_NAME_TABLE_HEADER_NAME,
    renderCell: (cellValues) => {
      return (
        <CellName
          assetId={cellValues.row.inletLinkedId ?? cellValues.row.idInlet}
          name={cellValues.row.nameInlet}
          assetType={cellValues.row.typeInlet}
        />
      );
    },
    flex: 1,
    ...columnProps,
  },
  {
    field: 'action',
    headerName: '',
    sortable: false,
    hideable: false,
    filterable: false,
    flex: 0.5,
    align: 'center',
    ...columnProps,
    renderCell: (cellValues) => {
      const row = cellValues.row;
      const isRowWithTee = row.nodeTypeInlet === 'TEE' || row.nodeTypeOutlet === 'TEE';
      if (isRowWithTee) {
        return null;
      }
      return (
        <ContextMenu>
          {allowEdit && <ActionEdit onClick={() => editFlowLink(cellValues.row)} />}
          {allowDelete && <ActionDelete onClick={() => removeFlowLink(cellValues.row.id)} />}
        </ContextMenu>
      );
    },
  },
];
