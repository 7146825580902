import { datapointToUom } from '@marlin/shared/utils-format-reading';
import { TDatapointWithUiMetadata } from '@marlin/shared/utils/datapoint-mappers';
import { useMemo } from 'react';

import { useDatapointsContext } from '../../context/datapoints.context';
import type { TEquipmentProductDatapoint } from '../../utils/types';

export const useDatapointTiles = (productDatapoints?: Array<TEquipmentProductDatapoint>) => {
  const { datapoints, isLoading } = useDatapointsContext();
  const datapointTilesData: TDatapointWithUiMetadata[] = useMemo(() => {
    return (productDatapoints || [])
      .map((productDatapoint) => {
        const datapointValues: TDatapointWithUiMetadata | undefined = datapoints.find(
          (datapoint) => productDatapoint.name.toLowerCase() === datapoint.name.toLowerCase()
        );

        if (datapointValues) {
          return {
            ...datapointValues,
            ...productDatapoint,
            unitOfMeasure:
              productDatapoint.unitOfMeasure || datapointValues.unitOfMeasure || datapointToUom(datapointValues.name),
          };
        }

        return null;
      })
      .filter(Boolean) as TDatapointWithUiMetadata[];
  }, [datapoints, productDatapoints]);

  return { datapointTilesData, isLoading };
};
