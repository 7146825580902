import {
  TDashboardDevice,
  TDashboardHealthSystemSchema,
  TDashboardSystemSchema,
} from '@marlin/asset/data-access/home-dashboard';

import { CRITICALITY } from './types';
import { getBatteriesCriticality } from './utils';

interface IPerformanceDashboardStatus {
  performance: TDashboardSystemSchema;
}

export const usePerformanceDashboardStatus = ({ performance }: IPerformanceDashboardStatus) => {
  const isSystemOperational = performance.equipment.highAlertsCount + performance.sensors.highAlertsCount === 0;
  const isSystemWithLowAlerts = performance.equipment.lowAlertsCount + performance.sensors.lowAlertsCount > 0;
  const isSystemWithHighAlerts = performance.equipment.highAlertsCount + performance.sensors.highAlertsCount > 0;

  return {
    isSystemOperational,
    isSystemWithLowAlerts,
    isSystemWithHighAlerts,
  };
};

interface IDevicesDashboardStatus {
  health: TDashboardHealthSystemSchema;
  devices: TDashboardDevice[];
}

export const useHealthDashboardStatus = ({ health, devices }: IDevicesDashboardStatus) => {
  const batteriesCriticality = getBatteriesCriticality(devices);

  const checkCommunication = health.gateways.lostCommunicationCount > 0 || health.sensors.lostCommunicationCount > 1;
  const checkErrors = health.equipment.deviceErrorsCount > 0;
  const performSystemMaintenance =
    health.sensors.lostCommunicationCount > 0 ||
    batteriesCriticality !== CRITICALITY.NO ||
    health.equipment.lostCommunicationCount > 0;

  return {
    checkCommunication,
    checkErrors,
    performSystemMaintenance,
    batteriesCriticality,
  };
};
