import { navStorageService } from '@marlin/shared/utils-common-desktop-sidenav';
import { getLogger } from '@marlin/shared/utils/logger';
import { useEffect, useState } from 'react';
import { lastValueFrom } from 'rxjs';

import { getAuthService } from './auth.module';

interface IAuth {
  authorised: boolean;
  logOut: (path?: string) => void;
  token?: string;
}

export const useAuth = (): IAuth => {
  const [authorised, setAuthorised] = useState(false);
  const [token, setToken] = useState('');

  useEffect(() => {
    const authService = getAuthService();

    if (!authService) {
      return;
    }

    (async () => {
      const data = await lastValueFrom(authService.getAccessToken());

      setToken(data || '');
    })();

    const subscription = authService.initLoginFlow().subscribe(
      (isAuthorized: boolean): void => {
        setAuthorised(isAuthorized);
      },
      (): void => {
        setAuthorised(false);
        authService.logOut();
      }
    );
    return (): void => subscription?.unsubscribe();
  }, []);

  return {
    authorised,
    logOut: (path?: string) => {
      navStorageService.set('');
      getLogger()?.reset();
      return getAuthService()?.logOut(path);
    },
    token,
  };
};
