import { ILogger } from './logger.types';

let logger: ILogger;

export const initLogger = (newLogger: ILogger): void => {
  logger = newLogger;
};

export const getLogger = (): ILogger | undefined => {
  if (!logger) {
    // eslint-disable-next-line no-console
    console.warn('[LOGGER] Logger is not initialized');
  }
  return logger;
};
