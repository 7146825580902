import { MarlinTheme } from '@marlin/shared/theme';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { Divider } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface IFormGroupHeaderProps {
  title: string;
  prefix: string;
  tooltip?: string;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    marginBottom: theme.typography.pxToRem(24),
  },

  title: {
    fontSize: theme.typography.pxToRem(24),
    marginTop: 0,
    marginBottom: theme.typography.pxToRem(16),
  },

  tooltip: {
    marginRight: theme.typography.pxToRem(8),
  },
}));

export function FormGroupHeader({ title, prefix, tooltip }: IFormGroupHeaderProps): JSX.Element {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <h5 data-testid={`${prefix}-title`} id={`${prefix}-title`} className={classes.title}>
        {title}
        {tooltip && (
          <span className={classes.tooltip}>
            <Tooltip text={tooltip} />
          </span>
        )}
      </h5>
      <Divider />
    </div>
  );
}
