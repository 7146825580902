export const content = {
  DELETE_LOCATION: 'Delete Location?',
  REMOVE_ATTACHED_ASSETS: 'Remove attached assets',
  DELETE_LOCATION_PARENT: `This location is a parent location to another location and cannot be deleted until it is unlinked to the child location. Please visit the Child location's details and remove/edit the Parent Location field before deleting.`,
  DELETE_LOCATION_ASSETS: `You must remove the asset(s) attached to this location before you can delete`,
  CANCEL: 'Cancel',
  DELETE: 'Delete',
  GO_BACK: 'Go Back',
  ACTION_CAN_NOT_BE_UNDONE: 'This action cannot be undone.',
  DELETE_LOCATION_CONFIRMATION: (assetName: string) => (
    <div>
      Are you sure you want to delete <span style={{ fontWeight: 'bold' }}>{assetName}?</span>
    </div>
  ),
  FLOW_LINKS_INFO: 'The following flow link attachments will also be deleted.',
  FLOW_LINKS_CONFIRMATION: 'Are you sure you want to delete this location and all of its flow link attachments?',
  FLOW_LINKS_TABLE: 'Flow Link Attachments',
  DELETE_LOCATION_ERROR: 'Location deletion failed, please try again.',
  DELETE_LOCATION_SUCCESS: 'Your location has been deleted',
};
