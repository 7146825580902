export const content = {
  TITLE_CURRENT: (total: number) => `Current Alerts (${total})`,
  TITLE_RESOLVED: (total: number) => `Resolved Alerts (${total})`,
  TITLE_SNOOZED: (total: number) => `Snoozed Alerts (${total})`,

  CARD: {
    TIME_START: 'Start Time',
    TIME_RESOLVED: 'Resolved Time',
    TIME_SNOOZED: 'snoozed until',
    EMAIL: 'Emailed',
    TEXT: 'Texted',

    RECIPIENTS: {
      UNKNOWN: 'Unknown',
    },
  },
  BUTTON_LOAD_MORE: 'Load more alerts',
  CANCEL_SNOOZE_SUCCESS: (automationName: string) => `Snooze for ${automationName} have been successfully canceled`, //TODO - NOT OFFICAL COPY
  CANCEL_SNOOZE_MESSAGE_SYSTEM: (automationName: string, sensorName: string) =>
    `You are removing the snooze that was set for ${sensorName} for ${automationName} automation`,
  CANCEL_SNOOZE_ERROR: 'Error during canceling snooze.', //TODO - NOT OFFICAL COPY
  SNOOZE_SUCCESS: (automationName: string, hours: string, hourText: string) =>
    `Alert notification will be snoozed for Automation ${automationName} for ${hours} ${hourText}`,
  SNOOZE_SYSTEM_SUCCESS: (automationName: string, hours: string, hourText: string, sensorName: string) =>
    `Alert notification will be snoozed for ${sensorName} for Automation ${automationName} for ${hours} ${hourText}`,
  SNOOZE_ERROR: 'Error during snooze automation.', //TODO - NOT OFFICAL COPY
  HOURS: 'hours',
  HOUR: 'hour',
};
