import { DateSchema, createDateString, createIsoStringDate, isBefore } from '@marlin/shared/utils-common-date';
import { z } from 'zod';

import { reduceAutomationHistoryToRulesHistory } from './utils';

export const automationHistoryParamsSchema = z
  .object({
    deviceId: z.string(),
    from: DateSchema.transform(createIsoStringDate),
    to: DateSchema.transform(createIsoStringDate),
  })
  .refine(({ from, to }) => {
    return isBefore(createDateString(from), createDateString(to));
  }, 'From date should be less than to date');

export type TAutomationHistoryParams = z.infer<typeof automationHistoryParamsSchema>;

export const automationHistorySchema = z.object({
  ruleId: z.string(),
  from: DateSchema,
  to: DateSchema,
  isEnabled: z.boolean(),
  thresholdMin: z.number().nullish(),
  thresholdMax: z.number().nullish(),
  deadbandMin: z.number().nullish(),
  deadbandMax: z.number().nullish(),
});

export type TAutomationHistory = z.infer<typeof automationHistorySchema>;

export const automationHistoryListSchema = z
  .array(automationHistorySchema)
  .default([])
  .transform(reduceAutomationHistoryToRulesHistory);

export type TAutomationHistoryList = z.infer<typeof automationHistoryListSchema>;
