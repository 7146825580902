import { SelectChangeEvent } from '@mui/material/Select';
import { useCallback, useMemo } from 'react';

import { AUTOMATION_STATUS } from '../types';

export const useAutomationStatus = (
  onChange: (value: boolean | null) => void,
  isEnabled: boolean | null
): {
  changeStatus: (event: SelectChangeEvent<AUTOMATION_STATUS>) => void;
  status: AUTOMATION_STATUS;
} => {
  const changeStatus = useCallback(
    (event: SelectChangeEvent<AUTOMATION_STATUS>) => {
      const {
        target: { value },
      } = event;
      let filterValue: boolean | null = null;
      switch (value) {
        case AUTOMATION_STATUS.ON: {
          filterValue = true;
          break;
        }
        case AUTOMATION_STATUS.OFF: {
          filterValue = false;
          break;
        }
        case AUTOMATION_STATUS.ALL:
        default: {
          break;
        }
      }
      onChange(filterValue);
    },
    [onChange]
  );

  const status = useMemo(() => {
    switch (isEnabled) {
      case true: {
        return AUTOMATION_STATUS.ON;
      }
      case false: {
        return AUTOMATION_STATUS.OFF;
      }
      case null:
      default: {
        return AUTOMATION_STATUS.ALL;
      }
    }
  }, [isEnabled]);

  return {
    status,
    changeStatus,
  };
};
