import { ALERT_STATUS } from '@marlin/shared/ui-recipient-tags';
import { TDateString } from '@marlin/shared/utils-common-date';
import { TUnitOfMeasure } from '@marlin/shared/utils-format-reading';

export enum ALERT_STATUS_FILTER {
  CURRENT = 'CURRENT',
  SNOOZED = 'SNOOZED',
  RESOLVED = 'RESOLVED',
  ALL = 'ALL',
}

export enum ERROR_TYPES {
  LOWER = 'LOWER',
  HIGHER = 'HIGHER',
  UNKNOWN = 'UNKNOWN',
  LEAK_DETECTION = 'LEAK_DETECTION',
  LOST_COMMUNICATION = 'LOST_COMMUNICATION',
  LOW_BATTERY = 'LOW_BATTERY',
  DEVICE_ALERT = 'DEVICE_ALERT',
  DEVICE_ERROR = 'DEVICE_ERROR',
}

export enum DEVICE_TYPE {
  TEMPERATURE = 'TEMPERATURE',
  PRESSURE = 'PRESSURE',
  LEAK = 'LEAK',
  PULSE_METER = 'PULSE_METER',
  GATEWAY = 'GATEWAY',
  UNKNOWN = 'UNKNOWN',
  EQUIPMENT = 'EQUIPMENT',
}

export interface IAlert {
  id: string;
  status: ALERT_STATUS_FILTER;
  device: {
    id: string;
    name: string;
    type: DEVICE_TYPE;
    isDeleted?: boolean;
  };
  location: {
    id: string;
    name: string;
    isDeleted?: boolean;
  } | null;
  equipment: {
    id: string;
    name: string;
    isDeleted?: boolean;
  };
  automation: {
    id: string;
    name: string;
  };
  startTime: TDateString;
  resolveTime: TDateString;
  lastReading: TDateString;
  readingValue: number;
  errorType: ERROR_TYPES;
  absoluteDifferenceValue: number | null;
  thresholdMin: number;
  thresholdMax: number;
  criticality: CRITICALITY;
  channels: IChannels;
  snoozeUntil: TDateString;
  snoozeIntervalInMinutes: number;
  description: string | null;
  title: string | null;
  hyperlink: string | null;
  formattedReading: string | null;
  uoM: TUnitOfMeasure | null;
}

enum CRITICALITY {
  LOW = 'LOW',
  HIGH = 'HIGH',
}

export enum CHANNEL_TYPE {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  HOT_SOS = 'HOT_SOS',
}

export interface IChannels {
  [CHANNEL_TYPE.EMAIL]: IEmailChannel;
  [CHANNEL_TYPE.SMS]: ISmsChannel;
  [CHANNEL_TYPE.HOT_SOS]: IHotSosChannel;
}

interface IEmailChannel extends IChannel {
  type: CHANNEL_TYPE.EMAIL;
}

interface ISmsChannel extends IChannel {
  type: CHANNEL_TYPE.SMS;
}

interface IHotSosChannel {
  type: CHANNEL_TYPE.HOT_SOS;
  errorMessage: string;
  status: ALERT_STATUS | null;
}

interface IChannel {
  type: string;
  recipients?: IRecipient[];
}

export interface IRecipient {
  id: string;
  firstName: string;
  lastName: string;
  status: ALERT_STATUS | null;
  error: string;
}

export interface IAlertsMetadata {
  name: string;
  id: string;
}

export interface IDevice extends IAlertsMetadata {
  deviceType?: DEVICE_TYPE;
}
