import { useModal } from '@marlin/shared/ui-modal';
import { PageContainer, PageHeader, SystemConstraintsPage } from '@marlin/shared/ui-page';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { PERMISSIONS, Restricted, usePermission } from '@marlin/shared/utils-permission';
import {
  TFlowLinkFilterParams,
  TFlowLinkType,
  TUpdateSystemMapLinkParams,
  useDeleteFlowLink,
  useUpdateAttachment,
  useUpdateFlowLink,
} from '@marlin/system-map/data-access/system-map';
import { TNodeType } from '@marlin/system-map/shared/data-access-schemas';
import { FlowLinkEditModal } from '@marlin/system-map/shared/flow-link-edit-modal';
import { AttachementForm as UIAttachementForm } from '@marlin/system-map/ui/attachement-upsert-form';
import { DeleteFlowLinkModal, FlowLinkList, IFlowLinkRow } from '@marlin/system-map/ui/flow-link-list';
import { ASSET_TYPE, FlowLinkForm as UIFlowLinkForm, IFlowLink } from '@marlin/system-map/ui/flow-link-upsert-form';
import MediationRoundedIcon from '@mui/icons-material/MediationRounded';
import { Box } from '@mui/material';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { AttachementForm } from './attachement-form.component';
import { IFlowLinkError } from './constants';
import { content } from './content';
import { FlowLinkForm } from './flow-link-form.component';
import { FlowMapDiagram } from './flow-map-diagram.component';
import { handleFlowLinkError, mapFlowLinkToRowData, mapFlowLinkType } from './helpers';
import { useFlowLinkList } from './use-flow-links.hook';

const useStyles = makeStyles()(() => ({
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const defaultInletNodeType = (isAttachment: boolean): TNodeType => (isAttachment ? 'ATTACHMENT' : 'ASSET');

interface ISelectedFlowLinkProps {
  isDeleteModalVisible: boolean;
  flowLinkId: string | undefined;
}

const defaultFilterParams: TFlowLinkFilterParams = {
  flowMapId: undefined,
  inletAssetId: undefined,
  inletAssetType: undefined,
  inletAssetName: undefined,
  outletAssetId: undefined,
  outletAssetName: undefined,
  outletAssetType: undefined,
};

export const FlowLinkHub = () => {
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { data, changePage, changePageSize, pagination, onSortChange, isError } = useFlowLinkList(defaultFilterParams);
  const onSuccess = () => {
    enqueueSnackbar(content.DELETE_FLOW_LINK_SUCCESS, { variant: 'success', preventDuplicate: true });
  };
  const onError = () => {
    enqueueSnackbar(content.DELETE_FLOW_LINK_ERROR, { variant: 'error', preventDuplicate: true });
  };
  const onUpdateSuccess = () => {
    enqueueSnackbar(content.UPDATE_FLOW_LINK_SUCCESS, { variant: 'success', preventDuplicate: true });
  };
  const { mutateAsync } = useDeleteFlowLink({ onSuccess, onError });
  const [editFlowLinkModal, openEditFlowLinkModal, closeEditFlowLinkModal] = useModal();

  const [selectedFlowLinkProps, setSelectedFlowLinkProps] = useState<ISelectedFlowLinkProps>({
    isDeleteModalVisible: false,
    flowLinkId: undefined,
  });
  const [selectedFlowLinkAsset, setSelectedFlowLinkAsset] = useState<IFlowLink | null>();
  const [flowLinkFormType, setFlowLinkFormType] = useState<TFlowLinkType>('FlowLink');
  const { mutateAsync: updateFlowLink } = useUpdateFlowLink({ onSuccess: onUpdateSuccess });
  const { mutateAsync: updateAttachment } = useUpdateAttachment({ onSuccess: onUpdateSuccess });

  const flowLinkRows = mapFlowLinkToRowData(data);

  const allowEdit = usePermission(PERMISSIONS.EDIT_SYSTEM_MAP_FLOW_LINK);
  const allowDelete = usePermission(PERMISSIONS.DELETE_SYSTEM_MAP_FLOW_LINK);

  const updateSelectedFlowLinkFactory =
    (updateLinkCommand: (params: TUpdateSystemMapLinkParams) => Promise<unknown>, isAttachment = false) =>
    async (link: IFlowLink) => {
      if (!selectedFlowLinkProps.flowLinkId) {
        enqueueSnackbar(content.ADD_FLOW_LINK_ERROR, {
          variant: 'error',
          preventDuplicate: true,
        });
        return;
      }

      return updateLinkCommand({
        flowLinkId: selectedFlowLinkProps.flowLinkId,
        params: {
          inletNodeType: link.inlet?.nodeType || defaultInletNodeType(isAttachment),
          inletAssetId: link.inlet?.assetId || '',
          inletAssetType: (link.inlet?.assetType as ASSET_TYPE) || null,
          inletTags: link.inlet?.tags,
          outletNodeType: link.outlet?.nodeType || 'ASSET',
          outletAssetId: link.outlet?.assetId || '',
          outletAssetType: (link.outlet?.assetType as ASSET_TYPE) || null,
          outletTags: link.outlet?.tags,
        },
      }).catch((error: AxiosError<IFlowLinkError>) => {
        if (typeof error?.response?.data.errorCode === 'number') {
          // TODO: Some errors are handled by the error codes - always 0, but another are handled only by the message. Should be unified and changed on BE side first
          handleFlowLinkError({
            error,
            enqueueSnackbar,
          });
          return;
        }
        throw error;
      });
    };

  const updateSelectedFlowLink = updateSelectedFlowLinkFactory(updateFlowLink);
  const updateSelectedAttachment = updateSelectedFlowLinkFactory(updateAttachment, true);

  const openEditModal = (flowLink: IFlowLinkRow) => {
    setSelectedFlowLinkAsset({
      isAttachment: flowLink.isAttachment,
      inlet: {
        assetId: flowLink.idInlet || '',
        name: flowLink.nameInlet || '',
        assetType: mapFlowLinkType(flowLink.typeInlet),
        nodeType: flowLink.nodeTypeInlet || defaultInletNodeType(flowLink.isAttachment),
        tags: flowLink.inletTags,
      },
      outlet: {
        assetId: flowLink.idOutlet || '',
        name: flowLink.nameOutlet || '',
        assetType: mapFlowLinkType(flowLink.typeOutlet),
        nodeType: flowLink.nodeTypeOutlet || 'ASSET',
        tags: flowLink.outletTags,
      },
      linkTags: flowLink.linkTags,
    });

    setSelectedFlowLinkProps({
      isDeleteModalVisible: false,
      flowLinkId: flowLink.id,
    });
    openEditFlowLinkModal();
  };

  const closeEditModal = () => {
    setSelectedFlowLinkAsset(null);
    closeEditFlowLinkModal();
  };

  const openDeleteModal = (flowLinkId: string) => {
    setSelectedFlowLinkProps({
      isDeleteModalVisible: true,
      flowLinkId,
    });
  };

  const closeDeleteModal = () => {
    setSelectedFlowLinkProps({
      isDeleteModalVisible: false,
      flowLinkId: undefined,
    });
  };

  const deleteFlowLink = () => {
    if (selectedFlowLinkProps.flowLinkId) {
      mutateAsync({ flowLinkId: selectedFlowLinkProps.flowLinkId });
    }
    closeDeleteModal();
  };

  const changeFlowLinkFormType = (flowLinkFormType: TFlowLinkType) => {
    setFlowLinkFormType(flowLinkFormType);
  };

  if (isError) {
    return <SystemConstraintsPage />;
  }

  return (
    <PageContainer>
      <Box className={classes.headerWrapper}>
        <PageHeader prefix="system-map-header" title={content.TITLE} icon={<MediationRoundedIcon />} />
      </Box>
      <FlowMapDiagram />
      {flowLinkFormType === 'FlowLink' && (
        <Restricted to={PERMISSIONS.ADD_SYSTEM_MAP_FLOW_LINK}>
          <FlowLinkForm changeFlowLinkFormType={changeFlowLinkFormType} />
        </Restricted>
      )}
      {flowLinkFormType === 'Attachement' && (
        <Restricted to={PERMISSIONS.ADD_SYSTEM_MAP_ATTACHMENT}>
          <AttachementForm changeFlowLinkFormType={changeFlowLinkFormType} />
        </Restricted>
      )}
      <FlowLinkList
        rows={flowLinkRows}
        isLoading={false}
        isError={false}
        pagination={{ page: pagination.page, pageSize: pagination.pageSize, totalItems: pagination.totalItems }}
        removeFlowLink={openDeleteModal}
        editFlowLink={openEditModal}
        changePage={changePage}
        changePageSize={changePageSize}
        allowDelete={allowDelete}
        allowEdit={allowEdit}
        onSortChange={onSortChange}
      />
      <DeleteFlowLinkModal modalProps={selectedFlowLinkProps} onCancel={closeDeleteModal} onSubmit={deleteFlowLink} />
      {editFlowLinkModal && selectedFlowLinkAsset && (
        <FlowLinkEditModal onClose={closeEditModal}>
          {selectedFlowLinkAsset.isAttachment ? (
            <UIAttachementForm
              defaultValues={selectedFlowLinkAsset}
              onSubmit={updateSelectedAttachment}
              editMode={true}
              onCloseModal={closeEditModal}
            />
          ) : (
            <UIFlowLinkForm
              defaultValues={selectedFlowLinkAsset}
              onSubmit={updateSelectedFlowLink}
              editMode={true}
              onCloseModal={closeEditModal}
              changeFlowLinkFormType={changeFlowLinkFormType}
            />
          )}
        </FlowLinkEditModal>
      )}
    </PageContainer>
  );
};
