import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(16)}`,
    cursor: 'pointer',
  },

  deviceNameWrapper: {
    display: 'flex',
  },

  deviceName: {
    fontWeight: theme.typography.fontWeightRegular,
    maxWidth: theme.typography.pxToRem(192),
  },

  mismatchTooltip: {
    marginLeft: theme.typography.pxToRem(4),
  },
}));
