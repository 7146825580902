import { ISorting, mapSortDirection } from '@marlin/shared/utils-sorting';
import { usePagination } from '@marlin/shared/utils/react-query';
import { TFlowLink, TFlowLinkFilterParams, TPagination, useFlowLinks } from '@marlin/system-map/data-access/system-map';
import { GridSortModel } from '@mui/x-data-grid';
import { useCallback, useMemo, useState } from 'react';

interface IFlowLinkList {
  data: TFlowLink[];
  pagination: TPagination;
  isLoading: boolean;
  isError: boolean;
  changePage: (page: number) => void;
  changePageSize: (pageSize: number) => void;
  onSortChange: (sortModel: GridSortModel) => void;
}

const defaultParams = { initialPageSize: 5 };

export const useFlowLinkList = (queryParams: TFlowLinkFilterParams): IFlowLinkList => {
  const { page, pageSize, changePage, changePageSize } = usePagination(defaultParams);
  const [sorting, setSorting] = useState<ISorting | undefined>(undefined);

  const { data, isLoading, isError } = useFlowLinks({
    queryParams: {
      ...queryParams,
      page,
      pageSize,
      sorting,
    },
  });

  const onSortChange = useCallback((sortModel: GridSortModel) => {
    if (!sortModel[0] || !sortModel[0].sort) {
      setSorting(undefined);
    } else {
      setSorting({
        sortBy: sortModel[0]?.field,
        direction: mapSortDirection(sortModel[0]?.sort),
      });
    }
  }, []);

  const flowLinksData = useMemo(() => data?.data || [], [data]);

  return useMemo(
    () => ({
      data: flowLinksData,
      pagination: data?.pagination || { page: page, pageSize: pageSize, totalItems: 0 },
      isLoading,
      isError,
      changePage,
      changePageSize,
      onSortChange,
    }),
    [flowLinksData, data?.pagination, page, pageSize, isLoading, isError, changePage, changePageSize, onSortChange]
  );
};
