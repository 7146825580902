import { queryClient, useMutation } from '@marlin/shared/utils/react-query';

import { IDevice } from '../device.model';
import { updateDevice } from '../infrastructure/device';
import { queryKey } from './query-key.enum';

interface IUpdateDeviceProps {
  data: IDevice;
  deviceId: string;
}

interface IUpdateDevice {
  previousData: IDevice | undefined;
}

interface IUseUpdateDevice {
  onSuccess: () => void;
  onError: () => void;
}

export const useUpdateDevice = ({ onSuccess, onError }: IUseUpdateDevice) => {
  return useMutation<IDevice, unknown, IUpdateDeviceProps, IUpdateDevice>({
    onMutate: async (updatingDevice: IUpdateDeviceProps) => {
      await queryClient.cancelQueries({ queryKey: queryKey.DEVICE(updatingDevice?.deviceId) });

      const previousData = queryClient.getQueryData<IDevice>(queryKey.DEVICE(updatingDevice?.deviceId));

      queryClient.setQueryData(queryKey.DEVICE(updatingDevice?.deviceId), {
        ...updatingDevice.data,
        id: updatingDevice.deviceId,
      });

      return { previousData };
    },
    onError: (_, __, context: IUpdateDevice | undefined) => {
      onError();

      if (context?.previousData) {
        queryClient.setQueryData(queryKey.DEVICE(context.previousData.id), context.previousData);
      }
    },
    onSuccess: (_data, params) => {
      onSuccess();

      queryClient.refetchQueries({ queryKey: queryKey.DEVICE(params.deviceId) });
      queryClient.invalidateQueries({ queryKey: queryKey.DEVICES() });
    },
    mutationFn: updateDevice,
  });
};
