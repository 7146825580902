import { useAnalyticsTelemetry } from '@marlin/asset/data-access/telemetry';
import { AVERAGING_FUNCTION_FILTER } from '@marlin/shared/utils-chart';
import * as Plotly from 'plotly.js';
import { useMemo } from 'react';

import { IChartSettings } from './types';

const flowTelemetry = [
  { manufacturerId: '9b5bb059-ebd4-418b-a01d-d46047b5732f', datapoints: ['OutletFlow', 'RecircFlow'] },
  { manufacturerId: '5fc9f2e0-dd43-409b-848b-2a923b8a2ea4', datapoints: ['OutletFlow', 'RecircFlow'] },
];

const leakTelemetry = [{ manufacturerId: 'sentinel-220220036', datapoints: ['InLdsAlarm'] }];

const pressureTelemetry = [
  {
    manufacturerId: '5fc9f2e0-dd43-409b-848b-2a923b8a2ea4',
    datapoints: ['ColdPressure', 'HotPressure', 'OutletPressure', 'RecircPressure'],
  },
  {
    manufacturerId: '9b5bb059-ebd4-418b-a01d-d46047b5732f',
    datapoints: ['ColdPressure', 'HotPressure', 'OutletPressure', 'RecircPressure'],
  },
];

const temperatureTelemetry = [
  {
    manufacturerId: '5fc9f2e0-dd43-409b-848b-2a923b8a2ea4',
    datapoints: ['ColdTemp', 'HotTemp', 'MixingValve', 'SetpointTarget', 'MixingTemp', 'RecircTarget', 'RecircTemp'],
  },
];

export type TTelemetryType = 'flow' | 'leak' | 'pressure' | 'temperature';

const telemetryMap: Record<TTelemetryType, { manufacturerId: string; datapoints: string[] }[]> = {
  flow: flowTelemetry,
  leak: leakTelemetry,
  pressure: pressureTelemetry,
  temperature: temperatureTelemetry,
};

interface IUseAnalyticsData {
  data: Plotly.Data[];
  apexData: ApexAxisChartSeries;
  isInitialLoading: boolean;
  isFetching: boolean;
}

export const useAnalyticsData = (type: TTelemetryType, chartSettings: IChartSettings): IUseAnalyticsData => {
  const { data, isFetching, isInitialLoading } = useAnalyticsTelemetry({
    dateFrom: chartSettings.minDate?.toISOString() ?? '',
    dateTo: chartSettings.maxDate?.toISOString() ?? '',
    requestedTelemetry: telemetryMap[type],
    numberOfBuckets: chartSettings.buckets,
    averagingFunctionFilter: AVERAGING_FUNCTION_FILTER.AVERAGE,
    chartDisplayType: type === 'flow' ? 'bar' : 'line',

    bucketOption: 'months',
    isCustomPeriod: false,
  });

  const chartData: Plotly.Data[] = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.telemetryData.map((telemetry) => {
      const { x, y } = telemetry.values.reduce(
        (acc, val) => {
          acc.x.push(new Date(val.x));
          acc.y.push(val.y ?? null);
          return acc;
        },
        { x: [] as Plotly.Datum[], y: [] as Plotly.Datum[] }
      );
      return {
        type: type === 'flow' ? 'bar' : 'scatter',
        mode: type === 'flow' ? undefined : 'lines',
        line: type === 'leak' ? { shape: 'hv' } : undefined,
        name: `${telemetry.manufacturerId.slice(0, 2)}-${telemetry.datapointName}`,
        connectgaps: false,
        x,
        y,
      };
    });
  }, [data, type]);

  const apexChartData: ApexAxisChartSeries = useMemo(() => {
    switch (type) {
      case 'leak': {
        return (
          data?.telemetryData.map((telemetry) => ({
            name: telemetry.datapointName,
            type: 'line',
            data: telemetry.values.map((point) => ({ x: new Date(point.x).valueOf(), y: point.y ? 1 : 0 })),
          })) ?? []
        );
      }
      case 'temperature':
      case 'pressure':
      case 'flow': {
        return (
          data?.telemetryData.map((telemetry) => ({
            name: telemetry.datapointName,
            type: type === 'flow' ? 'bar' : 'line',
            data: telemetry.values.map((point) => ({ x: new Date(point.x).valueOf(), y: point.y ?? null })),
          })) ?? []
        );
      }
      default: {
        return [];
      }
    }
  }, [data, type]);

  return {
    apexData: apexChartData,
    data: chartData,
    isFetching,
    isInitialLoading,
  };
};
