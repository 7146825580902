import { MarlinTheme } from '@marlin/shared/theme';
import { SxProps, useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  dataGridWrapper: {
    height: theme.typography.pxToRem(370),
    width: '100%',
  },

  column: {
    '&.MuiDataGrid-columnHeader': {
      padding: theme.typography.pxToRem(16),
      backgroundColor: theme.palette.background.alternative,
    },
    '& > .MuiDataGrid-columnSeparator': {
      visibility: 'hidden',
    },
  },
}));

export const useDataGridSx = (): SxProps<MarlinTheme> => {
  const theme = useTheme<MarlinTheme>();

  return {
    border: 0,
    '& .MuiDataGrid-cell': {
      padding: theme.typography.pxToRem(16),
    },
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: theme.palette.background.alternative,
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
  };
};
