import { MarlinTheme } from '@marlin/shared/theme';
import { Typography } from '@mui/material';
import { alpha } from '@mui/material';
import isNil from 'lodash/isNil';
import { PropsWithChildren, ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  entry: {
    padding: theme.typography.pxToRem(16),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.background.primary,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  title: {
    fontSize: theme.typography.pxToRem(14),
    color: alpha(theme.palette.text.primary, 0.8),
  },

  subtitle: {
    marginTop: theme.typography.pxToRem(4),
    fontSize: theme.typography.pxToRem(12),
    color: alpha(theme.palette.text.secondary, 0.8),
  },

  value: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export interface ISectionEntry extends PropsWithChildren {
  title: ReactNode;
  subtitle?: ReactNode;
  testId: string;
  subtitleTestId?: string;
}

export const SectionEntry = ({ title, subtitle, testId, subtitleTestId, children }: ISectionEntry) => {
  const { classes } = useStyles();

  return (
    <div className={classes.entry} data-testid={testId}>
      <div>
        <Typography className={classes.title} variant="subtitle1" textAlign="left" data-testid={`${testId}-title`}>
          {title}
        </Typography>
        {subtitle && (
          <Typography
            className={classes.subtitle}
            variant="subtitle2"
            textAlign="left"
            data-testid={subtitleTestId || `${testId}-subtitle`}
          >
            {subtitle}
          </Typography>
        )}
      </div>
      {!isNil(children) && (
        <div>
          <Typography className={classes.value} variant="body2" textAlign="right" data-testid={`${testId}-value`}>
            {children}
          </Typography>
        </div>
      )}
    </div>
  );
};
