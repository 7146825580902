import isNil from 'lodash/isNil';
import { z } from 'zod';

import { ICondition } from '../automation.model';

export const mapConditions = ({
  thresholdMin,
  thresholdMax,
  deadbandMin,
  deadbandMax,
}: {
  thresholdMin: number | null;
  thresholdMax: number | null;
  deadbandMin: number | null;
  deadbandMax: number | null;
}): ICondition => {
  const conditions: ICondition = {};

  if (!isNil(thresholdMin)) {
    conditions.thresholdMin = {
      value: thresholdMin,
      enabled: true,
    };
  }

  if (!isNil(thresholdMax)) {
    conditions.thresholdMax = {
      value: thresholdMax,
      enabled: true,
    };
  }

  if (!isNil(deadbandMin)) {
    conditions.deadbandMin = {
      value: deadbandMin,
      enabled: true,
    };
  }

  if (!isNil(deadbandMax)) {
    conditions.deadbandMax = {
      value: deadbandMax,
      enabled: true,
    };
  }

  return conditions;
};

export const conditionSchema = z.object({
  thresholdMin: z
    .object({
      enabled: z.boolean(),
      value: z.number(),
    })
    .optional(),
  thresholdMax: z
    .object({
      enabled: z.boolean(),
      value: z.number(),
    })
    .optional(),
  deadbandMin: z
    .object({
      enabled: z.boolean(),
      value: z.number(),
    })
    .optional(),
  deadbandMax: z
    .object({
      enabled: z.boolean(),
      value: z.number(),
    })
    .optional(),
});
