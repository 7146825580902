import { STATUS_OPTION, STATUS_TYPE } from '../models/constants';
import { configService } from '../services/config.service';
import { buildUrl, getQueryParamsFromUrl, removeQueryParam } from './url';

const getParamFromUrl = (param: string): string | null => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(param);
};

export const hasStatusCode = (): boolean => !!getParamFromUrl(STATUS_TYPE.B2C);

export const isSuccessStatus = (): boolean => getParamFromUrl(STATUS_TYPE.B2C) === STATUS_OPTION.SUCCESS;

export const isErrorStatus = (): boolean => getParamFromUrl(STATUS_TYPE.B2C) === STATUS_OPTION.ERROR;

export const getUrlWithoutAuthParams = (): string => {
  let baseUrl = configService.get().baseUrl;
  baseUrl = baseUrl.startsWith('/') ? baseUrl.substring(1) : baseUrl;
  baseUrl = baseUrl.endsWith('/') ? baseUrl.slice(0, -1) : baseUrl;
  let origin = window.location.origin;
  origin = baseUrl.length ? `${origin}/${baseUrl}` : origin;
  let url = window.location.href;
  url = url.replace(origin, '');
  const queryParams = getQueryParamsFromUrl(url);

  return buildUrl(url, removeQueryParam(queryParams, STATUS_TYPE.B2C));
};
