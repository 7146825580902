import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.typography.pxToRem(16),
    alignSelf: 'stretch',
    padding: theme.typography.pxToRem(32),
    backgroundColor: theme.palette.background.alternative,
    borderRadius: theme.typography.pxToRem(4),
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
  },
  text: {
    color: theme.palette.text.primary,
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: '160%',
  },
  button: {
    backgroundColor: theme.palette.secondary.light,
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
}));
