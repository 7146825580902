import { IDevice } from '@marlin/asset/data-access/device';
import { useEquipmentDetails as useEquipmentDetailsQuery } from '@marlin/asset/data-access/equipment';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { useIdFromPathname } from '@marlin/shared/utils/url-params';
import { TEquipment } from '@marlin/shared/utils/zod';
// TODO: move useAssets to assets domain
// eslint-disable-next-line @nx/enforce-module-boundaries,ordered-imports/ordered-imports
import { ASSET_TYPE, useAssets } from '@marlin/system-map/data-access/system-map';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { content } from '../content';
import { useNavigation } from './use-navigation.hook';

type TLocation = {
  id: string;
  name: string;
};

type TFilterStyle = {
  background?: string;
};

export interface IDeviceExtended extends Omit<IDevice, 'locationId' | 'equipmentId' | 'manufacturerId' | 'deviceType'> {
  location?: string;
  locationId?: string;
  equipmentId?: string;
  deviceType?: string;
  manufacturerId?: string;
}

export interface IDevicesHub {
  handleQuickFilterChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleLocationFilterChange: (newValue: TLocation[]) => void;
  handleLocationFilterDelete: (index: number) => void;
  handleTemperatureFilterClick: () => void;
  handlePressureFilterClick: () => void;
  handleLeakFilterClick: () => void;
  handleClearAllFilters: () => void;
  getLabelById: (id: string) => string;
  rows: IDeviceExtended[];
  title: string;
  isLoading: boolean;
  isError: boolean;
  locationFilter: TLocation[];
  quickFilter: string;
  quickFilterTrimmed: string;
  temperatureFilterStyle: TFilterStyle;
  pressureFilterStyle: TFilterStyle;
  leakFilterStyle: TFilterStyle;
  serialNumber: string | undefined | null;
  model: string | undefined | null;
  locationsList: TLocation[];
  equipment: TEquipment | undefined;
  refetch: () => void;
}

enum DEVICE_TYPE {
  PRESSURE = 'Pressure',
  LEAK = 'Leak',
  TEMPERATURE = 'Temperature',
}

export const useEquipmentDetails = (): IDevicesHub => {
  const locationsQuery = useAssets(
    {
      filter: ASSET_TYPE.LOCATION,
    },
    true
  );
  const equipmentId = useIdFromPathname();
  const navigate = useNavigate();
  const location = useLocation();
  const navigation = useNavigation();
  const { enqueueSnackbar } = useSnackbar();
  const equipmentQuery = useEquipmentDetailsQuery({
    equipmentId,
    config: {
      onError: (error) => {
        if (isEquipmentNotFound(error)) {
          navigate(navigation.getEquipmentLink());
          enqueueSnackbar(content.ERROR, {
            variant: 'error',
            preventDuplicate: true,
          });
        }
      },
    },
  });
  const [locationFilter, setLocationFilter] = useState<TLocation[]>([]);
  const [quickFilter, setQuickFilter] = useState<string>('');
  const [temperatureFilter, setTemperatureFilter] = useState<boolean>(false);
  const [pressureFilter, setPressureFilter] = useState<boolean>(false);
  const [leakFilter, setLeakFilter] = useState<boolean>(false);
  const quickFilterTrimmed = useMemo(() => quickFilter.trim(), [quickFilter]);

  const equipment = useMemo(() => equipmentQuery?.data, [equipmentQuery?.data]);

  const onFilterRemoveDeviceDrawer = useCallback(() => {
    if (location.search) {
      navigate(location.pathname);
    }
  }, [navigate, location.search, location.pathname]);

  const handleQuickFilterChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = event;
      setQuickFilter(value);
      onFilterRemoveDeviceDrawer();
    },
    [onFilterRemoveDeviceDrawer]
  );

  const handleLocationFilterChange = useCallback(
    (newValue: TLocation[]) => {
      setLocationFilter(newValue);
      onFilterRemoveDeviceDrawer();
    },
    [onFilterRemoveDeviceDrawer]
  );

  const handleLocationFilterDelete = useCallback(
    (index: number) => {
      setLocationFilter([...locationFilter.slice(0, index), ...locationFilter.slice(index + 1, locationFilter.length)]);
      onFilterRemoveDeviceDrawer();
    },
    [locationFilter, onFilterRemoveDeviceDrawer]
  );

  const handleTemperatureFilterClick = useCallback(() => {
    setTemperatureFilter(!temperatureFilter);
    onFilterRemoveDeviceDrawer();
  }, [temperatureFilter, onFilterRemoveDeviceDrawer]);

  const handlePressureFilterClick = useCallback(() => {
    setPressureFilter(!pressureFilter);
    onFilterRemoveDeviceDrawer();
  }, [onFilterRemoveDeviceDrawer, pressureFilter]);

  const handleLeakFilterClick = useCallback(() => {
    setLeakFilter(!leakFilter);
    onFilterRemoveDeviceDrawer();
  }, [leakFilter, onFilterRemoveDeviceDrawer]);

  const handleClearAllFilters = useCallback(() => {
    setQuickFilter('');
    setLocationFilter([]);
    setTemperatureFilter(false);
    setPressureFilter(false);
    setLeakFilter(false);
    onFilterRemoveDeviceDrawer();
  }, [onFilterRemoveDeviceDrawer]);

  const deviceRows = useMemo(() => equipmentQuery?.data?.devices, [equipmentQuery?.data?.devices]);

  const locationsList = useMemo(() => {
    const locationIds: string[] = [];
    deviceRows?.forEach((device) => {
      if (device?.locationId) {
        locationIds.push(device?.locationId);
      }
    });
    const uniqueLocationsList: string[] = [...new Set(locationIds)];
    return uniqueLocationsList.map((id: string) => {
      return {
        id,
        name: locationsQuery?.data?.find((location) => location.id === id)?.name ?? '',
      };
    });
  }, [deviceRows, locationsQuery?.data]);

  const rows = useMemo((): IDeviceExtended[] => {
    const term = quickFilterTrimmed.toLocaleLowerCase();
    const filteredLocationRows =
      locationFilter.length === 0
        ? deviceRows || []
        : deviceRows?.filter((row) => {
            if (row.locationId) {
              return locationFilter.map((location) => location.id).includes(row?.locationId);
            }
            return false;
          }) || [];

    const filteredRows =
      !temperatureFilter && !pressureFilter && !leakFilter
        ? filteredLocationRows || []
        : filteredLocationRows?.filter((row) => {
            if (row.deviceType) {
              return (
                (temperatureFilter && row.deviceType === DEVICE_TYPE.TEMPERATURE) ||
                (pressureFilter && row.deviceType === DEVICE_TYPE.PRESSURE) ||
                (leakFilter && row.deviceType === DEVICE_TYPE.LEAK)
              );
            }
            return false;
          }) || [];

    const devices =
      filteredRows.map((row) => {
        return {
          ...row,
          location: locationsQuery?.data?.find((location) => location.id === row.locationId)?.name,
        };
      }) || [];

    return !term.length
      ? devices
      : devices.filter((row) => {
          return row.name.toLocaleLowerCase().includes(term) || row.location?.toLocaleLowerCase().includes(term);
        });
  }, [
    locationFilter,
    deviceRows,
    temperatureFilter,
    pressureFilter,
    leakFilter,
    locationsQuery?.data,
    quickFilterTrimmed,
  ]);

  const getLabelById = useCallback(
    (value: string) => locationsQuery?.data?.find((location) => location.id === value)?.name as string,
    [locationsQuery.data]
  );

  const title = useMemo(() => equipmentQuery?.data?.name as string, [equipmentQuery?.data?.name]);
  const temperatureFilterStyle = useMemo(
    () => (temperatureFilter ? { background: '#0099A314' } : {}),
    [temperatureFilter]
  );
  const pressureFilterStyle = useMemo(() => (pressureFilter ? { background: '#0099A314' } : {}), [pressureFilter]);
  const leakFilterStyle = useMemo(() => (leakFilter ? { background: '#0099A314' } : {}), [leakFilter]);
  const serialNumber = useMemo(() => equipmentQuery?.data?.serialNumber, [equipmentQuery?.data?.serialNumber]);
  const model = useMemo(() => equipmentQuery?.data?.model, [equipmentQuery?.data?.model]);
  const refetch = useCallback(() => {
    equipmentQuery.refetch();
  }, [equipmentQuery]);
  const isLoading = useMemo(
    () =>
      locationsQuery.isLoading ||
      locationsQuery.isInitialLoading ||
      equipmentQuery.isLoading ||
      equipmentQuery.isInitialLoading,
    [
      equipmentQuery.isInitialLoading,
      equipmentQuery.isLoading,
      locationsQuery.isInitialLoading,
      locationsQuery.isLoading,
    ]
  );

  const isError = useMemo(
    () => locationsQuery.isError || equipmentQuery.isError,
    [equipmentQuery.isError, locationsQuery.isError]
  );

  return {
    handleQuickFilterChange,
    handleLocationFilterChange,
    handleLocationFilterDelete,
    handleTemperatureFilterClick,
    handlePressureFilterClick,
    handleLeakFilterClick,
    handleClearAllFilters,
    getLabelById,
    rows,
    title,
    temperatureFilterStyle,
    pressureFilterStyle,
    leakFilterStyle,
    serialNumber,
    model,
    equipment,
    isLoading,
    isError,
    locationFilter,
    quickFilter,
    quickFilterTrimmed,
    locationsList,
    refetch,
  };
};

const isEquipmentNotFound = (error: unknown) => {
  const parsedError = z
    .object({
      response: z.object({
        data: z.object({
          status: z.number(),
        }),
      }),
    })
    .passthrough()
    .safeParse(error);

  if (parsedError.success) {
    return parsedError.data.response.data.status === 404;
  }

  return false;
};
