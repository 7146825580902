import { MarlinTheme } from '@marlin/shared/theme';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  error: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.warning.main,
    display: 'flex',
    alignItems: 'center',
  },

  errorText: {
    marginLeft: theme.typography.pxToRem(10),
    color: theme.palette.text.primary,
  },
}));

interface IWarningProps {
  message: string;
}

export function Warning({ message }: IWarningProps) {
  const { classes } = useStyles();

  return (
    <div className={classes.error}>
      <WarningRoundedIcon />
      <span className={classes.errorText}>{message}</span>
    </div>
  );
}
