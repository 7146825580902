export enum COMPARE_TO_MODE {
  PREVIOUS_PERIOD = 'previousPeriod',
  MONTH_OVER_MONTH = 'monthOverMonth',
  QUARTER_OVER_QUARTER = 'quarterOverQuarter',
  YEAR_OVER_YEAR = 'yearOverYear',
  CUSTOM_START_DATE = 'customStartDate',
}

export type TCompareToValue =
  | { mode: COMPARE_TO_MODE.CUSTOM_START_DATE; date: string; label: string }
  | { mode: Exclude<COMPARE_TO_MODE, COMPARE_TO_MODE.CUSTOM_START_DATE>; label: string };
