import { useState } from 'react';

import { Tooltip } from './tooltip.component';

export const TruncatedStringWithTooltip = ({
  text,
  maxCharsCount = 50,
  textClassName,
}: {
  text: string;
  maxCharsCount?: number;
  textClassName?: string;
}) => {
  const [tooltipEnabled, setTooltipEnabled] = useState(false);
  const isTextTooLong = (text ?? '').length > maxCharsCount;

  return (
    <Tooltip text={text} open={tooltipEnabled} onClose={() => setTooltipEnabled(false)}>
      <div onMouseOver={isTextTooLong ? () => setTooltipEnabled(true) : undefined} className={textClassName}>
        {isTextTooLong ? `${text.slice(0, maxCharsCount)}...` : text}
      </div>
    </Tooltip>
  );
};
