import { MarlinTheme } from '@marlin/shared/theme';
import { ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

import { CHANNEL_TYPE, IExtendedChannels } from './types';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.primary,
    marginTop: theme.typography.pxToRem(5),
    marginRight: theme.typography.pxToRem(16),
  },

  icon: {
    height: theme.typography.pxToRem(16),
    '& > svg': {
      color: theme.palette.action.active,
      height: '100%',
    },
  },

  label: {
    fontSize: theme.typography.pxToRem(14),
    marginRight: theme.typography.pxToRem(8),
  },
}));

interface IDetailsItemProps {
  channel: IExtendedChannels[CHANNEL_TYPE];
  label: string;
  icon?: JSX.Element;
  children?: ReactNode;
}

const ChannelWrapper = ({ channel, label, icon, children }: IDetailsItemProps) => {
  const { classes } = useStyles();

  if (!channel) {
    return null;
  }

  return (
    <div className={classes.wrapper}>
      {icon && <span className={classes.icon}>{icon}</span>}
      <span className={classes.label}>{label}</span>
      <span>{children}</span>
    </div>
  );
};

export { ChannelWrapper };
