import { MarlinTheme } from '@marlin/shared/theme';
import { warrantyStorageService } from '@marlin/shared/utils-common-warranty-storage';
import { TAvailableWarrantyModel } from '@marlin/shared/utils/zod';
import { Button, Link, Typography } from '@mui/material';
import { useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';
import { ONBOARDING_STEP } from '../onboarding-wizard/steps/steps';
import { ReactComponent as SetupSuccess } from './icons/setup-success.svg';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  successWrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.text.secondary,
    textAlign: 'center',
  },
  icon: {
    margin: `${theme.typography.pxToRem(48)} 0 ${theme.typography.pxToRem(24)}`,
    [theme.breakpoints.down('md')]: {
      width: '75%',
      height: 'auto',
    },
  },

  title: {
    color: theme.palette.text.primary,
    lineHeight: theme.typography.pxToRem(32),
    marginBottom: theme.typography.pxToRem(8),
  },

  subtitle: {
    marginTop: theme.typography.pxToRem(24),
  },

  title_bonus: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(25.6),
    margin: `${theme.typography.pxToRem(24)} 0 ${theme.typography.pxToRem(8)}`,
  },

  link: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.text.primary,
    margin: `${theme.typography.pxToRem(24)} 0 ${theme.typography.pxToRem(32)}`,
  },

  button: {
    alignSelf: 'flex-end',
    width: theme.typography.pxToRem(86),
  },
}));

interface ISetupCompleteProps {
  onNextStep: (step: ONBOARDING_STEP) => void;
}

export const SetupComplete = ({ onNextStep }: ISetupCompleteProps) => {
  const { classes } = useStyles();
  const warrantyModel = warrantyStorageService.get();
  const isWarrantyOrigin = Boolean(warrantyModel);

  useEffect(() => {
    if (!isWarrantyOrigin) {
      setTimeout(() => {
        onNextStep(ONBOARDING_STEP.INVITE_USERS);
      }, 3000);
    }
  }, [onNextStep, isWarrantyOrigin]);

  const handleClick = () => {
    warrantyStorageService.clear();
    onNextStep(ONBOARDING_STEP.INVITE_USERS);
  };

  const { bonusText, termsAndConditionsText, termsAndConditionsLink, termsAndConditionsLinkText } =
    getContent(warrantyModel);

  return (
    <div className={classes.successWrapper}>
      <SetupSuccess className={classes.icon} />
      <Typography variant="h6" className={classes.title}>
        {content.SETUP_COMPLETE.TITLE}
      </Typography>
      <Typography variant="body2">{content.SETUP_COMPLETE.SUBTITLE1}</Typography>
      {!isWarrantyOrigin && (
        <Typography className={classes.subtitle} variant="body2">
          {content.SETUP_COMPLETE.SUBTITLE2}
        </Typography>
      )}
      {isWarrantyOrigin && (
        <>
          <Typography variant="h6" className={classes.title_bonus}>
            {content.SETUP_COMPLETE.BONUS_TITLE}
          </Typography>
          <Typography variant="body2">{bonusText}</Typography>
          <Typography variant="body2" className={classes.link}>
            {`${termsAndConditionsText} `}
            {/*TODO: popup for innovation terms and conditions*/}
            <Link target="_blank" href={termsAndConditionsLink}>
              {termsAndConditionsLinkText}
            </Link>
          </Typography>
          <Button className={classes.button} variant="contained" onClick={handleClick}>
            {content.SETUP_COMPLETE.BUTTON}
          </Button>
        </>
      )}
    </div>
  );
};

const getContent = (warrantyModel: TAvailableWarrantyModel | null) => {
  if (warrantyModel === 'intellistation2') {
    return {
      bonusText: content.SETUP_COMPLETE.BONUS_TEXT_INTELLISTATION,
      termsAndConditionsText: content.SETUP_COMPLETE.TERMS_AND_CONDITIONS_INTELLISTATION,
      termsAndConditionsLinkText: content.SETUP_COMPLETE.LINK_INTELLISTATION,
      termsAndConditionsLink: 'https://www.watts.com/resources/warranty-information#9',
    };
  }

  return {
    bonusText: content.SETUP_COMPLETE.BONUS_TEXT_INNOVATIONEDGE,
    termsAndConditionsText: content.SETUP_COMPLETE.TERMS_AND_CONDITIONS_INNOVATIONEDGE,
    termsAndConditionsLinkText: content.SETUP_COMPLETE.LINK_INNOVATIONEDGE,
    termsAndConditionsLink:
      'https://www.aerco.com/dfsmedia/0533dbba17714b1ab581ab07a4cbb521/58103-source/aerco-innovation-limited-warranty',
  };
};
