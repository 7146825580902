import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import { useCallback, useContext, useMemo } from 'react';

import { CancelModalBody } from './cancel-modal-body.component';
import { CancelModalFooter } from './cancel-modal-footer.component';
import { CancelModalTitle } from './cancel-modal-title.component';

export const useCancelModal = () => {
  const { modalDispatch } = useContext(ModalContext);

  const modalCancelPayload = useMemo(
    () => ({
      title: <CancelModalTitle />,
      body: <CancelModalBody />,
      footer: <CancelModalFooter />,
    }),
    []
  );

  const openCancelModal = useCallback(() => {
    modalDispatch({
      type: MODAL_ACTION_TYPE.SHOW,
      payload: modalCancelPayload,
    });
  }, [modalDispatch, modalCancelPayload]);

  return { openCancelModal };
};
