import { MarlinTheme } from '@marlin/shared/theme';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { TDashboardSection } from './types';

const displayValue = (fullScreenChart: TDashboardSection | null, chartId: TDashboardSection) => {
  if (!fullScreenChart) {
    return 'block';
  }
  if (fullScreenChart === chartId) {
    return 'block';
  }
  return 'none';
};

interface ITypes {
  fullScreenSection: TDashboardSection | null;
  waterTraceabilitySlot: 'waterTraceability' | 'individualAccumulatedVolumes';
}

export const useStyles = makeStyles<ITypes>()((theme: MarlinTheme, { fullScreenSection, waterTraceabilitySlot }) => ({
  container: {
    display: 'grid',
    rowGap: theme.typography.pxToRem(24),
    columnGap: theme.typography.pxToRem(24),
    gridTemplateColumns: 'auto 1fr 1fr 1fr 1fr 1fr',
    gridTemplateRows: `auto 1fr`,
    gridTemplateAreas: `"totalWaterUsePerPeriod totalWaterUsePerPeriod totalWaterConsumption totalWaterConsumption totalWaterConsumption totalWaterConsumption"
                        "${waterTraceabilitySlot} ${waterTraceabilitySlot} ${waterTraceabilitySlot} individualAccumulatedVolumes individualAccumulatedVolumes individualAccumulatedVolumes"`, // TODO: change after sankey
  },
  fullScreenContainer: {
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    padding: theme.typography.pxToRem(24),
  },
  totalWaterUsePerPeriod: {
    gridArea: 'totalWaterUsePerPeriod',
    minWidth: theme.typography.pxToRem(384),
    display: displayValue(fullScreenSection, 'totalWaterUsePerPeriod'),
    width: '100%',
    height: '100%',
  },
  totalWaterConsumption: {
    gridArea: 'totalWaterConsumption',
    display: displayValue(fullScreenSection, 'totalWaterConsumption'),
    width: '100%',
    height: '100%',
  },
  waterTraceability: {
    gridArea: 'waterTraceability',
    display: displayValue(fullScreenSection, 'waterTraceability'),
    width: '100%',
    height: '100%',
  },
  individualAccumulatedVolumes: {
    gridArea: 'individualAccumulatedVolumes',
    display: displayValue(fullScreenSection, 'individualAccumulatedVolumes'),
    width: '100%',
    height: 'auto',
  },
}));

interface ISustaiabilityLayoutProps {
  totalWaterUsePerPeriod?: JSX.Element;
  totalWaterConsumption: JSX.Element;
  waterTraceability: JSX.Element | null;
  individualAccumulatedVolumes: JSX.Element;
  fullScreenChart: TDashboardSection | null;
}

export const SustaiabilityLayout = ({
  totalWaterUsePerPeriod,
  totalWaterConsumption,
  waterTraceability,
  individualAccumulatedVolumes,
  fullScreenChart,
}: ISustaiabilityLayoutProps) => {
  const { classes } = useStyles({
    fullScreenSection: fullScreenChart,
    waterTraceabilitySlot: waterTraceability ? 'waterTraceability' : 'individualAccumulatedVolumes',
  });

  return (
    <Box className={fullScreenChart ? classes.fullScreenContainer : classes.container}>
      <Box className={classes.totalWaterUsePerPeriod}> {totalWaterUsePerPeriod} </Box>
      <Box className={classes.totalWaterConsumption}> {totalWaterConsumption} </Box>
      <Box className={classes.waterTraceability}> {waterTraceability} </Box>
      <Box className={classes.individualAccumulatedVolumes}> {individualAccumulatedVolumes} </Box>
    </Box>
  );
};
