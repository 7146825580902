import { SelectChangeEvent } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';

import { EXTENDED_RANGE_FILTER } from '../charts/collapsed-chart/chart-constants';
import { CHART_SELECTION_TYPE } from '../charts/extended-chart/hooks/use-chart-selection.hook';
import { onMainChartSelectionChange } from '../charts/extended-chart/hooks/utils';
import { RANGE_FILTER, chartRangeTypeOptions, isOfChartRangeType } from '../constant';
import { IChartRangeContext, IRange } from '../context/chart-range.context';
import { rangeFilterToRange } from '../utils/range-filter-to-range';

export const useRange = (): IChartRangeContext => {
  const [rangeFilter, setRangeFilter] = useState<RANGE_FILTER>(chartRangeTypeOptions[0].id);
  const [extendedRangeFilter, setExtendedRangeFilter] = useState<RANGE_FILTER | EXTENDED_RANGE_FILTER>(rangeFilter);
  const [savedRange, setSavedRange] = useState<IRange | null>(null);

  const onRangeFilterChange = useCallback((event: SelectChangeEvent<RANGE_FILTER>) => {
    const newRangeFilter = event.target.value;

    if (isOfChartRangeType(newRangeFilter)) {
      setRangeFilter(newRangeFilter);
      onMainChartSelectionChange({
        selection: rangeFilterToRange(newRangeFilter),
        type: CHART_SELECTION_TYPE.EXTERNAL,
      });
    }
  }, []);

  const range = useMemo(() => rangeFilterToRange(rangeFilter), [rangeFilter]);

  const saveRange = useCallback((range: IRange | null) => {
    setSavedRange(range);
  }, []);

  const restoreRange = useCallback(
    (rangeFilter: EXTENDED_RANGE_FILTER) => {
      if (rangeFilter === EXTENDED_RANGE_FILTER.CUSTOM) {
        return savedRange;
      }

      return null;
    },
    [savedRange]
  );

  return useMemo(
    (): IChartRangeContext => ({
      rangeFilter,
      onRangeFilterChange,
      range,
      extendedRangeFilter,
      setExtendedRangeFilter,
      savedRange,
      saveRange,
      restoreRange,
    }),
    [rangeFilter, onRangeFilterChange, range, extendedRangeFilter, savedRange, saveRange, restoreRange]
  );
};
