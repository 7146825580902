import { queryClient, useMutation } from '@marlin/shared/utils/react-query';
import { AxiosError } from 'axios';

import { toggleDeviceDetailsPinnedCache } from '../cache/toggle-device-details-pinned-cache';
import { createPinDevice } from '../infrastructure/create-pin-device';
import { IPinDevice } from '../pin-device.model';
import { queryKey } from './query-key.enum';

interface ICreatePinDeviceProps {
  data: {
    deviceId: string;
  };
}

interface ICreateDevice {
  previousData: IPinDevice[] | undefined;
}

export const useCreateDevice = () => {
  return useMutation<unknown, AxiosError, ICreatePinDeviceProps, ICreateDevice>({
    onMutate: async (data) => {
      await queryClient.cancelQueries({ queryKey: queryKey.PIN_DEVICES() });

      const previousData = queryClient.getQueryData<IPinDevice[]>(queryKey.PIN_DEVICES());

      toggleDeviceDetailsPinnedCache(data.data.deviceId);

      return { previousData };
    },
    onError: (_: unknown, data: ICreatePinDeviceProps) => {
      queryClient.invalidateQueries({ queryKey: queryKey.DEVICE(data.data.deviceId) });
    },
    onSuccess: (_: unknown, data: ICreatePinDeviceProps) => {
      queryClient.invalidateQueries({ queryKey: queryKey.PIN_DEVICES() });
      queryClient.invalidateQueries({ queryKey: queryKey.DEVICE(data.data.deviceId) });
      queryClient.invalidateQueries({ queryKey: queryKey.HOME_LOCATIONS() });
    },
    mutationFn: createPinDevice,
  });
};
