import { MarlinTheme } from '@marlin/shared/theme';
import { ModalMedium } from '@marlin/shared/ui-modal';
import { Button, Typography } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';

interface IConfirmationModalProps {
  onCancel: () => void;
  onConfirm: () => void;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  actionButtons: {
    display: 'flex',
    gap: theme.typography.pxToRem(8),
    marginLeft: 'auto',
  },
}));

export const ConfirmationModalBody = () => {
  return <Typography variant="body1">{content.MODAL.UNSAVED_CHANGES_MODAL_BODY}</Typography>;
};

export const ConfirmationModalFooter = ({ onConfirm, onCancel }: IConfirmationModalProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.actionButtons}>
      <Button onClick={onCancel} data-testid="modal-cancel-button">
        {content.MODAL.CANCEL}
      </Button>
      <Button onClick={onConfirm} variant="contained" data-testid="modal-save-button">
        {content.MODAL.LEAVE}
      </Button>
    </div>
  );
};

export const ConfirmationModal = (props: IConfirmationModalProps) => {
  return (
    <ModalMedium
      title={content.MODAL.UNSAVED_CHANGES_MODAL_TITLE}
      body={<ConfirmationModalBody />}
      footer={<ConfirmationModalFooter {...props} />}
      onClose={props.onCancel}
    />
  );
};
