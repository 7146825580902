import { TExtractFnReturnType, useQuery } from '@marlin/shared/utils/react-query';

import { getWaterSankey } from '../infrastructure/get-water-sankey';
import { TSankeyParams } from '../infrastructure/schema/water-sankey.schema';
import { queryKey } from './query-key.enum';

type TQueryFnType = typeof getWaterSankey;

export const useWaterSankey = (params: TSankeyParams) => {
  return useQuery<TExtractFnReturnType<TQueryFnType>>({
    queryKey: queryKey.WATER_SANKEY(params),
    queryFn: () => getWaterSankey(params),
  });
};
