import { TEquipmentSchema } from '@marlin/asset/data-access/equipment';
import {
  EquipmentCard,
  EquipmentListMobileUi,
  useEquipmentHubContextMenu,
} from '@marlin/asset/shared/ui/equipment-list';
import { useRemoveEquipment } from '@marlin/asset/ui/delete-equipment-modal';
import { MarlinTheme } from '@marlin/shared/theme';
import { ActionDelete, ActionEdit, ContextMenu } from '@marlin/shared/ui-context-menu';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  mobileMenu: {
    padding: 0,
  },
  emptyList: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 1,
    backgroundColor: theme.palette.background.secondary,
  },
  container: {
    backgroundColor: theme.palette.background.secondary,
  },
}));

interface IEquipmentListProps {
  rows: TEquipmentSchema[];
  fetchNextPage: () => void;
  isListLoading?: boolean;
  totalItems?: number;
  hasNextPage?: boolean;
}

export const EquipmentList = ({ rows, fetchNextPage, isListLoading, totalItems, hasNextPage }: IEquipmentListProps) => {
  const { classes } = useStyles();
  const { allowDelete, allowDeregister, allowEdit, editEquipment } = useEquipmentHubContextMenu();
  const { removeEquipment } = useRemoveEquipment();

  if (isListLoading) {
    return <LoadingSpinner />;
  }

  if (!rows.length) {
    return <div className={classes.emptyList}>{content.EMPTY_LIST}</div>;
  }

  return (
    <div className={classes.container}>
      <EquipmentListMobileUi
        rows={rows}
        fetchNextPage={fetchNextPage}
        totalItems={totalItems}
        hasNextPage={hasNextPage}
      >
        {(equipment: TEquipmentSchema) => (
          <EquipmentCard key={equipment.id} equipment={equipment} data-testid="equipment-card">
            <ContextMenu className={classes.mobileMenu} data-testid="equipment-card-menu">
              {allowEdit && <ActionEdit onClick={() => editEquipment(equipment.id)} />}
              {(allowDelete || allowDeregister) && (
                <ActionDelete onClick={() => removeEquipment(equipment)} deregisterText={equipment.isRegistered} />
              )}
            </ContextMenu>
          </EquipmentCard>
        )}
      </EquipmentListMobileUi>
    </div>
  );
};
