import { MarlinTheme } from '@marlin/shared/theme';
import { Icon, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface IStyles {
  isEquipmentOperational?: boolean;
  isErrorModeLabel?: boolean;
  isErrorStatusDot?: boolean;
  fullWidth?: boolean;
}

const useStyles = makeStyles<IStyles>()(
  (theme: MarlinTheme, { isEquipmentOperational, isErrorModeLabel, isErrorStatusDot, fullWidth }) => ({
    wrapper: {
      color: theme.palette.text.primary,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: theme.palette.background.alternative,
      flexWrap: 'wrap',
      marginLeft: fullWidth ? 0 : theme.typography.pxToRem(16),
      border: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
      borderRadius: `${theme.typography.pxToRem(4)} ${theme.typography.pxToRem(4)} 0 0`,
      [theme.breakpoints.down('md')]: {
        marginLeft: 0,
        gap: theme.typography.pxToRem(8),
        padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(16)}`,
      },
    },
    dot: {
      height: theme.typography.pxToRem(18),
      minWidth: theme.typography.pxToRem(18),
      borderRadius: '50%',
      display: 'inline-block',
      backgroundColor:
        isEquipmentOperational && !isErrorStatusDot ? theme.palette.success.light : theme.palette.error.main,
      marginRight: theme.typography.pxToRem(14),
    },
    statusWrapper: {
      display: 'flex',
      alignContent: 'center',
      alignItems: 'center',
      padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(16)}`,
      [theme.breakpoints.down('md')]: {
        padding: 0,
      },
    },
    icon: {
      color: isErrorModeLabel ? theme.palette.error.main : theme.palette.action.active,
      marginRight: theme.typography.pxToRem(8),
    },
    mode: {
      display: 'flex',
      marginRight: theme.typography.pxToRem(8),
      padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(16)}`,
      [theme.breakpoints.down('md')]: {
        flexBasis: '100%',
        marginRight: 0,
        padding: 0,
      },
    },
    status: {
      marginRight: theme.typography.pxToRem(8),
      whiteSpace: 'nowrap',
    },
    time: {
      whiteSpace: 'nowrap',
    },
    equipmentModeLabel: {
      color: isErrorModeLabel ? theme.palette.error.main : 'inherit',
    },
  })
);

interface IEquipmentStatusProps {
  operationalIconTag?: string;
  operationalStatusLabel?: string;
  isEquipmentOperational?: boolean;
  displayTime?: string;
  statusLabel: string;
  isErrorModeLabel?: boolean;
  isErrorStatusDot?: boolean;
  fullWidth?: boolean;
}

export const EquipmentStatusBar = ({
  isEquipmentOperational,
  operationalStatusLabel,
  operationalIconTag,
  fullWidth,
  isErrorModeLabel,
  isErrorStatusDot,
  statusLabel,
  displayTime,
}: IEquipmentStatusProps) => {
  const { classes } = useStyles({
    isEquipmentOperational,
    isErrorModeLabel,
    isErrorStatusDot,
    fullWidth,
  });

  return (
    <div className={classes.wrapper} data-testid="header-equpment-operational">
      {isEquipmentOperational && operationalStatusLabel && operationalIconTag && (
        <div className={classes.mode}>
          {operationalIconTag && (
            <Icon baseClassName="material-symbols-outlined" className={classes.icon} data-testid="equipment-mode-icon">
              {operationalIconTag}
            </Icon>
          )}
          {operationalStatusLabel && (
            <Typography variant="body1" data-testid="equipment-mode" className={classes.equipmentModeLabel}>
              {operationalStatusLabel}
            </Typography>
          )}
        </div>
      )}
      <div className={classes.statusWrapper}>
        <div className={classes.dot} data-testid="equipment-status-dot"></div>
        <Typography variant="body1" className={classes.status} data-testid="equipment-status">
          {statusLabel}
        </Typography>
        {isEquipmentOperational && (
          <Typography variant="body1" data-testid="equipment-time" className={classes.time}>
            {displayTime}
          </Typography>
        )}
      </div>
    </div>
  );
};
