import { MarlinTheme } from '@marlin/shared/theme';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

interface IFormGroupHeaderProps {
  title: string;
  prefix: string;
  tooltip?: string | NonNullable<ReactNode>;
  required?: boolean;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  sectionTitle: {
    fontSize: theme.typography.pxToRem(20),
    marginTop: theme.typography.pxToRem(24),
    marginBottom: theme.typography.pxToRem(20),
  },

  tooltip: {
    marginLeft: theme.typography.pxToRem(8),
  },
}));

export function SectionHeader({ title, prefix, tooltip, required = false }: IFormGroupHeaderProps) {
  const { classes } = useStyles();

  return (
    <h6 data-testid={`${prefix}-section-title`} className={classes.sectionTitle}>
      {title}
      {required && '*'}
      {tooltip && (
        <span className={classes.tooltip}>
          <Tooltip text={tooltip} />
        </span>
      )}
    </h6>
  );
}
