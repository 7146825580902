import { SelectChangeEvent } from '@mui/material';
import { useCallback, useMemo } from 'react';

import { useSensorFilteringStore } from '../context/sensor-filtering.context';
import { useEquipmentWithDevicesQuery, useLocationsWithDevicesQuery } from './use-filtered-queries.hook';

export const useSensorFiltering = () => {
  const [{ selectedLocationId, selectedEquipmentId, selectedChartType }, setSensorFiltering] = useSensorFilteringStore(
    (store) => store
  );

  const locationQuery = useLocationsWithDevicesQuery(false);
  const equipmentQuery = useEquipmentWithDevicesQuery({ selectedLocationId, enabled: false });

  const onLocationChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      setSensorFiltering({ selectedLocationId: event.target.value, selectedEquipmentId: '' });
    },
    [setSensorFiltering]
  );

  const onEquipmentChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      setSensorFiltering({ selectedEquipmentId: event.target.value });
    },
    [setSensorFiltering]
  );

  const locationsSelectData = useMemo(() => {
    if (!locationQuery.data?.data) return [];

    return locationQuery.data.data.map((location) => ({ id: location.id, name: location.name }));
  }, [locationQuery.data?.data]);

  const equipmentSelectData = useMemo(() => {
    if (!equipmentQuery.data?.data) return [];

    return equipmentQuery.data.data.map((equipment) => ({ id: equipment.id, name: equipment.name }));
  }, [equipmentQuery.data?.data]);

  return {
    selectedLocationId,
    selectedEquipmentId,
    onLocationChange,
    onEquipmentChange,
    locationsSelectData,
    equipmentSelectData,
    setSensorFiltering,
    selectedChartType,
    equipmentData: equipmentQuery.data?.data,
    locationData: locationQuery.data?.data,
  };
};
