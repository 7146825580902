import { useGateways, useGatewaysWithLoadMore } from '@marlin/asset/data-access/gateway';
import { PageContainer, PageHeader } from '@marlin/shared/ui-page';
import { PaginationMobile } from '@marlin/shared/ui-pagination-mobile';
import { usePagination } from '@marlin/shared/utils/react-query';
import { useMediaQuery, useTheme } from '@mui/material';
import { useMemo } from 'react';

import { content } from './content';
import { GatewayHubHeader } from './gateway-hub-header.component';
import { GatewayListMobile } from './gateway-list-mobile/gateway-list-mobile.component';
import { GatewayList } from './gateway-list/gateway-list.component';

export const GatewayHub = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { page, pageSize, changePage, changePageSize } = usePagination({
    initialPageSize: 100,
  });

  const gatewayQuery = useGateways({ params: { page: page, pageSize: pageSize }, enabled: !isMobile });
  const gatewayMobileQuery = useGatewaysWithLoadMore({ params: { pageSize: 10 }, enabled: isMobile });

  const pagination = useMemo(
    () => gatewayQuery.data?.pagination || { page: page, pageSize: pageSize, totalItems: 0 },
    [gatewayQuery.data?.pagination, page, pageSize]
  );

  const refreshData = () => {
    gatewayQuery.refetch();
  };

  const refreshDataMobile = () => {
    gatewayMobileQuery.refetch();
  };

  if (isMobile) {
    return (
      <PageContainer prefix="gateway-hub-page" data-testid="gateway-hub-container">
        <PageHeader title={content.GATEWAY_HUB_TITLE} prefix="gateway-hub-header" data-testid="gateway-hub-header" />
        <GatewayListMobile
          data={gatewayMobileQuery.rows || []}
          isLoading={gatewayMobileQuery.isLoading}
          isError={gatewayMobileQuery.isError}
          refreshData={refreshDataMobile}
        />
        <PaginationMobile
          totalItems={gatewayMobileQuery.totalItems}
          hasNextPage={gatewayMobileQuery.hasNextPage}
          fetchNextPage={gatewayMobileQuery.fetchNextPage}
          loadedItems={gatewayMobileQuery.rows.length}
          data-testid="gateways-pagination"
        />
      </PageContainer>
    );
  }

  return (
    <PageContainer prefix="gateway-hub-page" data-testid="gateway-hub-container">
      <GatewayHubHeader />
      <GatewayList
        rows={gatewayQuery.data?.data || []}
        isLoading={gatewayQuery.isLoading}
        isError={gatewayQuery.isError}
        pagination={pagination}
        changePage={changePage}
        changePageSize={changePageSize}
        refreshData={refreshData}
      />
    </PageContainer>
  );
};
