import { TDeviceTypeSchema } from '@marlin/asset/data-access/device';
import { MarlinTheme } from '@marlin/shared/theme';
import { getUserTimezone } from '@marlin/shared/utils-common-date';
import { TUnitOfMeasure, parseDisplayedValue } from '@marlin/shared/utils-format-reading';
import { useTheme } from '@mui/material/styles';
import { ApexOptions } from 'apexcharts';
import { useMemo } from 'react';

import { content } from '../../../../content';
import { RANGE_FILTER } from '../../constant';
import { ICharTicks } from '../../types';
import {
  EXTENDED_RANGE_FILTER,
  chartCurve,
  dateFormat,
  leakChartCurve,
  leakChartMax,
  leakChartMin,
  leakChartTicks,
  lineMarkerSize,
  measureChartTicks,
  scatterMarkerSize,
  strokeWidth,
  timeFormat,
  xAxisBorder,
  xAxisType,
  yAxisBorder,
} from './chart-constants';
import { renderTooltip } from './chart-tooltip.component';

type TChartType = 'scatter' | 'line';

interface IChartOptions {
  rangeFilter: RANGE_FILTER | EXTENDED_RANGE_FILTER;
  deviceType?: TDeviceTypeSchema;
  timeRange: number[];
  chartType: TChartType;
  timeZoneAbbr: string;
  uoM?: TUnitOfMeasure | null;
}

interface IMeasureChartOptions extends IChartOptions {
  ticks: ICharTicks;
  isExpanded?: boolean;
}

export const useCommonChartOptions = ({
  rangeFilter,
  deviceType,
  timeRange,
  chartType,
  timeZoneAbbr,
  uoM,
}: IChartOptions) => {
  const theme = useTheme<MarlinTheme>();
  return useMemo(
    () => ({
      chart: {
        toolbar: {
          show: false,
        },
        type: chartType,
        animations: {
          enabled: false,
        },
        zoom: {
          enabled: false,
        },
      },
      tooltip: {
        custom: renderTooltip({
          rangeFilter,
          deviceType,
          theme,
          uoM,
        }),
      },
      yaxis: {
        axisBorder: yAxisBorder,
        opposite: true,
        showForNullSeries: false,
        labels: {
          formatter: (value: number) =>
            parseDisplayedValue(
              value.toString(),
              deviceType === 'LEAK' ? 'WaterDetect' : uoM ?? '',
              undefined,
              'decimal'
            ),
        },
      },
      markers: {
        size: chartType === 'scatter' ? scatterMarkerSize : lineMarkerSize,
        colors: theme.palette.primary.dark,
        strokeWidth: 0,
        strokeOpacity: 0,
        hover: {
          sizeOffset: 1,
        },
      },
      xaxis: {
        title: {
          text: content.TIMEZONE(timeZoneAbbr),
          style: {
            fontSize: `${theme.typography.fontSize}px`,
            fontWeight: theme.typography.fontWeightRegular,
          },
        },
        axisBorder: xAxisBorder,
        type: xAxisType,
        min: timeRange[0],
        max: timeRange[1],
        labels: {
          format: rangeFilter === RANGE_FILTER.DAYS_7 ? dateFormat : timeFormat,
          datetimeUTC: getUserTimezone() === 'UTC',
        },
      },
      stroke: {
        width: strokeWidth,
        curve: chartCurve,
      },
      colors: [theme.palette.primary.dark],
    }),
    [chartType, deviceType, rangeFilter, theme, timeRange, timeZoneAbbr, uoM]
  );
};

export const useMeasureChartOptions = ({ ticks, isExpanded, ...props }: IMeasureChartOptions) => {
  const commonChartOptions = useCommonChartOptions(props);

  return useMemo(
    () => ({
      ...commonChartOptions,
      yaxis: {
        ...commonChartOptions.yaxis,
        min: isExpanded ? undefined : ticks.lowest,
        max: isExpanded ? undefined : ticks.highest,
        tickAmount: measureChartTicks,
      },
    }),
    [commonChartOptions, isExpanded, ticks.highest, ticks.lowest]
  );
};

export const useLeakChartOptions = (props: IChartOptions) => {
  const commonChartOptions = useCommonChartOptions(props);

  return useMemo(
    () => ({
      ...commonChartOptions,
      yaxis: {
        ...commonChartOptions.yaxis,
        min: leakChartMin,
        max: leakChartMax,
        tickAmount: leakChartTicks,
        labels: {
          formatter: (value: number) => parseDisplayedValue(value.toString(), 'WaterDetect'),
        },
      },
      stroke: {
        ...commonChartOptions.stroke,
        curve: leakChartCurve,
      },
    }),
    [commonChartOptions]
  );
};

export const useFlowChartOptions = ({ ticks, isExpanded, ...props }: IMeasureChartOptions) => {
  const commonChartOptions = useCommonChartOptions(props);

  return useMemo(
    () => ({
      ...commonChartOptions,
      yaxis: {
        ...commonChartOptions.yaxis,
        min: isExpanded ? undefined : ticks.lowest,
        max: isExpanded ? undefined : ticks.highest,
        tickAmount: measureChartTicks,
      },
    }),
    [commonChartOptions, isExpanded, ticks.highest, ticks.lowest]
  );
};

const selectOptionsHook = (deviceType?: TDeviceTypeSchema) => {
  switch (deviceType) {
    case 'LEAK': {
      return useLeakChartOptions;
    }
    case 'TEMPERATURE':
    case 'PRESSURE': {
      return useMeasureChartOptions;
    }
    case 'PULSE_METER': {
      return useFlowChartOptions;
    }
    default: {
      return useCommonChartOptions;
    }
  }
};

export const useChartOptions = ({
  deviceType,
  timeRange,
  ticks,
  rangeFilter,
  chartType,
  timeZoneAbbr,
  uoM,
}: IMeasureChartOptions): ApexOptions => {
  const useOptions = useMemo(() => selectOptionsHook(deviceType), [deviceType]);

  return useOptions({
    deviceType,
    timeRange,
    ticks,
    rangeFilter,
    chartType,
    timeZoneAbbr,
    uoM,
  });
};
