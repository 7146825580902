import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: theme.typography.pxToRem(1000),
    maxWidth: '100vw',
    backgroundColor: theme.palette.background.primary,
    // todo (THEME_COLORS_FIX) replace rgba colors with one from palette
    boxShadow: `0 ${theme.typography.pxToRem(11)} ${theme.typography.pxToRem(15)} ${theme.typography.pxToRem(
      -7
    )} rgba(0, 0, 0, 0.2), 0 ${theme.typography.pxToRem(24)} ${theme.typography.pxToRem(38)} ${theme.typography.pxToRem(
      3
    )} rgba(0, 0, 0, 0.14), 0 ${theme.typography.pxToRem(9)} ${theme.typography.pxToRem(46)} ${theme.typography.pxToRem(
      8
    )} rgba(0, 0, 0, 0.12)`,
    borderRadius: theme.typography.pxToRem(4),
    [theme.breakpoints.down('md')]: {
      width: `calc(100% - ${theme.typography.pxToRem(32)})`,
      marginRight: theme.typography.pxToRem(16),
    },
  },

  header: {
    margin: `${theme.typography.pxToRem(16)} ${theme.typography.pxToRem(24)}`,
    display: 'flex',
    justifyContent: 'space-between',
  },

  headerSection: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.typography.pxToRem(8),
  },

  headerStatus: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(14),
    marginRight: theme.typography.pxToRem(12),
  },

  sectionContent: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.typography.pxToRem(8),
    marginBottom: theme.typography.pxToRem(16),
  },

  sectionWide: {
    gap: theme.typography.pxToRem(16),
  },

  manualLink: {
    marginBottom: theme.typography.pxToRem(14),
  },

  sectionDate: {
    fontSize: theme.typography.pxToRem(13),
    lineHeight: theme.typography.pxToRem(32),
  },

  dateLabel: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.text.secondary,
    paddingRight: theme.typography.pxToRem(8),
    letterSpacing: theme.typography.pxToRem(1),
  },

  body: {
    display: 'flex',
    padding: `${theme.typography.pxToRem(24)} 0`,
    fontSize: theme.typography.pxToRem(14),
  },

  bodySectionOne: {
    padding: `0 ${theme.typography.pxToRem(24)}`,
    minWidth: theme.typography.pxToRem(300),
  },

  bodySectionTwo: {
    padding: `0 ${theme.typography.pxToRem(24)}`,
    flexGrow: 1,
  },

  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.typography.pxToRem(24),
  },

  footer: {
    display: 'flex',
    justifyContent: 'end',
    padding: theme.typography.pxToRem(8),
    '& > button': {
      color: theme.palette.primary.main,
    },
  },
}));
