import {
  DeleteDeviceModalBody,
  DeleteDeviceModalFooter,
  DeleteDeviceModalTitle,
} from '@marlin/asset/ui/delete-device-modal';
import { useNavigation } from '@marlin/shared/ui-device-drawer-mobile';
import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import { PERMISSIONS, Restricted } from '@marlin/shared/utils-permission';
import { useRouter, useSearchParamsRepository } from '@marlin/shared/utils-router';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { IconButton, useMediaQuery, useTheme } from '@mui/material';
import { FC, PropsWithChildren, useCallback, useContext } from 'react';

import { useDeviceDetailsContext } from '../../context/device-details.context';
import { useSensorId } from '../../hooks/use-sensor-id.hook';
import { PinDevice } from '../pin-device/pin-device.component';
import { useStyles } from './header.component.styles';

interface IDeviceDrawersParams {
  sensorId?: string;
  tab?: string;
}

const Header: FC<PropsWithChildren> = ({ children }) => {
  const { classes } = useStyles();
  const { removeSearchParam } = useSearchParamsRepository<IDeviceDrawersParams>();

  const router = useRouter();
  const deviceId = useSensorId();
  const deviceDetails = useDeviceDetailsContext();
  const { getDeviceEditPageLink } = useNavigation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { modalDispatch } = useContext(ModalContext);

  const handleGoToEditDevice = useCallback(() => {
    router.goTo(getDeviceEditPageLink(deviceId));
  }, [deviceId, getDeviceEditPageLink, router]);

  const close = useCallback(() => {
    removeSearchParam('sensorId');
    removeSearchParam('tab');
  }, [removeSearchParam]);

  const deleteDevice = () => {
    modalDispatch({
      type: MODAL_ACTION_TYPE.SHOW,
      payload: {
        title: <DeleteDeviceModalTitle />,
        body: <DeleteDeviceModalBody deviceName={deviceDetails?.name || ''} deviceId={deviceId} />,
        footer: <DeleteDeviceModalFooter deviceId={deviceId} />,
      },
    });
  };

  if (!deviceDetails) {
    return null;
  }

  return (
    <>
      <div className={classes.controls}>
        <div>
          <IconButton aria-label="close-drawer" onClick={close} data-testid="close-button">
            <CloseRoundedIcon className={classes.closeIcon} />
          </IconButton>
          {children}
        </div>
        <div>
          {!isMobile && (
            <span className={classes.pinIcon}>
              <PinDevice deviceId={deviceId} />
            </span>
          )}
          <Restricted to={PERMISSIONS.SHOW_DEVICE_DRAWER_DELETE_ICON}>
            <IconButton aria-label="delete-device" onClick={deleteDevice} data-testid="delete-button">
              <DeleteRoundedIcon className={classes.deleteIcon} />
            </IconButton>
          </Restricted>
          <Restricted to={PERMISSIONS.SHOW_DEVICE_DRAWER_EDIT_ICON}>
            <IconButton aria-label="edit-device" onClick={handleGoToEditDevice} data-testid="edit-button">
              <EditRoundedIcon className={classes.editIcon} />
            </IconButton>
          </Restricted>
        </div>
      </div>
    </>
  );
};

export { Header };
