export const content = {
  LOCATIONS_HUB_TITLE: 'Locations',
  LOCATIONS_HUB_SUBTITLE: 'Use the data table below to manage your locations.',
  NO_DATA: 'No results found.',
  ERROR: 'Something went wrong. Please try again later.',
  CREATE_NEW_BUTTON: 'Create New',
  SEARCH_LOCATION: 'Search by location',
  PARENT_LOCATION_LABEL: 'Parent Location',
  CLEAR_ALL: 'Clear All',
  ID_HEADER_NAME: 'ID',
  LOCATION_NAME_HEADER_NAME: 'Location Name',
  PARENT_LOCATION_HEADER_NAME: 'Parent Location',
  EQUIPMENT_HEADER_NAME: 'Equipment',
  SENSORS_HEADER_NAME: 'Sensors',
  UNKNOWN_OPTION: 'Unknown option',
  EMPTY_LIST: 'No results found.',
  SORTING_LOCATION_NAME: 'Location Name',
  SORTING_PARENT_LOCATION: 'Parent Location',
  SORTING_EQUIPMENT_NUMBER: 'Equipment Number',
  SORTING_SENSORS_NUMBER: 'Sensors Number',
};
