import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import ZoomInMapRoundedIcon from '@mui/icons-material/ZoomInMapRounded';
import ZoomOutMapRoundedIcon from '@mui/icons-material/ZoomOutMapRounded';
import { Button, IconButton } from '@mui/material';
import { useCallback, useEffect } from 'react';

import { content } from '../../content';
import { useStyles } from './options.styles';

interface IOptionsProps {
  onDownloadClick: () => void;
  onFullScreenOpen: () => void;
  onFullScreenClose: () => void;
  isFullScreenOpen: boolean;
}

export const Options = ({ onDownloadClick, onFullScreenOpen, onFullScreenClose, isFullScreenOpen }: IOptionsProps) => {
  const { classes } = useStyles();

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape' && isFullScreenOpen) {
        onFullScreenClose();
      }
    },
    [isFullScreenOpen, onFullScreenClose]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <div className={classes.wrapper}>
      <IconButton className={classes.icon} disableRipple color="primary" onClick={onDownloadClick}>
        <GetAppRoundedIcon />
      </IconButton>
      {isFullScreenOpen ? (
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={onFullScreenClose}
          endIcon={<ZoomInMapRoundedIcon />}
        >
          {content.EXIT_FULL_SCREEN}
        </Button>
      ) : (
        <IconButton className={classes.icon} disableRipple color="primary" onClick={onFullScreenOpen}>
          <ZoomOutMapRoundedIcon />
        </IconButton>
      )}
    </div>
  );
};
