import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.typography.pxToRem(16),
    marginLeft: theme.typography.pxToRem(16),
  },
  buttonWithIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.typography.pxToRem(8),
  },
  showLabel: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(24),
    letterSpacing: theme.typography.pxToRem(0.4),
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightMedium,
  },
  switches: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.typography.pxToRem(16),
  },
  switchLabel: {
    margin: 0,
  },
  bottomWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  chartIcon: {
    height: theme.typography.pxToRem(32),
    width: 'auto',
  },
  modal: {
    backgroundColor: theme.palette.background.secondary,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: theme.typography.pxToRem(4),
    padding: theme.typography.pxToRem(16),
    [theme.breakpoints.down('md')]: {
      width: `calc(100% - ${theme.typography.pxToRem(32)})`,
      marginRight: theme.typography.pxToRem(16),
    },
  },
  resetViewButton: {
    whiteSpace: 'nowrap',
  },
  filters: {
    display: 'flex',
    flexDirection: 'row',

    [theme.breakpoints.down('md')]: {
      marginTop: theme.typography.pxToRem(16),
      marginBottom: theme.typography.pxToRem(8),
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      gap: theme.typography.pxToRem(16),
    },
  },
}));
