import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  tableWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    paddingTop: theme.typography.pxToRem(24),
  },

  dataGrid: {
    borderLeft: 0,
    borderRight: 0,
    borderBottom: 0,
    borderRadius: 0,

    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: theme.palette.background.alternative,
    },

    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
  },

  dataGridWrapper: {
    width: '100%',
  },
}));
