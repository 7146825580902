import { useAssetLinks } from '@marlin/asset/data-access/asset';
import { EQUIPMENT_TYPE } from '@marlin/shared/utils/zod';
import { useMemo } from 'react';

import { content } from '../content';
import { ModalBody } from '../delete-modal.body';
import { useStyles } from '../delete-modal.styles';

export interface IDeregisterEquipmentModalBodyProps {
  assetId: string | undefined;
  deleteAssetName: string | undefined;
  type?: EQUIPMENT_TYPE;
}

export const DeregisterEquipmentModalBody = ({
  deleteAssetName,
  assetId,
  type,
}: IDeregisterEquipmentModalBodyProps) => {
  const { classes } = useStyles();
  const assetLinksQuery = useAssetLinks({ assetId: assetId ?? '', enabled: !!assetId });

  const isLoading = useMemo(() => {
    return assetLinksQuery.isLoading || assetLinksQuery.isInitialLoading;
  }, [assetLinksQuery.isLoading, assetLinksQuery.isInitialLoading]);

  return (
    <div data-testid="deregister-modal-body" className={classes.modalBody}>
      <ModalBody
        deleteAssetName={deleteAssetName}
        canDelete
        isLoading={isLoading}
        deleteAssetId={assetId}
        assetLinks={type === 'LeakPrevention' ? undefined : assetLinksQuery.data}
        flowLinkInfo={content.DEREGISTER_FLOW_LINKS_INFO}
        flowLinksConfirmation={content.DEREGISTER_FLOW_LINKS_CONFIRMATION}
        confirmationMessage={
          type === 'LeakPrevention'
            ? content.DEREGISTER_SENTINEL_EQUIPMENT_CONFIRMATION
            : content.DEREGISTER_EQUIPMENT_CONFIRMATION
        }
      />
    </div>
  );
};
