import { MarlinTheme } from '@marlin/shared/theme';
import AddIcon from '@mui/icons-material/Add';
import DeviceHubRoundedIcon from '@mui/icons-material/DeviceHubRounded';
import { IconButton } from '@mui/material';
import { Handle, NodeProps, NodeToolbar, Position, useViewport } from '@xyflow/react';
import { makeStyles } from 'tss-react/mui';

import { useCommonStyles } from './common.styles';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  equipment: {
    borderStyle: 'solid',
    borderColor: theme.palette.systemMap.main,
    borderWidth: theme.typography.pxToRem(1),
    borderRadius: theme.typography.pxToRem(8),
    backgroundColor: theme.palette.background.primary,
    padding: theme.typography.pxToRem(8),
  },
  emptyEquipment: {
    width: 'auto',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  equipmentLabel: {
    marginRight: theme.typography.pxToRem(8),
    whiteSpace: 'nowrap',
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
}));

export const EquipmentNode = ({ id }: NodeProps) => {
  const { classes, cx } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const { zoom } = useViewport();

  return (
    <div
      className={cx(commonClasses.node, classes.equipment, classes.emptyEquipment)}
      data-testid={`equipment-node-${id}`}
    >
      <div className={commonClasses.nodeContent}>
        <span className={classes.equipmentLabel} data-testid={`equipment-node-${id}-label`}>
          Equipment
        </span>
        <DeviceHubRoundedIcon />
      </div>
      <Handle type="target" id="top" position={Position.Top} />
      <Handle type="target" id="left" position={Position.Left} />
      <Handle type="source" id="right" position={Position.Right} />
      <Handle type="source" id="bottom" position={Position.Bottom} />
      <NodeToolbar isVisible position={Position.Right} offset={0}>
        <IconButton
          color="secondary"
          aria-label="add an alarm"
          style={{ transform: `scale(${zoom}) translate(-24px, 0) translate(${zoom * 18}px, 0)` }}
        >
          <AddIcon />
        </IconButton>
      </NodeToolbar>
    </div>
  );
};
