import { MarlinTheme } from '@marlin/shared/theme';
import { Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import kebabCase from 'lodash/kebabCase';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  exportButton: {
    margin: `0 ${theme.typography.pxToRem(16)}`,
  },
}));

export const ExportCsvButton = ({
  onExportCsvClick,
  buttonClassName,
  disabled,
  name,
}: {
  disabled: boolean;
  onExportCsvClick: () => void;
  buttonClassName: string;
  name: string;
}) => {
  const { classes, cx } = useStyles();

  if (disabled) {
    return (
      <Tooltip title={content.EXPORT_DISABLED_TOOLTIP}>
        <div>
          <Button
            variant="outlined"
            className={cx(buttonClassName, classes.exportButton)}
            data-testid={`export-csv-button-${kebabCase(name)}`}
            onClick={onExportCsvClick}
            disabled
          >
            {content.EXPORT_CSV}
          </Button>
        </div>
      </Tooltip>
    );
  }

  return (
    <Button
      variant="outlined"
      className={cx(buttonClassName, classes.exportButton)}
      data-testid={`export-csv-button-${kebabCase(name)}`}
      onClick={onExportCsvClick}
    >
      {content.EXPORT_CSV}
    </Button>
  );
};
