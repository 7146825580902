import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    alignSelf: 'center',
    flexDirection: 'column',
    width: theme.typography.pxToRem(676),
    height: '100%',
    [theme.breakpoints.down('md')]: {
      width: '100vw',
      paddingLeft: theme.typography.pxToRem(16),
      paddingRight: theme.typography.pxToRem(16),
    },
  },
  stepperWrapper: {
    display: 'flex',
    alignSelf: 'center',
    width: theme.typography.pxToRem(820),
    [theme.breakpoints.down('md')]: {
      width: '100vw',
      paddingLeft: theme.typography.pxToRem(16),
      paddingRight: theme.typography.pxToRem(16),
    },
  },
  stepLabel: {
    '&.Mui-active': {
      color: theme.palette.primary.main,
    },
  },
}));
