import { useCurrentUser } from '@marlin/account-data-access-organization';
import { MarlinTheme } from '@marlin/shared/theme';
import {
  DeviceUserMeasuresPreferences,
  TUnitOfMeasure,
  parseDisplayedValue,
} from '@marlin/shared/utils-format-reading';
import { Slider } from '@mui/material';
import isNaN from 'lodash/isNaN';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../content';

interface IValueSliderProps {
  value: number;
  unitOfMeasure: TUnitOfMeasure | null;
  onChange: (e: Event, value: number | number[]) => void;
  min: number;
  max: number;
  step?: number;
  isThresholdExceeded: boolean;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  markLabel: {
    display: 'inline-block',
    width: '50%',
    marginTop: theme.typography.pxToRem(20),
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    textAlign: 'left',
  },
  markLabelRight: {
    textAlign: 'right',
  },
}));

export const ValueSlider = ({
  max,
  min,
  step,
  onChange,
  value,
  unitOfMeasure,
  isThresholdExceeded,
}: IValueSliderProps) => {
  const { classes, cx } = useStyles();
  const { data: { settings = {} } = {} } = useCurrentUser();
  const parsedUserMeasuresPreferences = DeviceUserMeasuresPreferences.parse(settings);

  const sliderMarks = [
    {
      value: min,
      label: content.MIN_VALUE(parseDisplayedValue(String(min), unitOfMeasure, parsedUserMeasuresPreferences)),
    },
    {
      value: max,
      label: content.MAX_VALUE(parseDisplayedValue(String(max), unitOfMeasure, parsedUserMeasuresPreferences)),
    },
  ];

  return (
    <Slider
      value={value}
      onChange={onChange}
      step={step ?? 1}
      min={min}
      max={max}
      valueLabelDisplay="on"
      valueLabelFormat={(value) => {
        const labelValue = getLabelValue(value, min, max);
        return step === 0.5 ? labelValue.toFixed(1) : labelValue.toString();
      }}
      marks={sliderMarks}
      data-testid="value-slider"
      slots={{
        markLabel: ({ children, ...props }) => {
          const className =
            props['data-index'] === 1 ? cx(classes.markLabel, classes.markLabelRight) : classes.markLabel;

          return (
            <span {...props} className={className} data-testid="value-slider-mark-label">
              {children}
            </span>
          );
        },
      }}
      slotProps={{
        input: {
          role: 'slider',
        },
      }}
      color={isThresholdExceeded ? 'error' : 'primary'}
    />
  );
};

const getLabelValue = (value: number, min: number, max: number) => {
  if (isNaN(value) || value < min) {
    return min;
  }

  return value > max ? max : value;
};
