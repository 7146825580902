import { z } from 'zod';

import { content } from '../content';

const minCharacters = 1;
const maxCharacters = 100;

export const requiredTextFieldSchema = z
  .string()
  .min(minCharacters, content.TEXT_VALIDATION.REQUIRED)
  .max(maxCharacters, content.TEXT_VALIDATION.MAX_LENGTH(maxCharacters));

export const emailFieldSchema = z
  .string()
  .min(minCharacters, content.TEXT_VALIDATION.REQUIRED)
  .email(content.TEXT_VALIDATION.EMAIL)
  .max(maxCharacters, content.TEXT_VALIDATION.MAX_LENGTH(maxCharacters));
