import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  bodyContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(20),
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: theme.typography.pxToRem(32),
    letterSpacing: theme.typography.pxToRem(0.15),
    color: theme.palette.text.primary,
    fontFeatureSettings: "'liga' off, 'clig' off",
  },
  sectionTitle: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(16),
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: theme.typography.pxToRem(25.6),
    letterSpacing: theme.typography.pxToRem(0.15),
    color: theme.palette.text.primary,
    fontFeatureSettings: "'liga' off, 'clig' off",
  },
  description: {
    color: theme.palette.text.primary,
    fontFeatureSettings: "'liga' off, 'clig' off",
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(16),
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: theme.typography.pxToRem(24),
    letterSpacing: theme.typography.pxToRem(0.15),
    marginTop: theme.typography.pxToRem(4),
    marginBottom: theme.typography.pxToRem(16),
  },
  inputsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.typography.pxToRem(16),
    marginTop: theme.typography.pxToRem(8),
  },
  costInput: {
    flex: '40%',
  },
  unitInput: {
    flex: '60%',
  },
  buttons: {
    display: 'flex',
    gap: theme.typography.pxToRem(16),
    marginTop: theme.typography.pxToRem(8),
    justifyContent: 'flex-end',
  },
}));
