import { TExtractFnReturnType, TQueryConfig, useQuery } from '@marlin/shared/utils/react-query';

import { getLocationHierarchy } from '../infrastructure/hierarchy';

type TQueryFnType = typeof getLocationHierarchy;

type TUseLocationOptions = {
  locationId: string;
  config?: TQueryConfig<TQueryFnType>;
};

export const useLocationHierarchy = ({ locationId, config }: TUseLocationOptions) => {
  return useQuery<TExtractFnReturnType<TQueryFnType>>({
    ...config,
    queryKey: ['location', locationId, 'hierarchy'],
    queryFn: () => getLocationHierarchy({ locationId }),
  });
};
