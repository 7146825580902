import { FormField, Input, Select } from '@marlin/shared/ui-form-common';
import { Paper } from '@marlin/shared/ui-page';
import { Button } from '@mui/material';
import { FormProvider } from 'react-hook-form';

import { predefinedIssueCategories } from '../../consts';
import { content } from '../../content';
import { useStyles } from './help-form.component.styles';
import { IHelp, useHelpForm } from './use-help-form.hook';

interface IUpsertDeviceProps {
  firstName: string;
  lastName: string;
  onSuccess: (value: boolean) => void;
}

const createDefaultValues = (firstName: string | undefined, lastName: string | undefined) => {
  return {
    name: `${firstName} ${lastName}`,
    category: '' as const,
    description: '',
  };
};

export const HelpForm = ({ firstName, lastName, onSuccess }: IUpsertDeviceProps) => {
  const { classes, cx } = useStyles();

  const { form, onSubmit, onCancel, isLoading } = useHelpForm(onSuccess, createDefaultValues(firstName, lastName));

  return (
    <Paper className={classes.formContainer}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className={classes.formSection}>{content.DESCRIBE_INQUIRY}</div>
          <FormField<IHelp> fieldName="name">
            {(props) => <Input {...props} className={classes.input} label={content.NAME} disabled />}
          </FormField>

          <FormField<IHelp> fieldName="category">
            {(props) => (
              <div className={classes.selectWrapper}>
                <Select
                  {...props}
                  prefix="category"
                  label={content.ISSUE_CATEGORY}
                  data={predefinedIssueCategories || []}
                  className={classes.select}
                  required
                  onChange={(e: unknown) => {
                    props.onChange && props.onChange(e);
                  }}
                />
              </div>
            )}
          </FormField>

          <FormField<IHelp> fieldName="description">
            {(props) => (
              <Input
                {...props}
                maxLength={5000}
                className={classes.input}
                label={content.DESCRIPTION}
                multiline
                required
              />
            )}
          </FormField>

          <div className={classes.buttonWrapper}>
            <Button className={cx(classes.button, classes.buttonCancel)} variant="outlined" onClick={onCancel}>
              {content.CANCEL}
            </Button>
            <Button
              className={cx(classes.button, classes.buttonSend)}
              variant="contained"
              onClick={form.handleSubmit(onSubmit)}
              disabled={!form.formState.isDirty || !form.formState.isValid || isLoading}
            >
              {content.SEND}
            </Button>
          </div>
        </form>
      </FormProvider>
    </Paper>
  );
};
