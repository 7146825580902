import { useState } from 'react';

export const useEnableOption = () => {
  const [enabled, setEnabled] = useState(false);

  const enableOptions = () => {
    setEnabled(true);
  };

  return {
    enabled,
    enableOptions,
  };
};
