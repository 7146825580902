import { Observable, Subscription, lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';

import { IIdentityClaims, IStorageService } from './models/auth.model';
import { IAuthFacade, TAccountInfo } from './models/facade.model';
import { AuthService } from './services/auth.service';
import { LogoutTimerService } from './services/logout-timer.service';

export class AuthFacade implements IAuthFacade {
  public constructor(
    private readonly authService: AuthService,
    private readonly logoutTimerService: LogoutTimerService,
    private readonly storageService: IStorageService
  ) {}

  public getSignedInState(): Observable<boolean> {
    return this.authService.initLoginFlow();
  }

  public isSignedIn = async (): Promise<boolean> => {
    const isSignedIn = await this.authService.initLoginFlow().toPromise();

    return !!isSignedIn;
  };

  public initLoginFlow = (): Observable<boolean> => this.authService.initLoginFlow();

  public logOut = (path?: string): void => {
    this.storageService.clearKey('portfolioId');
    this.logoutTimerService.resetTimer();
    this.authService.logOut(path);
  };

  public getIdentityClaims = (): IIdentityClaims | null => this.authService.getIdentityClaims();

  public getCurrentAccount = (): TAccountInfo<IIdentityClaims> | null => this.authService.getCurrentAccount();

  public getAccessToken = (): Observable<string | undefined> => this.authService.getAccessToken();

  public getAccessTokenPromise = (): Promise<string> => {
    return lastValueFrom(this.getAccessToken().pipe(map((token) => (token ? token : ''))));
  };
  public subscribeToLogout = (complete: () => void): Subscription => this.authService.subscribeToLogout(complete);

  public signIn(): void {
    throw new Error('Method not implemented.');
  }

  public getAuthInProgress$(): Observable<boolean> {
    throw new Error('Method not implemented.');
  }
}
