export const content = {
  TITLE: 'Select one or multiple flow meters for total water usage',
  CANCEL: 'Cancel',
  SAVE: 'Save',
  LOCATION: 'LOCATION',
  CHANGE_FLOW_METER_SUCCESS: 'Flow Meter for Total Water Usage has been changed',
  CHANGE_FLOW_METER_ERROR: "Something went wrong. Flow Meter for Total Water Usage hasn't been changed",
  SELECTED_FLOW_METERS: 'Selected Flow Meter(s)',
  CHANGING_FLOW_METER_SUBTITLE: 'Changing flow meters will change the trends on the homepage',
};
