export const content = {
  TITLE: 'Flow Links',
  FLOW_LINK_OUTLET_TYPE_TABLE_HEADER_NAME: 'Type',
  FLOW_LINK_INLET_TYPE_TABLE_HEADER_NAME: 'Type',
  FLOW_LINK_OUTLET_NAME_TABLE_HEADER_NAME: 'Outlet name',
  FLOW_LINK_INLET_NAME_TABLE_HEADER_NAME: 'Inlet name',
  NO_DATA: 'No results found.',
  ERROR: 'Something went wrong. Please try again later.',
  ARROWS: '>>>>',
  SENSOR: 'Sensor',
  EQUIPMENT: 'Equipment',
  LOCATION: 'Location',
  TEE: 'Tee',
  ATTACHMENT_LABEL: 'Att',
  TEMPERATURE_LABEL: 'Temperature Sensor',
  PRESSURE_LABEL: 'Pressure Sensor',
  FLOW_LABEL: 'Flow Meter',
  LEAK_LABEL: 'Leak Detector',
  WATER_SYSTEM: 'Water system',
  REGULATOR: 'Regulator',
  DELETE_FLOW_LINK_TITLE: 'Delete flow link?',
  DELETE_FLOW_LINK_MESSAGE: 'Are you sure you want to delete the flow link?',
  DELETE_FLOW_LINK_MESSAGE_BOLD: "This action can't be undone.",
  DELETE_FLOW_LINK_CONFIRM: 'delete',
  DELETE_FLOW_LINK_CANCEL: 'cancel',
};
