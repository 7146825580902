export const content = {
  PLANT_LIST: {
    HEADER_BOILER: 'Boiler Plant Equipment',
    HEADER_WATER_HEATER: 'Water Heater Plant Equipment',
    OFFLINE: 'Offline',
    OPERATIONAL: 'Operational',
    MODES: {
      DISABLED: 'Disabled',
      STANDBY: 'Standby',
      MANUAL: 'Manual',
      REMOTE: 'Remote',
      AUTO: 'Auto',
      FAULT: 'Fault',
    },
    SETPOINT: 'Setpoint',
    OUTLET: 'Outlet',
    BURNER: 'Burner',
    ON: 'On',
    OFF: 'Off',
    MANAGER: 'Manager',
    BACK_UP_MANAGER: 'Back Up Manager',
    EMPTY_DATAPOINT_VALUE: '--',
    CONNECTION_ERROR: 'There is no connection. Check your connection and try to refresh the page.',
    EMPTY_LIST: 'No results found.',
  },
  CHART_TITLE: 'Plant Monitor',
  MOBILE_CHART_TITLE: 'Monitor',
  BOILER_TITLE: 'Boiler Plant Performance',
  WATER_HEATER_TITLE: 'Water Heater Plant Performance',
  SUBTITLE: 'Real time data and trends for this plant.',
  OVERVIEW_TITLE: 'Plant Settings & Readings',
  CONNECTION_ERROR: 'There is no connection. Check your connection and try to refresh the page.',
  DATAPOINT_LABELS: {
    SETPOINT_TEMP: 'Setpoint',
    OUTLET_TEMP: 'Outlet',
    RETURN_WTR: 'Return wtr in',
    INLET: 'Inlet',
    FIRING_RATE: 'Fire Rate',
  },
  EMPTY_DATAPOINT_VALUE: '--',
  EDIT_BUTTON: 'Edit',
};
