import { MarlinTheme } from '@marlin/shared/theme';
import { DataLoader } from '@marlin/shared/ui-loader';
import { PageContainer, PageHeader, Paper } from '@marlin/shared/ui-page';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { useAuth } from '@marlin/shared/utils-auth';
import { TMultiOrgRole } from '@marlin/shared/utils-role';
import { goToSupport } from '@marlin/shared/utils-router';
import { MutationStatus, UseMutateAsyncFunction } from '@marlin/shared/utils/react-query';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { OrganizationLoader } from './organization-loader/organization-loader.component';
import { content } from './translation-keys';
import { UserProfileUpdateForm } from './user-profile-update-form/user-profile-update-form.component';
import { IOrganizationUser, IUpdateUserProfile, IUser } from './user.model';

interface IUpdateUserProfileProps {
  user: IUser | undefined;
  redirectUrl: string;
  status: MutationStatus;
  mutateAsync: UseMutateAsyncFunction<
    | IOrganizationUser
    | (IOrganizationUser & {
        role: TMultiOrgRole;
      }),
    unknown,
    IUpdateUserProfile & { invitationId: string }
  >;
  isSupport?: boolean;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    margin: 'auto',
    width: theme.typography.pxToRem(712),
  },
}));

export function UpdateUserProfile({
  user,
  redirectUrl,
  status,
  mutateAsync,
  isSupport = false,
}: IUpdateUserProfileProps) {
  const { classes } = useStyles();
  const { invitationId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { logOut } = useAuth();
  const [currentOrgId, setCurrentOrgId] = useState<string | null>(null);

  const handleSubmit = async (data: IUpdateUserProfile) => {
    try {
      const user = await mutateAsync({ ...data, invitationId: invitationId ?? '' });
      if (user.currentOrganizationId) {
        setCurrentOrgId(user.currentOrganizationId);
      }

      if ('role' in user && ['Support', 'BUCS', 'CSM'].includes(user.role)) {
        goToSupport();
      }
    } catch (e) {
      enqueueSnackbar(content.ERROR_DEFAULT, {
        variant: 'error',
        preventDuplicate: true,
      });
    }
  };

  if (status === 'loading') {
    return <DataLoader />;
  }

  if (currentOrgId) {
    return <OrganizationLoader redirectUrl={redirectUrl} organizationId={currentOrgId} />;
  }

  return (
    <PageContainer prefix="update-user-profile" className={classes.container}>
      <PageHeader
        icon={null}
        title={content.UPDATE_USER_PROFILE_PAGE_TITLE}
        subtitle={content.UPDATE_USER_PROFILE_PAGE_SUBTITLE}
        prefix="update-user-profile-header"
      />
      <Paper data-testid="card">
        <UserProfileUpdateForm user={user} onSubmit={handleSubmit} onCancel={() => logOut()} isSupport={isSupport} />
      </Paper>
    </PageContainer>
  );
}
