import { TGraphLink } from '@marlin/system-map/data-access/system-map';
import { XYPosition, getSmoothStepPath, useReactFlow } from '@xyflow/react';
import { useCallback, useMemo } from 'react';

import { TSystemMapInternalNode } from '../../../types';
import { useTouchedState$ } from '../../buttons/use-observable-touched-state.hook';
import { getClosestHandleToPoint, getEdgeParams } from '../floating-edges-utils';
import { getLinearControlPoints, getLinearPath } from './linear';
import {
  getControlPoints,
  mapPointsToMidpoints,
  mapToControlPoints,
  mergePoints,
  moveSegment,
  resetPointIds,
} from './points.utils';
import { TControlPointData, TControlPointsUpdateMetadata, TEditableEdgeData } from './types';
import { getShiftVector, sameVectors, shiftPointsByVector } from './vector.utils';

interface IUsePathProps {
  id: string;
  data?: TGraphLink['data'] & TEditableEdgeData;
  sourceHandleId?: string | null;
  targetHandleId?: string | null;
  sourceNode?: TSystemMapInternalNode;
  targetNode?: TSystemMapInternalNode;
}

export const usePath = ({ id, data, sourceHandleId, targetHandleId, sourceNode, targetNode }: IUsePathProps) => {
  const { setEdges } = useReactFlow();
  const [, setTouched] = useTouchedState$(false);

  const closestSourcePos = getClosestHandleToPoint(sourceNode, data?.points?.[1], !!sourceHandleId);
  const closestTargetPos = getClosestHandleToPoint(
    targetNode,
    data?.points?.[data?.points?.length - 2],
    !!targetHandleId
  );

  const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(
    sourceNode,
    targetNode,
    sourceHandleId,
    targetHandleId
  );

  const [edgePath] = getSmoothStepPath({
    sourceX: closestSourcePos?.x || sx,
    sourceY: closestSourcePos?.y || sy,
    targetX: closestTargetPos?.x || tx,
    targetY: closestTargetPos?.y || ty,
    borderRadius: 0,
    sourcePosition: closestSourcePos?.position || sourcePos,
    targetPosition: closestTargetPos?.position || targetPos,
  });

  const [referenceEdgePath] = getSmoothStepPath({
    sourceX: sx,
    sourceY: sy,
    targetX: tx,
    targetY: ty,
    borderRadius: 0,
    sourcePosition: sourcePos,
    targetPosition: targetPos,
  });

  const sourceOrigin = useMemo(
    () => ({ x: closestSourcePos?.x || sx, y: closestSourcePos?.y || sy } as XYPosition),
    [closestSourcePos?.x, closestSourcePos?.y, sx, sy]
  );
  const targetOrigin = useMemo(
    () => ({ x: closestTargetPos?.x || tx, y: closestTargetPos?.y || ty } as XYPosition),
    [closestTargetPos?.x, closestTargetPos?.y, tx, ty]
  );

  const pathPoints = useMemo(() => {
    const edgePoints = getControlPoints(edgePath, id);
    let initialPathPoints = data?.points ?? edgePoints;

    if (data?.points) {
      const referenceEdgePoints = getControlPoints(referenceEdgePath, id);
      const shiftVectorStart = getShiftVector(data.points[0], referenceEdgePoints[0]);
      const shiftVectorEnd = getShiftVector(
        data.points[data.points.length - 1],
        referenceEdgePoints[referenceEdgePoints.length - 1]
      );
      if (!sameVectors(shiftVectorStart, shiftVectorEnd)) {
        return referenceEdgePoints;
      }

      if (sameVectors(shiftVectorStart, shiftVectorEnd) && (shiftVectorStart.x !== 0 || shiftVectorStart.y !== 0)) {
        initialPathPoints = shiftPointsByVector(initialPathPoints, shiftVectorStart);
        return initialPathPoints;
      }
    }

    return initialPathPoints;
  }, [data?.points, edgePath, id, referenceEdgePath]);

  const controlPoints = useMemo(() => {
    const midpoints = mapPointsToMidpoints(pathPoints);
    const midpointsWithId = mapToControlPoints(midpoints, id, 'midpoint');
    return getLinearControlPoints(midpointsWithId);
  }, [id, pathPoints]);

  const path = getLinearPath(pathPoints);

  const setControlPoint = useCallback(
    (
      update: (points: TControlPointData[]) => TControlPointData | undefined,
      { index, event }: TControlPointsUpdateMetadata
    ) => {
      setTouched(true);
      setEdges((edges) =>
        edges.map((e) => {
          if (e.id !== id) return e;

          const midpoint = update(controlPoints);
          if (!midpoint) return e;

          const newPoints = moveSegment(midpoint, pathPoints, index, controlPoints.length);

          const mergedPoints = mergePoints(newPoints, event === 'release');

          const newPointsWithIds = resetPointIds(mergedPoints, id);

          const points = [sourceOrigin, ...newPointsWithIds.slice(1, -1), targetOrigin];
          const data = { ...e.data, points: points.length > 0 ? points : undefined };

          return { ...e, data };
        })
      );
    },
    [controlPoints, id, pathPoints, setEdges, setTouched, sourceOrigin, targetOrigin]
  );

  return { path, controlPoints, pathPoints, setControlPoint };
};
