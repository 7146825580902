import { queryClient } from '@marlin/shared/utils/react-query';
import { UseMutationResult, useMutation } from '@marlin/shared/utils/react-query';

import { TGraph } from '../flow-map.model.schema';
import { IUpdateSystemMapNode, updateSystemMapNode } from '../infrastructure/update-node';
import { queryKey } from './query-key.enum';

export const useUpdateNode = (): UseMutationResult<TGraph, unknown, IUpdateSystemMapNode> => {
  return useMutation<TGraph, unknown, IUpdateSystemMapNode>({
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: queryKey.FLOW_LINK() });
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: queryKey.FLOW_LINK() });
      queryClient.invalidateQueries({ queryKey: queryKey.FLOW_LINKS() });
      queryClient.setQueryData(queryKey.GRAPH(), data);
    },
    mutationFn: updateSystemMapNode,
  });
};
