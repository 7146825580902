import { FeatureFlagsContext, TFeatureFlagsContext } from './feature-flags-context';

interface IFeatureFlagsContextProviderProps {
  featureFlags: TFeatureFlagsContext;
  children: React.ReactNode;
}

export const FeatureFlagsContextProvider = ({ featureFlags, children }: IFeatureFlagsContextProviderProps) => {
  return <FeatureFlagsContext.Provider value={featureFlags}>{children}</FeatureFlagsContext.Provider>;
};
