import { TLocationDetails, TSublocationAssetSchema } from '@marlin/asset/data-access/location';
import { IMobileFilterFormProps } from '@marlin/asset/shared/ui/mobile-filter-modal';
import { useMemo } from 'react';

import { IAsset } from '../desktop/components/grid/types';
import { Details } from './components/details/details.component';
import { LinkedItems } from './components/linked-items/linked-items.component';
import { TAB_VALUES } from './components/tabs/tabs.component';
import { ILocationDetailsFilters } from './location-details.component';
import { TSetMobileLocationDetailsFilters } from './types';

interface ILocationDetailsContentProps {
  location: TLocationDetails;
  tab: TAB_VALUES;
  rows: TSublocationAssetSchema[];
  deviceDrawerRows: TSublocationAssetSchema[];
  allowDelete: boolean;
  allowEdit: boolean;
  editAsset: (asset: IAsset) => void;
  handleAfterDelete: () => void;
  refetch: () => void;
  setMobileFilters: TSetMobileLocationDetailsFilters;
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  totalItems?: number;
  isListLoading: boolean;
  mobileFilterForm: IMobileFilterFormProps<'', ILocationDetailsFilters>;
}

export const LocationDetailsContent = ({
  location,
  tab,
  rows,
  allowDelete,
  allowEdit,
  refetch,
  handleAfterDelete,
  editAsset,
  setMobileFilters,
  hasNextPage,
  fetchNextPage,
  totalItems,
  isListLoading,
  deviceDrawerRows,
  mobileFilterForm,
}: ILocationDetailsContentProps) => {
  const content = useMemo(() => {
    switch (tab) {
      case TAB_VALUES.LinkedItems:
        return (
          <LinkedItems
            rows={rows}
            allowDelete={allowDelete}
            allowEdit={allowEdit}
            handleAfterDelete={handleAfterDelete}
            editAsset={editAsset}
            refetch={refetch}
            setMobileFilters={setMobileFilters}
            totalItems={totalItems}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            isListLoading={isListLoading}
            deviceDrawerRows={deviceDrawerRows}
            mobileFilterForm={mobileFilterForm}
          />
        );
      case TAB_VALUES.Details:
        return <Details location={location} />;
      default:
        return (
          <LinkedItems
            rows={rows}
            allowDelete={allowDelete}
            allowEdit={allowEdit}
            handleAfterDelete={handleAfterDelete}
            editAsset={editAsset}
            refetch={refetch}
            setMobileFilters={setMobileFilters}
            totalItems={totalItems}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            isListLoading={isListLoading}
            deviceDrawerRows={deviceDrawerRows}
            mobileFilterForm={mobileFilterForm}
          />
        );
    }
  }, [
    tab,
    rows,
    allowDelete,
    allowEdit,
    handleAfterDelete,
    editAsset,
    refetch,
    setMobileFilters,
    location,
    totalItems,
    hasNextPage,
    fetchNextPage,
    isListLoading,
    deviceDrawerRows,
    mobileFilterForm,
  ]);

  return content;
};
