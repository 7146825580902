import { z } from 'zod';

export const paginationSchema = z.object({
  pageSize: z.number(),
  page: z.number(),
  totalItems: z.number(),
});

// TODO: Fix this during the refactor of the create device form
export const emptyTextSchema = z
  .string()
  .nullish()
  .transform((text) => (!text ? undefined : text));

export const sortingSchema = z.object({
  direction: z.union([z.literal('Ascending'), z.literal('Descending')]),
  sortBy: z.string(),
});
