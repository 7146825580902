enum CHANNEL_TYPE {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  HOT_SOS = 'HOT_SOS',
}

export enum ALERT_STATUS {
  FAILED = 'FAILED',
  SENT = 'SENT',
  SNOOZED = 'SNOOZED',
  IN_PROGRESS = 'IN_PROGRESS',
}

export interface IHotSosChannel extends IChannel {
  type: CHANNEL_TYPE.HOT_SOS;
}

interface IChannel {
  type: string;
  errorMessage: string;
  status: ALERT_STATUS | null;
}
