import { MarlinTheme } from '@marlin/shared/theme';
import { TLastReadingValue } from '@marlin/system-map/data-access/system-map';
import { WarningAmberRounded } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../content';
import { formatRemainingTime } from '../utils/utils';
import { BaseHeader } from './base-header.component';
import { ModeValue } from './mode-value.component';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  title: {
    color: theme.palette.warning.dark,
    display: 'flex',
    alignItems: 'center',
    gap: theme.typography.pxToRem(4),
    fontSize: theme.typography.pxToRem(14),
  },
  values: {
    display: 'flex',
    gap: theme.typography.pxToRem(8),
  },
}));

interface IStartupHeaderProps {
  lastReadingValues: TLastReadingValue[];
  id: string;
}

export const StartupHeader = ({ lastReadingValues, id }: IStartupHeaderProps) => {
  const { classes } = useStyles();

  const overrideTime = lastReadingValues.find((reading) => reading.name === 'overrideTime');
  const formattedRemainingTime = overrideTime?.value ? formatRemainingTime(overrideTime.value) : undefined;

  return (
    <BaseHeader status="warning" testid="startup-header" equipmentId={id}>
      <div className={classes.title}>
        <WarningAmberRounded />
        {content.EQUIPMENT.START_UP_IN_PROGRESS}
      </div>
      <div className={classes.values}>
        {formattedRemainingTime && (
          <ModeValue label={content.EQUIPMENT.TIME_REMAINING}>{formattedRemainingTime}</ModeValue>
        )}
      </div>
    </BaseHeader>
  );
};
