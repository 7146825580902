import { SettingsGroupResponse } from '@marlin/shared/utils/datapoint-mappers';
import { TExtractFnReturnType, getHttpClient, useQuery } from '@marlin/shared/utils/react-query';
import { z } from 'zod';

import { paths } from '../infrastructure/api';
import { queryKey } from '../query-key.enum';

interface IUseGatewaySettingProps {
  gatewayId: string;
  onError?: () => void;
}

export const useGatewaySettings = ({ gatewayId, onError }: IUseGatewaySettingProps) => {
  return useQuery<TExtractFnReturnType<typeof getSettings>>({
    queryKey: queryKey.SETTINGS(gatewayId),
    queryFn: () => getSettings({ gatewayId }),
    onError: (error) => {
      // eslint-disable-next-line no-console
      if (error instanceof z.ZodError) console.log(error.issues);

      onError?.();
    },
    enabled: !!gatewayId,
  });
};

const getSettings = async ({ gatewayId }: { gatewayId: string }) => {
  const response = await getHttpClient().get(paths.GATEWAY_SETTINGS, {
    params: { gatewayId },
  });

  return SettingsGroupResponse.parse(response);
};
