import { content } from '../../../content';

export const conquestSectionConfig = [
  {
    title: content.SYSTEM_STATUS,
    style: { fullWidth: true },
    sectionDatapoints: [
      {
        sectionName: 'temperatureSensors',
        title: content.EQUIPMENT_READINGS_PVI.TEMPERATURES,
        items: [
          { name: 'MidTankTemperature', label: content.DATAPOINT_LABELS_PVI.MID_TANK },
          { name: 'ExhaustTemperature', label: content.DATAPOINT_LABELS_PVI.EXHAUST_TEMP },
        ],
      },
      {
        title: content.EQUIPMENT_READINGS_PVI.WATER_HEATER_PERFORMANCE,
        sectionName: 'boilerPerformance',
        items: [{ name: 'FlameStrength', label: content.DATAPOINT_LABELS_PVI.FLAME_STRENGTH }],
      },
    ],
    dataTestId: 'system-status-section',
  },
  {
    title: content.EQUIPMENT_READINGS_PVI.EQUIPMENT_METRICS,
    style: { fullWidth: true },
    sectionDatapoints: [
      {
        sectionName: 'boilerMetrics',
        title: content.EQUIPMENT_READINGS_PVI.WATER_HEATER_METRICS,
        items: [
          { name: 'HeaterRuntime', label: content.EQUIPMENT_READINGS_PVI.RUN_HOURS },
          { name: 'HeaterCycles', label: content.EQUIPMENT_READINGS_PVI.CYCLE_COUNT },
        ],
      },
      {
        sectionName: 'boilerInfo',
        title: content.EQUIPMENT_READINGS_PVI.ADDITIONAL_WATER_HEATER_INFO,
        items: [
          { name: 'HeaterEnable', label: content.EQUIPMENT_READINGS_PVI.HEATER_ENABLE },
          { name: 'Pump1Status', label: content.EQUIPMENT_READINGS_PVI.PUMP_1_STATUS },
        ],
      },
    ],
    dataTestId: 'equipment-metrics-section',
  },
];
