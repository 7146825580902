import { useDeviceDrawerContext } from '@marlin/shared/utils/sensor-provider';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import { IconButton } from '@mui/material';

import { useStyles } from './controls.component.styles';
import { useControls } from './use-controls.hook';

export const DeviceDrawerControls = (): JSX.Element | null => {
  const { classes } = useStyles();
  const { deviceDrawerState } = useDeviceDrawerContext();
  const { shouldRender, goToPreviousDevice, goToNextDevice, isGoToPreviousDeviceDisabled, isGoToNextDeviceDisabled } =
    useControls({ rows: deviceDrawerState.rows });

  if (!shouldRender) {
    return null;
  }

  return (
    <>
      <IconButton
        aria-label="previous-device"
        onClick={goToPreviousDevice}
        disabled={isGoToPreviousDeviceDisabled}
        classes={{
          disabled: classes.disabled,
        }}
        data-testid="up-button"
      >
        <KeyboardArrowUpRoundedIcon className={classes.upArrow} />
      </IconButton>
      <IconButton
        aria-label="next-device"
        onClick={goToNextDevice}
        disabled={isGoToNextDeviceDisabled}
        classes={{
          disabled: classes.disabled,
        }}
        data-testid="down-button"
      >
        <KeyboardArrowDownRoundedIcon className={classes.downArrow} />
      </IconButton>
    </>
  );
};
