import { Grid, Typography } from '@mui/material';

import { useStyles } from './header.styles';

interface ICardHeaderProps {
  title: string;
  enabled: boolean;
}

export function CardHeaderBuilder({ title, enabled }: ICardHeaderProps) {
  const { classes, cx } = useStyles();

  return (
    <Grid className={classes.headerRow} container>
      <Grid item className={cx({ [classes.disabledElements]: !enabled }, classes.titleGrid)}>
        <Typography variant="h5" className={classes.title} data-testid="card-title">
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
}
