import isNil from 'lodash/isNil';

import {
  IFlowMeterReading,
  IPressureReading,
  ITemperatureReading,
  IUnknownReading,
  TDeviceType,
  TRound,
} from './model';

/**
 * @deprecated formatDeviceReadings - use parseDisplayedValue instead
 */
export function formatDeviceReadings<T extends TDeviceType>(
  value: number | null,
  deviceType: T,
  round: TRound = 'decimal'
): ITemperatureReading | IPressureReading | IFlowMeterReading | IUnknownReading {
  const decimal = round === 'decimal' ? 1 : 0;

  switch (deviceType) {
    case 'TEMPERATURE': {
      return {
        value: !isNil(value) ? value.toFixed(decimal) : null,
        type: 'TEMPERATURE',
      };
    }
    case 'PRESSURE': {
      return {
        value: !isNil(value) ? value.toFixed(decimal) : null,
        type: 'PRESSURE',
      };
    }
    case 'PULSE_METER': {
      return {
        value: !isNil(value) ? value.toFixed(decimal) : null,
        type: 'PULSE_METER',
      };
    }
    default: {
      return {
        value: null,
        type: 'UNKNOWN',
      };
    }
  }
}
