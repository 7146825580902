import { content } from './content';
import type { TStatusMap } from './types';

export const statusIdToKeyAdapter = (id: string): string | undefined =>
  ({
    HOME: 'Home',
    AWAY: 'Away',
    STANDBY: 'Standby',
  }[id]);

export const sentinelEquipmentModeLabelMap: TStatusMap = new Map([
  [
    'Home',
    {
      mode: content.EQUIPMENT_MODE_LABELS_SENTINEL.HOME,
      iconTag: 'in_home_mode',
    },
  ],
  ['Away', { mode: content.EQUIPMENT_MODE_LABELS_SENTINEL.AWAY, iconTag: 'directions_walk' }],
  ['Standby', { mode: content.EQUIPMENT_MODE_LABELS_SENTINEL.STANDBY, iconTag: 'pending' }],
]);
