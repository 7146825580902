import { MarlinTheme } from '@marlin/shared/theme';
import { PageContainer, PageHeader } from '@marlin/shared/ui-page';
import { routes } from '@marlin/shared/utils-routes';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button, useMediaQuery, useTheme } from '@mui/material';
import { Navigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { UpsertDevice } from '../form/upsert-device.component';
import { content } from './content';
import { useNewDevice } from './use-new-device.hook';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  button: {
    marginLeft: theme.typography.pxToRem(16),
    marginTop: theme.typography.pxToRem(8),
  },
}));

const NewDevice = () => {
  const { classes } = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { successfullyCreatedDevice, loadingCreatedDevice, onSubmit, goToNextRoute } = useNewDevice();

  if (successfullyCreatedDevice) {
    return <Navigate to={routes.sensors.root} />;
  }
  return (
    <PageContainer prefix="create-device">
      <PageHeader
        icon={null}
        title={content.TITLE}
        subtitle={content.SUBTITLE}
        prefix="device-update-header"
        topActions={
          isMobile ? (
            <Button
              className={classes.button}
              startIcon={<CloseRoundedIcon />}
              variant="outlined"
              onClick={goToNextRoute}
            >
              {content.BUTTON_CANCEL}
            </Button>
          ) : undefined
        }
      />
      <UpsertDevice disableSubmit={loadingCreatedDevice} onSubmit={onSubmit} onCancel={goToNextRoute} />
    </PageContainer>
  );
};

export { NewDevice };
