import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: theme.typography.pxToRem(64),
    padding: theme.typography.pxToRem(16),
    backgroundColor: theme.palette.background.secondary,
  },

  itemCounterText: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.text.disabled,
  },
}));
