import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: theme.typography.pxToRem(516),
    backgroundColor: theme.palette.background.primary,
    padding: `${theme.typography.pxToRem(16)} ${theme.typography.pxToRem(24)}`,
    borderRadius: theme.typography.pxToRem(4),
    // todo (THEME_COLORS_FIX) replace rgba colors with one from palette
    boxShadow: `0 ${theme.typography.pxToRem(11)} ${theme.typography.pxToRem(15)} ${theme.typography.pxToRem(
      -7
    )} rgba(0, 0, 0, 0.2), 0 ${theme.typography.pxToRem(24)} ${theme.typography.pxToRem(38)} ${theme.typography.pxToRem(
      3
    )} rgba(0, 0, 0, 0.14), 0 ${theme.typography.pxToRem(9)} ${theme.typography.pxToRem(46)} ${theme.typography.pxToRem(
      8
    )} rgba(0, 0, 0, 0.12)`,
    [theme.breakpoints.down('lg')]: {
      width: `calc(100% - ${theme.typography.pxToRem(32)})`,
    },
  },
  actions: {
    display: 'flex',
    flexDirection: 'row-reverse',
    padding: theme.typography.pxToRem(8),
  },
  description: {
    margin: `${theme.typography.pxToRem(16)} 0`,
  },
  img: {
    maxWidth: '100%',
    height: 'auto',
    borderRadius: theme.typography.pxToRem(4),
  },
  activeStep: {
    fontWeight: theme.typography.fontWeightBold,
  },
  subtitle: {
    marginTop: theme.typography.pxToRem(8),
  },
}));
