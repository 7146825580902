import { useMediaQuery, useTheme } from '@mui/material';

import { DesktopHelpHub } from './desktop/help-hub.component';
import { MobileHelpHub } from './mobile/help-hub.component';

export function HelpHub() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (isMobile) {
    return <MobileHelpHub />;
  }

  return <DesktopHelpHub />;
}
