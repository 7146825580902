import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  confirmText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  button: {
    width: theme.typography.pxToRem(108),
  },
  cancelButton: {
    marginLeft: theme.typography.pxToRem(8),
  },
  linksTable: {
    marginTop: theme.typography.pxToRem(32),
    marginBottom: theme.typography.pxToRem(32),
  },
}));
