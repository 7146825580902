export const content = {
  temperature: 'Temperature',
  pressure: 'Pressure',
  flow: 'Flow',
  pumpValve: 'Pump & Valve Status',
  other: 'Other',
  flameStrength: 'Flame Strength',
  pumpStatus: 'Pump Status',
  boilerFiringRate: 'Boiler Firing Rate',
};
