import { useEquipmentStatus } from '../../../../../shared/hooks/use-equipment-status.hook';
import { content } from '../../../../content';
import { EquipmentStatusBar } from './equipment-status-bar.component';

interface IEquipmentStatusProps {
  fullWidth?: boolean;
}

export const EquipmentStatusSentinel = ({ fullWidth }: IEquipmentStatusProps) => {
  const { statusLabel, displayTime, operationalIconTag, operationalStatusLabel, isOperational } = useEquipmentStatus({
    statusDatapointName: 'Scene',
    modeMap: 'sentinel',
  });

  return (
    <EquipmentStatusBar
      fullWidth={fullWidth}
      isEquipmentOperational={isOperational}
      isErrorModeLabel={false}
      isErrorStatusDot={false}
      statusLabel={statusLabel}
      operationalStatusLabel={
        operationalStatusLabel && content.EQUIPMENT_MODE_LABELS_SENTINEL.SCENE(operationalStatusLabel)
      }
      operationalIconTag={operationalIconTag}
      displayTime={displayTime}
    />
  );
};
