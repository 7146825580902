import { MODEL } from '@marlin/shared/utils/zod';
import { TMetadataGroup, TMetadataLayout } from '@marlin/system-map/data-access/system-map';
import { useMemo } from 'react';

import { useDetailedNodeSensorsFilter } from './use-detailed-node-sensors-filter.hook';
import { useRegisteredEquipmentContext } from './use-registered-equipment-context';

interface IUseDetailedNodeLayoutProps {
  isDetailed: boolean;
  metadataLayout: TMetadataLayout;
  metadataGroups: TMetadataGroup[];
}

export const useDetailedNodeLayout = ({ isDetailed, metadataLayout, metadataGroups }: IUseDetailedNodeLayoutProps) => {
  const { model } = useRegisteredEquipmentContext();
  const filterSensors = useDetailedNodeSensorsFilter({ isDetailed });

  return useMemo(() => {
    if (!isDetailed) {
      return {
        metadataLayout,
        metadataGroups,
      };
    }
    switch (model) {
      case MODEL.SENTINEL: {
        return {
          metadataLayout: {
            ...metadataLayout,
            rows: metadataLayout.rows - 1,
          },
          metadataGroups: metadataGroups
            .filter((group) => {
              return filterSensors(group.sensors).length > 0;
            })
            .map((group) => ({
              ...group,
              row: group.row - 1,
            })),
        };
      }
      default: {
        return {
          metadataLayout,
          metadataGroups,
        };
      }
    }
  }, [filterSensors, isDetailed, metadataGroups, metadataLayout, model]);
};
