import { Button, Typography } from '@mui/material';

import { TEASER_MODAL_ACTION_TYPE } from '../../../teaser-modal-provider/types';
import { useTeaserModal } from '../../../teaser-modal-provider/use-teaser-modal';
import { content } from '../content';
import { useStyles } from './schedule-demo.styles';

export const ScheduleDemo = () => {
  const { classes } = useStyles();
  const { modalDispatch } = useTeaserModal();

  const handleBookDemo = () => {
    modalDispatch({
      type: TEASER_MODAL_ACTION_TYPE.SHOW_BOOKING_MODAL,
    });
  };

  return (
    <div className={classes.container}>
      <Typography className={classes.text}>{content.SCHEDULE_DEMO}</Typography>
      <Button className={classes.button} variant="contained" fullWidth onClick={handleBookDemo}>
        {content.BUTTONS.BOOK_DEMO}
      </Button>
    </div>
  );
};
