import { getHttpClient, queryClient, useMutation } from '@marlin/shared/utils/react-query';
import { z } from 'zod';

import { paths } from '../infrastructure/api';
import {
  RegisteredEquipmentResponse,
  TAercoBoilerEquipmentPayload,
  TAercoWaterHeaterEquipmentPayload,
  TRegisteredEquipmentPayload,
  TRegisteredEquipmentResponse,
  TSentinelEquipmentPayload,
  TSmartBoilerPayload,
  TSmartSteamPayload,
} from '../schema/equipment.schema';
import { queryKey } from './query-key.enum';

export type TCreateEquipmentType = {
  data:
    | TRegisteredEquipmentPayload
    | TSmartBoilerPayload
    | TSmartSteamPayload
    | TAercoWaterHeaterEquipmentPayload
    | TAercoBoilerEquipmentPayload
    | TSentinelEquipmentPayload;
};

export const createEquipment = async ({ data }: TCreateEquipmentType) => {
  const response = await getHttpClient().post(paths.EQUIPMENTS_WITH_REGISTER_CODE, data);

  return RegisteredEquipmentResponse.parse(response);
};

interface IUseCreateRegisteredEquipment {
  onSuccess?: (data: TRegisteredEquipmentResponse, payload: TCreateEquipmentType) => void;
}

export const useCreateRegisteredEquipment = ({ onSuccess }: IUseCreateRegisteredEquipment) => {
  return useMutation({
    onSuccess: (data, payload) => {
      queryClient.invalidateQueries({ queryKey: queryKey.EQUIPMENTS() });

      onSuccess && onSuccess(data, payload);
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      if (error instanceof z.ZodError) console.log(error.issues);
    },
    mutationFn: createEquipment,
  });
};
