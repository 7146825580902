import { MarlinTheme } from '@marlin/shared/theme';
import AddIcon from '@mui/icons-material/Add';
import SensorsRoundedIcon from '@mui/icons-material/SensorsRounded';
import { IconButton } from '@mui/material';
import { alpha } from '@mui/system';
import { Handle, NodeProps, NodeToolbar, Position, useViewport } from '@xyflow/react';
import { makeStyles } from 'tss-react/mui';

import { useCommonStyles } from './common.styles';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  device: {
    backgroundColor: theme.palette.systemMap.device,
    width: theme.typography.pxToRem(96),
    height: theme.typography.pxToRem(96),
    borderRadius: '50%',
    padding: 0,
  },
  deviceBackground: {
    backgroundColor: theme.palette.background.primary,
    width: theme.typography.pxToRem(96),
    height: theme.typography.pxToRem(96),
    borderRadius: '50%',
    padding: 0,
  },
  deviceNodeContent: {
    lineHeight: 1.6,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    textAlign: 'center',
  },
  deviceHandle: {},
  active: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  attachment: {
    color: theme.palette.systemMap.alternative,
    position: 'absolute',
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  alertLow: {
    backgroundColor: alpha(theme.palette.warning.main, 0.12),
    border: `1px solid ${theme.palette.warning.main}`,
    color: theme.palette.text.primary,
  },
  telemetryError: {
    backgroundColor: alpha(theme.palette.error.main, 0.12),
    border: `1px solid ${theme.palette.error.main}`,
    color: theme.palette.text.primary,
  },
  alertHigh: {
    backgroundColor: alpha(theme.palette.error.main, 0.12),
    border: `1px solid ${theme.palette.error.main}`,
    color: theme.palette.text.primary,
  },
  alertTooltipTitle: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(14),
  },
  alertTooltipLabel: {
    borderStyle: 'solid',
    borderWidth: theme.typography.pxToRem(1),
    borderColor: theme.palette.primary.contrastText,
    borderRadius: theme.typography.pxToRem(16),
    paddingTop: theme.typography.pxToRem(2),
    paddingBottom: theme.typography.pxToRem(2),
    paddingLeft: theme.typography.pxToRem(4),
    paddingRight: theme.typography.pxToRem(4),
    marginTop: theme.typography.pxToRem(16),
  },
}));

export const SensorNode = ({ id }: NodeProps) => {
  const { classes, cx } = useStyles();
  const { classes: commonClasses } = useCommonStyles();
  const { zoom } = useViewport();

  return (
    <div className={classes.deviceBackground}>
      <div className={cx(commonClasses.node, classes.device)} data-testid={`device-node-${id}`}>
        <div className={classes.deviceNodeContent}>
          <SensorsRoundedIcon />
          <span data-testid={`device-node-${id}-label`}>Sensor</span>
        </div>
        <Handle type="target" id="top" position={Position.Top} />
        <Handle type="target" id="left" position={Position.Left} />
        <Handle type="source" id="right" position={Position.Right} />
        <Handle type="source" id="bottom" position={Position.Bottom} />
      </div>

      <NodeToolbar isVisible position={Position.Right}>
        <IconButton
          color="secondary"
          aria-label="add an alarm"
          style={{ transform: `scale(${zoom}) translate(-24px, 0) translate(${zoom * 18}px, 0)` }}
        >
          <AddIcon />
        </IconButton>
      </NodeToolbar>
    </div>
  );
};
