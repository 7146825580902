import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  overlay: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    zIndex: theme.indexPosition.default,
    width: '100%',
    height: theme.typography.pxToRem(600),
    overflow: 'auto',
  },

  loader: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    width: theme.typography.pxToRem(60),
    height: theme.typography.pxToRem(60),
    position: 'absolute',
    top: '40%',
    left: `calc(50% - ${theme.typography.pxToRem(30)})`,
    '& svg': {
      fill: theme.palette.primary.main,
    },
  },
}));
