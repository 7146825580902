import { MarlinTheme } from '@marlin/shared/theme';
import { useTheme } from '@mui/system';
import { useMemo } from 'react';
import Plot from 'react-plotly.js';

import { sankeyConfig, sankeyLayout, sankeyStyle } from './constants';
import { useStyles } from './sankey.styles';
import { TLinkNode, TLinkValue, TNode } from './types';
import { createSankeyData } from './utils';

export interface ISankeyProps {
  sources: TLinkNode[];
  targets: TLinkNode[];
  values: TLinkValue[];
  nodes: TNode[];
}

export const Sankey = ({ sources, targets, values, nodes }: ISankeyProps) => {
  const { classes } = useStyles();
  const theme = useTheme<MarlinTheme>();

  const data = useMemo(
    () => [createSankeyData({ sources, targets, values, nodes, theme })],
    [sources, targets, values, nodes, theme]
  );

  return (
    <div className={classes.sankeyWrapper}>
      <Plot style={sankeyStyle} data={data} layout={sankeyLayout} config={sankeyConfig} />
    </div>
  );
};
