import { MarlinTheme } from '@marlin/shared/theme';
import { FormControl, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../content';
import { EXTENDED_RANGE_FILTER } from '../charts/collapsed-chart/chart-constants';
import { RANGE_FILTER, chartRangeTypeOptions } from '../constant';
import { useChartRangeContext } from '../context/chart-range.context';

interface IRangeFilterFormProps {
  isExtended: boolean;
  refreshData: () => void;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  chartRangeSelect: { margin: `0 ${theme.typography.pxToRem(8)}`, width: '100%' },
  viewingContainer: { display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' },
  extendedViewingContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    justifyContent: 'space-between',
  },
  selectContainer: { display: 'flex', alignItems: 'center', width: '100%' },
  viewing: {
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
  },
}));

export const RangeFilterForm = ({ isExtended }: IRangeFilterFormProps) => {
  const { classes } = useStyles();
  const { rangeFilter, extendedRangeFilter, onRangeFilterChange, setExtendedRangeFilter } = useChartRangeContext();

  return (
    <div
      className={isExtended ? classes.extendedViewingContainer : classes.viewingContainer}
      data-testid="drawer-readings-viewing-container"
    >
      <div className={classes.selectContainer}>
        <Typography variant="caption" className={classes.viewing}>
          {content.SENSOR_READINGS_RANGE_PREFIX}
        </Typography>
        <FormControl size="small" className={classes.chartRangeSelect}>
          {isExtended ? (
            <Select
              fullWidth
              value={extendedRangeFilter}
              onChange={(event) => {
                // we want to set custom, not pass it to the chart
                if (event.target.value !== EXTENDED_RANGE_FILTER.CUSTOM) {
                  onRangeFilterChange(event as SelectChangeEvent<RANGE_FILTER>);
                  setExtendedRangeFilter(event.target.value as RANGE_FILTER);
                }
              }}
              data-testid="chart-range-select"
            >
              {chartRangeTypeOptions.map(({ name, id }, index) => {
                return (
                  <MenuItem key={index} value={id} data-testid={`chart-range-select-item-${index}`}>
                    {name}
                  </MenuItem>
                );
              })}
              {extendedRangeFilter === EXTENDED_RANGE_FILTER.CUSTOM && (
                <MenuItem value={EXTENDED_RANGE_FILTER.CUSTOM}>{content.CUSTOM}</MenuItem>
              )}
            </Select>
          ) : (
            <Select fullWidth value={rangeFilter} onChange={onRangeFilterChange} data-testid="chart-range-select">
              {chartRangeTypeOptions.map(({ name, id }, index) => {
                return (
                  <MenuItem key={index} value={id} data-testid={`chart-range-select-item-${index}`}>
                    {name}
                  </MenuItem>
                );
              })}
            </Select>
          )}
        </FormControl>
      </div>
    </div>
  );
};
