import { MarlinTheme } from '@marlin/shared/theme';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { createDataTestId } from '@marlin/shared/utils/testing-utils';
import CircleIcon from '@mui/icons-material/Circle';
import { PropsWithChildren } from 'react';
import { makeStyles } from 'tss-react/mui';

import { TDirection, TStatus } from '../types';
import { getStatusColor, getStatusIconColor } from '../utils/utils';

interface IReadingProps extends PropsWithChildren {
  status: TStatus;
  direction: TDirection;
  label?: string | null;
  tooltipMessage?: string | null;
  isDetailed?: boolean;
}

interface IStylesProps {
  status: TStatus;
  direction: TDirection;
  isDetailed?: boolean;
}

export const useStyles = makeStyles<IStylesProps>()((theme: MarlinTheme, { status, direction, isDetailed }) => ({
  sensor: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightMedium,
    display: 'inline-flex',
    alignItems: 'center',
    flexDirection: direction === 'center' && isDetailed ? 'column' : 'row',
    gap: theme.typography.pxToRem(2),
  },
  reading: {
    color: getStatusColor(status, theme),
  },
  label: {
    '&::after': {
      content: '" "',
    },
  },
  icon: {
    width: theme.typography.pxToRem(10),
    height: theme.typography.pxToRem(10),
    color: getStatusIconColor(status, theme),
    marginRight: direction === 'left' ? theme.typography.pxToRem(2) : 0,
    marginLeft: direction === 'right' ? theme.typography.pxToRem(2) : 0,
    order: direction === 'right' ? 3 : 0,
  },
  tooltip: {
    whiteSpace: 'pre-line',
  },
}));

export const Reading = ({ label, children, status, direction, tooltipMessage, isDetailed }: IReadingProps) => {
  const { classes } = useStyles({ status, direction, isDetailed });

  const reading = (
    <div className={classes.sensor} data-testid={createDataTestId('sensorReading', label || tooltipMessage, status)}>
      {!label && <CircleIcon className={classes.icon} />}
      {label && <span className={classes.label}>{label}</span>}
      <span className={classes.reading}>{children}</span>
    </div>
  );

  return tooltipMessage ? (
    <Tooltip
      text={
        <div className={classes.tooltip} data-testid={createDataTestId('sensorReading', label, 'tooltip')}>
          {tooltipMessage}
        </div>
      }
      placement={direction === 'center' ? 'top' : direction}
    >
      {reading}
    </Tooltip>
  ) : (
    reading
  );
};
