import { MarlinTheme } from '@marlin/shared/theme';
import { Link } from '@marlin/shared/ui-page';
import { goToMarlin } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';
import { ReactComponent as SetupSuccess } from './icons/confirmation-finished.svg';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  successWrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    marginBottom: theme.typography.pxToRem(24),
    [theme.breakpoints.down('md')]: {
      width: '75%',
      height: 'auto',
    },
  },
  title: {
    fontSize: theme.typography.pxToRem(34),
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(20),
    },
  },
  subtitle: {
    marginTop: theme.typography.pxToRem(8),
    color: theme.palette.text.secondary,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },
}));

export const InvitationsComplete = () => {
  const { classes } = useStyles();

  useEffect(() => {
    setTimeout(() => {
      goToMarlin();
    }, 3000);
  }, []);

  return (
    <div className={classes.successWrapper}>
      <SetupSuccess className={classes.icon} />
      <Typography className={classes.title}>{content.INVITATIONS_COMPLETED.TITLE}</Typography>
      <Typography className={classes.subtitle}>
        {content.INVITATIONS_COMPLETED.SUBTITLE1}
        <br />
        {content.INVITATIONS_COMPLETED.SUBTITLE2}{' '}
        <Link to={routes.home.root} external>
          {content.INVITATIONS_COMPLETED.LINK}
        </Link>{' '}
        {content.INVITATIONS_COMPLETED.SUBTITLE3}
      </Typography>
    </div>
  );
};
