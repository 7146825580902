import { ALERT_STATUS_FILTER } from '@marlin/alert/data-access/alert-action';
import { TAB_INDEX } from '@marlin/alert/ui/alert-list';

export const mapTabsToStatus = (tab: TAB_INDEX): ALERT_STATUS_FILTER => {
  switch (tab) {
    case TAB_INDEX.ALL: {
      return ALERT_STATUS_FILTER.ALL;
    }
    case TAB_INDEX.CURRENT: {
      return ALERT_STATUS_FILTER.CURRENT;
    }
    case TAB_INDEX.SNOOZED: {
      return ALERT_STATUS_FILTER.SNOOZED;
    }
    case TAB_INDEX.RESOLVED: {
      return ALERT_STATUS_FILTER.RESOLVED;
    }
    default: {
      return ALERT_STATUS_FILTER.ALL;
    }
  }
};
