import { MarlinTheme } from '@marlin/shared/theme';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  header: {
    padding: theme.typography.pxToRem(16),
  },

  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export const ListHeader = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.header}>
      <Typography variant="h5" className={classes.title}>
        {content.GATEWAY_TABLE_TITLE}
      </Typography>
    </div>
  );
};
