import { DeviceNode } from './device-node.component';
import { EquipmentNode } from './equipment-node.component';
import { LocationNode } from './location-node.component';
import { TeeNode } from './tee-node.component';

export const nodeTypes = {
  LOCATION: LocationNode,
  EQUIPMENT: EquipmentNode,
  DEVICE: DeviceNode,
  TEE: TeeNode,
};
