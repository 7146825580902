import {
  AssetTypeResponseSchema,
  AssetTypeSchemaParser,
  DeviceTypeResponseSchema,
  DeviceTypeSchemaParser,
  NodeTypeResponseSchema,
  NodeTypeSchemaParser,
} from '@marlin/system-map/shared/data-access-schemas';
import { z } from 'zod';

export const flowLinkSchema = z
  .object({
    id: z.string(),
    inletAssetId: z.string().nullish(),
    inletLinkedId: z.string().nullish(),
    inletAssetName: z.string().nullish(),
    inletAssetType: AssetTypeResponseSchema.nullish(),
    inletDeviceType: DeviceTypeResponseSchema.nullish(),
    inletId: z.string().nullish(),
    inletName: z.string().nullish(),
    inletNodeType: NodeTypeResponseSchema,
    outletAssetId: z.string().nullish(),
    outletLinkedId: z.string().nullish(),
    outletAssetName: z.string().nullish(),
    outletAssetType: AssetTypeResponseSchema.nullish(),
    outletDeviceType: DeviceTypeResponseSchema.nullish(),
    outletId: z.string().nullish(),
    outletName: z.string().nullish(),
    outletNodeType: NodeTypeResponseSchema,
  })
  .transform((flowLink) => {
    const inletNodeType = NodeTypeSchemaParser.parse(flowLink.inletNodeType);
    const outletNodeType = NodeTypeSchemaParser.parse(flowLink.outletNodeType);
    const inletAssetType = AssetTypeSchemaParser.parse(flowLink.inletAssetType);
    const outletAssetType = AssetTypeSchemaParser.parse(flowLink.outletAssetType);
    const inletDeviceType = DeviceTypeSchemaParser.parse(flowLink.inletDeviceType);
    const outletDeviceType = DeviceTypeSchemaParser.parse(flowLink.outletDeviceType);

    return {
      ...flowLink,
      inletNodeType,
      outletNodeType,
      inletAssetType,
      outletAssetType,
      inletDeviceType,
      outletDeviceType,
    };
  });

export const assetLinkSchema = z.object({
  assetId: z.string(),
  links: z.array(flowLinkSchema).nullish(),
});

export type TFlowLink = z.infer<typeof flowLinkSchema>;
export type TAssetLink = z.infer<typeof assetLinkSchema>;
