import { IChart } from '@marlin/asset/shared/equipment-config';
import { createContext, useContext } from '@marlin/shared/utils-component-context';
import { PropsWithChildren } from 'react';

interface IHistoricalChartingConfigContext {
  charts: IChart[];
  calculationValues: boolean;
}

interface IHistoricalChartingConfigContextProviderProps extends PropsWithChildren<IHistoricalChartingConfigContext> {}

export const HistoricalChartingContext = createContext<IHistoricalChartingConfigContext>();

export const HistoricalChartingConfigProvider = ({
  charts,
  calculationValues,
  children,
}: IHistoricalChartingConfigContextProviderProps) => {
  return (
    <HistoricalChartingContext.Provider value={{ charts, calculationValues }}>
      {children}
    </HistoricalChartingContext.Provider>
  );
};

export const useHistoricalChartingConfigContext = () => useContext(HistoricalChartingContext);
