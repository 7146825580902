import moment, { Moment } from 'moment/moment';
import { useEffect, useMemo, useState } from 'react';

import { useNow } from './context/reload.context';
import { PERIOD_ENUM } from './period-map';
import { calculateFromDate, calculateRange } from './utils';

export type TPeriodDate = Moment | null;

interface ICustomPeriodModalProps {
  fromDate: TPeriodDate;
  toDate: TPeriodDate;
  open: boolean;
}

export const usePeriodState = (initialPeriod?: PERIOD_ENUM) => {
  const [selectedPeriod, setSelectedPeriod] = useState(initialPeriod ?? PERIOD_ENUM.DAY);
  const [durationModalOpened, setDurationModalOpened] = useState(false);
  const { now } = useNow();

  const [range, setRange] = useState({
    from: calculateFromDate(selectedPeriod, now ?? undefined) || null,
    to: now,
  });

  useEffect(() => {
    if (selectedPeriod !== PERIOD_ENUM.CUSTOM) {
      setRange({ from: calculateFromDate(selectedPeriod, now ?? undefined) || null, to: now });
    }
  }, [now, selectedPeriod]);

  const handleCustomRangeSet = ({ fromDate, toDate }: Partial<ICustomPeriodModalProps>) => {
    if (fromDate && toDate) {
      setRange({ from: fromDate, to: toDate });
      setSelectedPeriod(PERIOD_ENUM.CUSTOM);
    }
    setDurationModalOpened(false);
  };

  const handleChangePeriod = (_: React.MouseEvent<HTMLElement>, newPeriodProps: PERIOD_ENUM) => {
    let newPeriod = newPeriodProps;

    if (newPeriod === null) {
      newPeriod = selectedPeriod;
    } else {
      setSelectedPeriod(newPeriod);
    }
    if (newPeriod === PERIOD_ENUM.CUSTOM) {
      setDurationModalOpened((opened) => !opened);
    } else {
      setRange({ from: calculateFromDate(newPeriod, now ?? undefined) || null, to: now });
    }
  };

  const handleCloseDurationModal = () => {
    setDurationModalOpened(false);
  };

  const rangeFilterState = useMemo(() => {
    return {
      range: calculateRange(selectedPeriod),
      currentDate: now?.clone().utc() ?? moment().utc(),
    };
  }, [now, selectedPeriod]);

  const rangeFilter = useMemo(() => {
    return calculateRange(selectedPeriod);
  }, [selectedPeriod]);

  return {
    selectedPeriod,
    handleChangePeriod,
    range,
    setRange,
    durationModalOpened,
    handleCloseDurationModal,
    handleCustomRangeSet,
    rangeFilterState,
    rangeFilter,
  };
};
