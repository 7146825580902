import AddTaskRoundedIcon from '@mui/icons-material/AddTaskRounded';
import { Button, useMediaQuery } from '@mui/material';

import { content } from '../../../../content';
import { useStyles } from './common-parts';

export const FormButtons = ({
  isDisabled,
  onCancel,
  isEditForm,
  isAnotherEquipment,
  onFormSubmit,
}: {
  isDisabled: boolean;
  onFormSubmit: () => void;
  onCancel?: () => void;
  isEditForm?: boolean;
  isAnotherEquipment?: boolean;
}) => {
  const { classes, cx, theme } = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  if (isEditForm) {
    return (
      <div className={classes.buttonContainer}>
        <Button
          data-testid="edit-button"
          variant="outlined"
          size={isMobile ? 'medium' : 'small'}
          onClick={onCancel}
          className={classes.cancelButton}
        >
          {content.CANCEL}
        </Button>
        <Button
          disabled={isDisabled}
          data-testid="edit-button"
          variant="contained"
          size={isMobile ? 'medium' : 'small'}
          onClick={onFormSubmit}
        >
          {content.SAVE}
        </Button>
      </div>
    );
  }

  if (isAnotherEquipment) {
    return (
      <div className={classes.buttonContainer}>
        <Button
          data-testid="edit-button"
          variant="outlined"
          size={isMobile ? 'medium' : 'small'}
          onClick={onCancel}
          className={classes.cancelButton}
        >
          {content.CANCEL}
        </Button>
        <Button
          disabled={isDisabled}
          data-testid="register-button"
          variant="contained"
          size={isMobile ? 'medium' : 'small'}
          onClick={onFormSubmit}
        >
          <AddTaskRoundedIcon className={cx(classes.icon, classes.smallIcon)} />
          {content.REGISTER_EQUIPMENT}
        </Button>
      </div>
    );
  }

  return (
    <Button
      disabled={isDisabled}
      className={classes.button}
      data-testid="register-button"
      variant="contained"
      onClick={onFormSubmit}
    >
      <AddTaskRoundedIcon className={classes.icon} />
      {content.REGISTER_EQUIPMENT}
    </Button>
  );
};
