import { z } from 'zod';

import { RoleSchema } from './roles.schema';
import { paginationSchema, sortingSchema } from './utils.schema';

export const portfolioOrganizationRequestSchema = z.object({
  search: z.string().nullish(),
  page: z.number(),
  pageSize: z.number(),
  sorting: sortingSchema.optional(),
});

const OrganizationSchema = z.object({
  portfolioId: z.string(),
  organizationId: z.string(),
  name: z.string(),
  role: RoleSchema,
});

export const portfolioOrganizationResponseSchema = z.object({
  pagination: paginationSchema,
  data: z.array(OrganizationSchema),
});

export type TPortfolioOrganization = z.infer<typeof OrganizationSchema>;
export type TPortfolioOrganizationResponse = z.infer<typeof portfolioOrganizationResponseSchema>;
export type TPortfolioOrganizationRequest = z.infer<typeof portfolioOrganizationRequestSchema>;
