import { TUnitOfMeasure } from '@marlin/shared/utils-format-reading';

import { content } from './content';
import { getTitleErrorText } from './get-title-error-text';
import { ERROR_TYPES } from './types';

type TAlertSnackbar = {
  deviceName: string;
  deviceType: string;
  cause: ERROR_TYPES;
  thresholdMin?: number | null;
  thresholdMax?: number | null;
  message?: string;
  uoM: TUnitOfMeasure | null;
};

const getAlertMessage = (message: string, deviceName: string) => {
  return message.replace(deviceName, '');
};

export const getSnackbarAlertMessage = (alert: TAlertSnackbar): string => {
  const { cause, deviceName, thresholdMin, thresholdMax, deviceType, message, uoM } = alert;

  if (cause === ERROR_TYPES.DEVICE_ERROR || cause === ERROR_TYPES.DEVICE_ALERT) {
    return getAlertMessage(message || '', deviceName);
  }
  if (cause === ERROR_TYPES.LOW_BATTERY) {
    return ` ${content.TITLE.LOW_BATTERY}`;
  }
  if (cause === ERROR_TYPES.LOST_COMMUNICATION) {
    return ` ${content.TITLE.LOST_COMMUNICATION}`;
  }

  if (deviceType === 'LEAK') {
    return ` ${content.TITLE.LEAK}`;
  }

  const errorText = getTitleErrorText(cause, uoM, thresholdMin || undefined, thresholdMax || undefined);

  return ` ${errorText}`;
};
