import { timeZoneSchema, validatePostalCodeMessage } from '@marlin/shared/ui-form';
import { z } from 'zod';

import { content } from './content';

const minCharacters = 1;
const maxCharacters = 100;

const requiredTextFieldSchema = z
  .string()
  .min(minCharacters, content.REQUIRED)
  .max(maxCharacters, content.MAX_LENGTH(maxCharacters));

const optionalTextFieldSchema = z.string().max(maxCharacters, content.MAX_LENGTH(maxCharacters)).nullish();

export const AddressFormFieldsSchema = {
  country: requiredTextFieldSchema,
  address1: requiredTextFieldSchema,
  address2: optionalTextFieldSchema,
  city: requiredTextFieldSchema,
  state: requiredTextFieldSchema,
  postalCode: requiredTextFieldSchema,
  timeZone: timeZoneSchema,
};

export const AddressFormDataSchema = z.object(AddressFormFieldsSchema).superRefine((data, ctx) => {
  const { postalCode, country } = data;
  validatePostalCodeMessage(postalCode, country, ctx);
});

export type TAddressForm = z.infer<typeof AddressFormDataSchema>;
