export const content = {
  CREATE: 'Create New',
  GATEWAY: 'Gateway',
  CONFIGURE_GATEWAY_SUBTITLE: 'Use the form below to configure your gateway.',
  FORM: {
    CONNECTION_TITLE: (idx?: number) => (idx ? `Connection ${idx}` : 'Connection'),
    ADDRESS_TITLE: (idx?: number) => (idx ? `Address ${idx}` : 'Address'),
    CONNECTION_FIELD: 'Connection',
    REQUIRED: 'This field is required',
    UPDATE: 'Update',
    CREATE: 'Save',
    TEST: 'Test',
    CANCEL: 'Cancel',
    BAUD_RATE_FIELD: 'Baud Rate',
    DATA_BITS_FIELD: 'Data Bits',
    STOP_BITS_FIELD: 'Stop Bits',
    PARITY_FIELD: 'Parity',
    CONNECTION_OPTIONS: {
      MODBUS_1: 'Modbus-1',
      MODBUS_2: 'Modbus-2',
    },
    EQUIPMENT_BRANDS_OPTIONS: {
      BRADLEY: 'Bradley',
      PVI: 'PVI',
    },
    MODEL_OPTIONS: {
      PARTITION: 'Partition',
      WATER_HEATER: 'Water Heater',
    },
    MODBUS_ADDRESS_FIELD: 'Modbus Address',
    EQUIPMENT_BRAND_FIELD: 'Equipment Brand',
    EQUIPMENT_MODEL_FIELD: 'Model',
    NAME_FIELD: 'Name',
    LOCATION_FIELD: 'Location',
    ADD_ADDRESS: 'Add Address',
    ADD_CONNECTION: 'Add Connection',
    COMPLETE_CONNECTION_FIELDS_HELPER: 'Complete the Connection fields above to set up an address',
  },
  TEST_MODAL: {
    CANCEL: 'Cancel',
    SAVE: 'Save',
    SAVE_AND_TEST: 'Save & Test',
    TEST: 'Test Configuration',
    TITLE: 'Test Gateway Configuration',
    BODY_TEXT: 'Your configuration needs to be tested before the Gateway can be activated.',
    BODY_TEXT_QUESTION: 'Would you like to test it now?',
    BODY_TEXT_TEST: 'Your current configuration will be replaced.',
    BODY_SUBTEXT: 'Gateway will go into Standby Mode when testing begins.',
  },
  STOP_TEST_MODAL: {
    CANCEL: 'Cancel',
    STOP_TEST: 'Stop Test',
    TITLE: 'Stop Gateway Configuration Test?',
    BODY_TEXT: 'Are you sure you want to stop running the configuration test?',
    BODY_SUBTEXT: 'When restarted, the test will have to run from the beginning.',
  },
  SOMETHING_WENT_WRONG: 'Something went wrong',
  TEST_CONFIGURATION: {
    SUBTITLE: 'Use the form bellow to test the configuration of your gateway.',
    RESULTS: 'Gateway Test Results',
    TEST_RESULTS: 'Test Results',
    BACK: 'Back',
    SUMMARY: 'Results Summary',
    CONNECTIONS: 'Connections',
    ADDRESSES: 'Addresses',
    OPERATIONS: 'Operations',
    COPY_SUCCESS: 'Text was copied to clipboard',
    ERRORS: 'Errors',
    ERRORS_TITLE: 'Errors:',
    ERROR: 'Error',
    NO_ERRORS_MESSAGE:
      'Your Gateway has no errors. Click on Activate Gateway bellow to activate it. \n The equipments you registered will also be created.',
    ERRORS_INFO: (errorsAmount: number) => (
      <span>
        Your Gateway has <b>{`[${errorsAmount}]`}</b> errors. Click on Edit Configuration to check it.
      </span>
    ),
    COPY: 'Copy',
    EDIT_CONFIGURATION: 'Edit Configuration',
    CANCEL: 'Cancel',
    ACTIVATE_GATEWAY: 'Activate Gateway',
  },
  RETRY: 'Retry',
  STOP: 'Stop',
  DONE: 'Done',
  ERROR_MESSAGE: 'Something went wrong. Please try again later.',
  SAVE_MESSAGE: 'Gateway Configuration has been saved.',
  ACTIVATE_MESSAGE: 'Gateway has been activated.',
  CONFIG_FILE_DIALOG: {
    SHOW_CONFIG_FILE: 'Show Config File',
    CANCEL: 'Cancel',
    PRINT: 'Print',
    COPY: 'Copy',
    MODBUS_CONFIGURATION: 'Modbus Configuration',
  },
  CONFIGURATION_STATUS_LABEL: 'Configuration Status',
  STATUS_PUBLISHED: 'Published',
  STATUS_DRAFT: 'Draft',
  STATUS_NEW: 'New',
  CONFIGURATION_STATUS_TOOLTIP: {
    TITLE: 'Available configuration status:',
    DRAFT: 'New/Draft: The configuration has not been push to the Gateway.',
    PUBLISHED: 'Published: The configuration was pushed to the Gateway.',
  },
  REMOVE_ADDRESS_MODAL: {
    TITLE: 'Remove Address?',
    BODY_TEXT_1ST_PART: 'Are you sure you want to remove this address?',
    BODY_TEXT_2ND_PART:
      'All equipment or sensors that may be connected will also be removed and their Nexa history will be lost.',
    BODY_TEXT_3RD_PART: 'This action will be final when you click on Save at the bottom of the form',
    CANCEL: 'Cancel',
    DELETE: 'Delete',
  },
  REMOVE_CONNECTION_MODAL: {
    TITLE: 'Remove Connection?',
    BODY_TEXT_1ST_PART: 'Are you sure you want to remove this connection?',
    BODY_TEXT_2ND_PART: 'This action will be final when you click on Save at the bottom of the form.',
    CANCEL: 'Cancel',
    DELETE: 'Delete',
  },
  ACTIVATE_GATEWAY_MODAL: {
    TITLE: 'Activate Gateway?',
    BODY_TEXT: 'Are you sure you wish to activate this Gateway?',
    BODY_SUBTEXT: 'The equipment you registered will also be created.',
    CANCEL: 'Cancel',
    ACTIVATE: 'Activate Gateway',
  },
  ERROR_MESSAGE_ADDRESS: 'This address has an error, check the selections below.',
  ERROR_MESSAGE_ADDRESS_UNIQUE_NAME: 'The name must be unique in the organization',
  MODAL: {
    UNSAVED_CHANGES_MODAL_TITLE: 'You have unsaved changes',
    UNSAVED_CHANGES_MODAL_BODY: 'Are you sure you want to leave?',
    CANCEL: 'Cancel',
    LEAVE: 'Leave',
  },
};
