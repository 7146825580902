import { ITab, ScrollableTabs } from '@marlin/shared/ui-tabs-mobile';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { useIdFromPathname, useQueryParameters } from '@marlin/shared/utils/url-params';
import { useCallback, useEffect } from 'react';

import { content } from '../content';

export enum TAB_VALUES {
  Details = 'details',
  HierarchyView = 'hierarchy',
}

const tabs: ITab<TAB_VALUES>[] = [
  { id: TAB_VALUES.Details, label: content.DETAILS },
  { id: TAB_VALUES.HierarchyView, label: content.HIERARCHY_VIEW },
];

interface ITabsProps {
  tabValue: TAB_VALUES;
  setTabValue: (tab: TAB_VALUES) => void;
}

export const Tabs = ({ tabValue, setTabValue }: ITabsProps) => {
  const equipmentId = useIdFromPathname();
  const { goTo } = useRouter();
  const queryParams = useQueryParameters();
  const tabQueryParam = queryParams.get('tab');

  useEffect(() => {
    switch (tabQueryParam) {
      case TAB_VALUES.HierarchyView:
        setTabValue(TAB_VALUES.HierarchyView);
        break;
      case TAB_VALUES.Details:
      default:
        setTabValue(TAB_VALUES.Details);
        break;
    }
  }, [setTabValue, tabQueryParam]);

  const updateTab = useCallback(
    (tab: TAB_VALUES) => {
      equipmentId && goTo(routes.equipments.details.query(tab));
    },
    [equipmentId, goTo]
  );

  return <ScrollableTabs tabs={tabs} tabValue={tabValue} updateTab={updateTab} />;
};
