import { useObservableState } from 'observable-hooks';
import { Subject } from 'rxjs';

const touchedState$ = new Subject<boolean>();

type TObservableTouchedState = [boolean, (value: boolean) => void];

export const useTouchedState$ = (initialValue: boolean): TObservableTouchedState => {
  const [isTouched] = useObservableState<boolean>(() => touchedState$, initialValue);

  const setTouched = (value: boolean) => {
    touchedState$.next(value);
  };

  return [isTouched, setTouched];
};
