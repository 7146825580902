import { CircularProgress } from '@mui/material';
import { CircularProgressProps } from '@mui/material/CircularProgress/CircularProgress';

import { useStyles } from './loading-spinner.component.styles';

const LoadingSpinner = (props: CircularProgressProps): JSX.Element => {
  const { classes } = useStyles();

  return (
    <div className={classes.loader}>
      <CircularProgress {...props} />
    </div>
  );
};

export { LoadingSpinner };
