import { TLocationHierarchyLevel } from '@marlin/asset/data-access/location';
import { IBreadcrumb } from '@marlin/shared/breadcrumbs';
import { routes } from '@marlin/shared/utils-routes';
import DeviceHubRoundedIcon from '@mui/icons-material/DeviceHubRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export const useHierarchyBreadcrumbs = (
  data: TLocationHierarchyLevel | undefined,
  selectedItemId: string,
  customBreadcrumbs?: Omit<IBreadcrumb, 'onClick'>
) => {
  const navigate = useNavigate();

  const redirectToLocation = useCallback(
    (id: string) => {
      navigate(`${routes.locations.root}/${id}`);
    },
    [navigate]
  );
  const redirectToEquipment = useCallback((id: string) => navigate(`${routes.equipments.root}/${id}`), [navigate]);
  const createBreadcrumbSchema = useCallback(
    (item: TLocationHierarchyLevel): Omit<IBreadcrumb, 'icon' | 'onClick'> => ({
      label: item.name,
      id: item.id,
      isActive: item.id === selectedItemId,
    }),
    [selectedItemId]
  );

  if (!data) return [];

  const list: (IBreadcrumb | IBreadcrumb[])[] = [];

  const addBreadcrumbElement = (item: TLocationHierarchyLevel) => {
    list.push({
      ...createBreadcrumbSchema(item),
      icon: <LocationOnRoundedIcon />,
      onClick: () => redirectToLocation(item.id),
    });

    const selectedItem =
      item.equipment?.find(({ id }) => id === selectedItemId) || item.devices?.find(({ id }) => id === selectedItemId);

    if (selectedItem) {
      if (customBreadcrumbs) {
        list.push({
          id: selectedItem.id,
          label: selectedItem.name,
          icon: <DeviceHubRoundedIcon />,
          onClick: () => redirectToEquipment(selectedItem.id),
        });

        return list.push({ ...customBreadcrumbs });
      }

      return list.push({
        id: selectedItem.id,
        label: selectedItem.name,
        // TODO: check if device or equipment to have proper icon here
        icon: <DeviceHubRoundedIcon />,
        isActive: true,
      });
    }

    if (item.children.length > 1)
      return list.push([
        ...item.children.map((child) => ({
          ...createBreadcrumbSchema(child),
          icon: <LocationOnRoundedIcon />,
          onClick: () => redirectToLocation(child.id),
        })),
      ]);

    return item.children.forEach(addBreadcrumbElement);
  };

  addBreadcrumbElement(data);

  return list;
};
