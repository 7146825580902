import { MarlinTheme } from '@marlin/shared/theme';
import { FC, PropsWithChildren } from 'react';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.typography.pxToRem(32),
  },
}));

interface IPageWrapperProps {
  prefix: string;
  className?: string;
}

export const PageWrapper: FC<PropsWithChildren<IPageWrapperProps>> = ({ children, prefix, className }) => {
  const { classes } = useStyles();

  return (
    <div data-testid={`${prefix}-container`} className={`${classes.container} ${className ?? ''}`}>
      {children}
    </div>
  );
};
