import { TExtractFnReturnType, useQuery } from '@marlin/shared/utils/react-query';

import { getCalculatedValues } from '../infrastructure/equipment';
import { TCalculatedValues } from '../schema/calculated-values.schema';
import { queryKey } from './query-key.enum';

type TQueryFnType = typeof getCalculatedValues;

type TUseEquipmentOptions = {
  equipmentId: string;
  from: string;
  calculatedValues: TCalculatedValues[];
  to: string;
  isCalculateValues?: boolean;
};

export const useCalculationValues = (params: TUseEquipmentOptions) => {
  return useQuery<TExtractFnReturnType<TQueryFnType>>({
    enabled: Boolean(params.equipmentId) && params.isCalculateValues,
    queryKey: queryKey.CALCULATION_VALUES(params),
    queryFn: () => getCalculatedValues(params),
  });
};
