import { useHeader } from '@marlin/asset/ui/charts';
import { SelectAveragingFunction } from '@marlin/shared/ui-form';
import { AVERAGING_FUNCTION_FILTER, RANGE_FILTER, TChartType, getTimeRangeTimestamp } from '@marlin/shared/utils-chart';
import { formatTimestamp } from '@marlin/shared/utils-common-date';
import { Divider, FormControlLabel, Radio, RadioGroup, SelectChangeEvent } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useMemo, useState } from 'react';
import { ControllerRenderProps } from 'react-hook-form';

import { content } from '../../content';
import { useStyles } from './radio-buttons.styles';

export interface IRadioButton {
  value: string;
  label: string;
  id?: string;
  showAveragingFunction: boolean;
}

interface IInputProps extends Partial<ControllerRenderProps> {
  id?: string;
  className?: string;
  row?: boolean;
  disabled?: boolean;
  onAveragingFunctionFilterChange: (event: SelectChangeEvent<AVERAGING_FUNCTION_FILTER>) => void;
  averagingFunctionFilter: AVERAGING_FUNCTION_FILTER;
  chartType: TChartType;
  chartId: string;
}

export const customFormat = 'MM/DD/YY HH:mm A z';

const adjustDateTimezone = (date: string) => {
  if (date.endsWith('z') || date.endsWith('Z')) {
    return date;
  }
  return date.concat('Z');
};

const radioButtons: IRadioButton[] = [
  {
    label: content.EXPORT_CSV_MODAL.SHOW_AS_AGGREGATED,
    value: 'showAsAggregated',
    showAveragingFunction: true,
  },
  {
    label: content.EXPORT_CSV_MODAL.SHOW_ALL_DATA,
    value: 'showAllData',
    showAveragingFunction: false,
  },
];

export function RadioButtons({
  id,
  value,
  onChange,
  className,
  row,
  disabled,
  averagingFunctionFilter,
  onAveragingFunctionFilterChange,
  chartType,
  chartId,
}: IInputProps) {
  const { classes, cx } = useStyles();
  const [out, setOut] = useState<boolean | undefined>(undefined);

  const { range, durationDetails } = useHeader();

  const calculateTimeRange = (range: RANGE_FILTER) => {
    const timeRange = getTimeRangeTimestamp(range as RANGE_FILTER, new Date().getTime(), false);
    return {
      from: timeRange[0],
      to: timeRange[1],
    };
  };

  const renderDataRange = () => {
    const { from, to } =
      value === 'showAsAggregated' ? calculateTimeRange(range) : calculateTimeRange(RANGE_FILTER.DAYS_30);

    return (
      <>
        <Typography className={classes.headerTitle}>{content.EXPORT_CSV_MODAL.DATE_RANGE}</Typography>
        <div className={classes.datesWrapper}>
          <Typography className={classes.text}>
            {content.EXPORT_CSV_MODAL.FROM}:
            {durationDetails[chartId]?.currentDuration.from
              ? formatTimestamp(adjustDateTimezone(durationDetails[chartId]?.currentDuration.from), customFormat)
              : from && formatTimestamp(from, customFormat)}
          </Typography>
          <Typography className={classes.text}>
            {content.EXPORT_CSV_MODAL.TO}:
            {durationDetails[chartId]?.currentDuration.to
              ? formatTimestamp(adjustDateTimezone(durationDetails[chartId]?.currentDuration.to), customFormat)
              : to && formatTimestamp(to, customFormat)}
          </Typography>
        </div>
      </>
    );
  };

  const averagingFunction = useMemo(() => {
    switch (chartType) {
      case 'onOff':
      case 'flow':
        onAveragingFunctionFilterChange({
          target: { value: AVERAGING_FUNCTION_FILTER.SUM },
        } as SelectChangeEvent<AVERAGING_FUNCTION_FILTER>);
        return AVERAGING_FUNCTION_FILTER.SUM;
      case 'leak':
        onAveragingFunctionFilterChange({
          target: { value: AVERAGING_FUNCTION_FILTER.MAX },
        } as SelectChangeEvent<AVERAGING_FUNCTION_FILTER>);
        return AVERAGING_FUNCTION_FILTER.MAX;
      default:
        return averagingFunctionFilter;
    }
  }, [onAveragingFunctionFilterChange, chartType, averagingFunctionFilter]);

  return (
    <RadioGroup
      id={id}
      value={value}
      onChange={(e) => {
        setOut((p) => !p);
        onChange && onChange(e);
      }}
      className={className}
      row={row}
    >
      {radioButtons.map(({ label, value, id, showAveragingFunction }, index) => (
        <div className={classes.container}>
          <FormControlLabel
            key={index}
            value={value}
            control={<Radio data-testid={id ? id : 'export-to-csv-radio'} disabled={disabled} />}
            label={label}
          />
          {showAveragingFunction && (
            <SelectAveragingFunction
              averagingFunctionFilter={averagingFunction}
              onAveragingFunctionFilterChange={onAveragingFunctionFilterChange}
              width={180}
              disabled={chartType === 'leak' || chartType === 'flow' || chartType === 'onOff'}
            />
          )}
        </div>
      ))}
      <Divider />
      <div
        className={cx(
          out === undefined && classes.dateRangeWrapper,
          out === false && classes.dateRangeWrapperIn,
          out === true && classes.dateRangeWrapperOut
        )}
      >
        {renderDataRange()}
      </div>
    </RadioGroup>
  );
}
