import { getHttpClient, queryClient } from '@marlin/shared/utils/react-query';
import { useMutation } from '@marlin/shared/utils/react-query';

import { paths } from '../infrastructure/api';
import { queryKey } from './query-key.enum';

export const deleteEquipment = async ({ equipmentId }: { equipmentId: string }) => {
  return await getHttpClient().delete(paths.EQUIPMENT_DEREGISTER, { params: { equipmentId } });
};

export const useDeregisterEquipmentMutation = ({
  onSuccess,
  onError,
}: {
  onSuccess: () => void;
  onError: () => void;
}) => {
  return useMutation({
    onError,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.EQUIPMENTS() });
      onSuccess();
    },
    mutationFn: deleteEquipment,
  });
};
