import { ElkNode } from 'elkjs/lib/elk.bundled';

export const treeToFlatArray = (tree: ElkNode[]): ElkNode[] => {
  const extractChildrenFromTree = (node: ElkNode): ElkNode[] => {
    const children = node.children;

    if (children) {
      return [...children, ...children.map(extractChildrenFromTree).flat()];
    }

    return [];
  };

  return [...tree, ...tree.map(extractChildrenFromTree)].flat();
};
