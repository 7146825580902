import { Dispatch, Reducer } from 'react';

import { MODAL_ACTION_TYPE } from './types';

type TAction =
  | {
      type: MODAL_ACTION_TYPE.SHOW;
      payload: {
        title?: JSX.Element;
        body: JSX.Element;
        footer?: JSX.Element;
        fullscreen?: boolean;
        presentationClassName?: string;
        modalClassName?: string;
      };
    }
  | {
      type: MODAL_ACTION_TYPE.DISMISS;
    };

export type TModalState = {
  visible: boolean;
  title?: JSX.Element;
  body?: JSX.Element;
  footer?: JSX.Element;
  fullscreen?: boolean;
  presentationClassName?: string;
  modalClassName?: string;
};

export type TModalAction = Dispatch<TAction>;

export const initialState: TModalState = {
  visible: false,
  title: undefined,
  body: undefined,
  footer: undefined,
  fullscreen: false,
  presentationClassName: undefined,
  modalClassName: undefined,
};

export const modalReducer: Reducer<TModalState, TAction> = (state, action) => {
  switch (action.type) {
    case MODAL_ACTION_TYPE.SHOW: {
      const { title, body, footer, fullscreen, presentationClassName, modalClassName } = action.payload;
      return {
        ...state,
        visible: true,
        title,
        body,
        footer,
        fullscreen,
        presentationClassName,
        modalClassName,
      };
    }
    case MODAL_ACTION_TYPE.DISMISS: {
      return {
        ...initialState,
      };
    }
  }
};
