import { queryClient, useMutation } from '@marlin/shared/utils/react-query';

import { TUpdateSystemMapLinkParams } from '../flow-link.model.schema';
import { updateFlowLink } from '../infrastructure/update-flow-link';
import { queryKey } from './query-key.enum';

export const useUpdateFlowLink = ({ onSuccess }: { onSuccess: () => void }) => {
  return useMutation<unknown, unknown, TUpdateSystemMapLinkParams>({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.FLOW_LINKS() });
      queryClient.refetchQueries({ queryKey: queryKey.GRAPH() });
      onSuccess();
    },
    mutationFn: updateFlowLink,
  });
};
