import { Typography } from '@mui/material';

import { DetailItem } from './detail-item';
import { useStyles } from './device-drawer-mobile.styles';

interface IDetailRow {
  propName: string;
  propValue?: string | null;
}

export const DetailRow = ({ propName, propValue }: IDetailRow) => {
  const { classes } = useStyles();

  return (
    <DetailItem>
      <div>
        <Typography className={classes.detailsPropNameText}>{propName} </Typography>
      </div>
      <div className={classes.textWrapper}>
        <Typography className={classes.detailsValueText}>{propValue}</Typography>
      </div>
    </DetailItem>
  );
};
