import { useCreateWebhook } from '@marlin/account-data-access-webhook';
import { TWebhookForm } from '@marlin/account-ui-webhook-upsert-form';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { createWebhookApiParamsAdapter } from '../adapters';
import { content } from '../content';
import { useWebhookFlowMeters } from './use-webhook-flow-meters.hook';

interface IUseWebhookCreate {
  defaultValues: TWebhookForm;
  handleSubmit: (values: TWebhookForm) => void;
  handleCancel: () => void;
  isFlowMetersLoading: boolean;
  isFormSubmitting: boolean;
  isError: boolean;
  selectedDefaultFlowMeters: TWebhookForm['devices'];
  selectedAllFlowMeters: TWebhookForm['devices'];
}

interface IRouterStateParams {
  webhookCount?: number;
}

export const useWebhookCreate = (): IUseWebhookCreate => {
  const { mutateAsync: createOrg, isLoading: isFormSubmitting } = useCreateWebhook();
  const { isFlowMetersLoading, isFlowMetersError, selectedDefaultFlowMeters, selectedAllFlowMeters } =
    useWebhookFlowMeters();

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { getState } = useRouter<IRouterStateParams>();

  const { webhookCount = 0 } = getState();

  const defaultValues: TWebhookForm = useMemo(() => {
    return {
      name: content.WEBHOOK_DEFAULT_NAME(webhookCount + 1),
      managerEmail: '',
      url: '',
      frequency: '1day',
      apiKeys: [
        {
          name: '',
          value: '',
        },
      ],
      type: 'total-organization-volume',
      devices: selectedDefaultFlowMeters,
    };
  }, [selectedDefaultFlowMeters, webhookCount]);

  const handleSubmit = useCallback(
    (values: TWebhookForm) => {
      return createOrg(createWebhookApiParamsAdapter(values))
        .then(() => {
          navigate(routes.settings.integration.url());
          enqueueSnackbar(content.WEBHOOK_CREATE_SUCCESS, {
            variant: 'success',
            preventDuplicate: true,
          });
        })
        .catch(() => {
          enqueueSnackbar(content.WEBHOOK_CREATE_ERROR, {
            variant: 'error',
            preventDuplicate: true,
          });
        });
    },
    [createOrg, enqueueSnackbar, navigate]
  );

  const handleCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return useMemo(
    () => ({
      defaultValues,
      handleSubmit,
      isFlowMetersLoading,
      isFormSubmitting,
      handleCancel,
      isError: isFlowMetersError,
      selectedDefaultFlowMeters,
      selectedAllFlowMeters,
    }),
    [
      defaultValues,
      handleSubmit,
      isFlowMetersLoading,
      isFormSubmitting,
      handleCancel,
      isFlowMetersError,
      selectedDefaultFlowMeters,
      selectedAllFlowMeters,
    ]
  );
};
