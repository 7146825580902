import { zodResolver } from '@hookform/resolvers/zod';
import { MarlinTheme } from '@marlin/shared/theme';
import { FormField, Select } from '@marlin/shared/ui-form-common';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
// TODO: move useAssets to assets domain
// eslint-disable-next-line @nx/enforce-module-boundaries,ordered-imports/ordered-imports
import { ASSET_TYPE, useAssets } from '@marlin/system-map/data-access/system-map';
import AddIcon from '@mui/icons-material/Add';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
  Paper,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';
import { useTestGatewayContext } from '../../context/test-flow.context';
import { useRemoveConnectionModal } from '../../hooks/modals/use-remove-connection-modal.hook';
import { useUpsertFormOptions } from '../../hooks/use-upsert-form-options.hook';
import { ConfigureGatewayLeaveGuard } from '../configure-gateway-leave-guard.component';
import { STATUS } from '../status.component';
import { ConnectionAddresses } from './connection-addresses.component';
import {
  TUpsertConnectionFormSchemaType,
  TUpsertConnectionFormSchemaTypeUpdate,
  UpsertConnectionFormSchema,
  UpsertConnectionFormSchemaUpdate,
} from './upsert-connection-form.schema';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    padding: theme.typography.pxToRem(24),
    marginTop: theme.typography.pxToRem(24),
  },
  accordionWrapper: {
    boxShadow: 'unset',
    margin: 0,
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  formTitle: {
    borderBottom: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
    padding: 0,
    '&.Mui-expanded': {
      minHeight: theme.typography.pxToRem(32),
    },
  },
  expandedTitle: {
    '&.Mui-expanded': {
      margin: `0 0 ${theme.typography.pxToRem(24)}`,
    },
  },
  form: {
    margin: `${theme.typography.pxToRem(32)} 0 0`,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(32),
  },
  formSection: {
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(32),
  },
  select: {
    width: '100%',
    maxWidth: theme.typography.pxToRem(288),
  },
  buttonsWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: theme.typography.pxToRem(16),
    padding: 0,
    marginTop: theme.typography.pxToRem(24),
  },
  button: {
    width: `calc(33% - ${theme.typography.pxToRem(8)})`,
    height: theme.typography.pxToRem(42),
  },
  icon: {
    margin: theme.typography.pxToRem(8),
  },
}));

interface IUpsertConnectionFormProps {
  defaultValues?: TUpsertConnectionFormSchemaType;
  onSubmit: (values: TUpsertConnectionFormSchemaType) => void;
  status: STATUS;
}

const emptyAddress = {
  modbusAddress: 0,
  equipmentBrand: '',
  equipmentModel: '',
  name: '',
  locationId: '',
};

const preDefinedValues = {
  id: undefined,
  connections: [
    {
      connectionType: '',
      baudRate: 9600,
      dataBits: 8,
      stopBits: 1,
      parity: 'None',
      addresses: [],
    },
  ],
};

export const UpsertConnectionForm = ({ defaultValues, onSubmit, status }: IUpsertConnectionFormProps) => {
  const { classes } = useStyles();
  const { connectionTypeOptions, baudRateOptions, dataBitsOptions, stopBitsOptions, parityOptions } =
    useUpsertFormOptions();
  const { isTestOnly, setIsTestOnly, operationErrors, addressErrors } = useTestGatewayContext();
  const router = useRouter();

  const localDefaultValues = useMemo(
    () =>
      defaultValues && defaultValues.connections.length === 0
        ? {
            ...preDefinedValues,
            id: defaultValues.id,
          }
        : defaultValues,

    [defaultValues]
  );

  const form = useForm<TUpsertConnectionFormSchemaType | TUpsertConnectionFormSchemaTypeUpdate>({
    resolver: localDefaultValues
      ? zodResolver(UpsertConnectionFormSchemaUpdate)
      : zodResolver(UpsertConnectionFormSchema),
    mode: 'onTouched',
    defaultValues: localDefaultValues || preDefinedValues,
  });
  const {
    control,
    handleSubmit,
    trigger,
    formState: { errors, isValid, isDirty },
    watch,
  } = form;

  useEffect(() => {
    if (addressErrors?.invalidData.length) {
      for (const { connectionIndex, addressIndex } of addressErrors.invalidData) {
        form.setError(`connections.${connectionIndex}.addresses.${addressIndex}.name`, {
          type: 'uniqueName',
          message: content.ERROR_MESSAGE_ADDRESS_UNIQUE_NAME,
        });
      }
    }
  }, [addressErrors, form]);

  useEffect(() => {
    if (!localDefaultValues) {
      form.reset(preDefinedValues);
    } else {
      form.reset(localDefaultValues);
      if (operationErrors.length) {
        localDefaultValues.connections.map((connection, connectionIdx) =>
          connection.addresses.forEach((address, addressIdx) => {
            const error = operationErrors.find((error) => error.addressId === address.id);

            if (error) {
              form.setError(`connections.${connectionIdx}.addresses.${addressIdx}.modbusAddress`, {
                type: 'addressError',
                message: content.ERROR_MESSAGE_ADDRESS,
              });
            }
          })
        );
      }
    }
  }, [localDefaultValues, form, operationErrors]);

  const {
    fields: connections,
    append,
    remove,
    update,
  } = useFieldArray({
    control,
    name: 'connections',
  });
  const validateAllFields = async () => {
    connections.forEach((connection, connectionIdx) => {
      const addressFields = connection.addresses;
      addressFields.forEach((_, addressIndex) => {
        trigger(`connections.${connectionIdx}.addresses.${addressIndex}.name`);
      });
    });
  };

  const connectionsField = watch('connections');

  useEffect(() => {
    setIsTestOnly(status !== STATUS.NEW && !isDirty && connectionsField.some(({ addresses }) => addresses.length > 0));
  }, [status, isDirty, setIsTestOnly, connectionsField]);

  const checkAllLatestAddressFieldsAreValid = useCallback(
    ({
      equipmentBrand,
      equipmentModel,
      modbusAddress,
      name,
      locationId,
    }: TUpsertConnectionFormSchemaType['connections'][0]['addresses'][0]) =>
      !!(modbusAddress && equipmentBrand && equipmentModel && name && locationId),
    []
  );

  const disableSubmitButton =
    !isTestOnly &&
    (connectionsField.some((connection) => !connection.connectionType) ||
      !isValid ||
      !isDirty ||
      !!Object.keys(errors).length ||
      connectionsField.some(({ addresses }) => addresses.some((field) => !checkAllLatestAddressFieldsAreValid(field))));

  const addNewConnection = useCallback(() => append({ ...preDefinedValues.connections[0], addresses: [] }), [append]);
  const addAddressForNewConnection = (connectionIdx: number) => {
    if (connections[connectionIdx].addresses.length > 0) return;
    update(connectionIdx, {
      ...connections[connectionIdx],
      addresses: [emptyAddress],
    });
  };

  const { removeConnection } = useRemoveConnectionModal({
    onRemove: (index: number) => {
      remove(index);
      if (connections.length === 1) addNewConnection();
    },
  });

  const onCancel = useCallback(() => {
    router.goTo(routes.gateway.list.url());
  }, [router]);

  const locationsQuery = useAssets(
    {
      filter: ASSET_TYPE.LOCATION,
    },
    true
  );

  return (
    <FormProvider {...form}>
      {connections.map((connection, idx) => {
        const connectionType = connectionsField?.[idx]?.connectionType;
        const disableDeleteConnstion = connectionsField?.[idx]?.addresses.length > 0;

        return (
          <Paper className={classes.wrapper}>
            <Accordion defaultExpanded key={connection.id} className={classes.accordionWrapper}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                classes={{ root: classes.formTitle, content: classes.expandedTitle }}
              >
                <Typography variant="h6">{content.FORM.CONNECTION_TITLE(idx + 1)}</Typography>
              </AccordionSummary>
              <AccordionDetails data-testid="upsert-connection-form" className={classes.form}>
                <div className={classes.formSection}>
                  <div>
                    <FormField<TUpsertConnectionFormSchemaType> fieldName={`connections.${idx}.connectionType`}>
                      {(props) => (
                        <Select
                          {...props}
                          required
                          prefix="connectionType"
                          label={content.FORM.CONNECTION_FIELD}
                          data={connectionTypeOptions(connectionsField.map(({ connectionType }) => connectionType))}
                          className={classes.select}
                          error={errors.connections?.[idx]?.connectionType}
                          requiredErrorMessageOnBlur={content.FORM.REQUIRED}
                          onChange={(e: SelectChangeEvent) => {
                            addAddressForNewConnection(idx);
                            props.onChange && props.onChange(e);
                          }}
                        />
                      )}
                    </FormField>
                    <IconButton
                      disabled={disableDeleteConnstion}
                      onClick={() => {
                        removeConnection(idx);
                      }}
                    >
                      <DeleteRoundedIcon className={classes.icon} />
                    </IconButton>
                  </div>
                  {connectionType && (
                    <>
                      <FormField<TUpsertConnectionFormSchemaType> fieldName={`connections.${idx}.baudRate`}>
                        {(props) => (
                          <Select
                            {...props}
                            required
                            prefix="baudRate"
                            label={content.FORM.BAUD_RATE_FIELD}
                            data={baudRateOptions}
                            className={classes.select}
                            error={errors.connections?.[idx]?.baudRate}
                            requiredErrorMessageOnBlur={content.FORM.REQUIRED}
                          />
                        )}
                      </FormField>
                      <FormField<TUpsertConnectionFormSchemaType> fieldName={`connections.${idx}.dataBits`}>
                        {(props) => (
                          <Select
                            {...props}
                            required
                            prefix="dataBits"
                            label={content.FORM.DATA_BITS_FIELD}
                            data={dataBitsOptions}
                            className={classes.select}
                            error={errors.connections?.[idx]?.dataBits}
                          />
                        )}
                      </FormField>
                      <FormField<TUpsertConnectionFormSchemaType> fieldName={`connections.${idx}.stopBits`}>
                        {(props) => (
                          <Select
                            {...props}
                            required
                            prefix="stopBits"
                            label={content.FORM.STOP_BITS_FIELD}
                            data={stopBitsOptions}
                            className={classes.select}
                            error={errors.connections?.[idx]?.stopBits}
                          />
                        )}
                      </FormField>
                      <FormField<TUpsertConnectionFormSchemaType> fieldName={`connections.${idx}.parity`}>
                        {(props) => (
                          <Select
                            {...props}
                            required
                            prefix="parity"
                            label={content.FORM.PARITY_FIELD}
                            data={parityOptions}
                            className={classes.select}
                            error={errors.connections?.[idx]?.parity}
                          />
                        )}
                      </FormField>
                    </>
                  )}
                </div>
                {connectionType && (
                  <ConnectionAddresses
                    validateAllFields={validateAllFields}
                    connectionIdx={idx}
                    locations={locationsQuery.data || []}
                  />
                )}
              </AccordionDetails>
            </Accordion>
          </Paper>
        );
      })}
      <div className={classes.buttonsWrapper}>
        <Button data-testid="gateway-cancel-button" className={classes.button} variant="outlined" onClick={onCancel}>
          {content.FORM.CANCEL}
        </Button>
        <Button
          disabled={connections.length >= 2}
          data-testid="add-connection-button"
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={addNewConnection}
          className={classes.button}
        >
          {content.FORM.ADD_CONNECTION}
        </Button>
        <Button
          data-testid="gateway-submit-button"
          className={classes.button}
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          disabled={disableSubmitButton}
        >
          {isTestOnly ? content.FORM.TEST : content.FORM.CREATE}
        </Button>
      </div>
      <ConfigureGatewayLeaveGuard isDirty={isDirty} />
    </FormProvider>
  );
};
