import { createContext } from 'react';

import { TPermission } from './available-permissions';

interface IPermissionContextType {
  isAllowedTo: (permission: TPermission | TPermission[]) => boolean;
}

const defaultBehaviour: IPermissionContextType = {
  isAllowedTo: (): boolean => false,
};

const PermissionContext = createContext<IPermissionContextType>(defaultBehaviour);

export default PermissionContext;
