import { TDatapointWithMetadata } from '@marlin/shared/utils/datapoint-mappers';
import moment from 'moment';

import { useDatapointsContext } from '../../context/datapoints.context';

export const useSanitizationDetails = () => {
  const { getDatapoint } = useDatapointsContext();
  const overrideMode = getDatapoint('overrideMode');
  const overrideTime = getDatapoint('overrideTime');
  const setpointTarget = getDatapoint('setpointTarget');
  const isSanitization = overrideMode?.value === 'sanitize';

  const remainingTime = overrideTime?.value
    ? moment.utc(parseInt(overrideTime.value, 10) * 1000).format('HH:mm:ss')
    : undefined;

  return {
    isSanitization,
    shouldDisplayBanner: shouldDisplayBanner(overrideMode, overrideTime, setpointTarget),
    remainingTime,
    setpointTargetValue: setpointTarget?.displayedValue ?? '',
  };
};

const shouldDisplayBanner = (
  overrideMode: TDatapointWithMetadata | undefined,
  overrideTime: TDatapointWithMetadata | undefined,
  setpointTarget: TDatapointWithMetadata | undefined
) => {
  return !!(
    overrideMode &&
    overrideTime &&
    setpointTarget &&
    (overrideMode?.value.toLowerCase() === 'sanitize' || overrideMode?.value.toLowerCase() === 'startup')
  );
};
