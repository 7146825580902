export const idToken = 'id_token';
export const code = 'code';
export const expiresOn = 'expires_on';
export const grantType = 'grant_type';
export const clientId = 'client_id';
export const scope = 'scope';
export const clientSecret = 'client_secret';

export enum ERROR_CODE {
  ACCESS_DENIED = 'access_denied',
}

export enum RESPONSE_CODE {
  USER_CANCELLATION = 'AADB2C90091',
  ACCOUNT_NOT_FOUND_FOF_PROVIDED_USER_ID = 'AADB2C99002',
}

export enum STATUS_TYPE {
  B2C = 'b2c-status',
}

export enum STATUS_OPTION {
  ERROR = 'error',
  SUCCESS = 'success',
}
