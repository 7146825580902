import { FormField, useSelectWithBlurValidation } from '@marlin/shared/ui-form-common';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as TypesSelect,
  SelectChangeEvent,
} from '@mui/material';
import * as React from 'react';
import { ControllerProps, FieldValues, useController } from 'react-hook-form';

import { content } from '../content';
import { useStyles } from './equipment-model-control.styles';
import { TEquipmentFormTypes } from './types';
import { useEquipmentType } from './use-equipment-type.hook';

interface IEquipmentTypeControlProps<TFieldValues extends FieldValues> {
  required?: boolean;
  fieldName: ControllerProps<TFieldValues>['name'];
  onChange?: (event: SelectChangeEvent<TEquipmentFormTypes | undefined>) => void;
  requiredErrorMessageOnBlur?: string;
}

export const EquipmentModelControl = <TFieldValues extends FieldValues>({
  fieldName,
  required,
  onChange,
  requiredErrorMessageOnBlur,
}: IEquipmentTypeControlProps<TFieldValues>) => {
  const { classes } = useStyles();
  const { equipmentTypes } = useEquipmentType();
  const {
    fieldState: { error },
  } = useController<TFieldValues>({ name: fieldName });

  const { validateOnBlur, handleOnChange } = useSelectWithBlurValidation({
    fieldName,
    requiredErrorMessageOnBlur,
    onChange,
    error,
  });

  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    if (validateOnBlur) {
      validateOnBlur(event.target.value);
    }
  };

  return (
    <FormField<TFieldValues> fieldName={fieldName}>
      {({ error, ...props }) => (
        <FormControl error={!!error} className={classes.equipmentTypeSelect}>
          <InputLabel id="choose-equipment-label" required={true}>
            {content.CHOOSE_EQUIPMENT_LABEL}
          </InputLabel>
          <TypesSelect
            {...props}
            required={required}
            prefix={fieldName}
            label={content.CHOOSE_EQUIPMENT_LABEL}
            onChange={(e) => {
              props?.onChange?.(e);
              handleOnChange(e);
            }}
            onBlur={handleOnBlur}
            data-testId="select-filters-choose-equipment"
          >
            {equipmentTypes.map((type) => (
              <MenuItem key={type.id} value={type.id}>
                <div>
                  <span>{type.name}</span>
                  {type.description && (
                    <FormHelperText className={classes.helperText}>{type.description}</FormHelperText>
                  )}
                </div>
              </MenuItem>
            ))}
          </TypesSelect>
          <FormHelperText className={classes.helper}>{error?.message}</FormHelperText>
        </FormControl>
      )}
    </FormField>
  );
};
