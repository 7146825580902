import { MarlinTheme } from '@marlin/shared/theme';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../content';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  error: {
    color: theme.palette.error.main,
    fontWeight: theme.typography.fontWeightBold,
    marginRight: theme.typography.pxToRem(16),
  },
}));

export const DatapointError = () => {
  const { classes } = useStyles();

  return (
    <Typography variant="body1" className={classes.error}>
      {content.DATAPOINT_ERROR_LABEL}
    </Typography>
  );
};
