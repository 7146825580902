import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import { IconButton } from '@mui/material';
import { useCallback } from 'react';

export interface IPinDeviceToggleProps {
  pinned: boolean;
  onToggle: (value: boolean) => void;
}

const PinItemToggle = ({ pinned, onToggle }: IPinDeviceToggleProps) => {
  const onClick = useCallback(() => onToggle(!pinned), [onToggle, pinned]);

  return (
    <IconButton
      onClick={onClick}
      color="inherit"
      data-testid={pinned ? 'PushPinIconButton' : 'PushPinOutlinedIconButton'}
    >
      {pinned ? <PushPinIcon /> : <PushPinOutlinedIcon />}
    </IconButton>
  );
};

export { PinItemToggle };
