import { MarlinTheme } from '@marlin/shared/theme';
import Avatar from '@mui/material/Avatar';
import { alpha } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

interface ICounterWithIconProps {
  iconElement: JSX.Element;
  text: string;
  counter?: number;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  counterWithIcon: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(14),
    '& > svg': {
      marginRight: theme.typography.pxToRem(4),
      height: theme.typography.pxToRem(20),
      width: theme.typography.pxToRem(20),
      fill: alpha(theme.palette.text.primary, 0.54),
    },
    '& > div': {
      marginLeft: theme.typography.pxToRem(8),
      height: theme.typography.pxToRem(28),
      width: theme.typography.pxToRem(28),
      fontSize: theme.typography.pxToRem(13),
      color: theme.palette.primary.main,
      backgroundColor: `${theme.palette.primary.main}14`,
    },
  },
}));

export const CounterWithIcon = ({ iconElement, text, counter = 0 }: ICounterWithIconProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.counterWithIcon}>
      {iconElement}
      {text}
      <Avatar>{counter}</Avatar>
    </div>
  );
};
