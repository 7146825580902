import { TAsset } from '@marlin/alert/data-access/automated-action';
import { MarlinTheme } from '@marlin/shared/theme';
import { CommonChip } from '@marlin/shared/ui/chips';
import { useController } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { IAutomationHubFilters } from './types';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  locationChipsWrapper: {
    marginBottom: theme.typography.pxToRem(16),
  },
}));

export const FilterChips = () => {
  const { classes } = useStyles();
  const {
    field: { value: locations, onChange: onChangeLocations },
  } = useController<IAutomationHubFilters>({ name: 'locations' });

  const {
    field: { value: equipments, onChange: onChangeEquipments },
  } = useController<IAutomationHubFilters>({ name: 'equipments' });

  const {
    field: { value: devices, onChange: onChangeDevices },
  } = useController<IAutomationHubFilters>({ name: 'devices' });

  const removeLocationChip = (id: string): TAsset[] => {
    return (locations as TAsset[])?.filter((asset) => asset.id !== id);
  };

  const removeEquipmentChip = (id: string): TAsset[] => {
    return (equipments as TAsset[])?.filter((asset) => asset.id !== id);
  };

  const removeDeviceChip = (id: string): TAsset[] => {
    return (devices as TAsset[])?.filter((asset) => asset.id !== id);
  };

  return (
    <div className={classes.locationChipsWrapper}>
      {(locations as TAsset[])?.map((value: TAsset) => (
        <CommonChip
          testId={`filterChip-${value.id}`}
          key={value.id}
          label={value.name}
          active={true}
          onDelete={() => onChangeLocations && onChangeLocations({ target: { value: removeLocationChip(value.id) } })}
        />
      ))}
      {(equipments as TAsset[])?.map((value: TAsset) => (
        <CommonChip
          testId={`filterChip-${value.id}`}
          key={value.id}
          label={value.name}
          active={true}
          onDelete={() =>
            onChangeEquipments && onChangeEquipments({ target: { value: removeEquipmentChip(value.id) } })
          }
        />
      ))}
      {(devices as TAsset[])?.map((value: TAsset) => (
        <CommonChip
          testId={`filterChip-${value.id}`}
          key={value.id}
          label={value.name}
          active={true}
          onDelete={() => onChangeDevices && onChangeDevices({ target: { value: removeDeviceChip(value.id) } })}
        />
      ))}
    </div>
  );
};
