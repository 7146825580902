export enum TAB_INDEX {
  CURRENT = 1,
  SNOOZED = 2,
  RESOLVED = 3,
  ALL = 4,
}

export interface ITab {
  id: TAB_INDEX;
  label: string;
  icon: JSX.Element;
}
