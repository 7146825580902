import { z } from 'zod';

const timeTrendSchema = z.object({
  averageUsage: z.number(),
  totalUsage: z.number(),
  trend: z.number(),
  maxTrendOrganizationId: z.string(),
  maxTrendOrganization: z.string(),
  maxTrend: z.number(),
});

export const trendsSchema = z.object({
  waterUsage: z.object({
    week: timeTrendSchema.optional(),
    month: timeTrendSchema.optional(),
    year: timeTrendSchema.optional(),
  }),
  gasUsage: z.object({
    week: timeTrendSchema.optional(),
    month: timeTrendSchema.optional(),
    year: timeTrendSchema.optional(),
  }),
  electricityUsage: z.object({
    week: timeTrendSchema.optional(),
    month: timeTrendSchema.optional(),
    year: timeTrendSchema.optional(),
  }),
});

export type TTrends = z.infer<typeof trendsSchema>;
export type TTimeTrend = z.infer<typeof timeTrendSchema>;
