import { Chip, ChipPropsVariantOverrides } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import { ReactElement, ReactNode } from 'react';

import { useStyles } from './chip.styles';

export interface IChipProps {
  icon?: ReactElement;
  testId?: string;
  label: ReactNode;
  onDelete?: () => void;
  variant?: OverridableStringUnion<'filled' | 'outlined', ChipPropsVariantOverrides>;
  clickable?: boolean;
  onClick?: () => void;
  active?: boolean;
}

export const CommonChip = ({ testId, label, active, onDelete, onClick, clickable, variant, icon }: IChipProps) => {
  const { classes } = useStyles({ isActive: active });
  return (
    <Chip
      data-testid={testId}
      className={classes.locationChip}
      label={label}
      onDelete={onDelete}
      onClick={onClick}
      clickable={clickable}
      variant={variant}
      icon={icon}
    />
  );
};
