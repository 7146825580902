import { z } from 'zod';

const chartDatapointDataTypeList = ['number', 'bool'] as const;
export const ChartDatapointDataType = z.enum(chartDatapointDataTypeList);
export type TChartDatapointDataType = z.infer<typeof ChartDatapointDataType>;

const chartDatapointDisplayTypeList = ['datapoint', 'threshold'] as const;
export const ChartDatapointDisplayType = z.enum(chartDatapointDisplayTypeList);
export type TChartDatapointDisplayType = z.infer<typeof ChartDatapointDisplayType>;

const chartThresholdPositionList = ['top', 'bottom'] as const;
export const ChartThresholdPosition = z.enum(chartThresholdPositionList);
export type TChartThresholdPosition = z.infer<typeof ChartThresholdPosition>;
