import { queryClient, useMutation } from '@marlin/shared/utils/react-query';

import { updateGraph } from '../infrastructure/graph';
import { queryKey } from './query-key.enum';

export const useUpdateGraph = () => {
  return useMutation({
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: queryKey.FLOW_LINKS() });
      queryClient.setQueryData(queryKey.GRAPH(), data);
    },
    mutationFn: updateGraph,
  });
};
