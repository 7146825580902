import { useMemo } from 'react';

import { content } from '../../../content';
import { TStatus } from '../types';
import { BaseStatus } from './base-status.component';

export interface IPerformanceStatusProps {
  status: TStatus;
  className?: string;
}

export const PerformanceStatus = ({ status, className }: IPerformanceStatusProps) => {
  const message = useMemo(() => {
    switch (status) {
      case 'error':
        return content.EQUIPMENT.PERFORMANCE.error;
      case 'warning':
        return content.EQUIPMENT.PERFORMANCE.warning;
      default:
        return content.EQUIPMENT.PERFORMANCE.success;
    }
  }, [status]);

  return (
    <BaseStatus title={content.EQUIPMENT.PERFORMANCE.label} status={status} message={message} className={className} />
  );
};
