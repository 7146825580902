import { Input, Select } from '@marlin/shared/ui-form-common';
import { TNode } from '@marlin/system-map/data-access/system-map';
import { Grid } from '@mui/material';
import { ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';

const selectData = [
  {
    id: 'TEE',
    name: content.TEE,
  },
];

export const useStyles = makeStyles()(() => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'baseline',
  },

  assetSelect: {
    width: '100%',
  },
}));

interface INodeProps {
  defaultValue?: TNode;
  title: ReactNode;
}

export const TeeNode = ({ defaultValue, title }: INodeProps) => {
  const { classes } = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div className={classes.header}>{title}</div>
      </Grid>
      <Grid item xs={12}>
        <Select
          data={selectData}
          className={classes.assetSelect}
          fullWidth
          prefix="asset-type"
          emptyOption={content.ASSET_TYPE_PLACEHOLDER}
          label={content.ASSET_TYPE_LABEL}
          value="TEE"
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <Input label={content.ASSET_LABEL} value={defaultValue?.name} disabled />
      </Grid>
    </Grid>
  );
};
