import { environment } from '@marlin/environment';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import { MarlinTheme } from '../theme';

interface ILogoStyles {
  height: number;
  center?: boolean;
}

const marlinLogoHeight = 21;
const nexaLogoHeight = 32;

export const useStyles = makeStyles<ILogoStyles>()((theme: MarlinTheme, { height, center }) => ({
  logoWrapper: {
    display: 'flex',
    justifyContent: center ? 'center' : 'start',
    marginBottom: theme.typography.pxToRem(26),
    marginLeft: center ? 0 : theme.typography.pxToRem(16),
  },

  logo: {
    '& > svg': {
      height: theme.typography.pxToRem(height),
    },
  },

  textLogo: {
    marginLeft: theme.typography.pxToRem(12),
    '& > svg': {
      height: theme.typography.pxToRem(height),
    },
  },
}));

interface ILogoProps {
  isCollapsed?: boolean;
  locked?: boolean;
}

const showCollapsibleLogoVariant = environment.app.name === 'Nexa' || environment.app.name === 'OTA';

export const CollapsableLogo = ({ isCollapsed = false, locked = true }: ILogoProps) => {
  const logoHeight = showCollapsibleLogoVariant ? nexaLogoHeight : marlinLogoHeight;

  const { classes } = useStyles({ height: logoHeight, center: showCollapsibleLogoVariant });
  const theme = useTheme<MarlinTheme>();

  if (showCollapsibleLogoVariant)
    return (
      <div className={classes.logoWrapper}>
        {!isCollapsed || locked ? (
          <div className={classes.logo}>{theme.icon.fullLogo}</div>
        ) : (
          <div className={classes.logo}>{theme.icon.logo}</div>
        )}
      </div>
    );

  return (
    <div className={classes.logoWrapper}>
      <div className={classes.logo}>{theme.icon.logo}</div>
      {locked ? (
        <div className={classes.textLogo}>{theme.icon.textLogo}</div>
      ) : (
        !isCollapsed && <div className={classes.textLogo}>{theme.icon.textLogo}</div>
      )}
    </div>
  );
};
