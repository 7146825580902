import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { ControllerRenderProps } from 'react-hook-form';

export interface IRadioButton {
  value: string;
  label: string;
  id?: string;
}

interface IInputProps extends Partial<ControllerRenderProps> {
  id?: string;
  buttons: IRadioButton[];
  className?: string;
  row?: boolean;
  disabled?: boolean;
}

export function RadioButtons({ id, buttons, value, onChange, className, row, disabled }: IInputProps) {
  return (
    <RadioGroup id={id} value={value} onChange={onChange} className={className} row={row}>
      {buttons.map(({ label, value, id }, index) => (
        <FormControlLabel
          key={index}
          value={value}
          control={<Radio data-testid={id ? id : 'automation-criticality-radio'} disabled={disabled} />}
          label={label}
        />
      ))}
    </RadioGroup>
  );
}
