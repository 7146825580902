import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { getLogger } from '@marlin/shared/utils/logger';
import { TCreateSystemMapNodeParams, TTag, useCreateNode } from '@marlin/system-map/data-access/system-map';
import { Edge, useReactFlow } from '@xyflow/react';
import { useCallback } from 'react';

import { calculateSnappedValue } from '../../use-snap-to-grid.hook';
import { content } from './content';

type TTaggedEdge = Edge & { tags?: TTag[] };

export const useAddTeeContextMenu = () => {
  const createNodeMutation = useCreateNode();
  const { enqueueSnackbar } = useSnackbar();
  const { getEdge, screenToFlowPosition } = useReactFlow();

  const createNewTeeObj = useCallback(
    (edge: TTaggedEdge, x: number, y: number): { flowLinkId: string; data: TCreateSystemMapNodeParams } => {
      const { x: flowX, y: flowY } = screenToFlowPosition({ x, y });

      const inletTags: TTag[] = [];
      const outletTags: TTag[] = [];

      if (edge.tags) {
        edge.tags.forEach((tag) => {
          if (tag.name?.includes('Inlet')) {
            inletTags.push(tag);
          } else if (tag.name?.includes('Outlet')) {
            outletTags.push(tag);
          }
        });
      }

      return {
        flowLinkId: edge.id,
        data: {
          nodeType: 'TEE',
          positionX: flowX,
          positionY: flowY,
          inletTags: inletTags.length ? inletTags : undefined,
          outletTags: outletTags.length ? outletTags : undefined,
          tags: [{ name: 'rfPosition', value: `${flowX},${flowY}` }],
        },
      };
    },
    [screenToFlowPosition]
  );

  const createTee = useCallback(
    async (id: string, x: number, y: number) => {
      const edge = getEdge(id);
      if (!edge) {
        return;
      }
      try {
        await createNodeMutation.mutateAsync(createNewTeeObj(edge, calculateSnappedValue(x), calculateSnappedValue(y)));
        enqueueSnackbar(content.ADD_TEE_SUCCESS_MESSAGE, {
          variant: 'success',
          preventDuplicate: true,
        });
        getLogger()?.track('CreateTeeSuccess');
      } catch (e) {
        enqueueSnackbar(content.ADD_TEE_ERROR_MESSAGE, {
          variant: 'error',
          preventDuplicate: true,
        });
      }
    },
    [createNodeMutation, enqueueSnackbar, getEdge, createNewTeeObj]
  );

  return {
    createTee,
  };
};
