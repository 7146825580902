import { GatewayMode } from '@marlin/shared/utils/zod';
import { z } from 'zod';

import { Network } from './gateway.schema';

const Address = z.object({
  id: z.string().uuid(),
  address: z.number(),
  name: z.string(),
  password: z.string().nullish(),
  deviceId: z.string().uuid(),
  modelId: z.string().uuid(),
  locationId: z.string().uuid(),
  //TODO: remove optional after BE adjustments
  operations: z
    .array(
      z.object({
        code: z.string(),
      })
    )
    .optional(),
});

export const GatewayConfigurationResponse = z.object({
  id: z.string().uuid(),
  deviceId: z.string().uuid(),
  description: z.string().nullish(),
  version: z.number(),
  configurationFile: z.string(),
  currentConfigurationId: z.string().uuid().nullish(),
  gatewayMode: GatewayMode.nullish(),
  connections: z.array(
    z.object({
      id: z.string().uuid(),
      name: z.string(),
      network: Network,
      addresses: z.array(Address),
    })
  ),
});
export type TGatewayConfigurationResponse = z.infer<typeof GatewayConfigurationResponse>;
