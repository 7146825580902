import { ICondition, TCreateAutomation } from '@marlin/alert/data-access/automated-action';
import { IRadioButton, RadioButtons } from '@marlin/shared/ui-form-common';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { useContent } from '../../../../context';
import { DEVICE_TYPE } from '../../../../types';

const leakSensorConditions: ICondition = {
  thresholdMin: {
    value: 0,
    enabled: true,
  },
  thresholdMax: {
    value: 0.5,
    enabled: true,
  },
};

export function LeakConditions() {
  const content = useContent();
  const { setValue, trigger } = useFormContext<TCreateAutomation>();

  const buttons: IRadioButton[] = useMemo(
    () => [
      {
        label: content.CONDITIONS.LEAK,
        value: DEVICE_TYPE.LEAK,
      },
    ],
    [content.CONDITIONS.LEAK]
  );

  useEffect(() => {
    if (leakSensorConditions.thresholdMax && leakSensorConditions.thresholdMin) {
      setValue('conditions.thresholdMin.value', leakSensorConditions.thresholdMin.value);
      setValue('conditions.thresholdMax.value', leakSensorConditions.thresholdMax.value);
      setValue('conditions.thresholdMax.enabled', leakSensorConditions.thresholdMax.enabled);
      setValue('conditions.thresholdMin.enabled', leakSensorConditions.thresholdMin.enabled);
    }

    // trigger validation to mark condition fields as touched
    trigger(['conditions.thresholdMin.value', 'conditions.thresholdMax.value']);
  }, [trigger, setValue]);

  return <RadioButtons value={DEVICE_TYPE.LEAK} buttons={buttons} />;
}
