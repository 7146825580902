import { FilterChip, IFilter } from '@marlin/shared/ui/chips';
import { ReactNode, useMemo } from 'react';
import { FieldValues, Path, useController } from 'react-hook-form';

interface IFilterChipsProps<TFormValues extends FieldValues, TName extends keyof TFormValues> {
  fieldName: TName;
  mapFieldToChips: (value: TFormValues[TName]) => IFilter[];
  deleteFilter: (id: string, value: TFormValues[TName]) => TFormValues[TName];
}

export function FieldFiltersChips<
  TFormValues extends FieldValues,
  TName extends Path<TFormValues> & keyof TFormValues // TODO: Improve typings for nested fields
>({ fieldName, mapFieldToChips, deleteFilter }: IFilterChipsProps<TFormValues, TName>): ReactNode {
  const {
    field: { value, onChange },
  } = useController<TFormValues>({ name: fieldName });

  const chips = useMemo(() => mapFieldToChips(value), [mapFieldToChips, value]);

  return chips.map(({ id, name }) => (
    <FilterChip
      id={id}
      testId={`filters-chip-${id}`}
      label={name}
      onDelete={() => value && onChange && onChange({ target: { value: deleteFilter(id, value) } })}
    />
  ));
}
