import { z } from 'zod';

const datapointStatusList = [
  'okay',
  'absent',
  'open',
  'short',
  'noDemand',
  'demand',
  'overrideOff',
  'overrideHand',
  'overrideMaxHeat',
  'noHeatAbortCutoff',
  'noHeatNormal',
  'wwsd',
  'coolDown',
  'heatOff',
  'heatOn',
  'establishingSteamTime',
  'establishingSteamTemp',
  'invisible',
  'on',
  'off',
] as const;

export const DatapointStatus = z.enum(datapointStatusList);
export type TDatapointStatus = z.infer<typeof DatapointStatus>;

export const MetadataStatus = z.object({
  id: z.string(),
  name: DatapointStatus,
});
export type TMetadataStatus = z.infer<typeof MetadataStatus>;
