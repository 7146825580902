import { ModalMedium } from '@marlin/shared/ui-modal';
import { Button } from '@mui/material';

import { content } from './content';
import { useStyles } from './remove-recipient-modal.component.styles';

export interface IRemoveRecipientModal {
  onCancel: () => void;
  onSubmit: () => void;
  isOpen: boolean;
}

export const RemoveRecipientModal = ({ onCancel, onSubmit, isOpen }: IRemoveRecipientModal) => {
  const { classes } = useStyles();

  if (!isOpen) {
    return null;
  }

  return (
    <ModalMedium
      title={content.CONFIRM_DELETE_RECIPIENT_TITLE}
      onClose={onCancel}
      body={<div>{content.CONFIRM_DELETE_RECIPIENT_TEXT}</div>}
      footer={
        <>
          <Button variant="text" onClick={onCancel}>
            {content.CONFIRM_DELETE_RECIPIENT_CANCEL_BUTTON}
          </Button>
          <Button className={classes.cancelButton} variant="contained" color="error" onClick={onSubmit}>
            {content.CONFIRM_DELETE_RECIPIENT_DELETE_BUTTON}
          </Button>
        </>
      }
    />
  );
};
