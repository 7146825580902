import SignalCellular4BarRoundedIcon from '@mui/icons-material/SignalCellular4BarRounded';
import { Button } from '@mui/material';

import { useStyles } from './button.styles';

interface IButtonComponentProps {
  isOpen?: boolean;
  onClick: () => void;
}

export const ButtonExpandComponent = ({ isOpen = false, onClick }: IButtonComponentProps) => {
  const { classes } = useStyles();

  return (
    <Button
      data-testid="expand-device-drawer"
      className={isOpen ? classes.containerOpened : classes.container}
      variant="outlined"
      onClick={onClick}
    >
      <SignalCellular4BarRoundedIcon className={classes.icon} />
    </Button>
  );
};
