import { MarlinTheme } from '@marlin/shared/theme';
import { Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactNode } from 'react';

import { useStyles } from './section-container.styles';

interface ISectionContainerProps {
  title: string;
  children: ReactNode;
  isFullScreen?: boolean;
  options?: ReactNode;
  selector?: ReactNode;
}

export const SectionContainer = ({ title, options, children, isFullScreen, selector }: ISectionContainerProps) => {
  const { classes, cx } = useStyles({ isFullScreen });
  const theme = useTheme<MarlinTheme>();

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={cx(!!selector && classes.headerContent)}>
          <h2 className={classes.headerTitle}>{title}</h2>
          {selector}
        </div>
        {isFullScreen && <div className={classes.logo}>{theme.icon.fullLogo}</div>}
        <div>{options}</div>
      </div>
      <Divider />
      <div className={classes.children}>{children}</div>
    </div>
  );
};
