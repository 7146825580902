import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => {
  return {
    overlay: {
      position: 'fixed',
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      zIndex: theme.indexPosition.foreground,
      top: 0,
      left: 0,
      right: '100%',
      bottom: '100%',
      width: '100%',
      height: '100%',
      overflow: 'auto',
      backgroundColor: theme.palette.text.disabled,
    },
    loader: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
      width: theme.typography.pxToRem(60),
      height: theme.typography.pxToRem(60),
      position: 'absolute',
      top: '40%',
      left: `calc(50% - ${theme.typography.pxToRem(30)})`,
      '& svg': {
        fill: theme.palette.primary.main,
      },
    },
  };
});
