import { EQUIPMENT_TYPE } from '@marlin/shared/utils/zod';
import { AssetTypeSchema, NodeTypeSchema } from '@marlin/system-map/shared/data-access-schemas';
import isNil from 'lodash/isNil';
import * as z from 'zod';

import { content } from './content';
import { ASSET_TYPE } from './types';

const tagSchema = z.object({
  name: z.string().nullish(),
  value: z.string().nullish(),
});

export const NodeSchema = z.object({
  id: z.string().nullish(),
  assetId: z.string().nullish(),
  type: z.nativeEnum(ASSET_TYPE).nullish(),
  name: z.string(),
  nodeType: NodeTypeSchema,
  assetType: AssetTypeSchema.nullish(),
  tags: z.array(tagSchema).nullish(),
  equipmentModel: z.nativeEnum(EQUIPMENT_TYPE).nullish(),
  equipmentHandle: z.string().nullish(),
});

export type TFormNode = z.infer<typeof NodeSchema>;

const FlowNodeSchema = (required: string) =>
  NodeSchema.nullable().superRefine((value, ctx) => {
    if (value === null) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: required,
      });
    }

    const isEquipmentWithoutHandle = value?.tags?.some(
      (tag) => tag.name === 'Model' && (tag.value === undefined || tag.value === 'LDS')
    );

    if (isEquipmentWithoutHandle) {
      return;
    }

    const hasHandleIdTag = value?.tags?.some((tag) => tag.name === 'HandleId');

    if (value?.assetType === ASSET_TYPE.EQUIPMENT && !hasHandleIdTag && value?.equipmentHandle === undefined) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: content.EQUIPMENT_HANDLE_REQUIRED,
        path: ['equipmentHandle'],
      });
    }
  });

export const FlowLinkSchema = z
  .object({
    inlet: FlowNodeSchema(content.VALIDATION_INLET_REQUIRED),
    outlet: FlowNodeSchema(content.VALIDATION_OUTLET_REQUIRED),
  })
  .superRefine((value, ctx) => {
    const emptyBothAssets = isNil(value.inlet?.assetId) && isNil(value.outlet?.assetId);
    const emptyBothNodes = isNil(value.inlet?.id) && isNil(value.outlet?.id);

    if (!emptyBothNodes && value.inlet?.id === value.outlet?.id) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: content.VALIDATION_LOOP,
        path: ['inlet'],
      });
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: content.VALIDATION_LOOP,
        path: ['outlet'],
      });

      if (!emptyBothAssets && value.inlet?.assetId === value.outlet?.assetId) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: content.VALIDATION_LOOP,
          path: ['inlet'],
        });
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: content.VALIDATION_LOOP,
          path: ['outlet'],
        });
      }
    }
  });
