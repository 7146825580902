import { useDesktopSideNavbarCollapse } from '@marlin/shared/utils-common-desktop-sidenav';
import { useCallback, useMemo } from 'react';

import { TDashboardSection } from '../types';

export const useFullScreen = (
  handleFullScreen: (section: TDashboardSection | null) => void,
  fullScreenChart: TDashboardSection | null,
  chartId: TDashboardSection
) => {
  const { handleHideDesktopNavbar, handleShowDesktopNavbar } = useDesktopSideNavbarCollapse();
  const openFullScreen = useCallback(() => {
    handleFullScreen(chartId);
    handleHideDesktopNavbar();
  }, [chartId, handleFullScreen, handleHideDesktopNavbar]);

  const closeFullScreen = useCallback(() => {
    handleFullScreen(null);
    handleShowDesktopNavbar();
  }, [handleFullScreen, handleShowDesktopNavbar]);

  const isFullScreen = useMemo(() => fullScreenChart === chartId, [fullScreenChart, chartId]);

  return { openFullScreen, closeFullScreen, isFullScreen };
};
