import { TUnitOfMeasure, parseDisplayedValue } from '@marlin/shared/utils-format-reading';

import { content } from './content';
import { ERROR_TYPES } from './types';

export const getTitleErrorText = (
  alertType: ERROR_TYPES,
  uoM: TUnitOfMeasure | null,
  min?: number,
  max?: number
): string => {
  switch (alertType) {
    case ERROR_TYPES.LOWER: {
      if (min) {
        return content.TITLE.LOWER(parseDisplayedValue(min?.toString(), uoM, undefined, 'decimal'));
      }
      return content.TITLE.LOWER(min?.toString());
    }
    case ERROR_TYPES.HIGHER: {
      if (max) {
        return content.TITLE.HIGHER(parseDisplayedValue(max?.toString(), uoM, undefined, 'decimal'));
      }
      return content.TITLE.HIGHER(max?.toString());
    }
    default: {
      return '';
    }
  }
};
