export const content = {
  TITLE: 'New Recipient',
  BUTTONS: {
    CONFIRM: 'create',
    CANCEL: 'cancel',
  },
  VALIDATION: {
    REQUIRED: 'This is a required field',
    EMAIL: 'This value must be a valid email address',
    MAX_LENGTH: (max: number) => `Value cannot exceed ${max} characters`,
    REQUIRED_LENGTH: (length: number) => `Field value should have ${length} number(s)`,
    RECIPIENT_WITH_THIS_EMAIL_ALREADY_IN_ORGANIZATION:
      'Recipient with this email already exists in the current organization',
    USER_WITH_THIS_EMAIL_ALREADY_EXISTS: 'User with this email already exists',
    INVITATION_ALREADY_IN_PROGRESS: 'Invitation already in progress',
  },
};
