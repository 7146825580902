import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: theme.typography.pxToRem(64),
    padding: theme.typography.pxToRem(16),
  },

  tooltipContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: theme.typography.pxToRem(4),
  },

  detailsPropNameText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
  },

  detailsValueText: {
    wordWrap: 'break-word',
    textAlign: 'right',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.body2.fontSize,
  },

  textWrapper: {
    maxWidth: theme.typography.pxToRem(196),
  },
}));
