import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import { GraphSchema, TGraph, TGraphResponse } from '../flow-map.model.schema';
import { TCreateSystemMapLinkParams, createSystemMapLinkRequestSchema } from '../system-map.model.schema';
import { paths } from './api';

export const createSystemMapLink = async (params: TCreateSystemMapLinkParams): Promise<TGraph> => {
  const body = safeParseData(params, createSystemMapLinkRequestSchema);

  const res = await getHttpClient().post<unknown, TGraphResponse>(paths.SYSTEM_MAP_LINK, body);
  return safeParseData(res, GraphSchema);
};
