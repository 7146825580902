import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import { paths } from './api';
import { TLocationParamsSchema, getLocationParamsSchema } from './schema/location.request.schema';
import { TLocationResponseSchema, getLocationResponseSchema } from './schema/location.response.schema';

export const getFilteredLocations = async (params: TLocationParamsSchema): Promise<TLocationResponseSchema> => {
  const body = safeParseData(params, getLocationParamsSchema);
  const res = await getHttpClient().post<unknown, TLocationResponseSchema>(paths.LOCATIONS_FILTER, body);

  return getLocationResponseSchema.parse(res);
};
