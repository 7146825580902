import { Link } from '@marlin/shared/ui-page';

import { content } from '../../content';
import { BaseError } from './base-error.component';

export interface IRegistrationFailedErrorProps {
  handleButtonClick?: () => void;
}

export const RegistrationFailedError = ({ handleButtonClick }: IRegistrationFailedErrorProps) => {
  return (
    <BaseError
      title={content.ERROR.REGISTRATION_FAILED.TITLE}
      subtitle={
        <>
          {content.ERROR.REGISTRATION_FAILED.SUBTITLE1}{' '}
          <Link to={`mailto:${content.ERROR.REGISTRATION_FAILED.LINK}`} external>
            {content.ERROR.REGISTRATION_FAILED.LINK}
          </Link>{' '}
          {content.ERROR.REGISTRATION_FAILED.SUBTITLE2}
        </>
      }
      buttonText={content.ERROR.REGISTRATION_FAILED.BUTTON}
      handleButtonClick={handleButtonClick}
    />
  );
};
