import { MarlinTheme } from '@marlin/shared/theme';
import {
  CustomContentProps,
  SnackbarContent as SnackbarContentNotistack,
  useSnackbar,
} from '@marlin/shared/ui/snackbar-wrapper';
import { TCycleNode } from '@marlin/system-map/data-access/system-map';
import CloseIcon from '@mui/icons-material/Close';
import { Card, CardActions, IconButton } from '@mui/material';
import { forwardRef } from 'react';
import { makeStyles } from 'tss-react/mui';

import { SnackbarContent } from './loop-message.component';

interface ISnackbar extends CustomContentProps {
  cycleNode: TCycleNode;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  card: {
    width: theme.typography.pxToRem(460),
    backgroundColor: theme.palette.alternative.dark,
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },

  content: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${theme.typography.pxToRem(12)} ${theme.typography.pxToRem(20)}`,
  },

  crossIcon: {
    position: 'fixed',
    right: theme.typography.pxToRem(8),
    top: theme.typography.pxToRem(14),
    '& > svg': {
      height: theme.typography.pxToRem(20),
    },
  },
}));

const snackbarKey = 'loopSnackbar';

export const Snackbar = forwardRef<HTMLDivElement, ISnackbar>((props, ref) => {
  const { cycleNode, ...notistackProps } = props;
  const { closeSnackbar } = useSnackbar();
  const { classes } = useStyles();

  return (
    <SnackbarContentNotistack {...notistackProps} key={snackbarKey} ref={ref} role="alert">
      <Card className={classes.card}>
        <CardActions classes={{ root: classes.content }} data-testid="loop-snackbar">
          <SnackbarContent cycleNode={cycleNode} />
          <div>
            <IconButton
              className={classes.crossIcon}
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => closeSnackbar(`${snackbarKey}-${cycleNode.id}`)}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </CardActions>
      </Card>
    </SnackbarContentNotistack>
  );
});
