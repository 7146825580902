import { TDeviceMetadataDatapointsResponse } from '@marlin/shared/utils/datapoint-mappers';

import { logValidationIssue } from './log-validation-issue';

export const validateMetadata = (metadata: TDeviceMetadataDatapointsResponse | undefined, datapointName: string) => {
  if (!metadata) {
    logValidationIssue(datapointName, 'NO_METADATA');
    return false;
  }

  switch (metadata.type) {
    case 'datetime':
    case 'string':
    case 'bool':
      return true;
    case 'number':
      return validateMinMaxStep(metadata, datapointName);
    case 'enum':
      return validateEnumType(metadata, datapointName);
    default:
      logValidationIssue(datapointName, 'TYPE_NOT_EXISTS');
      return false;
  }
};

const validateEnumType = (metadata: TDeviceMetadataDatapointsResponse, datapointName: string) => {
  const minMaxStepValidationResult = validateMinMaxStep(metadata, datapointName);
  if (!minMaxStepValidationResult) {
    return false;
  }

  const options = metadata.options;
  if (!options || !options.length) {
    logValidationIssue(datapointName, 'OPTIONS_NOT_EXISTS');
    return false;
  }

  if (new Set(options.map((option) => option.id)).size !== options.length) {
    logValidationIssue(datapointName, 'DUPLICATED_OPTIONS');
    return false;
  }

  return true;
};

const validateMinMaxStep = (metadata: TDeviceMetadataDatapointsResponse, datapointName: string) => {
  if (!metadata.min) {
    logValidationIssue(datapointName, 'INCORRECT_MIN');
    return false;
  }
  if (!metadata.max) {
    logValidationIssue(datapointName, 'INCORRECT_MAX');
    return false;
  }
  if (!metadata.step) {
    logValidationIssue(datapointName, 'INCORRECT_STEP');
    return false;
  }

  return true;
};
