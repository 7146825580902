import { TCreateAutomation } from '@marlin/alert/data-access/automated-action';
import { MarlinTheme } from '@marlin/shared/theme';
import { DeviceTypeControl } from '@marlin/shared/ui-form';
import { Input } from '@marlin/shared/ui-form-common';
import { Autocomplete, Button, Grid } from '@mui/material';
import { useCallback } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useContent } from '../../../context';
import { ILocation } from '../../../types';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  select: {
    width: '100%',
  },

  deviceTypeSelect: {
    marginTop: theme.typography.pxToRem(8),
  },
}));

interface IDeviceFilters {
  locations: ILocation[];
  onFiltersClose: (value: boolean) => void;
  currentLocation: ILocation | null;
  onLocationChange: (value: ILocation | null) => void;
  clearDeviceType: () => void;
}

export function Filters({
  locations,
  onFiltersClose,
  currentLocation,
  onLocationChange,
  clearDeviceType,
}: IDeviceFilters) {
  const { classes } = useStyles();
  const content = useContent();

  const handleLocationChange = useCallback(
    (event: unknown, value: ILocation | null) => {
      onLocationChange(value);
    },
    [onLocationChange]
  );

  const clearFilters = useCallback(() => {
    onFiltersClose(false);
    onLocationChange(null);
    clearDeviceType();
  }, [onFiltersClose, onLocationChange, clearDeviceType]);

  return (
    <>
      <Grid item xs={12}>
        <Autocomplete
          value={currentLocation}
          data-testid="select-location"
          options={locations}
          getOptionLabel={(option: ILocation) => option.name}
          renderOption={(props, option: ILocation) => (
            <li {...props} key={option.id}>
              {option.name}
            </li>
          )}
          filterSelectedOptions
          className={classes.select}
          onChange={handleLocationChange}
          renderInput={(params) => <Input label={content.SENSOR.LOCATION_LABEL} {...params} />}
        />
      </Grid>
      <Grid item xs={12} className={classes.deviceTypeSelect}>
        <DeviceTypeControl<TCreateAutomation> fieldName="deviceType" />
      </Grid>
      <Grid item xs={12}>
        <Button data-testid="new-automation-filters-close" variant="text" onClick={clearFilters}>
          {content.SENSOR.CLEAR_ALL}
        </Button>
      </Grid>
    </>
  );
}
