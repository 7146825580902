import z from 'zod';

export const UserData = z.record(z.any());
export const UserDataResponse = z.object({
  data: z.string(),
});
export const UserDataPayload = z.object({
  area: z.string(),
  data: z.string(),
});
export type TUserDataPayload = z.infer<typeof UserDataPayload>;
