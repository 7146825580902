import { routes } from '@marlin/shared/utils-routes';

export interface IFeatureNavigation {
  getEquipmentDetailsPageLink: (id: string, isRegistered: boolean) => string;
  getGatewayDetailsPageLink: (id: string) => string;
}

export const useNavigation = (): IFeatureNavigation => {
  return {
    getEquipmentDetailsPageLink: (id: string, isRegistered: boolean) => {
      if (isRegistered) {
        return routes.equipmentDashboard.details.url(id);
      }
      return routes.equipments.details.url(id);
    },
    getGatewayDetailsPageLink: (id: string) => {
      return routes.gateway.details.url(id);
    },
  };
};
