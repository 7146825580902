import { MarlinTheme } from '@marlin/shared/theme';
import { Button, Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    marginTop: theme.typography.pxToRem(32),
    marginBottom: theme.typography.pxToRem(32),
    justifyContent: 'space-between',
  },
}));

interface IActionButtonProps {
  onNext: () => void;
  onCancel: () => void;
  isDisabled?: boolean;
  cancelLabel?: string;
  nextLabel?: string;
}

export const Buttons = ({ onNext, onCancel, isDisabled = false, cancelLabel, nextLabel }: IActionButtonProps) => {
  const { classes } = useStyles();

  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item>
        <Button variant="outlined" data-testid="cancel-button" onClick={onCancel}>
          {cancelLabel || content.BTN_CANCEL}
        </Button>
      </Grid>
      <Grid item>
        <Button disabled={isDisabled} data-testid="continue-button" variant="contained" onClick={onNext}>
          {nextLabel || content.BTN_CONTINUE}
        </Button>
      </Grid>
    </Grid>
  );
};
