import { MarlinTheme } from '@marlin/shared/theme';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface ICardHeader {
  title: string;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
    marginTop: 0,
    marginBottom: theme.typography.pxToRem(8),
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.body1.fontSize,
      marginBottom: 0,
    },
  },

  headerRow: {
    justifyContent: 'space-between',
  },
}));

export function CardHeader({ title }: ICardHeader) {
  const { classes } = useStyles();

  return (
    <Grid className={classes.headerRow} container>
      <Grid item>
        <Typography variant="h5" className={classes.title} data-testid="card-title">
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
}
