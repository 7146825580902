import { useCallback } from 'react';

import { useSelectedSensorsStore, useSetSelectedSensors } from '../context/selected-sensors.context';

interface IUseEditChartName {
  chartId: string;
}

export const useEditChartName = ({ chartId }: IUseEditChartName) => {
  const [selectedSensorsForChartId] = useSelectedSensorsStore((store) => store[chartId]);
  const setSelectedSensors = useSetSelectedSensors();

  const editChartName = useCallback(
    ({ chartName }: { chartName: string }) => {
      setSelectedSensors({
        [chartId]: {
          ...selectedSensorsForChartId,
          chartName,
        },
      });
    },
    [setSelectedSensors, selectedSensorsForChartId, chartId]
  );

  return {
    editChartName,
  };
};
