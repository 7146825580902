import { MarlinTheme } from '@marlin/shared/theme';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  changeOrgNameText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: theme.typography.pxToRem(0.15),
    lineHeight: '160%',
  },
}));

export const Title = () => {
  const { classes } = useStyles();
  return <Typography className={classes.changeOrgNameText}> {content.CHANGE_ORG_NAME} </Typography>;
};
