export const content = {
  CURRENTLY_SET: 'Current Setting',
  ERROR: 'Something went wrong. Please try again later.',
  CANCEL: 'Cancel',
  ENABLE: 'Enable',
  DISABLE: 'Disable',
  SET: 'Set',
  CONFIRMATION_MODAL_BODY_MESSAGE_ENABLE: 'Are you sure you want to enable this option?',
  CONFIRMATION_MODAL_BODY_MESSAGE_DISABLE: 'Are you sure you want to disable this option?',
  DATAPOINT_ERROR_LABEL: 'Error',
  ON: 'On',
  OFF: 'Off',
  NEW_VALUE: 'New value',
  THRESHOLD_ERROR: (val: string, isMaxExceeded: boolean) =>
    `You cannot set the temperature ${
      isMaxExceeded ? 'above' : 'below'
    } ${val} remotely. You must change it on the equipment locally to set it ${
      isMaxExceeded ? 'above' : 'below'
    } this threshold.`,
  MIN_VALUE: (value: number | string) => `Min ${value}`,
  MAX_VALUE: (value: number | string) => `Max ${value}`,
  SETTINGS_CONNECTION_ERROR_TITLE: 'There is no connection',
  SETTINGS_CONNECTION_ERROR_BODY: 'Check your connection and try to refresh the page.',
  REFRESH: 'Refresh',
  UNSAVED_CHANGES_MODAL_TITLE: 'You have unsaved changes',
  UNSAVED_CHANGES_MODAL_BODY: 'You are about to leave this page. Do you want to save changes?',
  DISCARD: 'Discard',
  SAVE: 'Save',
  PENDING: 'Pending',
};
