import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  section: {
    alignItems: 'center',
  },

  sectionTitle: {
    fontWeight: theme.typography.fontWeightBold,
    marginRight: theme.typography.pxToRem(75),
    marginBottom: theme.typography.pxToRem(16),
  },

  adornment: {
    marginRight: theme.typography.pxToRem(16),
    display: 'flex',
    justifyContent: 'center',
  },
}));
