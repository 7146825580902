import { TWaterUsageDevice } from '@marlin/asset/data-access/home-dashboard';
import { MarlinTheme } from '@marlin/shared/theme';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { getLogger } from '@marlin/shared/utils/logger';
import { Button, useMediaQuery, useTheme } from '@mui/material';
import { useMemo } from 'react';

import { content } from '../content';

interface ITotalizationButtonProps {
  devices: TWaterUsageDevice[];
  showFlowMeterDetails: (totalizationDevices: TWaterUsageDevice[]) => void;
}

export const TotalizationButton = ({ devices, showFlowMeterDetails }: ITotalizationButtonProps) => {
  const router = useRouter();
  const theme = useTheme<MarlinTheme>();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const currentUrl = useMemo(() => router.getUrl(), [router]);

  const isOnlyOneFlowMeterSelected = useMemo(() => devices.length === 1, [devices]);

  const handleOpenFlowMeter = () => {
    if (devices[0].deviceId) {
      router.goTo(routes.deviceDrawer.open(currentUrl, devices[0].deviceId));

      getLogger()?.track('HomePageTotalizationView_FlowMeterInteraction', {
        numberOfFlowMeteres: devices.length,
      });
    }
  };

  const handleShowHistory = () => {
    showFlowMeterDetails(devices);
  };

  return (
    <Button
      onClick={isOnlyOneFlowMeterSelected ? handleOpenFlowMeter : handleShowHistory}
      variant="outlined"
      size={isMobile ? 'small' : 'medium'}
      data-testid={isOnlyOneFlowMeterSelected ? 'view-flow-meter-button' : 'show-history-button'}
    >
      {isOnlyOneFlowMeterSelected ? content.VIEW_FLOW_METER : content.SHOW_HISTORY}
    </Button>
  );
};
