import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

import { AlertAccordion } from './alert-accordion/alert-accordion.component';
import { content } from './content';
import { ALERT_STATUS_FILTER } from './types';
import { useAlerts } from './use-alerts.hook';
import { getListPageQueryLink as automationLink } from './utils/get-automation-url';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    marginTop: theme.typography.pxToRem(16),
    marginBottom: theme.typography.pxToRem(16),
  },
}));

interface IAlertTabProps {
  isExtended?: boolean;
}

export function AlertTab({ isExtended }: IAlertTabProps): JSX.Element {
  const { classes } = useStyles();

  const {
    currentAlerts,
    totalCurrent,
    resolvedAlerts,
    totalResolved,
    snoozedAlerts,
    totalSnoozed,
    reloadAlerts,
    loadMoreCurrentAlerts,
    loadMoreResolvedAlerts,
    loadMoreSnoozedAlerts,
    isLoadingSnoozedAlerts,
    isLoadingCurrentAlerts,
    isLoadingResolvedAlerts,
  } = useAlerts();

  return (
    <div className={classes.container}>
      <AlertAccordion
        automationLink={automationLink}
        onOpen={() => reloadAlerts(ALERT_STATUS_FILTER.CURRENT)}
        title={content.TITLE_CURRENT(totalCurrent)}
        alerts={currentAlerts}
        totalAlertsCount={totalCurrent}
        onLoadMore={loadMoreCurrentAlerts}
        isLoading={isLoadingCurrentAlerts}
        defaultExpanded
        prefix="current"
        isExtended={isExtended}
      />
      <AlertAccordion
        automationLink={automationLink}
        onOpen={() => reloadAlerts(ALERT_STATUS_FILTER.SNOOZED)}
        title={content.TITLE_SNOOZED(totalSnoozed)}
        alerts={snoozedAlerts}
        totalAlertsCount={totalSnoozed}
        isLoading={isLoadingSnoozedAlerts}
        onLoadMore={loadMoreSnoozedAlerts}
        prefix="snoozed"
        isExtended={isExtended}
      />
      <AlertAccordion
        automationLink={automationLink}
        onOpen={() => reloadAlerts(ALERT_STATUS_FILTER.RESOLVED)}
        title={content.TITLE_RESOLVED(totalResolved)}
        alerts={resolvedAlerts}
        totalAlertsCount={totalResolved}
        isLoading={isLoadingResolvedAlerts}
        onLoadMore={loadMoreResolvedAlerts}
        prefix="resolved"
        isExtended={isExtended}
      />
    </div>
  );
}
