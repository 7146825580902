import { Link } from '@marlin/shared/ui-page';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { TAssetType } from '@marlin/system-map/shared/data-access-schemas';
import isFunction from 'lodash/isFunction';
import { useMemo } from 'react';

interface INameCell {
  assetType: TAssetType;
  name: string;
  assetId: string;
}

export const CellName = ({ assetType, assetId, name }: INameCell) => {
  const router = useRouter();
  const currentUrl = useMemo(() => router.getUrl(), [router]);
  let goTo: ((value: string) => string) | undefined;

  switch (assetType) {
    case 'LOCATION': {
      goTo = (id: string) => routes.locations.details.url(id);
      break;
    }
    case 'EQUIPMENT': {
      goTo = (id: string) => routes.equipments.details.url(id);
      break;
    }
    case 'DEVICE': {
      goTo = (id: string) => routes.deviceDrawer.open(currentUrl, id);
      break;
    }
    default: {
      break;
    }
  }

  if (!goTo || !isFunction(goTo)) {
    return <>{name}</>;
  }

  return <Link to={goTo(assetId)}>{name}</Link>;
};
