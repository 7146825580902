import { MarlinTheme } from '@marlin/shared/theme';
import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import { Button } from '@mui/material';
import { useCallback, useContext } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  cancelButton: {
    marginRight: theme.typography.pxToRem(8),
  },
}));

export const useStopTestsModal = ({ onStop }: { onStop: () => void }) => {
  const { classes } = useStyles();
  const { modalDispatch } = useContext(ModalContext);

  const handleCloseModal = useCallback(() => {
    modalDispatch({
      type: MODAL_ACTION_TYPE.DISMISS,
    });
  }, [modalDispatch]);

  const handleStopTest = useCallback(() => {
    onStop();
    modalDispatch({
      type: MODAL_ACTION_TYPE.DISMISS,
    });
  }, [modalDispatch, onStop]);

  const modalTestGateway = () => ({
    title: <div>{content.STOP_TEST_MODAL.TITLE}</div>,
    body: (
      <div>
        <div>{content.STOP_TEST_MODAL.BODY_TEXT}</div>
        <br />
        <div>{content.STOP_TEST_MODAL.BODY_SUBTEXT}</div>
      </div>
    ),
    footer: (
      <div data-testid="test-modal-footer">
        <Button
          className={classes.cancelButton}
          variant="text"
          data-testid="test-modal-footer-cancel"
          onClick={handleCloseModal}
        >
          {content.STOP_TEST_MODAL.CANCEL}
        </Button>
        <Button variant="contained" color="error" data-testid="test-modal-footer-confirm" onClick={handleStopTest}>
          {content.STOP_TEST_MODAL.STOP_TEST}
        </Button>
      </div>
    ),
  });

  const stopTestGateway = () => {
    modalDispatch({
      type: MODAL_ACTION_TYPE.SHOW,
      payload: modalTestGateway(),
    });
  };

  return { stopTestGateway, closeModal: handleCloseModal };
};
