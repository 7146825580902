import { HierarchyItem } from '@marlin/asset/shared/ui/hub-card';
import { LinkedItem } from '@marlin/asset/shared/ui/hub-card';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { useNavigation } from '@marlin/shared/ui-device-drawer-mobile';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { Accordion, AccordionDetails, Typography } from '@mui/material';

import { content } from '../../content';
import { useExtendedDeviceDrawer } from '../container/context';
import { ExtendedWrapper } from '../readings/extended-wrapper.component';
import { DEVICE_TYPE } from '../readings/types';
import { useStyles } from './details.component.styles';
import { useDetails } from './use-details.hook';

export const mapDeviceType = (deviceType?: string): string => {
  switch (deviceType) {
    case DEVICE_TYPE.LEAK: {
      return content.LEAK_ITEM;
    }
    case DEVICE_TYPE.PRESSURE: {
      return content.PRESSURE_ITEM;
    }
    case DEVICE_TYPE.TEMPERATURE: {
      return content.TEMPERATURE_ITEM;
    }
    case DEVICE_TYPE.PULSE_METER: {
      return content.FLOW_METER_ITEM;
    }
    default: {
      return '';
    }
  }
};

export const DeviceDrawerDetails = () => {
  const { classes } = useStyles();
  const { deviceDetails, deviceLocation, parentLocation, gatewayId } = useDetails();
  const { getLocationDetailsPageLink, getGatewayPageLink } = useNavigation();
  const isExtended = useExtendedDeviceDrawer();

  return (
    <ExtendedWrapper className={classes.accordionWrapper}>
      <Accordion
        disableGutters
        square
        defaultExpanded
        className={classes.accordionContainer}
        data-testid="drawer-device-details"
        disabled={isExtended}
      >
        <AccordionDetails className={isExtended ? classes.accordionDetailsExtended : classes.accordionDetails}>
          <div className={isExtended ? classes.expandedDetailsContainer : classes.detailsContainer}>
            <div className={classes.detailContainer}>
              <div data-testid="label" className={classes.detail}>
                {content.SENSOR_NAME}
              </div>
              <div
                data-testid="value"
                className={isExtended ? classes.propertyKeyTextExtended : classes.propertyKeyText}
              >
                <Typography className={classes.propertyValueText}>{deviceDetails?.name}</Typography>
              </div>
            </div>
            <div className={classes.detailContainer}>
              <div data-testid="label" className={classes.detail}>
                {content.SENSOR_TYPE}
              </div>
              <div
                data-testid="value"
                className={isExtended ? classes.propertyKeyTextExtended : classes.propertyKeyText}
              >
                <Typography className={classes.propertyValueText}>
                  {mapDeviceType(deviceDetails?.deviceType)}
                </Typography>
              </div>
            </div>
            <div className={classes.detailContainer}>
              <div data-testid="label" className={classes.detail}>
                {content.LOCATION}
              </div>
              <div data-testid="value" className={classes.linkItemWrapper}>
                <HierarchyItem parent={parentLocation} children={deviceLocation} goTo={getLocationDetailsPageLink} />
              </div>
            </div>
            <div className={classes.detailContainer}>
              <div data-testid="label" className={classes.detailWithTooltipContainer}>
                <span className={classes.detailWithTooltip}>{content.ID}</span>
                <Tooltip text={content.ID_TOOLTIP}>
                  <InfoRoundedIcon fontSize="small" color="primary" />
                </Tooltip>
              </div>
              <Tooltip text={deviceDetails?.id ?? ''}>
                <div
                  data-testid="value"
                  className={isExtended ? classes.propertyKeyTextExtended : classes.propertyKeyText}
                >
                  <Typography className={classes.propertyValueText}>{deviceDetails?.id}</Typography>
                </div>
              </Tooltip>
            </div>
            <div className={classes.detailContainer}>
              <div data-testid="label" className={classes.detail}>
                {content.GATEWAY_ID}
              </div>
              <div data-testid="value" className={classes.linkItemWrapper}>
                <LinkedItem id={gatewayId} name={gatewayId} goTo={getGatewayPageLink} />
              </div>
            </div>
            <div className={classes.detailContainer}>
              <div data-testid="label" className={classes.detail}>
                {content.MANUFACTURER}
              </div>
              <Tooltip text={deviceDetails?.manufacturer ?? ''}>
                <div
                  data-testid="value"
                  className={isExtended ? classes.propertyKeyTextExtended : classes.propertyKeyText}
                >
                  <Typography className={classes.propertyValueText}>{deviceDetails?.manufacturer}</Typography>
                </div>
              </Tooltip>
            </div>
            <div className={classes.detailContainer}>
              <div data-testid="label" className={classes.detailWithTooltipContainer}>
                <span className={classes.detailWithTooltip}>{content.MANUFACTURER_ID}</span>
                <Tooltip text={content.MANUFACTURER_ID_TOOLTIP}>
                  <InfoRoundedIcon fontSize="small" color="primary" />
                </Tooltip>
              </div>
              <Tooltip text={deviceDetails?.displayManufacturerId ?? ''}>
                <div
                  data-testid="value"
                  className={isExtended ? classes.propertyKeyTextExtended : classes.propertyKeyText}
                >
                  <Typography className={classes.propertyValueText}>{deviceDetails?.displayManufacturerId}</Typography>
                </div>
              </Tooltip>
            </div>
            <div className={classes.detailContainer}>
              <div data-testid="label" className={classes.detail}>
                {content.MODEL}
              </div>
              <div
                data-testid="value"
                className={isExtended ? classes.propertyKeyTextExtended : classes.propertyKeyText}
              >
                <Typography className={classes.propertyValueText}>{deviceDetails?.model}</Typography>
              </div>
            </div>
            <div className={classes.detailContainer}>
              <div data-testid="label" className={classes.detail}>
                {content.DESCRIPTION}
              </div>
              <Tooltip text={deviceDetails?.description ?? ''}>
                <div
                  data-testid="value"
                  className={isExtended ? classes.propertyKeyTextExtended : classes.propertyKeyText}
                >
                  <Typography className={classes.propertyValueText}>{deviceDetails?.description}</Typography>
                </div>
              </Tooltip>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </ExtendedWrapper>
  );
};
