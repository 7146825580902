import { dateAdapter } from '@marlin/shared/utils-common-date';
import { useSearchParamsRepository } from '@marlin/shared/utils-router';
import { ISensorHubParams } from '@marlin/shared/utils-routes';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { DeviceList } from './device-list.component';
import { IDeviceHubFilters, useDevicesHub } from './use-devices-hub.hook';

export const FilteredDeviceList = () => {
  const { watch } = useFormContext();
  const { getSearchParam } = useSearchParamsRepository<ISensorHubParams>();

  const batteryLevelStatus = getSearchParam('batteryLevelStatus');
  const filterByLostCommunication = !!getSearchParam('lostCommunication');

  const lastReadingTo = useMemo(() => {
    if (!filterByLostCommunication) {
      return undefined;
    }
    return dateAdapter?.date()?.subtract(8, 'hours').toISOString();
  }, [filterByLostCommunication]);

  const formState = watch();
  const filters = { ...(formState as IDeviceHubFilters), batteryLevelStatus, lastReadingTo };

  if (!filters.sorting || filters.sorting.sortBy === '') {
    delete filters.sorting;
  }

  const { rows, totalItems, hasNextPage, fetchNextPage, isLoading } = useDevicesHub(filters);

  return (
    <DeviceList
      rows={rows}
      totalItems={totalItems}
      hasNextPage={hasNextPage}
      isListLoading={isLoading}
      fetchNextPage={fetchNextPage}
    />
  );
};
