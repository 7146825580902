import { TDeviceSchema } from '@marlin/asset/data-access/device';
import { checkIfDeviceHasLostCommunication, formatSensorDatapoints } from '@marlin/shared/utils-format-reading';
import { useMemo } from 'react';

import { content } from '../../content';

interface ILastReadingCellProps {
  row: TDeviceSchema;
  cellClassName?: string;
}

export const LastReadingCell = ({
  row: { lastReadingValues, lastReadingTime },
  cellClassName,
}: ILastReadingCellProps) => {
  const formattedReading = useMemo(() => {
    if (lastReadingValues.length === 0) {
      if (checkIfDeviceHasLostCommunication(lastReadingTime)) {
        return content.LOST_COMMUNICATION;
      }

      return '';
    }

    return formatSensorDatapoints(lastReadingValues);
  }, [lastReadingTime, lastReadingValues]);

  return <span className={cellClassName}>{formattedReading}</span>;
};
