import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { ToggleFullScreenButton } from '@marlin/shared/ui-modal';
import { getLogger } from '@marlin/shared/utils/logger';
import { TCreateSystemMapNodeParams } from '@marlin/system-map/data-access/system-map';
import {
  Background,
  BackgroundVariant,
  ConnectionMode,
  Panel,
  ReactFlow,
  Viewport,
  useOnViewportChange,
} from '@xyflow/react';
import { useCallback, useEffect, useRef, useState } from 'react';

import { TSystemMapNode, TTeeGraphAsset } from '../types';
import { defaultEdgeOptions, gridSize } from './const';
import { SystemTagMenu } from './context-menu/components/system-tag-menu.component';
import { ContextMenu } from './context-menu/context-menu.component';
import { edgeTypes } from './edges';
import { MarkerDefinitions } from './edges/markers';
import { useStyles } from './flow-diagram.styles';
import { nodeTypes } from './nodes';
import { useResizeObserverError } from './resize-observer-error.hook';
import { useContextMenu } from './use-context-menu.hook';
import { useDragDrop } from './use-drag-drop.hook';
import { useFlowDiagram } from './use-flow-diagram.hook';
import { useRealtimeData } from './use-realtime-data.hook';

interface IFlowDiagramProps {
  toggleFullScreen: () => void;
  isFullScreen: boolean;
  onAddNodeToTee: (tee: TCreateSystemMapNodeParams) => void;
  openRenameTeeModal: (teeToRename: TTeeGraphAsset) => void;
  onNodeDrag: () => void;
  isEditMode: boolean;
}

export const FlowDiagram = ({
  toggleFullScreen,
  isFullScreen,
  onAddNodeToTee,
  openRenameTeeModal,
  onNodeDrag,
  isEditMode,
}: IFlowDiagramProps) => {
  useResizeObserverError();
  const ref = useRef(null);
  const { classes } = useStyles();
  const { nodes, edges, handleEdgeChange, handleNodeChange, setRfInstance, saveDiagram, isGraphLoading } =
    useFlowDiagram();
  const [showLoader, setShowLoader] = useState(true);

  const { menu, onNodeContextMenu, onPaneClick, onEdgeContextMenu, systemTagMenuState, setSystemTag } = useContextMenu({
    ref,
    onAddNodeToTee,
    openRenameTeeModal,
  });
  const { onNodeDragStop } = useDragDrop();
  useRealtimeData(isEditMode);

  useOnViewportChange({
    onChange: (viewport: Viewport) => onPaneClick(),
  });

  const handleToggleFullScreen = () => {
    saveDiagram();
    toggleFullScreen();
  };

  const onNodeDragHandler = useCallback(() => {
    // leaving it here in case we need additional action on drag
    onNodeDrag();
  }, [onNodeDrag]);

  useEffect(() => {
    if (isGraphLoading) {
      setShowLoader(isGraphLoading);
    } else {
      // note - delay to avoid chart rendering race condition and elements jumping
      setTimeout(() => setShowLoader(isGraphLoading), 500);
    }
  }, [isGraphLoading]);

  return (
    <>
      <MarkerDefinitions />
      <ReactFlow<TSystemMapNode>
        ref={ref}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        nodes={nodes}
        edges={edges}
        onNodesChange={handleNodeChange}
        onEdgesChange={handleEdgeChange}
        onEdgeContextMenu={isEditMode ? onEdgeContextMenu : undefined}
        onInit={setRfInstance}
        defaultEdgeOptions={defaultEdgeOptions}
        onNodeContextMenu={isEditMode ? onNodeContextMenu : undefined}
        onPaneClick={onPaneClick}
        onNodeDragStop={onNodeDragStop}
        nodesDraggable={isEditMode}
        elementsSelectable={isEditMode}
        onNodeDrag={onNodeDragHandler}
        elevateEdgesOnSelect={false}
        elevateNodesOnSelect={false}
        snapToGrid={false}
        snapGrid={[gridSize, gridSize]}
        connectionMode={ConnectionMode.Loose}
        minZoom={0.1}
        fitView
        proOptions={{
          hideAttribution: true,
        }}
        onNodeClick={(_event, node) => {
          getLogger()?.track('SystemMapNodeClicked', {
            activeAlerts: node?.data?.alerts?.length,
            nodeType: `${node?.data?.nodeType || ''} ${node?.data?.assetType || ''} ${
              node?.data?.deviceType || ''
            }`.trim(),
          });
        }}
      >
        <Background variant={BackgroundVariant.Dots} gap={gridSize} size={1} />
        {menu && <ContextMenu menu={menu} onClose={onPaneClick} />}
        {systemTagMenuState?.systemTagMenuAnchor && (
          <SystemTagMenu
            anchor={systemTagMenuState?.systemTagMenuAnchor}
            onClose={onPaneClick}
            selectedTag={systemTagMenuState?.currentSystemTag}
            setSystemTag={setSystemTag}
          />
        )}
        <Panel className={classes.panel} position={'bottom-right'}>
          <ToggleFullScreenButton isFullScreen={isFullScreen} onClick={handleToggleFullScreen} />
        </Panel>
      </ReactFlow>
      {showLoader && (
        <div className={classes.loader}>
          <LoadingSpinner />
        </div>
      )}
    </>
  );
};
