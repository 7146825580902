import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  label: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.primary,
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    padding: theme.typography.pxToRem(6),
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },

  dot: {
    marginRight: theme.typography.pxToRem(10),
    height: theme.typography.pxToRem(12),
    width: theme.typography.pxToRem(12),
    borderRadius: '50%',
    padding: theme.typography.pxToRem(4),
    display: 'inline-block',
  },

  high: {
    backgroundColor: theme.palette.error.main,
  },

  low: {
    backgroundColor: theme.palette.warning.light,
  },

  no: {
    backgroundColor: theme.palette.success.light,
  },
}));
