import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { MarlinTheme } from '@marlin/shared/theme';
import { FormHelperText } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { Buttons } from './buttons.component';
import { FormContent } from './form-content.component';
import { UpsertRecipientFormValidationSchema } from './recipient-upsert-form.schema';
import { IUpsertRecipientProfile } from './types';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  errorMessageWrapper: {
    paddingTop: theme.typography.pxToRem(24),
  },
}));

const defaultValues: IUpsertRecipientProfile = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
};

interface IRecipientUpsertFormProps {
  recipient?: IUpsertRecipientProfile | undefined;
  onSubmit: (data: IUpsertRecipientProfile) => void;
  onCancel: () => void;
  resetError: () => void;
  editMode?: boolean;
  errorMessage: string;
}

export const RecipientUpsertForm = ({
  onSubmit,
  onCancel,
  resetError,
  errorMessage,
  recipient,
  editMode = false,
}: IRecipientUpsertFormProps) => {
  const { classes } = useStyles();
  const form = useForm<IUpsertRecipientProfile>({
    defaultValues: { ...defaultValues, ...recipient },
    mode: 'onTouched',
    resolver: yupResolver(UpsertRecipientFormValidationSchema),
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} onChange={resetError}>
        <FormContent />
        <div className={classes.errorMessageWrapper}>
          {errorMessage.length > 0 && <FormHelperText error>{errorMessage}</FormHelperText>}
        </div>
        <Buttons editMode={editMode} onSubmit={onSubmit} onCancel={onCancel} />
      </form>
    </FormProvider>
  );
};
