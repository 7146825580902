export const intellistationDataPointEventCodes: Record<string, string[]> = {
  mixingTemp: ['mixingTempHigh', 'mixingTempLow', 'mixingOpen', 'mixingShort', 'outletTemp', 'outletLost'],
  hotTemp: ['hotTempHigh', 'hotTempLow', 'hotTemp', 'hotLost'],
  coldTemp: ['coldTemp', 'coldLost'],
  recircTemp: ['recircTemp', 'recircLost'],
  outletPressure: ['outletPressure'],
  recircPressure: ['recircPressure'],
  hotPressure: ['hotPressureHigh', 'hotPressureLow', 'inletPressureDiff', 'hotPressure'],
  coldPressure: ['coldPressureHigh', 'coldPressureLow', 'inletPressureDiff', 'coldPressure'],
  outletFlow: ['mixingFlow'],
  recircFlow: ['recircFlow'],
  pump1Status: ['pump1Proof', 'pump12Proof', 'pumpFalse'],
  pump2Status: ['pump2Proof', 'pump12Proof', 'pumpFalse'],
};
