import { HeaderDatapoint } from '@marlin/asset/shared/ui/datapoint-display';
import { MarlinTheme } from '@marlin/shared/theme';
import { TControlState } from '@marlin/shared/utils/datapoint-mappers';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  paper: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
  },
  datapointsWrapper: {
    display: 'flex',
    gap: theme.typography.pxToRem(24),
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.typography.pxToRem(16),
    },
  },
  sectionTitle: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: theme.typography.pxToRem(25.6),
    letterSpacing: theme.typography.pxToRem(0.15),
    padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(0)} ${theme.typography.pxToRem(16)} `,
  },
}));

interface IHeaderSection {
  chartDatapoints?: { label: string; controlState: TControlState; displayedValue: string }[];
  isLoading: boolean;
}

export const HeaderSection = ({ chartDatapoints, isLoading }: IHeaderSection) => {
  const { classes, cx } = useStyles();

  return (
    <>
      <Typography className={classes.sectionTitle}>{content.MONITOR}</Typography>
      <div className={cx(classes.paper, classes.datapointsWrapper)}>
        {chartDatapoints?.map((datapointItem) => {
          if (!datapointItem) return null;

          return (
            <HeaderDatapoint
              key={`header-datapoint-${datapointItem?.label}`}
              label={datapointItem?.label}
              value={datapointItem?.displayedValue}
              controlState={datapointItem?.controlState}
              isLoading={isLoading}
              withSpacings={false}
            />
          );
        })}
      </div>
    </>
  );
};
