import isFunction from 'lodash/isFunction';
import { ElementType, ReactChild } from 'react';
import {
  Control,
  ControllerProps,
  ControllerRenderProps,
  FieldError,
  FieldValues,
  useController,
} from 'react-hook-form';

type TRenderProps = Partial<Omit<ControllerRenderProps, 'ref'>>;

export interface IFormFieldChildrenProps extends TRenderProps {
  error?: FieldError;
}

export type TChildrenCallback = (props: IFormFieldChildrenProps) => ReactChild;

interface IFormControlProps<TFieldValues extends FieldValues> {
  control: Control<TFieldValues>;
  fieldName: ControllerProps<TFieldValues>['name'];
  children: TChildrenCallback | ReactChild;
}

interface IFormTypedProps<TFieldValues extends FieldValues, TName extends ControllerProps<TFieldValues>['name']> {
  control?: never;
  fieldName: TName;
  children: TChildrenCallback | ReactChild;
}

export function FormField<TFieldValues extends FieldValues>(props: IFormControlProps<TFieldValues>): JSX.Element;
export function FormField<
  TFieldValues extends FieldValues = object,
  TName extends ControllerProps<TFieldValues>['name'] = ControllerProps<TFieldValues>['name']
>(props: IFormTypedProps<TFieldValues, TName>): JSX.Element;

export function FormField<
  TFieldValues extends FieldValues,
  TName extends ControllerProps<TFieldValues>['name'] = ControllerProps<TFieldValues>['name']
>({ fieldName, control, children }: IFormControlProps<TFieldValues> | IFormTypedProps<TFieldValues, TName>) {
  const {
    field: { onChange, onBlur, name, value },
    fieldState: { error },
  } = useController<TFieldValues>({ name: fieldName, control: control });

  if (!children) {
    return null;
  }

  if (isFunction(children)) {
    return <>{children({ error, onChange, onBlur, name, value })}</>;
  }

  const Children: ElementType = children as ElementType;

  return <Children error={error} onChange={onChange} onBlur={onBlur} name={name} value={value} />;
}

type TAllFormFieldProps<TFieldValues extends FieldValues> = Parameters<typeof FormField<TFieldValues>>[0];

export type TFormFieldProps<TFieldValues extends FieldValues> = Omit<TAllFormFieldProps<TFieldValues>, 'children'>;
