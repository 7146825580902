import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    marginTop: theme.typography.pxToRem(8),
    marginBottom: theme.typography.pxToRem(8),
    paddingRight: theme.typography.pxToRem(12),
    paddingLeft: theme.typography.pxToRem(12),
    overflowX: 'auto',
    display: 'flex',
    '-ms-overflow-style': 'none' /* hide scrollbar on Internet Explorer 10+ */,
    'scrollbar-width': 'none' /* hide scrollbar on Firefox */,
    '&::-webkit-scrollbar': {
      display: 'none' /* hide scrollbar on Safari and Chrome */,
    },
  },
}));
