import { MarlinTheme } from '@marlin/shared/theme';
import { Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
  },
  button: {
    marginLeft: theme.typography.pxToRem(8),
  },
}));

export interface IInfoModalFooterProps {
  onConfirm: () => void;
  onCancel: () => void;
  confirmLabel: string;
  cancelLabel: string;
  testId: string;
}

export const InfoModalFooter = ({ onConfirm, onCancel, confirmLabel, cancelLabel, testId }: IInfoModalFooterProps) => {
  const { classes } = useStyles();

  return (
    <div data-testid={`info-modal-footer-${testId}`} className={classes.footer}>
      <Button variant="text" onClick={onCancel}>
        {cancelLabel}
      </Button>
      <div>
        <Button className={`${classes.button}`} variant="contained" onClick={onConfirm}>
          {confirmLabel}
        </Button>
      </div>
    </div>
  );
};
