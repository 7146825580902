import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { CommissionDateField, ICommissionDateFieldProps } from '@marlin/shared/ui-form';
import { FormField, INPUT_TYPE, Input } from '@marlin/shared/ui-form-common';
import { modelContent } from '@marlin/shared/utils/datapoint-mappers';
import { TEquipment } from '@marlin/shared/utils/zod';
import { Box, Icon, IconButton } from '@mui/material';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import { content } from '../content';
import { useStyles } from '../upsert-equipment.styles';

interface IIntellistationEquipmentProps extends ICommissionDateFieldProps {
  isEdit: boolean;
}

const registerHelpUrl = 'watts.com';

export const IntellistationForm = ({
  isEdit,
  setDateValidationErrorMessage,
  dateValidationErrorMessage,
}: IIntellistationEquipmentProps) => {
  const { classes } = useStyles();

  const {
    formState: { errors },
    watch,
    setValue,
  } = useFormContext<TEquipment>();

  const registerCode = watch('registrationCode');

  const handleClearRegisterCode = useCallback(() => {
    setValue('registrationCode', '');
  }, []);

  return (
    <>
      {!isEdit && (
        <>
          <div className={classes.tooltip}>
            {content.REGISTER_LABEL}
            <Tooltip
              text={
                <>
                  {content.REGISTER_CODE_TOOLTIP}
                  <a className={classes.link} href={`https://www.${registerHelpUrl}`}>
                    {registerHelpUrl}
                  </a>
                </>
              }
            />
          </div>
          <div className={classes.registerCodeSubSection}>{content.REGISTER_SUBTITLE_LABEL}</div>
          <FormField<TEquipment> fieldName="registrationCode">
            {(props) => (
              <Box width="100%" data-testid="register-code-field-wrapper">
                <Input
                  {...props}
                  required
                  type={INPUT_TYPE.NUMBER}
                  maxLength={8}
                  className={classes.input}
                  label={content.REGISTER_CODE}
                  testId="register-code-field"
                  error={
                    errors.registrationCode && {
                      type: 'custom',
                      message: errors.registrationCode.message,
                    }
                  }
                  externalEndAdornment={{
                    endAdornment: (
                      <>
                        {registerCode && (
                          <IconButton
                            className={classes.crossIcon}
                            onClick={handleClearRegisterCode}
                            data-testid="register-code-clear-button"
                          >
                            <Icon baseClassName="material-symbols-outlined">{content.CANCEL}</Icon>
                          </IconButton>
                        )}
                      </>
                    ),
                  }}
                />
              </Box>
            )}
          </FormField>
        </>
      )}
      <div className={classes.formSection}>{content.ADDITIONAL_INFORMATION}</div>
      {isEdit && (
        <>
          <FormField<TEquipment> fieldName="brand">
            {(props) => (
              <Input disabled className={classes.input} label={content.BRAND} {...props} testId="brand-field" />
            )}
          </FormField>
          <FormField<TEquipment> fieldName="model">
            {(props) => (
              <Input
                disabled
                {...props}
                value={modelContent.MODELS.get(props.value)}
                className={classes.input}
                label={content.MODEL}
                testId="model-field"
              />
            )}
          </FormField>
        </>
      )}
      <FormField<TEquipment> fieldName="serialNumber">
        {(props) => (
          <Input className={classes.input} label={content.SERIAL_NUMBER} {...props} testId="serial-number-field" />
        )}
      </FormField>
      <CommissionDateField
        setDateValidationErrorMessage={setDateValidationErrorMessage}
        dateValidationErrorMessage={dateValidationErrorMessage}
      />
      <FormField<TEquipment> fieldName="description">
        {(props) => (
          <Input
            {...props}
            className={classes.input}
            label={content.DESCRIPTION}
            testId="description-field"
            maxLength={500}
            multiline
          />
        )}
      </FormField>
    </>
  );
};
