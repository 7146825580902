import { InfiniteAutocompleteControl, SearchWrapper } from '@marlin/shared/ui-form-common';
import { Link } from '@marlin/shared/ui-page';
import { groupTimezone, timezoneLink, timezoneList } from '@marlin/shared/utils-common-date';
import { FormHelperText } from '@mui/material';
import { ComponentProps } from 'react';
import { FieldValues } from 'react-hook-form';

import { content } from '../content';
import { useStyles } from './timezone-control.styles';

export function TimezoneControl<TFieldValues extends FieldValues>(
  props: Partial<ComponentProps<typeof InfiniteAutocompleteControl<TFieldValues>>> & { fieldName: string }
) {
  const { classes } = useStyles();
  return (
    <SearchWrapper>
      {({ search, term }) => (
        <>
          <InfiniteAutocompleteControl<TFieldValues>
            fieldName={props.fieldName}
            control={props.control}
            testId="organization-address-timezone"
            label={content.TIME_ZONE_LABEL}
            options={timezoneList}
            term={term}
            search={search}
            isFetchingNextPage={false}
            hasNextPage={false}
            isLoading={false}
            isFetching={false}
            fetchNextPage={() => {}}
            multiple={false}
            groupBy={groupTimezone}
          />
          <FormHelperText className={classes.helperText}>
            {content.TIME_ZONE_HELPER_TEXT}
            <Link to={timezoneLink} external target="_blank">
              {content.TIME_ZONE_HELPER_TEXT_LINK}
            </Link>
          </FormHelperText>
        </>
      )}
    </SearchWrapper>
  );
}
