import { TDurationType } from '../../../schemas/duration.schema';

const convertDurationToMinutes = (duration?: TDurationType | null): number | null => {
  if (!duration?.value || !duration?.unit) {
    return null;
  }

  switch (duration.unit) {
    case 'DAYS': {
      return duration.value * 24 * 60;
    }
    case 'HOURS': {
      return duration.value * 60;
    }
    case 'MINUTES': {
      return duration.value;
    }
    default: {
      return null;
    }
  }
};

interface IIsGatewayDurationLonger {
  gatewayDuration?: TDurationType | null;
  deviceDuration?: TDurationType | null;
}

export const isGatewayDurationLonger = ({ gatewayDuration, deviceDuration }: IIsGatewayDurationLonger): boolean => {
  const gatewayDurationInMinutes = convertDurationToMinutes(gatewayDuration);
  const deviceDurationInMinutes = convertDurationToMinutes(deviceDuration);

  if (!gatewayDurationInMinutes || !deviceDurationInMinutes) {
    return false;
  }

  return gatewayDurationInMinutes > deviceDurationInMinutes;
};
