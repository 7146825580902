import { UnpinDeviceModal } from '@marlin/asset/shared/ui/pin-button';
import { MarlinTheme } from '@marlin/shared/theme';
import ExpandLessRounded from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRounded from '@mui/icons-material/ExpandMoreRounded';
import { Typography } from '@mui/material';
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { PinnedDeviceListMobile as UIPinnedDeviceListMobile } from './components/pinned-device-list-mobile.component';
import { PinnedDeviceList as UIPinnedDeviceList } from './components/pinned-device-list.component';
import { content } from './content';
import { usePinnedDevices } from './use-pinned-devices.hook';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  title: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightLight,
    lineHeight: theme.typography.pxToRem(20),
    marginTop: theme.typography.pxToRem(16),
    marginBottom: theme.typography.pxToRem(-16),
    textTransform: 'uppercase',
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(16),
      marginBottom: theme.typography.pxToRem(0),
      marginTop: theme.typography.pxToRem(0),
    },
  },
  mobileWrapper: {
    padding: theme.typography.pxToRem(16),
  },
  viewButton: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightLight,
    lineHeight: theme.typography.pxToRem(20),
    textTransform: 'uppercase',
    float: 'right',
    cursor: 'pointer',
    marginTop: theme.typography.pxToRem(16),
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      textTransform: 'none',
      fontSize: theme.typography.pxToRem(16),
    },
  },
  iconLess: {
    marginTop: theme.typography.pxToRem(-3),
  },
}));

const visibleElements = 6;

interface IPinnedDeviceListProps {
  isMobile?: boolean;
}

export const PinnedDeviceList = ({ isMobile }: IPinnedDeviceListProps) => {
  const { pinnedDevices = [], deletePinnedDevice } = usePinnedDevices();
  const [openUnpinModal, setOpenUnpinModal] = useState(false);
  const [viewMore, setViewMore] = useState(false);
  const [selectedPinnedDeviceId, setSelectedPinnedDeviceId] = useState(''); // id of selected device which is ready to remove from pinned section
  const { classes } = useStyles();

  const onCloseUnpinModal = () => {
    setOpenUnpinModal(false);
  };

  const onOpenUnpinModal = (id: string) => {
    setOpenUnpinModal(true);
    setSelectedPinnedDeviceId(id);
  };

  const onSubmitUnpinDevice = () => {
    deletePinnedDevice(selectedPinnedDeviceId);
    onCloseUnpinModal();
  };

  const onViewMoreClick = () => {
    setViewMore(!viewMore);
  };

  if (pinnedDevices.length === 0) {
    return null;
  }

  const moreDevicesThanVisible = pinnedDevices.length > visibleElements;

  if (isMobile) {
    return (
      <div className={classes.mobileWrapper} data-testid="pinned-sensor-container">
        <Typography className={classes.title}>
          {pinnedDevices.length > 1 ? content.PINNED_SENSORS : content.PINNED_SENSOR}
        </Typography>
        <UIPinnedDeviceListMobile onUnpin={onOpenUnpinModal} devices={pinnedDevices} />
        <UnpinDeviceModal isOpen={openUnpinModal} onCancel={onCloseUnpinModal} onSubmit={onSubmitUnpinDevice} />
      </div>
    );
  }
  return (
    <span data-testid="pinned-sensor-container">
      <Typography className={classes.title}>
        {pinnedDevices.length > 1 ? content.PINNED_SENSORS : content.PINNED_SENSOR}
      </Typography>
      <UIPinnedDeviceList
        onUnpin={onOpenUnpinModal}
        devices={moreDevicesThanVisible && !viewMore ? pinnedDevices.slice(0, visibleElements) : pinnedDevices}
      />
      <UnpinDeviceModal isOpen={openUnpinModal} onCancel={onCloseUnpinModal} onSubmit={onSubmitUnpinDevice} />
      {moreDevicesThanVisible ? (
        <Typography className={classes.viewButton} onClick={onViewMoreClick}>
          {viewMore ? content.VIEW_LESS : content.VIEW_MORE}{' '}
          {viewMore ? (
            <ExpandLessRounded className={classes.iconLess} />
          ) : (
            <ExpandMoreRounded className={classes.iconLess} />
          )}
        </Typography>
      ) : null}
    </span>
  );
};
