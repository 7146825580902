export const content = {
  TITLE: 'System Map',
  ADD_FLOW_LINK_SUCCESS: 'Flow link has been added.',
  ADD_ATTACHMENT_SUCCESS: 'Attachment has been added.',
  ADD_FLOW_LINK_ERROR: "Something went wrong. Flow link hasn't been added.",
  ADD_EXISTING_FLOW_LINK_ERROR: "Flow link already exists and hasn't been added.",
  ADD_ATTACHED_LOCATION_HIERARCHY:
    'Sensor must be an immediate child of the target location in order to be attached to it.',
  ADD_ATTACHED_EQUIPMENT_HIERARCHY:
    'Sensor must be shared by the same immediate parent of the target equipment in order to be attached to it.',
  SAVE_GRAPH_ERROR: "Something went wrong. System Map hasn't been saved.",
  ADD_LINK_SUCCESS_MESSAGE: 'Flow link has been created.',
  ADD_LINK_ERROR_MESSAGE: "Something went wrong. Flow link hasn't been created.",
  RENAME_TEE_SUCCESS_MESSAGE: 'Tee has been renamed.',
  RENAME_TEE_ERROR_MESSAGE: "Something went wrong. Tee hasn't been renamed.",
  DELETE_FLOW_LINK_SUCCESS: 'Flow link has been deleted.',
  DELETE_FLOW_LINK_ERROR: 'Error during deleting flow link.',
  UPDATE_FLOW_LINK_SUCCESS: 'Flow link has been updated.',
  UPDATE_FLOW_LINK_ERROR: 'Error during updating flow link.',
};
