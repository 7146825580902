import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  filterWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },

  dataGridWrapper: {
    height: theme.typography.pxToRem(600),
    width: '100%',
  },

  dataGrid: {
    borderRadius: 0,
    borderBottom: 0,
    borderLeft: 0,
    borderRight: 0,
  },
  cellStyle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flexWrap: 'nowrap',
  },
}));
