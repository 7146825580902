import { getHttpClient } from '@marlin/shared/utils/react-query';

import { paths } from './api';

interface ICreatePinDevice {
  data: { deviceId: string };
}

export const createPinDevice = ({ data }: ICreatePinDevice): Promise<string> => {
  return getHttpClient().post(paths.PIN_DEVICE_ACTION, null, { params: { deviceId: data.deviceId } });
};
