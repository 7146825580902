import { useObservableState } from 'observable-hooks';

import { TSystemMapUpdates } from '../flow-map-notification.model.schema';
import { getSystemMapUpdateNotificationSubject, initSubject } from '../infrastructure/get-system-map-notification';

export const useSystemMapNotification = (): TSystemMapUpdates | undefined => {
  const [notification] = useObservableState<TSystemMapUpdates | undefined>(
    getSystemMapUpdateNotificationSubject,
    undefined
  );

  return notification;
};

export const useInitSystemMapUpdateNotificationSubject = () => {
  return useObservableState(initSubject);
};
