import { Button } from '@mui/material';

import { content } from '../content';
import { useStyles } from './commission-date-modal.styles';

interface ICommissionDateModalFooterProps {
  onCancel: () => void;
  onConfirm: () => void;
}

export const CommissionDateModalFooter = ({ onCancel, onConfirm }: ICommissionDateModalFooterProps) => {
  const { classes } = useStyles();
  return (
    <div className={classes.buttonWrapper}>
      <Button variant="text" onClick={onCancel}>
        {content.COMMISSION_DATE_MODAL.FOOTER_CANCEL}
      </Button>
      <Button variant="contained" color="primary" onClick={onConfirm}>
        {content.COMMISSION_DATE_MODAL.FOOTER_CONFIRM}
      </Button>
    </div>
  );
};
