import { AERCO_UNIT_STATUS } from '@marlin/shared/utils/zod';

export enum PLANT_TYPE {
  Boiler = 'Boiler',
  WaterHeater = 'Water Heater',
}

export const onStatuses = [
  AERCO_UNIT_STATUS.Manual,
  AERCO_UNIT_STATUS.Remote,
  AERCO_UNIT_STATUS.Auto,
  AERCO_UNIT_STATUS.Standby,
];
