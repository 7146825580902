import { TNodeType } from '@marlin/system-map/shared/data-access-schemas';

export enum ASSET_TYPE {
  LOCATION = 'LOCATION',
  EQUIPMENT = 'EQUIPMENT',
  DEVICE = 'DEVICE',
}

export enum NODE_TYPE {
  INLET = 'inlet',
  OUTLET = 'outlet',
}

export interface IAsset {
  id: string;
  name: string;
  type: ASSET_TYPE;
}

export interface IFormAsset {
  id: string;
  nodeId: string | null;
  assetId?: string | null;
  name: string;
  type?: ASSET_TYPE;
  assetType?: ASSET_TYPE;
  nodeType: TNodeType;
}

export interface IAssetType {
  id: ASSET_TYPE | '';
  name: string;
}

export interface IFlowLink {
  isAttachment?: boolean;
  inlet?: IFormAsset;
  outlet?: IFormAsset;
}

export interface IAssetFilter {
  filter?: ASSET_TYPE;
  search?: string;
}
