import { useObservable, useObservableState, useSubscription } from 'observable-hooks';
import { useCallback, useMemo } from 'react';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export enum CHART_SELECTION_TYPE {
  BRUSH_CHART = 'BRUSH_CHART',
  MAIN_CHART = 'MAIN_CHART',
  EXTERNAL = 'EXTERNAL',
}

export interface ISelection {
  from: number;
  to: number;
}

export interface IChartSelectionEvent {
  selection: ISelection;
  type: CHART_SELECTION_TYPE;
}

interface IChartSelection {
  selection: ISelection;
  changeChartSelection: (selectionEvent: IChartSelectionEvent) => void;
  clearSelection: () => void;
}

interface IUseSelectionProps {
  onSelectionChange?: (selectionEvent: IChartSelectionEvent) => void;
  initialSelection: ISelection;
}

const debounceTimeInMs = 100;

export const useChartSelection = ({ onSelectionChange, initialSelection }: IUseSelectionProps): IChartSelection => {
  const $selectionSubject = useMemo(
    () =>
      new BehaviorSubject<IChartSelectionEvent>({
        selection: initialSelection,
        type: CHART_SELECTION_TYPE.EXTERNAL,
      }),
    // NOTE: We don't want to recreate the subject on every render
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const $currentSelectionEvent = useObservable<IChartSelectionEvent>(() =>
    $selectionSubject.pipe(debounceTime(debounceTimeInMs))
  );

  const { selection } = useObservableState<IChartSelectionEvent>($currentSelectionEvent, {
    selection: initialSelection,
    type: CHART_SELECTION_TYPE.EXTERNAL,
  });

  useSubscription($currentSelectionEvent, onSelectionChange);

  const changeChartSelection = useCallback(
    (event: IChartSelectionEvent) => {
      $selectionSubject.next(event);
    },
    [$selectionSubject]
  );

  const clearSelection = useCallback(() => {
    $selectionSubject.next({
      selection: initialSelection,
      type: CHART_SELECTION_TYPE.EXTERNAL,
    });
  }, [$selectionSubject, initialSelection]);

  return useMemo(
    (): IChartSelection => ({ selection, changeChartSelection, clearSelection }),
    [selection, changeChartSelection, clearSelection]
  );
};
