import { TAssetListFilters } from '../asset.model.schema';
import { TNodeListFilters } from '../node.model.schema';

enum QUERY_KEY {
  ASSETS = 'assets',
  NODES = 'nodes',
  FLOW_LINK = 'flow-link',
  FLOW_LINKS = 'flow-links',
  GRAPH = 'graph',
}

export const queryKey = {
  ASSETS: (params: TAssetListFilters) => [QUERY_KEY.ASSETS, params],
  NODES: (params: TNodeListFilters) => [QUERY_KEY.NODES, params],
  FLOW_LINK: () => [QUERY_KEY.FLOW_LINK],
  FLOW_LINKS: (queryParams: unknown = {}) => [QUERY_KEY.FLOW_LINKS, queryParams],
  GRAPH: () => [QUERY_KEY.GRAPH],
};
