import isNil from 'lodash/isNil';
import { z } from 'zod';

import { content } from '../content/validation-content';
import { TDeadbandValueSchema, TThresholdValueSchema } from './conditions.schema';

export const isDeadbandMinIssue = (deadbandMin: TDeadbandValueSchema, thresholdMin: TThresholdValueSchema) => {
  if (isNil(deadbandMin?.value) || isNil(thresholdMin?.value)) {
    return true;
  }
  if (deadbandMin.enabled && thresholdMin.enabled) {
    return deadbandMin.value > thresholdMin.value;
  }
  return false;
};

export const deadbandValueMinIssue = {
  code: z.ZodIssueCode.custom,
  message: content.DEADBAND_VALUE_MIN,
  path: ['deadbandMin.value'],
};

export const isDeadbandMaxIssue = (deadbandMax: TDeadbandValueSchema, thresholdMax: TThresholdValueSchema) => {
  if (isNil(deadbandMax.value) || isNil(thresholdMax.value)) {
    return true;
  }

  if (deadbandMax.enabled && thresholdMax.enabled) {
    return deadbandMax.value < thresholdMax.value;
  }
  return false;
};

export const deadbandValueMaxIssue = {
  code: z.ZodIssueCode.custom,
  message: content.DEADBAND_VALUE_MAX,
  path: ['deadbandMax.value'],
};

export const isDeadbandIssue = (deadbandMax: TDeadbandValueSchema, deadbandMin: TDeadbandValueSchema) => {
  if (isNil(deadbandMax?.value) || isNil(deadbandMin?.value)) {
    return true;
  }

  if (deadbandMax.enabled && deadbandMin.enabled) {
    return deadbandMax.value > deadbandMin.value;
  }
  return false;
};

export const deadbandsValueIssue = {
  code: z.ZodIssueCode.custom,
  message: content.DEADBAND_VALUE_MAX_GREATER_MIN,
  path: ['deadbandMax.value'],
};

export const isDeadbandMinHigherThanThresholdMaxIssue = (
  deadbandMin?: TDeadbandValueSchema,
  thresholdMin?: TThresholdValueSchema,
  thresholdMax?: TThresholdValueSchema
) => {
  if (isNil(deadbandMin?.value) || isNil(thresholdMin?.value) || isNil(thresholdMax?.value)) {
    return true;
  }

  if (deadbandMin?.enabled && thresholdMax?.enabled) {
    return deadbandMin.value < thresholdMax.value;
  }
  return false;
};

export const deadbandMinHigherThanThresholdMaxIssue = {
  code: z.ZodIssueCode.custom,
  message: content.DEADBAND_VALUE_MIN_LOWER_THAN_THRESHOLDMAX,
  path: ['deadbandMin.value'],
};

export const isDeadbandMaxLowerThanThresholdMinIssue = (
  deadbandMax?: TDeadbandValueSchema,
  thresholdMin?: TThresholdValueSchema
) => {
  if (isNil(deadbandMax?.value) || isNil(thresholdMin?.value)) {
    return true;
  }

  if (deadbandMax?.enabled && thresholdMin?.enabled) {
    return deadbandMax.value > thresholdMin.value;
  }
  return false;
};

export const deadbandMinLowerThanThresholdMinIssue = {
  code: z.ZodIssueCode.custom,
  message: content.DEADBAND_VALUE_MAX_LOWER_THAN_THRESHOLDMIN,
  path: ['deadbandMax.value'],
};
