import { getUnitType } from '@marlin/shared/utils-format-reading';
import { useMemo } from 'react';

import { useContent } from '../../../../context';
import { ConditionValidationError } from '../condition/condition-error.component';
import { Condition } from '../condition/condition.component';
import { Separator } from '../separator.component';

export function TemperatureConditions() {
  const content = useContent();
  const unit = useMemo(() => getUnitType('TEMPERATURE'), []);

  return (
    <>
      <Separator text={content.CONDITIONS.LOWER_BOUND} />
      <Condition
        conditionInputName={{
          threshold: 'conditions.thresholdMin',
          deadband: 'conditions.deadbandMin',
        }}
        conditionLabel={content.CONDITIONS.LABEL.MIN}
        conditionCheckboxLabel={content.CONDITIONS.TEMPERATURE.MIN}
        unit={unit}
      />
      <Separator text={content.CONDITIONS.HIGHER_BOUND} />
      <Condition
        conditionInputName={{
          threshold: 'conditions.thresholdMax',
          deadband: 'conditions.deadbandMax',
        }}
        conditionLabel={content.CONDITIONS.LABEL.MAX}
        conditionCheckboxLabel={content.CONDITIONS.TEMPERATURE.MAX}
        unit={unit}
      />
      <ConditionValidationError />
    </>
  );
}
