import { CommonChip } from '@marlin/shared/ui/chips';

interface IChipButtonProps {
  label: string;
  active?: boolean;
  onClick: () => unknown;
  testId?: string;
}

export const ChipButton = ({ label, active, onClick }: IChipButtonProps) => {
  return (
    <CommonChip
      testId={`chip-${label}`}
      variant="outlined"
      label={label}
      active={active}
      clickable={false}
      onClick={onClick}
    />
  );
};
