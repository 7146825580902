const allowedToTrackStagingOrgs = [
  'Field Trial Allele Condos',
  'Field Trial Cone Health',
  'Field Trial Grandover',
  'Field Trial Heritage Greens',
  'Field Trial Messiah University',
  'Field Trial Niagara University',
  'Field Trial North Andover',
  'Field Trial South Dakota Development Center',
  'Field Trial The Hickman',
  'Hilton Pearl River',
].map((org) => org.trim());

export const isStagingOrgAllowedToTrack = (orgName: string): boolean => {
  return allowedToTrackStagingOrgs.includes(orgName.trim());
};
