import isNil from 'lodash/isNil';
import { z } from 'zod';

const handleNullish = (value: string | null | undefined) => {
  if (isNil(value)) {
    return '';
  }

  return value;
};

const orgFeatureSchema = z.object({
  id: z.string(),
  name: z.string(),
  startDate: z.string(),
  endDate: z.string(),
  isBeta: z.boolean(),
  isActive: z.boolean(),
});

const orgAddressSchemaUpdate = z.object({
  country: z.string(),
  address1: z.string(),
  address2: z.string().nullish(),
  city: z.string(),
  state: z.string(),
  postalCode: z.string(),
  latitude: z.number().nullish(),
  longitude: z.number().nullish(),
});

const orgAddressSchema = z.object({
  country: z.string().nullish().transform(handleNullish),
  address1: z.string().nullish().transform(handleNullish),
  address2: z.string().nullish().transform(handleNullish),
  city: z.string().nullish().transform(handleNullish),
  state: z.string().nullish().transform(handleNullish),
  postalCode: z.string().nullish().transform(handleNullish),
  latitude: z.number().nullish(),
  longitude: z.number().nullish(),
});

export const orgDetailsSchema = z.object({
  id: z.string(),
  name: z.string(),
  address: orgAddressSchema.nullish(),
  timeZone: z.string().nullish(),
  features: z.array(orgFeatureSchema),
});

export type TOrgAddress = z.infer<typeof orgAddressSchema>;
export type TOrgDetails = z.infer<typeof orgDetailsSchema>;

export const updateOrgAddressSchema = z.object({
  name: z.string().nullish(),
  address: orgAddressSchemaUpdate.nullish(),
  timeZone: z.string().nullish(),
});

export type TUpdateOrgAddress = z.infer<typeof updateOrgAddressSchema>;
