import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  toggleCompareItem: {
    display: 'flex',
    alignItems: 'center',
  },
  toggleButton: {
    paddingLeft: theme.typography.pxToRem(12),
  },
  buttonText: {
    textTransform: 'none',
    fontSize: theme.typography.pxToRem(14),
  },
  buttonIcon: {
    marginLeft: theme.typography.pxToRem(2),
  },
  menu: {
    minWidth: theme.typography.pxToRem(348),
  },
}));
