import { EQUIPMENT_TYPE } from '@marlin/shared/utils/zod';
import {
  AssetTypeSchemaParser,
  DeviceTypeSchemaParser,
  NodeTypeSchemaParser,
  UpdateAssetTypeSchema,
} from '@marlin/system-map/shared/data-access-schemas';
import { z } from 'zod';

import { metadataSchema } from './metadata-model.schema';

export const nodeListFiltersSchema = z
  .object({
    filter: UpdateAssetTypeSchema.nullish(),
    search: z.string().trim().nullish(),
    equipmentType: z.nativeEnum(EQUIPMENT_TYPE).nullish(),
  })
  .transform(({ search, filter, equipmentType }) => ({
    equipmentType,
    name: search,
    assetType: filter,
  }))
  .or(z.object({}));

const tagSchema = z.object({
  name: z.string().nullish(),
  value: z.string().nullish(),
});

export const nodeSchema = z.object({
  id: z.string().nullish(),
  nodeType: NodeTypeSchemaParser,
  name: z.string().nullish(),
  assetType: AssetTypeSchemaParser.nullish(),
  assetId: z.string().nullish(),
  assetName: z.string().nullish(),
  deviceType: DeviceTypeSchemaParser.nullish(),
  metadata: metadataSchema.nullish(),
  tags: z.array(tagSchema).nullish(),
  linkTags: z.array(tagSchema).nullish(),
  equipmentModel: z.nativeEnum(EQUIPMENT_TYPE).nullish(),
  equipmentHandle: z.string().nullish(),
});

export const nodeListSchema = z.array(nodeSchema);

export type TNode = z.infer<typeof nodeSchema>;
export type TNodeListFilters = z.infer<typeof nodeListFiltersSchema>;
