import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { SyntheticEvent, useCallback } from 'react';

import { content } from '../../content';
import { TAB_VALUES } from '../../use-home.hook';

export interface IHomeTabsProps {
  tabValue: TAB_VALUES;
  updateTab: (newValue: number) => void;
  allLocationsLength: number;
  mechanicalRoomsLength: number;
  locationsWithOpenAlertsLength: number;
}

const HomeTabs = ({
  tabValue,
  updateTab,
  mechanicalRoomsLength,
  locationsWithOpenAlertsLength,
  allLocationsLength,
}: IHomeTabsProps) => {
  const handleTableChange = useCallback(
    (event: SyntheticEvent, newValue: number) => {
      updateTab(newValue);
    },
    [updateTab]
  );
  return (
    <Tabs value={tabValue} onChange={handleTableChange}>
      <Tab label={content.MECHANICAL_ROOMS_TAB(mechanicalRoomsLength)} />
      <Tab label={content.OPEN_ALERTS_TAB(locationsWithOpenAlertsLength)} />
      <Tab label={content.ALL_LOCATIONS_TAB(allLocationsLength)} />
    </Tabs>
  );
};

export { HomeTabs };
