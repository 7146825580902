import { EmailControl, FirstNameControl, LastNameControl, PhoneNumberControl } from '@marlin/shared/ui-form';
import { Grid } from '@mui/material';

import { ICreateRecipient } from './types';

export function FormContent() {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <FirstNameControl<ICreateRecipient> fieldName="firstName" />
      </Grid>

      <Grid item xs={12}>
        <LastNameControl<ICreateRecipient> fieldName="lastName" />
      </Grid>
      <Grid item xs={12}>
        <EmailControl<ICreateRecipient> fieldName="email" />
      </Grid>
      <Grid item xs={12}>
        <PhoneNumberControl<ICreateRecipient> fieldName="phoneNumber" />
      </Grid>
    </Grid>
  );
}
