import {
  AssetTypeSchemaParser,
  DeviceTypeSchemaParser,
  NodeTypeSchemaParser,
} from '@marlin/system-map/shared/data-access-schemas';
import { z } from 'zod';

export const cycleNodeResponseSchema = z.object({
  id: z.string(),
  name: z.string(),
  nodeType: NodeTypeSchemaParser,
  assetId: z.string().nullable(),
  assetType: AssetTypeSchemaParser.nullable(),
  deviceType: DeviceTypeSchemaParser.nullable(),
  parentId: z.string().nullable(),
  parentAssetId: z.string().nullable(),
  parentLocationName: z.string().nullable(),
});

export const flowLoopNotificationSchema = z.object({
  userId: z.string(),
  cyclicPath: z.array(cycleNodeResponseSchema),
});

export type TCycleNode = z.infer<typeof cycleNodeResponseSchema>;
export type TFlowLoopNotification = z.infer<typeof flowLoopNotificationSchema>;
