import { IPagination, IRecipient, useRecipientsWithFilters } from '@marlin/account-data-access-recipient';
import { IRecipientRow } from '@marlin/account-ui-recipient-list';
import { mapSortDirection } from '@marlin/shared/utils-sorting';
import { usePagination } from '@marlin/shared/utils/react-query';
import { useSearchFilter } from '@marlin/shared/utils/url-params';
import { GridSortModel } from '@mui/x-data-grid';
import { useCallback, useMemo, useState } from 'react';

interface IRecipientList {
  data: IRecipientRow[];
  pagination: IPagination;
  isLoading: boolean;
  isError: boolean;
  term: string;
  search: (value: string) => void;
  changePage: (page: number) => void;
  changePageSize: (pageSize: number) => void;
  onSortChange: (sortModel: GridSortModel) => void;
}

interface ISorting {
  direction: 'Ascending' | 'Descending';
  sortBy: string;
}

const mapToRecipientRow = (recipient: IRecipient): IRecipientRow => ({
  id: recipient.id,
  FirstName: `${recipient.firstName} ${recipient.lastName}`,
  Email: recipient.email,
  phone: recipient.phoneNumber,
});

export const useRecipientList = (): IRecipientList => {
  const { term, debouncedTerm, search } = useSearchFilter();
  const [sortingParams, setSortingParams] = useState<ISorting | undefined>(undefined);
  const { page, pageSize, changePage, changePageSize } = usePagination({
    initialPageSize: 5,
  });
  const { data, isLoading, isError } = useRecipientsWithFilters({
    search: debouncedTerm,
    page: page,
    pageSize: pageSize,
    sorting: sortingParams,
  });
  const searchWrapper = useCallback(
    (value: string) => {
      changePage(1);
      return search(value);
    },
    [changePage, search]
  );

  const recipientData = useMemo(() => (data?.data || []).map(mapToRecipientRow), [data]);

  const onSortChange = useCallback((sortModel: GridSortModel) => {
    if (!sortModel[0] || !sortModel[0].sort) {
      setSortingParams(undefined);
    } else {
      const sorting = {
        sortBy: sortModel[0]?.field,
        direction: mapSortDirection(sortModel[0]?.sort),
      };
      setSortingParams(sorting);
    }
  }, []);

  const userRecipient: IRecipientList = useMemo(
    () => ({
      data: recipientData,
      pagination: data?.pagination || { page: page, pageSize: pageSize, totalItems: 0 },
      isLoading,
      isError,
      term,
      search: searchWrapper,
      changePage,
      changePageSize,
      onSortChange,
    }),
    [
      recipientData,
      data?.pagination,
      page,
      pageSize,
      isLoading,
      isError,
      term,
      searchWrapper,
      changePage,
      changePageSize,
      onSortChange,
    ]
  );

  return userRecipient;
};
