import { useGatewayBrandsAndModels } from '@marlin/asset/data-access/gateway';
import { modelContent } from '@marlin/shared/utils/datapoint-mappers';

export const useBrandAndModelOptionsHook = () => {
  const { data: brandsAndModels } = useGatewayBrandsAndModels();

  return {
    brandOptions:
      brandsAndModels?.map((brand) => ({
        id: brand.id,
        name: brand.name,
      })) ?? [],
    modelOptions:
      brandsAndModels?.flatMap((brand) =>
        brand.models.map((model) => ({
          brandId: model.brandId,
          id: model.id,
          name: modelContent.MODELS.get(model.name) ?? model.name,
        }))
      ) || [],
  };
};
