import { getHttpClient } from '@marlin/shared/utils/react-query';

import { paths } from './api';
import { TRecipientResponse, TRecipients, recipientsSchema } from './recipient.model.schema';

interface IGetRecipientsParams {
  addDeleted?: boolean;
}

export const getRecipients = async (params?: IGetRecipientsParams): Promise<TRecipients> => {
  const res = await getHttpClient().get<unknown, TRecipientResponse[]>(paths.RECIPIENTS, { params });
  return recipientsSchema.parse(res);
};
