import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    rotate: '-90deg',
    height: theme.typography.pxToRem(24),
    backgroundColor: theme.palette.background.primary,
    '&:hover': {
      backgroundColor: theme.palette.background.primary,
    },
    borderEndStartRadius: 0,
    borderEndEndRadius: 0,
    minWidth: theme.typography.pxToRem(88),
  },
  containerOpened: {
    rotate: '90deg',
    height: theme.typography.pxToRem(24),
    backgroundColor: theme.palette.background.primary,
    '&:hover': {
      backgroundColor: theme.palette.background.primary,
    },
    borderEndStartRadius: 0,
    borderEndEndRadius: 0,
    marginLeft: theme.typography.pxToRem(46),
    zIndex: 1000,
    minWidth: theme.typography.pxToRem(88),
  },
  icon: {
    position: 'fixed',
    top: theme.typography.pxToRem(4),
    rotate: '-135deg',
  },
}));
