import { MarlinTheme } from '@marlin/shared/theme';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  statusBar: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.typography.pxToRem(8),
    maxWidth: '100%',
  },

  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flexWrap: 'nowrap',
  },

  redDot: {
    width: theme.typography.pxToRem(12),
    height: theme.typography.pxToRem(12),
    minWidth: theme.typography.pxToRem(12),
    borderRadius: '50%',
    backgroundColor: theme.palette.error.main,
  },

  greenDot: {
    width: theme.typography.pxToRem(12),
    height: theme.typography.pxToRem(12),
    minWidth: theme.typography.pxToRem(12),
    borderRadius: '50%',
    backgroundColor: theme.palette.success.light,
  },
}));

export const StatusBar = ({ isOperational, tooltip = false }: { isOperational: boolean; tooltip?: boolean }) => {
  const { classes } = useStyles();
  const statusLabel = isOperational ? content.STATUS_OPERATIONAL : content.STATUS_LOST_COMMUNICATION;

  if (tooltip) {
    return (
      <Tooltip text={statusLabel} placement="top">
        <div className={classes.statusBar}>
          <div className={isOperational ? classes.greenDot : classes.redDot}></div>
          <div className={classes.text}>{statusLabel}</div>
        </div>
      </Tooltip>
    );
  }

  return (
    <div className={classes.statusBar}>
      <div className={isOperational ? classes.greenDot : classes.redDot}></div>
      {statusLabel}
    </div>
  );
};
