import {
  ALERT_STATUS_FILTER,
  ALERT_STATUS_FILTER_REQUEST,
  DEVICE_TYPE,
  ICriticality,
  IPagedParams,
} from '../../alert.model';
import {
  CRITICALITY_RESPONSE,
  DEVICE_TYPE_RESPONSE,
  IAlertParamsRequest,
  TAlertStatus,
  TCriticalityRequest,
  TDeviceType,
} from '../alert.model';

const isDeviceType = (item: TDeviceType | undefined): item is TDeviceType => !!item;

const getDeviceTypes = (deviceTypes: string[]): TDeviceType[] => {
  return deviceTypes.map((deviceType) => getDeviceType(deviceType)).filter(isDeviceType);
};

const getDeviceType = (deviceType: string): TDeviceType | undefined => {
  switch (deviceType) {
    case DEVICE_TYPE.TEMPERATURE: {
      return DEVICE_TYPE_RESPONSE.TEMPERATURE;
    }
    case DEVICE_TYPE.PRESSURE: {
      return DEVICE_TYPE_RESPONSE.PRESSURE;
    }
    case DEVICE_TYPE.LEAK: {
      return DEVICE_TYPE_RESPONSE.LEAK;
    }
    case DEVICE_TYPE.PULSE_METER: {
      return DEVICE_TYPE_RESPONSE.FLOW_METER;
    }
    case DEVICE_TYPE.EQUIPMENT: {
      return DEVICE_TYPE_RESPONSE.EQUIPMENT;
    }
    case DEVICE_TYPE.GATEWAY: {
      return DEVICE_TYPE_RESPONSE.GATEWAY;
    }
    default: {
      // eslint-disable-next-line no-console
      console.warn(`Device type status (${deviceType}) is missing in request`);
      return undefined;
    }
  }
};

export const getCriticality = (criticality?: ICriticality): TCriticalityRequest | null => {
  if (!criticality) {
    return null;
  }

  if (criticality.low && criticality.high) {
    return null;
  } else if (criticality.low) {
    return CRITICALITY_RESPONSE.LOW;
  } else if (criticality.high) {
    return CRITICALITY_RESPONSE.HIGH;
  }

  return null;
};

export const parseAlertStatusRequest = (status: ALERT_STATUS_FILTER | undefined): TAlertStatus | null => {
  switch (status) {
    case ALERT_STATUS_FILTER.CURRENT: {
      return ALERT_STATUS_FILTER_REQUEST.CURRENT;
    }
    case ALERT_STATUS_FILTER.SNOOZED: {
      return ALERT_STATUS_FILTER_REQUEST.SNOOZED;
    }
    case ALERT_STATUS_FILTER.RESOLVED: {
      return ALERT_STATUS_FILTER_REQUEST.RESOLVED;
    }
    case ALERT_STATUS_FILTER.ALL: {
      return null;
    }
    default: {
      // eslint-disable-next-line no-console
      console.warn(`Status (${status}) is missing in request`);
      return null;
    }
  }
};

export const createAlertParams = (params: IPagedParams): IAlertParamsRequest => {
  return {
    locations: params?.locationIds ?? [],
    devices: params?.deviceIds ?? [],
    equipments: params?.equipmentIds ?? [],
    deviceTypes: getDeviceTypes(params?.deviceTypes ?? []),
    search: params?.search ?? '',
    criticality: getCriticality(params?.criticality),
    status: parseAlertStatusRequest(params?.status),
    ruleCategories: params?.ruleCategories ?? [],
    pageSize: params.pageSize,
    page: params.page,
    eventCodes: params?.eventCodes ?? [],
    sorting: params?.sorting,
  };
};
