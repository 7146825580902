import { TWaterUsageDevice, useHomeDashboard } from '@marlin/asset/data-access/home-dashboard';
import { FeatureCard, TYPE } from '@marlin/asset/ui-home-hub-cards';
import { ChartModal, IRequestedTelemetry } from '@marlin/asset/ui/charts';
import { PinnedDeviceList } from '@marlin/asset/ui/pinned-device-list';
import { ToggleButtons } from '@marlin/asset/ui/toggle-buttons-group';
import { TotalizationCard } from '@marlin/asset/ui/totalization';
import { MarlinTheme } from '@marlin/shared/theme';
import { PageContainer, PageHeader, SystemConstraintsPage } from '@marlin/shared/ui-page';
import { PERMISSIONS, PermissionGuard } from '@marlin/shared/utils-permission';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useCallback, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { HomeHeader, HomeList, HomeTabs, MobileHomeList, MobileHomeTabs } from './components';
import { content } from './content';
import { useHome } from './use-home.hook';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  featureCardContainer: {
    flexGrow: 1,
    width: '100%',
  },
  featureCardsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: theme.typography.pxToRem(12),
    marginTop: theme.typography.pxToRem(24),
    flexGrow: 1,
  },
  totalizationWrapper: {
    padding: theme.typography.pxToRem(16),
  },
  locationWrapper: {
    padding: ` ${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(16)}`,
  },
}));

export const Home = () => {
  const {
    tabValue,
    viewLocations,
    isLoading,
    isError,
    updateTab,
    allLocationsLength,
    mechanicalRoomsLength,
    locationsWithOpenAlertsLength,
  } = useHome();

  const { classes } = useStyles();
  const theme = useTheme();
  const { data, isError: isDashboardDataError } = useHomeDashboard();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const devicesCount = data ? data.health.sensors.deviceCount + data.health.equipment.deviceCount : 0;

  const [requestedAnalyticsTelemetry, setRequestedAnalyticsTelemetry] = useState<IRequestedTelemetry[]>([]);

  const showFlowMeterDetails = useCallback((totalizationDevices: TWaterUsageDevice[]) => {
    setRequestedAnalyticsTelemetry(
      totalizationDevices.map(({ manufacturerId, deviceName: name, locationName, locationId }) => ({
        manufacturerId,
        name,
        locationName,
        locationId,
        datapoints: ['FlowRate'],
      }))
    );
  }, []);

  const closeFlowMeterDetails = useCallback(() => {
    setRequestedAnalyticsTelemetry([]);
  }, []);

  if (isDashboardDataError) {
    return <SystemConstraintsPage />;
  }

  if (isMobile) {
    return (
      <>
        <PageContainer>
          <PageHeader
            title={content.HOME_TITLE}
            prefix="home"
            actions={<ToggleButtons isMobile />}
            bottomActions={
              <MobileHomeTabs
                tabValue={tabValue}
                updateTab={updateTab}
                allLocationsLength={allLocationsLength}
                mechanicalRoomsLength={mechanicalRoomsLength}
                locationsWithOpenAlertsLength={locationsWithOpenAlertsLength}
              />
            }
          />

          <PermissionGuard permissions={[PERMISSIONS.SENSOR_DEVICES]}>
            <PinnedDeviceList isMobile />
          </PermissionGuard>
          <div className={classes.locationWrapper}>
            <MobileHomeList viewLocations={viewLocations} isLoading={isLoading} isError={isError} tabValue={tabValue} />
          </div>
          <div className={classes.totalizationWrapper}>
            <TotalizationCard showFlowMeterDetails={showFlowMeterDetails} />
          </div>
        </PageContainer>
        <ChartModal
          requestedTelemetry={requestedAnalyticsTelemetry}
          closeChartModal={closeFlowMeterDetails}
          chartType={'flow'}
          title={content.CHART_TITLE_MODAL}
        />
      </>
    );
  }

  return (
    <>
      <PageContainer>
        <Box className={classes.headerWrapper}>
          <div>
            <HomeHeader />
          </div>
          <div>
            <ToggleButtons />
          </div>
        </Box>
        <HomeTabs
          tabValue={tabValue}
          updateTab={updateTab}
          allLocationsLength={allLocationsLength}
          mechanicalRoomsLength={mechanicalRoomsLength}
          locationsWithOpenAlertsLength={locationsWithOpenAlertsLength}
        />
        <PermissionGuard permissions={[PERMISSIONS.SENSOR_DEVICES]}>
          <PinnedDeviceList />
        </PermissionGuard>
        <HomeList viewLocations={viewLocations} tabValue={tabValue} isLoading={isLoading} isError={isError} />
        <div>
          <TotalizationCard showFlowMeterDetails={showFlowMeterDetails} />
        </div>
        <div className={classes.featureCardsWrapper}>
          <PermissionGuard permissions={[PERMISSIONS.SYSTEM_MAP]}>
            <div className={classes.featureCardContainer}>
              <FeatureCard data-testid="system-map-feature-card" type={TYPE.SYSTEM_MAP} devicesCount={devicesCount} />
            </div>
          </PermissionGuard>
          <PermissionGuard permissions={[PERMISSIONS.DATA_EXPORT]}>
            <div className={classes.featureCardContainer}>
              <FeatureCard data-testid="analytics-feature-card" type={TYPE.ANALYTICS} devicesCount={devicesCount} />
            </div>
          </PermissionGuard>
        </div>
      </PageContainer>
      <ChartModal
        requestedTelemetry={requestedAnalyticsTelemetry}
        closeChartModal={closeFlowMeterDetails}
        chartType={'flow'}
        title={content.CHART_TITLE_MODAL}
      />
    </>
  );
};
