import { TGraphAsset } from '../schema/cytoscape.schema';

export const cytoToReactFlowPosition = (node: TGraphAsset, parentNode?: TGraphAsset) => {
  if (!node.position || node.position?.x === null || node.position?.y === null) {
    return {
      x: 0,
      y: 0,
    };
  }
  if (parentNode) {
    return {
      x: (node.position?.x ?? 0) - (parentNode.position?.x ?? 0),
      y: (node.position?.y ?? 0) - (parentNode.position?.y ?? 0),
    };
  }
  return {
    x: node.position?.x ?? 0,
    y: node.position?.y ?? 0,
  };
};
