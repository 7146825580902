import { TEquipmentAlert } from '@marlin/asset/data-access/equipment';
import { MarlinTheme } from '@marlin/shared/theme';
import { TUnitOfMeasure } from '@marlin/shared/utils-format-reading';
import { TControlState } from '@marlin/shared/utils/datapoint-mappers';
import { Skeleton, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { DatapointDisplayValue } from './datapoint-display-value.component';

type TStyles = {
  emptyValue: boolean;
  withSpacings?: boolean;
};

const useStyles = makeStyles<TStyles>()((theme: MarlinTheme, { emptyValue, withSpacings }) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.typography.pxToRem(12),
    height: theme.typography.pxToRem(74),
    minWidth: theme.typography.pxToRem(144),
    flexDirection: 'column',
    borderRadius: theme.typography.pxToRem(4),
    backgroundColor: theme.palette.background.alternative,
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  value: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    color: emptyValue ? theme.palette.text.disabled : theme.palette.primary.main,
  },
  skeleton: {
    transform: 'none',
  },
}));

interface IHeaderDatapoint {
  label?: string;
  datapointAlerts?: TEquipmentAlert[];
  value?: string | number;
  name?: string;
  controlState?: TControlState;
  isLoading?: boolean;
  withSpacings?: boolean;
  unitOfMeasure?: TUnitOfMeasure | null;
}

const activeUnitOfMeasure: TUnitOfMeasure[] = ['ValvePosition', 'WaterDetect'];

const getControlState = (
  unitOfMeasure: TUnitOfMeasure | undefined | null,
  controlState: TControlState | undefined,
  datapointAlerts: TEquipmentAlert[]
): TControlState => {
  if (unitOfMeasure && activeUnitOfMeasure.includes(unitOfMeasure)) {
    return 'active';
  }

  if (datapointAlerts?.length) {
    return 'error';
  }

  if (!controlState) {
    return 'inactive';
  }

  return controlState;
};

export const HeaderDatapoint = ({
  label,
  value,
  controlState,
  datapointAlerts = [],
  isLoading = false,
  withSpacings = true,
  unitOfMeasure,
}: IHeaderDatapoint) => {
  const { classes, cx } = useStyles({ emptyValue: !value, withSpacings });
  const calculatedControlState = getControlState(unitOfMeasure, controlState, datapointAlerts);

  if (isLoading) {
    return <Skeleton className={cx(classes.container, classes.skeleton)} />;
  }

  return (
    <div className={classes.container} data-testid={`reading-datapoint-${label}`}>
      <div className={classes.titleWrapper}>
        <Typography variant="overline" className={classes.title}>
          {label}
        </Typography>
      </div>
      <div data-testid={label}>
        <DatapointDisplayValue value={value} controlState={calculatedControlState} alerts={datapointAlerts} separator />
      </div>
    </div>
  );
};
