import { TCalibrationParamsSchema } from '@marlin/asset/data-access/device';
import isNil from 'lodash/isNil';

import { TUomType } from './calibration-forms/uom-toggle-options';

export const notEnoughWater: Record<TUomType, number> = {
  gal: 1000,
  l: 3800,
  ft3: 130,
  m3: 3.8,
};

export const notEnoughWaterLabel: Record<TUomType, string> = {
  gal: 'gallons',
  l: 'liters',
  ft3: 'cubic feet',
  m3: 'cubic meters',
};

export const isNotEnoughWater = (calibration: TCalibrationParamsSchema) => {
  if (isNil(calibration.endMeasurementValue) || isNil(calibration.startMeasurementValue)) {
    return false;
  }
  return (
    Math.abs(calibration.endMeasurementValue - calibration.startMeasurementValue) <
    notEnoughWater[calibration.measurementUnit as TUomType]
  );
};
