import { AUTOMATION_RULE_TYPE, TCreateAutomation } from '@marlin/alert/data-access/automated-action';
import { MarlinTheme } from '@marlin/shared/theme';
import { FormField, Input } from '@marlin/shared/ui-form-common';
import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { useContent } from '../context';
import { Criticality } from './criticality.component';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  bigLabel: {
    marginBottom: theme.typography.pxToRem(16),
  },
}));

export interface IAutomationNameProps {
  isSystemRule?: boolean;
  ruleType?: AUTOMATION_RULE_TYPE;
}

export function AutomationName({ isSystemRule, ruleType }: IAutomationNameProps) {
  const { classes } = useStyles();
  const content = useContent();

  const name = (name: string) => {
    if (ruleType === AUTOMATION_RULE_TYPE.DEVICE_ERROR) {
      return content.AUTOMATION_NAME_RULE_TYPE_ERROR(name);
    }
    if (ruleType === AUTOMATION_RULE_TYPE.DEVICE_ALERT) {
      return content.AUTOMATION_NAME_RULE_TYPE_ALERT(name);
    }
    return name;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <div className={classes.bigLabel} data-testid="automation-name-header">
          {content.AUTOMATION_NAME.HEADER}
        </div>
        <FormField<TCreateAutomation> fieldName="name">
          {(props) => (
            <Input
              label={content.AUTOMATION_NAME.LABEL}
              required
              {...props}
              value={name(props.value)}
              disabled={isSystemRule}
              testId="automation-name"
            />
          )}
        </FormField>
      </Grid>
      <Criticality disabled={isSystemRule} />
    </Grid>
  );
}
