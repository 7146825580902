import { generatePath } from 'react-router-dom';

import {
  EQUIPMENT_TAB_VALUES,
  IAlertsQueryParam,
  ISensorHubParams,
  ONBOARDING_TYPE,
  TAB_VALUES,
  TDeviceDrawerTabs,
} from './params.model';
import { areParamsValid, createQueryParams } from './utils';

const homeBasePath = '/';
const homeDashboardPath = '/dashboard';
const homeLocationsPath = '/home';
const welcomePath = '/welcome';
const welcomeWithOnboardingType = '/welcome?type=:type';

const automationsBasePath = '/automations';
const automationsFilteredPath = `${automationsBasePath}/sensor?/:sensorId?`; // TODO: check
const automationsQueryPath = `${automationsBasePath}/sensor/:sensorId`;
const automationsCreatePath = `${automationsBasePath}/new`;
const automationsBuilderPath = `${automationsBasePath}/builder`;
const automationsCreateQueryPath = `${automationsBasePath}/new/sensor/:sensorId`;
const automationsUpdatePath = `${automationsBasePath}/edit/:automationId`;
const automationsHealthPath = `${automationsBasePath}/health`;
const automationsInformationPath = `${automationsBasePath}/information`;

const alertsBasePath = '/alerts';

const locationsBasePath = '/locations';
const locationsCreatePath = `new`;
const locationsWithDevicePath = `?sensorId=:sensorId`;
const locationDetailsPath = `:locationId`;
const locationDetailsQueryPath = `${locationDetailsPath}?tab=:tab`;
const locationDetailsQueryStringPath = `?tab=:tab`;
const locationUpdatePath = `${locationsBasePath}/edit/:locationId`;
const plantPath = `${locationsBasePath}/:locationId/plant`;

const sensorsBasePath = '/sensors';
const sensorsListPath = sensorsBasePath;
const sensorDetailsPath = `?sensorId=:sensorId`;
const sensorUpdatePath = `${sensorsBasePath}/edit/:sensorId`;
const sensorCreatePath = `new`;
const sensorGlobalCreatePath = `${sensorsBasePath}/new`;

const gatewayBasePath = '/gateways';
const gatewayQueryPath = `${gatewayBasePath}?gatewayId=:gatewayId`;
const configureGatewayPath = `${gatewayBasePath}/configuration/:deviceId`;
const detailsGatewayPath = `${gatewayBasePath}/details/:deviceId`;
const gatewayDetailsQueryPath = `${detailsGatewayPath}?tab=:tab`;
const registrationGatewayPath = 'registration';
const editGatewayPath = `${gatewayBasePath}/edit/:gatewayId`;

const equipmentsBasePath = '/equipment';
const equipmentDashboardBasePath = '/equipment-dashboard';
const equipmentsCreatePath = `new`;
const equipmentsWithDevicePath = `?sensorId=:sensorId`;
const equipmentDetailsPath = `:equipmentId`;
const equipmentDetailsQueryPath = `${equipmentDetailsPath}?tab=:tab`;
const equipmentDetailsQueryStringPath = `?tab=:tab`;
const equipmentUpdatePath = `${equipmentsBasePath}/edit/:equipmentId`;

const analyticsBasePath = '/analytics';
const sustainabilityPath = `/sustainability`;

const helpBasePath = '/help';

const settingsBasePath = '/settings';
const settingsPeoplePath = 'people';
const settingsHotsosPath = 'hotsos';
const settingsOrganizationPath = 'organization';
const settingsAppearancePath = 'appearance';

const settingsInviteNewUser = 'invite-user';

const settingsUpdateProfile = 'profile';

const settingsEditUserBasePath = 'user/:userId/update';
const settingsAddRecipient = 'recipient/add';

const settingsEditRecipientBasePath = 'recipient/:recipientId/update';

const invitationBasePath = '/invitation';
const invitationValidatePath = `${invitationBasePath}/:invitationId/validate`;
const invitationSupportValidatePath = `${invitationBasePath}/:invitationId/support/validate`;

const systemMapBasePath = '/system-map';

const alertLinkBasePath = '/alertLink';
const alertLinkPath = '/alertLink/:orgId/:sensorId';

const newOrganizationPath = '/create-organization';

const createWebhookPath = 'webhook/new';
const updateWebhookPath = 'webhook/:webhookId/update';

const integrationPath = 'integration';

export const routes = {
  home: {
    root: homeBasePath,
    dashboard: homeDashboardPath,
    locations: homeLocationsPath,
  },
  welcome: {
    root: welcomePath,
    withOnboardingType: (type: ONBOARDING_TYPE) => welcomeWithOnboardingType.replace(':type', type),
  },
  deviceDrawer: {
    open: (rootUrl: string, sensorId: string, tab?: TDeviceDrawerTabs) => {
      const [pathName, currentSearch] = rootUrl.split('?');

      const searchParams = new URLSearchParams(currentSearch);
      searchParams.set('sensorId', sensorId);
      searchParams.set('tab', tab ?? 'readings');

      return `${pathName}?${searchParams.toString()}`;
    },
  },
  automations: {
    root: automationsBasePath,
    list: {
      path: automationsBasePath,
      url: () => automationsBasePath,
      query: (sensorId: string) => automationsQueryPath.replace(':sensorId', sensorId),
      filteredList: {
        path: automationsFilteredPath,
      },
    },
    create: {
      path: automationsCreatePath,
      url: () => automationsCreatePath,
      query: (sensorId: string) => automationsCreateQueryPath.replace(':sensorId', sensorId),
      withDevice: {
        path: automationsCreateQueryPath,
      },
    },
    builder: {
      path: automationsBuilderPath,
      url: () => automationsBuilderPath,
    },
    update: {
      path: automationsUpdatePath,
      url: (automationId: string) => automationsUpdatePath.replace(':automationId', automationId),
    },
    health: {
      root: automationsHealthPath,
    },
    information: {
      root: automationsInformationPath,
    },
  },
  alerts: {
    root: alertsBasePath,
    list: {
      path: alertsBasePath,
      url: (params?: IAlertsQueryParam) => {
        if (areParamsValid(params)) {
          const queryParams = createQueryParams(params);
          return `${alertsBasePath}?${queryParams}`;
        }

        return alertsBasePath;
      },
    },
    alertLink: {
      root: alertLinkBasePath,
      path: alertLinkPath,
      details: {
        url: (orgId: string, sensorId: string) => alertLinkPath.replace(':orgId', orgId).replace(':sensorId', sensorId),
      },
    },
  },
  sensors: {
    root: sensorsBasePath,
    list: {
      path: sensorsListPath,
      url: (params?: ISensorHubParams) => {
        if (areParamsValid(params)) {
          const queryParams = createQueryParams(params);
          return `${sensorsListPath}?${queryParams}`;
        }

        return sensorsListPath;
      },
    },
    create: {
      path: sensorCreatePath,
      globalPath: sensorGlobalCreatePath,
      url: () => sensorCreatePath,
    },
    details: {
      url: (sensorId: string) => sensorDetailsPath.replace(':sensorId', sensorId),
    },
    update: {
      path: sensorUpdatePath,
      url: (sensorId: string) => sensorUpdatePath.replace(':sensorId', sensorId),
    },
  },
  gateway: {
    root: gatewayBasePath,
    list: {
      path: gatewayBasePath,
      url: () => gatewayBasePath,
      withId: (gatewayId: string) => gatewayQueryPath.replace(':gatewayId', gatewayId),
    },
    details: {
      path: detailsGatewayPath,
      query: (tabId?: EQUIPMENT_TAB_VALUES) =>
        gatewayDetailsQueryPath.replace(':tab', tabId ?? EQUIPMENT_TAB_VALUES.DASHBOARD),
      url: (id: string, tabId?: EQUIPMENT_TAB_VALUES) => {
        const queryParams = tabId ? `?tab=${tabId}` : '';
        const path = generatePath(`${detailsGatewayPath}`, {
          deviceId: id,
        });

        return `${path}${queryParams}`;
      },
    },
    configuration: {
      path: configureGatewayPath,
      url: (deviceId: string) => configureGatewayPath.replace(':deviceId', deviceId),
    },
    registration: {
      path: registrationGatewayPath,
      url: () => `${registrationGatewayPath}`,
    },
    edit: {
      path: editGatewayPath,
      url: (gatewayId: string) => editGatewayPath.replace(':gatewayId', gatewayId),
    },
  },
  locations: {
    root: locationsBasePath,
    list: {
      path: locationsBasePath,
      url: () => locationsBasePath,
      device: {
        url: (sensorId: string) => locationsWithDevicePath.replace(':sensorId', sensorId),
      },
    },
    details: {
      path: locationDetailsPath,
      query: (tabId?: TAB_VALUES) => locationDetailsQueryStringPath.replace(':tab', tabId ?? TAB_VALUES.LinkedItems),
      url: (locationId: string, tabId?: TAB_VALUES) =>
        `${locationsBasePath}/${locationDetailsQueryPath
          .replace(':locationId', locationId)
          .replace(':tab', tabId ?? TAB_VALUES.LinkedItems)}`,
    },
    create: {
      path: locationsCreatePath,
      url: () => `${locationsBasePath}/${locationsCreatePath}`,
    },
    update: {
      path: locationUpdatePath,
      url: (locationId: string) => locationUpdatePath.replace(':locationId', locationId),
    },
    plant: {
      path: plantPath,
      url: (locationId: string) => generatePath(plantPath, { locationId }),
    },
  },
  equipmentDashboard: {
    root: equipmentDashboardBasePath,
    details: {
      path: equipmentDetailsPath,
      query: (tabId?: EQUIPMENT_TAB_VALUES) =>
        equipmentDetailsQueryStringPath.replace(':tab', tabId ?? EQUIPMENT_TAB_VALUES.DASHBOARD),
      url: (id: string, tabId?: EQUIPMENT_TAB_VALUES) => {
        const queryParams = tabId ? `?tab=${tabId}` : '';
        const path = generatePath(`${equipmentDashboardBasePath}/:equipmentId`, {
          equipmentId: id,
        });

        return `${path}${queryParams}`;
      },
    },
  },
  equipments: {
    root: equipmentsBasePath,
    list: {
      path: equipmentsBasePath,
      url: () => equipmentsBasePath,
      device: {
        url: (sensorId: string) => equipmentsWithDevicePath.replace(':sensorId', sensorId),
      },
    },
    details: {
      path: equipmentDetailsPath,
      query: (tabId?: TAB_VALUES) => equipmentDetailsQueryStringPath.replace(':tab', tabId ?? TAB_VALUES.LinkedItems),
      url: (equipmentId: string, tabId?: TAB_VALUES) =>
        `${equipmentsBasePath}/${equipmentDetailsQueryPath
          .replace(':equipmentId', equipmentId)
          .replace(':tab', tabId ?? TAB_VALUES.LinkedItems)}`,
    },
    create: {
      path: equipmentsCreatePath,
      url: () => equipmentsCreatePath,
    },
    update: {
      path: equipmentUpdatePath,
      url: (equipmentId: string) => equipmentUpdatePath.replace(':equipmentId', equipmentId),
    },
  },
  analytics: {
    root: analyticsBasePath,
    sustainability: {
      root: sustainabilityPath,
    },
  },
  analyticsv2: {
    root: `${analyticsBasePath}-v2`,
  },
  help: {
    root: helpBasePath,
  },
  settings: {
    root: settingsBasePath,
    people: {
      path: settingsPeoplePath,
      url: () => `${settingsBasePath}/${settingsPeoplePath}`,
    },
    inviteNewUser: {
      path: settingsInviteNewUser,
      url: () => `${settingsBasePath}/${settingsInviteNewUser}`,
    },
    addRecipient: {
      path: settingsAddRecipient,
      url: () => `${settingsBasePath}/${settingsAddRecipient}`,
    },
    hotsos: {
      path: settingsHotsosPath,
      url: () => `${settingsBasePath}/${settingsHotsosPath}`,
    },
    orgSettings: {
      path: settingsOrganizationPath,
      url: () => `${settingsBasePath}/${settingsOrganizationPath}`,
    },
    appearance: {
      path: settingsAppearancePath,
      url: () => `${settingsBasePath}/${settingsAppearancePath}`,
    },
    editUser: {
      path: settingsEditUserBasePath,
      details: {
        url: (userId: string) => `${settingsBasePath}/${settingsEditUserBasePath}`.replace(':userId', userId),
      },
    },
    editRecipient: {
      path: settingsEditRecipientBasePath,
      details: {
        url: (recipientId: string) =>
          `${settingsBasePath}/${settingsEditRecipientBasePath}`.replace(':recipientId', recipientId),
      },
    },
    profile: {
      path: settingsUpdateProfile,
      url: () => `${settingsBasePath}/${settingsUpdateProfile}`,
    },
    createWebhook: {
      path: createWebhookPath,
      url: () => `${settingsBasePath}/${createWebhookPath}`,
    },
    editWebhook: {
      path: updateWebhookPath,
      url: (webhookId: string) => `${settingsBasePath}/${updateWebhookPath}`.replace(':webhookId', webhookId),
    },
    integration: {
      path: integrationPath,
      url: () => `${settingsBasePath}/${integrationPath}`,
    },
  },
  invitation: {
    root: invitationBasePath,
    validate: {
      path: invitationValidatePath,
    },
    supportValidate: {
      path: invitationSupportValidatePath,
    },
  },
  systemMap: {
    root: systemMapBasePath,
  },
  newOrganization: {
    root: newOrganizationPath,
  },
};
