import { AUTOMATION_RULE_TYPE, ICondition, TCreateAutomation } from '@marlin/alert/data-access/automated-action';
import { MarlinTheme } from '@marlin/shared/theme';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { IRadioButton, RadioButtons } from '@marlin/shared/ui-form-common';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { useContent } from '../../../../context';

const batteryLevelConditions: ICondition = {
  thresholdMax: {
    value: 0,
    enabled: false,
  },
};

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  radio: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },

  control: {
    display: 'flex',
    alignItems: 'baseline',
    gap: theme.typography.pxToRem(16),
    marginTop: theme.typography.pxToRem(16),
    paddingLeft: theme.typography.pxToRem(32),
  },

  buttonGroup: {
    '& > label:last-child': {
      marginRight: theme.typography.pxToRem(8),
    },
  },
}));

export function BatteryLevelConditions() {
  const content = useContent();
  const { classes } = useStyles();
  const { setValue, trigger, watch } = useFormContext<TCreateAutomation>();

  const batteryLevelTreshold = watch('conditions.thresholdMin.value') as number;

  const buttons: IRadioButton[] = useMemo(
    () => [
      {
        label: content.CONDITIONS.BATTERY(batteryLevelTreshold),
        value: AUTOMATION_RULE_TYPE.BATTERY_LEVEL,
      },
    ],
    [content.CONDITIONS, batteryLevelTreshold]
  );

  useEffect(() => {
    if (batteryLevelConditions.thresholdMax && batteryLevelConditions.thresholdMin) {
      setValue('conditions.thresholdMax.value', batteryLevelConditions.thresholdMax.value);
      setValue('conditions.thresholdMax.enabled', batteryLevelConditions.thresholdMax.enabled);
      setValue('conditions.thresholdMin.enabled', true);
    }

    // trigger validation to mark condition fields as touched
    trigger(['conditions.thresholdMin.value', 'conditions.thresholdMax.value']);
  }, [trigger, setValue]);

  return (
    <div className={classes.radio}>
      <RadioButtons value={AUTOMATION_RULE_TYPE.BATTERY_LEVEL} buttons={buttons} className={classes.buttonGroup} />
      <Tooltip text={content.CONDITIONS.BATTERY_TOOLTIP} />
    </div>
  );
}
