import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  accordionContainer: {
    boxShadow: 'none',
    '&.Mui-disabled': {
      backgroundColor: 'transparent',
    },
  },
  accordionWrapper: {
    padding: theme.typography.pxToRem(0),
  },
  accordionSummary: {
    paddingLeft: theme.typography.pxToRem(24),
    paddingRight: theme.typography.pxToRem(24),
  },
  accordionSummaryExtended: {
    padding: 0,
    '&.Mui-disabled': {
      opacity: 1,
    },
  },
  content: {
    margin: 0,
    marginBottom: theme.typography.pxToRem(16),
  },
  accordionDetailsExtended: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: theme.typography.pxToRem(16),
    padding: 0,
    [theme.breakpoints.down('xl')]: {
      flexDirection: 'column',
    },
  },
  accordionDetails: {
    paddingLeft: theme.typography.pxToRem(0),
    paddingRight: theme.typography.pxToRem(0),
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  expandedDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  detailContainer: {
    display: 'flex',
    gap: theme.typography.pxToRem(8),
    borderTop: `1px solid ${theme.palette.divider}`,
    minHeight: theme.typography.pxToRem(58),
    padding: theme.typography.pxToRem(16),
    alignItems: 'center',

    '& div:last-child': {
      marginLeft: 'auto',
    },

    '& div:last-child *': {
      marginLeft: 'auto',
    },

    '&:last-child': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  detail: {
    minWidth: theme.typography.pxToRem(216),
  },
  detailWithTooltipContainer: {
    display: 'flex',
    alignItems: 'center',
    minWidth: theme.typography.pxToRem(216),
  },
  detailWithTooltip: {
    marginRight: theme.typography.pxToRem(8),
  },
  propertyValueText: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.secondary,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  propertyKeyText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: theme.typography.pxToRem(254),
    [theme.breakpoints.down('xl')]: {
      width: theme.typography.pxToRem(400),
    },
    [theme.breakpoints.down('md')]: {
      width: theme.typography.pxToRem(100),
    },
  },
  propertyKeyTextExtended: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up(1700)]: {
      maxWidth: theme.typography.pxToRem(600),
    },
    [theme.breakpoints.down('xl')]: {
      width: theme.typography.pxToRem(600),
    },
  },
  propertySection: {
    marginBottom: theme.typography.pxToRem(12),
  },
  linkItemWrapper: {
    wordBreak: 'break-word',
  },
}));
