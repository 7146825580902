import { useFilteredEquipmentsWithPaging } from '@marlin/asset/data-access/equipment';

export const useLocationEquipments = (locationId: string) => {
  const { isFetching, data: { data } = {} } = useFilteredEquipmentsWithPaging({
    params: {
      page: 1,
      pageSize: 100,
      sorting: { sortBy: 'name', direction: 'Ascending' },
      datapoints: [
        'ActiveSetpt',
        'ActiveSetpointTemp',
        'OutletTemp',
        'UnitStatusIdx',
        'PlantMode',
        'UnitSize',
        'Inlet2Temp',
        'InletTemp',
        'FireRateOut',
        'CommAddr',
        'AutoModeSetpt',
        'RemoteModeSetpt',
        'ManualModeSetpt',
        'StandbyModeSetpt',
        'DisabledModeSetpt',
        'AutoManagerAddr',
        'DeviceGroupManagerId',
        'BackupManagerAddr',
      ],
      locations: [locationId],
    },
  });

  return {
    isFetching,
    data: data ?? [],
  };
};
