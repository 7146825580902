import { TDashboardDHealthAlertsSchema } from '@marlin/asset/data-access/home-dashboard';
import { MarlinTheme } from '@marlin/shared/theme';
import { Dot } from '@marlin/shared/ui-criticality';
import { useRouter } from '@marlin/shared/utils-router';
import { ISensorHubParams, routes } from '@marlin/shared/utils-routes';
import { makeStyles } from 'tss-react/mui';

import { CRITICALITY, HEALTH_TYPES } from '../../../shared/types';
import { transformCounter } from '../../../shared/utils';
import { content } from './content';

interface ICriticalityControl {
  data: TDashboardDHealthAlertsSchema;
  type: string;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    cursor: 'pointer',
  },
}));

export const DotControl = ({ data, type }: ICriticalityControl) => {
  const { classes } = useStyles();
  const { deviceErrorsCount, lostCommunicationCount } = data;
  const { goTo } = useRouter();

  const navigateToAlertsList = (alertType?: 'Sensor' | 'Equipment' | 'Gateway', criticality?: 'HIGH' | 'LOW') => {
    goTo(routes.alerts.list.url({ criticality, alertType, category: 'Health' }));
  };

  const navigateToSensors = (params?: ISensorHubParams) => {
    goTo(routes.sensors.list.url(params));
  };

  switch (type) {
    case HEALTH_TYPES.EQUIPMENT: {
      if (deviceErrorsCount > 0) {
        return (
          <Dot variant={CRITICALITY.HIGH}>
            <div
              data-testid={`health-tab-equipment-${transformCounter(deviceErrorsCount)}-${content.ERRORS}`}
              className={classes.container}
              onClick={() => navigateToAlertsList('Equipment', 'HIGH')}
            >
              {transformCounter(deviceErrorsCount)} {content.ERRORS}
            </div>
          </Dot>
        );
      } else if (lostCommunicationCount > 0) {
        return (
          <Dot variant={CRITICALITY.LOW}>
            <div
              data-testid={`health-tab-equipment-${transformCounter(lostCommunicationCount)}-${content.INACTIVE}`}
              className={classes.container}
              onClick={() => navigateToAlertsList('Equipment', 'HIGH')}
            >
              {transformCounter(lostCommunicationCount)} {content.INACTIVE}
            </div>
          </Dot>
        );
      }
      return (
        <Dot variant={CRITICALITY.NO}>
          <div
            data-testid={`health-tab-equipment-${content.ALL_ACTIVE}`}
            className={classes.container}
            onClick={() => navigateToAlertsList('Equipment')}
          >
            {content.ALL_ACTIVE}
          </div>
        </Dot>
      );
    }
    case HEALTH_TYPES.SENSORS: {
      if (lostCommunicationCount > 1) {
        return (
          <Dot variant={CRITICALITY.HIGH}>
            <div
              data-testid={`health-tab-sensors-${transformCounter(lostCommunicationCount)}-${content.INACTIVE}`}
              className={classes.container}
              onClick={() => navigateToSensors({ lostCommunication: true })}
            >
              {transformCounter(lostCommunicationCount)} {content.INACTIVE}
            </div>
          </Dot>
        );
      } else if (lostCommunicationCount > 0) {
        return (
          <Dot variant={CRITICALITY.LOW}>
            <div
              data-testid={`health-tab-sensors-${transformCounter(lostCommunicationCount)}-${content.INACTIVE}`}
              className={classes.container}
              onClick={() => navigateToAlertsList('Sensor', 'LOW')}
            >
              {transformCounter(lostCommunicationCount)} {content.INACTIVE}
            </div>
          </Dot>
        );
      }
      return (
        <Dot variant={CRITICALITY.NO}>
          <div
            data-testid={`health-tab-sensors-${content.ALL_ACTIVE}`}
            className={classes.container}
            onClick={() => navigateToAlertsList('Sensor')}
          >
            {content.ALL_ACTIVE}
          </div>
        </Dot>
      );
    }
    case HEALTH_TYPES.GATEWAYS: {
      if (lostCommunicationCount > 0) {
        return (
          <Dot variant={CRITICALITY.HIGH}>
            <div
              data-testid={`health-tab-gateways-${transformCounter(lostCommunicationCount)}-${content.INACTIVE}`}
              className={classes.container}
              onClick={() => navigateToAlertsList('Gateway', 'HIGH')}
            >
              {transformCounter(lostCommunicationCount)} {content.INACTIVE}
            </div>
          </Dot>
        );
      }
      return (
        <Dot variant={CRITICALITY.NO}>
          <div
            data-testid={`health-tab-gateways-${content.ALL_ACTIVE}`}
            className={classes.container}
            onClick={() => navigateToAlertsList('Gateway')}
          >
            {content.ALL_ACTIVE}
          </div>
        </Dot>
      );
    }
  }

  return <></>;
};
