// todo (TRANSLATIONS) merge this one with one from UI project
import { IAutomationCreateContent } from '@marlin/alert/ui/automated-action-create';
import { environment } from '@marlin/environment';

export const content = {
  CONFIRMATION_MESSAGE_UPDATE_AUTOMATION_ERROR: `Something went wrong. Your automation hasn't been updated`,
  CONFIRMATION_MESSAGE_UPDATE_AUTOMATION_SUCCESS: 'Your automation has been updated',
  CONFIRMATION_MESSAGE_CREATE_RECIPIENT_ERROR: `Something went wrong. Your recipient hasn't been created`,
  CONFIRMATION_MESSAGE_CREATE_RECIPIENT_SUCCESS: 'Your recipient has been created',
  ERROR: 'Something went wrong. Please try again later.',
};

export const uiContent: IAutomationCreateContent = {
  TITLE: 'Edit Automation',
  SUBTITLE: 'Use the form below to edit automation.',
  BUTTONS: {
    CANCEL: 'Cancel',
    CONFIRM: 'Save',
  },
  AUTOMATION_NAME: {
    HEADER: 'Automation Name',
    LABEL: 'Automation Name',
  },
  CRITICALITY: {
    LABEL: 'Automation Criticality *',
    VALUE: {
      LOW: 'Low',
      HIGH: 'High',
    },
    TOOLTIP: {
      INTRO:
        'Criticality allows for customization of the way alerts appear on your homescreen, and provide prioritization to an alert.',
      HIGH: 'Alerts appear as red on the homescreen, and a banner will also be triggered.',
      LOW: 'Alerts appear as orange on the homescreen.',
    },
  },
  TRIGGER: {
    TITLE: 'Trigger(s)',
    TOOLTIP: `Automations in ${environment.app.name} use IF/THEN logic. A trigger, considered the "IF" in the logic, will initiate an action if a sensor's set conditions are reached.`,
  },
  ACTION: {
    LABEL: {
      HOTSOS: 'Create HotSOS Work Order',
      IN_APP: 'Show on Alert Center and Homescreen',
      SMS: 'Text',
      EMAIL: 'Email',
    },
    RECIPIENT: {
      BUTTON: 'Create New Recipient',
      LABEL: 'Select Recipients',
      PLACEHOLDER: 'Select Recipients',
    },
    TITLE: 'Action',
    TOOLTIP: `Automations in ${environment.app.name} use IF/THEN logic. An action, considered the "THEN" in the logic, will take place when a trigger's conditions are reached.`,
  },
  SYSTEM_RULES: {
    BATTERY_LEVEL: 'All Sensors',
    GATEWAY_LOST_COMMUNICATION: 'All Gateways',
    SENSOR_LOST_COMMUNICATION: 'All Sensors',
    EQUIPMENT_LOST_COMMUNICATION: 'Equipment Lost Communication',
    SENSOR_ERROR: 'All Equipment Errors',
    SENSOR_ALERTS: 'All Equipment Alerts',
    EQUIPMENT_INFORMATION: 'Equipment Information',
  },
  CONDITIONS: {
    DEADBAND: 'Deadband Value',
    LOWER_BOUND: 'Lower bound',
    HIGHER_BOUND: 'Higher bound',
    LABEL: { MIN: 'Lower Value', MAX: 'Higher Value' },
    LEAK: 'Leak Detected',
    TEMPERATURE: {
      MIN: 'Temperature Lower Than',
      MAX: 'Temperature Higher Than',
      UNIT: '°F',
    },
    PRESSURE: {
      MIN: 'Pressure Lower Than',
      MAX: 'Pressure Higher Than',
      UNIT: 'PSI',
    },
    FLOW_METER: {
      MIN: 'Flow Lower Than',
      MAX: 'Flow Higher Than',
      UNIT: 'GPM',
    },
    DURATION: {
      GATEWAY_INACTIVITY_DURATION: 'Gateway Inactivity Duration',
      SENSOR_INACTIVITY_DURATION: 'Inactivity Duration',
      DURATION_TOOLTIP:
        'Alert will trigger after the last received gateway message becomes older than the below duration. This applies to any gateway. It is recommended to set this shorter than the sensor inactivity duration in case a gateway loses communication to prevent excessive alerts.',
    },
    BATTERY: (batteryLevel: number) => `Battery Level lower than ${batteryLevel}%`,
    BATTERY_TOOLTIP:
      "Alert will trigger after a sensor's battery level is lower than the defined value. This applies to any sensor.",
    TITLE: 'Condition(s)',
    TOOLTIP:
      'Conditions determine the "value" a sensor must reach in order to initiate the action. If both a high and low value are selected, the action will be triggered if either one of the conditions are met.',
    INFORMATION_TOOLTIP:
      'A change was made to one of the following settings: Mixed Outlet Setpoint, Mixed Outlet High Temp Alert, or Override Mode',
  },
  SENSOR: {
    LABEL: 'Sensor',
    PLACEHOLDER: 'Select Sensor',
    CLEAR_ALL: 'Clear All',
    TITLE: 'Sensor',
    LOCATION_LABEL: 'Location',
    EQUIPMENT_LABEL: 'Attached Equipment',
    EQUIPMENT_TITLE: 'Equipment',
  },
  REPEATING_ALERT_LIMIT: {
    TITLE: 'Snooze Interval',
    TOOLTIP:
      "Set the time that should pass before receiving an additional alert for this automation across all channels. Once an automation is triggered, don't alert me for the same issue until at least [20 minutes] have passed. \n\n Default: 0 mins (alert sent each time the automation is triggered)",
    SELECT_LABEL: 'Frequency',
  },
  CONDITION: 'Condition',
  AUTOMATION_NAME_RULE_TYPE_ERROR: (name: string) => `${name}`,
  AUTOMATION_NAME_RULE_TYPE_ALERT: (name: string) => `${name}`,
};
