import {
  DeleteLocationModalBody,
  DeleteLocationModalFooter,
  DeleteLocationModalTitle,
} from '@marlin/asset/ui/delete-location-modal';
import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import { useCallback, useContext } from 'react';

import { IDeleteLocationProps } from './location-list.component';

export const useDeleteLocationModal = () => {
  const { modalDispatch } = useContext(ModalContext);

  const deleteLocation = useCallback(
    (location: IDeleteLocationProps) => {
      const canDelete = !location.isParent && location.deviceCount === 0 && location.equipmentCount === 0;
      const reason = !canDelete ? (location.isParent ? 'parent' : 'assets') : undefined;
      modalDispatch({
        type: MODAL_ACTION_TYPE.SHOW,
        payload: {
          title: <DeleteLocationModalTitle canDelete={canDelete} />,
          body: (
            <DeleteLocationModalBody
              assetId={location.locationId}
              deleteAssetName={location.name}
              canDelete={canDelete}
              reason={reason}
            />
          ),
          footer: <DeleteLocationModalFooter assetId={location.locationId} canDelete={canDelete} />,
        },
      });
    },
    [modalDispatch]
  );

  return {
    deleteLocation,
  };
};
