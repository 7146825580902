import { useLocationHierarchy } from '@marlin/asset/data-access/location';
import { Breadcrumbs, IBreadcrumb } from '@marlin/shared/breadcrumbs';
import { MarlinTheme } from '@marlin/shared/theme';
import SubdirectoryArrowRightRoundedIcon from '@mui/icons-material/SubdirectoryArrowRightRounded';
import { useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useRef } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useHierarchyBreadcrumbs } from './use-hierarchy-breadcrumbs.hook';

interface IHierarchyBreadcrumbsProps {
  locationId: string;
  selectedItemId: string;
  customBreadcrumbs?: Omit<IBreadcrumb, 'onClick'>;
  locationStyle?: boolean;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    padding: `${theme.typography.pxToRem(5)} 0`,
    [theme.breakpoints.down('md')]: {
      padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(16)} 0`,
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
      },
    },
  },
}));

export function HierarchyBreadcrumbs({ locationId, selectedItemId, customBreadcrumbs }: IHierarchyBreadcrumbsProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const hierarchyBreadcrumbsRef = useRef<HTMLDivElement>(null);
  const { classes } = useStyles();

  const locationHierarchyQuery = useLocationHierarchy({ locationId });
  const hierarchy = useHierarchyBreadcrumbs(locationHierarchyQuery.data, selectedItemId, customBreadcrumbs);

  useEffect(() => {
    const selectedItemElementPosition = document
      .getElementById(`breadcrumb-${selectedItemId}`)
      ?.getBoundingClientRect();

    if (selectedItemElementPosition && isMobile) {
      hierarchyBreadcrumbsRef.current?.scrollTo({ left: selectedItemElementPosition.x, behavior: 'smooth' });
    }
  }, [hierarchy.length, isMobile, selectedItemId]);

  return (
    <div className={classes.wrapper} data-testid="hierarchy-breadcrumbs" ref={hierarchyBreadcrumbsRef}>
      <Breadcrumbs items={hierarchy} separator={<SubdirectoryArrowRightRoundedIcon />} />
    </div>
  );
}

export default HierarchyBreadcrumbs;
