import { createDateString } from '@marlin/shared/utils-common-date';
import {
  DeviceTypeResponseSchema,
  DeviceTypeSchema,
  DeviceTypeSchemaParser,
  UpdateDeviceTypeSchema,
} from '@marlin/system-map/shared/data-access-schemas';
import { z } from 'zod';

import { emptyTextSchema, paginationSchema, sortingSchema } from './utils.schema';

export const devicesParamsSchema = z.object({
  search: z.string().optional(),
  pageSize: z.number(),
  page: z.number(),
  deviceTypes: z.array(DeviceTypeSchema).optional(),
  locations: z.array(z.string()).optional(),
  sorting: sortingSchema.optional(),
});

export const devicesRequestParamsSchema = devicesParamsSchema.transform((params) => {
  return {
    ...params,
    deviceTypes: params.deviceTypes?.map((type) => UpdateDeviceTypeSchema.parse(type)),
  };
});

export const devicesSchema = z
  .object({
    id: z.string(),
    name: z.string(),
    deviceType: DeviceTypeResponseSchema.nullish(),
    locationId: emptyTextSchema,
    locationName: emptyTextSchema,
    equipmentId: emptyTextSchema,
    equipmentName: emptyTextSchema,
    lastReadingValue: z.number().nullish(),
    lastReadingTime: z.string().nullish(),
    manufacturerId: z.string(),
  })
  .transform((device) => {
    const deviceType = DeviceTypeSchemaParser.parse(device.deviceType);
    const lastReadingTime = createDateString(device.lastReadingTime ?? undefined);

    return {
      ...device,
      lastReadingTime,
      deviceType,
    };
  });

export const devicesResponseSchema = z.object({
  pagination: paginationSchema,
  data: z.array(devicesSchema).nullable(),
});

export type TDevicesParamsSchema = z.infer<typeof devicesParamsSchema>;
export type TEquipmentDevicesSchema = z.infer<typeof devicesSchema>;
export type TDevicesResponseSchema = z.infer<typeof devicesResponseSchema>;
