import { MarlinTheme } from '@marlin/shared/theme';
import { Button, Icon, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  buttonWrapper: {
    width: theme.typography.pxToRem(32),
    marginRight: theme.typography.pxToRem(34),
    cursor: 'pointer',
  },
  button: {
    width: theme.typography.pxToRem(76),
    marginLeft: theme.typography.pxToRem(16),
    padding: theme.typography.pxToRem(5),
  },
  icon: {
    marginRight: theme.typography.pxToRem(10),
    color: theme.palette.action.active,
  },
  menuItem: {
    marginRight: theme.typography.pxToRem(5),
    marginLeft: theme.typography.pxToRem(5),
  },
}));

interface IMenuItem {
  label: string;
  iconName?: string;
  handleClick: () => void;
}
interface IMenuItems {
  menuItems: IMenuItem[];
}

export const MenuItems = ({ menuItems }: IMenuItems) => {
  const { classes } = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!menuItems.length) {
    return null;
  }

  return (
    <div className={classes.buttonWrapper} data-testid="equipment-menu-button">
      <Button onClick={handleClick}>
        <Icon className={classes.icon} baseClassName="material-symbols-outlined">
          more_horiz
        </Icon>
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {menuItems.map(({ iconName, label, handleClick }) => (
          <Item key={label} label={label} iconName={iconName} handleClick={handleClick} />
        ))}
      </Menu>
    </div>
  );
};

const Item = ({ label, iconName, handleClick }: IMenuItem) => {
  const { classes } = useStyles();

  return (
    <MenuItem className={classes.menuItem} onClick={handleClick} data-testid="equipment-menu-edit-button">
      {iconName && (
        <Icon className={classes.icon} baseClassName="material-symbols-outlined">
          {iconName}
        </Icon>
      )}
      {label}
    </MenuItem>
  );
};
