import { TExtractFnReturnType, useInfiniteQuery, useQuery } from '@marlin/shared/utils/react-query';

import { getFilteredOrganizations } from '../infrastructure/get-filtered-organizations';
import { TFilteredOrganizationRequest } from '../infrastructure/organization.schema';
import { queryKey } from './query-key.enum';

type TQueryFnType = typeof getFilteredOrganizations;

type TUseFilteredOrganizationsOptions = {
  params: TFilteredOrganizationRequest;
};

export const useFilteredOrganizationsWithPaging = ({ params }: TUseFilteredOrganizationsOptions) => {
  return useQuery<TExtractFnReturnType<TQueryFnType>>({
    queryKey: queryKey.FILTERED_ORGANIZATIONS(params),
    queryFn: () => getFilteredOrganizations(params),
    keepPreviousData: true,
  });
};

type TUseInfiniteFilteredOrganizationsProps = {
  params: Omit<TFilteredOrganizationRequest, 'page'>;
  enabled: boolean;
};

export const useFilteredOrganizationsWithLoadMore = ({ params, enabled }: TUseInfiniteFilteredOrganizationsProps) => {
  return useInfiniteQuery<TExtractFnReturnType<TQueryFnType>>({
    queryKey: queryKey.FILTERED_ORGANIZATIONS(params),
    queryFn: (queryFnParams) => getFilteredOrganizations({ ...params, page: queryFnParams.pageParam || 1 }),
    getNextPageParam: (lastPage) => {
      return lastPage.pagination.page * lastPage.pagination.pageSize < lastPage.pagination.totalItems
        ? lastPage.pagination.page + 1
        : undefined;
    },
    enabled,
  });
};
