import {
  AVERAGING_FUNCTION_FILTER,
  RANGE_FILTER,
  TBucketOption,
  TChartDisplayType,
  TChartType,
} from '@marlin/shared/utils-chart';
import { Moment } from 'moment';

import { flowRateToFlowVolumeDatapointNameMap } from '../const';
import { getAveragingFunction } from './get-averaging-function';

interface ITelemetryDeviceParam {
  manufacturerId: string;
  datapoints: string[];
}
interface ICreateTelemetryRequestParams {
  dateFrom: string;
  dateTo: string;
  activeTelemetry: ITelemetryDeviceParam[];
  numberOfBuckets: number;
  averagingFunctionFilter: AVERAGING_FUNCTION_FILTER;
  chartDisplayType: TChartDisplayType;
  bucketOption?: TBucketOption;
  chartType: TChartType;
  rangeFilter: { range: RANGE_FILTER; currentDate: Moment };
  isZoomed: boolean;
}

export const createTelemetryRequestParams = ({
  activeTelemetry,
  chartDisplayType,
  chartType,
  dateTo,
  dateFrom,
  numberOfBuckets,
  averagingFunctionFilter,
  bucketOption,
  rangeFilter,
  isZoomed,
}: ICreateTelemetryRequestParams) => {
  const requestedTelemetry = activeTelemetry.map(({ manufacturerId, datapoints }) => ({
    manufacturerId,
    datapoints:
      chartType === 'flow' && chartDisplayType === 'bar'
        ? datapoints.map((item) => flowRateToFlowVolumeDatapointNameMap.get(item) || item)
        : datapoints,
  }));

  return {
    dateFrom,
    dateTo,
    requestedTelemetry,
    numberOfBuckets,
    averagingFunctionFilter: getAveragingFunction(chartType, chartDisplayType, averagingFunctionFilter),
    chartDisplayType,
    bucketOption,
    isCustomPeriod: rangeFilter.range === RANGE_FILTER.CUSTOM || isZoomed,
  };
};
