import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    padding: theme.typography.pxToRem(16),
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.typography.pxToRem(4),
    backgroundColor: theme.palette.background.alternative,
    minWidth: theme.typography.pxToRem(224),
    flexGrow: 1,
    width: '100%',
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: theme.typography.pxToRem(32),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'flex-start',
  },
  periodText: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
  },
  volumeText: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(24),
    fontWeight: theme.typography.fontWeightBold,
    wordWrap: 'break-word',
    [theme.breakpoints.down('md')]: {
      maxWidth: theme.typography.pxToRem(250),
    },
  },
  trendProp: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
  },
  trendValue: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold,
  },
  trendContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.typography.pxToRem(8),
  },
  infoText: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
  },
  yearWrapper: {
    paddingBottom: theme.typography.pxToRem(21),
  },
}));
