import { MarlinTheme } from '@marlin/shared/theme';
import { TDateString } from '@marlin/shared/utils-common-date';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../content';
import { formatReadingsDate } from '../../../hooks/use-telemetry-reading';
import { CurrentReading } from './current-reading.component';

interface ILostCommunicationMessageProps {
  lastReading: TDateString;
  isAlert: boolean;
  isExtended: boolean;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  lostCommunication: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${theme.typography.pxToRem(2)} 0`,
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.typography.pxToRem(4)} 0`,
    },
  },
  lostCommunicationExtended: {
    justifyContent: 'flex-start',
    columnGap: theme.typography.pxToRem(24),
  },
  lostCommunicationMessage: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const LostCommunicationMessage = ({ lastReading, isAlert, isExtended }: ILostCommunicationMessageProps) => {
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.lostCommunication, { [classes.lostCommunicationExtended]: isExtended })}>
      <div className={classes.lostCommunicationMessage} data-testid="device-drawer-current-reading">
        <CurrentReading currentReading={content.LOST_COMMUNICATION} isAlert={isAlert} />
        <Typography variant="subtitle2">{formatReadingsDate(lastReading, 'durationString')}</Typography>
      </div>
    </div>
  );
};
