import { dateAdapter } from '@marlin/shared/utils-common-date';
import { useMemo, useState } from 'react';

import { useDeviceDetailsContext } from '../../../../../context/device-details.context';
import { useSensorId } from '../../../../../hooks/use-sensor-id.hook';
import { TChartAutomation, useManageAutomations } from '../../../../manage-automations/use-manage-automations.hook';
import { RANGE_FILTER } from '../../../constant';
import { rangeFilterToRange } from '../../../utils/range-filter-to-range';
import { EXTENDED_RANGE_FILTER } from '../../collapsed-chart/chart-constants';
import { IAnnotationForm } from '../extended-chart-form.component';
import { defaultBrushChartParams } from './use-brush-chart.hook';
import { IChartSelectionEvent, ISelection, useChartSelection } from './use-chart-selection.hook';
import { IRange } from './use-main-chart.hook';
import { handleBrushRangeChange, onMainChartSelectionChange } from './utils';

const defaultValues: IAnnotationForm = {
  automations: [],
  trigger: true,
  alerts: true,
  deadband: true,
};

const createDefaultBrushRange = (commisionDate: string | null | undefined) => ({
  from:
    dateAdapter.date(commisionDate)?.toDate().getTime() ??
    dateAdapter.date()?.subtract(defaultBrushChartParams.period, defaultBrushChartParams.format).toDate().getTime() ??
    0,
  to: dateAdapter.date()?.toDate().getTime() ?? new Date().getTime(),
});

interface IExtendedChartProps {
  rangeFilter: RANGE_FILTER | EXTENDED_RANGE_FILTER;
}

export interface IExtendedChart {
  deviceId: string;
  automations: TChartAutomation[];
  setAutomationsSettings: (settings: IAnnotationForm) => void;
  formDefaultValues: IAnnotationForm;
  automationsSettings: IAnnotationForm;
  defaultBrushRange: IRange;
  selection: ISelection;
  brushSelection: ISelection;
  changeChartSelection: (selectionEvent: IChartSelectionEvent) => void;
  clearSelection: () => void;
  clearBrushSelection: () => void;
}

export const useExtendedChart = ({ rangeFilter }: IExtendedChartProps): IExtendedChart => {
  const deviceId = useSensorId();
  const deviceDetails = useDeviceDetailsContext();

  const chartSelectionParams = useMemo(
    () => ({
      onSelectionChange: onMainChartSelectionChange,
      initialSelection: rangeFilterToRange(rangeFilter),
    }),
    // NOTE: we don't want to change the initial selection on rangeFilter change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const brushChartSelectionParams = useMemo(
    () => ({
      onSelectionChange: handleBrushRangeChange,
      initialSelection: createDefaultBrushRange(deviceDetails.commissionDate),
    }),
    [deviceDetails.commissionDate]
  );

  const { selection, changeChartSelection, clearSelection } = useChartSelection(chartSelectionParams);
  const { selection: brushSelection, clearSelection: clearBrushSelection } =
    useChartSelection(brushChartSelectionParams);

  const { automations } = useManageAutomations(deviceId, { pageSize: 10 });
  const formDefaultValues = useMemo(
    () => ({
      ...defaultValues,
      automations: automations?.map((automation: TChartAutomation) => automation.id) || [],
    }),
    [automations]
  );

  const [automationsSettings, setAutomationsSettings] = useState<IAnnotationForm>(formDefaultValues);

  const defaultBrushRange = useMemo(() => {
    return createDefaultBrushRange(deviceDetails.commissionDate);
  }, [deviceDetails.commissionDate]);

  return useMemo(
    (): IExtendedChart => ({
      deviceId,
      automations: automations || [],
      setAutomationsSettings,
      formDefaultValues,
      automationsSettings,
      defaultBrushRange,
      selection,
      changeChartSelection,
      clearSelection,
      brushSelection,
      clearBrushSelection,
    }),
    [
      automations,
      automationsSettings,
      defaultBrushRange,
      deviceId,
      formDefaultValues,
      selection,
      changeChartSelection,
      clearSelection,
      brushSelection,
      clearBrushSelection,
    ]
  );
};
