import { UseMutationResult, queryClient, useMutation } from '@marlin/shared/utils/react-query';

import { deleteRecipientFromListCache } from '../cache/delete-recipient-from-list-cache';
import { updatePreviousRecipientListCache } from '../cache/update-previous-recipient-list-cache';
import { deleteRecipient } from '../infrastructure/recipient';
import { QUERY_KEY } from '../query-key.enum';
import { IListFilters, IRecipientList } from '../recipient.model';

interface IDeleteRecipient {
  previousSearchableRecipients: IRecipientList | undefined;
}

export const useDeleteRecipient = (
  filterParams?: IListFilters
): UseMutationResult<unknown, unknown, string, IDeleteRecipient> => {
  return useMutation<unknown, unknown, string, IDeleteRecipient>({
    onMutate: async (id: string) => {
      await queryClient.cancelQueries({ queryKey: [QUERY_KEY.RECIPIENTS], exact: false });

      const previousSearchableRecipients = deleteRecipientFromListCache(id, filterParams);

      return { previousSearchableRecipients };
    },
    onError: (_: unknown, _id: string, context: IDeleteRecipient | undefined) => {
      updatePreviousRecipientListCache(context?.previousSearchableRecipients, filterParams);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.RECIPIENTS] });
    },
    mutationFn: deleteRecipient,
  });
};
