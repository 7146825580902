import { IconButton } from '@mui/material';
import React, { useState } from 'react';

import { OrganizationSwitcherIcon } from '../components/organization-switcher-icon.component';
import { IEntity } from '../utils/types';
import { OrganizationDetails } from './organization-details';

interface IOrganizationSwitcherUIProps {
  currentOrgName?: string;
  currentOrgId?: string;
  entities: IEntity[];
  variant: 'marlin' | 'portfolio' | 'support';
  fetchNextPage?: () => void;
  isLoading: boolean;
  handleOrgSearchValue: (value: string) => void;
  orgSearchValue: string;
  hasNextPage: boolean;
}

export const OrganizationSwitcherMobile = ({
  currentOrgName,
  currentOrgId,
  entities,
  variant,
  fetchNextPage,
  isLoading,
  handleOrgSearchValue,
  orgSearchValue,
  hasNextPage,
}: IOrganizationSwitcherUIProps) => {
  const [organizationDetailsAnchor, setOrganizationDetailsAnchor] = useState<HTMLElement | null>(null);
  const [isOrganizationListVisible, setOrganizationListVisible] = useState(false);
  const organizationDetailsOpen = Boolean(organizationDetailsAnchor);

  const handleOpenOrganizationDetails = (event: React.MouseEvent<HTMLElement>) => {
    setOrganizationDetailsAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setOrganizationDetailsAnchor(null);
    setOrganizationListVisible(false);
  };

  const handleOpenOrganizationList = () => {
    setOrganizationListVisible((prev) => !prev);
  };

  return (
    <>
      <IconButton
        edge="start"
        color={organizationDetailsOpen ? 'primary' : 'default'}
        onClick={handleOpenOrganizationDetails}
      >
        <OrganizationSwitcherIcon variant={variant} />
      </IconButton>
      <OrganizationDetails
        entities={entities}
        entityName={currentOrgName}
        open={organizationDetailsOpen}
        anchor={organizationDetailsAnchor}
        isOrganizationListVisible={isOrganizationListVisible}
        currentOrgId={currentOrgId}
        onChange={handleOpenOrganizationList}
        onClose={handleClose}
        variant={variant}
        fetchNextPage={fetchNextPage}
        isLoading={isLoading}
        handleOrgSearchValue={handleOrgSearchValue}
        orgSearchValue={orgSearchValue}
        hasNextPage={hasNextPage}
      />
    </>
  );
};
