import { LeaveRouteGuard } from '@marlin/shared/utils-router';

import { ConfirmationModal } from './confirmation-modal.component';

interface IUpdateGatewayLeaveGuardProps {
  shouldDisplayGuard: boolean;
}

export const UpdateGatewayLeaveGuard = ({ shouldDisplayGuard }: IUpdateGatewayLeaveGuardProps) => {
  const handleOnConfirm = (onConfirm: () => void) => {
    onConfirm();
  };

  return (
    <LeaveRouteGuard when={shouldDisplayGuard} watchForShouldIgnoreGuard>
      {({ onCancel, onConfirm }) => {
        return <ConfirmationModal onCancel={onCancel} onConfirm={() => handleOnConfirm(onConfirm)} />;
      }}
    </LeaveRouteGuard>
  );
};
