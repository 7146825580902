import {
  DeleteLocationModalBody,
  DeleteLocationModalFooter,
  DeleteLocationModalTitle,
} from '@marlin/asset/ui/delete-location-modal';
import { ActionDelete, ActionEdit, ContextMenu } from '@marlin/shared/ui-context-menu';
import { PageContainer, Paper } from '@marlin/shared/ui-page';
import { Link } from '@marlin/shared/ui-page';
import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import { routes } from '@marlin/shared/utils-routes';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useCallback, useContext } from 'react';

import { content } from '../content';
import { useLocationsHubContextMenu } from '../shared/use-location-hub-context-menu.hook';
import { Filters } from './components/filters.component';
import { LocationsHubHeader } from './header.component';
import { useStyles } from './locations-hub.component.styles';
import { useLocationsHub } from './use-location-hub.hook';

interface ICreateColumns {
  deleteLocation: ({ locationId, name, isParent, deviceCount, equipmentCount }: IDeleteLocationProps) => void;
  editLocation: (locationId: string) => void;
  allowDelete: boolean;
  allowEdit: boolean;
}

interface IDeleteLocationProps {
  locationId: string;
  name: string;
  isParent: boolean;
  deviceCount: number;
  equipmentCount: number;
}

const useColumns = ({ allowDelete, deleteLocation, allowEdit, editLocation }: ICreateColumns): GridColDef[] => {
  return [
    {
      field: 'id',
      headerName: content.ID_HEADER_NAME,
      type: 'string',
      flex: 1,
    },
    {
      field: 'name',
      headerName: content.LOCATION_NAME_HEADER_NAME,
      type: 'string',
      flex: 2,
      headerAlign: 'left',
      align: 'left',
      renderCell: (cellValues) => {
        return <Link to={routes.locations.details.url(cellValues.id.toString())}>{cellValues.value}</Link>;
      },
    },
    {
      field: 'parentLocationName',
      headerName: content.PARENT_LOCATION_HEADER_NAME,
      type: 'string',
      flex: 2,
      headerAlign: 'left',
      align: 'left',
      renderCell: (cellValues) => {
        return <Link to={`/locations/${cellValues.row.parentLocationId}`}>{cellValues.row.parentLocationName}</Link>;
      },
    },
    {
      field: 'equipmentCount',
      headerName: content.EQUIPMENT_HEADER_NAME,
      type: 'number',
      flex: 2,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'deviceCount',
      headerName: content.SENSORS_HEADER_NAME,
      type: 'number',
      flex: 2,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'action',
      headerName: '',
      sortable: false,
      flex: 0.5,
      align: 'center',
      renderCell: (cellValues) => {
        return (
          <ContextMenu>
            {allowEdit && <ActionEdit onClick={() => editLocation(cellValues.row.id)} />}
            {allowDelete && (
              <ActionDelete
                onClick={() =>
                  deleteLocation({
                    locationId: cellValues.row.id,
                    name: cellValues.row.name,
                    isParent: cellValues.row.isParent,
                    deviceCount: cellValues.row.deviceCount,
                    equipmentCount: cellValues.row.equipmentCount,
                  })
                }
              />
            )}
          </ContextMenu>
        );
      },
    },
  ];
};

export const DesktopLocationHub = () => {
  const { classes } = useStyles();
  const {
    rows,
    parentLocationsList,
    isLoading,
    isError,
    pagination,
    changePage,
    changePageSize,
    onFiltersChange,
    onSortChange,
  } = useLocationsHub();
  const { modalDispatch } = useContext(ModalContext);
  const { allowDelete, allowEdit, editLocation } = useLocationsHubContextMenu();

  const deleteLocation = useCallback(
    (location: IDeleteLocationProps) => {
      const canDelete = !location.isParent && location.deviceCount === 0 && location.equipmentCount === 0;
      const reason = !canDelete ? (location.isParent ? 'parent' : 'assets') : undefined;
      modalDispatch({
        type: MODAL_ACTION_TYPE.SHOW,
        payload: {
          title: <DeleteLocationModalTitle canDelete={canDelete} />,
          body: (
            <DeleteLocationModalBody
              assetId={location.locationId}
              deleteAssetName={location.name}
              canDelete={canDelete}
              reason={reason}
            />
          ),
          footer: <DeleteLocationModalFooter assetId={location.locationId} canDelete={canDelete} />,
        },
      });
    },
    [modalDispatch]
  );

  const columns = useColumns({
    allowDelete,
    allowEdit,
    deleteLocation,
    editLocation,
  });

  return (
    <PageContainer prefix="location-hub-page">
      <div className={classes.wrapper}>
        <LocationsHubHeader />
        <Paper className={classes.filterWrapper} data-testid="card">
          <Filters onFiltersChange={onFiltersChange} parentLocationsList={parentLocationsList} />
          <div className={classes.dataGridWrapper} data-testid="grid-table-wrapper">
            <DataGrid
              localeText={{
                noRowsLabel: isError ? content.ERROR : content.NO_DATA,
              }}
              className={classes.dataGrid}
              loading={isLoading}
              rows={rows}
              columns={columns}
              rowsPerPageOptions={[5, 10, 50, 100]}
              columnVisibilityModel={{
                action: allowEdit || allowDelete,
              }}
              page={pagination.page}
              pageSize={pagination.pageSize}
              rowCount={pagination.rowCount}
              onPageChange={changePage}
              onPageSizeChange={changePageSize}
              paginationMode="server"
              onSortModelChange={onSortChange}
              disableColumnMenu
            />
          </div>
        </Paper>
      </div>
    </PageContainer>
  );
};
