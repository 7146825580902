import { MarlinTheme } from '@marlin/shared/theme';
import { PERMISSIONS, PermissionGuard } from '@marlin/shared/utils-permission';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import DeviceHubRoundedIcon from '@mui/icons-material/DeviceHubRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import SensorsRoundedIcon from '@mui/icons-material/SensorsRounded';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { IAlert } from '../../types';
import { MetadataItem } from '../metadata-item.component';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  extendedItems: {
    display: 'flex',
  },
}));

interface IRecipientsSection {
  alert: IAlert;
  icon?: JSX.Element;
  isCurrentAlert?: boolean;
  isEquipmentAlert?: boolean;
}

export const MetadataSection = ({ alert, isEquipmentAlert = false }: IRecipientsSection): JSX.Element => {
  const { classes } = useStyles();
  const router = useRouter();
  const currentUrl = useMemo(() => router.getUrl(), [router]);

  return (
    <div className={classes.extendedItems} data-testid="metadata-section">
      {alert.location?.name && (
        <MetadataItem
          label={alert.location?.name}
          icon={<LocationOnRoundedIcon />}
          link={routes.locations.details.url(alert.location?.id)}
          disabled={alert.location?.isDeleted}
          type="location"
        />
      )}

      {alert.equipment?.name && (
        <MetadataItem
          label={alert.equipment?.name}
          icon={<DeviceHubRoundedIcon />}
          link={routes.equipments.details.url(alert.equipment?.id)}
          disabled={alert.equipment?.isDeleted}
          type="equipment"
        />
      )}

      {alert.device?.name && alert.device?.type !== 'EQUIPMENT' && (
        <PermissionGuard permissions={[PERMISSIONS.SENSOR_DEVICES]}>
          <MetadataItem
            label={alert.device?.name}
            icon={<SensorsRoundedIcon />}
            link={routes.deviceDrawer.open(currentUrl, alert.device.id)}
            disabled={alert.device?.isDeleted}
            type="device"
          />
        </PermissionGuard>
      )}
    </div>
  );
};
