import { PERMISSIONS, TPermission } from '@marlin/shared/utils-permission';

export const managerPermissions: TPermission[] = [
  PERMISSIONS.ADD_ORG_TO_PORTFOLIO,
  PERMISSIONS.REMOVE_USER_FROM_PORTFOLIO,
  PERMISSIONS.EDIT_USER_PORTFOLIO,
  PERMISSIONS.PORTFOLIO_DETAILS,
  PERMISSIONS.DELETE_ORG_FROM_PORTFOLIO,
  PERMISSIONS.EDIT_PORTFOLIO,
  PERMISSIONS.DELETE_PORTFOLIO,
];
