import { IResendInvite } from '@marlin/account-ui-user-list';
import { MarlinTheme } from '@marlin/shared/theme';
import { ModalMedium } from '@marlin/shared/ui-modal';
import { Button } from '@mui/material';
import { useCallback } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';
import { useResendInvite } from './use-resend-invite.hook';

interface IResendModalProps {
  user: IResendInvite | null;
  onClose: () => void;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  confirmText: {
    fontWeight: theme.typography.fontWeightBold,
    wordWrap: 'break-word',
  },

  button: {
    width: theme.typography.pxToRem(88),
  },

  cancelButton: {
    marginLeft: theme.typography.pxToRem(8),
  },
}));

const ResendModal = ({ user, onClose }: IResendModalProps) => {
  const { classes } = useStyles();
  const { handleResend } = useResendInvite();

  const handleConfirm = useCallback(async (): Promise<void> => {
    if (!user) {
      return;
    }

    onClose();
    handleResend(user);
  }, [handleResend, onClose, user]);

  if (!user) {
    return null;
  }

  return (
    <ModalMedium
      onClose={onClose}
      title={content.RESEND_MODAL_TITLE}
      body={
        <>
          <div>{content.RESEND_MODAL_BODY}</div>
          <div className={classes.confirmText}>{user.email}</div>
        </>
      }
      footer={
        <>
          <Button className={classes.button} variant="text" onClick={onClose}>
            {content.RESEND_MODAL_BUTTON_CANCEL_TEXT}
          </Button>
          <Button className={`${classes.button} ${classes.cancelButton}`} variant="contained" onClick={handleConfirm}>
            {content.RESEND_MODAL_BUTTON_SUBMIT_TEXT}
          </Button>
        </>
      }
    />
  );
};

export { ResendModal };
