import { Chip } from '@marlin/shared/ui/chips';
import { TSystemTag } from '@marlin/system-map/shared/data-access-schemas';
import { Menu, MenuItem } from '@mui/material';

import { content } from '../../content';
import { useStyles } from './system-tag-menu.styles';

interface ISystemTagMenuProps {
  anchor: null | HTMLElement;
  onClose: () => void;
  selectedTag?: TSystemTag | null;
  setSystemTag: (systemTag: TSystemTag | null, onClose: () => void) => void;
}

interface IOption {
  id: TSystemTag;
  label: string;
}

const tagLabelDictionary = {
  DOMESTIC_COLD_WATER: content.SYSTEM_TAG.TAGS.DOMESTIC_COLD_WATER,
  DOMESTIC_HOT_WATER: content.SYSTEM_TAG.TAGS.DOMESTIC_HOT_WATER,
  HEATING_HOT_WATER: content.SYSTEM_TAG.TAGS.HEATING_HOT_WATER,
};

const options: IOption[] = [
  {
    id: 'DOMESTIC_COLD_WATER',
    label: tagLabelDictionary.DOMESTIC_COLD_WATER,
  },
  {
    id: 'DOMESTIC_HOT_WATER',
    label: tagLabelDictionary.DOMESTIC_HOT_WATER,
  },
  {
    id: 'HEATING_HOT_WATER',
    label: tagLabelDictionary.HEATING_HOT_WATER,
  },
];

export const SystemTagMenu = ({ anchor, onClose, selectedTag, setSystemTag }: ISystemTagMenuProps) => {
  const { classes } = useStyles();

  return (
    <Menu
      anchorEl={anchor}
      open={!!anchor}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      classes={{ list: classes.menu, root: classes.menuRoot }}
      data-testid="system-tag-context-menu"
    >
      {selectedTag && (
        <MenuItem
          key={selectedTag}
          className={classes.chipMenuItem}
          data-testid={`system-tag-context-menu-${(tagLabelDictionary[selectedTag] || '')
            .toLowerCase()
            .replaceAll(' ', '-')}`}
          dense
          divider
        >
          <Chip
            label={tagLabelDictionary[selectedTag] || ''}
            onDelete={() => setSystemTag(null, onClose)}
            size={'small'}
          />
        </MenuItem>
      )}
      {options
        .filter((option) => option.id !== selectedTag)
        .map((option) => (
          <MenuItem
            key={option.id}
            className={classes.optionMenuItem}
            onClick={() => setSystemTag(option.id, onClose)}
            data-testid={`system-tag-context-menu-${option.label.toLowerCase().replaceAll(' ', '-')}}`}
            dense
          >
            {option.label}
          </MenuItem>
        ))}
    </Menu>
  );
};
