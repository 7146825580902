import { FullLogo, MarlinTheme } from '@marlin/shared/theme';
import { TCreateSystemMapNodeParams } from '@marlin/system-map/data-access/system-map';
import { Divider, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';
import { EditModeContext } from './edit-mode.context';
import { SaveButton } from './react-flow/buttons/save-button.component';
import { useTouchedState$ } from './react-flow/buttons/use-observable-touched-state.hook';
import { FlowDiagram } from './react-flow/flow-diagram.component';
import { TTeeGraphAsset } from './types';

interface IDiagramProps {
  externalClasses: {
    header: string;
    canvas: string;
  };
  isFullScreen?: boolean;
  toggleFullScreen: () => void;
  onAddNodeToTee: (tee: TCreateSystemMapNodeParams) => void;
  openRenameTeeModal: (teeToRename: TTeeGraphAsset) => void;
}

const logoHeight = 30;

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  switcherContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.typography.pxToRem(32),
    margin: 0,
  },
  title: {
    display: 'inline-flex',
    alignItems: 'center',
  },
}));

export const Diagram = ({
  externalClasses,
  isFullScreen = false,
  toggleFullScreen,
  onAddNodeToTee,
  openRenameTeeModal,
}: IDiagramProps) => {
  const { classes } = useStyles();
  const [touched, setTouched] = useTouchedState$(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const onNodeDrag = useCallback(() => {
    setTouched(true);
  }, [setTouched]);

  const onModeChange = (newAlignment: string) => {
    if (newAlignment === content.EDIT) {
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
    }
  };

  return (
    <>
      <div className={externalClasses.header}>
        <Typography variant="h6" className={classes.title}>
          {content.TITLE}
        </Typography>
        {isFullScreen && <FullLogo height={logoHeight} />}
        <p className={classes.switcherContainer}>
          <SaveButton touched={touched} setTouched={setTouched} onModeChange={onModeChange} isEditMode={isEditMode} />
        </p>
      </div>
      <Divider />
      <div className={externalClasses.canvas}>
        <EditModeContext.Provider value={{ isEditMode }}>
          <FlowDiagram
            toggleFullScreen={toggleFullScreen}
            isFullScreen={isFullScreen}
            onAddNodeToTee={onAddNodeToTee}
            openRenameTeeModal={openRenameTeeModal}
            onNodeDrag={onNodeDrag}
            isEditMode={isEditMode}
          />
        </EditModeContext.Provider>
      </div>
    </>
  );
};
