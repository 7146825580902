import { zodResolver } from '@hookform/resolvers/zod';
import { OrganizationsList } from '@marlin/account-ui-organization-switcher';
import { FormField } from '@marlin/shared/ui-form-common';
import { getLogger } from '@marlin/shared/utils/logger';
import { FormControl, InputLabel, Select, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { content } from '../../../../content';
import { Buttons } from '../../../buttons/buttons.component';
import { TSelectOrganization, selectOrgComponentSchema } from '../../../onboarding.schema';
import { useOnboardingState } from '../../../use-onboarding-state.hook';
import { ONBOARDING_STEP } from '../../steps';
import { useStyles } from '../setup-organization.styles';

export interface IEntity {
  id: string;
  name: string;
  tierName?: string;
}

export const SelectOrganization = ({
  organizations,
  onCancel,
  onNextStep,
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
  isLoading,
  handleOrgSearchValue,
  term: orgSearchValue,
}: {
  organizations: IEntity[];
  onCancel: () => void;
  onNextStep: (step: ONBOARDING_STEP, completed?: ONBOARDING_STEP[]) => void;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
  fetchNextPage: () => void;
  isLoading: boolean;
  handleOrgSearchValue: (value: string) => void;
  term: string;
}) => {
  const { classes } = useStyles();
  const { addOrganization, onboardingState } = useOnboardingState();
  const [organizationsListAnchor, setOrganizationListAnchor] = useState<HTMLElement | null>(null);
  const organizationsListOpen = Boolean(organizationsListAnchor);
  const form = useForm<TSelectOrganization>({
    defaultValues: { organizationId: '' },
    mode: 'onChange',
    resolver: zodResolver(selectOrgComponentSchema),
  });
  const {
    handleSubmit,
    setValue,
    formState: { isValid },
    trigger,
  } = form;

  const onSubmit = (data: TSelectOrganization) => {
    getLogger()?.track(content.LOGGER.SELF_SERVICE.SELECT_ORGANIZATION_SUCCESS, {
      addOrganization: data.organizationId,
    });
    const organizationName = organizations.find((org) => org.id === data.organizationId)?.name ?? '';
    addOrganization({ data, organizationName });
    onNextStep(ONBOARDING_STEP.REGISTER_EQUIPMENT, [ONBOARDING_STEP.SETUP_ORGANIZATION]);
  };

  const handleOpenOrganizationsList = (event: React.MouseEvent<HTMLElement>) => {
    setOrganizationListAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setOrganizationListAnchor(null);
  };

  useEffect(() => {
    if (onboardingState.organizationId) {
      setValue('organizationId', onboardingState.organizationId);
    }
  }, [setValue, onboardingState.organizationId]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.inputsContainer}>
          <FormField<TSelectOrganization> fieldName="organizationId">
            {(field) => (
              <div className={classes.selectOrgContainer}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel id={`organizationId-select-label`} required={true}>
                    {content.ORGANIZATION}
                  </InputLabel>
                  <Select
                    fullWidth={true}
                    prefix="organizationId"
                    required
                    open={false}
                    label={content.ORGANIZATION}
                    className={classes.select}
                    onClick={(event) => {
                      event.preventDefault();
                      handleOpenOrganizationsList(event);
                    }}
                    value={field.value}
                    renderValue={() => {
                      return <Typography>{organizations.find((org) => org.id === field.value)?.name}</Typography>;
                    }}
                  />
                </FormControl>
                <OrganizationsList
                  anchor={organizationsListAnchor}
                  entities={organizations}
                  open={organizationsListOpen}
                  onChange={(organizationId) => {
                    setValue('organizationId', organizationId);
                    trigger('organizationId');
                    handleClose();
                  }}
                  onClose={handleClose}
                  handleOrgSearchValue={handleOrgSearchValue}
                  orgSearchValue={orgSearchValue}
                  fetchNextPage={fetchNextPage}
                  isLoading={isLoading || isFetchingNextPage}
                  hasNextPage={hasNextPage}
                  isPortfolio={false}
                  leftAnchor={true}
                  width={676}
                />
              </div>
            )}
          </FormField>
        </div>
        <Buttons onNext={handleSubmit(onSubmit)} onCancel={onCancel} isDisabled={!isValid} />
      </form>
    </FormProvider>
  );
};
