import { MarlinTheme } from '@marlin/shared/theme';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { ModalLarge } from '@marlin/shared/ui-modal';
import { createDateString, defaultDateTime, formatDate as formatDateUtil } from '@marlin/shared/utils-common-date';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { isSystemAutomation } from './automation-type.util';
import { Buttons } from './buttons.component';
import { content } from './content';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.typography.pxToRem(16),
  },

  snoozeUntilText: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.body2.fontWeight,
    color: theme.palette.text.secondary,
  },

  assetSelect: {
    width: '100%',
  },
}));

interface ISnoozeModalProps {
  onClose: () => void;
  onSubmit: (minutes: number, isSystemAutomation: boolean) => void;
  automationName: string;
  deviceName?: string;
}

interface ISnoozeOptionsProps {
  label: string;
  value: number;
}

const hourInMinutes = 60;

const SnoozeOptions: ISnoozeOptionsProps[] = [
  {
    label: content.HOUR_1,
    value: 1 * hourInMinutes,
  },
  {
    label: content.HOURS_8,
    value: 8 * hourInMinutes,
  },
  {
    label: content.HOURS_12,
    value: 12 * hourInMinutes,
  },
  {
    label: content.HOURS_24,
    value: 24 * hourInMinutes,
  },
  {
    label: content.HOURS_48,
    value: 48 * hourInMinutes,
  },
];

const ModalTitle = (
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: 4 }}>
    <Typography variant="h6" component="h2">
      {content.MODAL_TITLE}
    </Typography>
    <Tooltip placement="top" text={content.SNOOZE_TOOLTIP} />
  </div>
);

export const SnoozeModal = ({ onClose, onSubmit, automationName, deviceName }: ISnoozeModalProps) => {
  const { classes } = useStyles();
  const [selectedSnoozeTimeMinutes, setSelectedSnoozeTimeMinutes] = useState<number | undefined>(undefined);

  const handleSnoozeTimeChange = (event: SelectChangeEvent<number>) => {
    setSelectedSnoozeTimeMinutes(Number(event.target.value));
  };

  const handleSnoozeTimeConfirm = () => {
    if (selectedSnoozeTimeMinutes) {
      onSubmit(selectedSnoozeTimeMinutes, isSystemAutomation(automationName));
      setSelectedSnoozeTimeMinutes(undefined);
    }
  };

  const handleCancelModal = () => {
    setSelectedSnoozeTimeMinutes(undefined);
    onClose();
  };

  const snoozeUntilDate = useMemo(() => {
    if (!selectedSnoozeTimeMinutes) {
      return '';
    }
    const date = new Date();
    date.setMinutes(date.getMinutes() + selectedSnoozeTimeMinutes);
    const dateString = createDateString(date);
    return formatDateUtil(dateString, defaultDateTime);
  }, [selectedSnoozeTimeMinutes]);

  return (
    <ModalLarge
      onClose={onClose}
      title={ModalTitle}
      body={
        <div className={classes.container}>
          {isSystemAutomation(automationName) && deviceName
            ? content.MODAL_MESSAGE_SYSTEM_AUTOMATION(automationName, deviceName)
            : content.MODAL_MESSAGE(automationName)}
          <FormControl>
            <InputLabel id="snooze-time-select-label">{content.SNOOZE_TIME}</InputLabel>
            <Select
              labelId="select-label"
              label={content.SNOOZE_TIME}
              id="snooze-time-select-label"
              value={selectedSnoozeTimeMinutes}
              onChange={handleSnoozeTimeChange}
            >
              {SnoozeOptions.map((snoozeOption) => (
                <MenuItem key={snoozeOption.label} value={snoozeOption.value}>
                  {snoozeOption.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {selectedSnoozeTimeMinutes && (
            <Typography id="snooze-until" variant="body2" className={classes.snoozeUntilText}>
              {content.SNOOZE_DATE_UNTIL(snoozeUntilDate)}
            </Typography>
          )}
        </div>
      }
      footer={
        <Buttons
          onCancel={handleCancelModal}
          onSubmit={handleSnoozeTimeConfirm}
          selectedSnoozeTime={selectedSnoozeTimeMinutes}
        />
      }
    />
  );
};
