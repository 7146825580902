import { MarlinTheme } from '@marlin/shared/theme';
import { PageHeader } from '@marlin/shared/ui-page';
import { ReloadContext } from '@marlin/shared/ui/chart';
import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import { Button, Icon, IconButton, alpha } from '@mui/material';
import React, { useContext } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';
import { SettingsModalBody } from './settings-modal/settings-modal-body';
import { SettingsModalTitle } from './settings-modal/settings-modal-title';

export const useStyles = makeStyles()((theme: MarlinTheme) => {
  return {
    icon: {
      '& span': { fontSize: theme.typography.pxToRem(28) },
    },
    actionsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: theme.typography.pxToRem(8),
    },
    modal: {
      minWidth: theme.typography.pxToRem(396),
    },
    refreshIcon: {
      color: alpha(theme.palette.action.active, 0.54),
    },
  };
});

export const SustainabilityHeader = () => {
  const { classes } = useStyles();
  const { modalDispatch } = useContext(ModalContext);
  const { reload } = useContext(ReloadContext);

  const handleCloseModal = () => {
    modalDispatch({
      type: MODAL_ACTION_TYPE.DISMISS,
    });
  };

  const handleOpenSettingsModal = () => {
    modalDispatch({
      type: MODAL_ACTION_TYPE.SHOW,
      payload: {
        title: <SettingsModalTitle />,
        body: <SettingsModalBody handleCloseModal={handleCloseModal} />,
        modalClassName: classes.modal,
      },
    });
  };

  return (
    <PageHeader
      prefix="sustainability"
      title={content.SUSTAINABILITY_TITLE}
      subtitle={content.SUSTAINABILITY_SUBTITLE}
      icon={
        <Icon className={classes.icon} baseClassName="material-symbols-outlined">
          nest_eco_leaf
        </Icon>
      }
      actions={
        <div className={classes.actionsWrapper}>
          <IconButton color="inherit" data-testid="refresh" onClick={reload}>
            <RefreshRoundedIcon className={classes.refreshIcon} />
          </IconButton>
          <Button onClick={handleOpenSettingsModal} variant="outlined" data-testid="open-settings-modal-button">
            {content.SETTINGS}
          </Button>
        </div>
      }
    />
  );
};
