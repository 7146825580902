import { TEquipmentDetailsRow } from '@marlin/asset/shared/equipment-config';
import { dateFormatterValueCallback } from '@marlin/shared/utils-common-date';

import { content } from '../../../content';

const firmwareVersionCallback = (value: unknown) => {
  if (typeof value === 'string') {
    try {
      const configObject = JSON.parse(value);
      return configObject?.Firmware?.Version;
    } catch {
      return undefined;
    }
  }
  return undefined;
};

export const detailsConfig: TEquipmentDetailsRow[] = [
  { name: 'name', label: content.EQUIPMENT_NAME, valueSource: 'equipment' },
  { name: 'automationCount', label: content.AUTOMATIONS, valueSource: 'equipment' },
  {
    name: 'ConfigData',
    label: content.FIRMWARE_VERSION,
    valueSource: 'datapoints',
    valueCallback: firmwareVersionCallback,
  },
  { name: 'brand', label: content.BRAND, valueSource: 'equipment' },

  {
    name: 'manufacturerId',
    label: content.EQUIPMENT_ID,
    valueSource: 'device',
    tooltip: content.EQUIPMENT_ID_TOOLTIP,
  },
  { name: 'description', label: content.DESCRIPTION, valueSource: 'equipment' },
  {
    name: 'registrationStatusChangeDate',
    label: content.REGISTRATION_DATE,
    valueSource: 'equipment',
    valueCallback: dateFormatterValueCallback,
  },
  {
    name: 'commissionDate',
    label: content.COMMISSION_DATE,
    valueSource: 'equipment',
    valueCallback: dateFormatterValueCallback,
  },
];
