export const content = {
  CONFIRM_PIN_SENSOR_TITLE: 'Pin Sensor?',
  CONFIRM_PIN_SENSOR_TEXT: 'By pinning this sensor, you will see it at the top of your home page.',
  CONFIRM_PIN_SENSOR_QUESTION: 'Are you sure you want to pin this sensor?',
  CONFIRM_PIN_SENSOR_CANCEL_BUTTON: 'CANCEL',
  CONFIRM_PIN_SENSOR_PIN_BUTTON: 'PIN',

  CONFIRM_UNPIN_SENSOR_TITLE: 'Unpin Sensor?',
  CONFIRM_UNPIN_SENSOR_TEXT: 'By unpinning this sensor, you will remove it from the top of your home page.',
  CONFIRM_UNPIN_SENSOR_QUESTION: 'Are you sure you want to unpin this sensor?',
  CONFIRM_UNPIN_SENSOR_CANCEL_BUTTON: 'CANCEL',
  CONFIRM_UNPIN_SENSOR_PIN_BUTTON: 'UNPIN',
};
