import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingTop: theme.typography.pxToRem(12),
    paddingBottom: theme.typography.pxToRem(12),
  },

  createContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    alignItems: 'center',
    '& > svg': {
      color: theme.palette.action.active,
    },
  },

  iconContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  createInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.typography.pxToRem(12),
    marginLeft: theme.typography.pxToRem(12),
    paddingTop: theme.typography.pxToRem(2),
    paddingBottom: theme.typography.pxToRem(2),
  },

  createText: {
    fontFamily: 'Inter',
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },

  createTextField: {
    marginTop: theme.typography.pxToRem(8),
    marginBottom: theme.typography.pxToRem(8),
  },

  createBtn: {
    alignSelf: 'flex-end',
    marginTop: theme.typography.pxToRem(4),
    marginBottom: theme.typography.pxToRem(12),
  },
}));
