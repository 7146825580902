import { MarlinTheme } from '@marlin/shared/theme';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../content';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  link: {
    color: theme.palette.primary.light,
  },
}));

export const StepFooter = () => {
  const { classes } = useStyles();

  return (
    <Typography variant="caption" color="text.disabled" data-testid="register-equipment-footer">
      {content.REGISTER_EQUIPMENT_SUPPORT}
      <a className={classes.link} href={`mailto:${content.REGISTER_EQUIPMENT_EMAIL}`}>
        {content.REGISTER_EQUIPMENT_EMAIL}
      </a>
      .
    </Typography>
  );
};
