import { MarlinTheme } from '@marlin/shared/theme';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    backgroundColor: theme.palette.background.primary,
    padding: `${theme.typography.pxToRem(24)} 0`,
    borderRadius: theme.typography.pxToRem(4),
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
  },
  chartLogo: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.typography.pxToRem(16)} 0`,
    justifyContent: 'center',
    [theme.breakpoints.down('lg')]: {
      textAlign: 'center',
      padding: `${theme.typography.pxToRem(16)} 0 ${theme.typography.pxToRem(8)}`,
      '& svg': {
        width: theme.typography.pxToRem(198),
        height: theme.typography.pxToRem(112),
      },
    },
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.typography.pxToRem(8),
  },
  actions: {
    marginTop: theme.typography.pxToRem(24),
    display: 'flex',
    justifyContent: 'center',
    gap: theme.typography.pxToRem(24),
  },
}));

export const NoCharts = ({
  onAddChart,
  onRestoreDefault,
}: {
  onRestoreDefault: () => void;
  onAddChart: () => void;
}) => {
  const { classes, theme } = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.chartLogo}>{theme.icon.chartLogo}</div>
      <Typography variant="h4" align="center" className={classes.title}>
        {content.NO_CHARTS_TITLE}
      </Typography>
      <Typography variant="subtitle1" align="center">
        {content.NO_CHARTS_SUBTITLE}
      </Typography>
      <div className={classes.actions}>
        <Button variant="contained" onClick={onAddChart}>
          {content.ADD_CHART.SUBMIT}
        </Button>
        <Button variant="outlined" onClick={onRestoreDefault}>
          {content.RESTORE_DEFAULT}
        </Button>
      </div>
    </div>
  );
};
