import { ActionDelete, ActionEdit, ContextMenu } from '@marlin/shared/ui-context-menu';
import { Link } from '@marlin/shared/ui-page';
import { routes } from '@marlin/shared/utils-routes';
import { TAssetType, TDeviceType } from '@marlin/system-map/shared/data-access-schemas';
import { GridColDef } from '@mui/x-data-grid';

import { assetTypeOptions } from './asset-type';
import { IAsset } from './types';

interface ICreateColumns {
  deleteAsset: (asset: IAsset) => void;
  editAsset: (asset: IAsset) => void;
  allowDelete: boolean;
  allowEdit: boolean;
  currentUrl: string;
  addSensorsFromContext: () => void;
}

const isEquipment = (type: TAssetType) => type === 'EQUIPMENT';

export const createColumns = ({
  allowDelete,
  deleteAsset,
  allowEdit,
  editAsset,
  currentUrl,
  addSensorsFromContext,
}: ICreateColumns): GridColDef[] => [
  {
    field: 'id',
    headerName: 'ID',
    flex: 1,
    type: 'string',
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 3,
    headerAlign: 'left',
    align: 'left',
    type: 'string',
    renderCell: (cellValues) => {
      return isEquipment(cellValues.row.type) ? (
        <Link to={routes.equipments.details.url(cellValues.row.id)}>{cellValues.value}</Link>
      ) : (
        <Link onClick={addSensorsFromContext} to={routes.deviceDrawer.open(currentUrl, cellValues.row.id)}>
          {cellValues.value}
        </Link>
      );
    },
  },
  {
    field: 'type',
    headerName: 'Type',
    type: 'string',
    flex: 3,
    headerAlign: 'left',
    align: 'left',
    renderCell: (cellValues) => {
      const value: TAssetType = cellValues.value;
      if (value === 'DEVICE') {
        return assetTypeOptions[cellValues.row.deviceType as TDeviceType];
      }
      return assetTypeOptions[value];
    },
  },
  {
    field: 'locationName',
    headerName: 'Location',
    type: 'string',
    flex: 3,
    headerAlign: 'left',
    align: 'left',
    renderCell: (cellValues) => {
      return <Link to={routes.locations.details.url(cellValues.row.locationId)}>{cellValues.value}</Link>;
    },
  },
  {
    field: 'action',
    headerName: '',
    sortable: false,
    flex: 0.5,
    align: 'center',
    renderCell: (cellValues) => {
      const isNotGateway = cellValues.row.model !== 'Partition';
      return (
        <ContextMenu>
          {allowEdit && <ActionEdit onClick={() => editAsset(cellValues.row as IAsset)} />}
          {allowDelete && isNotGateway && <ActionDelete onClick={() => deleteAsset(cellValues.row as IAsset)} />}
        </ContextMenu>
      );
    },
  },
];
