import { environment } from '@marlin/environment';

export const content = {
  SETTINGS_TITLE: 'Settings',
  SETTINGS_SUBTITLE_SUPPORT: "Manage your organization's settings.",
  SETTINGS_SUBTITLE_VIEWER: 'Manage your profile and appearance settings below.',
  SETTINGS_SUBTITLE_MY_ACCOUNT_VIEWER: 'Manage your account settings. These settings will apply to you only.',
  SETTINGS_SUBTITLE_MY_PREFERENCES_VIEWER:
    'Manage your user interface display options. These settings will apply to you only.',
  SETTINGS_SUBTITLE_MANAGE_USER_SETTINGS: 'Manage user settings.',
  SETTINGS_SUBTITLE_ORGANIZATION_INFORMATION: 'Organization information for Administrators.',
  SETTINGS_TAB_PEOPLE: 'People',
  SETTINGS_TAB_MY_PREFERENCES: 'My Preferences',
  SETTINGS_TAB_ORGANIZATION: 'Organization',
  SETTINGS_TAB_INTEGRATION: 'Integration',
  SETTINGS_TAB_HOTSOS: 'HOTSOS INTEGRATION',
  SETTINGS_INVITE_NEW_USER_TITLE: 'Invite New User',
  SETTINGS_INVITE_NEW_USER_SUBTITLE: `Use this form to invite a new user to your organization\`s ${environment.app.name} platform.`,
  SETTINGS_EDIT_USER_TITLE: 'Edit User Information',
  SETTINGS_EDIT_USER_SUBTITLE: "Use this form to edit a user's profile.",
  SETTINGS_EDIT_RECIPIENT_TITLE: 'Edit Recipient Information',
  SETTINGS_EDIT_RECIPIENT_SUBTITLE: "Use this form to edit a recipient's profile.",
  SETTINGS_ADD_RECIPIENT_TITLE: 'Add New Recipient',
  SETTINGS_ADD_RECIPIENT_SUBTITLE: 'Use the form below to add a new recipient.',
  UPDATE_USER_PROFILE_PAGE_TITLE: 'Edit User Information',
  UPDATE_USER_PROFILE_PAGE_SUBTITLE: 'Use this form to edit your profile.',
  SETTINGS_TAB_MY_ACCOUNT: 'My Account',
  SETTINGS_TAB_GATEWAY: 'Gateway',
  SETTINGS_SUBTITLE_GATEWAY: 'Manage your gateway settings.',
};
