import { CREATE_RECIPIENT_ERROR_CODE } from '@marlin/account-data-access-recipient';

import { content } from './content';

export const errorCodeMessages: Record<CREATE_RECIPIENT_ERROR_CODE, string> = {
  [CREATE_RECIPIENT_ERROR_CODE.RECIPIENT_WITH_THIS_EMAIL_ALREADY_IN_ORGANIZATION]:
    content.VALIDATION.RECIPIENT_WITH_THIS_EMAIL_ALREADY_IN_ORGANIZATION,
  [CREATE_RECIPIENT_ERROR_CODE.USER_WITH_THIS_EMAIL_ALREADY_EXISTS]:
    content.VALIDATION.USER_WITH_THIS_EMAIL_ALREADY_EXISTS,
  [CREATE_RECIPIENT_ERROR_CODE.INVITATION_ALREADY_IN_PROGRESS]: content.VALIDATION.INVITATION_ALREADY_IN_PROGRESS,
};
