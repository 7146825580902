import { TOrganizationResponseSchema } from '@marlin/account-data-access-organization';
import { INTERNAL_ROLE } from '@marlin/shared/utils-role';
import { AxiosError } from 'axios';

import { content } from './content';

export enum INVITATION_ERROR {
  ALREADY_IN_PROGRESS = 6,
  USER_ALREADY_EXISTS = 5,
  INVITATION_EXPIRED = 1,
  INVITATION_ALREADY_ACCEPTED = 2,
  INVALID_INVITATION = 3,
  RECIPIENT_ALREADY_IN_ORGANIZATION = 4,
}

export interface IInvitationError {
  title: INVITATION_ERROR;
  errorCode: INVITATION_ERROR;
}

interface IErrorHandlerProps {
  error: AxiosError<IInvitationError>;
  onError: (message: string) => void;
}

export const errorHandler = ({ error, onError }: IErrorHandlerProps) => {
  if (error?.response?.status === 422) {
    switch (error.response.data.errorCode) {
      case INVITATION_ERROR.ALREADY_IN_PROGRESS: {
        onError(content.ERROR_INVITATION_ALREADY_IN_PROGRESS);
        break;
      }
      case INVITATION_ERROR.USER_ALREADY_EXISTS: {
        onError(content.ERROR_USER_ALREADY_EXISTS);
        break;
      }
      case INVITATION_ERROR.INVITATION_EXPIRED: {
        onError(content.ERROR_INVITATION_EXPIRED);
        break;
      }
      case INVITATION_ERROR.INVITATION_ALREADY_ACCEPTED: {
        onError(content.ERROR_INVITATION_ALREADY_ACCEPTED);
        break;
      }
      case INVITATION_ERROR.INVALID_INVITATION: {
        onError(content.ERROR_INVALID_INVITATION);
        break;
      }
      case INVITATION_ERROR.RECIPIENT_ALREADY_IN_ORGANIZATION: {
        onError(content.ERROR_RECIPIENT_ALREADY_IN_ORGANIZATION);
        break;
      }
      default: {
        onError(content.ERROR_UNKNOWN);
      }
    }
  }
};

export const isSupportUser = (invitedUserEmail: string, userData?: TOrganizationResponseSchema): boolean => {
  return userData?.email === invitedUserEmail && userData?.currentOrganization.roles.includes(INTERNAL_ROLE.SUPPORT);
};
