export const goToPortfolio = () => window.location.assign('/portfolio');
export const goToSupport = () => window.location.assign('/support');
export const goToCreatePortfolio = () => window.location.assign('/portfolio/create');
export const goToPortfolioSettingsPeople = () => window.location.assign('/portfolio/settings/people');
export const goToPurgatoryPage = () => window.location.assign('/portfolio/removed');
export const goToMarlin = () => window.location.assign('/');
export const goToHelp = () => window.location.assign('/help');

export const goToMarlinDashboardWithOrgId = (orgId: string) => window.location.assign(`/?orgId=${orgId}`);
export const goToMarlinSettingsWithOrgId = (orgId: string) => window.location.assign(`/settings/people?orgId=${orgId}`);

export const goToOrgSettings = () => window.location.assign(`/settings/organization`);
