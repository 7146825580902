import {
  CHANNEL_TYPE,
  CRITICALITY,
  DEVICE_RESPONSE_TYPE,
  DEVICE_TYPE,
  IChannels,
  TRecipientType,
} from '../../automation.model';
import { CHANNEL_TYPE_RESPONSE, CRITICALITY_RESPONSE, IChannelResponse } from '../automation.model';

const parseChannelType = (type: CHANNEL_TYPE): CHANNEL_TYPE_RESPONSE => {
  switch (type) {
    case CHANNEL_TYPE.SMS: {
      return CHANNEL_TYPE_RESPONSE.SMS;
    }
    case CHANNEL_TYPE.EMAIL: {
      return CHANNEL_TYPE_RESPONSE.EMAIL;
    }
    case CHANNEL_TYPE.IN_APP: {
      return CHANNEL_TYPE_RESPONSE.IN_APP;
    }
    case CHANNEL_TYPE.HOT_SOS: {
      return CHANNEL_TYPE_RESPONSE.HOT_SOS;
    }
  }
};

export const getCreateChannels = (channels: IChannels): IChannelResponse[] | undefined => {
  return Object.values(channels)
    .filter((channel) => channel !== undefined)
    .map((channel) => ({
      channelType: parseChannelType(channel.type),
      recipients: channel?.recipients?.map(
        ({ recipientId, recipientType }: { recipientId: string; recipientType: TRecipientType }) => ({
          recipientId,
          recipientType,
        })
      ),
    }));
};

export const mapCriticality = (criticality: CRITICALITY): CRITICALITY_RESPONSE => {
  switch (criticality) {
    case CRITICALITY.HIGH: {
      return CRITICALITY_RESPONSE.HIGH;
    }
    case CRITICALITY.LOW: {
      return CRITICALITY_RESPONSE.LOW;
    }
  }
};

export const mapCriticalityResponse = (criticality: CRITICALITY_RESPONSE): CRITICALITY => {
  switch (criticality) {
    case CRITICALITY_RESPONSE.HIGH: {
      return CRITICALITY.HIGH;
    }
    case CRITICALITY_RESPONSE.LOW: {
      return CRITICALITY.LOW;
    }
  }
};

export const mapDevice = (device: DEVICE_RESPONSE_TYPE): DEVICE_TYPE => {
  switch (device) {
    case DEVICE_RESPONSE_TYPE.LEAK: {
      return DEVICE_TYPE.LEAK;
    }
    case DEVICE_RESPONSE_TYPE.PRESSURE: {
      return DEVICE_TYPE.PRESSURE;
    }
    case DEVICE_RESPONSE_TYPE.TEMPERATURE: {
      return DEVICE_TYPE.TEMPERATURE;
    }
    case DEVICE_RESPONSE_TYPE.FLOW_METER:
    case DEVICE_RESPONSE_TYPE.PULSE_METER: {
      return DEVICE_TYPE.PULSE_METER;
    }
    case DEVICE_RESPONSE_TYPE.GATEWAY: {
      return DEVICE_TYPE.GATEWAY;
    }
    case DEVICE_RESPONSE_TYPE.EQUIPMENT: {
      return DEVICE_TYPE.EQUIPMENT;
    }
  }
};
