import { Dialog } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

import { createFullScreenStyles, useStyles, useSx } from './modal-full-screen.styles';

export interface IModalFullScreenProps {
  isFullScreen: boolean;
  open?: boolean;
  floatingMenu?: JSX.Element;
  dataTestId?: string;
}

const ModalFullScreen: FC<PropsWithChildren<IModalFullScreenProps>> = ({
  children,
  floatingMenu,
  isFullScreen,
  open = true,
  dataTestId,
}) => {
  const sx = useSx();
  const { classes } = useStyles();

  return (
    <Dialog
      sx={sx}
      fullScreen
      open={open}
      style={createFullScreenStyles(isFullScreen)}
      PaperProps={{
        'data-testid': dataTestId,
      }}
    >
      {children}
      {floatingMenu && <div className={classes.floatingMenu}>{floatingMenu}</div>}
    </Dialog>
  );
};

export { ModalFullScreen };
