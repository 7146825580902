export const content = {
  EMPTY_DATAPOINT_VALUE: '--',
  ERROR: 'Something went wrong. Please try again later.',
  DASHBOARD_CONNECTION_ERROR: `There is no connection. Check your connection and try to refresh the page.`,
  DASHBOARD_CONNECTION_ERROR_NATIVE: `There is no connection. Check your connection and try again.`,
  ROLE: {
    CLIENT: 'Client',
    MANAGER: 'Manager',
  },
  MODEL: 'Model',
  SANITIZATION_STOPPED: 'Sanitization mode has been stopped.',
};
