import moment from 'moment/moment';

export const getNumberOfBucketsExpandedDrawer = ({
  minSelection,
  maxSelection,
}: {
  minSelection: number;
  maxSelection: number;
}) => {
  const calculateNumberOfBucketsForCustomPeriod = () => {
    const from = moment(minSelection);
    const to = moment(maxSelection);
    const diffHours = to.diff(from, 'hours');

    if (!diffHours) {
      return 60;
    }

    if (diffHours <= 4) {
      return 120;
    }

    if (diffHours <= 8) {
      return 96;
    }

    if (diffHours <= 16) {
      return 192;
    }

    const diffDays = to.add(1, 'second').diff(from, 'days');
    if (diffDays < 7) {
      return 144;
    }
    const diffMonths = to.diff(from, 'months');
    if (diffMonths < 1) {
      return 168;
    }
    if (diffMonths <= 12) {
      return 180;
    }

    return 182.5;
  };
  const buckets = calculateNumberOfBucketsForCustomPeriod();
  return Math.floor(buckets);
};
