import { FunctionComponent } from 'react';

import { TPermission } from '../available-permissions';
import { usePermission } from '../use-permission.hook';

interface IRestrictedProps {
  to: TPermission | TPermission[];
  fallback?: JSX.Element | string;
  children: JSX.Element;
}

export const Restricted: FunctionComponent<IRestrictedProps> = ({ to, fallback, children }): JSX.Element => {
  const allowed = usePermission(to);

  if (allowed) {
    return <>{children}</>;
  }

  return <>{fallback}</>;
};
