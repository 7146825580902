import { TMutationConfig, getHttpClient } from '@marlin/shared/utils/react-query';
import { useMutation } from '@marlin/shared/utils/react-query';

import { paths } from '../infrastructure/api';

export const cancelSanitization = async ({ equipmentId }: { equipmentId: string }) => {
  await getHttpClient().post(paths.CANCEL_SANITIZATION, undefined, { params: { equipmentId } });
};

export const useCancelSanitization = (config: TMutationConfig<typeof cancelSanitization> = {}) => {
  return useMutation({
    mutationFn: cancelSanitization,
    ...config,
  });
};
