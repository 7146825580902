import { z } from 'zod';

export const paginationSchema = z.object({
  pageSize: z.number(),
  page: z.number(),
  totalItems: z.number(),
});

// TODO: Fix this during the refactor of the create device form
export const emptyTextSchema = z
  .string()
  .nullish()
  .transform((text) => (!text ? undefined : text));

export const DeviceTypeSchema = z.union([
  z.literal('PRESSURE'),
  z.literal('TEMPERATURE'),
  z.literal('LEAK'),
  z.literal('PULSE_METER'),
  z.literal('EQUIPMENT'),
]);

export type TDeviceType = z.infer<typeof DeviceTypeSchema>;

export const sortingSchema = z.object({
  direction: z.union([z.literal('Ascending'), z.literal('Descending')]),
  sortBy: z.string(),
});
