import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

interface IStyles {
  noHierarchyDisplayed: boolean;
}

export const useStyles = makeStyles<IStyles>()((theme: MarlinTheme, { noHierarchyDisplayed }) => ({
  title: {
    marginTop: 0,
    marginBottom: 0,
  },
  page: {
    minHeight: `calc(100% - ${theme.typography.pxToRem(64)})`,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.typography.pxToRem(16),
    width: '100%',
  },
  equipment: {
    fontSize: theme.typography.pxToRem(16),
    marginRight: theme.typography.pxToRem(5),
  },
  time: {
    fontSize: theme.typography.pxToRem(14),
  },
  editDeleteButton: {
    width: theme.typography.pxToRem(96),
  },
  actionsWrapper: {
    display: 'flex',
    justifyContent: noHierarchyDisplayed ? 'flex-end' : 'space-between',
    padding: `0 0 ${theme.typography.pxToRem(16)} 0`,
    alignItems: 'flex-start',
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
