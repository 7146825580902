import { environment } from '@marlin/environment';
import Axios, { AxiosInstance } from 'axios';

import { TAddressRequest, TAddressResponse, TCoordinates } from './address.schema';
import { addressValidationApiUrl } from './config';
import { validateVerdict } from './utils';

export class AddressValidationService {
  private httpClient: AxiosInstance;
  public constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }
  public async validateAddress(address: TAddressRequest): Promise<TAddressResponse> {
    const response = await this.httpClient.post<TAddressResponse>(addressValidationApiUrl, address, {
      params: {
        key: environment.module.googleMapsApiKey,
      },
    });
    return response.data;
  }

  public async getAddressCoordinates(address: TAddressRequest): Promise<TCoordinates> {
    let coordinates: TCoordinates = {
      latitude: null,
      longitude: null,
    };

    try {
      const validatedAddress = await this.validateAddress(address);

      if (validateVerdict(validatedAddress.result.verdict)) {
        const location = validatedAddress.result.geocode?.location;
        coordinates = {
          latitude: location?.latitude || coordinates.latitude,
          longitude: location?.longitude || coordinates.longitude,
        };
      }
    } catch (e) {
      coordinates = {
        latitude: null,
        longitude: null,
      };
    }

    return coordinates;
  }
}

export const initValidationService = () => {
  const instance = Axios.create({ timeout: 60000 });
  return new AddressValidationService(instance);
};
