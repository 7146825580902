export const content = {
  EQUIPMENT_HUB_TITLE: 'Equipment',
  EQUIPMENT_HUB_SUBTITLE: 'Use the data table below to manage your equipment.',
  NO_DATA: 'No results found.',
  ERROR: 'Something went wrong. Please try again later.',
  CREATE_NEW_BUTTON: 'Create New',
  SEARCH_LOCATION: 'Search by equipment or location',
  LOCATION_LABEL: 'Location',
  CLEAR_ALL: 'Clear All',
  EQUIPMENT_HEADER_NAME: 'Equipment',
  LOCATION_HEADER_NAME: 'Location',
  GATEWAY_HEADER_NAME: 'Gateway',
  TYPE_HEADER_NAME: 'Type',
  STATUS_HEADER_NAME: 'Status',
  MODE_HEADER_NAME: 'Mode',
  LAST_READING_TIME_HEADER_NAME: 'Last Reading Time',
  MODEL_HEADER_NAME: 'Model',
  SERIAL_NUMBER_HEADER_NAME: 'Serial Number',
  UNKNOWN_OPTION: 'Unknown option',
  EMPTY_LIST: 'No results found.',
  EQUIPMENT_SORTING_NAME: 'Equipment Name',
  LOCATION_SORTING_NAME: 'Location Name',
  SENSOR_NUMBER_SORTING: 'Sensors Number',
  STATUS_LOST_COMMUNICATION: 'Lost Communication',
  STATUS_OPERATIONAL: 'Operational',
  EMPTY_DATAPOINT_VALUE: '--',
  MODE: {
    STARTUP: 'Startup',
    AUTO: 'Auto',
    IDLE: 'Idle',
    MANUAL: 'Manual',
    SANITIZE: 'Sanitize',
    COOLDOWN: 'Cooldown',
    DISABLED: 'Disabled',
    STANDBY: 'StandBy',
    REMOTE: 'Remote',
    FAULT: 'Fault',
    HOME: 'Home',
    AWAY: 'Away',
  },
};
