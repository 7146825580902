import { useAssetLinks } from '@marlin/asset/data-access/asset';
import { useMemo } from 'react';

import { ModalBody } from './modal-body.component';

interface IDeleteModalProps {
  assetId: string;
  canDelete: boolean;
  deleteAssetName: string;
  reason?: 'parent' | 'assets';
}

export const DeleteLocationModalBody = ({ assetId, canDelete, deleteAssetName, reason }: IDeleteModalProps) => {
  const assetLinksQuery = useAssetLinks({ assetId: assetId ?? '', enabled: !!assetId });

  const isLoading = useMemo(() => {
    return assetLinksQuery.isLoading || assetLinksQuery.isInitialLoading;
  }, [assetLinksQuery.isLoading, assetLinksQuery.isInitialLoading]);

  return (
    <ModalBody
      deleteAssetName={deleteAssetName}
      deleteAssetId={assetId}
      canDelete={canDelete}
      isLoading={isLoading}
      assetLinks={assetLinksQuery.data}
      reason={reason}
    />
  );
};
