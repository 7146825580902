import { MarlinTheme } from '@marlin/shared/theme';
import { FormField, Input, Select } from '@marlin/shared/ui-form-common';
// TODO: move useAssets to assets domain
// eslint-disable-next-line @nx/enforce-module-boundaries,ordered-imports/ordered-imports
import { TAssets } from '@marlin/system-map/data-access/system-map';
import AddIcon from '@mui/icons-material/Add';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormHelperText,
  IconButton,
  Typography,
} from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';
import { useRemoveAddressModal } from '../../hooks/modals/use-remove-address-modal.hook';
import { useBrandAndModelOptionsHook } from '../../hooks/use-brand-and-model-options.hook';
import { useAddressOptions } from '../../hooks/use-upsert-form-options.hook';
import { TUpsertConnectionFormSchemaType } from './upsert-connection-form.schema';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  select: {
    width: '100%',
    maxWidth: theme.typography.pxToRem(288),
    '& span[class*="name"]': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      overflowWrap: 'unset',
    },
  },
  icon: {
    margin: theme.typography.pxToRem(8),
  },
  childAccordion: {
    boxShadow: 'unset',
    margin: 0,
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  childAccordionSummaryContent: {
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  childAccordionSummary: {
    borderBottom: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
    padding: 0,
  },
  childAccordionTitle: {
    fontWeight: theme.typography.fontWeightBold,
  },
  childAccordionDetails: {
    padding: `${theme.typography.pxToRem(32)} 0 0`,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.typography.pxToRem(32),
  },
  twoColumns: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.typography.pxToRem(16),
  },
  selectFullWidth: {
    width: '100%',
  },
  helper: {
    paddingLeft: theme.typography.pxToRem(12),
  },
  buttonsWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: theme.typography.pxToRem(16),
    padding: 0,
  },
}));

interface IConnectionAddressesProps {
  connectionIdx: number;
  validateAllFields: () => Promise<void>;
  locations: TAssets;
}

export const ConnectionAddresses = ({ connectionIdx, validateAllFields, locations }: IConnectionAddressesProps) => {
  const { classes } = useStyles();
  const { brandOptions, modelOptions } = useBrandAndModelOptionsHook();
  const { getAddressesOptions, totalAddresses } = useAddressOptions();

  const {
    formState: { errors },
    watch,
    setValue,
    clearErrors,
    control,
  } = useFormContext<TUpsertConnectionFormSchemaType>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `connections.${connectionIdx}.addresses`,
  });

  const baudRateField = watch(`connections.${connectionIdx}.baudRate`);
  const addressesField = watch(`connections.${connectionIdx}.addresses`);

  const disableAddAddressButton = addressesField.length >= totalAddresses;

  const { removeAddress } = useRemoveAddressModal({ onRemove: (index: number) => remove(index) });

  return (
    <>
      {fields.map((field, index) => (
        <Accordion defaultExpanded className={classes.childAccordion} key={field.id}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            classes={{ root: classes.childAccordionSummary, content: classes.childAccordionSummaryContent }}
          >
            <Typography variant="body1" className={classes.childAccordionTitle}>
              {content.FORM.ADDRESS_TITLE(addressesField[index]?.modbusAddress || index + 1)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.childAccordionDetails}>
            <FormField<TUpsertConnectionFormSchemaType>
              fieldName={`connections.${connectionIdx}.addresses.${index}.modbusAddress`}
            >
              {(props) => (
                <div>
                  <Select
                    {...props}
                    value={props.value === 0 ? '' : props.value}
                    onChange={(e) => {
                      if (
                        errors.connections?.[connectionIdx]?.addresses?.[index]?.modbusAddress?.type === 'addressError'
                      ) {
                        clearErrors(`connections.${connectionIdx}.addresses.${index}.modbusAddress`);
                      }
                      props.onChange?.(e);
                    }}
                    required
                    prefix={`addresses.${index}.modbusAddress`}
                    label={content.FORM.MODBUS_ADDRESS_FIELD}
                    data={getAddressesOptions(addressesField.map(({ modbusAddress }) => modbusAddress))}
                    className={classes.select}
                    error={errors.connections?.[connectionIdx]?.addresses?.[index]?.modbusAddress}
                    disabled={!baudRateField}
                    requiredErrorMessageOnBlur={content.FORM.REQUIRED}
                  />
                  <IconButton onClick={() => removeAddress(index)}>
                    <DeleteRoundedIcon className={classes.icon} />
                  </IconButton>
                  {!baudRateField && (
                    <FormHelperText className={classes.helper}>
                      {content.FORM.COMPLETE_CONNECTION_FIELDS_HELPER}
                    </FormHelperText>
                  )}
                </div>
              )}
            </FormField>
            {!!addressesField[index].modbusAddress && (
              <>
                <div className={classes.twoColumns}>
                  <FormField<TUpsertConnectionFormSchemaType>
                    fieldName={`connections.${connectionIdx}.addresses.${index}.equipmentBrand`}
                  >
                    {(props) => (
                      <Select
                        {...props}
                        required
                        prefix={`addresses.${index}.equipmentBrand`}
                        label={content.FORM.EQUIPMENT_BRAND_FIELD}
                        data={brandOptions}
                        onChange={(e) => {
                          if (
                            errors.connections?.[connectionIdx]?.addresses?.[index]?.modbusAddress?.type ===
                            'addressError'
                          ) {
                            clearErrors(`connections.${connectionIdx}.addresses.${index}.modbusAddress`);
                          }
                          if (props.onChange) {
                            setValue(`connections.${connectionIdx}.addresses.${index}.equipmentModel`, '');
                            props.onChange(e);
                          }
                        }}
                        className={classes.select}
                        error={errors.connections?.[connectionIdx]?.addresses?.[index]?.equipmentBrand}
                        requiredErrorMessageOnBlur={content.FORM.REQUIRED}
                      />
                    )}
                  </FormField>
                  <FormField<TUpsertConnectionFormSchemaType>
                    fieldName={`connections.${connectionIdx}.addresses.${index}.equipmentModel`}
                  >
                    {(props) => (
                      <Select
                        {...props}
                        required
                        prefix={`addresses.${index}.equipmentModel`}
                        label={content.FORM.EQUIPMENT_MODEL_FIELD}
                        onChange={(e) => {
                          if (
                            errors.connections?.[connectionIdx]?.addresses?.[index]?.modbusAddress?.type ===
                            'addressError'
                          ) {
                            clearErrors(`connections.${connectionIdx}.addresses.${index}.modbusAddress`);
                          }
                          props.onChange?.(e);
                        }}
                        data={modelOptions.filter((model) => model.brandId === addressesField[index].equipmentBrand)}
                        disabled={!addressesField[index].equipmentBrand}
                        className={classes.select}
                        error={errors.connections?.[connectionIdx]?.addresses?.[index]?.equipmentModel}
                        requiredErrorMessageOnBlur={content.FORM.REQUIRED}
                      />
                    )}
                  </FormField>
                </div>
                <FormField<TUpsertConnectionFormSchemaType>
                  fieldName={`connections.${connectionIdx}.addresses.${index}.name`}
                >
                  {(props) => (
                    <Input
                      {...props}
                      label={content.FORM.NAME_FIELD}
                      onChange={(e) => {
                        if (
                          errors.connections?.[connectionIdx]?.addresses?.[index]?.modbusAddress?.type ===
                          'addressError'
                        ) {
                          clearErrors(`connections.${connectionIdx}.addresses.${index}.modbusAddress`);
                        }
                        props.onChange?.(e);
                        validateAllFields();
                      }}
                      required
                      testId={`addresses.${index}.name`}
                      error={errors.connections?.[connectionIdx]?.addresses?.[index]?.name ?? props.error}
                    />
                  )}
                </FormField>
                <FormField<TUpsertConnectionFormSchemaType>
                  fieldName={`connections.${connectionIdx}.addresses.${index}.locationId`}
                >
                  {(props) => (
                    <Select
                      {...props}
                      required
                      prefix={`addresses.${index}.locationId`}
                      label={content.FORM.LOCATION_FIELD}
                      onChange={(e) => {
                        if (
                          errors.connections?.[connectionIdx]?.addresses?.[index]?.modbusAddress?.type ===
                          'addressError'
                        ) {
                          clearErrors(`connections.${connectionIdx}.addresses.${index}.modbusAddress`);
                        }
                        props.onChange?.(e);
                      }}
                      data={locations}
                      className={classes.selectFullWidth}
                      error={errors.connections?.[connectionIdx]?.addresses?.[index]?.locationId}
                      requiredErrorMessageOnBlur={content.FORM.REQUIRED}
                    />
                  )}
                </FormField>
              </>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
      <div className={classes.buttonsWrapper}>
        <Button
          data-testid="add-address-button"
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={() => {
            append({
              modbusAddress: 0,
              equipmentBrand: '',
              equipmentModel: '',
              name: '',
              locationId: '',
            });
          }}
          disabled={disableAddAddressButton}
        >
          {content.FORM.ADD_ADDRESS}
        </Button>
      </div>
    </>
  );
};
