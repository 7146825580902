import { useMemo } from 'react';

import { useDeviceDetailsContext } from '../../context/device-details.context';

export interface IHeader {
  deviceName: string;
  batteryLevel: number | null;
  isActive: boolean;
}

const maxBatteryLevel = 100;

export const useHeader = (): IHeader => {
  const deviceDetails = useDeviceDetailsContext();

  const deviceName = useMemo(() => deviceDetails?.name || '', [deviceDetails?.name]);

  const batteryLevelDatapoint = useMemo(() => {
    return deviceDetails.lastReadingValues.find((value) => value.name === 'BatteryLevel');
  }, [deviceDetails.lastReadingValues]);

  const batteryLevel = useMemo(() => {
    if (!batteryLevelDatapoint) {
      return null;
    }

    const batteryLevel = parseInt(batteryLevelDatapoint.value);

    if (batteryLevel > maxBatteryLevel) {
      return maxBatteryLevel;
    }

    return batteryLevel;
  }, [batteryLevelDatapoint]);

  const isActive = useMemo(() => {
    const eightHoursInMilliseconds = 8 * 60 * 60 * 1000;
    const eightHoursAgo = new Date(Date.now() - eightHoursInMilliseconds);
    if (!batteryLevelDatapoint) {
      return false;
    }
    const mostRecentReadingDatetime = new Date(batteryLevelDatapoint.lastReadingTime ?? new Date());
    return mostRecentReadingDatetime > eightHoursAgo;
  }, [batteryLevelDatapoint]);

  return {
    deviceName,
    batteryLevel,
    isActive,
  };
};
