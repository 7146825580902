import { DeviceSearchWrapper, TCreateAutomation } from '@marlin/alert/data-access/automated-action';
import { TAutomationDevice } from '@marlin/alert/data-access/automated-action';
import { MarlinTheme } from '@marlin/shared/theme';
import { InfiniteAutocompleteControl } from '@marlin/shared/ui-form-common';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import { Grid, IconButton } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { useContent } from '../../../context';
import { DEVICE_TYPE, IDevice, ILocation } from '../../../types';
import { Filters } from './filters.component';
import { Metadata } from './metadata.component';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    alignItems: 'center',
  },

  row: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
  },

  rowBlock: {
    display: 'block',
    width: '100%',
  },

  filterButton: {
    marginTop: theme.typography.pxToRem(8),
  },

  select: {
    flex: 1,
    width: '100%',
  },
}));

interface IDeviceFilters {
  locations: ILocation[];
  disableDeviceSelection: boolean;
  onDeviceChange: (device: IDevice) => void;
}

export function DeviceSelect({ locations, disableDeviceSelection, onDeviceChange }: IDeviceFilters) {
  const { classes } = useStyles();
  const content = useContent();
  const { setValue, resetField, watch } = useFormContext<TCreateAutomation>();

  const [openFilters, setOpenFilters] = useState(false);
  const [locationName, setLocationName] = useState<string | null>(null);

  const [selectedLocation, setSelectedLocation] = useState<ILocation | null>(null);

  const device = watch('device');
  const deviceType = watch('deviceType');

  const resetConditionFields = useCallback(() => {
    resetField('conditions.deadbandMin.value');
    resetField('conditions.deadbandMin.enabled');

    resetField('conditions.deadbandMax.value');
    resetField('conditions.deadbandMax.enabled');

    resetField('conditions.thresholdMax.value');
    resetField('conditions.thresholdMax.enabled');

    resetField('conditions.thresholdMin.value');
    resetField('conditions.thresholdMin.enabled');
  }, [resetField]);

  const setMetadata = useCallback(
    (newDevice?: IDevice) => {
      if (newDevice) {
        onDeviceChange(newDevice);
        setValue('manufacturerId', newDevice.manufacturerId);
        // TODO: add additional layer in feature lvl which will merge data from multiple sources and add all missing properties
        const currentLocation = locations.find((location) => location.id === newDevice.locationId);
        setLocationName(currentLocation?.name ?? null);
      } else {
        setLocationName(null);
      }
    },
    [locations, onDeviceChange, setValue]
  );

  useEffect(() => {
    if (device) {
      resetConditionFields();
      setMetadata({
        ...device,
        name: device.name ?? '',
        deviceType: (device.deviceType as DEVICE_TYPE) ?? DEVICE_TYPE.UNKNOWN,
        manufacturerId: device.manufacturerId ?? '',
      });
    } else {
      setMetadata();
    }
  }, [device, resetConditionFields, setMetadata]);

  return (
    <Grid className={classes.container} container spacing={1}>
      {openFilters && (
        <Filters
          locations={locations}
          onFiltersClose={setOpenFilters}
          currentLocation={selectedLocation}
          onLocationChange={setSelectedLocation}
          clearDeviceType={() => resetField('deviceType')}
        />
      )}
      <Grid item className={classes.row}>
        <Metadata locationName={locationName} />
      </Grid>
      <Grid item className={classes.row}>
        <div className={classes.rowBlock}>
          <DeviceSearchWrapper<Required<TAutomationDevice>>
            enableSearch={false}
            locations={selectedLocation ? [selectedLocation?.id] : undefined}
            deviceTypes={deviceType ? [deviceType] : undefined}
          >
            {(props) => {
              return (
                <InfiniteAutocompleteControl<TCreateAutomation, Required<TAutomationDevice>>
                  fieldName="device"
                  testId="select-device"
                  className={classes.select}
                  label={content.SENSOR.LABEL}
                  multiple={false}
                  required
                  disabled={disableDeviceSelection}
                  {...props}
                />
              );
            }}
          </DeviceSearchWrapper>
        </div>
        {!openFilters && (
          <IconButton
            className={classes.filterButton}
            disableRipple
            disabled={disableDeviceSelection}
            color="primary"
            onClick={() => setOpenFilters(true)}
          >
            <FilterAltRoundedIcon />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
}
