import { TAssetLink, TFlowLink } from '@marlin/asset/data-access/asset';

export const getAttachments = (data?: TAssetLink): TFlowLink[] => {
  if (!data || !data.links || !data.links.length) {
    return [];
  }

  return data.links.filter(
    (link: TFlowLink) => link.inletNodeType === 'ATTACHMENT' || link.outletNodeType === 'ATTACHMENT'
  );
};

export const useHasAttachments = (data?: TAssetLink): boolean => {
  return !!getAttachments(data).length;
};
