import { MarlinTheme } from '@marlin/shared/theme';
import { useMediaQuery, useTheme } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useLayoutBreakpoints } from '../../use-breakpoints';
import { DesktopSideNavLayout } from './desktop-side-nav-layout.component';
import { MobileSideNavLayout } from './mobile-side-nav-layout.component';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  appContainer: {
    display: 'flex',
  },

  appContent: {
    minHeight: '100vh',
    height: '100vh',
    overflowY: 'scroll',
    flexGrow: 1,
    backgroundColor: theme.palette.background.secondary,
  },

  main: {
    overflowY: 'hidden',
    height: '100%',
  },
}));

export const SideNavLayout: FC<PropsWithChildren> = ({ children }) => {
  useLayoutBreakpoints();
  const theme = useTheme();
  const isMobileNavbar = useMediaQuery(theme.breakpoints.down('lg'));

  return isMobileNavbar ? (
    <MobileSideNavLayout> {children} </MobileSideNavLayout>
  ) : (
    <DesktopSideNavLayout> {children} </DesktopSideNavLayout>
  );
};
