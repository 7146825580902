import { routes } from '@marlin/shared/utils-routes';

interface IFeatureNavigation {
  getLocationDetailsPageLink: (id: string) => string;
  getEquipmentDetailsPageLink: (id: string) => string;
  getDeviceEditPageLink: (id: string) => string;
  getListPageQueryLink: (sensorId: string, isSystemRule?: boolean) => string;
  getCreatePageQueryLink: (sensorId: string) => string;
  getGatewayPageLink: (gatewayId: string) => string;
}

export const useNavigation = (): IFeatureNavigation => {
  return {
    getLocationDetailsPageLink: (id) => routes.locations.details.url(id),
    getEquipmentDetailsPageLink: (id) => routes.equipments.details.url(id),
    getDeviceEditPageLink: (id) => routes.sensors.update.url(id),
    getListPageQueryLink: (sensorId: string, isSystemRule?: boolean) =>
      isSystemRule ? routes.automations.health.root : routes.automations.list.query(sensorId),
    getCreatePageQueryLink: (sensorId: string) => routes.automations.create.query(sensorId),
    getGatewayPageLink: (gatewayId: string) => routes.gateway.list.withId(gatewayId),
  };
};
