import { AUTOMATION_RULE_TYPE } from '@marlin/alert/data-access/automated-action';
import { unitsSchema } from '@marlin/shared/ui-form';
import isNil from 'lodash/isNil';
import { z } from 'zod';

import { content } from '../content/validation-content';

const minDurationDays = 1;
const minDurationHours = 1;
const minDurationMinutes = 5;

const maxDurationDays = 7;
const maxDurationHours = maxDurationDays * 24;
const maxDurationMinutes = maxDurationHours * 60;

const minDurationError = {
  code: z.ZodIssueCode.custom,
  message: content.MIN_DURATION,
  path: ['duration.value'],
};

const maxDurationError = {
  code: z.ZodIssueCode.custom,
  message: content.MAX_DURATION,
  path: ['duration.value'],
};

const requiredValueError = {
  code: z.ZodIssueCode.custom,
  message: content.REQUIRED,
  path: ['duration.value'],
};

const requiredUnitError = {
  code: z.ZodIssueCode.custom,
  message: content.REQUIRED,
  path: ['duration.unit'],
};

const durationRuleTypes: AUTOMATION_RULE_TYPE[] = [
  AUTOMATION_RULE_TYPE.DEVICE_LOST_COMMUNICATION,
  AUTOMATION_RULE_TYPE.GATEWAY_LOST_COMMUNICATION,
];

export const durationSchema = z.object({
  value: z.number().nullable(),
  unit: unitsSchema.nullable(),
});

export type TDurationType = z.infer<typeof durationSchema>;

export const isDurationRuleType = (ruleType?: AUTOMATION_RULE_TYPE | null) => {
  if (!ruleType) {
    return false;
  }

  return durationRuleTypes.includes(ruleType);
};

const isAboveMinDuration = (duration: TDurationType) => {
  if (duration?.unit === 'DAYS' && !isNil(duration?.value) && duration?.value < minDurationDays) {
    return false;
  }

  if (duration?.unit === 'HOURS' && !isNil(duration?.value) && duration?.value < minDurationHours) {
    return false;
  }

  if (duration?.unit === 'MINUTES' && !isNil(duration?.value) && duration?.value < minDurationMinutes) {
    return false;
  }

  return true;
};

const isBelowMaxDuration = (duration: TDurationType) => {
  if (duration?.unit === 'DAYS' && duration?.value && duration?.value > maxDurationDays) {
    return false;
  }

  if (duration?.unit === 'HOURS' && duration?.value && duration?.value > maxDurationHours) {
    return false;
  }

  if (duration?.unit === 'MINUTES' && duration?.value && duration?.value > maxDurationMinutes) {
    return false;
  }

  return true;
};

interface IRefineDurationRulesProps {
  addIssue: (arg: z.IssueData) => void;
  duration?: TDurationType | null;
}

export const refineDurationRules = ({ addIssue, duration }: IRefineDurationRulesProps) => {
  if (duration && !isAboveMinDuration(duration)) {
    addIssue(minDurationError);
  }

  if (duration && !isBelowMaxDuration(duration)) {
    addIssue(maxDurationError);
  }

  if (isNil(duration?.value)) {
    addIssue(requiredValueError);
  }

  if (isNil(duration?.unit)) {
    addIssue(requiredUnitError);
  }
};
