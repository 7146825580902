import { BehaviorSubject } from 'rxjs';

import { TPortfolio } from '../schema/portfolio.schema';

export class PortfolioService {
  private portfolio: BehaviorSubject<TPortfolio | null> = new BehaviorSubject<TPortfolio | null>(null);

  public getPortfolio = (): TPortfolio | null => this.portfolio.value;

  public setPortfolio = (portfolio: TPortfolio): void => {
    this.portfolio.next(portfolio);
  };

  public setPortfolioId = (portfolioId: string) => {
    const currentPortfolio = this.portfolio.value;
    if (currentPortfolio) {
      this.portfolio.next({
        ...currentPortfolio,
        id: portfolioId,
      });
    }
  };
}

export const portfolioService = new PortfolioService();
