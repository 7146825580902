import { MarlinTheme } from '@marlin/shared/theme';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  description: {
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: theme.typography.pxToRem(14),
    width: '96%',
    boxSizing: 'content-box',
    '-webkit-box-sizing': ' content-box',
    '-moz-box-sizing': 'content-box',
  },
  text: {
    fontFamily: 'Inter',
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.text.primary,
  },
}));

interface IDescriptionComponentProps {
  description: string | null;
  isMobile?: boolean;
}

export const DescriptionComponent = ({ description, isMobile = false }: IDescriptionComponentProps) => {
  const { classes, cx } = useStyles();

  if (isMobile) {
    return (
      <Typography className={classes.text} data-testid="description-component">
        {description}
      </Typography>
    );
  }
  return (
    <Tooltip placement="top" text={description || ''}>
      <Typography className={cx(classes.description, classes.text)} data-testid="description-component">
        {description}
      </Typography>
    </Tooltip>
  );
};
