import { ALERT_STATUS_FILTER, IAlert } from '@marlin/alert/data-access/alert-action';
import { MarlinTheme } from '@marlin/shared/theme';
import { CRITICALITY } from '@marlin/shared/utils/zod';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  iconCriticality: {
    width: theme.typography.pxToRem(20),
    height: theme.typography.pxToRem(20),
  },
}));

export const IconSwitcher = ({ alert }: { alert: IAlert | undefined }) => {
  const { classes } = useStyles();

  if (alert?.status === ALERT_STATUS_FILTER.RESOLVED) {
    return <CheckCircleIcon color="success" />;
  }

  if (alert?.ruleCategory === 'Information') {
    return <InfoRoundedIcon className={classes.iconCriticality} color="info" />;
  }

  if (alert?.criticality === CRITICALITY.HIGH) {
    return <WarningRoundedIcon className={classes.iconCriticality} color="error" />;
  }

  if (alert?.criticality === CRITICALITY.LOW) {
    return <WarningRoundedIcon className={classes.iconCriticality} color="warning" />;
  }

  return null;
};
