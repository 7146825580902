import { MarlinTheme } from '@marlin/shared/theme';
import { CriticalityControl } from '@marlin/shared/ui-criticality';
import { Paper } from '@marlin/shared/ui-page';
import { defaultDateTime, formatDate } from '@marlin/shared/utils-common-date';
import { Grid } from '@mui/material';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';
import { IAlert } from '../types';
import { DescriptionComponent } from './helper-components/description.component';
import { HyperlinkComponent } from './helper-components/hyperlink.component';
import { CheckIcon } from './sections/check-icon.component';
import { MetadataSection } from './sections/metadata-section.component';
import { RecipientsSection } from './sections/recipients-section.component';

interface IAlertCurrent {
  alert: IAlert;
  displayIconColumn: boolean;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  label: {
    fontSize: theme.typography.pxToRem(12),
    marginTop: theme.typography.pxToRem(2),
    marginBottom: theme.typography.pxToRem(22),
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
  },

  criticality: {
    marginTop: theme.typography.pxToRem(22),
  },

  value: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
  },

  section: {
    paddingLeft: theme.typography.pxToRem(24),
    borderLeft: `${theme.typography.pxToRem(1)} solid ${theme.palette.systemMap.device}`,
  },

  descriptionSection: {
    paddingTop: theme.typography.pxToRem(56),
    paddingLeft: theme.typography.pxToRem(24),
    width: theme.typography.pxToRem(336),
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.typography.pxToRem(8),
    borderLeft: `${theme.typography.pxToRem(1)} solid ${theme.palette.systemMap.device}`,
  },

  paper: {
    borderRadius: 0,
    borderBottom: 'none',
  },
}));

export function AlertResolvedCard({ alert, displayIconColumn }: IAlertCurrent) {
  const { classes } = useStyles();
  const checkIcon = displayIconColumn ? <CheckIcon /> : undefined;

  const isEquipmentAlert = alert.errorType === 'DEVICE_ALERT' || alert.errorType === 'DEVICE_ERROR';

  const startTime = useMemo(() => {
    return formatDate(alert.startTime, defaultDateTime);
  }, [alert.startTime]);

  const resolveTime = useMemo(() => {
    return formatDate(alert.resolveTime, defaultDateTime);
  }, [alert.resolveTime]);

  return (
    <Grid item xs={12}>
      <Paper testId="alert-card" className={classes.paper}>
        <Grid container>
          <Grid item xs={6}>
            <>
              <RecipientsSection isEquipmentAlert={isEquipmentAlert} alert={alert} icon={checkIcon} />
              <MetadataSection isEquipmentAlert={isEquipmentAlert} alert={alert} />
            </>
          </Grid>
          {isEquipmentAlert ? (
            <>
              <Grid item xs={2}>
                <div className={classes.label}>
                  {content.CARD.CURRENT.LABEL.START_TIME}: <span className={classes.value}>{startTime}</span>
                </div>
                <div className={classes.label}>
                  {content.CARD.CURRENT.LABEL.RESOLVED_TIME}: <span className={classes.value}>{resolveTime}</span>
                </div>
                <div className={classes.criticality}>
                  <CriticalityControl severity={alert.criticality} />
                </div>
              </Grid>
              <Grid item xs={4} className={classes.descriptionSection}>
                <HyperlinkComponent link={alert?.hyperlink} />
                <DescriptionComponent description={alert.description} />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={2}></Grid>
              <Grid item xs={4} className={classes.section}>
                <div className={classes.label}>
                  {content.CARD.CURRENT.LABEL.START_TIME}: <span className={classes.value}>{startTime}</span>
                </div>
                <div className={classes.label}>
                  {content.CARD.CURRENT.LABEL.RESOLVED_TIME}: <span className={classes.value}>{resolveTime}</span>
                </div>
                <div className={classes.criticality}>
                  <CriticalityControl severity={alert.criticality} />
                </div>
              </Grid>
            </>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
}
