import { MarlinTheme } from '@marlin/shared/theme';
import { TUnitOfMeasure } from '@marlin/shared/utils-format-reading';
import { getErrorTextWithUnit } from '@marlin/shared/utils/format-alert';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { ERROR_TYPES } from '../../types';

type TDeviceType = 'TEMPERATURE' | 'PRESSURE' | 'PULSE_METER';

interface IUnitSection<T extends TDeviceType> {
  alert: {
    device: {
      type: T;
    };
    errorType: ERROR_TYPES;
    absoluteDifferenceValue: number | null;
    readingValue: number;
    formattedReading: string | null;
    uoM: TUnitOfMeasure | null;
  };
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  temperature: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(24),
    minWidth: theme.typography.pxToRem(130),
    marginTop: theme.typography.pxToRem(5),
    marginRight: theme.typography.pxToRem(8),
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(20),
      minWidth: 0,
    },
  },

  icon: {
    '& > svg': {
      height: theme.typography.pxToRem(15),
      marginTop: theme.typography.pxToRem(2),
      color: theme.palette.error.main,
    },
  },

  value: {
    fontSize: theme.typography.pxToRem(13),
    display: 'flex',
    alignItems: 'flex-start',
    [theme.breakpoints.down('md')]: {
      alignItems: 'center',
    },
  },
}));

export const UnitSection = <T extends TDeviceType>({ alert }: IUnitSection<T>) => {
  const { classes } = useStyles();

  const absoluteDiffText = useMemo(
    () => getErrorTextWithUnit(alert.errorType, alert.absoluteDifferenceValue?.toString() || null, alert.uoM),
    [alert.errorType, alert.absoluteDifferenceValue, alert.uoM]
  );
  return (
    <>
      <div className={classes.temperature} data-testid="reading-unit">
        {alert.formattedReading}
      </div>
      <div className={classes.value} data-testid="reading-diff">
        <span className={classes.icon}>
          <ErrorRoundedIcon />
        </span>
        {absoluteDiffText}
      </div>
    </>
  );
};
