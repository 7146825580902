import { TMutationConfig, getHttpClient, queryClient } from '@marlin/shared/utils/react-query';
import { UseMutationOptions, useMutation } from '@marlin/shared/utils/react-query';

import { paths } from '../infrastructure/api';
import { ILocation } from '../location.model';

export const deleteLocation = ({ locationId }: { locationId: string }) => {
  return getHttpClient().delete(paths.LOCATION, { params: { locationId } });
};

type TUseDeleteLocationOptions = {
  config?: TMutationConfig<typeof deleteLocation>;
  onSuccess?: () => void;
  onError?: () => void;
};

export const useDeleteLocation = ({ config, onError, onSuccess }: TUseDeleteLocationOptions = {}) => {
  return useMutation<unknown, unknown, { locationId: string }>({
    onMutate: async (deletedLocation: { locationId: string }) => {
      await queryClient.cancelQueries({ queryKey: ['locations'] });

      const previousLocations = queryClient.getQueryData<ILocation[]>(['locations']);

      queryClient.setQueryData(
        ['locations'],
        previousLocations?.filter((location: ILocation) => location.id !== deletedLocation.locationId)
      );

      return { previousLocations };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (_: any, __: { locationId: string }, context: any) => {
      if (context?.previousLocations) {
        queryClient.setQueryData(['locations'], context.previousLocations);
      }
      onError && onError();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['locations'] });
      onSuccess && onSuccess();
    },
    ...config,
    mutationFn: deleteLocation,
  } as UseMutationOptions<unknown, unknown, { locationId: string }>);
};
