import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import { useContext } from 'react';

import { ModalMedium } from './modal-medium/modal-medium.component';

export const Modal = () => {
  const {
    modalState: { visible, title, body, footer, presentationClassName, modalClassName },
    modalDispatch,
  } = useContext(ModalContext);

  const handleClose = () => {
    modalDispatch({
      type: MODAL_ACTION_TYPE.DISMISS,
    });
  };

  if (!visible) {
    return null;
  }

  return (
    <ModalMedium
      onClose={handleClose}
      title={title}
      body={body}
      footer={footer}
      presentationClassName={presentationClassName}
      modalClassName={modalClassName}
    />
  );
};
