import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  section: {
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },

  sectionTitle: {
    fontWeight: theme.typography.fontWeightBold,
    marginRight: theme.typography.pxToRem(75),
    marginBottom: theme.typography.pxToRem(16),
  },
}));
