import { useMultiChartsStore } from '@marlin/asset/ui/charts';
import { MarlinTheme } from '@marlin/shared/theme';
import { TextField } from '@mui/material';
import debounce from 'lodash/debounce';
import { useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../content';
import { useSelectedSensorsStore } from '../../context/selected-sensors.context';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    width: '100%',
    margin: `${theme.typography.pxToRem(32)} 0 ${theme.typography.pxToRem(16)}`,
  },
}));

export const ChartNameInput = () => {
  const { classes } = useStyles();
  const [addChartParams, setStore] = useMultiChartsStore((store) => store.addChart);
  const [selectedSensors] = useSelectedSensorsStore((store) => store);
  const [touched, setTouched] = useState(false);

  const names = useMemo(
    () => Object.values(selectedSensors).map((chart) => chart.chartName.toLowerCase()),
    [selectedSensors]
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!touched) {
      setTouched(true);
    }

    if (!event.target.value.trim()) {
      setStore({ addChart: { ...addChartParams, chartName: '' } });

      return;
    }

    setStore({ addChart: { ...addChartParams, chartName: event.target.value } });
  };

  const isRequiredError = !addChartParams.chartName && touched;
  const isUniqueNameError = names.includes(addChartParams.chartName.toLowerCase());

  const isError = isRequiredError || isUniqueNameError;

  const getHelperText = () => {
    if (isRequiredError) {
      return content.EDIT_MODAL.CHART_NAME_ERROR;
    }

    if (isUniqueNameError) {
      return content.EDIT_MODAL.CHART_NAME_UNIQUE;
    }

    return '';
  };

  return (
    <TextField
      className={classes.wrapper}
      label={content.CHART_NAME}
      data-testid="chart-name-field"
      error={isError}
      helperText={getHelperText()}
      required
      name="chartName"
      onChange={debounce(handleChange, 200)}
      InputProps={{
        inputProps: {
          maxLength: 80,
        },
      }}
    />
  );
};
