import { PERMISSIONS, usePermission } from '@marlin/shared/utils-permission';
import { routes } from '@marlin/shared/utils-routes';
import { useCallback, useMemo } from 'react';

import { useRouter } from './use-router.hook';

interface IUseLocationsHubContextMenu {
  allowDelete: boolean;
  allowEdit: boolean;
  editLocation: (locationId: string) => void;
}

export const useLocationsHubContextMenu = (): IUseLocationsHubContextMenu => {
  const { goTo } = useRouter();

  const allowDelete = usePermission(PERMISSIONS.DELETE_LOCATION);
  const allowEdit = usePermission(PERMISSIONS.EDIT_LOCATION);

  const editLocation = useCallback(
    (locationId: string) => {
      const editLocationUrl = routes.locations.update.url(locationId);
      goTo(editLocationUrl);
    },
    [goTo]
  );

  return useMemo(
    () => ({
      allowDelete,
      allowEdit,
      editLocation,
    }),
    [allowDelete, allowEdit, editLocation]
  );
};
