import { UseMutationResult, queryClient, useMutation } from '@marlin/shared/utils/react-query';

import { deleteUserFromListCache } from '../cache/delete-user-from-list-cache';
import { updatePreviousUserListCache } from '../cache/update-previous-user-list-cache';
import { deleteUser } from '../infrastructure/users';
import { QUERY_KEY } from '../query-key.enum';
import { IUserList, IUserListFilters } from '../user.model';

interface IDeleteUser {
  previousSearchableUsers: IUserList | undefined;
}

export const useDeleteUser = (
  filterParams?: IUserListFilters
): UseMutationResult<unknown, unknown, string, IDeleteUser> => {
  return useMutation<unknown, unknown, string, IDeleteUser>({
    onMutate: async (id: string) => {
      await queryClient.cancelQueries([QUERY_KEY.USER]);

      const previousSearchableUsers = deleteUserFromListCache(id, filterParams);

      return { previousSearchableUsers };
    },
    onError: (_: unknown, _id: string, context: IDeleteUser | undefined) => {
      updatePreviousUserListCache(context?.previousSearchableUsers, filterParams);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEY.USER]);
    },
    mutationFn: deleteUser,
  });
};
