import { createDateString } from '@marlin/shared/utils-common-date';

import { CRITICALITY, CRITICALITY_RESPONSE, DEVICE_TYPE, IPinDevice, IPinDeviceResponse } from '../../pin-device.model';

const mapAlertCriticality = (criticality: CRITICALITY_RESPONSE): CRITICALITY => {
  switch (criticality) {
    case CRITICALITY_RESPONSE.HIGH: {
      return CRITICALITY.HIGH;
    }
    case CRITICALITY_RESPONSE.LOW: {
      return CRITICALITY.LOW;
    }
  }
};

const mapDeviceType = (deviceType: string): DEVICE_TYPE => {
  switch (deviceType) {
    case 'Temperature': {
      return DEVICE_TYPE.TEMPERATURE;
    }
    case 'Pressure': {
      return DEVICE_TYPE.PRESSURE;
    }
    case 'Leak': {
      return DEVICE_TYPE.LEAK;
    }
    case 'FlowMeter':
    case 'PulseMeter': {
      return DEVICE_TYPE.PULSE_METER;
    }
    default: {
      return DEVICE_TYPE.UNKNOWN;
    }
  }
};

export const createDevicePinDto = (data: IPinDeviceResponse): IPinDevice => {
  return {
    id: data.id,
    order: data.order,
    owner: {
      id: data.ownerId,
    },
    device: {
      id: data.deviceId,
      name: data.deviceName,
      type: mapDeviceType(data.deviceType),
      lastReading: {
        value: data.lastReadingValue,
        date: createDateString(data.lastReadingTime),
        unitOfMeasure: data.unitOfMeasure,
      },
    },
    location: {
      id: data.locationId,
      name: data.locationName,
    },
    equipment: {
      id: data.equipmentId,
      name: data.equipmentName,
    },
    alert: {
      criticality: mapAlertCriticality(data.alertCriticality),
    },
  };
};
