import { content } from '../content';
import { GatewayUpsertForm } from '../gateway-upsert-form.component';
import { useGatewayHook } from '../hooks/use-gateway.hook';

export const GatewayRegistration = () => {
  const { onSubmit, registrationErrorCode } = useGatewayHook();

  return (
    <GatewayUpsertForm
      onSubmit={onSubmit}
      registrationErrorCode={registrationErrorCode}
      title={content.NEW_GATEWAY}
      subtitle={content.CREATE_SUBTITLE}
    />
  );
};
