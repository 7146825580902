import { queryClient, useMutation } from '@marlin/shared/utils/react-query';

import { deleteAutomation } from '../infrastructure/automation';
import { TAutomation, TPagedAutomation } from '../schemas/automation.schema';
import { queryKey } from './query-key.enum';

interface IDeleteAutomation {
  previousData: TAutomation | undefined;
}

export const useDeleteAutomation = () => {
  return useMutation({
    onMutate: async (id: string) => {
      await queryClient.cancelQueries({ queryKey: queryKey.AUTOMATIONS(), exact: false });

      const previousData = queryClient
        .getQueryData<TPagedAutomation>(queryKey.AUTOMATIONS())
        ?.data.find((automation: TAutomation) => automation.id === id);

      queryClient.removeQueries({ queryKey: queryKey.AUTOMATION(id) });

      queryClient.setQueriesData<TPagedAutomation | undefined>(
        { queryKey: queryKey.AUTOMATIONS() },
        (data?: TPagedAutomation) => {
          if (!data) {
            return data;
          }

          return {
            pagination: data.pagination,
            data: data.data.filter((automation: TAutomation) => automation.id !== id) || [],
          };
        }
      );

      return { previousData };
    },
    onError: (_: unknown, id: string, context: IDeleteAutomation | undefined) => {
      const previousData = context?.previousData;

      if (!previousData) {
        return;
      }

      queryClient.setQueriesData({ queryKey: queryKey.AUTOMATION(id) }, previousData);

      queryClient.setQueriesData<TPagedAutomation | undefined>(
        { queryKey: queryKey.AUTOMATIONS() },
        (data?: TPagedAutomation) => {
          if (!data) {
            return data;
          }

          return {
            pagination: data.pagination,
            data: [...(data.data || []), previousData],
          };
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.AUTOMATIONS() });
    },
    mutationFn: deleteAutomation,
  });
};
