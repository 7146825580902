import { dateAdapter } from '@marlin/shared/utils-common-date';
import moment from 'moment/moment';

const liveDataExpirationSeconds = 28800; // 8 hours

export const getIsOnline = (timestamp: string | undefined, lastReadingTime?: string | undefined) => {
  if (timestamp) {
    const diffSeconds = moment.duration(dateAdapter.date()?.diff(dateAdapter.date(timestamp))).asSeconds();

    return diffSeconds < liveDataExpirationSeconds;
  }

  if (lastReadingTime) {
    const diffSeconds = moment.duration(dateAdapter.date()?.diff(dateAdapter.date(lastReadingTime))).asSeconds();

    return diffSeconds < liveDataExpirationSeconds;
  }

  return false;
};
