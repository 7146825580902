import { CRITICALITY, TCreateAutomation } from '@marlin/alert/data-access/automated-action';
import { MarlinTheme } from '@marlin/shared/theme';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { FormField, IRadioButton, RadioButtons } from '@marlin/shared/ui-form-common';
import { Grid } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { useContent } from '../context';
import { CriticalityTooltip } from './criticality-tooltip.component';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  tooltip: {
    marginLeft: theme.typography.pxToRem(8),
  },

  buttons: {
    marginBottom: theme.typography.pxToRem(8),
  },
}));

export interface ICriticalityProps {
  disabled?: boolean;
}

export function Criticality({ disabled }: ICriticalityProps) {
  const { classes } = useStyles();
  const content = useContent();

  const { trigger } = useFormContext();

  const criticalityButtons: IRadioButton[] = useMemo(
    () => [
      {
        label: content.CRITICALITY.VALUE.HIGH,
        value: CRITICALITY.HIGH,
        id: 'automation-criticality-high-radio',
      },
      {
        label: content.CRITICALITY.VALUE.LOW,
        value: CRITICALITY.LOW,
        id: 'automation-criticality-low-radio',
      },
    ],
    [content.CRITICALITY.VALUE.HIGH, content.CRITICALITY.VALUE.LOW]
  );

  const onChangeCriticality = useCallback(
    (onChange: ((event: unknown) => void) | undefined) => (event: unknown) => {
      if (onChange) {
        onChange(event);
      }
      trigger('criticality');
    },
    [trigger]
  );

  return (
    <Grid item xs={12} md={6} className={classes.container}>
      <div data-testid="automation-criticality-header">
        {content.CRITICALITY.LABEL}
        <span className={classes.tooltip}>
          <Tooltip placement="right" text={<CriticalityTooltip />} />
        </span>
      </div>
      <FormField<TCreateAutomation> fieldName="criticality">
        {({ onChange, ...props }) => (
          <RadioButtons
            className={classes.buttons}
            row
            {...props}
            onChange={onChangeCriticality(onChange)}
            buttons={criticalityButtons}
            disabled={disabled}
          />
        )}
      </FormField>
    </Grid>
  );
}
