export const content = {
  ERROR: 'Something went wrong. Please try again later.',
  TOGGLE_AUTOMATION_MODAL: {
    TITLE: 'Turn off automation?',
    BODY: (name: string) =>
      `Are you sure you want to turn off ${name}? If there are outstanding alerts for this automation, they will be resolved.`,
    BODY_SIMPLE: (name: string) => `Your team will no longer receive ${name} alerts. Are you sure?`,
    CANCEL: 'Cancel',
    TURN_OFF: 'Turn off',
  },

  CARD_AUTOMATION_STATUS_ON: 'Automation On',
  CARD_AUTOMATION_STATUS_OFF: 'Automation Off',
  CARD_LABELS: {
    ID: 'ID',
    TRIGGER: 'Trigger(s)',
    ACTION: 'Action(s)',
    LOCATION: 'Location',
    EQUIPMENT: 'Equipment',
    SENSOR: 'Sensor',
    CRITICALITY: 'Criticality',
  },
  ACTIONS: {
    HOT_SOS: 'Create HotSOS Work Order ',
    IN_APP_ALERT: 'Show on Alert Center and Homescreen',
    EMAIL: 'Email',
    TEXT: 'Text',
  },
  SENSOR_TYPES: {
    TEMPERATURE: 'Temperature',
    PRESSURE: 'Pressure',
    FLOW_METER: 'Flow',
  },
  LEAK_TRIGGER: 'Leak detected',
  SYSTEM_TRIGGERS: {
    SENSOR_COMMUNICATION: (unit: string, value: number) => `Inactivity duration set to ${value} ${unit}`,
    GATEWAY: (unit: string, value: number) => `Gateway inactivity duration set to ${value} ${unit}`,
    SENSOR_BATTERY: (value: number) => `Battery level lower than ${value}%`,
  },
  TRIGGERS: {
    MAX: (sensorType: string, unit: string, value: string, separator: string) =>
      `${sensorType} higher than ${value}${separator}${unit}`,
    MIN: (sensorType: string, unit: string, value: string, separator: string) =>
      `${sensorType} lower than ${value}${separator}${unit}`,
    ALL: (sensorType: string, unit: string, max: string, min: string, separator: string) =>
      `${sensorType} higher than ${max}${separator}${unit}, or lower than ${min}${separator}${unit}`,
  },
  MENU: {
    EDIT: 'Edit',
    DELETE: 'Delete',
  },
  CANCEL_SNOOZE_SUCCESS: (automationName: string) => `Snooze for ${automationName} have been successfully canceled`, //TODO - NOT OFFICAL COPY
  CANCEL_SNOOZE_SUCCESS_SYSTEM: (automationName: string, sensorName: string) =>
    `Snooze for ${sensorName} for ${automationName} have been successfully canceled`, //TODO - NOT OFFICAL COPY
  CANCEL_SNOOZE_ERROR: 'Error during canceling snooze.', //TODO - NOT OFFICAL COPY
  SNOOZE_SUCCESS: (automationName: string, hours: string, hourText: string) =>
    `Alert notification will be snoozed for Automation ${automationName} for ${hours} ${hourText}`,
  SNOOZE_SYSTEM_SUCCESS: (automationName: string, hours: string, hourText: string, sensorName: string) =>
    `Alert notification will be snoozed for ${sensorName} for Automation ${automationName} for ${hours} ${hourText}`,
  SNOOZE_ERROR: 'Error during snooze automation.', //TODO - NOT OFFICAL COPY
  HOURS: 'hours',
  HOUR: 'hour',
  ALERT_INTERVAL: (alertLimitValue: string) => `Snooze Interval set to ${alertLimitValue} mins`,
  ALERTS: 'Alerts',
  EQUIPMENT_NAME_ERRORS: (name: string) => `${name}`,
  ALL_EQUIPMENT_ERRORS: 'All Equipment Errors',
  ALL_EQUIPMENT_ALERTS: 'All Equipment Alerts',
  EQUIPMENT_INFORMATION: 'Settings Change Event',
  EQUIPMENT_NAME_ALERTS: (name: string) => `${name}`,
};
