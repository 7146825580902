import { AnnotationTooltip } from '@marlin/shared/ui/annotation-tooltip';
import { parseDisplayedValue } from '@marlin/shared/utils-format-reading';
import { useMemo } from 'react';

import { content } from '../../../../../content';
import { IAnnotationTooltip } from './types';

export const AnnotationTooltipWrapper = ({
  value,
  deviceType,
  name,
  currentAnnotation,
  annotationType,
  uoM,
}: IAnnotationTooltip): JSX.Element | null => {
  const formattedLevel = useMemo(() => {
    const formattedReading = parseDisplayedValue(
      value.toString(),
      deviceType === 'LEAK' ? 'WaterDetect' : uoM ?? '',
      undefined,
      'detail'
    );

    switch (annotationType) {
      case 'TRESHOLD_MAX': {
        return content.ANNOTATION_TOOLTIP_ALERT_HIGH(formattedReading);
      }
      case 'TRESHOLD_MIN': {
        return content.ANNOTATION_TOOLTIP_ALERT_LOW(formattedReading);
      }
      case 'DEADBAND_MAX': {
        return content.ANNOTATION_TOOLTIP_DEADBAND_HIGH(formattedReading);
      }
      case 'DEADBAND_MIN': {
        return content.ANNOTATION_TOOLTIP_DEADBAND_LOW(formattedReading);
      }
      default: {
        return '';
      }
    }
  }, [annotationType, deviceType, uoM, value]);

  return <AnnotationTooltip formattedValue={formattedLevel} name={name} currentAnnotation={currentAnnotation} />;
};
