export const content = {
  TOO_MANY_LINKS_ON_SELECTED_TEE: 'Too many links on selected Tee. Create a new Tee.',
  ADD_TEE_SUCCESS_MESSAGE: 'Tee was added successfully',
  ADD_TEE_ERROR_MESSAGE: 'Tee was not added',
  DELETE_LINK_SUCCESS_MESSAGE: 'Flow link has been deleted.',
  DELETE_LINK_ERROR_MESSAGE: 'Error during deleting flow link.',
  DELETE_TEE_REMOVE_EXISTING_FLOW_LINKS_ERROR_MESSAGE: (linksCount: number) =>
    `Remove ${linksCount} flow links first before deleting tee`,
  DELETE_TEE_REMOVE_EXISTING_FLOW_LINK_ERROR_MESSAGE: 'Remove 1 flow link first before deleting tee',
  DELETE_TEE_SUCCESS_MESSAGE: 'Tee has been deleted.',
  DELETE_TEE_ERROR_MESSAGE: "Something went wrong. Tee hasn't been deleted.",
  CONTEXT_MENU_ADD_TEE: 'Add tee',
  CONTEXT_MENU_ADD_TEE_TOOLTIP: 'Add tee',
  DELETE_LINK_VALIDATION_MESSAGE: 'Cannot remove a link connected to a tee. First, remove the tee, and try again.',
};
