import { z } from 'zod';

const recipientTypeValues = ['Recipient', 'User'] as const;

export const recipientResponseSchema = z.object({
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  phoneNumber: z.string(),
  email: z.string(),
  channelRecipientType: z.enum(recipientTypeValues),
});

export const recipientSchema = recipientResponseSchema.transform((recipient) => ({
  id: recipient.id,
  firstName: recipient.firstName,
  lastName: recipient.lastName,
  phoneNumber: recipient.phoneNumber,
  email: recipient.email,
  recipientType: recipient.channelRecipientType,
}));

export const recipientsSchema = z.array(recipientSchema);

export const createRecipientSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  phoneNumber: z.string(),
  email: z.string(),
});
export enum CREATE_RECIPIENT_ERROR_CODE {
  RECIPIENT_WITH_THIS_EMAIL_ALREADY_IN_ORGANIZATION = 4,
  USER_WITH_THIS_EMAIL_ALREADY_EXISTS = 5,
  INVITATION_ALREADY_IN_PROGRESS = 6,
}

export type TRecipientResponse = z.infer<typeof recipientResponseSchema>;
export type TRecipient = z.infer<typeof recipientSchema>;
export type TRecipients = z.infer<typeof recipientsSchema>;
export type TCreateRecipient = z.infer<typeof createRecipientSchema>;
