import { UnitOfMeasureType } from '@marlin/shared/utils-format-reading';
import { z } from 'zod';

export const waterTotalParamsSchema = z.object({
  periodStart: z.string(),
  periodEnd: z.string(),
  previousPeriodStart: z.string(),
  aggregationMinutes: z.number().min(1),
});

export const volumeDataPointSchema = z.object({
  eventDateTime: z.string(),
  volume: z.coerce.number(),
});

export const waterTotalResponseSchema = z.object({
  volumeTotal: z.array(volumeDataPointSchema),
  previousPeriodVolumeTotal: z.array(volumeDataPointSchema),
  uoM: UnitOfMeasureType.nullish(),
});

export type TWaterTotalParams = z.infer<typeof waterTotalParamsSchema>;
export type TWaterTotalResponse = z.infer<typeof waterTotalResponseSchema>;
export type TVolumeDataPoint = z.infer<typeof volumeDataPointSchema>;
