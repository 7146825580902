import { TAppName } from '@marlin/environment';
import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

interface IStyles {
  appName?: TAppName;
}

export const useStyles = makeStyles<IStyles>()((theme: MarlinTheme, { appName }) => ({
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.secondary,
    paddingTop: theme.typography.pxToRem(12),
    borderBottom: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
    [theme.breakpoints.down('md')]: {
      maxHeight: theme.typography.pxToRem(theme.static.mobileHeaderHeight),
    },
  },

  toolbarLeft: {
    display: 'flex',
    flexDirection: 'row',
    flex: '33%',
    justifyContent: 'flex-start',
  },
  toolbarRight: {
    display: 'flex',
    flexDirection: 'row',
    flex: '33%',
    justifyContent: 'flex-end',
  },

  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
  },

  upgradeNexaBtn: {
    display: 'flex',
    justifyContent: 'center',
  },

  wrapper: {
    width: theme.typography.pxToRem(256),
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: theme.typography.pxToRem(256),
      boxSizing: 'border-box',
    },
  },

  optionsWrapper: {
    minHeight: '100dvh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingTop: theme.typography.pxToRem(36),
    paddingLeft: theme.typography.pxToRem(8),
    paddingRight: theme.typography.pxToRem(8),
  },

  headerRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    paddingRight: theme.typography.pxToRem(16),
    paddingLeft: theme.typography.pxToRem(16),
    paddingBottom: theme.typography.pxToRem(24),
  },

  bottomWrapper: {
    marginBottom: theme.typography.pxToRem(24),
  },

  badgeContainer: {
    paddingRight: theme.typography.pxToRem(16),
  },
}));
