import { createDateString } from '@marlin/shared/utils-common-date';
import { UnitOfMeasureType, parseDisplayedValue } from '@marlin/shared/utils-format-reading';
import { z } from 'zod';

import { ALERT_CAUSE, CriticalitySchema } from './home-dashboard.schema';

export enum DEVICE_TYPE {
  TEMPERATURE = 'TEMPERATURE',
  PRESSURE = 'PRESSURE',
  LEAK = 'LEAK',
  PULSE_METER = 'PULSE_METER',
  UNKNOWN = 'UNKNOWN',
}

const formattedDeviceType = (type: string | undefined) => {
  switch (type) {
    case 'Temperature':
      return DEVICE_TYPE.TEMPERATURE;
    case 'Pressure':
      return DEVICE_TYPE.PRESSURE;
    case 'Leak':
      return DEVICE_TYPE.LEAK;
    case 'FlowMeter':
    case 'PulseMeter':
      return DEVICE_TYPE.PULSE_METER;
    default:
      return DEVICE_TYPE.UNKNOWN;
  }
};

export const datapointsSchema = z.union([
  z.literal('Leak'),
  z.literal('Temperature'),
  z.literal('Pressure'),
  z.literal('FlowRate'),
  z.literal('FlowVolume'),
  z.literal('BatteryLevel'),
  z.literal('SignalStrength'),
  z.literal('Pulses'),
  z.literal('Voltage'),
  z.string(),
]);

export const locationTypeSchema = z.union([
  z.literal('MechanicalRoom'),
  z.literal('NotClassified'),
  z.literal('GuestRoom'),
  z.literal('Laundry'),
  z.literal('Kitchen'),
  z.literal('Plant'),
]);

export const homeLocationsParamsSchema = z.object({
  isMechanicalRoom: z.boolean().nullish(),
  withAlerts: z.boolean().nullish(),
});

const homeAlertSchema = z.object({
  alertId: z.string(),
  criticality: CriticalitySchema,
  alertCause: z.nativeEnum(ALERT_CAUSE),
});

const homeDeviceReadingsSchema = z
  .object({
    name: datapointsSchema,
    value: z.string().nullable(),
    unitOfMeasure: UnitOfMeasureType.nullable(),
    isDefault: z.boolean(),
    lastReadingTime: z.string().nullish(),
  })
  .transform((data) => ({
    ...data,
    formattedReading:
      data.value && !!data.unitOfMeasure?.length
        ? parseDisplayedValue(data.value, data.unitOfMeasure, undefined, 'detail')
        : null,
    lastReadingTime: createDateString(data.lastReadingTime ?? undefined),
    value: data.value ? data.value : '',
  }));

const homeDeviceSchema = z.object({
  deviceId: z.string(),
  deviceName: z.string().nullish(),
  deviceType: z.string().transform(formattedDeviceType),
  deviceLastReading: z.number().nullish(),
  deviceLastReadings: z.array(homeDeviceReadingsSchema).nullish(),
  alerts: z.array(homeAlertSchema).nullish(),
});

const homeEquipmentSchema = z.object({
  equipmentId: z.string().nullish(),
  equipmentName: z.string().nullish(),
  devices: z.array(homeDeviceSchema).nullish(),
});

export const homeLocationSchema = z.object({
  locationId: z.string(),
  locationName: z.string().nullish(),
  parentLocationId: z.string().nullish(),
  parentLocationName: z.string().nullish(),
  equipment: z.array(homeEquipmentSchema).nullish(),
  unattachedDevices: z.array(homeDeviceSchema).nullish(),
  locationType: locationTypeSchema,
});

export const homeLocationsSchema = z.object({
  mechanicalRoomCount: z.number(),
  locationWithAlertsCount: z.number(),
  locationCount: z.number(),
  locations: z.array(homeLocationSchema).nullish(),
});

export type THomeEquipmentSchema = z.infer<typeof homeEquipmentSchema>;
export type THomeDeviceSchema = z.infer<typeof homeDeviceSchema>;
export type THomeAlertSchema = z.infer<typeof homeAlertSchema>;
export type THomeLocationsParamsSchema = z.infer<typeof homeLocationsParamsSchema>;
export type THomeLocationsSchema = z.infer<typeof homeLocationsSchema>;
export type THomeLocationSchema = z.infer<typeof homeLocationSchema>;
