import { TDeviceTypeSchema } from '@marlin/asset/data-access/device';
import { useMemo } from 'react';

import { IChartPoint } from '../../types';

const mapChartData = (
  data: IChartPoint[],
  deviceType?: TDeviceTypeSchema,
  name?: string,
  type: 'line' | 'scatter' | 'bar' | 'rangeArea' = 'line'
) => {
  switch (deviceType) {
    case 'LEAK': {
      return [{ name, type, data: data.map((point) => ({ x: point.timestamp, y: point.value ? 1 : 0 })) }];
    }
    case 'TEMPERATURE':
    case 'PULSE_METER':
    case 'PRESSURE': {
      return [{ name, type, data: data.map((point) => ({ x: point.timestamp, y: point.value })) }];
    }
    default: {
      return [];
    }
  }
};

export const useChartData = (
  data: IChartPoint[],
  deviceType?: TDeviceTypeSchema,
  name?: string,
  type?: 'line' | 'scatter' | 'bar' | 'rangeArea'
) => {
  return useMemo(() => mapChartData(data, deviceType, name, type), [data, deviceType, name, type]);
};
