import { MarlinTheme } from '@marlin/shared/theme';
import { Paper as UIPaper } from '@mui/material';
import { ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

interface IPaperProps {
  className?: string;
  testId?: string;
  children: ReactNode;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  paper: {
    padding: theme.typography.pxToRem(24),
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.typography.pxToRem(12),
      paddingRight: theme.typography.pxToRem(12),
      paddingTop: theme.typography.pxToRem(20),
      paddingBottom: theme.typography.pxToRem(20),
    },
  },
}));

export function Paper({ className, testId, children }: IPaperProps) {
  const { classes, cx } = useStyles();
  return (
    <UIPaper className={cx(classes.paper, className)} elevation={0} variant="outlined" data-testid={testId}>
      {children}
    </UIPaper>
  );
}
