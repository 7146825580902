import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import { GraphSchema, TGraphResponse } from '../flow-map.model.schema';
import { paths } from './api';

interface IDeleteFlowLink {
  flowLinkId: string;
}

export const deleteFlowLink = async ({ flowLinkId }: IDeleteFlowLink) => {
  const res = await getHttpClient().delete<unknown, TGraphResponse>(paths.SYSTEM_MAP_LINK_DETAILS, {
    params: { flowLinkId },
  });
  return safeParseData(res, GraphSchema);
};
