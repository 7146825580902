export const content = {
  YEARS: 'years',
  YEAR: 'year',
  MONTHS: 'months',
  MONTH: 'month',
  DAYS: 'days',
  DAY: 'day',
  HOURS: 'hours',
  HOUR: 'hour',
  MINUTES: 'minutes',
  MINUTE: 'minute',
  SECONDS: 'seconds',
  SECOND: 'second',
  US_TIMEZONES: 'US Timezones',
  EU_TIMEZONES: 'Europe Timezones',
};
