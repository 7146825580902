import { zodResolver } from '@hookform/resolvers/zod';
import { useInviteUsers } from '@marlin/account-data-access-invitation';
import { getLogger } from '@marlin/shared/utils/logger';
import { TInvitationFormValues, invitationFormSchema } from '@marlin/shared/utils/zod';
import { BaseSyntheticEvent, useCallback, useEffect, useMemo } from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';
import { z } from 'zod';

import { content } from '../../../../content';
import { defaultInvitationValues, useOnboardingState } from '../../../use-onboarding-state.hook';

export interface IUseInviteUsersFormProps {
  handleSuccess: () => void;
  handleError: () => void;
}

export interface IUseInviteUsersForm {
  onSubmit: (e?: BaseSyntheticEvent<object> | undefined) => Promise<void>;
  form: UseFormReturn<TInvitationFormValues>;
  validEmailFieldsCount: number;
  isSubmitting: boolean;
}

export const useInviteUsersForm = ({ handleSuccess, handleError }: IUseInviteUsersFormProps): IUseInviteUsersForm => {
  const { mutateAsync: inviteUsers, isLoading: isSubmitting } = useInviteUsers();
  const { invitationState, updateInvitationState } = useOnboardingState();

  const form = useForm<TInvitationFormValues>({
    resolver: zodResolver(invitationFormSchema),
    mode: 'onBlur',
    defaultValues: defaultInvitationValues,
  });

  const { handleSubmit, watch } = form;
  const users = watch('users');

  const validEmailFieldsCount = users.reduce((acc, field) => {
    const emailResult = z.string().email().safeParse(field.email);
    return emailResult.success ? acc + 1 : acc;
  }, 0);

  const onSubmit = useCallback(
    async (values: TInvitationFormValues) => {
      updateInvitationState(values);

      try {
        await inviteUsers(values);

        getLogger()?.track(content.LOGGER.SELF_SERVICE.INVITE_USERS_SUCCESS, {
          users: values,
        });
        handleSuccess();
      } catch (error) {
        handleError();
      }
    },
    [handleError, handleSuccess, inviteUsers, updateInvitationState]
  );

  useEffect(() => {
    form.setValue('users', invitationState.users, { shouldDirty: true, shouldValidate: true });
  }, [form, invitationState.users]);

  return useMemo(
    () => ({ onSubmit: handleSubmit(onSubmit), form, validEmailFieldsCount, isSubmitting }),
    [form, handleSubmit, onSubmit, validEmailFieldsCount, isSubmitting]
  );
};
