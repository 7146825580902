import { PERMISSIONS, PermissionGuard } from '@marlin/shared/utils-permission';
import { routes } from '@marlin/shared/utils-routes';
import { FlowLinkHub } from '@marlin/system-map/feature/system-map-hub';
import { Route } from 'react-router-dom';

export const FlowMapHub = (): JSX.Element => {
  return (
    <Route
      path={routes.systemMap.root}
      element={
        <PermissionGuard redirectTo={routes.home.dashboard} permissions={[PERMISSIONS.SYSTEM_MAP]}>
          <FlowLinkHub />
        </PermissionGuard>
      }
    ></Route>
  );
};
