import { environment } from '@marlin/environment';

const isEnvDefined =
  environment.module.automationHelper.dataTestId && environment.module.automationHelper.dataTestId.length > 0;

export class AutomationHelperService {
  private static instance: AutomationHelperService;
  private readonly htmlRootElem: Element | null = null;

  /**
   * @returns instance of AutomationHelperService class or null if env automatonHelper env not specified
   */
  public static getInstance = () => {
    if (!isEnvDefined) {
      return null;
    }
    if (!this.instance) {
      this.instance = new AutomationHelperService();
    }

    return this.instance;
  };

  private constructor() {
    if (!isEnvDefined) {
      /* eslint-disable no-console */
      console.warn(
        "[Automation helper] e2e root element doesn't exist",
        environment.module.automationHelper.dataTestId
      );
      return;
    }
    if (environment.module.automationHelper?.allowedAttributes?.includes('datatest-id')) {
      const e2eRootElem = document.querySelector(`[datatest-id="${environment.module.automationHelper.dataTestId}"`);
      if (e2eRootElem) {
        this.htmlRootElem = e2eRootElem;
        this.setupOrganizationIdAttribute();
        return;
      }
    }
    /* eslint-disable no-console */
    console.warn('[Automation helper] Not allowed attribute');
    return;
  }

  /**
   * Sets the value of element's first attribute whose qualified name is qualifiedName to value.
   * @param attrName - name of attribute
   * @param attrValue - attribute value
   * @param true if successfully added, @param false if not found
   */
  public addAttribute = (attrName: string, attrValue: string) => {
    if (environment.module.automationHelper.allowedAttributes?.includes(attrName)) {
      if (this.htmlRootElem) {
        this.htmlRootElem.setAttribute(attrName, attrValue);
        return true;
      }
    } else {
      /* eslint-disable no-console */
      console.warn('[Automation helper] Not allowed attribute');
      return;
    }
    /* eslint-disable no-console */
    console.warn("[Automation helper] e2e root element doesn't exist");
    return false;
  };

  /**
   * Removes element's first attribute whose qualified name is qualifiedName.
   * @param attrName - name of attribute
   * @returns
   * @param true if successfully removed, @param false if not found
   */
  public removeAttribute = (attrName: string) => {
    if (this.htmlRootElem) {
      if (this.htmlRootElem.hasAttribute(attrName)) {
        this.htmlRootElem.removeAttribute(attrName);
        return true;
      }
    }
    /* eslint-disable no-console */
    console.warn("[Automation helper] e2e root element doesn't exist");
    return false;
  };

  private setupOrganizationIdAttribute = () => {
    this.addOrganizationIdAttribute();
    // note: we want that event listener here
    // eslint-disable-next-line no-restricted-globals
    window.addEventListener('OrgIdChanged', (event) => {
      this.addOrganizationIdAttribute();
    });
  };

  private addOrganizationIdAttribute = () => {
    const attributeKey = 'data-orgid';
    const storageKey = 'organizationId';
    const organizationId = localStorage.getItem(storageKey);
    if (organizationId) {
      this.removeAttribute(attributeKey);
      this.addAttribute(attributeKey, organizationId);
    }
  };
}
