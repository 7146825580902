import { content } from '../content';

export type TSankeyUnit = 'volume' | 'dollars';

export interface IUnitOption {
  value: TSankeyUnit;
  label: string;
}

export const unitOptions: IUnitOption[] = [
  {
    value: 'volume' as const,
    label: content.VOLUME,
  },
  {
    value: 'dollars' as const,
    label: content.DOLLARS,
  },
];
