import { useCurrentUser, useUpdateOrg } from '@marlin/account-data-access-organization';
import { environment } from '@marlin/environment';
import { Loader } from '@marlin/shared/ui-loader';
import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

interface IGuardProps {
  children: JSX.Element;
}

export const OrgIdQueryGuard = ({ children }: IGuardProps): JSX.Element => {
  const { pathname, search } = useLocation();
  const [newPathname, setNewPathname] = useState(pathname);
  const user = useCurrentUser();
  const updateOrgMutation = useUpdateOrg({ shouldRefetch: true });
  const [canRedirect, setCanRedirect] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    if (queryParams.has('orgId')) {
      const orgId = queryParams.get('orgId');
      queryParams.delete('orgId');
      setNewPathname(`${pathname}?${queryParams.toString()}`);
      updateOrgMutation.mutateAsync(orgId ?? '').then(() => {
        setCanRedirect(true);
      });
    }
  }, [pathname, search, updateOrgMutation]);

  useEffect(() => {
    if (environment.module.automationHelper.extendedQaLogging) {
      // eslint-disable-next-line no-console
      console.log(
        `[Activity Log of Page ${pathname}]\nOrgIdQueryGuard\n`,
        'queryParams',
        search,
        'navigateTo',
        newPathname,
        'canRedirect',
        canRedirect,
        'isFetched',
        user.isFetched,
        'isLoading',
        user.isLoading,
        'data',
        JSON.stringify(user.data)
      );
    }
  }, [canRedirect, newPathname, pathname, search, user.data, user.isFetched, user.isLoading]);

  if (!new URLSearchParams(search).has('orgId')) {
    return children;
  }

  if (canRedirect) {
    return <Navigate to={newPathname} />;
  }

  return <Loader />;
};
