import CircleRoundedIcon from '@mui/icons-material/CircleRounded';

import { content } from '../../content';
import { useStyles } from './device-state.component.styles';

const LostCommunication = () => {
  const { classes } = useStyles();

  return (
    <>
      <CircleRoundedIcon className={classes.inactiveIcon} />
      <span className={classes.activeText} data-testid="status-icon-container-text">
        {content.LOST_COMMUNICATION}
      </span>
    </>
  );
};

export { LostCommunication };
