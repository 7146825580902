import { TTelemetry } from '@marlin/asset/data-access/telemetry';

import { useExtendedDeviceDrawer } from '../../container/context';
import { CollapsedChart } from './collapsed-chart/collapsed-chart.component';
import { ExtendedChart } from './extended-chart/extended-chart.component';
import { useChart } from './use-chart.hook';

export interface IChartProps {
  telemetry: TTelemetry | undefined;
}

export const Chart = (props: IChartProps): JSX.Element | null => {
  const isExtended = useExtendedDeviceDrawer();
  const chartProps = useChart({ telemetry: props.telemetry });

  return isExtended ? <ExtendedChart {...chartProps} /> : <CollapsedChart {...chartProps} />;
};
