import { MarlinTheme } from '@marlin/shared/theme';
import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { IPinnedDevice } from '../types';
import { PinnedDeviceCard } from './pinned-device-card.component';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    marginTop: 8,
  },
  container: {
    overflowX: 'auto',
    display: 'flex',
    '-ms-overflow-style': 'none' /* hide scrollbar on Internet Explorer 10+ */,
    'scrollbar-width': 'none' /* hide scrollbar on Firefox */,
    '&::-webkit-scrollbar': {
      display: 'none' /* hide scrollbar on Safari and Chrome */,
    },
    '& > .MuiGrid-root:first-child': {
      marginLeft: theme.typography.pxToRem(16),
    },
  },
}));

interface IPinnedDeviceListMobileProps {
  devices: IPinnedDevice[];
  onUnpin: (id: string) => void;
}

export function PinnedDeviceListMobile({ devices, onUnpin }: IPinnedDeviceListMobileProps) {
  const { classes } = useStyles();
  return (
    <Grid container spacing={3} className={classes.wrapper}>
      <div className={classes.container}>
        {devices.map((device) => (
          <PinnedDeviceCard key={device.id} onUnpin={onUnpin} pinnedDevice={device} deviceNumbers={devices.length} />
        ))}
      </div>
    </Grid>
  );
}
