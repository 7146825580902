import { useFilteredEquipmentsWithPaging } from '@marlin/asset/data-access/equipment';
import { useFilteredLocationsWithPaging } from '@marlin/asset/data-access/location';
import { EQUIPMENT_TYPE } from '@marlin/shared/utils/zod';

export const useLocationsWithDevicesQuery = (enabled?: boolean) => {
  return useFilteredLocationsWithPaging({
    params: {
      page: 1,
      pageSize: 100,
    },
    select: (data) => {
      return {
        ...data,
        data: data.data?.filter((location) => location.devices.length) ?? null,
      };
    },
    enabled,
  });
};

export const useEquipmentWithDevicesQuery = ({
  enabled,
  selectedLocationId,
}: {
  enabled?: boolean;
  selectedLocationId?: string;
}) => {
  return useFilteredEquipmentsWithPaging({
    params: {
      page: 1,
      pageSize: 100,
      locations: selectedLocationId ? [selectedLocationId] : undefined,
      equipmentType: [
        EQUIPMENT_TYPE.HOT_WATER_HEATER,
        EQUIPMENT_TYPE.PUMP,
        EQUIPMENT_TYPE.LEAK_DEFENSE_SYSTEM,
        EQUIPMENT_TYPE.DIGITAL_MIXING_VALVE,
        EQUIPMENT_TYPE.BOILER,
        EQUIPMENT_TYPE.PRESSURE_REDUCING_VALVE,
        EQUIPMENT_TYPE.SHUT_OFF_VALVE,
        EQUIPMENT_TYPE.WATER_TANK,
        EQUIPMENT_TYPE.PARTITION_CONTROLLER,
      ],
    },
    select: (data) => {
      return {
        ...data,
        data: data.data?.filter((equipment) => !!equipment.devices.length) ?? null,
      };
    },
    enabled,
  });
};
