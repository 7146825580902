import { MarlinTheme } from '@marlin/shared/theme';
import { Button, Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.typography.pxToRem(8),
    justifyContent: 'space-between',
  },
}));

interface IActionButtonProps {
  onSubmit: () => void;
  onCancel: () => void;
  selectedSnoozeTime: number | undefined;
}

export const Buttons = ({ onSubmit, onCancel, selectedSnoozeTime }: IActionButtonProps) => {
  const { classes } = useStyles();

  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item>
        <Button variant="outlined" data-testid="upsert-user-profile-cancel-button" onClick={onCancel}>
          {content.BTN_CANCEL}
        </Button>
      </Grid>
      <Grid item>
        <Button
          data-testid="upsert-user-profile-confirm-button"
          variant="contained"
          disabled={!selectedSnoozeTime}
          onClick={onSubmit}
        >
          {content.BTN_SNOOZE}
        </Button>
      </Grid>
    </Grid>
  );
};
