import { MarlinTheme } from '@marlin/shared/theme';
import { FC, PropsWithChildren } from 'react';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  appContainer: {
    display: 'flex',
  },

  appContent: {
    minHeight: '100vh',
    height: '100vh',
    overflowY: 'auto',
    flexGrow: 1,
    backgroundColor: theme.palette.background.secondary,
  },

  main: {
    overflowY: 'hidden',
    height: '100%',
  },
}));

export const FullPageLayout: FC<PropsWithChildren> = ({ children }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.main}>
      <div className={classes.appContainer}>
        <div className={classes.appContent}>{children}</div>
      </div>
    </div>
  );
};
