import { MarlinTheme } from '@marlin/shared/theme';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { ChangeEvent, useCallback } from 'react';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  input: {
    backgroundColor: theme.palette.background.primary,
    width: '100%',
    height: '100%',
  },
}));

interface ISearchProps {
  value: string;
  onChange?: (value: string) => void;
  label: string;
  prefix: string;
  className?: string;
}

export function Search({ value, onChange, label, prefix, className = '' }: ISearchProps): JSX.Element {
  const { classes, cx } = useStyles();

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => onChange && onChange(event.target.value),
    [onChange]
  );

  return (
    <TextField
      className={cx([classes.input, { [className]: !!className }])}
      data-testid={`${prefix}-filters-search`}
      value={value}
      onChange={handleChange}
      label={label}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton>
              <SearchRoundedIcon color="action" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
