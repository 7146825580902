import { IAlert } from '@marlin/alert/data-access/alert-action';
import { MarlinTheme } from '@marlin/shared/theme';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from '../../../content';
import { CurrentReading } from './current-reading.component';
import { LostCommunicationMessage } from './lost-communication.component';
import { MinMaxReading } from './min-max-reading.component';

interface IStyles {
  isExtended?: boolean;
}

const useStyles = makeStyles<IStyles>()((theme: MarlinTheme, { isExtended }) => ({
  container: {
    display: 'flex',
    flexDirection: isExtended ? 'row' : 'column',
    gap: isExtended ? theme.typography.pxToRem(24) : 0,
  },
  currentReadings: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: isExtended ? 0 : theme.typography.pxToRem(18),
  },
  currentReadingsExtended: {
    justifyContent: 'flex-start',
    columnGap: theme.typography.pxToRem(24),
  },
  currentReadingContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  currentReadingContainerExtended: {
    flexDirection: 'column',
    alignItems: 'baseline',
    columnGap: theme.typography.pxToRem(16),
  },
  leakContainer: {
    display: 'flex',
    gap: isExtended ? theme.typography.pxToRem(24) : theme.typography.pxToRem(8),
  },
}));

interface IReading {
  formattedValue: string;
  formattedDate: string;
}

export interface ICurrentReadings {
  isExtended?: boolean;
  isLeakUnitOfMeasure?: boolean;
  isAlert: boolean;
  recentReading: IReading | null;
  lowReading: IReading | null;
  highReading: IReading | null;
  communicationLostAlert?: IAlert;
  showLostCommunicationMessage?: boolean;
}
export const CurrentReadings = ({
  isExtended,
  isLeakUnitOfMeasure,
  recentReading,
  highReading,
  lowReading,
  isAlert,
  communicationLostAlert,
  showLostCommunicationMessage,
}: ICurrentReadings) => {
  const { classes, cx } = useStyles({ isExtended });

  const showCurrentReadings = recentReading && lowReading && highReading;

  if (showLostCommunicationMessage) {
    return (
      <LostCommunicationMessage
        lastReading={communicationLostAlert?.lastReading || null}
        isAlert={isAlert}
        isExtended={!!isExtended}
      />
    );
  }

  if (showCurrentReadings) {
    return (
      <div className={classes.container}>
        <div className={cx(classes.currentReadings, { [classes.currentReadingsExtended]: isExtended })}>
          <div
            className={cx(classes.currentReadingContainer, { [classes.currentReadingContainerExtended]: isExtended })}
            data-testid="device-drawer-current-reading"
          >
            <CurrentReading isExtended={isExtended} currentReading={recentReading.formattedValue} isAlert={isAlert} />
            <Typography variant="subtitle2">{recentReading.formattedDate}</Typography>
          </div>
        </div>
        {!isLeakUnitOfMeasure && (
          <div className={classes.leakContainer}>
            <MinMaxReading reading={lowReading} label={content.LOWEST} testId="device-drawer-lowest-reading" />
            <MinMaxReading reading={highReading} label={content.HIGHEST} testId="device-drawer-highest-reading" />
          </div>
        )}
      </div>
    );
  }

  return null;
};
