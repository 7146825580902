import { MarlinTheme } from '@marlin/shared/theme';
import { alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    cursor: 'pointer',
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.divider}`,
  },
  containerActive: {
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.primary.main}`,
    backgroundColor: alpha(theme.palette.primary.main, 0.04),
  },
  titleContainerNoActive: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: theme.typography.pxToRem(12),
    alignContent: 'center',
    alignItems: 'center',
    padding: theme.typography.pxToRem(24),
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: theme.typography.pxToRem(12),
    alignContent: 'center',
    alignItems: 'center',
  },
  titleContainerActive: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.typography.pxToRem(24),
    justifyContent: 'space-between',
  },
  title: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightLight,
    color: theme.palette.text.primary,
    maxWidth: theme.typography.pxToRem(324),
    paddingLeft: theme.typography.pxToRem(24),
    paddingRight: theme.typography.pxToRem(24),
    marginBottom: theme.typography.pxToRem(24),
  },
}));
