import { ModalMedium } from '@marlin/shared/ui-modal';
import { Button } from '@mui/material';

import { content } from '../../content';

interface IConfirmationModalProps {
  onConfirm: () => void;
  onCancel: () => void;
}

export const ConfirmationModal = ({ onCancel, onConfirm }: IConfirmationModalProps) => (
  <ModalMedium
    title={content.MODAL_TITLE}
    onClose={onCancel}
    body={<>{content.MODAL_CONTENT}</>}
    footer={
      <>
        <Button variant="text" onClick={onCancel}>
          {content.MODAL_CANCEL}
        </Button>
        <Button variant="contained" color="error" onClick={onConfirm}>
          {content.MODAL_CONFIRM}
        </Button>
      </>
    }
  />
);
