import { Paper } from '@marlin/shared/ui-page';
import { TAssetType, TDeviceType, TNodeType } from '@marlin/system-map/shared/data-access-schemas';
import { Divider, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { GridSortModel } from '@mui/x-data-grid';
import { useCallback, useMemo } from 'react';

import { rowsPerPageOptions } from './constants';
import { content } from './content';
import { createColumns } from './data-grid-columns';
import { useStyles } from './flow-link-list.component.styles';

type TTags = {
  name?: string | null;
  value?: string | null;
};

export interface IFlowLinkRow {
  id: string;
  typeOutlet?: TAssetType;
  nodeTypeOutlet?: TNodeType;
  deviceTypeOutlet?: TDeviceType;
  nameOutlet?: string | null;
  idOutlet?: string | null;
  typeInlet?: TAssetType;
  nodeTypeInlet?: TNodeType;
  deviceTypeInlet?: TDeviceType;
  nameInlet?: string | null;
  idInlet?: string | null;
  isAttachment: boolean;
  inletTags: TTags[];
  outletTags: TTags[];
  linkTags: TTags[];
}

export interface IFlowLinkProps {
  rows: IFlowLinkRow[];
  isLoading: boolean;
  removeFlowLink: (rowId: string) => void;
  editFlowLink: (flowLink: IFlowLinkRow) => void;
  isError: boolean;
  pagination: {
    page: number;
    pageSize: number;
    totalItems: number;
  };
  changePage: (page: number) => void;
  changePageSize: (pageSize: number) => void;
  allowDelete: boolean;
  allowEdit: boolean;
  onSortChange: (sortModel: GridSortModel) => void;
}

const dataGridPageCounterOffset = 1;

export const FlowLinkList = ({
  rows,
  isLoading,
  isError,
  pagination,
  changePage,
  changePageSize,
  removeFlowLink,
  editFlowLink,
  allowDelete,
  allowEdit,
  onSortChange,
}: IFlowLinkProps) => {
  const { classes } = useStyles();

  const dataGridPage = useMemo(() => pagination.page - dataGridPageCounterOffset, [pagination.page]);

  const dataGridChangePage = useCallback(
    (page: number) => {
      changePage(page + dataGridPageCounterOffset);
    },
    [changePage]
  );

  return (
    <Paper className={classes.container}>
      <div className={classes.header}>
        <Typography variant="h6">{content.TITLE}</Typography>
      </div>
      <Divider />
      <div className={classes.dataGridWrapper}>
        <DataGrid
          className={classes.dataGrid}
          loading={isLoading}
          onPageChange={dataGridChangePage}
          onPageSizeChange={changePageSize}
          localeText={{
            noRowsLabel: isError ? content.ERROR : content.NO_DATA,
          }}
          page={dataGridPage}
          pageSize={pagination.pageSize}
          rowCount={pagination.totalItems}
          rows={rows}
          columns={createColumns({
            removeFlowLink,
            editFlowLink,
            columnProps: { headerClassName: classes.column },
            arrowClassName: classes.arrows,
            allowDelete,
            allowEdit,
          })}
          columnVisibilityModel={{
            action: allowEdit || allowDelete,
          }}
          rowsPerPageOptions={rowsPerPageOptions}
          paginationMode="server"
          onSortModelChange={onSortChange}
          disableColumnMenu
        />
      </div>
    </Paper>
  );
};
