import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const skeletons = {
  defaultHeight: 200,
  rowGap: 24,
};

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'grid',
    rowGap: theme.typography.pxToRem(24),
    columnGap: theme.typography.pxToRem(24),
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateAreas: `"performance topAlerts"
                        "health topAlerts"
                        "totalization totalization"
                        "pinned pinned"
                        "systemMap analytics"`,
  },
  mobileContainer: {
    display: 'grid',
    rowGap: theme.typography.pxToRem(16),
    padding: theme.typography.pxToRem(16),
    gridTemplateColumns: '1fr',
    gridTemplateAreas: `"performance" "health" "topAlerts" "totalization" "pinned"`,
  },
  performance: {
    gridArea: 'performance',
    [theme.breakpoints.down('md')]: {
      width: `calc( 100vw - ${theme.typography.pxToRem(theme.static.marginBig)} );`,
    },
  },
  health: {
    gridArea: 'health',
    [theme.breakpoints.down('md')]: {
      width: `calc( 100vw - ${theme.typography.pxToRem(theme.static.marginBig)} );`,
    },
  },
  systemMap: {
    gridArea: 'systemMap',
    [theme.breakpoints.down('md')]: {
      width: `calc( 100vw - ${theme.typography.pxToRem(theme.static.marginBig)} );`,
    },
  },
  topAlerts: {
    gridArea: 'topAlerts',
    [theme.breakpoints.down('md')]: {
      width: `calc( 100vw - ${theme.typography.pxToRem(theme.static.marginBig)} );`,
    },
  },
  totalization: {
    gridArea: 'totalization',
    [theme.breakpoints.down('md')]: {
      width: `calc( 100vw - ${theme.typography.pxToRem(theme.static.marginBig)} );`,
    },
  },
  analytics: {
    gridArea: 'analytics',
    [theme.breakpoints.down('md')]: {
      width: `calc( 100vw - ${theme.typography.pxToRem(theme.static.marginBig)} );`,
    },
  },
  pinned: {
    gridArea: 'pinned',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.typography.pxToRem(16),
      width: `calc( 100vw - ${theme.typography.pxToRem(theme.static.marginBig)} );`,
    },
  },
}));
