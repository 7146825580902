import { TUnitOfMeasure } from '@marlin/shared/utils-format-reading';

import { content } from './content';
import { getTitleErrorText } from './get-title-error-text';
import { ERROR_TYPES, TDeviceType } from './types';

export const getDeviceTypeText = (deviceType?: TDeviceType): string => {
  switch (deviceType) {
    case 'TEMPERATURE': {
      return content.SENSOR_TYPES.TEMPERATURE;
    }
    case 'PRESSURE': {
      return content.SENSOR_TYPES.PRESSURE;
    }
    case 'LEAK': {
      return content.SENSOR_TYPES.LEAK;
    }
    case 'PULSE_METER': {
      return content.SENSOR_TYPES.FLOW;
    }
    default: {
      return '';
    }
  }
};

type TAlertChartData = {
  alertCause: ERROR_TYPES;
  thresholdMin?: number | null;
  thresholdMax?: number | null;
  uoM: TUnitOfMeasure | null;
};

export const getChartAlertTitle = (alert: TAlertChartData, deviceType?: TDeviceType): string => {
  if (alert.alertCause === ERROR_TYPES.LOW_BATTERY) {
    return content.TITLE.SYSTEM_MAP_LOW_BATTERY;
  }
  if (alert.alertCause === ERROR_TYPES.LOST_COMMUNICATION) {
    return content.TITLE.SYSTEM_MAP_LOST_COMMUNICATION;
  }

  if (deviceType === 'LEAK') {
    return content.TITLE.SYSTEM_MAP_LEAK;
  }

  const errorText = getTitleErrorText(
    alert.alertCause,
    alert.uoM,
    alert.thresholdMin || undefined,
    alert.thresholdMax || undefined
  );
  const deviceTypeText = getDeviceTypeText(deviceType);

  return `${deviceTypeText} ${errorText}`;
};
