import { useDeviceDetails } from '@marlin/asset/data-access/device';
import { useRouter } from '@marlin/shared/utils-router';
import { useEffect, useMemo } from 'react';

import { useSensorId } from './use-sensor-id.hook';

export const useDeviceDrawer = () => {
  const deviceId = useSensorId();
  const deviceQuery = useDeviceDetails({ deviceId, datapoints: ['BatteryLevel'], enabled: deviceId.length > 0 });
  const router = useRouter();
  const shouldRender = useMemo(() => Boolean(deviceId && !deviceQuery.isError), [deviceId, deviceQuery.isError]);

  useEffect(() => {
    if (deviceId && deviceQuery.isError) {
      router.refresh(false);
    }
  }, [deviceId, deviceQuery.isError, router]);

  return {
    shouldRender,
    deviceDetails: deviceQuery.data,
    isLoading: deviceQuery.isLoading,
  };
};
