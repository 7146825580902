import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

import { headerHeight } from './extended-drawer-header.styles';

interface IStyle {
  desktopNavbarLocked: boolean;
}

export const useStyles = makeStyles<IStyle>()((theme: MarlinTheme, { desktopNavbarLocked }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: desktopNavbarLocked
      ? `calc(100vw - ${theme.typography.pxToRem(256)})`
      : `calc(100vw - ${theme.typography.pxToRem(80)})`,
    top: 0,
    right: 0,
    zIndex: 1300, // note: needs to be over system map full screen z-index: 1299
    left: 'auto',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
    },
    backgroundColor: theme.palette.background.secondary,
    overflow: 'hidden',
  },
  closeIcon: {
    color: theme.palette.primary.contrastText,
    marginLeft: theme.typography.pxToRem(12),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: theme.typography.pxToRem(24),
    paddingTop: theme.typography.pxToRem(16),
    height: `calc('100%' - ${theme.typography.pxToRem(headerHeight)})`,
    overflowY: 'auto',
    [theme.breakpoints.down('md')]: {
      padding: theme.typography.pxToRem(8),
    },
  },
}));
