import { TEquipmentResponseSchema } from '@marlin/asset/data-access/equipment';
import { TLocationResponseSchema } from '@marlin/asset/data-access/location';

import { content } from '../content';
import { ILocationData, TGroupNames, TSelectedDatapoint, TSensorMetadata, TypesValues } from '../types';
import { mapGroupNameToDatapointType } from './chart-type-group-name-converters';

export const parseOptions = (data: ILocationData[], metadata: TSensorMetadata): TSelectedDatapoint[] => {
  return data
    .map((location) => {
      const equipmentDatapoints = location.equipmentList
        .map((equipment) => {
          const metadataForEquipment = metadata[equipment.model];

          if (!metadataForEquipment) return [];

          return metadataForEquipment.map((datapoint) => ({
            id: `${equipment.manufacturerId}-${datapoint.name}`,
            name: datapoint.name,
            manufacturerId: equipment.manufacturerId,
            equipmentName: equipment.name,
            locationId: location.id,
            locationName: location.name,
            type: mapGroupNameToDatapointType(datapoint.group),
            label: datapoint.label ?? '',
            isDefault: datapoint.isDefault,
            isActive: false,
            parentType: TypesValues.Enum.equipment,
          }));
        })
        .flat();

      const sensorDatapoints = location.sensorsList
        .map((sensor) => {
          const metadataForSensor = metadata[sensor.model];
          if (!metadataForSensor || !metadataForSensor.length) return undefined;

          const group =
            sensor.type === 'FlowMeter' || sensor.type === 'PulseMeter'
              ? 'flow'
              : (sensor.type.toLowerCase() as TGroupNames);

          return {
            id: `${sensor.manufacturerId}-${sensor.name}`,
            name: metadataForSensor?.[0].name ?? sensor.name,
            manufacturerId: sensor.manufacturerId,
            equipmentName: content.NEXA_SENSORS,
            locationId: location.id,
            locationName: location.name,
            type: mapGroupNameToDatapointType(group),
            label: sensor.name,
            isDefault: false,
            isActive: false,
            parentType: TypesValues.Enum.sensor,
          };
        })
        .filter(Boolean) as TSelectedDatapoint[];

      return [...sensorDatapoints, ...equipmentDatapoints];
    })
    .flat();
};

export const getFilteredLocationData = ({
  equipmentData,
  locationData,
  selectedLocationId,
  selectedEquipmentId,
}: {
  locationData: TLocationResponseSchema['data'] | undefined;
  equipmentData: TEquipmentResponseSchema['data'] | undefined;
  selectedLocationId: string;
  selectedEquipmentId: string;
}): ILocationData[] => {
  if (!locationData) return [];

  const manufacturerIdToFilter =
    selectedEquipmentId && equipmentData
      ? equipmentData.find((equipment) => equipment.id === selectedEquipmentId)?.devices[0]?.manufacturerId
      : undefined;

  return locationData
    .filter((location) => !selectedLocationId || location.id === selectedLocationId)
    .map((location) => ({
      id: location.id,
      name: location.name,
      equipmentList: location.devices.filter(
        (device) =>
          device.type === 'Equipment' && (!manufacturerIdToFilter || device.manufacturerId === manufacturerIdToFilter)
      ),
      sensorsList: manufacturerIdToFilter ? [] : location.devices.filter((device) => device.type !== 'Equipment'),
    }));
};
