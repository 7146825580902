import { useContext } from 'react';

import { DeviceDrawerContext } from './device-drawer-context';

export const useDeviceDrawerContext = () => {
  const { deviceDrawerState, deviceDrawerDispatch } = useContext(DeviceDrawerContext);

  return {
    deviceDrawerState,
    deviceDrawerDispatch,
  };
};
