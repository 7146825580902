import { IAlertsQueryParam, routes } from '@marlin/shared/utils-routes';

interface IUseNavigation {
  getAlertsPageLink: () => string;
  getAlertsPageWithIdLink: (params?: IAlertsQueryParam) => string;
}

export const useNavigation = (): IUseNavigation => {
  return {
    getAlertsPageLink: () => routes.alerts.root,
    getAlertsPageWithIdLink: (params?: IAlertsQueryParam) => routes.alerts.list.url(params),
  };
};
