import { TSublocationAssetSchema } from '@marlin/asset/data-access/location';
import { AssetMobileCard } from '@marlin/shared/ui-asset-card-mobile';
import { ActionDelete, ActionEdit, ContextMenu } from '@marlin/shared/ui-context-menu';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { PaginationMobile } from '@marlin/shared/ui-pagination-mobile';
import { DEVICE_DRAWER_ACTION_TYPE, useDeviceDrawerContext } from '@marlin/shared/utils/sensor-provider';
import { useCallback } from 'react';

import { IAsset } from '../../../desktop/components/grid/types';
import { TSetMobileLocationDetailsFilters } from '../../types';
import { content } from './content';
import { useStyles } from './linked-items.styles';
import { useDeleteAssetModal } from './use-delete-asset-modal.hook';

interface IAssetProps {
  rows: TSublocationAssetSchema[];
  deviceDrawerRows: TSublocationAssetSchema[];
  allowDelete: boolean;
  allowEdit: boolean;
  handleAfterDelete: () => void;
  refetch: () => void;
  editAsset: (asset: IAsset) => void;
  setMobileFilters: TSetMobileLocationDetailsFilters;
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  totalItems?: number;
  isListLoading: boolean;
}

export const AssetList = ({
  rows,
  allowDelete,
  allowEdit,
  editAsset,
  handleAfterDelete,
  refetch,
  hasNextPage,
  fetchNextPage,
  totalItems,
  isListLoading,
  deviceDrawerRows,
}: IAssetProps) => {
  const { classes } = useStyles();
  const handleDelete = useDeleteAssetModal({ refetch, handleAfterDelete });
  const { deviceDrawerDispatch } = useDeviceDrawerContext();

  const addSensorsFromContext = useCallback(() => {
    deviceDrawerDispatch({
      type: DEVICE_DRAWER_ACTION_TYPE.SET_SENSORS,
      payload: {
        rows: deviceDrawerRows,
      },
    });
  }, [deviceDrawerRows, deviceDrawerDispatch]);

  if (isListLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {rows.length > 0 ? (
        rows.map((item: TSublocationAssetSchema) => (
          <AssetMobileCard
            addSensorsFromContext={addSensorsFromContext}
            isEquipment={item.type === 'EQUIPMENT'}
            key={item.id}
            asset={item}
          >
            <ContextMenu className={classes.mobileMenu}>
              {allowEdit && <ActionEdit onClick={() => editAsset(item)} />}
              {allowDelete && <ActionDelete onClick={() => handleDelete(item)} />}
            </ContextMenu>
          </AssetMobileCard>
        ))
      ) : (
        <div className={classes.emptyState}>{content.NO_DATA}</div>
      )}
      <PaginationMobile
        totalItems={totalItems}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        loadedItems={rows.length}
      />
    </>
  );
};
