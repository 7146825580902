import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { getLogger } from '@marlin/shared/utils/logger';
import { TGraphNodeData, useUpdateNode } from '@marlin/system-map/data-access/system-map';
import { useCallback, useMemo, useState } from 'react';

import { content } from './content';

export type TTeeGraphAsset = TGraphNodeData & { nodeType: 'TEE' };

interface IRenameTee {
  name: string;
}

interface IUseRenameTee {
  teeToRename: TTeeGraphAsset | null;
  closeRenameTeeModal: () => void;
  openRenameTeeModal: (teeToRename: TTeeGraphAsset) => void;
  renameTee: (values: IRenameTee) => void;
}

export const useRenameTee = (): IUseRenameTee => {
  const [teeToRename, setTeeToRename] = useState<TTeeGraphAsset | null>(null);
  const updateNodeMutation = useUpdateNode();
  const { enqueueSnackbar } = useSnackbar();

  const openRenameTeeModal = useCallback((teeToRename: TTeeGraphAsset) => {
    setTeeToRename(teeToRename);
  }, []);

  const closeRenameTeeModal = useCallback(() => {
    setTeeToRename(null);
  }, []);

  const renameTee = useCallback(
    async ({ name }: IRenameTee) => {
      if (!teeToRename) {
        return;
      }

      try {
        await updateNodeMutation.mutateAsync({
          nodeId: teeToRename.id,
          data: { name },
        });
        closeRenameTeeModal();
        enqueueSnackbar(content.RENAME_TEE_SUCCESS_MESSAGE, {
          variant: 'success',
          preventDuplicate: true,
        });
        getLogger()?.track('RenameTee', { teeId: teeToRename?.id, name });
      } catch (e) {
        enqueueSnackbar(content.RENAME_TEE_ERROR_MESSAGE, {
          variant: 'error',
          preventDuplicate: true,
        });
      }
    },
    [closeRenameTeeModal, enqueueSnackbar, teeToRename, updateNodeMutation]
  );

  return useMemo(
    () => ({
      teeToRename,
      closeRenameTeeModal,
      openRenameTeeModal,
      renameTee,
    }),
    [closeRenameTeeModal, openRenameTeeModal, renameTee, teeToRename]
  );
};
