import { Typography } from '@mui/material';
import { PropsWithChildren } from 'react';

import { useStyles } from './form-section.styles';

interface IFormSectionProps extends PropsWithChildren {
  title?: string;
  testId: string;
}

export const FormSection = ({ title, children, testId }: IFormSectionProps) => {
  const { classes } = useStyles();
  return (
    <div className={classes.formSection} data-testid={testId}>
      {title && (
        <div className={classes.formSectionTitle}>
          <Typography fontWeight="bold">{title}</Typography>
        </div>
      )}
      <div className={classes.formSectionContent}>{children}</div>
    </div>
  );
};
