import { THomeLocationSchema } from '@marlin/asset/data-access/home-dashboard';
import { MarlinTheme } from '@marlin/shared/theme';
import { Link } from '@marlin/shared/ui-page';
import { routes } from '@marlin/shared/utils-routes';
import { makeStyles } from 'tss-react/mui';

import { HomeCardHeader } from '../card-header';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
    minHeight: theme.typography.pxToRem(56),
    backgroundColor: theme.palette.background.alternative,
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.typography.pxToRem(12),
      paddingBottom: theme.typography.pxToRem(12),
      paddingLeft: theme.typography.pxToRem(12),
      paddingRight: theme.typography.pxToRem(12),
    },
  },

  label: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: theme.typography.pxToRem(24),
  },

  icon: {
    marginRight: theme.typography.pxToRem(8),
    width: theme.typography.pxToRem(16),
    color: theme.palette.action.active,
  },

  locationName: {
    fontSize: theme.typography.body1.fontSize,
    letterSpacing: theme.typography.body1.letterSpacing,
    fontWeight: theme.typography.fontWeightBold,
    cursor: 'default',
  },

  link: {
    textDecoration: 'none',
    cursor: 'default',
    color: theme.palette.text.primary,
  },
}));

interface IHomeCardProps {
  homeLocation: THomeLocationSchema;
}

export const MobileHomeCard = ({ homeLocation }: IHomeCardProps) => {
  const { classes } = useStyles();

  return (
    <Link to={routes.locations.details.url(homeLocation.locationId)} className={classes.link}>
      <HomeCardHeader location={homeLocation} isMobile />
    </Link>
  );
};
