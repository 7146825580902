import { MarlinTheme } from '@marlin/shared/theme';
import { useRouter } from '@marlin/shared/utils-router';
import { useTheme } from '@mui/material';
import { useMemo } from 'react';

import { content } from './content';
import { ErrorPageLayout } from './page-layout.component';

interface INotFoundPage {
  onlyContent?: boolean;
  customBackFunction?: () => void;
}

export const NotFoundPage = ({ onlyContent, customBackFunction }: INotFoundPage) => {
  const { goTo } = useRouter();
  const theme = useTheme<MarlinTheme>();

  const backFunction = useMemo(() => {
    if (customBackFunction) {
      return customBackFunction;
    }
    return () => goTo('/');
  }, [customBackFunction, goTo]);

  return (
    <ErrorPageLayout
      content={content.NOT_FOUND}
      onBackClick={backFunction}
      onlyContent={onlyContent}
      errorIcon={theme.icon.notFoundLogo}
    />
  );
};
