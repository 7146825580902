import isNil from 'lodash/isNil';

import { useContent } from '../../../../context';
import { Deadband } from '../deadband';
import { TCondition } from '../paths.model';
import { Threshold } from '../threshold.component';

interface IConditionProps {
  conditionInputName: TCondition;
  conditionLabel: string;
  conditionCheckboxLabel: string;
  unit: string;
}

export const Condition = ({
  conditionInputName: { threshold, deadband },
  conditionLabel,
  conditionCheckboxLabel,
  unit,
}: IConditionProps) => {
  const content = useContent();

  return (
    <>
      <Threshold name={threshold} label={conditionCheckboxLabel} placeholder={conditionLabel} unit={unit}>
        {({ enabled, value }) => (
          <Deadband
            name={deadband}
            disabled={!enabled || isNil(value)}
            label={content.CONDITIONS.DEADBAND}
            unit={unit}
          />
        )}
      </Threshold>
    </>
  );
};
