import { queryClient, useMutation } from '@marlin/shared/utils/react-query';
import { z } from 'zod';

import { editUser } from '../infrastructure/user';
import { queryKey } from './query-key.enum';

type TUseUserOptions = {
  userId: string;
};

export const useUpdateUser = ({ userId }: TUseUserOptions) => {
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queryKey.USER(userId) });
    },
    mutationFn: editUser,
    onError: (error) => {
      // eslint-disable-next-line no-console
      if (error instanceof z.ZodError) console.log(error.issues);
    },
  });
};
