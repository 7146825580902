import { MarlinTheme } from '@marlin/shared/theme';
import { alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { TType, TYPE } from './feature-card.component';

interface IStyles {
  type?: TType;
}

export const useStyles = makeStyles<IStyles>()((theme: MarlinTheme, { type }) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    position: 'relative',
    padding: theme.typography.pxToRem(24),
    borderRadius: theme.typography.pxToRem(4),
    backgroundColor:
      type === TYPE.SYSTEM_MAP
        ? alpha(theme.palette.secondary.main, 0.08)
        : alpha(theme.palette.alternative.main, 0.08),
  },
  leftText: {
    flexDirection: 'column',
    flexGrow: 1,
  },
  title: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.typography.pxToRem(12),
  },
  text: {
    paddingTop: theme.typography.pxToRem(16),
    fontSize: theme.typography.pxToRem(14),
  },
  titleNoDevices: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
  },
  rightItems: {
    alignItems: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    color: type === TYPE.SYSTEM_MAP ? theme.palette.secondary.main : theme.palette.alternative.main,
    marginBottom: theme.typography.pxToRem(16),
  },
  iconNoDevices: {
    color: type === TYPE.SYSTEM_MAP ? theme.palette.secondary.main : theme.palette.alternative.main,
    marginTop: theme.typography.pxToRem(10),
    marginBottom: theme.typography.pxToRem(0),
  },
  button: {
    width: theme.typography.pxToRem(55),
    height: theme.typography.pxToRem(30),
  },
}));
