export enum TEASER_MODAL_ACTION_TYPE {
  SHOW_TEASER_MODAL = 'show_teaser_modal',
  SHOW_BOOKING_MODAL = 'show_booking_modal',
  DISMISS = 'dismiss',
}

export enum SLIDE {
  SYSTEM_MAP,
  ANALYTICS,
  AUTOMATION,
}
