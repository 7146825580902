import { PERMISSIONS, usePermission } from '@marlin/shared/utils-permission';
import { routes } from '@marlin/shared/utils-routes';
import { useCallback, useMemo } from 'react';

import { useRouter } from './use-router.hook';

interface IUseEquipmentDetailsContextMenu {
  allowDelete: boolean;
  allowEdit: boolean;
  editDevice: (deviceId: string) => void;
}

export const useEquipmentDetailsContextMenu = (): IUseEquipmentDetailsContextMenu => {
  const { goTo } = useRouter();

  const allowDelete = usePermission(PERMISSIONS.DELETE_EQUIPMENT);
  const allowEdit = usePermission(PERMISSIONS.EDIT_EQUIPMENT);

  const editDevice = useCallback(
    (deviceId: string) => {
      const editDeviceUrl = routes.sensors.update.url(deviceId);
      goTo(editDeviceUrl);
    },
    [goTo]
  );

  return useMemo(
    () => ({
      allowDelete,
      allowEdit,
      editDevice,
    }),
    [allowDelete, allowEdit, editDevice]
  );
};
