import { MarlinTheme } from '@marlin/shared/theme';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import { IconButton, Menu } from '@mui/material';
import { Attributes, MouseEvent, ReactElement, useCallback } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useChildrenOverride } from './use-children-override.hook';
import { useMenu } from './use-menu.hook';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  menu: {
    '& svg': {
      fontSize: theme.typography.pxToRem(24),
    },
  },
}));

interface IChildProps extends Attributes {
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
}

type TMenuElement = ReactElement<IChildProps> | null | undefined | boolean;

interface IContextMenuProps {
  children?: TMenuElement[] | TMenuElement;
  className?: string;
  icon?: JSX.Element;
}

export const ContextMenu = ({ children = [], className, icon }: IContextMenuProps) => {
  const { classes, cx } = useStyles();
  const { menuAnchor, isMenuOpen, open, close } = useMenu();

  const overrideOnClick = useCallback(
    (child: ReactElement<IChildProps>) => ({
      onClick: (event: MouseEvent<HTMLButtonElement>) => {
        close(event);
        if (child.props.onClick) {
          child.props.onClick(event);
        }
      },
    }),
    [close]
  );

  const childrenWithOnClose = useChildrenOverride<IChildProps>(children, overrideOnClick);

  if (!childrenWithOnClose || (Array.isArray(childrenWithOnClose) && !childrenWithOnClose.length)) {
    return null;
  }

  return (
    <>
      <IconButton data-testid="actions-button" onClick={open} className={cx(classes.menu, className)}>
        {icon || <MoreHorizRoundedIcon />}
      </IconButton>

      <Menu data-testid="actionsMenu" anchorEl={menuAnchor} open={isMenuOpen} onClose={close}>
        {childrenWithOnClose}
      </Menu>
    </>
  );
};
