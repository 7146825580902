import { ComponentType, PropsWithChildren } from 'react';

import { ConfigureGateway } from './components/configure-gateway.component';
import { TestGatewayConfiguration } from './components/test-configuration/test-gateway-configuration.component';
import { TEST_GATEWAY_STEPS, TestGatewayProvider, useTestGatewayContext } from './context/test-flow.context';

const ConfigurationFlowWrapper = () => {
  const { step } = useTestGatewayContext();

  switch (step) {
    case TEST_GATEWAY_STEPS.CONFIGURE:
      return <ConfigureGateway />;
    case TEST_GATEWAY_STEPS.TEST_RESULTS:
      return <TestGatewayConfiguration />;
    default:
      return <ConfigureGateway />;
  }
};

export const withTestGatewayStepsProvider =
  (Cmp: ComponentType<PropsWithChildren>) =>
  (props: PropsWithChildren): JSX.Element => {
    return (
      <TestGatewayProvider>
        <Cmp {...props} />
      </TestGatewayProvider>
    );
  };

export const ConfigurationFlow = withTestGatewayStepsProvider(ConfigurationFlowWrapper);
