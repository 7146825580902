import { UserAccountExistGuard } from '@marlin/account-feature-welcome-page';
import { ValidateInvitation, ValidateSupportInvitation } from '@marlin/account-ui-invitation-validate';
import { Onboarding } from '@marlin/account/feature/onboarding';
import { AlertLink } from '@marlin/alert/feature/alert-list';
import { HomeDashboardHub } from '@marlin/asset/feature/home-dashboard-hub';
import { Home } from '@marlin/asset/feature/home-hub';
import { environment } from '@marlin/environment';
import { NotFoundPage } from '@marlin/shared/ui-page';
import { PERMISSIONS, usePermission } from '@marlin/shared/utils-permission';
import { routes } from '@marlin/shared/utils-routes';
import { Route, Routes } from 'react-router-dom';

import { FullPageLayout } from './layouts/full-page-layout.component';
import { HomeLayout } from './layouts/home-layout.component';
import { SideNavLayout } from './layouts/side-nav-layout.component';
import { AlertRoutes } from './routes/alert.route';
import { AnalyticsV2Routes } from './routes/analytics-v2.route';
import { EmbeddedAnalyticsRoutes } from './routes/analytics.route';
import { AutomationRoutes } from './routes/automation.route';
import { EquipmentDashboardRoutes } from './routes/equipment-dashboard.route';
import { EquipmentRoutes } from './routes/equipment.route';
import { FlowMapHub } from './routes/flow-map.route';
import { GatewayRoutes } from './routes/gateway.route';
import { HelpHubRoutes } from './routes/help.route';
import { LocationRoutes } from './routes/location.route';
import { OrgIdQueryGuard } from './routes/orgid-query.guard';
import { SensorRoutes } from './routes/sensor.route';
import { SettingsHubRoutes } from './routes/settings.route';

const baseUrl = '/';

export const AppRoutes = () => {
  const isTier = usePermission(PERMISSIONS.SENSOR_DEVICES);

  return (
    <Routes>
      <Route
        path={routes.welcome.root}
        element={
          <FullPageLayout>
            <Onboarding />
          </FullPageLayout>
        }
      />

      <Route path={routes.invitation.root}>
        <Route
          path={routes.invitation.validate.path}
          element={
            <FullPageLayout>
              <ValidateInvitation redirectUrl={baseUrl} />
            </FullPageLayout>
          }
        />
        <Route
          path={routes.invitation.supportValidate.path}
          element={
            <FullPageLayout>
              <ValidateSupportInvitation />
            </FullPageLayout>
          }
        />
      </Route>

      {[routes.alerts.alertLink.root, routes.alerts.alertLink.path].map((path) => (
        <Route
          key={path}
          path={path}
          element={
            <UserAccountExistGuard redirectTo={routes.welcome.root}>
              <AlertLink />
            </UserAccountExistGuard>
          }
        />
      ))}

      <Route
        path="*"
        element={
          <UserAccountExistGuard redirectTo={routes.welcome.root}>
            <OrgIdQueryGuard>
              <SideNavLayout>
                <Routes>
                  <Route path={routes.home.root} element={<HomeLayout />} />
                  <Route path={routes.home.dashboard} element={<HomeDashboardHub />} />
                  <Route path={routes.home.locations} element={<Home />} />
                  {/* Note: we need to call running-trend-tile as fucntion, because React Router v6 doesn't allow nested routes declared as running-trend-tile */}
                  {SensorRoutes()}
                  {LocationRoutes()}
                  {EquipmentRoutes()}
                  {EquipmentDashboardRoutes()}
                  {FlowMapHub()}
                  {EmbeddedAnalyticsRoutes()}
                  {AnalyticsV2Routes()}
                  {AutomationRoutes()}
                  {AlertRoutes()}
                  {HelpHubRoutes()}
                  {SettingsHubRoutes()}
                  {(isTier || (!isTier && environment.module.features.gateway)) && GatewayRoutes()}
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
              </SideNavLayout>
            </OrgIdQueryGuard>
          </UserAccountExistGuard>
        }
      />
    </Routes>
  );
};
