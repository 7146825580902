import { useSearchFilter } from '@marlin/shared/utils/url-params';
import { Box } from '@mui/material';
import { useMemo } from 'react';

import { useFilteredAssetsLocationWithLoadMore } from '../queries/get-assets-location.command';
import { TAsset, TAssetResponseSchema } from '../schemas/assets/assets.schema';
import { useEnableOption } from './use-enable-option';

interface ILocationSearchWrapper {
  options: TAsset[];
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  totalItems?: number;
  loadedItems?: number;
  isLoading?: boolean;
  isError?: boolean;
  search: (value: string) => void;
  term: string;
  isFetching: boolean;
  isFetchingNextPage: boolean;
  enableOptions: () => void;
}

interface ILocationSearchWrapperProps {
  children: (props: ILocationSearchWrapper) => JSX.Element;
  parentLocation?: string;
  showUsedByLocations?: boolean;
  enableSearch?: boolean;
  useEmptyOption?: boolean;
}

const emptyOption: TAsset = {
  id: '',
  name: '',
};

export function LocationSearchWrapper({
  children,
  parentLocation,
  showUsedByLocations,
  enableSearch = true,
  useEmptyOption,
}: ILocationSearchWrapperProps) {
  const { term, debouncedTerm, search } = useSearchFilter();
  const { enabled, enableOptions } = useEnableOption();

  const locationsQuery = useFilteredAssetsLocationWithLoadMore({
    params: { search: enableSearch ? debouncedTerm : undefined, pageSize: 10, parentLocation, showUsedByLocations },
    enabled,
  });

  const options: TAsset[] = useMemo(() => {
    const unfilteredRows: (TAsset | null)[] =
      locationsQuery.data?.pages?.flatMap((i: TAssetResponseSchema) => i.data) || [];
    const filteredRows = unfilteredRows.filter((i: TAsset | null): i is TAsset => i !== null);
    if (useEmptyOption) {
      return [emptyOption, ...filteredRows];
    }
    return filteredRows;
  }, [useEmptyOption, locationsQuery.data?.pages]);

  return (
    <Box onClick={enableOptions}>
      {children({
        options,
        hasNextPage: locationsQuery.hasNextPage,
        totalItems: locationsQuery.data?.pages[0].pagination.totalItems || 0,
        fetchNextPage: locationsQuery.fetchNextPage,
        isLoading: locationsQuery.isLoading,
        isError: locationsQuery.isError,
        isFetching: locationsQuery.isFetching,
        isFetchingNextPage: locationsQuery.isFetchingNextPage,
        search,
        term,
        enableOptions,
      })}
    </Box>
  );
}
