import { MarlinTheme } from '@marlin/shared/theme';
import { getLastReadingText } from '@marlin/shared/utils/format-alert';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { DEVICE_TYPE, ERROR_TYPES, IAlert } from '../../types';
import { LeakSection } from './leak-section.component';
import { LostCommunicationSection } from './lost-communication-section.component';
import { LowBatterySection } from './low-battery-section.component';
import { UnitSection } from './unit-section.component';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      alignItems: 'baseline',
      flexWrap: 'wrap',
    },
  },

  section: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.text.secondary,
  },
}));

interface ILastReadingSection {
  alert: IAlert;
}

const Section = ({ alert }: { alert: IAlert }): JSX.Element | null => {
  if (alert.errorType === ERROR_TYPES.LOW_BATTERY) {
    return <LowBatterySection />;
  }
  if (alert.errorType === ERROR_TYPES.LOST_COMMUNICATION) {
    return <LostCommunicationSection />;
  }

  const type = alert.device?.type;

  switch (type) {
    case DEVICE_TYPE.LEAK: {
      return <LeakSection />;
    }

    case DEVICE_TYPE.PRESSURE: {
      return <UnitSection alert={{ ...alert, device: { ...alert.device, type } }} />;
    }

    case DEVICE_TYPE.TEMPERATURE: {
      return <UnitSection alert={{ ...alert, device: { ...alert.device, type } }} />;
    }

    case DEVICE_TYPE.PULSE_METER: {
      return <UnitSection alert={{ ...alert, device: { ...alert.device, type } }} />;
    }

    default: {
      return null;
    }
  }
};

export const LastReadingSection = ({ alert }: ILastReadingSection) => {
  const { classes } = useStyles();
  const lastReadingMinutes = useMemo(() => getLastReadingText(alert.lastReading), [alert.lastReading]);

  return (
    <>
      <div className={classes.section} data-testid="last-reading-time">
        {lastReadingMinutes}
      </div>
      <div className={classes.wrapper} data-testid="last-reading-value">
        {<Section alert={alert} />}
      </div>
    </>
  );
};
