import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  success: {
    color: theme.palette.success.light,
  },
  warning: {
    color: theme.palette.warning.light,
  },
  error: {
    color: theme.palette.error.main,
  },
}));
