import { intellistationDataPointEventCodes } from '@marlin/shared/utils/datapoint-mappers';
import { RULE_CATEGORY, TNodeAlert } from '@marlin/system-map/data-access/system-map';
import flow from 'lodash/flow';
import isNil from 'lodash/isNil';

import { TStatus } from '../types';

const intellistationDataPointEventCodesLowerCase: Record<string, string[]> = Object.fromEntries(
  Object.entries(intellistationDataPointEventCodes).map(([key, value]) => [key.toLowerCase(), value])
);

export const mapAlertsToStatus = (alerts: TNodeAlert[]): TStatus => {
  if (alerts.some((alert) => alert.criticality === 'HIGH')) {
    return 'error';
  }

  if (alerts.some((alert) => alert.criticality === 'LOW')) {
    return 'warning';
  }

  return 'success';
};

export const getHighestStatus = (statuses: TStatus[]): TStatus => {
  if (statuses.some((status) => status === 'error')) {
    return 'error';
  }

  if (statuses.some((status) => status === 'warning')) {
    return 'warning';
  }

  return 'success';
};

const dataPointNamesToEventCodes = (datapointNames: string[]): string[] => {
  return datapointNames
    .map((datapointName) => {
      return intellistationDataPointEventCodesLowerCase[datapointName.toLowerCase()];
    })
    .flat();
};

export const getAlertsByRuleCategory = (alerts: TNodeAlert[], ruleCategory: RULE_CATEGORY): TNodeAlert[] => {
  return alerts.filter((alert) => alert.ruleCategory === ruleCategory);
};

export const getAlertsByDataPointNames = (alerts: TNodeAlert[], datapointNames: string[]): TNodeAlert[] => {
  const eventCodes = dataPointNamesToEventCodes(datapointNames).map((eventCode) => eventCode?.toLowerCase());

  return alerts.filter((alert) => eventCodes.includes(alert.deviceEventCode?.toLowerCase() || ''));
};

const getAlertMessages = (alerts: TNodeAlert[]): string[] => {
  const messages = alerts?.map((alert: TNodeAlert) => alert.title);
  return messages.filter((message) => !isNil(message)) as string[];
};

const getUniqueAlertTitles = (messages: string[]): string[] => {
  return Array.from(new Set(messages));
};

type TGetAlertMessagesByDatapointNames = (alerts: TNodeAlert[], deviceEventCodes: string[]) => string[];

export const getAlertMessagesByDatapointNames: TGetAlertMessagesByDatapointNames = flow([
  getAlertsByDataPointNames,
  getAlertMessages,
  getUniqueAlertTitles,
]);
