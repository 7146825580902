import { BehaviorSubject } from 'rxjs';

import { IConfig } from '../models/auth.model';

const defaultConfig: IConfig = {
  auth: {
    authority: '',
    clientId: '',
    knownAuthorities: [],
    redirectUri: '',
  },
  requestRedirect: {
    scopes: [],
  },
  b2cUrls: {
    editProfile: '',
    resetPassword: '',
  },
  system: {
    setupAutomaticSilentRefresh: false,
  },
  baseUrl: '',
};

export class ConfigService {
  private config$ = new BehaviorSubject<IConfig>(defaultConfig);

  public set = (config: IConfig): void => this.config$.next(config);

  public get = (): IConfig => this.config$.getValue();
}

export const configService = new ConfigService();
