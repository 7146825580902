import { EmailControl, FirstNameControl, LastNameControl, PhoneNumberControl } from '@marlin/shared/ui-form';
import { Grid } from '@mui/material';

import { IUpsertRecipientProfile } from './types';

export const FormContent = () => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <FirstNameControl<IUpsertRecipientProfile> fieldName="firstName" />
      </Grid>
      <Grid item xs={12}>
        <LastNameControl<IUpsertRecipientProfile> fieldName="lastName" />
      </Grid>
      <Grid item xs={12}>
        <EmailControl<IUpsertRecipientProfile> fieldName="email" />
      </Grid>
      <Grid item xs={12}>
        <PhoneNumberControl<IUpsertRecipientProfile> fieldName="phone" />
      </Grid>
    </Grid>
  );
};
