import { THomeLocationSchema } from '@marlin/asset/data-access/home-dashboard';
import { CardHeader } from '@marlin/asset/ui-home-hub-cards';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { useMemo } from 'react';

import { content } from '../../content';
import { useStyles } from './card-header.component.styles';
import { useCardHeader } from './use-card-header.hook';
import { useImpact } from './use-impact.hook';

type THomeCardHeaderProps = {
  location: THomeLocationSchema;
  isMobile?: boolean;
};

const HomeCardHeader = ({ location, isMobile }: THomeCardHeaderProps) => {
  const { classes, cx } = useStyles();
  const { parentLocationName, goToParentLocation, goToLocation } = useCardHeader(location);
  const { locationHasHighAlerts, locationHasLowAlerts, locationAlerts, locationAlertsLength } = useImpact(location);

  const impactCopy = useMemo(() => {
    if (locationHasHighAlerts && locationHasLowAlerts) {
      return content.IMPACT(locationAlertsLength);
    } else if (locationHasLowAlerts) {
      return content.IMPACT_LOW(locationAlertsLength);
    } else if (locationHasHighAlerts) {
      return content.IMPACT_HIGH(locationAlertsLength);
    } else if (locationAlerts.length) {
      return content.IMPACT(locationAlertsLength);
    }
    return content.IMPACT_NONE;
  }, [locationAlerts.length, locationAlertsLength, locationHasHighAlerts, locationHasLowAlerts]);

  const locationIcon = useMemo(() => {
    if (locationHasHighAlerts) {
      return <WarningRoundedIcon color="error" />;
    } else if (locationHasLowAlerts) {
      return <WarningRoundedIcon color="warning" />;
    }
    return <LocationOnRoundedIcon />;
  }, [locationHasHighAlerts, locationHasLowAlerts]);

  return (
    <CardHeader
      status={locationHasHighAlerts ? 'error' : locationHasLowAlerts ? 'warning' : undefined}
      isMobile={isMobile}
      title={location.locationName}
      onTitleClick={goToLocation}
      subtitle={parentLocationName}
      onSubtitleClick={goToParentLocation}
      impact={
        <>
          {content.IMPACT_INTRO}
          <span
            className={cx({
              [classes.impactAmountAlert]: locationHasHighAlerts || locationHasLowAlerts,
            })}
          >
            {impactCopy}
          </span>
        </>
      }
      icon={locationIcon}
    />
  );
};

export { HomeCardHeader };
