import { MarlinTheme } from '@marlin/shared/theme';
import { FormControl, InputLabel, Select } from '@mui/material';
import { PropsWithChildren } from 'react';
import { ControllerProps, ControllerRenderProps, FieldError, FieldValues } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { FormField } from '../form-fields/form-field';
import { IFormControlProps, IFormTypedProps } from '../form.types';
import { useInfiniteAutocomplete } from './use-infinite-autocomplete-list.hook';

type TRenderProps = Partial<Omit<ControllerRenderProps, 'ref'>>;

interface ISelectControlProps extends TRenderProps, PropsWithChildren {
  error?: FieldError;
  disabled?: boolean;
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  isLoading?: boolean;
  isError?: boolean;
  search: (value: string) => void;
  term: string;
  label: string;
  testId: string;
  isFetching: boolean;
  isFetchingNextPage: boolean;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  paper: {
    maxHeight: theme.typography.pxToRem(300),
    overflowY: 'scroll',
    [theme.breakpoints.down('md')]: {
      maxHeight: theme.typography.pxToRem(102),
    },
  },

  listbox: {
    maxHeight: 'unset',
  },
}));

const InfiniteSelect = ({
  hasNextPage,
  fetchNextPage,
  search,
  term,
  label,
  testId,
  isFetching,
  isFetchingNextPage,

  children,
  ...rest
}: ISelectControlProps) => {
  const { loadMore } = useInfiniteAutocomplete({ fetchNextPage, hasNextPage });
  const { classes } = useStyles();

  return (
    <FormControl fullWidth={true}>
      <InputLabel id="role-select-label" required={true}>
        {label}
      </InputLabel>
      <Select
        id="role-select"
        labelId="role-select-label"
        label={label}
        multiple={false}
        required
        fullWidth
        isLoading={isFetching || isFetchingNextPage}
        MenuProps={{
          PaperProps: {
            onScroll: loadMore,
            className: classes.paper,
          },
          MenuListProps: {
            className: classes.listbox,
          },
        }}
        {...rest}
        error={!!rest.error}
      >
        {children}
      </Select>
    </FormControl>
  );
};

export function InfiniteSelectControl<TFieldValues extends FieldValues>(
  props: IFormControlProps<TFieldValues> & ISelectControlProps
): JSX.Element;
export function InfiniteSelectControl<
  TFieldValues extends FieldValues = object,
  TName extends ControllerProps<TFieldValues>['name'] = ControllerProps<TFieldValues>['name']
>(props: IFormTypedProps<TFieldValues, TName> & ISelectControlProps): JSX.Element;

export function InfiniteSelectControl<
  TFieldValues extends FieldValues,
  TName extends ControllerProps<TFieldValues>['name'] = ControllerProps<TFieldValues>['name']
>({
  hasNextPage,
  isLoading,
  fetchNextPage,
  search,
  term,
  testId,
  label,
  children,
  isFetchingNextPage,
  isFetching,
  ...params
}: (IFormControlProps<TFieldValues> | IFormTypedProps<TFieldValues, TName>) & ISelectControlProps) {
  if (params.control) {
    return (
      <FormField {...params}>
        {(props) => (
          <InfiniteSelect
            {...props}
            disabled={params?.disabled}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            isLoading={isLoading}
            search={search}
            term={term}
            testId={testId}
            label={label}
            isFetchingNextPage={isFetchingNextPage}
            isFetching={isFetching}
            children={children}
          />
        )}
      </FormField>
    );
  }

  return (
    <FormField<TFieldValues> {...params}>
      {(props) => (
        <InfiniteSelect
          {...props}
          disabled={params?.disabled}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isLoading={isLoading}
          search={search}
          term={term}
          testId={testId}
          label={label}
          isFetchingNextPage={isFetchingNextPage}
          isFetching={isFetching}
          children={children}
        />
      )}
    </FormField>
  );
}
