import { useUnreadAlertsCount } from '@marlin/alert/data-access/alert-action';
import { MarlinTheme } from '@marlin/shared/theme';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import { IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';
import { ITotalItems } from './tabs/tabs.utils';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  info: {
    fontSize: theme.typography.pxToRem(12),
    display: 'flex',
    alignItems: 'center',
  },
  dot: {
    fontSize: theme.typography.pxToRem(10),
    marginRight: theme.typography.pxToRem(4),
  },
  update: {
    color: theme.palette.info.main,
  },
  success: {
    color: theme.palette.success.light,
  },
}));

interface IRefreshButtonProps {
  onRefreshButtonClick: () => Promise<unknown>;
  totalItems: ITotalItems | undefined;
}

export const RefreshButton = ({ onRefreshButtonClick, totalItems }: IRefreshButtonProps) => {
  const { classes, cx } = useStyles();
  const [alertsChanged, setAlertsChanged] = useState(false);
  const [alertsUpdated, setAlertsUpdated] = useState(false);
  const { unreadAlerts, isAlertNumberReady } = useUnreadAlertsCount();

  useEffect(() => {
    if (isAlertNumberReady && totalItems && unreadAlerts !== totalItems?.current) {
      setAlertsChanged(true);
    }
  }, [isAlertNumberReady, totalItems, unreadAlerts]);

  const handleRefreshButtonClick = () => {
    setAlertsChanged(false);
    onRefreshButtonClick().then(() => {
      setAlertsUpdated(true);
      setTimeout(() => {
        setAlertsUpdated(false);
      }, 3000);
    });
  };

  return (
    <div className={classes.container}>
      {alertsChanged && (
        <div className={classes.info}>
          <CircleRoundedIcon className={cx(classes.dot, classes.update)} />
          <span data-testid="alert-update-available">{content.ALERT_UPDATE_AVAILABLE}</span>
        </div>
      )}
      {alertsUpdated && (
        <div className={classes.info}>
          <CircleRoundedIcon className={cx(classes.dot, classes.success)} />
          <span data-testid="alert-update-success">{content.ALERT_UPDATE_SUCCESS}</span>
        </div>
      )}
      <IconButton aria-label="refresh-alerts" onClick={handleRefreshButtonClick} data-testid="refresh-button">
        <RefreshRoundedIcon />
      </IconButton>
    </div>
  );
};
