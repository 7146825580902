import BoltRoundedIcon from '@mui/icons-material/BoltRounded';
import { Button } from '@mui/material';

import { useStyles } from './teaser-button.styles';

interface ITeaserButtonProps {
  onClick?: () => void;
  variant: 'main' | 'collapsed';
  text?: string;
}

export const TeaserButton = ({ onClick, variant, text }: ITeaserButtonProps) => {
  const { classes } = useStyles();

  if (variant === 'collapsed') {
    return (
      <Button data-testid={'teaser-btn-collapsed'} className={classes.collapsed} variant="contained" onClick={onClick}>
        <BoltRoundedIcon className={classes.icon} />
      </Button>
    );
  }
  if (variant === 'main') {
    return (
      <Button data-testid={'teaser-btn-main'} className={classes.main} variant="contained" onClick={onClick}>
        <BoltRoundedIcon className={classes.icon} />
        {text}
      </Button>
    );
  }

  return null;
};
