import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const headerHeight = 48;

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  header: {
    background: theme.palette.primary.main,
    height: theme.typography.pxToRem(headerHeight),
    minHeight: theme.typography.pxToRem(headerHeight),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));
