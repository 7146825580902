import { getListener, initializeListenerConnection } from '@marlin/shared/utils-realtime-communication';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { SystemMapFullNotificationSchema, TSystemMapFullNotification } from '../flow-map.model.schema';
import { SYSTEM_MAP_EVENTS } from './system-map-events.enum';

export class TimeoutError extends Error {}

export const getSystemMapFullNotificationSubject = (): BehaviorSubject<TSystemMapFullNotification> => {
  return getListener<TSystemMapFullNotification>(SYSTEM_MAP_EVENTS.SEND_SYSTEM_MAP_FULL, { organizationId: undefined });
};

const mapOperator = (
  source$: Observable<TSystemMapFullNotification | undefined>
): Observable<TSystemMapFullNotification | undefined> =>
  source$.pipe(
    map((notification: TSystemMapFullNotification | undefined) => {
      const parsedResult = SystemMapFullNotificationSchema.safeParse(notification);
      if (!parsedResult.success) {
        return undefined;
      }
      return parsedResult.data;
    }),
    catchError((error) => {
      return throwError(error);
    })
  );

export const initSubject = (): Observable<void> => {
  return initializeListenerConnection<TSystemMapFullNotification | undefined>(
    SYSTEM_MAP_EVENTS.SEND_SYSTEM_MAP_FULL,
    undefined,
    mapOperator
  );
};
