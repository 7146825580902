import { MarlinTheme } from '@marlin/shared/theme';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: theme.typography.pxToRem(48),
    marginBottom: theme.typography.pxToRem(32),
  },
  title: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(34),
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
    letterSpacing: theme.typography.pxToRem(0.15),
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(20),
      marginBottom: theme.typography.pxToRem(8),
    },
  },
  subtitle: {
    fontFamily: 'Inter',
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.text.primary,
    letterSpacing: theme.typography.pxToRem(0.25),
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },
}));

interface IStepHeaderProps {
  title: string;
  subtitle: string;
}

export const StepHeader = ({ title, subtitle }: IStepHeaderProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <Typography className={classes.title} data-testid="header-title">
        {title}
      </Typography>
      <Typography className={classes.subtitle} data-testid="header-subtitle">
        {subtitle}
      </Typography>
    </div>
  );
};
