import {
  TransformDeviceUnitOfMeasuresToApi,
  UnitOfMeasure,
  UnitOfMeasureType,
} from '@marlin/shared/utils-format-reading';
import { z } from 'zod';

export const BaseCalibrationSchema = z.object({
  startMeasurementValue: z.coerce.number().nullish(),
  startMeasurementTimestamp: z.string().nullish(),
  endMeasurementValue: z.coerce.number().nullish(),
  endMeasurementTimestamp: z.string().nullish(),
  unitsPerPulse: z.coerce.number().nullish(),
});

export const CalibrationParamsSchema = BaseCalibrationSchema.extend({
  measurementUnit: UnitOfMeasure,
});

export const CalibrationRequestSchema = BaseCalibrationSchema.extend({
  measurementUnit: UnitOfMeasure.or(z.literal('')).transform((unitOfMeasureRequest) => {
    const unitOfMeasure = TransformDeviceUnitOfMeasuresToApi.safeParse(unitOfMeasureRequest);
    return unitOfMeasure.success ? unitOfMeasure.data : undefined;
  }),
});

export const CalibrationResponseSchema = BaseCalibrationSchema.extend({
  measurementUnit: UnitOfMeasureType,
  calibrationId: z.string(),
  deviceId: z.string().nullish(),
  calculationTimestamp: z.string().nullish(),
});

export const SavedCalibrationsRequestSchema = z.object({
  deviceIds: z.array(z.string()).nullish(),
  manufacturerIds: z.array(z.string()).nullish(),
  completed: z.boolean().nullish(),
  search: z.string().nullish(),
  pageSize: z.number(),
  page: z.number(),
  sorting: z
    .object({
      direction: z.union([z.literal('Ascending'), z.literal('Descending')]),
      sortBy: z.literal('CalibrationTimestamp'),
    })
    .optional(),
});

export const SavedCalibrationsResponseSchema = z.object({
  pagination: z.object({
    totalItems: z.number(),
    page: z.number(),
    pageSize: z.number(),
  }),
  data: z
    .array(
      z.object({
        calibrationId: z.string().nullish(),
        deviceId: z.string(),
        startMeasurementValue: z.number().nullish(),
        startMeasurementTimestamp: z.string().nullish(),
        endMeasurementValue: z.number().nullish(),
        endMeasurementTimestamp: z.string().nullish(),
        measurementUnit: UnitOfMeasureType,
        unitsPerPulse: z.number().nullish(),
        calculationTimestamp: z.string().nullish(),
      })
    )
    .nullish(),
});

export const LatestSavedCalibrationResponseSchema = z
  .object({
    pagination: z.object({
      totalItems: z.number(),
      page: z.number(),
      pageSize: z.number(),
    }),
    data: z
      .array(
        z.object({
          calibrationId: z.string().nullish(),
          deviceId: z.string(),
          startMeasurementValue: z.number().nullish(),
          startMeasurementTimestamp: z.string().nullish(),
          endMeasurementValue: z.number().nullish(),
          endMeasurementTimestamp: z.string().nullish(),
          measurementUnit: UnitOfMeasureType.transform((unitOfMeasure) => {
            if (unitOfMeasure === null) return '';
            return unitOfMeasure;
          }),
          unitsPerPulse: z.number().nullish(),
          calculationTimestamp: z.string().nullish(),
        })
      )
      .nullish(),
  })
  .transform((data) => {
    return data?.data?.[0] ?? null;
  });

export type TCalibrationResponseSchema = z.infer<typeof CalibrationResponseSchema>;
export type TCalibrationParamsSchema = z.infer<typeof CalibrationParamsSchema>;
export type TSavedCalibrationsRequestSchema = z.infer<typeof SavedCalibrationsRequestSchema>;
export type TSavedCalibrationsResponseSchema = z.infer<typeof SavedCalibrationsResponseSchema>;
export type TLatestSavedCalibrationResponseSchema = z.infer<typeof LatestSavedCalibrationResponseSchema>;
