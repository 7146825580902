import { MarlinTheme } from '@marlin/shared/theme';
import { Button, Grid } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';
import { ICreateRecipient } from './types';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    marginTop: theme.typography.pxToRem(8),
  },
}));

interface IAutomatedActionButtonProps {
  onSubmit: (data: ICreateRecipient) => void;
  onCancel: () => void;
}

export function Buttons({ onCancel, onSubmit }: IAutomatedActionButtonProps) {
  const { classes } = useStyles();
  const { handleSubmit, formState } = useFormContext<ICreateRecipient>();

  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item xs={12} md={6}>
        <Button fullWidth variant="outlined" data-testid="create-recipient-cancel-button" onClick={onCancel}>
          {content.BUTTONS.CANCEL}
        </Button>
      </Grid>
      <Grid item xs={12} md={6}>
        <Button
          fullWidth
          data-testid="create-recipient-confirm-button"
          variant="contained"
          disabled={!formState.isDirty || !formState.isValid}
          onClick={handleSubmit(onSubmit)}
        >
          {content.BUTTONS.CONFIRM}
        </Button>
      </Grid>
    </Grid>
  );
}
