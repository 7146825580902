import { MarlinTheme } from '@marlin/shared/theme';
import { Button, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';
import { useMobileFilterFormContext } from './mobile-filter-form-provider.component';
import { IBaseFilters } from './model';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.typography.pxToRem(16),
  },

  title: {
    fontSize: theme.typography.pxToRem(14),
    letterSpacing: theme.typography.body1.letterSpacing,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export function MobileFilterHeader<TSort extends string, TFilters extends IBaseFilters<TSort>>({
  children,
}: PropsWithChildren) {
  const { classes } = useStyles();

  const { submit } = useMobileFilterFormContext<TSort, TFilters>();

  return (
    <>
      <div className={classes.header}>
        {children && (
          <Typography variant="h5" className={classes.title} data-testid="filters-title">
            {children}
          </Typography>
        )}
        <Button onClick={submit}>{content.HIDE_FILTERS_BUTTON}</Button>
      </div>
    </>
  );
}

export default MobileFilterHeader;
