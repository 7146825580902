import { useAnalyticsTelemetry } from '@marlin/asset/data-access/telemetry';
import { AVERAGING_FUNCTION_FILTER } from '@marlin/shared/utils-chart';
import { ColorScale, Data } from 'plotly.js';

import { IChartSettings } from './types';

const leakTelemetry = [
  {
    manufacturerId: 'sentinel-220220036',
    datapoints: ['InLdsAlarm'],
  },
  {
    manufacturerId: 'monnit-313124',
    datapoints: ['Leak'],
  },
  {
    manufacturerId: 'monnit-399999',
    datapoints: ['Leak'],
  },
];

interface IUseLeakHeatmapData {
  chartSettings: IChartSettings;
}

export const useLeakHeatmapData = ({ chartSettings }: IUseLeakHeatmapData) => {
  const { data, isLoading, isFetching } = useAnalyticsTelemetry({
    dateFrom: chartSettings.minDate?.toISOString() ?? '',
    dateTo: chartSettings.maxDate?.toISOString() ?? '',
    requestedTelemetry: leakTelemetry,
    numberOfBuckets: chartSettings.buckets,
    averagingFunctionFilter: AVERAGING_FUNCTION_FILTER.AVERAGE,
    chartDisplayType: 'bar',
    bucketOption: 'months',
    isCustomPeriod: false,
  });

  const xValues = new Set<Date>();
  const yValues = new Set<string>();
  const zValues = [] as number[][];

  data?.telemetryData.forEach((telemetry) => {
    telemetry.values.forEach((data) => {
      xValues.add(new Date(data.x));
    });
  });

  data?.telemetryData.forEach((telemetry) => {
    yValues.add(telemetry.manufacturerId);
  });

  data?.telemetryData.forEach((telemetry) => {
    const zValue = [] as number[];
    telemetry.values.forEach((data) => {
      zValue.push(data.y === '1.0' ? 1 : 0);
    });
    zValues.push(zValue);
  });

  const chartData = [
    {
      x: Array.from(xValues),
      y: Array.from(yValues),
      z: Array.from(zValues),
      type: 'heatmap' as const,
      colorscale: [] as ColorScale,
      showscale: false,
      xgap: 5,
      ygap: 5,
    } as Data,
  ];

  return {
    chartData,
    isLoading,
    isFetching,
  };
};
