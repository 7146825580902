import { Position } from '@xyflow/react';

import { TSystemMapInternalNode } from '../../types';
import { TControlPointData } from './path/types';

interface IHandlePosition {
  primary: Position;
  secondary: Position;
}

// returns the position (top,right,bottom or right) passed node compared to
function getParams(nodeA?: TSystemMapInternalNode, nodeB?: TSystemMapInternalNode, nodeAHandleId?: string | null) {
  if (!nodeA || !nodeB) {
    return { x: 0, y: 0, position: Position.Top };
  }
  const centerA = getNodeCenter(nodeA);
  const centerB = getNodeCenter(nodeB);

  const horizontalDiff = Math.abs(centerA.x - centerB.x);
  const verticalDiff = Math.abs(centerA.y - centerB.y);
  const horizontalPosition = centerA.x > centerB.x ? Position.Left : Position.Right;
  const verticalPostion = centerA.y > centerB.y ? Position.Top : Position.Bottom;

  let position: IHandlePosition;

  // when the horizontal difference between the nodes is bigger, we use Position.Left or Position.Right for the handle
  if (horizontalDiff > verticalDiff) {
    position = {
      primary: horizontalPosition,
      secondary: verticalPostion,
    };
  } else {
    // here the vertical difference between the nodes is bigger, so we use Position.Top or Position.Bottom for the handle
    position = {
      primary: verticalPostion,
      secondary: horizontalPosition,
    };
  }

  const [x, y, finalPosition] = getHandleCoordsByPosition(nodeA, position, nodeAHandleId);

  return { x, y, position: finalPosition };
}

export function getHandleCoordsByPosition(
  node: TSystemMapInternalNode,
  handlePositionMap: IHandlePosition,
  handleId?: string | null
): [number, number, Position] {
  const handleBounds = node.internals.handleBounds;
  const { positionAbsolute } = node.internals;

  const allBounds = [...(handleBounds?.source || []), ...(handleBounds?.target || [])];
  const primaryHandle = allBounds.find((h) =>
    handleId ? h.id === handleId : h.position === handlePositionMap.primary
  );
  const secondaryHandle = allBounds.find((h) =>
    handleId ? h.id === handleId : h.position === handlePositionMap.secondary
  );
  const handle = primaryHandle ?? secondaryHandle;
  const handlePosition = primaryHandle ? handlePositionMap.primary : handlePositionMap.secondary;

  if (!handle || !positionAbsolute) {
    return [0, 0, handlePosition];
  }

  let offsetX = handle.width / 2;
  let offsetY = handle.height / 2;

  // this is a tiny detail to make the markerEnd of an edge visible.
  // The handle position that gets calculated has the origin top-left, so depending which side we are using, we add a little offset
  // when the handlePosition is Position.Right for example, we need to add an offset as big as the handle itself in order to get the correct position
  switch (handlePosition) {
    case Position.Left:
      offsetX = 0;
      break;
    case Position.Right:
      offsetX = handle.width;
      break;
    case Position.Top:
      offsetY = 0;
      break;
    case Position.Bottom:
      offsetY = handle.height;
      break;
  }

  const x = positionAbsolute.x + handle.x + offsetX;
  const y = positionAbsolute.y + handle.y + offsetY;

  return [x, y, handle.position];
}

function getNodeCenter(node: TSystemMapInternalNode) {
  const { positionAbsolute } = node.internals;

  if (!node.measured.width || !node.measured.height || !positionAbsolute) {
    return { x: 0, y: 0 };
  }
  return {
    x: positionAbsolute.x + node.measured.width / 2,
    y: positionAbsolute.y + node.measured.height / 2,
  };
}

// returns the parameters (sx, sy, tx, ty, sourcePos, targetPos) you need to create an edge
export function getEdgeParams(
  source?: TSystemMapInternalNode,
  target?: TSystemMapInternalNode,
  sourceHandleId?: string | null,
  targetHandleId?: string | null
): { sx: number; sy: number; tx: number; ty: number; sourcePos: Position; targetPos: Position } {
  const { x: sx, y: sy, position: sourcePos } = getParams(source, target, sourceHandleId);
  const { x: tx, y: ty, position: targetPos } = getParams(target, source, targetHandleId);

  return {
    sx,
    sy,
    tx,
    ty,
    sourcePos,
    targetPos,
  };
}

export function getClosestHandleToPoint(
  node?: TSystemMapInternalNode,
  point?: TControlPointData,
  hasFixedHandle?: boolean
) {
  if (point && node && !hasFixedHandle) {
    const posArr = [Position.Top, Position.Bottom, Position.Left, Position.Right].map((pos) => {
      return getHandleCoordsByPosition(node, { primary: pos, secondary: pos });
    });
    const distances = posArr.map(([x, y]) => {
      return Math.sqrt((point.x - x) ** 2 + (point.y - y) ** 2);
    });
    const [x, y, position] = posArr[distances.indexOf(Math.min(...distances))];
    return { x, y, position };
  }
  return undefined;
}
