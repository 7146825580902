import { SvgIcon } from '@mui/material';
import { PropsWithChildren } from 'react';

export interface ISideNavOption {
  to: string;
  Icon: typeof SvgIcon;
  title: string;
  Badge?: ({ children }: PropsWithChildren) => JSX.Element;
  nestedOptions?: ISideNavOption[];
  endAdornment?: JSX.Element;
  onClick?: () => void;
}

export interface ISideNavSeparator {
  key: string;
}

export type TSideNavElement = ISideNavOption | ISideNavSeparator;

export function isOption(pet: ISideNavOption | ISideNavSeparator): pet is ISideNavOption {
  return (pet as ISideNavOption).to !== undefined;
}
