import { MarlinTheme } from '@marlin/shared/theme';
import { Box } from '@mui/material';
import { PropsWithChildren } from 'react';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  header: {
    padding: theme.typography.pxToRem(16),
    backgroundColor: theme.palette.background.secondary,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

export const SectionHeader = ({ children }: PropsWithChildren) => {
  const { classes } = useStyles();
  return <Box className={classes.header}>{children}</Box>;
};
