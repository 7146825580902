import { MarlinTheme } from '@marlin/shared/theme';
import { PageHeader } from '@marlin/shared/ui-page';
import { PERMISSIONS, Restricted } from '@marlin/shared/utils-permission';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import { Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';
import { useRouter } from '../shared/use-router.hook';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  button: {
    width: theme.typography.pxToRem(123),
  },
}));

const LocationsHubHeader = () => {
  const { goToNewLocationPage } = useRouter();
  const { classes } = useStyles();
  return (
    <PageHeader
      prefix="locations-hub"
      title={content.LOCATIONS_HUB_TITLE}
      subtitle={content.LOCATIONS_HUB_SUBTITLE}
      icon={<LocationOnRoundedIcon />}
      actions={
        <Restricted to={PERMISSIONS.CREATE_NEW_LOCATION}>
          <Button
            id="locations-hub-button"
            variant="contained"
            onClick={goToNewLocationPage}
            className={classes.button}
          >
            {content.CREATE_NEW_BUTTON}
          </Button>
        </Restricted>
      }
    />
  );
};

export { LocationsHubHeader };
