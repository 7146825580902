import { getHttpClient } from '@marlin/shared/utils/react-query';

import { TSystemTagParams, systemTagParamsSchema } from '../system-tag.schema';
import { paths } from './api';

export const setSystemTag = async (requestParams: TSystemTagParams): Promise<unknown> => {
  const params = systemTagParamsSchema.parse(requestParams);

  return await getHttpClient().put(paths.SYSTEM_TAG, undefined, {
    params,
  });
};
