import { MarlinTheme } from '@marlin/shared/theme';
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  message: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  status: {
    color: theme.palette.info.main,
    height: theme.typography.pxToRem(12),
    width: theme.typography.pxToRem(12),
  },
  activeText: {
    marginLeft: theme.typography.pxToRem(8),
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.text.primary,
    lineHeight: theme.typography.pxToRem(12),
  },
}));

export const AlreadyExistsMessage = () => {
  const { classes } = useStyles();

  return (
    <span className={classes.message}>
      <CircleRoundedIcon className={classes.status} />
      <span className={classes.activeText}>{content.USER_ALREADY_EXISTS}</span>
    </span>
  );
};
