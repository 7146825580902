import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';

import { content } from './content';

interface IActionResendProps {
  onClick: () => void;
}

export const ActionResend = ({ onClick }: IActionResendProps) => {
  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon>
        <SendRoundedIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>{content.USER_TABLE_ACTION_RESEND}</ListItemText>
    </MenuItem>
  );
};
