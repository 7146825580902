import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    minWidth: theme.typography.pxToRem(274),
  },

  changeOrgContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.typography.pxToRem(8),
    paddingBottom: theme.typography.pxToRem(8),
    marginRight: theme.typography.pxToRem(12),
    marginLeft: theme.typography.pxToRem(12),
    minHeight: theme.typography.pxToRem(48),
  },

  currentOrgContainter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: theme.typography.pxToRem(8),
    paddingTop: theme.typography.pxToRem(8),
    paddingBottom: theme.typography.pxToRem(8),
  },

  title: {
    fontFamily: 'Inter',
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },

  orgName: {
    fontFamily: 'Inter',
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    textAlign: 'left',
    color: theme.palette.text.primary,
    wordBreak: 'break-word',
  },

  changeBtn: {
    textTransform: 'none',
  },

  greenDot: {
    color: theme.palette.success.light,
    fontSize: theme.typography.pxToRem(14),
  },

  divider: {
    '&.MuiDivider-root': {
      marginBottom: 0,
    },
  },

  orgIcon: {
    color: theme.palette.action.active,
  },
}));
