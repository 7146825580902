import { TUnitOfMeasure } from '@marlin/shared/utils-format-reading';

import { transformBigNumber } from './utils';

export const content = {
  EXIT_FULL_SCREEN: 'Exit Full Screen',
  SUSTAINABILITY_TITLE: 'Sustainability',
  SUSTAINABILITY_SUBTITLE: "Track your org's resource consumption from various lenses to draw insights into action",
  TOTAL_WATER_USE_TITLE: 'Total Water Use Per Period',
  TOTAL_WATER_CONSUMPTION_TITLE: 'Total Water Consumption by',
  TREND_DESCRIPTION_1: "On average, you're using ",
  TREND_DESCRIPTION_1_DOLLARS: 'On average, you spent ',
  TREND_DESCRIPTION_1_EQUAL: 'On average, ',
  TREND_DESCRIPTION_2: (longPeriod: string, shortPeriod: string) =>
    ` in the past ${longPeriod} than you did in the previous ${shortPeriod}.`,
  TREND_DESCRIPTION_2_EQUAL: (longPeriod: string, shortPeriod: string) =>
    ` in water use in the past ${longPeriod} relative to the previous ${shortPeriod}.`,
  TREND_LESS_PERCENT: (value: number) => `${transformBigNumber(value)}% less water`,
  TREND_MORE_PERCENT: (value: number) => `${transformBigNumber(value)}% more water`,
  TREND_LESS_DOLLAR: (value: number) => `$${transformBigNumber(Math.abs(value))} less`,
  TREND_MORE_DOLLAR: (value: number) => `$${transformBigNumber(value)} more`,
  TREND_NO_PREVIOUS_USE_GAL: (value: number) => `${transformBigNumber(value)} gal of water `,
  TREND_NO_PREVIOUS_USE_DOLLARS: (value: number) => `$${transformBigNumber(value)} `,
  TREND_DESCRIPTION_NO_PREVIOUS_USE_1: "On average, you've used ",
  TREND_DESCRIPTION_NO_PREVIOUS_USE_1_DOLLARS: "On average, you've spent ",
  TREND_DESCRIPTION_NO_PREVIOUS_USE_2: (longPeriod: string, shortPeriod: string) =>
    `in the past ${longPeriod} relative to no use in the previous ${shortPeriod}.`,
  TREND_NO_CURRENT_USE: 'There is no use ',
  TREND_DESCRIPTION_NO_CURRENT_USE: (value: number, longPeriod: string, shortPeriod: string) =>
    `of water in the past ${longPeriod} relative to ${transformBigNumber(
      value
    )} gal of water used in the previous ${shortPeriod}.`,
  TREND_DESCRIPTION_NO_CURRENT_DOLLARS: (value: number, longPeriod: string, shortPeriod: string) =>
    `of water in the past ${longPeriod} relative to $${transformBigNumber(
      value
    )} spent in the previous ${shortPeriod}.`,
  TREND_EQUAL: 'there is no change',
  SELECTED: 'Selected',
  PREVIOUS: 'Previous',
  TOTAL_WATER_CONSUMPTION_CURRENT_SERIES: 'Current Period',
  TOTAL_WATER_CONSUMPTION_PREVIOUS_SERIES: 'Comparison Period',
  TOTAL_AMOUNT: (amount: number, uoM?: TUnitOfMeasure) => `(Total ${transformBigNumber(amount)} ${uoM || ''})`,
  TOTAL_AMOUNT_DOLLARS: (amount: number) => `(Total $${transformBigNumber(amount)})`,
  SETTINGS: 'Settings',
  PERCENT: 'Percent',
  DOLLARS: 'Dollars',
  AVERAGE_USE_BY: 'AVERAGE USE BY: ',
  VOLUME: 'Volume',
  WATER_TRACEABILITY_TITLE: 'Water Traceability By',
  SANKEY_LABEL_VOLUME: (label: string, amount: number, uoM?: TUnitOfMeasure) =>
    `${label} (${transformBigNumber(amount)} ${uoM || ''})`,
  SANKEY_LABEL_DOLLARS: (label: string, amount: number) => `${label} ($${transformBigNumber(amount)})`,
};
