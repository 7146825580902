import { MarlinTheme } from '@marlin/shared/theme';
import { alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

interface IStyles {
  isFetching: boolean;
}

export const useStyles = makeStyles<IStyles>()((theme: MarlinTheme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
    minHeight: theme.typography.pxToRem(320),
    marginTop: theme.typography.pxToRem(8),
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.primary,
  },
  loader: {
    backgroundColor: alpha(theme.palette.background.primary, 0.5),
  },
  noData: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: theme.typography.pxToRem(12),
    backgroundColor: theme.palette.background.primary,
    padding: theme.typography.pxToRem(12),
    borderWidth: theme.typography.pxToRem(1),
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
    borderRadius: theme.typography.pxToRem(4),
  },
  noDataInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.typography.pxToRem(4),
  },
  dot: {
    width: theme.typography.pxToRem(8),
    height: theme.typography.pxToRem(8),
    borderRadius: '50%',
    backgroundColor: alpha(theme.palette.error.main, 0.12),
    marginRight: theme.typography.pxToRem(4),
  },
  range: {
    fontSize: theme.typography.pxToRem(10),
  },
  chart: {
    height: '100%',
    '& .apexcharts-series': {
      opacity: 1,
      animation: 'none', // note: temporary removing keyframes loading animation, before we have final designs
    },
  },
}));
