import { MarlinTheme } from '@marlin/shared/theme';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';

interface IBodyProps {
  currentOrgName: string;
  newOrgName: string;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    maxWidth: theme.typography.pxToRem(400),
    wordWrap: 'break-word',
  },
}));

export const Body = ({ currentOrgName, newOrgName }: IBodyProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <Typography>{content.CONFIRM_CHANGE_ORG_TEXT(currentOrgName, newOrgName)}</Typography>
    </div>
  );
};
