import { MarlinTheme } from '@marlin/shared/theme';
import { Box, Tab, Tabs } from '@mui/material';
import { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { RefreshButton } from '../refresh-button.component';
import { tabs } from './tabs-config';
import { ITab, TAB_INDEX } from './tabs.types';
import { ITotalItems, getLabelForTab } from './tabs.utils';

interface IAlertTabsProps {
  totalItems: ITotalItems | undefined;
  onRefreshButtonClick: () => Promise<unknown>;
  onTabChange: (tab: TAB_INDEX) => void;
  children: (key: TAB_INDEX) => JSX.Element;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    marginTop: theme.typography.pxToRem(30),
    display: 'flex',
    justifyContent: 'space-between',
  },

  tabs: {
    minHeight: 0,
  },

  tab: {
    minHeight: theme.typography.pxToRem(20),
    paddingBottom: theme.typography.pxToRem(10),
  },
}));

export function AlertTabs({ totalItems, onTabChange, onRefreshButtonClick, children }: IAlertTabsProps) {
  const { classes } = useStyles();
  const [tab, setTab] = useState(TAB_INDEX.CURRENT);

  const handleChange = useCallback(
    (event: SyntheticEvent, value: number) => {
      setTab(value);
      onTabChange(value);
    },
    [setTab, onTabChange]
  );

  const filters = useMemo(() => children(tab), [tab, children]);

  return (
    <>
      <Box className={classes.container}>
        <Tabs className={classes.tabs} value={tab} onChange={handleChange} data-testid="alert-tabs">
          {tabs.map((tab: ITab) => (
            <Tab
              className={classes.tab}
              key={tab.id}
              label={getLabelForTab(tab, totalItems)}
              value={tab.id}
              icon={tab.icon}
              iconPosition="start"
              data-testid={`alert-tab-${tab.label}`}
            />
          ))}
        </Tabs>
        <RefreshButton onRefreshButtonClick={onRefreshButtonClick} totalItems={totalItems} />
      </Box>
      {filters}
    </>
  );
}
