import { z } from 'zod';

export interface ITierFilters {
  search?: string;
  page: number;
  pageSize: number;
  sorting?: {
    direction: 'Ascending' | 'Descending';
    sortBy: string;
  };
}

export const TierSchema = z.object({
  tierName: z.string(),
  id: z.string(),
});

export const TiersResponseSchema = z.object({
  data: z.array(TierSchema),
  pagination: z.object({
    totalItems: z.number(),
    page: z.number(),
    pageSize: z.number(),
  }),
});

export type TTiersResponseSchema = z.infer<typeof TiersResponseSchema>;

export type TTierSchema = z.infer<typeof TierSchema>;
