import { z } from 'zod';

export const flowMetersSchema = z.array(
  z.object({
    deviceId: z.string(),
    deviceName: z.string(),
    locationId: z.string(),
    locationName: z.string(),
    manufacturerId: z.string(),
  })
);

export type TFlowMetersSchema = z.infer<typeof flowMetersSchema>;
