import { TExtractFnReturnType, useQuery } from '@marlin/shared/utils/react-query';

import { getAlert } from '../infrastructure/alerts';
import { queryKey } from './query-key.enum';

type TQueryFnType = typeof getAlert;

export const useAlert = (alertId: string, enabled?: boolean) => {
  return useQuery<TExtractFnReturnType<TQueryFnType>>({
    queryKey: queryKey.ALERT(alertId),
    queryFn: () => getAlert(alertId),
    enabled,
  });
};
