import { queryClient, useMutation } from '@marlin/shared/utils/react-query';
import { AxiosError } from 'axios';

import { IDevice } from '../device.model';
import { createDevice } from '../infrastructure/device';
import { queryKey } from './query-key.enum';

interface ICreateDeviceProps {
  data: IDevice;
}

interface ICreateDevice {
  previousData: IDevice[] | undefined;
}

interface IUseCreateDevice {
  onSuccess: () => void;
}

export const useCreateDevice = ({ onSuccess }: IUseCreateDevice) => {
  return useMutation<unknown, AxiosError, ICreateDeviceProps, ICreateDevice>({
    onMutate: async (newDevice: ICreateDeviceProps) => {
      await queryClient.cancelQueries({ queryKey: queryKey.DEVICES() });

      const previousData = queryClient.getQueryData<IDevice[]>(queryKey.DEVICES());

      queryClient.setQueryData(
        [queryKey.DEVICES()],
        [...(previousData || []), { ...newDevice.data, id: 'processing' }]
      );

      return { previousData };
    },
    onSuccess: () => {
      onSuccess();

      queryClient.invalidateQueries({ queryKey: queryKey.DEVICES() });
    },
    mutationFn: createDevice,
  });
};
