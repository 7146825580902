import { CHANNEL_TYPE, CRITICALITY, TAutomationBuilder } from '@marlin/alert/data-access/automated-action';
import { AutomationCardBuilder } from '@marlin/alert/shared/ui/automation-card';
import { AutomationContentContext } from '@marlin/alert/ui/automated-action-create';
import { PageContainer } from '@marlin/shared/ui-page';
import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import { ReactFlowProvider } from '@xyflow/react';
import '@xyflow/react/dist/base.css';
import '@xyflow/react/dist/style.css';
import { useCallback, useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { Canvas } from './canvas.component';
import { uiContent } from './content';

const useStyles = makeStyles()(() => ({
  modal: {
    width: 'auto',
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  container: {
    height: '100%',
    margin: 0,
  },
}));

const defaultValues: TAutomationBuilder = {
  id: '',
  name: '',
  manufacturerId: null,
  isEnabled: true,
  snoozeIntervalInMinutes: 0,
  criticality: CRITICALITY.LOW,
  channels: {
    [CHANNEL_TYPE.IN_APP]: {
      type: CHANNEL_TYPE.IN_APP,
    },
  },
};

export function AutomationBuilder() {
  const { classes } = useStyles();
  const form = useForm<TAutomationBuilder>({
    defaultValues,
    mode: 'onTouched',
  });
  const { modalDispatch } = useContext(ModalContext);

  const handleSubmit = useCallback(
    (values: TAutomationBuilder) => {
      modalDispatch({
        type: MODAL_ACTION_TYPE.SHOW,
        payload: {
          modalClassName: classes.modal,
          body: <AutomationCardBuilder item={values} />,
        },
      });
    },
    [classes.modal, modalDispatch]
  );

  return (
    <PageContainer prefix="automation-builder" className={classes.container}>
      <AutomationContentContext.Provider value={uiContent}>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)} className={classes.container}>
            <ReactFlowProvider>
              <div style={{ height: '100%', width: '100%', minHeight: '100vh' }}>
                <Canvas />
              </div>
            </ReactFlowProvider>
          </form>
        </FormProvider>
      </AutomationContentContext.Provider>
    </PageContainer>
  );
}
