import { useCurrentUser } from '@marlin/account-data-access-organization';
import { useUserData } from '@marlin/account-data-access-user';
import { useSetMultiChartsStore } from '@marlin/asset/ui/charts';
import { AVERAGING_FUNCTION_FILTER, AveragingFunctionFilter } from '@marlin/shared/utils-chart';
import { getChartName } from '@marlin/shared/utils-chart';
import { useCallback, useEffect, useMemo } from 'react';

import {
  analyticsAveragingFunctionFilterUserDataAreaKey,
  analyticsSelectedSensorsUserDataAreaKey,
  maxDatapointsCount,
} from '../consts';
import { useSetSelectedSensors } from '../context/selected-sensors.context';
import { multiChartStorageService } from '../multi-chart.storage';
import {
  SelectedGroupsWithDatapoints,
  TChartType,
  TSelectedDatapoint,
  TSelectedGroupsWithDatapoints,
  latestAnalyticsJsonSchemaVersion,
} from '../types';
import { generateChartId } from '../utils/generate-chart-id';

interface IUseMultiChartsParams {
  allOptions: TSelectedDatapoint[];
  areLocationsAndMetadataLoading: boolean;
}

export const useMultiCharts = ({ areLocationsAndMetadataLoading, allOptions }: IUseMultiChartsParams) => {
  const setSelectedSensors = useSetSelectedSensors();
  const setMultiChartsStore = useSetMultiChartsStore();

  const { data, isFetching } = useCurrentUser();

  const selectionFromUserDataQuery = useUserData(analyticsSelectedSensorsUserDataAreaKey);
  const averagingFilterFromUserDataQuery = useUserData(analyticsAveragingFunctionFilterUserDataAreaKey);

  const isLoading = useMemo(
    () =>
      isFetching ||
      areLocationsAndMetadataLoading ||
      selectionFromUserDataQuery.isFetching ||
      averagingFilterFromUserDataQuery.isFetching,
    [
      isFetching,
      areLocationsAndMetadataLoading,
      selectionFromUserDataQuery.isFetching,
      averagingFilterFromUserDataQuery.isFetching,
    ]
  );

  const defaultSelectedSensors = useMemo(() => {
    const alreadySelectedCounts: Record<Exclude<TChartType, 'custom'>, number> = {
      temperature: 0,
      pressure: 0,
      flow: 0,
      onOff: 0,
      leak: 0,
    };

    // TODO: figure out what to do with mixingValve since it in temperature group but has % uom
    const optionsToSelect = allOptions.filter(
      (option) => option.name.toLowerCase() !== 'MixingValve'.toLowerCase() && option.type !== 'onOff'
    );

    const defaultSensors = optionsToSelect.reduce<TSelectedGroupsWithDatapoints>((acc, option) => {
      if (option.type && alreadySelectedCounts[option.type] < maxDatapointsCount) {
        alreadySelectedCounts[option.type] += 1;

        const chartId = Object.keys(acc).find((key) => acc[key].chartType === option.type) || generateChartId();

        return {
          ...acc,
          [chartId]: {
            chartType: option.type,
            chartName: getChartName(option.type),
            chartDatapoints: [...(acc?.[chartId]?.chartDatapoints || []), { ...option, isActive: true }],
          },
        };
      }

      return acc;
    }, {});

    const sortingOrder = Object.keys(alreadySelectedCounts);

    return Object.fromEntries(
      Object.entries(defaultSensors).sort(([, chartA], [, chartB]) => {
        return sortingOrder.indexOf(chartA.chartType) - sortingOrder.indexOf(chartB.chartType);
      })
    );
  }, [allOptions]);

  const selectionFromStorage = useMemo(
    () =>
      multiChartStorageService.getSelectedSensors({
        userId: data?.id,
        organizationId: data?.currentOrganization.organizationId,
      }),
    [data?.currentOrganization.organizationId, data?.id]
  );

  useEffect(() => {
    if (isLoading) return;

    const selectionFromUserData = getSelectionFromUserData(selectionFromUserDataQuery.data);
    const averagingFunctionFilterFromUserData = getAveragingFunctionFilterFromUserData(
      averagingFilterFromUserDataQuery.data
    );

    if (selectionFromUserData) {
      if (averagingFunctionFilterFromUserData) {
        setMultiChartsStore({
          averagingFunctionFilter: averagingFunctionFilterFromUserData,
        });
      }

      setSelectedSensors(selectionFromUserData);
      return;
    }

    setSelectedSensors(selectionFromStorage ?? defaultSelectedSensors);
    // update only once when fetching is done
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, defaultSelectedSensors]);

  const onResetView = useCallback(() => {
    setSelectedSensors(defaultSelectedSensors);
  }, [defaultSelectedSensors, setSelectedSensors]);

  return {
    onResetView,
    isLoading,
  };
};

const getSelectionFromUserData = (
  selectionFromUserData: Record<string, unknown> | undefined
): TSelectedGroupsWithDatapoints | undefined => {
  if (
    selectionFromUserData &&
    'version' in selectionFromUserData &&
    typeof selectionFromUserData.version === 'string'
  ) {
    const { version, ...selection } = selectionFromUserData;

    if (version !== latestAnalyticsJsonSchemaVersion) return undefined;

    const parsed = SelectedGroupsWithDatapoints.safeParse(selection);

    if (parsed.success) return parsed.data;
  }

  return undefined;
};

const getAveragingFunctionFilterFromUserData = (
  averagingFunctionFilterFromUserData: Record<string, unknown> | undefined
): AVERAGING_FUNCTION_FILTER | undefined => {
  if (
    averagingFunctionFilterFromUserData &&
    'version' in averagingFunctionFilterFromUserData &&
    typeof averagingFunctionFilterFromUserData.version === 'string'
  ) {
    const { version, ...rest } = averagingFunctionFilterFromUserData;

    if (version !== latestAnalyticsJsonSchemaVersion) return undefined;

    if ('averagingFunctionFilter' in rest) {
      const parsed = AveragingFunctionFilter.safeParse(rest.averagingFunctionFilter);

      if (parsed.success) return parsed.data;
    }
  }

  return undefined;
};
