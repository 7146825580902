import { TDurationDetails } from '@marlin/shared/utils-chart';
import { createStoreContext, useContext } from '@marlin/shared/utils-component-context';
import { FC } from 'react';

const {
  useStore: useDurationDetailsStore,
  Provider: DurationDetailsProvider,
  StoreContext: DurationDetailsContext,
} = createStoreContext<TDurationDetails<string>>({});

export function useSetDurationDetailsStore() {
  const context = useContext(DurationDetailsContext);
  return context.set;
}

export function withDurationDetailsContext(Component: FC) {
  return () => (
    <DurationDetailsProvider>
      <Component />
    </DurationDetailsProvider>
  );
}

export { useDurationDetailsStore, DurationDetailsProvider };
