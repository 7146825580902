import { AutocompleteRenderGroupParams } from '@mui/material';
import isArray from 'lodash/isArray';
import { HTMLAttributes, ReactNode, useMemo } from 'react';
import { ControllerProps, ControllerRenderProps, FieldError, FieldValues } from 'react-hook-form';

import { Autocomplete, IOption } from '../autocomplete/autocomplete.component';
import { FormField } from '../form-fields/form-field';
import { IFormControlProps, IFormTypedProps } from '../form.types';
import { useStyles } from './inifinite-autocomplete.styles';
import { useInfiniteAutocomplete } from './use-infinite-autocomplete-list.hook';

type TRenderProps = Partial<Omit<ControllerRenderProps, 'ref' | 'value'>>;

// TODO: Adjust interface to allow offline mode without unnecessary props related to fetching
interface IAutocompleteBaseProps<TGenericOption extends IOption> {
  className?: string;
  options: TGenericOption[];
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  isLoading?: boolean;
  isError?: boolean;
  search: (value: string) => void;
  term: string;
  label: string;
  testId: string;
  isFetching: boolean;
  isFetchingNextPage: boolean;
  renderOption?: (props: HTMLAttributes<HTMLLIElement>, option: TGenericOption) => ReactNode;
  size?: 'small' | 'medium';
  renderGroup?: (params: AutocompleteRenderGroupParams) => ReactNode;
  groupBy?: (option: TGenericOption) => string;
  required?: boolean;
}

interface IAutocompleteMultipleProps<TGenericOption extends IOption> extends IAutocompleteBaseProps<TGenericOption> {
  value?: TGenericOption[];
  multiple?: true;
  customOnChange?: ControllerRenderProps['onChange'];
}

interface IAutocompleteSingleProps<TGenericOption extends IOption> extends IAutocompleteBaseProps<TGenericOption> {
  value?: TGenericOption | null;
  multiple?: false;
  customOnChange?: ControllerRenderProps['onChange'];
}

type TAutocompleteProps<TGenericOption extends IOption> =
  | IAutocompleteSingleProps<TGenericOption>
  | IAutocompleteMultipleProps<TGenericOption>;

interface IInfiniteAutocompleteChildren<TGenericOption extends IOption> {
  children?: (props: {
    options: TGenericOption[];
    value: TGenericOption[] | TGenericOption | null;
    onDelete: (id: string) => void;
  }) => JSX.Element;
}

interface IInfiniteAutocompleteMultipleProps<TGenericOption extends IOption>
  extends TRenderProps,
    IAutocompleteMultipleProps<TGenericOption>,
    IInfiniteAutocompleteChildren<TGenericOption> {
  error?: FieldError;
  disabled?: boolean;
}

interface IInfiniteAutocompleteSingleProps<TGenericOption extends IOption>
  extends TRenderProps,
    IAutocompleteSingleProps<TGenericOption>,
    IInfiniteAutocompleteChildren<TGenericOption> {
  error?: FieldError;
  disabled?: boolean;
}

const InfiniteAutocomplete = <TGenericOption extends IOption>({
  onChange,
  value,
  options,
  hasNextPage,
  fetchNextPage,
  search,
  term,
  label,
  testId,
  isFetching,
  isFetchingNextPage,
  className,
  children,
  renderOption,
  multiple,
  error,
  size,
  renderGroup,
  groupBy,
  disabled,
  required,
  onBlur,
  customOnChange,
}: IInfiniteAutocompleteMultipleProps<TGenericOption> | IInfiniteAutocompleteSingleProps<TGenericOption>) => {
  const { classes } = useStyles();

  const { loadMore } = useInfiniteAutocomplete({
    fetchNextPage,
    hasNextPage,
    loading: isFetchingNextPage || isFetching,
  });

  const onDelete = (idToRemove: string) => {
    if (isArray(value)) {
      const newIds = (value || []).filter(({ id }) => id !== idToRemove);
      onChange && onChange(newIds);
    }
  };

  const autocompleteValue = useMemo(() => {
    if (multiple) {
      return value || [];
    }

    if (typeof value === 'string') {
      return options.find((option) => option.id === value) || null;
    }

    return value || null;
  }, [multiple, options, value]);

  return (
    <>
      <Autocomplete<TGenericOption>
        options={options}
        value={autocompleteValue}
        onChange={(ids) => {
          if (customOnChange) {
            return customOnChange(ids);
          }
          return onChange && onChange(ids);
        }}
        label={label}
        testid={testId}
        className={className}
        handleInputChange={(_event: unknown, newValue: string) => {
          return search(newValue);
        }}
        getOptionKey={(option) => (typeof option === 'string' ? option : option.id)}
        classes={{ paper: classes.paper, listbox: classes.listbox, noOptions: classes.noOptions }}
        onScroll={loadMore}
        loading={isFetching || isFetchingNextPage}
        inputValue={term}
        renderOption={renderOption}
        fullWidth
        useObjects={true}
        multiple={multiple}
        autoSelect={false}
        size={size}
        renderGroup={renderGroup}
        groupBy={groupBy}
        disabled={disabled}
        required={required}
        onBlur={onBlur}
        error={error}
      />
      {children && children({ options, value: autocompleteValue, onDelete })}
    </>
  );
};

export function InfiniteAutocompleteControl<TFieldValues extends FieldValues, TGenericOption extends IOption>(
  props: IFormControlProps<TFieldValues> &
    TAutocompleteProps<TGenericOption> &
    IInfiniteAutocompleteChildren<TGenericOption>
): JSX.Element;
export function InfiniteAutocompleteControl<
  TFieldValues extends FieldValues = object,
  TGenericOption extends IOption = IOption,
  TName extends ControllerProps<TFieldValues>['name'] = ControllerProps<TFieldValues>['name']
>(
  props: IFormTypedProps<TFieldValues, TName> &
    TAutocompleteProps<TGenericOption> &
    IInfiniteAutocompleteChildren<TGenericOption>
): JSX.Element;

export function InfiniteAutocompleteControl<
  TFieldValues extends FieldValues = object,
  TGenericOption extends IOption = IOption,
  TName extends ControllerProps<TFieldValues>['name'] = ControllerProps<TFieldValues>['name']
>({
  options,
  hasNextPage,
  isLoading,
  fetchNextPage,
  search,
  term,
  testId,
  label,
  children,
  isFetchingNextPage,
  isFetching,
  className,
  renderOption,
  multiple,
  size,
  groupBy,
  renderGroup,
  required,
  customOnChange,
  ...params
}: (IFormControlProps<TFieldValues> | IFormTypedProps<TFieldValues, TName>) &
  TAutocompleteProps<TGenericOption> &
  IInfiniteAutocompleteChildren<TGenericOption>) {
  if (params.control) {
    return (
      <FormField {...params}>
        {(props) => (
          <InfiniteAutocomplete<TGenericOption>
            {...props}
            className={className}
            disabled={params?.disabled}
            options={options}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            isLoading={isLoading}
            search={search}
            term={term}
            testId={testId}
            label={label}
            isFetchingNextPage={isFetchingNextPage}
            isFetching={isFetching}
            children={children}
            renderOption={renderOption}
            multiple={multiple}
            size={size}
            groupBy={groupBy}
            renderGroup={renderGroup}
            required={required}
            customOnChange={customOnChange}
          />
        )}
      </FormField>
    );
  }

  return (
    <FormField<TFieldValues> {...params}>
      {(props) => (
        <InfiniteAutocomplete<TGenericOption>
          {...props}
          className={className}
          disabled={params?.disabled}
          options={options}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isLoading={isLoading}
          search={search}
          term={term}
          testId={testId}
          label={label}
          isFetchingNextPage={isFetchingNextPage}
          isFetching={isFetching}
          renderOption={renderOption}
          children={children}
          multiple={multiple}
          size={size}
          groupBy={groupBy}
          renderGroup={renderGroup}
          required={required}
          customOnChange={customOnChange}
        />
      )}
    </FormField>
  );
}
