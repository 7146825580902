import { intellistationDataPointEventCodes } from '@marlin/shared/utils/datapoint-mappers';

export const intellistationEventCodes = {
  readings: {
    mixedOutlet: intellistationDataPointEventCodes.mixingTemp,
  },
  temperatureSensors: {
    hotTemp: intellistationDataPointEventCodes.hotTemp,
    coldTemp: intellistationDataPointEventCodes.coldTemp,
    recircTemp: intellistationDataPointEventCodes.recircTemp,
  },
  pressureSensors: {
    outletPressure: intellistationDataPointEventCodes.outletPressure,
    recircPressure: intellistationDataPointEventCodes.recircPressure,
    hotPressure: intellistationDataPointEventCodes.hotPressure,
    coldPressure: intellistationDataPointEventCodes.coldPressure,
  },
  flowSensors: {
    outletFlow: intellistationDataPointEventCodes.outletFlow,
    recircFlow: intellistationDataPointEventCodes.recircFlow,
  },
  pumps: {
    pump1Status: intellistationDataPointEventCodes.pump1Status,
    pump2Status: intellistationDataPointEventCodes.pump2Status,
  },
};
