import { LoadingSpinner } from '@marlin/shared/ui-loader';
import Plot from 'react-plotly.js';

import { IChartSettings } from './types';
import { useLeakHeatmapData } from './use-leak-heatmap-data';

interface ILeakHeatMapProps {
  chartSettings: IChartSettings;
}

export const LeakHeatMap = ({ chartSettings }: ILeakHeatMapProps) => {
  const { chartData, isLoading, isFetching } = useLeakHeatmapData({ chartSettings });

  if (isLoading || isFetching) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: 16, marginTop: 16 }}>
        <LoadingSpinner /> Please wait, chart data is fetching...
      </div>
    );
  }

  if (chartData) {
    return (
      <Plot
        data={chartData}
        layout={{
          title: 'test leak heatmap ',
          xaxis: {
            title: `${chartSettings.minDate?.format()} - ${chartSettings.maxDate?.format()}`,
          },
        }}
      />
    );
  }

  return null;
};
