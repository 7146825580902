import { TDataPointsSchema } from '@marlin/shared/utils-format-reading';
import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import { IDevice, IDeviceResponse } from '../device.model';
import { paths } from './api';
import { createDeviceDto } from './dtos/device.response';
import { TDeviceParamsSchema, createDeviceSchema, getDeviceRequestSchema } from './schema/device.request.schema';
import {
  TDeviceDetails,
  TGetDeviceResponseSchema,
  TGetDeviceSchema,
  deviceDetailsSchema,
  getDeviceSchema,
} from './schema/device.response.schema';

export const getDevice = async ({ deviceId }: { deviceId: string }): Promise<IDevice> => {
  const res = await getHttpClient().get<unknown, IDeviceResponse>(paths.DEVICE, { params: { deviceId } });

  return createDeviceDto(res);
};

/**
 * @deprecated useFilteredDevicesWithPaging instead
 * */
export const getDevices = async (): Promise<IDevice[]> => {
  const res = await getHttpClient().get<unknown, IDeviceResponse[]>(paths.DEVICES);

  return res.map((value) => createDeviceDto(value));
};

type TDeleteDevice = Promise<void>;

export const deleteDevice = (deviceId: string): Promise<TDeleteDevice> => {
  return getHttpClient().delete(paths.DEVICE, { params: { deviceId } });
};

export const getDeviceDetails = async ({
  deviceId,
  datapoints,
}: {
  deviceId: string;
  datapoints?: TDataPointsSchema[];
}): Promise<TDeviceDetails> => {
  const res = await getHttpClient().get<unknown, TDeviceDetails>(paths.DEVICE_DETAILS, {
    params: { deviceId, datapointNames: datapoints },
    paramsSerializer: {
      indexes: null,
    },
  });

  return safeParseData(res, deviceDetailsSchema);
};

interface ICreateDevice {
  data: IDevice;
}

export const createDevice = ({ data }: ICreateDevice): Promise<IDevice> => {
  const newDeviceData = safeParseData(data, createDeviceSchema);

  return getHttpClient().post(paths.DEVICES, newDeviceData);
};

interface IUpdateDevice {
  data: IDevice;
  deviceId: string;
}

export const updateDevice = ({ data, deviceId }: IUpdateDevice): Promise<IDevice> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateDeviceDto = (data: Record<string, any>) => {
    for (const variableKey in data) {
      if (data[variableKey] === '') {
        delete data[variableKey];
      }
    }
    return data;
  };

  return getHttpClient().put(paths.DEVICE, updateDeviceDto(data), { params: { deviceId } });
};

export const getFilteredDevices = async (params: TDeviceParamsSchema): Promise<TGetDeviceSchema> => {
  const body = safeParseData(params, getDeviceRequestSchema);
  const res = await getHttpClient().post<unknown, TGetDeviceResponseSchema>(paths.DEVICES_FILTER, body);

  return safeParseData(res, getDeviceSchema);
};
