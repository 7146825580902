import { AUTOMATION_RULE_TYPE } from '@marlin/alert/data-access/automated-action';
import { Input } from '@marlin/shared/ui-form-common';
import { useState } from 'react';

import { useContent } from '../../context';
import { IDevice, ILocation } from '../../types';
import { FormGroupHeader } from '../shared/form-group-header.component';
import { SectionHeader } from '../shared/section-header.component';
import { BatteryLevelConditions } from './conditions/condition-type/battery-level-conditions.component';
import { DeviceLostCommunicationConditions } from './conditions/condition-type/device-lost-communication-conditions.component';
import { GatewayLostCommunicationConditions } from './conditions/condition-type/gateway-lost-communication-conditions.component';
import { ConditionSwitcher } from './conditions/condition/condition-switcher.component';
import { DeviceSelect } from './device/device-select.component';
import { SystemRuleTemplate } from './system-rule/system-rule-template.component';

interface ITriggerProps {
  locations: ILocation[];
  disableDeviceSelection: boolean;
  ruleType?: AUTOMATION_RULE_TYPE;
  automationName?: string;
  isInformationType?: boolean;
  isEquipment?: boolean;
}

export function Trigger({
  locations,
  disableDeviceSelection,
  ruleType,
  automationName,
  isInformationType,
  isEquipment,
}: ITriggerProps): JSX.Element {
  const [device, setDevice] = useState<IDevice>();
  const content = useContent();

  if (ruleType === AUTOMATION_RULE_TYPE.GATEWAY_LOST_COMMUNICATION) {
    return (
      <SystemRuleTemplate ruleName={content.SYSTEM_RULES.GATEWAY_LOST_COMMUNICATION}>
        <GatewayLostCommunicationConditions />
      </SystemRuleTemplate>
    );
  }

  if (ruleType === AUTOMATION_RULE_TYPE.DEVICE_LOST_COMMUNICATION) {
    return (
      <SystemRuleTemplate
        ruleName={
          isEquipment
            ? content.SYSTEM_RULES.EQUIPMENT_LOST_COMMUNICATION
            : content.SYSTEM_RULES.SENSOR_LOST_COMMUNICATION
        }
        isEquipment={isEquipment}
      >
        <DeviceLostCommunicationConditions isEquipment={isEquipment} />
      </SystemRuleTemplate>
    );
  }

  if (ruleType === AUTOMATION_RULE_TYPE.BATTERY_LEVEL) {
    return (
      <SystemRuleTemplate ruleName={content.SYSTEM_RULES.BATTERY_LEVEL}>
        <BatteryLevelConditions />
      </SystemRuleTemplate>
    );
  }

  if (ruleType === AUTOMATION_RULE_TYPE.DEVICE_ERROR) {
    return (
      <SystemRuleTemplate ruleName={automationName || ''} isEquipment={isEquipment}>
        <Input disabled value={content.SYSTEM_RULES.SENSOR_ERROR} label={content.CONDITION} />
      </SystemRuleTemplate>
    );
  }

  if (ruleType === AUTOMATION_RULE_TYPE.DEVICE_ALERT) {
    return (
      <SystemRuleTemplate ruleName={automationName || ''} isEquipment={isEquipment}>
        <Input disabled value={content.SYSTEM_RULES.SENSOR_ALERTS} label={content.CONDITION} />
      </SystemRuleTemplate>
    );
  }

  if (ruleType === AUTOMATION_RULE_TYPE.EQUIPMENT_INFORMATION) {
    return (
      <SystemRuleTemplate ruleName={automationName || ''} isInformationType isEquipment={isEquipment}>
        <Input disabled value={content.SYSTEM_RULES.EQUIPMENT_INFORMATION} label={content.CONDITION} />
      </SystemRuleTemplate>
    );
  }

  return (
    <>
      <FormGroupHeader title={content.TRIGGER.TITLE} tooltip={content.TRIGGER.TOOLTIP} prefix="action" />
      <SectionHeader title={content.SENSOR.TITLE} prefix="device" />
      <DeviceSelect locations={locations} disableDeviceSelection={disableDeviceSelection} onDeviceChange={setDevice} />
      <SectionHeader
        title={content.CONDITIONS.TITLE}
        prefix="conditions"
        tooltip={content.CONDITIONS.TOOLTIP}
        required
      />
      <ConditionSwitcher device={device} />
    </>
  );
}
