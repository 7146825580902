import { IFilterParams, TAutomation, useAutomations } from '@marlin/alert/data-access/automated-action';
import { isDataLoading } from '@marlin/shared/utils/react-query';
import { useMemo } from 'react';

export type TChartAutomation = TAutomation & {
  label: string;
};

interface IStore {
  totalItems?: number;
  isLoading: boolean;
  automations?: TChartAutomation[];
}

const defaultParams: IFilterParams = {
  deviceId: [],
  equipmentId: [],
  locationId: [],
  page: 1,
  pageSize: 10,
  isEnabled: null,
};

const createLabel = (index: number) => String.fromCharCode(65 + index);

export const useManageAutomations = (currentDeviceId: string, externalParams?: Partial<IFilterParams>): IStore => {
  const automationsQuery = useAutomations({
    params: { ...defaultParams, ...externalParams, deviceId: [currentDeviceId] },
    enabled: !!currentDeviceId && !!currentDeviceId.length,
  });

  const isLoading = useMemo(() => isDataLoading([automationsQuery]), [automationsQuery]);

  const totalItems = useMemo(() => {
    if (!automationsQuery?.data) {
      return undefined;
    }

    return automationsQuery.data.pagination.totalItems;
  }, [automationsQuery]);

  const automations = useMemo(
    () =>
      automationsQuery?.data?.data
        ?.filter((automation) => automation.isEnabled)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(
          (automation, index) =>
            ({
              ...automation,
              label: createLabel(index),
            } as TChartAutomation)
        ),
    [automationsQuery?.data?.data]
  );

  return {
    automations,
    isLoading,
    totalItems,
  };
};
