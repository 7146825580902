import { MarlinTheme } from '@marlin/shared/theme';
import { Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { useContent } from '../context';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    padding: theme.typography.pxToRem(8),
  },

  criticalityRow: {
    gap: '5%',
    paddingTop: theme.typography.pxToRem(8),
  },

  labelItem: {
    flexBasis: '20%',
    maxWidth: '20%',
  },

  descriptionItem: {
    flexBasis: '75%',
    maxWidth: '75%',
  },

  chip: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.typography.pxToRem(5),
    borderRadius: theme.typography.pxToRem(24),
    width: theme.typography.pxToRem(56),
  },

  chipHighWrapper: {
    border: '1px solid #F44336', // TODO ADD COLOR TO PALLETE
  },

  chipLowWrapper: {
    border: '1px solid #FF9800', // TODO ADD COLOR TO PALLETE
  },

  dot: {
    marginRight: theme.typography.pxToRem(8),
    height: theme.typography.pxToRem(8),
    width: theme.typography.pxToRem(8),
    borderRadius: '50%',
    display: 'inline-block',
  },

  high: {
    backgroundColor: theme.palette.error.main,
  },

  low: {
    backgroundColor: theme.palette.warning.main,
  },
}));

export function CriticalityTooltip() {
  const { classes } = useStyles();
  const content = useContent();

  return (
    <div className={classes.container}>
      <div>{content.CRITICALITY.TOOLTIP.INTRO}</div>
      <Grid container className={classes.criticalityRow}>
        <Grid item className={classes.labelItem}>
          <div className={`${classes.chip} ${classes.chipHighWrapper}`}>
            <div>
              <span className={`${classes.dot} ${classes.high}`} />
            </div>
            {content.CRITICALITY.VALUE.HIGH}
          </div>
        </Grid>
        <Grid item className={classes.descriptionItem}>
          {content.CRITICALITY.TOOLTIP.HIGH}
        </Grid>
      </Grid>
      <Grid container className={classes.criticalityRow}>
        <Grid item className={classes.labelItem}>
          <div className={`${classes.chip} ${classes.chipLowWrapper}`}>
            <div>
              <span className={`${classes.dot} ${classes.low}`} />
            </div>
            {content.CRITICALITY.VALUE.LOW}
          </div>
        </Grid>
        <Grid item className={classes.descriptionItem}>
          {content.CRITICALITY.TOOLTIP.LOW}
        </Grid>
      </Grid>
    </div>
  );
}
