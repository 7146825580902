import { THomeAlertSchema, THomeDeviceSchema, THomeLocationSchema } from '@marlin/asset/data-access/home-dashboard';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { Dot } from '@marlin/shared/ui-criticality';
import { CRITICALITY } from '@marlin/shared/utils/zod';
import SensorsRoundedIcon from '@mui/icons-material/SensorsRounded';
import { Divider, Typography } from '@mui/material';
import { useMemo } from 'react';

import { content } from '../../content';
import { HomeCardDevice } from '../card-device';
import { useStyles } from './card-unassigned-devices.component.styles';

type THomeCardUnassignedDevicesProps = {
  devices: THomeDeviceSchema[];
  homeLocation: THomeLocationSchema;
};

const HomeCardUnassignedDevices = ({ devices }: THomeCardUnassignedDevicesProps) => {
  const { classes, cx } = useStyles();

  const unassignedDevicesAlerts = useMemo(() => devices.flatMap((device) => device.alerts || []), [devices]);

  const unassignedDevicesHaveLowAlerts = useMemo(
    () => unassignedDevicesAlerts.every((alert: THomeAlertSchema) => alert.criticality === CRITICALITY.LOW),
    [unassignedDevicesAlerts]
  );
  const alertClass = useMemo(() => {
    if (unassignedDevicesHaveLowAlerts) {
      return classes.warningRow;
    }
    return classes.errorRow;
  }, [classes.errorRow, classes.warningRow, unassignedDevicesHaveLowAlerts]);

  const alertCriticality = useMemo(() => {
    if (unassignedDevicesHaveLowAlerts) {
      return CRITICALITY.LOW;
    }
    return CRITICALITY.HIGH;
  }, [unassignedDevicesHaveLowAlerts]);

  return (
    <div className={classes.wrapper}>
      <div className={cx(classes.equipmentWrapper, { [`${alertClass}`]: !!unassignedDevicesAlerts.length })}>
        <div className={classes.equipmentRow}>
          <div className={classes.equipmentNameWrapper}>
            <SensorsRoundedIcon className={classes.equipmentIcon} />
            <Typography className={classes.equipmentName} variant="body1">
              {content.UNATTCHED_SENSORS_TITLE(devices.length)}
            </Typography>
          </div>
          {unassignedDevicesAlerts.length ? (
            <Tooltip placement="top" text={content.EQUIPMENT_ALERTS}>
              <Dot variant={alertCriticality} labelClassName={classes.dotLabel}>
                <>{content.IMPACT_MOBILE(unassignedDevicesAlerts.length)}</>
              </Dot>
            </Tooltip>
          ) : null}
        </div>
      </div>
      {!!devices?.length && <Divider />}
      {devices?.map((device) => (
        <HomeCardDevice key={device.deviceId} device={device} />
      ))}
    </div>
  );
};

export { HomeCardUnassignedDevices };
