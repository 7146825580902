import {
  getListener,
  initializeListenerConnection,
  realtimeCommunicationFacade,
} from '@marlin/shared/utils-realtime-communication';
import { getHttpClient } from '@marlin/shared/utils/react-query';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IAlert, IAlertNotification, IAlertNotificationResponse, IPagedAlerts, IPagedParams } from '../alert.model';
import { TimeoutError } from '../timeout-error';
import { IAlertResponse, IPagedAlertsResponse } from './alert.model';
import { paths } from './api';
import { ALERT_NOTIFICATION_EVENTS } from './constant';
import { createAlert } from './dtos/alert-notification-request.dto';
import { createAlertParams } from './dtos/alert-request.dto';
import { createAlertDto, createAlertsListDto } from './dtos/alert-response.dto';
import { parseAlertStatusRequest } from './dtos/utils';

export const getAlerts = async (params?: IPagedParams): Promise<IPagedAlerts> => {
  const res = await getHttpClient().get<unknown, IPagedAlertsResponse>(paths.ALERTS, {
    params: {
      page: params?.page,
      pageSize: params?.pageSize,
      status: parseAlertStatusRequest(params?.status),
      // TODO: (MISSING_BE_API) add locations and equipments after api-gateway
    },
    paramsSerializer: {
      indexes: null,
    },
  });

  return createAlertsListDto(res);
};

export const getFilteredAlerts = async (params: IPagedParams): Promise<IPagedAlerts> => {
  const body = createAlertParams(params);
  const res = await getHttpClient().post<unknown, IPagedAlertsResponse>(paths.FILTERED_ALERTS, body);

  return createAlertsListDto(res);
};

export const getAlert = async (alertId?: string): Promise<IAlert> => {
  const res = await getHttpClient().get<unknown, IAlertResponse>(paths.ALERT, {
    params: {
      alertId,
    },
  });

  return createAlertDto(res);
};

export const getUnreadAlertCountSubject = (): BehaviorSubject<number | null> => {
  return getListener<number | null>(ALERT_NOTIFICATION_EVENTS.SEND_CURRENT_ALERTS_NUMBER, null);
};

export const initSubject = (): Observable<void> => {
  return initializeListenerConnection<number | null>(ALERT_NOTIFICATION_EVENTS.SEND_CURRENT_ALERTS_NUMBER, null).pipe(
    catchError(() => throwError(new TimeoutError()))
  );
};

export const getAlertNotification = (): Observable<IAlertNotification | undefined> => {
  return realtimeCommunicationFacade.getConnection().pipe(
    switchMap((connection) =>
      (connection.listenOn(ALERT_NOTIFICATION_EVENTS.SEND_IN_APP_ALERT) as Observable<IAlertNotificationResponse>).pipe(
        map((alert: IAlertNotificationResponse) => createAlert(alert)),
        catchError(() => {
          return throwError(new TimeoutError());
        })
      )
    )
  );
};
