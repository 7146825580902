import { MarlinTheme } from '@marlin/shared/theme';
import { HotSos } from '@marlin/shared/ui-hot-sos';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import TextsmsRoundedIcon from '@mui/icons-material/TextsmsRounded';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';
import { CHANNEL_TYPE, IAlert } from '../types';
import { CurrentAlertContentHeaderMobile } from './alert-content-current-header-mobile.component';
import { CurrentAlertContentHeader } from './alert-content-current-header.component';
import { AutomationLinkSection } from './automation-link-section.component';
import { RecipientList } from './recipient-list.component';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  iconHigh: {
    color: theme.palette.error.main,
  },

  iconLow: {
    color: theme.palette.warning.light,
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: theme.typography.pxToRem(24),
    [theme.breakpoints.down('md')]: {
      justifyContent: 'left',
      gap: theme.typography.pxToRem(8),
    },
  },
}));

interface IAlertContentProps {
  alert: IAlert;
  isMobile: boolean;
  automationLink: (deviceId: string, isSystemRule: boolean) => string;
}

export function CurrentAlertContent({ alert, isMobile, automationLink }: IAlertContentProps) {
  const { classes } = useStyles();

  return (
    <>
      {isMobile ? <CurrentAlertContentHeaderMobile alert={alert} /> : <CurrentAlertContentHeader alert={alert} />}
      <AutomationLinkSection
        name={alert.automation.name}
        link={automationLink(alert.device.id, alert.automation.isSystemRule)}
        errorType={alert.errorType}
      />
      <div className={classes.row}>
        <RecipientList
          channelType={CHANNEL_TYPE.EMAIL}
          icon={<EmailRoundedIcon />}
          label={content.CARD.EMAIL}
          users={alert.channels[CHANNEL_TYPE.EMAIL]?.recipients}
        />
        <RecipientList
          channelType={CHANNEL_TYPE.SMS}
          icon={<TextsmsRoundedIcon />}
          label={content.CARD.TEXT}
          users={alert.channels[CHANNEL_TYPE.SMS]?.recipients}
        />
      </div>
      <HotSos channel={alert.channels[CHANNEL_TYPE.HOT_SOS]} />
    </>
  );
}
