export const content = {
  ASSET_TYPE_LABEL: 'Asset type',
  EQUIPMENT_MODEL_LABGEL: 'Equipment model',
  ASSET_TYPE_PLACEHOLDER: 'No filter',

  ASSET_LABEL: 'Choose Asset',

  ASSET_LOCATION: 'Location',
  ASSET_EQUIPMENT: 'Equipment',
  ASSET_SENSOR: 'Sensor',

  GROUP_LOCATION: 'Locations',
  GROUP_EQUIPMENT: 'Equipments',
  GROUP_SENSOR: 'Sensors',
  GROUP_ASSET: 'Assets',
  GROUP_ATTACHMENT: 'Attachments',
  GROUP_TEE: 'Tees',

  TEE: 'Tee',

  NO_RESULTS: 'No results found',
  INTELLISTATION_TYPE: 'Powers IntelliStation 2',
  AERCO_BENCHMARK_TYPE: 'AERCO Benchmark Boiler',
  AERCO_INNOVATION_TYPE: 'AERCO Innovation Water Heater',
  SENTINEL_TYPE: 'Sentinel Leak Defense',
  EQUIPMENT_HANDLE: 'Equipment handle',
  OTHER: 'Other',
};
