import { MarlinTheme } from '@marlin/shared/theme';
import { TruncatedStringWithTooltip } from '@marlin/shared/ui-common-tooltip';
import { makeStyles } from 'tss-react/mui';

interface IChipLabelStyles {
  color: string;
}

const useChipColorLabelStyles = makeStyles<IChipLabelStyles>()((theme: MarlinTheme, { color }) => ({
  chipLabelContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  chipLabel: {
    color: theme.palette.primary.main,
  },
  chipCircle: {
    display: 'block',
    width: theme.typography.pxToRem(12),
    height: theme.typography.pxToRem(12),
    borderRadius: '50%',
    border: `${theme.typography.pxToRem(1)} solid ${theme.palette.text.primary}`,
    backgroundColor: color,
    marginRight: theme.typography.pxToRem(8),
  },
}));

interface IChipColorLabel {
  label: string;
  color: string;
}

export const ChipColorLabel = ({ label, color }: IChipColorLabel) => {
  const { classes } = useChipColorLabelStyles({ color });

  return (
    <div className={classes.chipLabelContainer}>
      <span className={classes.chipCircle} />
      <span className={classes.chipLabel}>
        <TruncatedStringWithTooltip text={label} />
      </span>
    </div>
  );
};
