import { MarlinTheme } from '@marlin/shared/theme';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { Icon, Switch, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { content } from './content';
import { useWebhookToggle } from './use-webhook-toggle.hook';

interface IWebhookToggleProps {
  webhookId: string;
  isSuspended: boolean;
  isEnabled: boolean;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  tooltip: {
    marginRight: theme.typography.pxToRem(8),
  },
}));

export const WebhookToggle = ({ webhookId, isEnabled, isSuspended }: IWebhookToggleProps) => {
  const { handleSwitchChange, isChangingStatus } = useWebhookToggle({ webhookId, isSuspended, isEnabled });
  const { classes } = useStyles();

  const checked = isEnabled && !isSuspended;

  return (
    <>
      {isSuspended && (
        <Tooltip text={content.WEBHOOK_TOGGLE.SUSPENDED_TOOLTIP} placement={'bottom'}>
          <Icon color="error" baseClassName="material-symbols-outlined" className={classes.tooltip}>
            info
          </Icon>
        </Tooltip>
      )}
      <Typography variant="body2" data-testid="webhook-toggle-label">
        {checked ? content.WEBHOOK_TOGGLE.ENABLED : content.WEBHOOK_TOGGLE.DISABLED}
      </Typography>
      <Switch
        disabled={isChangingStatus}
        checked={checked}
        onChange={handleSwitchChange}
        data-testid="webhook-toggle"
      />
    </>
  );
};
