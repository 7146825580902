import { useWaterTrends } from '@marlin/asset/data-access/device';
import { MarlinTheme } from '@marlin/shared/theme';
import { PERIOD_ENUM, Period, usePeriodState, usePreviousPeriodState } from '@marlin/shared/ui/chart';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { IUnitOption } from '../shared/unit-selector/use-unit-selector';
import { TrendDescription } from './trend-description.component';
import { WaterUsageBars } from './water-usage-bars.component';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  section: {
    display: 'flex',
    gap: theme.typography.pxToRem(16),
    padding: theme.typography.pxToRem(16),
    outline: `0.5px solid ${theme.palette.divider}`,
    flex: 1,
    flexWrap: 'wrap',
  },
  content: {
    display: 'flex',
    flex: 1,
    width: '100%',
    gap: theme.typography.pxToRem(16),
  },
  header: {
    flexBasis: '100%',
  },
}));

export function WaterUseSection({
  period,
  timezone,
  selectedUnit,
}: {
  period: PERIOD_ENUM;
  timezone: string;
  selectedUnit: IUnitOption;
}) {
  const { classes } = useStyles();
  const { range: currentPeriod, selectedPeriod, handleChangePeriod, handleCustomRangeSet } = usePeriodState(period);
  const { previousPeriod } = usePreviousPeriodState(selectedPeriod, currentPeriod);

  const { data } = useWaterTrends({
    enabled: true,
    params: {
      periodStart: currentPeriod.from?.toISOString() ?? '',
      periodEnd: currentPeriod.to?.toISOString() ?? '',
    },
  });

  const waterTrends = useMemo(() => {
    if (data) {
      return {
        ...data,
        periodStart: currentPeriod.from,
        periodEnd: currentPeriod.to,
        previousPeriodStart: previousPeriod.from,
        previousPeriodEnd: previousPeriod.to,
      };
    }
    return null;
  }, [currentPeriod.from, currentPeriod.to, data, previousPeriod.from, previousPeriod.to]);

  return (
    <div className={classes.section}>
      <div className={classes.header}>
        <Period
          timezone={timezone}
          onChange={handleChangePeriod}
          handleCustomRangeSet={handleCustomRangeSet}
          selectedPeriod={selectedPeriod}
          variant="popover"
          currentPeriod={currentPeriod}
        />
      </div>
      <div className={classes.content}>
        <TrendDescription
          trend={waterTrends?.trend}
          period={selectedPeriod}
          previousValue={waterTrends?.previousPeriodTotalVolume}
          currentValue={waterTrends?.totalVolume}
          unit={selectedUnit.value === 'percent' ? '%' : '$'}
        />
        <WaterUsageBars trends={waterTrends} />
      </div>
    </div>
  );
}
