import { getHttpClient } from '@marlin/shared/utils/react-query';
import { TOrgAddress, orgAddressSchema, safeParseData } from '@marlin/shared/utils/zod';

import { paths } from './api';
import { TOnboardDataSchema, onboardDataSchema, onboardResponseSchema } from './onboard.schema';

export const onboard = async (data: TOnboardDataSchema) => {
  const body = safeParseData(data, onboardDataSchema);

  const response = await getHttpClient().post(paths.ONBOARD, body);

  return safeParseData(response, onboardResponseSchema);
};

export const getOrganizationAddress = async (email: string): Promise<TOrgAddress> => {
  const response = await getHttpClient().get(paths.GET_ORG_ADDRESS, { params: { email } });

  return orgAddressSchema.parse(response);
};
