import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  activeIcon: {
    color: theme.palette.success.light,
    [theme.breakpoints.down('md')]: {
      height: theme.typography.pxToRem(14),
    },
  },

  activeText: {
    fontSize: theme.typography.pxToRem(14),
    marginLeft: theme.typography.pxToRem(8),
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.typography.pxToRem(4),
    },
  },

  inactiveIcon: {
    color: theme.palette.error.main,
    [theme.breakpoints.down('md')]: {
      height: theme.typography.pxToRem(14),
    },
  },

  batteryContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  batteryIcon: {
    color: theme.palette.success.light,
    [theme.breakpoints.down('md')]: {
      height: theme.typography.pxToRem(16),
    },
  },

  orangeBatteryIcon: {
    color: theme.palette.warning.main,
    [theme.breakpoints.down('md')]: {
      height: theme.typography.pxToRem(16),
    },
  },

  redBatteryIcon: {
    color: theme.palette.error.main,
    [theme.breakpoints.down('md')]: {
      height: theme.typography.pxToRem(16),
    },
  },

  batteryText: {
    marginLeft: theme.typography.pxToRem(4),
  },
}));
