import { useEquipmentDetails as useEquipmentDetailsQuery } from '@marlin/asset/data-access/equipment';
import {
  DeleteEquipmentModalBody,
  DeleteEquipmentModalFooter,
  DeleteEquipmentModalTitle,
} from '@marlin/asset/ui/delete-equipment-modal';
import { ActionDelete, ActionEdit, ContextMenu } from '@marlin/shared/ui-context-menu';
import { PageContainer, PageHeader } from '@marlin/shared/ui-page';
import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { useIdFromPathname } from '@marlin/shared/utils/url-params';
import { gatewayEquipmentModels, registerableEquipmentModels } from '@marlin/shared/utils/zod';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { Button } from '@mui/material';
import { useCallback, useContext, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { content } from '../content';
import { useEquipmentDetailsContextMenu } from '../desktop/use-equipment-details-context-menu.hook';
import { useNavigation } from '../desktop/use-navigation.hook';
import { EquipmentDetailsContent } from './equipment-details-content.component';
import { useStyles } from './equipment-details.styles';
import { TAB_VALUES, Tabs } from './tabs.component';

export const EquipmentDetailsMobile = () => {
  const { classes } = useStyles();
  const equipmentId = useIdFromPathname();
  const { goTo } = useRouter();
  const [tabValue, setTabValue] = useState<TAB_VALUES>(TAB_VALUES.Details);
  const { modalDispatch } = useContext(ModalContext);
  const navigation = useNavigation();

  const router = useRouter();
  const goToEquipmentList = useCallback(() => {
    router.goTo(routes.equipments.list.url());
  }, [router]);

  const { allowDelete, allowEdit } = useEquipmentDetailsContextMenu();

  const equipmentQuery = useEquipmentDetailsQuery({ equipmentId });
  const handleGoBack = () => {
    goTo(routes.equipments.list.path);
  };
  const handleEditAsset = () => {
    equipmentQuery?.data && goTo(routes.equipments.update.url(equipmentQuery?.data?.id));
  };

  if (
    ((equipmentQuery?.data?.isRegistered && registerableEquipmentModels.includes(equipmentQuery?.data?.model ?? '')) ||
      (equipmentQuery?.data?.additionalProperties?.['gatewayId'] &&
        gatewayEquipmentModels.includes(equipmentQuery?.data?.model ?? ''))) &&
    equipmentQuery?.data?.id
  ) {
    return <Navigate to={navigation.getEquipmentDashboardLink(equipmentQuery?.data.id)} replace />;
  }

  return (
    <PageContainer>
      <PageHeader
        title={equipmentQuery?.data?.name || ''}
        prefix="location-details-mobile"
        topActions={
          <Button
            data-testid="equipment-details-mobile-back-button"
            className={classes.button}
            startIcon={<ArrowBackRoundedIcon />}
            variant="outlined"
            onClick={handleGoBack}
          >
            {content.BUTTON_CANCEL}
          </Button>
        }
        rightSideActions={
          <div className={classes.sideActions}>
            <ContextMenu className={classes.mobileMenu}>
              {allowEdit && <ActionEdit onClick={handleEditAsset} />}
              {allowDelete && (
                <ActionDelete
                  onClick={() => {
                    modalDispatch({
                      type: MODAL_ACTION_TYPE.SHOW,
                      payload: {
                        title: <DeleteEquipmentModalTitle deviceCount={equipmentQuery.data?.deviceCount || 0} />,
                        body: (
                          <DeleteEquipmentModalBody
                            deleteAssetName={equipmentQuery.data?.name}
                            deviceCount={equipmentQuery.data?.deviceCount || 0}
                            assetId={equipmentQuery.data?.id}
                          />
                        ),
                        footer: (
                          <DeleteEquipmentModalFooter
                            goToEquipmentList={goToEquipmentList}
                            assetId={equipmentQuery.data?.id}
                            deviceCount={equipmentQuery.data?.deviceCount || 0}
                          />
                        ),
                      },
                    });
                  }}
                />
              )}
            </ContextMenu>
          </div>
        }
        bottomActions={<Tabs tabValue={tabValue} setTabValue={setTabValue} />}
      />
      <EquipmentDetailsContent equipment={equipmentQuery.data} tab={tabValue} />
    </PageContainer>
  );
};
