import { Button } from '@mui/material';

import { content } from './content';
import { useStyles } from './delete-modal.styles';

interface IModalFooterProps {
  canDelete: boolean;
  onClose: () => void;
  deleteAssetMethod: () => Promise<void>;
}

export const ModalFooter = ({ canDelete, onClose, deleteAssetMethod }: IModalFooterProps): JSX.Element | null => {
  const { classes } = useStyles();

  return canDelete ? (
    <div data-testid="delete-modal-footer">
      <Button className={classes.cancelButton} variant="text" onClick={onClose}>
        {content.CANCEL}
      </Button>
      <Button
        className={`${classes.button} ${classes.cancelButton}`}
        variant="contained"
        color="error"
        onClick={deleteAssetMethod}
      >
        {content.DELETE}
      </Button>
    </div>
  ) : (
    <Button className={classes.button} variant="contained" onClick={onClose} data-testid="delete-modal-go-back">
      {content.GO_BACK}
    </Button>
  );
};
