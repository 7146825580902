import { MarlinTheme } from '@marlin/shared/theme';
import { LoadingSpinner as UiLoadingSpinner } from '@marlin/shared/ui-loader';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    paddingTop: theme.typography.pxToRem(15),
    paddingBottom: theme.typography.pxToRem(15),
  },
}));

export const LoadingSpinner = ({ isLoading }: { isLoading: boolean }): JSX.Element | null => {
  const { classes } = useStyles();

  if (!isLoading) {
    return null;
  }

  return (
    <div className={classes.container}>
      <UiLoadingSpinner />
    </div>
  );
};
