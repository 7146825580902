import isInteger from 'lodash/isInteger';
import isString from 'lodash/isString';

const multiplyFactor = 100;

// decimal: 1.5 -> 2, 1.4 -> 1
// half: 1.5 -> 1.5, 1.4 -> 1.5
// detail: 1.5 -> 1.5, 1.4 -> 1.4
export type TRoundMode = 'decimal' | 'half' | 'detail';

export const roundValue = (value: number | string, mode: TRoundMode = 'detail') => {
  if (value === '') return value;

  const valueToRound = isString(value) ? parseFloat(value) : value;
  const sign = Math.sign(valueToRound);

  if (mode === 'half') {
    const roundedValue = (sign * Math.round(sign * valueToRound * 2)) / 2;
    return isInteger(roundedValue) ? `${roundedValue}.0` : roundedValue.toString();
  }

  if (mode === 'detail') {
    const roundedValue = (sign * Math.round(sign * valueToRound * 10)) / 10;
    return isInteger(roundedValue) ? `${roundedValue}.0` : roundedValue.toString();
  }

  return (sign * Math.round(sign * valueToRound)).toString();
};

export const getModulo = (value: number, mod: number): number => {
  return (value * multiplyFactor) % (mod * multiplyFactor);
};
