import { dateAdapter } from '@marlin/shared/utils-common-date';

import { TBucketOption } from '../model';

export const DateFormat = 'YYYY-MM-DDTHH:mm:ss';

export const getDatesForBarChart = (from: string, to: string, bucketOption?: TBucketOption) => {
  const dateFrom = dateAdapter.date(from);
  const dateTo = dateAdapter.date(to);

  if (!dateFrom || !dateTo || !bucketOption) return { dateFrom: from, dateTo: to };

  if (!shouldAdjustDate({ min: dateFrom.valueOf(), max: dateTo.valueOf() }, bucketOption)) {
    return {
      dateFrom: dateFrom.utc().format(DateFormat),
      dateTo: dateTo.utc().format(DateFormat),
    };
  }

  return {
    dateFrom: getDateFrom(dateFrom, bucketOption)?.utc().format(DateFormat),
    dateTo: getDateTo(dateTo, bucketOption)?.utc().format(DateFormat),
  };
};

export const getBucketSizeForBarChart = (bucketOption?: TBucketOption) => {
  if (!bucketOption) return 0;

  switch (bucketOption) {
    case 'minutes':
      return 1;
    case 'hours':
      return 60;
    case 'days':
      return 1440;
    case 'weeks':
      return 10080;
    case 'months':
      return 43200;
  }
};

const getDateFrom = (dateFrom: moment.Moment | null, bucketOption: TBucketOption) => {
  if (!dateFrom) return dateFrom;
  const timeUnit = getTimeUnitForBucketOption(bucketOption);

  return dateFrom.clone().startOf(timeUnit);
};

const getDateTo = (dateTo: moment.Moment | null, bucketOption: TBucketOption) => {
  if (!dateTo) return dateTo;
  const timeUnit = getTimeUnitForBucketOption(bucketOption);

  return dateTo.clone().endOf(timeUnit);
};

export const getDateFromWithAdjustByBucket = (
  dateFrom: moment.Moment | null | undefined,
  bucketOption: TBucketOption
) => {
  if (!dateFrom) return dateFrom;
  const timeUnit = getTimeUnitForBucketOption(bucketOption);

  return dateFrom.clone().add(1, timeUnit).startOf(timeUnit);
};

export const getDateToWithAdjustByBucket = (dateTo: moment.Moment | null | undefined, bucketOption: TBucketOption) => {
  if (!dateTo) return dateTo;
  const timeUnit = getTimeUnitForBucketOption(bucketOption);

  return dateTo.clone().endOf(timeUnit);
};

export const getTimeUnitForBucketOption = (bucketOption: TBucketOption) => {
  return bucketOption.slice(0, -1) as 'minute' | 'hour' | 'day' | 'week' | 'month';
};

export const shouldAdjustDate = (
  selection: {
    min: number;
    max: number;
  },
  bucketOption: TBucketOption
): boolean => {
  const timeUnit = getTimeUnitForBucketOption(bucketOption);
  const shouldAdjustDateFrom = getShouldAdjustDateFrom(selection.min, timeUnit);
  const shouldAdjustDateTo = getShouldAdjustDateTo(selection.max, timeUnit);

  return shouldAdjustDateFrom || shouldAdjustDateTo;
};

export const getShouldAdjustDateFrom = (timestamp: number, timeUnit: 'month' | 'week' | 'day' | 'hour' | 'minute') =>
  !compareTimestampValuesWithoutMilliseconds(
    dateAdapter.date?.(timestamp)?.startOf(timeUnit)?.valueOf() ?? 0,
    timestamp
  );

export const getShouldAdjustDateTo = (timestamp: number, timeUnit: 'month' | 'week' | 'day' | 'hour' | 'minute') =>
  !compareTimestampValuesWithoutMilliseconds(dateAdapter.date?.(timestamp)?.endOf(timeUnit)?.valueOf() ?? 0, timestamp);

const compareTimestampValuesWithoutMilliseconds = (timestamp1: number, timestamp2: number) => {
  return Math.floor(timestamp1 / 1000) === Math.floor(timestamp2 / 1000);
};
