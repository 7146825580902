import { TRole } from '@marlin/shared/utils-role';

export enum INVITATION_STATUS {
  PENDING = 'PENDING',
  EXPIRED = 'EXPIRED',
}

export interface IResendInvite {
  firstName: string;
  lastName: string;
  title: string;
  email: string;
  role: TRole;
}

export interface IDeleteUser {
  id: string;
  firstName: string;
  lastName: string;
}
