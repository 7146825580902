import { CommonChip } from '@marlin/shared/ui/chips';

import { ITab } from '../types';
import { useStyles } from './chip-tabs.styles';

interface IMobileTabsProps<TTabId> {
  tabs: ITab<TTabId>[];
  currentTabId: TTabId;
  changeTab: (tab: ITab<TTabId>) => void;
  testid: string;
}

export function MobileChipTabs<TTabId>({ tabs, currentTabId, changeTab, testid }: IMobileTabsProps<TTabId>) {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      {tabs.map((tab) => (
        <CommonChip
          label={tab.label}
          variant="outlined"
          onClick={() => changeTab(tab)}
          testId={`${testid}-${tab.label}`}
          active={tab.id === currentTabId}
        />
      ))}
    </div>
  );
}
