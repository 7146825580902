import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  filters: {
    marginLeft: theme.typography.pxToRem(16),
    marginTop: theme.typography.pxToRem(16),
    marginBottom: theme.typography.pxToRem(16),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  filterFields: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: theme.typography.pxToRem(16),
    width: '90%',
  },
  searchFilter: {
    width: theme.typography.pxToRem(308),
    minWidth: theme.typography.pxToRem(308),
  },

  locationFilter: {
    width: theme.typography.pxToRem(180),
    minWidth: theme.typography.pxToRem(180),
  },

  deviceTypeFilter: {
    width: theme.typography.pxToRem(280),
    minWidth: theme.typography.pxToRem(280),
  },
  clearAll: {
    marginRight: theme.typography.pxToRem(16),
    whiteSpace: 'nowrap',
  },
}));
