import { DetailRow, GatewayIdRow, LocationDetailRow, TooltipDetailRow } from '@marlin/shared/ui-device-drawer-mobile';
import { Divider } from '@mui/material';

import { content } from '../../content';
import { mapDeviceType } from '../details/details.component';
import { useDetails } from '../details/use-details.hook';
import { useStyles } from './device-details-mobile.styles';

export const DeviceDetailsMobile = () => {
  const { classes } = useStyles();

  const { deviceDetails, deviceLocation, parentLocation, gatewayId } = useDetails();

  return (
    <div className={classes.containaer}>
      <Divider />
      <DetailRow propName={content.SENSOR_TYPE} propValue={mapDeviceType(deviceDetails?.deviceType)} />
      <LocationDetailRow propName={content.LOCATION} deviceLocation={deviceLocation} parentLocation={parentLocation} />
      <TooltipDetailRow propName={content.ID} propValue={deviceDetails?.id} tooltipText={content.ID_TOOLTIP} />
      <GatewayIdRow propName={content.GATEWAY_ID} id={gatewayId} />
      <DetailRow propName={content.MANUFACTURER} propValue={deviceDetails?.manufacturer} />
      <TooltipDetailRow
        propName={content.MANUFACTURER_ID}
        propValue={deviceDetails?.displayManufacturerId}
        tooltipText={content.MANUFACTURER_ID_TOOLTIP}
      />
      <DetailRow propName={content.MODEL} propValue={deviceDetails?.model} />
      <DetailRow propName={content.DESCRIPTION} propValue={deviceDetails?.description} />
    </div>
  );
};
