import { content } from './content';
import type { TStatusMap } from './types';

export const statusIdToKeyAdapter = (id: string): string | undefined =>
  ({
    '0': 'startup',
    '1': 'auto',
    '2': 'idle',
    '3': 'manual',
    '4': 'sanitize',
    '5': 'cooldown',
  }[id]);

export const intellistationEquipmentModeLabelMap: TStatusMap = new Map([
  ['auto', { mode: content.EQUIPMENT_MODE_LABELS.AUTO, iconTag: 'smart_toy' }],
  ['manual', { mode: content.EQUIPMENT_MODE_LABELS.MANUAL, iconTag: 'pan_tool' }],
  ['idle', { mode: content.EQUIPMENT_MODE_LABELS.IDLE, iconTag: 'pending' }],
  ['sanitize', { mode: content.EQUIPMENT_MODE_LABELS.SANITIZE, iconTag: 'settings_suggest' }],
  ['startup', { mode: content.EQUIPMENT_MODE_LABELS.START_UP, iconTag: 'switch_access_shortcut' }],
  ['cooldown', { mode: content.EQUIPMENT_MODE_LABELS.COOL_DOWN, iconTag: 'ac_unit' }],
]);
