import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

import Breadcrumb from './breadcrumb.component';

export interface IBreadcrumb {
  id: string;
  label: string;
  icon: React.ReactElement;
  isActive?: boolean;
  onClick?: () => void;
}
export interface IBreadcrumbsProps {
  items: (IBreadcrumb | IBreadcrumb[])[];
  separator?: string | React.ReactElement;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  breadcrumbs: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    rowGap: theme.typography.pxToRem(8),
    [theme.breakpoints.down('md')]: {
      flexWrap: 'nowrap',
    },
  },
  breadcrumb: {
    display: 'flex',
    alignItems: 'center',
  },
  separator: {
    padding: `0 ${theme.typography.pxToRem(8)}`,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.action.active,
    '& svg': {
      fill: theme.palette.action.active,
      width: theme.typography.pxToRem(20),
      height: theme.typography.pxToRem(20),
    },
  },
  spacing: {
    paddingRight: theme.typography.pxToRem(8),
  },
}));

export function Breadcrumbs({ items = [], separator }: IBreadcrumbsProps) {
  const { classes } = useStyles();

  return (
    <div className={classes.breadcrumbs} data-testid="breadcrumbs">
      {items.map((item, index) => {
        if (Array.isArray(item)) {
          return <Breadcrumbs key={`lvl-${index}`} items={item} />;
        }

        const shouldDisplaySeparator = index !== items.length - 1 && separator;

        return (
          <div
            className={`${classes.breadcrumb}${shouldDisplaySeparator ? '' : ` ${classes.spacing}`}`}
            key={item.id}
            data-testid="breadcrumb"
            id={`breadcrumb-${item.id}`}
          >
            <Breadcrumb {...item} />
            {shouldDisplaySeparator && (
              <div className={classes.separator} data-testid="separator">
                {separator}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default Breadcrumbs;
