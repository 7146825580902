export const content = {
  SUSTAINABILITY_DASHBOARD: 'Sustainability Dashboard Settings',
  FLOW_METER_DASHBOARD: 'Flow Meter(s) on Dashboard',
  FLOW_METER_SECTION:
    'Select one or multiple flow meters for total water usage. Changing flow meters will change the trends on the homepage as well',
  AVERAGE_WATER_PRICE: 'Average Water Price',
  AVERAGE_WATER_PRICE_SECTION:
    "Enter your water utility's cost per volume. Read your water bill carefully as it may be in units of $ per 1000 gallons.",
  CANCEL: 'Cancel',
  SAVE: 'Save',
  SELECTED_FLOW_METERS: 'Selected Flow Meter(s)',
  COST_OF_WATER: 'Cost of Water',
  UNIT: 'Unit',
  SETTINGS_UPDATED: 'Your dashboard settings have been updated',
  CHANGE_FLOW_METER_ERROR: "Something went wrong. Settings hasn't been updated",
  INVALID_PRICE: 'Invalid price. Please enter a valid price',
  AT_LEAST_ONE_FLOW_METER_REQUIRED: 'At least one flow meter is required',
  PRICE_CAN_NOT_BE_EMPTY: 'Price can not be empty',
  PER_LITER: 'per liter',
  PER_GALLON: 'per gallon',
  PER_CUBIC_METER: 'per m³',
  PER_CUBIC_FOOT: 'per ft³',
};
