import { z } from 'zod';

export enum PORTFOLIO_NOTIFICATION_EVENTS {
  USER_REMOVED_FROM_PORTFOLIO = 'SendPortfolioUserRemovedNotification',
}

export enum PORTFOLIO_USER_REMOVED_REASON {
  REMOVED_FROM_PORTFOLIO_ORGANIZATION = 'RemovedFromPortfolioOrganization',
}

const portfolioUserRemovedReason = z.nativeEnum(PORTFOLIO_USER_REMOVED_REASON);

export const portfolioUserRemovedNotificationSchema = z.object({
  userId: z.string(),
  portfolios: z.array(z.string()),
  reason: portfolioUserRemovedReason.nullish(),
  organizationId: z.string(),
});

export type TPortfolioUserRemovedNotification = z.infer<typeof portfolioUserRemovedNotificationSchema>;
export type TPortfolioUserRemovedReason = z.infer<typeof portfolioUserRemovedReason>;
