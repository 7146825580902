import { TDynamicMetadataDashboardConfig, useDynamicMetadataConfig } from '@marlin/asset/data-access/equipment';
import { IConfig } from '@marlin/asset/shared/equipment-config';
import { ModelEnum } from '@marlin/shared/utils/zod';

import { aercoBoilerConfig, aercoWaterHeaterConfig } from '../details/aerco/config/config';
import { intellistationConfig } from '../details/intellistation/config/config';
import { partitionConfig } from '../details/partition/config/config';
import { pviBrigadeConfig, pviConquestConfig } from '../details/pvi/config/config';
import { sentinelConfig } from '../details/sentinel/config/config';
import { tekmarSmartBoilerConfig } from '../details/tekmar-smart-boiler/config/config';
import { tekmarSmartSteamConfig } from '../details/tekmar-smart-steam/config/config';

const getEquipmentConfig = (model?: string) => {
  switch (model) {
    case ModelEnum.enum.INTELLISTATION:
      return intellistationConfig;
    case ModelEnum.enum.TEKMAR_SMART_STEAM:
      return tekmarSmartSteamConfig;
    case ModelEnum.enum.TEKMAR_SMART_BOILER:
      return tekmarSmartBoilerConfig;
    case ModelEnum.enum.AERCO_BOILER_LN:
    case ModelEnum.enum.AERCO_BOILER_DUAL:
      return aercoBoilerConfig;
    case ModelEnum.enum.AERCO_WATER_HEATER_N:
    case ModelEnum.enum.AERCO_WATER_HEATER_INNOVATION:
      return aercoWaterHeaterConfig;
    case ModelEnum.enum.SENTINEL:
      return sentinelConfig;
    case ModelEnum.enum.PVI_BRIGADE:
      return pviBrigadeConfig;
    case ModelEnum.enum.CONQUEST_100_20L100A_GCL:
    case ModelEnum.enum.CONQUEST_100_25L100A_GCL:
    case ModelEnum.enum.CONQUEST_100_30L100A_GCL:
    case ModelEnum.enum.CONQUEST_1100_CQT1100:
    case ModelEnum.enum.CONQUEST_1100_CQTO1100:
    case ModelEnum.enum.CONQUEST_1200_CQT1200:
    case ModelEnum.enum.CONQUEST_1200_CQTO1200:
    case ModelEnum.enum.CONQUEST_130_100L130A_GCML:
    case ModelEnum.enum.CONQUEST_130_40L130A_GCML:
    case ModelEnum.enum.CONQUEST_130_40L130A_GCMLW:
    case ModelEnum.enum.CONQUEST_130_50L130A_GCML:
    case ModelEnum.enum.CONQUEST_130_50L130A_GCMLW:
    case ModelEnum.enum.CONQUEST_130_60L130A_GCML:
    case ModelEnum.enum.CONQUEST_130_60L130A_GCMLW:
    case ModelEnum.enum.CONQUEST_130_70L130A_GCMLW:
    case ModelEnum.enum.CONQUEST_130_70L130A_GCML:
    case ModelEnum.enum.CONQUEST_130_80L130A_GCML:
    case ModelEnum.enum.CONQUEST_130_80L130A_GCMLW:
    case ModelEnum.enum.CONQUEST_130_90L130A_GCML:
      return pviConquestConfig;
    case ModelEnum.enum.PARTITION:
      return partitionConfig;
    default:
      return null;
  }
};

export const mergeDynamicConfig = (config: IConfig, dynamicConfig: TDynamicMetadataDashboardConfig): IConfig => {
  const datapointToDisplay = (config.dashboard?.productDetails?.datapoints || []).map((datapoint) => datapoint.name);

  const productDetailsDatapoints = dynamicConfig.productDetails.datapoints.filter((datapoint) => {
    if (datapointToDisplay.length > 0) {
      return datapointToDisplay.includes(datapoint.name);
    }

    return true;
  });

  return {
    ...config,
    dashboard: {
      ...config.dashboard,
      productDetails: config.dashboard.productDetails
        ? {
            ...config.dashboard.productDetails,
            datapoints: productDetailsDatapoints,
          }
        : undefined,
      chart: config.dashboard.chart
        ? {
            ...config.dashboard.chart,
            datapointNames: dynamicConfig.chart.datapointNames,
            datapointGroupNames: dynamicConfig.chart.datapointGroupNames,
          }
        : undefined,
      sections: dynamicConfig.sections.map((section) => ({
        ...config.dashboard.commonSectionSettings,
        ...section,
      })),
    },
  };
};

interface IUseConfigParams {
  equipmentId?: string;
  equipmentModel?: string;
}

export const useEquipmentConfig = ({ equipmentId, equipmentModel }: IUseConfigParams) => {
  const config = getEquipmentConfig(equipmentModel);

  const { data, isSuccess } = useDynamicMetadataConfig({
    equipmentId: equipmentId ?? '',
    enabled: !!equipmentId && config?.dashboard.dynamic,
  });

  if (!equipmentModel || !equipmentId) {
    return null;
  }

  if (config?.dashboard.dynamic && isSuccess) {
    return mergeDynamicConfig(config, data);
  }

  return config;
};
