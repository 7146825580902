import { Grid } from '@mui/material';

import { content } from './content';
import { useStyles } from './tooltip-info.styles';

export const TooltipContent: React.FC = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.container} data-testid="health-card-tooltip">
      {content.HEALTH}
      <Grid container className={classes.criticalityRow}>
        <Grid item className={classes.labelItem}>
          <span className={`${classes.dot} ${classes.high}`} />
        </Grid>
        <Grid item className={classes.descriptionItem}>
          {content.HIGH_CRITICALITY}
        </Grid>
      </Grid>
      <Grid container className={classes.criticalityRow}>
        <Grid item className={classes.labelItem}>
          <span className={`${classes.dot} ${classes.low}`} />
        </Grid>
        <Grid item className={classes.descriptionItem}>
          {content.LOW_CRITICALITY}
        </Grid>
      </Grid>
      <Grid container className={classes.criticalityRow}>
        <Grid item className={classes.labelItem}>
          <span className={`${classes.dot} ${classes.no}`} />
        </Grid>
        <Grid item className={classes.descriptionItem}>
          {content.NO_ALERTS}
        </Grid>
      </Grid>
    </div>
  );
};
