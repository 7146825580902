import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import { Typography } from '@mui/material';

import { content } from '../content';
import { useStyles } from './dot-items.styles';

const items = [
  {
    text: content.EXPANSIVE_DATA_ANALYTICS,
  },
  {
    text: content.SYSTEM_VIEW,
  },
  {
    text: content.EXPERT_ADVICE,
  },
  {
    text: content.WEEKLY_REPORTS,
  },
];

export const DotItems = () => {
  const { classes } = useStyles();
  return (
    <div className={classes.container}>
      {items.map((item, index) => (
        <div key={index} className={classes.item}>
          <CircleRoundedIcon className={classes.dot} />
          <div>
            <Typography className={classes.dotText}>{item.text}</Typography>
          </div>
        </div>
      ))}
    </div>
  );
};
