import { useCallback, useMemo, useState } from 'react';

import { IRequestedTelemetry } from '../../types';

interface IUseDataPoints {
  activeRequestedTelemetry: IRequestedTelemetry[];
  onActiveDatapointsChange: (manufacturerId: string) => void;
  isDatapointActive: (manufacturerId: string) => boolean;
  clearAllActiveDatapoints: () => void;
}

export const useDataPoints = (requestedTelemetry: IRequestedTelemetry[]): IUseDataPoints => {
  const [activeDatapoints, setActiveDatapoints] = useState(
    new Set<string>(requestedTelemetry.map(({ manufacturerId }) => manufacturerId))
  );

  const onActiveDatapointsChange = (manufacturerId: string) => {
    setActiveDatapoints((prev) => {
      const newSet = new Set(prev);
      newSet.has(manufacturerId) ? newSet.delete(manufacturerId) : newSet.add(manufacturerId);

      return newSet;
    });
  };

  const isDatapointActive = useCallback(
    (manufacturerId: string) => {
      return activeDatapoints.has(manufacturerId);
    },
    [activeDatapoints]
  );

  const activeRequestedTelemetry = useMemo(
    () => requestedTelemetry.filter(({ manufacturerId }) => isDatapointActive(manufacturerId)),
    [isDatapointActive, requestedTelemetry]
  );

  const clearAllActiveDatapoints = () => {
    setActiveDatapoints(new Set<string>());
  };

  return { activeRequestedTelemetry, onActiveDatapointsChange, isDatapointActive, clearAllActiveDatapoints };
};
