export const content = {
  GATEWAY_ERROR_MESSAGE: `Something went wrong. Your sensor hasn't been created`,
  ALREADY_REGISTERED: 'This register code is already registered. Please try again.',
  NOT_EXISTS: 'This register code does not exist. Please try again.',
  REGISTRATION_CODE_LINK: 'How do I find my registration code?',
  REGISTER_CODE_ALREADY_USED: 'The code is already used, please try again',
  REGISTER_CODE_INCORRECT: 'The code is incorrect, please try again',
  EQUIPMENT_NAME_NOT_UNIQUE: 'The name already exists in the organization, please try again',
  REGISTER_CODE_EXPIRED: 'The code is expired, please try again',
  DATE_VALIDATION_FUTURE: 'The date cannot be in the future, please try again',
  INVALID_GATEWAY_MODE: 'Invalid gateway mode',
  GATEWAY_IS_NOT_CONFIGURED: 'Gateway is not configured',

  REQUIRED: 'This is a required field',
  MIN_MAX: 'Must be between 3 and 200 characters',
  REGISTER_CODE_MIN_MAX: 'You must enter 8 digits, no spaces or special characters. E.g.: 12345678',
};
