import { content } from './content';
import { DEVICE_TYPE } from './types';

export const getDeviceTypeText = (deviceType?: DEVICE_TYPE): string => {
  switch (deviceType) {
    case DEVICE_TYPE.TEMPERATURE: {
      return content.SENSOR_TYPES.TEMPERATURE;
    }
    case DEVICE_TYPE.PRESSURE: {
      return content.SENSOR_TYPES.PRESSURE;
    }
    case DEVICE_TYPE.LEAK: {
      return content.SENSOR_TYPES.LEAK;
    }
    case DEVICE_TYPE.PULSE_METER: {
      return content.SENSOR_TYPES.FLOW;
    }
    default: {
      return '';
    }
  }
};
