import { DEVICE_TYPE, IDevice } from '../../../../types';
import { FlowMeterConditions } from '../condition-type/flow-meter-conditions.component';
import { LeakConditions } from '../condition-type/leak-conditions.component';
import { PressureConditions } from '../condition-type/pressure-conditions.component';
import { TemperatureConditions } from '../condition-type/temperature-conditions.component';

interface IConditionSwitcherProps {
  device?: IDevice | undefined;
}

export function ConditionSwitcher({ device }: IConditionSwitcherProps): JSX.Element | null {
  if (!device) {
    return null;
  }

  switch (device.deviceType) {
    case DEVICE_TYPE.TEMPERATURE: {
      return <TemperatureConditions />;
    }
    case DEVICE_TYPE.PRESSURE: {
      return <PressureConditions />;
    }
    case DEVICE_TYPE.LEAK: {
      return <LeakConditions />;
    }
    case DEVICE_TYPE.PULSE_METER: {
      return <FlowMeterConditions />;
    }
    default: {
      return null;
    }
  }
}
