import { MarlinTheme } from '@marlin/shared/theme';
import { TablePagination } from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { IPagination, IRequestPagination } from './pagination.types';

interface IPaginationProps {
  pagination: IPagination | undefined;
  onPaginationChange: (pagination: IRequestPagination) => void;
}

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    marginTop: theme.typography.pxToRem(16),
    marginBottom: theme.typography.pxToRem(16),
  },
}));

const defaultPage = 1;
const defaultPageSize = 10;

export function Pagination({ pagination, onPaginationChange }: IPaginationProps): JSX.Element | null {
  const { classes } = useStyles();

  const [page, setPage] = useState((pagination?.page ?? defaultPage) - 1);
  const [rowsPerPage, setRowsPerPage] = useState(pagination?.pageSize ?? defaultPageSize);

  const handleChangePage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage);
      onPaginationChange({
        page: newPage + 1,
        pageSize: rowsPerPage,
      });
    },
    [onPaginationChange, rowsPerPage]
  );

  const handleChangeRowsPerPage = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newPageSize = parseInt(event.target.value, 10);
      setRowsPerPage(newPageSize);
      setPage(0);
      onPaginationChange({
        pageSize: newPageSize,
        page: defaultPage,
      });
    },
    [onPaginationChange]
  );

  useEffect(() => {
    if (pagination) {
      setPage(pagination.page - 1);
      setRowsPerPage(pagination.pageSize);
    }
  }, [pagination, setPage, setRowsPerPage]);

  if (!pagination || pagination?.page === 0 || pagination?.pageSize === 0) {
    return null;
  }

  return (
    <div data-testid="automation-list-pagination" className={classes.container}>
      <TablePagination
        component="div"
        count={pagination.totalItems}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
