import { RULE_CATEGORY } from '@marlin/alert/ui/automated-action-list';

export const defaultFilterParams = {
  equipmentId: [],
  locationId: [],
  term: '',
  isEnabled: null,
  ruleCategory: RULE_CATEGORY.HEALTH,
};

export const defaultPaginationParams = { page: 1, pageSize: 10 };
