import {
  QUERY_KEY,
  TExecuteOperationResponse,
  executeOperationQuery,
  queryClient as executeOperationQueryClient,
} from '@marlin/asset/data-access/gateway';
import { MarlinTheme } from '@marlin/shared/theme';
import { PageContainer, PageHeader } from '@marlin/shared/ui-page';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { ErrorRounded, HubRounded, LanRounded, SettingsRounded, ThermostatRounded } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Card, CardContent, Divider, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { z } from 'zod';

import { content } from '../../content';
import { IConnectionInfo, TEST_GATEWAY_STEPS, TErrors, useTestGatewayContext } from '../../context/test-flow.context';
import { useActivateGatewayModal } from '../../hooks/modals/use-activate-gateway-modal.hook';
import { useStopTestsModal } from '../../hooks/modals/use-stop-tests-modal.component';
import { useCounterFinishedOperation } from '../../hooks/use-counter-finished-operation.hook';
import { Errors } from './errors.component';
import { LinearProgressBar } from './linear-progress-bar-component';
import { SummaryItem } from './summary-item.component';
import { TestResult } from './test-results.component';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  card: {
    minWidth: theme.typography.pxToRem(600),
    width: 'min-content',
  },
  cardContent: {
    padding: theme.typography.pxToRem(24),
  },
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.typography.pxToRem(20),
  },
  backButton: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.typography.pxToRem(8),
  },
  summary: {
    marginTop: theme.typography.pxToRem(32),
  },
  summaryHeading: {
    fontWeight: theme.typography.fontWeightBold,
  },
  summaryItems: {
    display: 'flex',
    gap: theme.typography.pxToRem(16),
    marginTop: theme.typography.pxToRem(16),
  },
  noErrorsMessage: {
    whiteSpace: 'pre-line',
    marginTop: theme.typography.pxToRem(16),
    marginBottom: theme.typography.pxToRem(32),
  },
  actions: {
    display: 'flex',
    gap: theme.typography.pxToRem(16),
    '& > button': {
      flex: 1,
      padding: theme.typography.pxToRem(6),
    },
    marginTop: theme.typography.pxToRem(32),
  },
}));

export const TestGatewayConfiguration = () => {
  const { classes } = useStyles();
  const { deviceId } = useParams();

  const { operations, setStep, connections, operationErrors, setOperationErrors } = useTestGatewayContext();

  const [finishedOperations, setFinishedOperations] = useState<TExecuteOperationResponse[]>([]);
  const [progress, setProgress] = useState<number>(0);
  const [disableCancel, setDisableCancel] = useState<boolean>(false);
  const [loadedCount, setLoadedCount] = useState<number>(0);
  const { countConnectionFinishedId, countAddressesFinishedId } = useCounterFinishedOperation(
    operations,
    finishedOperations
  );
  const { enqueueSnackbar } = useSnackbar();

  const canActivate = !!operations.length && loadedCount === operations.length && !operationErrors?.length;

  useEffect(() => {
    setOperationErrors([]);
  }, [setOperationErrors]);

  useEffect(() => {
    operations.forEach((operation) => {
      const response = executeOperationQuery({ params: operation });

      response
        .then((res) => {
          const allErrors: TErrors[] = [];

          res.operations.forEach((op) => {
            if (op.errors.length) {
              op.errors.forEach((error) => {
                const operationError = {
                  ...error,
                  addressId: op.addressId,
                };
                allErrors.push(operationError);
              });
            }
            op.telemetry.forEach(({ error }) => {
              if (error) {
                allErrors.push({ ...error, addressId: op.addressId });
              }
            });
          });
          setOperationErrors((prev) => [...prev, ...allErrors]);
          setFinishedOperations((prev) => [...prev, { ...res }]);
          setLoadedCount((prev) => prev + 1);
        })
        .catch((err) => {
          const isCancelError = err.silent;
          if (!isCancelError) {
            enqueueSnackbar(content.ERROR_MESSAGE, {
              variant: 'error',
              preventDuplicate: true,
            });
            setLoadedCount((prev) => prev + 1);
          }

          // eslint-disable-next-line no-console
          if (err instanceof z.ZodError) console.log(err.issues);
        });
    });
  }, [operations, enqueueSnackbar, setFinishedOperations, setLoadedCount, setOperationErrors]);

  useEffect(() => {
    if (loadedCount === operations.length) {
      setProgress(100);
    } else {
      setProgress(Math.floor((loadedCount / operations.length) * 100));
    }
  }, [loadedCount, operations.length]);

  const handleRefetch = useCallback(() => {
    setFinishedOperations([]);
    setProgress(0);
    setLoadedCount(0);

    operations.forEach((operation) => {
      const response = executeOperationQuery({ params: operation });

      response
        .then((res) => {
          setFinishedOperations((prev) => [...prev, { ...res }]);
          setLoadedCount((prev) => prev + 1);
        })
        .catch((err) => {
          const isCancelError = err.silent;

          if (!isCancelError) {
            enqueueSnackbar(content.ERROR_MESSAGE, {
              variant: 'error',
              preventDuplicate: true,
            });
            setLoadedCount((prev) => prev + 1);
          }
        });
    });
  }, [operations, enqueueSnackbar, setFinishedOperations, setLoadedCount]);

  const { activateGateway } = useActivateGatewayModal({
    deviceId,
  });

  const cancelAllQueries = useCallback(async () => {
    setDisableCancel(true);
    executeOperationQueryClient.removeQueries({ queryKey: [QUERY_KEY.EXECUTE_OPERATION] });
    executeOperationQueryClient.cancelQueries({ queryKey: [QUERY_KEY.EXECUTE_OPERATION] });
  }, []);

  const handleBack = useCallback(() => {
    cancelAllQueries();
    setStep(TEST_GATEWAY_STEPS.CONFIGURE);
  }, [cancelAllQueries, setStep]);

  const handleCancel = useCallback(() => {
    cancelAllQueries();
  }, [cancelAllQueries]);

  useEffect(() => {
    setDisableCancel(progress === 100);
  }, [progress]);

  const { stopTestGateway } = useStopTestsModal({
    onStop: handleCancel,
  });

  const openStopModal = useCallback(() => {
    stopTestGateway();
  }, [stopTestGateway]);

  return (
    <PageContainer>
      <PageHeader
        title={content.GATEWAY}
        subtitle={content.TEST_CONFIGURATION.SUBTITLE}
        prefix="test-gateway-configuration"
        icon={<SettingsRounded />}
      />
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <div className={classes.heading}>
            <Typography variant="h6">{content.TEST_CONFIGURATION.RESULTS}</Typography>
            <Button
              onClick={handleBack}
              variant="text"
              className={classes.backButton}
              data-testid="test-gateway-go-back-button"
            >
              <ArrowBackIcon />
              {content.TEST_CONFIGURATION.BACK}
            </Button>
          </div>
          <Divider />
          <div className={classes.summary}>
            <Typography variant="body1" className={classes.summaryHeading}>
              {content.TEST_CONFIGURATION.SUMMARY}
            </Typography>
            <div className={classes.summaryItems}>
              <SummaryItem
                title={content.TEST_CONFIGURATION.CONNECTIONS}
                value={countConnectionFinishedId}
                icon={<HubRounded />}
              />
              <SummaryItem
                title={content.TEST_CONFIGURATION.ADDRESSES}
                value={countAddressesFinishedId}
                icon={<LanRounded />}
              />
              <SummaryItem
                title={content.TEST_CONFIGURATION.OPERATIONS}
                value={loadedCount}
                icon={<ThermostatRounded />}
              />
              <SummaryItem
                title={content.TEST_CONFIGURATION.ERRORS}
                value={operationErrors?.length ?? 0}
                icon={<ErrorRounded />}
                error={!!operationErrors?.length}
              />
            </div>
            {!canActivate && (
              <LinearProgressBar
                value={progress}
                disableCancel={disableCancel}
                onRefetch={handleRefetch}
                onCancel={openStopModal}
              />
            )}
          </div>
          {canActivate && (
            <Typography className={classes.noErrorsMessage} variant="body2">
              {content.TEST_CONFIGURATION.NO_ERRORS_MESSAGE}
            </Typography>
          )}
          <TestResult
            operationResults={finishedOperations.sort((resultA, resultB) =>
              sortTestResults(resultA, resultB, connections)
            )}
          />
          {operationErrors?.length ? <Errors errors={operationErrors} /> : null}
          <div className={classes.actions}>
            <Button variant="outlined" disabled={disableCancel} onClick={openStopModal}>
              {content.TEST_CONFIGURATION.CANCEL}
            </Button>
            <Button variant="outlined" onClick={handleBack}>
              {content.TEST_CONFIGURATION.EDIT_CONFIGURATION}
            </Button>
            <Button variant="contained" disabled={!canActivate} onClick={activateGateway}>
              {content.TEST_CONFIGURATION.ACTIVATE_GATEWAY}
            </Button>
          </div>
        </CardContent>
      </Card>
    </PageContainer>
  );
};

const sortTestResults = (
  resultA: TExecuteOperationResponse,
  resultB: TExecuteOperationResponse,
  connections: IConnectionInfo[]
) => {
  const connectionA = connections.find((connection) => connection.id === resultA.operations[0]?.connectionId);
  const connectionB = connections.find((connection) => connection.id === resultB.operations[0]?.connectionId);
  if (connectionA && connectionB) {
    if (connectionA.id !== connectionB.id) {
      return connectionA.name.localeCompare(connectionB.name);
    }

    const addressA = connectionA.addresses.find((address) => address.id === resultA.operations[0].addressId);
    const addressB = connectionB.addresses.find((address) => address.id === resultB.operations[0].addressId);
    if (addressA && addressB) {
      return addressA.address - addressB.address;
    }
  }

  return 0;
};
