import { IEquipmentTypeOption } from '@marlin/shared/ui-device-type';
import { EQUIPMENT_TYPE } from '@marlin/shared/utils/zod';
import { useCallback, useState } from 'react';

export interface IEquipmentParams {
  search?: string | undefined;
  locations?: string[];
  gatewayIds?: string[];
  equipmentType?: EQUIPMENT_TYPE[];
}

interface IEquipmentFilter {
  search: string | undefined;
  locations: { id: string; name: string }[];
  equipmentType: IEquipmentTypeOption[];
}

export const usePagination = () => {
  const [filterParams, setFilterParams] = useState<IEquipmentParams>();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const setPageSize = (size: number) => {
    setPaginationModel((prev) => ({ ...prev, pageSize: size }));
  };

  const setPage = useCallback(
    (page: number) => {
      setPaginationModel((prev) => ({ ...prev, page }));
    },
    [setPaginationModel]
  );
  const onFiltersChange = useCallback(
    (data: IEquipmentFilter) => {
      setPage(0);
      setFilterParams({
        search: data.search?.trim() || undefined,
        locations: data.locations?.map((location) => location.id) ?? [],
        equipmentType: data.equipmentType.map((option) => option.id),
      });
    },
    [setPage]
  );

  return { filterParams, onFiltersChange, setPageSize, setPage, paginationModel };
};
