import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  pullTab: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: theme.typography.pxToRem(24),
    '&.MuiPaper-root-MuiDrawer-paper': {
      backgroundColor: 'tomato',
    },
  },
}));
