import { ITab, MobileChipTabs } from '@marlin/shared/ui-tabs-mobile';
import { useCallback, useMemo, useState } from 'react';

import { tabs } from './tabs-config';
import { TAB_INDEX } from './tabs.types';
import { ITotalItems, getLabelForTab } from './tabs.utils';

interface IMobileAlertTabsProps {
  totalItems: ITotalItems | undefined;
  onTabChange: (tab: TAB_INDEX) => void;
}

export function MobileAlertTabs({ totalItems, onTabChange }: IMobileAlertTabsProps) {
  const [currentTabId, setCurrentTab] = useState<TAB_INDEX>(TAB_INDEX.CURRENT);

  const handleChange = useCallback(
    (tab: ITab<TAB_INDEX>) => {
      setCurrentTab(tab.id);
      onTabChange(tab.id);
    },
    [setCurrentTab, onTabChange]
  );

  const mobileTabs: ITab<TAB_INDEX>[] = useMemo(
    () => tabs.map((tab) => ({ id: tab.id, label: getLabelForTab(tab, totalItems) })),
    [totalItems]
  );

  return (
    <MobileChipTabs<TAB_INDEX>
      tabs={mobileTabs}
      currentTabId={currentTabId}
      changeTab={handleChange}
      testid="alert-tab"
    />
  );
}
