import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  menu: {
    padding: 0,
  },
  chipMenuItem: {
    padding: theme.typography.pxToRem(8),
  },
  optionMenuItem: {
    padding: `${theme.typography.pxToRem(4)} ${theme.typography.pxToRem(16)}`,
  },
  menuRoot: {
    marginLeft: theme.typography.pxToRem(4),
  },
}));
