export const content = {
  SENSOR_DETAILS: 'Sensor Details',
  SENSOR_NAME_LABEL: 'Sensor Name',
  MANUFACTURER_ID_LABEL: 'Manufacturer ID',
  SENSOR_TYPE_LABEL: 'Sensor Type',
  TEMPERATURE_LABEL: 'Temperature Sensor',
  PRESSURE_LABEL: 'Pressure Sensor',
  LEAK_LABEL: 'Leak Detector',
  LOCATION_LABEL: 'Location',
  EQUIPMENT_LABEL: 'Linked Equipment',
  PIN_SENSOR_LABEL: 'Pin Sensor to Home',
  PIN_SENSOR_HELPER: 'Use the pinning feature to pull a specific sensor to the top of the home screen.',
  ADDITIONAL_INFORMATION: 'Additional Information',
  MANUFACTURER_LABEL: 'Brand',
  MODEL_LABEL: 'Model',
  COMMISSION_DATE_LABEL: 'Commission Date and Time',
  ORGANIZATION_TIME_ZONE: (timezone?: string) => (timezone ? `Organization Time Zone: ${timezone}` : undefined),
  DESCRIPTION_LABEL: 'Description',
  DESCRIPTION_PLACEHOLDER: 'Add a short description about sensor',
  BUTTON_CANCEL: 'Cancel',
  BUTTON_UPDATE: 'Update',
  EMPTY_LOCATION_HELPER_TEXT: 'Location is required for equipment selection.',
  EMPTY_EQUIPMENT: 'No results found.',
  CLEAR_EQUIPMENT: ' ',
  BUTTON_CREATE: 'Create',
  REQUIRED: 'This is a required field',
  MIN_MAX: 'Must be between 3 and 200 characters',
  CONNECT_TO_SENSOR: 'Connect to your sensor to monitor and receive alerts.',
  REGISTER_CONNECT: 'Register & Connect',
  MANUFACTURER_ID_IS_NOT_RECOGNIZED: 'Manufacturer ID is not recognized',
  CONECTED_METER: 'Connected Meter',
  COMPOUND_PULSE_COUNTER: 'Compound Pulse Counter',
  SIGNAL_RECEIVER_TYPE: 'Signal Receiver Type',
  COMMISSION_DATE_MODAL: {
    TITLE: 'Change Commission Date?',
    BODY_1: 'Changing the model or type will change the ',
    BODY_2: 'commission date. ',
    BODY_3:
      'This will remove all previous alerts, existing automations, and hide previous data associated with the previous type. ',
    BODY_4: 'Continue?',
    FOOTER_CANCEL: 'Cancel',
    FOOTER_CONFIRM: 'Yes',
  },
  CALIBRATE: 'calibrate',
  RECALIBRATE: 're-calibrate',
  CALIBRATED_AT: (date: string) => `Calibrated at ${date}`,
  CALIBRATION_WARNING:
    'Default multiplier values are available for this meter for use at your own risk. It is strongly advised to perform the calibration process on the meter before using any data.',
  CALIBRATION_MODAL: {
    TITLE: 'Meter Calibration',
    TITLE_SMALL: 'Small Meter Calibration (CH1)',
    TITLE_LARGE: 'Large Meter Calibration (CH2)',
    READINGS_MULTIPLIER: 'METER READINGS MULTIPLIER',
    MANUAL_MULTIPLIER: 'MANUAL MULTIPLIER',
    MODAL_BODY:
      'We recommend to wait until 2 hours have passed since the initial reading. More accurate meter multipliers are calculated over longer time periods.',
    UOM_LABEL: 'Unit of Measurement',
    START_READING: 'Start Reading',
    START_DATE_AND_TIME: 'Start Date and Time',
    STOP_READING: 'Stop Reading',
    STOP_DATE_AND_TIME: 'Stop Date and Time',
    MULTIPLIER_SECTION: 'Multiplier',
    METER_MULTIPLIER_LABEL: 'Meter Multiplier',
    METER_READING: 'Meter Reading',
    CALIBRATE: 'Calibrate',
    SAVE_FOR_LATER: 'save for later',
    CANCEL: 'cancel',
    ON_PROCESS_SUCCESS: 'Calibration processed successfully',
    ON_PROCESS_ERROR: 'Error while processing calibration',
    ERRORS: {
      NOT_ENOUGH_TIME: 'Please wait until 2 hours have passed since the initial reading',
      INVALID_NUMBER: (digits: number) => `Enter a numerical value up to ${digits} digits. E.g.: 1234.123`,
      INVALID_DATE: `Enter correct date`,
      START_BEFORE_END: 'The Start Date and Time must be before the Stop Date and Time',
      START_BEFORE_COMMISION: 'The Start Date and Time cannot be before the Commission Date',
      START_READING_BEFORE_END_READING: 'The Start Measurement Value must be less than End Measurement Value',
      REQUIRED_VALUES:
        'Measurement unit, start and end measurement values and timestamps are required for auto calibration',
      DEVICE_NOT_FOUND: 'Device not found',
    },
    ORGANIZATION_TIME_ZONE: (timezone?: string) => (timezone ? `Org Time Zone: ${timezone}` : undefined),
  },
  WATER_MODAL: {
    TITLE: 'Not enough water has flowed',
    BODY: (value: number, unit: string) =>
      `Less than ${value} ${unit} have passed through the flow meter. More accurate meter multipliers are calculated over larger volumes.`,
    CONFIRM: 'calibrate anyway',
    CANCEL: 'back',
  },
  METER_MODEL_LABEL: 'Meter Model',
  METER_MANUFACTURER_LABEL: 'Meter Manufacturer',
  METER_MULTIPLIER_LABEL: 'Meter Multiplier',
  UNITS_OF_MEASUREMENT_LABEL: 'Unit of Measurement',
  CUBIC_FEET: 'ft³ - Cubic feet',
  LITERS: 'L - Liters',
  GALLONS: 'gal - Gallons',
  M3: 'm³ - Cubic meters',
  CHANNEL: 'CH',
  METER_TYPE: 'Meter Type',
  MULTIPLIER: 'Multiplier',
  READING_IS_SAVED: 'Reading Is Saved',
  READING_IS_SAVED_BODY:
    'You may continue calibration later. Flow data since initial meter reading date will still be using the existing multiplier from the last calibration.',
  GO_BACK_TO_CALIBRATION: 'go back to calibration',
  OK: 'OK',
  READING_HAS_BEEN_SAVED: 'Reading has been saved',
  SET_COMMISSION_DATE_INFO_TOOLTIP: 'Set commission date after creating the sensor',
  INVALID_COMMISION_DATE: (assignmentDate: string) =>
    `Choose a date on or after the sensor registration date: ${assignmentDate}`,

  INVALID_COMMISION_DATE_FUTURE: (date: string) =>
    `The date cannot be in the future. Choose a date on or before ${date}`,
};
