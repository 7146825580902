import { MarlinTheme } from '@marlin/shared/theme';
import { useRouter } from '@marlin/shared/utils-router';
import { routes } from '@marlin/shared/utils-routes';
import { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { IMetadata } from '../types';
import { LinkedItem } from './linked-item.component';

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  title: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: theme.typography.pxToRem(20),
    marginTop: theme.typography.pxToRem(8),
  },
}));

interface IPinnedDeviceCardProps {
  device?: IMetadata;
}

export function CardTitle({ device }: IPinnedDeviceCardProps) {
  const { classes } = useStyles();
  const router = useRouter();
  const currentUrl = useMemo(() => router.getUrl(), [router]);

  return (
    <span className={classes.title}>
      <LinkedItem item={device} goTo={(id: string) => routes.deviceDrawer.open(currentUrl, id)} />
    </span>
  );
}
