import { MODAL_ACTION_TYPE, ModalContext } from '@marlin/shared/utils-common-modal-context';
import { useCallback, useContext } from 'react';

import { ActivateModalFooter } from '../../components/activate-modal/activate-modal-footer.component';
import { content } from '../../content';

export const useActivateGatewayModal = ({ deviceId }: { deviceId?: string }) => {
  const { modalDispatch } = useContext(ModalContext);

  const handleCloseModal = useCallback(() => {
    modalDispatch({
      type: MODAL_ACTION_TYPE.DISMISS,
    });
  }, [modalDispatch]);

  const activateGatewayModal = () => {
    return {
      title: <div>{content.ACTIVATE_GATEWAY_MODAL.TITLE}</div>,
      body: (
        <div>
          <div>{content.ACTIVATE_GATEWAY_MODAL.BODY_TEXT}</div>
          <br />
          <div>{content.ACTIVATE_GATEWAY_MODAL.BODY_SUBTEXT}</div>
        </div>
      ),
      footer: <ActivateModalFooter deviceId={deviceId} handleCloseModal={handleCloseModal} />,
    };
  };

  const activateGateway = () => {
    modalDispatch({
      type: MODAL_ACTION_TYPE.SHOW,
      payload: activateGatewayModal(),
    });
  };

  return { activateGateway };
};
