import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import React, { ComponentType } from 'react';
import { GlobalStyles } from 'tss-react';

import { ColorMode, MarlinTheme } from './theme';
import { ColorModeContext } from './theme-toggle';

export const withTheme =
  (Cmp: ComponentType, theme: (mode: ColorMode) => MarlinTheme) =>
  (props: Record<string, unknown>): JSX.Element => {
    const [mode, setMode] = React.useState<ColorMode>('light');
    const colorMode = React.useMemo(
      () => ({
        toggleColorMode: () => {
          setMode((prevMode: ColorMode) => (prevMode === 'light' ? 'dark' : 'light'));
        },
      }),
      []
    );

    const currentTheme = theme(mode);

    return (
      <StyledEngineProvider injectFirst>
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={currentTheme}>
            <CssBaseline />
            {/* TODO: Improve this to one Link component which is mixed with mui and react router components */}
            <GlobalStyles styles={{ a: { color: currentTheme.palette.primary.main } }} />
            <Cmp {...props} />
          </ThemeProvider>
        </ColorModeContext.Provider>
      </StyledEngineProvider>
    );
  };
