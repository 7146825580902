import { FC, PropsWithChildren, useReducer } from 'react';

import { TeaserModalContext } from './teaser-modal-context';
import { initialState, modalReducer } from './teaser-modal-reducer';

export const TeaserModalProvider: FC<PropsWithChildren> = ({ children }) => {
  const [modalState, modalDispatch] = useReducer(modalReducer, initialState);

  return <TeaserModalContext.Provider value={{ modalState, modalDispatch }}>{children}</TeaserModalContext.Provider>;
};
