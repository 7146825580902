import { content } from './content';
import { TFrequency, TWebhookType } from './schema';

interface IFrequencyOption {
  id: TFrequency;
  name: string;
}

export const webhookFrequencyOptions: IFrequencyOption[] = [
  { id: '1hr', name: content.FREQUENCY_OPTIONS.ONE_HOUR },
  { id: '4hr', name: content.FREQUENCY_OPTIONS.FOUR_HOUR },
  { id: '8hr', name: content.FREQUENCY_OPTIONS.EIGHT_HOUR },
  { id: '1day', name: content.FREQUENCY_OPTIONS.ONE_DAY },
  { id: '1week', name: content.FREQUENCY_OPTIONS.ONE_WEEK },
];

interface IWebhookTypeOption {
  id: TWebhookType;
  name: string;
}

export const webhookTypeOptions: IWebhookTypeOption[] = [
  { id: 'total-organization-volume', name: content.WEBHOOK_TYPE_OPTIONS.TOTAL_ORGANIZATION_VOLUME },
  { id: 'all-individual-flow-meters', name: content.WEBHOOK_TYPE_OPTIONS.ALL_INDIVIDUAL_FLOW_METERS },
];
