import { IAlert } from '../types';
import { ResolvedAlertContentHeaderMobile } from './alert-content-resolved-header-mobile.component';
import { ResolvedAlertContentHeader } from './alert-content-resolved-header.component';
import { AutomationLinkSection } from './automation-link-section.component';

interface IAlertContentProps {
  alert: IAlert;
  isMobile: boolean;
  automationLink: (deviceId: string, isSystemRule: boolean) => string;
}

export function ResolvedAlertContent({ alert, isMobile, automationLink }: IAlertContentProps) {
  return (
    <div>
      {isMobile ? <ResolvedAlertContentHeaderMobile alert={alert} /> : <ResolvedAlertContentHeader alert={alert} />}
      <AutomationLinkSection
        name={alert.automation.name}
        link={automationLink(alert.device.id, alert.automation.isSystemRule)}
        errorType={alert.errorType}
      />
    </div>
  );
}
