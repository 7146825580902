import { TGateway } from '@marlin/asset/data-access/gateway';
import { MarlinTheme } from '@marlin/shared/theme';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { useCallback } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';
import { useDeregisterModal } from '../hook/use-deregister-modal.component';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  deleteIcon: {
    color: theme.palette.error.main,
  },

  deleteText: {
    color: theme.palette.error.main,
  },
}));

interface IActionDeleteProps {
  refreshData: () => void;
  gateway: TGateway;
}

export const DeregisterAction = ({ refreshData, gateway }: IActionDeleteProps) => {
  const { classes } = useStyles();
  const { openDeregisterModal } = useDeregisterModal({ gatewayId: gateway.id, refreshData });

  const handleDeregisterModal = useCallback(() => openDeregisterModal(gateway), [gateway, openDeregisterModal]);

  return (
    <MenuItem
      onClick={(event) => {
        handleDeregisterModal();
        event.stopPropagation();
      }}
    >
      <ListItemIcon>
        <DeleteRoundedIcon fontSize="small" className={classes.deleteIcon} />
      </ListItemIcon>
      <ListItemText className={classes.deleteText}>{content.DEREGISTER}</ListItemText>
    </MenuItem>
  );
};
