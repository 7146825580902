import { MarlinTheme } from '@marlin/shared/theme';
import { alpha } from '@mui/system';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  filtersWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.typography.pxToRem(16),
    paddingLeft: theme.typography.pxToRem(16),
  },

  filters: {
    display: 'flex',
    alignItems: 'center',
  },

  chipFilters: {
    marginRight: theme.typography.pxToRem(16),
  },

  filterControl: {
    display: 'flex',
    alignItems: 'center',
  },

  locationFilter: {
    width: theme.typography.pxToRem(180),
    marginRight: theme.typography.pxToRem(16),
  },

  searchFilter: {
    width: theme.typography.pxToRem(200),
  },

  clearAll: {
    marginRight: theme.typography.pxToRem(16),
  },

  locationChipsWrapper: {
    marginLeft: theme.typography.pxToRem(16),
    marginBottom: theme.typography.pxToRem(16),
  },

  locationChip: {
    marginRight: theme.typography.pxToRem(8),
    background: alpha(theme.palette.secondary.main, 0.08),
  },
}));
