import { getHttpClient } from '@marlin/shared/utils/react-query';
import { safeParseData } from '@marlin/shared/utils/zod';

import { paths } from './api';
import {
  TWaterTrendsParams,
  TWaterTrendsResponse,
  waterTrendsRequestSchema,
  waterTrendsResponseSchema,
} from './schema/water-trends.schema';

export const getWaterTrendsPerPeriod = async (params: TWaterTrendsParams): Promise<TWaterTrendsResponse> => {
  const requestParams = safeParseData(params, waterTrendsRequestSchema);
  const res = await getHttpClient().get(paths.WATER_TRENDS, { params: requestParams });
  return safeParseData(res, waterTrendsResponseSchema);
};
