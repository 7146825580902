import { MarlinTheme } from '@marlin/shared/theme';
import { Tooltip } from '@marlin/shared/ui-common-tooltip';
import { useMediaQuery, useTheme } from '@mui/material';
import { alpha } from '@mui/material';
import Chip from '@mui/material/Chip';
import { useRef } from 'react';
import { makeStyles } from 'tss-react/mui';

export interface IStyles {
  isActive: boolean;
}
export interface IBreadcrumbProps {
  label: string;
  isActive?: boolean;
  icon?: React.ReactElement;
  onClick?: () => void;
}

const useStyles = makeStyles<IStyles>()((theme: MarlinTheme, { isActive }) => ({
  root: {
    height: theme.typography.pxToRem(26),
    borderRadius: theme.typography.pxToRem(4),
    backgroundColor: alpha(theme.palette.primary.main, 0.04),
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22.4),
    color: isActive ? theme.palette.primary.main : theme.palette.text.primary,
    padding: `${theme.typography.pxToRem(2)} ${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(
      2
    )} ${theme.typography.pxToRem(4)}`,
    '&:hover': {
      backgroundColor: isActive ? alpha(theme.palette.primary.main, 0.14) : theme.palette.action.hover,
    },
    '& .MuiChip-icon': {
      fill: theme.palette.action.active,
      margin: 0,
      width: theme.typography.pxToRem(20),
      height: theme.typography.pxToRem(20),
      paddingRight: theme.typography.pxToRem(4),
    },
  },
  label: {
    maxWidth: theme.typography.pxToRem(240),
    padding: 0,
  },
}));

export function Breadcrumb({ label, isActive = false, icon, onClick }: IBreadcrumbProps) {
  const { classes } = useStyles({ isActive });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const chipRef = useRef<HTMLDivElement>(null);
  const isLabelTooLong = chipRef.current?.children?.length
    ? Array.from(chipRef.current.children).findIndex((el) => el.scrollWidth > el.clientWidth) !== -1
    : false;

  return (
    <Tooltip
      placement="bottom"
      text={label}
      disableHoverListener={isMobile ? true : !isLabelTooLong}
      disableTouchListener
      data-testid="breadcrumb-tooltip"
    >
      <Chip
        ref={chipRef}
        label={label}
        icon={icon}
        classes={{ root: classes.root, label: classes.label }}
        onClick={onClick}
        data-testid={isActive ? 'active-breadcrumb' : 'single-breadcrumb'}
        clickable={!isActive}
      />
    </Tooltip>
  );
}

export default Breadcrumb;
