import { useLocationHierarchy } from '@marlin/asset/data-access/location';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { TEquipment } from '@marlin/shared/utils/zod';
import TreeView from '@mui/lab/TreeView';
import { useMemo } from 'react';

import { getDefaultExpandedIds } from './get-default-expanded-id.util';
import { HierarchyIcon } from './hierarchy-icon.component';
import { RecursiveHierarchyLevel } from './recursive-hierarchy-level.component';

interface IHierarchyProps {
  locationId?: string;
  equipment?: TEquipment;
}

export const LocationHierarchy = ({ locationId, equipment }: IHierarchyProps) => {
  const locationHierarchyQuery = useLocationHierarchy({ locationId: locationId ?? '' });

  const defaultExpandedIds: string[] = useMemo(
    () => getDefaultExpandedIds(locationHierarchyQuery.data),
    [locationHierarchyQuery.data]
  );

  if (locationHierarchyQuery.isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <TreeView
      defaultCollapseIcon={<HierarchyIcon />}
      selected={equipment ? equipment.id : locationId}
      expanded={defaultExpandedIds}
    >
      {locationHierarchyQuery.data && (
        <RecursiveHierarchyLevel hierarchyLevel={locationHierarchyQuery.data} equipment={equipment} />
      )}
    </TreeView>
  );
};
