import { Box, Modal, Typography } from '@mui/material';

import { useStyles } from './modal-large.styles';

interface IModalLargeProps {
  title?: string | JSX.Element;
  body?: JSX.Element;
  footer?: JSX.Element;
  onClose: () => void;
}

const ModalLarge = ({ title, body, footer, onClose }: IModalLargeProps) => {
  const { classes } = useStyles();
  return (
    <Modal
      open={true}
      onClose={onClose}
      data-testid="modal-root"
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={classes.container} data-testid="modal-body">
        <div className={classes.body}>
          {typeof title === 'string' ? (
            <Typography variant="h6" component="h2" data-testid="modal-title">
              {title}
            </Typography>
          ) : (
            title
          )}
          <div className={classes.contentWrapper} data-testid="modal-content">
            {body}
          </div>
          <div className={classes.footer} data-testid="modal-footer">
            {footer}
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export { ModalLarge };
