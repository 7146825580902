import { TDeviceUserMeasuresPreferences, parseDisplayedValue } from '@marlin/shared/utils-format-reading';
import {
  ControlType,
  TControlState,
  TDeviceMetadataDatapointsResponse,
  TEquipmentSetting,
  TMetadataDynamicOption,
  TSettingDatapoint,
  content,
} from '@marlin/shared/utils/datapoint-mappers';

import { getControlType } from './get-datapoint-type';
import { validateDatapoint } from './validators/datapoint-validator';
import { validateMetadata } from './validators/metadata-validator';

export const mergeSettingsDatapointsWithMetadata = (
  metadataList: TDeviceMetadataDatapointsResponse[] | undefined,
  datapoints: TSettingDatapoint[] | undefined,
  userMeasuresPreferences?: TDeviceUserMeasuresPreferences
): TEquipmentSetting[] => {
  if (!metadataList || !datapoints) {
    return [];
  }

  const datapointsWithMetadata = datapoints.reduce((acc, datapoint): TEquipmentSetting[] => {
    let metadata = metadataList.find(
      (metadataItem) =>
        metadataItem.name.toLowerCase() === datapoint.name.toLowerCase() &&
        metadataItem.unitOfMeasure?.toLowerCase() === datapoint.unitOfMeasure?.toLowerCase()
    );

    if (!metadata) {
      metadata = metadataList.find((metadataItem) => metadataItem.name.toLowerCase() === datapoint.name.toLowerCase());
    }

    if (metadata && datapoint.dynamicMetadata) {
      metadata = {
        ...metadata,
        defaultValue: datapoint.dynamicMetadata.defaultValue ?? metadata.defaultValue,
        min: datapoint.dynamicMetadata.min ?? metadata.min,
        max: datapoint.dynamicMetadata.max ?? metadata.max,
        step: datapoint.dynamicMetadata.step ?? metadata.step,
        type: datapoint.dynamicMetadata.type ?? metadata.type,
        unitOfMeasure: datapoint.unitOfMeasure ?? metadata.unitOfMeasure,
        availableStatuses: datapoint.dynamicMetadata.availableStatuses ?? metadata.availableStatuses,
        options:
          datapoint.dynamicMetadata.options.map((dynamicOption) => {
            const metadataOption = metadata?.options?.find(
              (metadataOption) => metadataOption.name === dynamicOption.name
            );

            return {
              ...dynamicOption,
              label: metadataOption?.label ?? dynamicOption.name,
            };
          }) ?? metadata.options,
      };
    }

    const isValid = validateMetadata(metadata, datapoint.name) && validateDatapoint(datapoint, metadata);
    const controlState: TControlState = isValid ? 'active' : 'error';
    const controlType = getControlType(metadata);

    const baseSetting = {
      name: datapoint.name,
      label: metadata?.label || datapoint.name,
      orderNumber: metadata?.orderNumber ?? 0,
    };

    if (controlType === ControlType.Enum.boolean) {
      const value = !!Number(datapoint.value);

      return [
        ...acc,
        {
          ...baseSetting,
          value,
          controlState,
          displayedValue: value ? content.ON : content.OFF,
          controlType: ControlType.Enum.boolean,
        },
      ];
    }

    const extendedSetting = {
      ...baseSetting,
      min: metadata?.min ? parseFloat(metadata.min) : undefined,
      max: metadata?.max ? parseFloat(metadata.max) : undefined,
      step: metadata?.step ? parseFloat(metadata.step) : undefined,
      value: datapoint.value,
    };

    if (controlType === ControlType.Enum.option) {
      const options = (metadata?.options ?? []).filter(({ id }) => !!id) as TMetadataDynamicOption[];

      return [
        ...acc,
        {
          ...extendedSetting,
          options: options ?? [],
          controlState,
          displayedValue: metadata?.options.find((option) => option.id === datapoint.value)?.label || datapoint.value,
          controlType: ControlType.Enum.option,
        },
      ];
    }

    return [
      ...acc,
      {
        ...extendedSetting,
        maxThreshold: metadata?.maxThreshold ? parseFloat(metadata.maxThreshold) : undefined,
        minThreshold: metadata?.minThreshold ? parseFloat(metadata.minThreshold) : undefined,
        controlState,
        unitOfMeasure: metadata?.unitOfMeasure ?? null,
        displayedValue: parseDisplayedValue(datapoint.value, metadata?.unitOfMeasure ?? null, userMeasuresPreferences),
        controlType: ControlType.Enum.value,
      },
    ];
  }, [] as TEquipmentSetting[]);

  return datapointsWithMetadata.sort((a, b) => {
    if (!a.orderNumber && !b.orderNumber) {
      return 0;
    }
    if (!a.orderNumber) {
      return 1;
    }
    if (!b.orderNumber) {
      return -1;
    }
    return a.orderNumber - b.orderNumber;
  });
};
