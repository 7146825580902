import { TCreateSystemMapNodeParams } from '@marlin/system-map/data-access/system-map';
import { TAssetType } from '@marlin/system-map/shared/data-access-schemas';

import { TFormNode } from './flow-link-form.schema';
import { ASSET_TYPE, IFlowLink, IFormAsset, NODE_TYPE } from './types';

export const mapFlowLinkType = (type: TAssetType | undefined): ASSET_TYPE | undefined => {
  switch (type) {
    case 'DEVICE': {
      return ASSET_TYPE.DEVICE;
    }
    case 'EQUIPMENT': {
      return ASSET_TYPE.EQUIPMENT;
    }
    case 'LOCATION': {
      return ASSET_TYPE.LOCATION;
    }
    default: {
      return undefined;
    }
  }
};

export const mapNodeType = (type: string): NODE_TYPE | undefined => {
  switch (type) {
    case 'inlet': {
      return NODE_TYPE.INLET;
    }
    case 'outlet': {
      return NODE_TYPE.OUTLET;
    }
    default: {
      return undefined;
    }
  }
};

const mapFormNodeValues = (node: TCreateSystemMapNodeParams): IFormAsset => ({
  id: node.nodeId || '',
  assetId: node.assetId || null,
  nodeId: node.nodeId || null,
  name: node.name || '',
  type: mapFlowLinkType(node.assetType || undefined),
  nodeType: node.nodeType,
});

export const mapFormLinkValues = (node: TCreateSystemMapNodeParams, nodeType: NODE_TYPE): IFlowLink =>
  nodeType === NODE_TYPE.INLET ? { outlet: mapFormNodeValues(node) } : { inlet: mapFormNodeValues(node) };

export const mapAssetToNode = (asset?: TFormNode): TCreateSystemMapNodeParams => {
  return {
    assetId: asset?.assetId || null,
    nodeId: asset?.id || null,
    assetType: asset?.assetType || undefined,
    nodeType: asset?.nodeType || 'ASSET',
    name: asset?.name,
    tags: asset?.tags || undefined,
  };
};
