import { getHttpClient } from '@marlin/shared/utils/react-query';

import { TNode, TNodeListFilters, nodeListSchema } from '../node.model.schema';
import { paths } from './api';

export const getNodes = async (body: TNodeListFilters): Promise<TNode[]> => {
  const res = await getHttpClient().post<unknown, unknown>(paths.NODES, body);

  return nodeListSchema.parse(res);
};
