import { INPUT_TYPE, Input } from '@marlin/shared/ui-form-common';
import { Paper } from '@marlin/shared/ui-page';
import { Grid } from '@mui/material';
import { useState } from 'react';

import { useStyles } from './api-key.styles';
import { content } from './content';
import { Copy } from './copy.component';
import { Visibility } from './visibility.component';

interface IApiKeyProps {
  keyHeader?: string;
  keyValue?: string;
}

export const ApiKey = ({ keyHeader = '', keyValue = '' }: IApiKeyProps) => {
  const { classes } = useStyles();
  const [valueInputType, setValueInputType] = useState(INPUT_TYPE.PASSWORD);

  const headerEndAdornment = (
    <div className={classes.adornment}>
      <Copy value={keyHeader} testId="api-key-header" />
    </div>
  );

  const valueEndAdornment = (
    <div className={classes.adornment}>
      <Visibility inputType={valueInputType} onInputTypeChange={setValueInputType} />
      <Copy value={keyValue} testId="api-key-value" />
    </div>
  );

  return (
    <Paper data-testid="card">
      <Grid container columnSpacing={2} className={classes.section}>
        <Grid item className={classes.sectionTitle} data-testid="card-title">
          {content.API_KEY_TITLE}
        </Grid>
      </Grid>
      <Grid container columnSpacing={2} rowSpacing={2} className={classes.section}>
        <Grid item md={6} xs={12}>
          <Input
            disabled
            label={content.API_KEY_HEADER}
            value={keyHeader}
            externalEndAdornment={{ endAdornment: headerEndAdornment }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Input
            disabled
            label={content.API_KEY_VALUE}
            value={keyValue}
            type={valueInputType}
            externalEndAdornment={{
              endAdornment: valueEndAdornment,
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};
