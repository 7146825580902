import { EQUIPMENT_UPSERT_ERROR } from '@marlin/asset/data-access/equipment';
import { TEquipmentFormTypes } from '@marlin/shared/ui-form';
import { useSnackbar } from '@marlin/shared/ui/snackbar-wrapper';
import { TUpsertEquipmentForm } from '@marlin/shared/utils/zod';
import { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { handleRegistrationError } from '../utils';

export const useUpsertEquipment = ({
  upsertEquipmentErrorCode,
  setError,
  equipmentType,
}: {
  upsertEquipmentErrorCode?: EQUIPMENT_UPSERT_ERROR | undefined;
  setError: UseFormReturn<TUpsertEquipmentForm>['setError'];
  equipmentType?: TEquipmentFormTypes;
}) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (upsertEquipmentErrorCode) {
      handleRegistrationError(upsertEquipmentErrorCode, setError, enqueueSnackbar, equipmentType);
    }
  }, [upsertEquipmentErrorCode, setError, enqueueSnackbar]);

  const [dateValidationErrorMessage, setDateValidationErrorMessage] = useState<string | undefined>(undefined);

  return {
    setDateValidationErrorMessage,
    dateValidationErrorMessage,
  };
};
