import { TDeviceSchema } from '@marlin/asset/data-access/device';
import { MarlinTheme } from '@marlin/shared/theme';
import { AssetMobileCard } from '@marlin/shared/ui-asset-card-mobile';
import { ActionCopy, ActionDelete, ActionEdit, ContextMenu } from '@marlin/shared/ui-context-menu';
import { LoadingSpinner } from '@marlin/shared/ui-loader';
import { PaginationMobile } from '@marlin/shared/ui-pagination-mobile';
import { DEVICE_DRAWER_ACTION_TYPE, useDeviceDrawerContext } from '@marlin/shared/utils/sensor-provider';
import { useCallback } from 'react';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';
import { IDeviceExtended } from '../shared/types';
import { useDeviceHubContextMenu } from '../shared/use-devices-hub-context-menu.hook';
import { useDeleteDevice } from './use-delete-device.hook';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  mobileMenu: {
    padding: 0,
  },
  emptyList: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 1,
    backgroundColor: theme.palette.background.secondary,
  },
  container: {
    backgroundColor: theme.palette.background.secondary,
  },
}));

interface IDeviceListProps {
  rows: TDeviceSchema[];
  fetchNextPage: () => void;
  isListLoading?: boolean;
  totalItems?: number;
  hasNextPage?: boolean;
}

export const DeviceList = ({ rows, totalItems, hasNextPage, fetchNextPage, isListLoading }: IDeviceListProps) => {
  const { classes } = useStyles();
  const { allowDelete, allowEdit, editDevice } = useDeviceHubContextMenu();
  const { deleteDevice } = useDeleteDevice();
  const { deviceDrawerDispatch } = useDeviceDrawerContext();

  const addSensorsFromContext = useCallback(() => {
    deviceDrawerDispatch({
      type: DEVICE_DRAWER_ACTION_TYPE.SET_SENSORS,
      payload: {
        rows,
      },
    });
  }, [rows, deviceDrawerDispatch]);

  if (isListLoading) {
    return <LoadingSpinner />;
  }

  if (!rows.length) {
    return <div className={classes.emptyList}>{content.EMPTY_LIST}</div>;
  }

  return (
    <div className={classes.container}>
      {rows.map((device: TDeviceSchema) => (
        <AssetMobileCard
          addSensorsFromContext={addSensorsFromContext}
          isEquipment={false}
          key={device.id}
          asset={device}
          data-testid="device-card"
        >
          <ContextMenu className={classes.mobileMenu} data-testid="device-card-menu">
            {allowEdit && <ActionEdit onClick={() => editDevice(device.id)} />}
            {allowDelete && <ActionDelete onClick={() => deleteDevice(device as unknown as IDeviceExtended)} />}
            <ActionCopy textToCopy={device.id} contentCopy={content.ACTION_COPY} />
          </ContextMenu>
        </AssetMobileCard>
      ))}
      <PaginationMobile
        totalItems={totalItems}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        loadedItems={rows.length}
        data-testid="devices-pagination"
      />
    </div>
  );
};
