import { EQUIPMENT_TYPE } from '@marlin/shared/utils/zod';
import DeviceHubRoundedIcon from '@mui/icons-material/DeviceHubRounded';
import { Icon } from '@mui/material';
import React, { ReactElement } from 'react';

export const getEquipmentIcon = (type: EQUIPMENT_TYPE | undefined | null, className?: string): ReactElement => {
  switch (type) {
    case EQUIPMENT_TYPE.DIGITAL_MIXING_VALVE:
    case EQUIPMENT_TYPE.SHUT_OFF_VALVE: {
      return (
        <Icon baseClassName="material-symbols-outlined" className={className}>
          valve
        </Icon>
      );
    }
    case EQUIPMENT_TYPE.STEAM_BOILER:
    case EQUIPMENT_TYPE.BOILER: {
      return (
        <Icon baseClassName="material-symbols-outlined" className={className}>
          water_heater
        </Icon>
      );
    }
    case EQUIPMENT_TYPE.HOT_WATER_HEATER: {
      return (
        <Icon baseClassName="material-symbols-outlined" className={className}>
          water_voc
        </Icon>
      );
    }
    case EQUIPMENT_TYPE.LEAK_DEFENSE_SYSTEM: {
      return (
        <Icon baseClassName="material-symbols-outlined" className={className}>
          water_lock_rounded
        </Icon>
      );
    }
    case EQUIPMENT_TYPE.PARTITION_CONTROLLER: {
      return (
        <Icon baseClassName="material-symbols-outlined" className={className}>
          sensor_door
        </Icon>
      );
    }
    default: {
      return <DeviceHubRoundedIcon className={className} />;
    }
  }
};
