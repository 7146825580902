import { MarlinTheme } from '@marlin/shared/theme';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useController } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { content } from '../content';
import { AUTOMATION_STATUS, IFilter } from '../types';
import { IAutomationHubFilters } from './types';
import { useAutomationStatus } from './use-automation-status.hook';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  input: {
    backgroundColor: theme.palette.background.primary,
    width: '100%',
    height: '100%',
  },
}));

const data: IFilter[] = [
  {
    id: AUTOMATION_STATUS.ALL,
    name: content.FILTERS_AUTOMATION.VALUE.ALL,
  },

  {
    id: AUTOMATION_STATUS.ON,
    name: content.FILTERS_AUTOMATION.VALUE.ON,
  },
  {
    id: AUTOMATION_STATUS.OFF,
    name: content.FILTERS_AUTOMATION.VALUE.OFF,
  },
];

export function StatusSelect() {
  const { classes } = useStyles();
  const {
    field: { value, onChange },
  } = useController<IAutomationHubFilters>({ name: 'isEnabled' });

  const { status, changeStatus } = useAutomationStatus(onChange, value as boolean | null);

  return (
    <FormControl className={classes.input}>
      <InputLabel>{content.FILTERS_AUTOMATION.LABEL}</InputLabel>
      <Select
        data-testid="automations-filters-status"
        label={content.FILTERS_AUTOMATION.LABEL}
        value={status}
        onChange={changeStatus}
      >
        {data?.map(({ name, id }, index) => {
          return (
            <MenuItem key={index} value={id}>
              {name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
