import { TLocationSchema } from '@marlin/asset/data-access/location';
import { ISortParams } from '@marlin/asset/shared/ui/mobile-filter-modal';

import { content } from '../content';

export type TLocationSort = keyof TLocationSchema;

export const sortList: ISortParams<TLocationSort>[] = [
  {
    id: 'name',
    name: content.SORTING_LOCATION_NAME,
  },
  {
    id: 'parentLocationName',
    name: content.SORTING_PARENT_LOCATION,
  },
  {
    id: 'equipmentCount',
    name: content.SORTING_EQUIPMENT_NUMBER,
  },
  {
    id: 'deviceCount',
    name: content.SORTING_SENSORS_NUMBER,
  },
];
