import { zodResolver } from '@hookform/resolvers/zod';
import { MarlinTheme } from '@marlin/shared/theme';
import { Paper } from '@marlin/shared/ui-page';
import { TCreateSystemMapLinkParams, TCreateSystemMapNodeParams } from '@marlin/system-map/data-access/system-map';
import { Divider, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { ChangeEvent, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { Buttons } from './buttons.component';
import { content } from './content';
import { FlowLinkSchema } from './flow-link-form.schema';
import { FlowNodeSelect } from './flow-node-select/flow-node-select.component';
import { InletTitle } from './flow-node-select/inlet-title.component';
import { OutletTitle } from './flow-node-select/outlet-title.component';
import { mapAssetToNode, mapFormLinkValues, mapNodeType } from './helpers';
import { IFlowLink, NODE_TYPE } from './types';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    padding: 0,
  },

  header: {
    padding: theme.typography.pxToRem(24),
  },

  form: {
    padding: theme.typography.pxToRem(24),
  },
}));

interface IFlowLinkFormProps {
  defaultValues: TCreateSystemMapNodeParams;
  onSubmit: (link: TCreateSystemMapLinkParams) => void;
  closeModal: () => void;
}

export const FlowLinkForm = ({ onSubmit, closeModal, defaultValues }: IFlowLinkFormProps) => {
  const { classes } = useStyles();

  const [nodeType, setNodeType] = useState<NODE_TYPE>(NODE_TYPE.OUTLET);
  const formDefaultValues = mapFormLinkValues(defaultValues, nodeType);

  const handleNodeTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newNodeType = mapNodeType(event.target.value);
    if (newNodeType) {
      setNodeType(newNodeType);
      const newDefaultValues = mapFormLinkValues(defaultValues, newNodeType);
      form.reset(newDefaultValues);
    }
  };

  const form = useForm<IFlowLink>({
    defaultValues: formDefaultValues,
    mode: 'onChange', // NOTE: onchange mode set because of autocomplete
    resolver: zodResolver(FlowLinkSchema),
  });

  const createFlowLinkSubmit = useCallback(
    (link: IFlowLink) => {
      const teeNode = defaultValues;
      const assetNode = mapAssetToNode(nodeType === NODE_TYPE.INLET ? link.inlet : link.outlet);
      const systemMapLink: TCreateSystemMapLinkParams =
        nodeType === NODE_TYPE.INLET
          ? { outletData: teeNode, inletData: assetNode }
          : { outletData: assetNode, inletData: teeNode };

      onSubmit(systemMapLink);
      form.reset(formDefaultValues);
      closeModal();
    },
    [defaultValues, nodeType, onSubmit, form, formDefaultValues, closeModal]
  );

  const cancelCreateFlowLink = useCallback(() => {
    form.reset(formDefaultValues);
    closeModal();
  }, [closeModal, form, formDefaultValues]);

  return (
    <Paper className={classes.container}>
      <div className={classes.header}>
        <Typography variant="h6">{content.TITLE}</Typography>
      </div>
      <Divider />
      <div className={classes.form}>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(createFlowLinkSubmit)}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <RadioGroup row name="nodeType" value={nodeType} onChange={handleNodeTypeChange}>
                  <FormControlLabel value={NODE_TYPE.OUTLET} control={<Radio />} label={content.RADIO_OUTLET_LABEL} />
                  <FormControlLabel value={NODE_TYPE.INLET} control={<Radio />} label={content.RADIO_INLET_LABEL} />
                </RadioGroup>
              </Grid>
              <Grid item xs={12} md={6}>
                <FlowNodeSelect
                  nodeType={NODE_TYPE.OUTLET}
                  title={<OutletTitle>{content.OUTLET}</OutletTitle>}
                  defaultValue={formDefaultValues.outlet}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FlowNodeSelect
                  nodeType={NODE_TYPE.INLET}
                  title={<InletTitle>{content.INLET}</InletTitle>}
                  defaultValue={formDefaultValues.inlet}
                />
              </Grid>
            </Grid>
            <Buttons onSubmit={createFlowLinkSubmit} onCancel={cancelCreateFlowLink} />
          </form>
        </FormProvider>
      </div>
    </Paper>
  );
};
