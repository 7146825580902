export enum PERMISSIONS {
  SHOW_SETTINGS_PAGE = 'settings.settings-people.show-settings-hub',
  DEVICE_DRAWER_MANAGE_AUTOMATION = 'device-drawer.manage-automations.manage-automation',
  SHOW_DEVICE_DRAWER_EDIT_ICON = 'device.device-drawer.edit-icon',
  SHOW_DEVICE_DRAWER_DELETE_ICON = 'device.device-drawer.delete-icon',
  CREATE_NEW_DEVICE = 'devices-hub.header.create-new-device',
  CREATE_NEW_EQUIPMENT = 'equipment-hub.header.create-new-equipment',
  CREATE_NEW_LOCATION = 'locations-hub.header.create-new-location',
  CREATE_NEW_AUTOMATION = 'automation.header.create-new-automation',
  CREATE_NEW_MECHANICAL_ROOM = 'home.create-new-mechanical-room',
  EDIT_LOCATION = 'location.location-hub.edit-location',
  DELETE_LOCATION = 'location.location-hub.delete-location',
  SHOW_AUTOMATION_TOOGLE = 'automation.automation-list.show-automation-toogle',
  SHOW_ORGANIZATION_SETTINGS_PAGE = 'settings.settings-organization.show-organization-settings',
  SHOW_ORGANIZATION_ADDRESS_SETTINGS_PAGE = 'settings.settings-organization.show-organization-address',
  SHOW_MONNIT_API_KEY_SETTINGS_PAGE = 'settings.settings-organization.show-monnit-api-key',
  AUTOMATION_SNOOZE_ALERTS = 'automation.automation-list.snooze-alerts',
  EDIT_ASSET = 'location.location-details.edit-asset',
  DELETE_ASSET = 'location.location-details.delete-asset',
  EDIT_DEVICE = 'device.device-hub.edit-device',
  DELETE_DEVICE = 'device.device-hub.delete-device',
  EDIT_EQUIPMENT = 'equipment.equipment-details.edit-equipment',
  DELETE_EQUIPMENT = 'equipment.equipment-details.delete-equipment',
  DEREGISTER_EQUIPMENT = 'equipment.equipment-details.deregister-equipment',
  UPDATE_EQUIPMENT_SCHEDULE = 'equipment.equipment-settings.update-equipment-schedule',
  UPDATE_EQUIPMENT_SETTINGS = 'equipment.equipment-settings.update-equipment-settings',
  EDIT_AUTOMATION = 'automation.automation-hub.edit-automation',
  DELETE_AUTOMATION = 'automation.automation-hub.delete-automation',
  CREATE_ORGANIZATION = 'account.organization-switcher.create-organization',
  CREATE_TIER_ONE_ORGANIZATION = 'account.organization-switcher.create-tier-one-organization',
  SAVE_SYSTEM_MAP = 'system-map.system-map-graph.save-system-map',
  ADD_SYSTEM_MAP_FLOW_LINK = 'system-map.flow-link-form.add-flow-link',
  ADD_SYSTEM_MAP_ATTACHMENT = 'system-map.flow-link-form.add-attachment',
  EDIT_SYSTEM_MAP_FLOW_LINK = 'system-map.flow-link-form.edit-flow-link',
  DELETE_SYSTEM_MAP_FLOW_LINK = 'system-map.flow-link-form.delete-flow-link',
  ADD_NODE_TO_TEE = 'system-map.tee.add-node-to-tee',
  RENAME_TEE = 'system-map.tee.rename-tee',
  ADD_TEE = 'system-map.tee.add-tee',
  DELETE_TEE = 'system-map.tee.delete-tee',
  CREATE_NEW_GATEWAY = 'gateways-hub.header.create-new-gateway',
  SHOW_SETTINGS_PROFILE_PAGE = 'settings.settings-people.show-settings-profile-page',
  SHOW_WATER_TRACEABILITY_SECTION = 'sustainability.water-traceability.show-section',

  /// PORTFOLIO PERMISSIONS
  ADD_ORG_TO_PORTFOLIO = 'portfolio.settings.add-org-to',
  ADD_USER_TO_PORTFOLIO = 'portfolio.settings.add-user-to',
  REMOVE_USER_FROM_PORTFOLIO = 'portfolio.settings.remove-user-from',
  EDIT_USER_PORTFOLIO = 'portfolio.settings.edit-user',
  PORTFOLIO_DETAILS = 'portfolio.settings.portfolio-details',
  DELETE_ORG_FROM_PORTFOLIO = 'portfolio.settings.delete-org',
  EDIT_PORTFOLIO = 'portfolio.settings.edit-portfolio',
  DELETE_PORTFOLIO = 'portfolio.settings.delete-portfolio',

  SHOW_SUPPORT_PAGE = 'support.show-page',
  SHOW_SUPPORT_PAGE_ANALYTICS = 'support.show-page-analytics',
  INVITE_OTHER_SUPPORT_USERS = 'support.invite-other-users',
  INVITE_SUPPORT_USERS = 'support.invite-users',

  // NAMED TIERS PERMISSIONS:
  // TIER 2
  SENSOR_DEVICES = 'tier.sensor-devices',
  SYSTEM_MAP = 'tier.system-map',
  SUSTAINABILITY_DATA = 'tier.sustainability-data',
  CUSTOM_AUTOMATIONS = 'tier.custom-automations',
  MULTI_DATA_CHARTS = 'tier.multi-data-charts',
  ENERGY_CALCULATIONS = 'tier.energy-calculations',
  DATA_EXPORT = 'tier.data-export',
  PERFORMANCE_SCORES = 'tier.performance-scores',
  AUTOGENERATED_REPORTS = 'tier.autogenerated-reports',
  //TIER 3
  ENERGY_CALCULATIONS_2 = 'tier.energy-calculations-2',
  MULTI_POINT_CALCULATIONS = 'tier.multi-point-calculations',
  PREDICTIVE_INSIGHTS = 'tier.predictive-insights',
  EQUATION_BUILDER = 'tier.equation-builder',
  MULTI_DATA_CHARTS_2 = 'tier.multi-data-charts-2',
  CUSTOM_REPORT_FORMATS = 'tier.custom-report-formats',
  // Standalone features - vendor
  VENDOR_API = 'vendor.api',
  VENDOR_WEBHOOKS = 'vendor.webhooks',
}

export type TPermission = `${PERMISSIONS}`;
