import Axios, { AxiosInstance } from 'axios';

import { EndpointInterceptor } from './endpoint/endpoint.interceptor';
import { IEndpointConfig } from './endpoint/endpoint.models';
import { getEndpointModule, initEndpointModule } from './endpoint/init';
import { IHttpInterceptor } from './model';

let instance: AxiosInstance | undefined = undefined;

const applyEndpointInterceptor = (instance: AxiosInstance, config: IEndpointConfig) => {
  initEndpointModule(config);

  const interceptor = getEndpointModule().injector.get<EndpointInterceptor, typeof EndpointInterceptor>(
    EndpointInterceptor
  );

  if (!interceptor) {
    return;
  }

  instance.interceptors.request.use(
    (config) => interceptor.intercept(config),
    (error) => interceptor.handleError(error)
  );

  instance.interceptors.response.use(
    (response) => response,
    (error) => interceptor.handleError(error)
  );
};

export const initHttpClient = (config: IEndpointConfig, interceptors: IHttpInterceptor[]) => {
  instance = Axios.create({ timeout: 60000 });

  instance.interceptors.response.use((response) => response.data);

  if (!instance) {
    return;
  }

  applyEndpointInterceptor(instance, config);

  if (!interceptors.length) {
    return;
  }

  interceptors.forEach((interceptor) => {
    instance?.interceptors.request.use(
      (config) => interceptor.intercept(config),
      (error) => interceptor.handleError(error)
    );

    instance?.interceptors.response.use(
      (response) => response,
      (error) => interceptor.handleError(error)
    );
  });
};

export const getHttpClient = () => {
  if (!instance) {
    throw new Error(`HttpClient isn't initialized`);
  }
  return instance;
};
