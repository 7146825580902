import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

interface IStyles {
  isExpanded: boolean;
}

export const useStyles = makeStyles<IStyles>()((theme: MarlinTheme, { isExpanded }) => ({
  southIcon: {
    '& > svg': {
      color: theme.palette.success.light,
      width: isExpanded ? theme.typography.pxToRem(24) : theme.typography.pxToRem(14),
      height: isExpanded ? theme.typography.pxToRem(24) : theme.typography.pxToRem(14),
    },
  },
  northIcon: {
    '& > svg': {
      color: theme.palette.error.main,
      width: isExpanded ? theme.typography.pxToRem(24) : theme.typography.pxToRem(14),
      height: isExpanded ? theme.typography.pxToRem(24) : theme.typography.pxToRem(14),
    },
  },
}));
