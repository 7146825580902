import { EquipmentDetails } from '@marlin/asset/feature/equipment-dashboard';
import { EquipmentHub } from '@marlin/asset/feature/equipment-hub';
import { routes } from '@marlin/shared/utils-routes';
import { Route } from 'react-router-dom';

export const EquipmentDashboardRoutes = () => {
  const path = routes.equipmentDashboard.root;

  return (
    <Route path={path}>
      <Route index element={<EquipmentHub />} />
      <Route path={routes.equipmentDashboard.details.path} element={<EquipmentDetails />} />
    </Route>
  );
};
