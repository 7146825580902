import { TExtractFnReturnType, useQuery } from '@marlin/shared/utils/react-query';

import { getWebhook } from '../infrastructure/get-webhook';
import { TGetWebhookParams } from '../schemas/get-webhook.schema';
import { queryKey } from './query-key.enum';

interface IUseGetWebhookProps {
  params: TGetWebhookParams;
  enabled?: boolean;
}

type TQueryFnType = typeof getWebhook;

export const useGetWebhook = ({ params }: IUseGetWebhookProps) => {
  return useQuery<TExtractFnReturnType<TQueryFnType>>({
    queryKey: queryKey.WEBHOOK(params),
    queryFn: () => getWebhook(params),
    keepPreviousData: true,
  });
};
