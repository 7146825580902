import { MarlinTheme } from '@marlin/shared/theme';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: MarlinTheme) => ({
  container: {
    margin: 0,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    userSelect: 'none',
    position: 'relative',
  },

  innerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  header: {
    margin: 0,
    marginBottom: theme.typography.pxToRem(25),
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      top: theme.typography.pxToRem(50),
    },
  },

  logo: {
    marginRight: theme.typography.pxToRem(25),
    '& > svg': {
      height: theme.typography.pxToRem(55),
      [theme.breakpoints.down('md')]: {
        height: theme.typography.pxToRem(18),
      },
    },
  },

  textLogo: {
    '& > svg': {
      height: theme.typography.pxToRem(55),
      [theme.breakpoints.down('md')]: {
        height: theme.typography.pxToRem(18),
      },
    },
  },

  welcome: {
    margin: 0,
    marginTop: theme.typography.pxToRem(40),
    fontSize: theme.typography.pxToRem(28),
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.typography.pxToRem(40),
      marginRight: theme.typography.pxToRem(40),
      fontSize: theme.typography.pxToRem(24),
      textAlign: 'center',
    },
  },

  body: {
    margin: 0,
    marginTop: theme.typography.pxToRem(15),
    maxWidth: theme.typography.pxToRem(600),
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.typography.pxToRem(40),
      marginRight: theme.typography.pxToRem(40),
    },
  },

  signOutButton: {
    margin: 0,
    marginTop: theme.typography.pxToRem(80),
    minWidth: theme.typography.pxToRem(250),
    [theme.breakpoints.down('md')]: {
      minWidth: theme.typography.pxToRem(120),
      maxWidth: theme.typography.pxToRem(120),
      height: theme.typography.pxToRem(42),
      marginTop: theme.typography.pxToRem(96),
    },
  },
}));
