import isEqual from 'lodash/isEqual';
import { useMemo, useState } from 'react';

import { IBaseFilters, IMobileFilterFormProps, ISortParams } from './model';

export interface IUseMobileFilterFormProps<TSort extends string, TFilters extends IBaseFilters<TSort>> {
  defaultFilters: TFilters;
  sortList: ISortParams<TSort>[];
}

export const useMobileFilterForm = <TSort extends string, TFilters extends IBaseFilters<TSort>>({
  defaultFilters,
  sortList,
}: IUseMobileFilterFormProps<TSort, TFilters>): IMobileFilterFormProps<TSort, TFilters> => {
  const [open, setOpen] = useState<boolean>(false);
  const [filters, setFilterValues] = useState<TFilters>(defaultFilters);

  const toggleOpen = () => setOpen((value) => !value);
  const setFilters = (filters: TFilters) => setFilterValues(filters);
  const clearFilters = () => setFilterValues(defaultFilters);
  const isAnyFilterSet: boolean = useMemo(() => !isEqual(defaultFilters, filters), [defaultFilters, filters]);

  return {
    filters,
    open,
    toggleOpen,
    setFilters,
    clearFilters,
    isAnyFilterSet,
    sortList,
  };
};
