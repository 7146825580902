import { TAnalyticsTelemetryResponse } from '@marlin/shared/utils-format-reading';
import { ModelEnum } from '@marlin/shared/utils/zod';

import { TChartDisplayType } from '../chart-data-types';
import { getUomWithoutPerMin } from '../get-uom-without-per-min';

type TChartTelemetry = TAnalyticsTelemetryResponse['telemetryData'];

export const parseToChartData = (data: TChartTelemetry | undefined, chartDisplayType?: TChartDisplayType) => {
  if (!data) return [];

  return data.map((datapoint) => {
    if (chartDisplayType === 'bar') {
      return {
        ...datapoint,
        values: datapoint.values.map(({ y, x }) => {
          return {
            x,
            y: !y ? null : Number(y),
          };
        }),
        unitOfMeasure: getUomWithoutPerMin(datapoint.unitOfMeasure ?? null) ?? null,
      };
    }

    const unitOfMeasure = datapoint.unitOfMeasure || null;
    const values = datapoint.values.map(({ x, y }) => ({
      x,
      y: !y ? null : Number(y),
    }));

    return { ...datapoint, values, unitOfMeasure };
  });
};

const defaultMaxEmptySpan = 3600;

type TTelemetryValue = { x: number; y: string | undefined };

export const removeEmptyChartData = ({
  data,
  maxEmptySpanToRemoveInSeconds = defaultMaxEmptySpan,
}: {
  data: TAnalyticsTelemetryResponse;
  maxEmptySpanToRemoveInSeconds?: number;
}) => ({
  telemetryData: data.telemetryData.map((data) => {
    const filteredValues: TTelemetryValue[] = [];
    let emptyValues: TTelemetryValue[] = [];
    let lastDefinedValue: TTelemetryValue | undefined = data.values.find((value) => value.y !== undefined);

    if (!lastDefinedValue) {
      return data;
    }

    data.values.forEach((value) => {
      if (!lastDefinedValue) {
        return;
      }

      if (value.y === undefined) {
        emptyValues.push(value);
        return;
      }

      if (value.x - lastDefinedValue.x > maxEmptySpanToRemoveInSeconds * 1000) {
        filteredValues.push(...emptyValues, value);
      } else {
        filteredValues.push(value);
      }
      emptyValues = [];
      lastDefinedValue = value;
    });

    return { ...data, values: filteredValues };
  }),
});

export const isAercoDevice = (model?: string) => {
  if (!model) {
    return false;
  }

  return [
    ModelEnum.enum.AERCO_BOILER_DUAL,
    ModelEnum.enum.AERCO_BOILER_LN,
    ModelEnum.enum.AERCO_WATER_HEATER_N,
    ModelEnum.enum.AERCO_WATER_HEATER_INNOVATION,
  ].includes(ModelEnum.parse(model));
};
