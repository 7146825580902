import { MarlinTheme } from '@marlin/shared/theme';
import { FormField, IFormControlProps, IFormTypedProps } from '@marlin/shared/ui-form-common';
import { FormControl, MenuItem, Select } from '@mui/material';
import { Typography } from '@mui/material';
import { useState } from 'react';
import { ControllerProps, ControllerRenderProps, FieldError, FieldValues } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { TSettingsOptions, useOptions } from './use-options.hook';

type TRenderProps = Partial<Omit<ControllerRenderProps, 'ref'>>;

interface ISelectProps<TValue extends string> {
  className?: string;
  options: TSettingsOptions<TValue>;
  label: string;
  testId?: string;
}

interface ISelectControlProps<TFieldValues extends FieldValues, TValue extends string>
  extends ISelectProps<TValue>,
    TRenderProps {
  error?: FieldError;
  disabled?: boolean;
  defaultValue?: ControllerProps<TFieldValues>['defaultValue'];
  required?: boolean;
  options: TSettingsOptions<TValue>;
  label: string;
}

const useStyles = makeStyles()((theme: MarlinTheme) => ({
  energyOption: {
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.typography.pxToRem(10),
    flexWrap: 'wrap',
  },

  active: {
    color: theme.palette.action.active,
  },

  disabled: {
    color: theme.palette.text.secondary,
  },

  emptyOption: {
    height: theme.typography.pxToRem(36),
  },

  title: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.text.secondary,
    marginBottom: theme.typography.pxToRem(8),
  },
}));

const SelectControl = <TFieldValues extends FieldValues = object, TValue extends string = string>({
  error,
  disabled = false,
  required = false,
  options,
  label,
  testId,
  ...rest
}: ISelectControlProps<TFieldValues, TValue>) => {
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);
  const { selectOptions } = useOptions<TValue>(options);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    if (selectOptions.length) {
      setOpen(true);
    }
  };

  return (
    <FormControl error={!!error} fullWidth={true}>
      <Typography className={classes.title}>{label}</Typography>
      <Select
        id={`settings-select-${testId}`}
        labelId={`settings-select-${testId}-label`}
        required={required}
        fullWidth={true}
        disabled={disabled}
        error={!!error}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        {...rest}
      >
        {selectOptions.map(({ value, name }) => (
          <MenuItem value={value} key={value} onClick={handleClose}>
            <div className={classes.energyOption}>{name}</div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export function SettingsSelectControl<TFieldValues extends FieldValues, TValue extends string>(
  props: IFormControlProps<TFieldValues> & ISelectProps<TValue>
): JSX.Element;
export function SettingsSelectControl<
  TFieldValues extends FieldValues = object,
  TValue extends string = string,
  TName extends ControllerProps<TFieldValues>['name'] = ControllerProps<TFieldValues>['name']
>(props: IFormTypedProps<TFieldValues, TName> & ISelectProps<TValue>): JSX.Element;
export function SettingsSelectControl<
  TFieldValues extends FieldValues,
  TValue extends string = string,
  TName extends ControllerProps<TFieldValues>['name'] = ControllerProps<TFieldValues>['name']
>(params: (IFormControlProps<TFieldValues> | IFormTypedProps<TFieldValues, TName>) & ISelectProps<TValue>) {
  if (params.control) {
    return (
      <FormField {...params}>
        {(props) => (
          <SelectControl<TFieldValues, TValue>
            {...props}
            options={params.options}
            label={params.label}
            className={params?.className}
            disabled={params?.disabled}
            defaultValue={params?.defaultValue}
            required={params?.required}
            testId={params?.testId}
          />
        )}
      </FormField>
    );
  }

  return (
    <FormField<TFieldValues> {...params}>
      {(props) => (
        <SelectControl<TFieldValues, TValue>
          {...props}
          options={params.options}
          label={params.label}
          className={params?.className}
          disabled={params?.disabled}
          required={params?.required}
          testId={params?.testId}
        />
      )}
    </FormField>
  );
}
