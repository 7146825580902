import { PropsWithChildren, ReactNode } from 'react';

import { content } from '../content';
import { LoadingSpinner } from './loading-spinner.component';

interface ILoadableProps extends PropsWithChildren {
  showLoader?: boolean;
  showError?: boolean;
  loaderComponent?: ReactNode;
  errorComponent?: ReactNode;
}

export const Loadable = ({ showLoader, children, showError, loaderComponent, errorComponent }: ILoadableProps) => {
  if (showLoader) {
    return loaderComponent ? loaderComponent : <LoadingSpinner />;
  }

  if (showError) {
    return errorComponent ? errorComponent : content.GENERIC_ERROR_MESSAGE;
  }

  return children;
};
