import { IAdditionalInformation, IAddress, TLocationDetails } from '@marlin/asset/data-access/location';
import { SectionEntry, SectionHeader, SectionTitle } from '@marlin/shared/ui-asset-card-mobile';
import { Link } from '@marlin/shared/ui-page';
import { routes } from '@marlin/shared/utils-routes';

import { content } from '../../../content';
import { AdditionalInformation } from './additional-information.component';
import { Address } from './address.component';
import { useStyles } from './details.styles';

interface IDetailsProps {
  location: TLocationDetails;
}

export const Details = ({ location }: IDetailsProps) => {
  const { classes } = useStyles();

  const additionalInformation: IAdditionalInformation = {
    locationId: location.id,
    numberOfAutomations: location.automationCount,
    numberOfDevices: location.deviceCount,
    numberOfEquipments: location.equipmentCount,
  };

  const address: IAddress = location;

  const locationDataTestId = 'location-details';
  const locationDataEntryTestId = `${locationDataTestId}-entry`;
  const locationSubtitleEntryTestId = `${locationDataTestId}-entry-value`;

  return (
    <div>
      <SectionHeader>
        <SectionTitle testId={locationDataTestId}>{content.DETAILS_TAB}</SectionTitle>
      </SectionHeader>
      <SectionEntry testId={locationDataEntryTestId} title={content.LOCATION_NAME}>
        {location.name}
      </SectionEntry>
      {location.parentLocationId && (
        <SectionEntry testId={locationDataEntryTestId} title={content.PARENT_LOCATION_NAME}>
          {
            <Link to={routes.locations.details.url(location.parentLocationId)} className={classes.link}>
              {location.parentLocationName}
            </Link>
          }
        </SectionEntry>
      )}
      {location.description && (
        <SectionEntry
          testId={locationDataEntryTestId}
          subtitleTestId={locationSubtitleEntryTestId}
          title={content.DESCRIPTION}
          subtitle={location.description}
        />
      )}
      <AdditionalInformation additionalInformation={additionalInformation} />
      <Address address={address} />
    </div>
  );
};
