import { SORT_DIRECTION } from './models';

export const mapSortDirection = (direction: 'asc' | 'desc'): SORT_DIRECTION => {
  switch (direction) {
    case 'asc': {
      return SORT_DIRECTION.Ascending;
    }
    case 'desc': {
      return SORT_DIRECTION.Descending;
    }
  }
};
