import { UnitOfMeasureType } from '@marlin/shared/utils-format-reading';
import { AlertCauseSchema, CriticalitySchema } from '@marlin/system-map/shared/data-access-schemas';
import { z } from 'zod';

export enum RULE_CATEGORY {
  HEALTH = 'Health',
  PERFORMANCE = 'Performance',
  INFORMATION = 'Information',
}

export const NodeAlertSchema = z
  .object({
    alertCause: AlertCauseSchema,
    alertId: z.string(),
    criticality: CriticalitySchema.nullish(),
    alertCriticality: CriticalitySchema.nullish(),
    ruleId: z.string(),
    thresholdMax: z.number().nullish(),
    thresholdMin: z.number().nullish(),
    title: z.string().nullish(),
    deviceEventCode: z.string().nullish(),
    ruleCategory: z.nativeEnum(RULE_CATEGORY).nullish(),
    uoM: UnitOfMeasureType,
  })
  .transform((alert) => ({
    ...alert,
    criticality: alert.criticality || alert.alertCriticality,
    deviceEventCode: alert.deviceEventCode?.toLowerCase(),
  }));

export type TNodeAlert = z.infer<typeof NodeAlertSchema>;
